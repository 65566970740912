module.exports = {
    "110000": {"110100": "北京市"},
    "110100": {
        "110101": "东城区",
        "110102": "西城区",
        "110105": "朝阳区",
        "110106": "丰台区",
        "110107": "石景山区",
        "110108": "海淀区",
        "110109": "门头沟区",
        "110111": "房山区",
        "110112": "通州区",
        "110113": "顺义区",
        "110114": "昌平区",
        "110115": "大兴区",
        "110116": "怀柔区",
        "110117": "平谷区",
        "110118": "密云区",
        "110119": "延庆区"
    },
    "110101": {
        "110101001": "东华门街道",
        "110101002": "景山街道",
        "110101003": "交道口街道",
        "110101004": "安定门街道",
        "110101005": "北新桥街道",
        "110101006": "东四街道",
        "110101007": "朝阳门街道",
        "110101008": "建国门街道",
        "110101009": "东直门街道",
        "110101010": "和平里街道",
        "110101011": "前门街道",
        "110101012": "崇文门外街道",
        "110101013": "东花市街道",
        "110101014": "龙潭街道",
        "110101015": "体育馆路街道",
        "110101016": "天坛街道",
        "110101017": "永定门外街道"
    },
    "110102": {
        "110102001": "西长安街街道",
        "110102003": "新街口街道",
        "110102007": "月坛街道",
        "110102009": "展览路街道",
        "110102010": "德胜街道",
        "110102011": "金融街街道",
        "110102012": "什刹海街道",
        "110102013": "大栅栏街道",
        "110102014": "天桥街道",
        "110102015": "椿树街道",
        "110102016": "陶然亭街道",
        "110102017": "广安门内街道",
        "110102018": "牛街街道",
        "110102019": "白纸坊街道",
        "110102020": "广安门外街道"
    },
    "110105": {
        "110105001": "建外街道",
        "110105002": "朝外街道",
        "110105003": "呼家楼街道",
        "110105004": "三里屯街道",
        "110105005": "左家庄街道",
        "110105006": "香河园街道",
        "110105007": "和平街街道",
        "110105008": "安贞街道",
        "110105009": "亚运村街道",
        "110105010": "小关街道",
        "110105011": "酒仙桥街道",
        "110105012": "麦子店街道",
        "110105013": "团结湖街道",
        "110105014": "六里屯街道",
        "110105015": "八里庄街道",
        "110105016": "双井街道",
        "110105017": "劲松街道",
        "110105018": "潘家园街道",
        "110105019": "垡头街道",
        "110105021": "南磨房地区",
        "110105022": "高碑店地区",
        "110105023": "将台地区",
        "110105024": "太阳宫地区",
        "110105025": "大屯街道",
        "110105026": "望京街道",
        "110105027": "小红门地区",
        "110105028": "十八里店地区",
        "110105029": "平房地区",
        "110105030": "东风地区",
        "110105031": "奥运村街道",
        "110105032": "来广营地区",
        "110105033": "常营地区",
        "110105034": "三间房地区",
        "110105035": "管庄地区",
        "110105036": "金盏地区",
        "110105037": "孙河地区",
        "110105038": "崔各庄地区",
        "110105039": "东坝地区",
        "110105040": "黑庄户地区",
        "110105041": "豆各庄地区",
        "110105042": "王四营地区",
        "110105043": "东湖街道",
        "110105400": "首都机场街道"
    },
    "110106": {
        "110106001": "右安门街道",
        "110106002": "太平桥街道",
        "110106003": "西罗园街道",
        "110106004": "大红门街道",
        "110106005": "南苑街道",
        "110106006": "东高地街道",
        "110106007": "东铁匠营街道",
        "110106008": "卢沟桥街道",
        "110106009": "丰台街道",
        "110106010": "新村街道",
        "110106011": "长辛店街道",
        "110106012": "云岗街道",
        "110106013": "方庄地区",
        "110106014": "宛平城地区",
        "110106015": "马家堡街道",
        "110106016": "和义街道",
        "110106017": "卢沟桥地区",
        "110106018": "花乡地区",
        "110106019": "南苑地区",
        "110106100": "长辛店镇",
        "110106101": "王佐镇"
    },
    "110107": {
        "110107001": "八宝山街道",
        "110107002": "老山街道",
        "110107003": "八角街道",
        "110107004": "古城街道",
        "110107005": "苹果园街道",
        "110107006": "金顶街街道",
        "110107009": "广宁街道",
        "110107010": "五里坨街道",
        "110107011": "鲁谷街道"
    },
    "110108": {
        "110108001": "万寿路街道",
        "110108002": "永定路街道",
        "110108003": "羊坊店街道",
        "110108004": "甘家口街道",
        "110108005": "八里庄街道",
        "110108006": "紫竹院街道",
        "110108007": "北下关街道",
        "110108008": "北太平庄街道",
        "110108010": "学院路街道",
        "110108011": "中关村街道",
        "110108012": "海淀街道",
        "110108013": "青龙桥街道",
        "110108014": "清华园街道",
        "110108015": "燕园街道",
        "110108016": "香山街道",
        "110108017": "清河街道",
        "110108018": "花园路街道",
        "110108019": "西三旗街道",
        "110108020": "马连洼街道",
        "110108021": "田村路街道",
        "110108022": "上地街道",
        "110108023": "万柳地区",
        "110108024": "东升地区",
        "110108025": "曙光街道",
        "110108026": "温泉地区",
        "110108027": "四季青地区",
        "110108028": "西北旺地区",
        "110108029": "苏家坨地区",
        "110108030": "上庄地区"
    },
    "110109": {
        "110109001": "大峪街道",
        "110109002": "城子街道",
        "110109003": "东辛房街道",
        "110109004": "大台街道",
        "110109005": "王平地区",
        "110109006": "永定地区",
        "110109007": "龙泉地区",
        "110109101": "潭柘寺镇",
        "110109104": "军庄镇",
        "110109105": "雁翅镇",
        "110109106": "斋堂镇",
        "110109107": "清水镇",
        "110109108": "妙峰山镇"
    },
    "110111": {
        "110111001": "城关街道",
        "110111002": "新镇街道",
        "110111004": "向阳街道",
        "110111005": "东风街道",
        "110111006": "迎风街道",
        "110111007": "星城街道",
        "110111008": "良乡地区",
        "110111009": "周口店地区",
        "110111010": "琉璃河地区",
        "110111011": "拱辰街道",
        "110111012": "西潞街道",
        "110111101": "阎村镇",
        "110111103": "窦店镇",
        "110111104": "石楼镇",
        "110111105": "长阳镇",
        "110111107": "河北镇",
        "110111108": "长沟镇",
        "110111109": "大石窝镇",
        "110111110": "张坊镇",
        "110111111": "十渡镇",
        "110111112": "青龙湖镇",
        "110111115": "韩村河镇",
        "110111208": "霞云岭乡",
        "110111209": "南窖乡",
        "110111210": "佛子庄乡",
        "110111211": "大安山乡",
        "110111212": "史家营乡",
        "110111213": "蒲洼乡"
    },
    "110112": {
        "110112001": "中仓街道",
        "110112002": "新华街道",
        "110112003": "北苑街道",
        "110112004": "玉桥街道",
        "110112005": "永顺地区",
        "110112006": "梨园地区",
        "110112104": "宋庄镇",
        "110112105": "张家湾镇",
        "110112106": "漷县镇",
        "110112109": "马驹桥镇",
        "110112110": "西集镇",
        "110112114": "台湖镇",
        "110112117": "永乐店镇",
        "110112119": "潞城镇",
        "110112209": "于家务回族乡"
    },
    "110113": {
        "110113001": "胜利街道",
        "110113002": "光明街道",
        "110113003": "仁和地区",
        "110113004": "后沙峪地区",
        "110113005": "天竺地区",
        "110113006": "杨镇地区",
        "110113007": "牛栏山地区",
        "110113008": "南法信地区",
        "110113009": "马坡地区",
        "110113010": "石园街道",
        "110113011": "空港街道",
        "110113012": "双丰街道",
        "110113013": "旺泉街道",
        "110113101": "高丽营镇",
        "110113104": "李桥镇",
        "110113105": "李遂镇",
        "110113106": "南彩镇",
        "110113108": "北务镇",
        "110113109": "大孙各庄镇",
        "110113110": "张镇",
        "110113111": "龙湾屯镇",
        "110113112": "木林镇",
        "110113113": "北小营镇",
        "110113115": "北石槽镇",
        "110113116": "赵全营镇"
    },
    "110114": {
        "110114001": "城北街道",
        "110114002": "南口地区",
        "110114003": "马池口地区",
        "110114004": "沙河地区",
        "110114005": "城南街道",
        "110114007": "东小口地区",
        "110114008": "天通苑北街道",
        "110114009": "天通苑南街道",
        "110114010": "霍营街道",
        "110114011": "回龙观街道",
        "110114012": "龙泽园街道",
        "110114013": "史各庄街道",
        "110114104": "阳坊镇",
        "110114110": "小汤山镇",
        "110114111": "南邵镇",
        "110114112": "崔村镇",
        "110114113": "百善镇",
        "110114115": "北七家镇",
        "110114116": "兴寿镇",
        "110114118": "流村镇",
        "110114119": "十三陵镇",
        "110114120": "延寿镇"
    },
    "110115": {
        "110115001": "兴丰街道",
        "110115002": "林校路街道",
        "110115003": "清源街道",
        "110115004": "亦庄地区",
        "110115005": "黄村地区",
        "110115006": "旧宫地区",
        "110115007": "西红门地区",
        "110115008": "瀛海地区",
        "110115009": "观音寺街道",
        "110115010": "天宫院街道",
        "110115011": "高米店街道",
        "110115012": "荣华街道",
        "110115013": "博兴街道",
        "110115103": "青云店镇",
        "110115104": "采育镇",
        "110115105": "安定镇",
        "110115106": "礼贤镇",
        "110115107": "榆垡镇",
        "110115108": "庞各庄镇",
        "110115109": "北臧村镇",
        "110115110": "魏善庄镇",
        "110115111": "长子营镇",
        "110115403": "北京经济技术开发区",
        "110115404": "中关村国家自主创新示范区大兴生物医药产业基地",
        "110115405": "国家新媒体产业基地"
    },
    "110116": {
        "110116001": "泉河街道",
        "110116002": "龙山街道",
        "110116003": "怀柔地区",
        "110116004": "雁栖地区",
        "110116005": "庙城地区",
        "110116102": "北房镇",
        "110116103": "杨宋镇",
        "110116105": "桥梓镇",
        "110116106": "怀北镇",
        "110116107": "汤河口镇",
        "110116108": "渤海镇",
        "110116109": "九渡河镇",
        "110116110": "琉璃庙镇",
        "110116111": "宝山镇",
        "110116211": "长哨营满族乡",
        "110116213": "喇叭沟门满族乡",
        "110116400": "北京雁栖经济开发区"
    },
    "110117": {
        "110117001": "滨河街道",
        "110117002": "兴谷街道",
        "110117003": "渔阳地区",
        "110117004": "峪口地区",
        "110117005": "马坊地区",
        "110117006": "金海湖地区",
        "110117101": "东高村镇",
        "110117102": "山东庄镇",
        "110117104": "南独乐河镇",
        "110117105": "大华山镇",
        "110117106": "夏各庄镇",
        "110117108": "马昌营镇",
        "110117110": "王辛庄镇",
        "110117111": "大兴庄镇",
        "110117112": "刘家店镇",
        "110117114": "镇罗营镇",
        "110117213": "黄松峪乡",
        "110117214": "熊儿寨乡"
    },
    "110118": {
        "110118001": "鼓楼街道",
        "110118002": "果园街道",
        "110118003": "檀营地区",
        "110118100": "密云镇",
        "110118101": "溪翁庄镇",
        "110118102": "西田各庄镇",
        "110118103": "十里堡镇",
        "110118104": "河南寨镇",
        "110118105": "巨各庄镇",
        "110118106": "穆家峪镇",
        "110118107": "太师屯镇",
        "110118108": "高岭镇",
        "110118109": "不老屯镇",
        "110118110": "冯家峪镇",
        "110118111": "古北口镇",
        "110118112": "大城子镇",
        "110118113": "东邵渠镇",
        "110118114": "北庄镇",
        "110118115": "新城子镇",
        "110118116": "石城镇",
        "110118400": "北京密云经济开发区"
    },
    "110119": {
        "110119001": "百泉街道",
        "110119002": "香水园街道",
        "110119003": "儒林街道",
        "110119100": "延庆镇",
        "110119101": "康庄镇",
        "110119102": "八达岭镇",
        "110119103": "永宁镇",
        "110119104": "旧县镇",
        "110119105": "张山营镇",
        "110119106": "四海镇",
        "110119107": "千家店镇",
        "110119108": "沈家营镇",
        "110119109": "大榆树镇",
        "110119110": "井庄镇",
        "110119200": "大庄科乡",
        "110119201": "刘斌堡乡",
        "110119202": "香营乡",
        "110119203": "珍珠泉乡"
    },
    "120000": {"120100": "天津市"},
    "120100": {
        "120101": "和平区",
        "120102": "河东区",
        "120103": "河西区",
        "120104": "南开区",
        "120105": "河北区",
        "120106": "红桥区",
        "120110": "东丽区",
        "120111": "西青区",
        "120112": "津南区",
        "120113": "北辰区",
        "120114": "武清区",
        "120115": "宝坻区",
        "120116": "滨海新区",
        "120117": "宁河区",
        "120118": "静海区",
        "120119": "蓟州区"
    },
    "120101": {
        "120101001": "劝业场街道",
        "120101002": "小白楼街道",
        "120101003": "五大道街道",
        "120101004": "新兴街道",
        "120101005": "南营门街道",
        "120101006": "南市街道"
    },
    "120102": {
        "120102001": "大王庄街道",
        "120102002": "大直沽街道",
        "120102003": "中山门街道",
        "120102004": "富民路街道",
        "120102005": "二号桥街道",
        "120102006": "春华街道",
        "120102007": "唐家口街道",
        "120102008": "向阳楼街道",
        "120102009": "常州道街道",
        "120102010": "上杭路街道",
        "120102011": "东新街道",
        "120102012": "鲁山道街道",
        "120102013": "天津铁厂街道"
    },
    "120103": {
        "120103001": "大营门街道",
        "120103002": "下瓦房街道",
        "120103003": "桃园街道",
        "120103004": "挂甲寺街道",
        "120103005": "马场街道",
        "120103006": "越秀路街道",
        "120103007": "友谊路街道",
        "120103008": "天塔街道",
        "120103009": "尖山街道",
        "120103010": "陈塘庄街道",
        "120103011": "柳林街道",
        "120103012": "东海街道",
        "120103013": "梅江街道",
        "120103014": "太湖路街道"
    },
    "120104": {
        "120104001": "长虹街道",
        "120104002": "鼓楼街道",
        "120104003": "兴南街道",
        "120104004": "广开街道",
        "120104005": "万兴街道",
        "120104006": "学府街道",
        "120104007": "向阳路街道",
        "120104008": "嘉陵道街道",
        "120104009": "王顶堤街道",
        "120104010": "水上公园街道",
        "120104011": "体育中心街道",
        "120104012": "华苑街道"
    },
    "120105": {
        "120105001": "光复道街道",
        "120105002": "望海楼街道",
        "120105003": "鸿顺里街道",
        "120105004": "新开河街道",
        "120105005": "铁东路街道",
        "120105006": "建昌道街道",
        "120105007": "宁园街道",
        "120105008": "王串场街道",
        "120105009": "江都路街道",
        "120105010": "月牙河街道"
    },
    "120106": {
        "120106001": "西于庄街道",
        "120106002": "双环村街道",
        "120106003": "咸阳北路街道",
        "120106004": "丁字沽街道",
        "120106005": "西沽街道",
        "120106006": "三条石街道",
        "120106008": "邵公庄街道",
        "120106009": "芥园街道",
        "120106010": "铃铛阁街道",
        "120106011": "大胡同街道",
        "120106012": "和苑街道"
    },
    "120110": {
        "120110001": "张贵庄街道",
        "120110002": "丰年村街道",
        "120110003": "万新街道",
        "120110004": "无瑕街道",
        "120110005": "新立街道",
        "120110006": "华明街道",
        "120110007": "金钟街道",
        "120110008": "军粮城街道",
        "120110009": "金桥街道",
        "120110010": "华新街道",
        "120110011": "东丽湖街道",
        "120110400": "天津开发区西区",
        "120110401": "天津航空物流区",
        "120110402": "天津空港经济区",
        "120110403": "东丽区经济技术开发区",
        "120110405": "综合保税区",
        "120110406": "航空新城",
        "120110407": "服务滨海新区建设管理委员会"
    },
    "120111": {
        "120111001": "西营门街道",
        "120111002": "李七庄街道",
        "120111003": "赤龙南街道",
        "120111100": "中北镇",
        "120111101": "杨柳青镇",
        "120111102": "辛口镇",
        "120111103": "张家窝镇",
        "120111104": "精武镇",
        "120111105": "大寺镇",
        "120111106": "王稳庄镇",
        "120111400": "天津开发区微电子小区",
        "120111401": "新技术产业园区",
        "120111402": "西青高校区",
        "120111450": "西青区开发区"
    },
    "120112": {
        "120112001": "双新街道",
        "120112002": "双林街道",
        "120112100": "咸水沽镇",
        "120112101": "葛沽镇",
        "120112102": "小站镇",
        "120112103": "双港镇",
        "120112104": "辛庄镇",
        "120112106": "双桥河镇",
        "120112107": "八里台镇",
        "120112108": "北闸口镇",
        "120112401": "铁路十八局",
        "120112403": "长青",
        "120112404": "津南开发区东区",
        "120112405": "津南开发区西区",
        "120112407": "海河教育园区",
        "120112500": "双林农场",
        "120112501": "津南国家农业园区"
    },
    "120113": {
        "120113001": "果园新村街道",
        "120113002": "集贤里街道",
        "120113003": "普东街道",
        "120113004": "瑞景街道",
        "120113005": "佳荣里街道",
        "120113006": "青源街道",
        "120113007": "广源街道",
        "120113100": "天穆镇",
        "120113101": "北仓镇",
        "120113102": "双街镇",
        "120113103": "双口镇",
        "120113104": "青光镇",
        "120113106": "宜兴埠镇",
        "120113107": "小淀镇",
        "120113108": "大张庄镇",
        "120113111": "西堤头镇",
        "120113400": "科技园区北区",
        "120113401": "科技园区南区",
        "120113402": "天津医药医疗器械工业园",
        "120113403": "天津陆路港物流装备产业园",
        "120113404": "天津风电产业园",
        "120113500": "红旗农场",
        "120113501": "曙光农场"
    },
    "120114": {
        "120114001": "杨村街道",
        "120114002": "下朱庄街道",
        "120114003": "东蒲洼街道",
        "120114004": "黄庄街道",
        "120114005": "徐官屯街道",
        "120114006": "运河西街道",
        "120114101": "梅厂镇",
        "120114102": "大碱厂镇",
        "120114103": "崔黄口镇",
        "120114104": "大良镇",
        "120114105": "下伍旗镇",
        "120114106": "南蔡村镇",
        "120114107": "大孟庄镇",
        "120114108": "泗村店镇",
        "120114109": "河西务镇",
        "120114110": "城关镇",
        "120114111": "东马圈镇",
        "120114112": "黄花店镇",
        "120114113": "石各庄镇",
        "120114114": "王庆坨镇",
        "120114115": "汊沽港镇",
        "120114116": "河北屯镇",
        "120114117": "上马台镇",
        "120114118": "大王古庄镇",
        "120114119": "陈咀镇",
        "120114120": "豆张庄镇",
        "120114121": "曹子里镇",
        "120114122": "大黄堡镇",
        "120114123": "高村镇",
        "120114124": "白古屯镇",
        "120114400": "天津开发区逸仙科学工业园",
        "120114401": "武清经济技术开发区",
        "120114402": "天津武清商务区",
        "120114403": "天津京滨工业园",
        "120114404": "天津京津电子商务产业园",
        "120114405": "天津武清汽车产业园",
        "120114406": "天津京津科技谷",
        "120114407": "京津高村科技创新园",
        "120114500": "武清农场"
    },
    "120115": {
        "120115001": "海滨街道",
        "120115002": "宝平街道",
        "120115003": "钰华街道",
        "120115004": "大白街道",
        "120115005": "口东街道",
        "120115006": "周良街道",
        "120115007": "潮阳街道",
        "120115008": "朝霞街道",
        "120115101": "大口屯镇",
        "120115103": "王卜庄镇",
        "120115104": "方家庄镇",
        "120115105": "林亭口镇",
        "120115106": "八门城镇",
        "120115107": "大钟庄镇",
        "120115108": "新安镇",
        "120115111": "霍各庄镇",
        "120115113": "新开口镇",
        "120115115": "大唐庄镇",
        "120115119": "牛道口镇",
        "120115120": "史各庄镇",
        "120115121": "郝各庄镇",
        "120115123": "牛家牌镇",
        "120115124": "尔王庄镇",
        "120115125": "黄庄镇",
        "120115453": "天宝工业园区",
        "120115454": "九园工业园区",
        "120115455": "京津新城",
        "120115500": "里自沽农场",
        "120115501": "黄庄农场",
        "120115502": "大钟农场"
    },
    "120116": {
        "120116001": "塘沽街道",
        "120116005": "杭州道街道",
        "120116006": "新河街道",
        "120116007": "大沽街道",
        "120116008": "新北街道",
        "120116009": "北塘街道",
        "120116011": "胡家园街道",
        "120116012": "新港街道",
        "120116013": "新村街道",
        "120116014": "泰达街道（天津经济技术开发区东区）",
        "120116031": "汉沽街道",
        "120116032": "寨上街道",
        "120116033": "茶淀街道",
        "120116051": "大港街道",
        "120116053": "古林街道",
        "120116054": "海滨街道",
        "120116100": "新城镇",
        "120116135": "杨家泊镇",
        "120116156": "太平镇",
        "120116157": "小王庄镇",
        "120116158": "中塘镇",
        "120116400": "天津经济技术开发区（其他片区）",
        "120116401": "天津港保税区",
        "120116402": "天津滨海新区高新技术产业开发区",
        "120116403": "东疆保税港区",
        "120116406": "中新天津生态城",
        "120116415": "天津滨海中关村科技园"
    },
    "120117": {
        "120117100": "芦台镇",
        "120117102": "宁河镇",
        "120117103": "苗庄镇",
        "120117104": "丰台镇",
        "120117105": "岳龙镇",
        "120117106": "板桥镇",
        "120117107": "潘庄镇",
        "120117109": "造甲城镇",
        "120117110": "七里海镇",
        "120117111": "大北涧沽镇",
        "120117112": "东棘坨镇",
        "120117113": "北淮淀镇",
        "120117114": "俵口镇",
        "120117115": "廉庄镇",
        "120117400": "宁河区贸易开发区",
        "120117401": "宁河区经济开发区",
        "120117402": "天津未来科技城开发区片区",
        "120117403": "天津未来科技城滨海高新区片区",
        "120117451": "天津未来科技城宁河片区现代产业区组团",
        "120117452": "天津未来科技城宁河片区潘庄工业区组团",
        "120117453": "天津未来科技城宁河片区北淮淀组团",
        "120117500": "潘庄农场"
    },
    "120118": {
        "120118001": "华康街道",
        "120118100": "静海镇",
        "120118101": "唐官屯镇",
        "120118102": "独流镇",
        "120118103": "王口镇",
        "120118104": "台头镇",
        "120118105": "子牙镇",
        "120118106": "陈官屯镇",
        "120118107": "中旺镇",
        "120118108": "大邱庄镇",
        "120118109": "蔡公庄镇",
        "120118110": "梁头镇",
        "120118111": "团泊镇",
        "120118112": "双塘镇",
        "120118113": "大丰堆镇",
        "120118114": "沿庄镇",
        "120118115": "西翟庄镇",
        "120118200": "良王庄乡",
        "120118201": "杨成庄乡",
        "120118400": "天津静海经济开发区",
        "120118402": "天津子牙循环经济产业区",
        "120118403": "团泊新城"
    },
    "120119": {
        "120119001": "文昌街道",
        "120119100": "渔阳镇",
        "120119101": "洇溜镇",
        "120119102": "官庄镇",
        "120119103": "马伸桥镇",
        "120119104": "下营镇",
        "120119105": "邦均镇",
        "120119106": "别山镇",
        "120119108": "尤古庄镇",
        "120119109": "上仓镇",
        "120119111": "下仓镇",
        "120119112": "罗庄子镇",
        "120119113": "白涧镇",
        "120119114": "五百户镇",
        "120119115": "侯家营镇",
        "120119116": "桑梓镇",
        "120119117": "东施古镇",
        "120119118": "下窝头镇",
        "120119119": "杨津庄镇",
        "120119120": "出头岭镇",
        "120119121": "西龙虎峪镇",
        "120119122": "穿芳峪镇",
        "120119123": "东二营镇",
        "120119124": "许家台镇",
        "120119125": "礼明庄镇",
        "120119126": "东赵各庄镇",
        "120119203": "孙各庄乡",
        "120119400": "蓟县新城建设管理委员会"
    },
    "130000": {
        "130100": "石家庄市",
        "130200": "唐山市",
        "130300": "秦皇岛市",
        "130400": "邯郸市",
        "130500": "邢台市",
        "130600": "保定市",
        "130700": "张家口市",
        "130800": "承德市",
        "130900": "沧州市",
        "131000": "廊坊市",
        "131100": "衡水市"
    },
    "130100": {
        "130102": "长安区",
        "130104": "桥西区",
        "130105": "新华区",
        "130107": "井陉矿区",
        "130108": "裕华区",
        "130109": "藁城区",
        "130110": "鹿泉区",
        "130111": "栾城区",
        "130121": "井陉县",
        "130123": "正定县",
        "130125": "行唐县",
        "130126": "灵寿县",
        "130127": "高邑县",
        "130128": "深泽县",
        "130129": "赞皇县",
        "130130": "无极县",
        "130131": "平山县",
        "130132": "元氏县",
        "130133": "赵县",
        "130171": "石家庄高新技术产业开发区",
        "130172": "石家庄循环化工园区",
        "130181": "辛集市",
        "130183": "晋州市",
        "130184": "新乐市"
    },
    "130102": {
        "130102001": "建北街道",
        "130102002": "青园街道",
        "130102003": "广安街道",
        "130102004": "育才街道",
        "130102005": "跃进街道",
        "130102007": "河东街道",
        "130102010": "长丰街道",
        "130102011": "谈固街道",
        "130102012": "中山东路街道",
        "130102013": "阜康街道",
        "130102014": "建安街道",
        "130102015": "胜利北街道",
        "130102100": "西兆通镇",
        "130102101": "南村镇",
        "130102102": "高营镇",
        "130102103": "桃园镇"
    },
    "130104": {
        "130104001": "东里街道",
        "130104002": "中山街道",
        "130104004": "南长街道",
        "130104005": "维明街道",
        "130104007": "友谊街道",
        "130104008": "红旗街道",
        "130104009": "新石街道",
        "130104010": "苑东街道",
        "130104011": "西里街道",
        "130104012": "振头街道",
        "130104013": "留营街道",
        "130104014": "长兴街道",
        "130104015": "彭后街道",
        "130104016": "东风街道",
        "130104017": "东华街道",
        "130104018": "休门街道",
        "130104019": "汇通街道"
    },
    "130105": {
        "130105001": "革新街街道",
        "130105003": "新华路街道",
        "130105004": "宁安街道",
        "130105005": "东焦街道",
        "130105006": "西苑街道",
        "130105007": "合作路街道",
        "130105008": "联盟街道",
        "130105009": "石岗街道",
        "130105011": "天苑街道",
        "130105012": "北苑街道",
        "130105013": "赵陵铺路街道",
        "130105014": "西三庄街道",
        "130105015": "大郭街道",
        "130105016": "杜北街道",
        "130105017": "赵佗路街道"
    },
    "130107": {"130107001": "矿市街道", "130107002": "四微街道", "130107100": "贾庄镇", "130107101": "凤山镇", "130107200": "横涧乡"},
    "130108": {
        "130108001": "裕兴街道",
        "130108002": "裕强街道",
        "130108003": "东苑街道",
        "130108004": "建通街道",
        "130108005": "槐底街道",
        "130108006": "裕华路街道",
        "130108007": "裕东街道",
        "130108008": "裕翔街道",
        "130108009": "建华南街道",
        "130108101": "方村镇"
    },
    "130109": {
        "130109100": "廉州镇",
        "130109101": "兴安镇",
        "130109102": "贾市庄镇",
        "130109103": "南营镇",
        "130109104": "梅花镇",
        "130109105": "岗上镇",
        "130109107": "南董镇",
        "130109108": "张家庄镇",
        "130109109": "南孟镇",
        "130109110": "增村镇",
        "130109111": "常安镇",
        "130109112": "西关镇",
        "130109200": "九门回族乡",
        "130109500": "石家庄经济技术开发区"
    },
    "130110": {
        "130110100": "获鹿镇",
        "130110101": "铜冶镇",
        "130110102": "寺家庄镇",
        "130110103": "上庄镇",
        "130110104": "李村镇",
        "130110105": "宜安镇",
        "130110106": "黄壁庄镇",
        "130110107": "大河镇",
        "130110108": "山尹村镇",
        "130110200": "石井乡",
        "130110201": "白鹿泉乡",
        "130110202": "上寨乡",
        "130110500": "河北鹿泉经济开发区"
    },
    "130111": {
        "130111100": "栾城镇",
        "130111103": "冶河镇",
        "130111104": "窦妪镇",
        "130111105": "楼底镇",
        "130111200": "南高乡",
        "130111201": "柳林屯乡",
        "130111202": "西营乡"
    },
    "130121": {
        "130121100": "微水镇",
        "130121101": "上安镇",
        "130121102": "天长镇",
        "130121103": "秀林镇",
        "130121104": "南峪镇",
        "130121105": "威州镇",
        "130121106": "小作镇",
        "130121107": "南障城镇",
        "130121108": "苍岩山镇",
        "130121109": "测鱼镇",
        "130121200": "吴家窑乡",
        "130121201": "北正乡",
        "130121202": "于家乡",
        "130121203": "孙庄乡",
        "130121204": "南陉乡",
        "130121205": "辛庄乡",
        "130121206": "南王庄乡"
    },
    "130123": {
        "130123001": "诸福屯街道",
        "130123002": "三里屯街道",
        "130123100": "正定镇",
        "130123102": "新城铺镇",
        "130123103": "新安镇",
        "130123200": "南牛乡",
        "130123201": "南楼乡",
        "130123202": "西平乐乡",
        "130123203": "北早现乡",
        "130123204": "曲阳桥乡"
    },
    "130125": {
        "130125100": "龙州镇",
        "130125101": "南桥镇",
        "130125102": "上碑镇",
        "130125103": "口头镇",
        "130125200": "独羊岗乡",
        "130125201": "安香乡",
        "130125202": "只里乡",
        "130125203": "市同乡",
        "130125204": "翟营乡",
        "130125205": "城寨乡",
        "130125206": "上方乡",
        "130125207": "玉亭乡",
        "130125208": "北河乡",
        "130125209": "上闫庄乡",
        "130125210": "九口子乡",
        "130125400": "开发区"
    },
    "130126": {
        "130126100": "灵寿镇",
        "130126101": "青同镇",
        "130126102": "塔上镇",
        "130126103": "陈庄镇",
        "130126104": "慈峪镇",
        "130126105": "岔头镇",
        "130126200": "三圣院乡",
        "130126201": "北洼乡",
        "130126202": "牛城乡",
        "130126203": "狗台乡",
        "130126204": "南寨乡",
        "130126205": "南燕川乡",
        "130126206": "北谭庄乡",
        "130126207": "寨头乡",
        "130126208": "南营乡"
    },
    "130127": {"130127100": "高邑镇", "130127101": "大营镇", "130127102": "富村镇", "130127103": "万城镇", "130127201": "中韩乡"},
    "130128": {
        "130128100": "深泽镇",
        "130128101": "铁杆镇",
        "130128102": "赵八镇",
        "130128200": "白庄乡",
        "130128201": "留村乡",
        "130128203": "桥头乡"
    },
    "130129": {
        "130129100": "赞皇镇",
        "130129101": "院头镇",
        "130129102": "南邢郭镇",
        "130129103": "嶂石岩镇",
        "130129200": "西龙门乡",
        "130129202": "南清河乡",
        "130129203": "西阳泽乡",
        "130129204": "土门乡",
        "130129205": "黄北坪乡",
        "130129207": "许亭乡",
        "130129208": "张楞乡"
    },
    "130130": {
        "130130100": "无极镇",
        "130130101": "七汲镇",
        "130130102": "张段固镇",
        "130130103": "北苏镇",
        "130130104": "郭庄镇",
        "130130105": "大陈镇",
        "130130200": "高头回族乡",
        "130130201": "郝庄乡",
        "130130202": "东侯坊乡",
        "130130203": "里城道乡",
        "130130204": "南流乡"
    },
    "130131": {
        "130131100": "平山镇",
        "130131101": "东回舍镇",
        "130131102": "温塘镇",
        "130131103": "南甸镇",
        "130131104": "岗南镇",
        "130131105": "古月镇",
        "130131106": "下槐镇",
        "130131107": "孟家庄镇",
        "130131108": "小觉镇",
        "130131109": "蛟潭庄镇",
        "130131110": "西柏坡镇",
        "130131111": "下口镇",
        "130131200": "西大吾乡",
        "130131201": "上三汲乡",
        "130131202": "两河乡",
        "130131203": "东王坡乡",
        "130131204": "苏家庄乡",
        "130131205": "宅北乡",
        "130131207": "北冶乡",
        "130131209": "上观音堂乡",
        "130131210": "杨家桥乡",
        "130131211": "营里乡",
        "130131212": "合河口乡"
    },
    "130132": {
        "130132001": "城区街道",
        "130132100": "槐阳镇",
        "130132101": "殷村镇",
        "130132102": "南佐镇",
        "130132103": "宋曹镇",
        "130132104": "南因镇",
        "130132105": "姬村镇",
        "130132106": "北褚镇",
        "130132107": "马村镇",
        "130132201": "东张乡",
        "130132202": "赵同乡",
        "130132204": "苏村乡",
        "130132205": "苏阳乡",
        "130132206": "北正乡",
        "130132207": "前仙乡",
        "130132208": "黑水河乡"
    },
    "130133": {
        "130133100": "赵州镇",
        "130133101": "范庄镇",
        "130133102": "北王里镇",
        "130133103": "新寨店镇",
        "130133104": "韩村镇",
        "130133105": "南柏舍镇",
        "130133106": "沙河店镇",
        "130133200": "前大章乡",
        "130133201": "谢庄乡",
        "130133202": "高村乡",
        "130133203": "王西章乡"
    },
    "130171": {"130171010": "长江街道", "130171011": "太行街道", "130171100": "宋营镇", "130171101": "郄马镇"},
    "130172": {"130172100": "丘头镇"},
    "130181": {
        "130181100": "辛集镇",
        "130181101": "旧城镇",
        "130181102": "张古庄镇",
        "130181103": "位伯镇",
        "130181104": "新垒头镇",
        "130181105": "新城镇",
        "130181106": "南智邱镇",
        "130181107": "王口镇",
        "130181200": "天宫营乡",
        "130181201": "前营乡",
        "130181202": "马庄乡",
        "130181203": "和睦井乡",
        "130181204": "田家庄乡",
        "130181205": "中里厢乡",
        "130181206": "小辛庄乡",
        "130181500": "辛集经济开发区"
    },
    "130183": {
        "130183100": "晋州镇",
        "130183101": "总十庄镇",
        "130183102": "营里镇",
        "130183103": "桃园镇",
        "130183104": "东卓宿镇",
        "130183105": "马于镇",
        "130183106": "小樵镇",
        "130183107": "槐树镇",
        "130183108": "东里庄镇",
        "130183201": "周家庄乡"
    },
    "130184": {
        "130184001": "长寿街道",
        "130184101": "化皮镇",
        "130184102": "承安镇",
        "130184103": "正莫镇",
        "130184104": "南大岳镇",
        "130184105": "杜固镇",
        "130184106": "邯邰镇",
        "130184107": "东王镇",
        "130184108": "马头铺镇",
        "130184200": "协神乡",
        "130184201": "木村乡",
        "130184202": "彭家庄回族乡"
    },
    "130200": {
        "130202": "路南区",
        "130203": "路北区",
        "130204": "古冶区",
        "130205": "开平区",
        "130207": "丰南区",
        "130208": "丰润区",
        "130209": "曹妃甸区",
        "130224": "滦南县",
        "130225": "乐亭县",
        "130227": "迁西县",
        "130229": "玉田县",
        "130271": "唐山市芦台经济技术开发区",
        "130272": "唐山市汉沽管理区",
        "130273": "唐山高新技术产业开发区",
        "130274": "河北唐山海港经济开发区",
        "130281": "遵化市",
        "130283": "迁安市",
        "130284": "滦州市"
    },
    "130202": {
        "130202001": "学院南路街道",
        "130202002": "友谊街道",
        "130202003": "广场街道",
        "130202004": "永红桥街道",
        "130202005": "小山街道",
        "130202006": "文北街道",
        "130202007": "钱营街道",
        "130202008": "惠民道街道",
        "130202009": "梁家屯路街道",
        "130202102": "稻地镇",
        "130202200": "女织寨乡"
    },
    "130203": {
        "130203001": "乔屯街道",
        "130203002": "文化路街道",
        "130203003": "钓鱼台街道",
        "130203004": "东新村街道",
        "130203005": "缸窑街道",
        "130203006": "机场路街道",
        "130203007": "河北路街道",
        "130203008": "龙东街道",
        "130203009": "大里街道",
        "130203010": "光明街道",
        "130203011": "翔云道街道",
        "130203100": "韩城镇",
        "130203200": "果园乡"
    },
    "130204": {
        "130204001": "林西街道",
        "130204002": "唐家庄街道",
        "130204003": "古冶街道",
        "130204004": "赵各庄街道",
        "130204007": "京华街道",
        "130204100": "范各庄镇",
        "130204101": "卑家店镇",
        "130204201": "王辇庄乡",
        "130204202": "习家套乡",
        "130204203": "大庄坨乡"
    },
    "130205": {
        "130205001": "马家沟街道",
        "130205002": "开平街道",
        "130205003": "税务庄街道",
        "130205005": "陡电街道",
        "130205006": "荆各庄街道",
        "130205100": "开平镇",
        "130205101": "栗园镇",
        "130205102": "郑庄子镇",
        "130205103": "双桥镇",
        "130205104": "洼里镇",
        "130205105": "越河镇"
    },
    "130207": {
        "130207103": "小集镇",
        "130207104": "黄各庄镇",
        "130207105": "西葛镇",
        "130207106": "大新庄镇",
        "130207107": "钱营镇",
        "130207108": "唐坊镇",
        "130207109": "王兰庄镇",
        "130207110": "柳树酄镇",
        "130207111": "黑沿子镇",
        "130207113": "丰南镇",
        "130207114": "大齐各庄镇",
        "130207115": "岔河镇",
        "130207201": "南孙庄乡",
        "130207202": "东田庄乡",
        "130207203": "尖字沽乡"
    },
    "130208": {
        "130208001": "太平路街道",
        "130208002": "燕山路街道",
        "130208003": "浭阳街道",
        "130208100": "丰润镇",
        "130208102": "任各庄镇",
        "130208103": "左家坞镇",
        "130208104": "泉河头镇",
        "130208105": "王官营镇",
        "130208106": "火石营镇",
        "130208109": "新军屯镇",
        "130208110": "小张各庄镇",
        "130208111": "丰登坞镇",
        "130208112": "李钊庄镇",
        "130208113": "白官屯镇",
        "130208114": "石各庄镇",
        "130208115": "沙流河镇",
        "130208116": "七树庄镇",
        "130208117": "杨官林镇",
        "130208118": "银城铺镇",
        "130208119": "常庄镇",
        "130208202": "姜家营乡",
        "130208205": "欢喜庄乡",
        "130208208": "刘家营乡"
    },
    "130209": {
        "130209100": "唐海镇",
        "130209112": "滨海镇",
        "130209113": "柳赞镇",
        "130209401": "一农场",
        "130209403": "三农场",
        "130209404": "四农场",
        "130209405": "五农场",
        "130209406": "六农场",
        "130209407": "七农场",
        "130209408": "八农场",
        "130209409": "九农场",
        "130209410": "十农场",
        "130209411": "十一农场",
        "130209412": "八里滩养殖场",
        "130209413": "十里海养殖场",
        "130209450": "南堡经济开发区",
        "130209451": "曹妃甸工业区",
        "130209452": "曹妃甸新城"
    },
    "130224": {
        "130224001": "友谊路街道",
        "130224100": "倴城镇",
        "130224101": "宋道口镇",
        "130224102": "长凝镇",
        "130224103": "胡各庄镇",
        "130224104": "坨里镇",
        "130224105": "姚王庄镇",
        "130224106": "司各庄镇",
        "130224107": "安各庄镇",
        "130224108": "扒齿港镇",
        "130224109": "程庄镇",
        "130224110": "青坨营镇",
        "130224111": "柏各庄镇",
        "130224114": "南堡镇",
        "130224115": "方各庄镇",
        "130224116": "东黄坨镇",
        "130224117": "马城镇"
    },
    "130225": {
        "130225001": "乐安街道",
        "130225100": "乐亭镇",
        "130225101": "汤家河镇",
        "130225102": "胡家坨镇",
        "130225104": "阎各庄镇",
        "130225105": "马头营镇",
        "130225106": "新寨镇",
        "130225107": "汀流河镇",
        "130225108": "姜各庄镇",
        "130225109": "毛庄镇",
        "130225110": "中堡镇",
        "130225201": "庞各庄乡",
        "130225202": "大相各庄乡",
        "130225203": "古河乡",
        "130225453": "河北乐亭经济开发区",
        "130225454": "乐亭县城区工业聚集区管理委员会"
    },
    "130227": {
        "130227001": "迁西县栗乡街道",
        "130227100": "兴城镇",
        "130227101": "金厂峪镇",
        "130227102": "洒河桥镇",
        "130227103": "太平寨镇",
        "130227104": "罗家屯镇",
        "130227105": "东荒峪镇",
        "130227106": "新集镇",
        "130227107": "三屯营镇",
        "130227108": "滦阳镇",
        "130227200": "白庙子乡",
        "130227201": "上营乡",
        "130227202": "汉儿庄乡",
        "130227203": "渔户寨乡",
        "130227204": "旧城乡",
        "130227205": "尹庄乡",
        "130227206": "东莲花院乡",
        "130227207": "新庄子乡",
        "130227450": "迁西县栗乡工业产业聚集区"
    },
    "130229": {
        "130229001": "无终街道",
        "130229100": "玉田镇",
        "130229101": "亮甲店镇",
        "130229102": "鸦鸿桥镇",
        "130229103": "窝洛沽镇",
        "130229104": "石臼窝镇",
        "130229105": "虹桥镇",
        "130229106": "散水头镇",
        "130229107": "林南仓镇",
        "130229108": "林西镇",
        "130229109": "杨家板桥镇",
        "130229110": "彩亭桥镇",
        "130229111": "孤树镇",
        "130229112": "大安镇镇",
        "130229113": "唐自头镇",
        "130229114": "郭家屯镇",
        "130229115": "杨家套镇",
        "130229201": "林头屯乡",
        "130229203": "潮洛窝乡",
        "130229204": "陈家铺乡",
        "130229205": "郭家桥乡"
    },
    "130271": {"130271100": "芦台开发区海北镇", "130271450": "芦台开发区新华路街道"},
    "130272": {"130272101": "汉沽管理区汉丰镇", "130272451": "汉沽管理区兴农", "130272452": "汉沽管理区振兴街道"},
    "130273": {"130273001": "街道", "130273003": "庆北", "130273004": "三女河", "130273101": "老庄子镇"},
    "130274": {"130274103": "唐山海港开发区王滩镇", "130274451": "唐山海港经济开发区街道", "130274452": "唐山湾国际旅游岛", "130274455": "大清河"},
    "130281": {
        "130281001": "华明路街道",
        "130281002": "文化路街道",
        "130281100": "遵化镇",
        "130281101": "堡子店镇",
        "130281102": "马兰峪镇",
        "130281103": "平安城镇",
        "130281104": "东新庄镇",
        "130281105": "新店子镇",
        "130281106": "党峪镇",
        "130281107": "地北头镇",
        "130281108": "东旧寨镇",
        "130281109": "铁厂镇",
        "130281110": "苏家洼镇",
        "130281111": "建明镇",
        "130281112": "石门镇",
        "130281200": "西留村乡",
        "130281201": "崔家庄乡",
        "130281202": "兴旺寨乡",
        "130281203": "西下营满族乡",
        "130281204": "汤泉满族乡",
        "130281205": "东陵满族乡",
        "130281206": "刘备寨乡",
        "130281207": "团瓢庄乡",
        "130281208": "娘娘庄乡",
        "130281209": "西三里乡",
        "130281210": "侯家寨乡",
        "130281211": "小厂乡"
    },
    "130283": {
        "130283100": "迁安镇",
        "130283101": "夏官营镇",
        "130283102": "杨各庄镇",
        "130283103": "建昌营镇",
        "130283104": "赵店子镇",
        "130283105": "野鸡坨镇",
        "130283106": "大崔庄镇",
        "130283107": "杨店子镇",
        "130283108": "蔡园镇",
        "130283109": "马兰庄镇",
        "130283110": "沙河驿镇",
        "130283111": "木厂口镇",
        "130283200": "扣庄乡",
        "130283201": "彭店子乡",
        "130283202": "上射雁庄乡",
        "130283203": "闫家店乡",
        "130283204": "五重安乡",
        "130283205": "大五里乡",
        "130283206": "太平庄乡"
    },
    "130284": {
        "130284001": "滦河街道",
        "130284002": "古城街道",
        "130284003": "滦城路街道",
        "130284004": "响堂街道",
        "130284102": "东安各庄镇",
        "130284103": "雷庄镇",
        "130284104": "茨榆坨镇",
        "130284105": "榛子镇",
        "130284106": "杨柳庄镇",
        "130284107": "油榨镇",
        "130284108": "古马镇",
        "130284109": "小马庄镇",
        "130284110": "九百户镇",
        "130284111": "王店子镇"
    },
    "130300": {
        "130302": "海港区",
        "130303": "山海关区",
        "130304": "北戴河区",
        "130306": "抚宁区",
        "130321": "青龙满族自治县",
        "130322": "昌黎县",
        "130324": "卢龙县",
        "130371": "秦皇岛市经济技术开发区",
        "130372": "北戴河新区"
    },
    "130302": {
        "130302001": "文化路街道",
        "130302002": "海滨路街道",
        "130302003": "北环路街道",
        "130302004": "建设大街街道",
        "130302005": "河东街道",
        "130302006": "西港路街道",
        "130302007": "燕山大街街道",
        "130302008": "港城大街街道",
        "130302009": "东环路街道",
        "130302010": "白塔岭街道",
        "130302100": "东港镇",
        "130302101": "海港镇",
        "130302102": "西港镇",
        "130302103": "海阳镇",
        "130302104": "北港镇",
        "130302105": "杜庄镇",
        "130302106": "石门寨镇",
        "130302107": "驻操营镇",
        "130302403": "临港物流园区",
        "130302404": "海港经济开发区"
    },
    "130303": {
        "130303001": "南关街道",
        "130303002": "古城街道",
        "130303003": "西关街道",
        "130303004": "路南街道",
        "130303100": "第一关镇",
        "130303101": "石河镇",
        "130303102": "孟姜镇"
    },
    "130304": {"130304001": "西山街道", "130304002": "东山街道", "130304100": "海滨镇", "130304101": "戴河镇", "130304103": "牛头崖镇"},
    "130306": {
        "130306001": "骊城街道",
        "130306100": "抚宁镇",
        "130306101": "留守营镇",
        "130306102": "榆关镇",
        "130306105": "台营镇",
        "130306106": "大新寨镇",
        "130306200": "茶棚乡",
        "130306202": "深河乡"
    },
    "130321": {
        "130321001": "都阳路街道",
        "130321100": "青龙镇",
        "130321101": "祖山镇",
        "130321102": "木头凳镇",
        "130321103": "双山子镇",
        "130321104": "马圈子镇",
        "130321105": "肖营子镇",
        "130321106": "大巫岚镇",
        "130321107": "土门子镇",
        "130321108": "八道河镇",
        "130321109": "隔河头镇",
        "130321110": "娄杖子镇",
        "130321200": "凤凰山乡",
        "130321201": "龙王庙乡",
        "130321202": "三星口乡",
        "130321203": "干沟乡",
        "130321206": "大石岭乡",
        "130321208": "官场乡",
        "130321209": "茨榆山乡",
        "130321210": "平方子乡",
        "130321211": "安子岭乡",
        "130321212": "朱杖子乡",
        "130321213": "草碾乡",
        "130321214": "七道河乡",
        "130321217": "三拨子乡",
        "130321218": "凉水河乡"
    },
    "130322": {
        "130322100": "昌黎镇",
        "130322101": "靖安镇",
        "130322102": "安山镇",
        "130322103": "龙家店镇",
        "130322104": "泥井镇",
        "130322105": "大蒲河镇",
        "130322106": "新集镇",
        "130322107": "刘台庄镇",
        "130322108": "茹荷镇",
        "130322109": "朱各庄镇",
        "130322110": "荒佃庄镇",
        "130322201": "团林乡",
        "130322202": "葛条港乡",
        "130322203": "马坨店乡",
        "130322206": "两山乡",
        "130322207": "十里铺乡"
    },
    "130324": {
        "130324100": "卢龙镇",
        "130324101": "潘庄镇",
        "130324102": "燕河营镇",
        "130324103": "双望镇",
        "130324104": "刘田各庄镇",
        "130324105": "石门镇",
        "130324106": "木井镇",
        "130324107": "陈官屯镇",
        "130324108": "蛤泊镇",
        "130324200": "下寨乡",
        "130324201": "刘家营乡",
        "130324203": "印庄乡",
        "130324400": "河北卢龙经济开发区"
    },
    "130371": {
        "130371005": "船厂路街道",
        "130371011": "珠江道街道",
        "130371012": "黄河道街道",
        "130371013": "腾飞路街道",
        "130371200": "渤海乡",
        "130371400": "榆关管理区",
        "130371401": "牛头崖管理区"
    },
    "130372": {"130372001": "南戴河街道", "130372400": "大蒲河管理处", "130372401": "团林管理处", "130372402": "留守营管理处"},
    "130400": {
        "130402": "邯山区",
        "130403": "丛台区",
        "130404": "复兴区",
        "130406": "峰峰矿区",
        "130407": "肥乡区",
        "130408": "永年区",
        "130423": "临漳县",
        "130424": "成安县",
        "130425": "大名县",
        "130426": "涉县",
        "130427": "磁县",
        "130430": "邱县",
        "130431": "鸡泽县",
        "130432": "广平县",
        "130433": "馆陶县",
        "130434": "魏县",
        "130435": "曲周县",
        "130471": "邯郸经济技术开发区",
        "130473": "邯郸冀南新区",
        "130481": "武安市"
    },
    "130402": {
        "130402001": "火磨街道",
        "130402002": "陵园路街道",
        "130402003": "光明路街道",
        "130402004": "滏东街道",
        "130402005": "罗城头街道",
        "130402006": "渚河路街道",
        "130402007": "浴新南街道",
        "130402008": "农林路街道",
        "130402009": "贸东街道",
        "130402010": "贸西街道",
        "130402011": "盛和路街道",
        "130402101": "北张庄镇",
        "130402102": "河沙镇镇",
        "130402200": "马庄乡",
        "130402201": "南堡乡",
        "130402202": "代召乡"
    },
    "130403": {
        "130403001": "丛台西街道",
        "130403002": "联纺西街道",
        "130403003": "联纺东街道",
        "130403004": "光明桥街道",
        "130403005": "丛台东街道",
        "130403006": "四季青街道",
        "130403007": "和平街道",
        "130403008": "中华街道",
        "130403009": "人民路街道",
        "130403010": "柳林桥街道",
        "130403101": "黄粱梦镇",
        "130403200": "苏曹乡",
        "130403205": "三陵乡",
        "130403206": "南吕固乡",
        "130403207": "兼庄乡"
    },
    "130404": {
        "130404001": "胜利桥街道",
        "130404002": "百家村街道",
        "130404003": "铁路大院街道",
        "130404004": "化林路街道",
        "130404005": "庞村街道",
        "130404006": "二六七二街道",
        "130404007": "石化街道",
        "130404100": "户村镇",
        "130404200": "彭家寨乡",
        "130404201": "康庄乡"
    },
    "130406": {
        "130406001": "滏阳东路街道",
        "130406100": "临水镇",
        "130406101": "峰峰镇",
        "130406102": "新坡镇",
        "130406103": "大社镇",
        "130406104": "和村镇",
        "130406105": "义井镇",
        "130406106": "彭城镇",
        "130406107": "界城镇",
        "130406108": "大峪镇",
        "130406200": "西固义乡"
    },
    "130407": {
        "130407100": "肥乡镇",
        "130407101": "天台山镇",
        "130407102": "辛安镇镇",
        "130407103": "大寺上镇",
        "130407104": "东漳堡镇",
        "130407202": "毛演堡乡",
        "130407203": "元固乡",
        "130407204": "屯庄营乡",
        "130407206": "旧店乡"
    },
    "130408": {
        "130408100": "临洺关镇",
        "130408101": "大北汪镇",
        "130408102": "张西堡镇",
        "130408103": "广府镇",
        "130408105": "永合会镇",
        "130408106": "刘营镇",
        "130408107": "西苏镇",
        "130408108": "讲武镇",
        "130408109": "东杨庄镇",
        "130408201": "界河店乡",
        "130408203": "刘汉乡",
        "130408204": "正西乡",
        "130408206": "曲陌乡",
        "130408207": "辛庄堡乡",
        "130408208": "小龙马乡",
        "130408211": "西河庄乡",
        "130408213": "西阳城乡"
    },
    "130423": {
        "130423100": "临漳镇",
        "130423101": "南东坊镇",
        "130423102": "孙陶集镇",
        "130423103": "柳园镇",
        "130423104": "称勾集镇",
        "130423105": "邺城镇",
        "130423106": "章里集镇",
        "130423200": "狄邱乡",
        "130423201": "张村集乡",
        "130423202": "西羊羔乡",
        "130423204": "杜村集乡",
        "130423206": "习文乡",
        "130423207": "砖寨营乡",
        "130423208": "柏鹤集乡"
    },
    "130424": {
        "130424100": "成安镇",
        "130424101": "商城镇",
        "130424102": "漳河店镇",
        "130424103": "李家疃镇",
        "130424104": "北乡义镇",
        "130424200": "辛义乡",
        "130424201": "柏寺营乡",
        "130424202": "道东堡乡",
        "130424204": "长巷乡"
    },
    "130425": {
        "130425100": "大名镇",
        "130425101": "杨桥镇",
        "130425102": "万堤镇",
        "130425103": "龙王庙镇",
        "130425104": "束馆镇",
        "130425105": "金滩镇",
        "130425106": "沙圪塔镇",
        "130425107": "大街镇",
        "130425108": "铺上镇",
        "130425109": "孙甘店镇",
        "130425201": "王村乡",
        "130425203": "黄金堤乡",
        "130425205": "旧治乡",
        "130425206": "西未庄乡",
        "130425208": "西付集乡",
        "130425209": "埝头乡",
        "130425210": "北峰乡",
        "130425211": "张铁集乡",
        "130425212": "红庙乡",
        "130425213": "营镇回族乡"
    },
    "130426": {
        "130426001": "平安街道",
        "130426101": "河南店镇",
        "130426102": "索堡镇",
        "130426103": "西戌镇",
        "130426104": "井店镇",
        "130426105": "更乐镇",
        "130426106": "固新镇",
        "130426107": "西达镇",
        "130426108": "偏城镇",
        "130426200": "神头乡",
        "130426201": "辽城乡",
        "130426202": "偏店乡",
        "130426203": "龙虎乡",
        "130426204": "木井乡",
        "130426205": "关防乡",
        "130426206": "合漳乡",
        "130426207": "鹿头乡",
        "130426400": "涉城镇"
    },
    "130427": {
        "130427100": "磁州镇",
        "130427103": "讲武城镇",
        "130427104": "岳城镇",
        "130427105": "观台镇",
        "130427107": "白土镇",
        "130427108": "黄沙镇",
        "130427200": "路村营乡",
        "130427204": "时村营乡",
        "130427207": "陶泉乡",
        "130427208": "都党乡",
        "130427209": "北贾壁乡"
    },
    "130430": {
        "130430100": "新马头镇",
        "130430101": "邱城镇",
        "130430102": "梁二庄镇",
        "130430103": "香城固镇",
        "130430104": "古城营镇",
        "130430201": "南辛店乡",
        "130430204": "陈村回族乡"
    },
    "130431": {
        "130431100": "鸡泽镇",
        "130431101": "小寨镇",
        "130431102": "双塔镇",
        "130431103": "曹庄镇",
        "130431200": "浮图店乡",
        "130431201": "吴官营乡",
        "130431202": "风正乡"
    },
    "130432": {
        "130432100": "广平镇",
        "130432101": "平固店镇",
        "130432102": "胜营镇",
        "130432103": "南阳堡镇",
        "130432201": "十里铺乡",
        "130432202": "南韩村乡",
        "130432204": "东张孟乡"
    },
    "130433": {
        "130433100": "馆陶镇",
        "130433101": "房寨镇",
        "130433102": "柴堡镇",
        "130433103": "魏僧寨镇",
        "130433200": "寿山寺乡",
        "130433201": "王桥乡",
        "130433202": "南徐村乡",
        "130433203": "路桥乡"
    },
    "130434": {
        "130434100": "魏城镇",
        "130434101": "德政镇",
        "130434102": "北皋镇",
        "130434103": "双井镇",
        "130434104": "牙里镇",
        "130434105": "车往镇",
        "130434106": "回隆镇",
        "130434107": "张二庄镇",
        "130434108": "东代固镇",
        "130434109": "院堡镇",
        "130434110": "棘针寨镇",
        "130434111": "南双庙镇",
        "130434202": "沙口集乡",
        "130434203": "野胡拐乡",
        "130434204": "仕望集乡",
        "130434205": "前大磨乡",
        "130434208": "大辛庄乡",
        "130434209": "大马村乡",
        "130434210": "边马乡",
        "130434212": "北台头乡",
        "130434213": "泊口乡"
    },
    "130435": {
        "130435100": "曲周镇",
        "130435101": "安寨镇",
        "130435102": "侯村镇",
        "130435103": "河南疃镇",
        "130435104": "第四疃镇",
        "130435105": "白寨镇",
        "130435200": "槐桥乡",
        "130435201": "南里岳乡",
        "130435203": "大河道乡",
        "130435204": "依庄乡"
    },
    "130471": {"130471001": "开发区", "130471100": "尚璧镇", "130471101": "南沿村镇", "130471200": "小西堡乡", "130471201": "姚寨乡"},
    "130473": {
        "130473001": "城南",
        "130473100": "高臾镇",
        "130473101": "西光禄镇",
        "130473102": "林坛镇",
        "130473103": "马头镇",
        "130473200": "辛庄营乡",
        "130473201": "花官营乡",
        "130473202": "台城乡",
        "130473203": "南城乡"
    },
    "130481": {
        "130481100": "武安镇",
        "130481101": "康二城镇",
        "130481102": "午汲镇",
        "130481103": "磁山镇",
        "130481104": "伯延镇",
        "130481105": "淑村镇",
        "130481106": "大同镇",
        "130481107": "邑城镇",
        "130481108": "矿山镇",
        "130481109": "贺进镇",
        "130481110": "阳邑镇",
        "130481111": "徘徊镇",
        "130481112": "冶陶镇",
        "130481200": "上团城乡",
        "130481201": "北安庄乡",
        "130481202": "北安乐乡",
        "130481203": "西土山乡",
        "130481204": "西寺庄乡",
        "130481205": "活水乡",
        "130481206": "石洞乡",
        "130481207": "管陶乡",
        "130481208": "马家庄乡"
    },
    "130500": {
        "130502": "桥东区",
        "130503": "桥西区",
        "130521": "邢台县",
        "130522": "临城县",
        "130523": "内丘县",
        "130524": "柏乡县",
        "130525": "隆尧县",
        "130526": "任县",
        "130527": "南和县",
        "130528": "宁晋县",
        "130529": "巨鹿县",
        "130530": "新河县",
        "130531": "广宗县",
        "130532": "平乡县",
        "130533": "威县",
        "130534": "清河县",
        "130535": "临西县",
        "130571": "河北邢台经济开发区",
        "130581": "南宫市",
        "130582": "沙河市"
    },
    "130502": {
        "130502001": "南长街街道",
        "130502002": "北大街街道",
        "130502003": "西大街街道",
        "130502004": "西门里街道",
        "130502005": "泉东街道",
        "130502006": "豫让桥街道",
        "130502100": "东郭村镇",
        "130502101": "祝村镇",
        "130502200": "大梁庄乡"
    },
    "130503": {
        "130503001": "钢铁路街道",
        "130503002": "中兴路街道",
        "130503003": "达活泉街道",
        "130503004": "张宽街道",
        "130503005": "章村街道",
        "130503006": "中华大街街道",
        "130503007": "团结路街道",
        "130503008": "泉西街道",
        "130503100": "南大郭镇",
        "130503101": "李村镇"
    },
    "130521": {
        "130521103": "晏家屯镇",
        "130521104": "南石门镇",
        "130521105": "羊范镇",
        "130521106": "皇寺镇",
        "130521107": "会宁镇",
        "130521108": "西黄村镇",
        "130521109": "路罗镇",
        "130521110": "将军墓镇",
        "130521111": "浆水镇",
        "130521112": "宋家庄镇",
        "130521200": "太子井乡",
        "130521201": "龙泉寺乡",
        "130521202": "北小庄乡",
        "130521203": "城计头乡",
        "130521204": "白岸乡",
        "130521206": "冀家村乡"
    },
    "130522": {
        "130522100": "临城镇",
        "130522101": "东镇镇",
        "130522102": "西竖镇",
        "130522103": "郝庄镇",
        "130522200": "黑城乡",
        "130522201": "鸭鸽营乡",
        "130522203": "石城乡",
        "130522205": "赵庄乡"
    },
    "130523": {
        "130523100": "内丘镇",
        "130523101": "大孟村镇",
        "130523102": "金店镇",
        "130523103": "官庄镇",
        "130523104": "柳林镇",
        "130523200": "五郭店乡",
        "130523203": "南赛乡",
        "130523204": "獐獏乡",
        "130523205": "侯家庄乡"
    },
    "130524": {
        "130524100": "柏乡镇",
        "130524101": "固城店镇",
        "130524102": "西汪镇",
        "130524103": "龙华镇",
        "130524200": "王家庄乡",
        "130524203": "内步乡"
    },
    "130525": {
        "130525100": "隆尧镇",
        "130525101": "魏家庄镇",
        "130525102": "尹村镇",
        "130525103": "山口镇",
        "130525104": "莲子镇镇",
        "130525105": "固城镇",
        "130525106": "东良镇",
        "130525200": "北楼乡",
        "130525202": "双碑乡",
        "130525203": "牛家桥乡",
        "130525204": "千户营乡",
        "130525205": "大张庄乡",
        "130525400": "柳行农场"
    },
    "130526": {
        "130526100": "任城镇",
        "130526101": "邢家湾镇",
        "130526102": "辛店镇",
        "130526103": "天口镇",
        "130526200": "西固城乡",
        "130526201": "永福庄乡",
        "130526202": "大屯乡",
        "130526204": "骆庄乡"
    },
    "130527": {
        "130527100": "和阳镇",
        "130527101": "贾宋镇",
        "130527102": "郝桥镇",
        "130527200": "东三召乡",
        "130527201": "阎里乡",
        "130527202": "河郭乡",
        "130527203": "史召乡",
        "130527204": "三思乡"
    },
    "130528": {
        "130528001": "宁北街道",
        "130528100": "凤凰镇",
        "130528101": "河渠镇",
        "130528102": "北河庄镇",
        "130528103": "耿庄桥镇",
        "130528104": "东汪镇",
        "130528105": "贾家口镇",
        "130528106": "四芝兰镇",
        "130528107": "大陆村镇",
        "130528108": "苏家庄镇",
        "130528109": "换马店镇",
        "130528110": "唐邱镇",
        "130528200": "侯口乡",
        "130528202": "纪昌庄乡",
        "130528205": "北鱼乡",
        "130528208": "徐家河乡",
        "130528209": "大曹庄乡",
        "130528401": "大曹庄管理区"
    },
    "130529": {
        "130529100": "巨鹿镇",
        "130529101": "王虎寨镇",
        "130529102": "西郭城镇",
        "130529103": "官亭镇",
        "130529104": "阎疃镇",
        "130529105": "小吕寨镇",
        "130529106": "苏家营镇",
        "130529200": "堤村乡",
        "130529201": "张王疃乡",
        "130529202": "观寨乡"
    },
    "130530": {
        "130530100": "新河镇",
        "130530101": "寻寨镇",
        "130530200": "白神首乡",
        "130530201": "荆家庄乡",
        "130530202": "西流乡",
        "130530203": "仁让里乡"
    },
    "130531": {
        "130531100": "广宗镇",
        "130531101": "冯家寨镇",
        "130531102": "北塘疃镇",
        "130531103": "核桃园镇",
        "130531200": "葫芦乡",
        "130531201": "大平台乡",
        "130531202": "件只乡",
        "130531204": "东召乡"
    },
    "130532": {
        "130532001": "平乡县中华路街道",
        "130532101": "平乡镇",
        "130532102": "河古庙镇",
        "130532200": "节固乡",
        "130532201": "油召乡",
        "130532202": "田付村乡",
        "130532203": "寻召乡"
    },
    "130533": {
        "130533100": "洺州镇",
        "130533101": "梨园屯镇",
        "130533102": "章台镇",
        "130533103": "侯贯镇",
        "130533104": "七级镇",
        "130533105": "贺营镇",
        "130533106": "方家营镇",
        "130533107": "常庄镇",
        "130533108": "第什营镇",
        "130533109": "贺钊镇",
        "130533110": "赵村镇",
        "130533202": "枣园乡",
        "130533203": "固献乡",
        "130533206": "张家营乡",
        "130533207": "常屯乡",
        "130533209": "高公庄乡"
    },
    "130534": {
        "130534100": "葛仙庄镇",
        "130534101": "连庄镇",
        "130534102": "油坊镇",
        "130534103": "谢炉镇",
        "130534104": "王官庄镇",
        "130534105": "坝营镇"
    },
    "130535": {
        "130535100": "临西镇",
        "130535101": "河西镇",
        "130535102": "下堡寺镇",
        "130535103": "尖冢镇",
        "130535104": "老官寨镇",
        "130535105": "吕寨镇",
        "130535200": "东枣园乡",
        "130535203": "摇鞍镇乡",
        "130535204": "大刘庄乡",
        "130535400": "轴承工业园区"
    },
    "130571": {
        "130571100": "东汪镇",
        "130571101": "王快镇",
        "130571103": "沙河城镇",
        "130571104": "留村镇",
        "130571400": "市高新技术开发区"
    },
    "130581": {
        "130581001": "凤岗街道",
        "130581002": "南杜街道",
        "130581003": "北胡街道",
        "130581004": "西丁街道",
        "130581100": "苏村镇",
        "130581101": "大高村镇",
        "130581102": "垂杨镇",
        "130581103": "明化镇",
        "130581104": "段芦头镇",
        "130581105": "紫冢镇",
        "130581200": "大村乡",
        "130581201": "南便村乡",
        "130581202": "大屯乡",
        "130581203": "王道寨乡",
        "130581204": "薛吴村乡"
    },
    "130582": {
        "130582001": "褡裢街道",
        "130582002": "桥东街道",
        "130582003": "桥西街道",
        "130582004": "赞善",
        "130582005": "周庄街道",
        "130582101": "新城镇",
        "130582102": "白塔镇",
        "130582103": "十里亭镇",
        "130582104": "綦村镇",
        "130582201": "册井乡",
        "130582202": "刘石岗乡",
        "130582203": "柴关乡",
        "130582204": "蝉房乡"
    },
    "130600": {
        "130602": "竞秀区",
        "130606": "莲池区",
        "130607": "满城区",
        "130608": "清苑区",
        "130609": "徐水区",
        "130623": "涞水县",
        "130624": "阜平县",
        "130626": "定兴县",
        "130627": "唐县",
        "130628": "高阳县",
        "130629": "容城县",
        "130630": "涞源县",
        "130631": "望都县",
        "130632": "安新县",
        "130633": "易县",
        "130634": "曲阳县",
        "130635": "蠡县",
        "130636": "顺平县",
        "130637": "博野县",
        "130638": "雄县",
        "130671": "保定高新技术产业开发区",
        "130672": "保定白沟新城",
        "130681": "涿州市",
        "130682": "定州市",
        "130683": "安国市",
        "130684": "高碑店市"
    },
    "130602": {
        "130602001": "先锋街道",
        "130602002": "新市场街道",
        "130602003": "东风街道",
        "130602004": "建南街道",
        "130602005": "韩村北路街道",
        "130602200": "颉庄乡",
        "130602201": "富昌乡",
        "130602202": "韩村乡",
        "130602203": "南奇乡",
        "130602204": "江城乡"
    },
    "130606": {
        "130606001": "和平里街道",
        "130606002": "五四路街道",
        "130606003": "西关街道",
        "130606004": "中华路街道",
        "130606005": "东关街道",
        "130606006": "联盟街道",
        "130606007": "红星街道",
        "130606008": "裕华街道",
        "130606009": "永华街道",
        "130606010": "南关街道",
        "130606200": "韩庄乡",
        "130606201": "东金庄乡",
        "130606202": "百楼乡",
        "130606203": "杨庄乡",
        "130606204": "南大园乡",
        "130606205": "焦庄乡",
        "130606206": "五尧乡"
    },
    "130607": {
        "130607001": "惠阳街道",
        "130607100": "满城镇",
        "130607101": "大册营镇",
        "130607102": "神星镇",
        "130607103": "南韩村镇",
        "130607104": "方顺桥镇",
        "130607201": "于家庄乡",
        "130607203": "要庄乡",
        "130607204": "白龙乡",
        "130607205": "石井乡",
        "130607206": "坨南乡",
        "130607207": "刘家台乡",
        "130607400": "河北满城经济开发区"
    },
    "130608": {
        "130608100": "清苑镇",
        "130608101": "冉庄镇",
        "130608102": "阳城镇",
        "130608103": "魏村镇",
        "130608104": "温仁镇",
        "130608105": "张登镇",
        "130608106": "大庄镇",
        "130608107": "臧村镇",
        "130608108": "望亭镇",
        "130608200": "白团乡",
        "130608201": "北店乡",
        "130608202": "石桥乡",
        "130608203": "李庄乡",
        "130608204": "北王力乡",
        "130608205": "东吕乡",
        "130608206": "何桥乡",
        "130608207": "孙村乡",
        "130608208": "阎庄乡",
        "130608400": "河北清苑经济开发区"
    },
    "130609": {
        "130609100": "安肃镇",
        "130609101": "崔庄镇",
        "130609102": "大因镇",
        "130609103": "遂城镇",
        "130609104": "高林村镇",
        "130609105": "大王店镇",
        "130609106": "漕河镇",
        "130609107": "东史端镇",
        "130609108": "留村镇",
        "130609109": "正村镇",
        "130609203": "户木乡",
        "130609204": "瀑河乡",
        "130609205": "东釜山乡",
        "130609206": "义联庄乡"
    },
    "130623": {
        "130623001": "城区社区管理办公室街道",
        "130623100": "涞水镇",
        "130623101": "永阳镇",
        "130623102": "义安镇",
        "130623103": "石亭镇",
        "130623104": "赵各庄镇",
        "130623105": "九龙镇",
        "130623106": "三坡镇",
        "130623107": "一渡镇",
        "130623108": "明义镇",
        "130623109": "王村镇",
        "130623110": "娄村镇",
        "130623202": "东文山乡",
        "130623205": "其中口乡",
        "130623206": "龙门乡",
        "130623207": "胡家庄乡",
        "130623400": "涞水县经济开发区"
    },
    "130624": {
        "130624100": "阜平镇",
        "130624101": "龙泉关镇",
        "130624102": "平阳镇",
        "130624103": "城南庄镇",
        "130624104": "天生桥镇",
        "130624105": "王林口镇",
        "130624202": "台峪乡",
        "130624203": "大台乡",
        "130624204": "史家寨乡",
        "130624205": "砂窝乡",
        "130624206": "吴王口乡",
        "130624207": "夏庄乡",
        "130624208": "北果元乡"
    },
    "130626": {
        "130626100": "定兴镇",
        "130626101": "固城镇",
        "130626102": "贤寓镇",
        "130626103": "北河镇",
        "130626104": "天宫寺镇",
        "130626105": "小朱庄镇",
        "130626106": "姚村镇",
        "130626200": "东落堡乡",
        "130626201": "高里乡",
        "130626202": "张家庄乡",
        "130626204": "肖村乡",
        "130626205": "柳卓乡",
        "130626206": "杨村乡",
        "130626207": "北田乡",
        "130626208": "北南蔡乡",
        "130626209": "李郁庄乡"
    },
    "130627": {
        "130627100": "仁厚镇",
        "130627101": "王京镇",
        "130627102": "高昌镇",
        "130627103": "北罗镇",
        "130627104": "白合镇",
        "130627105": "军城镇",
        "130627106": "川里镇",
        "130627107": "长古城镇",
        "130627108": "罗庄镇",
        "130627201": "都亭乡",
        "130627202": "南店头乡",
        "130627203": "北店头乡",
        "130627205": "雹水乡",
        "130627206": "大洋乡",
        "130627207": "迷城乡",
        "130627208": "齐家佐乡",
        "130627209": "羊角乡",
        "130627210": "石门乡",
        "130627211": "黄石口乡",
        "130627212": "倒马关乡"
    },
    "130628": {
        "130628001": "高阳县锦华街道",
        "130628101": "庞口镇",
        "130628102": "西演镇",
        "130628103": "邢家南镇",
        "130628104": "晋庄镇",
        "130628202": "蒲口乡",
        "130628203": "小王果庄乡",
        "130628205": "庞家佐乡",
        "130628400": "高阳县经济开发区"
    },
    "130629": {
        "130629100": "容城镇",
        "130629101": "小里镇",
        "130629102": "南张镇",
        "130629103": "大河镇",
        "130629104": "晾马台镇",
        "130629200": "八于乡",
        "130629201": "贾光乡",
        "130629203": "平王乡"
    },
    "130630": {
        "130630100": "涞源镇",
        "130630101": "银坊镇",
        "130630102": "走马驿镇",
        "130630103": "水堡镇",
        "130630104": "王安镇",
        "130630105": "杨家庄镇",
        "130630106": "白石山镇",
        "130630107": "南屯镇",
        "130630201": "南马庄乡",
        "130630202": "北石佛乡",
        "130630203": "金家井乡",
        "130630204": "留家庄乡",
        "130630205": "上庄乡",
        "130630206": "东团堡乡",
        "130630207": "塔崖驿乡",
        "130630208": "乌龙沟乡",
        "130630209": "烟煤洞乡"
    },
    "130631": {
        "130631100": "望都镇",
        "130631101": "固店镇",
        "130631102": "贾村镇",
        "130631103": "中韩庄镇",
        "130631200": "寺庄乡",
        "130631201": "赵庄乡",
        "130631202": "黑堡乡",
        "130631204": "高岭乡",
        "130631400": "望都经济开发区"
    },
    "130632": {
        "130632100": "安新镇",
        "130632101": "大王镇",
        "130632102": "三台镇",
        "130632103": "端村镇",
        "130632104": "赵北口镇",
        "130632105": "同口镇",
        "130632106": "刘李庄镇",
        "130632107": "安州镇",
        "130632108": "老河头镇",
        "130632200": "圈头乡",
        "130632201": "寨里乡",
        "130632202": "芦庄乡",
        "130632203": "龙化乡"
    },
    "130633": {
        "130633100": "易州镇",
        "130633101": "梁格庄镇",
        "130633102": "西陵镇",
        "130633103": "裴山镇",
        "130633104": "塘湖镇",
        "130633105": "狼牙山镇",
        "130633106": "良岗镇",
        "130633107": "紫荆关镇",
        "130633108": "高村镇",
        "130633200": "桥头乡",
        "130633201": "白马乡",
        "130633202": "流井乡",
        "130633204": "高陌乡",
        "130633205": "大龙华乡",
        "130633206": "安格庄乡",
        "130633207": "凌云册乡",
        "130633208": "西山北乡",
        "130633209": "尉都乡",
        "130633210": "独乐乡",
        "130633211": "七峪乡",
        "130633212": "富岗乡",
        "130633213": "坡仓乡",
        "130633214": "牛岗乡",
        "130633215": "桥家河乡",
        "130633216": "甘河净乡",
        "130633217": "蔡家峪乡",
        "130633218": "南城司乡"
    },
    "130634": {
        "130634100": "恒州镇",
        "130634101": "灵山镇",
        "130634102": "燕赵镇",
        "130634103": "羊平镇",
        "130634104": "文德镇",
        "130634105": "晓林镇",
        "130634106": "邸村镇",
        "130634107": "齐村镇",
        "130634200": "路庄子乡",
        "130634201": "下河乡",
        "130634202": "庄窠乡",
        "130634203": "孝墓乡",
        "130634205": "东旺乡",
        "130634208": "产德乡",
        "130634210": "党城乡",
        "130634211": "郎家庄乡",
        "130634212": "范家庄乡",
        "130634213": "北台乡"
    },
    "130635": {
        "130635100": "蠡吾镇",
        "130635101": "留史镇",
        "130635102": "大百尺镇",
        "130635103": "辛兴镇",
        "130635104": "北郭丹镇",
        "130635105": "万安镇",
        "130635106": "桑园镇",
        "130635107": "南庄镇",
        "130635108": "大曲堤镇",
        "130635109": "鲍墟镇",
        "130635200": "小陈乡",
        "130635201": "林堡乡",
        "130635202": "北埝头乡"
    },
    "130636": {
        "130636100": "蒲阳镇",
        "130636101": "高于铺镇",
        "130636102": "腰山镇",
        "130636103": "蒲上镇",
        "130636104": "神南镇",
        "130636201": "白云乡",
        "130636202": "河口乡",
        "130636203": "安阳乡",
        "130636204": "台鱼乡",
        "130636205": "大悲乡"
    },
    "130637": {
        "130637100": "博野镇",
        "130637101": "小店镇",
        "130637102": "程委镇",
        "130637103": "东墟镇",
        "130637104": "北杨镇",
        "130637105": "城东镇",
        "130637203": "南小王乡"
    },
    "130638": {
        "130638100": "雄州镇",
        "130638101": "昝岗镇",
        "130638102": "大营镇",
        "130638103": "龙湾镇",
        "130638104": "朱各庄镇",
        "130638105": "米家务镇",
        "130638106": "鄚州镇",
        "130638107": "苟各庄镇",
        "130638200": "北沙口乡",
        "130638203": "双堂乡",
        "130638204": "张岗乡",
        "130638205": "七间房乡"
    },
    "130671": {"130671202": "贤台乡", "130671205": "大马坊乡", "130671400": "保定国家高新区"},
    "130672": {"130672103": "白沟镇"},
    "130681": {
        "130681001": "双塔街道",
        "130681002": "桃园街道",
        "130681003": "清凉寺街道",
        "130681100": "松林店镇",
        "130681101": "码头镇",
        "130681102": "东城坊镇",
        "130681103": "高官庄镇",
        "130681104": "东仙坡镇",
        "130681105": "百尺竿镇",
        "130681106": "义和庄镇",
        "130681107": "刁窝镇",
        "130681108": "林家屯镇",
        "130681109": "豆庄镇",
        "130681204": "孙庄乡",
        "130681400": "高新技术产业开发区管理委员会",
        "130681401": "京南经济开发区管理委员会"
    },
    "130682": {
        "130682001": "南城区街道",
        "130682002": "北城区街道",
        "130682003": "西城区街道",
        "130682004": "长安路街道",
        "130682100": "留早镇",
        "130682101": "清风店镇",
        "130682102": "庞村镇",
        "130682103": "砖路镇",
        "130682104": "明月店镇",
        "130682105": "叮咛店镇",
        "130682106": "东亭镇",
        "130682107": "大辛庄镇",
        "130682108": "东旺镇",
        "130682109": "高蓬镇",
        "130682110": "邢邑镇",
        "130682111": "李亲顾镇",
        "130682112": "子位镇",
        "130682113": "开元镇",
        "130682115": "周村镇",
        "130682116": "息冢镇",
        "130682203": "东留春乡",
        "130682204": "号头庄回族乡",
        "130682205": "杨家庄乡",
        "130682206": "大鹿庄乡",
        "130682208": "西城乡"
    },
    "130683": {
        "130683001": "药都街道",
        "130683002": "祁州路街道",
        "130683101": "伍仁桥镇",
        "130683102": "石佛镇",
        "130683103": "郑章镇",
        "130683104": "大五女镇",
        "130683105": "西佛落镇",
        "130683106": "西城镇",
        "130683200": "明官店乡",
        "130683201": "南娄底乡",
        "130683204": "北段村乡",
        "130683400": "现代中药工业园区"
    },
    "130684": {
        "130684001": "和平街道",
        "130684002": "军城街道",
        "130684003": "东盛街道",
        "130684004": "北城街道",
        "130684005": "兴华路街道",
        "130684100": "方官镇",
        "130684101": "新城镇",
        "130684102": "泗庄镇",
        "130684104": "辛立庄镇",
        "130684105": "东马营镇",
        "130684106": "辛桥镇",
        "130684107": "肖官营镇",
        "130684108": "张六庄镇",
        "130684201": "梁家营乡"
    },
    "130700": {
        "130702": "桥东区",
        "130703": "桥西区",
        "130705": "宣化区",
        "130706": "下花园区",
        "130708": "万全区",
        "130709": "崇礼区",
        "130722": "张北县",
        "130723": "康保县",
        "130724": "沽源县",
        "130725": "尚义县",
        "130726": "蔚县",
        "130727": "阳原县",
        "130728": "怀安县",
        "130730": "怀来县",
        "130731": "涿鹿县",
        "130732": "赤城县",
        "130771": "张家口市高新技术产业开发区",
        "130772": "张家口市察北管理区",
        "130773": "张家口市塞北管理区"
    },
    "130702": {
        "130702001": "红旗楼街道",
        "130702002": "胜利北路街道",
        "130702003": "五一路街道",
        "130702004": "花园街街道",
        "130702005": "工业路街道",
        "130702101": "姚家庄镇",
        "130702102": "大仓盖镇",
        "130702202": "东望山乡"
    },
    "130703": {
        "130703001": "新华街街道",
        "130703002": "大境门街道",
        "130703003": "明德北街街道",
        "130703004": "明德南街街道",
        "130703005": "堡子里街道",
        "130703006": "南营坊街道",
        "130703007": "工人新村街道",
        "130703100": "东窑子镇"
    },
    "130705": {
        "130705001": "天泰寺街道",
        "130705002": "皇城街道",
        "130705003": "南关街道",
        "130705004": "南大街街道",
        "130705005": "大北街街道",
        "130705006": "工业街街道",
        "130705007": "建国街街道",
        "130705100": "庞家堡镇",
        "130705101": "深井镇",
        "130705102": "崞村镇",
        "130705104": "洋河南镇",
        "130705106": "贾家营镇",
        "130705107": "顾家营镇",
        "130705108": "赵川镇",
        "130705200": "河子西乡",
        "130705201": "春光乡",
        "130705202": "侯家庙乡",
        "130705204": "李家堡乡",
        "130705205": "王家湾乡",
        "130705206": "塔儿村乡",
        "130705207": "江家屯乡"
    },
    "130706": {
        "130706001": "城镇街道",
        "130706002": "煤矿街道",
        "130706200": "花园乡",
        "130706201": "辛庄子乡",
        "130706202": "定方水乡",
        "130706203": "段家堡乡"
    },
    "130708": {
        "130708001": "孔家庄街道",
        "130708100": "孔家庄镇",
        "130708101": "万全镇",
        "130708102": "洗马林镇",
        "130708103": "郭磊庄镇",
        "130708200": "膳房堡乡",
        "130708201": "北新屯乡",
        "130708202": "宣平堡乡",
        "130708203": "高庙堡乡",
        "130708204": "旧堡乡",
        "130708205": "安家堡乡",
        "130708206": "北沙城乡"
    },
    "130709": {
        "130709001": "西湾子街道办",
        "130709100": "西湾子镇",
        "130709101": "高家营镇",
        "130709200": "四台嘴乡",
        "130709201": "红旗营乡",
        "130709202": "石窑子乡",
        "130709203": "驿马图乡",
        "130709204": "石嘴子乡",
        "130709205": "狮子沟乡",
        "130709206": "清三营乡",
        "130709207": "白旗乡"
    },
    "130722": {
        "130722100": "张北镇",
        "130722101": "公会镇",
        "130722102": "二台镇",
        "130722103": "大囫囵镇",
        "130722104": "小二台镇",
        "130722106": "油篓沟镇",
        "130722107": "大河镇",
        "130722200": "台路沟乡",
        "130722202": "馒头营乡",
        "130722203": "二泉井乡",
        "130722204": "单晶河乡",
        "130722206": "海流图乡",
        "130722207": "两面井乡",
        "130722208": "大西湾乡",
        "130722209": "郝家营乡",
        "130722210": "白庙滩乡",
        "130722212": "战海乡",
        "130722213": "三号乡"
    },
    "130723": {
        "130723100": "康保镇",
        "130723101": "张纪镇",
        "130723102": "土城子镇",
        "130723103": "邓油坊镇",
        "130723104": "李家地镇",
        "130723105": "照阳河镇",
        "130723106": "屯垦镇",
        "130723200": "闫油坊乡",
        "130723201": "丹清河乡",
        "130723202": "哈咇嘎乡",
        "130723203": "二号卜乡",
        "130723204": "芦家营乡",
        "130723205": "忠义乡",
        "130723206": "处长地乡",
        "130723207": "满德堂乡",
        "130723500": "康保牧场",
        "130723501": "屯垦林场"
    },
    "130724": {
        "130724100": "平定堡镇",
        "130724101": "小厂镇",
        "130724102": "黄盖淖镇",
        "130724103": "九连城镇",
        "130724200": "高山堡乡",
        "130724201": "小河子乡",
        "130724202": "二道渠乡",
        "130724203": "大二号回族乡",
        "130724204": "闪电河乡",
        "130724205": "长梁乡",
        "130724206": "丰源店乡",
        "130724207": "西辛营乡",
        "130724208": "莲花滩乡",
        "130724209": "白土窑乡"
    },
    "130725": {
        "130725100": "南壕堑镇",
        "130725101": "大青沟镇",
        "130725102": "八道沟镇",
        "130725103": "红土梁镇",
        "130725104": "小蒜沟镇",
        "130725105": "三工地镇",
        "130725106": "满井镇",
        "130725200": "大营盘乡",
        "130725201": "大苏计乡",
        "130725202": "石井乡",
        "130725205": "七甲乡",
        "130725206": "套里庄乡",
        "130725207": "甲石河乡",
        "130725208": "下马圈乡"
    },
    "130726": {
        "130726100": "蔚州镇",
        "130726101": "代王城镇",
        "130726102": "西合营镇",
        "130726103": "吉家庄镇",
        "130726104": "白乐镇",
        "130726105": "暖泉镇",
        "130726106": "南留庄镇",
        "130726107": "北水泉镇",
        "130726108": "桃花镇",
        "130726109": "阳眷镇",
        "130726110": "宋家庄镇",
        "130726200": "下宫村乡",
        "130726202": "南杨庄乡",
        "130726203": "柏树乡",
        "130726204": "常宁乡",
        "130726205": "涌泉庄乡",
        "130726206": "杨庄窠乡",
        "130726207": "南岭庄乡",
        "130726208": "陈家洼乡",
        "130726209": "黄梅乡",
        "130726210": "白草村乡",
        "130726211": "草沟堡乡"
    },
    "130727": {
        "130727100": "西城镇",
        "130727101": "东城镇",
        "130727102": "化稍营镇",
        "130727103": "揣骨疃镇",
        "130727104": "东井集镇",
        "130727200": "要家庄乡",
        "130727201": "东坊城堡乡",
        "130727202": "井儿沟乡",
        "130727203": "三马坊乡",
        "130727204": "高墙乡",
        "130727205": "大田洼乡",
        "130727206": "辛堡乡",
        "130727207": "马圈堡乡",
        "130727208": "浮图讲乡"
    },
    "130728": {
        "130728100": "柴沟堡镇",
        "130728101": "左卫镇",
        "130728102": "头百户镇",
        "130728103": "怀安城镇",
        "130728200": "渡口堡乡",
        "130728201": "第六屯乡",
        "130728202": "西湾堡乡",
        "130728203": "西沙城乡",
        "130728204": "太平庄乡",
        "130728205": "王虎屯乡",
        "130728206": "第三堡乡"
    },
    "130730": {
        "130730100": "沙城镇",
        "130730101": "北辛堡镇",
        "130730102": "新保安镇",
        "130730103": "东花园镇",
        "130730104": "官厅镇",
        "130730105": "桑园镇",
        "130730106": "存瑞镇",
        "130730107": "土木镇",
        "130730108": "大黄庄镇",
        "130730109": "西八里镇",
        "130730110": "小南辛堡镇",
        "130730200": "狼山乡",
        "130730203": "鸡鸣驿乡",
        "130730205": "东八里乡",
        "130730207": "瑞云观乡",
        "130730208": "孙庄子乡",
        "130730210": "王家楼回族乡"
    },
    "130731": {
        "130731100": "涿鹿镇",
        "130731101": "张家堡镇",
        "130731102": "武家沟镇",
        "130731103": "五堡镇",
        "130731104": "保岱镇",
        "130731105": "矾山镇",
        "130731106": "大堡镇",
        "130731107": "河东镇",
        "130731108": "东小庄镇",
        "130731109": "辉耀镇",
        "130731110": "大河南镇",
        "130731111": "温泉屯镇",
        "130731112": "蟒石口镇",
        "130731201": "栾庄乡",
        "130731204": "黑山寺乡",
        "130731205": "卧佛寺乡",
        "130731206": "谢家堡乡"
    },
    "130732": {
        "130732100": "赤城镇",
        "130732101": "田家窑镇",
        "130732102": "龙关镇",
        "130732103": "雕鹗镇",
        "130732104": "独石口镇",
        "130732105": "白草镇",
        "130732106": "龙门所镇",
        "130732107": "后城镇",
        "130732108": "东卯镇",
        "130732200": "炮梁乡",
        "130732201": "大海陀乡",
        "130732202": "镇宁堡乡",
        "130732203": "马营乡",
        "130732204": "云州乡",
        "130732205": "三道川乡",
        "130732206": "东万口乡",
        "130732207": "茨营子乡",
        "130732208": "样田乡"
    },
    "130771": {
        "130771001": "南站街道",
        "130771002": "马路东街道",
        "130771100": "老鸦庄镇",
        "130771101": "沈家屯镇",
        "130771102": "姚家房镇",
        "130771103": "沙岭子镇"
    },
    "130772": {
        "130772105": "沙沟镇",
        "130772215": "宇宙营乡",
        "130772401": "察北管理区黄山管理处",
        "130772402": "察北管理区石门管理处",
        "130772403": "察北管理区乌兰管理处",
        "130772404": "察北管理区金沙管理处",
        "130772405": "察北管理区白塔管理处"
    },
    "130773": {"130773501": "榆树沟管理处", "130773502": "沙梁子管理处", "130773503": "小城子管理处", "130773504": "东大门管理处"},
    "130800": {
        "130802": "双桥区",
        "130803": "双滦区",
        "130804": "鹰手营子矿区",
        "130821": "承德县",
        "130822": "兴隆县",
        "130824": "滦平县",
        "130825": "隆化县",
        "130826": "丰宁满族自治县",
        "130827": "宽城满族自治县",
        "130828": "围场满族蒙古族自治县",
        "130871": "承德高新技术产业开发区",
        "130881": "平泉市"
    },
    "130802": {
        "130802001": "西大街街道",
        "130802002": "头道牌楼街道",
        "130802003": "潘家沟街道",
        "130802004": "中华路街道",
        "130802005": "新华路街道",
        "130802006": "石洞子沟街道",
        "130802007": "桥东街道",
        "130802100": "水泉沟镇",
        "130802101": "狮子沟镇",
        "130802102": "牛圈子沟镇",
        "130802103": "大石庙镇",
        "130802105": "双峰寺镇"
    },
    "130803": {
        "130803001": "元宝山街道",
        "130803002": "钢城街道",
        "130803003": "秀水街道",
        "130803100": "双塔山镇",
        "130803101": "滦河镇",
        "130803102": "大庙镇",
        "130803103": "偏桥子镇",
        "130803104": "西地镇",
        "130803200": "陈栅子乡"
    },
    "130804": {
        "130804001": "鹰手营子矿区铁北路街道",
        "130804100": "鹰手营子镇",
        "130804101": "北马圈子镇",
        "130804102": "寿王坟镇",
        "130804103": "汪家庄镇"
    },
    "130821": {
        "130821100": "下板城镇",
        "130821102": "甲山镇",
        "130821103": "六沟镇",
        "130821104": "三沟镇",
        "130821105": "头沟镇",
        "130821106": "高寺台镇",
        "130821107": "鞍匠镇",
        "130821108": "三家镇",
        "130821109": "磴上镇",
        "130821110": "上谷镇",
        "130821111": "新杖子镇",
        "130821112": "石灰窑镇",
        "130821200": "东小白旗乡",
        "130821202": "刘杖子乡",
        "130821204": "孟家院乡",
        "130821205": "大营子乡",
        "130821206": "八家乡",
        "130821208": "满杖子乡",
        "130821210": "五道河乡",
        "130821211": "岔沟乡",
        "130821212": "岗子满族乡",
        "130821214": "两家满族乡",
        "130821216": "仓子乡"
    },
    "130822": {
        "130822100": "兴隆镇",
        "130822101": "半壁山镇",
        "130822102": "挂兰峪镇",
        "130822103": "青松岭镇",
        "130822104": "六道河镇",
        "130822105": "平安堡镇",
        "130822106": "北营房镇",
        "130822107": "孤山子镇",
        "130822108": "蓝旗营镇",
        "130822109": "雾灵山镇",
        "130822110": "李家营镇",
        "130822111": "大杖子镇",
        "130822112": "三道河镇",
        "130822113": "蘑菇峪镇",
        "130822114": "大水泉镇",
        "130822200": "南天门满族乡",
        "130822202": "八卦岭满族乡",
        "130822203": "陡子峪乡",
        "130822204": "上石洞乡",
        "130822211": "安子岭乡"
    },
    "130824": {
        "130824001": "中兴路街道",
        "130824100": "滦平镇",
        "130824101": "长山峪镇",
        "130824102": "红旗镇",
        "130824103": "金沟屯镇",
        "130824104": "虎什哈镇",
        "130824105": "巴克什营镇",
        "130824106": "张百湾镇",
        "130824107": "付营子镇",
        "130824108": "大屯镇",
        "130824109": "火斗山镇",
        "130824200": "平坊满族乡",
        "130824201": "安纯沟门满族乡",
        "130824205": "小营满族乡",
        "130824206": "西沟满族乡",
        "130824207": "邓厂满族乡",
        "130824208": "五道营子满族乡",
        "130824209": "马营子满族乡",
        "130824210": "付家店满族乡",
        "130824212": "两间房乡",
        "130824213": "涝洼乡"
    },
    "130825": {
        "130825001": "安州街道",
        "130825100": "隆化镇",
        "130825101": "韩麻营镇",
        "130825102": "中关镇",
        "130825103": "七家镇",
        "130825104": "汤头沟镇",
        "130825105": "张三营镇",
        "130825106": "唐三营镇",
        "130825107": "蓝旗镇",
        "130825108": "步古沟镇",
        "130825109": "郭家屯镇",
        "130825200": "荒地乡",
        "130825201": "章吉营乡",
        "130825202": "茅荆坝乡",
        "130825203": "尹家营满族乡",
        "130825204": "庙子沟蒙古族满族乡",
        "130825205": "偏坡营满族乡",
        "130825206": "山湾乡",
        "130825207": "八达营蒙古族乡",
        "130825208": "太平庄满族乡",
        "130825209": "旧屯满族乡",
        "130825210": "西阿超满族蒙古族乡",
        "130825211": "白虎沟满族蒙古族乡",
        "130825212": "碱房乡",
        "130825213": "韩家店乡",
        "130825214": "湾沟门乡"
    },
    "130826": {
        "130826100": "大阁镇",
        "130826101": "大滩镇",
        "130826102": "鱼儿山镇",
        "130826103": "土城镇",
        "130826104": "黄旗镇",
        "130826105": "凤山镇",
        "130826106": "波罗诺镇",
        "130826107": "黑山咀镇",
        "130826108": "天桥镇",
        "130826109": "胡麻营镇",
        "130826200": "万胜永乡",
        "130826201": "四岔口乡",
        "130826202": "苏家店乡",
        "130826203": "外沟门乡",
        "130826204": "草原乡",
        "130826205": "窟窿山乡",
        "130826206": "小坝子乡",
        "130826207": "五道营乡",
        "130826208": "南关蒙古族乡",
        "130826209": "选将营乡",
        "130826210": "西官营乡",
        "130826211": "王营乡",
        "130826212": "北头营乡",
        "130826214": "石人沟乡",
        "130826215": "汤河乡",
        "130826216": "杨木栅子乡"
    },
    "130827": {
        "130827100": "宽城镇",
        "130827101": "龙须门镇",
        "130827102": "峪耳崖镇",
        "130827103": "板城镇",
        "130827104": "汤道河镇",
        "130827105": "饽罗台镇",
        "130827106": "碾子峪镇",
        "130827107": "亮甲台镇",
        "130827108": "化皮溜子镇",
        "130827109": "松岭镇",
        "130827201": "塌山乡",
        "130827203": "孟子岭乡",
        "130827204": "独石沟乡",
        "130827207": "铧尖乡",
        "130827208": "东黄花川乡",
        "130827210": "苇子沟乡",
        "130827211": "大字沟门乡",
        "130827212": "大石柱子乡"
    },
    "130828": {
        "130828100": "围场镇",
        "130828101": "四合永镇",
        "130828102": "克勒沟镇",
        "130828103": "棋盘山镇",
        "130828104": "半截塔镇",
        "130828105": "朝阳地镇",
        "130828106": "朝阳湾镇",
        "130828107": "腰站镇",
        "130828108": "龙头山镇",
        "130828109": "新拨镇",
        "130828110": "御道口镇",
        "130828111": "城子镇",
        "130828200": "道坝子乡",
        "130828203": "黄土坎乡",
        "130828204": "四道沟乡",
        "130828205": "兰旗卡伦乡",
        "130828206": "银窝沟乡",
        "130828207": "新地乡",
        "130828208": "广发永乡",
        "130828209": "育太和乡",
        "130828210": "郭家湾乡",
        "130828211": "杨家湾乡",
        "130828212": "大唤起乡",
        "130828213": "哈里哈乡",
        "130828215": "张家湾乡",
        "130828216": "宝元栈乡",
        "130828217": "山湾子乡",
        "130828218": "三义永乡",
        "130828219": "姜家店乡",
        "130828220": "下伙房乡",
        "130828221": "燕格柏乡",
        "130828222": "牌楼乡",
        "130828224": "老窝铺乡",
        "130828226": "石桌子乡",
        "130828227": "大头山乡",
        "130828228": "南山嘴乡",
        "130828229": "西龙头乡",
        "130828450": "塞罕坝机械林场",
        "130828500": "国营御道口牧场"
    },
    "130871": {"130871104": "冯营子镇", "130871106": "上板城镇"},
    "130881": {
        "130881100": "平泉镇",
        "130881101": "黄土梁子镇",
        "130881102": "榆树林子镇",
        "130881103": "杨树岭镇",
        "130881104": "七沟镇",
        "130881105": "小寺沟镇",
        "130881106": "党坝镇",
        "130881107": "卧龙镇",
        "130881108": "南五十家子镇",
        "130881109": "北五十家子镇",
        "130881110": "桲椤树镇",
        "130881111": "柳溪镇",
        "130881112": "平北镇",
        "130881113": "青河镇",
        "130881114": "台头山镇",
        "130881200": "王土房乡",
        "130881203": "七家岱满族乡",
        "130881206": "茅兰沟满族蒙古族乡",
        "130881210": "道虎沟乡"
    },
    "130900": {
        "130902": "新华区",
        "130903": "运河区",
        "130921": "沧县",
        "130922": "青县",
        "130923": "东光县",
        "130924": "海兴县",
        "130925": "盐山县",
        "130926": "肃宁县",
        "130927": "南皮县",
        "130928": "吴桥县",
        "130929": "献县",
        "130930": "孟村回族自治县",
        "130971": "河北沧州经济开发区",
        "130972": "沧州高新技术产业开发区",
        "130973": "沧州渤海新区",
        "130981": "泊头市",
        "130982": "任丘市",
        "130983": "黄骅市",
        "130984": "河间市"
    },
    "130902": {
        "130902001": "建设北街街道",
        "130902002": "车站街道",
        "130902003": "南大街街道",
        "130902004": "东环街道",
        "130902005": "道东街道",
        "130902200": "小赵庄乡"
    },
    "130903": {
        "130903001": "水月寺街道",
        "130903002": "南环中路街道",
        "130903003": "南湖街道",
        "130903004": "市场街道",
        "130903005": "西环中街街道",
        "130903006": "公园街道",
        "130903100": "小王庄镇",
        "130903200": "南陈屯乡"
    },
    "130921": {
        "130921100": "旧州镇",
        "130921101": "兴济镇",
        "130921102": "杜生镇",
        "130921103": "崔尔庄镇",
        "130921200": "薛官屯乡",
        "130921201": "捷地回族乡",
        "130921202": "张官屯乡",
        "130921203": "李天木回族乡",
        "130921204": "风化店乡",
        "130921205": "姚官屯乡",
        "130921206": "杜林回族乡",
        "130921207": "汪家铺乡",
        "130921208": "刘家庙乡",
        "130921209": "仵龙堂乡",
        "130921210": "大官厅乡",
        "130921211": "高川乡",
        "130921212": "黄递铺乡",
        "130921213": "大褚村回族乡",
        "130921214": "纸房头乡"
    },
    "130922": {
        "130922100": "清州镇",
        "130922101": "金牛镇",
        "130922102": "新兴镇",
        "130922103": "流河镇",
        "130922104": "木门店镇",
        "130922105": "马厂镇",
        "130922106": "盘古镇",
        "130922200": "上伍乡",
        "130922201": "曹寺乡",
        "130922203": "陈嘴乡",
        "130922400": "青县农场"
    },
    "130923": {
        "130923100": "东光镇",
        "130923101": "连镇镇",
        "130923102": "找王镇",
        "130923103": "秦村镇",
        "130923104": "灯明寺镇",
        "130923105": "南霞口镇",
        "130923106": "大单镇",
        "130923107": "龙王李镇",
        "130923201": "于桥乡"
    },
    "130924": {
        "130924100": "苏基镇",
        "130924101": "辛集镇",
        "130924102": "高湾镇",
        "130924200": "赵毛陶乡",
        "130924201": "香坊乡",
        "130924202": "小山乡",
        "130924203": "张会亭乡",
        "130924400": "海兴县农场",
        "130924401": "青先农场",
        "130924402": "青锋农场"
    },
    "130925": {
        "130925100": "盐山镇",
        "130925101": "望树镇",
        "130925102": "庆云镇",
        "130925103": "韩集镇",
        "130925104": "千童镇",
        "130925105": "圣佛镇",
        "130925200": "边务乡",
        "130925201": "小营乡",
        "130925202": "杨集乡",
        "130925203": "孟店乡",
        "130925204": "常庄乡",
        "130925205": "小庄乡"
    },
    "130926": {
        "130926100": "肃宁镇",
        "130926101": "梁家村镇",
        "130926102": "窝北镇",
        "130926103": "尚村镇",
        "130926104": "万里镇",
        "130926105": "师素镇",
        "130926203": "河北留善寺乡",
        "130926204": "付家佐乡",
        "130926205": "邵庄乡"
    },
    "130927": {
        "130927100": "南皮镇",
        "130927101": "冯家口镇",
        "130927102": "寨子镇",
        "130927103": "鲍官屯镇",
        "130927104": "王寺镇",
        "130927105": "乌马营镇",
        "130927200": "大浪淀乡",
        "130927201": "刘八里乡",
        "130927202": "潞灌乡"
    },
    "130928": {
        "130928100": "桑园镇",
        "130928101": "铁城镇",
        "130928102": "于集镇",
        "130928103": "梁集镇",
        "130928104": "安陵镇",
        "130928200": "曹家洼乡",
        "130928201": "宋门乡",
        "130928202": "杨家寺乡",
        "130928203": "沟店铺乡",
        "130928204": "何庄乡",
        "130928400": "吴桥经济技术开发区"
    },
    "130929": {
        "130929100": "乐寿镇",
        "130929101": "淮镇镇",
        "130929102": "郭庄镇",
        "130929103": "河城街镇",
        "130929104": "韩村镇",
        "130929105": "陌南镇",
        "130929106": "陈庄镇",
        "130929205": "徐留高乡",
        "130929206": "商林乡",
        "130929207": "段村乡",
        "130929209": "张村乡",
        "130929210": "临河乡",
        "130929211": "小平王乡",
        "130929212": "十五级乡",
        "130929213": "垒头乡",
        "130929214": "南河头乡",
        "130929215": "西城乡",
        "130929216": "本斋回族乡",
        "130929400": "农场",
        "130929401": "献县经济开发区"
    },
    "130930": {
        "130930100": "孟村镇",
        "130930101": "新县镇",
        "130930102": "辛店镇",
        "130930103": "高寨镇",
        "130930200": "宋庄子乡",
        "130930201": "牛进庄乡"
    },
    "130971": {"130971400": "开发区乡"},
    "130972": {"130972400": "高新乡"},
    "130973": {
        "130973206": "新村回族乡",
        "130973400": "南大港管理区",
        "130973401": "国营中捷农场",
        "130973402": "港城街道",
        "130973403": "临港化工园区"
    },
    "130981": {
        "130981001": "解放街道",
        "130981002": "河东街道",
        "130981003": "古楼街道",
        "130981100": "泊镇",
        "130981101": "交河镇",
        "130981102": "齐桥镇",
        "130981103": "寺门村镇",
        "130981104": "郝村镇",
        "130981105": "富镇镇",
        "130981106": "文庙镇",
        "130981107": "洼里王镇",
        "130981201": "王武庄乡",
        "130981202": "营子乡",
        "130981203": "四营乡",
        "130981204": "西辛店乡",
        "130981400": "泊头市工业区管委会"
    },
    "130982": {
        "130982001": "新华路街道",
        "130982002": "西环路街道",
        "130982003": "永丰路街道",
        "130982004": "中华路",
        "130982005": "渤海路街道",
        "130982006": "油建路街道",
        "130982007": "会战道街道",
        "130982100": "出岸镇",
        "130982101": "石门桥镇",
        "130982102": "吕公堡镇",
        "130982103": "长丰镇",
        "130982106": "梁召镇",
        "130982107": "辛中驿镇",
        "130982108": "麻家坞镇",
        "130982203": "议论堡乡",
        "130982204": "青塔乡",
        "130982205": "北辛庄乡",
        "130982207": "北汉乡",
        "130982208": "于村乡",
        "130982400": "华北石油管理局",
        "130982500": "开发区管理委员会",
        "130982501": "河北任丘雁翎工业园区"
    },
    "130983": {
        "130983001": "骅东街道",
        "130983002": "骅中街道",
        "130983003": "骅西街道",
        "130983100": "黄骅镇",
        "130983101": "南排河镇",
        "130983102": "吕桥镇",
        "130983103": "旧城镇",
        "130983200": "羊二庄回族乡",
        "130983202": "常郭乡",
        "130983203": "滕庄子乡",
        "130983204": "官庄乡",
        "130983205": "齐家务乡",
        "130983207": "羊三木回族乡"
    },
    "130984": {
        "130984001": "瀛州路街道",
        "130984002": "城垣西路街道",
        "130984101": "米各庄镇",
        "130984102": "景和镇",
        "130984103": "卧佛堂镇",
        "130984104": "束城镇",
        "130984105": "留古寺镇",
        "130984106": "沙河桥镇",
        "130984107": "诗经村镇",
        "130984200": "故仙乡",
        "130984201": "黎民居乡",
        "130984202": "兴村乡",
        "130984203": "沙洼乡",
        "130984204": "西九吉乡",
        "130984205": "北石槽乡",
        "130984209": "时村乡",
        "130984210": "行别营乡",
        "130984211": "尊祖庄乡",
        "130984212": "龙华店乡",
        "130984213": "果子洼回族乡",
        "130984401": "河间经济开发区管理委员会",
        "130984402": "河北河间瀛州经济开发区管理委员会"
    },
    "131000": {
        "131002": "安次区",
        "131003": "广阳区",
        "131022": "固安县",
        "131023": "永清县",
        "131024": "香河县",
        "131025": "大城县",
        "131026": "文安县",
        "131028": "大厂回族自治县",
        "131071": "廊坊经济技术开发区",
        "131081": "霸州市",
        "131082": "三河市"
    },
    "131002": {
        "131002001": "银河南路街道",
        "131002002": "光明西道街道",
        "131002003": "永华道街道",
        "131002100": "落垡镇",
        "131002101": "码头镇",
        "131002102": "葛渔城镇",
        "131002103": "东沽港镇",
        "131002104": "调河头镇",
        "131002200": "杨税务乡",
        "131002201": "仇庄乡",
        "131002203": "北史家务乡",
        "131002400": "廊坊龙河高新技术产业开发区",
        "131002401": "河北廊坊高新技术产业开发区",
        "131002402": "河北廊坊龙港经济开发区",
        "131002403": "河北安次经济开发区"
    },
    "131003": {
        "131003001": "银河北路街道",
        "131003002": "爱民东道街道",
        "131003003": "解放道街道",
        "131003004": "新开路街道",
        "131003005": "新源道街道",
        "131003100": "南尖塔镇",
        "131003101": "万庄镇",
        "131003102": "九州镇",
        "131003200": "北旺乡",
        "131003500": "新世纪步行街管理委员会"
    },
    "131022": {
        "131022100": "固安镇",
        "131022101": "宫村镇",
        "131022102": "柳泉镇",
        "131022103": "牛驼镇",
        "131022104": "马庄镇",
        "131022200": "东湾乡",
        "131022201": "彭村乡",
        "131022202": "渠沟乡",
        "131022203": "礼让店乡",
        "131022400": "固安温泉休闲商务产业园区"
    },
    "131023": {
        "131023001": "城区街道",
        "131023100": "永清镇",
        "131023101": "韩村镇",
        "131023102": "后奕镇",
        "131023103": "别古庄镇",
        "131023104": "里澜城镇",
        "131023200": "管家务回族乡",
        "131023201": "曹家务乡",
        "131023202": "龙虎庄乡",
        "131023203": "刘街乡",
        "131023204": "三圣口乡",
        "131023400": "河北永清经济开发区",
        "131023401": "北京亦庄永清高新技术产业开发区区"
    },
    "131024": {
        "131024100": "淑阳镇",
        "131024101": "蒋辛屯镇",
        "131024102": "渠口镇",
        "131024103": "安头屯镇",
        "131024104": "安平镇",
        "131024105": "刘宋镇",
        "131024106": "五百户镇",
        "131024107": "钱旺镇",
        "131024108": "钳屯镇",
        "131024500": "香河开发区",
        "131024501": "香河新兴产业示范区"
    },
    "131025": {
        "131025100": "平舒镇",
        "131025101": "旺村镇",
        "131025102": "大尚屯镇",
        "131025103": "南赵扶镇",
        "131025104": "留各庄镇",
        "131025105": "权村镇",
        "131025106": "里坦镇",
        "131025107": "广安镇",
        "131025200": "北魏乡",
        "131025202": "臧屯乡",
        "131025400": "河北大城经济开发区"
    },
    "131026": {
        "131026100": "文安镇",
        "131026101": "新镇镇",
        "131026102": "苏桥镇",
        "131026103": "大柳河镇",
        "131026104": "左各庄镇",
        "131026105": "滩里镇",
        "131026106": "史各庄镇",
        "131026107": "赵各庄镇",
        "131026108": "兴隆宫镇",
        "131026109": "大留镇镇",
        "131026110": "孙氏镇",
        "131026111": "德归镇",
        "131026200": "大围河回族满族乡"
    },
    "131028": {
        "131028001": "北辰街道",
        "131028100": "大厂镇",
        "131028101": "夏垫镇",
        "131028102": "祁各庄镇",
        "131028103": "邵府镇",
        "131028104": "陈府镇",
        "131028400": "河北大厂高新技术产业开发区"
    },
    "131071": {"131071450": "耀华道街道", "131071451": "云鹏道街道"},
    "131081": {
        "131081001": "裕华街道",
        "131081100": "霸州镇",
        "131081101": "南孟镇",
        "131081102": "信安镇",
        "131081103": "堂二里镇",
        "131081104": "煎茶铺镇",
        "131081105": "胜芳镇",
        "131081106": "杨芬港镇",
        "131081200": "岔河集乡",
        "131081201": "康仙庄乡",
        "131081202": "东杨庄乡",
        "131081203": "王庄子乡",
        "131081204": "东段乡"
    },
    "131082": {
        "131082001": "鼎盛东大街街道",
        "131082002": "泃阳西大街街道",
        "131082100": "泃阳镇",
        "131082101": "李旗庄镇",
        "131082102": "杨庄镇",
        "131082103": "皇庄镇",
        "131082104": "新集镇",
        "131082105": "段甲岭镇",
        "131082106": "黄土庄镇",
        "131082107": "高楼镇",
        "131082108": "齐心庄镇",
        "131082109": "燕郊镇",
        "131082450": "燕郊经济技术开发区",
        "131082451": "三河市农业高新技术园区"
    },
    "131100": {
        "131102": "桃城区",
        "131103": "冀州区",
        "131121": "枣强县",
        "131122": "武邑县",
        "131123": "武强县",
        "131124": "饶阳县",
        "131125": "安平县",
        "131126": "故城县",
        "131127": "景县",
        "131128": "阜城县",
        "131171": "河北衡水高新技术产业开发区",
        "131172": "衡水滨湖新区",
        "131182": "深州市"
    },
    "131102": {
        "131102001": "河西街道",
        "131102002": "河东街道",
        "131102003": "路北街道",
        "131102004": "中华街道",
        "131102100": "郑家河沿镇",
        "131102101": "赵家圈镇",
        "131102102": "邓庄镇",
        "131102200": "何家庄乡"
    },
    "131103": {
        "131103100": "冀州镇",
        "131103102": "官道李镇",
        "131103103": "南午村镇",
        "131103104": "周村镇",
        "131103105": "码头李镇",
        "131103106": "西王镇",
        "131103200": "门家庄乡",
        "131103201": "徐家庄乡",
        "131103202": "北漳淮乡",
        "131103203": "小寨乡"
    },
    "131121": {
        "131121100": "枣强镇",
        "131121101": "恩察镇",
        "131121102": "大营镇",
        "131121103": "嘉会镇",
        "131121104": "马屯镇",
        "131121105": "肖张镇",
        "131121106": "张秀屯镇",
        "131121107": "新屯镇",
        "131121108": "唐林镇",
        "131121202": "王均乡",
        "131121204": "王常乡"
    },
    "131122": {
        "131122100": "武邑镇",
        "131122101": "清凉店镇",
        "131122102": "审坡镇",
        "131122103": "赵桥镇",
        "131122104": "韩庄镇",
        "131122105": "肖桥头镇",
        "131122106": "龙店镇",
        "131122201": "圈头乡",
        "131122203": "大紫塔乡"
    },
    "131123": {
        "131123100": "武强镇",
        "131123101": "街关镇",
        "131123102": "周窝镇",
        "131123103": "东孙庄镇",
        "131123200": "豆村乡",
        "131123201": "北代乡"
    },
    "131124": {
        "131124100": "饶阳镇",
        "131124101": "大尹村镇",
        "131124102": "五公镇",
        "131124103": "大官亭镇",
        "131124104": "王同岳镇",
        "131124202": "留楚乡",
        "131124203": "东里满乡"
    },
    "131125": {
        "131125100": "安平镇",
        "131125101": "马店镇",
        "131125102": "南王庄镇",
        "131125103": "大子文镇",
        "131125104": "东黄城镇",
        "131125200": "大何庄乡",
        "131125201": "程油子乡",
        "131125202": "西两洼乡",
        "131125400": "河北丝网工业园区"
    },
    "131126": {
        "131126100": "郑口镇",
        "131126101": "夏庄镇",
        "131126102": "青罕镇",
        "131126103": "故城镇",
        "131126104": "武官寨镇",
        "131126105": "饶阳店镇",
        "131126106": "军屯镇",
        "131126107": "建国镇",
        "131126108": "西半屯镇",
        "131126109": "房庄镇",
        "131126110": "三朗镇",
        "131126200": "辛庄乡",
        "131126201": "里老乡"
    },
    "131127": {
        "131127100": "景州镇",
        "131127101": "龙华镇",
        "131127102": "广川镇",
        "131127103": "王瞳镇",
        "131127104": "洚河流镇",
        "131127105": "安陵镇",
        "131127106": "杜桥镇",
        "131127107": "王谦寺镇",
        "131127108": "北留智镇",
        "131127109": "留智庙镇",
        "131127110": "梁集镇",
        "131127200": "刘集乡",
        "131127201": "连镇乡",
        "131127203": "温城乡",
        "131127204": "后留名府乡",
        "131127205": "青兰乡",
        "131127400": "河北景县高新技术产业开发区"
    },
    "131128": {
        "131128100": "阜城镇",
        "131128101": "古城镇",
        "131128102": "码头镇",
        "131128103": "霞口镇",
        "131128104": "崔家庙镇",
        "131128105": "漫河镇",
        "131128201": "建桥乡",
        "131128202": "蒋坊乡",
        "131128203": "大白乡",
        "131128204": "王集乡"
    },
    "131171": {"131171201": "大麻森乡", "131171400": "衡水高新技术产业开发区", "131171401": "新型功能材料产业园", "131171402": "苏正"},
    "131172": {"131172101": "魏家屯镇", "131172203": "彭杜村乡", "131172401": "滨湖新区教育园区"},
    "131182": {
        "131182100": "唐奉镇",
        "131182101": "深州镇",
        "131182102": "辰时镇",
        "131182103": "榆科镇",
        "131182104": "魏家桥镇",
        "131182105": "大堤镇",
        "131182106": "前磨头镇",
        "131182107": "王家井镇",
        "131182108": "护驾迟镇",
        "131182109": "大屯镇",
        "131182110": "高古庄镇",
        "131182200": "兵曹乡",
        "131182201": "穆村乡",
        "131182202": "东安庄乡",
        "131182203": "北溪村乡",
        "131182204": "大冯营乡",
        "131182206": "乔屯乡",
        "131182400": "河北省深州监狱",
        "131182401": "深州市城市新区管理委员会",
        "131182403": "经济开发区"
    },
    "140000": {
        "140100": "太原市",
        "140200": "大同市",
        "140300": "阳泉市",
        "140400": "长治市",
        "140500": "晋城市",
        "140600": "朔州市",
        "140700": "晋中市",
        "140800": "运城市",
        "140900": "忻州市",
        "141000": "临汾市",
        "141100": "吕梁市"
    },
    "140100": {
        "140105": "小店区",
        "140106": "迎泽区",
        "140107": "杏花岭区",
        "140108": "尖草坪区",
        "140109": "万柏林区",
        "140110": "晋源区",
        "140121": "清徐县",
        "140122": "阳曲县",
        "140123": "娄烦县",
        "140171": "山西转型综合改革示范区",
        "140181": "古交市"
    },
    "140105": {
        "140105001": "坞城街道",
        "140105002": "营盘街道",
        "140105003": "北营街道",
        "140105004": "平阳路街道",
        "140105005": "黄陵街道",
        "140105006": "小店街道",
        "140105007": "龙城街道",
        "140105100": "北格镇",
        "140105200": "西温庄乡",
        "140105201": "刘家堡乡"
    },
    "140106": {
        "140106001": "柳巷街道",
        "140106002": "文庙街道",
        "140106003": "庙前街道",
        "140106004": "迎泽街道",
        "140106005": "桥东街道",
        "140106006": "老军营街道",
        "140106100": "郝庄镇"
    },
    "140107": {
        "140107001": "巨轮街道",
        "140107002": "三桥街道",
        "140107003": "鼓楼街道",
        "140107004": "杏花岭街道",
        "140107005": "坝陵桥街道",
        "140107006": "大东关街道",
        "140107007": "职工新街街道",
        "140107008": "敦化坊街道",
        "140107009": "涧河街道",
        "140107010": "杨家峪街道",
        "140107200": "中涧河乡",
        "140107201": "小返乡"
    },
    "140108": {
        "140108001": "尖草坪街道",
        "140108002": "光社街道",
        "140108003": "上兰街道",
        "140108004": "南寨街道",
        "140108005": "迎新街道",
        "140108006": "古城街道",
        "140108007": "汇丰街道",
        "140108008": "柴村街道",
        "140108009": "新城街道",
        "140108100": "向阳镇",
        "140108101": "阳曲镇",
        "140108200": "马头水乡",
        "140108201": "柏板乡",
        "140108202": "西焉乡"
    },
    "140109": {
        "140109001": "千峰街道",
        "140109002": "下元街道",
        "140109003": "和平街道",
        "140109004": "兴华街道",
        "140109005": "万柏林街道",
        "140109006": "杜儿坪街道",
        "140109007": "白家庄街道",
        "140109008": "南寒街道",
        "140109009": "东社街道",
        "140109010": "化客头街道",
        "140109011": "小井峪街道",
        "140109012": "西铭街道",
        "140109013": "长风西街街道",
        "140109014": "神堂沟街道",
        "140109200": "王封乡"
    },
    "140110": {
        "140110001": "义井街道",
        "140110002": "罗城街道",
        "140110003": "晋源街道",
        "140110100": "金胜镇",
        "140110101": "晋祠镇",
        "140110102": "姚村镇"
    },
    "140121": {
        "140121100": "清源镇",
        "140121101": "徐沟镇",
        "140121102": "东于镇",
        "140121103": "孟封镇",
        "140121200": "马峪乡",
        "140121201": "柳杜乡",
        "140121202": "西谷乡",
        "140121203": "王答乡",
        "140121204": "集义乡"
    },
    "140122": {
        "140122100": "黄寨镇",
        "140122101": "大盂镇",
        "140122102": "东黄水镇",
        "140122103": "泥屯镇",
        "140122104": "中心镇建设管理办公室",
        "140122200": "高村乡",
        "140122201": "侯村乡",
        "140122202": "凌井店乡",
        "140122203": "西凌井乡",
        "140122204": "北小店乡",
        "140122205": "杨兴乡"
    },
    "140123": {
        "140123100": "娄烦镇",
        "140123101": "静游镇",
        "140123102": "杜交曲镇",
        "140123200": "庙湾乡",
        "140123201": "马家庄乡",
        "140123202": "盖家庄乡",
        "140123203": "米峪镇乡",
        "140123204": "天池店乡"
    },
    "140171": {
        "140171401": "唐槐园区街道",
        "140171402": "学府园区街道",
        "140171403": "阳曲产业园区街道",
        "140171404": "潇河园区街道",
        "140171405": "科创城街道"
    },
    "140181": {
        "140181001": "东曲街道",
        "140181002": "西曲街道",
        "140181003": "桃园街道",
        "140181004": "屯兰街道",
        "140181100": "河口镇",
        "140181101": "镇城底镇",
        "140181102": "马兰镇",
        "140181200": "阁上乡",
        "140181201": "加乐泉乡",
        "140181202": "梭峪乡",
        "140181203": "岔口乡",
        "140181204": "常安乡",
        "140181205": "原相乡",
        "140181206": "邢家社乡"
    },
    "140200": {
        "140212": "新荣区",
        "140213": "平城区",
        "140214": "云冈区",
        "140215": "云州区",
        "140221": "阳高县",
        "140222": "天镇县",
        "140223": "广灵县",
        "140224": "灵丘县",
        "140225": "浑源县",
        "140226": "左云县",
        "140271": "山西大同经济开发区"
    },
    "140212": {
        "140212100": "新荣镇",
        "140212101": "古店镇",
        "140212200": "破鲁堡乡",
        "140212202": "郭家窑乡",
        "140212203": "花元屯乡",
        "140212206": "西村乡",
        "140212207": "上深涧乡",
        "140212209": "堡子湾乡",
        "140212400": "新荣区街道管理中心"
    },
    "140213": {
        "140213001": "南关街道",
        "140213002": "北关街道",
        "140213003": "东街街道",
        "140213004": "西街街道",
        "140213005": "南街街道",
        "140213006": "北街街道",
        "140213007": "新建南路街道",
        "140213008": "新建北路街道",
        "140213009": "大庆路街道",
        "140213010": "新华街街道",
        "140213011": "向阳里街道",
        "140213012": "振华南街街道",
        "140213013": "开源街街道",
        "140213201": "新旺乡",
        "140213202": "水泊寺乡",
        "140213203": "马军营乡"
    },
    "140214": {
        "140214001": "新胜街道",
        "140214002": "新平旺街道",
        "140214003": "煤峪口街道",
        "140214004": "永定庄街道",
        "140214005": "同家梁街道",
        "140214006": "四老沟街道",
        "140214007": "忻州窑街道",
        "140214008": "白洞街道",
        "140214009": "雁崖街道",
        "140214010": "挖金湾街道",
        "140214011": "晋华宫街道",
        "140214012": "马脊梁街道",
        "140214013": "大斗沟街道",
        "140214014": "王村街道",
        "140214015": "姜家湾街道",
        "140214016": "新泉路街道",
        "140214017": "民胜街道",
        "140214018": "口泉街道",
        "140214019": "马口街道",
        "140214020": "燕子山街道",
        "140214021": "杏儿沟街道",
        "140214022": "青磁窑街道",
        "140214023": "平泉路街道",
        "140214024": "四台沟街道",
        "140214025": "和顺街道",
        "140214026": "和瑞街道",
        "140214027": "平盛路街道",
        "140214028": "清泉街街道",
        "140214029": "西花园街道",
        "140214030": "老平旺街道",
        "140214101": "高山镇",
        "140214102": "云冈镇",
        "140214200": "口泉乡",
        "140214206": "西韩岭乡",
        "140214207": "平旺乡",
        "140214210": "鸦儿崖乡"
    },
    "140215": {
        "140215100": "西坪镇",
        "140215101": "倍加造镇",
        "140215102": "周士庄镇",
        "140215201": "吉家庄乡",
        "140215202": "峰峪乡",
        "140215204": "杜庄乡",
        "140215205": "党留庄乡",
        "140215207": "瓜园乡",
        "140215209": "聚乐乡",
        "140215211": "许堡乡",
        "140215401": "湖东",
        "140215402": "东街",
        "140215403": "西街"
    },
    "140221": {
        "140221100": "龙泉镇",
        "140221101": "罗文皂镇",
        "140221102": "大白登镇",
        "140221103": "王官屯镇",
        "140221104": "古城镇",
        "140221105": "东小村镇",
        "140221106": "友宰镇",
        "140221201": "长城乡",
        "140221202": "北徐屯乡",
        "140221204": "狮子屯乡",
        "140221209": "下深井乡",
        "140221212": "鳌石乡"
    },
    "140222": {
        "140222100": "玉泉镇",
        "140222101": "谷前堡镇",
        "140222102": "米薪关镇",
        "140222103": "逯家湾镇",
        "140222104": "新平堡镇",
        "140222200": "卅里铺乡",
        "140222202": "南河堡乡",
        "140222204": "贾家屯乡",
        "140222206": "赵家沟乡",
        "140222208": "南高崖乡",
        "140222209": "张西河乡",
        "140222210": "马家皂乡"
    },
    "140223": {
        "140223100": "壶泉镇",
        "140223101": "南村镇",
        "140223200": "一斗泉乡",
        "140223202": "蕉山乡",
        "140223203": "加斗乡",
        "140223206": "宜兴乡",
        "140223207": "作疃乡",
        "140223209": "梁庄乡",
        "140223211": "望狐乡"
    },
    "140224": {
        "140224100": "武灵镇",
        "140224101": "东河南镇",
        "140224102": "上寨镇",
        "140224200": "落水河乡",
        "140224203": "史庄乡",
        "140224205": "赵北乡",
        "140224206": "石家田乡",
        "140224207": "柳科乡",
        "140224208": "白崖台乡",
        "140224210": "红石塄乡",
        "140224212": "下关乡",
        "140224213": "独峪乡"
    },
    "140225": {
        "140225100": "永安镇",
        "140225101": "西坊城镇",
        "140225102": "蔡村镇",
        "140225103": "沙圪坨镇",
        "140225104": "王庄堡镇",
        "140225105": "大磁窑镇",
        "140225201": "东坊城乡",
        "140225203": "裴村乡",
        "140225204": "驼峰乡",
        "140225205": "西留村乡",
        "140225206": "下韩村乡",
        "140225208": "南榆林乡",
        "140225210": "吴城乡",
        "140225212": "黄花滩乡",
        "140225213": "大仁庄乡",
        "140225215": "千佛岭乡",
        "140225218": "官儿乡",
        "140225220": "青磁窑乡"
    },
    "140226": {
        "140226100": "云兴镇",
        "140226101": "鹊儿山镇",
        "140226102": "店湾镇",
        "140226200": "管家堡乡",
        "140226201": "张家场乡",
        "140226205": "三屯乡",
        "140226206": "马道头乡",
        "140226207": "小京庄乡",
        "140226209": "水窑乡"
    },
    "140271": {"140271400": "经济技术开发区乡级单位"},
    "140300": {"140302": "城区", "140303": "矿区", "140311": "郊区", "140321": "平定县", "140322": "盂县"},
    "140302": {
        "140302001": "上站街道",
        "140302002": "下站街道",
        "140302003": "北大街街道",
        "140302004": "南山路街道",
        "140302005": "义井街道",
        "140302006": "坡底街道",
        "140302100": "义井镇"
    },
    "140303": {
        "140303001": "平潭街街道",
        "140303003": "蔡洼街道",
        "140303004": "赛鱼街道",
        "140303005": "沙坪街道",
        "140303006": "贵石沟街道",
        "140303007": "桥头街道"
    },
    "140311": {
        "140311100": "荫营镇",
        "140311101": "河底镇",
        "140311103": "平坦镇",
        "140311200": "西南舁乡",
        "140311201": "杨家庄乡",
        "140311202": "李家庄乡",
        "140311203": "旧街乡",
        "140311400": "开发区"
    },
    "140321": {
        "140321100": "冠山镇",
        "140321101": "冶西镇",
        "140321102": "锁簧镇",
        "140321103": "张庄镇",
        "140321104": "东回镇",
        "140321105": "柏井镇",
        "140321106": "娘子关镇",
        "140321107": "巨城镇",
        "140321200": "石门口乡",
        "140321201": "岔口乡"
    },
    "140322": {
        "140322100": "秀水镇",
        "140322101": "孙家庄镇",
        "140322102": "路家村镇",
        "140322103": "南娄镇",
        "140322104": "牛村镇",
        "140322105": "苌池镇",
        "140322106": "上社镇",
        "140322107": "西烟镇",
        "140322200": "仙人乡",
        "140322201": "北下庄乡",
        "140322202": "下社乡",
        "140322203": "梁家寨乡",
        "140322204": "西潘乡",
        "140322205": "东梁乡"
    },
    "140400": {
        "140403": "潞州区",
        "140404": "上党区",
        "140405": "屯留区",
        "140406": "潞城区",
        "140423": "襄垣县",
        "140425": "平顺县",
        "140426": "黎城县",
        "140427": "壶关县",
        "140428": "长子县",
        "140429": "武乡县",
        "140430": "沁县",
        "140431": "沁源县",
        "140471": "山西长治高新技术产业园区"
    },
    "140403": {
        "140403001": "东街街道",
        "140403002": "西街街道",
        "140403003": "英雄南路街道",
        "140403004": "英雄中路街道",
        "140403005": "紫金街街道",
        "140403006": "太行东街街道",
        "140403007": "太行西街街道",
        "140403008": "延安南路街道",
        "140403009": "常青街道",
        "140403010": "五马街道",
        "140403011": "长北街道",
        "140403012": "故县街道",
        "140403100": "老顶山镇",
        "140403101": "堠北庄镇",
        "140403102": "大辛庄镇",
        "140403103": "马厂镇",
        "140403104": "黄碾镇",
        "140403200": "西白兔乡",
        "140403400": "长治市漳泽工业转型试验区管理委员会",
        "140403401": "长治市老顶山旅游开发管理中心"
    },
    "140404": {
        "140404100": "韩店镇",
        "140404101": "苏店镇",
        "140404102": "荫城镇",
        "140404103": "西火镇",
        "140404104": "八义镇",
        "140404105": "贾掌镇",
        "140404200": "郝家庄乡",
        "140404201": "西池乡",
        "140404202": "北呈乡",
        "140404203": "东和乡",
        "140404204": "南宋乡",
        "140404400": "振兴新区",
        "140404401": "上党经济技术开发区",
        "140404406": "长治市经济技术开发区长治县部分",
        "140404407": "信义管委会"
    },
    "140405": {
        "140405100": "麟绛镇",
        "140405101": "上村镇",
        "140405102": "渔泽镇",
        "140405103": "余吾镇",
        "140405104": "吾元镇",
        "140405105": "张店镇",
        "140405106": "丰宜镇",
        "140405200": "李高乡",
        "140405201": "路村乡",
        "140405202": "河神庙乡",
        "140405203": "西贾乡",
        "140405400": "西流寨开发区",
        "140405401": "康庄工业园区",
        "140405402": "上莲开发区",
        "140405403": "屯留县经济技术开发区"
    },
    "140406": {
        "140406001": "潞华街道",
        "140406002": "成家川街道",
        "140406100": "店上镇",
        "140406101": "微子镇",
        "140406102": "辛安泉镇",
        "140406103": "翟店镇",
        "140406200": "合室乡",
        "140406201": "黄牛蹄乡",
        "140406202": "史回乡",
        "140406400": "潞城经济技术开发区"
    },
    "140423": {
        "140423100": "古韩镇",
        "140423101": "王桥镇",
        "140423102": "侯堡镇",
        "140423103": "夏店镇",
        "140423104": "虒亭镇",
        "140423105": "西营镇",
        "140423106": "王村镇",
        "140423107": "下良镇",
        "140423200": "善福乡",
        "140423201": "北底乡",
        "140423202": "上马乡",
        "140423400": "富阳工业园区",
        "140423402": "襄垣经济技术开发区"
    },
    "140425": {
        "140425100": "青羊镇",
        "140425101": "龙溪镇",
        "140425102": "石城镇",
        "140425103": "苗庄镇",
        "140425104": "杏城镇",
        "140425200": "西沟乡",
        "140425201": "东寺头乡",
        "140425202": "虹梯关乡",
        "140425203": "阳高乡",
        "140425204": "北耽车乡",
        "140425205": "中五井乡",
        "140425206": "北社乡",
        "140425400": "平顺经济技术开发区"
    },
    "140426": {
        "140426100": "黎侯镇",
        "140426101": "东阳关镇",
        "140426102": "上遥镇",
        "140426103": "西井镇",
        "140426104": "黄崖洞镇",
        "140426200": "西仵乡",
        "140426201": "停河铺乡",
        "140426202": "程家山乡",
        "140426203": "洪井乡",
        "140426400": "黎城县经济技术开发区"
    },
    "140427": {
        "140427100": "龙泉镇",
        "140427101": "百尺镇",
        "140427102": "店上镇",
        "140427103": "晋庄镇",
        "140427104": "树掌镇",
        "140427200": "集店乡",
        "140427201": "黄山乡",
        "140427202": "东井岭乡",
        "140427203": "石坡乡",
        "140427204": "五龙山乡",
        "140427205": "鹅屋乡",
        "140427206": "桥上乡",
        "140427400": "常平",
        "140427401": "壶关经济开发区"
    },
    "140428": {
        "140428100": "丹朱镇",
        "140428101": "鲍店镇",
        "140428102": "石哲镇",
        "140428103": "大堡头镇",
        "140428104": "慈林镇",
        "140428105": "色头镇",
        "140428106": "南漳镇",
        "140428200": "岚水乡",
        "140428201": "碾张乡",
        "140428202": "常张乡",
        "140428203": "南陈乡",
        "140428204": "宋村乡",
        "140428400": "长子经济技术开发区"
    },
    "140429": {
        "140429100": "丰州镇",
        "140429101": "洪水镇",
        "140429102": "蟠龙镇",
        "140429103": "监漳镇",
        "140429104": "故城镇",
        "140429200": "墨镫乡",
        "140429201": "韩北乡",
        "140429202": "大有乡",
        "140429203": "贾豁乡",
        "140429204": "故县乡",
        "140429205": "上司乡",
        "140429206": "石北乡",
        "140429207": "涌泉乡",
        "140429208": "分水岭乡",
        "140429400": "武乡经济技术开发区"
    },
    "140430": {
        "140430100": "定昌镇",
        "140430101": "郭村镇",
        "140430102": "故县镇",
        "140430103": "新店镇",
        "140430104": "漳源镇",
        "140430105": "册村镇",
        "140430200": "段柳乡",
        "140430201": "松村乡",
        "140430202": "次村乡",
        "140430203": "牛寺乡",
        "140430204": "南里乡",
        "140430205": "南泉乡",
        "140430206": "杨安乡"
    },
    "140431": {
        "140431100": "沁河镇",
        "140431101": "郭道镇",
        "140431102": "灵空山镇",
        "140431103": "王和镇",
        "140431104": "李元镇",
        "140431200": "中峪乡",
        "140431201": "法中乡",
        "140431202": "交口乡",
        "140431203": "聪子峪乡",
        "140431204": "韩洪乡",
        "140431205": "官滩乡",
        "140431206": "景凤乡",
        "140431207": "赤石桥乡",
        "140431208": "王陶乡",
        "140431400": "沁源经济技术开发区"
    },
    "140471": {"140471400": "长治高新技术开发区城北街"},
    "140500": {"140502": "城区", "140521": "沁水县", "140522": "阳城县", "140524": "陵川县", "140525": "泽州县", "140581": "高平市"},
    "140502": {
        "140502001": "东街街道",
        "140502002": "西街街道",
        "140502003": "南街街道",
        "140502004": "北街街道",
        "140502006": "钟家庄",
        "140502007": "西上庄",
        "140502009": "开发区街道",
        "140502100": "北石店镇"
    },
    "140521": {
        "140521100": "龙港镇",
        "140521101": "中村镇",
        "140521102": "郑庄镇",
        "140521103": "端氏镇",
        "140521104": "嘉峰镇",
        "140521105": "郑村镇",
        "140521106": "柿庄镇",
        "140521200": "樊村河乡",
        "140521201": "土沃乡",
        "140521202": "张村乡",
        "140521203": "苏庄乡",
        "140521204": "胡底乡",
        "140521205": "固县乡",
        "140521206": "十里乡"
    },
    "140522": {
        "140522100": "凤城镇",
        "140522101": "北留镇",
        "140522102": "润城镇",
        "140522103": "町店镇",
        "140522104": "芹池镇",
        "140522105": "次营镇",
        "140522106": "横河镇",
        "140522107": "河北镇",
        "140522108": "蟒河镇",
        "140522109": "东冶镇",
        "140522200": "白桑乡",
        "140522201": "寺头乡",
        "140522202": "西河乡",
        "140522203": "演礼乡",
        "140522204": "固隆乡",
        "140522205": "董封乡",
        "140522206": "驾岭乡",
        "140522401": "林场管理处"
    },
    "140524": {
        "140524100": "崇文镇",
        "140524101": "礼义镇",
        "140524102": "附城镇",
        "140524103": "西河底镇",
        "140524104": "平城镇",
        "140524105": "杨村镇",
        "140524106": "潞城镇",
        "140524204": "夺火乡",
        "140524206": "马圪当乡",
        "140524207": "古郊乡",
        "140524209": "六泉乡",
        "140524212": "秦家庄乡"
    },
    "140525": {
        "140525100": "南村镇",
        "140525101": "下村镇",
        "140525102": "大东沟镇",
        "140525103": "周村镇",
        "140525104": "犁川镇",
        "140525105": "晋庙铺镇",
        "140525106": "金村镇",
        "140525107": "高都镇",
        "140525108": "巴公镇",
        "140525109": "大阳镇",
        "140525110": "山河镇",
        "140525111": "大箕镇",
        "140525112": "柳树口镇",
        "140525113": "北义城镇",
        "140525200": "川底乡",
        "140525201": "李寨乡",
        "140525202": "南岭乡"
    },
    "140581": {
        "140581001": "北城街道",
        "140581002": "东城街道",
        "140581003": "南城街道",
        "140581101": "米山镇",
        "140581102": "三甲镇",
        "140581103": "神农镇",
        "140581104": "陈区镇",
        "140581105": "北诗镇",
        "140581106": "河西镇",
        "140581107": "马村镇",
        "140581108": "野川镇",
        "140581109": "寺庄镇",
        "140581201": "建宁乡",
        "140581202": "石末乡",
        "140581203": "原村乡",
        "140581204": "永录乡"
    },
    "140600": {
        "140602": "朔城区",
        "140603": "平鲁区",
        "140621": "山阴县",
        "140622": "应县",
        "140623": "右玉县",
        "140671": "山西朔州经济开发区",
        "140681": "怀仁市"
    },
    "140602": {
        "140602001": "北城街道",
        "140602002": "南城街道",
        "140602003": "神头街道",
        "140602004": "北旺庄街道",
        "140602100": "神头镇",
        "140602101": "利民镇",
        "140602200": "下团堡乡",
        "140602201": "小平易乡",
        "140602202": "滋润乡",
        "140602203": "福善庄乡",
        "140602204": "南榆林乡",
        "140602205": "贾庄乡",
        "140602206": "沙塄河乡",
        "140602207": "窑子头乡",
        "140602208": "张蔡庄乡"
    },
    "140603": {
        "140603100": "井坪镇",
        "140603101": "凤凰城镇",
        "140603200": "白堂乡",
        "140603201": "陶村乡",
        "140603202": "下水头乡",
        "140603203": "双碾乡",
        "140603204": "阻虎乡",
        "140603205": "高石庄乡",
        "140603206": "西水界乡",
        "140603207": "下面高乡",
        "140603208": "下木角乡",
        "140603209": "向阳堡乡",
        "140603210": "榆岭乡"
    },
    "140621": {
        "140621001": "东城管理委员会",
        "140621002": "西城管理委员会",
        "140621100": "玉井镇",
        "140621101": "北周庄镇",
        "140621102": "古城镇",
        "140621103": "岱岳镇",
        "140621200": "吴马营乡",
        "140621201": "马营乡",
        "140621202": "下喇叭乡",
        "140621203": "合盛堡乡",
        "140621205": "安荣乡",
        "140621206": "薛圐圙乡",
        "140621207": "后所乡",
        "140621208": "张家庄乡",
        "140621209": "马营庄乡",
        "140621400": "山阴农牧场"
    },
    "140622": {
        "140622100": "金城镇",
        "140622101": "南河种镇",
        "140622102": "下社镇",
        "140622200": "镇子梁乡",
        "140622201": "义井乡",
        "140622202": "藏寨乡",
        "140622203": "大黄巍乡",
        "140622204": "杏寨乡",
        "140622205": "下马峪乡",
        "140622206": "南泉乡",
        "140622207": "大临河乡",
        "140622208": "白马石乡"
    },
    "140623": {
        "140623100": "新城镇",
        "140623101": "右卫镇",
        "140623102": "威远镇",
        "140623103": "元堡子镇",
        "140623200": "牛心堡乡",
        "140623201": "白头里乡",
        "140623202": "高家堡乡",
        "140623203": "丁家窑乡",
        "140623204": "杨千河乡",
        "140623205": "李达窑乡"
    },
    "140671": {"140671400": "山西朔州经济开发区街道筹备处"},
    "140681": {
        "140681100": "云中镇",
        "140681101": "吴家窑镇",
        "140681102": "金沙滩镇",
        "140681103": "毛家皂镇",
        "140681200": "何家堡乡",
        "140681201": "新家园乡",
        "140681202": "亲和乡",
        "140681203": "海北头乡",
        "140681204": "马辛庄乡",
        "140681205": "河头乡",
        "140681500": "金沙滩农牧场",
        "140681501": "金沙滩林场"
    },
    "140700": {
        "140702": "榆次区",
        "140721": "榆社县",
        "140722": "左权县",
        "140723": "和顺县",
        "140724": "昔阳县",
        "140725": "寿阳县",
        "140726": "太谷县",
        "140727": "祁县",
        "140728": "平遥县",
        "140729": "灵石县",
        "140781": "介休市"
    },
    "140702": {
        "140702001": "北关街道",
        "140702002": "锦纶街道",
        "140702003": "新华街道",
        "140702004": "西南街道",
        "140702005": "路西街道",
        "140702006": "经纬街道",
        "140702007": "安宁街道",
        "140702008": "新建街道",
        "140702009": "晋华街道",
        "140702100": "乌金山镇",
        "140702101": "东阳镇",
        "140702102": "什贴镇",
        "140702103": "长凝镇",
        "140702104": "北田镇",
        "140702105": "修文镇",
        "140702200": "郭家堡乡",
        "140702201": "张庆乡",
        "140702202": "庄子乡",
        "140702203": "东赵乡",
        "140702204": "榆次区使赵乡社会事务管理中心",
        "140702401": "山西转型综合改革示范区晋中开发区"
    },
    "140721": {
        "140721100": "箕城镇",
        "140721101": "云簇镇",
        "140721102": "郝北镇",
        "140721103": "社城镇",
        "140721200": "河峪乡",
        "140721201": "北寨乡",
        "140721202": "西马乡",
        "140721203": "岚峪乡",
        "140721204": "讲堂乡",
        "140721400": "城区管理委员会"
    },
    "140722": {
        "140722001": "城区街道管理委员会",
        "140722100": "辽阳镇",
        "140722101": "桐峪镇",
        "140722102": "麻田镇",
        "140722103": "芹泉镇",
        "140722104": "拐儿镇",
        "140722200": "寒王乡",
        "140722201": "石匣乡",
        "140722202": "龙泉乡",
        "140722203": "粟城乡",
        "140722204": "羊角乡"
    },
    "140723": {
        "140723001": "城区管理委员会",
        "140723100": "义兴镇",
        "140723101": "李阳镇",
        "140723102": "松烟镇",
        "140723103": "青城镇",
        "140723104": "横岭镇",
        "140723200": "喂马乡",
        "140723201": "平松乡",
        "140723202": "牛川乡",
        "140723203": "马坊乡",
        "140723204": "阳光占乡"
    },
    "140724": {
        "140724001": "城区社区管理委员会",
        "140724100": "乐平镇",
        "140724101": "皋落镇",
        "140724102": "冶头镇",
        "140724103": "沾尚镇",
        "140724104": "大寨镇",
        "140724200": "李家庄乡",
        "140724201": "界都乡",
        "140724202": "三都乡",
        "140724203": "赵壁乡",
        "140724204": "孔氏乡",
        "140724205": "闫庄乡",
        "140724206": "西寨乡"
    },
    "140725": {
        "140725100": "朝阳镇",
        "140725101": "南燕竹镇",
        "140725102": "宗艾镇",
        "140725103": "平头镇",
        "140725104": "松塔镇",
        "140725105": "西洛镇",
        "140725106": "尹灵芝镇",
        "140725200": "平舒乡",
        "140725201": "解愁乡",
        "140725202": "温家庄乡",
        "140725203": "景尚乡",
        "140725204": "上湖乡",
        "140725205": "羊头崖乡",
        "140725206": "马首乡"
    },
    "140726": {
        "140726100": "明星镇",
        "140726101": "胡村镇",
        "140726102": "范村镇",
        "140726200": "侯城乡",
        "140726201": "北汪乡",
        "140726202": "水秀乡",
        "140726203": "阳邑乡",
        "140726204": "小白乡",
        "140726205": "任村乡",
        "140726401": "白塔区管委会",
        "140726402": "北城区管委会",
        "140726403": "南城区管委会"
    },
    "140727": {
        "140727001": "昌源城区管理委员会",
        "140727002": "丹枫城区管理委员会",
        "140727003": "麓台城区管理委员会",
        "140727100": "昭馀镇",
        "140727101": "东观镇",
        "140727102": "古县镇",
        "140727103": "贾令镇",
        "140727104": "城赵镇",
        "140727105": "来远镇",
        "140727200": "西六支乡",
        "140727201": "峪口乡",
        "140727400": "祁县经济开发区管理委员会"
    },
    "140728": {
        "140728001": "古城街道",
        "140728002": "城东街道",
        "140728003": "城西街道",
        "140728100": "古陶镇",
        "140728101": "段村镇",
        "140728102": "东泉镇",
        "140728103": "洪善镇",
        "140728104": "宁固镇",
        "140728200": "南政乡",
        "140728201": "中都乡",
        "140728202": "岳壁乡",
        "140728203": "卜宜乡",
        "140728204": "孟山乡",
        "140728205": "朱坑乡",
        "140728206": "襄垣乡",
        "140728207": "杜家庄乡",
        "140728208": "香乐乡"
    },
    "140729": {
        "140729001": "东城管理委员会",
        "140729002": "西城管理委员会",
        "140729003": "北城管理委员会",
        "140729100": "翠峰镇",
        "140729101": "静升镇",
        "140729102": "两渡镇",
        "140729103": "夏门镇",
        "140729104": "南关镇",
        "140729105": "段纯镇",
        "140729200": "马和乡",
        "140729201": "英武乡",
        "140729202": "王禹乡",
        "140729203": "坛镇乡",
        "140729204": "梁家焉乡",
        "140729205": "交口乡"
    },
    "140781": {
        "140781001": "北关街道",
        "140781002": "西关街道",
        "140781003": "东南街道",
        "140781004": "西南街道",
        "140781005": "北坛街道",
        "140781100": "义安镇",
        "140781101": "张兰镇",
        "140781102": "连福镇",
        "140781103": "洪山镇",
        "140781104": "龙凤镇",
        "140781105": "绵山镇",
        "140781106": "义棠镇",
        "140781200": "城关乡",
        "140781201": "宋古乡",
        "140781202": "三佳乡"
    },
    "140800": {
        "140802": "盐湖区",
        "140821": "临猗县",
        "140822": "万荣县",
        "140823": "闻喜县",
        "140824": "稷山县",
        "140825": "新绛县",
        "140826": "绛县",
        "140827": "垣曲县",
        "140828": "夏县",
        "140829": "平陆县",
        "140830": "芮城县",
        "140881": "永济市",
        "140882": "河津市"
    },
    "140802": {
        "140802001": "中城街道",
        "140802002": "东城街道",
        "140802003": "西城街道",
        "140802004": "南城街道",
        "140802005": "北城街道",
        "140802006": "安邑街道",
        "140802007": "大渠街道",
        "140802008": "姚孟街道",
        "140802100": "解州镇",
        "140802101": "龙居镇",
        "140802102": "北相镇",
        "140802103": "泓芝驿镇",
        "140802104": "三路里镇",
        "140802105": "陶村镇",
        "140802106": "东郭镇",
        "140802200": "席张乡",
        "140802201": "金井乡",
        "140802202": "王范乡",
        "140802203": "冯村乡",
        "140802204": "上郭乡",
        "140802205": "上王乡",
        "140802402": "山西运城经济开发区",
        "140802403": "运城空港经济开发区"
    },
    "140821": {
        "140821100": "猗氏镇",
        "140821101": "嵋阳镇",
        "140821102": "临晋镇",
        "140821103": "七级镇",
        "140821104": "东张镇",
        "140821105": "孙吉镇",
        "140821106": "三管镇",
        "140821107": "牛杜镇",
        "140821108": "耽子镇",
        "140821200": "楚侯乡",
        "140821201": "庙上乡",
        "140821202": "角杯乡",
        "140821203": "北辛乡",
        "140821204": "北景乡",
        "140821402": "临猗县城市社区管理办公室"
    },
    "140822": {
        "140822100": "解店镇",
        "140822101": "通化镇",
        "140822102": "汉薛镇",
        "140822103": "荣河镇",
        "140822200": "万泉乡",
        "140822201": "里望乡",
        "140822202": "西村乡",
        "140822203": "南张乡",
        "140822204": "高村乡",
        "140822205": "皇甫乡",
        "140822206": "贾村乡",
        "140822207": "王显乡",
        "140822208": "光华乡",
        "140822209": "裴庄乡"
    },
    "140823": {
        "140823100": "桐城镇",
        "140823101": "郭家庄镇",
        "140823102": "畖底镇",
        "140823103": "薛店镇",
        "140823104": "东镇镇",
        "140823105": "礼元镇",
        "140823106": "河底镇",
        "140823200": "神柏乡",
        "140823201": "阳隅乡",
        "140823202": "侯村乡",
        "140823203": "裴社乡",
        "140823204": "后宫乡",
        "140823205": "石门乡",
        "140823400": "社区管理办公室"
    },
    "140824": {
        "140824100": "稷峰镇",
        "140824101": "西社镇",
        "140824102": "化峪镇",
        "140824103": "翟店镇",
        "140824104": "清河镇",
        "140824200": "蔡村乡",
        "140824201": "太阳乡",
        "140824400": "稷山县社区服务中心"
    },
    "140825": {
        "140825100": "龙兴镇",
        "140825101": "三泉镇",
        "140825102": "泽掌镇",
        "140825103": "北张镇",
        "140825104": "古交镇",
        "140825105": "万安镇",
        "140825106": "阳王镇",
        "140825107": "泉掌镇",
        "140825200": "横桥乡",
        "140825400": "社区办",
        "140825498": "新绛县煤化园区管委会"
    },
    "140826": {
        "140826100": "古绛镇",
        "140826101": "横水镇",
        "140826102": "陈村镇",
        "140826103": "卫庄镇",
        "140826104": "么里镇",
        "140826105": "南樊镇",
        "140826106": "安峪镇",
        "140826107": "大交镇",
        "140826200": "郝庄乡",
        "140826201": "冷口乡",
        "140826402": "山西绛县经济开发区"
    },
    "140827": {
        "140827100": "新城镇",
        "140827101": "历山镇",
        "140827102": "古城镇",
        "140827103": "王茅镇",
        "140827104": "毛家湾镇",
        "140827200": "蒲掌乡",
        "140827201": "英言乡",
        "140827202": "解峪乡",
        "140827203": "华峰乡",
        "140827204": "长直乡",
        "140827205": "皋落乡"
    },
    "140828": {
        "140828100": "瑶峰镇",
        "140828101": "庙前镇",
        "140828102": "裴介镇",
        "140828103": "水头镇",
        "140828104": "埝掌镇",
        "140828105": "泗交镇",
        "140828200": "尉郭乡",
        "140828201": "禹王乡",
        "140828202": "胡张乡",
        "140828203": "南大里乡",
        "140828204": "祁家河乡"
    },
    "140829": {
        "140829100": "圣人涧镇",
        "140829101": "常乐镇",
        "140829102": "张店镇",
        "140829103": "张村镇",
        "140829104": "曹川镇",
        "140829105": "三门镇",
        "140829200": "洪池乡",
        "140829201": "杜马乡",
        "140829202": "部官乡",
        "140829203": "坡底乡"
    },
    "140830": {
        "140830100": "古魏镇",
        "140830101": "风陵渡镇",
        "140830102": "陌南镇",
        "140830103": "西陌镇",
        "140830104": "永乐镇",
        "140830105": "大王镇",
        "140830106": "阳城镇",
        "140830200": "东垆乡",
        "140830201": "南卫乡",
        "140830202": "学张乡",
        "140830401": "城镇社区管理委员会",
        "140830402": "山西风陵渡经济开发区"
    },
    "140881": {
        "140881001": "城西街道",
        "140881002": "城北街道",
        "140881003": "城东街道",
        "140881100": "虞乡镇",
        "140881101": "卿头镇",
        "140881102": "开张镇",
        "140881103": "栲栳镇",
        "140881104": "蒲州镇",
        "140881105": "韩阳镇",
        "140881106": "张营镇",
        "140881400": "伍姓湖农场",
        "140881401": "虞乡农场",
        "140881402": "董村农场"
    },
    "140882": {
        "140882001": "城区街道",
        "140882002": "清涧街道",
        "140882100": "樊村镇",
        "140882101": "僧楼镇",
        "140882200": "小梁乡",
        "140882201": "柴家乡",
        "140882202": "赵家庄乡",
        "140882203": "下化乡",
        "140882204": "阳村乡",
        "140882450": "山西铝厂"
    },
    "140900": {
        "140902": "忻府区",
        "140921": "定襄县",
        "140922": "五台县",
        "140923": "代县",
        "140924": "繁峙县",
        "140925": "宁武县",
        "140926": "静乐县",
        "140927": "神池县",
        "140928": "五寨县",
        "140929": "岢岚县",
        "140930": "河曲县",
        "140931": "保德县",
        "140932": "偏关县",
        "140971": "五台山风景名胜区",
        "140981": "原平市"
    },
    "140902": {
        "140902001": "秀容街道",
        "140902002": "长征街街道",
        "140902003": "新建路街道",
        "140902100": "播明镇",
        "140902101": "奇村镇",
        "140902102": "三交镇",
        "140902103": "庄磨镇",
        "140902104": "豆罗镇",
        "140902105": "董村镇",
        "140902200": "曹张乡",
        "140902201": "高城乡",
        "140902202": "秦城乡",
        "140902203": "解原乡",
        "140902204": "合索乡",
        "140902205": "阳坡乡",
        "140902206": "兰村乡",
        "140902207": "紫岩乡",
        "140902208": "西张乡",
        "140902209": "东楼乡",
        "140902210": "北义井乡"
    },
    "140921": {
        "140921100": "晋昌镇",
        "140921101": "河边镇",
        "140921102": "宏道镇",
        "140921200": "杨芳乡",
        "140921201": "南王乡",
        "140921202": "蒋村乡",
        "140921203": "神山乡",
        "140921204": "季庄乡",
        "140921205": "受禄乡"
    },
    "140922": {
        "140922100": "台城镇",
        "140922102": "耿镇镇",
        "140922103": "豆村镇",
        "140922104": "白家庄镇",
        "140922105": "东冶镇",
        "140922200": "沟南乡",
        "140922201": "东雷乡",
        "140922202": "高洪口乡",
        "140922203": "门限石乡",
        "140922204": "陈家庄乡",
        "140922205": "建安乡",
        "140922206": "神西乡",
        "140922207": "蒋坊乡",
        "140922208": "灵境乡",
        "140922209": "阳白乡",
        "140922210": "茹村乡"
    },
    "140923": {
        "140923100": "上馆镇",
        "140923101": "阳明堡镇",
        "140923102": "峨口镇",
        "140923103": "聂营镇",
        "140923104": "枣林镇",
        "140923105": "滩上镇",
        "140923200": "新高乡",
        "140923201": "峪口乡",
        "140923202": "上磨坊乡",
        "140923203": "胡峪乡",
        "140923204": "雁门关乡"
    },
    "140924": {
        "140924100": "繁城镇",
        "140924101": "砂河镇",
        "140924102": "大营镇",
        "140924200": "下茹越乡",
        "140924201": "杏园乡",
        "140924202": "光裕堡乡",
        "140924203": "集义庄乡",
        "140924204": "东山乡",
        "140924205": "金山铺乡",
        "140924206": "柏家庄乡",
        "140924207": "横涧乡",
        "140924208": "神堂堡乡",
        "140924209": "岩头乡",
        "140924401": "繁峙县城居民"
    },
    "140925": {
        "140925100": "凤凰镇",
        "140925101": "阳方口镇",
        "140925102": "东寨镇",
        "140925103": "石家庄镇",
        "140925200": "薛家洼乡",
        "140925201": "余庄乡",
        "140925202": "涔山乡",
        "140925203": "化北屯乡",
        "140925204": "西马坊乡",
        "140925205": "新堡乡",
        "140925206": "迭台寺乡",
        "140925207": "圪廖乡",
        "140925208": "怀道乡",
        "140925209": "东马坊乡",
        "140925400": "阳方口煤矿生活区",
        "140925401": "城区居民"
    },
    "140926": {
        "140926100": "鹅城镇",
        "140926101": "杜家村镇",
        "140926102": "康家会镇",
        "140926103": "丰润镇",
        "140926201": "堂尔上乡",
        "140926202": "中庄乡",
        "140926203": "双路乡",
        "140926204": "段家寨乡",
        "140926205": "辛村乡",
        "140926206": "王村乡",
        "140926207": "神峪沟乡",
        "140926208": "娘子神乡",
        "140926209": "娑婆乡",
        "140926210": "赤泥洼乡"
    },
    "140927": {
        "140927100": "龙泉镇",
        "140927101": "义井镇",
        "140927102": "八角镇",
        "140927200": "东湖乡",
        "140927201": "太平庄乡",
        "140927202": "虎北乡",
        "140927203": "贺职乡",
        "140927204": "长畛乡",
        "140927205": "烈堡乡",
        "140927206": "大严备乡"
    },
    "140928": {
        "140928100": "砚城镇",
        "140928101": "小河头镇",
        "140928102": "三岔镇",
        "140928200": "前所乡",
        "140928201": "李家坪乡",
        "140928202": "孙家坪乡",
        "140928203": "梁家坪乡",
        "140928204": "胡会乡",
        "140928205": "新寨乡",
        "140928206": "韩家楼乡",
        "140928207": "东秀庄乡",
        "140928208": "杏岭子乡"
    },
    "140929": {
        "140929100": "岚漪镇",
        "140929101": "三井镇",
        "140929200": "神堂坪乡",
        "140929201": "高家会乡",
        "140929202": "李家沟乡",
        "140929203": "水峪贯乡",
        "140929204": "西豹峪乡",
        "140929205": "温泉乡",
        "140929206": "阳坪乡",
        "140929207": "大涧乡",
        "140929208": "宋家沟乡",
        "140929209": "王家岔乡"
    },
    "140930": {
        "140930100": "文笔镇",
        "140930101": "楼子营镇",
        "140930102": "刘家塔镇",
        "140930103": "巡镇镇",
        "140930200": "鹿固乡",
        "140930201": "前川乡",
        "140930202": "单寨乡",
        "140930203": "土沟乡",
        "140930204": "旧县乡",
        "140930205": "沙坪乡",
        "140930206": "社梁乡",
        "140930207": "沙泉乡",
        "140930208": "赵家沟乡",
        "140930400": "河曲县居民"
    },
    "140931": {
        "140931100": "东关镇",
        "140931101": "义门镇",
        "140931102": "桥头镇",
        "140931103": "杨家湾镇",
        "140931200": "腰庄乡",
        "140931201": "韩家川乡",
        "140931202": "林遮峪乡",
        "140931203": "冯家川乡",
        "140931204": "土崖塔乡",
        "140931205": "孙家沟乡",
        "140931206": "窑洼乡",
        "140931207": "窑圪台乡",
        "140931208": "南河沟乡"
    },
    "140932": {
        "140932100": "新关镇",
        "140932101": "天峰坪镇",
        "140932102": "老营镇",
        "140932103": "万家寨镇",
        "140932200": "窑头乡",
        "140932201": "楼沟乡",
        "140932202": "尚峪乡",
        "140932203": "南堡子乡",
        "140932204": "水泉乡",
        "140932205": "陈家营乡"
    },
    "140971": {"140971100": "台怀镇", "140971200": "金岗库乡", "140971201": "石咀乡"},
    "140981": {
        "140981001": "北城街道",
        "140981002": "南城街道",
        "140981003": "轩煤矿区街道",
        "140981100": "东社镇",
        "140981101": "苏龙口镇",
        "140981102": "崞阳镇",
        "140981103": "大牛店镇",
        "140981104": "闫庄镇",
        "140981105": "长梁沟镇",
        "140981106": "轩岗镇",
        "140981200": "新原乡",
        "140981201": "南白乡",
        "140981202": "子干乡",
        "140981203": "中阳乡",
        "140981204": "沿沟乡",
        "140981205": "大林乡",
        "140981206": "西镇乡",
        "140981207": "解村乡",
        "140981208": "王家庄乡",
        "140981209": "楼板寨乡",
        "140981210": "段家堡乡",
        "140981400": "山西鲁能晋北铝业工矿区",
        "140981401": "原平市石豹沟煤矿工矿区",
        "140981403": "原平农场"
    },
    "141000": {
        "141002": "尧都区",
        "141021": "曲沃县",
        "141022": "翼城县",
        "141023": "襄汾县",
        "141024": "洪洞县",
        "141025": "古县",
        "141026": "安泽县",
        "141027": "浮山县",
        "141028": "吉县",
        "141029": "乡宁县",
        "141030": "大宁县",
        "141031": "隰县",
        "141032": "永和县",
        "141033": "蒲县",
        "141034": "汾西县",
        "141081": "侯马市",
        "141082": "霍州市"
    },
    "141002": {
        "141002001": "解放路街道",
        "141002002": "鼓楼西街街道",
        "141002003": "水塔街街道",
        "141002004": "南街街道",
        "141002005": "乡贤街街道",
        "141002006": "辛寺街街道",
        "141002007": "铁路东街道",
        "141002008": "车站街街道",
        "141002009": "汾河街道",
        "141002010": "滨河",
        "141002100": "屯里镇",
        "141002101": "乔李镇",
        "141002102": "大阳镇",
        "141002103": "县底镇",
        "141002104": "刘村镇",
        "141002105": "金殿镇",
        "141002106": "吴村镇",
        "141002107": "土门镇",
        "141002108": "魏村镇",
        "141002109": "尧庙镇",
        "141002200": "段店乡",
        "141002201": "贾得乡",
        "141002202": "贺家庄乡",
        "141002203": "一平垣乡",
        "141002204": "枕头乡",
        "141002205": "河底乡"
    },
    "141021": {
        "141021100": "乐昌镇",
        "141021101": "史村镇",
        "141021102": "曲村镇",
        "141021103": "高显镇",
        "141021104": "里村镇",
        "141021200": "北董乡",
        "141021201": "杨谈乡"
    },
    "141022": {
        "141022100": "唐兴镇",
        "141022101": "南梁镇",
        "141022102": "里砦镇",
        "141022103": "隆化镇",
        "141022104": "桥上镇",
        "141022105": "西阎镇",
        "141022200": "中卫乡",
        "141022201": "南唐乡",
        "141022202": "王庄乡",
        "141022203": "浇底乡"
    },
    "141023": {
        "141023100": "新城镇",
        "141023101": "赵康镇",
        "141023102": "汾城镇",
        "141023103": "南贾镇",
        "141023104": "古城镇",
        "141023105": "襄陵镇",
        "141023106": "邓庄镇",
        "141023200": "陶寺乡",
        "141023201": "永固乡",
        "141023202": "景毛乡",
        "141023203": "西贾乡",
        "141023204": "南辛店乡",
        "141023205": "大邓乡"
    },
    "141024": {
        "141024100": "大槐树镇",
        "141024101": "甘亭镇",
        "141024102": "曲亭镇",
        "141024103": "苏堡镇",
        "141024104": "广胜寺镇",
        "141024105": "明姜镇",
        "141024106": "赵城镇",
        "141024107": "万安镇",
        "141024108": "刘家垣镇",
        "141024200": "淹底乡",
        "141024201": "兴唐寺乡",
        "141024202": "堤村乡",
        "141024203": "辛村乡",
        "141024204": "龙马乡",
        "141024205": "山头乡",
        "141024206": "左木乡"
    },
    "141025": {
        "141025100": "岳阳镇",
        "141025101": "北平镇",
        "141025102": "古阳镇",
        "141025103": "旧县镇",
        "141025200": "石壁乡",
        "141025201": "永乐乡",
        "141025202": "南垣乡"
    },
    "141026": {
        "141026100": "府城镇",
        "141026101": "和川镇",
        "141026102": "唐城镇",
        "141026103": "冀氏镇",
        "141026200": "马必乡",
        "141026201": "杜村乡",
        "141026202": "良马乡"
    },
    "141027": {
        "141027100": "天坛镇",
        "141027101": "响水河镇",
        "141027200": "张庄乡",
        "141027201": "东张乡",
        "141027202": "槐埝乡",
        "141027203": "北王乡",
        "141027204": "北韩乡",
        "141027205": "米家垣乡",
        "141027206": "寨圪塔乡"
    },
    "141028": {
        "141028100": "吉昌镇",
        "141028101": "屯里镇",
        "141028102": "壶口镇",
        "141028200": "车城乡",
        "141028201": "文城乡",
        "141028202": "东城乡",
        "141028203": "柏山寺乡",
        "141028204": "中垛乡"
    },
    "141029": {
        "141029100": "昌宁镇",
        "141029101": "光华镇",
        "141029102": "台头镇",
        "141029103": "管头镇",
        "141029104": "西坡镇",
        "141029200": "双鹤乡",
        "141029201": "关王庙乡",
        "141029202": "尉庄乡",
        "141029203": "西交口乡",
        "141029204": "枣岭乡"
    },
    "141030": {
        "141030100": "昕水镇",
        "141030101": "曲峨镇",
        "141030200": "三多乡",
        "141030201": "太德乡",
        "141030202": "徐家垛乡",
        "141030203": "太古乡"
    },
    "141031": {
        "141031100": "龙泉镇",
        "141031101": "午城镇",
        "141031102": "黄土镇",
        "141031200": "阳头升乡",
        "141031201": "寨子乡",
        "141031202": "陡坡乡",
        "141031203": "下李乡",
        "141031204": "城南乡"
    },
    "141032": {
        "141032100": "芝河镇",
        "141032101": "桑壁镇",
        "141032200": "阁底乡",
        "141032201": "南庄乡",
        "141032202": "打石腰乡",
        "141032203": "坡头乡",
        "141032204": "交口乡"
    },
    "141033": {
        "141033100": "蒲城镇",
        "141033101": "薛关镇",
        "141033102": "黑龙关镇",
        "141033103": "克城镇",
        "141033200": "山中乡",
        "141033201": "古县乡",
        "141033202": "红道乡",
        "141033203": "乔家湾乡",
        "141033204": "太林乡"
    },
    "141034": {
        "141034100": "永安镇",
        "141034101": "对竹镇",
        "141034102": "勍香镇",
        "141034103": "和平镇",
        "141034104": "僧念镇",
        "141034200": "佃坪乡",
        "141034201": "团柏乡",
        "141034202": "邢家要乡",
        "141034400": "社区管理委员会"
    },
    "141081": {
        "141081001": "路东街道",
        "141081002": "路西街道",
        "141081003": "浍滨街道",
        "141081004": "上马街道",
        "141081005": "张村街道",
        "141081200": "新田乡",
        "141081201": "高村乡",
        "141081202": "凤城乡"
    },
    "141082": {
        "141082001": "鼓楼街道",
        "141082002": "北环路街道",
        "141082003": "南环路街道",
        "141082004": "开元街道",
        "141082005": "退沙街道",
        "141082100": "白龙镇",
        "141082101": "辛置镇",
        "141082102": "大张镇",
        "141082103": "李曹镇",
        "141082200": "陶唐峪乡",
        "141082201": "三教乡",
        "141082202": "师庄乡"
    },
    "141100": {
        "141102": "离石区",
        "141121": "文水县",
        "141122": "交城县",
        "141123": "兴县",
        "141124": "临县",
        "141125": "柳林县",
        "141126": "石楼县",
        "141127": "岚县",
        "141128": "方山县",
        "141129": "中阳县",
        "141130": "交口县",
        "141181": "孝义市",
        "141182": "汾阳市"
    },
    "141102": {
        "141102001": "滨河街道",
        "141102002": "凤山街道",
        "141102003": "莲花池街道",
        "141102004": "城北街道",
        "141102005": "田家会街道",
        "141102006": "交口街道",
        "141102007": "西属巴街道",
        "141102100": "吴城镇",
        "141102101": "信义镇",
        "141102200": "红眼川乡",
        "141102201": "枣林乡",
        "141102202": "坪头乡"
    },
    "141121": {
        "141121100": "凤城镇",
        "141121101": "开栅镇",
        "141121102": "南庄镇",
        "141121103": "南安镇",
        "141121104": "刘胡兰镇",
        "141121105": "下曲镇",
        "141121106": "孝义镇",
        "141121200": "南武乡",
        "141121201": "西城乡",
        "141121202": "北张乡",
        "141121203": "马西乡",
        "141121204": "西槽头乡"
    },
    "141122": {
        "141122100": "天宁镇",
        "141122101": "夏家营镇",
        "141122102": "西营镇",
        "141122103": "水峪贯镇",
        "141122104": "西社镇",
        "141122105": "庞泉沟镇",
        "141122200": "洪相乡",
        "141122201": "岭底乡",
        "141122202": "东坡底乡",
        "141122203": "会立乡",
        "141122400": "夏家营生态工业园区管委会"
    },
    "141123": {
        "141123100": "蔚汾镇",
        "141123101": "魏家滩镇",
        "141123102": "瓦塘镇",
        "141123103": "康宁镇",
        "141123104": "高家村镇",
        "141123105": "罗峪口镇",
        "141123106": "蔡家会镇",
        "141123200": "交楼申乡",
        "141123201": "恶虎滩乡",
        "141123202": "东会乡",
        "141123203": "固贤乡",
        "141123204": "奥家湾乡",
        "141123205": "蔡家崖乡",
        "141123206": "贺家会乡",
        "141123207": "孟家坪乡",
        "141123208": "赵家坪乡",
        "141123209": "圪垯上乡",
        "141123400": "兴县经济技术开发区"
    },
    "141124": {
        "141124100": "临泉镇",
        "141124101": "白文镇",
        "141124102": "城庄镇",
        "141124103": "兔坂镇",
        "141124104": "克虎镇",
        "141124105": "三交镇",
        "141124106": "湍水头镇",
        "141124107": "林家坪镇",
        "141124108": "招贤镇",
        "141124109": "碛口镇",
        "141124110": "刘家会镇",
        "141124111": "丛罗峪镇",
        "141124112": "曲峪镇",
        "141124200": "木瓜坪乡",
        "141124201": "安业乡",
        "141124202": "玉坪乡",
        "141124203": "青凉寺乡",
        "141124204": "石白头乡",
        "141124205": "雷家碛乡",
        "141124206": "八堡乡",
        "141124207": "大禹乡",
        "141124208": "车赶乡",
        "141124209": "安家庄乡"
    },
    "141125": {
        "141125100": "柳林镇",
        "141125101": "穆村镇",
        "141125102": "薛村镇",
        "141125103": "庄上镇",
        "141125104": "留誉镇",
        "141125105": "三交镇",
        "141125106": "成家庄镇",
        "141125107": "孟门镇",
        "141125200": "李家湾乡",
        "141125201": "贾家垣乡",
        "141125202": "陈家湾乡",
        "141125203": "金家庄乡",
        "141125204": "高家沟乡",
        "141125205": "石西乡",
        "141125206": "王家沟乡"
    },
    "141126": {
        "141126100": "灵泉镇",
        "141126101": "罗村镇",
        "141126102": "义牒镇",
        "141126103": "小蒜镇",
        "141126200": "龙交乡",
        "141126201": "和合乡",
        "141126202": "前山乡",
        "141126203": "曹家垣乡",
        "141126204": "裴沟乡"
    },
    "141127": {
        "141127100": "东村镇",
        "141127101": "岚城镇",
        "141127102": "普明镇",
        "141127103": "界河口镇",
        "141127200": "土峪乡",
        "141127201": "上明乡",
        "141127202": "王狮乡",
        "141127203": "梁家庄乡",
        "141127204": "顺会乡",
        "141127205": "河口乡",
        "141127206": "社科乡",
        "141127207": "大蛇头乡",
        "141127400": "岚县普明工业园区"
    },
    "141128": {
        "141128100": "圪洞镇",
        "141128101": "马坊镇",
        "141128102": "峪口镇",
        "141128103": "大武镇",
        "141128104": "北武当镇",
        "141128200": "积翠乡",
        "141128201": "麻地会乡"
    },
    "141129": {
        "141129100": "宁乡镇",
        "141129101": "金罗镇",
        "141129102": "枝柯镇",
        "141129103": "武家庄镇",
        "141129104": "暖泉镇",
        "141129201": "下枣林乡",
        "141129202": "车鸣峪乡"
    },
    "141130": {
        "141130100": "水头镇",
        "141130101": "康城镇",
        "141130102": "双池镇",
        "141130103": "桃红坡镇",
        "141130200": "石口乡",
        "141130201": "回龙乡",
        "141130202": "温泉乡"
    },
    "141181": {
        "141181001": "新义街道",
        "141181002": "中阳楼街道",
        "141181003": "振兴街道",
        "141181004": "胜溪湖街道",
        "141181005": "崇文街道",
        "141181100": "兑镇镇",
        "141181101": "阳泉曲镇",
        "141181102": "下堡镇",
        "141181103": "西辛庄镇",
        "141181104": "高阳镇",
        "141181105": "梧桐镇",
        "141181106": "柱濮镇",
        "141181200": "大孝堡乡",
        "141181201": "下栅乡",
        "141181202": "驿马乡",
        "141181203": "南阳乡",
        "141181204": "杜村乡"
    },
    "141182": {
        "141182001": "文峰街道",
        "141182002": "太和桥街道",
        "141182100": "贾家庄镇",
        "141182101": "杏花村镇",
        "141182102": "冀村镇",
        "141182103": "肖家庄镇",
        "141182104": "演武镇",
        "141182105": "三泉镇",
        "141182106": "石庄镇",
        "141182107": "杨家庄镇",
        "141182108": "峪道河镇",
        "141182200": "西河乡",
        "141182201": "阳城乡",
        "141182202": "栗家庄乡",
        "141182400": "南薰街道",
        "141182401": "辰北街道"
    },
    "150000": {
        "150100": "呼和浩特市",
        "150200": "包头市",
        "150300": "乌海市",
        "150400": "赤峰市",
        "150500": "通辽市",
        "150600": "鄂尔多斯市",
        "150700": "呼伦贝尔市",
        "150800": "巴彦淖尔市",
        "150900": "乌兰察布市",
        "152200": "兴安盟",
        "152500": "锡林郭勒盟",
        "152900": "阿拉善盟"
    },
    "150100": {
        "150102": "新城区",
        "150103": "回民区",
        "150104": "玉泉区",
        "150105": "赛罕区",
        "150121": "土默特左旗",
        "150122": "托克托县",
        "150123": "和林格尔县",
        "150124": "清水河县",
        "150125": "武川县",
        "150171": "呼和浩特金海工业园区",
        "150172": "呼和浩特经济技术开发区"
    },
    "150102": {
        "150102001": "海拉尔东路街道",
        "150102002": "锡林路街道",
        "150102003": "中山东路街道",
        "150102004": "东街街道",
        "150102005": "西街街道",
        "150102006": "东风路街道",
        "150102007": "迎新路街道",
        "150102008": "成吉思汗大街街道",
        "150102101": "保合少镇",
        "150102400": "新城区鸿盛高科技园区"
    },
    "150103": {
        "150103001": "新华西路",
        "150103002": "中山西路",
        "150103003": "光明路",
        "150103004": "海拉尔西路",
        "150103005": "环河街",
        "150103006": "通道街",
        "150103007": "钢铁路",
        "150103100": "攸攸板镇"
    },
    "150104": {
        "150104001": "小召前街",
        "150104002": "兴隆巷",
        "150104003": "长和廊",
        "150104004": "石东路",
        "150104005": "大南街",
        "150104006": "鄂尔多斯路",
        "150104007": "西菜园",
        "150104008": "昭君路",
        "150104100": "小黑河镇",
        "150104400": "裕隆工业园区"
    },
    "150105": {
        "150105001": "人民路街道",
        "150105002": "大学西路街道",
        "150105003": "乌兰察布东路街道",
        "150105004": "大学东路街道",
        "150105005": "中专路街道",
        "150105006": "昭乌达路街道",
        "150105007": "巴彦街道",
        "150105008": "敕勒川路街道",
        "150105101": "榆林镇",
        "150105103": "黄合少镇",
        "150105104": "金河镇",
        "150105400": "金桥经济技术开发区"
    },
    "150121": {
        "150121100": "察素齐镇",
        "150121101": "毕克齐镇",
        "150121102": "善岱镇",
        "150121103": "台阁牧镇",
        "150121104": "白庙子镇",
        "150121105": "沙尔沁镇",
        "150121106": "敕勒川镇",
        "150121207": "北什轴乡",
        "150121208": "塔布赛乡",
        "150121401": "呼和浩特金山经济技术开发区"
    },
    "150122": {
        "150122100": "双河镇",
        "150122101": "新营子镇",
        "150122102": "五申镇",
        "150122103": "伍什家镇",
        "150122104": "古城镇",
        "150122400": "托县工业园区"
    },
    "150123": {
        "150123100": "城关镇",
        "150123101": "盛乐镇",
        "150123102": "新店子镇",
        "150123203": "舍必崖乡",
        "150123205": "大红城乡",
        "150123206": "羊群沟乡",
        "150123207": "黑老夭乡",
        "150123208": "巧什营乡",
        "150123400": "盛乐经济工业园区"
    },
    "150124": {
        "150124100": "城关镇",
        "150124101": "宏河镇",
        "150124102": "喇嘛湾镇",
        "150124103": "老牛湾镇",
        "150124200": "窑沟乡",
        "150124201": "北堡乡",
        "150124202": "韮菜庄乡",
        "150124203": "五良太乡"
    },
    "150125": {
        "150125100": "可可以力更镇",
        "150125101": "哈乐镇",
        "150125102": "西乌兰不浪镇",
        "150125200": "大青山乡",
        "150125205": "上秃亥乡",
        "150125207": "得胜沟乡",
        "150125211": "二份子乡",
        "150125213": "哈拉合少乡",
        "150125214": "耗赖山乡",
        "150125402": "内蒙古武川经济开发区",
        "150125403": "武川金三角经济开发区"
    },
    "150171": {"150171400": "金海开发区"},
    "150172": {
        "150172401": "如意工业园区",
        "150172402": "金川工业园区",
        "150172403": "呼和浩特出口加工区",
        "150172404": "呼和浩特留学人员创业园",
        "150172405": "沙尔沁工业区（如意南区）",
        "150172406": "呼和浩特白塔空港物流园区"
    },
    "150200": {
        "150202": "东河区",
        "150203": "昆都仑区",
        "150204": "青山区",
        "150205": "石拐区",
        "150206": "白云鄂博矿区",
        "150207": "九原区",
        "150221": "土默特右旗",
        "150222": "固阳县",
        "150223": "达尔罕茂明安联合旗",
        "150271": "包头稀土高新技术产业开发区"
    },
    "150202": {
        "150202001": "和平街道",
        "150202002": "财神庙街道",
        "150202003": "西脑包街道",
        "150202004": "南门外街道",
        "150202005": "南圪洞街道",
        "150202006": "东站街道",
        "150202007": "回民街道",
        "150202008": "天骄街道",
        "150202009": "河东街道",
        "150202010": "铁西街道",
        "150202011": "东兴街道",
        "150202012": "杨圪楞街道",
        "150202100": "河东镇",
        "150202101": "沙尔沁镇",
        "150202198": "县级单位直辖村级单位（特殊镇）",
        "150202400": "铝业工业园区"
    },
    "150203": {
        "150203001": "少先路街道",
        "150203003": "沼潭街道",
        "150203004": "林荫路街道",
        "150203005": "友谊大街街道",
        "150203006": "阿尔丁大街街道",
        "150203007": "团结大街街道",
        "150203008": "鞍山道街道",
        "150203009": "前进道街道",
        "150203010": "市府东路街道",
        "150203011": "白云路街道",
        "150203012": "黄河西路街道",
        "150203013": "昆工路街道",
        "150203014": "昆北街道",
        "150203100": "昆河镇",
        "150203102": "卜尔汉图镇",
        "150203400": "包钢厂区"
    },
    "150204": {
        "150204001": "先锋道街道",
        "150204002": "幸福路街道",
        "150204003": "万青路街道",
        "150204004": "富强路街道",
        "150204005": "科学路街道",
        "150204006": "青山路街道",
        "150204007": "自由路街道",
        "150204008": "乌素图街道",
        "150204100": "青福镇",
        "150204101": "兴胜镇",
        "150204401": "包头市装备制造产业园区"
    },
    "150205": {
        "150205001": "石拐街道",
        "150205002": "大发街道",
        "150205003": "大磁街道",
        "150205004": "五当沟街道",
        "150205005": "白狐沟街道",
        "150205006": "大德恒",
        "150205101": "五当召镇",
        "150205202": "吉忽伦图苏木",
        "150205400": "工业园区管理委员会"
    },
    "150206": {"150206001": "矿山路街道", "150206002": "通阳道街道"},
    "150207": {
        "150207001": "沙河街道",
        "150207002": "赛汗街道",
        "150207003": "萨如拉街道",
        "150207004": "白音席勒街道",
        "150207103": "麻池镇",
        "150207105": "哈林格尔镇",
        "150207110": "哈业胡同镇",
        "150207202": "阿嘎如泰苏木乡"
    },
    "150221": {
        "150221104": "萨拉齐镇",
        "150221105": "双龙镇",
        "150221106": "美岱召镇",
        "150221107": "沟门镇",
        "150221108": "将军尧镇",
        "150221213": "海子乡",
        "150221214": "明沙淖乡",
        "150221215": "苏波盖乡",
        "150221500": "九峰山生态管理委员会"
    },
    "150222": {
        "150222102": "金山镇",
        "150222103": "西斗铺镇",
        "150222104": "下湿壕镇",
        "150222105": "银号镇",
        "150222106": "怀朔镇",
        "150222107": "兴顺西镇"
    },
    "150223": {
        "150223102": "满都拉镇",
        "150223103": "希拉穆仁镇",
        "150223104": "百灵庙镇",
        "150223105": "石宝镇",
        "150223106": "乌克忽洞镇",
        "150223107": "明安镇",
        "150223108": "巴音花镇",
        "150223213": "达尔罕苏木乡",
        "150223214": "查干哈达苏木",
        "150223215": "巴音敖包苏木",
        "150223216": "西河乡",
        "150223217": "小文公乡"
    },
    "150271": {"150271010": "稀土路街道", "150271020": "民馨路街道", "150271102": "万水泉镇"},
    "150300": {"150302": "海勃湾区", "150303": "海南区", "150304": "乌达区"},
    "150302": {
        "150302001": "凤凰岭街道",
        "150302002": "海北街道",
        "150302003": "新华街道",
        "150302004": "新华西街道",
        "150302007": "滨河街道",
        "150302008": "林荫街道",
        "150302101": "千里山镇",
        "150302400": "乌海市海勃湾千里山工业园区"
    },
    "150303": {
        "150303001": "拉僧仲街道",
        "150303002": "西卓子山街道",
        "150303102": "公乌素镇",
        "150303103": "拉僧庙镇",
        "150303104": "巴音陶亥镇"
    },
    "150304": {
        "150304002": "三道坎街道",
        "150304003": "五虎山街道",
        "150304008": "新达街道",
        "150304009": "巴音赛街道",
        "150304010": "梁家沟街道",
        "150304011": "苏海图街道",
        "150304012": "滨海街道",
        "150304101": "乌兰淖尔镇",
        "150304400": "乌达经济开发区"
    },
    "150400": {
        "150402": "红山区",
        "150403": "元宝山区",
        "150404": "松山区",
        "150421": "阿鲁科尔沁旗",
        "150422": "巴林左旗",
        "150423": "巴林右旗",
        "150424": "林西县",
        "150425": "克什克腾旗",
        "150426": "翁牛特旗",
        "150428": "喀喇沁旗",
        "150429": "宁城县",
        "150430": "敖汉旗"
    },
    "150402": {
        "150402001": "西屯街道",
        "150402002": "三中街街道",
        "150402003": "永巨街道",
        "150402004": "东城街道",
        "150402005": "南新街街道",
        "150402006": "站前街道",
        "150402007": "铁南街道",
        "150402008": "长青街道",
        "150402009": "哈达街道",
        "150402010": "西城街道",
        "150402011": "桥北街道",
        "150402100": "红庙子镇",
        "150402102": "文钟镇",
        "150402400": "红山高新技术产业园区管理委员会",
        "150402401": "红山物流园区",
        "150402402": "红山区农畜产品加工产业园区"
    },
    "150403": {
        "150403001": "西露天街道",
        "150403002": "平庄城区街道",
        "150403003": "平庄东城街道",
        "150403004": "平庄西城街道",
        "150403005": "马林街道",
        "150403006": "云杉路街道",
        "150403100": "风水沟镇",
        "150403101": "元宝山镇",
        "150403102": "美丽河镇",
        "150403103": "平庄镇",
        "150403104": "五家镇",
        "150403200": "小五家乡"
    },
    "150404": {
        "150404001": "振兴街道",
        "150404002": "向阳街道",
        "150404003": "松州街道",
        "150404004": "铁东街道",
        "150404100": "穆家营子镇",
        "150404101": "初头朗镇",
        "150404102": "大庙镇",
        "150404103": "王府镇",
        "150404104": "老府镇",
        "150404105": "哈拉道口镇",
        "150404106": "上官地镇",
        "150404107": "安庆镇",
        "150404108": "太平地镇",
        "150404200": "当铺地满族乡",
        "150404201": "夏家店乡",
        "150404202": "城子乡",
        "150404203": "大夫营子乡",
        "150404204": "岗子乡",
        "150404400": "玉龙街道",
        "150404401": "全宁街道",
        "150404407": "兴安街道"
    },
    "150421": {
        "150421100": "天山镇",
        "150421101": "天山口镇",
        "150421102": "双胜镇",
        "150421103": "坤都镇",
        "150421104": "巴彦花镇",
        "150421105": "绍根镇",
        "150421106": "扎嘎斯台镇",
        "150421200": "新民乡",
        "150421201": "先锋乡",
        "150421202": "罕苏木苏木",
        "150421203": "赛汉塔拉苏木",
        "150421204": "巴拉奇如德苏木",
        "150421205": "乌兰哈达乡",
        "150421206": "巴彦温都尔苏木",
        "150421400": "轻工食品产业园",
        "150421401": "新能源产业园",
        "150421402": "绍根煤碳及煤化工产业园",
        "150421403": "罕乌拉街道",
        "150421404": "欧沐沦街道"
    },
    "150422": {
        "150422100": "林东镇",
        "150422101": "隆昌镇",
        "150422102": "十三敖包镇",
        "150422103": "碧流台镇",
        "150422104": "富河镇",
        "150422105": "白音勿拉镇",
        "150422106": "哈拉哈达镇",
        "150422200": "查干哈达苏木",
        "150422201": "乌兰达坝苏木",
        "150422202": "三山乡",
        "150422203": "花加拉嘎乡",
        "150422400": "上京高新技术产业集中区",
        "150422401": "南塔工业集中区",
        "150422402": "凤凰山工业集中区",
        "150422403": "林东西城街道",
        "150422404": "林东东城街道"
    },
    "150423": {
        "150423100": "大板镇",
        "150423101": "索博日嘎镇",
        "150423102": "宝日勿苏镇",
        "150423103": "查干诺尔镇",
        "150423105": "巴彦琥硕镇",
        "150423200": "西拉沐沦苏木",
        "150423201": "巴彦塔拉苏木",
        "150423202": "幸福之路苏木",
        "150423203": "查干沐沦苏木",
        "150423400": "大板煤电化基地"
    },
    "150424": {
        "150424100": "林西镇",
        "150424101": "新城子镇",
        "150424102": "新林镇",
        "150424103": "五十家子镇",
        "150424104": "官地镇",
        "150424105": "大井镇",
        "150424106": "统部镇",
        "150424200": "大营子乡",
        "150424201": "十二吐乡",
        "150424400": "城北街道筹备处",
        "150424401": "城南街道筹备处",
        "150424402": "内蒙古林西工业园区"
    },
    "150425": {
        "150425100": "经棚镇",
        "150425101": "宇宙地镇",
        "150425102": "土城子镇",
        "150425103": "同兴镇",
        "150425104": "万合永镇",
        "150425105": "芝瑞镇",
        "150425106": "达来诺日镇",
        "150425200": "新开地乡",
        "150425201": "红山子乡",
        "150425202": "达日罕乌拉苏木",
        "150425203": "巴彦查干苏木",
        "150425204": "浩来呼热苏木",
        "150425205": "乌兰布统苏木",
        "150425400": "应昌街道",
        "150425401": "热水塘温泉疗养旅游开发区管委会",
        "150425402": "克什克腾旗煤电化基地",
        "150425403": "西拉沐沦街道",
        "150425502": "克什克腾旗渔场"
    },
    "150426": {
        "150426100": "乌丹镇",
        "150426101": "乌敦套海镇",
        "150426102": "五分地镇",
        "150426103": "桥头镇",
        "150426104": "广德公镇",
        "150426105": "梧桐花镇",
        "150426106": "海拉苏镇",
        "150426107": "亿合公镇",
        "150426200": "解放营子乡",
        "150426201": "阿什罕苏木",
        "150426202": "新苏莫苏木",
        "150426203": "白音套海苏木",
        "150426204": "毛山东乡",
        "150426205": "格日僧苏木",
        "150426400": "玉龙工业园区",
        "150426402": "紫城街道筹备处",
        "150426403": "全宁街道筹备处"
    },
    "150428": {
        "150428100": "锦山镇",
        "150428101": "美林镇",
        "150428102": "王爷府镇",
        "150428103": "小牛群镇",
        "150428104": "牛家营子镇",
        "150428105": "乃林镇",
        "150428106": "西桥镇",
        "150428200": "十家满族乡",
        "150428201": "南台子乡",
        "150428400": "乃林果树农场",
        "150428401": "马鞍山羊场",
        "150428402": "赤峰市和美工贸园区",
        "150428403": "锦山工业园区"
    },
    "150429": {
        "150429100": "天义镇",
        "150429101": "小城子镇",
        "150429102": "大城子镇",
        "150429103": "八里罕镇",
        "150429104": "黑里河镇",
        "150429105": "右北平镇",
        "150429106": "大双庙镇",
        "150429107": "汐子镇",
        "150429108": "大明镇",
        "150429109": "忙农镇",
        "150429110": "五化镇",
        "150429111": "三座店镇",
        "150429112": "必斯营子镇",
        "150429202": "一肯中乡",
        "150429203": "存金沟乡",
        "150429400": "中京工业园",
        "150429401": "塞飞亚食品工业园",
        "150429402": "八里罕酒业园区",
        "150429403": "再生资源产业园",
        "150429404": "生物科技产业园区",
        "150429405": "汐子工业园",
        "150429406": "农机产业园区"
    },
    "150430": {
        "150430100": "新惠镇",
        "150430101": "四家子镇",
        "150430102": "长胜镇",
        "150430103": "贝子府镇",
        "150430104": "四道湾子镇",
        "150430105": "下洼镇",
        "150430106": "金厂沟梁镇",
        "150430107": "兴隆洼镇",
        "150430108": "黄羊洼镇",
        "150430109": "牛古吐镇",
        "150430110": "古鲁板蒿镇",
        "150430202": "木头营子乡",
        "150430204": "丰收乡",
        "150430205": "玛尼罕乡",
        "150430206": "萨力巴乡",
        "150430207": "敖润苏莫苏木",
        "150430400": "国营种羊场",
        "150430401": "敖汉旗新惠工业园区",
        "150430402": "贝子府化工集中区",
        "150430403": "四家子冶金工业集中区"
    },
    "150500": {
        "150502": "科尔沁区",
        "150521": "科尔沁左翼中旗",
        "150522": "科尔沁左翼后旗",
        "150523": "开鲁县",
        "150524": "库伦旗",
        "150525": "奈曼旗",
        "150526": "扎鲁特旗",
        "150571": "通辽经济技术开发区",
        "150581": "霍林郭勒市"
    },
    "150502": {
        "150502001": "科尔沁街道",
        "150502002": "西门街道",
        "150502003": "永清街道",
        "150502004": "明仁街道",
        "150502005": "施介街道",
        "150502006": "清真街道",
        "150502007": "东郊街道",
        "150502008": "铁路街道",
        "150502009": "红星街道",
        "150502010": "霍林街道",
        "150502011": "建国街道",
        "150502100": "大林镇",
        "150502101": "钱家店镇",
        "150502102": "余粮堡镇",
        "150502103": "木里图镇",
        "150502104": "丰田镇",
        "150502105": "清河镇",
        "150502106": "育新镇",
        "150502107": "庆和镇",
        "150502108": "敖力布皋镇",
        "150502200": "莫力庙苏木",
        "150502400": "哲南农场",
        "150502401": "三义堂农场",
        "150502402": "高林屯种畜场",
        "150502403": "胡力海原种繁殖场",
        "150502404": "莫力庙羊场",
        "150502405": "半截店牧场"
    },
    "150521": {
        "150521100": "保康镇",
        "150521101": "宝龙山镇",
        "150521102": "舍伯吐镇",
        "150521103": "巴彦塔拉镇",
        "150521104": "门达镇",
        "150521105": "架玛吐镇",
        "150521106": "腰林毛都镇",
        "150521107": "希伯花镇",
        "150521108": "花吐古拉镇",
        "150521109": "代力吉镇",
        "150521110": "努日木镇",
        "150521200": "花胡硕苏木乡",
        "150521201": "协代苏木乡",
        "150521202": "白兴吐苏木乡",
        "150521203": "图布信苏木乡",
        "150521204": "敖包苏木",
        "150521205": "胜利乡",
        "150521400": "白音塔拉农场",
        "150521401": "珠日河牧场",
        "150521402": "丰库牧场",
        "150521403": "保康街道"
    },
    "150522": {
        "150522100": "甘旗卡镇",
        "150522101": "吉尔嘎朗镇",
        "150522102": "金宝屯镇",
        "150522103": "常胜镇",
        "150522104": "查日苏镇",
        "150522105": "双胜镇",
        "150522106": "阿古拉镇",
        "150522107": "朝鲁吐镇",
        "150522108": "努古斯台镇",
        "150522109": "海鲁吐镇",
        "150522200": "阿都沁苏木",
        "150522201": "茂道吐苏木",
        "150522202": "巴嘎塔拉苏木",
        "150522203": "散都苏木",
        "150522204": "巴彦毛都苏木",
        "150522400": "胜利农场",
        "150522401": "孟根达坝牧场",
        "150522402": "查金台牧场",
        "150522403": "原种场",
        "150522404": "大青沟管理局",
        "150522405": "哈日乌苏种畜场",
        "150522406": "乌兰敖道渔场",
        "150522407": "金宝屯林场",
        "150522408": "朝鲁吐林场",
        "150522409": "茂道吐林场",
        "150522410": "伊胡塔林场",
        "150522411": "乌旦塔拉林场"
    },
    "150523": {
        "150523100": "开鲁镇",
        "150523101": "大榆树镇",
        "150523102": "黑龙坝镇",
        "150523103": "麦新镇",
        "150523104": "义和塔拉镇",
        "150523105": "建华镇",
        "150523106": "小街基镇",
        "150523107": "东风镇",
        "150523108": "吉日嘎郎吐镇",
        "150523109": "东来镇",
        "150523400": "清河牧场",
        "150523401": "辽河农场",
        "150523402": "保安农场"
    },
    "150524": {
        "150524100": "库伦镇",
        "150524101": "扣河子镇",
        "150524102": "白音花镇",
        "150524103": "六家子镇",
        "150524104": "额勒顺镇",
        "150524200": "茫汗苏木",
        "150524201": "先进苏木",
        "150524202": "水泉乡",
        "150524400": "库伦街道"
    },
    "150525": {
        "150525100": "大沁他拉镇",
        "150525101": "八仙筒镇",
        "150525102": "青龙山镇",
        "150525103": "新镇",
        "150525104": "治安镇",
        "150525105": "东明镇",
        "150525106": "沙日浩来镇",
        "150525107": "义隆永镇",
        "150525200": "固日班花苏木",
        "150525201": "白音他拉苏木",
        "150525202": "明仁苏木",
        "150525203": "黄花塔拉苏木",
        "150525204": "土城子乡",
        "150525205": "苇莲苏乡",
        "150525400": "国有六号农场"
    },
    "150526": {
        "150526100": "鲁北镇",
        "150526101": "黄花山镇",
        "150526102": "嘎亥图镇",
        "150526103": "巨日合镇",
        "150526104": "巴雅尔图胡硕镇",
        "150526105": "香山镇",
        "150526106": "阿日昆都楞镇",
        "150526200": "巴彦塔拉苏木乡",
        "150526201": "乌力吉木仁苏木乡",
        "150526202": "道老杜苏木乡",
        "150526203": "格日朝鲁苏木乡",
        "150526204": "前德门苏木",
        "150526205": "乌兰哈达苏木",
        "150526206": "查布嘎图苏木",
        "150526207": "乌额格其苏木",
        "150526400": "乌额格其牧场",
        "150526401": "香山农场",
        "150526402": "乌日根塔拉农场",
        "150526403": "嘎达苏种畜场"
    },
    "150571": {"150571001": "河西街道", "150571002": "电厂街道", "150571003": "新城街道", "150571004": "滨河街道", "150571100": "辽河镇"},
    "150581": {
        "150581001": "珠斯花街道",
        "150581002": "莫斯台街道",
        "150581003": "宝日呼吉尔街道",
        "150581004": "沙尔呼热街道",
        "150581200": "达来胡硕苏木",
        "150581400": "东蒙鑫农牧业有限责任公司"
    },
    "150600": {
        "150602": "东胜区",
        "150603": "康巴什区",
        "150621": "达拉特旗",
        "150622": "准格尔旗",
        "150623": "鄂托克前旗",
        "150624": "鄂托克旗",
        "150625": "杭锦旗",
        "150626": "乌审旗",
        "150627": "伊金霍洛旗"
    },
    "150602": {
        "150602001": "交通街道",
        "150602002": "公园街道",
        "150602003": "林荫街道",
        "150602004": "建设街道",
        "150602005": "富兴街道",
        "150602006": "天骄街道",
        "150602007": "诃额伦街道",
        "150602008": "巴音门克街道",
        "150602010": "幸福街道",
        "150602011": "纺织街道",
        "150602012": "兴盛街道",
        "150602013": "民族街道",
        "150602100": "泊尔江海子镇",
        "150602101": "罕台镇",
        "150602102": "铜川镇",
        "150602400": "鄂尔多斯市装备制造基地",
        "150602401": "东胜区轻纺工业园区",
        "150602404": "铜川汽车博览园"
    },
    "150603": {
        "150603001": "哈巴格希街道",
        "150603002": "青春山街道",
        "150603003": "滨河街道",
        "150603008": "康新街道",
        "150603401": "鄂尔多斯市高新技术产业园区",
        "150603402": "鄂尔多斯市云计算产业园区"
    },
    "150621": {
        "150621001": "工业街道",
        "150621002": "昭君街道",
        "150621003": "锡尼街道",
        "150621004": "白塔街道",
        "150621005": "西园街道",
        "150621006": "平原街道",
        "150621100": "树林召镇",
        "150621101": "吉格斯太镇",
        "150621102": "白泥井镇",
        "150621103": "王爱召镇",
        "150621104": "昭君镇",
        "150621105": "恩格贝镇",
        "150621106": "中和西镇",
        "150621107": "风水梁镇",
        "150621200": "展旦召苏木乡",
        "150621400": "达拉特经济开发区",
        "150621401": "鄂尔多斯市生态示范区管理委员会"
    },
    "150622": {
        "150622001": "兴隆街道",
        "150622002": "迎泽街道",
        "150622003": "蓝天街道",
        "150622004": "友谊街道",
        "150622100": "薛家湾镇",
        "150622101": "沙圪堵镇",
        "150622102": "大路镇",
        "150622103": "纳日松镇",
        "150622104": "龙口镇",
        "150622105": "准格尔召镇",
        "150622106": "魏家峁镇",
        "150622200": "暖水乡",
        "150622201": "十二连城乡",
        "150622202": "布尔陶亥苏木乡",
        "150622400": "准格尔经济开发区",
        "150622401": "大路煤化工基地"
    },
    "150623": {
        "150623100": "敖勒召其镇",
        "150623101": "上海庙镇",
        "150623102": "城川镇",
        "150623103": "昂素镇",
        "150623400": "上海庙经济开发区"
    },
    "150624": {
        "150624100": "乌兰镇",
        "150624101": "棋盘井镇",
        "150624102": "蒙西镇",
        "150624103": "木凯淖尔镇",
        "150624200": "苏米图苏木乡",
        "150624201": "阿尔巴斯苏木乡",
        "150624403": "蒙西工业园区",
        "150624404": "棋盘井工业园区"
    },
    "150625": {
        "150625100": "锡尼镇",
        "150625101": "巴拉贡镇",
        "150625102": "吉日嘎朗图镇",
        "150625103": "独贵特拉镇",
        "150625104": "呼和木独镇",
        "150625200": "伊和乌素苏木乡",
        "150625400": "杭锦旗独贵塔拉工业园区管理委员会",
        "150625401": "鄂尔多斯新能源产业示范区"
    },
    "150626": {
        "150626100": "嘎鲁图镇",
        "150626101": "乌审召镇",
        "150626102": "图克镇",
        "150626103": "乌兰陶勒盖镇",
        "150626104": "无定河镇",
        "150626200": "苏力德苏木乡"
    },
    "150627": {
        "150627100": "阿勒腾席热镇",
        "150627101": "札萨克镇",
        "150627102": "乌兰木伦镇",
        "150627103": "纳林陶亥镇",
        "150627104": "苏布尔嘎镇",
        "150627105": "红庆河镇",
        "150627106": "伊金霍洛镇",
        "150627400": "鄂尔多斯江苏工业园区",
        "150627403": "鄂尔多斯圣圆煤化工基地",
        "150627405": "鄂尔多斯蒙古源流文化产业园区",
        "150627406": "伊金霍洛旗札萨克物流园区",
        "150627407": "鄂尔多斯空港物流园区"
    },
    "150700": {
        "150702": "海拉尔区",
        "150703": "扎赉诺尔区",
        "150721": "阿荣旗",
        "150722": "莫力达瓦达斡尔族自治旗",
        "150723": "鄂伦春自治旗",
        "150724": "鄂温克族自治旗",
        "150725": "陈巴尔虎旗",
        "150726": "新巴尔虎左旗",
        "150727": "新巴尔虎右旗",
        "150781": "满洲里市",
        "150782": "牙克石市",
        "150783": "扎兰屯市",
        "150784": "额尔古纳市",
        "150785": "根河市"
    },
    "150702": {
        "150702001": "正阳",
        "150702002": "健康街道",
        "150702003": "靠山街道",
        "150702004": "胜利街道",
        "150702005": "呼伦街道",
        "150702007": "建设街道",
        "150702008": "东山街道",
        "150702100": "哈克镇",
        "150702101": "奋斗镇"
    },
    "150703": {
        "150703001": "第三街道",
        "150703002": "第一街道",
        "150703003": "第二街道",
        "150703004": "第四街道",
        "150703005": "第五街道",
        "150703100": "灵泉镇"
    },
    "150721": {
        "150721100": "那吉镇",
        "150721101": "六合镇",
        "150721102": "亚东镇",
        "150721103": "霍尔奇镇",
        "150721104": "向阳峪镇",
        "150721105": "三岔河镇",
        "150721106": "复兴镇",
        "150721107": "兴安镇",
        "150721200": "得力其尔鄂温克民族乡",
        "150721201": "查巴奇鄂温克民族乡",
        "150721202": "音河达斡尔鄂温克民族乡",
        "150721203": "新发朝鲜民族乡",
        "150721400": "那吉屯农场",
        "150721401": "格尼河农场",
        "150721402": "阿荣旗林业局"
    },
    "150722": {
        "150722100": "尼尔基镇",
        "150722101": "红彦镇",
        "150722102": "宝山镇",
        "150722103": "哈达阳镇",
        "150722104": "阿尔拉镇",
        "150722105": "汉古尔河镇",
        "150722106": "西瓦尔图镇",
        "150722108": "腾克镇",
        "150722109": "奎勒河镇",
        "150722110": "塔温敖宝镇",
        "150722111": "登特科镇",
        "150722200": "巴彦鄂温克民族乡",
        "150722201": "库如奇乡",
        "150722202": "额尔和乡",
        "150722203": "杜拉尔鄂温克民族乡",
        "150722400": "国营甘河农场",
        "150722401": "国营巴彦农场",
        "150722402": "国营欧肯河农场",
        "150722403": "国营东方红农场"
    },
    "150723": {
        "150723100": "阿里河镇",
        "150723101": "大杨树镇",
        "150723102": "甘河镇",
        "150723103": "吉文镇",
        "150723104": "诺敏镇",
        "150723105": "乌鲁布铁镇",
        "150723106": "宜里镇",
        "150723107": "克一河镇",
        "150723200": "古里乡",
        "150723201": "托扎敏乡",
        "150723400": "大兴安岭管理局诺敏河农场",
        "150723401": "大兴安岭农场宜里农场",
        "150723402": "大兴安岭农场管理局扎兰河农场",
        "150723403": "大兴安岭农场管理局古里农场",
        "150723404": "加格达奇区",
        "150723405": "松岭地区",
        "150723406": "大杨树林业局",
        "150723407": "甘河林业局",
        "150723408": "克一河林业局",
        "150723409": "吉文林业局",
        "150723410": "阿里河林业局",
        "150723411": "大兴安岭农工商联合公司",
        "150723412": "大兴安岭农场管理局东方红农场",
        "150723413": "大兴安岭农场管理局欧肯河农场",
        "150723414": "大兴安岭林管局毕拉河林业局",
        "150723415": "大兴安岭林管局伊图里河林业局",
        "150723416": "大兴安岭林管局库都尔林业局",
        "150723417": "大兴安岭林管局乌尔其汗林业局"
    },
    "150724": {
        "150724100": "巴彦托海镇",
        "150724110": "大雁镇",
        "150724111": "伊敏河镇",
        "150724112": "红花尔基镇",
        "150724210": "巴彦查岗苏木",
        "150724211": "锡尼河西苏木",
        "150724212": "锡尼河东苏木",
        "150724213": "巴彦塔拉达斡尔民族乡",
        "150724214": "伊敏苏木",
        "150724215": "辉苏木",
        "150724400": "巴彦托海经济开发区",
        "150724401": "伊敏河高载能工业园区",
        "150724402": "大雁马铃薯高新技术示范园区",
        "150724403": "呼伦贝尔新城区"
    },
    "150725": {
        "150725100": "陈旗巴彦库仁镇",
        "150725101": "陈旗宝日希勒镇",
        "150725102": "陈旗呼和诺尔镇",
        "150725200": "陈旗西乌珠尔苏木",
        "150725201": "陈旗鄂温克民族苏木",
        "150725202": "陈旗东乌珠尔苏木",
        "150725203": "陈旗巴彦哈达苏木",
        "150725501": "陈巴尔虎旗国营哈图达牧场",
        "150725502": "陈旗国营浩特陶海农牧场",
        "150725503": "陈旗国营特泥河牧场"
    },
    "150726": {
        "150726101": "嵯岗镇",
        "150726102": "阿木古郎镇",
        "150726202": "新宝力格苏木",
        "150726203": "乌布尔宝力格苏木",
        "150726204": "罕达盖苏木",
        "150726205": "吉布胡郎图苏木",
        "150726206": "甘珠尔苏木",
        "150726400": "嵯岗双娃牧场"
    },
    "150727": {
        "150727100": "阿拉坦额莫勒镇",
        "150727101": "阿日哈沙特镇",
        "150727102": "呼伦镇",
        "150727200": "贝尔苏木",
        "150727201": "克尔伦苏木",
        "150727202": "达赉苏木",
        "150727203": "宝格德乌拉苏木",
        "150727500": "敖尔金牧场"
    },
    "150781": {
        "150781001": "东山街道",
        "150781002": "道南街道",
        "150781003": "道北街道",
        "150781004": "兴华街道",
        "150781012": "敖尔金街道",
        "150781100": "新开河镇",
        "150781400": "满洲里市互市贸易区",
        "150781401": "满洲里市经济合作区",
        "150781402": "满洲里市东湖区",
        "150781403": "满洲里市扎煤公司",
        "150781404": "扎赉诺尔矿区",
        "150781405": "满洲里市工业园区",
        "150781406": "满洲里国际物流产业园区"
    },
    "150782": {
        "150782001": "胜利街道",
        "150782002": "红旗街道",
        "150782003": "新工街道",
        "150782004": "永兴街道",
        "150782005": "建设街道",
        "150782006": "暖泉街道",
        "150782100": "免渡河镇",
        "150782101": "博克图镇",
        "150782102": "绰河源镇",
        "150782103": "乌尔其汉镇",
        "150782104": "库都尔镇",
        "150782105": "图里河镇",
        "150782106": "乌奴耳镇",
        "150782107": "塔尔气镇",
        "150782108": "伊图里河镇",
        "150782109": "牧原镇",
        "150782400": "莫拐农场",
        "150782401": "牙克石农场",
        "150782402": "免渡河农场"
    },
    "150783": {
        "150783001": "兴华街道",
        "150783002": "正阳街道",
        "150783003": "繁荣街道",
        "150783004": "向阳街道",
        "150783005": "高台子街道",
        "150783006": "铁东街道",
        "150783007": "河西街道",
        "150783100": "蘑菇气镇",
        "150783102": "卧牛河镇",
        "150783103": "成吉思汗镇",
        "150783104": "大河湾镇",
        "150783105": "浩饶山镇",
        "150783106": "柴河镇",
        "150783107": "中和镇",
        "150783108": "哈多河镇",
        "150783200": "达斡尔民族乡",
        "150783201": "鄂伦春民族乡",
        "150783202": "萨马街鄂温克民族乡",
        "150783203": "洼堤乡",
        "150783400": "大河湾农场",
        "150783401": "绰尔河农场",
        "150783402": "扎兰屯马场",
        "150783403": "成吉思汗牧场",
        "150783404": "公安部后勤供应处农场",
        "150783405": "南木林业局",
        "150783406": "柴河林业局",
        "150783407": "扎兰屯市林业局",
        "150783408": "市原种场"
    },
    "150784": {
        "150784001": "拉布达林街道",
        "150784002": "上库力街道",
        "150784101": "黑山头镇",
        "150784103": "莫尔道嘎镇",
        "150784104": "恩和哈达镇",
        "150784200": "三河回族乡",
        "150784210": "恩和俄罗斯族民族乡",
        "150784211": "蒙兀室韦苏木",
        "150784212": "奇乾乡",
        "150784400": "海拉尔农垦集团有限公司拉布大林农场分公司",
        "150784401": "三河金騋农牧有限责任公司",
        "150784402": "海拉尔农垦集团有限责任公司上库力农场分公司",
        "150784403": "海拉尔农垦集团有限责任公司苏沁牧场分公司"
    },
    "150785": {
        "150785001": "河东街道",
        "150785002": "河西街道",
        "150785003": "森工街道",
        "150785004": "好里堡街道",
        "150785101": "金河镇",
        "150785102": "阿龙山镇",
        "150785103": "满归镇",
        "150785104": "得耳布尔镇",
        "150785200": "敖鲁古雅乡"
    },
    "150800": {
        "150802": "临河区",
        "150821": "五原县",
        "150822": "磴口县",
        "150823": "乌拉特前旗",
        "150824": "乌拉特中旗",
        "150825": "乌拉特后旗",
        "150826": "杭锦后旗"
    },
    "150802": {
        "150802001": "团结",
        "150802002": "车站",
        "150802003": "先锋",
        "150802004": "解放",
        "150802005": "新华",
        "150802006": "东环路",
        "150802007": "铁南",
        "150802008": "西环路",
        "150802009": "北环路",
        "150802013": "金川",
        "150802014": "汇丰",
        "150802100": "狼山镇",
        "150802101": "新华镇",
        "150802102": "干召庙镇",
        "150802103": "乌兰图克镇",
        "150802104": "双河镇",
        "150802105": "城关镇",
        "150802106": "白脑包镇",
        "150802200": "曙光乡",
        "150802201": "八一乡",
        "150802400": "狼山农场",
        "150802401": "临河农场"
    },
    "150821": {
        "150821100": "隆兴昌镇",
        "150821101": "塔尔湖镇",
        "150821102": "套海镇",
        "150821103": "新公中镇",
        "150821104": "天吉太镇",
        "150821105": "胜丰镇",
        "150821106": "银定图镇",
        "150821107": "复兴镇",
        "150821200": "和胜乡",
        "150821400": "巴彦淖尔市建丰农场",
        "150821401": "内蒙古东土城劳动教养管理所"
    },
    "150822": {
        "150822100": "巴彦高勒镇",
        "150822101": "隆盛合镇",
        "150822102": "渡口镇",
        "150822103": "补隆淖镇",
        "150822200": "沙金套海苏木",
        "150822400": "乌兰布和农场",
        "150822401": "巴彦套海农场",
        "150822402": "哈腾套海农场",
        "150822403": "包尔盖农场",
        "150822404": "纳林套海农场",
        "150822405": "沙漠林业实验中心农场"
    },
    "150823": {
        "150823100": "乌拉山镇",
        "150823101": "白彦花镇",
        "150823102": "先锋镇",
        "150823103": "新安镇",
        "150823104": "西小召镇",
        "150823105": "大佘太镇",
        "150823106": "明安镇",
        "150823107": "小佘太镇",
        "150823108": "苏独仑镇",
        "150823200": "额尔登布拉格苏木",
        "150823201": "沙德格苏木",
        "150823400": "巴彦淖尔市中滩农场",
        "150823401": "巴彦淖尔市西山咀农场",
        "150823402": "巴彦淖尔市新安农场",
        "150823403": "巴彦淖尔市苏独仑农场",
        "150823404": "巴彦淖尔市大佘太牧场",
        "150823405": "巴彦淖尔市乌梁素海渔场"
    },
    "150824": {
        "150824100": "海流图镇",
        "150824101": "乌加河镇",
        "150824102": "德岭山镇",
        "150824103": "石哈河镇",
        "150824104": "甘其毛都镇",
        "150824105": "温更镇",
        "150824200": "呼鲁斯太苏木",
        "150824201": "川井苏木",
        "150824202": "巴音乌兰苏木",
        "150824203": "新忽热苏木",
        "150824400": "牧羊海牧场",
        "150824401": "同和太种畜场"
    },
    "150825": {
        "150825100": "巴音宝力格镇",
        "150825101": "呼和温都尔镇",
        "150825102": "潮格温都尔镇",
        "150825200": "获各琦苏木",
        "150825201": "巴音前达门苏木",
        "150825202": "乌盖苏木"
    },
    "150826": {
        "150826100": "陕坝镇",
        "150826101": "头道桥镇",
        "150826102": "二道桥镇",
        "150826103": "三道桥镇",
        "150826104": "蛮会镇",
        "150826105": "团结镇",
        "150826106": "双庙镇",
        "150826107": "沙海镇",
        "150826108": "蒙海镇",
        "150826500": "太阳庙农场"
    },
    "150900": {
        "150902": "集宁区",
        "150921": "卓资县",
        "150922": "化德县",
        "150923": "商都县",
        "150924": "兴和县",
        "150925": "凉城县",
        "150926": "察哈尔右翼前旗",
        "150927": "察哈尔右翼中旗",
        "150928": "察哈尔右翼后旗",
        "150929": "四子王旗",
        "150981": "丰镇市"
    },
    "150902": {
        "150902001": "新体路街道",
        "150902002": "桥东街道",
        "150902003": "前进路街道",
        "150902004": "常青街道",
        "150902005": "虎山街道",
        "150902006": "桥西街道",
        "150902007": "新华街街道",
        "150902008": "泉山街道",
        "150902009": "卧龙街道",
        "150902010": "霸王河街道",
        "150902105": "白海子镇",
        "150902205": "马莲渠乡"
    },
    "150921": {
        "150921106": "卓资山镇",
        "150921107": "旗下营镇",
        "150921108": "卓资县十八台镇",
        "150921109": "卓资县巴音锡勒镇",
        "150921110": "卓资县梨花镇",
        "150921210": "卓资县大榆树乡",
        "150921211": "卓资县红召乡",
        "150921212": "卓资县复兴乡"
    },
    "150922": {
        "150922103": "化德县长顺镇",
        "150922104": "化德县朝阳镇",
        "150922105": "化德县七号镇",
        "150922200": "化德县德包图乡",
        "150922201": "化德县公腊胡洞乡",
        "150922218": "化德县白音特拉乡"
    },
    "150923": {
        "150923101": "七台镇",
        "150923102": "十八顷镇",
        "150923103": "大黑沙土镇",
        "150923104": "西井子镇",
        "150923105": "屯垦队镇",
        "150923106": "小海子镇",
        "150923200": "大库伦乡",
        "150923201": "卯都乡",
        "150923202": "玻璃忽镜乡",
        "150923203": "三大顷乡"
    },
    "150924": {
        "150924106": "城关镇",
        "150924107": "张皋镇",
        "150924108": "赛乌素镇",
        "150924109": "鄂尔栋镇",
        "150924110": "店子镇",
        "150924209": "大库联乡",
        "150924210": "民族团结乡",
        "150924211": "大同夭乡",
        "150924212": "五股泉乡",
        "150924400": "南部矿区管委会"
    },
    "150925": {
        "150925103": "鸿茅镇",
        "150925104": "六苏木镇",
        "150925105": "麦胡图镇",
        "150925106": "永兴镇",
        "150925107": "蛮汉镇",
        "150925108": "岱海镇",
        "150925218": "天成乡",
        "150925220": "曹碾满族乡",
        "150925401": "蛮汉山林场",
        "150925402": "岱海林场"
    },
    "150926": {
        "150926105": "土贵乌拉镇",
        "150926106": "平地泉镇",
        "150926107": "玫瑰营镇",
        "150926108": "巴音塔拉镇",
        "150926109": "黄旗海镇",
        "150926209": "乌拉哈乌拉乡",
        "150926210": "黄茂营乡",
        "150926211": "三岔口乡",
        "150926212": "老圈沟乡",
        "150926400": "察右前旗天皮山冶金化工工业园区",
        "150926401": "察右前旗察哈尔工业园区"
    },
    "150927": {
        "150927103": "科布尔镇",
        "150927104": "铁沙盖镇",
        "150927105": "黄羊城镇",
        "150927106": "广益隆镇",
        "150927107": "乌素图镇",
        "150927216": "大滩乡",
        "150927217": "宏盘乡",
        "150927218": "巴音乡",
        "150927219": "库联苏木",
        "150927220": "乌兰哈页苏木",
        "150927221": "察右中旗土城子乡",
        "150927400": "辉腾锡勒园区管理委员会"
    },
    "150928": {
        "150928105": "白音察干镇",
        "150928106": "察哈尔右翼后旗土牧尔台镇",
        "150928107": "察哈尔右翼后旗红格尔图镇",
        "150928108": "察哈尔右翼后旗贲红镇",
        "150928109": "察哈尔右翼后旗大六号镇",
        "150928209": "察哈尔右翼后旗当郎忽洞苏木",
        "150928210": "察哈尔右翼后旗乌兰哈达苏木",
        "150928211": "察哈尔右翼后旗锡勒乡"
    },
    "150929": {
        "150929100": "四子王旗乌兰花镇",
        "150929101": "四子王旗吉生太镇",
        "150929102": "四子王旗库伦图镇",
        "150929103": "四子王旗供济堂镇",
        "150929104": "四子王旗白音朝克图镇",
        "150929200": "四子王旗红格尔苏木",
        "150929201": "四子王旗江岸苏木",
        "150929202": "四子王旗查干补力格苏木",
        "150929203": "四子王旗脑木更苏木",
        "150929204": "四子王旗东八号乡",
        "150929205": "四子王旗忽鸡图乡",
        "150929206": "四子王旗大黑河乡",
        "150929207": "四子王旗巴音敖包苏木",
        "150929400": "四子王旗乌兰牧场"
    },
    "150981": {
        "150981005": "新城区街道",
        "150981006": "旧城区街道",
        "150981007": "北城区街道",
        "150981008": "工业区街道",
        "150981009": "南城区街道",
        "150981107": "隆盛庄镇",
        "150981108": "黑土台镇",
        "150981109": "红砂坝镇",
        "150981110": "巨宝庄镇",
        "150981111": "三义泉镇",
        "150981207": "浑源窑乡",
        "150981208": "元山子乡",
        "150981209": "官屯堡乡"
    },
    "152200": {
        "152201": "乌兰浩特市",
        "152202": "阿尔山市",
        "152221": "科尔沁右翼前旗",
        "152222": "科尔沁右翼中旗",
        "152223": "扎赉特旗",
        "152224": "突泉县"
    },
    "152201": {
        "152201001": "爱国",
        "152201002": "和平",
        "152201003": "兴安",
        "152201004": "胜利",
        "152201005": "铁西",
        "152201006": "都林",
        "152201007": "五一",
        "152201008": "城郊",
        "152201009": "新城",
        "152201100": "乌兰哈达镇",
        "152201101": "葛根庙镇",
        "152201102": "太本站镇",
        "152201103": "义勒力特镇",
        "152201401": "呼和马场",
        "152201402": "乌兰浩特工业经济开发区",
        "152201403": "乌兰浩特经济技术开发区"
    },
    "152202": {
        "152202001": "阿尔山市林海街道",
        "152202002": "阿尔山市新城街道",
        "152202003": "阿尔山市温泉街道",
        "152202004": "伊尔施街道",
        "152202100": "阿尔山市天池镇",
        "152202101": "阿尔山市白狼镇",
        "152202102": "阿尔山市五岔沟镇",
        "152202103": "明水河镇"
    },
    "152221": {
        "152221100": "科尔沁镇",
        "152221101": "索伦镇",
        "152221102": "德佰斯镇",
        "152221103": "大石寨镇",
        "152221104": "归流河镇",
        "152221105": "居力很镇",
        "152221106": "察尔森镇",
        "152221107": "额尔格图镇",
        "152221108": "俄体镇",
        "152221200": "满族屯满族乡",
        "152221201": "乌兰毛都苏木乡",
        "152221202": "阿力得尔苏木乡",
        "152221203": "巴日嘎斯台乡",
        "152221204": "桃合木苏木",
        "152221400": "跃进马场",
        "152221401": "索伦牧场",
        "152221403": "阿力得尔牧场",
        "152221404": "公主陵牧场",
        "152221405": "绿水种畜场",
        "152221407": "科右前旗工业园区",
        "152221408": "科右前旗现代农牧业园区"
    },
    "152222": {
        "152222100": "巴彦呼舒镇",
        "152222101": "巴仁哲里木镇",
        "152222102": "吐列毛都镇",
        "152222103": "杜尔基镇",
        "152222104": "高力板镇",
        "152222105": "好腰苏木镇",
        "152222200": "代钦塔拉苏木",
        "152222201": "新佳木苏木",
        "152222202": "哈日诺尔苏木",
        "152222203": "额木庭高勒苏木",
        "152222204": "巴彦茫哈苏木",
        "152222205": "巴彦淖尔苏木",
        "152222400": "布敦化牧场",
        "152222401": "吐列毛杜农场",
        "152222402": "孟恩套力盖矿区工作部",
        "152222403": "布敦化矿区工作部"
    },
    "152223": {
        "152223100": "音德尔镇",
        "152223101": "新林镇",
        "152223102": "巴彦高勒镇",
        "152223103": "胡尔勒镇",
        "152223104": "阿尔本格勒镇",
        "152223105": "巴达尔胡镇",
        "152223106": "图牧吉镇",
        "152223107": "好力保镇",
        "152223201": "巴彦乌兰苏木",
        "152223202": "宝力根花苏木",
        "152223203": "阿拉达尔吐苏木",
        "152223204": "巴彦扎拉嘎乡",
        "152223205": "努文木仁乡",
        "152223400": "内蒙古自治区图牧吉强制隔离戒毒所",
        "152223401": "保安沼监狱",
        "152223402": "乌兰监狱",
        "152223403": "乌塔其监狱",
        "152223404": "种畜场",
        "152223405": "八一牧场",
        "152223406": "巴达尔胡农场"
    },
    "152224": {
        "152224100": "突泉镇",
        "152224101": "六户镇",
        "152224102": "东杜尔基镇",
        "152224103": "永安镇",
        "152224104": "水泉镇",
        "152224105": "宝石镇",
        "152224200": "学田乡",
        "152224201": "九龙乡",
        "152224202": "太平乡",
        "152224400": "东杜尔基国营农场"
    },
    "152500": {
        "152501": "二连浩特市",
        "152502": "锡林浩特市",
        "152522": "阿巴嘎旗",
        "152523": "苏尼特左旗",
        "152524": "苏尼特右旗",
        "152525": "东乌珠穆沁旗",
        "152526": "西乌珠穆沁旗",
        "152527": "太仆寺旗",
        "152528": "镶黄旗",
        "152529": "正镶白旗",
        "152530": "正蓝旗",
        "152531": "多伦县",
        "152571": "乌拉盖管委会"
    },
    "152501": {"152501200": "格日勒敖都苏木", "152501400": "二连浩特市社区建设管理局", "152501401": "二连边境经济技术合作区"},
    "152502": {
        "152502001": "希日塔拉街道",
        "152502002": "宝力根街道",
        "152502003": "杭盖街道",
        "152502004": "楚古兰街道",
        "152502005": "额尔敦街道",
        "152502006": "南郊街道",
        "152502007": "巴彦查干街道",
        "152502100": "阿尔善宝拉格镇",
        "152502200": "宝力根苏木",
        "152502201": "朝克乌拉苏木",
        "152502202": "巴彦锡勒乡",
        "152502203": "巴彦宝拉格苏木",
        "152502400": "锡林郭勒盟经济技术开发区",
        "152502500": "白音库伦牧场",
        "152502501": "贝力克牧场",
        "152502502": "毛登牧场"
    },
    "152522": {
        "152522100": "别力古台镇",
        "152522101": "洪格尔高勒镇",
        "152522102": "查干淖尔镇",
        "152522200": "那仁宝拉格苏木",
        "152522201": "伊和高勒苏木",
        "152522202": "吉尔嘎郎图苏木",
        "152522203": "巴彦图嘎苏木",
        "152522400": "玛尼图煤矿",
        "152522401": "阿旗德力格尔工业园区"
    },
    "152523": {
        "152523100": "满都拉图镇",
        "152523101": "查干敖包镇",
        "152523102": "巴彦淖尔镇",
        "152523200": "巴彦乌拉苏木",
        "152523201": "赛罕高毕苏木",
        "152523202": "洪格尔苏木",
        "152523203": "达来苏木",
        "152523400": "苏尼特左旗芒来循环经济产业园区",
        "152523500": "恩格尔河灌区"
    },
    "152524": {
        "152524100": "赛汉塔拉镇",
        "152524101": "朱日和镇",
        "152524102": "乌日根塔拉镇",
        "152524200": "桑宝拉格苏木",
        "152524201": "额仁淖尔苏木",
        "152524202": "赛罕乌力吉苏木",
        "152524203": "阿其图乌拉苏木",
        "152524400": "苏尼特右旗朱日和工业园区"
    },
    "152525": {
        "152525100": "乌里雅斯太镇",
        "152525101": "道特淖尔镇",
        "152525102": "嘎达布其镇",
        "152525103": "满都胡宝拉格镇",
        "152525104": "额吉淖尔镇",
        "152525200": "呼热图淖尔苏木",
        "152525201": "萨麦苏木",
        "152525202": "嘎海乐苏木",
        "152525203": "阿拉坦合力苏木",
        "152525400": "乌里雅斯太工业区",
        "152525503": "宝格达乌拉总场"
    },
    "152526": {
        "152526100": "巴拉嘎尔高勒镇",
        "152526101": "巴彦花镇",
        "152526102": "吉仁高勒镇",
        "152526103": "浩勒图高勒镇",
        "152526104": "高日罕镇",
        "152526200": "巴彦胡舒苏木",
        "152526201": "乌兰哈拉嘎苏木",
        "152526400": "西乌旗白音华能源化工园区",
        "152526500": "林业总场"
    },
    "152527": {
        "152527100": "宝昌镇",
        "152527101": "千斤沟镇",
        "152527102": "红旗镇",
        "152527103": "骆驼山镇",
        "152527104": "永丰镇",
        "152527200": "幸福乡",
        "152527201": "贡宝拉格苏木",
        "152527500": "万寿滩良种场"
    },
    "152528": {"152528100": "新宝拉格镇", "152528101": "巴彦塔拉镇", "152528200": "翁贡乌拉苏木", "152528201": "宝格达音髙勒苏木"},
    "152529": {
        "152529100": "明安图镇",
        "152529101": "星耀镇",
        "152529200": "伊和淖尔苏木",
        "152529201": "乌兰查布苏木",
        "152529202": "宝拉根陶海苏木",
        "152529500": "额里图种畜场",
        "152529502": "哲日根图林场"
    },
    "152530": {
        "152530100": "上都镇",
        "152530101": "桑根达来镇",
        "152530102": "哈毕日嘎镇",
        "152530200": "宝绍代苏木",
        "152530201": "那日图苏木",
        "152530202": "赛音呼都嘎苏木",
        "152530203": "扎格斯台苏木",
        "152530500": "五一种畜场总场",
        "152530501": "黑城子示范区"
    },
    "152531": {
        "152531100": "大北沟镇",
        "152531101": "多伦诺尔镇",
        "152531102": "滦源镇",
        "152531201": "蔡木山乡",
        "152531202": "西干沟乡",
        "152531400": "多伦新型工业化化工区"
    },
    "152571": {"152571101": "巴彦胡硕镇", "152571500": "哈拉盖图农牧场", "152571501": "贺斯格乌拉牧场", "152571502": "乌拉盖牧场"},
    "152900": {"152921": "阿拉善左旗", "152922": "阿拉善右旗", "152923": "额济纳旗", "152971": "内蒙古阿拉善经济开发区"},
    "152921": {
        "152921102": "温都尔勒图镇",
        "152921105": "巴润别立镇",
        "152921110": "巴彦浩特镇",
        "152921111": "嘉尔嘎勒赛汉镇",
        "152921112": "吉兰泰镇",
        "152921113": "宗别立镇",
        "152921114": "敖伦布拉格镇",
        "152921115": "腾格里额里斯镇",
        "152921203": "巴彦木仁苏木乡",
        "152921213": "乌力吉苏木乡",
        "152921214": "巴彦诺日公苏木乡",
        "152921215": "额尔克哈什哈苏木乡",
        "152921216": "银根苏木",
        "152921217": "超格图呼热苏木",
        "152921401": "腾格里经济技术开发区",
        "152921402": "巴彦浩特额鲁特街道",
        "152921403": "巴彦浩特南环路街道",
        "152921404": "巴彦浩特新华街道",
        "152921405": "巴彦浩特王府街道"
    },
    "152922": {
        "152922103": "巴丹吉林镇",
        "152922104": "雅布赖镇",
        "152922105": "阿拉腾敖包镇",
        "152922211": "曼德拉苏木",
        "152922212": "阿拉腾朝格苏木",
        "152922213": "巴音高勒苏木",
        "152922214": "塔木素布拉格苏木"
    },
    "152923": {
        "152923001": "额济纳旗航空街道",
        "152923002": "额济纳旗东风街道",
        "152923100": "达来呼布镇",
        "152923101": "额济纳旗东风镇",
        "152923102": "哈日布日格德音乌拉镇",
        "152923200": "额济纳旗赛汉陶来苏木",
        "152923201": "额济纳旗马鬃山苏木",
        "152923202": "额济纳旗苏泊淖尔苏木",
        "152923203": "巴彦陶来苏木",
        "152923204": "温图高勒苏木"
    },
    "152971": {"152971100": "乌斯太镇"},
    "210000": {
        "210100": "沈阳市",
        "210200": "大连市",
        "210300": "鞍山市",
        "210400": "抚顺市",
        "210500": "本溪市",
        "210600": "丹东市",
        "210700": "锦州市",
        "210800": "营口市",
        "210900": "阜新市",
        "211000": "辽阳市",
        "211100": "盘锦市",
        "211200": "铁岭市",
        "211300": "朝阳市",
        "211400": "葫芦岛市"
    },
    "210100": {
        "210102": "和平区",
        "210103": "沈河区",
        "210104": "大东区",
        "210105": "皇姑区",
        "210106": "铁西区",
        "210111": "苏家屯区",
        "210112": "浑南区",
        "210113": "沈北新区",
        "210114": "于洪区",
        "210115": "辽中区",
        "210123": "康平县",
        "210124": "法库县",
        "210181": "新民市"
    },
    "210102": {
        "210102001": "浑河湾街道",
        "210102002": "新华街道",
        "210102007": "西塔街道",
        "210102013": "八经街道",
        "210102016": "马路湾街道",
        "210102017": "集贤街道",
        "210102019": "南湖街道",
        "210102021": "长白街道",
        "210102022": "太原街街道",
        "210102023": "北市场街道",
        "210102025": "南市场街道",
        "210102027": "沈水湾街道",
        "210102028": "浑河站西街道"
    },
    "210103": {
        "210103004": "大西街道",
        "210103005": "滨河街道",
        "210103006": "万莲街道",
        "210103010": "大南街道",
        "210103011": "山东庙街道",
        "210103013": "朱剪炉街道",
        "210103014": "新北站街道",
        "210103015": "风雨坛街道",
        "210103017": "皇城街道",
        "210103018": "五里河街道",
        "210103019": "南塔街道",
        "210103020": "泉园街道",
        "210103021": "丰乐街道",
        "210103022": "马官桥街道",
        "210103023": "东陵街道"
    },
    "210104": {
        "210104002": "大北街道",
        "210104003": "万泉街道",
        "210104004": "洮昌街道",
        "210104006": "文官街道",
        "210104007": "二台子街道",
        "210104008": "津桥街道",
        "210104009": "东站街道",
        "210104010": "北海街道",
        "210104012": "长安街道",
        "210104014": "新东街道",
        "210104015": "东塔街道",
        "210104016": "小东街道",
        "210104017": "上园街道",
        "210104018": "前进街道"
    },
    "210105": {
        "210105001": "三台子街道",
        "210105003": "辽河街道",
        "210105008": "黄河街道",
        "210105012": "三洞桥街道",
        "210105013": "华山街道",
        "210105014": "寿泉街道",
        "210105015": "塔湾街道",
        "210105017": "明廉街道",
        "210105019": "新乐街道",
        "210105020": "舍利塔街道",
        "210105021": "北塔街道",
        "210105022": "陵东街道"
    },
    "210106": {
        "210106001": "昆明湖街道",
        "210106003": "工人村街道",
        "210106005": "七路街道",
        "210106007": "启工街道",
        "210106013": "笃工街道",
        "210106015": "兴华街道",
        "210106018": "兴工街道",
        "210106019": "霁虹街道",
        "210106020": "凌空街道",
        "210106021": "重工街道",
        "210106022": "艳粉街道",
        "210106023": "保工街道",
        "210106024": "兴顺街道",
        "210106025": "贵和街道",
        "210106026": "大青中朝友谊街道",
        "210106027": "翟家街道",
        "210106028": "西三环街道",
        "210106029": "大潘街道",
        "210106030": "彰驿站街道",
        "210106031": "高花街道",
        "210106401": "沈阳市经济技术开发区管理委员会"
    },
    "210111": {
        "210111001": "解放街道",
        "210111003": "民主街道",
        "210111004": "临湖街道",
        "210111005": "中兴街道",
        "210111006": "湖西街道",
        "210111007": "八一街道",
        "210111008": "红菱街道",
        "210111009": "林盛街道",
        "210111010": "沙河街道",
        "210111011": "十里河街道",
        "210111012": "陈相街道",
        "210111013": "姚千街道",
        "210111014": "王纲街道",
        "210111015": "永乐街道",
        "210111016": "大沟街道",
        "210111017": "白清街道",
        "210111018": "佟沟街道"
    },
    "210112": {
        "210112009": "东湖街道",
        "210112010": "五三街道",
        "210112012": "浑河站东街道",
        "210112014": "高坎街道",
        "210112015": "满堂街道",
        "210112016": "深井子街道",
        "210112017": "祝家街道",
        "210112018": "白塔街道",
        "210112019": "桃仙街道",
        "210112020": "李相街道",
        "210112021": "营城子街道",
        "210112022": "汪家街道",
        "210112023": "王滨街道",
        "210112024": "望滨街道",
        "210112025": "永胜街道"
    },
    "210113": {
        "210113001": "新城子街道",
        "210113002": "清水台街道",
        "210113003": "辉山街道",
        "210113004": "道义街道",
        "210113006": "虎石台街道",
        "210113007": "财落街道",
        "210113009": "沈北街道",
        "210113010": "马刚街道",
        "210113011": "石佛寺街道",
        "210113012": "黄家街道",
        "210113013": "尹家街道",
        "210113014": "兴隆台街道",
        "210113015": "清泉街道",
        "210113401": "青年农场",
        "210113402": "前进农场",
        "210113403": "育新农场",
        "210113404": "沈北新区马刚林场",
        "210113405": "沈北新区示范农场",
        "210113406": "沈北新区种畜场"
    },
    "210114": {
        "210114001": "迎宾路街道",
        "210114003": "陵西街道",
        "210114005": "于洪街道",
        "210114007": "北陵街道",
        "210114009": "沙岭街道",
        "210114010": "南阳湖街道",
        "210114011": "城东湖街道",
        "210114012": "平罗街道",
        "210114013": "马三家街道",
        "210114014": "造化街道",
        "210114015": "大兴街道",
        "210114016": "光辉街道",
        "210114401": "马三家强制隔离戒毒所",
        "210114402": "光辉农场",
        "210114404": "监狱城"
    },
    "210115": {
        "210115001": "蒲西街道",
        "210115002": "蒲东街道",
        "210115003": "茨榆坨街道",
        "210115004": "城郊街道",
        "210115101": "于家房镇",
        "210115102": "朱家房镇",
        "210115103": "冷子堡镇",
        "210115104": "刘二堡镇",
        "210115106": "新民屯镇",
        "210115107": "满都户镇",
        "210115108": "杨士岗镇",
        "210115109": "肖寨门镇",
        "210115110": "长滩镇",
        "210115111": "四方台镇",
        "210115113": "六间房镇",
        "210115114": "养士堡镇",
        "210115115": "潘家堡镇",
        "210115117": "老大房镇",
        "210115118": "大黑岗子镇",
        "210115119": "牛心坨镇",
        "210115401": "四方台农场"
    },
    "210123": {
        "210123001": "北三家子街道",
        "210123002": "胜利街道",
        "210123003": "东关街道",
        "210123101": "小城子镇",
        "210123102": "张强镇",
        "210123103": "方家屯镇",
        "210123105": "郝官屯镇",
        "210123106": "二牛所口镇",
        "210123203": "北四家子乡",
        "210123204": "两家子乡",
        "210123206": "海洲窝堡乡",
        "210123208": "沙金台蒙古族满族乡",
        "210123209": "柳树屯蒙古族满族乡",
        "210123210": "西关屯蒙古族满族乡",
        "210123211": "东升满族蒙古族乡"
    },
    "210124": {
        "210124001": "吉祥街道",
        "210124002": "龙山街道",
        "210124101": "大孤家子镇",
        "210124102": "三面船镇",
        "210124103": "秀水河子镇",
        "210124104": "叶茂台镇",
        "210124105": "登仕堡子镇",
        "210124106": "柏家沟镇",
        "210124107": "丁家房镇",
        "210124108": "孟家镇",
        "210124109": "十间房镇",
        "210124110": "冯贝堡镇",
        "210124111": "依牛堡子镇",
        "210124113": "包家屯镇",
        "210124201": "慈恩寺乡",
        "210124203": "和平乡",
        "210124208": "四家子蒙古族乡",
        "210124209": "双台子乡",
        "210124210": "卧牛石乡"
    },
    "210181": {
        "210181001": "东城街道",
        "210181002": "辽滨街道",
        "210181003": "西城街道",
        "210181004": "新柳街道",
        "210181005": "新城街道",
        "210181101": "大红旗镇",
        "210181102": "梁山镇",
        "210181103": "公主屯镇",
        "210181104": "兴隆镇",
        "210181105": "前当堡镇",
        "210181106": "大民屯镇",
        "210181107": "大柳屯镇",
        "210181108": "兴隆堡镇",
        "210181109": "胡台镇",
        "210181110": "法哈牛镇",
        "210181111": "柳河沟镇",
        "210181112": "高台子镇",
        "210181113": "张家屯镇",
        "210181114": "罗家房镇",
        "210181115": "三道岗子镇",
        "210181116": "东蛇山子镇",
        "210181117": "陶家屯镇",
        "210181118": "周坨子镇",
        "210181119": "金五台子镇",
        "210181120": "新农村镇",
        "210181205": "红旗乡",
        "210181206": "卢家屯乡",
        "210181207": "姚堡乡",
        "210181209": "于家窝堡乡"
    },
    "210200": {
        "210202": "中山区",
        "210203": "西岗区",
        "210204": "沙河口区",
        "210211": "甘井子区",
        "210212": "旅顺口区",
        "210213": "金州区",
        "210214": "普兰店区",
        "210224": "长海县",
        "210281": "瓦房店市",
        "210283": "庄河市"
    },
    "210202": {
        "210202001": "海军广场街道",
        "210202003": "桂林街道",
        "210202005": "人民路街道",
        "210202008": "青泥洼桥街道",
        "210202009": "昆明街道",
        "210202010": "葵英街道",
        "210202011": "桃源街道",
        "210202012": "老虎滩街道",
        "210202013": "东港街道"
    },
    "210203": {
        "210203001": "香炉礁街道",
        "210203002": "站北街道",
        "210203004": "日新街道",
        "210203005": "北京街道",
        "210203010": "八一路街道",
        "210203011": "人民广场街道",
        "210203013": "白云街道"
    },
    "210204": {
        "210204002": "中山公园街道",
        "210204004": "白山路街道",
        "210204005": "兴工街道",
        "210204006": "春柳街道",
        "210204008": "马栏街道",
        "210204009": "南沙河口街道",
        "210204010": "黑石礁街道",
        "210204011": "李家街道",
        "210204017": "星海湾街道"
    },
    "210211": {
        "210211001": "周水子街道",
        "210211002": "椒金山街道",
        "210211004": "甘井子街道",
        "210211006": "南关岭街道",
        "210211007": "泡崖街道",
        "210211008": "中华路街道",
        "210211009": "兴华街道",
        "210211010": "机场街道",
        "210211014": "辛寨子街道",
        "210211015": "红旗街道",
        "210211016": "凌水街道",
        "210211017": "大连湾街道",
        "210211018": "泉水街道",
        "210211019": "革镇堡街道",
        "210211020": "营城子街道",
        "210211021": "七贤岭街道",
        "210211400": "大连华侨果树农场"
    },
    "210212": {
        "210212001": "登峰街道",
        "210212002": "市场街道",
        "210212003": "得胜街道",
        "210212004": "光荣街道",
        "210212005": "水师营街道",
        "210212006": "龙王塘街道",
        "210212007": "铁山街道",
        "210212009": "双岛湾街道",
        "210212010": "三涧堡街道",
        "210212011": "长城街道",
        "210212012": "龙头街道",
        "210212013": "北海街道",
        "210212400": "旅顺经济开发区",
        "210212401": "大连奶牛场",
        "210212402": "龙头分园"
    },
    "210213": {
        "210213001": "拥政街道",
        "210213002": "友谊街道",
        "210213003": "光明街道",
        "210213004": "马桥子街道",
        "210213005": "海青岛街道",
        "210213006": "大孤山街道",
        "210213007": "中长街道",
        "210213008": "站前街道",
        "210213009": "先进街道",
        "210213011": "董家沟街道",
        "210213012": "金石滩街道",
        "210213013": "湾里街道",
        "210213014": "二十里堡街道",
        "210213016": "亮甲店街道",
        "210213017": "登沙河街道",
        "210213019": "大魏家街道",
        "210213020": "杏树街道",
        "210213021": "七顶山街道",
        "210213022": "华家街道",
        "210213023": "向应街道",
        "210213024": "大李家街道",
        "210213025": "得胜街道",
        "210213026": "大窑湾街道",
        "210213027": "炮台街道",
        "210213028": "复州湾街道",
        "210213029": "三十里堡街道",
        "210213030": "石河街道"
    },
    "210214": {
        "210214001": "丰荣街道",
        "210214002": "铁西街道",
        "210214003": "太平街道",
        "210214004": "南山街道",
        "210214005": "皮口街道",
        "210214006": "城子坦街道",
        "210214007": "大刘家街道",
        "210214008": "杨树房街道",
        "210214009": "大谭街道",
        "210214010": "唐家房街道",
        "210214011": "莲山街道",
        "210214012": "安波街道",
        "210214013": "沙包街道",
        "210214014": "星台街道",
        "210214015": "墨盘街道",
        "210214016": "同益街道",
        "210214017": "乐甲街道",
        "210214018": "双塔街道",
        "210214019": "四平街道"
    },
    "210224": {
        "210224100": "大长山岛镇",
        "210224101": "獐子岛镇",
        "210224102": "广鹿岛镇",
        "210224103": "小长山岛镇",
        "210224104": "海洋岛镇"
    },
    "210281": {
        "210281001": "新华",
        "210281002": "文兰",
        "210281003": "岭东",
        "210281004": "共济",
        "210281005": "铁东",
        "210281006": "祝华",
        "210281007": "岗店",
        "210281008": "太阳街道",
        "210281009": "长兴岛",
        "210281010": "九龙",
        "210281011": "交流岛街道",
        "210281101": "复州城镇",
        "210281103": "松树镇",
        "210281105": "得利寺镇",
        "210281106": "万家岭镇",
        "210281107": "许屯镇",
        "210281108": "永宁镇",
        "210281109": "谢屯镇",
        "210281112": "老虎屯镇",
        "210281113": "红沿河镇",
        "210281115": "李官镇",
        "210281116": "仙浴湾镇",
        "210281117": "元台镇",
        "210281118": "瓦窝镇",
        "210281201": "赵屯乡",
        "210281203": "土城乡",
        "210281204": "阎店乡",
        "210281205": "西杨乡",
        "210281206": "驼山乡",
        "210281208": "三台满族乡",
        "210281210": "泡崖乡",
        "210281212": "杨家满族乡"
    },
    "210283": {
        "210283001": "城关街道",
        "210283002": "新华街道",
        "210283003": "兴达街道",
        "210283004": "昌盛街道",
        "210283005": "明阳街道",
        "210283101": "青堆镇",
        "210283102": "徐岭镇",
        "210283104": "黑岛镇",
        "210283105": "栗子房镇",
        "210283107": "大营镇",
        "210283108": "塔岭镇",
        "210283109": "仙人洞镇",
        "210283110": "蓉花山镇",
        "210283111": "长岭镇",
        "210283112": "荷花山镇",
        "210283113": "城山镇",
        "210283114": "光明山镇",
        "210283115": "大郑镇",
        "210283118": "吴炉镇",
        "210283120": "王家镇",
        "210283204": "鞍子山乡",
        "210283208": "太平岭满族乡",
        "210283209": "步云山乡",
        "210283211": "桂云花满族乡",
        "210283214": "兰店乡",
        "210283216": "石城乡"
    },
    "210300": {
        "210302": "铁东区",
        "210303": "铁西区",
        "210304": "立山区",
        "210311": "千山区",
        "210321": "台安县",
        "210323": "岫岩满族自治县",
        "210381": "海城市"
    },
    "210302": {
        "210302001": "长甸街道",
        "210302002": "解放街道",
        "210302003": "山南街道",
        "210302004": "园林街道",
        "210302005": "胜利街道",
        "210302006": "站前街道",
        "210302007": "钢城街道",
        "210302008": "和平街道",
        "210302009": "对炉街道",
        "210302010": "东长甸街道",
        "210302011": "湖南街道",
        "210302012": "常青街道",
        "210302013": "新兴街道",
        "210302014": "旧堡街道",
        "210302015": "大孤山街道",
        "210302016": "温泉街道",
        "210302400": "玉佛山风景区管理委员会"
    },
    "210303": {
        "210303001": "启明街道",
        "210303002": "繁荣街道",
        "210303003": "八家子街道",
        "210303004": "兴盛街道",
        "210303005": "共和街道",
        "210303006": "永乐街道",
        "210303007": "北陶官街道",
        "210303008": "南华街道",
        "210303009": "大陆街道",
        "210303010": "新陶官街道",
        "210303011": "新城街道",
        "210303012": "永发街道",
        "210303013": "宁远街道",
        "210303014": "达道湾街道"
    },
    "210304": {
        "210304001": "友好街道",
        "210304004": "双山街道",
        "210304006": "立山街道",
        "210304008": "曙光街道",
        "210304009": "灵山街道",
        "210304013": "深南街道",
        "210304014": "深北街道",
        "210304015": "沙河街道",
        "210304016": "滨河街道",
        "210304017": "千山街道",
        "210304019": "齐大山街道",
        "210304020": "汪峪街道",
        "210304021": "红岭街道"
    },
    "210311": {
        "210311005": "对桩石街道",
        "210311006": "汤岗子街道",
        "210311007": "东鞍山街道",
        "210311102": "唐家房镇",
        "210311103": "大屯镇",
        "210311104": "甘泉镇"
    },
    "210321": {
        "210321001": "八角台街道",
        "210321002": "台东街道",
        "210321003": "台南街道",
        "210321004": "台北街道",
        "210321101": "西佛镇",
        "210321102": "新开河镇",
        "210321103": "黄沙坨镇",
        "210321104": "高力房镇",
        "210321105": "桑林镇",
        "210321106": "富家镇",
        "210321107": "达牛镇",
        "210321109": "台安县韭菜台镇",
        "210321110": "新台镇",
        "210321111": "桓洞镇",
        "210321400": "新华农场",
        "210321401": "洪家农牧场"
    },
    "210323": {
        "210323001": "阜昌街道",
        "210323002": "大宁街道",
        "210323003": "兴隆街道",
        "210323004": "雅河街道",
        "210323005": "仙人咀街道",
        "210323101": "三家子镇",
        "210323102": "石庙子镇",
        "210323103": "黄花甸镇",
        "210323104": "大营子镇",
        "210323105": "苏子沟镇",
        "210323106": "偏岭镇",
        "210323107": "哈达碑镇",
        "210323108": "新甸镇",
        "210323109": "洋河镇",
        "210323111": "杨家堡镇",
        "210323112": "清凉山镇",
        "210323113": "石灰窑镇",
        "210323114": "前营子镇",
        "210323115": "龙潭镇",
        "210323116": "牧牛镇",
        "210323117": "药山镇",
        "210323118": "大房身镇",
        "210323119": "朝阳镇",
        "210323206": "红旗营子乡",
        "210323211": "岭沟乡",
        "210323212": "哨子河乡"
    },
    "210381": {
        "210381001": "海州街道",
        "210381002": "兴海街道",
        "210381003": "响堂街道",
        "210381004": "东四街道",
        "210381005": "验军街道",
        "210381006": "温泉街道",
        "210381101": "孤山镇",
        "210381102": "岔沟镇",
        "210381103": "接文镇",
        "210381104": "析木镇",
        "210381105": "马风镇",
        "210381107": "牌楼镇",
        "210381108": "八里镇",
        "210381109": "毛祁镇",
        "210381110": "英落镇",
        "210381111": "感王镇",
        "210381112": "西柳镇",
        "210381113": "中小镇",
        "210381114": "王石镇",
        "210381115": "南台镇",
        "210381118": "腾鳌镇",
        "210381121": "耿庄镇",
        "210381123": "牛庄镇",
        "210381124": "西四镇",
        "210381125": "望台镇",
        "210381126": "温香镇",
        "210381127": "高坨镇"
    },
    "210400": {
        "210402": "新抚区",
        "210403": "东洲区",
        "210404": "望花区",
        "210411": "顺城区",
        "210421": "抚顺县",
        "210422": "新宾满族自治县",
        "210423": "清原满族自治县"
    },
    "210402": {
        "210402001": "站前街道",
        "210402003": "东公园街道",
        "210402005": "福民街道",
        "210402007": "新抚街道",
        "210402009": "榆林街道",
        "210402011": "永安台街道",
        "210402013": "千金街道",
        "210402015": "南阳街道",
        "210402017": "南花园街道",
        "210402019": "刘山街道",
        "210402201": "千金乡"
    },
    "210403": {
        "210403002": "东洲街道",
        "210403003": "张甸街道",
        "210403004": "搭连街道",
        "210403005": "龙凤街道",
        "210403006": "新屯街道",
        "210403007": "万新街道",
        "210403008": "老虎台街道",
        "210403009": "平山街道",
        "210403012": "章党街道",
        "210403103": "章党镇",
        "210403104": "哈达镇",
        "210403202": "碾盘乡",
        "210403215": "兰山乡"
    },
    "210404": {
        "210404001": "田屯街道",
        "210404002": "工农街道",
        "210404003": "建设街道",
        "210404004": "和平街道",
        "210404005": "光明街道",
        "210404006": "朴屯街道",
        "210404007": "演武街道",
        "210404008": "五老屯街道",
        "210404009": "古城子街道",
        "210404010": "新民街道",
        "210404011": "李石街道",
        "210404101": "塔峪镇",
        "210404201": "拉古满族乡"
    },
    "210411": {
        "210411001": "河东街道",
        "210411002": "长春街道",
        "210411003": "葛布街道",
        "210411004": "将军堡街道",
        "210411005": "新华街道",
        "210411007": "抚顺城街道",
        "210411102": "前甸镇",
        "210411201": "河北乡",
        "210411205": "会元乡"
    },
    "210421": {
        "210421101": "石文镇",
        "210421102": "后安镇",
        "210421105": "上马镇",
        "210421106": "救兵镇",
        "210421204": "马圈子乡",
        "210421209": "峡河乡",
        "210421211": "海浪乡",
        "210421216": "汤图满族乡"
    },
    "210422": {
        "210422100": "新宾镇",
        "210422101": "旺清门镇",
        "210422102": "永陵镇",
        "210422103": "平顶山镇",
        "210422104": "大四平镇",
        "210422105": "苇子峪镇",
        "210422106": "木奇镇",
        "210422108": "上夹河镇",
        "210422109": "南杂木镇",
        "210422202": "红升乡",
        "210422203": "响水河子乡",
        "210422204": "红庙子乡",
        "210422205": "北四平乡",
        "210422207": "榆树乡",
        "210422208": "下夹河乡"
    },
    "210423": {
        "210423100": "清原镇",
        "210423101": "红透山镇",
        "210423103": "草市镇",
        "210423104": "英额门镇",
        "210423105": "南口前镇",
        "210423106": "南山城镇",
        "210423107": "湾甸子镇",
        "210423108": "大孤家镇",
        "210423109": "夏家堡镇",
        "210423110": "北三家镇",
        "210423203": "土口子乡",
        "210423206": "敖家堡乡",
        "210423207": "大苏河乡",
        "210423208": "枸乃甸乡"
    },
    "210500": {
        "210502": "平山区",
        "210503": "溪湖区",
        "210504": "明山区",
        "210505": "南芬区",
        "210521": "本溪满族自治县",
        "210522": "桓仁满族自治县"
    },
    "210502": {
        "210502001": "南地街道",
        "210502002": "工人街道",
        "210502003": "平山街道",
        "210502004": "东明街道",
        "210502005": "崔东街道",
        "210502007": "站前街道",
        "210502008": "千金街道",
        "210502010": "北台街道",
        "210502011": "桥头街道"
    },
    "210503": {
        "210503001": "河东街道",
        "210503003": "河西街道",
        "210503005": "彩屯街道",
        "210503006": "竖井街道",
        "210503007": "彩北街道",
        "210503009": "东风街道",
        "210503010": "石桥子街道",
        "210503011": "日月岛街道",
        "210503012": "张其寨街道",
        "210503013": "火连寨街道"
    },
    "210504": {
        "210504001": "金山街道",
        "210504002": "北地街道",
        "210504004": "高峪街道",
        "210504005": "明山街道",
        "210504007": "东兴街道",
        "210504008": "新明街道",
        "210504009": "牛心台街道",
        "210504010": "卧龙街道",
        "210504011": "高台子街道"
    },
    "210505": {
        "210505001": "南芬街道",
        "210505002": "铁山街道",
        "210505003": "郭家街道",
        "210505004": "思山岭街道",
        "210505005": "下马塘街道"
    },
    "210521": {
        "210521001": "观音阁街道",
        "210521100": "小市镇",
        "210521101": "草河掌镇",
        "210521102": "草河城镇",
        "210521103": "草河口镇",
        "210521104": "连山关镇",
        "210521106": "清河城镇",
        "210521109": "田师傅镇",
        "210521110": "南甸镇",
        "210521111": "碱厂镇",
        "210521112": "高官镇",
        "210521202": "东营房乡"
    },
    "210522": {
        "210522001": "八卦城街道",
        "210522100": "桓仁镇",
        "210522101": "普乐堡镇",
        "210522102": "二棚甸子镇",
        "210522103": "沙尖子镇",
        "210522104": "五里甸子镇",
        "210522108": "八里甸子镇",
        "210522110": "华来镇",
        "210522111": "古城镇",
        "210522201": "雅河朝鲜族乡",
        "210522202": "向阳乡",
        "210522206": "黑沟乡",
        "210522208": "北甸子乡"
    },
    "210600": {
        "210602": "元宝区",
        "210603": "振兴区",
        "210604": "振安区",
        "210624": "宽甸满族自治县",
        "210681": "东港市",
        "210682": "凤城市"
    },
    "210602": {
        "210602001": "六道口街道",
        "210602002": "七道街道",
        "210602003": "八道街道",
        "210602004": "九道街道",
        "210602005": "广济街道",
        "210602006": "兴东街道",
        "210602101": "金山镇"
    },
    "210603": {
        "210603001": "头道桥街道",
        "210603002": "站前街道",
        "210603003": "临江街道",
        "210603004": "六道沟街道",
        "210603005": "帽盔山街道",
        "210603006": "纤维街道",
        "210603007": "永昌街道",
        "210603008": "花园街道",
        "210603009": "江海街道",
        "210603010": "西城街道",
        "210603101": "浪头镇",
        "210603102": "安民镇",
        "210603103": "汤池镇"
    },
    "210604": {
        "210604001": "鸭绿江街道",
        "210604003": "金矿街道",
        "210604004": "珍珠街道",
        "210604005": "太平湾街道",
        "210604102": "同兴镇",
        "210604104": "五龙背镇",
        "210604105": "楼房镇",
        "210604106": "九连城镇",
        "210604107": "汤山城镇"
    },
    "210624": {
        "210624100": "宽甸镇",
        "210624101": "灌水镇",
        "210624102": "硼海镇",
        "210624103": "红石镇",
        "210624104": "毛甸子镇",
        "210624105": "长甸镇",
        "210624106": "永甸镇",
        "210624108": "太平哨镇",
        "210624109": "青山沟镇",
        "210624110": "牛毛坞镇",
        "210624111": "大川头镇",
        "210624112": "青椅山镇",
        "210624113": "杨木川镇",
        "210624114": "虎山镇",
        "210624115": "振江镇",
        "210624116": "步达远镇",
        "210624117": "大西岔镇",
        "210624118": "八河川镇",
        "210624119": "双山子镇",
        "210624201": "石湖沟乡",
        "210624206": "古楼子乡",
        "210624212": "下露河朝鲜族乡"
    },
    "210681": {
        "210681001": "大东街道",
        "210681002": "新兴街道",
        "210681003": "新城街道",
        "210681101": "孤山镇",
        "210681104": "前阳镇",
        "210681105": "长安镇",
        "210681106": "十字街镇",
        "210681107": "长山镇",
        "210681108": "北井子镇",
        "210681109": "椅圈镇",
        "210681110": "黄土坎镇",
        "210681113": "马家店镇",
        "210681114": "龙王庙镇",
        "210681115": "小甸子镇",
        "210681117": "菩萨庙镇",
        "210681118": "黑沟镇",
        "210681119": "新农镇",
        "210681206": "合隆满族乡",
        "210681401": "示范农场",
        "210681402": "黄土坎农场",
        "210681403": "海洋红农场",
        "210681404": "兴隆农场",
        "210681405": "五四农场"
    },
    "210682": {
        "210682001": "凤凰城街道",
        "210682002": "凤山街道",
        "210682003": "草河街道",
        "210682101": "宝山镇",
        "210682102": "白旗镇",
        "210682103": "沙里寨镇",
        "210682104": "红旗镇",
        "210682105": "蓝旗镇",
        "210682107": "边门镇",
        "210682110": "东汤镇",
        "210682111": "石城镇",
        "210682112": "大兴镇",
        "210682113": "爱阳镇",
        "210682114": "赛马镇",
        "210682115": "弟兄山镇",
        "210682116": "鸡冠山镇",
        "210682117": "刘家河镇",
        "210682118": "通远堡镇",
        "210682119": "四门子镇",
        "210682120": "青城子镇",
        "210682200": "大堡蒙古族乡"
    },
    "210700": {
        "210702": "古塔区",
        "210703": "凌河区",
        "210711": "太和区",
        "210726": "黑山县",
        "210727": "义县",
        "210781": "凌海市",
        "210782": "北镇市"
    },
    "210702": {
        "210702001": "天安街道",
        "210702002": "石油街道",
        "210702003": "北街街道",
        "210702004": "敬业街道",
        "210702005": "保安街道",
        "210702006": "南街街道",
        "210702007": "饶阳街道",
        "210702008": "站前街道",
        "210702009": "士英街道",
        "210702010": "钟屯街道"
    },
    "210703": {
        "210703001": "正大街道",
        "210703002": "石桥子街道",
        "210703003": "龙江街道",
        "210703004": "榴花街道",
        "210703005": "铁新街道",
        "210703006": "凌安街道",
        "210703007": "菊园街道",
        "210703008": "康宁街道",
        "210703009": "锦铁街道",
        "210703010": "马家洼子街道",
        "210703011": "紫荆街道",
        "210703403": "锦州北山农工商总公司"
    },
    "210711": {
        "210711001": "太和街道",
        "210711002": "兴隆街道",
        "210711003": "汤河子街道",
        "210711004": "凌西街道",
        "210711007": "大薛街道",
        "210711008": "女儿河街道",
        "210711009": "营盘街道",
        "210711010": "新民街道",
        "210711070": "王家街道",
        "210711071": "天桥街道",
        "210711072": "杏山街道",
        "210711073": "娘娘宫街道",
        "210711074": "龙栖湾街道",
        "210711080": "凌南街道",
        "210711081": "松山街道",
        "210711480": "锦州市果树农场"
    },
    "210726": {
        "210726001": "黑山街道",
        "210726002": "大虎山街道",
        "210726101": "芳山镇",
        "210726102": "白厂门镇",
        "210726103": "常兴镇",
        "210726104": "姜屯镇",
        "210726105": "励家镇",
        "210726106": "绕阳河镇",
        "210726107": "半拉门镇",
        "210726108": "无梁殿镇",
        "210726109": "胡家镇",
        "210726110": "新立屯镇",
        "210726111": "八道壕镇",
        "210726113": "四家子镇",
        "210726114": "新兴镇",
        "210726116": "太和镇",
        "210726117": "镇安镇",
        "210726201": "英城子乡",
        "210726209": "段家乡",
        "210726210": "大兴乡",
        "210726212": "薛屯乡",
        "210726400": "辽宁省锦州市小东种畜场"
    },
    "210727": {
        "210727001": "义州街道",
        "210727002": "城关街道",
        "210727101": "刘龙台镇",
        "210727102": "七里河镇",
        "210727103": "大榆树堡镇",
        "210727104": "稍户营子镇",
        "210727105": "九道岭镇",
        "210727106": "高台子镇",
        "210727108": "瓦子峪镇",
        "210727109": "头台镇",
        "210727110": "前杨镇",
        "210727111": "张家堡镇",
        "210727112": "头道河镇",
        "210727113": "留龙沟镇",
        "210727114": "聚粮屯镇",
        "210727203": "地藏寺满族乡",
        "210727205": "大定堡满族乡",
        "210727207": "白庙子乡"
    },
    "210781": {
        "210781001": "大凌河街道",
        "210781002": "金城街道",
        "210781003": "八千街道",
        "210781004": "大有街道",
        "210781101": "石山镇",
        "210781102": "余积镇",
        "210781103": "双羊镇",
        "210781104": "班吉塔镇",
        "210781105": "沈家台镇",
        "210781106": "三台子镇",
        "210781107": "右卫满族镇",
        "210781108": "阎家镇",
        "210781109": "新庄子镇",
        "210781112": "翠岩镇",
        "210781113": "安屯镇",
        "210781114": "大业镇",
        "210781116": "建业镇",
        "210781117": "温滴楼满族镇",
        "210781118": "白台子镇",
        "210781212": "板石沟乡",
        "210781217": "谢屯乡",
        "210781401": "辽宁省金城原种场"
    },
    "210782": {
        "210782004": "北镇街道",
        "210782006": "富屯街道",
        "210782007": "广宁街道",
        "210782008": "沟帮子街道",
        "210782009": "沙子河街道",
        "210782101": "大市镇",
        "210782102": "罗罗堡镇",
        "210782103": "常兴店镇",
        "210782104": "正安镇",
        "210782105": "闾阳镇",
        "210782106": "中安镇",
        "210782107": "廖屯镇",
        "210782109": "赵屯镇",
        "210782110": "青堆子镇",
        "210782111": "高山子镇",
        "210782113": "吴家镇",
        "210782203": "鲍家乡",
        "210782206": "大屯乡",
        "210782209": "柳家乡",
        "210782402": "辽宁省北镇市高山子监狱",
        "210782403": "新立农场"
    },
    "210800": {"210802": "站前区", "210803": "西市区", "210804": "鲅鱼圈区", "210811": "老边区", "210881": "盖州市", "210882": "大石桥市"},
    "210802": {
        "210802001": "八田地街道",
        "210802002": "建丰街道",
        "210802003": "建设街道",
        "210802004": "跃进街道",
        "210802005": "东风街道",
        "210802006": "新兴街道",
        "210802007": "新建街道",
        "210802401": "营口高新区站前区工业园"
    },
    "210803": {
        "210803001": "胜利街道",
        "210803002": "清华街道",
        "210803003": "滨海街道",
        "210803004": "得胜街道",
        "210803005": "五台子街道",
        "210803006": "渔市街道",
        "210803007": "河北街道",
        "210803008": "沿海街道",
        "210803400": "辽宁自贸区营口片区",
        "210803401": "辽宁（营口）沿海产业基地"
    },
    "210804": {
        "210804001": "红海街道",
        "210804002": "海星街道",
        "210804003": "望海街道",
        "210804004": "海东街道",
        "210804100": "熊岳镇",
        "210804101": "芦屯镇",
        "210804102": "红旗满族镇"
    },
    "210811": {
        "210811001": "老边街道",
        "210811002": "城东街道",
        "210811101": "路南镇",
        "210811102": "柳树镇",
        "210811103": "边城镇",
        "210811400": "营口辽河经济开发区"
    },
    "210881": {
        "210881001": "鼓楼街道",
        "210881002": "西城街道",
        "210881003": "东城街道",
        "210881004": "太阳升街道",
        "210881005": "团山街道",
        "210881006": "西海街道",
        "210881007": "九垄地街道",
        "210881008": "归州街道",
        "210881102": "高屯镇",
        "210881105": "沙岗镇",
        "210881109": "九寨镇",
        "210881110": "万福镇",
        "210881111": "卧龙泉镇",
        "210881112": "青石岭镇",
        "210881113": "暖泉镇",
        "210881116": "榜式堡镇",
        "210881117": "团甸镇",
        "210881118": "双台镇",
        "210881119": "杨运镇",
        "210881120": "徐屯镇",
        "210881121": "什字街镇",
        "210881122": "矿洞沟镇",
        "210881123": "陈屯镇",
        "210881124": "梁屯镇",
        "210881216": "小石棚乡",
        "210881217": "果园乡",
        "210881218": "二台乡"
    },
    "210882": {
        "210882001": "石桥街道",
        "210882002": "青花街道",
        "210882003": "金桥街道",
        "210882004": "钢都街道",
        "210882005": "百寨街道",
        "210882101": "水源镇",
        "210882102": "沟沿镇",
        "210882103": "石佛镇",
        "210882104": "高坎镇",
        "210882105": "旗口镇",
        "210882106": "虎庄镇",
        "210882107": "官屯镇",
        "210882112": "博洛铺镇",
        "210882113": "永安镇",
        "210882114": "汤池镇",
        "210882115": "建一镇",
        "210882116": "黄土岭镇",
        "210882117": "周家镇",
        "210882400": "大石桥市水稻良种场",
        "210882401": "大石桥市示范场"
    },
    "210900": {
        "210902": "海州区",
        "210903": "新邱区",
        "210904": "太平区",
        "210905": "清河门区",
        "210911": "细河区",
        "210921": "阜新蒙古族自治县",
        "210922": "彰武县"
    },
    "210902": {
        "210902002": "新兴街道",
        "210902003": "和平街道",
        "210902004": "西山街道",
        "210902005": "河北街道",
        "210902006": "站前街道",
        "210902007": "西阜新街道",
        "210902008": "五龙街道",
        "210902009": "平安西部街道",
        "210902010": "工人村街道",
        "210902011": "东梁街道",
        "210902101": "韩家店镇"
    },
    "210903": {
        "210903001": "兴隆街道",
        "210903002": "中兴街道",
        "210903003": "益民街道",
        "210903004": "新发街道",
        "210903101": "长营子蒙古族镇"
    },
    "210904": {
        "210904001": "红树街道",
        "210904002": "煤海街道",
        "210904003": "高德街道",
        "210904004": "孙家湾街道",
        "210904005": "城南街道",
        "210904101": "水泉镇"
    },
    "210905": {
        "210905001": "清河街道",
        "210905002": "六台街道",
        "210905003": "艾友街道",
        "210905004": "新北街道",
        "210905101": "河西镇",
        "210905102": "乌龙坝镇"
    },
    "210911": {
        "210911001": "西苑街道",
        "210911002": "北苑街道",
        "210911003": "东苑街道",
        "210911004": "学苑街道",
        "210911005": "中苑街道",
        "210911006": "华东街道",
        "210911102": "四合镇",
        "210911401": "六家子管委会"
    },
    "210921": {
        "210921001": "城区街道",
        "210921100": "阜新镇",
        "210921102": "东梁镇",
        "210921103": "佛寺镇",
        "210921104": "伊吗图镇",
        "210921105": "旧庙镇",
        "210921106": "务欢池镇",
        "210921107": "建设镇",
        "210921108": "大巴镇",
        "210921109": "泡子镇",
        "210921110": "十家子镇",
        "210921111": "王府镇",
        "210921112": "于寺镇",
        "210921113": "富荣镇",
        "210921114": "新民镇",
        "210921115": "福兴地镇",
        "210921116": "平安地镇",
        "210921117": "沙拉镇",
        "210921118": "大固本镇",
        "210921119": "大五家子镇",
        "210921120": "大板镇",
        "210921121": "招束沟镇",
        "210921122": "八家子镇",
        "210921123": "蜘蛛山镇",
        "210921124": "塔营子镇",
        "210921125": "扎兰营子镇",
        "210921126": "七家子镇",
        "210921127": "红帽子镇",
        "210921128": "紫都台镇",
        "210921129": "化石戈镇",
        "210921130": "哈达户稍镇",
        "210921131": "老河土镇",
        "210921132": "太平镇",
        "210921203": "卧凤沟乡",
        "210921211": "苍土乡",
        "210921222": "国华乡",
        "210921401": "泡子农场"
    },
    "210922": {
        "210922100": "彰武镇",
        "210922101": "哈尔套镇",
        "210922102": "章古台镇",
        "210922103": "五峰镇",
        "210922104": "冯家镇",
        "210922105": "后新秋镇",
        "210922106": "东六家子镇",
        "210922107": "阿尔乡镇",
        "210922108": "前福兴地镇",
        "210922109": "双庙镇",
        "210922110": "大四家子镇",
        "210922111": "苇子沟镇",
        "210922112": "兴隆山镇",
        "210922113": "满堂红镇",
        "210922114": "四合城镇",
        "210922115": "大冷蒙古族镇",
        "210922116": "两家子镇",
        "210922117": "平安镇",
        "210922118": "四堡子镇",
        "210922119": "西六家子镇",
        "210922120": "大德镇",
        "210922121": "兴隆堡镇",
        "210922202": "二道河子蒙古族乡",
        "210922209": "丰田乡"
    },
    "211000": {
        "211002": "白塔区",
        "211003": "文圣区",
        "211004": "宏伟区",
        "211005": "弓长岭区",
        "211011": "太子河区",
        "211021": "辽阳县",
        "211081": "灯塔市"
    },
    "211002": {
        "211002001": "星火街道",
        "211002002": "胜利街道",
        "211002003": "跃进街道",
        "211002005": "卫国路街道",
        "211002006": "站前街道",
        "211002010": "武圣街道",
        "211002011": "襄平街道",
        "211002012": "文圣街道",
        "211002013": "南门街道",
        "211002014": "东兴街道",
        "211002015": "新华街道"
    },
    "211003": {"211003004": "庆阳街道", "211003008": "东京陵街道", "211003009": "新城街道", "211003101": "小屯镇", "211003102": "罗大台镇"},
    "211004": {
        "211004001": "工农街道",
        "211004002": "光华街道",
        "211004003": "新村街道",
        "211004004": "长征街道",
        "211004101": "曙光镇",
        "211004102": "兰家镇"
    },
    "211005": {"211005001": "苏家街道", "211005002": "团山街道", "211005003": "安平街道", "211005101": "汤河镇", "211005201": "安平乡"},
    "211011": {
        "211011002": "望水台街道",
        "211011003": "铁西街道",
        "211011101": "祁家镇",
        "211011102": "沙岭镇",
        "211011103": "王家镇",
        "211011204": "东宁卫乡"
    },
    "211021": {
        "211021100": "首山镇",
        "211021101": "刘二堡镇",
        "211021103": "小北河镇",
        "211021104": "黄泥洼镇",
        "211021106": "唐马寨镇",
        "211021107": "穆家镇",
        "211021108": "柳壕镇",
        "211021110": "河栏镇",
        "211021111": "隆昌镇",
        "211021112": "八会镇",
        "211021113": "寒岭镇",
        "211021114": "兴隆镇",
        "211021201": "下达河乡",
        "211021202": "吉洞峪满族乡",
        "211021206": "甜水满族乡"
    },
    "211081": {
        "211081001": "烟台街道",
        "211081002": "万宝桥街道",
        "211081003": "古城子街道",
        "211081101": "佟二堡镇",
        "211081102": "铧子镇",
        "211081103": "张台子镇",
        "211081104": "西大窑镇",
        "211081105": "沈旦堡镇",
        "211081106": "西马峰镇",
        "211081107": "柳条寨镇",
        "211081108": "柳河子镇",
        "211081111": "大河南镇",
        "211081114": "五星镇",
        "211081205": "鸡冠山乡"
    },
    "211100": {"211102": "双台子区", "211103": "兴隆台区", "211104": "大洼区", "211122": "盘山县"},
    "211102": {
        "211102002": "胜利街道",
        "211102003": "建设街道",
        "211102004": "红旗街道",
        "211102005": "辽河街道",
        "211102008": "铁东街道",
        "211102009": "双盛街道",
        "211102100": "统一镇",
        "211102101": "陆家镇"
    },
    "211103": {
        "211103001": "振兴街道",
        "211103002": "兴隆街道",
        "211103003": "渤海街道",
        "211103004": "新工街道",
        "211103006": "友谊街道",
        "211103007": "曙光街道",
        "211103008": "欢喜街道",
        "211103009": "平安街道",
        "211103010": "新生街道",
        "211103012": "高升街道",
        "211103013": "沈采街道",
        "211103014": "锦采街道",
        "211103015": "茨采街道",
        "211103016": "创新街道",
        "211103017": "兴盛街道",
        "211103018": "兴海街道",
        "211103019": "惠宾街道"
    },
    "211104": {
        "211104001": "荣滨街道",
        "211104002": "二界沟街道",
        "211104003": "荣兴街道",
        "211104004": "大洼街道",
        "211104005": "田家街道",
        "211104006": "榆树街道",
        "211104007": "王家街道",
        "211104009": "于楼街道",
        "211104101": "田庄台镇",
        "211104103": "东风镇",
        "211104104": "新开镇",
        "211104106": "清水镇",
        "211104107": "新兴镇",
        "211104108": "西安镇",
        "211104109": "新立镇",
        "211104112": "唐家镇",
        "211104113": "平安镇",
        "211104114": "赵圈河镇"
    },
    "211122": {
        "211122001": "太平街道",
        "211122002": "高升街道",
        "211122003": "得胜街道",
        "211122101": "沙岭镇",
        "211122103": "胡家镇",
        "211122104": "石新镇",
        "211122105": "东郭镇",
        "211122106": "羊圈子镇",
        "211122107": "古城子镇",
        "211122108": "坝墙子镇",
        "211122110": "陈家镇",
        "211122111": "甜水镇",
        "211122112": "吴家镇",
        "211122400": "盘山县林场"
    },
    "211200": {
        "211202": "银州区",
        "211204": "清河区",
        "211221": "铁岭县",
        "211223": "西丰县",
        "211224": "昌图县",
        "211281": "调兵山市",
        "211282": "开原市"
    },
    "211202": {
        "211202001": "红旗街道",
        "211202002": "工人街道",
        "211202003": "铁西街道",
        "211202004": "铜钟街道",
        "211202005": "柴河街道",
        "211202006": "岭东街道",
        "211202007": "辽海街道",
        "211202201": "龙山乡",
        "211202401": "铁岭经济开发区"
    },
    "211204": {
        "211204001": "红旗街道",
        "211204002": "向阳街道",
        "211204101": "张相镇",
        "211204102": "杨木林子镇",
        "211204203": "聂家满族乡"
    },
    "211221": {
        "211221101": "新台子镇",
        "211221102": "阿吉镇",
        "211221103": "平顶堡镇",
        "211221104": "大甸子镇",
        "211221105": "凡河镇",
        "211221106": "腰堡镇",
        "211221107": "镇西堡镇",
        "211221108": "蔡牛镇",
        "211221109": "李千户镇",
        "211221110": "熊官屯镇",
        "211221111": "横道河子镇",
        "211221112": "双井子镇",
        "211221208": "鸡冠山乡",
        "211221209": "白旗寨满族乡",
        "211221401": "种畜场"
    },
    "211223": {
        "211223100": "西丰镇",
        "211223101": "平岗镇",
        "211223102": "郜家店镇",
        "211223103": "凉泉镇",
        "211223104": "振兴镇",
        "211223105": "安民镇",
        "211223106": "天德镇",
        "211223107": "房木镇",
        "211223108": "柏榆镇",
        "211223109": "陶然镇",
        "211223110": "钓鱼镇",
        "211223111": "更刻镇",
        "211223204": "德兴满族乡",
        "211223206": "明德满族乡",
        "211223209": "成平满族乡",
        "211223211": "和隆满族乡",
        "211223212": "营厂满族乡",
        "211223213": "金星满族乡"
    },
    "211224": {
        "211224100": "昌图镇",
        "211224101": "老城镇",
        "211224102": "八面城镇",
        "211224103": "三江口镇",
        "211224104": "金家镇",
        "211224105": "宝力镇",
        "211224106": "泉头镇",
        "211224107": "双庙子镇",
        "211224108": "亮中桥镇",
        "211224109": "马仲河镇",
        "211224110": "毛家店镇",
        "211224111": "老四平镇",
        "211224112": "大洼镇",
        "211224113": "头道镇",
        "211224114": "鴜鷺树镇",
        "211224115": "傅家镇",
        "211224117": "四合镇",
        "211224118": "朝阳镇",
        "211224119": "古榆树镇",
        "211224120": "七家子镇",
        "211224121": "东嘎镇",
        "211224122": "四面城镇",
        "211224123": "前双井镇",
        "211224124": "通江口镇",
        "211224125": "大四家子镇",
        "211224126": "曲家店镇",
        "211224127": "十八家子镇",
        "211224128": "太平镇",
        "211224129": "下二台镇",
        "211224130": "平安堡镇",
        "211224131": "大兴镇",
        "211224132": "后窑镇",
        "211224133": "长发镇",
        "211224401": "三江口农场",
        "211224402": "宝力农场",
        "211224403": "新乡农场",
        "211224404": "付家林场",
        "211224405": "牤牛马场",
        "211224406": "两家子农场"
    },
    "211281": {"211281001": "兀术街街道", "211281002": "调兵山街道", "211281102": "晓明镇", "211281103": "大明镇", "211281105": "晓南镇"},
    "211282": {
        "211282001": "新城街道",
        "211282002": "老城街道",
        "211282003": "兴开街道",
        "211282102": "威远堡镇",
        "211282103": "庆云堡镇",
        "211282104": "中固镇",
        "211282105": "八棵树镇",
        "211282106": "金沟子镇",
        "211282107": "八宝镇",
        "211282108": "业民镇",
        "211282109": "莲花镇",
        "211282110": "靠山镇",
        "211282111": "马家寨镇",
        "211282112": "下肥镇",
        "211282113": "松山镇",
        "211282114": "城东镇",
        "211282115": "李家台镇",
        "211282116": "上肥地满族镇",
        "211282117": "黄旗寨满族镇",
        "211282217": "林丰满族乡"
    },
    "211300": {
        "211302": "双塔区",
        "211303": "龙城区",
        "211321": "朝阳县",
        "211322": "建平县",
        "211324": "喀喇沁左翼蒙古族自治县",
        "211381": "北票市",
        "211382": "凌源市"
    },
    "211302": {
        "211302001": "南塔街道",
        "211302002": "北塔街道",
        "211302003": "前进街道",
        "211302004": "凌河街道",
        "211302005": "光明街道",
        "211302006": "凌凤街道",
        "211302007": "龙山街道",
        "211302008": "站南街道",
        "211302009": "红旗街道",
        "211302010": "燕北街道",
        "211302011": "燕都街道",
        "211302100": "桃花吐镇",
        "211302101": "他拉皋镇",
        "211302102": "孙家湾镇",
        "211302205": "长宝营子乡"
    },
    "211303": {
        "211303001": "向阳街道",
        "211303002": "燕山街道",
        "211303003": "马山街道",
        "211303004": "新华街道",
        "211303005": "海龙街道",
        "211303070": "开发区龙泉街道",
        "211303101": "七道泉子镇",
        "211303102": "西大营子镇",
        "211303103": "召都巴镇",
        "211303105": "大平房镇",
        "211303106": "联合镇",
        "211303107": "边杖子镇"
    },
    "211321": {
        "211321001": "柳城街道",
        "211321102": "波罗赤镇",
        "211321103": "木头城子镇",
        "211321104": "二十家子镇",
        "211321105": "羊山镇",
        "211321106": "六家子镇",
        "211321107": "瓦房子镇",
        "211321108": "大庙镇",
        "211321110": "古山子镇",
        "211321111": "南双庙镇",
        "211321112": "台子镇",
        "211321113": "清风岭镇",
        "211321114": "胜利镇",
        "211321115": "七道岭镇",
        "211321116": "杨树湾镇",
        "211321211": "西五家子乡",
        "211321212": "北沟门子乡",
        "211321214": "东大道乡",
        "211321215": "乌兰河硕蒙古族乡",
        "211321220": "东大屯乡",
        "211321221": "松岭门蒙古族乡",
        "211321222": "根德营子乡",
        "211321224": "西营子乡",
        "211321228": "北四家子乡",
        "211321230": "王营子乡",
        "211321231": "黑牛营子乡",
        "211321232": "尚志乡",
        "211321400": "国营朝阳县贾家店农场"
    },
    "211322": {
        "211322001": "叶柏寿街道",
        "211322002": "红山街道",
        "211322003": "铁南街道",
        "211322004": "万寿街道",
        "211322005": "富山街道",
        "211322006": "新城街道",
        "211322007": "东城街道",
        "211322101": "朱碌科镇",
        "211322102": "建平镇",
        "211322103": "黑水镇",
        "211322104": "喀喇沁镇",
        "211322105": "北二十家子镇",
        "211322106": "沙海镇",
        "211322108": "哈拉道口镇",
        "211322109": "榆树林子镇",
        "211322110": "老官地镇",
        "211322111": "深井镇",
        "211322112": "奎德素镇",
        "211322113": "小塘镇",
        "211322114": "马场镇",
        "211322115": "昌隆镇",
        "211322117": "张家营子镇",
        "211322118": "青峰山镇",
        "211322119": "太平庄镇",
        "211322205": "青松岭乡",
        "211322206": "杨树岭乡",
        "211322209": "罗福沟乡",
        "211322211": "烧锅营子乡",
        "211322214": "白山乡",
        "211322218": "三家蒙古族乡",
        "211322220": "义成功乡",
        "211322400": "八家国营农场",
        "211322401": "热水国营畜牧农场"
    },
    "211324": {
        "211324001": "大城子街道",
        "211324002": "利州街道",
        "211324003": "南哨街道",
        "211324101": "南公营子镇",
        "211324102": "山嘴子镇",
        "211324104": "公营子镇",
        "211324105": "白塔子镇",
        "211324106": "中三家镇",
        "211324107": "老爷庙镇",
        "211324108": "六官营子镇",
        "211324109": "平房子镇",
        "211324110": "十二德堡镇",
        "211324111": "羊角沟镇",
        "211324112": "兴隆庄镇",
        "211324113": "甘招镇",
        "211324114": "东哨镇",
        "211324115": "水泉镇",
        "211324204": "尤杖子乡",
        "211324207": "草场乡",
        "211324210": "坤都营子乡",
        "211324211": "大营子乡",
        "211324214": "卧虎沟乡",
        "211324400": "国营官大海农场"
    },
    "211381": {
        "211381001": "城关街道",
        "211381002": "南山街道",
        "211381003": "冠山街道",
        "211381004": "桥北街道",
        "211381005": "三宝街道",
        "211381006": "台吉街道",
        "211381008": "双河街道",
        "211381101": "西官营镇",
        "211381102": "大板镇",
        "211381103": "上园镇",
        "211381104": "宝国老镇",
        "211381105": "黑城子镇",
        "211381107": "五间房镇",
        "211381108": "台吉镇",
        "211381109": "东官营镇",
        "211381110": "龙潭镇",
        "211381111": "北塔镇",
        "211381112": "蒙古营镇",
        "211381113": "大三家镇",
        "211381201": "长皋乡",
        "211381202": "常河营乡",
        "211381203": "小塔子乡",
        "211381204": "马友营蒙古族乡",
        "211381206": "泉巨永乡",
        "211381212": "哈尔脑乡",
        "211381213": "南八家子乡",
        "211381214": "章吉营乡",
        "211381215": "三宝营乡",
        "211381216": "巴图营乡",
        "211381218": "台吉营乡",
        "211381220": "娄家店乡",
        "211381221": "北四家乡",
        "211381223": "凉水河蒙古族乡",
        "211381228": "三宝乡",
        "211381400": "兴顺德国营农场",
        "211381470": "下府经济开发区",
        "211381471": "大黑山特别行政管理区"
    },
    "211382": {
        "211382001": "东城街道",
        "211382002": "北街街道",
        "211382003": "南街街道",
        "211382004": "凌北街道",
        "211382006": "热水汤街道",
        "211382007": "兴源街道",
        "211382008": "红山街道",
        "211382009": "城关街道",
        "211382101": "万元店镇",
        "211382102": "宋杖子镇",
        "211382103": "三十家子镇",
        "211382104": "杨杖子镇",
        "211382105": "刀尔登镇",
        "211382106": "松岭子镇",
        "211382107": "四官营子镇",
        "211382108": "沟门子镇",
        "211382110": "小城子镇",
        "211382111": "四合当镇",
        "211382113": "乌兰白镇",
        "211382114": "瓦房店镇",
        "211382115": "大河北镇",
        "211382116": "牛营子镇",
        "211382117": "三道河子镇",
        "211382118": "刘杖子镇",
        "211382204": "大王杖子乡",
        "211382206": "前进乡",
        "211382210": "北炉乡",
        "211382217": "三家子蒙古族乡",
        "211382219": "佛爷洞乡",
        "211382220": "河坎子乡"
    },
    "211400": {"211402": "连山区", "211403": "龙港区", "211404": "南票区", "211421": "绥中县", "211422": "建昌县", "211481": "兴城市"},
    "211402": {
        "211402001": "连山街道",
        "211402002": "站前街道",
        "211402003": "渤海街道",
        "211402004": "兴工街道",
        "211402005": "石油街道",
        "211402006": "化工街道",
        "211402007": "化机街道",
        "211402008": "水泥街道",
        "211402011": "锦郊街道",
        "211402104": "钢屯镇",
        "211402105": "寺儿堡镇",
        "211402106": "新台门镇",
        "211402201": "沙河营乡",
        "211402202": "孤竹营子乡",
        "211402203": "白马石乡",
        "211402205": "山神庙子乡",
        "211402206": "塔山乡",
        "211402210": "杨郊乡",
        "211402400": "杨家杖子经济开发区"
    },
    "211403": {
        "211403001": "葫芦岛街道",
        "211403002": "东街道",
        "211403003": "西街道",
        "211403004": "望海寺街道",
        "211403005": "龙湾街道",
        "211403006": "滨海街道",
        "211403007": "双龙街道",
        "211403008": "玉皇街道",
        "211403009": "连湾街道",
        "211403010": "北港街道",
        "211403201": "双树乡",
        "211403400": "葫芦岛经济开发区",
        "211403401": "葫芦岛市专利技术园区"
    },
    "211404": {
        "211404001": "赵家屯街道",
        "211404002": "沙锅屯街道",
        "211404003": "邱皮沟街道",
        "211404005": "苇子沟街道",
        "211404006": "三家子街道",
        "211404007": "小凌河街道",
        "211404008": "九龙街道",
        "211404009": "龙飞街道",
        "211404010": "龙腾街道",
        "211404011": "龙翔街道",
        "211404101": "缸窑岭镇",
        "211404102": "暖池塘镇",
        "211404103": "高桥镇",
        "211404104": "虹螺岘镇",
        "211404105": "金星镇",
        "211404106": "台集屯镇",
        "211404201": "沙锅屯乡",
        "211404203": "黄土坎乡",
        "211404204": "大兴乡",
        "211404205": "张相公屯乡",
        "211404400": "乌金塘水库"
    },
    "211421": {
        "211421100": "绥中镇",
        "211421101": "西甸子镇",
        "211421102": "宽邦镇",
        "211421103": "大王庙镇",
        "211421104": "万家镇",
        "211421105": "前所镇",
        "211421106": "高岭镇",
        "211421107": "前卫镇",
        "211421108": "荒地镇",
        "211421109": "塔山屯镇",
        "211421113": "高台镇",
        "211421114": "王宝镇",
        "211421115": "沙河镇",
        "211421116": "小庄子镇",
        "211421201": "西平坡满族乡",
        "211421202": "葛家满族乡",
        "211421204": "高甸子满族乡",
        "211421206": "范家满族乡",
        "211421207": "明水满族乡",
        "211421208": "秋子沟乡",
        "211421210": "加碑岩乡",
        "211421211": "永安堡乡",
        "211421212": "李家堡乡",
        "211421214": "网户满族乡",
        "211421217": "城郊乡",
        "211421400": "大台山果树农场",
        "211421401": "前所果树农场"
    },
    "211422": {
        "211422100": "建昌镇",
        "211422101": "八家子镇",
        "211422102": "喇嘛洞镇",
        "211422103": "药王庙镇",
        "211422104": "汤神庙镇",
        "211422105": "玲珑塔镇",
        "211422106": "大屯镇",
        "211422201": "牤牛营子乡",
        "211422203": "素珠营子乡",
        "211422204": "石佛乡",
        "211422206": "王宝营子乡",
        "211422207": "老大杖子乡",
        "211422208": "要路沟乡",
        "211422209": "魏家岭乡",
        "211422210": "西碱厂乡",
        "211422211": "头道营子乡",
        "211422212": "新开岭乡",
        "211422213": "贺杖子乡",
        "211422214": "养马甸子乡",
        "211422215": "和尚房子乡",
        "211422218": "杨树湾子乡",
        "211422219": "黑山科乡",
        "211422220": "雷家店乡",
        "211422222": "小德营子乡",
        "211422223": "二道湾子蒙古族乡",
        "211422224": "巴什罕乡",
        "211422225": "娘娘庙乡",
        "211422226": "谷杖子乡"
    },
    "211481": {
        "211481001": "古城街道",
        "211481002": "宁远街道",
        "211481003": "城东街道",
        "211481004": "温泉街道",
        "211481005": "钓鱼台街道",
        "211481006": "华山街道",
        "211481007": "四家屯街道",
        "211481008": "菊花街道",
        "211481009": "临海街道",
        "211481101": "曹庄镇",
        "211481103": "沙后所满族镇",
        "211481104": "东辛庄满族镇",
        "211481105": "郭家满族镇",
        "211481106": "红崖子镇",
        "211481107": "徐大堡镇",
        "211481108": "高家岭满族镇",
        "211481201": "羊安满族乡",
        "211481203": "元台子满族乡",
        "211481204": "白塔满族乡",
        "211481206": "望海满族乡",
        "211481207": "刘台子满族乡",
        "211481208": "大寨满族乡",
        "211481209": "南大满族乡",
        "211481210": "围屏满族乡",
        "211481212": "碱厂满族乡",
        "211481213": "三道沟满族乡",
        "211481215": "旧门满族乡",
        "211481216": "药王满族乡"
    },
    "220000": {
        "220100": "长春市",
        "220200": "吉林市",
        "220300": "四平市",
        "220400": "辽源市",
        "220500": "通化市",
        "220600": "白山市",
        "220700": "松原市",
        "220800": "白城市",
        "222400": "延边朝鲜族自治州"
    },
    "220100": {
        "220102": "南关区",
        "220103": "宽城区",
        "220104": "朝阳区",
        "220105": "二道区",
        "220106": "绿园区",
        "220112": "双阳区",
        "220113": "九台区",
        "220122": "农安县",
        "220171": "长春经济技术开发区",
        "220172": "长春净月高新技术产业开发区",
        "220173": "长春高新技术产业开发区",
        "220174": "长春汽车经济技术开发区",
        "220182": "榆树市",
        "220183": "德惠市"
    },
    "220102": {
        "220102001": "南岭街道",
        "220102002": "自强街道",
        "220102003": "民康街道",
        "220102004": "新春街道",
        "220102005": "长通街道",
        "220102006": "全安街道",
        "220102007": "曙光街道",
        "220102008": "永吉街道",
        "220102009": "桃源街道",
        "220102010": "鸿城街道",
        "220102011": "明珠街道",
        "220102012": "富裕街道",
        "220102200": "幸福乡"
    },
    "220103": {
        "220103001": "新发街道",
        "220103002": "站前街道",
        "220103003": "南广街道",
        "220103004": "东广街道",
        "220103005": "群英街道",
        "220103006": "兴业街道",
        "220103007": "凯旋街道",
        "220103008": "团山街道",
        "220103009": "柳影街道",
        "220103010": "欣园街道",
        "220103100": "兰家镇",
        "220103101": "米沙子镇",
        "220103102": "万宝镇",
        "220103171": "合隆镇"
    },
    "220104": {
        "220104001": "前进街道",
        "220104002": "桂林街道",
        "220104003": "南湖街道",
        "220104004": "永昌街道",
        "220104005": "重庆街道",
        "220104006": "清和街道",
        "220104007": "红旗街道",
        "220104008": "湖西街道",
        "220104009": "富锋街道",
        "220104100": "乐山镇",
        "220104101": "永春镇"
    },
    "220105": {
        "220105001": "东盛街道",
        "220105002": "吉林街道",
        "220105003": "荣光街道",
        "220105004": "东站街道",
        "220105005": "远达街道",
        "220105006": "八里堡街道",
        "220105007": "长青街道",
        "220105100": "英俊镇",
        "220105173": "劝农山镇（长春莲花山生态旅游度假区省级）",
        "220105174": "泉眼镇",
        "220105270": "四家乡（长春莲花山生态旅游度假区省级）"
    },
    "220106": {
        "220106001": "正阳街道",
        "220106003": "春城街道",
        "220106005": "青年街道",
        "220106006": "普阳街道",
        "220106007": "铁西街道",
        "220106010": "林园街道",
        "220106011": "同心街道",
        "220106100": "合心镇",
        "220106101": "西新镇",
        "220106102": "城西镇",
        "220106400": "绿园经济开发区"
    },
    "220112": {
        "220112001": "云山街道",
        "220112002": "平湖街道",
        "220112003": "奢岭街道",
        "220112004": "山河街道",
        "220112100": "齐家镇",
        "220112101": "太平镇",
        "220112102": "鹿乡镇",
        "220112270": "双营子回族乡（梅花鹿产业经济开发区）（省级）"
    },
    "220113": {
        "220113001": "九台街道",
        "220113002": "营城街道",
        "220113003": "九郊街道",
        "220113004": "西营城街道",
        "220113005": "土们岭街道",
        "220113006": "苇子沟街道",
        "220113007": "兴隆街道",
        "220113008": "纪家街道",
        "220113009": "波泥河街道",
        "220113010": "卡伦湖街道",
        "220113011": "东湖街道",
        "220113012": "龙嘉街道",
        "220113013": "兴港街道",
        "220113014": "沐石河街道",
        "220113015": "城子街街道",
        "220113102": "其塔木镇",
        "220113103": "上河湾镇",
        "220113200": "胡家回族乡",
        "220113201": "莽卡满族乡"
    },
    "220122": {
        "220122001": "兴农街道",
        "220122002": "宝塔街道",
        "220122003": "和谐街道",
        "220122004": "黄龙街道",
        "220122100": "农安镇",
        "220122101": "伏龙泉镇",
        "220122102": "哈拉海镇",
        "220122103": "靠山镇",
        "220122104": "开安镇",
        "220122105": "烧锅镇",
        "220122106": "高家店镇",
        "220122107": "华家镇",
        "220122108": "三盛玉镇",
        "220122109": "巴吉垒镇",
        "220122110": "三岗镇",
        "220122200": "前岗乡",
        "220122201": "龙王乡",
        "220122203": "万顺乡",
        "220122204": "杨树林乡",
        "220122205": "永安乡",
        "220122206": "青山口乡",
        "220122207": "黄鱼圈乡",
        "220122208": "新农乡",
        "220122209": "万金塔乡",
        "220122210": "小城子乡",
        "220122400": "洼中高农场"
    },
    "220171": {
        "220171001": "临河街道",
        "220171002": "东方广场街道",
        "220171003": "会展街道",
        "220171004": "世纪街道",
        "220171100": "兴隆山镇"
    },
    "220172": {
        "220172001": "永兴街道",
        "220172002": "净月街道",
        "220172003": "福祉街道",
        "220172004": "德正街道",
        "220172005": "彩织街道",
        "220172006": "德容街道",
        "220172007": "博硕街道",
        "220172100": "新立城镇",
        "220172101": "新湖镇",
        "220172102": "玉潭镇"
    },
    "220173": {"220173001": "硅谷街道", "220173002": "北湖街道", "220173200": "奋进乡", "220173201": "双德乡"},
    "220174": {"220174001": "锦程街道", "220174002": "东风街道", "220174100": "长春汽车经济技术开发区（特殊镇）"},
    "220182": {
        "220182001": "华昌街道",
        "220182002": "正阳街道",
        "220182003": "培英街道",
        "220182004": "城郊街道",
        "220182100": "五棵树镇",
        "220182101": "弓棚镇",
        "220182102": "闵家镇",
        "220182103": "大坡镇",
        "220182104": "黑林镇",
        "220182105": "土桥镇",
        "220182106": "新立镇",
        "220182107": "大岭镇",
        "220182108": "于家镇",
        "220182109": "泗河镇",
        "220182110": "八号镇",
        "220182111": "刘家镇",
        "220182112": "秀水镇",
        "220182113": "保寿镇",
        "220182114": "新庄镇",
        "220182200": "育民乡",
        "220182201": "红星乡",
        "220182202": "太安乡",
        "220182203": "先峰乡",
        "220182204": "青山乡",
        "220182205": "延和朝鲜族乡",
        "220182206": "恩育乡",
        "220182207": "城发乡",
        "220182208": "环城乡"
    },
    "220183": {
        "220183001": "胜利街道",
        "220183002": "建设街道",
        "220183003": "惠发街道",
        "220183004": "夏家店街道",
        "220183100": "大青嘴镇",
        "220183101": "郭家镇",
        "220183102": "松花江镇",
        "220183103": "达家沟镇",
        "220183104": "大房身镇",
        "220183105": "岔路口镇",
        "220183106": "朱城子镇",
        "220183107": "布海镇",
        "220183108": "天台镇",
        "220183109": "菜园子镇",
        "220183200": "同太乡",
        "220183201": "边岗乡",
        "220183202": "五台乡",
        "220183203": "朝阳乡"
    },
    "220200": {
        "220202": "昌邑区",
        "220203": "龙潭区",
        "220204": "船营区",
        "220211": "丰满区",
        "220221": "永吉县",
        "220271": "吉林经济开发区",
        "220272": "吉林高新技术产业开发区",
        "220273": "吉林中国新加坡食品区",
        "220281": "蛟河市",
        "220282": "桦甸市",
        "220283": "舒兰市",
        "220284": "磐石市"
    },
    "220202": {
        "220202001": "兴华街道",
        "220202002": "文庙街道",
        "220202003": "东局子街道",
        "220202004": "新地号街道",
        "220202005": "延安街道",
        "220202006": "站前街道",
        "220202007": "民主街道",
        "220202008": "莲花街道",
        "220202009": "通江街道",
        "220202010": "哈达湾街道",
        "220202011": "新建街道",
        "220202012": "延江街道",
        "220202071": "吉林东市商贸示范区（特殊街道）",
        "220202100": "孤店子镇",
        "220202101": "桦皮厂镇",
        "220202102": "左家镇",
        "220202200": "两家子满族乡",
        "220202201": "土城子满族朝鲜族乡",
        "220202401": "幸福经济管理区",
        "220202402": "吉林哈达湾经济开发区"
    },
    "220203": {
        "220203001": "龙华街道",
        "220203002": "湘潭街道",
        "220203003": "铁东街道",
        "220203004": "泡子沿街道",
        "220203005": "龙潭街道",
        "220203006": "新吉林街道",
        "220203007": "山前街道",
        "220203008": "新安街道",
        "220203009": "遵义街道",
        "220203010": "榆树街道",
        "220203012": "东城街道",
        "220203013": "承德街道",
        "220203072": "靠山街道",
        "220203100": "乌拉街镇",
        "220203101": "缸窑镇",
        "220203102": "江密峰镇",
        "220203103": "大口钦镇",
        "220203104": "金珠镇",
        "220203200": "江北乡",
        "220203400": "龙潭经济开发区",
        "220203401": "金珠工业区"
    },
    "220204": {
        "220204001": "德胜街道",
        "220204002": "南京街道",
        "220204003": "大东街道",
        "220204004": "青岛街道",
        "220204005": "向阳街道",
        "220204006": "北极街道",
        "220204007": "致和街道",
        "220204008": "长春路街道",
        "220204009": "临江街道",
        "220204010": "北山街道",
        "220204011": "黄旗街道",
        "220204100": "大绥河镇",
        "220204101": "搜登站镇",
        "220204102": "越北镇",
        "220204200": "欢喜乡",
        "220204400": "吉林船营经济开发区"
    },
    "220211": {
        "220211001": "泰山街道",
        "220211002": "江南街道",
        "220211003": "石井街道",
        "220211004": "沿丰街道",
        "220211005": "丰满街道",
        "220211071": "红旗街道",
        "220211100": "旺起镇",
        "220211200": "江南乡",
        "220211201": "前二道乡",
        "220211270": "小白山乡"
    },
    "220221": {
        "220221100": "口前镇",
        "220221101": "双河镇",
        "220221102": "西阳镇",
        "220221103": "北大湖镇",
        "220221104": "一拉溪镇",
        "220221171": "万昌镇",
        "220221172": "永吉经济开发区（特殊乡镇）",
        "220221200": "金家乡",
        "220221201": "黄榆乡"
    },
    "220271": {"220271001": "九站街道", "220271013": "双吉街道"},
    "220272": {"220272001": "高新开发区街道", "220272002": "新北街道"},
    "220273": {"220273100": "岔路河镇"},
    "220281": {
        "220281001": "民主街道",
        "220281002": "长安街道",
        "220281003": "河南街道",
        "220281004": "奶子山街道",
        "220281005": "拉法街道",
        "220281006": "河北街道",
        "220281007": "新农街道",
        "220281100": "新站镇",
        "220281101": "天岗镇",
        "220281102": "白石山镇",
        "220281103": "漂河镇",
        "220281104": "黄松甸镇",
        "220281105": "天北镇",
        "220281106": "松江镇",
        "220281107": "庆岭镇",
        "220281200": "乌林朝鲜族乡",
        "220281201": "前进乡"
    },
    "220282": {
        "220282001": "明桦街道",
        "220282002": "永吉街道",
        "220282003": "胜利街道",
        "220282004": "新华街道",
        "220282005": "启新街道",
        "220282100": "夹皮沟镇",
        "220282101": "二道甸子镇",
        "220282102": "红石砬子镇",
        "220282103": "八道河子镇",
        "220282104": "常山镇",
        "220282105": "金沙镇",
        "220282200": "桦郊乡",
        "220282201": "横道河子乡",
        "220282204": "公吉乡"
    },
    "220283": {
        "220283001": "北城街道",
        "220283002": "南城街道",
        "220283003": "环城街道",
        "220283004": "吉舒街道",
        "220283005": "滨河街道",
        "220283100": "法特镇",
        "220283101": "白旗镇",
        "220283102": "溪河镇",
        "220283103": "朝阳镇",
        "220283104": "小城镇",
        "220283105": "上营镇",
        "220283106": "水曲柳镇",
        "220283107": "平安镇",
        "220283108": "金马镇",
        "220283109": "开原镇",
        "220283200": "莲花乡",
        "220283201": "亮甲山乡",
        "220283202": "新安乡",
        "220283203": "七里乡",
        "220283204": "天德乡"
    },
    "220284": {
        "220284001": "福安街道",
        "220284002": "东宁街道",
        "220284003": "河南街道",
        "220284070": "磐石经济开发区（省级）（特殊街道）",
        "220284100": "烟筒山镇",
        "220284101": "红旗岭镇",
        "220284102": "明城镇",
        "220284103": "石嘴镇",
        "220284104": "驿马镇",
        "220284105": "牛心镇",
        "220284106": "呼兰镇",
        "220284107": "吉昌镇",
        "220284108": "松山镇",
        "220284109": "黑石镇",
        "220284110": "朝阳山镇",
        "220284111": "富太镇",
        "220284112": "取柴河镇",
        "220284200": "宝山乡"
    },
    "220300": {
        "220302": "铁西区",
        "220303": "铁东区",
        "220322": "梨树县",
        "220323": "伊通满族自治县",
        "220381": "公主岭市",
        "220382": "双辽市"
    },
    "220302": {
        "220302001": "仁兴街道",
        "220302002": "英雄街道",
        "220302003": "站前街道",
        "220302004": "北沟街道",
        "220302005": "地直街道",
        "220302200": "平西乡",
        "220302400": "红嘴高新技术开发区"
    },
    "220303": {
        "220303001": "平东街道",
        "220303002": "北市场街道",
        "220303003": "七马路街道",
        "220303004": "四马路街道",
        "220303005": "解放街道",
        "220303006": "北门街道",
        "220303007": "黄土坑街道",
        "220303008": "平南街道",
        "220303100": "山门镇",
        "220303101": "石岭镇",
        "220303102": "叶赫满族镇",
        "220303170": "四平经济开发区（省级）（特殊乡镇）",
        "220303200": "城东乡"
    },
    "220322": {
        "220322100": "梨树镇",
        "220322101": "郭家店镇",
        "220322102": "榆树台镇",
        "220322103": "孤家子镇（四平辽河农垦管理区）",
        "220322104": "小城子镇",
        "220322105": "喇嘛甸镇",
        "220322106": "蔡家镇",
        "220322107": "刘家馆子镇",
        "220322108": "十家堡镇",
        "220322109": "孟家岭镇",
        "220322110": "万发镇",
        "220322111": "东河镇",
        "220322112": "沈洋镇",
        "220322113": "林海镇",
        "220322114": "小宽镇",
        "220322200": "白山乡",
        "220322201": "泉眼岭乡",
        "220322202": "胜利乡",
        "220322203": "四棵树乡",
        "220322204": "双河乡",
        "220322205": "金山乡",
        "220322400": "国营梨树农场（四平辽河农垦管理区）",
        "220322405": "良种场生产区"
    },
    "220323": {
        "220323001": "永盛街道",
        "220323002": "永宁街道",
        "220323100": "伊通镇",
        "220323101": "二道镇",
        "220323102": "伊丹镇",
        "220323103": "马鞍镇",
        "220323104": "景台镇",
        "220323105": "靠山镇",
        "220323106": "大孤山镇",
        "220323107": "小孤山镇",
        "220323108": "营城子镇",
        "220323109": "西苇镇",
        "220323110": "河源镇",
        "220323111": "黄岭子镇",
        "220323200": "新兴乡",
        "220323201": "莫里青乡",
        "220323202": "三道乡"
    },
    "220381": {
        "220381001": "河南街道",
        "220381002": "河北街道",
        "220381003": "东三街道",
        "220381004": "岭东街道",
        "220381005": "铁北街道",
        "220381006": "岭西街道",
        "220381007": "刘房子街道",
        "220381008": "南崴子街道",
        "220381009": "环岭街道",
        "220381010": "苇子沟街道",
        "220381100": "二十家子镇",
        "220381101": "黑林子镇",
        "220381102": "陶家屯镇",
        "220381103": "范家屯镇",
        "220381104": "响水镇",
        "220381105": "大岭镇",
        "220381106": "怀德镇",
        "220381107": "双城堡镇",
        "220381108": "双龙镇",
        "220381109": "杨大城子镇",
        "220381110": "毛城子镇",
        "220381111": "玻璃城子镇",
        "220381112": "朝阳坡镇",
        "220381113": "大榆树镇",
        "220381114": "秦家屯镇",
        "220381115": "八屋镇",
        "220381116": "十屋镇",
        "220381117": "桑树台镇",
        "220381200": "龙山乡",
        "220381201": "永发乡",
        "220381400": "省原种繁殖场",
        "220381401": "四平农科院",
        "220381402": "公主岭市种猪场",
        "220381403": "十屋农场",
        "220381404": "十屋种牛场",
        "220381405": "创业农场",
        "220381406": "公主岭市鹿场",
        "220381407": "范家屯经济开发区"
    },
    "220382": {
        "220382001": "郑家屯街道",
        "220382002": "辽西街道",
        "220382003": "辽南街道",
        "220382004": "辽北街道",
        "220382005": "辽东街道",
        "220382006": "红旗街道",
        "220382100": "茂林镇",
        "220382101": "双山镇",
        "220382102": "卧虎镇",
        "220382103": "服先镇",
        "220382104": "王奔镇",
        "220382105": "玻璃山镇",
        "220382106": "兴隆镇",
        "220382107": "东明镇",
        "220382200": "那木乡",
        "220382201": "柳条乡",
        "220382202": "新立乡",
        "220382203": "永加乡",
        "220382400": "双辽农场（四平辽河农垦管理区）",
        "220382401": "双辽种羊场（四平辽河农垦管理区）",
        "220382402": "双辽经济开发区",
        "220382403": "双辽鸭场"
    },
    "220400": {"220402": "龙山区", "220403": "西安区", "220421": "东丰县", "220422": "东辽县"},
    "220402": {"220402070": "辽源经济开发区（特殊街道）", "220402100": "寿山镇", "220402198": "县级直管村级单位（特殊镇）", "220402200": "工农乡"},
    "220403": {"220403100": "灯塔镇", "220403198": "县级直管村级单位（特殊镇）"},
    "220421": {
        "220421100": "东丰镇",
        "220421101": "大阳镇",
        "220421102": "横道河镇",
        "220421103": "那丹伯镇",
        "220421104": "猴石镇",
        "220421105": "杨木林镇",
        "220421106": "小四平镇",
        "220421107": "黄河镇",
        "220421108": "拉拉河镇",
        "220421109": "沙河镇",
        "220421110": "南屯基镇",
        "220421111": "大兴镇",
        "220421200": "三合满族朝鲜族乡",
        "220421201": "二龙山乡"
    },
    "220422": {
        "220422100": "白泉镇",
        "220422101": "渭津镇",
        "220422102": "安石镇",
        "220422103": "辽河源镇",
        "220422104": "泉太镇",
        "220422105": "建安镇",
        "220422106": "安恕镇",
        "220422107": "平岗镇",
        "220422108": "云顶镇",
        "220422200": "凌云乡",
        "220422201": "甲山乡",
        "220422202": "足民乡",
        "220422203": "金洲乡"
    },
    "220500": {
        "220502": "东昌区",
        "220503": "二道江区",
        "220521": "通化县",
        "220523": "辉南县",
        "220524": "柳河县",
        "220581": "梅河口市",
        "220582": "集安市"
    },
    "220502": {
        "220502001": "东昌街道",
        "220502002": "民主街道",
        "220502003": "老站街道",
        "220502004": "团结街道",
        "220502005": "新站街道",
        "220502006": "光明街道",
        "220502007": "龙泉街道",
        "220502100": "金厂镇",
        "220502120": "通化经济开发区（特殊乡镇）",
        "220502200": "环通乡",
        "220502201": "江东乡"
    },
    "220503": {
        "220503001": "桃园街道",
        "220503002": "东通化街道",
        "220503100": "鸭园镇",
        "220503101": "铁厂镇",
        "220503102": "五道江镇",
        "220503200": "二道江乡"
    },
    "220521": {
        "220521100": "快大茂镇",
        "220521101": "二密镇",
        "220521102": "果松镇",
        "220521103": "石湖镇",
        "220521104": "大安镇",
        "220521105": "光华镇",
        "220521106": "兴林镇",
        "220521107": "英额布镇",
        "220521108": "三棵榆树镇",
        "220521109": "西江镇",
        "220521120": "通化聚鑫经济开发区管理委员会（特殊乡镇）",
        "220521200": "富江乡",
        "220521201": "四棚乡",
        "220521202": "东来乡",
        "220521203": "大泉源满族朝鲜族乡",
        "220521204": "金斗朝鲜族满族乡",
        "220521401": "东宝新村"
    },
    "220523": {
        "220523100": "朝阳镇",
        "220523101": "辉南镇",
        "220523102": "样子哨镇",
        "220523103": "杉松岗镇",
        "220523104": "石道河镇",
        "220523105": "辉发城镇",
        "220523106": "抚民镇",
        "220523107": "金川镇",
        "220523108": "团林镇",
        "220523109": "庆阳镇",
        "220523200": "楼街朝鲜族乡",
        "220523400": "辉南县经济技术开发区"
    },
    "220524": {
        "220524100": "柳河镇",
        "220524101": "三源浦朝鲜族镇",
        "220524102": "五道沟镇",
        "220524103": "驼腰岭镇",
        "220524104": "孤山子镇",
        "220524105": "圣水河子镇",
        "220524106": "罗通山镇",
        "220524107": "安口镇",
        "220524108": "向阳镇",
        "220524109": "红石镇",
        "220524110": "凉水河子镇",
        "220524111": "亨通镇",
        "220524200": "柳南乡",
        "220524201": "时家店乡",
        "220524202": "姜家店朝鲜族乡"
    },
    "220581": {
        "220581001": "新华街道",
        "220581002": "光明街道",
        "220581070": "和平街道（梅河口市经济贸易开发区）（省级）",
        "220581071": "解放街道（梅河口市经济贸易开发区）（省级）",
        "220581072": "福民街道（梅河口市经济贸易开发区）（省级）",
        "220581100": "山城镇",
        "220581101": "红梅镇",
        "220581102": "海龙镇",
        "220581103": "新合镇",
        "220581104": "曙光镇",
        "220581105": "中和镇",
        "220581106": "黑山头镇",
        "220581107": "水道镇",
        "220581108": "进化镇",
        "220581109": "一座营镇",
        "220581110": "康大营镇",
        "220581111": "牛心顶镇",
        "220581112": "杏岭镇",
        "220581113": "湾龙镇",
        "220581114": "兴华镇",
        "220581115": "双兴镇",
        "220581201": "李炉乡",
        "220581205": "小杨满族朝鲜族乡",
        "220581206": "吉乐乡"
    },
    "220582": {
        "220582001": "团结街道",
        "220582002": "黎明街道",
        "220582003": "通胜街道",
        "220582004": "城东街道",
        "220582100": "青石镇",
        "220582101": "榆林镇",
        "220582102": "花甸镇",
        "220582103": "头道镇",
        "220582104": "清河镇",
        "220582105": "台上镇",
        "220582106": "财源镇",
        "220582107": "大路镇",
        "220582108": "太王镇",
        "220582200": "麻线乡",
        "220582201": "凉水朝鲜族乡"
    },
    "220600": {
        "220602": "浑江区",
        "220605": "江源区",
        "220621": "抚松县",
        "220622": "靖宇县",
        "220623": "长白朝鲜族自治县",
        "220681": "临江市"
    },
    "220602": {
        "220602001": "新建街道",
        "220602002": "通沟街道",
        "220602003": "东兴街道",
        "220602004": "红旗街道",
        "220602005": "城南街道",
        "220602006": "江北街道",
        "220602007": "河口街道",
        "220602008": "板石街道",
        "220602100": "七道江镇",
        "220602101": "六道江镇",
        "220602102": "红土崖镇",
        "220602103": "三道沟镇"
    },
    "220605": {
        "220605001": "孙家堡子街道",
        "220605002": "江源街道",
        "220605003": "正岔街道",
        "220605004": "城墙街道",
        "220605102": "湾沟镇",
        "220605103": "松树镇",
        "220605104": "砟子镇",
        "220605105": "石人镇",
        "220605106": "大阳岔镇",
        "220605107": "大石人镇"
    },
    "220621": {
        "220621100": "抚松镇",
        "220621101": "松江河镇",
        "220621102": "泉阳镇",
        "220621103": "露水河镇",
        "220621104": "仙人桥镇",
        "220621105": "万良镇",
        "220621106": "新屯子镇",
        "220621107": "东岗镇",
        "220621108": "漫江镇",
        "220621109": "北岗镇",
        "220621110": "兴参镇",
        "220621120": "长白山保护开发区管委会池西区（特殊乡镇）",
        "220621121": "长白山保护开发区管委会池南区（特殊乡镇）",
        "220621200": "兴隆乡",
        "220621201": "抽水乡",
        "220621202": "沿江乡",
        "220621407": "抚松工业集中区"
    },
    "220622": {
        "220622100": "靖宇镇",
        "220622101": "三道湖镇",
        "220622102": "龙泉镇",
        "220622103": "那尔轰镇",
        "220622104": "花园口镇",
        "220622105": "景山镇",
        "220622106": "赤松镇",
        "220622201": "蒙江乡"
    },
    "220623": {
        "220623100": "长白镇",
        "220623101": "八道沟镇",
        "220623102": "十四道沟镇",
        "220623103": "马鹿沟镇",
        "220623104": "宝泉山镇",
        "220623105": "新房子镇",
        "220623106": "十二道沟镇",
        "220623200": "金华乡"
    },
    "220681": {
        "220681001": "建国街道",
        "220681002": "新市街道",
        "220681003": "兴隆街道",
        "220681004": "大湖街道",
        "220681005": "森工街道",
        "220681006": "大栗子街道",
        "220681100": "桦树镇",
        "220681101": "六道沟镇",
        "220681102": "苇沙河镇",
        "220681103": "花山镇",
        "220681104": "闹枝镇",
        "220681105": "四道沟镇",
        "220681200": "蚂蚁河乡",
        "220681400": "临江经济开发区",
        "220681401": "临江硅藻土工业集中区"
    },
    "220700": {
        "220702": "宁江区",
        "220721": "前郭尔罗斯蒙古族自治县",
        "220722": "长岭县",
        "220723": "乾安县",
        "220771": "吉林松原经济开发区",
        "220781": "扶余市"
    },
    "220702": {
        "220702001": "团结街道",
        "220702002": "文化街道",
        "220702003": "民主街道",
        "220702004": "临江街道",
        "220702005": "新区街道",
        "220702006": "前进街道",
        "220702007": "和平街道",
        "220702008": "工农街道",
        "220702009": "沿江街道",
        "220702010": "铁西街道",
        "220702011": "繁荣街道",
        "220702012": "建设街道",
        "220702013": "石化街道",
        "220702014": "伯都讷街道",
        "220702015": "长宁街道",
        "220702016": "滨江街道",
        "220702017": "镜湖街道",
        "220702100": "大洼镇",
        "220702101": "善友镇",
        "220702102": "毛都站镇",
        "220702103": "哈达山镇",
        "220702200": "新城乡",
        "220702202": "伯都乡",
        "220702400": "华侨农场",
        "220702401": "大洼商业渔场",
        "220702402": "国营善友林场",
        "220702403": "国营八家子苗圃",
        "220702404": "良种繁育场",
        "220702405": "哈达山林场",
        "220702406": "雅达虹工业集中区"
    },
    "220721": {
        "220721100": "前郭尔罗斯镇",
        "220721101": "长山镇",
        "220721102": "海渤日戈镇",
        "220721103": "乌兰图嘎镇",
        "220721104": "查干花镇",
        "220721105": "王府站镇",
        "220721106": "八郎镇",
        "220721107": "哈拉毛都镇",
        "220721108": "查干湖镇",
        "220721200": "宝甸乡",
        "220721201": "平凤乡",
        "220721203": "达里巴乡",
        "220721204": "吉拉吐乡",
        "220721205": "白依拉嘎乡",
        "220721206": "洪泉乡",
        "220721207": "额如乡",
        "220721208": "套浩太乡",
        "220721209": "长龙乡",
        "220721210": "乌兰塔拉乡",
        "220721211": "东三家子乡",
        "220721212": "浩特芒哈乡",
        "220721213": "乌兰敖都乡",
        "220721214": "吉林前郭经济开发区（特殊乡镇）",
        "220721400": "红旗农场",
        "220721401": "红光农场",
        "220721402": "红星牧场",
        "220721403": "查干花种畜场",
        "220721404": "莲花泡农场",
        "220721405": "乌兰嘎图林场",
        "220721406": "查干花林场",
        "220721407": "王府林场",
        "220721408": "八郎农场",
        "220721409": "查干湖渔场",
        "220721410": "韩家店林场",
        "220721411": "长山余热鱼苗繁殖场",
        "220721412": "库里渔场",
        "220721413": "哈拉毛都良种场",
        "220721414": "哈拉毛都林场",
        "220721415": "新庙泡渔场"
    },
    "220722": {
        "220722100": "长岭镇",
        "220722101": "太平川镇",
        "220722102": "巨宝山镇",
        "220722103": "太平山镇",
        "220722104": "前七号镇",
        "220722105": "新安镇",
        "220722106": "三青山镇",
        "220722107": "大兴镇",
        "220722108": "北正镇",
        "220722109": "流水镇",
        "220722110": "永久镇",
        "220722111": "利发盛镇",
        "220722123": "长岭经济开发区（特殊乡镇）",
        "220722200": "集体乡",
        "220722201": "光明乡",
        "220722202": "三县堡乡",
        "220722203": "海青乡",
        "220722204": "前进乡",
        "220722205": "东岭乡",
        "220722206": "腰坨子乡",
        "220722207": "八十八乡",
        "220722208": "三团乡",
        "220722209": "三十号乡",
        "220722400": "长岭种马场",
        "220722405": "太平川机械林场",
        "220722406": "三团机械林场",
        "220722407": "东岭机械林场",
        "220722408": "十四号种畜场",
        "220722409": "良种繁育场",
        "220722410": "前七号机械林场"
    },
    "220723": {
        "220723100": "乾安镇",
        "220723101": "大布苏镇",
        "220723102": "水字镇",
        "220723103": "让字镇",
        "220723104": "所字镇",
        "220723105": "安字镇",
        "220723200": "余字乡",
        "220723201": "道字乡",
        "220723202": "严字乡",
        "220723203": "赞字乡",
        "220723400": "国营林场",
        "220723401": "腾字种畜场",
        "220723402": "大遐畜牧场",
        "220723403": "乾安鹿场",
        "220723404": "来字良种繁育基地",
        "220723405": "地字种畜场"
    },
    "220771": {"220771100": "松原经济技术开发区（特殊乡镇）", "220771200": "兴原乡"},
    "220781": {
        "220781001": "和兴街道",
        "220781002": "育才街道",
        "220781003": "联盟街道",
        "220781004": "士英街道",
        "220781005": "铁西街道",
        "220781100": "三岔河镇",
        "220781101": "长春岭镇",
        "220781102": "五家站镇",
        "220781103": "陶赖昭镇",
        "220781104": "蔡家沟镇",
        "220781105": "弓棚子镇",
        "220781106": "三井子镇",
        "220781107": "增盛镇",
        "220781108": "新万发镇",
        "220781109": "大林子镇",
        "220781110": "新源镇",
        "220781111": "得胜镇",
        "220781200": "三骏满族蒙古族锡伯族乡",
        "220781201": "永平乡",
        "220781203": "新站乡",
        "220781204": "更新乡",
        "220781206": "肖家乡",
        "220781400": "伊家店农场",
        "220781401": "拉林灌区管理局",
        "220781402": "下岱吉灌区管理局"
    },
    "220800": {
        "220802": "洮北区",
        "220821": "镇赉县",
        "220822": "通榆县",
        "220871": "吉林白城经济开发区",
        "220881": "洮南市",
        "220882": "大安市"
    },
    "220802": {
        "220802001": "海明街道",
        "220802002": "长庆街道",
        "220802003": "瑞光街道",
        "220802004": "明仁街道",
        "220802005": "铁东街道",
        "220802006": "城南街道（工业园区）",
        "220802007": "新立街道",
        "220802009": "新华街道",
        "220802100": "平安镇",
        "220802101": "青山镇",
        "220802102": "林海镇",
        "220802103": "洮河镇",
        "220802104": "平台镇",
        "220802105": "到保镇",
        "220802170": "岭下镇（查干浩特旅游经济开发区）（省级）",
        "220802200": "东风乡",
        "220802201": "三合乡",
        "220802202": "东胜乡",
        "220802203": "金祥乡",
        "220802204": "德顺蒙古族乡",
        "220802402": "镇南种羊场",
        "220802403": "白城牧场",
        "220802404": "洮儿河农场",
        "220802405": "保民农场",
        "220802406": "到保林场",
        "220802407": "白城市生态新区"
    },
    "220821": {
        "220821100": "镇赉镇",
        "220821101": "坦途镇",
        "220821102": "东屏镇",
        "220821103": "大屯镇",
        "220821104": "沿江镇",
        "220821105": "五棵树镇",
        "220821106": "黑鱼泡镇",
        "220821200": "哈吐气蒙古族乡",
        "220821201": "莫莫格蒙古族乡",
        "220821202": "建平乡",
        "220821203": "嘎什根乡",
        "220821400": "四方坨子农场",
        "220821401": "白音套海牧场",
        "220821402": "良种繁育场",
        "220821403": "九龙山马场",
        "220821405": "大岗林场",
        "220821406": "国营渔场",
        "220821407": "到保农场"
    },
    "220822": {
        "220822100": "开通镇",
        "220822101": "瞻榆镇",
        "220822102": "双岗镇",
        "220822103": "兴隆山镇",
        "220822104": "边昭镇",
        "220822105": "鸿兴镇",
        "220822106": "新华镇",
        "220822107": "乌兰花镇",
        "220822200": "新发乡",
        "220822201": "新兴乡",
        "220822202": "向海蒙古族乡",
        "220822203": "包拉温都蒙古族乡",
        "220822204": "团结乡",
        "220822205": "十花道乡",
        "220822206": "八面乡",
        "220822207": "苏公坨乡",
        "220822400": "通榆经济开发区",
        "220822401": "三家子种牛场",
        "220822402": "良井子畜牧场",
        "220822403": "双岗鹿场",
        "220822404": "同发牧场",
        "220822405": "新华牛场"
    },
    "220871": {"220871001": "幸福街道", "220871002": "保平街道", "220871003": "西郊街道"},
    "220881": {
        "220881001": "团结街道",
        "220881002": "富文街道",
        "220881003": "光明街道",
        "220881004": "兴隆街道",
        "220881005": "永康街道",
        "220881006": "通达街道",
        "220881007": "洮府街道",
        "220881008": "向阳街道",
        "220881100": "瓦房镇",
        "220881101": "万宝镇",
        "220881102": "黑水镇",
        "220881103": "那金镇",
        "220881104": "安定镇",
        "220881105": "福顺镇",
        "220881200": "胡力吐蒙古族乡",
        "220881201": "万宝乡",
        "220881202": "聚宝乡",
        "220881203": "东升乡",
        "220881204": "野马乡",
        "220881205": "永茂乡",
        "220881206": "蛟流河乡",
        "220881207": "大通乡",
        "220881209": "二龙乡",
        "220881210": "呼和车力蒙古族乡",
        "220881400": "东方红畜牧场",
        "220881401": "永茂林场",
        "220881402": "市原种场"
    },
    "220882": {
        "220882001": "慧阳街道",
        "220882002": "临江街道",
        "220882003": "长虹街道",
        "220882004": "锦华街道",
        "220882005": "安北街道",
        "220882100": "月亮泡镇",
        "220882101": "安广镇",
        "220882102": "丰收镇",
        "220882103": "新平安镇",
        "220882104": "两家子镇",
        "220882105": "舍力镇",
        "220882106": "大岗子镇",
        "220882107": "叉干镇",
        "220882108": "龙沼镇",
        "220882109": "太山镇",
        "220882200": "四棵树乡",
        "220882201": "联合乡",
        "220882202": "大赉乡",
        "220882203": "红岗子乡",
        "220882204": "海坨乡",
        "220882205": "新艾里蒙古族乡",
        "220882206": "烧锅镇乡",
        "220882207": "乐胜乡",
        "220882400": "大安经济开发区（省级）",
        "220882401": "新荒渔场",
        "220882402": "东方红农场",
        "220882403": "舍力林场",
        "220882404": "五间房水库",
        "220882405": "大岗子林场",
        "220882406": "风水山牧场",
        "220882407": "东风马场",
        "220882408": "良种繁育场",
        "220882409": "大安马场",
        "220882410": "安广猪场",
        "220882411": "红旗饲养场"
    },
    "222400": {
        "222401": "延吉市",
        "222402": "图们市",
        "222403": "敦化市",
        "222404": "珲春市",
        "222405": "龙井市",
        "222406": "和龙市",
        "222424": "汪清县",
        "222426": "安图县"
    },
    "222401": {
        "222401001": "进学街道",
        "222401002": "北山街道",
        "222401003": "新兴街道",
        "222401004": "公园街道",
        "222401005": "河南街道",
        "222401006": "建工街道",
        "222401100": "小营镇",
        "222401101": "依兰镇",
        "222401102": "三道湾镇",
        "222401105": "朝阳川镇",
        "222401400": "延吉市林业局",
        "222401401": "东山农场",
        "222401402": "延边原种场",
        "222401404": "延边新兴工业集中区"
    },
    "222402": {
        "222402001": "向上街道",
        "222402002": "新华街道",
        "222402003": "月宫街道",
        "222402100": "月晴镇",
        "222402101": "石岘镇",
        "222402102": "长安镇",
        "222402103": "凉水镇"
    },
    "222403": {
        "222403001": "渤海街道",
        "222403002": "胜利街道",
        "222403003": "民主街道",
        "222403004": "丹江街道",
        "222403100": "大石头镇",
        "222403101": "黄泥河镇",
        "222403102": "官地镇",
        "222403103": "沙河沿镇",
        "222403104": "秋梨沟镇",
        "222403105": "额穆镇",
        "222403106": "贤儒镇",
        "222403107": "大蒲柴河镇",
        "222403108": "雁鸣湖镇",
        "222403109": "江源镇",
        "222403110": "江南镇",
        "222403200": "大桥乡",
        "222403201": "黑石乡",
        "222403202": "青沟子乡",
        "222403203": "翰章乡",
        "222403204": "红石乡",
        "222403400": "长白山森工集团敦化林业有限公司",
        "222403401": "长白山森工集团黄泥河林业有限公司",
        "222403402": "长白山森工集团大石头林业有限公司",
        "222403403": "敦化市林业局",
        "222403404": "敖东鹿场"
    },
    "222404": {
        "222404001": "靖和街道",
        "222404002": "新安街道",
        "222404003": "河南街道",
        "222404060": "近海街道",
        "222404100": "春化镇",
        "222404101": "敬信镇",
        "222404102": "板石镇",
        "222404103": "英安镇",
        "222404200": "马川子乡",
        "222404201": "杨泡满族乡",
        "222404202": "三家子满族乡",
        "222404203": "密江乡",
        "222404204": "哈达门乡",
        "222404400": "珲春林业局"
    },
    "222405": {
        "222405001": "安民街道",
        "222405002": "龙门街道",
        "222405100": "开山屯镇",
        "222405102": "老头沟镇",
        "222405103": "三合镇",
        "222405104": "东盛涌镇",
        "222405105": "智新镇",
        "222405200": "德新乡",
        "222405201": "白金乡",
        "222405400": "龙井市林业局",
        "222405401": "龙井大苏果树农场"
    },
    "222406": {
        "222406001": "民慧街道",
        "222406002": "光明街道",
        "222406003": "文化街道",
        "222406100": "八家子镇",
        "222406101": "福洞镇",
        "222406102": "头道镇",
        "222406103": "西城镇",
        "222406104": "南坪镇",
        "222406105": "东城镇",
        "222406106": "崇善镇",
        "222406107": "龙城镇",
        "222406400": "和龙林业局",
        "222406401": "八家子林业局",
        "222406402": "和龙市林业局"
    },
    "222424": {
        "222424011": "大川街道",
        "222424012": "新民街道",
        "222424013": "长荣街道",
        "222424100": "汪清镇",
        "222424101": "大兴沟镇",
        "222424102": "天桥岭镇",
        "222424103": "罗子沟镇",
        "222424104": "百草沟镇",
        "222424105": "春阳镇",
        "222424106": "复兴镇",
        "222424107": "东光镇",
        "222424200": "鸡冠乡",
        "222424400": "汪清林业局",
        "222424401": "天桥岭林业局",
        "222424402": "大兴沟林业局",
        "222424404": "八人沟农场",
        "222424407": "罗子沟农场",
        "222424408": "复兴农场"
    },
    "222426": {
        "222426100": "明月镇",
        "222426101": "松江镇",
        "222426102": "二道白河镇",
        "222426103": "两江镇",
        "222426104": "石门镇",
        "222426105": "万宝镇",
        "222426106": "亮兵镇",
        "222426120": "长白山保护开发区管委会池北区（特殊乡镇）",
        "222426198": "县级直管村级单位（特殊镇）",
        "222426200": "新合乡",
        "222426201": "永庆乡",
        "222426400": "白河林业局",
        "222426401": "安图林业有限公司",
        "222426402": "安图县林业局",
        "222426403": "海沟金矿"
    },
    "230000": {
        "230100": "哈尔滨市",
        "230200": "齐齐哈尔市",
        "230300": "鸡西市",
        "230400": "鹤岗市",
        "230500": "双鸭山市",
        "230600": "大庆市",
        "230700": "伊春市",
        "230800": "佳木斯市",
        "230900": "七台河市",
        "231000": "牡丹江市",
        "231100": "黑河市",
        "231200": "绥化市",
        "232700": "大兴安岭地区"
    },
    "230100": {
        "230102": "道里区",
        "230103": "南岗区",
        "230104": "道外区",
        "230108": "平房区",
        "230109": "松北区",
        "230110": "香坊区",
        "230111": "呼兰区",
        "230112": "阿城区",
        "230113": "双城区",
        "230123": "依兰县",
        "230124": "方正县",
        "230125": "宾县",
        "230126": "巴彦县",
        "230127": "木兰县",
        "230128": "通河县",
        "230129": "延寿县",
        "230183": "尚志市",
        "230184": "五常市"
    },
    "230102": {
        "230102001": "兆麟街道",
        "230102002": "新阳路街道",
        "230102003": "抚顺街道",
        "230102004": "共乐街道",
        "230102005": "新华街道",
        "230102006": "城乡路街道",
        "230102007": "工农街道",
        "230102008": "尚志街道",
        "230102009": "斯大林街道",
        "230102010": "通江街道",
        "230102011": "经纬街道",
        "230102012": "工程街道",
        "230102013": "安静街道",
        "230102014": "安和街道",
        "230102015": "正阳河街道",
        "230102016": "建国街道",
        "230102017": "康安街道",
        "230102018": "爱建街道",
        "230102019": "群力街道",
        "230102101": "太平镇",
        "230102102": "新发镇",
        "230102103": "新农镇",
        "230102104": "榆树镇",
        "230102500": "闫家岗农场",
        "230102579": "道里区农垦"
    },
    "230103": {
        "230103001": "花园街道",
        "230103002": "奋斗路街道",
        "230103003": "革新街道",
        "230103004": "文化街道",
        "230103005": "大成街道",
        "230103006": "芦家街道",
        "230103007": "荣市街道",
        "230103009": "燎原街道",
        "230103010": "松花江街道",
        "230103011": "曲线街道",
        "230103012": "通达街道",
        "230103013": "七政街道",
        "230103015": "和兴路街道",
        "230103016": "哈西街道",
        "230103017": "保健路街道",
        "230103018": "先锋路街道",
        "230103019": "新春街道",
        "230103020": "跃进街道",
        "230103101": "王岗镇",
        "230103201": "红旗满族乡",
        "230103500": "红旗农场",
        "230103579": "南岗区农垦"
    },
    "230104": {
        "230104001": "靖宇街道",
        "230104002": "太古街道",
        "230104003": "东莱街道",
        "230104004": "滨江街道",
        "230104005": "仁里街道",
        "230104006": "南市街道",
        "230104007": "崇俭街道",
        "230104008": "振江街道",
        "230104009": "东原街道",
        "230104010": "大兴街道",
        "230104011": "胜利街道",
        "230104012": "南马街道",
        "230104021": "民强街道",
        "230104022": "大有坊街道",
        "230104023": "南直路街道",
        "230104024": "化工街道",
        "230104025": "火车头街道",
        "230104026": "新一街道",
        "230104027": "三棵树大街街道",
        "230104028": "水泥路街道",
        "230104029": "太平大街街道",
        "230104030": "黎华街道",
        "230104031": "新乐街道",
        "230104102": "永源镇",
        "230104109": "巨源镇",
        "230104111": "团结镇",
        "230104112": "民主镇",
        "230104500": "青年农场",
        "230104579": "道外农垦"
    },
    "230108": {
        "230108001": "兴建街道",
        "230108002": "保国街道",
        "230108003": "联盟街道",
        "230108004": "友协街道",
        "230108005": "新疆街道",
        "230108006": "新伟街道",
        "230108007": "平新街道",
        "230108008": "建安街道",
        "230108009": "平盛街道",
        "230108101": "平房镇",
        "230108579": "平房农垦"
    },
    "230109": {
        "230109001": "三电街道",
        "230109002": "太阳岛街道",
        "230109003": "松浦街道",
        "230109004": "万宝街道",
        "230109005": "松北街道",
        "230109006": "松安街道",
        "230109007": "松祥街道",
        "230109008": "船口街道",
        "230109101": "对青山镇",
        "230109102": "乐业镇"
    },
    "230110": {
        "230110001": "香坊大街街道",
        "230110002": "安埠街道",
        "230110003": "通天街道",
        "230110004": "新香坊街道",
        "230110005": "铁东街道",
        "230110006": "新成街道",
        "230110007": "红旗街道",
        "230110008": "六顺街道",
        "230110009": "建筑街道",
        "230110010": "哈平路街道",
        "230110011": "安乐街道",
        "230110012": "健康路街道",
        "230110013": "大庆路街道",
        "230110014": "进乡街道",
        "230110015": "通乡街道",
        "230110016": "和平路街道",
        "230110017": "民生路街道",
        "230110018": "文政街道",
        "230110019": "王兆街道",
        "230110020": "黎明街道",
        "230110101": "成高子镇",
        "230110102": "幸福镇",
        "230110103": "朝阳镇",
        "230110104": "向阳镇",
        "230110400": "哈尔滨综合保税区",
        "230110500": "香坊实验农场",
        "230110579": "香坊区农垦"
    },
    "230111": {
        "230111001": "呼兰街道",
        "230111002": "兰河街道",
        "230111003": "腰卜街道",
        "230111004": "利民街道",
        "230111005": "康金街道",
        "230111006": "双井街道",
        "230111007": "建设路街道",
        "230111008": "学院路街道",
        "230111009": "长岭街道",
        "230111010": "沈家街道",
        "230111011": "南京路街道",
        "230111012": "裕民街道",
        "230111013": "裕田街道",
        "230111014": "裕强街道",
        "230111015": "萧乡街道",
        "230111016": "公园路街道",
        "230111105": "二八镇",
        "230111106": "石人镇",
        "230111107": "白奎镇",
        "230111109": "方台镇",
        "230111110": "莲花镇",
        "230111111": "大用镇",
        "230111114": "利业镇",
        "230111201": "杨林乡",
        "230111203": "许卜乡",
        "230111205": "孟家乡",
        "230111579": "呼兰农垦"
    },
    "230112": {
        "230112001": "金城街道",
        "230112002": "金都街道",
        "230112003": "通城街道",
        "230112004": "河东街道",
        "230112005": "阿什河街道",
        "230112006": "玉泉街道",
        "230112007": "新利街道",
        "230112008": "双丰街道",
        "230112009": "舍利街道",
        "230112010": "小岭街道",
        "230112011": "亚沟街道",
        "230112012": "交界街道",
        "230112013": "料甸街道",
        "230112014": "蜚克图街道",
        "230112015": "杨树街道",
        "230112107": "平山镇",
        "230112108": "松峰山镇",
        "230112109": "红星镇",
        "230112112": "金龙山镇",
        "230112503": "阿城原种场"
    },
    "230113": {
        "230113002": "五家街道",
        "230113003": "新兴街道",
        "230113004": "兰棱街道",
        "230113005": "周家街道",
        "230113006": "公正街道",
        "230113007": "承旭街道",
        "230113008": "承恩街道",
        "230113009": "永治街道",
        "230113010": "永和街道",
        "230113011": "幸福街道",
        "230113104": "韩甸镇",
        "230113105": "单城镇",
        "230113106": "东官镇",
        "230113107": "农丰满族锡伯族镇",
        "230113108": "杏山镇",
        "230113109": "西官镇",
        "230113110": "联兴镇",
        "230113111": "永胜镇",
        "230113112": "胜丰镇",
        "230113201": "金城乡",
        "230113203": "青岭满族乡",
        "230113209": "临江乡",
        "230113210": "水泉乡",
        "230113211": "乐群满族乡",
        "230113214": "万隆乡",
        "230113216": "希勤满族乡",
        "230113217": "同心满族乡"
    },
    "230123": {
        "230123100": "依兰镇",
        "230123101": "达连河镇",
        "230123102": "江湾镇",
        "230123103": "三道岗镇",
        "230123104": "道台桥镇",
        "230123105": "宏克利镇",
        "230123200": "团山子乡",
        "230123201": "愚公乡",
        "230123202": "迎兰朝鲜族乡",
        "230123400": "依兰县林业局",
        "230123500": "依兰农场",
        "230123501": "松花江农场"
    },
    "230124": {
        "230124100": "方正镇",
        "230124101": "会发镇",
        "230124102": "大罗密镇",
        "230124103": "得莫利镇",
        "230124200": "天门乡",
        "230124201": "松南乡",
        "230124202": "德善乡",
        "230124204": "宝兴乡",
        "230124400": "方正林业局",
        "230124500": "沙河农场"
    },
    "230125": {
        "230125100": "宾州镇",
        "230125101": "居仁镇",
        "230125102": "宾西镇",
        "230125103": "糖坊镇",
        "230125104": "宾安镇",
        "230125105": "新甸镇",
        "230125106": "胜利镇",
        "230125107": "宁远镇",
        "230125109": "摆渡镇",
        "230125110": "平坊镇",
        "230125111": "满井镇",
        "230125112": "常安镇",
        "230125201": "永和乡",
        "230125203": "鸟河乡",
        "230125204": "民和乡",
        "230125205": "经建乡",
        "230125210": "三宝乡",
        "230125500": "九龙山柞蚕育种场",
        "230125579": "农垦农业职业学院"
    },
    "230126": {
        "230126100": "巴彦镇",
        "230126101": "兴隆镇",
        "230126102": "西集镇",
        "230126103": "洼兴镇",
        "230126104": "龙泉镇",
        "230126105": "巴彦港镇",
        "230126106": "龙庙镇",
        "230126107": "万发镇",
        "230126108": "天增镇",
        "230126110": "黑山镇",
        "230126200": "松花江乡",
        "230126202": "富江乡",
        "230126204": "华山乡",
        "230126205": "丰乐乡",
        "230126209": "德祥乡",
        "230126210": "红光乡",
        "230126212": "山后乡",
        "230126214": "镇东乡",
        "230126400": "兴隆林业局",
        "230126401": "工业园区"
    },
    "230127": {
        "230127100": "木兰镇",
        "230127101": "东兴镇",
        "230127102": "大贵镇",
        "230127103": "利东镇",
        "230127104": "柳河镇",
        "230127105": "新民镇",
        "230127200": "建国乡",
        "230127203": "吉兴乡",
        "230127400": "兴隆林业局"
    },
    "230128": {
        "230128100": "通河镇",
        "230128101": "乌鸦泡镇",
        "230128102": "清河镇",
        "230128103": "浓河镇",
        "230128104": "凤山镇",
        "230128105": "祥顺镇",
        "230128106": "富林镇",
        "230128107": "三站镇",
        "230128400": "清河林业局",
        "230128401": "兴隆林业局",
        "230128500": "岔林河农场"
    },
    "230129": {
        "230129100": "延寿镇",
        "230129101": "六团镇",
        "230129102": "中和镇",
        "230129103": "加信镇",
        "230129104": "延河镇",
        "230129105": "玉河镇",
        "230129204": "安山乡",
        "230129205": "寿山乡",
        "230129209": "青川乡",
        "230129400": "太平川",
        "230129500": "庆阳农场"
    },
    "230183": {
        "230183100": "尚志镇",
        "230183101": "一面坡镇",
        "230183102": "苇河镇",
        "230183103": "亚布力镇",
        "230183104": "帽儿山镇",
        "230183105": "亮河镇",
        "230183106": "庆阳镇",
        "230183107": "石头河子镇",
        "230183108": "元宝镇",
        "230183109": "黑龙宫镇",
        "230183202": "长寿乡",
        "230183204": "乌吉密乡",
        "230183206": "鱼池乡",
        "230183207": "珍珠山乡",
        "230183208": "老街基乡",
        "230183209": "马延乡",
        "230183211": "河东乡",
        "230183400": "苇河林业局",
        "230183401": "亚布力林业局"
    },
    "230184": {
        "230184100": "五常镇",
        "230184101": "拉林满族镇",
        "230184102": "山河镇",
        "230184103": "小山子镇",
        "230184104": "安家镇",
        "230184105": "牛家满族镇",
        "230184106": "杜家镇",
        "230184107": "背荫河镇",
        "230184108": "冲河镇",
        "230184109": "沙河子镇",
        "230184110": "向阳镇",
        "230184111": "龙凤山镇",
        "230184200": "兴盛乡",
        "230184201": "志广乡",
        "230184202": "卫国乡",
        "230184203": "常堡乡",
        "230184206": "民意乡",
        "230184209": "红旗满族乡",
        "230184210": "八家子乡",
        "230184211": "民乐朝鲜族乡",
        "230184212": "营城子满族乡",
        "230184214": "长山乡",
        "230184215": "兴隆乡",
        "230184216": "二河乡",
        "230184400": "山河屯林业局"
    },
    "230200": {
        "230202": "龙沙区",
        "230203": "建华区",
        "230204": "铁锋区",
        "230205": "昂昂溪区",
        "230206": "富拉尔基区",
        "230207": "碾子山区",
        "230208": "梅里斯达斡尔族区",
        "230221": "龙江县",
        "230223": "依安县",
        "230224": "泰来县",
        "230225": "甘南县",
        "230227": "富裕县",
        "230229": "克山县",
        "230230": "克东县",
        "230231": "拜泉县",
        "230281": "讷河市"
    },
    "230202": {
        "230202001": "五龙街道",
        "230202002": "湖滨街道",
        "230202003": "江安街道",
        "230202004": "正阳街道",
        "230202005": "彩虹街道",
        "230202006": "南航街道",
        "230202007": "大民街道",
        "230202579": "齐齐哈尔局直"
    },
    "230203": {
        "230203002": "中华街道",
        "230203004": "西大桥街道",
        "230203005": "卜奎街道",
        "230203006": "建设街道",
        "230203007": "文化街道",
        "230203198": "建华区（镇）直辖地域"
    },
    "230204": {
        "230204001": "站前街道",
        "230204002": "南浦街道",
        "230204003": "通东街道",
        "230204004": "光荣街道",
        "230204005": "龙华街道",
        "230204006": "北局宅街道",
        "230204008": "东湖街道",
        "230204100": "扎龙镇",
        "230204500": "齐齐哈尔种畜场"
    },
    "230205": {
        "230205001": "新兴街道",
        "230205002": "新建街道",
        "230205005": "林机街道",
        "230205006": "道北街道",
        "230205100": "水师营满族镇",
        "230205101": "榆树屯镇"
    },
    "230206": {
        "230206001": "红岸街道",
        "230206003": "沿江街道",
        "230206004": "电力街道",
        "230206005": "幸福街道",
        "230206006": "红宝石街道",
        "230206007": "北兴街道",
        "230206008": "铁北街道",
        "230206009": "和平街道",
        "230206200": "长青乡",
        "230206202": "杜尔门沁达斡尔族乡"
    },
    "230207": {"230207001": "东安街道", "230207002": "富强街道", "230207003": "跃进街道", "230207004": "繁荣街道"},
    "230208": {
        "230208001": "梅里斯街道",
        "230208101": "雅尔塞镇",
        "230208102": "卧牛吐达斡尔族镇",
        "230208103": "达呼店镇",
        "230208104": "共和镇",
        "230208105": "梅里斯镇",
        "230208200": "莽格吐达斡尔族乡",
        "230208501": "哈拉海农场"
    },
    "230221": {
        "230221100": "龙江镇",
        "230221101": "景星镇",
        "230221102": "龙兴镇",
        "230221103": "山泉镇",
        "230221104": "七棵树镇",
        "230221105": "杏山镇",
        "230221106": "白山镇",
        "230221107": "头站镇",
        "230221201": "黑岗乡",
        "230221202": "广厚乡",
        "230221203": "华民乡",
        "230221204": "哈拉海乡",
        "230221215": "鲁河乡",
        "230221217": "济沁河乡"
    },
    "230223": {
        "230223100": "依安镇",
        "230223101": "依龙镇",
        "230223102": "双阳镇",
        "230223103": "三兴镇",
        "230223104": "中心镇",
        "230223105": "新兴镇",
        "230223200": "富饶乡",
        "230223201": "解放乡",
        "230223202": "阳春乡",
        "230223203": "新发乡",
        "230223204": "太东乡",
        "230223205": "上游乡",
        "230223206": "红星乡",
        "230223207": "先锋乡",
        "230223208": "新屯乡",
        "230223500": "依安农场",
        "230223501": "红旗种马场"
    },
    "230224": {
        "230224100": "泰来镇",
        "230224101": "平洋镇",
        "230224102": "汤池镇",
        "230224103": "江桥蒙古族镇",
        "230224104": "塔子城镇",
        "230224105": "大兴镇",
        "230224106": "和平镇",
        "230224107": "克利镇",
        "230224200": "胜利蒙古族乡",
        "230224209": "宁姜蒙古族乡",
        "230224400": "葡萄场",
        "230224401": "果树场",
        "230224402": "泰来县一苗圃",
        "230224403": "泰来县二苗圃",
        "230224404": "东方红机械林场",
        "230224480": "六三监狱",
        "230224500": "泰来农场",
        "230224580": "第一良种场",
        "230224581": "第二良种场",
        "230224582": "宏胜种畜场",
        "230224583": "二龙涛农场"
    },
    "230225": {
        "230225100": "甘南镇",
        "230225101": "兴十四镇",
        "230225102": "平阳镇",
        "230225103": "东阳镇",
        "230225104": "巨宝镇",
        "230225201": "长山乡",
        "230225202": "中兴乡",
        "230225203": "兴隆乡",
        "230225204": "宝山乡",
        "230225206": "查哈阳乡",
        "230225500": "查哈阳农场",
        "230225580": "北京市双河农场"
    },
    "230227": {
        "230227100": "富裕镇",
        "230227101": "富路镇",
        "230227102": "富海镇",
        "230227103": "二道湾镇",
        "230227104": "龙安桥镇",
        "230227105": "塔哈镇",
        "230227200": "繁荣乡",
        "230227201": "绍文乡",
        "230227202": "忠厚乡",
        "230227203": "友谊乡",
        "230227500": "富裕牧场",
        "230227501": "繁荣种畜场"
    },
    "230229": {
        "230229100": "克山镇",
        "230229101": "北兴镇",
        "230229102": "西城镇",
        "230229103": "古城镇",
        "230229104": "北联镇",
        "230229105": "西河镇",
        "230229106": "双河镇",
        "230229200": "河南乡",
        "230229203": "河北乡",
        "230229204": "古北乡",
        "230229205": "西联乡",
        "230229206": "发展乡",
        "230229207": "西建乡",
        "230229208": "向华乡",
        "230229210": "曙光乡",
        "230229400": "克山县河北林场",
        "230229401": "克山县涌泉林场",
        "230229402": "克山县北联林场",
        "230229500": "克山农场",
        "230229580": "克山县第一良种场",
        "230229581": "克山县第二良种场",
        "230229583": "克山县种猪场",
        "230229584": "克山县种畜场"
    },
    "230230": {
        "230230100": "克东镇",
        "230230101": "宝泉镇",
        "230230102": "乾丰镇",
        "230230103": "玉岗镇",
        "230230104": "蒲峪路镇",
        "230230202": "润津乡",
        "230230204": "昌盛乡",
        "230230400": "第一苗圃",
        "230230401": "第二苗圃",
        "230230402": "爱华林场",
        "230230403": "发展林场",
        "230230404": "东兴林场",
        "230230580": "红旗奶山羊场",
        "230230581": "第一良种场",
        "230230582": "第二良种繁殖场",
        "230230583": "果树繁殖场",
        "230230584": "种猪场",
        "230230585": "黑龙江省社会救助安置中心"
    },
    "230231": {
        "230231100": "拜泉镇",
        "230231101": "三道镇",
        "230231102": "兴农镇",
        "230231103": "长春镇",
        "230231104": "龙泉镇",
        "230231105": "国富镇",
        "230231106": "富强镇",
        "230231200": "新生乡",
        "230231201": "兴国乡",
        "230231203": "上升乡",
        "230231204": "兴华乡",
        "230231206": "大众乡",
        "230231209": "丰产乡",
        "230231210": "永勤乡",
        "230231212": "爱农乡",
        "230231214": "时中乡"
    },
    "230281": {
        "230281001": "雨亭街道",
        "230281002": "通江街道",
        "230281101": "拉哈镇",
        "230281102": "二克浅镇",
        "230281103": "学田镇",
        "230281104": "龙河镇",
        "230281105": "讷南镇",
        "230281106": "六合镇",
        "230281107": "长发镇",
        "230281108": "通南镇",
        "230281109": "同义镇",
        "230281110": "九井镇",
        "230281111": "老莱镇",
        "230281201": "孔国乡",
        "230281207": "和盛乡",
        "230281208": "同心乡",
        "230281210": "兴旺鄂温克族乡",
        "230281400": "龙河镇保安林场",
        "230281401": "龙河镇茂山林场",
        "230281402": "龙河镇国庆林场",
        "230281403": "学田镇富源林场",
        "230281404": "老莱镇宽余林场",
        "230281405": "新江林场",
        "230281406": "一良苗圃",
        "230281480": "黑龙江省老莱农场",
        "230281581": "六合镇黎明奶牛场",
        "230281582": "孔国乡进化种猪场",
        "230281583": "二克浅镇二里种畜场",
        "230281584": "龙河镇青色草原种畜场",
        "230281585": "第一良种场",
        "230281586": "第二良种场",
        "230281587": "第三良种场",
        "230281588": "第四良种场"
    },
    "230300": {
        "230302": "鸡冠区",
        "230303": "恒山区",
        "230304": "滴道区",
        "230305": "梨树区",
        "230306": "城子河区",
        "230307": "麻山区",
        "230321": "鸡东县",
        "230381": "虎林市",
        "230382": "密山市"
    },
    "230302": {
        "230302001": "向阳街道",
        "230302002": "南山街道",
        "230302003": "立新街道",
        "230302004": "东风街道",
        "230302005": "红军路街道",
        "230302006": "西鸡西街道",
        "230302007": "西山街道",
        "230302200": "红星乡",
        "230302201": "西郊乡"
    },
    "230303": {
        "230303001": "桦木林街道",
        "230303002": "大恒山街道",
        "230303003": "小恒山街道",
        "230303004": "二道河子街道",
        "230303005": "张新街道",
        "230303006": "奋斗街道",
        "230303007": "柳毛街道",
        "230303200": "红旗乡",
        "230303201": "柳毛乡"
    },
    "230304": {
        "230304001": "东兴街道",
        "230304002": "矿里街道",
        "230304003": "洗煤街道",
        "230304004": "大通沟街道",
        "230304200": "滴道河乡",
        "230304201": "兰岭乡"
    },
    "230305": {
        "230305001": "街里街道",
        "230305002": "穆棱街道",
        "230305003": "平岗街道",
        "230305004": "碱场街道",
        "230305005": "石磷街道",
        "230305101": "梨树镇"
    },
    "230306": {
        "230306001": "城子河街道",
        "230306002": "正阳街道",
        "230306003": "东海街道",
        "230306004": "城西街道",
        "230306005": "杏花街道",
        "230306200": "长青乡",
        "230306201": "永丰乡"
    },
    "230307": {"230307001": "麻山街道", "230307101": "麻山镇"},
    "230321": {
        "230321100": "鸡东镇",
        "230321101": "平阳镇",
        "230321102": "向阳镇",
        "230321103": "哈达镇",
        "230321104": "永安镇",
        "230321105": "永和镇",
        "230321106": "东海镇",
        "230321107": "兴农镇",
        "230321200": "鸡林乡",
        "230321205": "明德乡",
        "230321206": "下亮子乡",
        "230321400": "林业局",
        "230321500": "八五一０农场"
    },
    "230381": {
        "230381100": "虎林镇",
        "230381101": "东方红镇",
        "230381102": "迎春镇",
        "230381103": "虎头镇",
        "230381104": "杨岗镇",
        "230381105": "东诚镇",
        "230381106": "宝东镇",
        "230381203": "新乐乡",
        "230381204": "伟光乡",
        "230381206": "珍宝岛乡",
        "230381207": "阿北乡",
        "230381400": "东方红林业局",
        "230381401": "迎春林业局",
        "230381500": "八五０农场",
        "230381501": "八五四农场",
        "230381502": "八五六农场",
        "230381503": "八五八农场",
        "230381504": "庆丰农场",
        "230381505": "云山农场"
    },
    "230382": {
        "230382001": "中心街道",
        "230382100": "密山镇",
        "230382101": "连珠山镇",
        "230382102": "当壁镇",
        "230382103": "知一镇",
        "230382104": "黑台镇",
        "230382105": "兴凯镇",
        "230382106": "裴德镇",
        "230382107": "白鱼湾镇",
        "230382200": "柳毛乡",
        "230382201": "杨木乡",
        "230382202": "兴凯湖乡",
        "230382203": "承紫河乡",
        "230382206": "二人班乡",
        "230382208": "太平乡",
        "230382210": "和平乡",
        "230382212": "富源乡",
        "230382400": "林业局",
        "230382480": "青年水库",
        "230382481": "煤炭工业局",
        "230382482": "经济开发区管理委员会",
        "230382501": "牡丹江管理局局直",
        "230382502": "八五五农场",
        "230382503": "八五七农场",
        "230382504": "八五一一农场",
        "230382505": "兴凯湖农场",
        "230382506": "牡丹江管理局北大营管委会",
        "230382507": "牡丹江管理局双峰农场",
        "230382580": "水产养殖有限公司",
        "230382581": "种畜场",
        "230382582": "水田良种场",
        "230382583": "市良种场",
        "230382584": "校办企业公司",
        "230382585": "蜂蜜山粮库有限公司"
    },
    "230400": {
        "230402": "向阳区",
        "230403": "工农区",
        "230404": "南山区",
        "230405": "兴安区",
        "230406": "东山区",
        "230407": "兴山区",
        "230421": "萝北县",
        "230422": "绥滨县"
    },
    "230402": {"230402001": "北山街道", "230402002": "红军街道", "230402003": "光明街道", "230402004": "胜利街道", "230402005": "南翼街道"},
    "230403": {
        "230403001": "育才街道",
        "230403002": "红旗街道",
        "230403003": "新南街道",
        "230403004": "湖滨街道",
        "230403005": "解放街道",
        "230403006": "团结街道"
    },
    "230404": {
        "230404001": "铁西街道",
        "230404002": "铁东街道",
        "230404003": "六号街道",
        "230404004": "大陆街道",
        "230404005": "富力街道",
        "230404006": "麓林山街道"
    },
    "230405": {
        "230405001": "兴安路街道",
        "230405002": "兴建路街道",
        "230405003": "兴长路街道",
        "230405004": "峻德路街道",
        "230405005": "河东路街道",
        "230405006": "光宇街道",
        "230405100": "红旗镇"
    },
    "230406": {
        "230406001": "工人村街道",
        "230406002": "新一街道",
        "230406003": "三街街道",
        "230406004": "东山街道",
        "230406005": "鹤兴街道",
        "230406100": "新华镇",
        "230406201": "蔬园乡",
        "230406202": "东方红乡",
        "230406400": "鹤岗市林业局",
        "230406501": "新华农场"
    },
    "230407": {"230407001": "岭北街道", "230407002": "岭南街道", "230407003": "沟北街道", "230407004": "沟南街道"},
    "230421": {
        "230421100": "凤翔镇",
        "230421101": "鹤北镇",
        "230421102": "名山镇",
        "230421103": "团结镇",
        "230421104": "肇兴镇",
        "230421105": "云山镇",
        "230421200": "东明朝鲜族乡",
        "230421203": "太平沟乡",
        "230421400": "萝北县林业局",
        "230421401": "鹤北林业局",
        "230421500": "宝泉岭管理局局直",
        "230421501": "江滨农场",
        "230421502": "军川农场",
        "230421503": "名山农场",
        "230421504": "延军农场",
        "230421505": "共青农场",
        "230421506": "宝泉岭农场",
        "230421580": "萝北县农业局"
    },
    "230422": {
        "230422100": "绥滨镇",
        "230422101": "绥东镇",
        "230422102": "忠仁镇",
        "230422200": "连生乡",
        "230422201": "北岗乡",
        "230422202": "富强乡",
        "230422203": "北山乡",
        "230422204": "福兴乡",
        "230422205": "新富乡",
        "230422400": "绥滨西林场",
        "230422401": "国营中兴边防林场",
        "230422480": "忠仁镇良种场",
        "230422481": "绥东镇种畜场",
        "230422500": "二九０农场",
        "230422501": "绥滨农场",
        "230422503": "普阳农场"
    },
    "230500": {
        "230502": "尖山区",
        "230503": "岭东区",
        "230505": "四方台区",
        "230506": "宝山区",
        "230521": "集贤县",
        "230522": "友谊县",
        "230523": "宝清县",
        "230524": "饶河县"
    },
    "230502": {
        "230502001": "二马路街道",
        "230502002": "八马路街道",
        "230502003": "中心站街道",
        "230502004": "富安街道",
        "230502005": "窑地街道",
        "230502006": "长安街道",
        "230502007": "铁西街道",
        "230502200": "安邦乡"
    },
    "230503": {
        "230503001": "中山街道",
        "230503002": "北山街道",
        "230503003": "南山街道",
        "230503004": "东山街道",
        "230503005": "中心街道",
        "230503006": "西山街道",
        "230503200": "长胜乡",
        "230503400": "岭东区青山旅游公司",
        "230503401": "岭东区岭东经营所"
    },
    "230505": {
        "230505001": "振兴中路街道",
        "230505002": "振兴东路街道",
        "230505004": "集贤街道",
        "230505005": "东荣街道",
        "230505101": "太保镇"
    },
    "230506": {
        "230506001": "红旗街道",
        "230506002": "跃进街道",
        "230506003": "东保卫街道",
        "230506004": "七星街道",
        "230506005": "双阳街道",
        "230506006": "新安街道",
        "230506007": "电厂街道",
        "230506101": "七星镇",
        "230506401": "双鸭山林业局宝山经营所",
        "230506402": "双鸭山林业局大叶沟林场",
        "230506500": "双鸭山农场"
    },
    "230521": {
        "230521100": "福利镇",
        "230521101": "集贤镇",
        "230521102": "升昌镇",
        "230521103": "丰乐镇",
        "230521104": "太平镇",
        "230521201": "腰屯乡",
        "230521202": "兴安乡",
        "230521204": "永安乡",
        "230521401": "太平林场",
        "230521402": "丰乐林场",
        "230521403": "七星林场",
        "230521404": "峻山林场",
        "230521405": "爱林林场",
        "230521406": "腰屯林场",
        "230521407": "升平煤矿",
        "230521480": "黑龙江省双鸭山监狱",
        "230521500": "二九一农场",
        "230521581": "良种场",
        "230521582": "种畜场",
        "230521583": "果树示范场"
    },
    "230522": {
        "230522100": "友谊镇",
        "230522101": "兴隆镇",
        "230522102": "龙山镇",
        "230522103": "凤岗镇",
        "230522200": "兴盛乡",
        "230522201": "东建乡",
        "230522202": "庆丰乡",
        "230522203": "建设乡",
        "230522204": "友邻乡",
        "230522205": "新镇乡",
        "230522206": "成富朝鲜族满族乡",
        "230522501": "红兴隆管理局局直",
        "230522502": "友谊农场"
    },
    "230523": {
        "230523100": "宝清镇",
        "230523101": "七星泡镇",
        "230523102": "青原镇",
        "230523103": "夹信子镇",
        "230523104": "龙头镇",
        "230523105": "小城子镇",
        "230523202": "朝阳乡",
        "230523203": "万金山乡",
        "230523204": "尖山子乡",
        "230523209": "七星河乡",
        "230523400": "双鸭山林业局上游经营所",
        "230523401": "双鸭山林业局南瓮泉经营所",
        "230523402": "双鸭山林业局七一林场",
        "230523403": "双鸭山林业局七星河林场",
        "230523404": "双鸭山林业局红旗林场",
        "230523405": "双鸭山林业局三岔河林场",
        "230523406": "双鸭山林业局青龙林场",
        "230523407": "双鸭山林业局宝石经营所",
        "230523408": "双鸭山林业局七星河金矿",
        "230523409": "桦南林业局岚峰林场",
        "230523500": "五九七农场",
        "230523501": "八五二农场",
        "230523502": "八五三农场"
    },
    "230524": {
        "230524100": "饶河镇",
        "230524101": "小佳河镇",
        "230524102": "西丰镇",
        "230524103": "五林洞镇",
        "230524200": "西林子乡",
        "230524201": "四排乡",
        "230524203": "大佳河乡",
        "230524204": "山里乡",
        "230524207": "大通河乡",
        "230524400": "小佳河林场",
        "230524401": "威山林场",
        "230524402": "西丰林场",
        "230524403": "大牙克林场",
        "230524404": "石场林场",
        "230524405": "宝马山林场",
        "230524406": "大岱林场",
        "230524407": "永幸林场",
        "230524408": "奇源林场",
        "230524409": "芦源林场",
        "230524410": "五林洞林场",
        "230524500": "饶河农场",
        "230524501": "红旗岭农场",
        "230524502": "八五九农场",
        "230524503": "胜利农场",
        "230524504": "红卫农场"
    },
    "230600": {
        "230602": "萨尔图区",
        "230603": "龙凤区",
        "230604": "让胡路区",
        "230605": "红岗区",
        "230606": "大同区",
        "230621": "肇州县",
        "230622": "肇源县",
        "230623": "林甸县",
        "230624": "杜尔伯特蒙古族自治县",
        "230671": "大庆高新技术产业开发区"
    },
    "230602": {
        "230602001": "萨尔图街道",
        "230602005": "铁人街道",
        "230602006": "友谊街道",
        "230602007": "富强街道",
        "230602008": "拥军街道",
        "230602009": "会战街道",
        "230602010": "火炬街道",
        "230602011": "东风街道",
        "230602012": "东安街道"
    },
    "230603": {
        "230603001": "龙凤街道",
        "230603002": "兴化街道",
        "230603007": "卧里屯街道",
        "230603008": "东光街道",
        "230603009": "三永街道",
        "230603100": "龙凤镇",
        "230603580": "高新区农场"
    },
    "230604": {
        "230604001": "龙岗街道",
        "230604002": "银浪街道",
        "230604004": "奋斗街道",
        "230604005": "庆新街道",
        "230604007": "西宾街道",
        "230604009": "乘风街道",
        "230604101": "喇嘛甸镇",
        "230604580": "红骥牧场",
        "230604581": "星火牧场",
        "230604582": "银浪牧场"
    },
    "230605": {
        "230605001": "红岗街道",
        "230605004": "八百垧街道",
        "230605005": "杏南街道",
        "230605006": "解放街道",
        "230605007": "创业街道",
        "230605100": "杏树岗镇",
        "230605480": "红岗区铁人生态工业园区"
    },
    "230606": {
        "230606001": "庆葡街道",
        "230606002": "高台子镇街道",
        "230606004": "林源镇街道",
        "230606005": "立志街道",
        "230606006": "新华街道",
        "230606007": "大同镇街道",
        "230606100": "大同镇",
        "230606101": "高台子镇",
        "230606102": "太阳升镇",
        "230606103": "林源镇",
        "230606200": "祝三乡",
        "230606203": "老山头乡",
        "230606205": "八井子乡",
        "230606206": "双榆树乡",
        "230606500": "和平牧场"
    },
    "230621": {
        "230621100": "肇州镇",
        "230621101": "永乐镇",
        "230621102": "丰乐镇",
        "230621103": "朝阳沟镇",
        "230621104": "兴城镇",
        "230621105": "二井镇",
        "230621202": "双发乡",
        "230621203": "托古乡",
        "230621205": "朝阳乡",
        "230621206": "永胜乡",
        "230621208": "榆树乡",
        "230621211": "新福乡",
        "230621580": "乐园良种场",
        "230621581": "卫星种畜场"
    },
    "230622": {
        "230622100": "肇源镇",
        "230622101": "三站镇",
        "230622102": "二站镇",
        "230622103": "茂兴镇",
        "230622104": "古龙镇",
        "230622105": "新站镇",
        "230622106": "头台镇",
        "230622107": "古恰镇",
        "230622200": "福兴乡",
        "230622201": "薄荷台乡",
        "230622203": "和平乡",
        "230622207": "超等乡",
        "230622208": "民意乡",
        "230622210": "义顺乡",
        "230622211": "浩德乡",
        "230622212": "大兴乡",
        "230622500": "肇源农场",
        "230622581": "种畜场",
        "230622583": "新立良种繁育场",
        "230622584": "立陡山良种场",
        "230622586": "果树示范场",
        "230622587": "经济作物示范场",
        "230622588": "茂兴湖水产养殖场"
    },
    "230623": {
        "230623100": "林甸镇",
        "230623101": "红旗镇",
        "230623102": "花园镇",
        "230623103": "四季青镇",
        "230623104": "鹤鸣湖镇",
        "230623200": "东兴乡",
        "230623201": "宏伟乡",
        "230623204": "四合乡",
        "230623400": "林甸县长青林场",
        "230623500": "巨浪牧场",
        "230623580": "国营苇场",
        "230623581": "新兴畜牧场"
    },
    "230624": {
        "230624100": "泰康镇",
        "230624101": "胡吉吐莫镇",
        "230624102": "烟筒屯镇",
        "230624103": "他拉哈镇",
        "230624104": "连环湖镇",
        "230624200": "一心乡",
        "230624201": "克尔台乡",
        "230624203": "敖林西伯乡",
        "230624204": "巴彦查干乡",
        "230624205": "腰新乡",
        "230624206": "江湾乡",
        "230624500": "绿色草原牧场",
        "230624501": "大山种羊场"
    },
    "230671": {"230671010": "开发区黎明街道"},
    "230700": {
        "230702": "伊春区",
        "230703": "南岔区",
        "230704": "友好区",
        "230705": "西林区",
        "230706": "翠峦区",
        "230707": "新青区",
        "230708": "美溪区",
        "230709": "金山屯区",
        "230710": "五营区",
        "230711": "乌马河区",
        "230712": "汤旺河区",
        "230713": "带岭区",
        "230714": "乌伊岭区",
        "230715": "红星区",
        "230716": "上甘岭区",
        "230722": "嘉荫县",
        "230781": "铁力市"
    },
    "230702": {
        "230702002": "旭日社区",
        "230702003": "林都社区",
        "230702005": "北郡社区",
        "230702008": "东升街道",
        "230702009": "红升社区",
        "230702010": "永红社区",
        "230702011": "南郡社区",
        "230702012": "新欣社区",
        "230702013": "扶林社区",
        "230702014": "前进社区",
        "230702015": "红光社区",
        "230702016": "朝阳社区"
    },
    "230703": {
        "230703006": "文明社区",
        "230703007": "利民社区",
        "230703008": "新建社区",
        "230703009": "梧桐社区",
        "230703010": "城林社区",
        "230703011": "建国社区",
        "230703012": "和平社区",
        "230703013": "兴林社区",
        "230703014": "红星社区",
        "230703015": "东风社区",
        "230703016": "向阳社区",
        "230703101": "晨明镇",
        "230703102": "浩良河镇",
        "230703200": "迎春乡",
        "230703400": "三岔河林场",
        "230703401": "四合营林场",
        "230703402": "亮子河林场",
        "230703403": "石头河林场",
        "230703405": "岩石经营所",
        "230703406": "奋斗经营所",
        "230703407": "桦阳经营所",
        "230703408": "浩良河经营所",
        "230703409": "太平经营所",
        "230703410": "南岔经营所",
        "230703411": "威岭经营所",
        "230703413": "松青经营所",
        "230703414": "柳树经营所",
        "230703579": "浩良河化肥厂"
    },
    "230704": {
        "230704005": "双子河社区",
        "230704006": "先锋社区",
        "230704007": "滨水社区",
        "230704008": "前进社区",
        "230704009": "向阳社区",
        "230704010": "保安社区",
        "230704011": "铁兴社区",
        "230704400": "三合经营所",
        "230704401": "松园林场",
        "230704402": "中心经营所",
        "230704403": "永青经营所",
        "230704404": "碧云经营所",
        "230704405": "友谊经营所",
        "230704406": "青杨经营所",
        "230704407": "岭峰林场",
        "230704408": "奋斗林场",
        "230704409": "东卡林场",
        "230704410": "鸡爪河林场",
        "230704411": "绿源林场",
        "230704412": "广川经营所",
        "230704413": "朝阳经营所"
    },
    "230705": {
        "230705006": "河东社区",
        "230705007": "河西社区",
        "230705008": "繁荣社区",
        "230705009": "铁西社区",
        "230705010": "三公里社区",
        "230705011": "钢城社区",
        "230705012": "松胜社区",
        "230705013": "强达社区",
        "230705014": "白林社区",
        "230705015": "苔青社区"
    },
    "230706": {
        "230706003": "新春社区",
        "230706004": "新建社区",
        "230706005": "育林社区",
        "230706006": "繁荣社区",
        "230706007": "保安社区",
        "230706008": "文化社区",
        "230706009": "胜利社区",
        "230706010": "幸福社区",
        "230706400": "解放经营所",
        "230706401": "昆仑气林场",
        "230706402": "开源河林场",
        "230706403": "么河经营所",
        "230706404": "冲锋经营所",
        "230706405": "翠峦河经营所",
        "230706406": "抚育河经营所",
        "230706407": "尖山河经营所"
    },
    "230707": {
        "230707004": "黎明社区",
        "230707005": "红建中心社区",
        "230707006": "新民社区",
        "230707007": "富民社区",
        "230707008": "育林社区",
        "230707009": "新立中心社区",
        "230707400": "红林经营所",
        "230707401": "松林林场",
        "230707402": "泉林林场",
        "230707404": "水源林场",
        "230707405": "笑山林场",
        "230707406": "汤林林场",
        "230707407": "桦林经营所",
        "230707408": "青林林场",
        "230707409": "结源林场",
        "230707410": "北沟林场",
        "230707411": "南沟林场",
        "230707412": "乌拉嘎经营所",
        "230707413": "柳树河林场",
        "230707414": "北影林场"
    },
    "230708": {
        "230708002": "红光社区",
        "230708003": "新兴社区",
        "230708004": "胜利社区",
        "230708005": "文化社区",
        "230708006": "东林社区",
        "230708400": "大西林林场",
        "230708401": "五道库经营所",
        "230708402": "缓岭经营所",
        "230708403": "对青山经营所",
        "230708404": "群峦经营所",
        "230708405": "兰新经营所",
        "230708406": "顺利河林场",
        "230708407": "松岭经营所",
        "230708408": "青山口林场",
        "230708409": "金沙河林场",
        "230708410": "桦皮羌子林场",
        "230708411": "碧仓库林场",
        "230708412": "三股流经营所",
        "230708413": "卧龙河林场"
    },
    "230709": {
        "230709009": "爱民社区",
        "230709010": "新建社区",
        "230709011": "育林社区",
        "230709012": "文明社区",
        "230709013": "金山社区",
        "230709401": "白山林场",
        "230709402": "丰岭林场",
        "230709403": "丰林林场",
        "230709404": "丰沟经营所",
        "230709405": "丰茂林场",
        "230709406": "横山经营所",
        "230709407": "丰丽林场",
        "230709408": "大昆仑经营所",
        "230709409": "小昆仑林场",
        "230709410": "育林经营所"
    },
    "230710": {
        "230710002": "育林社区",
        "230710003": "建林社区",
        "230710004": "松林社区",
        "230710005": "新林社区",
        "230710006": "五星社区",
        "230710400": "翠北林场",
        "230710401": "前丰林场",
        "230710402": "永丰经营所",
        "230710403": "平原经营所",
        "230710404": "丽丰经营所",
        "230710405": "杨树河经营所",
        "230710406": "平山经营所",
        "230710407": "丽林经营所"
    },
    "230711": {
        "230711002": "红旗社区",
        "230711003": "团结社区",
        "230711004": "繁荣社区",
        "230711005": "奋斗社区",
        "230711006": "振华社区",
        "230711007": "先锋社区",
        "230711008": "长征社区",
        "230711009": "锦河社区",
        "230711400": "伊敏林场",
        "230711401": "伊东中心林场",
        "230711402": "东方红农场",
        "230711403": "翠岭经营所",
        "230711404": "乌马河经营所",
        "230711405": "青山林场",
        "230711406": "育苗经营所",
        "230711407": "安全经营所",
        "230711408": "伊青经营所",
        "230711409": "前进经营所",
        "230711410": "西岭林场",
        "230711411": "伊林经营所"
    },
    "230712": {
        "230712003": "南山社区",
        "230712004": "向阳社区",
        "230712005": "文化社区",
        "230712006": "振兴社区",
        "230712007": "兴安社区",
        "230712400": "日新林场",
        "230712401": "石林林场",
        "230712402": "东升林场",
        "230712403": "二龙山林场",
        "230712404": "高峰林场",
        "230712405": "二清河林场",
        "230712406": "守虎山林场",
        "230712407": "泉石林场",
        "230712408": "峻岭林场",
        "230712409": "育林经营所",
        "230712410": "团结经营所",
        "230712411": "磨石山经营所",
        "230712412": "中心苗卜",
        "230712413": "克林经营所"
    },
    "230713": {
        "230713002": "团结社区",
        "230713003": "育西社区",
        "230713004": "永胜社区",
        "230713005": "铁南社区",
        "230713006": "宾北社区",
        "230713400": "大青川林场",
        "230713401": "东方红林场",
        "230713402": "秀水林场",
        "230713403": "永翠林场",
        "230713404": "明月林场",
        "230713405": "红光林场",
        "230713406": "秋沙鸭自然保护区",
        "230713407": "北列林场",
        "230713408": "南列林场",
        "230713409": "寒月林场",
        "230713410": "环山林场",
        "230713411": "凉水自然保护区"
    },
    "230714": {
        "230714002": "林铁社区",
        "230714003": "向阳社区",
        "230714004": "建设社区",
        "230714005": "新风社区",
        "230714006": "幸福社区",
        "230714400": "上游林场",
        "230714401": "美峰林场",
        "230714402": "美林林场",
        "230714403": "建新林场",
        "230714404": "移山林场",
        "230714405": "林海林场",
        "230714406": "桔源经营所",
        "230714407": "东克林林场",
        "230714408": "福民林场",
        "230714409": "阿廷河林场",
        "230714410": "永胜经营所",
        "230714411": "前卫林场"
    },
    "230715": {
        "230715002": "东升社区",
        "230715003": "前进社区",
        "230715004": "团结社区",
        "230715400": "清水河经营所",
        "230715401": "五星河经营所",
        "230715402": "汤洪岭林场",
        "230715403": "汤北林场",
        "230715404": "霍吉河林场",
        "230715405": "二杨经营所",
        "230715406": "三杨林场",
        "230715407": "汤南林场",
        "230715408": "共青经营所",
        "230715409": "库斯特林场",
        "230715410": "二皮河经营所"
    },
    "230716": {
        "230716001": "为民社区",
        "230716002": "安民社区",
        "230716003": "便民社区",
        "230716004": "乐民社区",
        "230716400": "山峰林场",
        "230716401": "阳光林场",
        "230716402": "新风林场",
        "230716403": "卫国林场",
        "230716404": "查山经营所",
        "230716405": "长青经营所",
        "230716406": "青山农场",
        "230716407": "溪水经营所",
        "230716408": "蔚兰经营所",
        "230716409": "永绪经营所",
        "230716410": "美林经营所",
        "230716411": "红山经营所"
    },
    "230722": {
        "230722100": "朝阳镇",
        "230722101": "乌云镇",
        "230722102": "乌拉嘎镇",
        "230722103": "保兴镇",
        "230722200": "常胜乡",
        "230722201": "向阳乡",
        "230722202": "沪嘉乡",
        "230722203": "红光乡",
        "230722205": "青山乡",
        "230722400": "太平林场",
        "230722402": "清河林场",
        "230722404": "马连林场",
        "230722500": "嘉荫农场"
    },
    "230781": {
        "230781100": "铁力镇",
        "230781101": "双丰镇",
        "230781102": "桃山镇",
        "230781104": "朗乡镇",
        "230781200": "年丰乡",
        "230781201": "工农乡",
        "230781202": "王杨乡",
        "230781400": "铁力林业局",
        "230781401": "双丰林业局",
        "230781402": "桃山林业局",
        "230781403": "朗乡林业局",
        "230781500": "铁力农场"
    },
    "230800": {
        "230803": "向阳区",
        "230804": "前进区",
        "230805": "东风区",
        "230811": "郊区",
        "230822": "桦南县",
        "230826": "桦川县",
        "230828": "汤原县",
        "230881": "同江市",
        "230882": "富锦市",
        "230883": "抚远市"
    },
    "230803": {
        "230803001": "西林街道",
        "230803002": "保卫街道",
        "230803003": "桥南街道",
        "230803004": "西南岗街道",
        "230803005": "建设街道",
        "230803006": "长安街道",
        "230803198": "向阳区（镇）直辖地域"
    },
    "230804": {"230804198": "前进区镇直辖", "230804579": "前进区农垦"},
    "230805": {
        "230805001": "晓云街道",
        "230805002": "佳东街道",
        "230805003": "造纸街道",
        "230805004": "佳南街道",
        "230805005": "建国街道",
        "230805100": "建国镇",
        "230805200": "松江乡",
        "230805579": "东风区农垦"
    },
    "230811": {
        "230811001": "佳西街道",
        "230811002": "友谊街道",
        "230811101": "大来镇",
        "230811102": "敖其镇",
        "230811103": "望江镇",
        "230811104": "长发镇",
        "230811105": "莲江口镇",
        "230811106": "西格木镇",
        "230811200": "长青乡",
        "230811201": "沿江乡",
        "230811208": "平安乡",
        "230811210": "四丰乡",
        "230811211": "群胜乡",
        "230811480": "莲江口监狱",
        "230811579": "郊区农垦"
    },
    "230822": {
        "230822103": "驼腰子镇",
        "230822104": "石头河子镇",
        "230822106": "桦南镇",
        "230822107": "土龙山镇",
        "230822108": "孟家岗镇",
        "230822109": "闫家镇",
        "230822205": "金沙乡",
        "230822213": "梨树乡",
        "230822214": "明义乡",
        "230822215": "大八浪乡",
        "230822400": "桦南林业局",
        "230822480": "黑龙江桦南经济开发区",
        "230822500": "曙光农场",
        "230822580": "桦南种畜场"
    },
    "230826": {
        "230826101": "横头山镇",
        "230826103": "苏家店镇",
        "230826104": "悦来镇",
        "230826105": "新城镇",
        "230826106": "四马架镇",
        "230826201": "东河乡",
        "230826202": "梨丰乡",
        "230826205": "创业乡",
        "230826207": "星火乡",
        "230826500": "江川农场",
        "230826501": "宝山农场"
    },
    "230828": {
        "230828101": "香兰镇",
        "230828102": "鹤立镇",
        "230828103": "竹帘镇",
        "230828104": "汤原镇",
        "230828201": "汤旺乡",
        "230828203": "胜利乡",
        "230828208": "吉祥乡",
        "230828209": "振兴乡",
        "230828210": "太平川乡",
        "230828211": "永发乡",
        "230828400": "鹤立林业局",
        "230828480": "香兰监狱",
        "230828500": "汤原农场",
        "230828501": "梧桐河农场"
    },
    "230881": {
        "230881001": "同江市城市管理委员会",
        "230881100": "同江镇",
        "230881101": "乐业镇",
        "230881102": "三村镇",
        "230881103": "临江镇",
        "230881104": "向阳镇",
        "230881105": "青河镇",
        "230881204": "街津口乡",
        "230881205": "八岔乡",
        "230881206": "金川乡",
        "230881207": "银川乡",
        "230881400": "街津口林场",
        "230881401": "鸭北林场",
        "230881500": "勤得利农场",
        "230881501": "青龙山农场",
        "230881502": "前进农场",
        "230881503": "洪河农场",
        "230881504": "鸭绿河农场",
        "230881505": "浓江农场",
        "230881580": "良种场",
        "230881581": "畜牧场",
        "230881582": "知青农场"
    },
    "230882": {
        "230882001": "城东街道",
        "230882003": "城西街道",
        "230882102": "富锦镇",
        "230882103": "长安镇",
        "230882106": "砚山镇",
        "230882107": "头林镇",
        "230882108": "兴隆岗镇",
        "230882109": "宏胜镇",
        "230882110": "向阳川镇",
        "230882111": "二龙山镇",
        "230882112": "上街基镇",
        "230882113": "锦山镇",
        "230882114": "大榆树镇",
        "230882400": "石砬山林场",
        "230882401": "东风岗林场",
        "230882402": "太东林场",
        "230882403": "工农林场",
        "230882404": "富锦市国营原种场",
        "230882405": "富锦市国营果树示范场",
        "230882406": "富锦市科研所",
        "230882407": "工业园区",
        "230882500": "建三江管理局局直",
        "230882501": "七星农场",
        "230882502": "大兴农场",
        "230882503": "创业农场",
        "230882580": "种猪场",
        "230882581": "种畜场"
    },
    "230883": {
        "230883100": "抚远镇",
        "230883101": "寒葱沟镇",
        "230883102": "浓桥镇",
        "230883103": "乌苏镇",
        "230883104": "黑瞎子岛镇",
        "230883200": "通江乡",
        "230883201": "浓江乡",
        "230883202": "海青乡",
        "230883203": "别拉洪乡",
        "230883204": "鸭南乡",
        "230883500": "前哨农场",
        "230883501": "前锋农场",
        "230883502": "二道河农场"
    },
    "230900": {"230902": "新兴区", "230903": "桃山区", "230904": "茄子河区", "230921": "勃利县"},
    "230902": {
        "230902011": "兴安社区公共服务站",
        "230902012": "兴乐社区公共服务站",
        "230902013": "兴富社区公共服务站",
        "230902014": "兴秀社区公共服务站",
        "230902015": "兴平社区公共服务站",
        "230902016": "兴和社区公共服务站",
        "230902017": "兴城社区公共服务站",
        "230902018": "兴盛社区公共服务站",
        "230902019": "北山社区公共服务站",
        "230902020": "欣源社区公共服务站",
        "230902021": "冬梅社区公共服务站",
        "230902022": "枫叶社区公共服务站",
        "230902023": "安居社区公共服务站",
        "230902024": "河南社区公共服务站",
        "230902025": "新立社区公共服务站",
        "230902026": "新建社区公共服务站",
        "230902027": "新城社区公共服务站",
        "230902028": "缸窑沟社区公共服务站",
        "230902100": "红旗镇",
        "230902200": "长兴乡",
        "230902400": "黑龙江七台河经济开发区管理委员会",
        "230902480": "金沙新区管理委员会（金沙农场）",
        "230902500": "北兴农场",
        "230902579": "黑龙江红兴隆农垦宝利采金"
    },
    "230903": {
        "230903031": "祥和社区公共服务站",
        "230903032": "运管社区公共服务站",
        "230903033": "安康社区公共服务站",
        "230903034": "文苑社区公共服务站",
        "230903035": "朝阳社区公共服务站",
        "230903036": "东正社区公共服务站",
        "230903037": "旭日社区公共服务站",
        "230903038": "花园社区公共服务站",
        "230903039": "银泉社区公共服务站",
        "230903040": "运销社区公共服务站",
        "230903041": "同仁社区公共服务站",
        "230903042": "东方社区公共服务站",
        "230903043": "学府社区公共服务站",
        "230903044": "桃山社区公共服务站",
        "230903045": "长青社区公共服务站",
        "230903100": "万宝河镇"
    },
    "230904": {
        "230904011": "东风社区公共服务站",
        "230904012": "康富社区公共服务站",
        "230904013": "湖东社区公共服务站",
        "230904014": "康乐社区公共服务站",
        "230904015": "永泰社区公共服务站",
        "230904016": "通达社区公共服务站",
        "230904017": "惠民社区公共服务站",
        "230904018": "安民社区公共服务站",
        "230904019": "盛馨社区公共服务站",
        "230904020": "欣苑社区公共服务站",
        "230904021": "东胜社区公共服务站",
        "230904022": "富强社区公共服务站",
        "230904023": "龙湖社区公共服务站",
        "230904100": "茄子河镇",
        "230904101": "宏伟镇",
        "230904200": "铁山乡",
        "230904201": "中心河乡"
    },
    "230921": {
        "230921001": "新起街道",
        "230921002": "新华街道",
        "230921003": "元明街道",
        "230921004": "铁西街道",
        "230921005": "城西街道",
        "230921100": "勃利镇",
        "230921101": "小五站镇",
        "230921102": "大四站镇",
        "230921103": "双河镇",
        "230921104": "倭肯镇",
        "230921200": "青山乡",
        "230921202": "永恒乡",
        "230921203": "抢垦乡",
        "230921204": "杏树朝鲜族乡",
        "230921205": "吉兴朝鲜族满族乡"
    },
    "231000": {
        "231002": "东安区",
        "231003": "阳明区",
        "231004": "爱民区",
        "231005": "西安区",
        "231025": "林口县",
        "231071": "牡丹江经济技术开发区",
        "231081": "绥芬河市",
        "231083": "海林市",
        "231084": "宁安市",
        "231085": "穆棱市",
        "231086": "东宁市"
    },
    "231002": {"231002001": "新安街道", "231002002": "长安街道", "231002003": "七星街道", "231002004": "五星街道", "231002101": "兴隆镇"},
    "231003": {
        "231003001": "阳明街道",
        "231003002": "前进街道",
        "231003003": "新兴街道",
        "231003004": "桦林橡胶厂街道",
        "231003100": "铁岭镇",
        "231003101": "桦林镇",
        "231003102": "磨刀石镇",
        "231003103": "五林镇"
    },
    "231004": {
        "231004001": "向阳街道",
        "231004002": "黄花街道",
        "231004003": "铁北街道",
        "231004004": "新华街道",
        "231004005": "大庆街道",
        "231004006": "兴平街道",
        "231004007": "北山街道",
        "231004100": "三道关镇"
    },
    "231005": {
        "231005001": "先锋街道",
        "231005002": "火炬街道",
        "231005003": "立新街道",
        "231005004": "牡丹街道",
        "231005005": "江滨街道",
        "231005006": "沿江街道",
        "231005100": "温春镇",
        "231005201": "海南朝鲜族乡"
    },
    "231025": {
        "231025100": "林口镇",
        "231025101": "古城镇",
        "231025102": "刁翎镇",
        "231025104": "朱家镇",
        "231025105": "柳树镇",
        "231025106": "三道通镇",
        "231025107": "龙爪镇",
        "231025108": "莲花镇",
        "231025110": "青山镇",
        "231025111": "建堂镇",
        "231025112": "奎山镇",
        "231025400": "林口林业局"
    },
    "231071": {"231071001": "牡丹江经济开发区城乡社区工作委员会"},
    "231081": {"231081100": "绥芬河镇", "231081101": "阜宁镇"},
    "231083": {
        "231083100": "海林镇",
        "231083101": "长汀镇",
        "231083102": "横道镇",
        "231083103": "山市镇",
        "231083104": "柴河镇",
        "231083105": "二道镇",
        "231083106": "新安朝鲜族镇",
        "231083107": "三道镇",
        "231083400": "牡林工程公司街道",
        "231083401": "柴河林机厂街道",
        "231083402": "大海林林业局",
        "231083403": "海林林业局",
        "231083404": "柴河林业局",
        "231083500": "海林农场",
        "231083501": "山市种奶牛场"
    },
    "231084": {
        "231084001": "城区街道",
        "231084100": "宁安镇",
        "231084101": "东京城镇",
        "231084102": "渤海镇",
        "231084103": "石岩镇",
        "231084104": "沙兰镇",
        "231084105": "海浪镇",
        "231084106": "兰岗镇",
        "231084107": "镜泊镇",
        "231084202": "江南朝鲜族满族乡",
        "231084206": "卧龙朝鲜族乡",
        "231084208": "马河乡",
        "231084211": "三陵乡",
        "231084400": "东京城林业局",
        "231084500": "宁安农场"
    },
    "231085": {
        "231085100": "八面通镇",
        "231085101": "穆棱镇",
        "231085102": "下城子镇",
        "231085103": "马桥河镇",
        "231085104": "兴源镇",
        "231085200": "福录乡",
        "231085201": "河西乡",
        "231085202": "共和乡",
        "231085400": "八面通林业局",
        "231085401": "穆棱林业局"
    },
    "231086": {
        "231086100": "东宁镇",
        "231086101": "三岔口镇",
        "231086102": "大肚川镇",
        "231086103": "老黑山镇",
        "231086104": "道河镇",
        "231086105": "绥阳镇",
        "231086400": "绥阳林业局"
    },
    "231100": {"231102": "爱辉区", "231121": "嫩江县", "231123": "逊克县", "231124": "孙吴县", "231181": "北安市", "231182": "五大连池市"},
    "231102": {
        "231102001": "花园街道",
        "231102002": "兴安街道",
        "231102003": "海兰街道",
        "231102004": "西兴街道",
        "231102101": "西岗子镇",
        "231102102": "瑷珲镇",
        "231102103": "罕达汽镇",
        "231102200": "幸福乡",
        "231102201": "四嘉子乡",
        "231102202": "坤河乡",
        "231102203": "上马厂乡",
        "231102204": "张地营子乡",
        "231102205": "西峰山乡",
        "231102206": "新生乡",
        "231102207": "二站乡",
        "231102407": "大平林场",
        "231102408": "七二七林场",
        "231102409": "望峰林场",
        "231102410": "卡伦山林场",
        "231102411": "胜山林场",
        "231102412": "二站林场",
        "231102413": "三站林场",
        "231102414": "大岭林场",
        "231102415": "桦皮窑林场",
        "231102416": "滨南林场",
        "231102417": "江防林场",
        "231102418": "河南屯林场",
        "231102421": "西岗子试验林场",
        "231102425": "大新屯储木场",
        "231102480": "宋集屯煤矿",
        "231102481": "西岗子煤矿",
        "231102482": "东方红煤矿",
        "231102500": "锦河农场",
        "231102580": "奶牛场",
        "231102581": "种畜场",
        "231102582": "第二良种场",
        "231102583": "第一良种场"
    },
    "231121": {
        "231121100": "嫩江镇",
        "231121101": "伊拉哈镇",
        "231121102": "双山镇",
        "231121103": "多宝山镇",
        "231121104": "海江镇",
        "231121105": "前进镇",
        "231121106": "长福镇",
        "231121107": "科洛镇",
        "231121108": "霍龙门镇",
        "231121202": "临江乡",
        "231121206": "联兴乡",
        "231121207": "白云乡",
        "231121208": "塔溪乡",
        "231121211": "长江乡",
        "231121400": "多宝山铜矿",
        "231121500": "九三管理局局直",
        "231121501": "鹤山农场",
        "231121502": "大西江农场",
        "231121503": "尖山农场",
        "231121504": "荣军农场",
        "231121505": "红五月农场",
        "231121506": "七星泡农场",
        "231121507": "嫩江农场",
        "231121508": "山河农场",
        "231121509": "嫩北农场",
        "231121510": "建边农场",
        "231121581": "马铃薯原种繁殖场"
    },
    "231123": {
        "231123001": "奇克街道",
        "231123100": "奇克镇",
        "231123101": "逊河镇",
        "231123102": "逊克县克林镇",
        "231123201": "干岔子乡",
        "231123202": "松树沟乡",
        "231123203": "车陆乡",
        "231123204": "新鄂乡",
        "231123205": "新兴乡",
        "231123207": "宝山乡",
        "231123402": "道干林场",
        "231123403": "干岔子林场",
        "231123404": "新立林场",
        "231123405": "三间房林场",
        "231123406": "良种场",
        "231123407": "种畜场",
        "231123500": "逊克农场"
    },
    "231124": {
        "231124100": "孙吴镇",
        "231124101": "辰清镇",
        "231124201": "西兴乡",
        "231124202": "沿江满族乡",
        "231124203": "腰屯乡",
        "231124204": "卧牛河乡",
        "231124205": "群山乡",
        "231124206": "奋斗乡",
        "231124207": "红旗乡",
        "231124208": "正阳山乡",
        "231124210": "清溪乡",
        "231124402": "前进林场",
        "231124403": "大河口林场",
        "231124404": "正阳林场",
        "231124405": "平山林场",
        "231124406": "沿江林场",
        "231124407": "辰清林场",
        "231124408": "红旗林场",
        "231124409": "向阳林场",
        "231124410": "良种场",
        "231124411": "种畜场",
        "231124500": "红色边疆农场"
    },
    "231181": {
        "231181001": "兆麟街道",
        "231181002": "和平街道",
        "231181003": "北岗街道",
        "231181004": "庆华街道",
        "231181005": "铁西街道",
        "231181006": "铁南街道",
        "231181101": "通北镇",
        "231181102": "赵光镇",
        "231181103": "海星镇",
        "231181104": "石泉镇",
        "231181105": "二井镇",
        "231181200": "城郊乡",
        "231181201": "东胜乡",
        "231181203": "杨家乡",
        "231181207": "主星乡",
        "231181400": "通北林业局",
        "231181500": "北安管理局局直",
        "231181501": "长水河农场",
        "231181502": "赵光农场",
        "231181503": "红星农场",
        "231181504": "建设农场"
    },
    "231182": {
        "231182001": "青山街道",
        "231182101": "龙镇",
        "231182102": "和平镇",
        "231182103": "五大连池镇",
        "231182104": "双泉镇",
        "231182105": "新发镇",
        "231182106": "团结镇",
        "231182107": "兴隆镇",
        "231182203": "建设乡",
        "231182205": "太平乡",
        "231182208": "兴安乡",
        "231182210": "朝阳乡",
        "231182406": "元青山林场",
        "231182407": "三九六林场",
        "231182408": "小兴安林场",
        "231182409": "二龙山林场",
        "231182410": "朝阳林场",
        "231182411": "引龙河林场",
        "231182412": "焦得布林场",
        "231182414": "小孤山林场",
        "231182415": "药泉林场",
        "231182417": "沾河林业局",
        "231182418": "莲花管理委员会",
        "231182427": "二龙山蚕种场",
        "231182429": "畜牧场",
        "231182430": "第二良种场",
        "231182480": "花园劳教所",
        "231182481": "永丰监狱",
        "231182482": "华山监狱",
        "231182483": "凤凰山监狱",
        "231182501": "龙门农场",
        "231182502": "襄河农场",
        "231182503": "龙镇农场",
        "231182504": "二龙山农场",
        "231182505": "引龙河农场",
        "231182506": "尾山农场",
        "231182507": "格球山农场",
        "231182508": "五大连池农场"
    },
    "231200": {
        "231202": "北林区",
        "231221": "望奎县",
        "231222": "兰西县",
        "231223": "青冈县",
        "231224": "庆安县",
        "231225": "明水县",
        "231226": "绥棱县",
        "231281": "安达市",
        "231282": "肇东市",
        "231283": "海伦市"
    },
    "231202": {
        "231202001": "紫来街道",
        "231202002": "爱路街道",
        "231202003": "大有街道",
        "231202004": "吉泰街道",
        "231202005": "东兴街道",
        "231202006": "北林街道",
        "231202007": "朝旭街道",
        "231202008": "春雷街道",
        "231202009": "北辰街道",
        "231202010": "康庄街道",
        "231202011": "先锋街道",
        "231202012": "东城街道",
        "231202101": "宝山镇",
        "231202102": "绥胜满族镇",
        "231202103": "西长发镇",
        "231202104": "永安满族镇",
        "231202105": "太平川镇",
        "231202106": "秦家镇",
        "231202107": "双河镇",
        "231202108": "三河镇",
        "231202109": "四方台镇",
        "231202110": "津河镇",
        "231202111": "张维镇",
        "231202113": "东津镇",
        "231202114": "东富镇",
        "231202115": "兴福镇",
        "231202201": "红旗满族乡",
        "231202202": "连岗乡",
        "231202203": "新华乡",
        "231202209": "三井乡",
        "231202212": "五营乡",
        "231202213": "兴和朝鲜族乡",
        "231202579": "绥化局直"
    },
    "231221": {
        "231221001": "东风街道",
        "231221002": "前进街道",
        "231221003": "东胜街道",
        "231221004": "兴安街道",
        "231221100": "望奎镇",
        "231221101": "通江镇",
        "231221102": "卫星镇",
        "231221103": "海丰镇",
        "231221104": "莲花镇",
        "231221105": "惠七满族镇",
        "231221106": "先锋镇",
        "231221107": "火箭镇",
        "231221108": "东郊镇",
        "231221109": "灯塔镇",
        "231221202": "灵山满族乡",
        "231221203": "后三乡",
        "231221209": "东升乡",
        "231221210": "恭六乡",
        "231221211": "厢白满族乡",
        "231221400": "白四林场",
        "231221580": "种畜场",
        "231221581": "第一良种场",
        "231221582": "第二良种场"
    },
    "231222": {
        "231222001": "颜河街道",
        "231222002": "顺达街道",
        "231222003": "兰亚街道",
        "231222004": "正阳街道",
        "231222101": "兰西镇",
        "231222102": "榆林镇",
        "231222103": "临江镇",
        "231222104": "平山镇",
        "231222105": "红光镇",
        "231222106": "远大镇",
        "231222107": "康荣镇",
        "231222108": "燎原镇",
        "231222202": "北安乡",
        "231222203": "长江乡",
        "231222204": "兰河乡",
        "231222205": "红星乡",
        "231222207": "长岗乡",
        "231222210": "星火乡",
        "231222211": "奋斗乡",
        "231222580": "第一原种场",
        "231222581": "第二原种场",
        "231222582": "兰西县畜牧技术推广服务中心"
    },
    "231223": {
        "231223001": "北城街道",
        "231223002": "西城街道",
        "231223003": "靖城街道",
        "231223004": "东城街道",
        "231223100": "青冈镇",
        "231223101": "中和镇",
        "231223102": "祯祥镇",
        "231223103": "兴华镇",
        "231223104": "永丰镇",
        "231223105": "芦河镇",
        "231223106": "民政镇",
        "231223107": "柞岗镇",
        "231223108": "劳动镇",
        "231223109": "迎春镇",
        "231223110": "德胜镇",
        "231223202": "建设乡",
        "231223203": "新村乡",
        "231223206": "昌盛乡",
        "231223212": "连丰乡",
        "231223400": "青冈县林场",
        "231223401": "青冈县互利营林站",
        "231223402": "青冈县果树场",
        "231223580": "青冈县种马场",
        "231223581": "青冈县种羊场",
        "231223582": "青冈县原种场"
    },
    "231224": {
        "231224001": "吉康街道",
        "231224002": "庆瑞街道",
        "231224003": "平顺街道",
        "231224004": "安泰街道",
        "231224101": "庆安镇",
        "231224102": "民乐镇",
        "231224103": "大罗镇",
        "231224104": "平安镇",
        "231224105": "勤劳镇",
        "231224106": "久胜镇",
        "231224107": "同乐镇",
        "231224108": "柳河镇",
        "231224201": "建民乡",
        "231224202": "巨宝山乡",
        "231224206": "丰收乡",
        "231224208": "发展乡",
        "231224210": "致富乡",
        "231224211": "欢胜乡",
        "231224400": "国有林场管理局",
        "231224500": "柳河农场"
    },
    "231225": {
        "231225001": "明阳街道",
        "231225002": "明源街道",
        "231225003": "明新街道",
        "231225004": "明泉街道",
        "231225100": "明水镇",
        "231225101": "兴仁镇",
        "231225102": "永兴镇",
        "231225103": "崇德镇",
        "231225104": "通达镇",
        "231225106": "双兴镇",
        "231225203": "永久乡",
        "231225204": "树人乡",
        "231225205": "光荣乡",
        "231225206": "繁荣乡",
        "231225207": "通泉乡",
        "231225208": "育林乡",
        "231225400": "国营林场",
        "231225580": "县联社马场",
        "231225581": "明水种畜场",
        "231225582": "育林畜牧场",
        "231225583": "国营第一良种",
        "231225584": "国营第二良种",
        "231225585": "国营第三良种"
    },
    "231226": {
        "231226001": "车站街道",
        "231226002": "建设街道",
        "231226003": "西北街道",
        "231226004": "东南街道",
        "231226100": "绥棱镇",
        "231226101": "上集镇",
        "231226102": "四海店镇",
        "231226103": "双岔河镇",
        "231226104": "阁山镇",
        "231226105": "长山镇",
        "231226200": "靠山乡",
        "231226201": "后头乡",
        "231226205": "克音河乡",
        "231226206": "绥中乡",
        "231226207": "泥尔河乡",
        "231226400": "阁山林场",
        "231226401": "四海店林场",
        "231226402": "三吉台林场",
        "231226403": "半截河林场",
        "231226404": "绥棱林业局",
        "231226500": "绥棱农场",
        "231226580": "种马场",
        "231226581": "良种场",
        "231226582": "奶山羊场"
    },
    "231281": {
        "231281001": "铁西街道",
        "231281002": "新兴街道",
        "231281003": "安虹街道",
        "231281004": "东城街道",
        "231281100": "安达镇",
        "231281101": "任民镇",
        "231281103": "万宝山镇",
        "231281104": "昌德镇",
        "231281105": "升平镇",
        "231281106": "羊草镇",
        "231281107": "老虎岗镇",
        "231281108": "中本镇",
        "231281109": "太平庄镇",
        "231281110": "吉兴岗镇",
        "231281111": "卧里屯镇",
        "231281202": "火石山乡",
        "231281205": "青肯泡乡",
        "231281209": "先源乡",
        "231281500": "安达畜牧场",
        "231281581": "原种场",
        "231281583": "红旗泡水库管理所",
        "231281584": "东湖水库管理站"
    },
    "231282": {
        "231282001": "朝阳区",
        "231282002": "东升区",
        "231282003": "西园区",
        "231282004": "正阳区",
        "231282100": "肇东镇",
        "231282101": "昌五镇",
        "231282102": "宋站镇",
        "231282103": "五站镇",
        "231282104": "尚家镇",
        "231282105": "姜家镇",
        "231282106": "里木店镇",
        "231282107": "四站镇",
        "231282108": "涝洲镇",
        "231282109": "五里明镇",
        "231282110": "黎明镇",
        "231282111": "西八里镇",
        "231282201": "太平乡",
        "231282203": "海城乡",
        "231282204": "向阳乡",
        "231282205": "洪河乡",
        "231282206": "跃进乡",
        "231282212": "德昌乡",
        "231282213": "宣化乡",
        "231282214": "安民乡",
        "231282215": "明久乡",
        "231282500": "四方山农场",
        "231282501": "涝洲鱼种场"
    },
    "231283": {
        "231283100": "海伦镇",
        "231283101": "海北镇",
        "231283102": "伦河镇",
        "231283103": "共合镇",
        "231283104": "海兴镇",
        "231283105": "祥富镇",
        "231283106": "东风镇",
        "231283107": "百祥镇",
        "231283108": "向荣镇",
        "231283109": "永富镇",
        "231283110": "长发镇",
        "231283111": "联发镇",
        "231283112": "前进镇",
        "231283113": "共荣镇",
        "231283205": "东林乡",
        "231283208": "海南乡",
        "231283210": "乐业乡",
        "231283211": "福民乡",
        "231283212": "丰山乡",
        "231283217": "永和乡",
        "231283218": "爱民乡",
        "231283220": "扎音河乡",
        "231283221": "双录乡",
        "231283400": "林场公司",
        "231283401": "东方红水库地区",
        "231283500": "海伦农场",
        "231283501": "红光农场",
        "231283581": "海伦市董家农作物种植场",
        "231283582": "海伦市东方农牧场"
    },
    "232700": {
        "232701": "漠河市",
        "232721": "呼玛县",
        "232722": "塔河县",
        "232761": "加格达奇区",
        "232762": "松岭区",
        "232763": "新林区",
        "232764": "呼中区"
    },
    "232701": {
        "232701100": "西林吉镇",
        "232701101": "图强镇",
        "232701102": "阿木尔镇",
        "232701103": "兴安镇",
        "232701104": "北极镇",
        "232701105": "古莲镇",
        "232701400": "西林吉林业局",
        "232701401": "图强林业局",
        "232701402": "阿木尔林业局"
    },
    "232721": {
        "232721100": "呼玛镇",
        "232721101": "韩家园镇",
        "232721200": "三卡乡",
        "232721201": "金山乡",
        "232721202": "兴华乡",
        "232721203": "鸥浦乡",
        "232721204": "白银纳鄂伦春族民族乡",
        "232721205": "北疆乡",
        "232721400": "韩家园林业局"
    },
    "232722": {
        "232722100": "塔河镇",
        "232722101": "瓦拉干镇",
        "232722102": "盘古镇",
        "232722103": "古驿镇",
        "232722200": "十八站鄂伦春族民族乡",
        "232722201": "依西肯乡",
        "232722202": "开库康乡",
        "232722400": "十八站林业局"
    },
    "232761": {
        "232761001": "东山街道",
        "232761002": "卫东街道",
        "232761003": "红旗街道",
        "232761004": "长虹街道",
        "232761005": "曙光街道",
        "232761006": "光明街道",
        "232761200": "加北乡",
        "232761201": "白桦乡",
        "232761400": "加格达奇林业局"
    },
    "232762": {"232762100": "小扬气镇", "232762101": "劲松镇", "232762102": "古源镇"},
    "232763": {
        "232763100": "新林镇",
        "232763101": "翠岗镇",
        "232763102": "塔源镇",
        "232763103": "大乌苏镇",
        "232763104": "塔尔根镇",
        "232763105": "碧洲镇",
        "232763106": "宏图镇"
    },
    "232764": {"232764100": "呼中镇", "232764101": "碧水镇", "232764102": "呼源镇", "232764103": "宏伟镇"},
    "310000": {"310100": "上海市"},
    "310100": {
        "310101": "黄浦区",
        "310104": "徐汇区",
        "310105": "长宁区",
        "310106": "静安区",
        "310107": "普陀区",
        "310109": "虹口区",
        "310110": "杨浦区",
        "310112": "闵行区",
        "310113": "宝山区",
        "310114": "嘉定区",
        "310115": "浦东新区",
        "310116": "金山区",
        "310117": "松江区",
        "310118": "青浦区",
        "310120": "奉贤区",
        "310151": "崇明区"
    },
    "310101": {
        "310101002": "南京东路街道",
        "310101013": "外滩街道",
        "310101015": "半淞园路街道",
        "310101017": "小东门街道",
        "310101018": "豫园街道",
        "310101019": "老西门街道",
        "310101020": "五里桥街道",
        "310101021": "打浦桥街道",
        "310101022": "淮海中路街道",
        "310101023": "瑞金二路街道"
    },
    "310104": {
        "310104003": "天平路街道",
        "310104004": "湖南路街道",
        "310104007": "斜土路街道",
        "310104008": "枫林路街道",
        "310104010": "长桥街道",
        "310104011": "田林街道",
        "310104012": "虹梅路街道",
        "310104013": "康健新村街道",
        "310104014": "徐家汇街道",
        "310104015": "凌云路街道",
        "310104016": "龙华街道",
        "310104017": "漕河泾街道",
        "310104103": "华泾镇",
        "310104501": "漕河泾新兴技术开发区"
    },
    "310105": {
        "310105001": "华阳路街道",
        "310105002": "江苏路街道",
        "310105004": "新华路街道",
        "310105005": "周家桥街道",
        "310105006": "天山路街道",
        "310105008": "仙霞新村街道",
        "310105009": "虹桥街道",
        "310105010": "程家桥街道",
        "310105011": "北新泾街道",
        "310105102": "新泾镇"
    },
    "310106": {
        "310106006": "江宁路街道",
        "310106011": "石门二路街道",
        "310106012": "南京西路街道",
        "310106013": "静安寺街道",
        "310106014": "曹家渡街道",
        "310106015": "天目西路街道",
        "310106016": "北站街道",
        "310106017": "宝山路街道",
        "310106018": "共和新路街道",
        "310106019": "大宁路街道",
        "310106020": "彭浦新村街道",
        "310106021": "临汾路街道",
        "310106022": "芷江西路街道",
        "310106101": "彭浦镇"
    },
    "310107": {
        "310107005": "曹杨新村街道",
        "310107014": "长风新村街道",
        "310107015": "长寿路街道",
        "310107016": "甘泉路街道",
        "310107017": "石泉路街道",
        "310107020": "宜川路街道",
        "310107021": "万里街道",
        "310107022": "真如镇街道",
        "310107102": "长征镇",
        "310107103": "桃浦镇"
    },
    "310109": {
        "310109009": "欧阳路街道",
        "310109010": "曲阳路街道",
        "310109011": "广中路街道",
        "310109014": "嘉兴路街道",
        "310109016": "凉城新村街道",
        "310109017": "四川北路街道",
        "310109018": "北外滩街道",
        "310109019": "江湾镇街道"
    },
    "310110": {
        "310110001": "定海路街道",
        "310110006": "平凉路街道",
        "310110008": "江浦路街道",
        "310110009": "四平路街道",
        "310110012": "控江路街道",
        "310110013": "长白新村街道",
        "310110015": "延吉新村街道",
        "310110016": "殷行街道",
        "310110018": "大桥街道",
        "310110019": "五角场街道",
        "310110020": "新江湾城街道",
        "310110101": "五角场镇"
    },
    "310112": {
        "310112001": "江川路街道",
        "310112006": "古美街道",
        "310112008": "新虹街道",
        "310112009": "浦锦街道",
        "310112101": "莘庄镇",
        "310112102": "七宝镇",
        "310112103": "颛桥镇",
        "310112106": "华漕镇",
        "310112107": "虹桥镇",
        "310112108": "梅陇镇",
        "310112110": "吴泾镇",
        "310112112": "马桥镇",
        "310112114": "浦江镇",
        "310112501": "莘庄工业区"
    },
    "310113": {
        "310113003": "友谊路街道",
        "310113007": "吴淞街道",
        "310113008": "张庙街道",
        "310113101": "罗店镇",
        "310113102": "大场镇",
        "310113103": "杨行镇",
        "310113104": "月浦镇",
        "310113106": "罗泾镇",
        "310113109": "顾村镇",
        "310113111": "高境镇",
        "310113112": "庙行镇",
        "310113113": "淞南镇",
        "310113501": "宝山城市工业园区"
    },
    "310114": {
        "310114001": "新成路街道",
        "310114002": "真新街道",
        "310114003": "菊园新区管委会",
        "310114004": "嘉定镇街道",
        "310114102": "南翔镇",
        "310114103": "安亭镇",
        "310114106": "马陆镇",
        "310114109": "徐行镇",
        "310114111": "华亭镇",
        "310114114": "外冈镇",
        "310114118": "江桥镇",
        "310114501": "嘉定工业区"
    },
    "310115": {
        "310115004": "潍坊新村街道",
        "310115005": "陆家嘴街道",
        "310115007": "周家渡街道",
        "310115008": "塘桥街道",
        "310115009": "上钢新村街道",
        "310115010": "南码头路街道",
        "310115011": "沪东新村街道",
        "310115012": "金杨新村街道",
        "310115013": "洋泾街道",
        "310115014": "浦兴路街道",
        "310115015": "东明路街道",
        "310115016": "花木街道",
        "310115103": "川沙新镇",
        "310115104": "高桥镇",
        "310115105": "北蔡镇",
        "310115110": "合庆镇",
        "310115114": "唐镇",
        "310115117": "曹路镇",
        "310115120": "金桥镇",
        "310115121": "高行镇",
        "310115123": "高东镇",
        "310115125": "张江镇",
        "310115130": "三林镇",
        "310115131": "惠南镇",
        "310115132": "周浦镇",
        "310115133": "新场镇",
        "310115134": "大团镇",
        "310115136": "康桥镇",
        "310115137": "航头镇",
        "310115139": "祝桥镇",
        "310115140": "泥城镇",
        "310115141": "宣桥镇",
        "310115142": "书院镇",
        "310115143": "万祥镇",
        "310115144": "老港镇",
        "310115145": "南汇新城镇",
        "310115401": "芦潮港农场",
        "310115402": "东海农场",
        "310115403": "朝阳农场",
        "310115501": "中国（上海）自由贸易试验区（保税片区）",
        "310115502": "金桥经济技术开发区",
        "310115503": "张江高科技园区"
    },
    "310116": {
        "310116001": "石化街道",
        "310116101": "朱泾镇",
        "310116102": "枫泾镇",
        "310116103": "张堰镇",
        "310116104": "亭林镇",
        "310116105": "吕巷镇",
        "310116107": "廊下镇",
        "310116109": "金山卫镇",
        "310116112": "漕泾镇",
        "310116113": "山阳镇",
        "310116503": "金山工业区"
    },
    "310117": {
        "310117001": "岳阳街道",
        "310117002": "永丰街道",
        "310117003": "方松街道",
        "310117004": "中山街道",
        "310117005": "广富林街道",
        "310117006": "九里亭街道",
        "310117102": "泗泾镇",
        "310117103": "佘山镇",
        "310117104": "车墩镇",
        "310117105": "新桥镇",
        "310117106": "洞泾镇",
        "310117107": "九亭镇",
        "310117109": "泖港镇",
        "310117116": "石湖荡镇",
        "310117117": "新浜镇",
        "310117120": "叶榭镇",
        "310117121": "小昆山镇",
        "310117501": "松江工业区",
        "310117504": "佘山度假区",
        "310117507": "上海松江出口加工区"
    },
    "310118": {
        "310118001": "夏阳街道",
        "310118002": "盈浦街道",
        "310118003": "香花桥街道",
        "310118102": "朱家角镇",
        "310118103": "练塘镇",
        "310118104": "金泽镇",
        "310118105": "赵巷镇",
        "310118106": "徐泾镇",
        "310118107": "华新镇",
        "310118109": "重固镇",
        "310118110": "白鹤镇"
    },
    "310120": {
        "310120001": "西渡街道",
        "310120002": "奉浦街道",
        "310120101": "南桥镇",
        "310120102": "奉城镇",
        "310120104": "庄行镇",
        "310120106": "金汇镇",
        "310120109": "四团镇",
        "310120111": "青村镇",
        "310120118": "柘林镇",
        "310120123": "海湾镇",
        "310120503": "上海市奉贤区海湾旅游区",
        "310120504": "金海社区"
    },
    "310151": {
        "310151101": "城桥镇",
        "310151102": "堡镇",
        "310151103": "新河镇",
        "310151104": "庙镇",
        "310151105": "竖新镇",
        "310151106": "向化镇",
        "310151107": "三星镇",
        "310151108": "港沿镇",
        "310151109": "中兴镇",
        "310151110": "陈家镇",
        "310151111": "绿华镇",
        "310151112": "港西镇",
        "310151113": "建设镇",
        "310151114": "新海镇",
        "310151115": "东平镇",
        "310151116": "长兴镇",
        "310151201": "新村乡",
        "310151202": "横沙乡",
        "310151401": "前卫农场",
        "310151402": "东平林场",
        "310151501": "上实现代农业园区"
    },
    "320000": {
        "320100": "南京市",
        "320200": "无锡市",
        "320300": "徐州市",
        "320400": "常州市",
        "320500": "苏州市",
        "320600": "南通市",
        "320700": "连云港市",
        "320800": "淮安市",
        "320900": "盐城市",
        "321000": "扬州市",
        "321100": "镇江市",
        "321200": "泰州市",
        "321300": "宿迁市"
    },
    "320100": {
        "320102": "玄武区",
        "320104": "秦淮区",
        "320105": "建邺区",
        "320106": "鼓楼区",
        "320111": "浦口区",
        "320113": "栖霞区",
        "320114": "雨花台区",
        "320115": "江宁区",
        "320116": "六合区",
        "320117": "溧水区",
        "320118": "高淳区"
    },
    "320102": {
        "320102002": "梅园新村街道",
        "320102003": "新街口街道",
        "320102005": "玄武门街道",
        "320102007": "锁金村街道",
        "320102008": "红山街道",
        "320102009": "孝陵卫街道",
        "320102010": "玄武湖街道",
        "320102400": "徐庄高新技术产业开发区"
    },
    "320104": {
        "320104001": "秦虹街道",
        "320104002": "夫子庙街道",
        "320104003": "红花街道",
        "320104004": "双塘街道",
        "320104005": "中华门街道",
        "320104006": "五老村街道",
        "320104007": "洪武路街道",
        "320104008": "大光路街道",
        "320104009": "瑞金路街道",
        "320104010": "月牙湖街道",
        "320104011": "光华路街道",
        "320104012": "朝天宫街道",
        "320104400": "夫管办",
        "320104401": "高新园区"
    },
    "320105": {
        "320105001": "莫愁湖街道",
        "320105002": "沙洲街道",
        "320105003": "双闸街道",
        "320105004": "江心洲街道",
        "320105006": "兴隆街道",
        "320105007": "南苑街道",
        "320105400": "建邺区新城科技园",
        "320105401": "建邺区河西中央商务区",
        "320105402": "建邺区江东商业文化旅游中心区",
        "320105403": "中新南京生态科技岛"
    },
    "320106": {
        "320106001": "宁海路街道",
        "320106002": "华侨路街道",
        "320106003": "湖南路街道",
        "320106004": "中央门街道",
        "320106005": "挹江门街道",
        "320106006": "江东街道",
        "320106007": "凤凰街道",
        "320106008": "下关街道",
        "320106009": "热河南路街道",
        "320106010": "幕府山街道",
        "320106011": "建宁路街道",
        "320106012": "宝塔桥街道",
        "320106013": "小市街道"
    },
    "320111": {
        "320111001": "泰山街道",
        "320111002": "顶山街道",
        "320111003": "沿江街道",
        "320111004": "江浦街道",
        "320111005": "桥林街道",
        "320111006": "汤泉街道",
        "320111007": "盘城街道",
        "320111008": "星甸街道",
        "320111009": "永宁街道",
        "320111400": "老山林场",
        "320111401": "汤泉农场",
        "320111402": "珍珠泉管委会",
        "320111403": "浦口经济开发区",
        "320111404": "南京浦口高新技术产业开发区",
        "320111450": "高新技术开发区"
    },
    "320113": {
        "320113001": "尧化街道",
        "320113002": "马群街道",
        "320113003": "迈皋桥街道",
        "320113004": "燕子矶街道",
        "320113005": "栖霞街道",
        "320113006": "龙潭街道",
        "320113007": "仙林街道",
        "320113009": "八卦洲街道",
        "320113010": "西岗街道",
        "320113400": "龙潭监狱",
        "320113401": "栖霞经济开发区",
        "320113402": "南京龙潭物流园",
        "320113403": "南京市靖安三江口工业园",
        "320113405": "南京市马群科技园",
        "320113406": "南京仙林大学城",
        "320113407": "江苏生命科技创新园",
        "320113408": "江苏金箔文化产业园",
        "320113409": "南京大学科学园",
        "320113410": "南京紫东国际创意园",
        "320113411": "南京金港科技创业中心",
        "320113412": "新港环保科技园",
        "320113413": "仙林智谷",
        "320113450": "南京经济技术开发区"
    },
    "320114": {
        "320114001": "雨花街道",
        "320114002": "赛虹桥街道",
        "320114004": "西善桥街道",
        "320114005": "板桥街道",
        "320114006": "铁心桥街道",
        "320114007": "梅山街道",
        "320114400": "雨花经济开发区",
        "320114401": "板桥新城",
        "320114402": "中国（南京）软件谷"
    },
    "320115": {
        "320115001": "东山街道",
        "320115002": "秣陵街道",
        "320115003": "汤山街道",
        "320115004": "淳化街道",
        "320115005": "禄口街道",
        "320115006": "江宁街道",
        "320115007": "谷里街道",
        "320115008": "湖熟街道",
        "320115009": "横溪街道",
        "320115010": "麒麟街道",
        "320115401": "青龙山林场",
        "320115402": "江宁开发区",
        "320115403": "江宁滨江开发区",
        "320115404": "江宁高新技术产业园",
        "320115405": "麒麟科技创新园（生态科技城）"
    },
    "320116": {
        "320116001": "大厂街道",
        "320116004": "葛塘街道",
        "320116005": "长芦街道",
        "320116006": "雄州街道",
        "320116007": "龙池街道",
        "320116008": "程桥街道",
        "320116009": "金牛湖街道",
        "320116010": "横梁街道",
        "320116011": "龙袍街道",
        "320116012": "马鞍街道",
        "320116013": "冶山街道",
        "320116110": "竹镇镇",
        "320116400": "六合开发区",
        "320116401": "新材料产业园",
        "320116450": "化工园区"
    },
    "320117": {
        "320117001": "永阳街道",
        "320117002": "柘塘街道",
        "320117003": "东屏街道",
        "320117004": "石湫街道",
        "320117005": "洪蓝街道",
        "320117101": "白马镇",
        "320117106": "晶桥镇",
        "320117107": "和凤镇",
        "320117400": "溧水开发区"
    },
    "320118": {
        "320118001": "淳溪街道",
        "320118002": "古柏街道",
        "320118101": "阳江镇",
        "320118102": "砖墙镇",
        "320118104": "漆桥镇",
        "320118105": "固城镇",
        "320118106": "东坝镇",
        "320118108": "桠溪镇",
        "320118400": "高淳县团结圩良种繁育场",
        "320118401": "高淳县茶叶实验场",
        "320118402": "高淳监狱",
        "320118403": "高淳县傅家坛林场"
    },
    "320200": {
        "320205": "锡山区",
        "320206": "惠山区",
        "320211": "滨湖区",
        "320213": "梁溪区",
        "320214": "新吴区",
        "320281": "江阴市",
        "320282": "宜兴市"
    },
    "320205": {
        "320205001": "东亭街道",
        "320205002": "安镇街道",
        "320205003": "东北塘街道",
        "320205102": "羊尖镇",
        "320205103": "鹅湖镇",
        "320205105": "锡北镇",
        "320205106": "东港镇",
        "320205400": "无锡锡山开发区",
        "320205401": "锡山台湾农民创业园"
    },
    "320206": {
        "320206001": "堰桥街道",
        "320206002": "长安街道",
        "320206003": "钱桥街道",
        "320206004": "前洲街道",
        "320206005": "玉祁街道",
        "320206102": "洛社镇",
        "320206103": "阳山镇",
        "320206400": "无锡惠山经济开发区",
        "320206500": "无锡惠山工业转型集聚区"
    },
    "320211": {
        "320211001": "河埒街道",
        "320211002": "荣巷街道",
        "320211003": "蠡园街道",
        "320211004": "蠡湖街道",
        "320211006": "华庄街道",
        "320211007": "太湖街道",
        "320211008": "雪浪街道",
        "320211009": "马山街道",
        "320211101": "胡埭镇"
    },
    "320213": {
        "320213001": "崇安寺街道",
        "320213002": "通江街道",
        "320213003": "广瑞路街道",
        "320213004": "上马墩街道",
        "320213005": "江海街道",
        "320213006": "广益街道",
        "320213007": "迎龙桥街道",
        "320213008": "南禅寺街道",
        "320213009": "清名桥街道",
        "320213010": "金星街道",
        "320213011": "金匮街道",
        "320213012": "扬名街道",
        "320213013": "黄巷街道",
        "320213014": "山北街道",
        "320213015": "北大街街道",
        "320213016": "惠山街道"
    },
    "320214": {
        "320214050": "旺庄街道",
        "320214051": "硕放街道",
        "320214052": "江溪街道",
        "320214053": "新安街道",
        "320214054": "梅村街道",
        "320214055": "鸿山街道",
        "320214450": "无锡新加坡工业园",
        "320214451": "无锡高新技术产业开发区",
        "320214452": "无锡高新区综合保税区",
        "320214453": "无锡太湖国际科技园"
    },
    "320281": {
        "320281001": "澄江街道",
        "320281004": "南闸街道",
        "320281005": "云亭街道",
        "320281007": "申港街道",
        "320281008": "利港街道",
        "320281009": "夏港街道",
        "320281100": "璜土镇",
        "320281102": "月城镇",
        "320281103": "青阳镇",
        "320281104": "徐霞客镇",
        "320281107": "华士镇",
        "320281108": "周庄镇",
        "320281109": "新桥镇",
        "320281110": "长泾镇",
        "320281111": "顾山镇",
        "320281112": "祝塘镇",
        "320281400": "江阴高新技术产业开发区",
        "320281403": "江苏江阴靖江工业园区",
        "320281404": "临港经济开发区",
        "320281405": "青阳园区"
    },
    "320282": {
        "320282001": "宜城街道",
        "320282002": "屺亭街道",
        "320282003": "新庄街道",
        "320282004": "新街街道",
        "320282005": "芳桥街道",
        "320282100": "张渚镇",
        "320282101": "西渚镇",
        "320282102": "太华镇",
        "320282103": "徐舍镇",
        "320282104": "官林镇",
        "320282105": "杨巷镇",
        "320282106": "新建镇",
        "320282107": "和桥镇",
        "320282108": "高塍镇",
        "320282109": "万石镇",
        "320282110": "周铁镇",
        "320282112": "丁蜀镇",
        "320282113": "湖父镇",
        "320282400": "环科园",
        "320282401": "宜兴经济开发区"
    },
    "320300": {
        "320302": "鼓楼区",
        "320303": "云龙区",
        "320305": "贾汪区",
        "320311": "泉山区",
        "320312": "铜山区",
        "320321": "丰县",
        "320322": "沛县",
        "320324": "睢宁县",
        "320371": "徐州经济技术开发区",
        "320381": "新沂市",
        "320382": "邳州市"
    },
    "320302": {
        "320302001": "黄楼街道",
        "320302002": "丰财街道",
        "320302003": "琵琶街道",
        "320302004": "牌楼街道",
        "320302005": "铜沛街道",
        "320302006": "环城街道",
        "320302007": "九里街道"
    },
    "320303": {
        "320303001": "彭城街道",
        "320303002": "子房街道",
        "320303003": "黄山街道",
        "320303004": "骆驼山街道",
        "320303005": "大郭庄街道",
        "320303006": "翠屏山街道",
        "320303007": "大龙湖街道",
        "320303008": "潘塘街道",
        "320303400": "淮海食品城",
        "320303401": "户部山回龙窝历史文化街区管理中心"
    },
    "320305": {
        "320305001": "老矿街道",
        "320305003": "大泉街道",
        "320305004": "大吴街道",
        "320305005": "潘安湖街道",
        "320305006": "茱萸山街道",
        "320305102": "青山泉镇",
        "320305104": "紫庄镇",
        "320305105": "塔山镇",
        "320305106": "汴塘镇",
        "320305107": "江庄镇",
        "320305401": "鹿庄粮棉原种场",
        "320305402": "工业园区管委会"
    },
    "320311": {
        "320311001": "王陵街道",
        "320311002": "七里沟街道",
        "320311003": "永安街道",
        "320311004": "湖滨街道",
        "320311005": "段庄街道",
        "320311006": "翟山街道",
        "320311007": "奎山街道",
        "320311008": "和平街道",
        "320311009": "金山街道",
        "320311010": "泰山街道",
        "320311011": "庞庄街道",
        "320311012": "火花街道",
        "320311013": "桃园街道",
        "320311014": "苏山街道",
        "320311401": "徐州泉山经济开发区",
        "320311402": "徐州软件园管理委员会"
    },
    "320312": {
        "320312001": "三河尖街道",
        "320312002": "张双楼街道",
        "320312003": "垞城街道",
        "320312004": "张集街道",
        "320312006": "利国街道",
        "320312007": "电厂街道",
        "320312008": "拾屯街道",
        "320312009": "铜山街道",
        "320312010": "沿湖街道",
        "320312051": "新区街道",
        "320312052": "三堡街道",
        "320312102": "何桥镇",
        "320312103": "黄集镇",
        "320312104": "马坡镇",
        "320312105": "郑集镇",
        "320312106": "柳新镇",
        "320312107": "刘集镇",
        "320312108": "大彭镇",
        "320312109": "汉王镇",
        "320312111": "棠张镇",
        "320312112": "张集镇",
        "320312113": "房村镇",
        "320312114": "伊庄镇",
        "320312115": "单集镇",
        "320312116": "利国镇",
        "320312118": "大许镇",
        "320312119": "茅村镇",
        "320312120": "柳泉镇",
        "320312402": "徐州高新技术产业开发区",
        "320312403": "江苏铜山经济开发区"
    },
    "320321": {
        "320321001": "中阳里街道",
        "320321002": "凤城街道",
        "320321003": "孙楼街道",
        "320321102": "首羡镇",
        "320321103": "顺河镇",
        "320321104": "常店镇",
        "320321105": "欢口镇",
        "320321106": "师寨镇",
        "320321107": "华山镇",
        "320321108": "梁寨镇",
        "320321109": "范楼镇",
        "320321111": "宋楼镇",
        "320321112": "大沙河镇",
        "320321113": "王沟镇",
        "320321114": "赵庄镇",
        "320321401": "大沙河林场"
    },
    "320322": {
        "320322001": "沛城街道",
        "320322002": "大屯街道",
        "320322003": "汉源街道",
        "320322004": "汉兴街道",
        "320322101": "龙固镇",
        "320322102": "杨屯镇",
        "320322105": "胡寨镇",
        "320322106": "魏庙镇",
        "320322107": "五段镇",
        "320322108": "张庄镇",
        "320322109": "张寨镇",
        "320322110": "敬安镇",
        "320322111": "河口镇",
        "320322112": "栖山镇",
        "320322113": "鹿楼镇",
        "320322114": "朱寨镇",
        "320322115": "安国镇",
        "320322401": "中煤集团大屯煤电（集团）公司",
        "320322403": "沛县湖西农场管理委员会"
    },
    "320324": {
        "320324001": "睢城街道",
        "320324002": "金城街道",
        "320324003": "睢河街道",
        "320324102": "王集镇",
        "320324103": "双沟镇",
        "320324104": "岚山镇",
        "320324105": "李集镇",
        "320324106": "桃园镇",
        "320324107": "官山镇",
        "320324108": "高作镇",
        "320324109": "沙集镇",
        "320324110": "凌城镇",
        "320324111": "邱集镇",
        "320324112": "古邳镇",
        "320324113": "姚集镇",
        "320324114": "魏集镇",
        "320324115": "梁集镇",
        "320324116": "庆安镇",
        "320324401": "经济开发区",
        "320324405": "空港经济开发区"
    },
    "320371": {
        "320371001": "金山桥街道",
        "320371002": "东环街道",
        "320371003": "大黄山街道",
        "320371004": "大庙街道",
        "320371005": "金龙湖街道",
        "320371101": "徐庄镇"
    },
    "320381": {
        "320381001": "新安街道",
        "320381002": "北沟街道",
        "320381003": "墨河街道",
        "320381004": "唐店街道",
        "320381102": "瓦窑镇",
        "320381103": "港头镇",
        "320381105": "合沟镇",
        "320381106": "草桥镇",
        "320381107": "窑湾镇",
        "320381108": "棋盘镇",
        "320381109": "马陵山镇",
        "320381110": "新店镇",
        "320381111": "邵店镇",
        "320381113": "时集镇",
        "320381114": "高流镇",
        "320381115": "阿湖镇",
        "320381116": "双塘镇",
        "320381401": "经济开发区",
        "320381402": "锡沂高新技术产业开发区",
        "320381403": "马陵山风景名胜区"
    },
    "320382": {
        "320382001": "东湖街道",
        "320382002": "运河街道",
        "320382003": "戴圩街道",
        "320382004": "炮车街道",
        "320382102": "邳城镇",
        "320382103": "官湖镇",
        "320382104": "四户镇",
        "320382105": "宿羊山镇",
        "320382106": "八义集镇",
        "320382107": "土山镇",
        "320382108": "碾庄镇",
        "320382109": "港上镇",
        "320382110": "邹庄镇",
        "320382111": "占城镇",
        "320382112": "新河镇",
        "320382113": "八路镇",
        "320382115": "铁富镇",
        "320382116": "岔河镇",
        "320382118": "陈楼镇",
        "320382119": "邢楼镇",
        "320382120": "戴庄镇",
        "320382121": "车辐山镇",
        "320382122": "燕子埠镇",
        "320382123": "赵墩镇",
        "320382124": "议堂镇",
        "320382401": "邳城农场",
        "320382402": "张楼农场",
        "320382403": "江苏邳州经济开发区"
    },
    "320400": {"320402": "天宁区", "320404": "钟楼区", "320411": "新北区", "320412": "武进区", "320413": "金坛区", "320481": "溧阳市"},
    "320402": {
        "320402001": "雕庄街道",
        "320402002": "青龙街道",
        "320402003": "茶山街道",
        "320402004": "红梅街道",
        "320402005": "天宁街道",
        "320402006": "兰陵街道",
        "320402109": "郑陆镇"
    },
    "320404": {
        "320404001": "五星街道",
        "320404002": "永红街道",
        "320404003": "北港街道",
        "320404004": "西林街道",
        "320404005": "南大街街道",
        "320404006": "荷花池街道",
        "320404007": "新闸街道",
        "320404116": "邹区镇"
    },
    "320411": {
        "320411001": "河海街道",
        "320411002": "三井街道",
        "320411003": "龙虎塘街道",
        "320411100": "春江镇",
        "320411101": "孟河镇",
        "320411102": "新桥镇",
        "320411103": "薛家镇",
        "320411104": "罗溪镇",
        "320411105": "西夏墅镇",
        "320411122": "奔牛镇"
    },
    "320412": {
        "320412001": "戚墅堰街道",
        "320412002": "丁堰街道",
        "320412003": "潞城街道",
        "320412100": "湖塘镇",
        "320412102": "牛塘镇",
        "320412103": "洛阳镇",
        "320412104": "遥观镇",
        "320412105": "横林镇",
        "320412106": "横山桥镇",
        "320412110": "雪堰镇",
        "320412113": "前黄镇",
        "320412114": "礼嘉镇",
        "320412119": "嘉泽镇",
        "320412120": "湟里镇",
        "320412400": "武进经济开发区",
        "320412411": "高新区",
        "320412412": "西太湖生态休闲区",
        "320412413": "太湖旅游度假区管委会"
    },
    "320413": {
        "320413001": "西城街道",
        "320413100": "金城镇",
        "320413104": "儒林镇",
        "320413106": "直溪镇",
        "320413108": "朱林镇",
        "320413109": "薛埠镇",
        "320413112": "指前镇",
        "320413400": "开发区",
        "320413401": "茅山旅游度假区"
    },
    "320481": {
        "320481001": "昆仑街道",
        "320481100": "溧城镇",
        "320481101": "埭头镇",
        "320481102": "上黄镇",
        "320481103": "戴埠镇",
        "320481106": "天目湖镇",
        "320481107": "别桥镇",
        "320481109": "上兴镇",
        "320481111": "竹箦镇",
        "320481114": "南渡镇",
        "320481116": "社渚镇",
        "320481400": "常州监狱",
        "320481401": "溧阳监狱"
    },
    "320500": {
        "320505": "虎丘区",
        "320506": "吴中区",
        "320507": "相城区",
        "320508": "姑苏区",
        "320509": "吴江区",
        "320571": "苏州工业园区",
        "320581": "常熟市",
        "320582": "张家港市",
        "320583": "昆山市",
        "320585": "太仓市"
    },
    "320505": {
        "320505001": "横塘街道",
        "320505002": "狮山街道",
        "320505003": "枫桥街道",
        "320505004": "镇湖街道",
        "320505005": "东渚街道",
        "320505100": "浒墅关镇",
        "320505101": "通安镇",
        "320505400": "浒墅关经济技术开发区",
        "320505405": "苏州高新技术创业服务中心",
        "320505407": "苏州科技城",
        "320505408": "苏州高新区综合保税区"
    },
    "320506": {
        "320506001": "长桥街道",
        "320506003": "越溪街道",
        "320506004": "郭巷街道",
        "320506005": "横泾街道",
        "320506006": "香山街道",
        "320506009": "城南街道",
        "320506010": "太湖街道",
        "320506100": "甪直镇",
        "320506103": "木渎镇",
        "320506104": "胥口镇",
        "320506107": "东山镇",
        "320506108": "光福镇",
        "320506109": "金庭镇",
        "320506110": "临湖镇",
        "320506400": "江苏省太湖采石公司",
        "320506401": "吴中区林场",
        "320506402": "西山农业园区",
        "320506403": "穹窿山风景区",
        "320506404": "开发区",
        "320506405": "国家苏州太湖旅游度假区"
    },
    "320507": {
        "320507001": "元和街道",
        "320507002": "太平街道",
        "320507003": "黄桥街道",
        "320507004": "北桥街道",
        "320507005": "北河泾街道",
        "320507006": "漕湖街道",
        "320507007": "澄阳街道",
        "320507100": "望亭镇",
        "320507102": "黄埭镇",
        "320507105": "渭塘镇",
        "320507109": "阳澄湖镇",
        "320507401": "阳澄湖生态休闲旅游度假区",
        "320507402": "苏州高铁新城"
    },
    "320508": {
        "320508017": "白洋湾街道",
        "320508018": "平江街道",
        "320508019": "金阊街道",
        "320508020": "沧浪街道",
        "320508021": "双塔街道",
        "320508022": "虎丘街道",
        "320508023": "苏锦街道",
        "320508024": "吴门桥街道"
    },
    "320509": {
        "320509100": "松陵镇",
        "320509104": "平望镇",
        "320509105": "盛泽镇",
        "320509107": "七都镇",
        "320509108": "震泽镇",
        "320509109": "桃源镇",
        "320509110": "黎里镇",
        "320509400": "吴江经济技术开发区",
        "320509401": "东方丝绸市场"
    },
    "320571": {
        "320571050": "娄葑街道",
        "320571051": "斜塘街道",
        "320571052": "唯亭街道",
        "320571053": "胜浦街道",
        "320571400": "湖西社区工作委员会",
        "320571401": "湖东社区工作委员会",
        "320571402": "月亮湾社区工作委员会",
        "320571403": "东沙湖社区工作委员会"
    },
    "320581": {
        "320581001": "虞山街道",
        "320581002": "常福街道",
        "320581003": "琴川街道",
        "320581004": "莫城街道",
        "320581005": "碧溪街道",
        "320581006": "东南街道",
        "320581101": "梅李镇",
        "320581102": "海虞镇",
        "320581104": "古里镇",
        "320581105": "沙家浜镇",
        "320581106": "支塘镇",
        "320581107": "董浜镇",
        "320581110": "辛庄镇",
        "320581111": "尚湖镇",
        "320581401": "常熟经济技术开发区",
        "320581402": "常熟高新技术产业开发区",
        "320581406": "常熟昆承湖管理委员会",
        "320581407": "常熟国家大学科技园"
    },
    "320582": {
        "320582100": "杨舍镇",
        "320582101": "塘桥镇",
        "320582102": "金港镇",
        "320582103": "锦丰镇",
        "320582104": "乐余镇",
        "320582105": "凤凰镇",
        "320582106": "南丰镇",
        "320582107": "大新镇",
        "320582400": "张家港市现代农业示范园区",
        "320582401": "张家港经济技术开发区",
        "320582403": "张家港保税区",
        "320582404": "张家港市市稻麦良种场",
        "320582405": "张家港市畜禽良种场",
        "320582406": "张家港市冶金工业园",
        "320582407": "双山岛旅游度假区"
    },
    "320583": {
        "320583100": "玉山镇",
        "320583101": "巴城镇",
        "320583102": "周市镇",
        "320583103": "陆家镇",
        "320583104": "花桥镇",
        "320583105": "淀山湖镇",
        "320583106": "张浦镇",
        "320583107": "周庄镇",
        "320583108": "千灯镇",
        "320583109": "锦溪镇",
        "320583400": "开发区"
    },
    "320585": {
        "320585100": "城厢镇",
        "320585101": "沙溪镇",
        "320585102": "浏河镇",
        "320585103": "浮桥镇",
        "320585104": "璜泾镇",
        "320585105": "双凤镇",
        "320585400": "经济开发区",
        "320585403": "科教新城"
    },
    "320600": {
        "320602": "崇川区",
        "320611": "港闸区",
        "320612": "通州区",
        "320623": "如东县",
        "320671": "南通经济技术开发区",
        "320681": "启东市",
        "320682": "如皋市",
        "320684": "海门市",
        "320685": "海安市"
    },
    "320602": {
        "320602001": "城东街道",
        "320602002": "和平桥街道",
        "320602003": "任港街道",
        "320602004": "新城桥街道",
        "320602005": "虹桥街道",
        "320602006": "学田街道",
        "320602007": "钟秀街道",
        "320602008": "文峰街道",
        "320602009": "观音山街道",
        "320602010": "狼山镇街道",
        "320602400": "南通崇川经济开发区"
    },
    "320611": {
        "320611001": "永兴街道",
        "320611002": "唐闸镇街道",
        "320611003": "天生港镇街道",
        "320611004": "秦灶街道",
        "320611005": "陈桥街道",
        "320611006": "幸福街道",
        "320611400": "港闸开发区"
    },
    "320612": {
        "320612001": "金沙街道",
        "320612002": "兴东街道",
        "320612003": "先锋街道",
        "320612004": "金新街道",
        "320612101": "西亭镇",
        "320612102": "二甲镇",
        "320612103": "东社镇",
        "320612104": "三余镇",
        "320612107": "十总镇",
        "320612110": "石港镇",
        "320612112": "刘桥镇",
        "320612114": "平潮镇",
        "320612116": "五接镇",
        "320612117": "兴仁镇",
        "320612119": "张芝山镇",
        "320612120": "川姜镇",
        "320612401": "环本农场"
    },
    "320623": {
        "320623100": "栟茶镇",
        "320623101": "洋口镇",
        "320623102": "苴镇",
        "320623103": "长沙镇",
        "320623104": "大豫镇",
        "320623105": "掘港镇",
        "320623107": "马塘镇",
        "320623108": "丰利镇",
        "320623109": "曹埠镇",
        "320623110": "岔河镇",
        "320623111": "双甸镇",
        "320623112": "新店镇",
        "320623113": "河口镇",
        "320623114": "袁庄镇",
        "320623400": "如东县经济开发区",
        "320623401": "如东沿海经济开发区",
        "320623402": "如东洋口港经济开发区",
        "320623403": "如东东安科技园区",
        "320623404": "如东沿海旅游经济开发区",
        "320623405": "南通外向型农业开发区"
    },
    "320671": {
        "320671001": "中兴街道",
        "320671002": "新开街道",
        "320671003": "竹行街道",
        "320671004": "小海街道",
        "320671400": "江海镇区",
        "320671401": "南通市富民港良种场",
        "320671402": "南通市富民港种畜场",
        "320671403": "南通苏通科技产业园"
    },
    "320681": {
        "320681100": "汇龙镇",
        "320681102": "惠萍镇",
        "320681104": "东海镇",
        "320681106": "南阳镇",
        "320681107": "海复镇",
        "320681108": "合作镇",
        "320681109": "王鲍镇",
        "320681110": "吕四港镇",
        "320681400": "启东经济开发区",
        "320681401": "启东滨海工业园",
        "320681402": "启东滨江化工园",
        "320681403": "江苏吕四海洋经济开发区",
        "320681404": "启隆生态科技产业园",
        "320681405": "启东海工船舶工业园",
        "320681406": "启东江海产业园",
        "320681407": "圆陀角旅游度假区"
    },
    "320682": {
        "320682001": "如城街道",
        "320682103": "东陈镇",
        "320682104": "丁堰镇",
        "320682105": "白蒲镇",
        "320682107": "下原镇",
        "320682108": "九华镇",
        "320682110": "石庄镇",
        "320682111": "长江镇",
        "320682112": "吴窑镇",
        "320682113": "江安镇",
        "320682116": "搬经镇",
        "320682117": "磨头镇",
        "320682400": "如皋市经济开发区",
        "320682401": "如皋高新技术产业开发区",
        "320682402": "南通市粮棉原种场",
        "320682403": "如皋市蚕种场",
        "320682404": "如皋市良种场",
        "320682406": "如皋市种猪场",
        "320682407": "如皋市农科所",
        "320682408": "如皋市江滨医院",
        "320682409": "如皋市中心沙水产养殖场",
        "320682410": "如皋市九华水产养殖场"
    },
    "320684": {
        "320684106": "常乐镇",
        "320684108": "悦来镇",
        "320684111": "四甲镇",
        "320684113": "余东镇",
        "320684114": "正余镇",
        "320684117": "海永镇",
        "320684400": "海门市经济技术开发区",
        "320684401": "海门市永隆沙农场",
        "320684403": "海门市大东农场",
        "320684404": "海门市畜禽良种场",
        "320684405": "海门市种羊场",
        "320684406": "海门市包场渔场",
        "320684407": "海门市长江芦荡养殖场",
        "320684408": "海门市沿江渔场",
        "320684409": "海门市三厂苗圃",
        "320684410": "海门市农科所",
        "320684412": "海门市财政局芦荡管理所",
        "320684413": "江苏省国营江心沙农场",
        "320684414": "海门市临江新区管理委员会",
        "320684415": "海门港新区",
        "320684416": "海门工业园区管理委员会",
        "320684417": "海门高新区",
        "320684418": "三厂工业园区"
    },
    "320685": {
        "320685100": "海安镇",
        "320685101": "城东镇",
        "320685102": "曲塘镇",
        "320685103": "李堡镇",
        "320685105": "角斜镇",
        "320685107": "大公镇",
        "320685109": "雅周镇",
        "320685111": "白甸镇",
        "320685112": "南莫镇",
        "320685113": "墩头镇",
        "320685400": "海安县农场"
    },
    "320700": {
        "320703": "连云区",
        "320706": "海州区",
        "320707": "赣榆区",
        "320722": "东海县",
        "320723": "灌云县",
        "320724": "灌南县",
        "320771": "连云港经济技术开发区",
        "320772": "连云港高新技术产业开发区"
    },
    "320703": {
        "320703001": "墟沟街道",
        "320703003": "连云街道",
        "320703004": "云山街道",
        "320703005": "板桥街道",
        "320703006": "连岛街道",
        "320703007": "徐圩街道",
        "320703008": "海州湾街道",
        "320703009": "宿城街道",
        "320703010": "高公岛街道",
        "320703203": "前三岛乡",
        "320703400": "连云港碱厂",
        "320703401": "东辛农场",
        "320703402": "连云经济技术开发区"
    },
    "320706": {
        "320706001": "朐阳街道",
        "320706006": "新海街道",
        "320706009": "云台街道",
        "320706010": "新浦街道",
        "320706011": "海州街道",
        "320706012": "幸福路街道",
        "320706013": "洪门街道",
        "320706014": "宁海街道",
        "320706016": "浦西街道",
        "320706017": "新东街道",
        "320706018": "新南街道",
        "320706019": "路南街道",
        "320706100": "锦屏镇",
        "320706102": "新坝镇",
        "320706103": "板浦镇",
        "320706104": "浦南镇",
        "320706402": "岗埠农场",
        "320706403": "新浦经济开发区",
        "320706404": "海州经济开发区"
    },
    "320707": {
        "320707100": "青口镇",
        "320707101": "柘汪镇",
        "320707102": "石桥镇",
        "320707103": "金山镇",
        "320707104": "黑林镇",
        "320707105": "厉庄镇",
        "320707106": "海头镇",
        "320707107": "塔山镇",
        "320707108": "赣马镇",
        "320707109": "班庄镇",
        "320707110": "城头镇",
        "320707112": "城西镇",
        "320707114": "宋庄镇",
        "320707115": "沙河镇",
        "320707116": "墩尚镇",
        "320707400": "沙河子园艺场",
        "320707402": "赣榆海洋经济开发区",
        "320707403": "赣榆经济开发区"
    },
    "320722": {
        "320722001": "牛山街道",
        "320722002": "石榴街道",
        "320722101": "白塔埠镇",
        "320722103": "黄川镇",
        "320722104": "石梁河镇",
        "320722105": "青湖镇",
        "320722107": "温泉镇",
        "320722108": "双店镇",
        "320722109": "桃林镇",
        "320722110": "洪庄镇",
        "320722111": "安峰镇",
        "320722112": "房山镇",
        "320722113": "平明镇",
        "320722200": "驼峰乡",
        "320722203": "李埝乡",
        "320722204": "山左口乡",
        "320722205": "石湖乡",
        "320722206": "曲阳乡",
        "320722207": "张湾乡",
        "320722401": "江苏东海经济开发区"
    },
    "320723": {
        "320723001": "侍庄街道",
        "320723100": "伊山镇",
        "320723102": "杨集镇",
        "320723103": "燕尾港镇",
        "320723104": "同兴镇",
        "320723105": "四队镇",
        "320723106": "圩丰镇",
        "320723107": "龙苴镇",
        "320723108": "下车镇",
        "320723109": "图河镇",
        "320723110": "东王集镇",
        "320723209": "小伊乡",
        "320723212": "南岗乡",
        "320723401": "五图河农场",
        "320723402": "灌西盐场",
        "320723403": "灌云经济开发区",
        "320723404": "灌云临港产业区"
    },
    "320724": {
        "320724100": "新安镇",
        "320724101": "堆沟港镇",
        "320724102": "田楼镇",
        "320724103": "北陈集镇",
        "320724104": "张店镇",
        "320724105": "三口镇",
        "320724106": "孟兴庄镇",
        "320724107": "汤沟镇",
        "320724108": "百禄镇",
        "320724109": "新集镇",
        "320724204": "李集乡"
    },
    "320771": {"320771001": "中云街道", "320771002": "猴嘴街道", "320771003": "朝阳街道", "320771450": "青口盐场"},
    "320772": {
        "320772001": "花果山街道",
        "320772002": "南城街道",
        "320772003": "郁州街道",
        "320772400": "云台农场",
        "320772401": "南云台林场"
    },
    "320800": {
        "320803": "淮安区",
        "320804": "淮阴区",
        "320812": "清江浦区",
        "320813": "洪泽区",
        "320826": "涟水县",
        "320830": "盱眙县",
        "320831": "金湖县",
        "320871": "淮安经济技术开发区"
    },
    "320803": {
        "320803001": "淮城街道",
        "320803002": "河下街道",
        "320803004": "山阳街道",
        "320803101": "平桥镇",
        "320803104": "朱桥镇",
        "320803106": "施河镇",
        "320803107": "车桥镇",
        "320803109": "流均镇",
        "320803110": "博里镇",
        "320803112": "复兴镇",
        "320803113": "苏嘴镇",
        "320803114": "钦工镇",
        "320803115": "顺河镇",
        "320803121": "漕运镇",
        "320803122": "石塘镇",
        "320803400": "白马湖农场",
        "320803401": "经济开发区",
        "320803402": "淮安新材料产业园"
    },
    "320804": {
        "320804001": "长江路街道",
        "320804002": "王家营街道",
        "320804003": "新渡口街道",
        "320804004": "古清口街道",
        "320804103": "南陈集镇",
        "320804107": "丁集镇",
        "320804109": "徐溜镇",
        "320804110": "渔沟镇",
        "320804113": "三树镇",
        "320804114": "高家堰镇",
        "320804115": "马头镇",
        "320804116": "刘老庄镇",
        "320804117": "淮高镇",
        "320804400": "淮安高新技术产业开发区"
    },
    "320812": {
        "320812001": "城南街道",
        "320812002": "清江街道",
        "320812003": "浦楼街道",
        "320812004": "闸口街道",
        "320812005": "清浦街道",
        "320812006": "府前街道",
        "320812007": "长西街道",
        "320812008": "淮海街道",
        "320812009": "长东街道",
        "320812010": "柳树湾街道",
        "320812011": "水渡口街道",
        "320812012": "清河街道",
        "320812100": "和平镇",
        "320812101": "武墩镇",
        "320812102": "盐河镇",
        "320812201": "黄码乡",
        "320812450": "宁连路"
    },
    "320813": {
        "320813001": "高良涧街道",
        "320813002": "朱坝街道",
        "320813003": "黄集街道",
        "320813101": "蒋坝镇",
        "320813103": "岔河镇",
        "320813104": "西顺河镇",
        "320813105": "老子山镇",
        "320813106": "三河镇",
        "320813110": "东双沟镇",
        "320813400": "洪泽经济开发区"
    },
    "320826": {
        "320826100": "涟城镇",
        "320826101": "高沟镇",
        "320826102": "唐集镇",
        "320826103": "保滩镇",
        "320826104": "大东镇",
        "320826105": "五港镇",
        "320826106": "梁岔镇",
        "320826107": "石湖镇",
        "320826108": "朱码镇",
        "320826109": "岔庙镇",
        "320826110": "东胡集镇",
        "320826111": "南集镇",
        "320826112": "义兴镇",
        "320826113": "成集镇",
        "320826114": "红窑镇",
        "320826115": "陈师镇",
        "320826116": "前进镇",
        "320826200": "徐集乡",
        "320826201": "黄营乡",
        "320826400": "杨口",
        "320826401": "方渡",
        "320826402": "南禄",
        "320826403": "小李集工业园区管委会",
        "320826404": "河网",
        "320826405": "时码",
        "320826406": "北集",
        "320826407": "灰墩",
        "320826408": "余圩",
        "320826409": "浅集",
        "320826410": "机场产业园区管委会",
        "320826411": "麻垛农业开发区管委会",
        "320826412": "经济开发区"
    },
    "320830": {
        "320830001": "盱城街道",
        "320830002": "太和街道",
        "320830003": "古桑街道",
        "320830101": "马坝镇",
        "320830102": "官滩镇",
        "320830104": "桂五镇",
        "320830106": "河桥镇",
        "320830107": "鲍集镇",
        "320830108": "黄花塘镇",
        "320830111": "淮河镇",
        "320830114": "天泉湖镇",
        "320830115": "管仲镇",
        "320830116": "穆店镇",
        "320830400": "三河农场",
        "320830401": "淮化集团"
    },
    "320831": {
        "320831001": "黎城街道",
        "320831002": "戴楼街道",
        "320831003": "金北街道",
        "320831101": "金南镇",
        "320831103": "塔集镇",
        "320831106": "前锋镇",
        "320831107": "吕良镇",
        "320831111": "银涂镇",
        "320831400": "宝应湖农场",
        "320831401": "复兴圩农场",
        "320831402": "粮棉原种场",
        "320831403": "金湖经济开发区"
    },
    "320871": {
        "320871050": "东湖",
        "320871051": "新港",
        "320871052": "广州路",
        "320871053": "枚乘路",
        "320871055": "金港路",
        "320871056": "张码",
        "320871057": "淮洪路",
        "320871060": "富城路",
        "320871061": "福地路",
        "320871062": "高铁新区",
        "320871150": "范集镇",
        "320871350": "钵池乡",
        "320871351": "徐杨乡",
        "320871352": "南马厂乡"
    },
    "320900": {
        "320902": "亭湖区",
        "320903": "盐都区",
        "320904": "大丰区",
        "320921": "响水县",
        "320922": "滨海县",
        "320923": "阜宁县",
        "320924": "射阳县",
        "320925": "建湖县",
        "320971": "盐城经济技术开发区",
        "320981": "东台市"
    },
    "320902": {
        "320902001": "五星街道",
        "320902002": "文峰街道",
        "320902003": "先锋街道",
        "320902004": "毓龙街道",
        "320902007": "新洋街道",
        "320902008": "大洋街道",
        "320902050": "伍佑街道（城南新区）",
        "320902051": "黄海街道（城南新区）",
        "320902052": "新河街道（城南新区）",
        "320902100": "南洋镇",
        "320902102": "新兴镇",
        "320902104": "便仓镇",
        "320902107": "盐东镇",
        "320902108": "黄尖镇",
        "320902400": "亭湖新区",
        "320902402": "环保产业园"
    },
    "320903": {
        "320903002": "张庄街道",
        "320903003": "盐龙街道",
        "320903004": "潘黄街道",
        "320903005": "滨湖街道",
        "320903006": "北龙港街道",
        "320903007": "中兴街道",
        "320903008": "葛武街道",
        "320903009": "北蒋街道",
        "320903010": "鞍湖街道",
        "320903011": "冈中街道",
        "320903012": "盐渎街道",
        "320903050": "新都街道（城南新区）",
        "320903100": "大纵湖镇",
        "320903102": "楼王镇",
        "320903103": "学富镇",
        "320903105": "尚庄镇",
        "320903108": "秦南镇",
        "320903109": "龙冈镇",
        "320903111": "郭猛镇",
        "320903112": "大冈镇",
        "320903402": "农村经济开发区"
    },
    "320904": {
        "320904001": "丰华街道",
        "320904002": "大中街道",
        "320904101": "草堰镇",
        "320904102": "白驹镇",
        "320904103": "刘庄镇",
        "320904104": "西团镇",
        "320904105": "小海镇",
        "320904106": "大桥镇",
        "320904107": "草庙镇",
        "320904108": "万盈镇",
        "320904109": "南阳镇",
        "320904110": "新丰镇",
        "320904111": "三龙镇",
        "320904400": "大中农场",
        "320904401": "方强农场",
        "320904402": "东坝头农场",
        "320904403": "大丰经济开发区管委会",
        "320904404": "大丰港经济开发区管委会",
        "320904405": "大丰常州高新区管委会"
    },
    "320921": {
        "320921100": "响水镇",
        "320921101": "陈家港镇",
        "320921102": "小尖镇",
        "320921103": "黄圩镇",
        "320921104": "大有镇",
        "320921105": "双港镇",
        "320921106": "南河镇",
        "320921107": "运河镇",
        "320921400": "县开发区",
        "320921401": "省属黄海农场",
        "320921402": "省属灌东盐场"
    },
    "320922": {
        "320922001": "东坎街道",
        "320922002": "坎南街道",
        "320922003": "坎北街道",
        "320922101": "五汛镇",
        "320922102": "蔡桥镇",
        "320922103": "正红镇",
        "320922104": "通榆镇",
        "320922105": "界牌镇",
        "320922106": "八巨镇",
        "320922107": "八滩镇",
        "320922109": "滨淮镇",
        "320922110": "天场镇",
        "320922111": "陈涛镇",
        "320922112": "滨海港镇",
        "320922400": "滨淮农场",
        "320922401": "新滩盐场",
        "320922403": "开发区沿海工业园",
        "320922404": "滨海港经济区",
        "320922405": "滨海县现代农业产业园区"
    },
    "320923": {
        "320923001": "阜城街道",
        "320923002": "吴滩街道",
        "320923003": "花园街道（开发区）",
        "320923004": "金沙湖街道",
        "320923100": "沟墩镇",
        "320923101": "陈良镇",
        "320923102": "三灶镇",
        "320923103": "郭墅镇（澳洋工业园）",
        "320923104": "新沟镇",
        "320923105": "陈集镇",
        "320923106": "羊寨镇",
        "320923107": "芦蒲镇",
        "320923108": "板湖镇",
        "320923109": "东沟镇",
        "320923110": "益林镇",
        "320923111": "古河镇",
        "320923112": "罗桥镇",
        "320923402": "现代服务业园区"
    },
    "320924": {
        "320924100": "合德镇",
        "320924101": "临海镇",
        "320924102": "千秋镇",
        "320924104": "四明镇",
        "320924106": "海河镇",
        "320924108": "海通镇",
        "320924109": "兴桥镇",
        "320924110": "新坍镇",
        "320924111": "长荡镇",
        "320924112": "盘湾镇",
        "320924113": "特庸镇",
        "320924114": "洋马镇",
        "320924117": "黄沙港镇",
        "320924400": "县经济开发区",
        "320924401": "淮海农场",
        "320924402": "临海农场",
        "320924403": "新洋农场",
        "320924404": "盐场",
        "320924406": "射阳港经济区"
    },
    "320925": {
        "320925001": "近湖街道",
        "320925050": "开发区（钟庄街道）",
        "320925051": "高新区（塘河街道）",
        "320925101": "建阳镇",
        "320925102": "九龙口镇",
        "320925103": "恒济镇",
        "320925104": "颜单镇",
        "320925105": "沿河镇",
        "320925106": "芦沟镇",
        "320925107": "庆丰镇",
        "320925108": "上冈镇",
        "320925111": "冈西镇",
        "320925113": "宝塔镇",
        "320925114": "高作镇"
    },
    "320971": {"320971001": "新城街道", "320971100": "步凤镇"},
    "320981": {
        "320981100": "溱东镇",
        "320981101": "时堰镇",
        "320981106": "五烈镇",
        "320981107": "梁垛镇",
        "320981108": "安丰镇",
        "320981109": "南沈灶镇",
        "320981110": "富安镇",
        "320981112": "唐洋镇",
        "320981113": "新街镇",
        "320981114": "许河镇",
        "320981115": "三仓镇",
        "320981118": "头灶镇",
        "320981121": "弶港镇",
        "320981122": "东台镇",
        "320981400": "江苏省新曹农场",
        "320981401": "江苏省农垦弶港农场",
        "320981409": "开发区",
        "320981410": "沿海经济区",
        "320981411": "城东新区",
        "320981412": "西溪景区"
    },
    "321000": {
        "321002": "广陵区",
        "321003": "邗江区",
        "321012": "江都区",
        "321023": "宝应县",
        "321071": "扬州经济技术开发区",
        "321081": "仪征市",
        "321084": "高邮市"
    },
    "321002": {
        "321002001": "东关街道",
        "321002002": "汶河街道",
        "321002003": "文峰街道",
        "321002004": "曲江街道",
        "321002102": "李典镇",
        "321002103": "沙头镇",
        "321002104": "头桥镇",
        "321002106": "湾头镇",
        "321002200": "汤汪乡",
        "321002400": "产业园",
        "321002401": "扬州市生态科技新城杭集镇",
        "321002402": "扬州市生态科技新城泰安镇"
    },
    "321003": {
        "321003001": "邗上街道",
        "321003002": "蒋王街道",
        "321003003": "汊河街道",
        "321003005": "双桥街道",
        "321003006": "梅岭街道",
        "321003007": "甘泉街道",
        "321003009": "新盛街道",
        "321003010": "竹西街道",
        "321003052": "瘦西湖街道",
        "321003100": "公道镇",
        "321003101": "方巷镇",
        "321003102": "槐泗镇",
        "321003103": "瓜洲镇",
        "321003108": "杨寿镇",
        "321003110": "杨庙镇",
        "321003111": "西湖镇",
        "321003201": "双桥乡",
        "321003350": "平山乡",
        "321003351": "城北乡",
        "321003401": "江苏省扬州高新技术产业开发区",
        "321003402": "维扬经济开发区",
        "321003403": "物流园区"
    },
    "321012": {
        "321012100": "仙女镇",
        "321012101": "小纪镇",
        "321012103": "武坚镇",
        "321012106": "樊川镇",
        "321012108": "真武镇",
        "321012109": "宜陵镇",
        "321012110": "丁沟镇",
        "321012112": "郭村镇",
        "321012114": "邵伯镇",
        "321012116": "丁伙镇",
        "321012118": "大桥镇",
        "321012119": "吴桥镇",
        "321012120": "浦头镇",
        "321012400": "立新农场",
        "321012401": "滨江新城管理委员会"
    },
    "321023": {
        "321023100": "安宜镇",
        "321023101": "氾水镇",
        "321023102": "夏集镇",
        "321023103": "柳堡镇",
        "321023104": "射阳湖镇",
        "321023105": "广洋湖镇",
        "321023106": "鲁垛镇",
        "321023107": "小官庄镇",
        "321023108": "望直港镇",
        "321023109": "曹甸镇",
        "321023110": "西安丰镇",
        "321023111": "山阳镇",
        "321023112": "黄塍镇",
        "321023113": "泾河镇",
        "321023400": "开发区管委会"
    },
    "321071": {"321071050": "扬子津街道", "321071051": "文汇街道", "321071150": "施桥镇", "321071151": "八里镇", "321071152": "朴席镇"},
    "321081": {
        "321081100": "真州镇",
        "321081103": "新集镇",
        "321081104": "新城镇",
        "321081105": "马集镇",
        "321081106": "刘集镇",
        "321081107": "陈集镇",
        "321081108": "大仪镇",
        "321081109": "月塘镇",
        "321081150": "青山镇",
        "321081401": "原种场",
        "321081402": "蚕种场",
        "321081403": "园艺试验场",
        "321081404": "水产试验场",
        "321081405": "捺山茶场",
        "321081407": "汽车工业园",
        "321081410": "开发区",
        "321081412": "枣林湾旅游度假区",
        "321081454": "化学工业园区"
    },
    "321084": {
        "321084001": "高邮街道",
        "321084101": "龙虬镇",
        "321084106": "汤庄镇",
        "321084107": "卸甲镇",
        "321084108": "三垛镇",
        "321084109": "甘垛镇",
        "321084112": "界首镇",
        "321084113": "周山镇",
        "321084115": "临泽镇",
        "321084116": "送桥镇",
        "321084200": "菱塘回族乡",
        "321084401": "城南经济新区（车逻镇）",
        "321084406": "经济开发区（马棚街道）"
    },
    "321100": {
        "321102": "京口区",
        "321111": "润州区",
        "321112": "丹徒区",
        "321171": "镇江新区",
        "321181": "丹阳市",
        "321182": "扬中市",
        "321183": "句容市"
    },
    "321102": {
        "321102001": "正东路街道",
        "321102002": "健康路街道",
        "321102003": "大市口街道",
        "321102004": "四牌楼街道",
        "321102005": "象山街道",
        "321102006": "谏壁街道",
        "321102400": "京口工业园区",
        "321102401": "新民洲临港产业园"
    },
    "321111": {
        "321111001": "宝塔路街道",
        "321111002": "和平路街道",
        "321111003": "金山街道",
        "321111004": "七里甸街道",
        "321111005": "蒋乔街道",
        "321111006": "官塘桥街道",
        "321111007": "韦岗街道",
        "321111008": "南山街道"
    },
    "321112": {
        "321112001": "高资街道",
        "321112100": "高桥镇",
        "321112101": "辛丰镇",
        "321112102": "谷阳镇",
        "321112103": "上党镇",
        "321112104": "宝堰镇",
        "321112105": "世业镇",
        "321112400": "宜城街道",
        "321112401": "江心洲生态农业园区",
        "321112402": "荣炳盐资源区",
        "321112403": "高新园区管委会"
    },
    "321171": {"321171001": "丁卯街道", "321171002": "大港街道", "321171100": "姚桥镇", "321171101": "大路镇", "321171102": "丁岗镇"},
    "321181": {
        "321181001": "云阳街道",
        "321181002": "曲阿街道",
        "321181100": "司徒镇",
        "321181101": "延陵镇",
        "321181102": "珥陵镇",
        "321181103": "导墅镇",
        "321181104": "皇塘镇",
        "321181105": "吕城镇",
        "321181106": "陵口镇",
        "321181107": "访仙镇",
        "321181108": "界牌镇",
        "321181113": "丹北镇"
    },
    "321182": {
        "321182001": "三茅街道",
        "321182101": "新坝镇",
        "321182102": "油坊镇",
        "321182103": "八桥镇",
        "321182104": "西来桥镇",
        "321182400": "经济开发区"
    },
    "321183": {
        "321183002": "华阳街道",
        "321183102": "下蜀镇",
        "321183103": "白兔镇",
        "321183104": "边城镇",
        "321183105": "茅山镇",
        "321183106": "后白镇",
        "321183107": "郭庄镇",
        "321183108": "天王镇",
        "321183109": "宝华镇",
        "321183400": "开发区",
        "321183429": "茅山管委会"
    },
    "321200": {
        "321202": "海陵区",
        "321203": "高港区",
        "321204": "姜堰区",
        "321271": "泰州医药高新技术产业开发区",
        "321281": "兴化市",
        "321282": "靖江市",
        "321283": "泰兴市"
    },
    "321202": {
        "321202001": "城东街道",
        "321202002": "城西街道",
        "321202003": "城南街道",
        "321202004": "城中街道",
        "321202005": "城北街道",
        "321202007": "京泰路街道",
        "321202050": "红旗街道",
        "321202100": "九龙镇",
        "321202101": "罡杨镇",
        "321202102": "苏陈镇",
        "321202401": "城北物流园区",
        "321202406": "海陵工业园区",
        "321202407": "农业示范园区",
        "321202408": "火车站街区"
    },
    "321203": {
        "321203001": "口岸街道",
        "321203002": "刁铺街道",
        "321203003": "许庄街道",
        "321203100": "永安洲镇",
        "321203101": "白马镇",
        "321203103": "胡庄镇",
        "321203104": "大泗镇",
        "321203400": "泰州市高港区科技创业园管理委员会"
    },
    "321204": {
        "321204001": "罗塘街道",
        "321204002": "三水街道",
        "321204101": "溱潼镇",
        "321204102": "蒋垛镇",
        "321204103": "顾高镇",
        "321204104": "大伦镇",
        "321204105": "张甸镇",
        "321204107": "梁徐镇",
        "321204109": "桥头镇",
        "321204110": "淤溪镇",
        "321204111": "白米镇",
        "321204112": "娄庄镇",
        "321204113": "沈高镇",
        "321204114": "兴泰镇",
        "321204115": "俞垛镇",
        "321204116": "华港镇",
        "321204401": "溱湖风景区",
        "321204403": "林场",
        "321204404": "种猪场",
        "321204405": "林业站",
        "321204406": "渔业社"
    },
    "321271": {
        "321271001": "凤凰街道",
        "321271002": "寺巷街道",
        "321271003": "明珠街道",
        "321271004": "沿江街道",
        "321271100": "野徐镇",
        "321271401": "医药城",
        "321271402": "经济开发区",
        "321271403": "数据园"
    },
    "321281": {
        "321281001": "昭阳街道",
        "321281002": "临城街道",
        "321281003": "垛田街道",
        "321281100": "戴窑镇",
        "321281101": "合陈镇",
        "321281102": "永丰镇",
        "321281103": "新垛镇",
        "321281104": "安丰镇",
        "321281105": "海南镇",
        "321281106": "钓鱼镇",
        "321281107": "大邹镇",
        "321281108": "沙沟镇",
        "321281109": "中堡镇",
        "321281114": "竹泓镇",
        "321281115": "沈沦镇",
        "321281116": "大垛镇",
        "321281117": "荻垛镇",
        "321281118": "陶庄镇",
        "321281119": "昌荣镇",
        "321281120": "茅山镇",
        "321281121": "周庄镇",
        "321281122": "陈堡镇",
        "321281123": "戴南镇",
        "321281124": "张郭镇",
        "321281126": "大营镇",
        "321281129": "兴东镇",
        "321281130": "千垛镇",
        "321281204": "林湖乡",
        "321281400": "开发区",
        "321281401": "良种场"
    },
    "321282": {
        "321282001": "靖城街道办",
        "321282101": "新桥镇",
        "321282102": "东兴镇",
        "321282103": "斜桥镇",
        "321282104": "西来镇",
        "321282105": "季市镇",
        "321282106": "孤山镇",
        "321282108": "生祠镇",
        "321282109": "马桥镇",
        "321282401": "城南",
        "321282402": "新港城",
        "321282403": "城北园区",
        "321282404": "江阴园区",
        "321282406": "滨江新城"
    },
    "321283": {
        "321283001": "济川街道",
        "321283100": "黄桥镇",
        "321283101": "分界镇",
        "321283102": "古溪镇",
        "321283104": "元竹镇",
        "321283105": "珊瑚镇",
        "321283106": "广陵镇",
        "321283108": "曲霞镇",
        "321283109": "张桥镇",
        "321283112": "河失镇",
        "321283113": "新街镇",
        "321283114": "姚王镇",
        "321283115": "宣堡镇",
        "321283123": "滨江镇",
        "321283124": "虹桥镇",
        "321283200": "根思乡",
        "321283401": "市农科所",
        "321283402": "城东高新技术产业园",
        "321283403": "农产品加工园"
    },
    "321300": {
        "321302": "宿城区",
        "321311": "宿豫区",
        "321322": "沭阳县",
        "321323": "泗阳县",
        "321324": "泗洪县",
        "321371": "宿迁经济技术开发区"
    },
    "321302": {
        "321302001": "幸福街道",
        "321302002": "项里街道",
        "321302003": "河滨街道",
        "321302004": "古城街道",
        "321302005": "双庄街道办",
        "321302006": "支口街道办",
        "321302101": "耿车镇",
        "321302102": "埠子镇",
        "321302103": "龙河镇",
        "321302104": "洋北镇",
        "321302107": "中扬镇",
        "321302109": "陈集镇",
        "321302110": "蔡集镇",
        "321302111": "王官集镇",
        "321302150": "洋河镇",
        "321302151": "仓集镇",
        "321302152": "郑楼镇",
        "321302201": "罗圩乡",
        "321302203": "屠园乡",
        "321302400": "宿城开发区",
        "321302450": "苏州宿迁工业园"
    },
    "321311": {
        "321311001": "顺河街道",
        "321311002": "豫新街道",
        "321311003": "下相街道",
        "321311105": "仰化镇",
        "321311106": "大兴镇",
        "321311107": "丁嘴镇",
        "321311108": "来龙镇",
        "321311110": "陆集镇",
        "321311111": "关庙镇",
        "321311112": "侍岭镇",
        "321311113": "新庄镇",
        "321311150": "晓店镇",
        "321311151": "皂河镇",
        "321311152": "黄墩镇",
        "321311201": "曹集乡",
        "321311202": "保安乡",
        "321311350": "井头乡",
        "321311401": "宿豫经济开发区",
        "321311402": "宿迁生态化工科技产业园",
        "321311403": "张家港宿豫工业园",
        "321311450": "嶂山林场",
        "321311451": "宿迁市软件与服务外包产业园"
    },
    "321322": {
        "321322001": "沭城街道",
        "321322002": "南湖街道",
        "321322003": "梦溪街道",
        "321322004": "十字街道",
        "321322005": "章集街道",
        "321322006": "七雄街道",
        "321322101": "陇集镇",
        "321322102": "胡集镇",
        "321322103": "钱集镇",
        "321322104": "塘沟镇",
        "321322105": "马厂镇",
        "321322106": "沂涛镇",
        "321322107": "庙头镇",
        "321322108": "韩山镇",
        "321322109": "华冲镇",
        "321322110": "桑墟镇",
        "321322111": "悦来镇",
        "321322112": "刘集镇",
        "321322114": "李恒镇",
        "321322115": "扎下镇",
        "321322116": "颜集镇",
        "321322117": "潼阳镇",
        "321322118": "龙庙镇",
        "321322119": "高墟镇",
        "321322120": "耿圩镇",
        "321322122": "汤涧镇",
        "321322124": "新河镇",
        "321322125": "贤官镇",
        "321322126": "吴集镇",
        "321322127": "湖东镇",
        "321322128": "青伊湖镇",
        "321322200": "北丁集乡",
        "321322201": "周集乡",
        "321322202": "东小店乡",
        "321322203": "张圩乡",
        "321322204": "茆圩乡",
        "321322205": "西圩乡",
        "321322206": "万匹乡",
        "321322207": "官墩乡",
        "321322400": "青伊湖农场",
        "321322401": "沭阳经济开发区"
    },
    "321323": {
        "321323100": "众兴镇",
        "321323101": "爱园镇",
        "321323102": "王集镇",
        "321323103": "裴圩镇",
        "321323104": "新袁镇",
        "321323105": "李口镇",
        "321323106": "临河镇",
        "321323107": "穿城镇",
        "321323108": "张家圩镇",
        "321323109": "高渡镇",
        "321323110": "卢集镇",
        "321323200": "庄圩乡",
        "321323201": "里仁乡",
        "321323202": "三庄乡",
        "321323203": "南刘集乡",
        "321323204": "八集乡",
        "321323400": "棉花原种场",
        "321323401": "泗阳农场",
        "321323402": "江苏泗阳开发区"
    },
    "321324": {
        "321324001": "青阳街道",
        "321324002": "大楼街道",
        "321324003": "重岗街道",
        "321324101": "双沟镇",
        "321324102": "上塘镇",
        "321324103": "魏营镇",
        "321324104": "临淮镇",
        "321324105": "半城镇",
        "321324106": "孙园镇",
        "321324107": "梅花镇",
        "321324108": "归仁镇",
        "321324109": "金锁镇",
        "321324110": "朱湖镇",
        "321324111": "界集镇",
        "321324112": "太平镇",
        "321324113": "龙集镇",
        "321324200": "四河乡",
        "321324201": "峰山乡",
        "321324202": "天岗湖乡",
        "321324203": "车门乡",
        "321324204": "瑶沟乡",
        "321324205": "石集乡",
        "321324206": "城头乡",
        "321324207": "陈圩乡",
        "321324208": "曹庙乡",
        "321324400": "五里江农场",
        "321324401": "洪泽湖农场",
        "321324402": "泗洪经济开发区"
    },
    "321371": {"321371050": "黄河街道办", "321371052": "三棵树街道办", "321371351": "南蔡乡", "321371400": "古楚街道办"},
    "330000": {
        "330100": "杭州市",
        "330200": "宁波市",
        "330300": "温州市",
        "330400": "嘉兴市",
        "330500": "湖州市",
        "330600": "绍兴市",
        "330700": "金华市",
        "330800": "衢州市",
        "330900": "舟山市",
        "331000": "台州市",
        "331100": "丽水市"
    },
    "330100": {
        "330102": "上城区",
        "330103": "下城区",
        "330104": "江干区",
        "330105": "拱墅区",
        "330106": "西湖区",
        "330108": "滨江区",
        "330109": "萧山区",
        "330110": "余杭区",
        "330111": "富阳区",
        "330112": "临安区",
        "330122": "桐庐县",
        "330127": "淳安县",
        "330182": "建德市"
    },
    "330102": {
        "330102001": "清波街道",
        "330102003": "湖滨街道",
        "330102004": "小营街道",
        "330102008": "南星街道",
        "330102009": "紫阳街道",
        "330102010": "望江街道"
    },
    "330103": {
        "330103001": "长庆街道",
        "330103002": "武林街道",
        "330103003": "天水街道",
        "330103005": "潮鸣街道",
        "330103006": "朝晖街道",
        "330103007": "文晖街道",
        "330103008": "东新街道",
        "330103009": "石桥街道"
    },
    "330104": {
        "330104005": "凯旋街道",
        "330104006": "采荷街道",
        "330104007": "闸弄口街道",
        "330104008": "四季青街道",
        "330104009": "白杨街道",
        "330104010": "下沙街道",
        "330104011": "彭埠街道",
        "330104012": "笕桥街道",
        "330104013": "丁兰街道",
        "330104014": "九堡街道"
    },
    "330105": {
        "330105001": "米市巷街道",
        "330105002": "湖墅街道",
        "330105003": "小河街道",
        "330105004": "和睦街道",
        "330105005": "拱宸桥街道",
        "330105007": "大关街道",
        "330105008": "上塘街道",
        "330105009": "祥符街道",
        "330105010": "康桥街道",
        "330105011": "半山街道"
    },
    "330106": {
        "330106002": "北山街道",
        "330106004": "西溪街道",
        "330106005": "翠苑街道",
        "330106007": "古荡街道",
        "330106008": "西湖街道",
        "330106009": "留下街道",
        "330106010": "转塘街道",
        "330106011": "蒋村街道",
        "330106012": "灵隐街道",
        "330106013": "文新街道",
        "330106109": "三墩镇",
        "330106110": "双浦镇"
    },
    "330108": {"330108001": "西兴街道", "330108002": "长河街道", "330108003": "浦沿街道"},
    "330109": {
        "330109001": "城厢街道",
        "330109002": "北干街道",
        "330109003": "蜀山街道",
        "330109004": "新塘街道",
        "330109005": "靖江街道",
        "330109006": "南阳街道",
        "330109007": "义蓬街道",
        "330109008": "河庄街道",
        "330109009": "新湾街道",
        "330109010": "临江街道",
        "330109011": "前进街道",
        "330109012": "闻堰街道",
        "330109013": "宁围街道",
        "330109014": "新街街道",
        "330109100": "楼塔镇",
        "330109101": "河上镇",
        "330109102": "戴村镇",
        "330109103": "浦阳镇",
        "330109104": "进化镇",
        "330109105": "临浦镇",
        "330109106": "义桥镇",
        "330109107": "所前镇",
        "330109108": "衙前镇",
        "330109113": "瓜沥镇",
        "330109115": "益农镇",
        "330109120": "党湾镇",
        "330109401": "萧山经济技术开发区",
        "330109403": "萧山商业城",
        "330109501": "围垦区",
        "330109503": "红山农场",
        "330109505": "萧山钱江世纪城"
    },
    "330110": {
        "330110001": "临平街道",
        "330110002": "南苑街道",
        "330110003": "东湖街道",
        "330110004": "星桥街道",
        "330110005": "五常街道",
        "330110006": "乔司街道",
        "330110007": "运河街道",
        "330110008": "崇贤街道",
        "330110009": "仁和街道",
        "330110010": "良渚街道",
        "330110011": "闲林街道",
        "330110012": "仓前街道",
        "330110013": "余杭街道",
        "330110014": "中泰街道",
        "330110102": "塘栖镇",
        "330110109": "径山镇",
        "330110110": "瓶窑镇",
        "330110111": "鸬鸟镇",
        "330110112": "百丈镇",
        "330110113": "黄湖镇"
    },
    "330111": {
        "330111001": "富春街道",
        "330111002": "春江街道",
        "330111004": "鹿山街道",
        "330111005": "东洲街道",
        "330111006": "银湖街道",
        "330111100": "万市镇",
        "330111101": "洞桥镇",
        "330111103": "渌渚镇",
        "330111105": "永昌镇",
        "330111108": "里山镇",
        "330111109": "常绿镇",
        "330111110": "场口镇",
        "330111111": "常安镇",
        "330111112": "龙门镇",
        "330111115": "新登镇",
        "330111116": "胥口镇",
        "330111117": "大源镇",
        "330111118": "灵桥镇",
        "330111200": "新桐乡",
        "330111201": "上官乡",
        "330111204": "环山乡",
        "330111205": "湖源乡",
        "330111206": "春建乡",
        "330111207": "渔山乡"
    },
    "330112": {
        "330112002": "玲珑街道",
        "330112005": "锦南街道",
        "330112006": "锦城街道",
        "330112007": "锦北街道",
        "330112008": "青山湖街道",
        "330112102": "高虹镇",
        "330112103": "太湖源镇",
        "330112104": "於潜镇",
        "330112106": "太阳镇",
        "330112107": "潜川镇",
        "330112108": "昌化镇",
        "330112109": "河桥镇",
        "330112111": "湍口镇",
        "330112112": "清凉峰镇",
        "330112113": "岛石镇",
        "330112115": "板桥镇",
        "330112116": "天目山镇",
        "330112117": "龙岗镇"
    },
    "330122": {
        "330122002": "旧县街道",
        "330122003": "桐君街道",
        "330122004": "城南街道",
        "330122005": "凤川街道",
        "330122101": "富春江镇",
        "330122102": "横村镇",
        "330122109": "分水镇",
        "330122110": "瑶琳镇",
        "330122112": "百江镇",
        "330122113": "江南镇",
        "330122201": "莪山畲族乡",
        "330122202": "钟山乡",
        "330122204": "新合乡",
        "330122210": "合村乡"
    },
    "330127": {
        "330127100": "千岛湖镇",
        "330127101": "文昌镇",
        "330127102": "石林镇",
        "330127103": "临岐镇",
        "330127104": "威坪镇",
        "330127106": "姜家镇",
        "330127107": "梓桐镇",
        "330127108": "汾口镇",
        "330127109": "中洲镇",
        "330127110": "大墅镇",
        "330127111": "枫树岭镇",
        "330127200": "里商乡",
        "330127201": "金峰乡",
        "330127202": "富文乡",
        "330127203": "左口乡",
        "330127205": "屏门乡",
        "330127206": "瑶山乡",
        "330127208": "王阜乡",
        "330127210": "宋村乡",
        "330127211": "鸠坑乡",
        "330127212": "浪川乡",
        "330127214": "界首乡",
        "330127216": "安阳乡"
    },
    "330182": {
        "330182001": "新安江街道",
        "330182002": "洋溪街道",
        "330182003": "更楼街道",
        "330182101": "莲花镇",
        "330182102": "乾潭镇",
        "330182104": "梅城镇",
        "330182105": "杨村桥镇",
        "330182106": "下涯镇",
        "330182107": "大洋镇",
        "330182108": "三都镇",
        "330182109": "寿昌镇",
        "330182110": "航头镇",
        "330182111": "大慈岩镇",
        "330182112": "大同镇",
        "330182113": "李家镇",
        "330182202": "钦堂乡"
    },
    "330200": {
        "330203": "海曙区",
        "330205": "江北区",
        "330206": "北仑区",
        "330211": "镇海区",
        "330212": "鄞州区",
        "330213": "奉化区",
        "330225": "象山县",
        "330226": "宁海县",
        "330281": "余姚市",
        "330282": "慈溪市"
    },
    "330203": {
        "330203001": "南门街道",
        "330203002": "江厦街道",
        "330203003": "西门街道",
        "330203004": "月湖街道",
        "330203005": "鼓楼街道",
        "330203006": "白云街道",
        "330203007": "段塘街道",
        "330203008": "望春街道",
        "330203009": "石碶街道",
        "330203100": "高桥镇",
        "330203101": "横街镇",
        "330203102": "集士港镇",
        "330203103": "古林镇",
        "330203104": "洞桥镇",
        "330203105": "鄞江镇",
        "330203106": "章水镇",
        "330203200": "龙观乡"
    },
    "330205": {
        "330205001": "中马街道",
        "330205002": "白沙街道",
        "330205003": "孔浦街道",
        "330205004": "文教街道",
        "330205005": "甬江街道",
        "330205006": "庄桥街道",
        "330205007": "洪塘街道",
        "330205103": "慈城镇"
    },
    "330206": {
        "330206001": "大榭街道",
        "330206002": "新碶街道",
        "330206003": "小港街道",
        "330206004": "大碶街道",
        "330206005": "霞浦街道",
        "330206006": "柴桥街道",
        "330206007": "戚家山街道",
        "330206008": "春晓街道",
        "330206009": "梅山街道",
        "330206010": "郭巨街道",
        "330206011": "白峰街道",
        "330206401": "保税区"
    },
    "330211": {
        "330211001": "招宝山街道",
        "330211002": "蛟川街道",
        "330211003": "骆驼街道",
        "330211004": "庄市街道",
        "330211005": "贵驷街道",
        "330211100": "澥浦镇",
        "330211101": "九龙湖镇"
    },
    "330212": {
        "330212001": "下应街道",
        "330212002": "钟公庙街道",
        "330212004": "梅墟街道",
        "330212005": "中河街道",
        "330212006": "首南街道",
        "330212007": "潘火街道",
        "330212008": "百丈街道",
        "330212009": "东胜街道",
        "330212010": "明楼街道",
        "330212011": "白鹤街道",
        "330212012": "东柳街道",
        "330212013": "东郊街道",
        "330212014": "福明街道",
        "330212015": "新明街道",
        "330212100": "瞻岐镇",
        "330212101": "咸祥镇",
        "330212102": "塘溪镇",
        "330212103": "东钱湖镇",
        "330212104": "东吴镇",
        "330212105": "五乡镇",
        "330212106": "邱隘镇",
        "330212108": "云龙镇",
        "330212109": "横溪镇",
        "330212110": "姜山镇"
    },
    "330213": {
        "330213001": "锦屏街道",
        "330213002": "岳林街道",
        "330213003": "江口街道",
        "330213004": "西坞街道",
        "330213005": "萧王庙街道",
        "330213100": "溪口镇",
        "330213103": "尚田镇",
        "330213104": "莼湖镇",
        "330213106": "裘村镇",
        "330213107": "大堰镇",
        "330213108": "松岙镇"
    },
    "330225": {
        "330225001": "丹东街道",
        "330225002": "丹西街道",
        "330225003": "爵溪街道",
        "330225101": "石浦镇",
        "330225102": "西周镇",
        "330225103": "鹤浦镇",
        "330225104": "贤庠镇",
        "330225105": "墙头镇",
        "330225106": "泗洲头镇",
        "330225107": "定塘镇",
        "330225108": "涂茨镇",
        "330225109": "大徐镇",
        "330225110": "新桥镇",
        "330225200": "东陈乡",
        "330225201": "晓塘乡",
        "330225202": "黄避岙乡",
        "330225203": "茅洋乡",
        "330225204": "高塘岛乡"
    },
    "330226": {
        "330226001": "跃龙街道",
        "330226002": "桃源街道",
        "330226003": "梅林街道",
        "330226004": "桥头胡街道",
        "330226101": "长街镇",
        "330226102": "力洋镇",
        "330226104": "一市镇",
        "330226105": "岔路镇",
        "330226106": "前童镇",
        "330226107": "桑洲镇",
        "330226108": "黄坛镇",
        "330226109": "大佳何镇",
        "330226110": "强蛟镇",
        "330226111": "西店镇",
        "330226112": "深甽镇",
        "330226200": "胡陈乡",
        "330226201": "茶院乡",
        "330226202": "越溪乡"
    },
    "330281": {
        "330281001": "梨洲街道",
        "330281002": "凤山街道",
        "330281003": "兰江街道",
        "330281004": "阳明街道",
        "330281005": "低塘街道",
        "330281006": "朗霞街道",
        "330281100": "临山镇",
        "330281101": "黄家埠镇",
        "330281102": "小曹娥镇",
        "330281103": "泗门镇",
        "330281106": "马渚镇",
        "330281108": "牟山镇",
        "330281109": "丈亭镇",
        "330281110": "三七市镇",
        "330281111": "河姆渡镇",
        "330281112": "大隐镇",
        "330281113": "陆埠镇",
        "330281114": "梁弄镇",
        "330281115": "大岚镇",
        "330281116": "四明山镇",
        "330281201": "鹿亭乡"
    },
    "330282": {
        "330282002": "宗汉街道",
        "330282003": "坎墩街道",
        "330282004": "浒山街道",
        "330282005": "白沙路街道",
        "330282006": "古塘街道",
        "330282104": "掌起镇",
        "330282107": "观海卫镇",
        "330282108": "附海镇",
        "330282109": "桥头镇",
        "330282110": "匡堰镇",
        "330282111": "逍林镇",
        "330282112": "新浦镇",
        "330282113": "胜山镇",
        "330282114": "横河镇",
        "330282116": "崇寿镇",
        "330282118": "庵东镇",
        "330282120": "长河镇",
        "330282121": "周巷镇",
        "330282123": "龙山镇",
        "330282400": "慈溪市农垦场",
        "330282401": "慈溪市林场",
        "330282402": "慈东工业区"
    },
    "330300": {
        "330302": "鹿城区",
        "330303": "龙湾区",
        "330304": "瓯海区",
        "330305": "洞头区",
        "330324": "永嘉县",
        "330326": "平阳县",
        "330327": "苍南县",
        "330328": "文成县",
        "330329": "泰顺县",
        "330371": "温州经济技术开发区",
        "330381": "瑞安市",
        "330382": "乐清市"
    },
    "330302": {
        "330302006": "五马街道",
        "330302023": "七都街道",
        "330302024": "滨江街道",
        "330302025": "南汇街道",
        "330302026": "松台街道",
        "330302027": "双屿街道",
        "330302028": "仰义街道",
        "330302029": "大南街道",
        "330302030": "蒲鞋市街道",
        "330302031": "南郊街道",
        "330302032": "广化街道",
        "330302033": "丰门街道",
        "330302102": "藤桥镇",
        "330302105": "山福镇"
    },
    "330303": {
        "330303001": "永中街道",
        "330303002": "蒲州街道",
        "330303003": "海滨街道",
        "330303004": "永兴街道",
        "330303006": "状元街道",
        "330303007": "瑶溪街道"
    },
    "330304": {
        "330304001": "景山街道",
        "330304002": "梧田街道",
        "330304003": "南白象街道",
        "330304004": "茶山街道",
        "330304005": "娄桥街道",
        "330304006": "新桥街道",
        "330304007": "三垟街道",
        "330304008": "瞿溪街道",
        "330304009": "郭溪街道",
        "330304010": "潘桥街道",
        "330304011": "丽岙街道",
        "330304012": "仙岩街道",
        "330304108": "泽雅镇"
    },
    "330305": {
        "330305001": "北岙街道",
        "330305002": "灵昆街道",
        "330305003": "东屏街道",
        "330305004": "元觉街道",
        "330305005": "霓屿街道",
        "330305101": "大门镇",
        "330305202": "鹿西乡"
    },
    "330324": {
        "330324001": "东城街道",
        "330324002": "北城街道",
        "330324003": "南城街道",
        "330324006": "三江街道",
        "330324007": "黄田街道",
        "330324008": "乌牛街道",
        "330324009": "瓯北街道",
        "330324102": "桥头镇",
        "330324104": "桥下镇",
        "330324105": "大若岩镇",
        "330324106": "碧莲镇",
        "330324107": "巽宅镇",
        "330324108": "岩头镇",
        "330324109": "枫林镇",
        "330324110": "岩坦镇",
        "330324111": "沙头镇",
        "330324112": "鹤盛镇",
        "330324113": "金溪镇",
        "330324226": "云岭乡",
        "330324227": "茗岙乡",
        "330324228": "溪下乡",
        "330324229": "界坑乡",
        "330324400": "县特产场场区",
        "330324401": "四海山林场",
        "330324402": "正江山林场"
    },
    "330326": {
        "330326100": "昆阳镇",
        "330326101": "鳌江镇",
        "330326102": "水头镇",
        "330326103": "萧江镇",
        "330326105": "腾蛟镇",
        "330326107": "山门镇",
        "330326108": "顺溪镇",
        "330326109": "南雁镇",
        "330326117": "万全镇",
        "330326118": "海西镇",
        "330326119": "南麂镇",
        "330326120": "麻步镇",
        "330326121": "凤卧镇",
        "330326122": "怀溪镇",
        "330326214": "青街畲族乡",
        "330326217": "闹村乡",
        "330326401": "滨海新区"
    },
    "330327": {
        "330327100": "灵溪镇",
        "330327101": "龙港镇",
        "330327103": "宜山镇",
        "330327104": "钱库镇",
        "330327107": "金乡镇",
        "330327112": "藻溪镇",
        "330327113": "桥墩镇",
        "330327116": "矾山镇",
        "330327117": "赤溪镇",
        "330327118": "马站镇",
        "330327122": "望里镇",
        "330327123": "炎亭镇",
        "330327124": "大渔镇",
        "330327125": "莒溪镇",
        "330327126": "南宋镇",
        "330327127": "霞关镇",
        "330327128": "沿浦镇",
        "330327216": "凤阳畲族乡",
        "330327218": "岱岭畲族乡"
    },
    "330328": {
        "330328100": "大峃镇",
        "330328101": "百丈漈镇",
        "330328102": "南田镇",
        "330328103": "西坑畲族镇",
        "330328104": "黄坦镇",
        "330328105": "珊溪镇",
        "330328106": "巨屿镇",
        "330328107": "玉壶镇",
        "330328108": "峃口镇",
        "330328109": "周壤镇",
        "330328110": "铜铃山镇",
        "330328111": "二源镇",
        "330328217": "周山畲族乡",
        "330328225": "桂山乡",
        "330328226": "双桂乡",
        "330328227": "平和乡",
        "330328228": "公阳乡"
    },
    "330329": {
        "330329100": "罗阳镇",
        "330329101": "司前畲族镇",
        "330329102": "百丈镇",
        "330329103": "筱村镇",
        "330329104": "泗溪镇",
        "330329105": "彭溪镇",
        "330329106": "雅阳镇",
        "330329107": "仕阳镇",
        "330329109": "三魁镇",
        "330329111": "南浦溪镇",
        "330329112": "龟湖镇",
        "330329113": "西旸镇",
        "330329204": "竹里畲族乡",
        "330329225": "包垟乡",
        "330329226": "凤垟乡",
        "330329227": "东溪乡",
        "330329228": "柳峰乡",
        "330329229": "雪溪乡",
        "330329230": "大安乡",
        "330329400": "乌岩岭自然保护区"
    },
    "330371": {"330371005": "海城街道", "330371008": "沙城街道", "330371009": "天河街道", "330371011": "星海街道"},
    "330381": {
        "330381001": "安阳街道",
        "330381002": "玉海街道",
        "330381003": "锦湖街道",
        "330381004": "东山街道",
        "330381005": "上望街道",
        "330381007": "莘塍街道",
        "330381008": "汀田街道",
        "330381009": "飞云街道",
        "330381010": "仙降街道",
        "330381011": "南滨街道",
        "330381012": "潘岱街道",
        "330381013": "云周街道",
        "330381101": "塘下镇",
        "330381116": "马屿镇",
        "330381120": "陶山镇",
        "330381122": "湖岭镇",
        "330381125": "高楼镇",
        "330381126": "桐浦镇",
        "330381127": "林川镇",
        "330381128": "曹村镇",
        "330381129": "平阳坑镇",
        "330381221": "芳庄乡",
        "330381222": "北麂乡"
    },
    "330382": {
        "330382001": "城东街道",
        "330382002": "乐成街道",
        "330382003": "城南街道",
        "330382004": "盐盆街道",
        "330382005": "翁垟街道",
        "330382006": "白石街道",
        "330382007": "石帆街道",
        "330382008": "天成街道",
        "330382101": "大荆镇",
        "330382102": "仙溪镇",
        "330382104": "雁荡镇",
        "330382105": "芙蓉镇",
        "330382106": "清江镇",
        "330382108": "虹桥镇",
        "330382111": "淡溪镇",
        "330382114": "柳市镇",
        "330382115": "北白象镇",
        "330382121": "湖雾镇",
        "330382122": "南塘镇",
        "330382123": "南岳镇",
        "330382124": "蒲岐镇",
        "330382125": "磐石镇",
        "330382210": "智仁乡",
        "330382211": "龙西乡",
        "330382212": "岭底乡"
    },
    "330400": {
        "330402": "南湖区",
        "330411": "秀洲区",
        "330421": "嘉善县",
        "330424": "海盐县",
        "330481": "海宁市",
        "330482": "平湖市",
        "330483": "桐乡市"
    },
    "330402": {
        "330402007": "建设街道",
        "330402008": "解放街道",
        "330402009": "新嘉街道",
        "330402010": "南湖街道",
        "330402011": "新兴街道",
        "330402012": "城南街道",
        "330402013": "东栅街道",
        "330402014": "长水街道",
        "330402015": "七星街道",
        "330402100": "凤桥镇",
        "330402101": "余新镇",
        "330402103": "新丰镇",
        "330402105": "大桥镇"
    },
    "330411": {
        "330411002": "新城街道",
        "330411003": "嘉北街道",
        "330411004": "塘汇街道",
        "330411005": "高照街道",
        "330411101": "王江泾镇",
        "330411103": "油车港镇",
        "330411104": "新塍镇",
        "330411105": "王店镇",
        "330411106": "洪合镇"
    },
    "330421": {
        "330421001": "魏塘街道",
        "330421002": "罗星街道",
        "330421003": "惠民街道",
        "330421102": "大云镇",
        "330421103": "西塘镇",
        "330421105": "干窑镇",
        "330421107": "陶庄镇",
        "330421111": "姚庄镇",
        "330421112": "天凝镇"
    },
    "330424": {
        "330424001": "武原街道",
        "330424002": "西塘桥街道",
        "330424004": "秦山街道",
        "330424005": "望海街道",
        "330424101": "沈荡镇",
        "330424102": "百步镇",
        "330424103": "于城镇",
        "330424105": "澉浦镇",
        "330424106": "通元镇"
    },
    "330481": {
        "330481001": "硖石街道",
        "330481002": "海洲街道",
        "330481003": "海昌街道",
        "330481004": "马桥街道",
        "330481101": "许村镇",
        "330481103": "长安镇",
        "330481105": "周王庙镇",
        "330481106": "丁桥镇",
        "330481107": "斜桥镇",
        "330481108": "黄湾镇",
        "330481110": "盐官镇",
        "330481112": "袁花镇"
    },
    "330482": {
        "330482001": "当湖街道",
        "330482002": "钟埭街道",
        "330482003": "曹桥街道",
        "330482101": "乍浦镇",
        "330482102": "新埭镇",
        "330482103": "新仓镇",
        "330482106": "广陈镇",
        "330482107": "林埭镇",
        "330482108": "独山港镇"
    },
    "330483": {
        "330483001": "梧桐街道",
        "330483003": "凤鸣街道",
        "330483004": "高桥街道",
        "330483100": "乌镇镇",
        "330483101": "濮院镇",
        "330483102": "屠甸镇",
        "330483103": "石门镇",
        "330483104": "河山镇",
        "330483105": "洲泉镇",
        "330483106": "大麻镇",
        "330483107": "崇福镇"
    },
    "330500": {"330502": "吴兴区", "330503": "南浔区", "330521": "德清县", "330522": "长兴县", "330523": "安吉县"},
    "330502": {
        "330502001": "月河街道",
        "330502002": "朝阳街道",
        "330502003": "爱山街道",
        "330502004": "飞英街道",
        "330502005": "龙泉街道",
        "330502006": "凤凰街道",
        "330502007": "康山街道",
        "330502008": "仁皇山街道",
        "330502009": "滨湖街道",
        "330502010": "龙溪街道",
        "330502011": "杨家埠街道",
        "330502012": "环渚街道",
        "330502013": "湖东街道",
        "330502100": "织里镇",
        "330502101": "八里店镇",
        "330502102": "妙西镇",
        "330502104": "埭溪镇",
        "330502105": "东林镇",
        "330502200": "道场乡"
    },
    "330503": {
        "330503100": "南浔镇",
        "330503101": "双林镇",
        "330503102": "练市镇",
        "330503103": "善琏镇",
        "330503104": "旧馆镇",
        "330503105": "菱湖镇",
        "330503106": "和孚镇",
        "330503107": "千金镇",
        "330503108": "石淙镇"
    },
    "330521": {
        "330521001": "武康街道",
        "330521002": "舞阳街道",
        "330521003": "阜溪街道",
        "330521004": "下渚湖街道",
        "330521101": "乾元镇",
        "330521102": "新市镇",
        "330521103": "洛舍镇",
        "330521104": "钟管镇",
        "330521110": "雷甸镇",
        "330521113": "禹越镇",
        "330521114": "新安镇",
        "330521115": "莫干山镇"
    },
    "330522": {
        "330522001": "雉城街道",
        "330522002": "画溪街道",
        "330522003": "太湖街道",
        "330522004": "龙山街道",
        "330522101": "洪桥镇",
        "330522102": "李家巷镇",
        "330522103": "夹浦镇",
        "330522104": "林城镇",
        "330522106": "虹星桥镇",
        "330522108": "小浦镇",
        "330522110": "和平镇",
        "330522111": "泗安镇",
        "330522112": "煤山镇",
        "330522200": "水口乡",
        "330522202": "吕山乡"
    },
    "330523": {
        "330523001": "递铺街道",
        "330523002": "昌硕街道",
        "330523003": "灵峰街道",
        "330523004": "孝源街道",
        "330523103": "鄣吴镇",
        "330523104": "杭垓镇",
        "330523105": "孝丰镇",
        "330523106": "报福镇",
        "330523107": "章村镇",
        "330523108": "天荒坪镇",
        "330523110": "梅溪镇",
        "330523111": "天子湖镇",
        "330523201": "溪龙乡",
        "330523205": "上墅乡",
        "330523206": "山川乡"
    },
    "330600": {"330602": "越城区", "330603": "柯桥区", "330604": "上虞区", "330624": "新昌县", "330681": "诸暨市", "330683": "嵊州市"},
    "330602": {
        "330602001": "塔山街道",
        "330602002": "府山街道",
        "330602003": "蕺山街道",
        "330602004": "北海街道",
        "330602005": "城南街道",
        "330602006": "稽山街道",
        "330602007": "迪荡街道",
        "330602008": "东湖街道",
        "330602009": "灵芝街道",
        "330602010": "东浦街道",
        "330602011": "鉴湖街道",
        "330602012": "斗门街道",
        "330602104": "皋埠镇",
        "330602105": "马山镇",
        "330602107": "孙端镇",
        "330602108": "富盛镇",
        "330602109": "陶堰镇"
    },
    "330603": {
        "330603001": "柯桥街道",
        "330603002": "柯岩街道",
        "330603003": "华舍街道",
        "330603004": "湖塘街道",
        "330603005": "齐贤街道",
        "330603006": "福全街道",
        "330603007": "安昌街道",
        "330603008": "兰亭街道",
        "330603101": "钱清镇",
        "330603104": "马鞍镇",
        "330603105": "平水镇",
        "330603107": "王坛镇",
        "330603109": "稽东镇",
        "330603110": "杨汛桥镇",
        "330603111": "漓渚镇",
        "330603114": "夏履镇"
    },
    "330604": {
        "330604001": "百官街道",
        "330604002": "曹娥街道",
        "330604003": "东关街道",
        "330604004": "道墟街道",
        "330604005": "梁湖街道",
        "330604006": "小越街道",
        "330604101": "长塘镇",
        "330604102": "上浦镇",
        "330604103": "汤浦镇",
        "330604104": "章镇镇",
        "330604105": "下管镇",
        "330604106": "丰惠镇",
        "330604107": "永和镇",
        "330604109": "驿亭镇",
        "330604111": "谢塘镇",
        "330604112": "盖北镇",
        "330604113": "崧厦镇",
        "330604114": "沥海镇",
        "330604200": "岭南乡",
        "330604201": "陈溪乡",
        "330604202": "丁宅乡"
    },
    "330624": {
        "330624001": "羽林街道",
        "330624002": "南明街道",
        "330624003": "七星街道",
        "330624101": "澄潭镇",
        "330624102": "梅渚镇",
        "330624104": "回山镇",
        "330624106": "大市聚镇",
        "330624107": "小将镇",
        "330624108": "沙溪镇",
        "330624109": "镜岭镇",
        "330624110": "儒岙镇",
        "330624200": "城南乡",
        "330624201": "东茗乡",
        "330624203": "双彩乡",
        "330624205": "新林乡",
        "330624206": "巧英乡"
    },
    "330681": {
        "330681001": "暨阳街道",
        "330681002": "浣东街道",
        "330681003": "陶朱街道",
        "330681101": "大唐镇",
        "330681102": "应店街镇",
        "330681103": "次坞镇",
        "330681104": "店口镇",
        "330681105": "阮市镇",
        "330681106": "直埠镇",
        "330681107": "江藻镇",
        "330681108": "山下湖镇",
        "330681109": "枫桥镇",
        "330681110": "赵家镇",
        "330681111": "马剑镇",
        "330681112": "五泄镇",
        "330681113": "草塔镇",
        "330681114": "王家井镇",
        "330681115": "牌头镇",
        "330681116": "同山镇",
        "330681117": "安华镇",
        "330681118": "街亭镇",
        "330681119": "璜山镇",
        "330681120": "陈宅镇",
        "330681121": "岭北镇",
        "330681122": "浬浦镇",
        "330681124": "东白湖镇",
        "330681201": "东和乡"
    },
    "330683": {
        "330683001": "剡湖街道",
        "330683002": "三江街道",
        "330683003": "鹿山街道",
        "330683004": "浦口街道",
        "330683100": "甘霖镇",
        "330683101": "长乐镇",
        "330683102": "崇仁镇",
        "330683103": "黄泽镇",
        "330683104": "三界镇",
        "330683105": "石璜镇",
        "330683106": "谷来镇",
        "330683107": "仙岩镇",
        "330683108": "金庭镇",
        "330683109": "北漳镇",
        "330683110": "下王镇",
        "330683200": "贵门乡",
        "330683201": "里南乡",
        "330683202": "竹溪乡",
        "330683203": "雅璜乡",
        "330683204": "王院乡",
        "330683205": "通源乡",
        "330683400": "嵊州经济开发区"
    },
    "330700": {
        "330702": "婺城区",
        "330703": "金东区",
        "330723": "武义县",
        "330726": "浦江县",
        "330727": "磐安县",
        "330781": "兰溪市",
        "330782": "义乌市",
        "330783": "东阳市",
        "330784": "永康市"
    },
    "330702": {
        "330702001": "城东街道",
        "330702002": "城中街道",
        "330702003": "城西街道",
        "330702004": "城北街道",
        "330702005": "江南街道",
        "330702006": "三江街道",
        "330702007": "西关街道",
        "330702008": "秋滨街道",
        "330702009": "新狮街道",
        "330702100": "罗店镇",
        "330702101": "雅畈镇",
        "330702102": "安地镇",
        "330702103": "白龙桥镇",
        "330702104": "琅琊镇",
        "330702105": "蒋堂镇",
        "330702106": "汤溪镇",
        "330702107": "罗埠镇",
        "330702108": "洋埠镇",
        "330702201": "乾西乡",
        "330702202": "竹马乡",
        "330702203": "长山乡",
        "330702204": "箬阳乡",
        "330702205": "沙畈乡",
        "330702206": "塔石乡",
        "330702207": "岭上乡",
        "330702208": "莘畈乡",
        "330702209": "苏孟乡"
    },
    "330703": {
        "330703001": "多湖街道",
        "330703002": "东孝街道",
        "330703101": "孝顺镇",
        "330703102": "傅村镇",
        "330703103": "曹宅镇",
        "330703104": "澧浦镇",
        "330703105": "岭下镇",
        "330703106": "江东镇",
        "330703107": "塘雅镇",
        "330703108": "赤松镇",
        "330703200": "源东乡"
    },
    "330723": {
        "330723001": "白洋街道",
        "330723002": "壶山街道",
        "330723003": "熟溪街道",
        "330723100": "柳城畲族镇",
        "330723101": "履坦镇",
        "330723102": "桐琴镇",
        "330723103": "泉溪镇",
        "330723104": "新宅镇",
        "330723105": "王宅镇",
        "330723106": "桃溪镇",
        "330723107": "茭道镇",
        "330723200": "大田乡",
        "330723201": "白姆乡",
        "330723202": "俞源乡",
        "330723203": "坦洪乡",
        "330723204": "西联乡",
        "330723205": "三港乡",
        "330723206": "大溪口乡"
    },
    "330726": {
        "330726001": "浦南街道",
        "330726002": "仙华街道",
        "330726003": "浦阳街道",
        "330726101": "黄宅镇",
        "330726102": "白马镇",
        "330726103": "郑家坞镇",
        "330726104": "郑宅镇",
        "330726105": "岩头镇",
        "330726106": "檀溪镇",
        "330726107": "杭坪镇",
        "330726200": "大畈乡",
        "330726201": "中余乡",
        "330726202": "前吴乡",
        "330726203": "花桥乡",
        "330726204": "虞宅乡"
    },
    "330727": {
        "330727001": "安文街道",
        "330727002": "新渥街道",
        "330727103": "仁川镇",
        "330727104": "大盘镇",
        "330727105": "方前镇",
        "330727106": "玉山镇",
        "330727107": "尚湖镇",
        "330727108": "冷水镇",
        "330727109": "尖山镇",
        "330727201": "双峰乡",
        "330727203": "双溪乡",
        "330727205": "窈川乡",
        "330727211": "九和乡",
        "330727212": "盘峰乡"
    },
    "330781": {
        "330781001": "兰江街道",
        "330781002": "云山街道",
        "330781004": "永昌街道",
        "330781005": "赤溪街道",
        "330781006": "女埠街道",
        "330781007": "上华街道",
        "330781102": "游埠镇",
        "330781104": "诸葛镇",
        "330781106": "黄店镇",
        "330781108": "香溪镇",
        "330781109": "马涧镇",
        "330781111": "梅江镇",
        "330781112": "横溪镇",
        "330781200": "灵洞乡",
        "330781203": "水亭畲族乡",
        "330781209": "柏社乡"
    },
    "330782": {
        "330782001": "稠城街道",
        "330782002": "江东街道",
        "330782003": "稠江街道",
        "330782004": "北苑街道",
        "330782005": "后宅街道",
        "330782006": "城西街道",
        "330782007": "廿三里街道",
        "330782008": "福田街道",
        "330782100": "佛堂镇",
        "330782101": "赤岸镇",
        "330782102": "义亭镇",
        "330782104": "上溪镇",
        "330782105": "苏溪镇",
        "330782106": "大陈镇"
    },
    "330783": {
        "330783001": "吴宁街道",
        "330783003": "南市街道",
        "330783004": "白云街道",
        "330783005": "江北街道",
        "330783006": "城东街道",
        "330783007": "六石街道",
        "330783106": "巍山镇",
        "330783107": "虎鹿镇",
        "330783108": "歌山镇",
        "330783109": "佐村镇",
        "330783110": "东阳江镇",
        "330783112": "湖溪镇",
        "330783114": "马宅镇",
        "330783116": "千祥镇",
        "330783118": "南马镇",
        "330783122": "画水镇",
        "330783123": "横店镇",
        "330783201": "三单乡"
    },
    "330784": {
        "330784001": "东城街道",
        "330784002": "西城街道",
        "330784003": "江南街道",
        "330784105": "石柱镇",
        "330784106": "前仓镇",
        "330784107": "舟山镇",
        "330784108": "古山镇",
        "330784109": "方岩镇",
        "330784110": "龙山镇",
        "330784111": "西溪镇",
        "330784112": "象珠镇",
        "330784113": "唐先镇",
        "330784114": "花街镇",
        "330784115": "芝英镇"
    },
    "330800": {"330802": "柯城区", "330803": "衢江区", "330822": "常山县", "330824": "开化县", "330825": "龙游县", "330881": "江山市"},
    "330802": {
        "330802005": "新新街道",
        "330802006": "府山街道",
        "330802007": "荷花街道",
        "330802008": "信安街道",
        "330802009": "白云街道",
        "330802010": "双港街道",
        "330802012": "衢化街道",
        "330802013": "花园街道",
        "330802100": "石梁镇",
        "330802101": "航埠镇",
        "330802205": "黄家乡",
        "330802209": "七里乡",
        "330802210": "九华乡",
        "330802211": "沟溪乡",
        "330802212": "华墅乡",
        "330802213": "姜家山乡",
        "330802214": "万田乡",
        "330802215": "石室乡"
    },
    "330803": {
        "330803001": "樟潭街道",
        "330803002": "浮石街道",
        "330803100": "上方镇",
        "330803101": "峡川镇",
        "330803103": "莲花镇",
        "330803109": "全旺镇",
        "330803110": "大洲镇",
        "330803111": "后溪镇",
        "330803112": "廿里镇",
        "330803113": "湖南镇",
        "330803114": "高家镇",
        "330803115": "杜泽镇",
        "330803200": "灰坪乡",
        "330803202": "太真乡",
        "330803203": "双桥乡",
        "330803206": "周家乡",
        "330803207": "云溪乡",
        "330803213": "举村乡",
        "330803218": "岭洋乡",
        "330803219": "黄坛口乡"
    },
    "330822": {
        "330822001": "天马街道",
        "330822002": "紫港街道",
        "330822003": "金川街道",
        "330822104": "白石镇",
        "330822108": "招贤镇",
        "330822109": "青石镇",
        "330822111": "球川镇",
        "330822115": "辉埠镇",
        "330822116": "芳村镇",
        "330822200": "何家乡",
        "330822211": "同弓乡",
        "330822213": "大桥头乡",
        "330822219": "新昌乡",
        "330822221": "东案乡"
    },
    "330824": {
        "330824106": "桐村镇",
        "330824107": "杨林镇",
        "330824108": "苏庄镇",
        "330824109": "齐溪镇",
        "330824113": "村头镇",
        "330824115": "华埠镇",
        "330824116": "马金镇",
        "330824117": "池淮镇",
        "330824202": "中村乡",
        "330824205": "长虹乡",
        "330824208": "何田乡",
        "330824216": "林山乡",
        "330824217": "音坑乡",
        "330824218": "大溪边乡"
    },
    "330825": {
        "330825001": "龙洲街道",
        "330825002": "东华街道",
        "330825101": "湖镇镇",
        "330825102": "小南海镇",
        "330825106": "詹家镇",
        "330825109": "溪口镇",
        "330825110": "横山镇",
        "330825111": "塔石镇",
        "330825201": "罗家乡",
        "330825203": "庙下乡",
        "330825207": "石佛乡",
        "330825209": "社阳乡",
        "330825211": "大街乡",
        "330825212": "沐尘畲族乡",
        "330825213": "模环乡"
    },
    "330881": {
        "330881001": "双塔街道",
        "330881002": "虎山街道",
        "330881003": "清湖街道",
        "330881102": "四都镇",
        "330881105": "坛石镇",
        "330881106": "大桥镇",
        "330881109": "新塘边镇",
        "330881112": "廿八都镇",
        "330881113": "长台镇",
        "330881116": "上余镇",
        "330881118": "凤林镇",
        "330881119": "峡口镇",
        "330881120": "石门镇",
        "330881121": "贺村镇",
        "330881201": "大陈乡",
        "330881203": "碗窑乡",
        "330881207": "保安乡",
        "330881213": "塘源口乡",
        "330881214": "张村乡"
    },
    "330900": {"330902": "定海区", "330903": "普陀区", "330921": "岱山县", "330922": "嵊泗县"},
    "330902": {
        "330902002": "昌国街道",
        "330902003": "环南街道",
        "330902004": "城东街道",
        "330902005": "盐仓街道",
        "330902006": "临城街道",
        "330902007": "岑港街道",
        "330902008": "马岙街道",
        "330902009": "双桥街道",
        "330902010": "小沙街道",
        "330902050": "千岛街道",
        "330902100": "金塘镇",
        "330902104": "白泉镇",
        "330902105": "干览镇"
    },
    "330903": {
        "330903001": "沈家门街道",
        "330903003": "东港街道",
        "330903004": "朱家尖街道",
        "330903005": "展茅街道",
        "330903100": "六横镇",
        "330903102": "虾峙镇",
        "330903104": "桃花镇",
        "330903105": "东极镇",
        "330903106": "普陀山镇"
    },
    "330921": {
        "330921100": "高亭镇",
        "330921101": "东沙镇",
        "330921102": "岱东镇",
        "330921103": "岱西镇",
        "330921105": "长涂镇",
        "330921106": "衢山镇",
        "330921200": "秀山乡"
    },
    "330922": {
        "330922100": "菜园镇",
        "330922101": "嵊山镇",
        "330922102": "洋山镇",
        "330922200": "五龙乡",
        "330922201": "黄龙乡",
        "330922202": "枸杞乡",
        "330922203": "花鸟乡"
    },
    "331000": {
        "331002": "椒江区",
        "331003": "黄岩区",
        "331004": "路桥区",
        "331022": "三门县",
        "331023": "天台县",
        "331024": "仙居县",
        "331081": "温岭市",
        "331082": "临海市",
        "331083": "玉环市"
    },
    "331002": {
        "331002001": "海门街道",
        "331002002": "白云街道",
        "331002003": "葭沚街道",
        "331002004": "洪家街道",
        "331002005": "三甲街道",
        "331002006": "下陈街道",
        "331002007": "前所街道",
        "331002008": "章安街道",
        "331002101": "大陈镇",
        "331002400": "椒江农场",
        "331002401": "滨海工业区",
        "331002402": "月湖新城"
    },
    "331003": {
        "331003001": "东城街道",
        "331003002": "南城街道",
        "331003003": "西城街道",
        "331003004": "北城街道",
        "331003005": "新前街道",
        "331003006": "澄江街道",
        "331003007": "江口街道",
        "331003008": "高桥街道",
        "331003101": "宁溪镇",
        "331003102": "北洋镇",
        "331003103": "头陀镇",
        "331003107": "院桥镇",
        "331003108": "沙埠镇",
        "331003200": "屿头乡",
        "331003201": "上郑乡",
        "331003202": "富山乡",
        "331003203": "茅畲乡",
        "331003204": "上垟乡",
        "331003205": "平田乡"
    },
    "331004": {
        "331004001": "路南街道",
        "331004002": "路桥街道",
        "331004003": "路北街道",
        "331004004": "螺洋街道",
        "331004005": "桐屿街道",
        "331004006": "峰江街道",
        "331004103": "新桥镇",
        "331004104": "横街镇",
        "331004106": "金清镇",
        "331004107": "蓬街镇",
        "331004400": "滨海工业城",
        "331004401": "滨海新区"
    },
    "331022": {
        "331022001": "海游街道",
        "331022002": "海润街道",
        "331022003": "沙柳街道",
        "331022102": "珠岙镇",
        "331022103": "亭旁镇",
        "331022105": "健跳镇",
        "331022106": "横渡镇",
        "331022107": "浦坝港镇",
        "331022108": "花桥镇",
        "331022205": "蛇蟠乡"
    },
    "331023": {
        "331023001": "赤城街道",
        "331023002": "始丰街道",
        "331023003": "福溪街道",
        "331023101": "白鹤镇",
        "331023102": "石梁镇",
        "331023104": "街头镇",
        "331023105": "平桥镇",
        "331023106": "坦头镇",
        "331023107": "三合镇",
        "331023108": "洪畴镇",
        "331023200": "三州乡",
        "331023203": "龙溪乡",
        "331023204": "雷峰乡",
        "331023207": "南屏乡",
        "331023209": "泳溪乡"
    },
    "331024": {
        "331024001": "安洲街道",
        "331024002": "南峰街道",
        "331024003": "福应街道",
        "331024101": "横溪镇",
        "331024102": "埠头镇",
        "331024103": "白塔镇",
        "331024104": "田市镇",
        "331024105": "官路镇",
        "331024106": "下各镇",
        "331024107": "朱溪镇",
        "331024200": "安岭乡",
        "331024201": "溪港乡",
        "331024202": "湫山乡",
        "331024203": "淡竹乡",
        "331024204": "皤滩乡",
        "331024205": "上张乡",
        "331024206": "步路乡",
        "331024207": "广度乡",
        "331024209": "大战乡",
        "331024210": "双庙乡"
    },
    "331081": {
        "331081001": "太平街道",
        "331081002": "城东街道",
        "331081003": "城西街道",
        "331081004": "城北街道",
        "331081005": "横峰街道",
        "331081100": "泽国镇",
        "331081101": "大溪镇",
        "331081102": "松门镇",
        "331081103": "箬横镇",
        "331081104": "新河镇",
        "331081105": "石塘镇",
        "331081106": "滨海镇",
        "331081107": "温峤镇",
        "331081108": "城南镇",
        "331081109": "石桥头镇",
        "331081110": "坞根镇",
        "331081400": "东部新区"
    },
    "331082": {
        "331082001": "古城街道",
        "331082002": "大洋街道",
        "331082003": "江南街道",
        "331082004": "大田街道",
        "331082005": "邵家渡街道",
        "331082100": "汛桥镇",
        "331082101": "东塍镇",
        "331082103": "汇溪镇",
        "331082104": "小芝镇",
        "331082105": "河头镇",
        "331082106": "白水洋镇",
        "331082107": "括苍镇",
        "331082108": "永丰镇",
        "331082109": "尤溪镇",
        "331082110": "涌泉镇",
        "331082111": "沿江镇",
        "331082112": "杜桥镇",
        "331082113": "上盘镇",
        "331082114": "桃渚镇"
    },
    "331083": {
        "331083001": "玉城街道",
        "331083002": "坎门街道",
        "331083003": "大麦屿街道",
        "331083101": "清港镇",
        "331083102": "楚门镇",
        "331083103": "干江镇",
        "331083105": "沙门镇",
        "331083106": "芦浦镇",
        "331083107": "龙溪镇",
        "331083201": "鸡山乡",
        "331083202": "海山乡",
        "331083400": "玉环经济开发区"
    },
    "331100": {
        "331102": "莲都区",
        "331121": "青田县",
        "331122": "缙云县",
        "331123": "遂昌县",
        "331124": "松阳县",
        "331125": "云和县",
        "331126": "庆元县",
        "331127": "景宁畲族自治县",
        "331181": "龙泉市"
    },
    "331102": {
        "331102001": "紫金街道",
        "331102002": "岩泉街道",
        "331102003": "万象街道",
        "331102004": "白云街道",
        "331102007": "联城街道",
        "331102008": "南明山街道",
        "331102100": "碧湖镇",
        "331102102": "大港头镇",
        "331102103": "老竹畲族镇",
        "331102104": "雅溪镇",
        "331102200": "太平乡",
        "331102201": "仙渡乡",
        "331102202": "峰源乡",
        "331102204": "丽新畲族乡",
        "331102206": "黄村乡"
    },
    "331121": {
        "331121001": "鹤城街道",
        "331121002": "瓯南街道",
        "331121003": "油竹街道",
        "331121101": "温溪镇",
        "331121102": "东源镇",
        "331121103": "高湖镇",
        "331121104": "船寮镇",
        "331121105": "海口镇",
        "331121106": "腊口镇",
        "331121107": "北山镇",
        "331121108": "山口镇",
        "331121109": "仁庄镇",
        "331121200": "万山乡",
        "331121201": "黄垟乡",
        "331121202": "季宅乡",
        "331121203": "高市乡",
        "331121204": "海溪乡",
        "331121205": "章村乡",
        "331121206": "祯旺乡",
        "331121207": "祯埠乡",
        "331121208": "舒桥乡",
        "331121209": "巨浦乡",
        "331121211": "万阜乡",
        "331121212": "方山乡",
        "331121213": "汤垟乡",
        "331121214": "贵岙乡",
        "331121215": "小舟山乡",
        "331121216": "吴坑乡",
        "331121217": "仁宫乡",
        "331121218": "章旦乡",
        "331121219": "阜山乡",
        "331121220": "石溪乡"
    },
    "331122": {
        "331122001": "五云街道",
        "331122002": "新碧街道",
        "331122003": "仙都街道",
        "331122101": "壶镇镇",
        "331122102": "新建镇",
        "331122103": "舒洪镇",
        "331122105": "大洋镇",
        "331122106": "东渡镇",
        "331122107": "东方镇",
        "331122108": "大源镇",
        "331122203": "七里乡",
        "331122206": "前路乡",
        "331122207": "三溪乡",
        "331122208": "溶江乡",
        "331122209": "双溪口乡",
        "331122210": "胡源乡",
        "331122211": "方溪乡",
        "331122212": "石笕乡"
    },
    "331123": {
        "331123001": "妙高街道",
        "331123002": "云峰街道",
        "331123102": "新路湾镇",
        "331123103": "北界镇",
        "331123104": "金竹镇",
        "331123105": "大柘镇",
        "331123106": "石练镇",
        "331123107": "王村口镇",
        "331123108": "黄沙腰镇",
        "331123200": "三仁畲族乡",
        "331123201": "濂竹乡",
        "331123202": "应村乡",
        "331123203": "高坪乡",
        "331123204": "湖山乡",
        "331123205": "蔡源乡",
        "331123206": "焦滩乡",
        "331123207": "龙洋乡",
        "331123208": "柘岱口乡",
        "331123209": "西畈乡",
        "331123210": "垵口乡"
    },
    "331124": {
        "331124001": "西屏街道",
        "331124002": "水南街道",
        "331124003": "望松街道",
        "331124101": "古市镇",
        "331124102": "玉岩镇",
        "331124103": "象溪镇",
        "331124104": "大东坝镇",
        "331124105": "新兴镇",
        "331124201": "叶村乡",
        "331124202": "斋坛乡",
        "331124203": "三都乡",
        "331124204": "竹源乡",
        "331124205": "四都乡",
        "331124206": "赤寿乡",
        "331124208": "樟溪乡",
        "331124211": "枫坪乡",
        "331124212": "板桥畲族乡",
        "331124213": "裕溪乡",
        "331124214": "安民乡"
    },
    "331125": {
        "331125001": "浮云街道",
        "331125002": "元和街道",
        "331125003": "白龙山街道",
        "331125004": "凤凰山街道",
        "331125105": "崇头镇",
        "331125106": "石塘镇",
        "331125107": "紧水滩镇",
        "331125201": "雾溪畲族乡",
        "331125202": "安溪畲族乡",
        "331125206": "赤石乡"
    },
    "331126": {
        "331126001": "松源街道",
        "331126002": "濛洲街道",
        "331126003": "屏都街道",
        "331126101": "黄田镇",
        "331126102": "竹口镇",
        "331126104": "荷地镇",
        "331126105": "左溪镇",
        "331126106": "贤良镇",
        "331126107": "百山祖镇",
        "331126200": "岭头乡",
        "331126201": "五大堡乡",
        "331126202": "淤上乡",
        "331126203": "安南乡",
        "331126204": "张村乡",
        "331126205": "隆宫乡",
        "331126206": "举水乡",
        "331126207": "江根乡",
        "331126209": "龙溪乡",
        "331126211": "官塘乡"
    },
    "331127": {
        "331127001": "红星街道",
        "331127002": "鹤溪街道",
        "331127101": "渤海镇",
        "331127102": "东坑镇",
        "331127103": "英川镇",
        "331127104": "沙湾镇",
        "331127201": "大均乡",
        "331127202": "澄照乡",
        "331127203": "梅岐乡",
        "331127205": "郑坑乡",
        "331127208": "大漈乡",
        "331127209": "景南乡",
        "331127210": "雁溪乡",
        "331127212": "鸬鹚乡",
        "331127213": "梧桐乡",
        "331127214": "标溪乡",
        "331127215": "毛垟乡",
        "331127216": "秋炉乡",
        "331127217": "大地乡",
        "331127218": "家地乡",
        "331127219": "九龙乡"
    },
    "331181": {
        "331181001": "龙渊街道",
        "331181002": "西街街道",
        "331181003": "剑池街道",
        "331181004": "石达石街道",
        "331181100": "八都镇",
        "331181101": "上垟镇",
        "331181102": "小梅镇",
        "331181103": "查田镇",
        "331181104": "安仁镇",
        "331181105": "锦溪镇",
        "331181106": "住龙镇",
        "331181107": "屏南镇",
        "331181200": "兰巨乡",
        "331181202": "宝溪乡",
        "331181203": "竹垟畲族乡",
        "331181204": "道太乡",
        "331181205": "岩樟乡",
        "331181206": "城北乡",
        "331181207": "龙南乡"
    },
    "340000": {
        "340100": "合肥市",
        "340200": "芜湖市",
        "340300": "蚌埠市",
        "340400": "淮南市",
        "340500": "马鞍山市",
        "340600": "淮北市",
        "340700": "铜陵市",
        "340800": "安庆市",
        "341000": "黄山市",
        "341100": "滁州市",
        "341200": "阜阳市",
        "341300": "宿州市",
        "341500": "六安市",
        "341600": "亳州市",
        "341700": "池州市",
        "341800": "宣城市"
    },
    "340100": {
        "340102": "瑶海区",
        "340103": "庐阳区",
        "340104": "蜀山区",
        "340111": "包河区",
        "340121": "长丰县",
        "340122": "肥东县",
        "340123": "肥西县",
        "340124": "庐江县",
        "340171": "合肥高新技术产业开发区",
        "340172": "合肥经济技术开发区",
        "340173": "合肥新站高新技术产业开发区",
        "340181": "巢湖市"
    },
    "340102": {
        "340102001": "明光路街道",
        "340102003": "胜利路街道",
        "340102004": "三里街街道",
        "340102005": "铜陵路街道",
        "340102006": "七里站街道",
        "340102007": "红光街道",
        "340102008": "和平路街道",
        "340102010": "城东街道",
        "340102011": "长淮街道",
        "340102012": "方庙街道",
        "340102014": "嘉山路街道",
        "340102100": "大兴镇",
        "340102401": "合肥龙岗综合经济开发区"
    },
    "340103": {
        "340103001": "亳州路街道",
        "340103002": "双岗街道",
        "340103003": "杏林街道",
        "340103004": "海棠街道",
        "340103005": "杏花村街道",
        "340103006": "逍遥津街道",
        "340103009": "三孝口街道",
        "340103012": "四里河街道",
        "340103100": "大杨镇",
        "340103200": "三十岗乡",
        "340103400": "庐阳工业区（林店街道）"
    },
    "340104": {
        "340104001": "三里庵街道",
        "340104002": "稻香村街道",
        "340104003": "琥珀街道",
        "340104004": "南七街道",
        "340104005": "西园街道",
        "340104006": "五里墩街道",
        "340104007": "荷叶地街道",
        "340104008": "笔架山街道",
        "340104100": "井岗镇",
        "340104101": "南岗镇",
        "340104102": "小庙镇",
        "340104400": "蜀山新产业园区"
    },
    "340111": {
        "340111001": "骆岗街道",
        "340111002": "常青街道",
        "340111003": "芜湖路街道",
        "340111004": "包公街道",
        "340111005": "望湖街道",
        "340111006": "义城街道",
        "340111007": "烟墩街道",
        "340111008": "滨湖世纪社区",
        "340111009": "方兴社区",
        "340111010": "万年埠街道",
        "340111100": "淝河镇",
        "340111102": "大圩镇"
    },
    "340121": {
        "340121100": "水湖镇",
        "340121101": "庄墓镇",
        "340121102": "杨庙镇",
        "340121103": "吴山镇",
        "340121104": "岗集镇",
        "340121105": "双墩镇",
        "340121106": "下塘镇",
        "340121107": "朱巷镇",
        "340121109": "陶楼镇",
        "340121110": "杜集镇",
        "340121200": "罗塘乡",
        "340121201": "义井乡",
        "340121203": "造甲乡",
        "340121204": "左店乡",
        "340121400": "双凤开发区",
        "340121401": "安徽省水家湖农场"
    },
    "340122": {
        "340122100": "店埠镇",
        "340122101": "撮镇镇",
        "340122102": "梁园镇",
        "340122103": "桥头集镇",
        "340122104": "长临河镇",
        "340122105": "石塘镇",
        "340122106": "古城镇",
        "340122107": "八斗镇",
        "340122108": "元疃镇",
        "340122109": "白龙镇",
        "340122110": "包公镇",
        "340122111": "陈集镇",
        "340122200": "众兴乡",
        "340122201": "张集乡",
        "340122202": "马湖乡",
        "340122204": "响导乡",
        "340122205": "杨店乡",
        "340122206": "牌坊回族满族乡",
        "340122401": "肥东新城开发区",
        "340122402": "合肥循环经济示范园"
    },
    "340123": {
        "340123100": "上派镇",
        "340123101": "三河镇",
        "340123103": "官亭镇",
        "340123105": "山南镇",
        "340123106": "花岗镇",
        "340123107": "紫蓬镇",
        "340123108": "桃花镇",
        "340123109": "丰乐镇",
        "340123200": "高店乡",
        "340123201": "铭传乡",
        "340123202": "柿树岗乡",
        "340123203": "严店乡",
        "340123400": "桃花工业园管委会",
        "340123401": "紫蓬山管委会"
    },
    "340124": {
        "340124100": "庐城镇",
        "340124101": "冶父山镇",
        "340124102": "万山镇",
        "340124103": "汤池镇",
        "340124104": "郭河镇",
        "340124105": "金牛镇",
        "340124106": "石头镇",
        "340124107": "同大镇",
        "340124108": "白山镇",
        "340124109": "盛桥镇",
        "340124110": "白湖镇",
        "340124111": "龙桥镇",
        "340124112": "矾山镇",
        "340124113": "罗河镇",
        "340124114": "泥河镇",
        "340124115": "乐桥镇",
        "340124116": "柯坦镇",
        "340124400": "安徽庐江经济开发区"
    },
    "340171": {"340171401": "天乐社区服务中心", "340171402": "兴园社区服务中心", "340171403": "蜀麓社区服务中心", "340171404": "长宁社区服务中心"},
    "340172": {
        "340172001": "莲花社区管理委员会",
        "340172002": "芙蓉社区管理委员会",
        "340172003": "锦绣社区管理委员会",
        "340172004": "海恒社区管理委员会",
        "340172005": "临湖社区管理委员会",
        "340172100": "高刘镇"
    },
    "340173": {
        "340173001": "七里塘街道",
        "340173002": "磨店街道",
        "340173003": "三十头街道",
        "340173400": "瑶海工业园",
        "340173401": "站北社区"
    },
    "340181": {
        "340181001": "中庙街道",
        "340181002": "亚父街道",
        "340181003": "卧牛山街道",
        "340181004": "凤凰山街道",
        "340181005": "天河街道",
        "340181006": "半汤街道",
        "340181100": "栏杆集镇",
        "340181101": "苏湾镇",
        "340181102": "柘皋镇",
        "340181103": "银屏镇",
        "340181104": "夏阁镇",
        "340181105": "中垾镇",
        "340181106": "散兵镇",
        "340181107": "烔炀镇",
        "340181108": "黄麓镇",
        "340181109": "槐林镇",
        "340181110": "坝镇镇",
        "340181200": "庙岗乡"
    },
    "340200": {
        "340202": "镜湖区",
        "340203": "弋江区",
        "340207": "鸠江区",
        "340208": "三山区",
        "340221": "芜湖县",
        "340222": "繁昌县",
        "340223": "南陵县",
        "340225": "无为县",
        "340271": "芜湖经济技术开发区",
        "340272": "安徽芜湖长江大桥经济开发区"
    },
    "340202": {
        "340202012": "方村街道",
        "340202401": "张家山公共服务中心",
        "340202402": "赭麓公共服务中心",
        "340202403": "滨江公共服务中心",
        "340202404": "赭山公共服务中心",
        "340202405": "弋矶山公共服务中心",
        "340202406": "汀棠公共服务中心",
        "340202407": "天门山公共服务中心",
        "340202408": "大砻坊公共服务中心",
        "340202409": "镜湖新城公共服务中心",
        "340202410": "荆山公共服务中心"
    },
    "340203": {
        "340203001": "弋江桥街道",
        "340203002": "中南街道",
        "340203004": "马塘街道",
        "340203005": "瀂港街道",
        "340203006": "火龙街道",
        "340203007": "白马街道",
        "340203098": "南瑞社区公共服务委员会",
        "340203400": "芜湖高新技术产业开发区"
    },
    "340207": {
        "340207001": "四褐山街道",
        "340207002": "裕溪口街道",
        "340207003": "官陡街道",
        "340207004": "湾里街道",
        "340207005": "清水街道",
        "340207101": "沈巷镇",
        "340207102": "二坝镇",
        "340207103": "汤沟镇",
        "340207104": "白茆镇",
        "340207401": "安徽省江北产业集中区",
        "340207402": "安徽芜湖鸠江经济开发区",
        "340207403": "鸠江经济开发区二坝园区"
    },
    "340208": {
        "340208001": "三山街道",
        "340208002": "保定街道",
        "340208003": "龙湖街道",
        "340208100": "峨桥镇",
        "340208400": "安徽芜湖三山经济开发区"
    },
    "340221": {
        "340221100": "湾沚镇",
        "340221101": "六郎镇",
        "340221102": "陶辛镇",
        "340221104": "红杨镇",
        "340221105": "花桥镇",
        "340221400": "安徽新芜经济开发区"
    },
    "340222": {
        "340222100": "繁阳镇",
        "340222101": "荻港镇",
        "340222102": "孙村镇",
        "340222103": "平铺镇",
        "340222104": "新港镇",
        "340222105": "峨山镇",
        "340222401": "安徽繁昌工业园区"
    },
    "340223": {
        "340223100": "籍山镇",
        "340223101": "许镇镇",
        "340223102": "弋江镇",
        "340223103": "三里镇",
        "340223104": "何湾镇",
        "340223105": "工山镇",
        "340223106": "烟墩镇",
        "340223107": "家发镇",
        "340223400": "安徽南陵工业园区"
    },
    "340225": {
        "340225100": "无城镇",
        "340225101": "襄安镇",
        "340225104": "陡沟镇",
        "340225105": "石涧镇",
        "340225106": "严桥镇",
        "340225107": "开城镇",
        "340225108": "蜀山镇",
        "340225109": "牛埠镇",
        "340225110": "刘渡镇",
        "340225111": "姚沟镇",
        "340225112": "泥汊镇",
        "340225114": "福渡镇",
        "340225115": "泉塘镇",
        "340225116": "赫店镇",
        "340225117": "红庙镇",
        "340225118": "高沟镇",
        "340225119": "鹤毛镇",
        "340225120": "十里墩镇",
        "340225121": "昆山镇",
        "340225122": "洪巷镇",
        "340225400": "安徽无为经济开发区"
    },
    "340271": {"340271001": "龙山街道", "340271002": "万春街道"},
    "340272": {"340272001": "高安街道"},
    "340300": {
        "340302": "龙子湖区",
        "340303": "蚌山区",
        "340304": "禹会区",
        "340311": "淮上区",
        "340321": "怀远县",
        "340322": "五河县",
        "340323": "固镇县",
        "340371": "蚌埠市高新技术开发区",
        "340372": "蚌埠市经济开发区"
    },
    "340302": {
        "340302001": "东风街道",
        "340302002": "延安街道",
        "340302003": "治淮街道",
        "340302004": "东升街道",
        "340302005": "解放街道",
        "340302006": "曹山街道",
        "340302100": "长淮卫镇",
        "340302200": "李楼乡"
    },
    "340303": {
        "340303001": "天桥街道",
        "340303002": "青年街道",
        "340303003": "纬二路街道",
        "340303004": "黄庄街道",
        "340303005": "宏业村街道",
        "340303200": "燕山乡",
        "340303201": "雪华乡"
    },
    "340304": {
        "340304100": "秦集镇",
        "340304101": "马城镇",
        "340304198": "禹会虚镇",
        "340304200": "长青乡",
        "340304401": "安徽怀远马城经济开发区"
    },
    "340311": {
        "340311001": "淮滨社区",
        "340311002": "明珠社区",
        "340311100": "小蚌埠镇",
        "340311101": "吴小街镇",
        "340311102": "曹老集镇",
        "340311103": "梅桥镇",
        "340311104": "沫河口镇",
        "340311400": "蚌埠工业园区",
        "340311401": "沫河口工业园区"
    },
    "340321": {
        "340321100": "榴城镇",
        "340321101": "包集镇",
        "340321102": "龙亢镇",
        "340321103": "河溜镇",
        "340321104": "常坟镇",
        "340321106": "双桥集镇",
        "340321107": "魏庄镇",
        "340321108": "万福镇",
        "340321109": "唐集镇",
        "340321110": "白莲坡镇",
        "340321111": "褚集镇",
        "340321112": "古城镇",
        "340321113": "荆山镇",
        "340321114": "淝南镇",
        "340321115": "陈集镇",
        "340321201": "淝河乡",
        "340321205": "徐圩乡",
        "340321206": "兰桥乡",
        "340321400": "龙亢农场",
        "340321401": "经济开发区",
        "340321403": "安徽怀远龙亢经济开发区"
    },
    "340322": {
        "340322100": "城关镇",
        "340322101": "新集镇",
        "340322103": "小溪镇",
        "340322104": "双忠庙镇",
        "340322105": "小圩镇",
        "340322106": "东刘集镇",
        "340322107": "头铺镇",
        "340322108": "大新镇",
        "340322109": "武桥镇",
        "340322110": "朱顶镇",
        "340322111": "浍南镇",
        "340322112": "申集镇",
        "340322200": "沱湖乡",
        "340322201": "临北回族乡",
        "340322400": "城南工业园"
    },
    "340323": {
        "340323100": "城关镇",
        "340323101": "王庄镇",
        "340323102": "新马桥镇",
        "340323103": "连城镇",
        "340323104": "刘集镇",
        "340323105": "任桥镇",
        "340323106": "湖沟镇",
        "340323107": "濠城镇",
        "340323200": "石湖乡",
        "340323201": "杨庙乡",
        "340323202": "仲兴乡",
        "340323400": "固镇开发区",
        "340323401": "蚌埠铜陵产业园"
    },
    "340371": {"340371400": "高新技术开发区"},
    "340372": {"340372006": "胜利街道", "340372007": "龙湖新村街道", "340372008": "湖滨社区行政事务管理中心", "340372009": "淮河社区行政事务管理中心"},
    "340400": {
        "340402": "大通区",
        "340403": "田家庵区",
        "340404": "谢家集区",
        "340405": "八公山区",
        "340406": "潘集区",
        "340421": "凤台县",
        "340422": "寿县"
    },
    "340402": {
        "340402001": "大通街道",
        "340402100": "上窑镇",
        "340402101": "洛河镇",
        "340402102": "九龙岗镇",
        "340402200": "孔店乡",
        "340402400": "淮南经济开发区"
    },
    "340403": {
        "340403001": "田东街道",
        "340403002": "新淮街道",
        "340403003": "国庆街道",
        "340403004": "淮滨街道",
        "340403005": "朝阳街道",
        "340403006": "公园街道",
        "340403007": "洞山街道",
        "340403008": "龙泉街道",
        "340403009": "泉山街道",
        "340403100": "舜耕镇",
        "340403101": "安成镇",
        "340403102": "曹庵镇",
        "340403103": "三和镇",
        "340403201": "史院乡"
    },
    "340404": {
        "340404001": "谢家集街道",
        "340404002": "蔡家岗街道",
        "340404003": "立新街道",
        "340404004": "谢三村街道",
        "340404005": "平山街道",
        "340404100": "望峰岗镇",
        "340404101": "李郢孜镇",
        "340404102": "唐山镇",
        "340404103": "杨公镇",
        "340404200": "孙庙乡",
        "340404201": "孤堆回族乡"
    },
    "340405": {
        "340405001": "新庄孜街道",
        "340405002": "土坝孜街道",
        "340405003": "毕家岗街道",
        "340405100": "八公山镇",
        "340405121": "山王镇",
        "340405400": "妙山林场"
    },
    "340406": {
        "340406001": "田集街道",
        "340406100": "高皇镇",
        "340406101": "平圩镇",
        "340406102": "泥河镇",
        "340406103": "潘集镇",
        "340406104": "芦集镇",
        "340406105": "架河镇",
        "340406106": "夹沟镇",
        "340406107": "祁集镇",
        "340406108": "贺疃镇",
        "340406202": "古沟回族乡"
    },
    "340421": {
        "340421100": "城关镇",
        "340421102": "新集镇",
        "340421103": "朱马店镇",
        "340421104": "岳张集镇",
        "340421105": "顾桥镇",
        "340421106": "毛集镇",
        "340421107": "夏集镇",
        "340421108": "桂集镇",
        "340421109": "焦岗湖镇",
        "340421110": "凤凰镇",
        "340421111": "杨村镇",
        "340421112": "丁集镇",
        "340421113": "刘集镇",
        "340421114": "大兴镇",
        "340421115": "尚塘镇",
        "340421204": "古店乡",
        "340421205": "钱庙乡",
        "340421209": "关店乡",
        "340421212": "李冲回族乡"
    },
    "340422": {
        "340422100": "寿春镇",
        "340422101": "双桥镇",
        "340422102": "涧沟镇",
        "340422103": "丰庄镇",
        "340422104": "正阳关镇",
        "340422105": "迎河镇",
        "340422106": "板桥镇",
        "340422107": "安丰塘镇",
        "340422108": "堰口镇",
        "340422109": "保义镇",
        "340422110": "隐贤镇",
        "340422111": "安丰镇",
        "340422112": "众兴镇",
        "340422113": "茶庵镇",
        "340422114": "三觉镇",
        "340422115": "炎刘镇",
        "340422116": "刘岗镇",
        "340422117": "双庙集镇",
        "340422118": "小甸镇",
        "340422119": "瓦埠镇",
        "340422120": "大顺镇",
        "340422121": "窑口镇",
        "340422200": "八公山乡",
        "340422201": "张李乡",
        "340422203": "陶店回族乡",
        "340422402": "安徽寿县经济开发区",
        "340422500": "寿西湖农场",
        "340422501": "正阳关农场"
    },
    "340500": {"340503": "花山区", "340504": "雨山区", "340506": "博望区", "340521": "当涂县", "340522": "含山县", "340523": "和县"},
    "340503": {
        "340503001": "沙塘路街道",
        "340503002": "解放路街道",
        "340503003": "湖东路街道",
        "340503004": "桃源路街道",
        "340503005": "霍里街道",
        "340503006": "金家庄街道",
        "340503007": "塘西街道",
        "340503008": "慈湖街道",
        "340503009": "江东街道",
        "340503101": "濮塘镇",
        "340503401": "慈湖高新技术产业开发区"
    },
    "340504": {
        "340504001": "平湖街道",
        "340504002": "雨山街道",
        "340504003": "安民街道",
        "340504004": "采石街道",
        "340504100": "向山镇",
        "340504101": "银塘镇",
        "340504200": "佳山乡",
        "340504401": "雨山经济开发区",
        "340504402": "市经济技术开发区"
    },
    "340506": {"340506100": "博望镇", "340506101": "丹阳镇", "340506102": "新市镇", "340506400": "博望高新技术产业开发区"},
    "340521": {
        "340521100": "姑孰镇",
        "340521101": "黄池镇",
        "340521102": "乌溪镇",
        "340521103": "石桥镇",
        "340521104": "塘南镇",
        "340521105": "护河镇",
        "340521106": "太白镇",
        "340521110": "年陡镇",
        "340521111": "湖阳镇",
        "340521112": "大陇镇",
        "340521202": "江心乡",
        "340521400": "当涂经济开发区",
        "340521401": "当涂青山河高新技术产业园区",
        "340521402": "当涂大青山李白文化旅游区",
        "340521500": "当涂现代农业示范区"
    },
    "340522": {
        "340522100": "环峰镇",
        "340522101": "运漕镇",
        "340522102": "铜闸镇",
        "340522103": "陶厂镇",
        "340522104": "林头镇",
        "340522105": "清溪镇",
        "340522106": "仙踪镇",
        "340522107": "昭关镇",
        "340522400": "安徽含山工业园区",
        "340522401": "安徽含山经济开发区",
        "340522402": "含山县褒禅山经济园区"
    },
    "340523": {
        "340523100": "历阳镇",
        "340523102": "白桥镇",
        "340523103": "姥桥镇",
        "340523104": "功桥镇",
        "340523105": "西埠镇",
        "340523106": "香泉镇",
        "340523107": "乌江镇",
        "340523108": "善厚镇",
        "340523109": "石杨镇",
        "340523400": "安徽和县经济开发区",
        "340523401": "安徽精细化工产业有机合成基地",
        "340523500": "安徽和县台湾农民创业园"
    },
    "340600": {"340602": "杜集区", "340603": "相山区", "340604": "烈山区", "340621": "濉溪县"},
    "340602": {
        "340602001": "高岳街道",
        "340602002": "矿山集街道",
        "340602100": "朔里镇",
        "340602101": "石台镇",
        "340602102": "段圆镇",
        "340602400": "淮北杜集经济开发区"
    },
    "340603": {
        "340603001": "相南街道",
        "340603002": "东街道",
        "340603003": "西街道",
        "340603005": "东山街道",
        "340603008": "任圩街道",
        "340603009": "南黎街道",
        "340603010": "曲阳街道",
        "340603011": "三堤口街道",
        "340603100": "渠沟镇",
        "340603400": "淮北凤凰山经济开发区"
    },
    "340604": {
        "340604001": "杨庄街道",
        "340604002": "临海童街道",
        "340604003": "百善街道",
        "340604004": "任楼街道",
        "340604100": "烈山镇",
        "340604101": "宋町镇",
        "340604102": "古饶镇",
        "340604400": "烈山区工业园"
    },
    "340621": {
        "340621100": "濉溪镇",
        "340621101": "韩村镇",
        "340621102": "刘桥镇",
        "340621103": "五沟镇",
        "340621104": "临涣镇",
        "340621105": "双堆集镇",
        "340621106": "铁佛镇",
        "340621107": "南坪镇",
        "340621108": "百善镇",
        "340621109": "孙町镇",
        "340621110": "四铺镇",
        "340621400": "濉溪县经济开发区",
        "340621401": "濉溪县濉芜产业园"
    },
    "340700": {"340705": "铜官区", "340706": "义安区", "340711": "郊区", "340722": "枞阳县"},
    "340705": {"340705005": "东郊", "340705006": "新城", "340705100": "西湖镇", "340705198": "铜官区虚镇", "340705400": "铜陵市经济开发区"},
    "340706": {
        "340706001": "新桥",
        "340706100": "五松镇",
        "340706101": "顺安镇",
        "340706102": "钟鸣镇",
        "340706103": "天门镇",
        "340706104": "东联镇",
        "340706105": "西联镇",
        "340706200": "老洲乡",
        "340706203": "胥坝乡",
        "340706400": "循环经济园",
        "340706401": "义安经济开发区"
    },
    "340711": {
        "340711001": "桥南",
        "340711002": "安矿",
        "340711100": "铜山镇",
        "340711101": "大通镇",
        "340711102": "老洲镇",
        "340711103": "陈瑶湖镇",
        "340711104": "周潭镇",
        "340711198": "郊区虚镇",
        "340711200": "灰河乡",
        "340711401": "大桥经济开发区"
    },
    "340722": {
        "340722100": "枞阳镇",
        "340722101": "欧山镇",
        "340722102": "汤沟镇",
        "340722106": "横埠镇",
        "340722107": "项铺镇",
        "340722108": "钱桥镇",
        "340722109": "麒麟镇",
        "340722110": "义津镇",
        "340722111": "浮山镇",
        "340722112": "会宫镇",
        "340722113": "官埠桥镇",
        "340722114": "钱铺镇",
        "340722115": "金社镇",
        "340722116": "白柳镇",
        "340722117": "雨坛镇",
        "340722200": "铁铜乡",
        "340722201": "凤仪乡",
        "340722202": "长沙乡",
        "340722205": "白梅乡",
        "340722400": "枞阳经济开发区"
    },
    "340800": {
        "340802": "迎江区",
        "340803": "大观区",
        "340811": "宜秀区",
        "340822": "怀宁县",
        "340825": "太湖县",
        "340826": "宿松县",
        "340827": "望江县",
        "340828": "岳西县",
        "340871": "安徽安庆经济开发区",
        "340881": "桐城市",
        "340882": "潜山市"
    },
    "340802": {
        "340802001": "宜城路街道",
        "340802002": "新河路街道",
        "340802003": "华中路街道",
        "340802004": "人民路街道",
        "340802005": "孝肃路街道",
        "340802006": "滨江街道",
        "340802200": "龙狮桥乡",
        "340802201": "长风乡",
        "340802202": "新洲乡"
    },
    "340803": {
        "340803001": "德宽路街道",
        "340803002": "玉琳路街道",
        "340803003": "龙山路街道",
        "340803004": "菱湖街道",
        "340803005": "集贤路街道",
        "340803006": "石化路街道",
        "340803007": "花亭路街道",
        "340803100": "海口镇",
        "340803200": "十里铺乡",
        "340803201": "山口乡",
        "340803400": "大观开发区",
        "340803401": "安徽安庆海口经济开发区",
        "340803500": "皖河农场"
    },
    "340811": {
        "340811001": "大桥街道",
        "340811100": "大龙山镇",
        "340811101": "杨桥镇",
        "340811102": "罗岭镇",
        "340811200": "白泽湖乡",
        "340811201": "五横乡"
    },
    "340822": {
        "340822100": "高河镇",
        "340822101": "石牌镇",
        "340822102": "月山镇",
        "340822103": "马庙镇",
        "340822104": "金拱镇",
        "340822105": "茶岭镇",
        "340822106": "公岭镇",
        "340822107": "黄墩镇",
        "340822108": "三桥镇",
        "340822109": "小市镇",
        "340822110": "黄龙镇",
        "340822111": "平山镇",
        "340822112": "腊树镇",
        "340822113": "洪铺镇",
        "340822114": "江镇镇",
        "340822200": "凉亭乡",
        "340822201": "石镜乡",
        "340822202": "秀山乡",
        "340822203": "清河乡",
        "340822204": "雷埠乡"
    },
    "340825": {
        "340825100": "晋熙镇",
        "340825101": "徐桥镇",
        "340825102": "新仓镇",
        "340825103": "小池镇",
        "340825104": "寺前镇",
        "340825105": "天华镇",
        "340825106": "牛镇镇",
        "340825107": "弥陀镇",
        "340825108": "北中镇",
        "340825109": "百里镇",
        "340825200": "大石乡",
        "340825201": "城西乡",
        "340825202": "江塘乡",
        "340825203": "汤泉乡",
        "340825204": "刘畈乡",
        "340825400": "经济开发区",
        "340825401": "花凉亭水电站"
    },
    "340826": {
        "340826100": "孚玉镇",
        "340826101": "复兴镇",
        "340826102": "汇口镇",
        "340826103": "许岭镇",
        "340826104": "下仓镇",
        "340826105": "二郎镇",
        "340826106": "破凉镇",
        "340826107": "凉亭镇",
        "340826108": "长铺镇",
        "340826200": "高岭乡",
        "340826201": "程岭乡",
        "340826202": "九姑乡",
        "340826203": "千岭乡",
        "340826204": "洲头乡",
        "340826205": "佐坝乡",
        "340826206": "北浴乡",
        "340826207": "陈汉乡",
        "340826208": "隘口乡",
        "340826209": "柳坪乡",
        "340826210": "趾凤乡",
        "340826211": "河塌乡",
        "340826212": "五里乡",
        "340826400": "九成监狱管理分局",
        "340826401": "华阳河农场总场"
    },
    "340827": {
        "340827100": "华阳镇",
        "340827101": "杨湾镇",
        "340827102": "漳湖镇",
        "340827103": "赛口镇",
        "340827104": "高士镇",
        "340827105": "鸦滩镇",
        "340827106": "长岭镇",
        "340827107": "太慈镇",
        "340827200": "凉泉乡",
        "340827201": "雷池乡",
        "340827400": "望江县经济开发区",
        "340827401": "安徽望江桥港经济开发区"
    },
    "340828": {
        "340828100": "天堂镇",
        "340828101": "店前镇",
        "340828102": "来榜镇",
        "340828103": "菖蒲镇",
        "340828104": "头陀镇",
        "340828105": "白帽镇",
        "340828106": "温泉镇",
        "340828107": "响肠镇",
        "340828108": "河图镇",
        "340828109": "五河镇",
        "340828110": "主簿镇",
        "340828111": "冶溪镇",
        "340828112": "黄尾镇",
        "340828113": "中关镇",
        "340828200": "毛尖山乡",
        "340828201": "莲云乡",
        "340828202": "青天乡",
        "340828203": "包家乡",
        "340828204": "古坊乡",
        "340828205": "田头乡",
        "340828207": "石关乡",
        "340828208": "姚河乡",
        "340828209": "和平乡",
        "340828210": "巍岭乡",
        "340828400": "安徽岳西县经济开发区"
    },
    "340871": {"340871001": "菱北街道", "340871100": "老峰镇"},
    "340881": {
        "340881001": "龙腾街道",
        "340881002": "文昌街道",
        "340881003": "龙眠街道",
        "340881100": "孔城镇",
        "340881101": "吕亭镇",
        "340881102": "范岗镇",
        "340881103": "新渡镇",
        "340881104": "双港镇",
        "340881105": "大关镇",
        "340881106": "青草镇",
        "340881107": "金神镇",
        "340881108": "嬉子湖镇",
        "340881109": "唐湾镇",
        "340881110": "黄甲镇",
        "340881111": "鲟鱼镇",
        "340881400": "桐城经济开发区",
        "340881401": "桐城双新经济开发区"
    },
    "340882": {
        "340882100": "梅城镇",
        "340882101": "源潭镇",
        "340882102": "余井镇",
        "340882103": "王河镇",
        "340882104": "黄铺镇",
        "340882105": "槎水镇",
        "340882106": "水吼镇",
        "340882107": "官庄镇",
        "340882108": "黄泥镇",
        "340882109": "黄柏镇",
        "340882110": "天柱山镇",
        "340882200": "塔畈乡",
        "340882201": "油坝乡",
        "340882202": "龙潭乡",
        "340882203": "痘姆乡",
        "340882204": "五庙乡",
        "340882400": "开发区",
        "340882401": "旅游度假区"
    },
    "341000": {
        "341002": "屯溪区",
        "341003": "黄山区",
        "341004": "徽州区",
        "341021": "歙县",
        "341022": "休宁县",
        "341023": "黟县",
        "341024": "祁门县"
    },
    "341002": {
        "341002001": "昱东街道",
        "341002002": "昱中街道",
        "341002003": "昱西街道",
        "341002004": "老街街道",
        "341002100": "屯光镇",
        "341002101": "阳湖镇",
        "341002102": "黎阳镇",
        "341002103": "新潭镇",
        "341002104": "奕棋镇"
    },
    "341003": {
        "341003100": "甘棠镇",
        "341003101": "仙源镇",
        "341003102": "汤口镇",
        "341003103": "谭家桥镇",
        "341003104": "太平湖镇",
        "341003105": "焦村镇",
        "341003106": "耿城镇",
        "341003107": "三口镇",
        "341003108": "乌石镇",
        "341003200": "新明乡",
        "341003201": "龙门乡",
        "341003203": "新华乡",
        "341003204": "新丰乡",
        "341003205": "永丰乡",
        "341003400": "黄山风景区"
    },
    "341004": {
        "341004100": "岩寺镇",
        "341004101": "西溪南镇",
        "341004102": "潜口镇",
        "341004103": "呈坎镇",
        "341004198": "徽州区虚镇",
        "341004200": "洽舍乡",
        "341004201": "杨村乡",
        "341004202": "富溪乡",
        "341004400": "安徽徽州经济开发区"
    },
    "341021": {
        "341021100": "徽城镇",
        "341021101": "深渡镇",
        "341021102": "北岸镇",
        "341021103": "富堨镇",
        "341021104": "郑村镇",
        "341021105": "桂林镇",
        "341021106": "许村镇",
        "341021107": "溪头镇",
        "341021108": "杞梓里镇",
        "341021109": "霞坑镇",
        "341021110": "岔口镇",
        "341021111": "街口镇",
        "341021112": "王村镇",
        "341021113": "雄村镇",
        "341021114": "三阳镇",
        "341021200": "坑口乡",
        "341021202": "上丰乡",
        "341021203": "昌溪乡",
        "341021204": "武阳乡",
        "341021206": "金川乡",
        "341021207": "小川乡",
        "341021208": "新溪口乡",
        "341021209": "璜田乡",
        "341021210": "长陔乡",
        "341021211": "森村乡",
        "341021212": "绍濂乡",
        "341021213": "石门乡",
        "341021214": "狮石乡",
        "341021400": "开发区类似乡级单位"
    },
    "341022": {
        "341022100": "海阳镇",
        "341022101": "齐云山镇",
        "341022102": "万安镇",
        "341022103": "五城镇",
        "341022104": "东临溪镇",
        "341022105": "蓝田镇",
        "341022106": "溪口镇",
        "341022107": "流口镇",
        "341022108": "汪村镇",
        "341022109": "商山镇",
        "341022201": "山斗乡",
        "341022202": "岭南乡",
        "341022203": "渭桥乡",
        "341022204": "板桥乡",
        "341022205": "陈霞乡",
        "341022206": "鹤城乡",
        "341022207": "源芳乡",
        "341022208": "榆村乡",
        "341022209": "龙田乡",
        "341022210": "璜尖乡",
        "341022211": "白际乡"
    },
    "341023": {
        "341023100": "碧阳镇",
        "341023101": "宏村镇",
        "341023102": "渔亭镇",
        "341023103": "西递镇",
        "341023104": "柯村镇",
        "341023201": "美溪乡",
        "341023202": "宏潭乡",
        "341023203": "洪星乡"
    },
    "341024": {
        "341024100": "祁山镇",
        "341024101": "小路口镇",
        "341024102": "金字牌镇",
        "341024103": "平里镇",
        "341024104": "历口镇",
        "341024105": "闪里镇",
        "341024106": "安凌镇",
        "341024107": "凫峰镇",
        "341024108": "塔坊镇",
        "341024109": "新安镇",
        "341024200": "大坦乡",
        "341024202": "柏溪乡",
        "341024204": "祁红乡",
        "341024205": "溶口乡",
        "341024206": "芦溪乡",
        "341024207": "渚口乡",
        "341024208": "古溪乡",
        "341024210": "箬坑乡"
    },
    "341100": {
        "341102": "琅琊区",
        "341103": "南谯区",
        "341122": "来安县",
        "341124": "全椒县",
        "341125": "定远县",
        "341126": "凤阳县",
        "341171": "苏滁现代产业园",
        "341172": "滁州经济技术开发区",
        "341181": "天长市",
        "341182": "明光市"
    },
    "341102": {
        "341102001": "琅琊街道",
        "341102002": "东门街道",
        "341102003": "西门街道",
        "341102004": "南门街道",
        "341102005": "北门街道",
        "341102006": "清流街道",
        "341102007": "扬子街道",
        "341102010": "西涧街道",
        "341102400": "安徽滁州琅琊经济开发区"
    },
    "341103": {
        "341103003": "龙蟠街道",
        "341103100": "乌衣镇",
        "341103101": "沙河镇",
        "341103102": "章广镇",
        "341103103": "黄泥岗镇",
        "341103104": "珠龙镇",
        "341103105": "大柳镇",
        "341103106": "腰铺镇",
        "341103107": "施集镇",
        "341103400": "南谯工业园区",
        "341103401": "滁州高教科创城管委会"
    },
    "341122": {
        "341122100": "新安镇",
        "341122101": "半塔镇",
        "341122102": "水口镇",
        "341122103": "汊河镇",
        "341122104": "大英镇",
        "341122105": "雷官镇",
        "341122106": "施官镇",
        "341122107": "舜山镇",
        "341122108": "三城镇",
        "341122109": "独山镇",
        "341122110": "张山镇",
        "341122203": "杨郢乡"
    },
    "341124": {
        "341124100": "襄河镇",
        "341124101": "古河镇",
        "341124102": "大墅镇",
        "341124103": "二郎口镇",
        "341124104": "武岗镇",
        "341124105": "马厂镇",
        "341124106": "石沛镇",
        "341124107": "十字镇",
        "341124108": "西王镇",
        "341124109": "六镇镇"
    },
    "341125": {
        "341125100": "定城镇",
        "341125101": "炉桥镇",
        "341125102": "永康镇",
        "341125103": "吴圩镇",
        "341125104": "朱湾镇",
        "341125105": "张桥镇",
        "341125106": "藕塘镇",
        "341125107": "池河镇",
        "341125108": "连江镇",
        "341125109": "界牌集镇",
        "341125110": "仓镇",
        "341125111": "三和集镇",
        "341125112": "西卅店镇",
        "341125113": "桑涧镇",
        "341125114": "蒋集镇",
        "341125115": "大桥镇",
        "341125200": "严桥乡",
        "341125201": "拂晓乡",
        "341125202": "能仁乡",
        "341125203": "七里塘乡",
        "341125204": "二龙回族乡",
        "341125205": "范岗乡",
        "341125400": "安徽定远经济开发区",
        "341125401": "定远盐化工业园",
        "341125402": "凌家湖农场"
    },
    "341126": {
        "341126100": "府城镇",
        "341126101": "临淮关镇",
        "341126102": "武店镇",
        "341126103": "西泉镇",
        "341126104": "官塘镇",
        "341126105": "刘府镇",
        "341126106": "大庙镇",
        "341126107": "殷涧镇",
        "341126108": "总铺镇",
        "341126109": "红心镇",
        "341126110": "板桥镇",
        "341126111": "大溪河镇",
        "341126112": "小溪河镇",
        "341126113": "枣巷镇",
        "341126201": "黄湾乡",
        "341126400": "安徽凤阳经济开发区",
        "341126401": "安徽凤阳硅工业园区"
    },
    "341171": {"341171001": "大王街道"},
    "341172": {"341172001": "凤凰街道", "341172002": "城北新区", "341172401": "滁州市经济技术开发区"},
    "341181": {
        "341181002": "天长街道",
        "341181100": "铜城镇",
        "341181101": "汊涧镇",
        "341181102": "秦栏镇",
        "341181103": "大通镇",
        "341181104": "杨村镇",
        "341181105": "石梁镇",
        "341181106": "金集镇",
        "341181107": "永丰镇",
        "341181108": "仁和集镇",
        "341181109": "冶山镇",
        "341181110": "郑集镇",
        "341181111": "张铺镇",
        "341181112": "新街镇",
        "341181113": "万寿镇",
        "341181500": "安徽省大圹圩农场"
    },
    "341182": {
        "341182001": "明光街道",
        "341182002": "明东街道",
        "341182003": "明南街道",
        "341182004": "明西街道",
        "341182100": "张八岭镇",
        "341182101": "三界镇",
        "341182102": "管店镇",
        "341182103": "自来桥镇",
        "341182104": "涧溪镇",
        "341182105": "石坝镇",
        "341182106": "苏巷镇",
        "341182107": "桥头镇",
        "341182108": "女山湖镇",
        "341182109": "古沛镇",
        "341182110": "潘村镇",
        "341182111": "柳巷镇",
        "341182200": "泊岗乡"
    },
    "341200": {
        "341202": "颍州区",
        "341203": "颍东区",
        "341204": "颍泉区",
        "341221": "临泉县",
        "341222": "太和县",
        "341225": "阜南县",
        "341226": "颍上县",
        "341271": "阜阳合肥现代产业园区",
        "341272": "阜阳经济技术开发区",
        "341282": "界首市"
    },
    "341202": {
        "341202001": "鼓楼街道",
        "341202002": "文峰街道",
        "341202003": "清河街道",
        "341202004": "颍西街道",
        "341202005": "西湖景区街道",
        "341202100": "王店镇",
        "341202101": "程集镇",
        "341202102": "三合镇",
        "341202103": "西湖镇",
        "341202104": "九龙镇",
        "341202105": "三十里铺镇",
        "341202106": "三塔集镇",
        "341202200": "马寨乡",
        "341202400": "安徽颍州经济开发区"
    },
    "341203": {
        "341203001": "河东",
        "341203002": "新华街道",
        "341203003": "向阳街道",
        "341203100": "口孜镇",
        "341203101": "插花镇",
        "341203102": "袁寨镇",
        "341203103": "枣庄镇",
        "341203104": "老庙镇",
        "341203105": "正午镇",
        "341203106": "杨楼孜镇",
        "341203107": "新乌江镇",
        "341203200": "冉庙乡"
    },
    "341204": {
        "341204001": "中市街道",
        "341204002": "周棚街道",
        "341204100": "伍明镇",
        "341204101": "宁老庄镇",
        "341204102": "闻集镇",
        "341204103": "行流镇"
    },
    "341221": {
        "341221001": "城关街道",
        "341221002": "邢塘街道",
        "341221003": "田桥街道",
        "341221004": "城东街道",
        "341221005": "城南街道",
        "341221101": "杨桥镇",
        "341221102": "鲖城镇",
        "341221103": "谭棚镇",
        "341221104": "老集镇",
        "341221105": "滑集镇",
        "341221106": "吕寨镇",
        "341221107": "单桥镇",
        "341221108": "长官镇",
        "341221109": "宋集镇",
        "341221110": "张新镇",
        "341221111": "艾亭镇",
        "341221112": "陈集镇",
        "341221113": "韦寨镇",
        "341221114": "迎仙镇",
        "341221115": "瓦店镇",
        "341221116": "姜寨镇",
        "341221117": "庙岔镇",
        "341221118": "黄岭镇",
        "341221119": "白庙镇",
        "341221120": "关庙镇",
        "341221122": "高塘镇",
        "341221203": "土陂乡",
        "341221206": "陶老乡"
    },
    "341222": {
        "341222100": "城关镇",
        "341222101": "旧县镇",
        "341222102": "税镇镇",
        "341222103": "皮条孙镇",
        "341222104": "原墙镇",
        "341222105": "倪邱镇",
        "341222106": "李兴镇",
        "341222107": "大新镇",
        "341222108": "肖口镇",
        "341222109": "关集镇",
        "341222110": "三塔镇",
        "341222111": "双浮镇",
        "341222112": "蔡庙镇",
        "341222113": "三堂镇",
        "341222114": "苗老集镇",
        "341222115": "赵庙镇",
        "341222116": "宫集镇",
        "341222117": "坟台镇",
        "341222118": "洪山镇",
        "341222119": "清浅镇",
        "341222120": "五星镇",
        "341222121": "高庙镇",
        "341222122": "桑营镇",
        "341222123": "大庙集镇",
        "341222124": "阮桥镇",
        "341222125": "双庙镇",
        "341222126": "胡总镇",
        "341222127": "郭庙镇",
        "341222128": "二郎镇",
        "341222129": "马集镇",
        "341222201": "赵集乡",
        "341222400": "开发区管理委员会"
    },
    "341225": {
        "341225101": "方集镇",
        "341225102": "中岗镇",
        "341225103": "柴集镇",
        "341225104": "新村镇",
        "341225106": "朱寨镇",
        "341225107": "柳沟镇",
        "341225108": "赵集镇",
        "341225109": "田集镇",
        "341225110": "苗集镇",
        "341225111": "黄岗镇",
        "341225112": "焦陂镇",
        "341225113": "张寨镇",
        "341225114": "王堰镇",
        "341225115": "地城镇",
        "341225116": "洪河桥镇",
        "341225117": "王家坝镇",
        "341225118": "王化镇",
        "341225119": "曹集镇",
        "341225120": "鹿城镇",
        "341225121": "会龙镇",
        "341225200": "王店孜乡",
        "341225201": "许堂乡",
        "341225204": "段郢乡",
        "341225205": "公桥乡",
        "341225206": "龙王乡",
        "341225207": "于集乡",
        "341225208": "老观乡",
        "341225209": "郜台乡",
        "341225401": "安徽阜南经济开发区",
        "341225500": "阜濛农场"
    },
    "341226": {
        "341226100": "慎城镇",
        "341226101": "谢桥镇",
        "341226102": "南照镇",
        "341226103": "杨湖镇",
        "341226104": "江口镇",
        "341226105": "润河镇",
        "341226106": "新集镇",
        "341226107": "六十铺镇",
        "341226108": "耿棚镇",
        "341226109": "半岗镇",
        "341226110": "王岗镇",
        "341226111": "夏桥镇",
        "341226112": "江店孜镇",
        "341226113": "陈桥镇",
        "341226114": "黄桥镇",
        "341226115": "八里河镇",
        "341226116": "迪沟镇",
        "341226117": "西三十铺镇",
        "341226118": "红星镇",
        "341226119": "十八里铺镇",
        "341226120": "鲁口镇",
        "341226121": "古城镇",
        "341226200": "建颍乡",
        "341226201": "五十铺乡",
        "341226202": "盛堂乡",
        "341226203": "关屯乡",
        "341226204": "垂岗乡",
        "341226205": "赛涧回族乡",
        "341226206": "刘集乡",
        "341226207": "黄坝乡"
    },
    "341271": {"341271100": "袁集镇"},
    "341272": {"341272001": "阜阳开发区京九"},
    "341282": {
        "341282001": "东城",
        "341282002": "西城",
        "341282003": "颍南",
        "341282100": "光武镇",
        "341282101": "泉阳镇",
        "341282102": "芦村镇",
        "341282103": "新马集镇",
        "341282104": "大黄镇",
        "341282105": "田营镇",
        "341282106": "陶庙镇",
        "341282107": "王集镇",
        "341282108": "砖集镇",
        "341282109": "顾集镇",
        "341282110": "代桥镇",
        "341282111": "舒庄镇",
        "341282200": "邴集乡",
        "341282201": "靳寨乡",
        "341282202": "任寨乡"
    },
    "341300": {
        "341302": "埇桥区",
        "341321": "砀山县",
        "341322": "萧县",
        "341323": "灵璧县",
        "341324": "泗县",
        "341371": "宿州马鞍山现代产业园区",
        "341372": "宿州经济技术开发区"
    },
    "341302": {
        "341302001": "埇桥街道",
        "341302002": "沱河街道",
        "341302003": "道东街道",
        "341302004": "东关街道",
        "341302005": "三里湾街道",
        "341302006": "南关街道",
        "341302007": "西关街道",
        "341302008": "北关街道",
        "341302009": "汴河街道",
        "341302010": "三八街道",
        "341302011": "城东街道",
        "341302100": "符离镇",
        "341302101": "芦岭镇",
        "341302102": "朱仙庄镇",
        "341302103": "褚兰镇",
        "341302104": "曹村镇",
        "341302105": "夹沟镇",
        "341302106": "栏杆镇",
        "341302107": "时村镇",
        "341302108": "永安镇",
        "341302109": "灰古镇",
        "341302110": "大店镇",
        "341302111": "大泽乡镇",
        "341302112": "桃园镇",
        "341302113": "蕲县镇",
        "341302114": "大营镇",
        "341302200": "杨庄乡",
        "341302201": "支河乡",
        "341302202": "解集乡",
        "341302203": "桃沟乡",
        "341302204": "顺河乡",
        "341302207": "永镇乡",
        "341302208": "西二铺乡",
        "341302209": "北杨寨乡",
        "341302210": "苗安乡",
        "341302211": "蒿沟乡",
        "341302400": "夹沟农场",
        "341302402": "宿州高新技术产业开发区",
        "341302403": "宿州埇桥经济开发区"
    },
    "341321": {
        "341321100": "砀城镇",
        "341321101": "赵屯镇",
        "341321102": "李庄镇",
        "341321103": "唐寨镇",
        "341321104": "葛集镇",
        "341321105": "周寨镇",
        "341321106": "玄庙镇",
        "341321107": "官庄坝镇",
        "341321108": "曹庄镇",
        "341321109": "关帝庙镇",
        "341321110": "朱楼镇",
        "341321111": "良梨镇",
        "341321112": "程庄镇",
        "341321400": "经济开发区",
        "341321401": "薛楼板材加工园",
        "341321402": "高铁新区"
    },
    "341322": {
        "341322100": "龙城镇",
        "341322101": "黄口镇",
        "341322102": "杨楼镇",
        "341322103": "闫集镇",
        "341322104": "新庄镇",
        "341322105": "刘套镇",
        "341322106": "马井镇",
        "341322107": "大屯镇",
        "341322108": "赵庄镇",
        "341322109": "杜楼镇",
        "341322110": "丁里镇",
        "341322111": "王寨镇",
        "341322112": "祖楼镇",
        "341322113": "青龙集镇",
        "341322114": "张庄寨镇",
        "341322115": "永堌镇",
        "341322116": "白土镇",
        "341322117": "官桥镇",
        "341322200": "圣泉乡",
        "341322201": "酒店乡",
        "341322202": "孙圩子乡",
        "341322203": "庄里乡",
        "341322204": "石林乡",
        "341322400": "萧县经济开发区"
    },
    "341323": {
        "341323100": "灵城镇",
        "341323101": "韦集镇",
        "341323102": "黄湾镇",
        "341323103": "娄庄镇",
        "341323104": "杨疃镇",
        "341323105": "尹集镇",
        "341323106": "浍沟镇",
        "341323107": "游集镇",
        "341323108": "下楼镇",
        "341323109": "朝阳镇",
        "341323110": "渔沟镇",
        "341323111": "高楼镇",
        "341323112": "冯庙镇",
        "341323200": "向阳乡",
        "341323201": "朱集乡",
        "341323202": "大路乡",
        "341323203": "大庙乡",
        "341323204": "禅堂乡",
        "341323205": "虞姬乡",
        "341323400": "灵璧县经济开发区"
    },
    "341324": {
        "341324100": "泗城镇",
        "341324101": "墩集镇",
        "341324102": "丁湖镇",
        "341324103": "草沟镇",
        "341324104": "长沟镇",
        "341324105": "黄圩镇",
        "341324106": "大庄镇",
        "341324107": "山头镇",
        "341324108": "刘圩镇",
        "341324109": "黑塔镇",
        "341324110": "草庙镇",
        "341324111": "屏山镇",
        "341324200": "大路口乡",
        "341324201": "大杨乡",
        "341324202": "瓦坊乡",
        "341324400": "泗县开发区管委会"
    },
    "341371": {"341371400": "宿州东部新城行政管理区"},
    "341372": {"341372001": "金海街道", "341372402": "鞋城行政区域管理委员会"},
    "341500": {
        "341502": "金安区",
        "341503": "裕安区",
        "341504": "叶集区",
        "341522": "霍邱县",
        "341523": "舒城县",
        "341524": "金寨县",
        "341525": "霍山县"
    },
    "341502": {
        "341502001": "中市街道",
        "341502002": "东市街道",
        "341502003": "三里桥街道",
        "341502004": "望城街道",
        "341502005": "清水河街道",
        "341502100": "木厂镇",
        "341502101": "马头镇",
        "341502102": "东桥镇",
        "341502103": "张店镇",
        "341502104": "毛坦厂镇",
        "341502105": "东河口镇",
        "341502106": "双河镇",
        "341502107": "施桥镇",
        "341502108": "孙岗镇",
        "341502109": "三十铺镇",
        "341502110": "椿树镇",
        "341502200": "城北乡",
        "341502201": "翁墩乡",
        "341502202": "淠东乡",
        "341502203": "中店乡",
        "341502204": "横塘岗乡",
        "341502205": "先生店乡",
        "341502400": "六安经济开发区"
    },
    "341503": {
        "341503001": "鼓楼街道",
        "341503002": "西市街道",
        "341503003": "小华山街道",
        "341503100": "苏埠镇",
        "341503101": "韩摆渡镇",
        "341503102": "新安镇",
        "341503103": "顺河镇",
        "341503104": "独山镇",
        "341503105": "石婆店镇",
        "341503106": "城南镇",
        "341503107": "丁集镇",
        "341503108": "固镇镇",
        "341503109": "徐集镇",
        "341503110": "分路口镇",
        "341503111": "江家店镇",
        "341503200": "单王乡",
        "341503201": "青山乡",
        "341503202": "石板冲乡",
        "341503203": "西河口乡",
        "341503204": "平桥乡",
        "341503205": "罗集乡",
        "341503206": "狮子岗乡",
        "341503400": "六安市裕安区经济开发区"
    },
    "341504": {
        "341504001": "史河街道",
        "341504002": "平岗街道",
        "341504102": "三元镇",
        "341504103": "洪集镇",
        "341504104": "姚李镇",
        "341504200": "孙岗乡"
    },
    "341522": {
        "341522100": "城关镇",
        "341522101": "河口镇",
        "341522102": "周集镇",
        "341522103": "临水镇",
        "341522104": "新店镇",
        "341522105": "石店镇",
        "341522106": "马店镇",
        "341522107": "孟集镇",
        "341522108": "花园镇",
        "341522109": "扈胡镇",
        "341522110": "长集镇",
        "341522113": "乌龙镇",
        "341522114": "高塘镇",
        "341522115": "龙潭镇",
        "341522116": "岔路镇",
        "341522117": "冯井镇",
        "341522118": "众兴集镇",
        "341522119": "夏店镇",
        "341522120": "曹庙镇",
        "341522123": "范桥镇",
        "341522124": "潘集镇",
        "341522200": "彭塔乡",
        "341522201": "王截流乡",
        "341522203": "临淮岗乡",
        "341522204": "城西湖乡",
        "341522205": "宋店乡",
        "341522206": "三流乡",
        "341522207": "邵岗乡",
        "341522208": "白莲乡",
        "341522210": "冯瓴乡",
        "341522400": "安徽霍邱经济开发区",
        "341522500": "水产局管辖村"
    },
    "341523": {
        "341523100": "城关镇",
        "341523101": "晓天镇",
        "341523102": "桃溪镇",
        "341523103": "万佛湖镇",
        "341523104": "千人桥镇",
        "341523105": "百神庙镇",
        "341523106": "杭埠镇",
        "341523107": "舒茶镇",
        "341523108": "南港镇",
        "341523109": "干汊河镇",
        "341523110": "张母桥镇",
        "341523111": "五显镇",
        "341523112": "山七镇",
        "341523113": "河棚镇",
        "341523114": "汤池镇",
        "341523200": "春秋乡",
        "341523201": "柏林乡",
        "341523202": "棠树乡",
        "341523203": "阙店乡",
        "341523204": "高峰乡",
        "341523205": "庐镇乡",
        "341523400": "舒城县经济开发区"
    },
    "341524": {
        "341524100": "梅山镇",
        "341524101": "麻埠镇",
        "341524102": "青山镇",
        "341524103": "燕子河镇",
        "341524104": "天堂寨镇",
        "341524105": "古碑镇",
        "341524106": "吴家店镇",
        "341524107": "斑竹园镇",
        "341524108": "汤家汇镇",
        "341524109": "南溪镇",
        "341524110": "双河镇",
        "341524111": "白塔畈镇",
        "341524201": "张冲乡",
        "341524202": "油坊店乡",
        "341524203": "长岭乡",
        "341524204": "槐树湾乡",
        "341524205": "花石乡",
        "341524206": "沙河乡",
        "341524207": "桃岭乡",
        "341524208": "果子园乡",
        "341524209": "关庙乡",
        "341524211": "全军乡",
        "341524214": "铁冲乡",
        "341524400": "现代产业园（经济开发区）"
    },
    "341525": {
        "341525100": "衡山镇",
        "341525101": "佛子岭镇",
        "341525102": "下符桥镇",
        "341525103": "但家庙镇",
        "341525104": "与儿街镇",
        "341525105": "黑石渡镇",
        "341525106": "诸佛庵镇",
        "341525107": "落儿岭镇",
        "341525108": "磨子潭镇",
        "341525109": "大化坪镇",
        "341525110": "漫水河镇",
        "341525111": "上土市镇",
        "341525112": "单龙寺镇",
        "341525201": "东西溪乡",
        "341525202": "太平畈乡",
        "341525203": "太阳乡",
        "341525400": "经济开发区",
        "341525401": "高桥湾现代产业园"
    },
    "341600": {"341602": "谯城区", "341621": "涡阳县", "341622": "蒙城县", "341623": "利辛县"},
    "341602": {
        "341602001": "花戏楼",
        "341602002": "薛阁",
        "341602003": "汤陵",
        "341602100": "古井镇",
        "341602101": "芦庙镇",
        "341602102": "华佗镇",
        "341602103": "魏岗镇",
        "341602104": "牛集镇",
        "341602105": "颜集镇",
        "341602106": "五马镇",
        "341602107": "十八里镇",
        "341602108": "谯东镇",
        "341602109": "十九里镇",
        "341602110": "沙土镇",
        "341602111": "观堂镇",
        "341602112": "大杨镇",
        "341602113": "城父镇",
        "341602114": "十河镇",
        "341602115": "双沟镇",
        "341602116": "淝河镇",
        "341602117": "古城镇",
        "341602118": "龙杨镇",
        "341602119": "立德镇",
        "341602200": "张店乡",
        "341602201": "赵桥乡",
        "341602400": "亳州市经济技术开发区",
        "341602401": "安徽亳州工业园区"
    },
    "341621": {
        "341621001": "城关街道",
        "341621003": "星园街道",
        "341621004": "天静宫街道",
        "341621103": "西阳镇",
        "341621104": "涡南镇",
        "341621105": "楚店镇",
        "341621106": "高公镇",
        "341621108": "高炉镇",
        "341621109": "曹市镇",
        "341621110": "青疃镇",
        "341621111": "石弓镇",
        "341621112": "龙山镇",
        "341621113": "义门镇",
        "341621114": "新兴镇",
        "341621115": "临湖镇",
        "341621116": "丹城镇",
        "341621117": "马店集镇",
        "341621118": "花沟镇",
        "341621119": "店集镇",
        "341621120": "陈大镇",
        "341621121": "牌坊镇",
        "341621122": "公吉寺镇",
        "341621123": "标里镇",
        "341621401": "安徽涡阳经济开发区"
    },
    "341622": {
        "341622001": "漆园（镇级单位）",
        "341622002": "庄周（乡级单位）",
        "341622003": "城关街道",
        "341622101": "双涧镇",
        "341622102": "小涧镇",
        "341622104": "坛城镇",
        "341622106": "许疃镇",
        "341622107": "板桥集镇",
        "341622108": "马集镇",
        "341622109": "岳坊镇",
        "341622110": "立仓镇",
        "341622111": "楚村镇",
        "341622112": "乐土镇",
        "341622113": "三义镇",
        "341622114": "篱笆镇",
        "341622201": "王集乡",
        "341622202": "小辛集乡",
        "341622400": "安徽蒙城经济开发区管理委员会",
        "341622401": "淮北矿业集团有限责任公司许疃煤矿"
    },
    "341623": {
        "341623100": "城关镇",
        "341623101": "阚疃镇",
        "341623102": "张村镇",
        "341623103": "江集镇",
        "341623104": "旧城镇",
        "341623105": "西潘楼镇",
        "341623106": "孙集镇",
        "341623107": "汝集镇",
        "341623108": "巩店镇",
        "341623109": "王人镇",
        "341623110": "王市镇",
        "341623111": "永兴镇",
        "341623112": "马店孜镇",
        "341623113": "大李集镇",
        "341623114": "胡集镇",
        "341623115": "展沟镇",
        "341623116": "程家集镇",
        "341623117": "中疃镇",
        "341623118": "望疃镇",
        "341623119": "城北镇",
        "341623203": "纪王场乡",
        "341623204": "孙庙乡",
        "341623205": "新张集乡",
        "341623401": "利辛县工业园区"
    },
    "341700": {"341702": "贵池区", "341721": "东至县", "341722": "石台县", "341723": "青阳县"},
    "341702": {
        "341702001": "池阳街道",
        "341702002": "秋浦街道",
        "341702003": "里山街道",
        "341702004": "江口街道",
        "341702005": "马衙街道",
        "341702006": "墩上街道",
        "341702007": "梅龙街道",
        "341702008": "秋江街道",
        "341702009": "杏花村街道",
        "341702010": "清风街道",
        "341702011": "清溪街道",
        "341702100": "殷汇镇",
        "341702101": "牛头山镇",
        "341702102": "涓桥镇",
        "341702103": "梅街镇",
        "341702104": "梅村镇",
        "341702105": "唐田镇",
        "341702106": "牌楼镇",
        "341702107": "乌沙镇",
        "341702108": "棠溪镇",
        "341702400": "安徽贵池工业园",
        "341702401": "池州经济技术开发区",
        "341702402": "平天湖风景区",
        "341702403": "前江工业园",
        "341702404": "安徽省江南产业集中区",
        "341702405": "池州承接产业转移集中示范园区"
    },
    "341721": {
        "341721100": "尧渡镇",
        "341721101": "东流镇",
        "341721102": "大渡口镇",
        "341721103": "胜利镇",
        "341721104": "张溪镇",
        "341721105": "洋湖镇",
        "341721106": "葛公镇",
        "341721107": "香隅镇",
        "341721108": "官港镇",
        "341721109": "昭潭镇",
        "341721110": "龙泉镇",
        "341721111": "泥溪镇",
        "341721200": "花园乡",
        "341721201": "木塔乡",
        "341721202": "青山乡",
        "341721400": "东至经济开发区",
        "341721401": "大渡口经济开发区"
    },
    "341722": {
        "341722100": "仁里镇",
        "341722101": "七都镇",
        "341722102": "仙寓镇",
        "341722103": "丁香镇",
        "341722104": "小河镇",
        "341722105": "横渡镇",
        "341722200": "大演乡",
        "341722201": "矶滩乡",
        "341722401": "池州经济技术开发区石台工业园区"
    },
    "341723": {
        "341723100": "蓉城镇",
        "341723101": "木镇镇",
        "341723102": "庙前镇",
        "341723103": "陵阳镇",
        "341723104": "新河镇",
        "341723105": "丁桥镇",
        "341723106": "朱备镇",
        "341723107": "杨田镇",
        "341723108": "九华镇",
        "341723109": "酉华镇",
        "341723200": "乔木乡",
        "341723202": "杜村乡",
        "341723203": "九华乡",
        "341723400": "青阳县开发区"
    },
    "341800": {
        "341802": "宣州区",
        "341821": "郎溪县",
        "341822": "广德县",
        "341823": "泾县",
        "341824": "绩溪县",
        "341825": "旌德县",
        "341871": "宣城市经济开发区",
        "341881": "宁国市"
    },
    "341802": {
        "341802001": "西林街道",
        "341802002": "澄江街道",
        "341802003": "鳌峰街道",
        "341802004": "济川街道",
        "341802005": "敬亭山街道",
        "341802006": "双桥街道",
        "341802009": "向阳街道",
        "341802100": "水阳镇",
        "341802101": "狸桥镇",
        "341802102": "沈村镇",
        "341802103": "古泉镇",
        "341802104": "洪林镇",
        "341802105": "寒亭镇",
        "341802106": "文昌镇",
        "341802107": "孙埠镇",
        "341802109": "杨柳镇",
        "341802110": "水东镇",
        "341802111": "新田镇",
        "341802112": "周王镇",
        "341802113": "溪口镇",
        "341802200": "朱桥乡",
        "341802201": "养贤乡",
        "341802202": "五星乡",
        "341802204": "黄渡乡"
    },
    "341821": {
        "341821100": "建平镇",
        "341821101": "十字镇",
        "341821102": "新发镇",
        "341821103": "涛城镇",
        "341821105": "梅渚镇",
        "341821107": "毕桥镇",
        "341821108": "飞鲤镇",
        "341821200": "凌笪乡",
        "341821202": "姚村乡",
        "341821400": "郎溪经济开发区",
        "341821500": "安徽省国营十字铺茶场",
        "341821501": "上海市白茅岭监狱"
    },
    "341822": {
        "341822100": "桃州镇",
        "341822101": "柏垫镇",
        "341822102": "誓节镇",
        "341822103": "邱村镇",
        "341822104": "新杭镇",
        "341822105": "杨滩镇",
        "341822200": "卢村乡",
        "341822201": "东亭乡",
        "341822203": "四合乡",
        "341822500": "祠山岗茶场"
    },
    "341823": {
        "341823100": "泾川镇",
        "341823101": "茂林镇",
        "341823102": "榔桥镇",
        "341823103": "桃花潭镇",
        "341823104": "琴溪镇",
        "341823105": "蔡村镇",
        "341823106": "云岭镇",
        "341823107": "黄村镇",
        "341823108": "丁家桥镇",
        "341823200": "汀溪乡",
        "341823201": "昌桥乡"
    },
    "341824": {
        "341824100": "华阳镇",
        "341824101": "临溪镇",
        "341824102": "长安镇",
        "341824103": "上庄镇",
        "341824104": "扬溪镇",
        "341824105": "伏岭镇",
        "341824106": "金沙镇",
        "341824107": "瀛洲镇",
        "341824201": "板桥头乡",
        "341824202": "家朋乡",
        "341824203": "荆州乡",
        "341824400": "生态工业园区"
    },
    "341825": {
        "341825100": "旌阳镇",
        "341825101": "蔡家桥镇",
        "341825102": "三溪镇",
        "341825103": "庙首镇",
        "341825104": "白地镇",
        "341825105": "俞村镇",
        "341825106": "兴隆镇",
        "341825107": "孙村镇",
        "341825108": "版书镇",
        "341825109": "云乐镇"
    },
    "341871": {"341871001": "天湖街道", "341871002": "飞彩街道", "341871003": "金坝街道"},
    "341881": {
        "341881001": "西津街道",
        "341881002": "南山街道",
        "341881003": "河沥溪街道",
        "341881004": "汪溪街道",
        "341881005": "竹峰街道",
        "341881100": "港口镇",
        "341881101": "梅林镇",
        "341881102": "中溪镇",
        "341881103": "宁墩镇",
        "341881104": "仙霞镇",
        "341881105": "甲路镇",
        "341881106": "胡乐镇",
        "341881107": "霞西镇",
        "341881200": "云梯畲族乡",
        "341881201": "南极乡",
        "341881202": "万家乡",
        "341881203": "青龙乡",
        "341881204": "方塘乡"
    },
    "350000": {
        "350100": "福州市",
        "350200": "厦门市",
        "350300": "莆田市",
        "350400": "三明市",
        "350500": "泉州市",
        "350600": "漳州市",
        "350700": "南平市",
        "350800": "龙岩市",
        "350900": "宁德市"
    },
    "350100": {
        "350102": "鼓楼区",
        "350103": "台江区",
        "350104": "仓山区",
        "350105": "马尾区",
        "350111": "晋安区",
        "350112": "长乐区",
        "350121": "闽侯县",
        "350122": "连江县",
        "350123": "罗源县",
        "350124": "闽清县",
        "350125": "永泰县",
        "350128": "平潭县",
        "350181": "福清市"
    },
    "350102": {
        "350102001": "鼓东街道",
        "350102002": "鼓西街道",
        "350102003": "温泉街道",
        "350102004": "东街街道",
        "350102005": "南街街道",
        "350102006": "安泰街道",
        "350102007": "华大街道",
        "350102008": "水部街道",
        "350102009": "五凤街道",
        "350102100": "洪山镇"
    },
    "350103": {
        "350103001": "瀛洲街道",
        "350103002": "后洲街道",
        "350103003": "义洲街道",
        "350103004": "新港街道",
        "350103005": "上海街道",
        "350103007": "苍霞街道",
        "350103009": "茶亭街道",
        "350103010": "洋中街道",
        "350103011": "鳌峰街道",
        "350103012": "宁化街道"
    },
    "350104": {
        "350104001": "仓前街道",
        "350104002": "东升街道",
        "350104003": "对湖街道",
        "350104004": "临江街道",
        "350104005": "三叉街街道",
        "350104006": "上渡街道",
        "350104007": "下渡街道",
        "350104008": "金山街道",
        "350104100": "仓山镇",
        "350104101": "城门镇",
        "350104102": "盖山镇",
        "350104103": "建新镇",
        "350104104": "螺洲镇",
        "350104400": "红星农场"
    },
    "350105": {"350105001": "罗星街道", "350105100": "马尾镇", "350105101": "亭江镇", "350105102": "琅岐镇"},
    "350111": {
        "350111001": "茶园街道",
        "350111002": "王庄街道",
        "350111003": "象园街道",
        "350111100": "鼓山镇",
        "350111101": "新店镇",
        "350111102": "岳峰镇",
        "350111103": "宦溪镇",
        "350111201": "寿山乡",
        "350111202": "日溪乡"
    },
    "350112": {
        "350112001": "吴航街道",
        "350112002": "航城街道",
        "350112003": "营前街道",
        "350112004": "漳港街道",
        "350112102": "首占镇",
        "350112103": "玉田镇",
        "350112104": "松下镇",
        "350112105": "江田镇",
        "350112106": "古槐镇",
        "350112107": "文武砂镇",
        "350112108": "鹤上镇",
        "350112110": "湖南镇",
        "350112111": "金峰镇",
        "350112112": "文岭镇",
        "350112113": "梅花镇",
        "350112114": "潭头镇",
        "350112200": "罗联乡",
        "350112201": "猴屿乡"
    },
    "350121": {
        "350121001": "甘蔗街道",
        "350121101": "白沙镇",
        "350121102": "南屿镇",
        "350121103": "尚干镇",
        "350121104": "祥谦镇",
        "350121105": "青口镇",
        "350121106": "南通镇",
        "350121107": "上街镇",
        "350121108": "荆溪镇",
        "350121200": "竹岐乡",
        "350121201": "鸿尾乡",
        "350121202": "洋里乡",
        "350121203": "大湖乡",
        "350121204": "廷坪乡",
        "350121206": "小箬乡",
        "350121400": "江洋农场"
    },
    "350122": {
        "350122100": "凤城镇",
        "350122101": "敖江镇",
        "350122102": "东岱镇",
        "350122103": "琯头镇",
        "350122104": "晓澳镇",
        "350122105": "东湖镇",
        "350122106": "丹阳镇",
        "350122107": "长龙镇",
        "350122108": "透堡镇",
        "350122109": "马鼻镇",
        "350122110": "官坂镇",
        "350122111": "筱埕镇",
        "350122112": "黄岐镇",
        "350122113": "苔菉镇",
        "350122114": "浦口镇",
        "350122115": "坑园镇",
        "350122200": "潘渡乡",
        "350122201": "江南乡",
        "350122202": "蓼沿乡",
        "350122203": "安凯乡",
        "350122204": "下宫乡",
        "350122205": "小沧畲族乡",
        "350122400": "马祖乡"
    },
    "350123": {
        "350123100": "凤山镇",
        "350123101": "松山镇",
        "350123102": "起步镇",
        "350123103": "中房镇",
        "350123104": "飞竹镇",
        "350123105": "鉴江镇",
        "350123200": "白塔乡",
        "350123201": "洪洋乡",
        "350123202": "西兰乡",
        "350123203": "霍口畲族乡",
        "350123204": "碧里乡",
        "350123500": "罗源湾"
    },
    "350124": {
        "350124100": "梅城镇",
        "350124101": "梅溪镇",
        "350124102": "白樟镇",
        "350124103": "金沙镇",
        "350124104": "白中镇",
        "350124105": "池园镇",
        "350124106": "坂东镇",
        "350124107": "塔庄镇",
        "350124108": "省璜镇",
        "350124109": "雄江镇",
        "350124110": "东桥镇",
        "350124200": "云龙乡",
        "350124201": "上莲乡",
        "350124204": "三溪乡",
        "350124205": "桔林乡",
        "350124206": "下祝乡"
    },
    "350125": {
        "350125100": "樟城镇",
        "350125101": "嵩口镇",
        "350125102": "梧桐镇",
        "350125103": "葛岭镇",
        "350125104": "城峰镇",
        "350125105": "清凉镇",
        "350125106": "长庆镇",
        "350125107": "同安镇",
        "350125108": "大洋镇",
        "350125200": "塘前乡",
        "350125201": "富泉乡",
        "350125202": "岭路乡",
        "350125203": "赤锡乡",
        "350125204": "洑口乡",
        "350125205": "盖洋乡",
        "350125206": "东洋乡",
        "350125207": "霞拔乡",
        "350125208": "盘谷乡",
        "350125209": "红星乡",
        "350125210": "白云乡",
        "350125211": "丹云乡"
    },
    "350128": {
        "350128100": "潭城镇",
        "350128101": "苏澳镇",
        "350128102": "流水镇",
        "350128103": "澳前镇",
        "350128104": "北厝镇",
        "350128105": "平原镇",
        "350128106": "敖东镇",
        "350128200": "白青乡",
        "350128201": "屿头乡",
        "350128202": "大练乡",
        "350128203": "芦洋乡",
        "350128204": "中楼乡",
        "350128205": "东庠乡",
        "350128206": "岚城乡",
        "350128207": "南海乡"
    },
    "350181": {
        "350181002": "玉屏街道",
        "350181003": "龙山街道",
        "350181004": "龙江街道",
        "350181005": "宏路街道",
        "350181006": "石竹街道",
        "350181007": "音西街道",
        "350181008": "阳下街道",
        "350181103": "海口镇",
        "350181104": "城头镇",
        "350181105": "南岭镇",
        "350181106": "龙田镇",
        "350181107": "江镜镇",
        "350181108": "港头镇",
        "350181109": "高山镇",
        "350181110": "沙埔镇",
        "350181111": "三山镇",
        "350181112": "东瀚镇",
        "350181113": "渔溪镇",
        "350181114": "上迳镇",
        "350181115": "新厝镇",
        "350181116": "江阴镇",
        "350181117": "东张镇",
        "350181118": "镜洋镇",
        "350181119": "一都镇",
        "350181400": "江镜华侨",
        "350181401": "东阁华侨"
    },
    "350200": {"350203": "思明区", "350205": "海沧区", "350206": "湖里区", "350211": "集美区", "350212": "同安区", "350213": "翔安区"},
    "350203": {
        "350203001": "厦港街道",
        "350203003": "中华街道",
        "350203005": "滨海街道",
        "350203006": "鹭江街道",
        "350203007": "开元街道",
        "350203008": "梧村街道",
        "350203009": "筼筜街道",
        "350203010": "莲前街道",
        "350203011": "嘉莲街道",
        "350203012": "鼓浪屿街道"
    },
    "350205": {
        "350205001": "海沧街道",
        "350205002": "新阳街道",
        "350205003": "嵩屿街道",
        "350205004": "东孚街道",
        "350205403": "天竺山林场",
        "350205407": "厦门海沧保税港区"
    },
    "350206": {
        "350206001": "湖里街道",
        "350206002": "殿前街道",
        "350206003": "禾山街道",
        "350206004": "江头街道",
        "350206005": "金山街道",
        "350206405": "火炬高技术开发区",
        "350206406": "象屿保税区"
    },
    "350211": {
        "350211001": "集美街道",
        "350211002": "侨英街道",
        "350211003": "杏林街道",
        "350211004": "杏滨街道",
        "350211102": "灌口镇",
        "350211103": "后溪镇",
        "350211401": "第二农场",
        "350211402": "省天马种猪场",
        "350211403": "天马华侨农场",
        "350211404": "坂头防护林场"
    },
    "350212": {
        "350212001": "大同街道",
        "350212002": "祥平街道",
        "350212105": "莲花镇",
        "350212106": "新民镇",
        "350212107": "洪塘镇",
        "350212108": "西柯镇",
        "350212109": "汀溪镇",
        "350212110": "五显镇",
        "350212403": "竹坝华侨农场（竹坝华侨经济开发区）"
    },
    "350213": {
        "350213001": "大嶝街道",
        "350213102": "马巷镇",
        "350213103": "新圩镇",
        "350213104": "新店镇",
        "350213111": "内厝镇",
        "350213404": "大帽山农场"
    },
    "350300": {"350302": "城厢区", "350303": "涵江区", "350304": "荔城区", "350305": "秀屿区", "350322": "仙游县"},
    "350302": {
        "350302001": "龙桥街道",
        "350302002": "凤凰山街道",
        "350302003": "霞林街道",
        "350302100": "常太镇",
        "350302101": "华亭镇",
        "350302102": "灵川镇",
        "350302103": "东海镇"
    },
    "350303": {
        "350303001": "涵东街道",
        "350303002": "涵西街道",
        "350303100": "三江口镇",
        "350303101": "白塘镇",
        "350303102": "国欢镇",
        "350303103": "梧塘镇",
        "350303104": "江口镇",
        "350303105": "萩芦镇",
        "350303106": "白沙镇",
        "350303107": "庄边镇",
        "350303108": "新县镇",
        "350303200": "大洋乡",
        "350303500": "赤港开发区"
    },
    "350304": {
        "350304001": "镇海街道",
        "350304002": "拱辰街道",
        "350304100": "西天尾镇",
        "350304101": "黄石镇",
        "350304102": "新度镇",
        "350304103": "北高镇"
    },
    "350305": {
        "350305100": "笏石镇",
        "350305101": "东庄镇",
        "350305102": "忠门镇",
        "350305103": "东埔镇",
        "350305104": "东峤镇",
        "350305105": "埭头镇",
        "350305106": "平海镇",
        "350305107": "南日镇",
        "350305108": "湄洲镇",
        "350305109": "山亭镇",
        "350305110": "月塘镇",
        "350305400": "前沁农场",
        "350305401": "后海管理局"
    },
    "350322": {
        "350322001": "鲤城街道",
        "350322100": "枫亭镇",
        "350322101": "榜头镇",
        "350322102": "郊尾镇",
        "350322103": "度尾镇",
        "350322104": "鲤南镇",
        "350322105": "赖店镇",
        "350322106": "盖尾镇",
        "350322107": "园庄镇",
        "350322108": "大济镇",
        "350322109": "龙华镇",
        "350322110": "钟山镇",
        "350322111": "游洋镇",
        "350322200": "西苑乡",
        "350322201": "石苍乡",
        "350322202": "社硎乡",
        "350322203": "书峰乡",
        "350322204": "菜溪乡"
    },
    "350400": {
        "350402": "梅列区",
        "350403": "三元区",
        "350421": "明溪县",
        "350423": "清流县",
        "350424": "宁化县",
        "350425": "大田县",
        "350426": "尤溪县",
        "350427": "沙县",
        "350428": "将乐县",
        "350429": "泰宁县",
        "350430": "建宁县",
        "350481": "永安市"
    },
    "350402": {
        "350402001": "列东街道",
        "350402002": "列西街道",
        "350402003": "徐碧街道",
        "350402100": "陈大镇",
        "350402101": "洋溪镇",
        "350402500": "福建梅列经济开发区"
    },
    "350403": {
        "350403001": "城关街道",
        "350403002": "白沙街道",
        "350403003": "富兴堡街道",
        "350403004": "荆西街道",
        "350403100": "莘口镇",
        "350403101": "岩前镇",
        "350403200": "城东乡",
        "350403201": "中村乡"
    },
    "350421": {
        "350421100": "雪峰镇",
        "350421101": "盖洋镇",
        "350421102": "胡坊镇",
        "350421103": "瀚仙镇",
        "350421200": "城关乡",
        "350421201": "沙溪乡",
        "350421202": "夏阳乡",
        "350421203": "枫溪乡",
        "350421204": "夏坊乡"
    },
    "350423": {
        "350423100": "龙津镇",
        "350423101": "嵩溪镇",
        "350423102": "嵩口镇",
        "350423103": "灵地镇",
        "350423104": "长校镇",
        "350423105": "赖坊镇",
        "350423201": "温郊乡",
        "350423202": "林畲乡",
        "350423203": "田源乡",
        "350423204": "沙芜乡",
        "350423206": "余朋乡",
        "350423208": "李家乡",
        "350423209": "里田乡"
    },
    "350424": {
        "350424100": "翠江镇",
        "350424101": "泉上镇",
        "350424102": "湖村镇",
        "350424103": "石壁镇",
        "350424104": "曹坊镇",
        "350424105": "安远镇",
        "350424106": "淮土镇",
        "350424107": "安乐镇",
        "350424108": "水茜镇",
        "350424200": "城郊乡",
        "350424201": "城南乡",
        "350424202": "济村乡",
        "350424204": "方田乡",
        "350424207": "治平畲族乡",
        "350424208": "中沙乡",
        "350424209": "河龙乡"
    },
    "350425": {
        "350425100": "均溪镇",
        "350425101": "石牌镇",
        "350425102": "上京镇",
        "350425103": "广平镇",
        "350425104": "桃源镇",
        "350425105": "太华镇",
        "350425106": "建设镇",
        "350425107": "奇韬镇",
        "350425108": "华兴镇",
        "350425109": "吴山镇",
        "350425110": "文江镇",
        "350425111": "梅山镇",
        "350425201": "屏山乡",
        "350425203": "济阳乡",
        "350425204": "武陵乡",
        "350425205": "谢洋乡",
        "350425208": "湖美乡",
        "350425209": "前坪乡",
        "350425400": "东风农场"
    },
    "350426": {
        "350426100": "城关镇",
        "350426101": "梅仙镇",
        "350426102": "西滨镇",
        "350426103": "洋中镇",
        "350426104": "新阳镇",
        "350426105": "管前镇",
        "350426106": "西城镇",
        "350426107": "尤溪口镇",
        "350426108": "坂面镇",
        "350426109": "联合镇",
        "350426201": "汤川乡",
        "350426202": "溪尾乡",
        "350426203": "中仙乡",
        "350426204": "台溪乡",
        "350426206": "八字桥乡"
    },
    "350427": {
        "350427001": "凤岗街道",
        "350427002": "虬江街道",
        "350427101": "青州镇",
        "350427102": "夏茂镇",
        "350427104": "高砂镇",
        "350427105": "高桥镇",
        "350427106": "富口镇",
        "350427107": "大洛镇",
        "350427201": "南霞乡",
        "350427203": "南阳乡",
        "350427204": "郑湖乡",
        "350427205": "湖源乡",
        "350427400": "金沙园开发区",
        "350427401": "金古园开发区",
        "350427402": "青山纸业工业区",
        "350427403": "海西物流园区"
    },
    "350428": {
        "350428100": "古镛镇",
        "350428101": "万安镇",
        "350428102": "高唐镇",
        "350428103": "白莲镇",
        "350428104": "黄潭镇",
        "350428105": "水南镇",
        "350428200": "光明乡",
        "350428201": "漠源乡",
        "350428202": "南口乡",
        "350428203": "万全乡",
        "350428204": "安仁乡",
        "350428205": "大源乡",
        "350428206": "余坊乡"
    },
    "350429": {
        "350429100": "杉城镇",
        "350429101": "朱口镇",
        "350429200": "新桥乡",
        "350429201": "上青乡",
        "350429202": "大田乡",
        "350429203": "梅口乡",
        "350429204": "下渠乡",
        "350429205": "开善乡",
        "350429208": "大龙乡"
    },
    "350430": {
        "350430100": "濉溪镇",
        "350430101": "里心镇",
        "350430102": "溪口镇",
        "350430103": "均口镇",
        "350430201": "伊家乡",
        "350430202": "黄坊乡",
        "350430203": "溪源乡",
        "350430204": "客坊乡",
        "350430205": "黄埠乡",
        "350430400": "福建建宁经济开发区"
    },
    "350481": {
        "350481001": "燕东街道",
        "350481002": "燕西街道",
        "350481003": "燕南街道",
        "350481004": "燕北街道",
        "350481100": "西洋镇",
        "350481101": "贡川镇",
        "350481102": "安砂镇",
        "350481103": "小陶镇",
        "350481104": "大湖镇",
        "350481105": "曹远镇",
        "350481106": "洪田镇",
        "350481107": "槐南镇",
        "350481202": "上坪乡",
        "350481203": "罗坊乡",
        "350481204": "青水畲族乡"
    },
    "350500": {
        "350502": "鲤城区",
        "350503": "丰泽区",
        "350504": "洛江区",
        "350505": "泉港区",
        "350521": "惠安县",
        "350524": "安溪县",
        "350525": "永春县",
        "350526": "德化县",
        "350581": "石狮市",
        "350582": "晋江市",
        "350583": "南安市"
    },
    "350502": {
        "350502001": "海滨街道",
        "350502002": "临江街道",
        "350502003": "鲤中街道",
        "350502004": "开元街道",
        "350502005": "浮桥街道",
        "350502006": "江南街道",
        "350502007": "金龙街道",
        "350502008": "常泰街道",
        "350502400": "清濛经济开发区"
    },
    "350503": {
        "350503001": "东湖街道",
        "350503002": "丰泽街道",
        "350503003": "泉秀街道",
        "350503004": "清源街道",
        "350503005": "华大街道",
        "350503006": "城东街道",
        "350503007": "东海街道",
        "350503008": "北峰街道"
    },
    "350504": {
        "350504001": "万安街道",
        "350504002": "双阳街道",
        "350504100": "罗溪镇",
        "350504101": "马甲镇",
        "350504102": "河市镇",
        "350504200": "虹山乡"
    },
    "350505": {
        "350505001": "山腰街道",
        "350505100": "南埔镇",
        "350505101": "界山镇",
        "350505102": "后龙镇",
        "350505103": "峰尾镇",
        "350505105": "前黄镇",
        "350505106": "涂岭镇"
    },
    "350521": {
        "350521100": "螺城镇",
        "350521101": "螺阳镇",
        "350521102": "黄塘镇",
        "350521103": "紫山镇",
        "350521104": "洛阳镇",
        "350521105": "东园镇",
        "350521106": "张坂镇",
        "350521107": "崇武镇",
        "350521108": "山霞镇",
        "350521109": "涂寨镇",
        "350521110": "东岭镇",
        "350521111": "东桥镇",
        "350521112": "净峰镇",
        "350521113": "小岞镇",
        "350521114": "辋川镇",
        "350521200": "百崎回族乡",
        "350521400": "城南工业区",
        "350521401": "惠南工业区"
    },
    "350524": {
        "350524100": "凤城镇",
        "350524101": "蓬莱镇",
        "350524102": "湖头镇",
        "350524103": "官桥镇",
        "350524104": "剑斗镇",
        "350524105": "城厢镇",
        "350524106": "金谷镇",
        "350524107": "龙门镇",
        "350524108": "虎邱镇",
        "350524109": "芦田镇",
        "350524110": "感德镇",
        "350524111": "魁斗镇",
        "350524112": "西坪镇",
        "350524200": "参内乡",
        "350524201": "白濑乡",
        "350524202": "湖上乡",
        "350524203": "尚卿乡",
        "350524204": "大坪乡",
        "350524205": "龙涓乡",
        "350524206": "长坑乡",
        "350524207": "蓝田乡",
        "350524208": "祥华乡",
        "350524209": "桃舟乡",
        "350524210": "福田乡"
    },
    "350525": {
        "350525100": "桃城镇",
        "350525101": "五里街镇",
        "350525102": "一都镇",
        "350525103": "下洋镇",
        "350525104": "蓬壶镇",
        "350525105": "达埔镇",
        "350525106": "吾峰镇",
        "350525107": "石鼓镇",
        "350525108": "岵山镇",
        "350525109": "东平镇",
        "350525110": "湖洋镇",
        "350525111": "坑仔口镇",
        "350525112": "玉斗镇",
        "350525113": "锦斗镇",
        "350525114": "东关镇",
        "350525115": "桂洋镇",
        "350525116": "苏坑镇",
        "350525117": "仙夹镇",
        "350525200": "横口乡",
        "350525201": "呈祥乡",
        "350525202": "介福乡",
        "350525204": "外山乡"
    },
    "350526": {
        "350526100": "浔中镇",
        "350526101": "龙浔镇",
        "350526102": "三班镇",
        "350526103": "龙门滩镇",
        "350526104": "雷峰镇",
        "350526105": "南埕镇",
        "350526106": "水口镇",
        "350526107": "赤水镇",
        "350526108": "上涌镇",
        "350526109": "葛坑镇",
        "350526110": "盖德镇",
        "350526111": "美湖镇",
        "350526200": "杨梅乡",
        "350526202": "汤头乡",
        "350526204": "桂阳乡",
        "350526206": "国宝乡",
        "350526208": "大铭乡",
        "350526209": "春美乡"
    },
    "350581": {
        "350581001": "湖滨街道",
        "350581002": "凤里街道",
        "350581100": "灵秀镇",
        "350581101": "宝盖镇",
        "350581102": "蚶江镇",
        "350581103": "祥芝镇",
        "350581104": "鸿山镇",
        "350581105": "锦尚镇",
        "350581106": "永宁镇"
    },
    "350582": {
        "350582001": "青阳街道",
        "350582002": "梅岭街道",
        "350582003": "西园街道",
        "350582004": "罗山街道",
        "350582005": "新塘街道",
        "350582006": "灵源街道",
        "350582101": "安海镇",
        "350582102": "磁灶镇",
        "350582103": "陈埭镇",
        "350582104": "东石镇",
        "350582105": "深沪镇",
        "350582106": "金井镇",
        "350582107": "池店镇",
        "350582109": "内坑镇",
        "350582110": "龙湖镇",
        "350582111": "永和镇",
        "350582112": "英林镇",
        "350582113": "紫帽镇",
        "350582114": "西滨镇",
        "350582400": "安平开发区",
        "350582401": "晋江市经济开发区",
        "350582402": "泉州出口加工区"
    },
    "350583": {
        "350583001": "溪美街道",
        "350583002": "柳城街道",
        "350583003": "美林街道",
        "350583100": "省新镇",
        "350583101": "仑苍镇",
        "350583102": "东田镇",
        "350583103": "英都镇",
        "350583104": "翔云镇",
        "350583105": "金淘镇",
        "350583106": "诗山镇",
        "350583107": "蓬华镇",
        "350583108": "码头镇",
        "350583109": "九都镇",
        "350583110": "乐峰镇",
        "350583111": "罗东镇",
        "350583112": "梅山镇",
        "350583113": "洪濑镇",
        "350583114": "洪梅镇",
        "350583115": "康美镇",
        "350583116": "丰州镇",
        "350583117": "霞美镇",
        "350583118": "官桥镇",
        "350583119": "水头镇",
        "350583120": "石井镇",
        "350583200": "眉山乡",
        "350583201": "向阳乡",
        "350583400": "雪峰管委会"
    },
    "350600": {
        "350602": "芗城区",
        "350603": "龙文区",
        "350622": "云霄县",
        "350623": "漳浦县",
        "350624": "诏安县",
        "350625": "长泰县",
        "350626": "东山县",
        "350627": "南靖县",
        "350628": "平和县",
        "350629": "华安县",
        "350681": "龙海市"
    },
    "350602": {
        "350602001": "东铺头街道",
        "350602002": "西桥街道",
        "350602003": "新桥街道",
        "350602004": "巷口街道",
        "350602005": "南坑街道",
        "350602006": "通北街道",
        "350602100": "浦南镇",
        "350602101": "天宝镇",
        "350602102": "芝山镇",
        "350602103": "石亭镇",
        "350602400": "芗城区奶牛场",
        "350602402": "天宝林场"
    },
    "350603": {
        "350603001": "步文街道",
        "350603002": "碧湖街道",
        "350603100": "蓝田镇",
        "350603102": "朝阳镇",
        "350603103": "郭坑镇",
        "350603500": "蓝田开发区管委会"
    },
    "350622": {
        "350622100": "云陵镇",
        "350622101": "陈岱镇",
        "350622102": "东厦镇",
        "350622103": "莆美镇",
        "350622104": "列屿镇",
        "350622105": "火田镇",
        "350622200": "下河乡",
        "350622201": "马铺乡",
        "350622202": "和平乡",
        "350622500": "常山华侨经济开发区",
        "350622501": "云陵工业开发区"
    },
    "350623": {
        "350623100": "绥安镇",
        "350623101": "旧镇镇",
        "350623102": "佛昙镇",
        "350623103": "赤湖镇",
        "350623104": "杜浔镇",
        "350623105": "霞美镇",
        "350623106": "官浔镇",
        "350623107": "石榴镇",
        "350623108": "盘陀镇",
        "350623109": "长桥镇",
        "350623110": "前亭镇",
        "350623111": "马坪镇",
        "350623112": "深土镇",
        "350623113": "六鳌镇",
        "350623114": "沙西镇",
        "350623115": "古雷镇",
        "350623116": "大南坂镇",
        "350623200": "南浦乡",
        "350623201": "赤岭畲族乡",
        "350623202": "湖西畲族乡",
        "350623203": "赤土乡",
        "350623400": "漳浦盐场",
        "350623401": "万安农场",
        "350623402": "石古农场",
        "350623403": "长桥农场",
        "350623404": "白竹湖农场",
        "350623405": "南山华侨茶果场",
        "350623406": "中西林场",
        "350623407": "玳瑁山茶场",
        "350623408": "下蔡林场"
    },
    "350624": {
        "350624100": "南诏镇",
        "350624101": "四都镇",
        "350624102": "梅岭镇",
        "350624103": "桥东镇",
        "350624104": "深桥镇",
        "350624105": "太平镇",
        "350624106": "霞葛镇",
        "350624107": "官陂镇",
        "350624108": "秀篆镇",
        "350624109": "西潭镇",
        "350624200": "金星乡",
        "350624202": "白洋乡",
        "350624203": "建设乡",
        "350624204": "红星乡",
        "350624205": "梅洲乡",
        "350624401": "国营西山农场",
        "350624402": "国营湖内林场",
        "350624403": "岭下溪国有防护林场",
        "350624501": "诏安工业园区管委会",
        "350624502": "诏安金都工业集中区管委会"
    },
    "350625": {
        "350625100": "武安镇",
        "350625101": "岩溪镇",
        "350625102": "陈巷镇",
        "350625103": "枋洋镇",
        "350625200": "坂里乡",
        "350625400": "国营古农农场",
        "350625500": "长泰经济开发区",
        "350625501": "马洋溪生态旅游区",
        "350625502": "林墩工业区"
    },
    "350626": {
        "350626100": "西埔镇",
        "350626101": "樟塘镇",
        "350626102": "康美镇",
        "350626103": "杏陈镇",
        "350626104": "陈城镇",
        "350626105": "前楼镇",
        "350626106": "铜陵镇"
    },
    "350627": {
        "350627100": "山城镇",
        "350627101": "丰田镇",
        "350627102": "靖城镇",
        "350627103": "龙山镇",
        "350627104": "金山镇",
        "350627105": "和溪镇",
        "350627106": "奎洋镇",
        "350627107": "梅林镇",
        "350627108": "书洋镇",
        "350627109": "船场镇",
        "350627110": "南坑镇"
    },
    "350628": {
        "350628100": "小溪镇",
        "350628101": "山格镇",
        "350628102": "文峰镇",
        "350628103": "南胜镇",
        "350628104": "坂仔镇",
        "350628105": "安厚镇",
        "350628106": "大溪镇",
        "350628107": "霞寨镇",
        "350628108": "九峰镇",
        "350628109": "芦溪镇",
        "350628200": "五寨乡",
        "350628201": "国强乡",
        "350628202": "崎岭乡",
        "350628203": "长乐乡",
        "350628204": "秀峰乡",
        "350628400": "安厚农场",
        "350628401": "福建平和工业园区"
    },
    "350629": {
        "350629100": "华丰镇",
        "350629101": "丰山镇",
        "350629102": "沙建镇",
        "350629103": "新圩镇",
        "350629104": "高安镇",
        "350629105": "仙都镇",
        "350629200": "高车乡",
        "350629201": "马坑乡",
        "350629203": "湖林乡",
        "350629400": "福建土楼（华安）旅游区"
    },
    "350681": {
        "350681100": "石码镇",
        "350681101": "海澄镇",
        "350681102": "角美镇",
        "350681103": "白水镇",
        "350681104": "浮宫镇",
        "350681105": "程溪镇",
        "350681106": "港尾镇",
        "350681107": "九湖镇",
        "350681108": "颜厝镇",
        "350681109": "榜山镇",
        "350681110": "紫泥镇",
        "350681111": "东园镇",
        "350681200": "东泗乡",
        "350681201": "隆教畲族乡",
        "350681400": "双第华侨农场",
        "350681401": "九龙岭林场",
        "350681402": "程溪农场",
        "350681403": "良种场",
        "350681404": "苍坂农场",
        "350681405": "林下林场",
        "350681501": "招商局漳州开发区"
    },
    "350700": {
        "350702": "延平区",
        "350703": "建阳区",
        "350721": "顺昌县",
        "350722": "浦城县",
        "350723": "光泽县",
        "350724": "松溪县",
        "350725": "政和县",
        "350781": "邵武市",
        "350782": "武夷山市",
        "350783": "建瓯市"
    },
    "350702": {
        "350702001": "梅山街道",
        "350702002": "黄墩街道",
        "350702003": "紫云街道",
        "350702004": "四鹤街道",
        "350702005": "水南街道",
        "350702006": "水东街道",
        "350702100": "来舟镇",
        "350702101": "樟湖镇",
        "350702102": "夏道镇",
        "350702103": "西芹镇",
        "350702104": "峡阳镇",
        "350702105": "南山镇",
        "350702106": "大横镇",
        "350702107": "王台镇",
        "350702108": "太平镇",
        "350702109": "塔前镇",
        "350702110": "茫荡镇",
        "350702111": "洋后镇",
        "350702112": "炉下镇",
        "350702200": "巨口乡",
        "350702202": "赤门乡"
    },
    "350703": {
        "350703001": "潭城街道",
        "350703002": "童游街道",
        "350703102": "将口镇",
        "350703103": "徐市镇",
        "350703104": "莒口镇",
        "350703105": "麻沙镇",
        "350703106": "黄坑镇",
        "350703107": "水吉镇",
        "350703108": "漳墩镇",
        "350703109": "小湖镇",
        "350703200": "崇雒乡",
        "350703201": "书坊乡",
        "350703202": "回龙乡"
    },
    "350721": {
        "350721001": "双溪街道",
        "350721100": "建西镇",
        "350721101": "洋口镇",
        "350721102": "元坑镇",
        "350721103": "埔上镇",
        "350721104": "大历镇",
        "350721105": "大干镇",
        "350721106": "仁寿镇",
        "350721107": "郑坊镇",
        "350721200": "洋墩乡",
        "350721202": "岚下乡",
        "350721203": "高阳乡"
    },
    "350722": {
        "350722001": "南浦街道",
        "350722002": "河滨街道",
        "350722101": "富岭镇",
        "350722102": "石陂镇",
        "350722103": "临江镇",
        "350722104": "仙阳镇",
        "350722105": "水北街镇",
        "350722106": "永兴镇",
        "350722107": "忠信镇",
        "350722108": "莲塘镇",
        "350722109": "九牧镇",
        "350722200": "万安乡",
        "350722201": "古楼乡",
        "350722202": "山下乡",
        "350722203": "枫溪乡",
        "350722204": "濠村乡",
        "350722205": "管厝乡",
        "350722206": "盘亭乡",
        "350722207": "官路乡",
        "350722400": "水南农场"
    },
    "350723": {
        "350723100": "杭川镇",
        "350723101": "寨里镇",
        "350723102": "止马镇",
        "350723200": "鸾凤乡",
        "350723201": "崇仁乡",
        "350723202": "李坊乡",
        "350723203": "华桥乡",
        "350723204": "司前乡"
    },
    "350724": {
        "350724001": "松源街道",
        "350724101": "郑墩镇",
        "350724102": "渭田镇",
        "350724200": "河东乡",
        "350724201": "茶平乡",
        "350724202": "旧县乡",
        "350724203": "溪东乡",
        "350724204": "花桥乡",
        "350724205": "祖墩乡"
    },
    "350725": {
        "350725001": "熊山街道",
        "350725101": "东平镇",
        "350725102": "石屯镇",
        "350725103": "铁山镇",
        "350725104": "镇前镇",
        "350725200": "星溪乡",
        "350725201": "外屯乡",
        "350725202": "杨源乡",
        "350725203": "澄源乡",
        "350725204": "岭腰乡"
    },
    "350781": {
        "350781001": "昭阳街道",
        "350781002": "通泰街道",
        "350781003": "水北街道",
        "350781004": "晒口街道",
        "350781100": "城郊镇",
        "350781101": "水北镇",
        "350781102": "下沙镇",
        "350781103": "卫闽镇",
        "350781104": "沿山镇",
        "350781105": "拿口镇",
        "350781106": "洪墩镇",
        "350781107": "大埠岗镇",
        "350781108": "和平镇",
        "350781109": "肖家坊镇",
        "350781110": "大竹镇",
        "350781111": "吴家塘镇",
        "350781200": "桂林乡",
        "350781201": "张厝乡",
        "350781202": "金坑乡"
    },
    "350782": {
        "350782001": "崇安街道",
        "350782002": "新丰街道",
        "350782003": "武夷街道",
        "350782100": "星村镇",
        "350782101": "兴田镇",
        "350782102": "五夫镇",
        "350782200": "上梅乡",
        "350782201": "吴屯乡",
        "350782202": "岚谷乡",
        "350782203": "洋庄乡"
    },
    "350783": {
        "350783001": "建安街道",
        "350783002": "通济街道",
        "350783003": "瓯宁街道",
        "350783004": "芝山街道",
        "350783100": "徐墩镇",
        "350783101": "吉阳镇",
        "350783102": "房道镇",
        "350783103": "南雅镇",
        "350783104": "迪口镇",
        "350783105": "小桥镇",
        "350783106": "玉山镇",
        "350783107": "东游镇",
        "350783108": "东峰镇",
        "350783109": "小松镇",
        "350783200": "顺阳乡",
        "350783201": "水源乡",
        "350783202": "川石乡",
        "350783203": "龙村乡"
    },
    "350800": {
        "350802": "新罗区",
        "350803": "永定区",
        "350821": "长汀县",
        "350823": "上杭县",
        "350824": "武平县",
        "350825": "连城县",
        "350881": "漳平市"
    },
    "350802": {
        "350802001": "东城街道",
        "350802002": "南城街道",
        "350802003": "西城街道",
        "350802004": "中城街道",
        "350802005": "西陂街道",
        "350802006": "曹溪街道",
        "350802007": "东肖街道",
        "350802008": "龙门街道",
        "350802009": "铁山街道",
        "350802010": "北城街道",
        "350802100": "红坊镇",
        "350802101": "适中镇",
        "350802102": "雁石镇",
        "350802103": "白沙镇",
        "350802104": "万安镇",
        "350802105": "大池镇",
        "350802106": "小池镇",
        "350802107": "江山镇",
        "350802108": "岩山镇",
        "350802109": "苏坂镇"
    },
    "350803": {
        "350803001": "凤城街道",
        "350803101": "坎市镇",
        "350803102": "下洋镇",
        "350803103": "湖雷镇",
        "350803104": "高陂镇",
        "350803105": "抚市镇",
        "350803106": "湖坑镇",
        "350803107": "培丰镇",
        "350803108": "龙潭镇",
        "350803109": "峰市镇",
        "350803110": "城郊镇",
        "350803111": "仙师镇",
        "350803112": "虎岗镇",
        "350803201": "西溪乡",
        "350803202": "金砂乡",
        "350803204": "洪山乡",
        "350803205": "湖山乡",
        "350803206": "岐岭乡",
        "350803207": "古竹乡",
        "350803208": "堂堡乡",
        "350803209": "合溪乡",
        "350803211": "大溪乡",
        "350803212": "陈东乡",
        "350803213": "高头乡"
    },
    "350821": {
        "350821100": "汀州镇",
        "350821101": "大同镇",
        "350821102": "古城镇",
        "350821103": "新桥镇",
        "350821104": "馆前镇",
        "350821105": "童坊镇",
        "350821106": "河田镇",
        "350821107": "南山镇",
        "350821108": "濯田镇",
        "350821109": "四都镇",
        "350821110": "涂坊镇",
        "350821111": "策武镇",
        "350821112": "三洲镇",
        "350821201": "铁长乡",
        "350821202": "庵杰乡",
        "350821204": "宣成乡",
        "350821205": "红山乡",
        "350821206": "羊牯乡"
    },
    "350823": {
        "350823100": "临江镇",
        "350823101": "临城镇",
        "350823102": "中都镇",
        "350823103": "蓝溪镇",
        "350823104": "稔田镇",
        "350823105": "白砂镇",
        "350823106": "古田镇",
        "350823107": "才溪镇",
        "350823108": "南阳镇",
        "350823109": "蛟洋镇",
        "350823110": "旧县镇",
        "350823111": "湖洋镇",
        "350823112": "溪口镇",
        "350823113": "太拔镇",
        "350823114": "通贤镇",
        "350823115": "下都镇",
        "350823116": "茶地镇",
        "350823202": "庐丰畲族乡",
        "350823206": "泮境乡",
        "350823208": "步云乡",
        "350823211": "官庄畲族乡",
        "350823212": "珊瑚乡"
    },
    "350824": {
        "350824100": "平川镇",
        "350824101": "中山镇",
        "350824102": "岩前镇",
        "350824103": "十方镇",
        "350824104": "中堡镇",
        "350824105": "桃溪镇",
        "350824106": "城厢镇",
        "350824107": "东留镇",
        "350824108": "武东镇",
        "350824109": "万安镇",
        "350824110": "永平镇",
        "350824111": "象洞镇",
        "350824203": "民主乡",
        "350824204": "下坝乡",
        "350824205": "中赤乡",
        "350824209": "湘店乡",
        "350824210": "大禾乡"
    },
    "350825": {
        "350825100": "莲峰镇",
        "350825101": "北团镇",
        "350825102": "姑田镇",
        "350825103": "朋口镇",
        "350825104": "莒溪镇",
        "350825105": "新泉镇",
        "350825106": "庙前镇",
        "350825107": "文亨镇",
        "350825108": "四堡镇",
        "350825109": "林坊镇",
        "350825200": "揭乐乡",
        "350825201": "塘前乡",
        "350825202": "隔川乡",
        "350825204": "罗坊乡",
        "350825208": "曲溪乡",
        "350825209": "赖源乡",
        "350825210": "宣和乡"
    },
    "350881": {
        "350881001": "菁城街道",
        "350881002": "桂林街道",
        "350881100": "新桥镇",
        "350881101": "双洋镇",
        "350881102": "永福镇",
        "350881103": "溪南镇",
        "350881104": "和平镇",
        "350881105": "拱桥镇",
        "350881106": "象湖镇",
        "350881107": "赤水镇",
        "350881108": "西园镇",
        "350881109": "南洋镇",
        "350881110": "芦芝镇",
        "350881203": "官田乡",
        "350881204": "吾祠乡",
        "350881205": "灵地乡"
    },
    "350900": {
        "350902": "蕉城区",
        "350921": "霞浦县",
        "350922": "古田县",
        "350923": "屏南县",
        "350924": "寿宁县",
        "350925": "周宁县",
        "350926": "柘荣县",
        "350981": "福安市",
        "350982": "福鼎市"
    },
    "350902": {
        "350902001": "蕉南街道",
        "350902002": "蕉北街道",
        "350902100": "城南镇",
        "350902101": "漳湾镇",
        "350902102": "七都镇",
        "350902103": "八都镇",
        "350902104": "九都镇",
        "350902105": "霍童镇",
        "350902106": "赤溪镇",
        "350902107": "洋中镇",
        "350902108": "飞鸾镇",
        "350902109": "三都镇",
        "350902110": "虎贝镇",
        "350902200": "金涵畲族乡",
        "350902201": "洪口乡",
        "350902202": "石后乡",
        "350902500": "东侨开发区"
    },
    "350921": {
        "350921001": "松城街道",
        "350921002": "松港街道",
        "350921101": "长春镇",
        "350921102": "牙城镇",
        "350921103": "溪南镇",
        "350921104": "沙江镇",
        "350921105": "下浒镇",
        "350921106": "三沙镇",
        "350921200": "盐田畲族乡",
        "350921201": "水门畲族乡",
        "350921202": "崇儒畲族乡",
        "350921203": "柏洋乡",
        "350921204": "北壁乡",
        "350921205": "海岛乡"
    },
    "350922": {
        "350922001": "城东街道",
        "350922002": "城西街道",
        "350922101": "平湖镇",
        "350922102": "大桥镇",
        "350922103": "黄田镇",
        "350922104": "鹤塘镇",
        "350922105": "杉洋镇",
        "350922106": "凤都镇",
        "350922107": "水口镇",
        "350922108": "大甲镇",
        "350922201": "吉巷乡",
        "350922203": "泮洋乡",
        "350922204": "凤埔乡",
        "350922205": "卓洋乡"
    },
    "350923": {
        "350923100": "古峰镇",
        "350923101": "双溪镇",
        "350923102": "黛溪镇",
        "350923103": "长桥镇",
        "350923200": "屏城乡",
        "350923201": "棠口乡",
        "350923202": "甘棠乡",
        "350923203": "熙岭乡",
        "350923204": "路下乡",
        "350923205": "寿山乡",
        "350923206": "岭下乡"
    },
    "350924": {
        "350924100": "鳌阳镇",
        "350924101": "斜滩镇",
        "350924102": "南阳镇",
        "350924103": "武曲镇",
        "350924104": "犀溪镇",
        "350924105": "平溪镇",
        "350924106": "凤阳镇",
        "350924107": "清源镇",
        "350924200": "大安乡",
        "350924201": "坑底乡",
        "350924203": "竹管垅乡",
        "350924205": "芹洋乡",
        "350924206": "托溪乡",
        "350924209": "下党乡"
    },
    "350925": {
        "350925100": "狮城镇",
        "350925101": "咸村镇",
        "350925102": "浦源镇",
        "350925103": "七步镇",
        "350925104": "李墩镇",
        "350925105": "纯池镇",
        "350925200": "泗桥乡",
        "350925201": "礼门乡",
        "350925202": "玛坑乡"
    },
    "350926": {
        "350926100": "双城镇",
        "350926101": "富溪镇",
        "350926200": "城郊乡",
        "350926201": "乍洋乡",
        "350926202": "东源乡",
        "350926203": "黄柏乡",
        "350926204": "宅中乡",
        "350926205": "楮坪乡",
        "350926206": "英山乡"
    },
    "350981": {
        "350981001": "城南街道",
        "350981002": "城北街道",
        "350981003": "阳头街道",
        "350981004": "罗江街道",
        "350981100": "赛岐镇",
        "350981101": "穆阳镇",
        "350981102": "上白石镇",
        "350981103": "潭头镇",
        "350981104": "社口镇",
        "350981105": "晓阳镇",
        "350981106": "溪潭镇",
        "350981107": "甘棠镇",
        "350981108": "下白石镇",
        "350981109": "溪尾镇",
        "350981110": "溪柄镇",
        "350981111": "湾坞镇",
        "350981112": "城阳镇",
        "350981201": "坂中畲族乡",
        "350981202": "范坑乡",
        "350981203": "穆云畲族乡",
        "350981204": "康厝畲族乡",
        "350981206": "松罗乡",
        "350981501": "福安畲族开发区",
        "350981502": "湾坞工业集中区"
    },
    "350982": {
        "350982001": "桐山街道",
        "350982002": "桐城街道",
        "350982003": "山前街道",
        "350982100": "贯岭镇",
        "350982101": "前岐镇",
        "350982102": "沙埕镇",
        "350982103": "店下镇",
        "350982104": "太姥山镇",
        "350982105": "磻溪镇",
        "350982106": "白琳镇",
        "350982107": "点头镇",
        "350982108": "管阳镇",
        "350982109": "嵛山镇",
        "350982200": "硖门畲族乡",
        "350982201": "叠石乡",
        "350982202": "佳阳乡",
        "350982500": "龙安开发区"
    },
    "360000": {
        "360100": "南昌市",
        "360200": "景德镇市",
        "360300": "萍乡市",
        "360400": "九江市",
        "360500": "新余市",
        "360600": "鹰潭市",
        "360700": "赣州市",
        "360800": "吉安市",
        "360900": "宜春市",
        "361000": "抚州市",
        "361100": "上饶市"
    },
    "360100": {
        "360102": "东湖区",
        "360103": "西湖区",
        "360104": "青云谱区",
        "360105": "湾里区",
        "360111": "青山湖区",
        "360112": "新建区",
        "360121": "南昌县",
        "360123": "安义县",
        "360124": "进贤县"
    },
    "360102": {
        "360102001": "公园街道",
        "360102002": "滕王阁街道",
        "360102003": "八一桥街道",
        "360102004": "百花洲街道",
        "360102005": "墩子塘街道",
        "360102006": "大院街道",
        "360102007": "豫章街道",
        "360102008": "董家窑街道",
        "360102009": "彭家桥街道",
        "360102050": "沙井街道（红谷滩新区）",
        "360102100": "扬子洲镇",
        "360102400": "贤士湖管理处",
        "360102401": "扬农管理处",
        "360102450": "凤凰洲管理处（红谷滩新区）"
    },
    "360103": {
        "360103001": "南浦街道",
        "360103002": "朝阳洲街道",
        "360103004": "广润门街道",
        "360103005": "西湖街道",
        "360103006": "系马桩街道",
        "360103007": "绳金塔街道",
        "360103009": "丁公路街道",
        "360103010": "南站街道",
        "360103011": "桃源街道",
        "360103012": "十字街街道",
        "360103013": "朝农街道",
        "360103101": "桃花镇"
    },
    "360104": {
        "360104002": "洪都街道",
        "360104006": "京山街道",
        "360104007": "三家店街道",
        "360104008": "岱山街道",
        "360104009": "徐家坊街道",
        "360104100": "青云谱镇",
        "360104400": "南昌昌南工业园"
    },
    "360105": {
        "360105001": "站前街道",
        "360105002": "幸福街道",
        "360105100": "招贤镇",
        "360105101": "梅岭镇",
        "360105102": "罗亭镇",
        "360105103": "太平镇"
    },
    "360111": {
        "360111001": "青山路街道",
        "360111003": "上海路街道",
        "360111004": "南钢街道",
        "360111005": "站东街道",
        "360111100": "京东镇",
        "360111103": "罗家镇",
        "360111104": "湖坊镇",
        "360111105": "塘山镇",
        "360111180": "蛟桥镇（南昌经济技术开发区）",
        "360111400": "南昌昌东工业区",
        "360111481": "白水湖管理处（南昌经济技术开发区）",
        "360111482": "冠山管理处（南昌经济技术开发区）",
        "360111490": "艾溪湖管理处（南昌高新开发区）",
        "360111493": "湖西新建区（南昌高新开发区）"
    },
    "360112": {
        "360112100": "长堎镇",
        "360112101": "望城镇",
        "360112102": "生米镇（红谷滩新区）",
        "360112103": "西山镇",
        "360112104": "石岗镇",
        "360112105": "松湖镇",
        "360112106": "樵舍镇",
        "360112107": "乐化镇",
        "360112108": "溪霞镇",
        "360112109": "象山镇",
        "360112110": "石埠镇",
        "360112111": "联圩镇",
        "360112112": "流湖镇",
        "360112202": "厚田乡",
        "360112204": "金桥乡",
        "360112205": "铁河乡",
        "360112206": "大塘坪乡",
        "360112207": "昌邑乡",
        "360112208": "南矶乡",
        "360112400": "恒湖垦殖场",
        "360112402": "成新实业有限公司",
        "360112403": "朱港实业有限公司",
        "360112404": "江西长堎外商投资工业区",
        "360112450": "红角洲管理处（红谷滩新区）",
        "360112451": "九龙湖管理处（红谷滩新区）",
        "360112460": "江西桑海集团有限公司（南昌经济技术开发区）",
        "360112461": "新祺周管理处（南昌经济技术开发区）",
        "360112502": "新丰垦殖场"
    },
    "360121": {
        "360121001": "八月湖街道",
        "360121100": "莲塘镇",
        "360121101": "向塘镇",
        "360121102": "三江镇",
        "360121103": "塘南镇",
        "360121104": "幽兰镇",
        "360121105": "蒋巷镇",
        "360121106": "武阳镇",
        "360121107": "冈上镇",
        "360121108": "广福镇",
        "360121191": "昌东镇（南昌高新开发区）",
        "360121192": "麻丘镇（南昌高新开发区）",
        "360121200": "泾口乡",
        "360121201": "南新乡",
        "360121202": "塔城乡",
        "360121203": "黄马乡",
        "360121204": "富山乡",
        "360121205": "东新乡",
        "360121206": "八一乡",
        "360121403": "小蓝经济开发区",
        "360121471": "南昌县银三角管理委员会",
        "360121501": "鲤鱼洲管理处（南昌高新开发区）",
        "360121572": "江西省良种繁殖场"
    },
    "360123": {
        "360123100": "龙津镇",
        "360123101": "万埠镇",
        "360123102": "石鼻镇",
        "360123103": "鼎湖镇",
        "360123104": "长埠镇",
        "360123105": "东阳镇",
        "360123106": "黄洲镇",
        "360123200": "乔乐乡",
        "360123201": "长均乡",
        "360123202": "新民乡",
        "360123402": "江西安义工业园区",
        "360123501": "国营万埠垦殖场"
    },
    "360124": {
        "360124100": "民和镇",
        "360124101": "李渡镇",
        "360124102": "温圳镇",
        "360124103": "文港镇",
        "360124104": "梅庄镇",
        "360124105": "张公镇",
        "360124106": "罗溪镇",
        "360124107": "架桥镇",
        "360124108": "前坊镇",
        "360124200": "三里乡",
        "360124201": "二塘乡",
        "360124202": "钟陵乡",
        "360124203": "池溪乡",
        "360124204": "南台乡",
        "360124205": "三阳集乡",
        "360124206": "七里乡",
        "360124207": "下埠集乡",
        "360124208": "衙前乡",
        "360124209": "白圩乡",
        "360124210": "长山晏乡",
        "360124211": "泉岭乡",
        "360124500": "五里垦殖场"
    },
    "360200": {"360202": "昌江区", "360203": "珠山区", "360222": "浮梁县", "360281": "乐平市"},
    "360202": {
        "360202001": "西郊街道",
        "360202002": "新枫街道",
        "360202101": "鲇鱼山镇",
        "360202102": "丽阳镇",
        "360202202": "荷塘乡",
        "360202290": "吕蒙乡",
        "360202451": "昌江化工园区",
        "360202452": "六零二所高科技园",
        "360202453": "昌江开发区",
        "360202500": "枫树山总场"
    },
    "360203": {
        "360203001": "石狮埠街道",
        "360203002": "新厂街道",
        "360203003": "里村街道",
        "360203004": "周路口街道",
        "360203005": "昌江街道",
        "360203006": "新村街道",
        "360203007": "珠山街道",
        "360203008": "太白园街道",
        "360203009": "昌河街道",
        "360203100": "竟成镇",
        "360203400": "高新技术开发区"
    },
    "360222": {
        "360222100": "浮梁镇",
        "360222101": "鹅湖镇",
        "360222102": "经公桥镇",
        "360222103": "蛟潭镇",
        "360222104": "湘湖镇",
        "360222105": "瑶里镇",
        "360222106": "洪源镇",
        "360222107": "寿安镇",
        "360222108": "三龙镇",
        "360222109": "峙滩镇",
        "360222200": "王港乡",
        "360222201": "庄湾乡",
        "360222203": "黄坛乡",
        "360222204": "兴田乡",
        "360222205": "江村乡",
        "360222207": "勒功乡",
        "360222208": "西湖乡",
        "360222209": "罗家桥乡",
        "360222400": "浮梁工业园区",
        "360222500": "罗家垦殖场"
    },
    "360281": {
        "360281001": "洎阳街道",
        "360281002": "塔山街道",
        "360281100": "镇桥镇",
        "360281101": "乐港镇",
        "360281102": "涌山镇",
        "360281103": "众埠镇",
        "360281104": "接渡镇",
        "360281105": "洪岩镇",
        "360281106": "礼林镇",
        "360281107": "后港镇",
        "360281108": "塔前镇",
        "360281109": "双田镇",
        "360281110": "临港镇",
        "360281111": "高家镇",
        "360281112": "名口镇",
        "360281113": "浯口镇",
        "360281114": "十里岗镇",
        "360281200": "鸬鹚乡",
        "360281400": "农业高新园",
        "360281401": "江西乐平工业园区"
    },
    "360300": {"360302": "安源区", "360313": "湘东区", "360321": "莲花县", "360322": "上栗县", "360323": "芦溪县"},
    "360302": {
        "360302001": "东大街街道",
        "360302002": "凤凰街街道",
        "360302003": "八一街街道",
        "360302004": "后埠街街道",
        "360302005": "丹江街街道",
        "360302006": "白源街街道",
        "360302100": "安源镇",
        "360302101": "高坑镇",
        "360302102": "五陂镇",
        "360302103": "青山镇",
        "360302400": "五陂下垦殖场",
        "360302401": "城郊管委会"
    },
    "360313": {
        "360313001": "峡山口街道",
        "360313101": "湘东镇",
        "360313102": "荷尧镇",
        "360313103": "老关镇",
        "360313104": "腊市镇",
        "360313105": "下埠镇",
        "360313106": "排上镇",
        "360313107": "东桥镇",
        "360313108": "麻山镇",
        "360313200": "广寒寨乡",
        "360313201": "白竺乡"
    },
    "360321": {
        "360321101": "琴亭镇",
        "360321102": "路口镇",
        "360321103": "良坊镇",
        "360321104": "升坊镇",
        "360321105": "坊楼镇",
        "360321200": "闪石乡",
        "360321201": "湖上乡",
        "360321202": "三板桥乡",
        "360321203": "神泉乡",
        "360321204": "六市乡",
        "360321205": "高洲乡",
        "360321206": "荷塘乡",
        "360321207": "南岭乡"
    },
    "360322": {
        "360322101": "上栗镇",
        "360322102": "桐木镇",
        "360322103": "金山镇",
        "360322104": "福田镇",
        "360322105": "彭高镇",
        "360322106": "赤山镇",
        "360322200": "鸡冠山乡",
        "360322201": "长平乡",
        "360322202": "东源乡",
        "360322203": "杨岐乡"
    },
    "360323": {
        "360323101": "芦溪镇",
        "360323102": "宣风镇",
        "360323103": "上埠镇",
        "360323104": "南坑镇",
        "360323105": "银河镇",
        "360323200": "源南乡",
        "360323201": "长丰乡",
        "360323202": "张佳坊乡",
        "360323203": "新泉乡",
        "360323204": "万龙山乡"
    },
    "360400": {
        "360402": "濂溪区",
        "360403": "浔阳区",
        "360404": "柴桑区",
        "360423": "武宁县",
        "360424": "修水县",
        "360425": "永修县",
        "360426": "德安县",
        "360428": "都昌县",
        "360429": "湖口县",
        "360430": "彭泽县",
        "360481": "瑞昌市",
        "360482": "共青城市",
        "360483": "庐山市"
    },
    "360402": {
        "360402001": "十里街道",
        "360402002": "五里街道",
        "360402090": "七里湖街道（九江经济技术开发区）",
        "360402100": "姑塘镇",
        "360402101": "威家镇",
        "360402102": "新港镇",
        "360402103": "莲花镇",
        "360402105": "赛阳镇",
        "360402200": "虞家河乡",
        "360402201": "高垅乡",
        "360402471": "庐山林科所",
        "360402472": "庐山生态文化新城"
    },
    "360403": {
        "360403001": "甘棠街道",
        "360403002": "湓浦街道",
        "360403003": "人民路街道",
        "360403004": "白水湖街道",
        "360403005": "金鸡坡街道",
        "360403091": "向阳街道（九江经济技术开发区）",
        "360403092": "滨兴街道（九江经济技术开发区）",
        "360403400": "茅山头企业集团（九江经济技术开发区）"
    },
    "360404": {
        "360404001": "沙河街道",
        "360404101": "马回岭镇",
        "360404102": "江洲镇",
        "360404103": "城子镇",
        "360404104": "港口街镇",
        "360404105": "新合镇",
        "360404106": "狮子镇",
        "360404200": "永安乡",
        "360404201": "涌泉乡",
        "360404202": "新塘乡",
        "360404203": "城门乡",
        "360404204": "岷山乡",
        "360404403": "沙河经济技术开发区",
        "360404501": "新洲垦殖场",
        "360404502": "赛城湖水产场",
        "360404503": "岷山林场"
    },
    "360423": {
        "360423001": "豫宁街道",
        "360423100": "新宁镇",
        "360423101": "泉口镇",
        "360423102": "鲁溪镇",
        "360423103": "船滩镇",
        "360423104": "澧溪镇",
        "360423105": "罗坪镇",
        "360423106": "石门楼镇",
        "360423107": "宋溪镇",
        "360423200": "大洞乡",
        "360423201": "横路乡",
        "360423202": "官莲乡",
        "360423203": "巾口乡",
        "360423204": "东林乡",
        "360423205": "上汤乡",
        "360423206": "甫田乡",
        "360423207": "清江乡",
        "360423208": "石渡乡",
        "360423209": "杨洲乡",
        "360423210": "罗溪乡",
        "360423500": "万福经济技术开发区管委会"
    },
    "360424": {
        "360424100": "义宁镇",
        "360424101": "白岭镇",
        "360424102": "全丰镇",
        "360424103": "古市镇",
        "360424104": "大桥镇",
        "360424105": "渣津镇",
        "360424106": "马坳镇",
        "360424107": "杭口镇",
        "360424108": "港口镇",
        "360424109": "溪口镇",
        "360424110": "西港镇",
        "360424111": "山口镇",
        "360424112": "黄沙镇",
        "360424113": "黄港镇",
        "360424114": "何市镇",
        "360424115": "上奉镇",
        "360424116": "四都镇",
        "360424117": "太阳升镇",
        "360424118": "宁州镇",
        "360424200": "路口乡",
        "360424201": "黄龙乡",
        "360424202": "上衫乡",
        "360424203": "余段乡",
        "360424204": "水源乡",
        "360424205": "石坳乡",
        "360424206": "东港乡",
        "360424207": "上杭乡",
        "360424209": "新湾乡",
        "360424210": "布甲乡",
        "360424211": "漫江乡",
        "360424212": "复原乡",
        "360424213": "竹坪乡",
        "360424214": "征村乡",
        "360424215": "庙岭乡",
        "360424216": "黄坳乡",
        "360424217": "大椿乡"
    },
    "360425": {
        "360425100": "涂埠镇（赣江新区永修组团）",
        "360425101": "吴城镇",
        "360425102": "三溪桥镇",
        "360425103": "虬津镇",
        "360425104": "艾城镇",
        "360425105": "滩溪镇",
        "360425106": "白槎镇",
        "360425107": "梅棠镇",
        "360425108": "燕坊镇",
        "360425109": "马口镇",
        "360425110": "柘林镇",
        "360425200": "三角乡",
        "360425201": "九合乡",
        "360425202": "立新乡",
        "360425203": "江上乡",
        "360425404": "江西永修云山经济技术开发区（赣江新区永修组团）",
        "360425500": "云山企业集团",
        "360425501": "恒丰企业集团",
        "360425502": "八角岭垦殖场",
        "360425503": "永丰垦殖场"
    },
    "360426": {
        "360426100": "蒲亭镇",
        "360426101": "聂桥镇",
        "360426102": "车桥镇",
        "360426103": "丰林镇",
        "360426104": "吴山镇",
        "360426200": "宝塔乡",
        "360426201": "河东乡",
        "360426202": "高塘乡",
        "360426203": "林泉乡",
        "360426205": "磨溪乡",
        "360426206": "爱民乡",
        "360426207": "邹桥乡",
        "360426208": "塘山乡",
        "360426500": "彭山林场",
        "360426501": "园艺场"
    },
    "360428": {
        "360428100": "都昌镇",
        "360428101": "周溪镇",
        "360428102": "三汊港镇",
        "360428103": "中馆镇",
        "360428104": "大沙镇",
        "360428105": "万户镇",
        "360428106": "南峰镇",
        "360428107": "土塘镇",
        "360428108": "大港镇",
        "360428109": "蔡岭镇",
        "360428110": "徐埠镇",
        "360428111": "左里镇",
        "360428200": "和合乡",
        "360428201": "阳峰乡",
        "360428202": "西源乡",
        "360428203": "芗溪乡",
        "360428204": "狮山乡",
        "360428205": "鸣山乡",
        "360428206": "春桥乡",
        "360428207": "苏山乡",
        "360428208": "多宝乡",
        "360428209": "汪墩乡",
        "360428210": "北山乡",
        "360428211": "大树乡",
        "360428400": "江西省对外经济技术合作蔡岭示范区",
        "360428500": "都昌县良种场"
    },
    "360429": {
        "360429100": "双钟镇",
        "360429101": "流泗镇",
        "360429102": "马影镇",
        "360429103": "武山镇",
        "360429104": "城山镇",
        "360429105": "均桥镇",
        "360429200": "大垅乡",
        "360429201": "凰村乡",
        "360429202": "张青乡",
        "360429204": "付垅乡",
        "360429205": "舜德乡",
        "360429206": "流芳乡",
        "360429500": "南北港水产场",
        "360429501": "武山垦殖场"
    },
    "360430": {
        "360430100": "龙城镇",
        "360430101": "棉船镇",
        "360430102": "马垱镇",
        "360430103": "芙蓉墩镇",
        "360430104": "定山镇",
        "360430105": "天红镇",
        "360430106": "杨梓镇",
        "360430107": "东升镇",
        "360430108": "瀼溪镇",
        "360430109": "黄花镇",
        "360430200": "太平关乡",
        "360430201": "黄岭乡",
        "360430202": "浩山乡",
        "360430403": "建筑材料厂",
        "360430404": "水产养殖场",
        "360430500": "太泊湖农业综合开发区",
        "360430501": "上十岭综合垦殖场",
        "360430502": "芙蓉农场",
        "360430503": "棉科所",
        "360430504": "棉花原种场国营分场",
        "360430505": "太泊湖水产养殖场",
        "360430506": "黄乐林场"
    },
    "360481": {
        "360481001": "湓城街道",
        "360481002": "桂林街道",
        "360481100": "码头镇",
        "360481101": "白杨镇",
        "360481102": "南义镇",
        "360481103": "横港镇",
        "360481104": "范镇",
        "360481105": "肇陈镇",
        "360481106": "高丰镇",
        "360481107": "夏畈镇",
        "360481200": "乐园乡",
        "360481201": "洪一乡",
        "360481202": "花园乡",
        "360481203": "洪下乡",
        "360481204": "武蛟乡",
        "360481205": "横立山乡",
        "360481206": "黄金乡",
        "360481207": "南阳乡",
        "360481500": "赛湖农场",
        "360481501": "青山林场",
        "360481502": "瑞昌市大德山林场"
    },
    "360482": {
        "360482001": "茶山街道（赣江新区共青组团）",
        "360482100": "甘露镇",
        "360482101": "江益镇",
        "360482200": "金湖乡",
        "360482201": "苏家垱乡",
        "360482202": "泽泉乡"
    },
    "360483": {
        "360483100": "南康镇",
        "360483101": "白鹿镇",
        "360483102": "温泉镇",
        "360483103": "星子镇",
        "360483104": "华林镇",
        "360483105": "蛟塘镇",
        "360483106": "横塘镇",
        "360483107": "牯岭镇",
        "360483108": "海会镇",
        "360483200": "蓼南乡",
        "360483401": "东牯山林场",
        "360483500": "沙湖山管理处"
    },
    "360500": {"360502": "渝水区", "360521": "分宜县"},
    "360502": {
        "360502001": "城南街道",
        "360502002": "城北街道",
        "360502003": "袁河街道",
        "360502004": "新钢街道",
        "360502100": "水北镇",
        "360502101": "下村镇",
        "360502102": "良山镇",
        "360502103": "罗坊镇",
        "360502104": "姚圩镇",
        "360502105": "珠珊镇",
        "360502170": "河下镇",
        "360502180": "观巢镇",
        "360502181": "欧里镇",
        "360502190": "水西镇",
        "360502200": "鹄山乡",
        "360502201": "人和乡",
        "360502202": "界水乡",
        "360502203": "南安乡",
        "360502204": "新溪乡",
        "360502271": "九龙山乡",
        "360502401": "仙来"
    },
    "360521": {
        "360521001": "钤东街道",
        "360521100": "分宜镇",
        "360521101": "杨桥镇",
        "360521102": "湖泽镇",
        "360521103": "双林镇",
        "360521104": "钤山镇",
        "360521105": "洋江镇",
        "360521106": "凤阳镇",
        "360521201": "洞村乡",
        "360521202": "高岚乡",
        "360521203": "操场乡",
        "360521405": "分宜县工业园管理委员会",
        "360521500": "分宜县芳山林场",
        "360521501": "年珠林场",
        "360521502": "山下林场",
        "360521503": "上村林场",
        "360521504": "长埠林场",
        "360521505": "东坑林场"
    },
    "360600": {"360602": "月湖区", "360603": "余江区", "360681": "贵溪市"},
    "360602": {
        "360602001": "江边街道",
        "360602002": "交通街道",
        "360602003": "东湖街道",
        "360602004": "梅园街道",
        "360602005": "四青街道",
        "360602006": "白露街道",
        "360602100": "童家镇",
        "360602200": "夏埠乡"
    },
    "360603": {
        "360603100": "邓埠镇",
        "360603101": "锦江镇",
        "360603102": "潢溪镇",
        "360603103": "中童镇",
        "360603104": "马荃镇",
        "360603105": "画桥镇",
        "360603106": "春涛镇",
        "360603200": "平定乡",
        "360603202": "杨溪乡",
        "360603203": "洪湖乡",
        "360603204": "黄庄乡",
        "360603205": "刘家站乡",
        "360603408": "余江县工业园区",
        "360603409": "鹰潭市龙岗新区",
        "360603501": "高公寨营林场",
        "360603503": "邓家埠水稻原种场",
        "360603504": "余江县水产场",
        "360603505": "塘潮源林场",
        "360603506": "张公桥农场",
        "360603507": "青年综合垦殖场",
        "360603508": "大桥农场"
    },
    "360681": {
        "360681001": "花园街道",
        "360681002": "雄石街道",
        "360681003": "东门街道",
        "360681100": "泗沥镇",
        "360681101": "河潭镇",
        "360681102": "周坊镇",
        "360681103": "鸿塘镇",
        "360681104": "志光镇",
        "360681105": "流口镇",
        "360681106": "罗河镇",
        "360681107": "金屯镇",
        "360681108": "塘湾镇",
        "360681109": "文坊镇",
        "360681110": "冷水镇",
        "360681111": "滨江镇",
        "360681112": "天禄镇",
        "360681113": "雷溪镇",
        "360681190": "龙虎山镇（龙虎山风景旅游区管委会）",
        "360681191": "上清镇（龙虎山风景旅游区管委会）",
        "360681201": "白田乡",
        "360681203": "彭湾乡",
        "360681204": "樟坪畲族乡",
        "360681205": "耳口乡",
        "360681407": "贵溪市工业园区",
        "360681408": "江北",
        "360681500": "塔桥园艺场",
        "360681501": "河潭埠垦殖场",
        "360681502": "西窑林场",
        "360681503": "双圳林场",
        "360681504": "耳口林场",
        "360681505": "冷水林场",
        "360681506": "三县岭林场",
        "360681592": "上清林场（龙虎山风景旅游区管委会）"
    },
    "360700": {
        "360702": "章贡区",
        "360703": "南康区",
        "360704": "赣县区",
        "360722": "信丰县",
        "360723": "大余县",
        "360724": "上犹县",
        "360725": "崇义县",
        "360726": "安远县",
        "360727": "龙南县",
        "360728": "定南县",
        "360729": "全南县",
        "360730": "宁都县",
        "360731": "于都县",
        "360732": "兴国县",
        "360733": "会昌县",
        "360734": "寻乌县",
        "360735": "石城县",
        "360781": "瑞金市"
    },
    "360702": {
        "360702001": "解放街道",
        "360702002": "赣江街道",
        "360702003": "南外街道",
        "360702004": "东外街道",
        "360702091": "黄金岭街道（赣州经济技术开发区）",
        "360702100": "沙石镇",
        "360702101": "水东镇",
        "360702102": "水南镇",
        "360702103": "湖边镇（赣州经济技术开发区）",
        "360702104": "沙河镇",
        "360702105": "水西镇",
        "360702190": "蟠龙镇（赣州经济技术开发区）",
        "360702191": "潭口镇（赣州蓉江新区）",
        "360702192": "潭东镇（赣州蓉江新区）",
        "360702400": "赣州经济技术开发区",
        "360702401": "沙河工业园"
    },
    "360703": {
        "360703001": "蓉江街道",
        "360703002": "东山街道",
        "360703100": "唐江镇",
        "360703101": "凤岗镇",
        "360703103": "龙岭镇",
        "360703104": "龙回镇",
        "360703105": "镜坝镇",
        "360703106": "横市镇",
        "360703200": "浮石乡",
        "360703201": "赤土畲族乡",
        "360703202": "横寨乡",
        "360703203": "朱坊乡",
        "360703204": "太窝乡",
        "360703205": "三江乡",
        "360703206": "龙华乡",
        "360703207": "十八塘乡",
        "360703208": "麻双乡",
        "360703209": "大坪乡",
        "360703210": "坪市乡",
        "360703211": "隆木乡",
        "360703400": "南康市工业园"
    },
    "360704": {
        "360704100": "梅林镇",
        "360704101": "王母渡镇",
        "360704102": "沙地镇",
        "360704103": "江口镇",
        "360704104": "田村镇",
        "360704105": "南塘镇",
        "360704106": "茅店镇",
        "360704107": "吉埠镇",
        "360704108": "五云镇",
        "360704109": "湖江镇",
        "360704110": "储潭镇",
        "360704111": "韩坊镇",
        "360704201": "阳埠乡",
        "360704202": "大埠乡",
        "360704203": "长洛乡",
        "360704204": "大田乡",
        "360704207": "石芫乡",
        "360704208": "三溪乡",
        "360704209": "白鹭乡",
        "360704400": "江西赣县经济开发区"
    },
    "360722": {
        "360722100": "嘉定镇",
        "360722101": "大塘埠镇",
        "360722102": "古陂镇",
        "360722103": "大桥镇",
        "360722104": "新田镇",
        "360722105": "安西镇",
        "360722106": "小江镇",
        "360722107": "铁石口镇",
        "360722108": "大阿镇",
        "360722109": "油山镇",
        "360722110": "小河镇",
        "360722111": "西牛镇",
        "360722112": "正平镇",
        "360722200": "虎山乡",
        "360722201": "崇仙乡",
        "360722202": "万隆乡",
        "360722400": "信丰县工业园"
    },
    "360723": {
        "360723100": "南安镇",
        "360723101": "新城镇",
        "360723102": "樟斗镇",
        "360723103": "池江镇",
        "360723104": "青龙镇",
        "360723105": "左拔镇",
        "360723106": "黄龙镇",
        "360723107": "吉村镇",
        "360723200": "浮江乡",
        "360723201": "河洞乡",
        "360723202": "内良乡",
        "360723400": "新世纪工业园"
    },
    "360724": {
        "360724100": "东山镇",
        "360724101": "陡水镇",
        "360724102": "社溪镇",
        "360724103": "营前镇",
        "360724104": "黄埠镇",
        "360724105": "寺下镇",
        "360724200": "梅水乡",
        "360724201": "油石乡",
        "360724202": "安和乡",
        "360724204": "双溪乡",
        "360724205": "水岩乡",
        "360724206": "平富乡",
        "360724207": "五指峰乡",
        "360724208": "紫阳乡",
        "360724400": "上犹县黄埠工业区"
    },
    "360725": {
        "360725100": "横水镇",
        "360725101": "扬眉镇",
        "360725102": "过埠镇",
        "360725103": "铅厂镇",
        "360725104": "长龙镇",
        "360725105": "关田镇",
        "360725200": "龙勾乡",
        "360725201": "杰坝乡",
        "360725202": "金坑乡",
        "360725203": "思顺乡",
        "360725204": "麟潭乡",
        "360725205": "上堡乡",
        "360725206": "聂都乡",
        "360725207": "文英乡",
        "360725208": "乐洞乡",
        "360725209": "丰州乡"
    },
    "360726": {
        "360726100": "欣山镇",
        "360726101": "孔田镇",
        "360726102": "版石镇",
        "360726103": "天心镇",
        "360726104": "龙布镇",
        "360726105": "鹤子镇",
        "360726106": "三百山镇",
        "360726107": "车头镇",
        "360726200": "镇岗乡",
        "360726201": "凤山乡",
        "360726202": "新龙乡",
        "360726203": "蔡坊乡",
        "360726204": "重石乡",
        "360726205": "长沙乡",
        "360726206": "浮槎乡",
        "360726207": "双芫乡",
        "360726208": "塘村乡",
        "360726209": "高云山乡",
        "360726400": "九龙工业园"
    },
    "360727": {
        "360727100": "龙南镇",
        "360727101": "武当镇",
        "360727102": "杨村镇",
        "360727103": "汶龙镇",
        "360727104": "程龙镇",
        "360727105": "关西镇",
        "360727106": "里仁镇",
        "360727107": "渡江镇",
        "360727108": "九连山镇",
        "360727200": "桃江乡",
        "360727201": "东江乡",
        "360727202": "临塘乡",
        "360727203": "南亨乡",
        "360727204": "夹湖乡",
        "360727400": "江西龙南经济技术开发区",
        "360727500": "安基山林场"
    },
    "360728": {
        "360728100": "历市镇",
        "360728101": "岿美山镇",
        "360728102": "老城镇",
        "360728103": "天九镇",
        "360728104": "龙塘镇",
        "360728105": "岭北镇",
        "360728106": "鹅公镇",
        "360728400": "定南县工业园"
    },
    "360729": {
        "360729100": "城厢镇",
        "360729101": "大吉山镇",
        "360729102": "陂头镇",
        "360729103": "金龙镇",
        "360729104": "南迳镇",
        "360729105": "龙源坝镇",
        "360729200": "中寨乡",
        "360729201": "社迳乡",
        "360729202": "龙下乡",
        "360729402": "全南县工业园"
    },
    "360730": {
        "360730100": "梅江镇",
        "360730101": "青塘镇",
        "360730102": "长胜镇",
        "360730103": "黄陂镇",
        "360730104": "固村镇",
        "360730105": "赖村镇",
        "360730106": "石上镇",
        "360730107": "东山坝镇",
        "360730108": "洛口镇",
        "360730109": "小布镇",
        "360730110": "黄石镇",
        "360730111": "田头镇",
        "360730200": "竹笮乡",
        "360730201": "对坊乡",
        "360730202": "固厚乡",
        "360730203": "田埠乡",
        "360730204": "会同乡",
        "360730205": "湛田乡",
        "360730206": "安福乡",
        "360730207": "东韶乡",
        "360730208": "肖田乡",
        "360730209": "钓峰乡",
        "360730210": "大沽乡",
        "360730211": "蔡江乡",
        "360730400": "宁都县水东工业园"
    },
    "360731": {
        "360731100": "贡江镇",
        "360731101": "铁山垅镇",
        "360731102": "盘古山镇",
        "360731103": "禾丰镇",
        "360731104": "祁禄山镇",
        "360731105": "梓山镇",
        "360731106": "银坑镇",
        "360731107": "岭背镇",
        "360731108": "罗坳镇",
        "360731200": "罗江乡",
        "360731201": "小溪乡",
        "360731202": "利村乡",
        "360731203": "新陂乡",
        "360731204": "靖石乡",
        "360731205": "黄麟乡",
        "360731206": "沙心乡",
        "360731207": "宽田乡",
        "360731208": "葛坳乡",
        "360731209": "桥头乡",
        "360731210": "马安乡",
        "360731211": "仙下乡",
        "360731212": "车溪乡",
        "360731213": "段屋乡",
        "360731400": "于都工业园"
    },
    "360732": {
        "360732100": "潋江镇",
        "360732101": "江背镇",
        "360732102": "古龙冈镇",
        "360732103": "梅窖镇",
        "360732104": "高兴镇",
        "360732105": "良村镇",
        "360732106": "龙口镇",
        "360732200": "兴江乡",
        "360732201": "樟木乡",
        "360732202": "东村乡",
        "360732203": "兴莲乡",
        "360732204": "杰村乡",
        "360732205": "社富乡",
        "360732206": "埠头乡",
        "360732207": "永丰乡",
        "360732208": "隆坪乡",
        "360732209": "均村乡",
        "360732210": "茶园乡",
        "360732211": "崇贤乡",
        "360732212": "枫边乡",
        "360732213": "南坑乡",
        "360732214": "城岗乡",
        "360732215": "方太乡",
        "360732216": "鼎龙乡",
        "360732217": "长冈乡",
        "360732400": "兴国县工业园"
    },
    "360733": {
        "360733100": "文武坝镇",
        "360733101": "筠门岭镇",
        "360733102": "西江镇",
        "360733103": "周田镇",
        "360733104": "麻州镇",
        "360733105": "庄口镇",
        "360733200": "清溪乡",
        "360733201": "右水乡",
        "360733202": "高排乡",
        "360733203": "晓龙乡",
        "360733204": "珠兰乡",
        "360733205": "洞头乡",
        "360733206": "中村乡",
        "360733207": "站塘乡",
        "360733208": "永隆乡",
        "360733209": "富城乡",
        "360733210": "小密乡",
        "360733211": "庄埠乡",
        "360733212": "白鹅乡",
        "360733400": "会昌县燕子窝工业园"
    },
    "360734": {
        "360734100": "长宁镇",
        "360734101": "晨光镇",
        "360734102": "留车镇",
        "360734103": "南桥镇",
        "360734104": "吉潭镇",
        "360734105": "澄江镇",
        "360734106": "桂竹帽镇",
        "360734200": "文峰乡",
        "360734201": "三标乡",
        "360734202": "菖蒲乡",
        "360734203": "龙廷乡",
        "360734204": "丹溪乡",
        "360734205": "项山乡",
        "360734206": "水源乡",
        "360734207": "罗珊乡"
    },
    "360735": {
        "360735100": "琴江镇",
        "360735101": "小松镇",
        "360735102": "屏山镇",
        "360735103": "横江镇",
        "360735104": "高田镇",
        "360735105": "赣江源镇",
        "360735200": "木兰乡",
        "360735201": "丰山乡",
        "360735202": "大由乡",
        "360735203": "龙岗乡",
        "360735204": "珠坑乡"
    },
    "360781": {
        "360781100": "象湖镇",
        "360781101": "瑞林镇",
        "360781102": "壬田镇",
        "360781103": "九堡镇",
        "360781104": "沙洲坝镇",
        "360781105": "谢坊镇",
        "360781106": "武阳镇",
        "360781200": "叶坪乡",
        "360781201": "丁陂乡",
        "360781202": "大柏地乡",
        "360781203": "岗面乡",
        "360781204": "日东乡",
        "360781205": "万田乡",
        "360781206": "黄柏乡",
        "360781207": "云石山乡",
        "360781208": "泽覃乡",
        "360781209": "拔英乡",
        "360781400": "江西瑞金经济开发区"
    },
    "360800": {
        "360802": "吉州区",
        "360803": "青原区",
        "360821": "吉安县",
        "360822": "吉水县",
        "360823": "峡江县",
        "360824": "新干县",
        "360825": "永丰县",
        "360826": "泰和县",
        "360827": "遂川县",
        "360828": "万安县",
        "360829": "安福县",
        "360830": "永新县",
        "360881": "井冈山市"
    },
    "360802": {
        "360802001": "古南街道",
        "360802002": "永叔街道",
        "360802003": "文山街道",
        "360802004": "习溪桥街道",
        "360802005": "北门街道",
        "360802006": "白塘街道",
        "360802007": "禾埠街道",
        "360802100": "兴桥镇",
        "360802101": "樟山镇",
        "360802102": "长塘镇",
        "360802103": "曲濑镇",
        "360802400": "吉州区工业园小区"
    },
    "360803": {
        "360803001": "河东街道",
        "360803002": "滨江街道",
        "360803100": "天玉镇",
        "360803101": "值夏镇",
        "360803102": "新圩镇",
        "360803103": "富滩镇",
        "360803104": "富田镇",
        "360803105": "文陂镇",
        "360803201": "东固畲族少数民族乡",
        "360803400": "青原区河东经济开发区",
        "360803500": "东固垦殖场"
    },
    "360821": {
        "360821001": "高新街道",
        "360821002": "金鸡湖街道",
        "360821100": "敦厚镇",
        "360821101": "永阳镇",
        "360821102": "天河镇",
        "360821103": "横江镇",
        "360821104": "固江镇",
        "360821105": "万福镇",
        "360821106": "永和镇",
        "360821107": "桐坪镇",
        "360821108": "凤凰镇",
        "360821109": "油田镇",
        "360821110": "敖城镇",
        "360821111": "梅塘镇",
        "360821112": "浬田镇",
        "360821200": "北源乡",
        "360821201": "大冲乡",
        "360821204": "登龙乡",
        "360821205": "安塘乡",
        "360821206": "官田乡",
        "360821207": "指阳乡",
        "360821400": "吉安高新技术产业园区",
        "360821401": "井冈山经济技术开发区"
    },
    "360822": {
        "360822100": "文峰镇",
        "360822101": "阜田镇",
        "360822102": "盘谷镇",
        "360822103": "枫江镇",
        "360822104": "黄桥镇",
        "360822105": "金滩镇",
        "360822106": "八都镇",
        "360822107": "双村镇",
        "360822108": "醪桥镇",
        "360822109": "螺田镇",
        "360822110": "白沙镇",
        "360822111": "白水镇",
        "360822112": "丁江镇",
        "360822113": "乌江镇",
        "360822114": "水南镇",
        "360822200": "尚贤乡",
        "360822201": "水田乡",
        "360822202": "冠山乡",
        "360822400": "吉水县工业园区"
    },
    "360823": {
        "360823100": "水边镇",
        "360823101": "马埠镇",
        "360823102": "巴邱镇",
        "360823103": "仁和镇",
        "360823104": "砚溪镇",
        "360823105": "罗田镇",
        "360823200": "桐林乡",
        "360823201": "福民乡",
        "360823202": "戈坪乡",
        "360823203": "金江乡",
        "360823204": "金坪民族乡",
        "360823400": "峡江县工业园区"
    },
    "360824": {
        "360824001": "洋峰街道办",
        "360824100": "金川镇",
        "360824101": "三湖镇",
        "360824102": "大洋洲镇",
        "360824103": "七琴镇",
        "360824104": "麦斜镇",
        "360824105": "界埠镇",
        "360824106": "溧江镇",
        "360824201": "桃溪乡",
        "360824202": "城上乡",
        "360824203": "潭丘乡",
        "360824204": "神政桥乡",
        "360824205": "沂江乡",
        "360824207": "荷浦乡",
        "360824404": "新干工业园区",
        "360824405": "地质大队",
        "360824502": "黎山林场"
    },
    "360825": {
        "360825100": "恩江镇",
        "360825101": "坑田镇",
        "360825102": "沿陂镇",
        "360825103": "古县镇",
        "360825104": "瑶田镇",
        "360825105": "藤田镇",
        "360825106": "石马镇",
        "360825107": "沙溪镇",
        "360825200": "佐龙乡",
        "360825201": "八江乡",
        "360825202": "潭城乡",
        "360825203": "鹿冈乡",
        "360825204": "七都乡",
        "360825205": "陶塘乡",
        "360825206": "中村乡",
        "360825207": "上溪乡",
        "360825208": "潭头乡",
        "360825209": "三坊乡",
        "360825210": "上固乡",
        "360825211": "君埠乡",
        "360825212": "龙冈畲族乡",
        "360825403": "永丰县工业园区",
        "360825501": "罗铺垦殖场",
        "360825502": "官山林场"
    },
    "360826": {
        "360826100": "澄江镇",
        "360826101": "碧溪镇",
        "360826102": "桥头镇",
        "360826103": "禾市镇",
        "360826104": "螺溪镇",
        "360826105": "苏溪镇",
        "360826106": "马市镇",
        "360826107": "塘洲镇",
        "360826108": "冠朝镇",
        "360826109": "沙村镇",
        "360826110": "老营盘镇",
        "360826111": "小龙镇",
        "360826112": "灌溪镇",
        "360826113": "苑前镇",
        "360826114": "万合镇",
        "360826115": "沿溪镇",
        "360826200": "石山乡",
        "360826201": "南溪乡",
        "360826202": "上模乡",
        "360826203": "水槎乡",
        "360826204": "上圯乡",
        "360826205": "中龙乡",
        "360826402": "小龙矿区管委会",
        "360826403": "八一八矿区",
        "360826404": "井冈山机场",
        "360826405": "泰和县工业园区",
        "360826501": "武山垦殖场",
        "360826502": "综合垦殖场"
    },
    "360827": {
        "360827100": "泉江镇",
        "360827101": "雩田镇",
        "360827102": "碧洲镇",
        "360827103": "草林镇",
        "360827104": "堆子前镇",
        "360827105": "左安镇",
        "360827106": "高坪镇",
        "360827107": "大汾镇",
        "360827108": "衙前镇",
        "360827109": "禾源镇",
        "360827110": "汤湖镇",
        "360827111": "枚江镇",
        "360827200": "珠田乡",
        "360827201": "巾石乡",
        "360827202": "大坑乡",
        "360827204": "双桥乡",
        "360827205": "新江乡",
        "360827206": "五斗江乡",
        "360827207": "西溪乡",
        "360827208": "南江乡",
        "360827209": "黄坑乡",
        "360827210": "戴家埔乡",
        "360827211": "营盘圩乡",
        "360827402": "遂川县工业园区",
        "360827501": "五指峰林场",
        "360827502": "云岭林场"
    },
    "360828": {
        "360828100": "芙蓉镇",
        "360828101": "五丰镇",
        "360828102": "枧头镇",
        "360828103": "窑头镇",
        "360828104": "百嘉镇",
        "360828105": "高陂镇",
        "360828106": "潞田镇",
        "360828107": "沙坪镇",
        "360828108": "夏造镇",
        "360828200": "罗塘乡",
        "360828201": "弹前乡",
        "360828202": "武术乡",
        "360828203": "宝山乡",
        "360828204": "涧田乡",
        "360828205": "顺峰乡",
        "360828206": "韶口乡",
        "360828401": "万安县工业园",
        "360828501": "万安县麻源垦殖场"
    },
    "360829": {
        "360829100": "平都镇",
        "360829101": "浒坑镇",
        "360829102": "洲湖镇",
        "360829103": "横龙镇",
        "360829104": "洋溪镇",
        "360829105": "严田镇",
        "360829106": "枫田镇",
        "360829200": "竹江乡",
        "360829201": "瓜畲乡",
        "360829202": "钱山乡",
        "360829203": "赤谷乡",
        "360829204": "山庄乡",
        "360829205": "洋门乡",
        "360829206": "金田乡",
        "360829207": "彭坊乡",
        "360829208": "泰山乡",
        "360829209": "寮塘乡",
        "360829210": "甘洛乡",
        "360829211": "章庄乡",
        "360829400": "安福县工业园"
    },
    "360830": {
        "360830001": "三月坪街道",
        "360830100": "禾川镇",
        "360830101": "石桥镇",
        "360830102": "龙源口镇",
        "360830103": "浬田镇",
        "360830104": "龙门镇",
        "360830105": "沙市镇",
        "360830106": "文竹镇",
        "360830107": "埠前镇",
        "360830108": "怀忠镇",
        "360830109": "高桥楼镇",
        "360830200": "坳南乡",
        "360830201": "曲白乡",
        "360830202": "才丰乡",
        "360830203": "烟阁乡",
        "360830204": "在中乡",
        "360830205": "三湾乡",
        "360830206": "台岭乡",
        "360830207": "龙田乡",
        "360830208": "高溪乡",
        "360830209": "莲洲乡",
        "360830210": "高市乡",
        "360830211": "象形乡",
        "360830212": "芦溪乡",
        "360830400": "永新县工业园区"
    },
    "360881": {
        "360881002": "红星街道",
        "360881100": "厦坪镇",
        "360881101": "龙市镇",
        "360881102": "古城镇",
        "360881103": "新城镇",
        "360881104": "大陇镇",
        "360881105": "茨坪镇",
        "360881106": "拿山镇",
        "360881201": "黄垇乡",
        "360881202": "下七乡",
        "360881203": "长坪乡",
        "360881204": "坳里乡",
        "360881205": "鹅岭乡",
        "360881206": "柏露乡",
        "360881207": "茅坪乡",
        "360881208": "葛田乡",
        "360881209": "荷花乡",
        "360881210": "睦村乡",
        "360881211": "东上乡",
        "360881400": "井企集团",
        "360881401": "井冈山自然保护区",
        "360881403": "井冈山市工业园区",
        "360881501": "白石垦殖场"
    },
    "360900": {
        "360902": "袁州区",
        "360921": "奉新县",
        "360922": "万载县",
        "360923": "上高县",
        "360924": "宜丰县",
        "360925": "靖安县",
        "360926": "铜鼓县",
        "360981": "丰城市",
        "360982": "樟树市",
        "360983": "高安市"
    },
    "360902": {
        "360902001": "灵泉街道",
        "360902002": "秀江街道",
        "360902004": "湛郎街道",
        "360902005": "珠泉街道",
        "360902006": "化成街道",
        "360902007": "官园街道",
        "360902008": "下浦街道",
        "360902009": "凤凰街道",
        "360902010": "金园街道",
        "360902011": "新康府街道（油茶林场）",
        "360902100": "彬江镇",
        "360902101": "西村镇",
        "360902102": "金瑞镇",
        "360902103": "温汤镇",
        "360902104": "三阳镇",
        "360902105": "慈化镇",
        "360902106": "天台镇",
        "360902107": "洪塘镇",
        "360902108": "渥江镇",
        "360902109": "新坊镇",
        "360902110": "寨下镇",
        "360902111": "芦村镇",
        "360902112": "湖田镇",
        "360902113": "新田镇",
        "360902114": "南庙镇",
        "360902115": "竹亭镇",
        "360902116": "水江镇",
        "360902117": "辽市镇",
        "360902118": "洪江镇",
        "360902206": "楠木乡",
        "360902210": "柏木乡",
        "360902213": "飞剑潭乡",
        "360902400": "宜春经济开发区管理委员会",
        "360902401": "袁州区工业园区",
        "360902402": "宜春市明月山温泉风景名胜区管理局",
        "360902403": "宜春市宜阳新区管理委员会",
        "360902501": "西岭布果园场",
        "360902502": "明月山采育林场"
    },
    "360921": {
        "360921100": "冯川镇",
        "360921101": "赤岸镇",
        "360921102": "赤田镇",
        "360921103": "宋埠镇",
        "360921104": "干洲镇",
        "360921105": "澡下镇",
        "360921106": "会埠镇",
        "360921107": "罗市镇",
        "360921108": "上富镇",
        "360921109": "甘坊镇",
        "360921200": "仰山乡",
        "360921201": "澡溪乡",
        "360921202": "柳溪乡",
        "360921400": "奉新县工业园区",
        "360921500": "石溪",
        "360921501": "百丈山名胜风景区",
        "360921502": "干洲垦殖场",
        "360921503": "东风垦殖场",
        "360921504": "农牧渔良种场"
    },
    "360922": {
        "360922001": "康乐街道",
        "360922100": "株潭镇",
        "360922101": "黄茅镇",
        "360922102": "潭埠镇",
        "360922103": "双桥镇",
        "360922104": "高村镇",
        "360922105": "罗城镇",
        "360922106": "三兴镇",
        "360922107": "高城镇",
        "360922108": "白良镇",
        "360922200": "鹅峰乡",
        "360922201": "马步乡",
        "360922203": "赤兴乡",
        "360922204": "岭东乡",
        "360922205": "白水乡",
        "360922206": "仙源乡",
        "360922208": "茭湖乡",
        "360922400": "万载县工业园区"
    },
    "360923": {
        "360923001": "敖阳街道",
        "360923100": "田心镇",
        "360923101": "徐家渡镇",
        "360923102": "锦江镇",
        "360923103": "泗溪镇",
        "360923104": "翰堂镇",
        "360923105": "南港镇",
        "360923106": "敖山镇",
        "360923107": "新界埠镇",
        "360923108": "蒙山镇",
        "360923200": "芦洲乡",
        "360923201": "塔下乡",
        "360923203": "镇渡乡",
        "360923204": "野市乡",
        "360923206": "墨山乡",
        "360923400": "上高县工业园区",
        "360923501": "上甘山林场"
    },
    "360924": {
        "360924100": "新昌镇",
        "360924101": "澄塘镇",
        "360924102": "棠浦镇",
        "360924103": "新庄镇",
        "360924104": "潭山镇",
        "360924105": "芳溪镇",
        "360924106": "石市镇",
        "360924107": "黄岗镇",
        "360924200": "花桥乡",
        "360924201": "同安乡",
        "360924202": "天宝乡",
        "360924204": "桥西乡",
        "360924400": "宜丰县工业园区",
        "360924500": "车上林场",
        "360924501": "双峰林场",
        "360924502": "石花尖垦殖场",
        "360924503": "黄岗山垦殖场"
    },
    "360925": {
        "360925100": "双溪镇",
        "360925101": "仁首镇",
        "360925102": "宝峰镇",
        "360925103": "高湖镇",
        "360925104": "璪都镇",
        "360925200": "香田乡",
        "360925201": "水口乡",
        "360925202": "中源乡",
        "360925203": "罗湾乡",
        "360925205": "三爪仑乡",
        "360925206": "雷公尖乡",
        "360925400": "靖安县工业园区"
    },
    "360926": {
        "360926100": "永宁镇",
        "360926101": "温泉镇",
        "360926102": "棋坪镇",
        "360926103": "排埠镇",
        "360926104": "三都镇",
        "360926105": "大塅镇",
        "360926200": "高桥乡",
        "360926201": "港口乡",
        "360926202": "带溪乡",
        "360926404": "江西铜鼓工业园区",
        "360926500": "花山林场",
        "360926501": "大沩山林场",
        "360926502": "茶山林场",
        "360926503": "龙门林场"
    },
    "360981": {
        "360981002": "剑光街道",
        "360981006": "河洲街道",
        "360981007": "剑南街道",
        "360981008": "孙渡街道",
        "360981009": "尚庄街道",
        "360981010": "龙津洲街道",
        "360981101": "白土镇",
        "360981102": "袁渡镇",
        "360981104": "张巷镇",
        "360981105": "杜市镇",
        "360981106": "淘沙镇",
        "360981107": "秀市镇",
        "360981109": "洛市镇",
        "360981110": "铁路镇",
        "360981111": "丽村镇",
        "360981115": "董家镇",
        "360981116": "隍城镇",
        "360981119": "小港镇",
        "360981120": "石滩镇",
        "360981121": "桥东镇",
        "360981122": "荣塘镇",
        "360981123": "拖船镇",
        "360981124": "泉港镇",
        "360981125": "梅林镇",
        "360981126": "曲江镇",
        "360981127": "上塘镇",
        "360981201": "筱塘乡",
        "360981202": "段潭乡",
        "360981203": "蕉坑乡",
        "360981204": "石江乡",
        "360981205": "荷湖乡",
        "360981206": "湖塘乡",
        "360981207": "同田乡",
        "360981402": "丰城市工业园区"
    },
    "360982": {
        "360982001": "淦阳街道",
        "360982002": "鹿江街道",
        "360982003": "福城街道",
        "360982004": "大桥街道",
        "360982005": "张家山街道",
        "360982100": "临江镇",
        "360982101": "永泰镇",
        "360982102": "黄土岗镇",
        "360982103": "经楼镇",
        "360982104": "昌傅镇",
        "360982105": "店下镇",
        "360982106": "阁山镇",
        "360982107": "刘公庙镇",
        "360982108": "观上镇",
        "360982109": "义成镇",
        "360982200": "中洲乡",
        "360982201": "洲上乡",
        "360982202": "洋湖乡",
        "360982203": "吴城乡",
        "360982400": "樟树市工业园区",
        "360982401": "滨江新城",
        "360982500": "省双金园艺场"
    },
    "360983": {
        "360983001": "瑞州街道",
        "360983002": "筠阳街道",
        "360983100": "蓝坊镇",
        "360983101": "荷岭镇",
        "360983102": "黄沙岗镇",
        "360983103": "新街镇",
        "360983104": "八景镇",
        "360983105": "独城镇",
        "360983106": "太阳镇",
        "360983107": "建山镇",
        "360983108": "田南镇",
        "360983109": "相城镇",
        "360983110": "灰埠镇",
        "360983111": "石脑镇",
        "360983112": "龙潭镇",
        "360983113": "杨圩镇",
        "360983114": "村前镇",
        "360983115": "伍桥镇",
        "360983116": "祥符镇",
        "360983117": "大城镇",
        "360983118": "华林山镇",
        "360983200": "上湖乡",
        "360983201": "汪家圩乡",
        "360983400": "高安市新世纪工业城管委会",
        "360983403": "英岗岭矿务局",
        "360983404": "江西八景煤矿有限公司",
        "360983405": "江西新茂实业公司",
        "360983500": "相城垦殖场",
        "360983501": "上游水库工程管理局",
        "360983502": "江西省瑞州监狱"
    },
    "361000": {
        "361002": "临川区",
        "361003": "东乡区",
        "361021": "南城县",
        "361022": "黎川县",
        "361023": "南丰县",
        "361024": "崇仁县",
        "361025": "乐安县",
        "361026": "宜黄县",
        "361027": "金溪县",
        "361028": "资溪县",
        "361030": "广昌县"
    },
    "361002": {
        "361002001": "青云街道",
        "361002002": "西大街街道",
        "361002003": "荆公路街道",
        "361002004": "六水桥街道",
        "361002005": "文昌街道",
        "361002091": "城西街道（金巢开发区）",
        "361002092": "钟岭街道（金巢开发区）",
        "361002100": "上顿渡镇",
        "361002101": "温泉镇",
        "361002102": "高坪镇",
        "361002103": "秋溪镇",
        "361002104": "荣山镇",
        "361002105": "龙溪镇",
        "361002107": "大岗镇",
        "361002108": "云山镇",
        "361002109": "唱凯镇",
        "361002110": "罗针镇",
        "361002111": "罗湖镇",
        "361002112": "太阳镇",
        "361002113": "东馆镇",
        "361002114": "腾桥镇",
        "361002115": "青泥镇",
        "361002116": "孝桥镇",
        "361002117": "抚北镇",
        "361002118": "展坪镇",
        "361002190": "崇岗镇（金巢开发区）",
        "361002201": "连城乡",
        "361002202": "桐源乡",
        "361002203": "湖南乡",
        "361002204": "七里岗乡",
        "361002205": "嵩湖乡",
        "361002206": "鹏田乡",
        "361002207": "茅排乡",
        "361002208": "河埠乡",
        "361002400": "荣山垦殖场",
        "361002401": "七里岗垦殖场",
        "361002500": "开发区管理委员会"
    },
    "361003": {
        "361003001": "金峰街道办",
        "361003100": "孝岗镇",
        "361003101": "小璜镇",
        "361003102": "圩上桥镇",
        "361003103": "马圩镇",
        "361003104": "詹圩镇",
        "361003105": "岗上积镇",
        "361003106": "杨桥殿镇",
        "361003107": "黎圩镇",
        "361003108": "王桥镇",
        "361003200": "珀玕乡",
        "361003201": "邓家乡",
        "361003202": "虎圩乡",
        "361003203": "瑶圩乡",
        "361003400": "红星垦殖场",
        "361003401": "红光垦殖场",
        "361003402": "红亮垦殖场",
        "361003403": "甘坑生态林场",
        "361003405": "江西磷肥厂",
        "361003406": "东乡县经济开发区"
    },
    "361021": {
        "361021100": "建昌镇",
        "361021101": "株良镇",
        "361021102": "上唐镇",
        "361021103": "里塔镇",
        "361021104": "洪门镇",
        "361021105": "沙洲镇",
        "361021106": "龙湖镇",
        "361021107": "新丰街镇",
        "361021108": "万坊镇",
        "361021109": "徐家镇",
        "361021201": "天井源乡",
        "361021203": "浔溪乡",
        "361021400": "南城县工业园区管理委员会"
    },
    "361022": {
        "361022100": "日峰镇",
        "361022101": "宏村镇",
        "361022102": "洵口镇",
        "361022103": "熊村镇",
        "361022104": "龙安镇",
        "361022105": "德胜镇",
        "361022106": "华山镇",
        "361022200": "潭溪乡",
        "361022201": "湖坊乡",
        "361022202": "荷源乡",
        "361022203": "厚村乡",
        "361022204": "社苹乡",
        "361022205": "樟溪乡",
        "361022206": "西城乡",
        "361022207": "中田乡",
        "361022401": "黎川县工业园区管理委员会",
        "361022500": "德胜企业集团"
    },
    "361023": {
        "361023100": "琴城镇",
        "361023101": "太和镇",
        "361023102": "白舍镇",
        "361023103": "市山镇",
        "361023104": "洽湾镇",
        "361023105": "桑田镇",
        "361023106": "紫霄镇",
        "361023200": "三溪乡",
        "361023201": "东坪乡",
        "361023202": "莱溪乡",
        "361023203": "太源乡",
        "361023204": "傅坊乡",
        "361023400": "南丰县长红垦殖场",
        "361023500": "南丰工业园区"
    },
    "361024": {
        "361024100": "巴山镇",
        "361024101": "相山镇",
        "361024102": "航埠镇",
        "361024103": "孙坊镇",
        "361024104": "河上镇",
        "361024105": "礼陂镇",
        "361024106": "马鞍镇",
        "361024200": "石庄乡",
        "361024201": "六家桥乡",
        "361024202": "白路乡",
        "361024203": "三山乡",
        "361024204": "白陂乡",
        "361024205": "桃源乡",
        "361024206": "许坊乡",
        "361024207": "郭圩乡"
    },
    "361025": {
        "361025100": "鳌溪镇",
        "361025101": "公溪镇",
        "361025102": "山砀镇",
        "361025103": "龚坊镇",
        "361025104": "戴坊镇",
        "361025105": "牛田镇",
        "361025106": "万崇镇",
        "361025107": "增田镇",
        "361025108": "招携镇",
        "361025200": "湖溪乡",
        "361025201": "罗陂乡",
        "361025202": "湖坪乡",
        "361025203": "南村乡",
        "361025204": "谷岗乡",
        "361025205": "金竹畲族乡",
        "361025206": "大马头乡"
    },
    "361026": {
        "361026100": "凤冈镇",
        "361026101": "棠阴镇",
        "361026102": "黄陂镇",
        "361026103": "东陂镇",
        "361026104": "梨溪镇",
        "361026105": "二都镇",
        "361026106": "中港镇",
        "361026107": "桃陂镇",
        "361026201": "新丰乡",
        "361026202": "神岗乡",
        "361026203": "圳口乡",
        "361026204": "南源乡",
        "361026400": "黄柏岭垦殖场",
        "361026401": "青年垦殖场",
        "361026500": "宜黄县工业园区"
    },
    "361027": {
        "361027100": "秀谷镇",
        "361027101": "浒湾镇",
        "361027102": "双塘镇",
        "361027103": "何源镇",
        "361027104": "合市镇",
        "361027105": "琅琚镇",
        "361027106": "左坊镇",
        "361027107": "对桥镇",
        "361027200": "黄通乡",
        "361027202": "陆坊乡",
        "361027203": "陈坊积乡",
        "361027204": "琉璃乡",
        "361027205": "石门乡",
        "361027400": "华侨管理区",
        "361027401": "江西金溪工业园区管理委员会"
    },
    "361028": {
        "361028100": "鹤城镇",
        "361028101": "马头山镇",
        "361028102": "高阜镇",
        "361028103": "嵩市镇",
        "361028104": "乌石镇",
        "361028200": "高田乡",
        "361028201": "石峡乡"
    },
    "361030": {
        "361030100": "盱江镇",
        "361030101": "头陂镇",
        "361030103": "赤水镇",
        "361030104": "驿前镇",
        "361030105": "甘竹镇",
        "361030106": "塘坊镇",
        "361030200": "千善乡",
        "361030201": "水南圩乡",
        "361030202": "长桥乡",
        "361030203": "杨溪乡",
        "361030204": "尖峰乡",
        "361030400": "翠雷山垦殖场",
        "361030500": "广昌县工业园区"
    },
    "361100": {
        "361102": "信州区",
        "361103": "广丰区",
        "361121": "上饶县",
        "361123": "玉山县",
        "361124": "铅山县",
        "361125": "横峰县",
        "361126": "弋阳县",
        "361127": "余干县",
        "361128": "鄱阳县",
        "361129": "万年县",
        "361130": "婺源县",
        "361181": "德兴市"
    },
    "361102": {
        "361102001": "水南街道",
        "361102002": "东市街道",
        "361102003": "西市街道",
        "361102004": "北门街道",
        "361102005": "茅家岭街道",
        "361102006": "灵溪街道",
        "361102100": "沙溪镇",
        "361102102": "朝阳镇",
        "361102103": "秦峰镇"
    },
    "361103": {
        "361103001": "永丰街道",
        "361103002": "芦林街道",
        "361103003": "丰溪街道",
        "361103004": "下溪街道",
        "361103005": "大石街道",
        "361103101": "五都镇",
        "361103102": "洋口镇",
        "361103103": "横山镇",
        "361103104": "桐畈镇",
        "361103105": "湖丰镇",
        "361103106": "大南镇",
        "361103107": "排山镇",
        "361103108": "毛村镇",
        "361103109": "枧底镇",
        "361103110": "泉波镇",
        "361103111": "壶峤镇",
        "361103112": "霞峰镇",
        "361103114": "吴村镇",
        "361103115": "沙田镇",
        "361103116": "铜钹山镇",
        "361103202": "东阳乡",
        "361103204": "嵩峰乡",
        "361103206": "少阳乡",
        "361103500": "铜拔山垦殖场"
    },
    "361121": {
        "361121001": "旭日街道",
        "361121002": "罗桥街道",
        "361121003": "兴园街道",
        "361121101": "田墩镇",
        "361121102": "上泸镇",
        "361121103": "华坛山镇",
        "361121104": "茶亭镇",
        "361121105": "皂头镇",
        "361121106": "四十八镇",
        "361121107": "枫岭头镇",
        "361121108": "煌固镇",
        "361121109": "花厅镇",
        "361121110": "五府山镇",
        "361121111": "郑坊镇",
        "361121200": "望仙乡",
        "361121201": "石人乡",
        "361121202": "清水乡",
        "361121203": "石狮乡",
        "361121204": "湖村乡",
        "361121207": "尊桥乡",
        "361121209": "应家乡",
        "361121210": "黄沙岭乡",
        "361121211": "铁山乡",
        "361121290": "董团乡"
    },
    "361123": {
        "361123002": "冰溪街道",
        "361123003": "文成街道",
        "361123101": "临湖镇",
        "361123102": "必姆镇",
        "361123103": "横街镇",
        "361123105": "下镇镇",
        "361123107": "岩瑞镇",
        "361123108": "双明镇",
        "361123109": "紫湖镇",
        "361123110": "仙岩镇",
        "361123111": "樟村镇",
        "361123112": "枫林镇",
        "361123200": "南山乡",
        "361123201": "怀玉乡",
        "361123202": "下塘乡",
        "361123203": "四股桥乡",
        "361123204": "六都乡",
        "361123290": "三清乡"
    },
    "361124": {
        "361124100": "河口镇",
        "361124101": "永平镇",
        "361124102": "石塘镇",
        "361124103": "鹅湖镇",
        "361124105": "湖坊镇",
        "361124106": "武夷山镇",
        "361124108": "汪二镇",
        "361124200": "陈坊乡",
        "361124201": "虹桥乡",
        "361124202": "新滩乡",
        "361124203": "葛仙山乡",
        "361124205": "稼轩乡",
        "361124206": "英将乡",
        "361124207": "紫溪乡",
        "361124208": "太源畲族乡",
        "361124209": "天柱山乡",
        "361124210": "篁碧畲族乡",
        "361124407": "铅山县青溪服务中心",
        "361124501": "铅山县国营森林苗圃"
    },
    "361125": {
        "361125001": "兴安街道",
        "361125100": "岑阳镇",
        "361125101": "葛源镇",
        "361125200": "姚家乡",
        "361125201": "莲荷乡",
        "361125202": "司铺乡",
        "361125203": "港边乡",
        "361125204": "龙门畈乡",
        "361125205": "青板乡",
        "361125401": "红桥垦殖场",
        "361125502": "上坑源林场",
        "361125503": "山黄林场",
        "361125504": "新篁"
    },
    "361126": {
        "361126001": "桃源街道",
        "361126002": "花亭街道",
        "361126101": "曹溪镇",
        "361126102": "漆工镇",
        "361126103": "樟树墩镇",
        "361126104": "南岩镇",
        "361126105": "朱坑镇",
        "361126106": "圭峰镇",
        "361126107": "叠山镇",
        "361126108": "港口镇",
        "361126109": "弋江镇",
        "361126110": "三县岭镇",
        "361126202": "中畈乡",
        "361126203": "葛溪乡",
        "361126204": "湾里乡",
        "361126206": "清湖乡",
        "361126207": "旭光乡"
    },
    "361127": {
        "361127100": "玉亭镇",
        "361127101": "瑞洪镇",
        "361127102": "黄金埠镇",
        "361127104": "古埠镇",
        "361127105": "乌泥镇",
        "361127106": "石口镇",
        "361127107": "杨埠镇",
        "361127108": "九龙镇",
        "361127109": "社赓镇",
        "361127200": "康山乡",
        "361127202": "东塘乡",
        "361127204": "大塘乡",
        "361127206": "鹭鸶港乡",
        "361127207": "三塘乡",
        "361127209": "洪家嘴乡",
        "361127210": "白马桥乡",
        "361127211": "江埠乡",
        "361127213": "枫港乡",
        "361127214": "大溪乡",
        "361127217": "梅港乡",
        "361127500": "渔池湖水产场",
        "361127501": "县良种场",
        "361127502": "李梅林场",
        "361127503": "康山垦总场",
        "361127504": "禾斛岭垦殖场",
        "361127505": "峡山林场",
        "361127506": "信丰垦殖场"
    },
    "361128": {
        "361128001": "饶州街道",
        "361128100": "鄱阳镇",
        "361128101": "谢家滩镇",
        "361128102": "石门街镇",
        "361128103": "四十里街镇",
        "361128104": "油墩街镇",
        "361128105": "田畈街镇",
        "361128106": "金盘岭镇",
        "361128107": "高家岭镇",
        "361128108": "凰岗镇",
        "361128109": "双港镇",
        "361128110": "古县渡镇",
        "361128111": "饶丰镇",
        "361128112": "乐丰镇",
        "361128113": "饶埠镇",
        "361128201": "侯家岗乡",
        "361128202": "莲花山乡",
        "361128203": "响水滩乡",
        "361128206": "枧田街乡",
        "361128207": "柘港乡",
        "361128208": "鸦鹊湖乡",
        "361128209": "银宝湖乡",
        "361128210": "游城乡",
        "361128211": "珠湖乡",
        "361128212": "白沙洲乡",
        "361128213": "团林乡",
        "361128215": "昌洲乡",
        "361128216": "三庙前乡",
        "361128217": "莲湖乡",
        "361128218": "芦田乡",
        "361128401": "农科所",
        "361128402": "饶洲监狱"
    },
    "361129": {
        "361129100": "陈营镇",
        "361129101": "石镇镇",
        "361129102": "青云镇",
        "361129103": "梓埠镇",
        "361129104": "大源镇",
        "361129105": "裴梅镇",
        "361129200": "湖云乡",
        "361129201": "齐埠乡",
        "361129203": "汪家乡",
        "361129205": "上坊乡",
        "361129206": "苏桥乡",
        "361129208": "珠田乡"
    },
    "361130": {
        "361130001": "蚺城街道",
        "361130100": "紫阳镇",
        "361130101": "清华镇",
        "361130102": "秋口镇",
        "361130103": "江湾镇",
        "361130104": "思口镇",
        "361130106": "赋春镇",
        "361130107": "镇头镇",
        "361130109": "太白镇",
        "361130110": "中云镇",
        "361130111": "许村镇",
        "361130200": "溪头乡",
        "361130201": "段莘乡",
        "361130204": "浙源乡",
        "361130205": "沱川乡",
        "361130206": "大鄣山乡",
        "361130208": "珍珠山乡"
    },
    "361181": {
        "361181001": "银城街道",
        "361181002": "新营街道",
        "361181003": "香屯街道",
        "361181004": "铜矿街道",
        "361181103": "绕二镇",
        "361181105": "海口镇",
        "361181106": "新岗山镇",
        "361181107": "泗洲镇",
        "361181108": "大茅山镇",
        "361181190": "花桥镇",
        "361181200": "黄柏乡",
        "361181201": "万村乡",
        "361181202": "张村乡",
        "361181203": "昄大乡",
        "361181204": "李宅乡",
        "361181205": "龙头山乡"
    },
    "370000": {
        "370100": "济南市",
        "370200": "青岛市",
        "370300": "淄博市",
        "370400": "枣庄市",
        "370500": "东营市",
        "370600": "烟台市",
        "370700": "潍坊市",
        "370800": "济宁市",
        "370900": "泰安市",
        "371000": "威海市",
        "371100": "日照市",
        "371200": "莱芜市",
        "371300": "临沂市",
        "371400": "德州市",
        "371500": "聊城市",
        "371600": "滨州市",
        "371700": "菏泽市"
    },
    "370100": {
        "370102": "历下区",
        "370103": "市中区",
        "370104": "槐荫区",
        "370105": "天桥区",
        "370112": "历城区",
        "370113": "长清区",
        "370114": "章丘区",
        "370115": "济阳区",
        "370124": "平阴县",
        "370126": "商河县",
        "370171": "济南高新技术产业开发区"
    },
    "370102": {
        "370102001": "解放路街道",
        "370102002": "千佛山街道",
        "370102003": "趵突泉街道",
        "370102004": "泉城路街道",
        "370102005": "大明湖街道",
        "370102006": "东关街道",
        "370102007": "文东街道",
        "370102008": "建新街道",
        "370102009": "甸柳街道",
        "370102010": "燕山街道",
        "370102011": "姚家街道",
        "370102012": "龙洞街道",
        "370102013": "智远街道"
    },
    "370103": {
        "370103002": "大观园街道",
        "370103003": "杆石桥街道",
        "370103004": "四里村街道",
        "370103006": "魏家庄街道",
        "370103008": "二七街道",
        "370103009": "七里山街道",
        "370103010": "六里山街道",
        "370103012": "舜玉路街道",
        "370103014": "泺源街道",
        "370103015": "王官庄街道",
        "370103016": "舜耕街道",
        "370103017": "白马山街道",
        "370103018": "七贤街道",
        "370103019": "十六里河街道",
        "370103020": "兴隆街道",
        "370103021": "党家街道",
        "370103022": "陡沟街道"
    },
    "370104": {
        "370104001": "振兴街街道",
        "370104002": "中大槐树街道",
        "370104003": "道德街街道",
        "370104004": "西市场街道",
        "370104005": "五里沟街道",
        "370104006": "营市街街道",
        "370104007": "青年公园街道",
        "370104008": "南辛庄街道",
        "370104009": "段店北路街道",
        "370104010": "张庄路街道",
        "370104011": "匡山街道",
        "370104012": "美里湖街道",
        "370104013": "腊山街道",
        "370104014": "兴福街道",
        "370104015": "玉清湖街道",
        "370104016": "吴家堡街道"
    },
    "370105": {
        "370105001": "无影山街道",
        "370105003": "天桥东街街道",
        "370105004": "北村街道",
        "370105005": "南村街道",
        "370105006": "堤口路街道",
        "370105007": "北坦街道",
        "370105009": "制锦市街道",
        "370105010": "宝华街道",
        "370105011": "官扎营街道",
        "370105012": "纬北路街道",
        "370105013": "药山街道",
        "370105014": "北园街道",
        "370105015": "泺口街道",
        "370105016": "桑梓店街道",
        "370105017": "大桥街道"
    },
    "370112": {
        "370112001": "山大路街道",
        "370112002": "洪家楼街道",
        "370112003": "东风街道",
        "370112004": "全福街道",
        "370112007": "华山街道",
        "370112008": "荷花路街道",
        "370112009": "王舍人街道",
        "370112010": "鲍山街道",
        "370112011": "郭店街道",
        "370112012": "唐冶街道",
        "370112013": "港沟街道",
        "370112016": "董家街道",
        "370112017": "彩石街道",
        "370112018": "仲宫街道",
        "370112019": "柳埠街道",
        "370112107": "唐王镇",
        "370112111": "西营镇"
    },
    "370113": {
        "370113001": "文昌街道",
        "370113002": "崮云湖街道",
        "370113003": "平安街道",
        "370113004": "五峰山街道",
        "370113005": "归德街道",
        "370113006": "张夏街道",
        "370113007": "万德街道",
        "370113102": "孝里镇",
        "370113107": "马山镇",
        "370113108": "双泉镇"
    },
    "370114": {
        "370114001": "明水街道",
        "370114002": "双山街道",
        "370114003": "枣园街道",
        "370114004": "龙山街道",
        "370114005": "埠村街道",
        "370114006": "圣井街道",
        "370114007": "普集街道",
        "370114008": "绣惠街道",
        "370114009": "相公庄街道",
        "370114010": "文祖街道",
        "370114011": "官庄街道",
        "370114012": "高官寨街道",
        "370114013": "白云湖街道",
        "370114014": "宁家埠街道",
        "370114015": "曹范街道",
        "370114100": "刁镇",
        "370114101": "垛庄镇",
        "370114102": "黄河镇"
    },
    "370115": {
        "370115001": "济阳街道",
        "370115002": "济北街道",
        "370115003": "崔寨街道",
        "370115004": "孙耿街道",
        "370115005": "回河街道",
        "370115006": "太平街道",
        "370115101": "垛石镇",
        "370115103": "曲堤镇",
        "370115104": "仁风镇",
        "370115110": "新市镇"
    },
    "370124": {
        "370124001": "榆山街道",
        "370124002": "锦水街道",
        "370124102": "东阿镇",
        "370124103": "孝直镇",
        "370124104": "孔村镇",
        "370124105": "洪范池镇",
        "370124106": "玫瑰镇",
        "370124107": "安城镇"
    },
    "370126": {
        "370126001": "许商街道",
        "370126101": "殷巷镇",
        "370126102": "怀仁镇",
        "370126104": "龙桑寺镇",
        "370126105": "郑路镇",
        "370126106": "贾庄镇",
        "370126107": "玉皇庙镇",
        "370126108": "白桥镇",
        "370126109": "孙集镇",
        "370126110": "韩庙镇",
        "370126111": "沙河镇",
        "370126112": "张坊镇"
    },
    "370171": {
        "370171001": "舜华路街道",
        "370171002": "孙村街道",
        "370171003": "巨野河街道",
        "370171004": "遥墙街道",
        "370171005": "临港街道",
        "370171400": "创新谷街道"
    },
    "370200": {
        "370202": "市南区",
        "370203": "市北区",
        "370211": "黄岛区",
        "370212": "崂山区",
        "370213": "李沧区",
        "370214": "城阳区",
        "370215": "即墨区",
        "370271": "青岛高新技术产业开发区",
        "370281": "胶州市",
        "370283": "平度市",
        "370285": "莱西市"
    },
    "370202": {
        "370202001": "香港中路街道",
        "370202002": "八大峡街道",
        "370202004": "云南路街道",
        "370202005": "中山路街道",
        "370202007": "江苏路街道",
        "370202009": "八大关街道",
        "370202010": "湛山街道",
        "370202012": "八大湖街道",
        "370202013": "金门路街道",
        "370202014": "珠海路街道"
    },
    "370203": {
        "370203005": "辽宁路街道",
        "370203008": "延安路街道",
        "370203011": "登州路街道",
        "370203013": "宁夏路街道",
        "370203014": "敦化路街道",
        "370203015": "辽源路街道",
        "370203016": "合肥路街道",
        "370203019": "大港街道",
        "370203020": "即墨路街道",
        "370203021": "台东街道",
        "370203022": "镇江路街道",
        "370203025": "浮山新区街道",
        "370203026": "阜新路街道",
        "370203027": "海伦路街道",
        "370203028": "四方街道",
        "370203029": "兴隆路街道",
        "370203030": "水清沟街道",
        "370203031": "洛阳路街道",
        "370203032": "河西街道",
        "370203033": "湖岛街道",
        "370203034": "开平路街道",
        "370203035": "双山街道"
    },
    "370211": {
        "370211001": "黄岛街道",
        "370211002": "辛安街道",
        "370211003": "薛家岛街道",
        "370211004": "灵珠山街道",
        "370211005": "长江路街道",
        "370211006": "红石崖街道",
        "370211007": "灵山卫街道",
        "370211009": "珠海街道",
        "370211010": "隐珠街道",
        "370211011": "铁山街道",
        "370211012": "滨海街道",
        "370211013": "胶南街道",
        "370211100": "王台镇",
        "370211101": "张家楼镇",
        "370211102": "琅琊镇",
        "370211103": "泊里镇",
        "370211104": "大场镇",
        "370211105": "大村镇",
        "370211106": "六汪镇",
        "370211107": "海青镇",
        "370211108": "宝山镇",
        "370211109": "藏南镇",
        "370211400": "临港管区",
        "370211401": "积米崖管区",
        "370211402": "琅琊台管区",
        "370211403": "胶河管区"
    },
    "370212": {
        "370212001": "中韩街道",
        "370212002": "沙子口街道",
        "370212003": "王哥庄街道",
        "370212004": "北宅街道",
        "370212005": "金家岭街道"
    },
    "370213": {
        "370213001": "振华路街道",
        "370213003": "沧口街道",
        "370213004": "兴华路街道",
        "370213005": "兴城路街道",
        "370213006": "李村街道",
        "370213007": "虎山路街道",
        "370213008": "浮山路街道",
        "370213010": "湘潭路街道",
        "370213011": "楼山街道",
        "370213012": "九水街道",
        "370213013": "世园街道"
    },
    "370214": {
        "370214001": "城阳街道",
        "370214002": "夏庄街道",
        "370214003": "流亭街道",
        "370214004": "棘洪滩街道",
        "370214005": "上马街道",
        "370214008": "惜福镇街道"
    },
    "370215": {
        "370215001": "环秀街道",
        "370215002": "潮海街道",
        "370215003": "通济街道",
        "370215004": "北安街道",
        "370215005": "龙山街道",
        "370215006": "龙泉街道",
        "370215007": "鳌山卫街道",
        "370215008": "温泉街道",
        "370215100": "田横镇",
        "370215101": "金口镇",
        "370215102": "灵山镇",
        "370215103": "段泊岚镇",
        "370215104": "移风店镇",
        "370215105": "蓝村镇",
        "370215106": "大信镇",
        "370215400": "通济新经济区",
        "370215401": "青岛蓝谷高新技术产业开发区",
        "370215402": "田横岛省级旅游度假区"
    },
    "370271": {"370271001": "红岛街道", "370271002": "河套街道"},
    "370281": {
        "370281001": "阜安街道",
        "370281002": "中云街道",
        "370281004": "三里河街道",
        "370281006": "九龙街道",
        "370281007": "胶东街道",
        "370281008": "胶北街道",
        "370281100": "胶莱镇",
        "370281102": "李哥庄镇",
        "370281105": "铺集镇",
        "370281108": "里岔镇",
        "370281110": "胶西镇",
        "370281111": "洋河镇",
        "370281400": "胶州经济技术开发区",
        "370281401": "大沽河省级生态旅游度假区"
    },
    "370283": {
        "370283001": "东阁街道",
        "370283002": "李园街道",
        "370283003": "同和街道",
        "370283004": "凤台街道",
        "370283005": "白沙河街道",
        "370283101": "古岘镇",
        "370283102": "仁兆镇",
        "370283105": "南村镇",
        "370283108": "蓼兰镇",
        "370283109": "崔家集镇",
        "370283110": "明村镇",
        "370283113": "田庄镇",
        "370283116": "新河镇",
        "370283119": "店子镇",
        "370283120": "大泽山镇",
        "370283122": "旧店镇",
        "370283124": "云山镇",
        "370283400": "平度经济开发区"
    },
    "370285": {
        "370285001": "水集街道",
        "370285002": "望城街道",
        "370285007": "沽河街道",
        "370285101": "姜山镇",
        "370285102": "夏格庄镇",
        "370285104": "院上镇",
        "370285105": "日庄镇",
        "370285106": "南墅镇",
        "370285107": "河头店镇",
        "370285108": "店埠镇",
        "370285116": "马连庄镇",
        "370285400": "莱西经济开发区"
    },
    "370300": {
        "370302": "淄川区",
        "370303": "张店区",
        "370304": "博山区",
        "370305": "临淄区",
        "370306": "周村区",
        "370321": "桓台县",
        "370322": "高青县",
        "370323": "沂源县"
    },
    "370302": {
        "370302001": "般阳路街道",
        "370302002": "松龄路街道",
        "370302004": "钟楼街道",
        "370302005": "将军路街道",
        "370302102": "昆仑镇",
        "370302104": "岭子镇",
        "370302111": "西河镇",
        "370302112": "龙泉镇",
        "370302113": "寨里镇",
        "370302114": "罗村镇",
        "370302115": "洪山镇",
        "370302116": "双杨镇",
        "370302117": "太河镇"
    },
    "370303": {
        "370303001": "车站街道",
        "370303002": "公园街道",
        "370303003": "湖田街道",
        "370303004": "和平街道",
        "370303005": "科苑街道",
        "370303006": "体育场街道",
        "370303008": "四宝山街道",
        "370303100": "马尚镇",
        "370303101": "南定镇",
        "370303102": "沣水镇",
        "370303104": "傅家镇",
        "370303105": "中埠镇",
        "370303107": "房镇镇"
    },
    "370304": {
        "370304001": "城东街道",
        "370304002": "城西街道",
        "370304004": "山头街道",
        "370304102": "域城镇",
        "370304103": "白塔镇",
        "370304106": "八陡镇",
        "370304108": "石马镇",
        "370304111": "源泉镇",
        "370304112": "池上镇",
        "370304113": "博山镇"
    },
    "370305": {
        "370305001": "闻韶街道",
        "370305002": "雪宫街道",
        "370305003": "辛店街道",
        "370305004": "稷下街道",
        "370305005": "齐陵街道",
        "370305100": "齐都镇",
        "370305102": "皇城镇",
        "370305103": "敬仲镇",
        "370305104": "朱台镇",
        "370305107": "金岭镇",
        "370305109": "凤凰镇",
        "370305111": "金山镇"
    },
    "370306": {
        "370306001": "丝绸路街道",
        "370306002": "大街街道",
        "370306003": "青年路街道",
        "370306004": "永安街道",
        "370306005": "城北路街道",
        "370306100": "北郊镇",
        "370306101": "南郊镇",
        "370306102": "王村镇",
        "370306103": "萌水镇",
        "370306104": "商家镇"
    },
    "370321": {
        "370321001": "索镇街道",
        "370321002": "少海街道",
        "370321101": "起凤镇",
        "370321103": "田庄镇",
        "370321104": "荆家镇",
        "370321105": "马桥镇",
        "370321107": "新城镇",
        "370321109": "唐山镇",
        "370321110": "果里镇"
    },
    "370322": {
        "370322001": "田镇街道",
        "370322002": "芦湖街道",
        "370322101": "青城镇",
        "370322102": "高城镇",
        "370322103": "黑里寨镇",
        "370322104": "唐坊镇",
        "370322105": "常家镇",
        "370322106": "花沟镇",
        "370322108": "木李镇"
    },
    "370323": {
        "370323001": "历山街道",
        "370323002": "南麻街道",
        "370323102": "鲁村镇",
        "370323103": "东里镇",
        "370323104": "悦庄镇",
        "370323105": "西里镇",
        "370323106": "大张庄镇",
        "370323107": "中庄镇",
        "370323108": "张家坡镇",
        "370323109": "燕崖镇",
        "370323110": "石桥镇",
        "370323111": "南鲁山镇"
    },
    "370400": {"370402": "市中区", "370403": "薛城区", "370404": "峄城区", "370405": "台儿庄区", "370406": "山亭区", "370481": "滕州市"},
    "370402": {
        "370402001": "中心街街道",
        "370402002": "各塔埠街道",
        "370402003": "矿区街道",
        "370402004": "文化路街道办事",
        "370402005": "龙山路街道",
        "370402006": "光明路街道办事",
        "370402101": "税郭镇",
        "370402102": "孟庄镇",
        "370402103": "齐村镇",
        "370402104": "永安镇",
        "370402105": "西王庄镇"
    },
    "370403": {
        "370403001": "临城街道",
        "370403002": "兴仁街道",
        "370403003": "兴城街道",
        "370403004": "张范街道",
        "370403101": "沙沟镇",
        "370403102": "周营镇",
        "370403103": "邹坞镇",
        "370403104": "陶庄镇",
        "370403106": "常庄镇"
    },
    "370404": {
        "370404001": "坛山街道办",
        "370404002": "吴林街道办",
        "370404100": "古邵镇",
        "370404101": "阴平镇",
        "370404102": "底阁镇",
        "370404103": "榴园镇",
        "370404104": "峨山镇"
    },
    "370405": {
        "370405001": "运河街道",
        "370405100": "邳庄镇",
        "370405101": "张山子镇",
        "370405102": "泥沟镇",
        "370405103": "涧头集镇",
        "370405104": "马兰屯镇"
    },
    "370406": {
        "370406001": "山城街道",
        "370406101": "店子镇",
        "370406102": "西集镇",
        "370406103": "桑村镇",
        "370406104": "北庄镇",
        "370406105": "城头镇",
        "370406106": "徐庄镇",
        "370406107": "水泉镇",
        "370406108": "冯卯镇",
        "370406109": "凫城镇",
        "370406405": "山亭经济开发区"
    },
    "370481": {
        "370481001": "荆河街道",
        "370481002": "龙泉街道",
        "370481003": "北辛街道",
        "370481004": "善南街道",
        "370481100": "东沙河镇",
        "370481101": "洪绪镇",
        "370481102": "南沙河镇",
        "370481103": "大坞镇",
        "370481104": "滨湖镇",
        "370481105": "级索镇",
        "370481106": "西岗镇",
        "370481107": "姜屯镇",
        "370481108": "鲍沟镇",
        "370481109": "张汪镇",
        "370481110": "官桥镇",
        "370481111": "柴胡店镇",
        "370481112": "羊庄镇",
        "370481113": "木石镇",
        "370481114": "界河镇",
        "370481115": "龙阳镇",
        "370481116": "东郭镇"
    },
    "370500": {
        "370502": "东营区",
        "370503": "河口区",
        "370505": "垦利区",
        "370522": "利津县",
        "370523": "广饶县",
        "370571": "东营经济技术开发区",
        "370572": "东营港经济开发区"
    },
    "370502": {
        "370502001": "文汇街道",
        "370502002": "黄河路街道",
        "370502003": "东城街道",
        "370502004": "辛店街道",
        "370502005": "胜利街道",
        "370502006": "胜园街道",
        "370502102": "牛庄镇",
        "370502103": "六户镇",
        "370502104": "史口镇",
        "370502105": "龙居镇",
        "370502400": "东营商贸园"
    },
    "370503": {
        "370503001": "河口街道",
        "370503002": "六合街道",
        "370503100": "义和镇",
        "370503101": "仙河镇",
        "370503102": "孤岛镇",
        "370503103": "新户镇",
        "370503400": "开发区管委会",
        "370503401": "河口蓝色经济开发区管理委员会"
    },
    "370505": {
        "370505001": "垦利街道",
        "370505002": "兴隆街道",
        "370505101": "胜坨镇",
        "370505102": "郝家镇",
        "370505104": "永安镇",
        "370505105": "黄河口镇",
        "370505106": "董集镇",
        "370505400": "开发区类似乡级单位",
        "370505402": "红光类似乡级单位",
        "370505403": "垦东类似乡级单位",
        "370505404": "东营市现代畜牧业示范区类似乡级单位"
    },
    "370522": {
        "370522001": "利津街道",
        "370522002": "凤凰城街道",
        "370522101": "北宋镇",
        "370522102": "盐窝镇",
        "370522103": "陈庄镇",
        "370522104": "汀罗镇",
        "370522201": "明集乡",
        "370522206": "刁口乡",
        "370522400": "利津县经济开发区"
    },
    "370523": {
        "370523001": "广饶街道",
        "370523002": "乐安街道",
        "370523003": "丁庄街道",
        "370523101": "大王镇",
        "370523102": "稻庄镇",
        "370523106": "李鹊镇",
        "370523107": "大码头镇",
        "370523108": "花官镇",
        "370523109": "陈官镇",
        "370523400": "东营农业高新技术产业示范区",
        "370523401": "广饶滨海新区管理委员会"
    },
    "370571": {"370571400": "经济开发区直属乡镇"},
    "370572": {"370572402": "经济开发区直属乡镇"},
    "370600": {
        "370602": "芝罘区",
        "370611": "福山区",
        "370612": "牟平区",
        "370613": "莱山区",
        "370634": "长岛县",
        "370671": "烟台高新技术产业开发区",
        "370672": "烟台经济技术开发区",
        "370681": "龙口市",
        "370682": "莱阳市",
        "370683": "莱州市",
        "370684": "蓬莱市",
        "370685": "招远市",
        "370686": "栖霞市",
        "370687": "海阳市"
    },
    "370602": {
        "370602001": "向阳街道",
        "370602002": "东山街道",
        "370602003": "毓璜顶街道",
        "370602004": "通伸街道",
        "370602005": "凤凰台街道",
        "370602006": "奇山街道",
        "370602007": "白石街道",
        "370602008": "芝罘岛街道",
        "370602009": "黄务街道",
        "370602010": "只楚街道",
        "370602011": "世回尧街道",
        "370602012": "幸福街道"
    },
    "370611": {
        "370611001": "清洋街道",
        "370611002": "福新街道",
        "370611006": "东厅街道",
        "370611007": "门楼街道",
        "370611102": "高疃镇",
        "370611103": "张格庄镇",
        "370611104": "回里镇"
    },
    "370612": {
        "370612001": "宁海街道",
        "370612002": "文化街道",
        "370612004": "武宁街道",
        "370612005": "大窑街道",
        "370612006": "姜格庄街道",
        "370612101": "观水镇",
        "370612105": "龙泉镇",
        "370612106": "玉林店镇",
        "370612107": "水道镇",
        "370612109": "高陵镇",
        "370612110": "王格庄镇",
        "370612111": "昆嵛镇",
        "370612112": "莒格庄镇"
    },
    "370613": {
        "370613001": "黄海路街道",
        "370613002": "初家街道",
        "370613003": "滨海路街道",
        "370613004": "解甲庄街道",
        "370613005": "莱山街道",
        "370613006": "院格庄街道"
    },
    "370634": {
        "370634001": "南长山街道",
        "370634101": "砣矶镇",
        "370634201": "北长山乡",
        "370634202": "黑山乡",
        "370634203": "大钦岛乡",
        "370634204": "小钦岛乡",
        "370634205": "南隍城乡",
        "370634206": "北隍城乡"
    },
    "370671": {"370671007": "马山街道"},
    "370672": {
        "370672003": "古现街道（烟台开发区）",
        "370672004": "大季家街道（烟台开发区）",
        "370672005": "八角街道（烟台开发区）",
        "370672008": "福莱山街道（烟台开发区）"
    },
    "370681": {
        "370681001": "东莱街道",
        "370681002": "龙港街道",
        "370681003": "新嘉街道",
        "370681004": "徐福街道",
        "370681005": "东江街道",
        "370681102": "黄山馆镇",
        "370681103": "北马镇",
        "370681104": "芦头镇",
        "370681106": "下丁家镇",
        "370681107": "七甲镇",
        "370681108": "石良镇",
        "370681109": "兰高镇",
        "370681110": "诸由观镇",
        "370681401": "山东龙口高新技术产业园区管理委员会"
    },
    "370682": {
        "370682001": "城厢街道",
        "370682002": "古柳街道",
        "370682003": "龙旺庄街道",
        "370682004": "冯格庄街道",
        "370682005": "柏林庄街道",
        "370682101": "沐浴店镇",
        "370682102": "团旺镇",
        "370682103": "穴坊镇",
        "370682104": "羊郡镇",
        "370682105": "姜疃镇",
        "370682106": "万第镇",
        "370682107": "照旺庄镇",
        "370682108": "谭格庄镇",
        "370682110": "河洛镇",
        "370682111": "吕格庄镇",
        "370682112": "高格庄镇",
        "370682113": "大夼镇",
        "370682114": "山前店镇"
    },
    "370683": {
        "370683001": "文昌路街道",
        "370683002": "永安路街道",
        "370683003": "三山岛街道",
        "370683004": "城港路街道",
        "370683005": "文峰路街道",
        "370683006": "金仓街道",
        "370683101": "沙河镇",
        "370683102": "朱桥镇",
        "370683103": "郭家店镇",
        "370683104": "金城镇",
        "370683105": "平里店镇",
        "370683106": "驿道镇",
        "370683107": "程郭镇",
        "370683108": "虎头崖镇",
        "370683109": "柞村镇",
        "370683110": "夏邱镇",
        "370683111": "土山镇"
    },
    "370684": {
        "370684001": "登州街道",
        "370684002": "紫荆山街道",
        "370684003": "新港街道",
        "370684004": "蓬莱阁街道",
        "370684005": "南王街道",
        "370684102": "刘家沟镇",
        "370684103": "潮水镇",
        "370684105": "大柳行镇",
        "370684106": "小门家镇",
        "370684107": "大辛店镇",
        "370684108": "村里集镇",
        "370684109": "北沟镇"
    },
    "370685": {
        "370685001": "罗峰街道",
        "370685002": "泉山街道",
        "370685003": "梦芝街道",
        "370685004": "温泉街道",
        "370685005": "大秦家街道",
        "370685101": "辛庄镇",
        "370685102": "蚕庄镇",
        "370685103": "金岭镇",
        "370685104": "毕郭镇",
        "370685105": "玲珑镇",
        "370685106": "张星镇",
        "370685108": "夏甸镇",
        "370685109": "阜山镇",
        "370685110": "齐山镇"
    },
    "370686": {
        "370686001": "翠屏街道",
        "370686002": "庄园街道",
        "370686003": "松山街道",
        "370686101": "观里镇",
        "370686102": "蛇窝泊镇",
        "370686103": "唐家泊镇",
        "370686104": "桃村镇",
        "370686105": "亭口镇",
        "370686106": "臧家庄镇",
        "370686107": "寺口镇",
        "370686108": "苏家店镇",
        "370686109": "杨础镇",
        "370686110": "西城镇",
        "370686111": "官道镇",
        "370686113": "庙后镇",
        "370686401": "山东栖霞经济开发区"
    },
    "370687": {
        "370687001": "方圆街道",
        "370687002": "东村街道",
        "370687003": "凤城街道",
        "370687004": "龙山街道",
        "370687102": "留格庄镇",
        "370687103": "盘石店镇",
        "370687104": "郭城镇",
        "370687105": "徐家店镇",
        "370687106": "发城镇",
        "370687107": "小纪镇",
        "370687108": "行村镇",
        "370687109": "辛安镇",
        "370687110": "二十里店镇",
        "370687112": "朱吴镇",
        "370687401": "外向型工业加工区",
        "370687402": "碧城工业区",
        "370687403": "旅游度假区",
        "370687404": "核电装备制造工业园区"
    },
    "370700": {
        "370702": "潍城区",
        "370703": "寒亭区",
        "370704": "坊子区",
        "370705": "奎文区",
        "370724": "临朐县",
        "370725": "昌乐县",
        "370772": "潍坊滨海经济技术开发区",
        "370781": "青州市",
        "370782": "诸城市",
        "370783": "寿光市",
        "370784": "安丘市",
        "370785": "高密市",
        "370786": "昌邑市"
    },
    "370702": {
        "370702001": "城关街道",
        "370702002": "南关街道",
        "370702003": "西关街道",
        "370702004": "北关街道",
        "370702005": "于河街道",
        "370702006": "望留街道",
        "370702400": "潍城经济开发区"
    },
    "370703": {"370703001": "寒亭街道", "370703002": "开元街道", "370703003": "固堤街道", "370703006": "高里街道", "370703007": "朱里街道"},
    "370704": {
        "370704003": "凤凰街道",
        "370704004": "坊安街道",
        "370704005": "坊城街道",
        "370704006": "九龙街道",
        "370704007": "黄旗堡街道",
        "370704008": "太保庄街道",
        "370704009": "王家庄街道"
    },
    "370705": {
        "370705001": "东关街道",
        "370705002": "大虞街道",
        "370705003": "梨园街道",
        "370705004": "廿里堡街道",
        "370705005": "潍州路街道",
        "370705006": "北苑街道",
        "370705007": "广文街道",
        "370705009": "新城街道",
        "370705010": "清池街道",
        "370705012": "北海路街道"
    },
    "370724": {
        "370724001": "城关街道",
        "370724002": "东城街道",
        "370724101": "五井镇",
        "370724103": "冶源镇",
        "370724104": "寺头镇",
        "370724105": "九山镇",
        "370724107": "辛寨镇",
        "370724112": "山旺镇",
        "370724113": "柳山镇",
        "370724116": "蒋峪镇"
    },
    "370725": {
        "370725001": "宝都街道",
        "370725002": "宝城街道",
        "370725003": "朱刘街道",
        "370725005": "五图街道",
        "370725107": "乔官镇",
        "370725108": "唐吾镇",
        "370725110": "红河镇",
        "370725116": "营丘镇"
    },
    "370772": {"370772004": "央子街道", "370772005": "大家洼街道"},
    "370781": {
        "370781001": "王府街道",
        "370781002": "益都街道",
        "370781006": "云门山街道",
        "370781007": "黄楼街道",
        "370781100": "弥河镇",
        "370781101": "王坟镇",
        "370781103": "庙子镇",
        "370781105": "邵庄镇",
        "370781109": "高柳镇",
        "370781111": "何官镇",
        "370781113": "东夏镇",
        "370781115": "谭坊镇",
        "370781400": "青州经济开发区管理委员会"
    },
    "370782": {
        "370782001": "密州街道",
        "370782002": "龙都街道",
        "370782003": "舜王街道",
        "370782101": "枳沟镇",
        "370782102": "贾悦镇",
        "370782105": "石桥子镇",
        "370782108": "相州镇",
        "370782110": "昌城镇",
        "370782111": "百尺河镇",
        "370782112": "辛兴镇",
        "370782114": "林家村镇",
        "370782117": "皇华镇",
        "370782118": "桃林镇",
        "370782400": "诸城经济开发区管理委员会"
    },
    "370783": {
        "370783001": "圣城街道",
        "370783003": "文家街道",
        "370783004": "古城街道",
        "370783005": "洛城街道",
        "370783006": "孙家集街道",
        "370783100": "化龙镇",
        "370783103": "营里镇",
        "370783104": "台头镇",
        "370783106": "田柳镇",
        "370783108": "上口镇",
        "370783109": "侯镇",
        "370783111": "纪台镇",
        "370783112": "稻田镇",
        "370783115": "羊口镇",
        "370783400": "双王城生态经济园区管委会"
    },
    "370784": {
        "370784001": "兴安街道",
        "370784003": "新安街道",
        "370784100": "景芝镇",
        "370784103": "凌河镇",
        "370784104": "官庄镇",
        "370784107": "大盛镇",
        "370784111": "石埠子镇",
        "370784112": "石堆镇",
        "370784118": "柘山镇",
        "370784119": "辉渠镇",
        "370784120": "吾山镇",
        "370784121": "金冢子镇"
    },
    "370785": {
        "370785001": "高密市朝阳街道",
        "370785002": "高密市醴泉街道",
        "370785003": "高密市密水街道",
        "370785100": "柏城镇",
        "370785103": "夏庄镇",
        "370785104": "姜庄镇",
        "370785106": "大牟家镇",
        "370785109": "阚家镇",
        "370785111": "井沟镇",
        "370785114": "柴沟镇"
    },
    "370786": {
        "370786001": "奎聚街道",
        "370786002": "都昌街道",
        "370786003": "围子街道",
        "370786101": "柳疃镇",
        "370786102": "龙池镇",
        "370786104": "卜庄镇",
        "370786108": "饮马镇",
        "370786109": "北孟镇",
        "370786112": "下营镇"
    },
    "370800": {
        "370811": "任城区",
        "370812": "兖州区",
        "370826": "微山县",
        "370827": "鱼台县",
        "370828": "金乡县",
        "370829": "嘉祥县",
        "370830": "汶上县",
        "370831": "泗水县",
        "370832": "梁山县",
        "370871": "济宁高新技术产业开发区",
        "370881": "曲阜市",
        "370883": "邹城市"
    },
    "370811": {
        "370811002": "许庄街道",
        "370811005": "李营街道",
        "370811006": "南张街道",
        "370811007": "仙营街道",
        "370811008": "金城街道",
        "370811009": "廿里铺街道",
        "370811011": "古槐街道",
        "370811012": "济阳街道",
        "370811013": "阜桥街道",
        "370811014": "越河街道",
        "370811015": "观音阁街道",
        "370811016": "南苑街道",
        "370811017": "安居街道",
        "370811018": "唐口街道",
        "370811102": "长沟镇",
        "370811109": "石桥镇",
        "370811111": "喻屯镇"
    },
    "370812": {
        "370812001": "鼓楼街道",
        "370812003": "龙桥街道",
        "370812006": "酒仙桥街道",
        "370812007": "兴隆庄街道",
        "370812101": "大安镇",
        "370812102": "新驿镇",
        "370812103": "颜店镇",
        "370812105": "新兖镇",
        "370812107": "漕河镇",
        "370812111": "小孟镇"
    },
    "370826": {
        "370826001": "夏镇街道",
        "370826002": "昭阳街道",
        "370826003": "傅村街道",
        "370826101": "韩庄镇",
        "370826102": "欢城镇",
        "370826103": "南阳镇",
        "370826104": "鲁桥镇",
        "370826106": "留庄镇",
        "370826107": "两城镇",
        "370826108": "马坡镇",
        "370826109": "赵庙镇",
        "370826110": "张楼镇",
        "370826111": "微山岛镇",
        "370826112": "西平镇",
        "370826206": "高楼乡"
    },
    "370827": {
        "370827001": "谷亭街道",
        "370827002": "滨湖街道",
        "370827101": "清河镇",
        "370827102": "鱼城镇",
        "370827103": "王鲁镇",
        "370827104": "张黄镇",
        "370827105": "王庙镇",
        "370827106": "李阁镇",
        "370827107": "唐马镇",
        "370827108": "老砦镇",
        "370827109": "罗屯镇"
    },
    "370828": {
        "370828001": "金乡街道",
        "370828002": "高河街道",
        "370828003": "鱼山街道",
        "370828004": "王丕街道",
        "370828101": "羊山镇",
        "370828102": "胡集镇",
        "370828103": "霄云镇",
        "370828104": "鸡黍镇",
        "370828107": "司马镇",
        "370828109": "马庙镇",
        "370828110": "化雨镇",
        "370828111": "卜集镇",
        "370828112": "兴隆镇"
    },
    "370829": {
        "370829001": "嘉祥镇街道",
        "370829002": "卧龙山街道",
        "370829003": "万张街道",
        "370829101": "纸坊镇",
        "370829102": "梁宝寺镇",
        "370829104": "疃里镇",
        "370829105": "马村镇",
        "370829106": "金屯镇",
        "370829107": "大张楼镇",
        "370829108": "马集镇",
        "370829110": "孟姑集镇",
        "370829111": "老僧堂镇",
        "370829112": "仲山镇",
        "370829113": "满硐镇",
        "370829114": "黄垓镇",
        "370829400": "嘉祥经济开发区管理委员会"
    },
    "370830": {
        "370830001": "中都街道",
        "370830002": "汶上街道",
        "370830101": "南站镇",
        "370830102": "南旺镇",
        "370830103": "次丘镇",
        "370830104": "寅寺镇",
        "370830105": "郭楼镇",
        "370830106": "康驿镇",
        "370830107": "苑庄镇",
        "370830108": "义桥镇",
        "370830109": "郭仓镇",
        "370830110": "白石镇",
        "370830111": "杨店镇",
        "370830112": "刘楼镇",
        "370830205": "军屯乡"
    },
    "370831": {
        "370831001": "泗河街道",
        "370831002": "济河街道",
        "370831101": "泉林镇",
        "370831102": "星村镇",
        "370831103": "柘沟镇",
        "370831104": "金庄镇",
        "370831105": "苗馆镇",
        "370831106": "中册镇",
        "370831107": "杨柳镇",
        "370831108": "泗张镇",
        "370831109": "圣水峪镇",
        "370831110": "高峪镇",
        "370831111": "华村镇"
    },
    "370832": {
        "370832001": "水泊街道",
        "370832002": "梁山街道",
        "370832101": "小路口镇",
        "370832102": "韩岗镇",
        "370832104": "拳铺镇",
        "370832105": "杨营镇",
        "370832106": "韩垓镇",
        "370832107": "馆驿镇",
        "370832108": "小安山镇",
        "370832109": "寿张集镇",
        "370832110": "黑虎庙镇",
        "370832111": "马营镇",
        "370832215": "赵堌堆乡",
        "370832217": "大路口乡",
        "370832400": "梁山经济开发区"
    },
    "370871": {
        "370871001": "洸河街道",
        "370871002": "柳行街道",
        "370871003": "接庄街道",
        "370871004": "王因街道",
        "370871005": "黄屯街道",
        "370871400": "济东物业服务中心"
    },
    "370881": {
        "370881001": "鲁城街道",
        "370881002": "书院街道",
        "370881003": "时庄街道",
        "370881004": "小雪街道",
        "370881101": "吴村镇",
        "370881102": "姚村镇",
        "370881103": "陵城镇",
        "370881105": "尼山镇",
        "370881108": "王庄镇",
        "370881109": "息陬镇",
        "370881110": "石门山镇",
        "370881111": "防山镇"
    },
    "370883": {
        "370883001": "钢山街道",
        "370883002": "千泉街道",
        "370883003": "凫山街道",
        "370883101": "香城镇",
        "370883102": "城前镇",
        "370883103": "大束镇",
        "370883104": "北宿镇",
        "370883105": "中心店镇",
        "370883106": "唐村镇",
        "370883107": "太平镇",
        "370883109": "石墙镇",
        "370883110": "峄山镇",
        "370883111": "看庄镇",
        "370883112": "张庄镇",
        "370883113": "田黄镇",
        "370883114": "郭里镇"
    },
    "370900": {"370902": "泰山区", "370911": "岱岳区", "370921": "宁阳县", "370923": "东平县", "370982": "新泰市", "370983": "肥城市"},
    "370902": {
        "370902001": "岱庙街道",
        "370902002": "财源街道",
        "370902003": "泰前街道",
        "370902004": "上高街道",
        "370902005": "徐家楼街道",
        "370902100": "省庄镇",
        "370902101": "邱家店镇",
        "370902202": "大津口乡"
    },
    "370911": {
        "370911001": "粥店街道",
        "370911002": "天平街道",
        "370911003": "北集坡街道",
        "370911100": "山口镇",
        "370911101": "祝阳镇",
        "370911102": "范镇",
        "370911103": "角峪镇",
        "370911104": "徂徕镇",
        "370911106": "满庄镇",
        "370911107": "夏张镇",
        "370911108": "道朗镇",
        "370911109": "黄前镇",
        "370911110": "大汶口镇",
        "370911111": "马庄镇",
        "370911112": "房村镇",
        "370911113": "良庄镇",
        "370911114": "下港镇",
        "370911201": "化马湾乡"
    },
    "370921": {
        "370921001": "文庙街道",
        "370921002": "八仙桥街道",
        "370921101": "泗店镇",
        "370921102": "东疏镇",
        "370921103": "伏山镇",
        "370921105": "堽城镇",
        "370921107": "蒋集镇",
        "370921109": "磁窑镇",
        "370921110": "华丰镇",
        "370921111": "葛石镇",
        "370921112": "东庄镇",
        "370921113": "鹤山镇",
        "370921206": "乡饮乡"
    },
    "370923": {
        "370923001": "东平街道",
        "370923002": "州城街道",
        "370923003": "彭集街道",
        "370923101": "沙河站镇",
        "370923106": "老湖镇",
        "370923107": "银山镇",
        "370923108": "斑鸠店镇",
        "370923109": "接山镇",
        "370923110": "大羊镇",
        "370923111": "梯门镇",
        "370923112": "新湖镇",
        "370923113": "戴庙镇",
        "370923206": "商老庄乡",
        "370923208": "旧县乡"
    },
    "370982": {
        "370982001": "青云街道",
        "370982002": "新汶街道",
        "370982003": "新甫街道",
        "370982101": "东都镇",
        "370982102": "小协镇",
        "370982103": "翟镇",
        "370982104": "泉沟镇",
        "370982105": "羊流镇",
        "370982106": "果都镇",
        "370982107": "西张庄镇",
        "370982108": "天宝镇",
        "370982110": "楼德镇",
        "370982111": "禹村镇",
        "370982112": "宫里镇",
        "370982113": "谷里镇",
        "370982114": "石莱镇",
        "370982115": "放城镇",
        "370982116": "刘杜镇",
        "370982117": "汶南镇",
        "370982119": "龙廷镇",
        "370982203": "岳家庄乡"
    },
    "370983": {
        "370983001": "新城街道",
        "370983002": "老城街道",
        "370983003": "王瓜店街道",
        "370983004": "仪阳街道",
        "370983101": "潮泉镇",
        "370983103": "桃园镇",
        "370983104": "王庄镇",
        "370983105": "湖屯镇",
        "370983106": "石横镇",
        "370983107": "安临站镇",
        "370983108": "孙伯镇",
        "370983109": "安驾庄镇",
        "370983110": "汶阳镇",
        "370983111": "边院镇"
    },
    "371000": {
        "371002": "环翠区",
        "371003": "文登区",
        "371071": "威海火炬高技术产业开发区",
        "371072": "威海经济技术开发区",
        "371073": "威海临港经济技术开发区",
        "371082": "荣成市",
        "371083": "乳山市"
    },
    "371002": {
        "371002001": "环翠楼街道",
        "371002002": "鲸园街道",
        "371002003": "竹岛街道",
        "371002011": "孙家疃街道",
        "371002100": "张村镇",
        "371002101": "羊亭镇",
        "371002102": "温泉镇",
        "371002400": "嵩山街道"
    },
    "371003": {
        "371003001": "龙山路街道",
        "371003002": "天福路街道",
        "371003003": "环山路街道",
        "371003100": "文登营镇",
        "371003101": "大水泊镇",
        "371003102": "张家产镇",
        "371003103": "高村镇",
        "371003104": "泽库镇",
        "371003105": "侯家镇",
        "371003106": "宋村镇",
        "371003107": "泽头镇",
        "371003108": "小观镇",
        "371003109": "葛家镇",
        "371003110": "米山镇",
        "371003111": "界石镇",
        "371003400": "开发区",
        "371003401": "埠口港管理委员会"
    },
    "371071": {"371071004": "怡园街道", "371071007": "田和街道", "371071108": "初村镇", "371071400": "双岛街道筹建处"},
    "371072": {
        "371072008": "皇冠街道",
        "371072009": "凤林街道",
        "371072010": "西苑街道",
        "371072103": "崮山镇",
        "371072105": "泊于镇",
        "371072106": "桥头镇"
    },
    "371073": {"371073107": "草庙子镇", "371073112": "汪疃镇", "371073113": "苘山镇", "371073400": "黄岚"},
    "371082": {
        "371082001": "宁津街道",
        "371082002": "港湾街道",
        "371082003": "桃园街道",
        "371082004": "王连街道",
        "371082005": "东山街道",
        "371082006": "斥山街道",
        "371082007": "崖头街道",
        "371082008": "城西街道",
        "371082009": "寻山街道",
        "371082010": "崂山街道",
        "371082101": "俚岛镇",
        "371082102": "成山镇",
        "371082103": "埠柳镇",
        "371082104": "港西镇",
        "371082105": "夏庄镇",
        "371082106": "崖西镇",
        "371082107": "荫子镇",
        "371082108": "滕家镇",
        "371082109": "大疃镇",
        "371082110": "上庄镇",
        "371082111": "虎山镇",
        "371082112": "人和镇"
    },
    "371083": {
        "371083001": "城区街道",
        "371083100": "夏村镇",
        "371083101": "乳山口镇",
        "371083102": "海阳所镇",
        "371083103": "白沙滩镇",
        "371083104": "大孤山镇",
        "371083105": "南黄镇",
        "371083106": "冯家镇",
        "371083107": "下初镇",
        "371083108": "午极镇",
        "371083109": "育黎镇",
        "371083110": "崖子镇",
        "371083111": "诸往镇",
        "371083112": "乳山寨镇",
        "371083114": "徐家镇"
    },
    "371100": {"371102": "东港区", "371103": "岚山区", "371121": "五莲县", "371122": "莒县", "371171": "日照经济技术开发区"},
    "371102": {
        "371102001": "日照街道",
        "371102002": "石臼街道",
        "371102004": "秦楼街道",
        "371102008": "卧龙山街道",
        "371102009": "两城街道",
        "371102100": "河山镇",
        "371102104": "后村镇",
        "371102106": "西湖镇",
        "371102107": "陈疃镇",
        "371102108": "南湖镇",
        "371102109": "三庄镇",
        "371102110": "涛雒镇"
    },
    "371103": {
        "371103001": "岚山头街道",
        "371103002": "安东卫街道",
        "371103100": "碑廓镇",
        "371103101": "虎山镇",
        "371103102": "巨峰镇",
        "371103103": "高兴镇",
        "371103105": "黄墩镇",
        "371103108": "中楼镇",
        "371103200": "前三岛乡"
    },
    "371121": {
        "371121001": "洪凝街道",
        "371121102": "街头镇",
        "371121103": "潮河镇",
        "371121104": "许孟镇",
        "371121105": "于里镇",
        "371121106": "汪湖镇",
        "371121107": "叩官镇",
        "371121108": "中至镇",
        "371121109": "高泽镇",
        "371121110": "松柏镇",
        "371121201": "石场乡",
        "371121202": "户部乡"
    },
    "371122": {
        "371122001": "城阳街道",
        "371122002": "店子集街道",
        "371122003": "陵阳街道",
        "371122004": "浮来山街道",
        "371122102": "招贤镇",
        "371122103": "阎庄镇",
        "371122104": "夏庄镇",
        "371122105": "刘官庄镇",
        "371122106": "峤山镇",
        "371122107": "小店镇",
        "371122109": "龙山镇",
        "371122110": "东莞镇",
        "371122114": "长岭镇",
        "371122115": "安庄镇",
        "371122116": "棋山镇",
        "371122117": "洛河镇",
        "371122118": "寨里河镇",
        "371122119": "桑园镇",
        "371122120": "果庄镇",
        "371122206": "库山乡",
        "371122400": "莒县经济开发区"
    },
    "371171": {"371171003": "奎山街道", "371171007": "北京路街道"},
    "371200": {"371202": "莱城区", "371203": "钢城区"},
    "371202": {
        "371202001": "凤城街道",
        "371202002": "张家洼街道",
        "371202003": "高庄街道",
        "371202004": "鹏泉街道",
        "371202100": "口镇",
        "371202101": "羊里镇",
        "371202102": "方下镇",
        "371202103": "牛泉镇",
        "371202105": "苗山镇",
        "371202106": "雪野镇",
        "371202107": "大王庄镇",
        "371202108": "寨里镇",
        "371202109": "杨庄镇",
        "371202110": "茶业口镇",
        "371202111": "和庄镇"
    },
    "371203": {"371203001": "艾山街道", "371203002": "里辛街道", "371203003": "汶源街道", "371203100": "颜庄镇", "371203103": "辛庄镇"},
    "371300": {
        "371302": "兰山区",
        "371311": "罗庄区",
        "371312": "河东区",
        "371321": "沂南县",
        "371322": "郯城县",
        "371323": "沂水县",
        "371324": "兰陵县",
        "371325": "费县",
        "371326": "平邑县",
        "371327": "莒南县",
        "371328": "蒙阴县",
        "371329": "临沭县",
        "371371": "临沂高新技术产业开发区",
        "371372": "临沂经济技术开发区",
        "371373": "临沂临港经济开发区"
    },
    "371302": {
        "371302001": "兰山街道",
        "371302002": "银雀山街道",
        "371302003": "金雀山街道",
        "371302004": "柳青街道",
        "371302101": "白沙埠镇",
        "371302102": "枣园镇",
        "371302103": "半程镇",
        "371302105": "义堂镇",
        "371302107": "李官镇",
        "371302109": "方城镇",
        "371302110": "汪沟镇"
    },
    "371311": {
        "371311001": "罗庄街道",
        "371311002": "傅庄街道",
        "371311003": "盛庄街道",
        "371311006": "册山街道",
        "371311007": "高都街道",
        "371311100": "沂堂镇",
        "371311101": "褚墩镇",
        "371311102": "黄山镇"
    },
    "371312": {
        "371312001": "九曲街道",
        "371312004": "相公街道",
        "371312005": "太平街道",
        "371312006": "汤头街道",
        "371312007": "凤凰岭街道",
        "371312104": "汤河镇",
        "371312107": "八湖镇",
        "371312108": "郑旺镇"
    },
    "371321": {
        "371321001": "界湖街道",
        "371321101": "岸堤镇",
        "371321102": "孙祖镇",
        "371321103": "双堠镇",
        "371321104": "青驼镇",
        "371321105": "张庄镇",
        "371321106": "砖埠镇",
        "371321109": "大庄镇",
        "371321110": "辛集镇",
        "371321111": "蒲汪镇",
        "371321112": "湖头镇",
        "371321113": "苏村镇",
        "371321114": "铜井镇",
        "371321115": "依汶镇",
        "371321201": "马牧池乡"
    },
    "371322": {
        "371322001": "郯城街道",
        "371322101": "马头镇",
        "371322102": "重坊镇",
        "371322103": "李庄镇",
        "371322105": "杨集镇",
        "371322107": "港上镇",
        "371322108": "高峰头镇",
        "371322109": "庙山镇",
        "371322111": "红花镇",
        "371322112": "胜利镇",
        "371322202": "花园乡",
        "371322203": "归昌乡",
        "371322205": "泉源乡"
    },
    "371323": {
        "371323001": "沂城街道",
        "371323002": "龙家圈街道",
        "371323101": "马站镇",
        "371323102": "高桥镇",
        "371323103": "许家湖镇",
        "371323104": "黄山铺镇",
        "371323106": "诸葛镇",
        "371323107": "崔家峪镇",
        "371323108": "四十里堡镇",
        "371323109": "杨庄镇",
        "371323110": "夏蔚镇",
        "371323111": "沙沟镇",
        "371323112": "高庄镇",
        "371323113": "院东头镇",
        "371323115": "富官庄镇",
        "371323116": "道托镇",
        "371323117": "泉庄镇",
        "371323201": "圈里乡"
    },
    "371324": {
        "371324001": "卞庄街道",
        "371324002": "苍山街道",
        "371324101": "大仲村镇",
        "371324102": "兰陵镇",
        "371324103": "长城镇",
        "371324104": "磨山镇",
        "371324105": "神山镇",
        "371324106": "车辋镇",
        "371324107": "尚岩镇",
        "371324108": "向城镇",
        "371324109": "新兴镇",
        "371324110": "南桥镇",
        "371324112": "庄坞镇",
        "371324114": "鲁城镇",
        "371324115": "矿坑镇",
        "371324117": "芦柞镇",
        "371324202": "下村乡"
    },
    "371325": {
        "371325001": "费城街道",
        "371325101": "上冶镇",
        "371325102": "薛庄镇",
        "371325105": "探沂镇",
        "371325106": "朱田镇",
        "371325107": "梁邱镇",
        "371325108": "新庄镇",
        "371325109": "马庄镇",
        "371325112": "胡阳镇",
        "371325113": "石井镇",
        "371325115": "东蒙镇",
        "371325200": "大田庄乡"
    },
    "371326": {
        "371326001": "平邑街道",
        "371326101": "仲村镇",
        "371326102": "武台镇",
        "371326103": "保太镇",
        "371326104": "柏林镇",
        "371326105": "卞桥镇",
        "371326106": "地方镇",
        "371326107": "铜石镇",
        "371326108": "温水镇",
        "371326109": "流峪镇",
        "371326110": "郑城镇",
        "371326111": "白彦镇",
        "371326112": "临涧镇",
        "371326113": "丰阳镇"
    },
    "371327": {
        "371327001": "十字路街道",
        "371327102": "大店镇",
        "371327103": "坊前镇",
        "371327106": "板泉镇",
        "371327107": "洙边镇",
        "371327108": "文疃镇",
        "371327111": "石莲子镇",
        "371327112": "岭泉镇",
        "371327113": "筵宾镇",
        "371327114": "涝坡镇",
        "371327116": "道口镇",
        "371327117": "相沟镇"
    },
    "371328": {
        "371328001": "蒙阴街道",
        "371328101": "常路镇",
        "371328102": "岱崮镇",
        "371328103": "坦埠镇",
        "371328104": "垛庄镇",
        "371328105": "高都镇",
        "371328106": "野店镇",
        "371328107": "桃墟镇",
        "371328109": "联城镇",
        "371328201": "旧寨乡"
    },
    "371329": {
        "371329001": "临沭街道",
        "371329002": "郑山街道",
        "371329101": "蛟龙镇",
        "371329102": "大兴镇",
        "371329103": "石门镇",
        "371329104": "曹庄镇",
        "371329108": "青云镇",
        "371329109": "玉山镇",
        "371329110": "店头镇"
    },
    "371371": {"371371001": "罗西街道", "371371100": "马厂湖镇"},
    "371372": {"371372001": "芝麻墩街道", "371372002": "梅埠街道", "371372003": "朝阳街道"},
    "371373": {"371373100": "坪上镇", "371373101": "团林镇", "371373102": "壮岗镇", "371373103": "朱芦镇"},
    "371400": {
        "371402": "德城区",
        "371403": "陵城区",
        "371422": "宁津县",
        "371423": "庆云县",
        "371424": "临邑县",
        "371425": "齐河县",
        "371426": "平原县",
        "371427": "夏津县",
        "371428": "武城县",
        "371471": "德州经济技术开发区",
        "371472": "德州运河经济开发区",
        "371481": "乐陵市",
        "371482": "禹城市"
    },
    "371402": {
        "371402001": "新湖街道",
        "371402002": "新华街道",
        "371402003": "天衢街道",
        "371402006": "广川街道",
        "371402100": "二屯镇",
        "371402101": "黄河涯镇"
    },
    "371403": {
        "371403001": "安德街道",
        "371403002": "临齐街道",
        "371403101": "郑家寨镇",
        "371403102": "糜镇",
        "371403103": "宋家镇",
        "371403104": "徽王庄镇",
        "371403105": "神头镇",
        "371403106": "滋镇",
        "371403107": "前孙镇",
        "371403108": "边临镇",
        "371403109": "义渡口镇",
        "371403110": "丁庄镇",
        "371403202": "于集乡"
    },
    "371422": {
        "371422001": "宁城街道",
        "371422002": "津城街道",
        "371422101": "柴胡店镇",
        "371422102": "长官镇",
        "371422103": "杜集镇",
        "371422104": "保店镇",
        "371422105": "大柳镇",
        "371422106": "大曹镇",
        "371422107": "相衙镇",
        "371422108": "时集镇",
        "371422109": "张大庄镇",
        "371422201": "刘营伍乡"
    },
    "371423": {
        "371423001": "渤海路街道",
        "371423100": "庆云镇",
        "371423101": "常家镇",
        "371423102": "尚堂镇",
        "371423103": "崔口镇",
        "371423104": "东辛店镇",
        "371423200": "严务乡",
        "371423202": "中丁乡",
        "371423203": "徐园子乡"
    },
    "371424": {
        "371424001": "邢侗街道",
        "371424002": "恒源街道",
        "371424003": "临盘街道",
        "371424100": "临邑镇",
        "371424102": "临南镇",
        "371424103": "德平镇",
        "371424104": "林子镇",
        "371424105": "兴隆镇",
        "371424106": "孟寺镇",
        "371424107": "翟家镇",
        "371424108": "理合务镇",
        "371424202": "宿安乡"
    },
    "371425": {
        "371425001": "晏城街道",
        "371425002": "晏北街道",
        "371425101": "表白寺镇",
        "371425102": "焦庙镇",
        "371425103": "赵官镇",
        "371425104": "祝阿镇",
        "371425105": "仁里集镇",
        "371425106": "潘店镇",
        "371425107": "胡官屯镇",
        "371425108": "宣章屯镇",
        "371425109": "马集镇",
        "371425110": "华店镇",
        "371425111": "刘桥镇",
        "371425201": "安头乡",
        "371425204": "大黄乡"
    },
    "371426": {
        "371426001": "龙门街道",
        "371426002": "桃园街道",
        "371426101": "王凤楼镇",
        "371426102": "前曹镇",
        "371426103": "恩城镇",
        "371426104": "王庙镇",
        "371426105": "王杲铺镇",
        "371426106": "张华镇",
        "371426107": "腰站镇",
        "371426108": "王打卦镇",
        "371426200": "坊子乡",
        "371426202": "三唐乡",
        "371426400": "平原县经济开发区"
    },
    "371427": {
        "371427001": "银城街道",
        "371427002": "北城街道",
        "371427100": "南城镇",
        "371427101": "苏留庄镇",
        "371427102": "新盛店镇",
        "371427103": "雷集镇",
        "371427104": "郑保屯镇",
        "371427105": "白马湖镇",
        "371427106": "东李官屯镇",
        "371427107": "宋楼镇",
        "371427108": "香赵庄镇",
        "371427109": "双庙镇",
        "371427200": "渡口驿乡",
        "371427204": "田庄乡"
    },
    "371428": {
        "371428001": "广运街道",
        "371428100": "武城镇",
        "371428101": "老城镇",
        "371428103": "鲁权屯镇",
        "371428104": "郝王庄镇",
        "371428106": "甲马营镇",
        "371428107": "四女寺镇",
        "371428108": "李家户镇"
    },
    "371471": {"371471007": "长河街道", "371471008": "宋官屯街道", "371471106": "赵虎镇", "371471107": "抬头寺镇", "371471108": "袁桥镇"},
    "371472": {"371472005": "运河街道"},
    "371481": {
        "371481001": "市中街道",
        "371481002": "胡家街道",
        "371481003": "云红街道",
        "371481004": "郭家街道",
        "371481100": "杨安镇",
        "371481101": "朱集镇",
        "371481102": "黄夹镇",
        "371481103": "丁坞镇",
        "371481104": "花园镇",
        "371481105": "郑店镇",
        "371481106": "化楼镇",
        "371481107": "孔镇",
        "371481108": "铁营镇",
        "371481200": "西段乡",
        "371481201": "大孙乡",
        "371481203": "寨头堡乡"
    },
    "371482": {
        "371482001": "市中街道",
        "371482100": "伦镇",
        "371482101": "房寺镇",
        "371482102": "张庄镇",
        "371482103": "辛店镇",
        "371482104": "安仁镇",
        "371482105": "辛寨镇",
        "371482106": "梁家镇",
        "371482107": "十里望回族镇",
        "371482108": "莒镇",
        "371482200": "李屯乡",
        "371482400": "东城街道"
    },
    "371500": {
        "371502": "东昌府区",
        "371521": "阳谷县",
        "371522": "莘县",
        "371523": "茌平县",
        "371524": "东阿县",
        "371525": "冠县",
        "371526": "高唐县",
        "371581": "临清市"
    },
    "371502": {
        "371502001": "古楼街道",
        "371502002": "柳园街道",
        "371502003": "新区街道",
        "371502004": "湖西街道",
        "371502005": "道口铺街道",
        "371502006": "阎寺街道",
        "371502008": "北城街道",
        "371502009": "东城街道",
        "371502010": "蒋官屯街道",
        "371502011": "李海务街道",
        "371502100": "侯营镇",
        "371502101": "沙镇镇",
        "371502102": "堂邑镇",
        "371502103": "梁水镇",
        "371502104": "斗虎屯镇",
        "371502105": "郑家镇",
        "371502106": "张炉集镇",
        "371502107": "于集镇",
        "371502108": "许营镇",
        "371502109": "朱老庄镇",
        "371502110": "顾官屯镇",
        "371502202": "广平乡",
        "371502203": "韩集乡",
        "371502400": "香江管委会"
    },
    "371521": {
        "371521001": "博济桥街道",
        "371521002": "侨润街道",
        "371521003": "狮子楼街道",
        "371521101": "阎楼镇",
        "371521103": "阿城镇",
        "371521104": "七级镇",
        "371521105": "安乐镇",
        "371521106": "定水镇",
        "371521107": "石佛镇",
        "371521108": "李台镇",
        "371521109": "寿张镇",
        "371521111": "十五里园镇",
        "371521112": "张秋镇",
        "371521113": "郭店屯镇",
        "371521114": "西湖镇",
        "371521115": "高庙王镇",
        "371521116": "金斗营镇",
        "371521203": "大布乡"
    },
    "371522": {
        "371522001": "燕塔街道",
        "371522002": "莘亭街道",
        "371522003": "莘州街道",
        "371522004": "东鲁街道",
        "371522101": "张鲁镇",
        "371522102": "朝城镇",
        "371522103": "观城镇",
        "371522104": "古城镇",
        "371522105": "大张家镇",
        "371522106": "古云镇",
        "371522107": "十八里铺镇",
        "371522109": "燕店镇",
        "371522110": "董杜庄镇",
        "371522111": "王奉镇",
        "371522112": "樱桃园镇",
        "371522113": "河店镇",
        "371522114": "妹冢镇",
        "371522115": "魏庄镇",
        "371522116": "张寨镇",
        "371522117": "大王寨镇",
        "371522118": "徐庄镇",
        "371522119": "王庄集镇",
        "371522120": "柿子园镇",
        "371522121": "俎店镇"
    },
    "371523": {
        "371523001": "振兴街道",
        "371523002": "信发街道",
        "371523003": "温陈街道",
        "371523101": "乐平铺镇",
        "371523102": "冯官屯镇",
        "371523103": "菜屯镇",
        "371523104": "博平镇",
        "371523105": "杜郎口镇",
        "371523106": "韩屯镇",
        "371523107": "胡屯镇",
        "371523108": "肖庄镇",
        "371523109": "贾寨镇",
        "371523110": "洪官屯镇",
        "371523208": "杨官屯乡"
    },
    "371524": {
        "371524001": "铜城街道",
        "371524002": "新城街道",
        "371524101": "刘集镇",
        "371524102": "牛角店镇",
        "371524103": "大桥镇",
        "371524104": "高集镇",
        "371524105": "姜楼镇",
        "371524107": "姚寨镇",
        "371524108": "鱼山镇",
        "371524109": "陈集镇"
    },
    "371525": {
        "371525001": "清泉街道",
        "371525002": "崇文街道",
        "371525003": "烟庄街道",
        "371525101": "贾镇",
        "371525102": "桑阿镇",
        "371525103": "柳林镇",
        "371525104": "清水镇",
        "371525105": "东古城镇",
        "371525106": "北馆陶镇",
        "371525107": "店子镇",
        "371525108": "定远寨镇",
        "371525109": "辛集镇",
        "371525110": "梁堂镇",
        "371525111": "范寨镇",
        "371525200": "斜店乡",
        "371525205": "甘官屯乡",
        "371525206": "兰沃乡",
        "371525209": "万善乡"
    },
    "371526": {
        "371526001": "鱼丘湖街道",
        "371526002": "汇鑫街道",
        "371526003": "人和街道",
        "371526101": "梁村镇",
        "371526102": "尹集镇",
        "371526103": "清平镇",
        "371526104": "固河镇",
        "371526105": "三十里铺镇",
        "371526106": "琉璃寺镇",
        "371526107": "赵寨子镇",
        "371526108": "姜店镇",
        "371526109": "杨屯镇"
    },
    "371581": {
        "371581001": "青年路街道",
        "371581002": "新华路街道",
        "371581003": "先锋路街道",
        "371581004": "大辛庄街道",
        "371581101": "松林镇",
        "371581102": "老赵庄镇",
        "371581103": "康庄镇",
        "371581104": "魏湾镇",
        "371581105": "刘垓子镇",
        "371581107": "八岔路镇",
        "371581108": "潘庄镇",
        "371581109": "烟店镇",
        "371581110": "唐园镇",
        "371581111": "金郝庄镇",
        "371581112": "戴湾镇",
        "371581113": "尚店镇"
    },
    "371600": {
        "371602": "滨城区",
        "371603": "沾化区",
        "371621": "惠民县",
        "371622": "阳信县",
        "371623": "无棣县",
        "371625": "博兴县",
        "371681": "邹平市"
    },
    "371602": {
        "371602001": "市中街道",
        "371602002": "市西街道",
        "371602003": "北镇街道",
        "371602004": "市东街道",
        "371602005": "彭李街道",
        "371602007": "小营街道",
        "371602008": "滨北街道",
        "371602009": "梁才街道",
        "371602010": "杜店街道",
        "371602011": "沙河街道",
        "371602012": "里则街道",
        "371602013": "青田街道",
        "371602105": "三河湖镇",
        "371602106": "杨柳雪镇",
        "371602203": "秦皇台乡"
    },
    "371603": {
        "371603001": "富国街道",
        "371603002": "富源街道",
        "371603101": "下洼镇",
        "371603102": "古城镇",
        "371603103": "冯家镇",
        "371603104": "泊头镇",
        "371603105": "大高镇",
        "371603106": "黄升镇",
        "371603107": "滨海镇",
        "371603203": "下河乡",
        "371603204": "利国乡",
        "371603205": "海防乡"
    },
    "371621": {
        "371621001": "孙武街道",
        "371621003": "何坊街道",
        "371621101": "石庙镇",
        "371621102": "桑落墅镇",
        "371621103": "淄角镇",
        "371621104": "胡集镇",
        "371621105": "李庄镇",
        "371621107": "麻店镇",
        "371621108": "魏集镇",
        "371621109": "清河镇",
        "371621111": "姜楼镇",
        "371621113": "辛店镇",
        "371621114": "大年陈镇",
        "371621115": "皂户李镇"
    },
    "371622": {
        "371622001": "信城街道",
        "371622002": "金阳街道",
        "371622101": "商店镇",
        "371622102": "温店镇",
        "371622103": "河流镇",
        "371622104": "翟王镇",
        "371622105": "流坡坞镇",
        "371622106": "水落坡镇",
        "371622107": "劳店镇",
        "371622204": "洋湖乡"
    },
    "371623": {
        "371623001": "棣丰街道",
        "371623002": "海丰街道",
        "371623101": "水湾镇",
        "371623102": "碣石山镇",
        "371623103": "小泊头镇",
        "371623104": "埕口镇",
        "371623105": "马山子镇",
        "371623106": "车王镇",
        "371623107": "柳堡镇",
        "371623108": "佘家镇",
        "371623109": "信阳镇",
        "371623110": "西小王镇"
    },
    "371625": {
        "371625001": "城东街道",
        "371625002": "锦秋街道",
        "371625003": "博昌街道",
        "371625101": "曹王镇",
        "371625102": "兴福镇",
        "371625103": "陈户镇",
        "371625104": "湖滨镇",
        "371625106": "店子镇",
        "371625107": "吕艺镇",
        "371625108": "纯化镇",
        "371625109": "庞家镇",
        "371625110": "乔庄镇"
    },
    "371681": {
        "371681001": "黛溪街道",
        "371681002": "黄山街道",
        "371681003": "高新街道",
        "371681004": "好生街道",
        "371681005": "西董街道",
        "371681101": "长山镇",
        "371681102": "魏桥镇",
        "371681105": "临池镇",
        "371681108": "焦桥镇",
        "371681109": "韩店镇",
        "371681110": "孙镇镇",
        "371681111": "九户镇",
        "371681112": "青阳镇",
        "371681113": "明集镇",
        "371681114": "台子镇",
        "371681115": "码头镇"
    },
    "371700": {
        "371702": "牡丹区",
        "371703": "定陶区",
        "371721": "曹县",
        "371722": "单县",
        "371723": "成武县",
        "371724": "巨野县",
        "371725": "郓城县",
        "371726": "鄄城县",
        "371728": "东明县",
        "371771": "菏泽经济技术开发区",
        "371772": "菏泽高新技术开发区"
    },
    "371702": {
        "371702001": "东城街道",
        "371702002": "西城街道",
        "371702003": "南城街道",
        "371702004": "北城街道",
        "371702005": "牡丹街道",
        "371702009": "何楼街道",
        "371702011": "皇镇街道",
        "371702100": "沙土镇",
        "371702101": "吴店镇",
        "371702102": "王浩屯镇",
        "371702103": "黄堽镇",
        "371702104": "都司镇",
        "371702105": "高庄镇",
        "371702106": "小留镇",
        "371702107": "李村镇",
        "371702108": "马岭岗镇",
        "371702109": "安兴镇",
        "371702110": "大黄集镇",
        "371702112": "胡集镇"
    },
    "371703": {
        "371703001": "天中街道",
        "371703002": "滨河街道",
        "371703101": "陈集镇",
        "371703102": "冉固镇",
        "371703103": "张湾镇",
        "371703104": "黄店镇",
        "371703105": "孟海镇",
        "371703106": "马集镇",
        "371703107": "仿山镇",
        "371703108": "半堤镇",
        "371703109": "杜堂镇",
        "371703110": "南王店镇"
    },
    "371721": {
        "371721001": "曹城街道",
        "371721002": "磐石街道",
        "371721003": "青菏街道",
        "371721004": "郑庄街道",
        "371721005": "倪集街道",
        "371721101": "庄寨镇",
        "371721102": "普连集镇",
        "371721103": "青固集镇",
        "371721104": "桃源集镇",
        "371721105": "韩集镇",
        "371721106": "砖庙镇",
        "371721107": "古营集镇",
        "371721108": "魏湾镇",
        "371721109": "侯集回族镇",
        "371721110": "苏集镇",
        "371721111": "孙老家镇",
        "371721112": "阎店楼镇",
        "371721113": "梁堤头镇",
        "371721114": "安才楼镇",
        "371721115": "曹县邵庄镇",
        "371721116": "曹县王集镇",
        "371721117": "青岗集镇",
        "371721120": "常乐集镇",
        "371721121": "曹县大集镇",
        "371721122": "曹县仵楼镇",
        "371721203": "曹县楼庄乡",
        "371721207": "曹县朱洪庙乡"
    },
    "371722": {
        "371722001": "北城街道",
        "371722002": "南城街道",
        "371722003": "园艺街道",
        "371722004": "东城街道",
        "371722101": "郭村镇",
        "371722102": "黄岗镇",
        "371722103": "终兴镇",
        "371722105": "高韦庄镇",
        "371722106": "徐寨镇",
        "371722107": "蔡堂镇",
        "371722108": "朱集镇",
        "371722109": "李新庄镇",
        "371722110": "浮岗镇",
        "371722111": "莱河镇",
        "371722112": "时楼镇",
        "371722113": "杨楼镇",
        "371722114": "张集镇",
        "371722115": "龙王庙镇",
        "371722116": "谢集镇",
        "371722117": "李田楼镇",
        "371722201": "高老家乡",
        "371722202": "曹庄乡"
    },
    "371723": {
        "371723001": "文亭街道",
        "371723002": "永昌街道",
        "371723101": "成武县大田集镇",
        "371723102": "成武县天宫庙镇",
        "371723103": "成武县汶上集镇",
        "371723104": "成武县南鲁集镇",
        "371723105": "成武县伯乐集镇",
        "371723106": "成武县苟村集镇",
        "371723107": "成武县白浮图镇",
        "371723108": "成武县孙寺镇",
        "371723109": "成武县九女集镇",
        "371723110": "成武县党集镇",
        "371723111": "成武县张楼镇"
    },
    "371724": {
        "371724001": "凤凰街道",
        "371724002": "永丰街道",
        "371724101": "龙固镇",
        "371724102": "大义镇",
        "371724103": "柳林镇",
        "371724104": "章缝镇",
        "371724105": "大谢集镇",
        "371724106": "独山镇",
        "371724107": "麒麟镇",
        "371724108": "核桃园镇",
        "371724109": "田庄镇",
        "371724110": "太平镇",
        "371724111": "万丰镇",
        "371724112": "陶庙镇",
        "371724113": "董官屯镇",
        "371724114": "田桥镇",
        "371724115": "营里镇"
    },
    "371725": {
        "371725001": "郓州街道",
        "371725002": "唐塔街道",
        "371725101": "黄安镇",
        "371725102": "杨庄集镇",
        "371725103": "侯咽集镇",
        "371725104": "武安镇",
        "371725105": "郭屯镇",
        "371725106": "丁里长镇",
        "371725107": "玉皇庙镇",
        "371725108": "程屯镇",
        "371725109": "随官屯镇",
        "371725110": "张营镇",
        "371725111": "潘渡镇",
        "371725112": "双桥镇",
        "371725113": "南赵楼镇",
        "371725114": "黄泥冈镇",
        "371725115": "唐庙镇",
        "371725116": "李集镇",
        "371725117": "黄集镇",
        "371725118": "张鲁集镇",
        "371725208": "水堡乡",
        "371725209": "陈坡乡"
    },
    "371726": {
        "371726001": "陈王街道",
        "371726002": "古泉街道",
        "371726101": "什集镇",
        "371726102": "红船镇",
        "371726103": "旧城镇",
        "371726104": "闫什镇",
        "371726105": "箕山镇",
        "371726106": "李进士堂镇",
        "371726107": "董口镇",
        "371726108": "临濮镇",
        "371726109": "彭楼镇",
        "371726110": "凤凰镇",
        "371726111": "郑营镇",
        "371726112": "大埝镇",
        "371726113": "引马镇",
        "371726200": "左营乡",
        "371726207": "富春乡"
    },
    "371728": {
        "371728001": "城关街道",
        "371728002": "鱼沃街道",
        "371728101": "东明集镇",
        "371728102": "刘楼镇",
        "371728103": "陆圈镇",
        "371728104": "马头镇",
        "371728105": "三春集镇",
        "371728106": "大屯镇",
        "371728107": "武胜桥镇",
        "371728108": "菜园集镇",
        "371728109": "沙窝镇",
        "371728110": "小井镇",
        "371728204": "长兴集乡",
        "371728205": "焦园乡"
    },
    "371771": {"371771001": "丹阳街道", "371771002": "岳程街道", "371771003": "佃户屯街道办"},
    "371772": {"371772001": "万福街道", "371772100": "吕陵镇"},
    "410000": {
        "410100": "郑州市",
        "410200": "开封市",
        "410300": "洛阳市",
        "410400": "平顶山市",
        "410500": "安阳市",
        "410600": "鹤壁市",
        "410700": "新乡市",
        "410800": "焦作市",
        "410900": "濮阳市",
        "411000": "许昌市",
        "411100": "漯河市",
        "411200": "三门峡市",
        "411300": "南阳市",
        "411400": "商丘市",
        "411500": "信阳市",
        "411600": "周口市",
        "411700": "驻马店市",
        "419001": "济源市"
    },
    "410100": {
        "410102": "中原区",
        "410103": "二七区",
        "410104": "管城回族区",
        "410105": "金水区",
        "410106": "上街区",
        "410108": "惠济区",
        "410122": "中牟县",
        "410171": "郑州经济技术开发区",
        "410172": "郑州高新技术产业开发区",
        "410173": "郑州航空港经济综合实验区",
        "410181": "巩义市",
        "410182": "荥阳市",
        "410183": "新密市",
        "410184": "新郑市",
        "410185": "登封市"
    },
    "410102": {
        "410102001": "林山寨街道",
        "410102002": "建设路街道",
        "410102003": "棉纺路街道",
        "410102004": "秦岭路街道",
        "410102005": "桐柏路街道",
        "410102006": "三官庙街道",
        "410102007": "绿东村街道",
        "410102008": "汝河路街道",
        "410102009": "航海西路街道",
        "410102010": "中原西路街道",
        "410102011": "西流湖街道",
        "410102012": "须水街道"
    },
    "410103": {
        "410103001": "淮河路街道",
        "410103002": "解放路街道",
        "410103003": "铭功路街道",
        "410103004": "一马路街道",
        "410103005": "蜜蜂张街道",
        "410103006": "五里堡街道",
        "410103007": "大学路街道",
        "410103008": "建中街街道",
        "410103009": "福华街街道",
        "410103010": "德化街街道",
        "410103011": "嵩山路街道",
        "410103012": "京广路街道",
        "410103013": "长江路街道",
        "410103015": "人和路街道",
        "410103016": "侯寨街道",
        "410103100": "马寨镇"
    },
    "410104": {
        "410104001": "北下街街道",
        "410104002": "西大街街道",
        "410104003": "南关街道",
        "410104004": "城东路街道",
        "410104005": "东大街街道",
        "410104006": "二里岗街道",
        "410104007": "陇海马路街道",
        "410104008": "紫荆山南路街道",
        "410104009": "航海东路街道",
        "410104010": "十八里河街道",
        "410104201": "南曹乡",
        "410104202": "圃田乡",
        "410104570": "商都路街道"
    },
    "410105": {
        "410105001": "经八路街道",
        "410105002": "花园路街道",
        "410105003": "人民路街道",
        "410105004": "杜岭街道",
        "410105005": "大石桥街道",
        "410105006": "南阳路街道",
        "410105007": "南阳新村街道",
        "410105008": "文化路街道",
        "410105009": "丰产路街道",
        "410105010": "东风路街道",
        "410105011": "北林路街道",
        "410105012": "未来路街道",
        "410105013": "凤凰台街道",
        "410105014": "龙子湖街道",
        "410105015": "祭城路街道",
        "410105016": "兴达路街道",
        "410105017": "国基路街道",
        "410105018": "杨金路街道",
        "410105019": "丰庆路街道",
        "410105560": "如意湖街道",
        "410105561": "博学路街道",
        "410105562": "龙湖街道",
        "410105563": "龙源路街道",
        "410105564": "金光路街道"
    },
    "410106": {
        "410106001": "济源路街道",
        "410106002": "中心路街道",
        "410106003": "新安路街道",
        "410106004": "工业路街道",
        "410106005": "矿山街道",
        "410106100": "峡窝镇"
    },
    "410108": {
        "410108001": "新城街道",
        "410108002": "刘寨街道",
        "410108003": "江山路街道",
        "410108004": "长兴路街道",
        "410108005": "迎宾路街道",
        "410108006": "大河路街道",
        "410108100": "花园口镇",
        "410108101": "古荥镇"
    },
    "410122": {
        "410122001": "青年路街道",
        "410122002": "东风路街道",
        "410122003": "广惠街街道",
        "410122101": "韩寺镇",
        "410122102": "官渡镇",
        "410122103": "狼城岗镇",
        "410122104": "万滩镇",
        "410122105": "白沙镇",
        "410122106": "郑庵镇",
        "410122109": "黄店镇",
        "410122110": "大孟镇",
        "410122112": "刘集镇",
        "410122114": "雁鸣湖镇",
        "410122115": "姚家镇",
        "410122208": "刁家乡",
        "410122570": "杨桥",
        "410122571": "豫兴"
    },
    "410171": {
        "410171111": "九龙镇",
        "410171560": "经济开发区明湖街道",
        "410171561": "经济开发区潮河街道",
        "410171562": "京航街道",
        "410171563": "前程",
        "410171565": "祥云"
    },
    "410172": {"410172160": "石佛镇", "410172360": "沟赵乡", "410172560": "枫杨", "410172561": "梧桐", "410172562": "双桥"},
    "410173": {
        "410173108": "张庄镇",
        "410173113": "八岗镇",
        "410173116": "三官庙镇",
        "410173202": "八千乡",
        "410173203": "龙王乡",
        "410173400": "新港",
        "410173401": "郑港",
        "410173404": "银河",
        "410173405": "明港",
        "410173580": "滨河",
        "410173581": "冯堂",
        "410173582": "清河",
        "410173583": "龙港"
    },
    "410181": {
        "410181001": "新华路街道",
        "410181002": "杜甫路街道",
        "410181003": "永安路街道",
        "410181004": "孝义街道",
        "410181005": "紫荆路街道",
        "410181100": "米河镇",
        "410181101": "新中镇",
        "410181102": "小关镇",
        "410181103": "竹林镇",
        "410181104": "大峪沟镇",
        "410181105": "河洛镇",
        "410181106": "站街镇",
        "410181108": "康店镇",
        "410181110": "北山口镇",
        "410181111": "西村镇",
        "410181112": "芝田镇",
        "410181113": "回郭镇",
        "410181114": "鲁庄镇",
        "410181115": "夹津口镇",
        "410181116": "涉村镇"
    },
    "410182": {
        "410182001": "索河街道",
        "410182002": "京城路街道",
        "410182101": "乔楼镇",
        "410182102": "豫龙镇",
        "410182103": "广武镇",
        "410182104": "王村镇",
        "410182105": "汜水镇",
        "410182106": "高山镇",
        "410182108": "刘河镇",
        "410182109": "崔庙镇",
        "410182110": "贾峪镇",
        "410182200": "城关乡",
        "410182201": "高村乡",
        "410182204": "金寨回族乡"
    },
    "410183": {
        "410183001": "青屏街街道",
        "410183002": "新华路街道",
        "410183003": "西大街街道",
        "410183100": "城关镇",
        "410183101": "米村镇",
        "410183102": "牛店镇",
        "410183103": "平陌镇",
        "410183104": "超化镇",
        "410183105": "苟堂镇",
        "410183106": "大隗镇",
        "410183107": "刘寨镇",
        "410183108": "白寨镇",
        "410183109": "岳村镇",
        "410183110": "来集镇",
        "410183113": "曲梁镇",
        "410183200": "袁庄乡",
        "410183400": "矿区街道",
        "410183401": "尖山风景区管理委员会",
        "410183402": "郑州曲梁产业集聚区管理委员会"
    },
    "410184": {
        "410184001": "新建路街道",
        "410184002": "新华路街道",
        "410184003": "新烟街道",
        "410184101": "新村镇",
        "410184102": "辛店镇",
        "410184103": "观音寺镇",
        "410184104": "梨河镇",
        "410184105": "和庄镇",
        "410184106": "薛店镇",
        "410184107": "孟庄镇",
        "410184108": "郭店镇",
        "410184109": "龙湖镇",
        "410184200": "城关乡",
        "410184402": "具茨山国家级森林公园管理委员会",
        "410184403": "中心城区新区建设管理委员会"
    },
    "410185": {
        "410185001": "嵩阳街道",
        "410185002": "少林街道",
        "410185003": "中岳街道",
        "410185100": "大金店镇",
        "410185101": "颍阳镇",
        "410185102": "卢店镇",
        "410185103": "告成镇",
        "410185104": "阳城区镇",
        "410185105": "大冶镇",
        "410185106": "宣化镇",
        "410185107": "徐庄镇",
        "410185108": "东华镇",
        "410185109": "唐庄镇",
        "410185202": "白坪乡",
        "410185203": "君召乡",
        "410185204": "石道乡",
        "410185400": "送表矿区"
    },
    "410200": {
        "410202": "龙亭区",
        "410203": "顺河回族区",
        "410204": "鼓楼区",
        "410205": "禹王台区",
        "410212": "祥符区",
        "410221": "杞县",
        "410222": "通许县",
        "410223": "尉氏县",
        "410225": "兰考县"
    },
    "410202": {
        "410202001": "北书店街道",
        "410202002": "午朝门街道",
        "410202003": "大兴街道",
        "410202005": "北道门街道",
        "410202006": "城西街道",
        "410202007": "梁苑街道",
        "410202008": "宋城",
        "410202009": "新城街道",
        "410202100": "杏花营镇",
        "410202201": "北郊乡",
        "410202202": "柳园口乡",
        "410202203": "西郊乡",
        "410202204": "水稻乡",
        "410202400": "杏花营农场"
    },
    "410203": {
        "410203001": "清平街道",
        "410203002": "铁塔街道",
        "410203003": "曹门街道",
        "410203004": "宋门街道",
        "410203005": "工业街道",
        "410203006": "苹果园街道",
        "410203200": "东郊乡",
        "410203201": "土柏岗乡"
    },
    "410204": {
        "410204001": "相国寺街道",
        "410204002": "新华街道",
        "410204003": "卧龙街道",
        "410204004": "州桥街道",
        "410204005": "西司门街道",
        "410204006": "南苑街道",
        "410204007": "五一街道",
        "410204008": "仙人庄街道"
    },
    "410205": {
        "410205001": "三里堡街道",
        "410205002": "新门关街道",
        "410205003": "繁塔街道",
        "410205004": "官坊街道",
        "410205005": "菜市街道",
        "410205200": "南郊乡",
        "410205201": "汪屯乡"
    },
    "410212": {
        "410212100": "城关镇",
        "410212101": "陈留镇",
        "410212102": "仇楼镇",
        "410212103": "八里湾镇",
        "410212104": "曲兴镇",
        "410212106": "朱仙镇",
        "410212200": "半坡店乡",
        "410212202": "罗王乡",
        "410212203": "刘店乡",
        "410212204": "袁坊乡",
        "410212205": "杜良乡",
        "410212206": "兴隆乡",
        "410212208": "西姜寨乡",
        "410212210": "万隆乡",
        "410212211": "范村乡"
    },
    "410221": {
        "410221100": "城关镇",
        "410221101": "五里河镇",
        "410221102": "傅集镇",
        "410221103": "圉镇镇",
        "410221104": "高阳镇",
        "410221105": "葛岗镇",
        "410221106": "阳堌镇",
        "410221107": "邢口镇",
        "410221200": "裴村店乡",
        "410221202": "宗店乡",
        "410221203": "板木乡",
        "410221204": "竹林乡",
        "410221205": "官庄乡",
        "410221206": "湖岗乡",
        "410221207": "苏木乡",
        "410221208": "沙沃乡",
        "410221209": "平城乡",
        "410221210": "泥沟乡",
        "410221211": "柿园乡",
        "410221212": "西寨乡",
        "410221213": "城郊乡",
        "410221401": "工业园区"
    },
    "410222": {
        "410222001": "咸平街道",
        "410222101": "竖岗镇",
        "410222102": "玉皇庙镇",
        "410222103": "四所楼镇",
        "410222104": "朱砂镇",
        "410222105": "长智镇",
        "410222200": "冯庄乡",
        "410222201": "孙营乡",
        "410222202": "大岗李乡",
        "410222203": "邸阁乡",
        "410222204": "练城乡",
        "410222205": "厉庄乡"
    },
    "410223": {
        "410223100": "城关镇",
        "410223101": "洧川镇",
        "410223102": "朱曲镇",
        "410223103": "蔡庄镇",
        "410223104": "永兴镇",
        "410223105": "张市镇",
        "410223106": "十八里镇",
        "410223107": "水坡镇",
        "410223108": "大营镇",
        "410223109": "庄头镇",
        "410223200": "邢庄乡",
        "410223203": "大马乡",
        "410223204": "岗李乡",
        "410223205": "门楼任乡",
        "410223206": "大桥乡",
        "410223207": "南曹乡",
        "410223208": "小陈乡"
    },
    "410225": {
        "410225001": "兰阳街道",
        "410225002": "桐乡街道",
        "410225003": "惠安街道",
        "410225101": "堌阳镇",
        "410225102": "南彰镇",
        "410225103": "考城镇",
        "410225104": "红庙镇",
        "410225105": "谷营镇",
        "410225201": "三义寨乡",
        "410225202": "东坝头乡",
        "410225205": "小宋乡",
        "410225206": "孟寨乡",
        "410225207": "许河乡",
        "410225208": "葡萄架乡",
        "410225209": "阎楼乡",
        "410225210": "仪封乡",
        "410225400": "中原石油勘探三公司",
        "410225401": "仪封园艺场",
        "410225402": "造纸林场",
        "410225403": "柳林林场",
        "410225404": "西关农场",
        "410225405": "畜牧良种农场",
        "410225406": "固阳农科所"
    },
    "410300": {
        "410302": "老城区",
        "410303": "西工区",
        "410304": "瀍河回族区",
        "410305": "涧西区",
        "410306": "吉利区",
        "410311": "洛龙区",
        "410322": "孟津县",
        "410323": "新安县",
        "410324": "栾川县",
        "410325": "嵩县",
        "410326": "汝阳县",
        "410327": "宜阳县",
        "410328": "洛宁县",
        "410329": "伊川县",
        "410371": "洛阳高新技术产业开发区",
        "410381": "偃师市"
    },
    "410302": {
        "410302001": "西关街道",
        "410302002": "西南隅街道",
        "410302003": "西北隅街道",
        "410302004": "东南隅街道",
        "410302005": "东北隅街道",
        "410302006": "南关街道",
        "410302007": "洛浦街道",
        "410302008": "邙山街道",
        "410302009": "道北路街道"
    },
    "410303": {
        "410303001": "王城路街道",
        "410303002": "金谷园街道",
        "410303003": "西工街道",
        "410303004": "邙岭路街道",
        "410303006": "唐宫路街道",
        "410303007": "汉屯路街道",
        "410303008": "凯旋东路街道",
        "410303009": "洛北街道",
        "410303010": "红山街道"
    },
    "410304": {
        "410304001": "东关街道",
        "410304002": "瀍西街道",
        "410304003": "五股路街道",
        "410304004": "北窑街道",
        "410304005": "塔湾街道",
        "410304006": "杨文街道",
        "410304007": "华林街道",
        "410304200": "瀍河回族乡"
    },
    "410305": {
        "410305001": "湖北路街道",
        "410305002": "天津路街道",
        "410305003": "长春路街道",
        "410305004": "南昌路街道",
        "410305005": "长安路街道",
        "410305006": "重庆路街道",
        "410305007": "郑州路街道",
        "410305008": "武汉路街道",
        "410305009": "徐家营街道",
        "410305010": "珠江路街道",
        "410305011": "周山路街道",
        "410305012": "工农街道"
    },
    "410306": {"410306002": "西霞院街道", "410306003": "康乐街道", "410306004": "吉利街道", "410306005": "河阳街道"},
    "410311": {
        "410311001": "安乐街道",
        "410311002": "开元路街道",
        "410311003": "龙门石窟街道",
        "410311004": "关林街道",
        "410311005": "太康东路街道",
        "410311006": "古城街道",
        "410311007": "科技园街道",
        "410311008": "翠云路街道",
        "410311009": "龙门街道",
        "410311010": "学府街道",
        "410311102": "安乐镇",
        "410311103": "白马寺镇",
        "410311107": "李楼镇",
        "410311108": "丰李镇",
        "410311195": "诸葛镇",
        "410311196": "李村镇",
        "410311197": "庞村镇",
        "410311198": "寇店镇",
        "410311199": "佃庄镇"
    },
    "410322": {
        "410322100": "城关镇",
        "410322101": "会盟镇",
        "410322102": "平乐镇",
        "410322103": "送庄镇",
        "410322104": "白鹤镇",
        "410322105": "朝阳镇",
        "410322106": "小浪底镇",
        "410322107": "麻屯镇",
        "410322108": "横水镇",
        "410322109": "常袋镇"
    },
    "410323": {
        "410323100": "城关镇",
        "410323101": "石寺镇",
        "410323102": "五头镇",
        "410323103": "磁涧镇",
        "410323104": "铁门镇",
        "410323105": "南李村镇",
        "410323106": "北冶镇",
        "410323107": "仓头镇",
        "410323108": "正村镇",
        "410323109": "石井镇",
        "410323110": "青要山镇"
    },
    "410324": {
        "410324100": "城关镇",
        "410324101": "赤土店镇",
        "410324102": "合峪镇",
        "410324103": "潭头镇",
        "410324104": "三川镇",
        "410324105": "冷水镇",
        "410324106": "陶湾镇",
        "410324107": "石庙镇",
        "410324108": "庙子镇",
        "410324109": "狮子庙镇",
        "410324110": "白土镇",
        "410324111": "叫河镇",
        "410324200": "栾川乡",
        "410324203": "秋扒乡"
    },
    "410325": {
        "410325100": "城关镇",
        "410325101": "田湖镇",
        "410325102": "旧县镇",
        "410325103": "车村镇",
        "410325104": "闫庄镇",
        "410325105": "德亭镇",
        "410325106": "大章镇",
        "410325107": "白河镇",
        "410325108": "纸房镇",
        "410325109": "饭坡镇",
        "410325110": "九皋镇",
        "410325201": "大坪乡",
        "410325202": "库区乡",
        "410325203": "何村乡",
        "410325209": "黄庄乡",
        "410325210": "木植街乡",
        "410325400": "陶村林场",
        "410325401": "五马寺林场",
        "410325402": "王莽寨林场"
    },
    "410326": {
        "410326100": "城关镇",
        "410326101": "上店镇",
        "410326102": "付店镇",
        "410326103": "小店镇",
        "410326104": "三屯镇",
        "410326105": "刘店镇",
        "410326106": "内埠镇",
        "410326107": "陶营镇",
        "410326200": "柏树乡",
        "410326202": "十八盘乡",
        "410326203": "靳村乡",
        "410326204": "王坪乡",
        "410326209": "蔡店乡",
        "410326400": "大安工业园区"
    },
    "410327": {
        "410327100": "城关镇",
        "410327102": "柳泉镇",
        "410327103": "韩城镇",
        "410327104": "白杨镇",
        "410327105": "香鹿山镇",
        "410327106": "锦屏镇",
        "410327107": "三乡镇",
        "410327108": "张坞镇",
        "410327109": "莲庄镇",
        "410327110": "赵保镇",
        "410327111": "樊村镇",
        "410327112": "高村镇",
        "410327203": "盐镇乡",
        "410327208": "花果山乡",
        "410327209": "上观乡",
        "410327212": "董王庄乡"
    },
    "410328": {
        "410328100": "城关镇",
        "410328101": "王范回族镇",
        "410328102": "上戈镇",
        "410328103": "下峪镇",
        "410328104": "河底镇",
        "410328105": "兴华镇",
        "410328106": "东宋镇",
        "410328107": "马店镇",
        "410328108": "故县镇",
        "410328109": "赵村镇",
        "410328110": "长水镇",
        "410328111": "景阳镇",
        "410328200": "城郊乡",
        "410328205": "小界乡",
        "410328209": "罗岭乡",
        "410328213": "底张乡",
        "410328216": "陈吴乡",
        "410328217": "涧口乡"
    },
    "410329": {
        "410329001": "城关街道",
        "410329002": "河滨街道",
        "410329101": "鸣皋镇",
        "410329102": "水寨镇",
        "410329103": "彭婆镇",
        "410329104": "白沙镇",
        "410329105": "江左镇",
        "410329106": "高山镇",
        "410329107": "吕店镇",
        "410329108": "半坡镇",
        "410329109": "酒后镇",
        "410329110": "白元镇",
        "410329111": "鸦岭镇",
        "410329112": "葛寨镇",
        "410329203": "平等乡"
    },
    "410371": {"410371001": "瀛洲街道", "410371002": "辛店街道"},
    "410381": {
        "410381001": "商城街道",
        "410381002": "槐新街道",
        "410381003": "首阳山街道",
        "410381004": "伊洛街道",
        "410381103": "翟镇",
        "410381104": "岳滩镇",
        "410381105": "顾县镇",
        "410381106": "缑氏镇",
        "410381107": "府店镇",
        "410381108": "高龙镇",
        "410381113": "山化镇",
        "410381114": "邙岭镇",
        "410381115": "大口镇"
    },
    "410400": {
        "410402": "新华区",
        "410403": "卫东区",
        "410404": "石龙区",
        "410411": "湛河区",
        "410421": "宝丰县",
        "410422": "叶县",
        "410423": "鲁山县",
        "410425": "郏县",
        "410471": "平顶山高新技术产业开发区",
        "410472": "平顶山市新城区",
        "410481": "舞钢市",
        "410482": "汝州市"
    },
    "410402": {
        "410402001": "曙光街街道",
        "410402002": "光明路街道",
        "410402003": "中兴路街道",
        "410402004": "矿工路街道",
        "410402005": "西市场街道",
        "410402006": "新新街街道",
        "410402007": "青石山街道",
        "410402008": "湛河北路街道",
        "410402010": "西高皇街道",
        "410402100": "焦店镇",
        "410402400": "香山管委会"
    },
    "410403": {
        "410403001": "东安路街道",
        "410403002": "优越路街道",
        "410403003": "五一路街道",
        "410403004": "建设路街道",
        "410403005": "东环路街道",
        "410403006": "东工人镇街道",
        "410403007": "光华路街道",
        "410403008": "鸿鹰街道",
        "410403009": "申楼街道",
        "410403010": "北环路街道",
        "410403011": "东高皇街道",
        "410403012": "蒲城街道"
    },
    "410404": {"410404001": "高庄街道", "410404002": "龙兴街道", "410404003": "人民路街道", "410404004": "龙河街道"},
    "410411": {
        "410411001": "马庄街道",
        "410411002": "南环路街道",
        "410411003": "姚孟街道",
        "410411004": "九里山街道",
        "410411005": "轻工路街道",
        "410411006": "高阳路街道",
        "410411007": "北渡街道",
        "410411008": "荆山",
        "410411009": "河滨街道",
        "410411200": "曹镇乡"
    },
    "410421": {
        "410421001": "铁路街道",
        "410421100": "城关镇",
        "410421101": "周庄镇",
        "410421102": "闹店镇",
        "410421103": "石桥镇",
        "410421104": "商酒务镇",
        "410421105": "大营镇",
        "410421106": "张八桥镇",
        "410421107": "杨庄镇",
        "410421108": "赵庄镇",
        "410421200": "肖旗乡",
        "410421202": "前营乡",
        "410421203": "李庄乡"
    },
    "410422": {
        "410422001": "九龙街道",
        "410422002": "昆阳街道",
        "410422003": "盐都街道",
        "410422101": "任店镇",
        "410422102": "保安镇",
        "410422103": "仙台镇",
        "410422105": "叶邑镇",
        "410422106": "廉村镇",
        "410422107": "常村镇",
        "410422108": "辛店镇",
        "410422109": "洪庄杨镇",
        "410422110": "龚店镇",
        "410422201": "夏李乡",
        "410422203": "马庄回族乡",
        "410422204": "田庄乡",
        "410422207": "龙泉乡",
        "410422208": "水寨乡",
        "410422210": "邓李乡"
    },
    "410423": {
        "410423001": "露峰街道",
        "410423002": "琴台街道",
        "410423003": "鲁阳街道",
        "410423004": "汇源街道",
        "410423101": "下汤镇",
        "410423102": "梁洼镇",
        "410423103": "张官营镇",
        "410423104": "张良镇",
        "410423105": "尧山镇",
        "410423106": "瓦屋镇",
        "410423107": "赵村镇",
        "410423201": "四棵树乡",
        "410423202": "团城乡",
        "410423203": "熊背乡",
        "410423204": "让河乡",
        "410423206": "观音寺乡",
        "410423207": "昭平台库区乡",
        "410423208": "背孜乡",
        "410423209": "仓头乡",
        "410423210": "董周乡",
        "410423211": "张店乡",
        "410423212": "辛集乡",
        "410423213": "滚子营乡",
        "410423214": "马楼乡"
    },
    "410425": {
        "410425001": "龙山街道",
        "410425002": "东城街道",
        "410425101": "冢头镇",
        "410425102": "安良镇",
        "410425103": "堂街镇",
        "410425104": "薛店镇",
        "410425105": "长桥镇",
        "410425106": "茨芭镇",
        "410425107": "黄道镇",
        "410425108": "李口镇",
        "410425200": "王集乡",
        "410425202": "姚庄回族乡",
        "410425203": "白庙乡",
        "410425204": "广阔天地乡",
        "410425205": "渣元乡"
    },
    "410471": {"410471001": "皇台街道", "410471100": "遵化店镇"},
    "410472": {"410472001": "湖滨路街道", "410472101": "滍阳镇", "410472401": "滨湖管委会", "410472402": "应滨管委会"},
    "410481": {
        "410481001": "垭口街道",
        "410481002": "寺坡街道",
        "410481003": "朱兰街道",
        "410481004": "院岭街道",
        "410481005": "矿建街道",
        "410481006": "铁山街道",
        "410481007": "红山街道",
        "410481100": "尚店镇",
        "410481101": "八台镇",
        "410481102": "尹集镇",
        "410481103": "枣林镇",
        "410481202": "庙街乡",
        "410481204": "武功乡",
        "410481205": "杨庄乡"
    },
    "410482": {
        "410482001": "煤山街道",
        "410482002": "风穴路街道",
        "410482003": "钟楼街道",
        "410482004": "洗耳河街道",
        "410482005": "汝南街道",
        "410482101": "寄料镇",
        "410482102": "温泉镇",
        "410482103": "临汝镇",
        "410482104": "小屯镇",
        "410482105": "杨楼镇",
        "410482106": "蟒川镇",
        "410482107": "庙下镇",
        "410482108": "米庙镇",
        "410482109": "陵头镇",
        "410482110": "纸坊镇",
        "410482111": "大峪镇",
        "410482112": "夏店镇",
        "410482113": "焦村镇",
        "410482202": "王寨乡",
        "410482207": "骑岭乡"
    },
    "410500": {
        "410502": "文峰区",
        "410503": "北关区",
        "410505": "殷都区",
        "410506": "龙安区",
        "410522": "安阳县",
        "410523": "汤阴县",
        "410526": "滑县",
        "410527": "内黄县",
        "410571": "安阳高新技术产业开发区",
        "410581": "林州市"
    },
    "410502": {
        "410502001": "东大街街道",
        "410502002": "头二三街道",
        "410502003": "甜水井街道",
        "410502004": "东关街道",
        "410502005": "南关街道",
        "410502006": "西大街街道",
        "410502008": "北大街街道",
        "410502011": "西关街道",
        "410502012": "紫薇大道街道",
        "410502013": "光华路街道",
        "410502014": "永明路街道",
        "410502015": "中华路街道",
        "410502101": "宝莲寺镇",
        "410502561": "步行街综合治理办公室",
        "410502562": "北大街综合治理办公室"
    },
    "410503": {
        "410503001": "红旗路街道",
        "410503003": "解放路街道",
        "410503004": "灯塔路街道",
        "410503005": "豆腐营街道",
        "410503006": "洹北街道",
        "410503008": "彰东街道",
        "410503009": "彰北街道",
        "410503010": "民航路街道",
        "410503011": "曙光路街道",
        "410503100": "柏庄镇"
    },
    "410505": {
        "410505001": "梅园庄街道",
        "410505002": "李珍街道",
        "410505003": "电厂路街道",
        "410505004": "纱厂路街道",
        "410505005": "铁西路街道",
        "410505006": "水冶街道",
        "410505007": "清风街街道",
        "410505008": "北蒙街道",
        "410505009": "相台街道",
        "410505200": "西郊乡"
    },
    "410506": {
        "410506001": "田村街道",
        "410506002": "彰武街道",
        "410506003": "文昌大道街道",
        "410506004": "文明大道街道",
        "410506005": "太行小区街道",
        "410506006": "中州路街道",
        "410506100": "龙泉镇",
        "410506101": "马投涧镇",
        "410506103": "善应镇",
        "410506201": "东风乡",
        "410506205": "马家乡"
    },
    "410522": {
        "410522101": "水冶镇",
        "410522102": "铜冶镇",
        "410522106": "白璧镇",
        "410522107": "曲沟镇",
        "410522108": "吕村镇",
        "410522109": "伦掌镇",
        "410522111": "崔家桥镇",
        "410522112": "辛村镇",
        "410522113": "韩陵镇",
        "410522114": "永和镇",
        "410522115": "都里镇",
        "410522116": "高庄镇",
        "410522203": "磊口乡",
        "410522204": "许家沟乡",
        "410522210": "安丰乡",
        "410522211": "洪河屯乡",
        "410522214": "瓦店乡",
        "410522216": "北郭乡"
    },
    "410523": {
        "410523100": "城关镇",
        "410523101": "菜园镇",
        "410523102": "任固镇",
        "410523103": "五陵镇",
        "410523104": "宜沟镇",
        "410523105": "白营镇",
        "410523106": "伏道镇",
        "410523107": "韩庄镇",
        "410523108": "古贤镇",
        "410523203": "瓦岗乡"
    },
    "410526": {
        "410526001": "道口镇街道",
        "410526002": "城关街道",
        "410526003": "锦和街道",
        "410526102": "白道口镇",
        "410526103": "留固镇",
        "410526104": "上官镇",
        "410526105": "牛屯镇",
        "410526106": "万古镇",
        "410526107": "高平镇",
        "410526108": "王庄镇",
        "410526109": "老店镇",
        "410526110": "慈周寨镇",
        "410526111": "焦虎镇",
        "410526112": "四间房镇",
        "410526113": "八里营镇",
        "410526114": "赵营镇",
        "410526115": "半坡店镇",
        "410526200": "枣村乡",
        "410526204": "大寨乡",
        "410526205": "桑村乡",
        "410526206": "老爷庙乡",
        "410526209": "瓦岗寨乡",
        "410526212": "小铺乡"
    },
    "410527": {
        "410527100": "城关镇",
        "410527101": "东庄镇",
        "410527102": "井店镇",
        "410527103": "梁庄镇",
        "410527104": "后河镇",
        "410527105": "楚旺镇",
        "410527106": "田氏镇",
        "410527107": "二安镇",
        "410527108": "亳城镇",
        "410527109": "豆公镇",
        "410527200": "张龙乡",
        "410527201": "马上乡",
        "410527202": "高堤乡",
        "410527205": "六村乡",
        "410527206": "中召乡",
        "410527207": "宋村乡",
        "410527209": "石盘屯乡"
    },
    "410571": {"410571563": "开发区峨嵋大街街道", "410571564": "开发区银杏大街街道", "410571565": "开发区商颂大街街道"},
    "410581": {
        "410581001": "开元街道",
        "410581002": "振林街道",
        "410581003": "龙山街道",
        "410581004": "桂园街道",
        "410581101": "合涧镇",
        "410581102": "临淇镇",
        "410581103": "东姚镇",
        "410581104": "横水镇",
        "410581105": "河顺镇",
        "410581106": "任村镇",
        "410581107": "姚村镇",
        "410581108": "陵阳镇",
        "410581109": "原康镇",
        "410581110": "五龙镇",
        "410581111": "采桑镇",
        "410581112": "东岗镇",
        "410581113": "桂林镇",
        "410581114": "茶店镇",
        "410581115": "石板岩镇",
        "410581116": "黄华镇"
    },
    "410600": {
        "410602": "鹤山区",
        "410603": "山城区",
        "410611": "淇滨区",
        "410621": "浚县",
        "410622": "淇县",
        "410671": "鹤壁经济技术开发区"
    },
    "410602": {
        "410602001": "中北街道",
        "410602002": "中山路街道",
        "410602003": "新华街街道",
        "410602004": "鹤山街街道",
        "410602005": "九矿广场街道",
        "410602100": "鹤壁集镇",
        "410602201": "姬家山乡"
    },
    "410603": {
        "410603001": "红旗街道",
        "410603002": "长风中路街道",
        "410603003": "山城路街道",
        "410603004": "汤河街街道",
        "410603005": "鹿楼街道",
        "410603006": "宝山街道",
        "410603007": "大胡街道",
        "410603100": "石林镇"
    },
    "410611": {
        "410611001": "金山",
        "410611002": "九州路街道",
        "410611003": "黎阳路街道",
        "410611100": "大赉店镇",
        "410611101": "钜桥镇",
        "410611200": "上峪乡",
        "410611201": "大河涧乡",
        "410611562": "长江路街道"
    },
    "410621": {
        "410621001": "卫溪街道",
        "410621002": "浚州街道",
        "410621003": "黎阳街道",
        "410621004": "伾山街道",
        "410621101": "善堂镇",
        "410621102": "屯子镇",
        "410621104": "新镇镇",
        "410621105": "小河镇",
        "410621107": "卫贤镇",
        "410621108": "王庄镇",
        "410621201": "白寺乡"
    },
    "410622": {
        "410622001": "朝歌街道",
        "410622002": "桥盟街道",
        "410622003": "卫都街道",
        "410622004": "灵山街道",
        "410622101": "高村镇",
        "410622102": "北阳镇",
        "410622103": "西岗镇",
        "410622104": "庙口镇",
        "410622202": "黄洞乡"
    },
    "410671": {"410671400": "东杨", "410671401": "渤海路", "410671402": "海河路"},
    "410700": {
        "410702": "红旗区",
        "410703": "卫滨区",
        "410704": "凤泉区",
        "410711": "牧野区",
        "410721": "新乡县",
        "410724": "获嘉县",
        "410725": "原阳县",
        "410726": "延津县",
        "410727": "封丘县",
        "410728": "长垣县",
        "410771": "新乡高新技术产业开发区",
        "410772": "新乡经济技术开发区",
        "410773": "新乡市平原城乡一体化示范区",
        "410781": "卫辉市",
        "410782": "辉县市"
    },
    "410702": {
        "410702001": "西街街道",
        "410702002": "东街街道",
        "410702003": "渠东街道",
        "410702007": "文化街街道",
        "410702009": "向阳小区街道",
        "410702100": "洪门镇",
        "410702101": "小店镇"
    },
    "410703": {
        "410703001": "胜利路街道",
        "410703002": "解放路街道",
        "410703003": "中同街街道",
        "410703004": "健康路街道",
        "410703007": "自由路街道",
        "410703008": "南桥街道",
        "410703009": "铁西街道",
        "410703100": "平原镇"
    },
    "410704": {"410704001": "宝西街道", "410704002": "宝东街道", "410704100": "大块镇", "410704101": "耿黄镇", "410704200": "潞王坟乡"},
    "410711": {
        "410711001": "东干道街道",
        "410711002": "荣校路街道",
        "410711003": "北干道街道",
        "410711004": "花园街道",
        "410711005": "卫北街道",
        "410711006": "新辉路街道",
        "410711007": "和平路街道",
        "410711100": "王村镇",
        "410711101": "牧野镇",
        "410711400": "新乡化学与物理电源产业园区管理委员会"
    },
    "410721": {
        "410721101": "翟坡镇",
        "410721102": "小冀镇",
        "410721103": "七里营镇",
        "410721104": "朗公庙镇",
        "410721105": "古固寨镇",
        "410721107": "大召营镇",
        "410721200": "合河乡",
        "410721560": "新乡经济开发区"
    },
    "410724": {
        "410724100": "城关镇",
        "410724101": "照镜镇",
        "410724102": "黄堤镇",
        "410724103": "中和镇",
        "410724104": "徐营镇",
        "410724105": "冯庄镇",
        "410724106": "亢村镇",
        "410724107": "史庄镇",
        "410724108": "太山镇",
        "410724200": "位庄乡",
        "410724203": "大新庄乡",
        "410724400": "原种场",
        "410724401": "园艺场",
        "410724402": "农场",
        "410724560": "西工区管理委员会"
    },
    "410725": {
        "410725002": "阳和街道",
        "410725003": "原兴街道",
        "410725103": "齐街镇",
        "410725104": "太平镇",
        "410725105": "福宁集镇",
        "410725200": "葛埠口乡",
        "410725206": "蒋庄乡",
        "410725207": "官厂乡",
        "410725209": "大宾乡",
        "410725211": "陡门乡",
        "410725215": "路寨乡",
        "410725216": "阳阿乡",
        "410725218": "靳堂乡"
    },
    "410726": {
        "410726100": "城关镇",
        "410726101": "东屯镇",
        "410726102": "丰庄镇",
        "410726103": "石婆固镇",
        "410726104": "王楼镇",
        "410726200": "僧固乡",
        "410726202": "位邱乡",
        "410726203": "司寨乡",
        "410726205": "马庄乡",
        "410726206": "胙城乡",
        "410726207": "榆林乡",
        "410726208": "小潭乡",
        "410726400": "国营林场",
        "410726401": "新兴农场",
        "410726402": "新乡市食品工业聚集区",
        "410726403": "新乡市纺织工业聚集区"
    },
    "410727": {
        "410727100": "城关镇",
        "410727101": "黄陵镇",
        "410727102": "黄德镇",
        "410727103": "应举镇",
        "410727104": "陈桥镇",
        "410727105": "赵岗镇",
        "410727106": "留光镇",
        "410727107": "潘店镇",
        "410727108": "李庄镇",
        "410727109": "陈固镇",
        "410727110": "居厢镇",
        "410727111": "鲁岗镇",
        "410727112": "尹岗镇",
        "410727200": "城关乡",
        "410727201": "回族乡",
        "410727202": "王村乡",
        "410727206": "荆宫乡",
        "410727208": "曹岗乡",
        "410727212": "冯村乡"
    },
    "410728": {
        "410728001": "蒲西街道",
        "410728002": "蒲东街道",
        "410728003": "南蒲街道",
        "410728004": "蒲北街道",
        "410728005": "魏庄街道",
        "410728101": "丁栾镇",
        "410728102": "樊相镇",
        "410728104": "恼里镇",
        "410728105": "常村镇",
        "410728106": "赵堤镇",
        "410728107": "孟岗镇",
        "410728108": "满村镇",
        "410728109": "苗寨镇",
        "410728110": "张三寨镇",
        "410728111": "方里镇",
        "410728112": "佘家镇",
        "410728202": "芦岗乡",
        "410728206": "武邱乡",
        "410728460": "河南长垣起重工业园区管理委员会"
    },
    "410771": {"410771360": "开发区关堤乡", "410771560": "振中街"},
    "410772": {"410772400": "小店工业园区"},
    "410773": {
        "410773001": "龙源街道",
        "410773101": "原武镇",
        "410773102": "师寨镇",
        "410773106": "韩董庄镇",
        "410773203": "祝楼乡",
        "410773204": "桥北乡"
    },
    "410781": {
        "410781100": "汲水镇",
        "410781101": "太公镇",
        "410781102": "孙杏村镇",
        "410781103": "后河镇",
        "410781104": "李源屯镇",
        "410781105": "唐庄镇",
        "410781106": "上乐村镇",
        "410781201": "狮豹头乡",
        "410781203": "安都乡",
        "410781204": "顿坊店乡",
        "410781205": "柳庄乡",
        "410781206": "庞寨乡",
        "410781208": "城郊乡",
        "410781400": "原种场",
        "410781401": "东风农场",
        "410781402": "苗圃场",
        "410781403": "农科所",
        "410781404": "五四农场"
    },
    "410782": {
        "410782001": "城关街道",
        "410782002": "胡桥街道",
        "410782101": "薄壁镇",
        "410782102": "峪河镇",
        "410782103": "百泉镇",
        "410782104": "孟庄镇",
        "410782105": "常村镇",
        "410782106": "吴村镇",
        "410782107": "南村镇",
        "410782108": "南寨镇",
        "410782109": "上八里镇",
        "410782110": "北云门镇",
        "410782111": "占城镇",
        "410782112": "冀屯镇",
        "410782200": "黄水乡",
        "410782201": "拍石头乡",
        "410782202": "高庄乡",
        "410782203": "张村乡",
        "410782205": "赵固乡",
        "410782206": "西平罗乡",
        "410782207": "洪洲乡",
        "410782208": "沙窑乡"
    },
    "410800": {
        "410802": "解放区",
        "410803": "中站区",
        "410804": "马村区",
        "410811": "山阳区",
        "410821": "修武县",
        "410822": "博爱县",
        "410823": "武陟县",
        "410825": "温县",
        "410871": "焦作城乡一体化示范区",
        "410882": "沁阳市",
        "410883": "孟州市"
    },
    "410802": {
        "410802001": "民生街道",
        "410802002": "民主街道",
        "410802003": "新华街道",
        "410802004": "焦西街道",
        "410802005": "焦南街道",
        "410802006": "焦北街道",
        "410802007": "七百间街道",
        "410802008": "上白作街道",
        "410802009": "王褚街道"
    },
    "410803": {
        "410803001": "李封街道",
        "410803002": "王封街道",
        "410803003": "朱村街道",
        "410803004": "冯封街道",
        "410803005": "龙洞街道",
        "410803006": "月山街道",
        "410803007": "丹河街道",
        "410803008": "许衡街道",
        "410803009": "府城街道",
        "410803010": "龙翔街道"
    },
    "410804": {
        "410804001": "马村街道",
        "410804002": "武王街道",
        "410804003": "冯营街道",
        "410804004": "九里山街道",
        "410804005": "待王街道",
        "410804006": "安阳城街道",
        "410804007": "演马街道"
    },
    "410811": {
        "410811001": "东方红街道",
        "410811002": "焦东街道",
        "410811003": "百间房街道",
        "410811004": "太行街道",
        "410811005": "艺新街道",
        "410811006": "光亚街道",
        "410811007": "定和街道",
        "410811008": "中星街道",
        "410811009": "新城街道"
    },
    "410821": {
        "410821100": "城关镇",
        "410821101": "七贤镇",
        "410821102": "郇封镇",
        "410821103": "周庄镇",
        "410821104": "云台山镇",
        "410821202": "王屯乡",
        "410821204": "五里源乡",
        "410821206": "西村乡",
        "410821400": "小营工贸区"
    },
    "410822": {
        "410822001": "博爱县清化镇",
        "410822002": "博爱县鸿昌",
        "410822101": "博爱县柏山镇",
        "410822102": "博爱县月山镇",
        "410822103": "博爱县许良镇",
        "410822105": "博爱县磨头镇",
        "410822106": "博爱县孝敬镇",
        "410822200": "博爱县寨豁乡",
        "410822203": "博爱县金城乡",
        "410822400": "国营博爱农场"
    },
    "410823": {
        "410823001": "木城街道",
        "410823002": "龙源街道",
        "410823003": "龙泉街道",
        "410823004": "木栾街道",
        "410823101": "詹店镇",
        "410823102": "西陶镇",
        "410823103": "谢旗营镇",
        "410823104": "大封镇",
        "410823107": "乔庙镇",
        "410823200": "嘉应观乡",
        "410823202": "圪当店乡",
        "410823203": "三阳乡",
        "410823204": "小董乡",
        "410823205": "大虹桥乡",
        "410823206": "北郭乡",
        "410823598": "武陟宁郭农场"
    },
    "410825": {
        "410825001": "温泉街道",
        "410825002": "岳村街道",
        "410825003": "张羌街道",
        "410825004": "黄河街道",
        "410825101": "祥云镇",
        "410825102": "番田镇",
        "410825104": "黄庄镇",
        "410825105": "武德镇",
        "410825106": "赵堡镇",
        "410825201": "招贤乡",
        "410825203": "北冷乡",
        "410825400": "国营温县农场"
    },
    "410871": {
        "410871060": "李万街道",
        "410871061": "文苑街道",
        "410871062": "文昌街道",
        "410871100": "阳庙镇",
        "410871101": "宁郭镇",
        "410871203": "苏家作乡"
    },
    "410882": {
        "410882001": "覃怀街道",
        "410882002": "怀庆街道",
        "410882003": "太行街道",
        "410882004": "沁园街道",
        "410882100": "崇义镇",
        "410882101": "西向镇",
        "410882102": "西万镇",
        "410882103": "柏香镇",
        "410882104": "山王庄镇",
        "410882105": "紫陵镇",
        "410882200": "常平乡",
        "410882201": "王召乡",
        "410882202": "王曲乡"
    },
    "410883": {
        "410883001": "大定街道",
        "410883002": "会昌街道",
        "410883003": "河雍街道",
        "410883004": "河阳街道",
        "410883100": "化工镇",
        "410883101": "南庄镇",
        "410883102": "城伯镇",
        "410883103": "谷旦镇",
        "410883104": "赵和镇",
        "410883105": "西虢镇",
        "410883201": "槐树乡"
    },
    "410900": {
        "410902": "华龙区",
        "410922": "清丰县",
        "410923": "南乐县",
        "410926": "范县",
        "410927": "台前县",
        "410928": "濮阳县",
        "410971": "河南濮阳工业园区",
        "410972": "濮阳经济技术开发区"
    },
    "410902": {
        "410902001": "中原路街道",
        "410902002": "胜利路街道",
        "410902003": "建设路街道",
        "410902004": "人民路街道",
        "410902005": "大庆路街道",
        "410902006": "黄河路街道",
        "410902007": "任丘路街道",
        "410902008": "长庆路街道",
        "410902009": "濮东街道",
        "410902101": "岳村镇",
        "410902203": "孟轲乡",
        "410902400": "中原油田街道"
    },
    "410922": {
        "410922100": "城关镇",
        "410922101": "马庄桥镇",
        "410922102": "瓦屋头镇",
        "410922103": "仙庄镇",
        "410922104": "柳格镇",
        "410922105": "韩村镇",
        "410922200": "六塔乡",
        "410922202": "巩营乡",
        "410922203": "马村乡",
        "410922204": "高堡乡",
        "410922205": "古城乡",
        "410922206": "大流乡",
        "410922208": "大屯乡",
        "410922209": "固城乡",
        "410922212": "双庙乡",
        "410922213": "纸房乡",
        "410922214": "阳邵乡"
    },
    "410923": {
        "410923100": "城关镇",
        "410923101": "韩张镇",
        "410923102": "元村镇",
        "410923103": "福坎镇",
        "410923104": "张果屯镇",
        "410923105": "千口镇",
        "410923200": "杨村乡",
        "410923204": "谷金楼乡",
        "410923205": "西邵乡",
        "410923206": "寺庄乡",
        "410923207": "梁村乡",
        "410923208": "近德固乡"
    },
    "410926": {
        "410926100": "城关镇",
        "410926101": "濮城镇",
        "410926102": "龙王庄镇",
        "410926103": "高码头镇",
        "410926104": "王楼镇",
        "410926105": "辛庄镇",
        "410926106": "陈庄镇",
        "410926201": "杨集乡",
        "410926203": "白衣阁乡",
        "410926205": "颜村铺乡",
        "410926208": "陆集乡",
        "410926209": "张庄乡"
    },
    "410927": {
        "410927100": "城关镇",
        "410927101": "侯庙镇",
        "410927102": "孙口镇",
        "410927103": "打渔陈镇",
        "410927104": "马楼镇",
        "410927105": "吴坝镇",
        "410927200": "后方乡",
        "410927201": "清水河乡",
        "410927205": "夹河乡"
    },
    "410928": {
        "410928100": "城关镇",
        "410928101": "濮阳县柳屯镇",
        "410928102": "濮阳县文留镇",
        "410928103": "濮阳县庆祖镇",
        "410928104": "濮阳县八公桥镇",
        "410928106": "濮阳县徐镇镇",
        "410928107": "濮阳县户部寨镇",
        "410928108": "濮阳县鲁河镇",
        "410928109": "濮阳县子岸镇",
        "410928110": "濮阳县胡状镇",
        "410928111": "濮阳县王称固镇",
        "410928112": "濮阳县梁庄镇",
        "410928202": "濮阳县清河头乡",
        "410928206": "濮阳县白罡乡",
        "410928207": "濮阳县梨园乡",
        "410928208": "濮阳县五星乡",
        "410928211": "濮阳县郎中乡",
        "410928212": "濮阳县海通乡",
        "410928213": "濮阳县渠村乡",
        "410928214": "濮阳县习城乡"
    },
    "410971": {"410971001": "河南濮阳工业园区昌湖"},
    "410972": {
        "410972060": "昆吾路街道",
        "410972061": "皇甫路街道",
        "410972062": "开州街道",
        "410972063": "濮上街道",
        "410972100": "王助镇",
        "410972101": "新习镇",
        "410972360": "胡村乡"
    },
    "411000": {
        "411002": "魏都区",
        "411003": "建安区",
        "411024": "鄢陵县",
        "411025": "襄城县",
        "411071": "许昌经济技术开发区",
        "411081": "禹州市",
        "411082": "长葛市"
    },
    "411002": {
        "411002001": "西大街道",
        "411002002": "东大街道",
        "411002003": "西关街道",
        "411002004": "南关街道",
        "411002005": "北大街道",
        "411002006": "五一路街道",
        "411002007": "高桥营街道",
        "411002008": "丁庄街道",
        "411002009": "七里店街道",
        "411002010": "文峰街道",
        "411002011": "新兴街道",
        "411002012": "半截河街道",
        "411002570": "东城区管委会"
    },
    "411003": {
        "411003001": "许由",
        "411003002": "新元",
        "411003003": "邓庄街道",
        "411003100": "将官池镇",
        "411003101": "五女店镇",
        "411003102": "尚集镇",
        "411003103": "苏桥镇",
        "411003104": "蒋李集镇",
        "411003105": "张潘镇",
        "411003106": "灵井镇",
        "411003200": "陈曹乡",
        "411003202": "小召乡",
        "411003203": "河街乡",
        "411003204": "桂村乡",
        "411003205": "椹涧乡",
        "411003206": "榆林乡",
        "411003208": "艾庄回族乡"
    },
    "411024": {
        "411024100": "安陵镇",
        "411024101": "马栏镇",
        "411024102": "柏梁镇",
        "411024103": "陈化店镇",
        "411024104": "望田镇",
        "411024105": "大马镇",
        "411024106": "陶城镇",
        "411024107": "张桥镇",
        "411024108": "彭店镇",
        "411024109": "只乐镇",
        "411024110": "南坞镇",
        "411024111": "马坊镇"
    },
    "411025": {
        "411025100": "城关镇",
        "411025101": "颍桥回族镇",
        "411025102": "麦岭镇",
        "411025103": "颍阳镇",
        "411025104": "王洛镇",
        "411025105": "紫云镇",
        "411025106": "库庄镇",
        "411025107": "十里铺镇",
        "411025108": "山头店镇",
        "411025109": "汾陈镇",
        "411025200": "湛北乡",
        "411025202": "茨沟乡",
        "411025203": "丁营乡",
        "411025204": "姜庄乡",
        "411025205": "范湖乡",
        "411025206": "双庙乡"
    },
    "411071": {"411071001": "长村张街道", "411071400": "龙湖街道"},
    "411081": {
        "411081001": "颍川街道",
        "411081002": "夏都街道",
        "411081003": "韩城街道",
        "411081004": "钧台街道",
        "411081100": "火龙镇",
        "411081101": "顺店镇",
        "411081102": "方山镇",
        "411081103": "神垕镇",
        "411081104": "鸿畅镇",
        "411081105": "梁北镇",
        "411081106": "古城镇",
        "411081107": "无梁镇",
        "411081108": "文殊镇",
        "411081109": "鸠山镇",
        "411081110": "褚河镇",
        "411081111": "范坡镇",
        "411081112": "郭连镇",
        "411081113": "朱阁镇",
        "411081114": "浅井镇",
        "411081115": "方岗镇",
        "411081116": "花石镇",
        "411081117": "张得镇",
        "411081118": "苌庄镇",
        "411081204": "磨街乡",
        "411081206": "小吕乡",
        "411081210": "山货回族乡"
    },
    "411082": {
        "411082001": "建设路街道",
        "411082002": "长兴路街道",
        "411082003": "长社路街道",
        "411082004": "金桥路街道",
        "411082100": "和尚桥镇",
        "411082101": "坡胡镇",
        "411082102": "后河镇",
        "411082103": "石固镇",
        "411082104": "老城镇",
        "411082105": "南席镇",
        "411082106": "大周镇",
        "411082107": "董村镇",
        "411082108": "石象镇",
        "411082109": "古桥镇",
        "411082110": "增福镇",
        "411082111": "佛耳湖镇"
    },
    "411100": {
        "411102": "源汇区",
        "411103": "郾城区",
        "411104": "召陵区",
        "411121": "舞阳县",
        "411122": "临颍县",
        "411171": "漯河经济技术开发区"
    },
    "411102": {
        "411102001": "老街街道",
        "411102002": "马路街街道",
        "411102003": "顺河街街道",
        "411102004": "干河陈街道",
        "411102100": "大刘镇",
        "411102101": "阴阳赵镇",
        "411102102": "空冢郭镇",
        "411102202": "问十乡"
    },
    "411103": {
        "411103001": "沙北街道",
        "411103100": "城关镇",
        "411103101": "孟庙镇",
        "411103102": "商桥镇",
        "411103103": "裴城镇",
        "411103104": "新店镇",
        "411103105": "龙城镇",
        "411103106": "李集镇",
        "411103107": "黑龙潭镇"
    },
    "411104": {
        "411104001": "天桥街街道",
        "411104002": "翟庄",
        "411104100": "召陵镇",
        "411104101": "邓襄镇",
        "411104102": "万金镇",
        "411104103": "老窝镇",
        "411104104": "姬石镇",
        "411104105": "青年镇"
    },
    "411121": {
        "411121100": "舞泉镇",
        "411121101": "吴城镇",
        "411121102": "北舞渡镇",
        "411121103": "莲花镇",
        "411121104": "辛安镇",
        "411121105": "孟寨镇",
        "411121106": "太尉镇",
        "411121107": "侯集镇",
        "411121108": "九街镇",
        "411121109": "章化镇",
        "411121200": "文峰乡",
        "411121201": "保和乡",
        "411121202": "马村乡",
        "411121204": "姜店乡"
    },
    "411122": {
        "411122001": "城关街道",
        "411122002": "新城街道",
        "411122101": "繁城镇",
        "411122102": "杜曲镇",
        "411122103": "王岗镇",
        "411122104": "台陈镇",
        "411122105": "巨陵镇",
        "411122106": "瓦店镇",
        "411122107": "三家店镇",
        "411122108": "窝城镇",
        "411122109": "王孟镇",
        "411122110": "大郭镇",
        "411122201": "皇帝庙乡",
        "411122202": "固厢乡",
        "411122203": "石桥乡",
        "411122204": "陈庄乡"
    },
    "411171": {"411171101": "后谢镇"},
    "411200": {
        "411202": "湖滨区",
        "411203": "陕州区",
        "411221": "渑池县",
        "411224": "卢氏县",
        "411271": "河南三门峡经济开发区",
        "411281": "义马市",
        "411282": "灵宝市"
    },
    "411202": {
        "411202001": "湖滨街道",
        "411202002": "前进街道",
        "411202003": "车站街道",
        "411202004": "涧河街道",
        "411202005": "大安街道",
        "411202006": "会兴街道",
        "411202007": "崖底街道",
        "411202201": "交口乡",
        "411202202": "磁钟乡",
        "411202203": "高庙乡"
    },
    "411203": {
        "411203100": "大营镇",
        "411203101": "原店镇",
        "411203102": "西张村镇",
        "411203103": "观音堂镇",
        "411203200": "张汴乡",
        "411203201": "张湾乡",
        "411203203": "菜园乡",
        "411203205": "张茅乡",
        "411203206": "王家后乡",
        "411203207": "硖石乡",
        "411203210": "西李村乡",
        "411203211": "宫前乡",
        "411203212": "店子乡"
    },
    "411221": {
        "411221100": "城关镇",
        "411221101": "英豪镇",
        "411221102": "张村镇",
        "411221103": "洪阳镇",
        "411221104": "天池镇",
        "411221105": "仰韶镇",
        "411221203": "仁村乡",
        "411221206": "果园乡",
        "411221207": "陈村乡",
        "411221208": "坡头乡",
        "411221209": "段村乡",
        "411221210": "南村乡"
    },
    "411224": {
        "411224100": "城关镇",
        "411224101": "杜关镇",
        "411224102": "五里川镇",
        "411224103": "官道口镇",
        "411224104": "朱阳关镇",
        "411224105": "官坡镇",
        "411224106": "范里镇",
        "411224107": "东明镇",
        "411224108": "双龙湾镇",
        "411224200": "文峪乡",
        "411224201": "横涧乡",
        "411224203": "双槐树乡",
        "411224204": "汤河乡",
        "411224205": "瓦窑沟乡",
        "411224206": "狮子坪乡",
        "411224207": "沙河乡",
        "411224208": "徐家湾乡",
        "411224209": "潘河乡",
        "411224210": "木桐乡"
    },
    "411271": {"411271560": "向阳街道"},
    "411281": {
        "411281001": "千秋路街道",
        "411281002": "朝阳路街道",
        "411281003": "新义街街道",
        "411281004": "常村路街道",
        "411281005": "泰山路街道",
        "411281006": "新区街道",
        "411281007": "东区街道"
    },
    "411282": {
        "411282100": "城关镇",
        "411282101": "尹庄镇",
        "411282102": "朱阳镇",
        "411282103": "阳平镇",
        "411282104": "故县镇",
        "411282105": "豫灵镇",
        "411282106": "大王镇",
        "411282107": "阳店镇",
        "411282108": "函谷关镇",
        "411282109": "焦村镇",
        "411282200": "川口乡",
        "411282201": "寺河乡",
        "411282202": "苏村乡",
        "411282203": "五亩乡",
        "411282204": "西阎乡",
        "411282400": "灵宝市园艺场",
        "411282401": "涧东区管委会",
        "411282402": "涧西区管委会"
    },
    "411300": {
        "411302": "宛城区",
        "411303": "卧龙区",
        "411321": "南召县",
        "411322": "方城县",
        "411323": "西峡县",
        "411324": "镇平县",
        "411325": "内乡县",
        "411326": "淅川县",
        "411327": "社旗县",
        "411328": "唐河县",
        "411329": "新野县",
        "411330": "桐柏县",
        "411371": "南阳高新技术产业开发区",
        "411372": "南阳市城乡一体化示范区",
        "411381": "邓州市"
    },
    "411302": {
        "411302001": "东关街道",
        "411302002": "新华街道",
        "411302003": "汉冶街道",
        "411302004": "仲景街道",
        "411302007": "赤虎街道",
        "411302008": "五里堡街道",
        "411302102": "官庄镇",
        "411302103": "瓦店镇",
        "411302104": "红泥湾镇",
        "411302105": "黄台岗镇",
        "411302106": "金华镇",
        "411302107": "高庙镇",
        "411302301": "溧河乡",
        "411302302": "汉冢乡",
        "411302304": "茶庵乡"
    },
    "411303": {
        "411303002": "七一街道",
        "411303003": "卧龙岗街道",
        "411303004": "武侯街道",
        "411303005": "梅溪街道",
        "411303006": "车站街道",
        "411303007": "光武街道",
        "411303008": "靳岗街道",
        "411303101": "石桥镇",
        "411303102": "潦河镇",
        "411303103": "安皋镇",
        "411303104": "蒲山镇",
        "411303105": "陆营镇",
        "411303106": "青华镇",
        "411303107": "英庄镇",
        "411303108": "潦河坡镇",
        "411303109": "谢庄镇",
        "411303300": "七里园乡",
        "411303306": "王村乡",
        "411303400": "龙王沟风景区"
    },
    "411321": {
        "411321100": "城关镇",
        "411321101": "留山镇",
        "411321102": "云阳镇",
        "411321103": "皇路店镇",
        "411321104": "南河店镇",
        "411321105": "板山坪镇",
        "411321106": "乔端镇",
        "411321107": "白土岗镇",
        "411321300": "城郊乡",
        "411321301": "小店乡",
        "411321302": "皇后乡",
        "411321303": "太山庙乡",
        "411321304": "石门乡",
        "411321305": "四棵树乡",
        "411321308": "马市坪乡",
        "411321310": "崔庄乡",
        "411321400": "河南红阳机械厂",
        "411321401": "河南向东机械厂",
        "411321402": "河南红宇机械厂",
        "411321403": "国营东风机械厂",
        "411321404": "国营长江机械厂"
    },
    "411322": {
        "411322001": "凤瑞街道",
        "411322002": "释之街道",
        "411322101": "独树镇",
        "411322102": "博望镇",
        "411322103": "拐河镇",
        "411322104": "小史店镇",
        "411322105": "赵河镇",
        "411322106": "广阳镇",
        "411322107": "二郎庙镇",
        "411322108": "杨楼镇",
        "411322109": "券桥镇",
        "411322110": "清河镇",
        "411322111": "四里店镇",
        "411322112": "古庄店镇",
        "411322113": "杨集镇",
        "411322114": "柳河镇",
        "411322311": "袁店回族乡",
        "411322400": "河南中南机械厂",
        "411322401": "方城大寺林场"
    },
    "411323": {
        "411323001": "白羽街道",
        "411323002": "紫金街道",
        "411323003": "莲花街道",
        "411323101": "丹水镇",
        "411323102": "西坪镇",
        "411323103": "双龙镇",
        "411323104": "回车镇",
        "411323105": "丁河镇",
        "411323106": "桑坪镇",
        "411323107": "米坪镇",
        "411323108": "五里桥镇",
        "411323109": "重阳镇",
        "411323110": "太平镇",
        "411323111": "阳城镇",
        "411323112": "二郎坪镇",
        "411323113": "石界河镇",
        "411323114": "军马河镇",
        "411323300": "田关乡",
        "411323307": "寨根乡"
    },
    "411324": {
        "411324001": "涅阳街道",
        "411324002": "雪枫街道",
        "411324003": "玉都街道",
        "411324101": "石佛寺镇",
        "411324102": "晁陂镇",
        "411324103": "贾宋镇",
        "411324104": "侯集镇",
        "411324105": "老庄镇",
        "411324106": "卢医镇",
        "411324107": "遮山镇",
        "411324108": "高丘镇",
        "411324109": "曲屯镇",
        "411324110": "枣园镇",
        "411324111": "杨营镇",
        "411324112": "安字营镇",
        "411324113": "张林镇",
        "411324114": "柳泉铺镇",
        "411324115": "彭营镇",
        "411324303": "二龙乡",
        "411324306": "王岗乡",
        "411324309": "马庄乡",
        "411324315": "郭庄回族乡"
    },
    "411325": {
        "411325100": "城关镇",
        "411325101": "夏馆镇",
        "411325102": "师岗镇",
        "411325103": "马山口镇",
        "411325104": "湍东镇",
        "411325105": "赤眉镇",
        "411325106": "瓦亭镇",
        "411325107": "王店镇",
        "411325108": "灌涨镇",
        "411325109": "桃溪镇",
        "411325110": "岞岖镇",
        "411325111": "余关镇",
        "411325300": "板场乡",
        "411325301": "大桥乡",
        "411325302": "赵店乡",
        "411325306": "七里坪乡"
    },
    "411326": {
        "411326001": "龙城街道",
        "411326002": "商圣街道",
        "411326101": "荆紫关镇",
        "411326102": "老城镇",
        "411326103": "香花镇",
        "411326104": "厚坡镇",
        "411326105": "丹阳镇",
        "411326106": "盛湾镇",
        "411326107": "金河镇",
        "411326108": "寺湾镇",
        "411326109": "仓房镇",
        "411326110": "上集镇",
        "411326111": "马蹬镇",
        "411326301": "西簧乡",
        "411326302": "毛堂乡",
        "411326303": "大石桥乡",
        "411326304": "滔河乡"
    },
    "411327": {
        "411327001": "赵河街道",
        "411327002": "潘河街道",
        "411327100": "赊店镇",
        "411327101": "桥头镇",
        "411327103": "饶良镇",
        "411327104": "兴隆镇",
        "411327105": "晋庄镇",
        "411327106": "李店镇",
        "411327107": "苗店镇",
        "411327108": "郝寨镇",
        "411327109": "朱集镇",
        "411327110": "下洼镇",
        "411327111": "太和镇",
        "411327112": "大冯营镇",
        "411327113": "陌陂镇",
        "411327313": "唐庄乡"
    },
    "411328": {
        "411328001": "滨河街道",
        "411328002": "文峰街道",
        "411328003": "东兴街道",
        "411328004": "兴唐街道",
        "411328101": "源潭镇",
        "411328102": "张店镇",
        "411328103": "郭滩镇",
        "411328104": "湖阳镇",
        "411328105": "黑龙镇",
        "411328106": "大河屯镇",
        "411328107": "龙潭镇",
        "411328108": "桐寨铺镇",
        "411328109": "苍台镇",
        "411328110": "上屯镇",
        "411328111": "毕店镇",
        "411328112": "少拜寺镇",
        "411328113": "祁仪镇",
        "411328114": "马振抚镇",
        "411328300": "城郊乡",
        "411328301": "桐河乡",
        "411328306": "昝岗乡",
        "411328309": "古城乡",
        "411328311": "东王集乡"
    },
    "411329": {
        "411329001": "汉城街道",
        "411329002": "汉华街道",
        "411329101": "王庄镇",
        "411329102": "沙堰镇",
        "411329103": "新甸铺镇",
        "411329104": "施庵镇",
        "411329105": "歪子镇",
        "411329106": "五星镇",
        "411329107": "溧河铺镇",
        "411329108": "王集镇",
        "411329300": "城郊乡",
        "411329303": "前高庙乡",
        "411329305": "樊集乡",
        "411329307": "上庄乡",
        "411329309": "上港乡"
    },
    "411330": {
        "411330100": "城关镇",
        "411330101": "月河镇",
        "411330102": "吴城镇",
        "411330103": "固县镇",
        "411330104": "毛集镇",
        "411330105": "大河镇",
        "411330106": "埠江镇",
        "411330107": "平氏镇",
        "411330108": "淮源镇",
        "411330109": "黄岗镇",
        "411330110": "安棚镇",
        "411330111": "朱庄镇",
        "411330112": "程湾镇",
        "411330300": "城郊乡",
        "411330301": "回龙乡",
        "411330306": "新集乡"
    },
    "411371": {"411371060": "张衡街道", "411371061": "百里奚街道", "411371401": "高新区产业集聚工业园"},
    "411372": {"411372005": "白河街道", "411372006": "枣林街道", "411372007": "姜营街道", "411372306": "新店乡"},
    "411381": {
        "411381001": "古城街道",
        "411381002": "花洲街道",
        "411381003": "湍河街道",
        "411381102": "罗庄镇",
        "411381103": "汲滩镇",
        "411381104": "穰东镇",
        "411381105": "孟楼镇",
        "411381106": "林扒镇",
        "411381107": "构林镇",
        "411381108": "十林镇",
        "411381109": "张村镇",
        "411381110": "都司镇",
        "411381111": "赵集镇",
        "411381112": "刘集镇",
        "411381113": "桑庄镇",
        "411381114": "彭桥镇",
        "411381115": "白牛镇",
        "411381116": "腰店镇",
        "411381117": "九龙镇",
        "411381118": "文渠镇",
        "411381119": "高集镇",
        "411381120": "夏集镇",
        "411381121": "陶营镇",
        "411381301": "张楼乡",
        "411381305": "裴营乡",
        "411381316": "小杨营乡",
        "411381319": "龙堰乡",
        "411381400": "南阳市黄牛良种繁育场",
        "411381560": "杏山旅游管理区杏山街道"
    },
    "411400": {
        "411402": "梁园区",
        "411403": "睢阳区",
        "411421": "民权县",
        "411422": "睢县",
        "411423": "宁陵县",
        "411424": "柘城县",
        "411425": "虞城县",
        "411426": "夏邑县",
        "411471": "豫东综合物流产业聚集区",
        "411472": "河南商丘经济开发区",
        "411481": "永城市"
    },
    "411402": {
        "411402001": "平原街道",
        "411402002": "长征街道",
        "411402003": "前进街道",
        "411402004": "白云街道",
        "411402005": "东风街道",
        "411402006": "八八街道",
        "411402007": "建设街道",
        "411402011": "解放街道",
        "411402100": "谢集镇",
        "411402101": "双八镇",
        "411402103": "观堂镇",
        "411402200": "刘口乡",
        "411402201": "水池铺乡",
        "411402203": "王楼乡",
        "411402204": "李庄乡",
        "411402205": "孙福集乡"
    },
    "411403": {
        "411403001": "古城街道",
        "411403002": "文化街道",
        "411403003": "东方街道",
        "411403004": "新城街道",
        "411403006": "宋城街道",
        "411403007": "古宋街道",
        "411403100": "宋集镇",
        "411403101": "郭村镇",
        "411403102": "李口镇",
        "411403103": "高辛镇",
        "411403104": "坞墙镇",
        "411403105": "冯桥镇",
        "411403106": "路河镇",
        "411403107": "闫集镇",
        "411403108": "毛固堆镇",
        "411403204": "包公庙乡",
        "411403205": "娄店乡",
        "411403208": "勒马乡",
        "411403209": "临河店乡"
    },
    "411421": {
        "411421001": "绿洲街道",
        "411421002": "南华街道",
        "411421101": "人和镇",
        "411421102": "龙塘镇",
        "411421103": "北关镇",
        "411421104": "程庄镇",
        "411421105": "王庄寨镇",
        "411421106": "孙六镇",
        "411421107": "白云寺镇",
        "411421108": "王桥镇",
        "411421109": "庄子镇",
        "411421110": "双塔镇",
        "411421111": "野岗镇",
        "411421200": "伯党乡",
        "411421201": "花园乡",
        "411421206": "林七乡",
        "411421207": "胡集乡",
        "411421208": "禇庙乡",
        "411421210": "老颜集乡",
        "411421400": "商丘市农场",
        "411421401": "商丘市林场"
    },
    "411422": {
        "411422100": "长岗镇",
        "411422101": "平岗镇",
        "411422102": "周堂镇",
        "411422103": "蓼堤镇",
        "411422104": "西陵寺镇",
        "411422105": "城关镇",
        "411422106": "潮庄镇",
        "411422107": "尚屯镇",
        "411422200": "后台乡",
        "411422202": "河集乡",
        "411422203": "孙聚寨乡",
        "411422204": "白楼乡",
        "411422205": "河堤乡",
        "411422206": "白庙乡",
        "411422207": "胡堂乡",
        "411422208": "尤吉屯乡",
        "411422210": "董店乡",
        "411422211": "涧岗乡",
        "411422213": "匡城乡",
        "411422214": "城郊乡"
    },
    "411423": {
        "411423100": "城关镇",
        "411423101": "张弓镇",
        "411423102": "柳河镇",
        "411423103": "逻岗镇",
        "411423104": "石桥镇",
        "411423105": "黄岗镇",
        "411423106": "华堡镇",
        "411423203": "刘楼乡",
        "411423204": "程楼乡",
        "411423205": "乔楼乡",
        "411423206": "城郊乡",
        "411423207": "阳驿乡",
        "411423209": "孔集乡",
        "411423210": "赵村乡",
        "411423400": "产业集聚区"
    },
    "411424": {
        "411424001": "长江新城街道",
        "411424002": "浦东街道",
        "411424100": "柘城县城关镇",
        "411424101": "陈青集镇",
        "411424102": "起台镇",
        "411424103": "胡襄镇",
        "411424104": "慈圣镇",
        "411424105": "安平镇",
        "411424106": "远襄镇",
        "411424107": "岗王镇",
        "411424108": "伯岗镇",
        "411424109": "张桥镇",
        "411424200": "邵元乡",
        "411424203": "洪恩乡",
        "411424204": "老王集乡",
        "411424205": "大仵乡",
        "411424206": "马集乡",
        "411424207": "牛城乡",
        "411424208": "惠济乡",
        "411424212": "申桥乡",
        "411424213": "李原乡",
        "411424214": "皇集乡"
    },
    "411425": {
        "411425100": "城关镇",
        "411425101": "界沟镇",
        "411425102": "木兰镇",
        "411425103": "杜集镇",
        "411425104": "谷熟镇",
        "411425105": "大杨集镇",
        "411425107": "利民镇",
        "411425108": "张集镇",
        "411425109": "站集镇",
        "411425110": "稍岗镇",
        "411425200": "黄冢乡",
        "411425201": "沙集乡",
        "411425202": "店集乡",
        "411425204": "闻集乡",
        "411425205": "芒种桥乡",
        "411425207": "刘店乡",
        "411425208": "大候乡",
        "411425210": "城郊乡",
        "411425211": "郑集乡",
        "411425212": "李老家乡",
        "411425214": "镇里固乡",
        "411425215": "古王集乡",
        "411425219": "刘集乡",
        "411425220": "乔集乡",
        "411425221": "田庙乡"
    },
    "411426": {
        "411426100": "城关镇",
        "411426101": "会亭镇",
        "411426102": "马头镇",
        "411426103": "济阳镇",
        "411426104": "李集镇",
        "411426105": "车站镇",
        "411426106": "杨集镇",
        "411426107": "韩道口镇",
        "411426108": "太平镇",
        "411426109": "罗庄镇",
        "411426110": "火店镇",
        "411426111": "北岭镇",
        "411426112": "郭店镇",
        "411426200": "曹集乡",
        "411426201": "胡桥乡",
        "411426202": "歧河乡",
        "411426204": "业庙乡",
        "411426205": "中峰乡",
        "411426207": "桑固乡",
        "411426208": "何营乡",
        "411426210": "王集乡",
        "411426211": "刘店集乡",
        "411426212": "骆集乡",
        "411426214": "孔庄乡"
    },
    "411471": {"411471001": "中州街道", "411471100": "张阁镇", "411471101": "贾寨镇"},
    "411472": {"411472009": "平台街道", "411472010": "平安街道", "411472200": "周集乡"},
    "411481": {
        "411481100": "演集镇",
        "411481101": "城关镇",
        "411481102": "芒山镇",
        "411481103": "高庄镇",
        "411481104": "酇城镇",
        "411481105": "裴桥镇",
        "411481106": "马桥镇",
        "411481107": "薛湖镇",
        "411481108": "蒋口镇",
        "411481109": "陈集镇",
        "411481110": "十八里镇",
        "411481111": "太邱镇",
        "411481112": "李寨镇",
        "411481113": "苗桥镇",
        "411481114": "顺和镇",
        "411481115": "茴村镇",
        "411481116": "酇阳镇",
        "411481117": "龙岗镇",
        "411481118": "马牧镇",
        "411481119": "王集镇",
        "411481120": "刘河镇",
        "411481121": "双桥镇",
        "411481122": "卧龙镇",
        "411481123": "黄口镇",
        "411481124": "新桥镇",
        "411481200": "城厢乡",
        "411481201": "候岭乡",
        "411481217": "条河乡",
        "411481219": "陈官庄乡"
    },
    "411500": {
        "411502": "浉河区",
        "411503": "平桥区",
        "411521": "罗山县",
        "411522": "光山县",
        "411523": "新县",
        "411524": "商城县",
        "411525": "固始县",
        "411526": "潢川县",
        "411527": "淮滨县",
        "411528": "息县",
        "411571": "信阳高新技术产业开发区"
    },
    "411502": {
        "411502001": "老城街道",
        "411502002": "民权街道",
        "411502003": "车站街道",
        "411502004": "五里墩街道",
        "411502005": "五星街道",
        "411502006": "湖东街道",
        "411502007": "南湾街道",
        "411502008": "金牛山街道",
        "411502009": "双井街道",
        "411502010": "贤山街道",
        "411502100": "李家寨镇",
        "411502101": "吴家店镇",
        "411502102": "东双河镇",
        "411502103": "董家河镇",
        "411502104": "浉河港镇",
        "411502203": "游河乡",
        "411502206": "谭家河乡",
        "411502207": "柳林乡",
        "411502208": "十三里桥乡"
    },
    "411503": {
        "411503001": "羊山街道",
        "411503002": "前进街道",
        "411503003": "南京路",
        "411503004": "平桥街道",
        "411503005": "甘岸街道",
        "411503006": "五里店街道",
        "411503007": "平西街道",
        "411503008": "平东街道",
        "411503009": "震雷山街道",
        "411503101": "明港镇",
        "411503102": "五里镇",
        "411503103": "邢集镇",
        "411503105": "平昌镇",
        "411503106": "洋河镇",
        "411503107": "肖王镇",
        "411503202": "龙井乡",
        "411503203": "胡店乡",
        "411503205": "彭家湾乡",
        "411503206": "长台乡",
        "411503207": "肖店乡",
        "411503209": "王岗乡",
        "411503210": "高粱店乡",
        "411503211": "查山乡",
        "411503400": "土城街道",
        "411503401": "明港工业管理区",
        "411503404": "城阳城址保护区",
        "411503405": "龙飞山",
        "411503406": "北湖风景管理区",
        "411503408": "农村改革发展综合试验核心区",
        "411503410": "信阳国际家居产业小镇"
    },
    "411521": {
        "411521001": "丽水街道",
        "411521002": "宝城街道",
        "411521003": "龙山街道",
        "411521101": "周党镇",
        "411521102": "竹竿镇",
        "411521103": "灵山镇",
        "411521104": "子路镇",
        "411521105": "楠杆镇",
        "411521106": "青山镇",
        "411521107": "潘新镇",
        "411521108": "彭新镇",
        "411521109": "莽张镇",
        "411521110": "东卜镇",
        "411521111": "铁铺镇",
        "411521202": "庙仙乡",
        "411521204": "定远乡",
        "411521205": "山店乡",
        "411521207": "朱堂乡",
        "411521208": "尤店乡",
        "411521209": "高店乡",
        "411521400": "河南省五一农场"
    },
    "411522": {
        "411522001": "弦山街道",
        "411522002": "紫水街道",
        "411522101": "十里镇",
        "411522102": "寨河镇",
        "411522103": "孙铁铺镇",
        "411522104": "马畈镇",
        "411522105": "泼陂河镇",
        "411522106": "白雀园镇",
        "411522107": "砖桥镇",
        "411522201": "仙居乡",
        "411522203": "北向店乡",
        "411522204": "罗陈乡",
        "411522206": "殷棚乡",
        "411522207": "南向店乡",
        "411522208": "晏河乡",
        "411522210": "凉亭乡",
        "411522212": "斛山乡",
        "411522213": "槐店乡",
        "411522215": "文殊乡"
    },
    "411523": {
        "411523001": "金兰山街道",
        "411523100": "新集镇",
        "411523101": "沙窝镇",
        "411523103": "吴陈河镇",
        "411523104": "苏河镇",
        "411523105": "八里畈镇",
        "411523201": "周河乡",
        "411523202": "陡山河乡",
        "411523203": "浒湾乡",
        "411523204": "千斤乡",
        "411523205": "卡房乡",
        "411523206": "郭家河乡",
        "411523207": "陈店乡",
        "411523208": "箭厂河乡",
        "411523209": "泗店乡",
        "411523210": "田铺乡"
    },
    "411524": {
        "411524001": "赤城街道",
        "411524002": "鲇鱼山街道",
        "411524101": "上石桥镇",
        "411524102": "鄢岗镇",
        "411524103": "双椿铺镇",
        "411524104": "汪桥镇",
        "411524105": "余集镇",
        "411524106": "达权店镇",
        "411524107": "丰集镇",
        "411524108": "汪岗镇",
        "411524109": "观庙镇",
        "411524110": "金刚台镇",
        "411524203": "河风桥乡",
        "411524204": "李集乡",
        "411524207": "苏仙石乡",
        "411524209": "伏山乡",
        "411524212": "吴河乡",
        "411524213": "冯店乡",
        "411524215": "长竹园乡",
        "411524400": "汤泉池管理处"
    },
    "411525": {
        "411525001": "蓼城",
        "411525002": "番城",
        "411525003": "秀水",
        "411525101": "陈淋子镇",
        "411525102": "黎集镇",
        "411525103": "蒋集镇",
        "411525104": "往流镇",
        "411525105": "郭陆滩镇",
        "411525106": "胡族铺镇",
        "411525107": "方集镇",
        "411525108": "三河尖镇",
        "411525109": "段集镇",
        "411525110": "汪棚镇",
        "411525111": "张广庙镇",
        "411525112": "陈集镇",
        "411525113": "武庙集镇",
        "411525114": "分水亭镇",
        "411525115": "石佛店镇",
        "411525116": "泉河铺镇",
        "411525117": "祖师庙镇",
        "411525118": "李店镇",
        "411525119": "沙河铺镇",
        "411525201": "洪埠乡",
        "411525202": "杨集乡",
        "411525203": "马堽集乡",
        "411525204": "草庙集乡",
        "411525206": "南大桥乡",
        "411525208": "赵岗乡",
        "411525211": "张老埠乡",
        "411525218": "徐集乡",
        "411525222": "丰港乡",
        "411525223": "柳树店乡",
        "411525224": "观堂乡"
    },
    "411526": {
        "411526001": "春申街道",
        "411526002": "定城街道",
        "411526003": "弋阳街道",
        "411526004": "老城街道",
        "411526101": "双柳树镇",
        "411526102": "伞陂镇",
        "411526103": "卜塔集镇",
        "411526105": "仁和镇",
        "411526106": "付店镇",
        "411526107": "踅孜镇",
        "411526108": "桃林铺镇",
        "411526109": "黄寺岗镇",
        "411526110": "江家集镇",
        "411526201": "传流店乡",
        "411526202": "魏岗乡",
        "411526203": "张集乡",
        "411526204": "来龙乡",
        "411526205": "隆古乡",
        "411526206": "谈店乡",
        "411526207": "上油岗乡",
        "411526209": "白店乡",
        "411526400": "黄湖农场",
        "411526560": "河南省潢川经济技术开发区"
    },
    "411527": {
        "411527001": "顺河街道",
        "411527002": "滨湖街道",
        "411527003": "栏杆街道",
        "411527004": "桂花街道",
        "411527101": "马集镇",
        "411527103": "防胡镇",
        "411527104": "新里镇",
        "411527105": "期思镇",
        "411527106": "赵集镇",
        "411527200": "淮滨县台头乡",
        "411527201": "王家岗乡",
        "411527202": "固城乡",
        "411527203": "三空桥乡",
        "411527204": "张里乡",
        "411527206": "邓湾乡",
        "411527207": "张庄乡",
        "411527208": "王店乡",
        "411527209": "淮滨县谷堆乡",
        "411527212": "芦集乡"
    },
    "411528": {
        "411528001": "谯楼街道",
        "411528002": "龙湖街道",
        "411528003": "淮河街道",
        "411528101": "包信镇",
        "411528102": "夏庄镇",
        "411528103": "东岳镇",
        "411528104": "项店镇",
        "411528105": "小茴店镇",
        "411528106": "曹黄林镇",
        "411528201": "孙庙乡",
        "411528202": "路口乡",
        "411528203": "彭店乡",
        "411528204": "杨店乡",
        "411528205": "张陶乡",
        "411528206": "白土店乡",
        "411528207": "岗李店乡",
        "411528208": "长陵乡",
        "411528209": "陈棚乡",
        "411528210": "临河乡",
        "411528211": "关店乡",
        "411528214": "八里岔乡",
        "411528400": "地矿局"
    },
    "411571": {"411571001": "城东街道", "411571002": "珍珠路街道"},
    "411600": {
        "411602": "川汇区",
        "411621": "扶沟县",
        "411622": "西华县",
        "411623": "商水县",
        "411624": "沈丘县",
        "411625": "郸城县",
        "411626": "淮阳县",
        "411627": "太康县",
        "411628": "鹿邑县",
        "411671": "河南周口经济开发区",
        "411681": "项城市"
    },
    "411602": {
        "411602001": "陈州回族街道",
        "411602002": "七一路街道",
        "411602003": "荷花街道",
        "411602004": "人和街道",
        "411602005": "小桥街道",
        "411602006": "城南",
        "411602007": "城北",
        "411602008": "文昌",
        "411602009": "搬口",
        "411602010": "金海路",
        "411602200": "李埠口乡",
        "411602201": "许湾乡"
    },
    "411621": {
        "411621001": "桐丘街道",
        "411621002": "扶亭街道",
        "411621101": "崔桥镇",
        "411621102": "江村镇",
        "411621103": "白潭镇",
        "411621104": "韭园镇",
        "411621105": "练寺镇",
        "411621106": "大新镇",
        "411621107": "包屯镇",
        "411621108": "汴岗镇",
        "411621200": "曹里乡",
        "411621201": "柴岗乡",
        "411621202": "固城乡",
        "411621203": "吕潭乡",
        "411621204": "大李庄乡",
        "411621205": "城郊乡"
    },
    "411622": {
        "411622001": "娲城街道",
        "411622002": "箕子台街道",
        "411622003": "昆山街道",
        "411622004": "皮营街道",
        "411622101": "西夏亭镇",
        "411622102": "逍遥镇",
        "411622103": "奉母镇",
        "411622104": "红花集镇",
        "411622105": "聂堆镇",
        "411622106": "东夏亭镇",
        "411622107": "西华营镇",
        "411622108": "址坊镇",
        "411622109": "迟营镇",
        "411622200": "田口乡",
        "411622201": "清河驿乡",
        "411622203": "东王营乡",
        "411622204": "大王庄乡",
        "411622205": "李大庄乡",
        "411622206": "叶埠口乡",
        "411622208": "黄土桥乡",
        "411622209": "艾岗乡",
        "411622405": "黄泛区农场",
        "411622406": "五二农场"
    },
    "411623": {
        "411623001": "新城街道",
        "411623002": "东城街道",
        "411623003": "老城街道",
        "411623101": "黄寨镇",
        "411623102": "练集镇",
        "411623103": "魏集镇",
        "411623104": "固墙镇",
        "411623105": "白寺镇",
        "411623106": "巴村镇",
        "411623107": "谭庄镇",
        "411623108": "邓城镇",
        "411623109": "胡吉镇",
        "411623110": "郝岗镇",
        "411623111": "姚集镇",
        "411623200": "城关乡",
        "411623202": "平店乡",
        "411623203": "袁老乡",
        "411623204": "化河乡",
        "411623206": "舒庄乡",
        "411623207": "大武乡",
        "411623208": "张明乡",
        "411623210": "张庄乡",
        "411623211": "汤庄乡",
        "411623500": "商水县农场"
    },
    "411624": {
        "411624001": "东城",
        "411624002": "北城",
        "411624100": "槐店回族镇",
        "411624101": "刘庄店镇",
        "411624102": "留福集镇",
        "411624103": "老城镇",
        "411624104": "赵德营镇",
        "411624105": "付井镇",
        "411624106": "纸店镇",
        "411624107": "新安集镇",
        "411624108": "白集镇",
        "411624109": "刘湾镇",
        "411624110": "莲池镇",
        "411624111": "洪山镇",
        "411624112": "北杨集镇",
        "411624113": "邢庄镇",
        "411624114": "周营镇",
        "411624201": "石槽集乡",
        "411624202": "范营乡",
        "411624203": "李老庄乡",
        "411624205": "冯营乡",
        "411624209": "卞路口乡"
    },
    "411625": {
        "411625001": "洺南",
        "411625002": "洺北",
        "411625003": "新城",
        "411625101": "吴台镇",
        "411625102": "南丰镇",
        "411625103": "白马镇",
        "411625104": "宁平镇",
        "411625105": "宜路镇",
        "411625106": "钱店镇",
        "411625107": "汲冢镇",
        "411625108": "石槽镇",
        "411625200": "城郊乡",
        "411625201": "虎头岗乡",
        "411625202": "汲水乡",
        "411625203": "张完集乡",
        "411625204": "丁村乡",
        "411625205": "双楼乡",
        "411625206": "秋渠乡",
        "411625207": "东风乡",
        "411625208": "巴集乡",
        "411625209": "李楼乡",
        "411625210": "胡集乡"
    },
    "411626": {
        "411626001": "柳湖街道",
        "411626100": "城关回族镇",
        "411626101": "新站镇",
        "411626102": "鲁台镇",
        "411626103": "四通镇",
        "411626104": "临蔡镇",
        "411626105": "安岭镇",
        "411626106": "白楼镇",
        "411626200": "朱集乡",
        "411626201": "豆门乡",
        "411626202": "冯塘乡",
        "411626203": "刘振屯乡",
        "411626204": "王店乡",
        "411626205": "大连乡",
        "411626206": "葛店乡",
        "411626207": "黄集乡",
        "411626209": "齐老乡",
        "411626210": "郑集乡",
        "411626211": "曹河乡"
    },
    "411627": {
        "411627100": "城关回族镇",
        "411627101": "常营镇",
        "411627102": "逊母口镇",
        "411627103": "老冢镇",
        "411627104": "朱口镇",
        "411627105": "马头镇",
        "411627106": "龙曲镇",
        "411627107": "板桥镇",
        "411627108": "符草楼镇",
        "411627109": "马厂镇",
        "411627110": "毛庄镇",
        "411627111": "张集镇",
        "411627112": "清集镇",
        "411627113": "大许寨镇",
        "411627114": "转楼镇",
        "411627200": "城郊乡",
        "411627201": "杨庙乡",
        "411627202": "王集乡",
        "411627203": "高贤乡",
        "411627204": "芝麻洼乡",
        "411627206": "独塘乡",
        "411627208": "五里口乡",
        "411627210": "高朗乡"
    },
    "411628": {
        "411628001": "真源",
        "411628002": "谷阳",
        "411628003": "卫真",
        "411628004": "鸣鹿",
        "411628100": "涡北镇",
        "411628101": "玄武镇",
        "411628102": "宋河镇",
        "411628103": "太清宫镇",
        "411628104": "王皮溜镇",
        "411628105": "试量镇",
        "411628106": "辛集镇",
        "411628107": "马铺镇",
        "411628108": "贾滩镇",
        "411628109": "杨湖口镇",
        "411628110": "张店镇",
        "411628111": "观堂镇",
        "411628112": "生铁冢镇",
        "411628200": "郑家集乡",
        "411628204": "赵村乡",
        "411628205": "任集乡",
        "411628206": "唐集乡",
        "411628207": "高集乡",
        "411628208": "邱集乡",
        "411628209": "穆店乡"
    },
    "411671": {"411671011": "太昊路", "411671012": "淮河路"},
    "411681": {
        "411681001": "花园",
        "411681002": "水寨",
        "411681003": "东方",
        "411681004": "莲花",
        "411681005": "千佛阁",
        "411681006": "光武",
        "411681100": "南顿镇",
        "411681101": "孙店镇",
        "411681102": "李寨镇",
        "411681103": "贾岭镇",
        "411681104": "高寺镇",
        "411681105": "新桥镇",
        "411681106": "付集镇",
        "411681107": "官会镇",
        "411681108": "丁集镇",
        "411681109": "郑郭镇",
        "411681110": "秣陵镇",
        "411681111": "王明口镇",
        "411681112": "范集镇",
        "411681113": "三店镇",
        "411681114": "永丰镇"
    },
    "411700": {
        "411702": "驿城区",
        "411721": "西平县",
        "411722": "上蔡县",
        "411723": "平舆县",
        "411724": "正阳县",
        "411725": "确山县",
        "411726": "泌阳县",
        "411727": "汝南县",
        "411728": "遂平县",
        "411729": "新蔡县",
        "411771": "河南驻马店经济开发区"
    },
    "411702": {
        "411702001": "人民街道",
        "411702002": "东风街道",
        "411702003": "西园街道",
        "411702004": "新华街道",
        "411702005": "南海街道",
        "411702006": "老街街道",
        "411702007": "橡林街道",
        "411702008": "雪松街道",
        "411702009": "顺河街道",
        "411702010": "刘阁街道",
        "411702011": "香山街道",
        "411702012": "古城街道",
        "411702100": "水屯镇",
        "411702101": "沙河店镇",
        "411702102": "板桥镇",
        "411702103": "诸市镇",
        "411702104": "蚁蜂镇",
        "411702201": "老河乡",
        "411702204": "朱古洞乡",
        "411702205": "胡庙乡",
        "411702561": "金桥街道"
    },
    "411721": {
        "411721001": "柏城街道",
        "411721002": "柏亭街道",
        "411721003": "柏苑街道",
        "411721101": "五沟营镇",
        "411721102": "权寨镇",
        "411721103": "师灵镇",
        "411721104": "出山镇",
        "411721105": "盆尧镇",
        "411721106": "嫘祖镇",
        "411721107": "宋集镇",
        "411721108": "二郎镇",
        "411721201": "重渠乡",
        "411721203": "人和乡",
        "411721205": "谭店乡",
        "411721208": "芦庙乡",
        "411721209": "杨庄乡",
        "411721210": "专探乡",
        "411721212": "蔡寨回族乡",
        "411721213": "焦庄乡",
        "411721500": "老王坡农场"
    },
    "411722": {
        "411722001": "蔡都街道",
        "411722002": "芦岗街道",
        "411722003": "重阳街道",
        "411722004": "卧龙街道",
        "411722101": "黄埠镇",
        "411722102": "杨集镇",
        "411722103": "洙湖镇",
        "411722104": "党店镇",
        "411722105": "朱里镇",
        "411722106": "华陂镇",
        "411722107": "塔桥镇",
        "411722108": "东洪镇",
        "411722109": "邵店镇",
        "411722110": "五龙镇",
        "411722111": "和店镇",
        "411722112": "韩寨镇",
        "411722201": "大路李乡",
        "411722202": "无量寺乡",
        "411722205": "杨屯乡",
        "411722207": "蔡沟乡",
        "411722209": "齐海乡",
        "411722210": "崇礼乡",
        "411722212": "东岸乡",
        "411722215": "小岳寺乡",
        "411722216": "西洪乡",
        "411722217": "百尺乡"
    },
    "411723": {
        "411723001": "古槐街道",
        "411723002": "清河街道",
        "411723003": "东皇街道",
        "411723101": "杨埠镇",
        "411723102": "东和店镇",
        "411723103": "庙湾镇",
        "411723104": "射桥镇",
        "411723105": "西洋店镇",
        "411723106": "阳城镇",
        "411723107": "郭楼镇",
        "411723108": "李屯镇",
        "411723109": "万金店镇",
        "411723110": "高杨店镇",
        "411723111": "万冢镇",
        "411723202": "十字路乡",
        "411723205": "玉皇庙乡",
        "411723208": "老王岗乡",
        "411723209": "辛店乡",
        "411723211": "双庙乡"
    },
    "411724": {
        "411724001": "真阳街道",
        "411724002": "清源街道",
        "411724101": "寒冻镇",
        "411724102": "汝南埠镇",
        "411724103": "铜钟镇",
        "411724104": "陡沟镇",
        "411724105": "熊寨镇",
        "411724106": "大林镇",
        "411724107": "永兴镇",
        "411724108": "袁寨镇",
        "411724200": "慎水乡",
        "411724201": "傅寨乡",
        "411724203": "新阮店乡",
        "411724204": "油坊店乡",
        "411724207": "雷寨乡",
        "411724208": "王勿桥乡",
        "411724210": "吕河乡",
        "411724212": "皮店乡",
        "411724213": "彭桥乡",
        "411724214": "兰青乡"
    },
    "411725": {
        "411725001": "盘龙街道",
        "411725002": "三里河街道",
        "411725003": "朗陵街道",
        "411725101": "竹沟镇",
        "411725102": "任店镇",
        "411725103": "新安店镇",
        "411725104": "留庄镇",
        "411725105": "刘店镇",
        "411725107": "瓦岗镇",
        "411725108": "双河镇",
        "411725109": "石滚河镇",
        "411725110": "李新店镇",
        "411725111": "普会寺镇"
    },
    "411726": {
        "411726001": "花园街道",
        "411726002": "泌水街道",
        "411726003": "古城街道",
        "411726102": "羊册镇",
        "411726103": "马谷田镇",
        "411726105": "春水镇",
        "411726106": "官庄镇",
        "411726107": "赊湾镇",
        "411726108": "郭集镇",
        "411726109": "泰山庙镇",
        "411726110": "王店镇",
        "411726111": "杨家集镇",
        "411726112": "高店镇",
        "411726201": "盘古乡",
        "411726202": "高邑乡",
        "411726204": "铜山乡",
        "411726206": "下碑寺乡",
        "411726207": "象河乡",
        "411726208": "付庄乡",
        "411726209": "贾楼乡",
        "411726210": "黄山口乡",
        "411726215": "双庙街乡",
        "411726400": "工业园区管委会",
        "411726401": "石材开发区管委会"
    },
    "411727": {
        "411727001": "汝宁街道",
        "411727002": "三门闸街道",
        "411727003": "古塔街道",
        "411727004": "宿鸭湖街道",
        "411727101": "王岗镇",
        "411727102": "梁祝镇",
        "411727103": "和孝镇",
        "411727104": "老君庙镇",
        "411727105": "留盆镇",
        "411727106": "金铺镇",
        "411727107": "东官庄镇",
        "411727108": "常兴镇",
        "411727109": "罗店镇",
        "411727110": "韩庄镇",
        "411727111": "三桥镇",
        "411727112": "张楼镇",
        "411727204": "南余店乡",
        "411727212": "板店乡"
    },
    "411728": {
        "411728001": "瞿阳街道",
        "411728002": "车站街道",
        "411728003": "莲花湖街道",
        "411728004": "褚堂街道",
        "411728102": "玉山镇",
        "411728103": "查岈山镇",
        "411728104": "石寨铺镇",
        "411728105": "和兴镇",
        "411728106": "沈寨镇",
        "411728107": "阳丰镇",
        "411728108": "常庄镇",
        "411728109": "花庄镇",
        "411728204": "槐树乡",
        "411728209": "文城乡",
        "411728400": "工业园区",
        "411728500": "查岈山风景区",
        "411728501": "凤鸣谷风景区"
    },
    "411729": {
        "411729001": "古吕街道",
        "411729002": "今是街道",
        "411729003": "月亮湾街道",
        "411729101": "砖店镇",
        "411729102": "陈店镇",
        "411729103": "佛阁寺镇",
        "411729104": "练村镇",
        "411729105": "棠村镇",
        "411729106": "韩集镇",
        "411729107": "龙口镇",
        "411729108": "李桥回族镇",
        "411729109": "黄楼镇",
        "411729110": "孙召镇",
        "411729111": "余店镇",
        "411729204": "河坞乡",
        "411729205": "关津乡",
        "411729206": "宋岗乡",
        "411729207": "顿岗乡",
        "411729208": "涧头乡",
        "411729209": "杨庄户乡",
        "411729210": "化庄乡",
        "411729211": "栎城乡",
        "411729213": "弥陀寺乡"
    },
    "411771": {"411771360": "关王庙乡", "411771560": "金河街道", "411771562": "金山街道", "411771563": "开源"},
    "419000": {"419001": "济源市"},
    "419001": {
        "419001001": "沁园街道",
        "419001002": "济水街道",
        "419001003": "北海街道",
        "419001004": "天坛街道",
        "419001005": "玉泉街道",
        "419001100": "克井镇",
        "419001101": "五龙口镇",
        "419001102": "轵城镇",
        "419001103": "承留镇",
        "419001104": "邵原镇",
        "419001105": "坡头镇",
        "419001106": "梨林镇",
        "419001107": "大峪镇",
        "419001108": "思礼镇",
        "419001109": "王屋镇",
        "419001110": "下冶镇"
    },
    "420000": {
        "420100": "武汉市",
        "420200": "黄石市",
        "420300": "十堰市",
        "420500": "宜昌市",
        "420600": "襄阳市",
        "420700": "鄂州市",
        "420800": "荆门市",
        "420900": "孝感市",
        "421000": "荆州市",
        "421100": "黄冈市",
        "421200": "咸宁市",
        "421300": "随州市",
        "422800": "恩施土家族苗族自治州",
        "429004": "仙桃市",
        "429005": "潜江市",
        "429006": "天门市",
        "429021": "神农架林区"
    },
    "420100": {
        "420102": "江岸区",
        "420103": "江汉区",
        "420104": "硚口区",
        "420105": "汉阳区",
        "420106": "武昌区",
        "420107": "青山区",
        "420111": "洪山区",
        "420112": "东西湖区",
        "420113": "汉南区",
        "420114": "蔡甸区",
        "420115": "江夏区",
        "420116": "黄陂区",
        "420117": "新洲区"
    },
    "420102": {
        "420102002": "大智街",
        "420102003": "一元街",
        "420102004": "车站街",
        "420102005": "四唯街",
        "420102006": "永清街",
        "420102007": "西马街",
        "420102008": "球场街",
        "420102009": "劳动街",
        "420102010": "二七街",
        "420102011": "新村街",
        "420102012": "丹水池街",
        "420102014": "台北街",
        "420102015": "花桥街",
        "420102016": "谌家矶街",
        "420102017": "后湖街",
        "420102018": "塔子湖街",
        "420102400": "百步亭花园"
    },
    "420103": {
        "420103001": "民族街",
        "420103002": "花楼街",
        "420103003": "水塔街",
        "420103004": "民权街",
        "420103005": "满春街",
        "420103006": "民意街",
        "420103007": "新华街",
        "420103008": "万松街",
        "420103009": "唐家墩街",
        "420103010": "北湖街",
        "420103011": "前进街",
        "420103012": "常青街",
        "420103013": "汉兴街",
        "420103400": "江汉经济开发区",
        "420103401": "武汉中央商务区管理委员会"
    },
    "420104": {
        "420104001": "古田街道",
        "420104002": "韩家墩街道",
        "420104003": "宗关街道",
        "420104004": "汉水桥街道",
        "420104005": "宝丰街道",
        "420104006": "荣华街道",
        "420104008": "汉中街道",
        "420104009": "汉正街道",
        "420104010": "六角亭街道",
        "420104011": "长丰街道",
        "420104012": "易家街道"
    },
    "420105": {
        "420105002": "建桥街",
        "420105004": "晴川街",
        "420105005": "鹦鹉街",
        "420105006": "洲头街",
        "420105007": "五里墩街",
        "420105008": "琴断口街",
        "420105009": "江汉二桥街",
        "420105010": "永丰街",
        "420105011": "江堤街",
        "420105012": "四新街",
        "420105013": "龙阳街"
    },
    "420106": {
        "420106001": "积玉桥街",
        "420106002": "杨园街",
        "420106003": "徐家棚街",
        "420106005": "粮道街",
        "420106006": "中华路街",
        "420106007": "黄鹤楼街",
        "420106008": "紫阳街",
        "420106009": "白沙洲街",
        "420106010": "首义路街",
        "420106011": "中南路街",
        "420106012": "水果湖街",
        "420106013": "珞珈山街",
        "420106014": "石洞街",
        "420106015": "南湖街",
        "420106490": "东湖风景区街道（武昌）"
    },
    "420107": {
        "420107001": "红卫路街道",
        "420107002": "冶金街道",
        "420107003": "新沟桥街道",
        "420107004": "红钢城街道",
        "420107005": "工人村街道",
        "420107006": "青山镇街道",
        "420107007": "厂前街道",
        "420107008": "武东街道",
        "420107009": "白玉山街道",
        "420107010": "钢花村街道",
        "420107400": "武钢集团",
        "420107401": "中国一冶有限责任公司",
        "420107402": "武汉钢铁有限公司",
        "420107403": "中国一冶集体企业管理处",
        "420107404": "开发区",
        "420107405": "钢都花园管理委员会",
        "420107406": "北湖工业园管理办公室"
    },
    "420111": {
        "420111001": "珞南街道",
        "420111002": "关山街道",
        "420111003": "狮子山街道",
        "420111004": "张家湾街道",
        "420111005": "梨园街道",
        "420111006": "卓刀泉街道",
        "420111007": "洪山街道",
        "420111008": "和平街道",
        "420111009": "青菱街道",
        "420111061": "八吉府街",
        "420111080": "关东街道",
        "420111081": "花山街道",
        "420111082": "左岭街道",
        "420111083": "九峰街道",
        "420111205": "天兴乡",
        "420111460": "清谭湖",
        "420111491": "东湖风景区街道（洪山）"
    },
    "420112": {
        "420112001": "吴家山街道",
        "420112002": "柏泉街道",
        "420112004": "将军路街道",
        "420112005": "慈惠街道",
        "420112006": "走马岭街道",
        "420112007": "径河街道",
        "420112008": "长青街道",
        "420112011": "辛安渡街道",
        "420112012": "东山街道",
        "420112013": "常青花园新区街道管理委员会",
        "420112014": "新沟镇街道",
        "420112015": "金银湖街道",
        "420112400": "产业管理处",
        "420112401": "保税物流管理处",
        "420112402": "海峡两岸科技产业管理处",
        "420112403": "食品工业管理处",
        "420112404": "临空经济管理处",
        "420112405": "农科所管理处",
        "420112406": "现代服务业管理处"
    },
    "420113": {"420113001": "纱帽街道", "420113002": "邓南街道", "420113003": "东荆街道", "420113004": "湘口街道"},
    "420114": {
        "420114001": "蔡甸街道",
        "420114002": "奓山街道",
        "420114003": "永安街道",
        "420114004": "侏儒山街道",
        "420114005": "大集街道",
        "420114006": "张湾街道",
        "420114007": "索河街道",
        "420114008": "玉贤街道",
        "420114070": "沌口街",
        "420114071": "军山街道",
        "420114072": "沌阳街道",
        "420114200": "消泗乡",
        "420114400": "桐湖",
        "420114401": "洪北管委会",
        "420114404": "中法武汉生态示范城管委会",
        "420114406": "成功现代都市农业发展区管理委员会",
        "420114407": "武汉蔡甸经济开发区管理委员会"
    },
    "420115": {
        "420115001": "纸坊街道",
        "420115002": "金口街道",
        "420115003": "乌龙泉街道",
        "420115005": "郑店街道",
        "420115007": "五里界街道",
        "420115008": "金水",
        "420115009": "安山街道",
        "420115010": "山坡街道",
        "420115014": "法泗街道",
        "420115015": "湖泗街道",
        "420115016": "舒安街道",
        "420115086": "佛祖岭街道",
        "420115087": "豹澥街道",
        "420115088": "龙泉街道",
        "420115089": "滨湖街道",
        "420115401": "武汉江夏经济开发区庙山高新技术产业园",
        "420115402": "武汉江夏经济开发区藏龙岛高新技术产业园",
        "420115403": "武汉江夏经济开发区大桥现代产业园",
        "420115404": "江夏区经济开发区梁子湖风景区办事",
        "420115405": "武汉江夏经济开发区金港汽车产业园"
    },
    "420116": {
        "420116001": "前川街道",
        "420116002": "祁家湾街道",
        "420116003": "横店街道",
        "420116004": "罗汉寺街道",
        "420116005": "滠口街道",
        "420116006": "六指街道",
        "420116007": "天河街道",
        "420116008": "王家河街道",
        "420116009": "长轩岭街道",
        "420116010": "李集街道",
        "420116011": "姚集街道",
        "420116012": "蔡榨街道",
        "420116013": "武湖街道",
        "420116014": "三里桥街道",
        "420116015": "蔡店街道",
        "420116201": "木兰乡",
        "420116401": "木兰山风景区管理处",
        "420116402": "大潭",
        "420116403": "盘龙城经济开发区",
        "420116404": "汉口北商贸物流枢纽区管委会"
    },
    "420117": {
        "420117001": "邾城街道",
        "420117002": "阳逻街道",
        "420117003": "仓埠街道",
        "420117004": "汪集街道",
        "420117005": "李集街道",
        "420117006": "三店街道",
        "420117007": "潘塘街道",
        "420117008": "旧街街道",
        "420117009": "双柳街道",
        "420117010": "涨渡湖街道",
        "420117011": "辛冲街道",
        "420117012": "徐古街道",
        "420117102": "凤凰镇",
        "420117400": "道观河风景旅游管理处",
        "420117406": "新洲原种场"
    },
    "420200": {"420202": "黄石港区", "420203": "西塞山区", "420204": "下陆区", "420205": "铁山区", "420222": "阳新县", "420281": "大冶市"},
    "420202": {
        "420202070": "花湖街道",
        "420202400": "江北管理区",
        "420202402": "黄石港区环磁湖片区工作委员会",
        "420202403": "黄石港区黄石港片区工作委员会",
        "420202404": "黄石港区胜阳港片区工作委员会"
    },
    "420203": {
        "420203007": "章山街道",
        "420203400": "冶钢农场",
        "420203402": "西塞山工业园区管委会",
        "420203501": "八泉街道",
        "420203502": "澄月街道",
        "420203503": "牧羊湖街道",
        "420203504": "黄思湾街道"
    },
    "420204": {
        "420204070": "团城山街道",
        "420204400": "社区事务管理委员会",
        "420204401": "长乐山工业园管理委员会",
        "420204402": "东方山风景区服务处",
        "420204403": "下陆区生态新区管理委员会"
    },
    "420205": {"420205198": "区直辖村模拟镇"},
    "420222": {
        "420222100": "兴国镇",
        "420222101": "富池镇",
        "420222102": "黄颡口镇",
        "420222103": "韦源口镇",
        "420222104": "太子镇",
        "420222105": "大王镇",
        "420222106": "陶港镇",
        "420222107": "白沙镇",
        "420222108": "浮屠镇",
        "420222109": "三溪镇",
        "420222110": "龙港镇",
        "420222111": "洋港镇",
        "420222112": "排市镇",
        "420222113": "木港镇",
        "420222114": "枫林镇",
        "420222115": "王英镇",
        "420222451": "率洲农场",
        "420222452": "半壁山农场",
        "420222453": "荆头山农场",
        "420222455": "金海开发区",
        "420222456": "城东新区"
    },
    "420281": {
        "420281001": "东岳路街道",
        "420281002": "东风路街道",
        "420281003": "金湖街道",
        "420281004": "罗家桥街道",
        "420281070": "金山街道",
        "420281100": "金牛镇",
        "420281101": "保安镇",
        "420281102": "灵乡镇",
        "420281103": "金山店镇",
        "420281104": "还地桥镇",
        "420281105": "殷祖镇",
        "420281107": "刘仁八镇",
        "420281108": "陈贵镇",
        "420281109": "大箕铺镇",
        "420281170": "汪仁镇",
        "420281200": "茗山乡",
        "420281401": "东风农场管理区",
        "420281402": "四顾闸管理处"
    },
    "420300": {
        "420302": "茅箭区",
        "420303": "张湾区",
        "420304": "郧阳区",
        "420322": "郧西县",
        "420323": "竹山县",
        "420324": "竹溪县",
        "420325": "房县",
        "420381": "丹江口市"
    },
    "420302": {
        "420302001": "武当路街道",
        "420302002": "二堰街道",
        "420302003": "五堰街道",
        "420302004": "白浪开发区白浪街道",
        "420302100": "大川镇人民政府",
        "420302200": "小川乡",
        "420302201": "茅塔乡",
        "420302202": "鸳鸯乡"
    },
    "420303": {
        "420303001": "花果街道",
        "420303002": "红卫街道",
        "420303003": "车城路街道",
        "420303004": "汉江路街道",
        "420303100": "黄龙镇",
        "420303101": "柏林镇",
        "420303200": "方滩乡",
        "420303201": "西沟乡",
        "420303400": "西城开发区",
        "420303401": "十堰工业新区",
        "420303500": "秦家坪林场",
        "420303501": "凤凰山茶场"
    },
    "420304": {
        "420304101": "安阳镇",
        "420304102": "杨溪铺镇",
        "420304103": "青曲镇",
        "420304104": "白桑关镇",
        "420304105": "南化塘镇",
        "420304106": "白浪镇",
        "420304107": "刘洞镇",
        "420304108": "谭山镇",
        "420304109": "梅铺镇",
        "420304110": "青山镇",
        "420304111": "郧县经济开发区（茶店镇）",
        "420304112": "柳陂镇",
        "420304113": "鲍峡镇",
        "420304114": "胡家营镇",
        "420304115": "谭家湾镇",
        "420304117": "郧县城关镇",
        "420304200": "大柳乡",
        "420304201": "五峰乡",
        "420304202": "叶大乡",
        "420304400": "红岩背林场"
    },
    "420322": {
        "420322100": "城关镇",
        "420322101": "土门镇",
        "420322102": "上津镇",
        "420322103": "店子镇",
        "420322104": "夹河镇",
        "420322105": "羊尾镇",
        "420322106": "观音镇",
        "420322107": "马鞍镇",
        "420322108": "河夹镇",
        "420322200": "香口乡",
        "420322201": "关防乡",
        "420322202": "湖北口回族乡",
        "420322203": "景阳乡",
        "420322204": "六郎乡",
        "420322205": "涧池乡",
        "420322206": "安家乡",
        "420322400": "槐树林特场管委会",
        "420322401": "三官洞林区",
        "420322402": "工业园区"
    },
    "420323": {
        "420323100": "城关镇",
        "420323101": "溢水镇",
        "420323102": "麻家渡镇",
        "420323103": "宝丰镇",
        "420323104": "擂鼓镇",
        "420323105": "秦古镇",
        "420323106": "得胜镇",
        "420323107": "上庸镇",
        "420323108": "官渡镇",
        "420323200": "潘口乡",
        "420323201": "竹坪乡",
        "420323202": "大庙乡",
        "420323203": "双台乡",
        "420323204": "楼台乡",
        "420323205": "文峰乡",
        "420323206": "深河乡",
        "420323207": "柳林乡"
    },
    "420324": {
        "420324100": "城关镇",
        "420324101": "蒋家堰镇",
        "420324102": "中峰镇",
        "420324103": "水坪镇",
        "420324104": "县河镇",
        "420324105": "泉溪镇",
        "420324106": "丰溪镇",
        "420324107": "龙坝镇",
        "420324108": "兵营镇",
        "420324109": "汇湾镇",
        "420324110": "新洲镇",
        "420324203": "鄂坪乡",
        "420324205": "天宝乡",
        "420324206": "桃源乡",
        "420324207": "向坝乡",
        "420324500": "龙王垭茶场",
        "420324501": "国营竹溪综合农场",
        "420324502": "原种场",
        "420324503": "渔种场",
        "420324504": "种畜场",
        "420324505": "王家山茶场",
        "420324506": "标湖林场",
        "420324507": "双竹林场",
        "420324508": "八卦山林场",
        "420324509": "源茂林场",
        "420324510": "岱王沟林场",
        "420324511": "望府座林场",
        "420324512": "天池垭林场",
        "420324513": "九里岗林场",
        "420324514": "竹溪县十八里长峡管理局"
    },
    "420325": {
        "420325100": "城关镇",
        "420325101": "军店镇",
        "420325102": "化龙堰镇",
        "420325103": "土城镇",
        "420325104": "大木厂镇",
        "420325105": "青峰镇",
        "420325106": "门古寺镇",
        "420325107": "白鹤镇",
        "420325108": "野人谷镇",
        "420325109": "红塔镇",
        "420325110": "窑淮镇",
        "420325111": "尹吉甫镇",
        "420325203": "姚坪乡",
        "420325205": "沙河乡",
        "420325206": "万峪河乡",
        "420325208": "上龛乡",
        "420325209": "中坝乡",
        "420325210": "九道乡",
        "420325211": "回龙乡",
        "420325212": "五台乡"
    },
    "420381": {
        "420381001": "均州路街道",
        "420381002": "大坝路街道",
        "420381003": "丹赵路街道",
        "420381005": "三官殿街道",
        "420381100": "土关垭镇",
        "420381101": "浪河镇",
        "420381102": "丁家营镇",
        "420381103": "六里坪镇",
        "420381104": "盐池河镇",
        "420381105": "均县镇",
        "420381106": "习家店镇",
        "420381107": "蒿坪镇",
        "420381108": "石鼓镇",
        "420381109": "凉水河镇",
        "420381110": "官山镇",
        "420381111": "龙山镇",
        "420381400": "新港经济开发管理处",
        "420381401": "牛河林业开发管理区",
        "420381402": "白杨坪林业开发管理区",
        "420381403": "大沟林业开发管理区",
        "420381450": "武当山特区"
    },
    "420500": {
        "420502": "西陵区",
        "420503": "伍家岗区",
        "420504": "点军区",
        "420505": "猇亭区",
        "420506": "夷陵区",
        "420525": "远安县",
        "420526": "兴山县",
        "420527": "秭归县",
        "420528": "长阳土家族自治县",
        "420529": "五峰土家族自治县",
        "420581": "宜都市",
        "420582": "当阳市",
        "420583": "枝江市"
    },
    "420502": {
        "420502001": "西陵街道",
        "420502002": "学院街道",
        "420502003": "云集街道",
        "420502004": "西坝街道",
        "420502005": "葛洲坝街道",
        "420502006": "夜明珠街道",
        "420502007": "东苑街道",
        "420502008": "南苑街道",
        "420502009": "北苑街道",
        "420502010": "窑湾街道"
    },
    "420503": {
        "420503001": "大公桥街道",
        "420503002": "万寿桥街道",
        "420503003": "宝塔河街道",
        "420503004": "伍家岗街道",
        "420503201": "伍家乡"
    },
    "420504": {"420504001": "点军街道", "420504101": "艾家镇", "420504102": "桥边镇", "420504201": "联棚乡", "420504202": "土城乡"},
    "420505": {"420505001": "古老背街道", "420505002": "虎牙街道", "420505003": "云池街道"},
    "420506": {
        "420506001": "小溪塔街道办",
        "420506003": "东城城乡统筹发展试验区管理委员会",
        "420506101": "樟村坪镇",
        "420506102": "雾渡河镇",
        "420506103": "分乡镇",
        "420506104": "太平溪镇",
        "420506105": "三斗坪镇",
        "420506106": "乐天溪镇",
        "420506107": "龙泉镇",
        "420506108": "鸦鹊岭镇",
        "420506109": "黄花镇",
        "420506201": "下堡坪乡",
        "420506202": "邓村乡",
        "420506401": "三峡坝区"
    },
    "420525": {
        "420525101": "鸣凤镇",
        "420525102": "花林寺镇",
        "420525103": "旧县镇",
        "420525104": "洋坪镇",
        "420525105": "茅坪场镇",
        "420525106": "嫘祖镇",
        "420525201": "河口乡"
    },
    "420526": {
        "420526101": "古夫镇",
        "420526102": "昭君镇",
        "420526103": "峡口镇",
        "420526104": "南阳镇",
        "420526105": "黄粮镇",
        "420526106": "水月寺镇",
        "420526201": "高桥乡",
        "420526202": "榛子乡"
    },
    "420527": {
        "420527101": "茅坪镇",
        "420527102": "归州镇",
        "420527103": "屈原镇",
        "420527104": "沙镇溪镇",
        "420527105": "两河口镇",
        "420527106": "郭家坝镇",
        "420527107": "杨林桥镇",
        "420527108": "九畹溪镇",
        "420527201": "水田坝乡",
        "420527202": "泄滩乡",
        "420527203": "梅家河乡",
        "420527204": "磨坪乡"
    },
    "420528": {
        "420528101": "龙舟坪镇",
        "420528102": "高家堰镇",
        "420528103": "磨市镇",
        "420528104": "都镇湾镇",
        "420528105": "资丘镇",
        "420528106": "渔峡口镇",
        "420528107": "榔坪镇",
        "420528108": "贺家坪镇",
        "420528201": "大堰乡",
        "420528202": "鸭子口乡",
        "420528203": "火烧坪乡"
    },
    "420529": {
        "420529111": "渔洋关镇",
        "420529112": "仁和坪镇",
        "420529113": "长乐坪镇",
        "420529114": "五峰镇",
        "420529115": "湾潭镇",
        "420529201": "付家堰乡",
        "420529202": "牛庄乡",
        "420529203": "采花乡"
    },
    "420581": {
        "420581001": "陆城街道",
        "420581100": "红花套镇",
        "420581101": "高坝洲镇",
        "420581102": "聂家河镇",
        "420581103": "松木坪镇",
        "420581104": "枝城镇",
        "420581105": "姚家店镇",
        "420581106": "五眼泉镇",
        "420581107": "王家畈镇",
        "420581200": "潘家湾土家族乡",
        "420581400": "松宜矿区管理委员会"
    },
    "420582": {
        "420582001": "当阳市玉阳街道",
        "420582002": "当阳市坝陵街道",
        "420582003": "当阳市玉泉街道",
        "420582101": "当阳市两河镇",
        "420582102": "当阳市河溶镇",
        "420582103": "当阳市淯溪镇",
        "420582104": "当阳市庙前镇",
        "420582105": "当阳市王店镇",
        "420582106": "当阳市半月镇",
        "420582107": "当阳市草埠湖镇"
    },
    "420583": {
        "420583001": "马家店街道",
        "420583101": "安福寺镇",
        "420583102": "白洋镇",
        "420583103": "顾家店镇",
        "420583104": "董市镇",
        "420583105": "仙女镇",
        "420583106": "问安镇",
        "420583107": "七星台镇",
        "420583108": "百里洲镇"
    },
    "420600": {
        "420602": "襄城区",
        "420606": "樊城区",
        "420607": "襄州区",
        "420624": "南漳县",
        "420625": "谷城县",
        "420626": "保康县",
        "420682": "老河口市",
        "420683": "枣阳市",
        "420684": "宜城市"
    },
    "420602": {
        "420602001": "真武山街道",
        "420602002": "古城街道",
        "420602003": "庞公街道",
        "420602004": "檀溪街道",
        "420602005": "隆中街道",
        "420602006": "余家湖街道",
        "420602100": "欧庙镇",
        "420602101": "卧龙镇",
        "420602200": "尹集乡"
    },
    "420606": {
        "420606001": "汉江街道",
        "420606002": "王寨街道",
        "420606003": "中原街道",
        "420606004": "定中门街道",
        "420606005": "清河口街道",
        "420606006": "屏襄门街道",
        "420606007": "米公街道",
        "420606008": "柿铺街道",
        "420606070": "高新区紫贞街道",
        "420606071": "高新区七里河街道",
        "420606072": "高新区东风",
        "420606100": "牛首镇",
        "420606101": "太平店镇",
        "420606170": "高新区团山镇",
        "420606171": "高新区米庄镇",
        "420606400": "经济开发区",
        "420606480": "鱼梁洲开发区",
        "420606570": "高新区汽车工业园",
        "420606571": "高新区高新技术产业园",
        "420606572": "高新区高新技术创业服务中心"
    },
    "420607": {
        "420607001": "张湾",
        "420607002": "刘集",
        "420607003": "肖湾",
        "420607004": "六两河街道",
        "420607100": "龙王镇",
        "420607101": "石桥镇",
        "420607102": "黄集镇",
        "420607103": "伙牌镇",
        "420607104": "古驿镇",
        "420607105": "朱集镇",
        "420607106": "程河镇",
        "420607107": "双沟镇",
        "420607108": "张家集镇",
        "420607109": "黄龙镇",
        "420607110": "峪山镇",
        "420607111": "东津镇",
        "420607402": "鹿门风景名胜区",
        "420607500": "襄北农场",
        "420607570": "深圳工业园"
    },
    "420624": {
        "420624100": "城关镇",
        "420624101": "武安镇",
        "420624102": "九集镇",
        "420624103": "李庙镇",
        "420624104": "长坪镇",
        "420624105": "薛坪镇",
        "420624106": "板桥镇",
        "420624107": "巡检镇",
        "420624108": "东巩镇",
        "420624109": "肖堰镇",
        "420624500": "清河管理区"
    },
    "420625": {
        "420625100": "城关镇",
        "420625101": "石花镇",
        "420625102": "盛康镇",
        "420625103": "庙滩镇",
        "420625104": "五山镇",
        "420625105": "茨河镇",
        "420625106": "南河镇",
        "420625107": "紫金镇",
        "420625108": "冷集镇",
        "420625200": "赵湾乡",
        "420625500": "薤山林场"
    },
    "420626": {
        "420626100": "城关镇",
        "420626101": "黄堡镇",
        "420626102": "后坪镇",
        "420626103": "龙坪镇",
        "420626104": "店垭镇",
        "420626105": "马良镇",
        "420626106": "歇马镇",
        "420626107": "马桥镇",
        "420626108": "寺坪镇",
        "420626109": "过渡湾镇",
        "420626200": "两峪乡",
        "420626400": "湖北保康经济开发区管理委员会"
    },
    "420682": {
        "420682001": "光化街道",
        "420682002": "酂阳街道",
        "420682100": "孟楼镇",
        "420682101": "竹林桥镇",
        "420682102": "薛集镇",
        "420682103": "张集镇",
        "420682104": "仙人渡镇",
        "420682105": "洪山嘴镇",
        "420682106": "李楼镇",
        "420682200": "袁冲乡",
        "420682501": "蔬菜原种场",
        "420682502": "百花山林场",
        "420682503": "林茂山林场",
        "420682504": "二房营原种场"
    },
    "420683": {
        "420683001": "北城街道",
        "420683002": "南城街道",
        "420683003": "环城街道",
        "420683100": "琚湾镇",
        "420683101": "七方镇",
        "420683102": "杨当镇",
        "420683103": "太平镇",
        "420683104": "新市镇",
        "420683105": "鹿头镇",
        "420683106": "刘升镇",
        "420683107": "兴隆镇",
        "420683108": "王城镇",
        "420683109": "吴店镇",
        "420683110": "熊集镇",
        "420683111": "平林镇",
        "420683402": "枣阳经济开发区",
        "420683500": "随阳农场",
        "420683501": "车河农场"
    },
    "420684": {
        "420684001": "鄢城街道",
        "420684002": "南营街道",
        "420684102": "郑集镇",
        "420684103": "小河镇",
        "420684104": "刘猴镇",
        "420684105": "孔湾镇",
        "420684106": "流水镇",
        "420684107": "板桥镇",
        "420684108": "王集镇",
        "420684109": "雷河镇",
        "420684400": "襄南监狱",
        "420684401": "劳教所",
        "420684450": "大雁工业园区",
        "420684452": "宜城经济开发区"
    },
    "420700": {"420702": "梁子湖区", "420703": "华容区", "420704": "鄂城区"},
    "420702": {
        "420702100": "太和镇",
        "420702101": "东沟镇",
        "420702102": "梁子生态管理区（镇）",
        "420702103": "涂家垴镇",
        "420702104": "沼山镇",
        "420702400": "梧桐湖新区"
    },
    "420703": {
        "420703100": "华容镇",
        "420703101": "葛店镇",
        "420703102": "庙岭镇",
        "420703103": "段店镇",
        "420703200": "临江乡",
        "420703201": "蒲团乡",
        "420703400": "葛店开发区"
    },
    "420704": {
        "420704001": "凤凰街道",
        "420704002": "古楼街道",
        "420704003": "西山街道",
        "420704100": "泽林镇",
        "420704101": "杜山镇",
        "420704102": "新庙镇",
        "420704103": "碧石镇",
        "420704104": "汀祖镇",
        "420704105": "燕矶镇",
        "420704106": "杨叶镇",
        "420704107": "花湖镇",
        "420704108": "长港镇",
        "420704200": "沙窝乡",
        "420704400": "鄂州经济开发区"
    },
    "420800": {"420802": "东宝区", "420804": "掇刀区", "420822": "沙洋县", "420881": "钟祥市", "420882": "京山市"},
    "420802": {
        "420802001": "龙泉街道办",
        "420802002": "泉口街道办",
        "420802100": "栗溪镇",
        "420802101": "子陵镇",
        "420802102": "漳河镇",
        "420802103": "马河镇",
        "420802104": "石桥驿镇",
        "420802105": "牌楼镇",
        "420802200": "仙居乡",
        "420802400": "东宝工业园区"
    },
    "420804": {
        "420804001": "掇刀街道",
        "420804002": "白庙街道",
        "420804003": "兴隆街道",
        "420804004": "双喜街道",
        "420804100": "团林铺镇",
        "420804101": "麻城镇"
    },
    "420822": {
        "420822100": "沙洋镇",
        "420822101": "五里铺镇",
        "420822102": "十里铺镇",
        "420822103": "纪山镇",
        "420822104": "拾回桥镇",
        "420822105": "后港镇",
        "420822106": "毛李镇",
        "420822107": "官当镇",
        "420822108": "李市镇",
        "420822109": "马良镇",
        "420822110": "高阳镇",
        "420822111": "沈集镇",
        "420822112": "曾集镇",
        "420822450": "沙洋监狱管理局"
    },
    "420881": {
        "420881001": "郢中街道",
        "420881100": "洋梓镇",
        "420881101": "长寿镇",
        "420881102": "丰乐镇",
        "420881103": "胡集镇",
        "420881104": "双河镇",
        "420881105": "磷矿镇",
        "420881106": "文集镇",
        "420881107": "冷水镇",
        "420881108": "石牌镇",
        "420881109": "旧口镇",
        "420881110": "柴湖镇",
        "420881111": "长滩镇",
        "420881112": "东桥镇",
        "420881113": "客店镇",
        "420881114": "张集镇",
        "420881200": "九里乡",
        "420881400": "湖北钟祥经济开发区",
        "420881450": "钟祥市官庄湖管理区",
        "420881451": "南湖原种场",
        "420881452": "罗汉寺种畜场",
        "420881453": "双桥原种场",
        "420881454": "东岳种畜场",
        "420881455": "康桥湖农场",
        "420881456": "大口林场",
        "420881457": "盘石岭林场",
        "420881458": "温峡水库",
        "420881459": "石门水库",
        "420881460": "黄坡水库",
        "420881461": "湖北钟祥胡集经济技术开发区"
    },
    "420882": {
        "420882100": "新市镇",
        "420882101": "永兴镇",
        "420882102": "曹武镇",
        "420882103": "罗店镇",
        "420882104": "宋河镇",
        "420882105": "坪坝镇",
        "420882106": "三阳镇",
        "420882107": "绿林镇",
        "420882108": "杨集镇",
        "420882109": "孙桥镇",
        "420882110": "石龙镇",
        "420882111": "永漋镇",
        "420882112": "雁门口镇",
        "420882113": "钱场镇",
        "420882170": "开发区（镇）",
        "420882449": "温泉新区",
        "420882450": "太子山",
        "420882451": "罗汉寺",
        "420882452": "长滩",
        "420882453": "何集",
        "420882455": "易家岭",
        "420882456": "屈家岭经济开发区",
        "420882598": "原种场镇"
    },
    "420900": {
        "420902": "孝南区",
        "420921": "孝昌县",
        "420922": "大悟县",
        "420923": "云梦县",
        "420981": "应城市",
        "420982": "安陆市",
        "420984": "汉川市"
    },
    "420902": {
        "420902001": "孝南区书院街",
        "420902002": "孝南区新华街",
        "420902003": "孝南区广场街",
        "420902004": "孝南区车站街道",
        "420902100": "孝南区新铺镇",
        "420902101": "孝南区西河镇",
        "420902102": "孝南区杨店镇",
        "420902103": "孝南区陡岗镇",
        "420902104": "孝南区肖港镇",
        "420902105": "孝南区毛陈镇",
        "420902106": "孝南区三汊镇",
        "420902107": "孝南区祝站镇",
        "420902200": "孝南区朋兴乡",
        "420902201": "孝南区卧龙乡",
        "420902202": "孝南区闵集乡",
        "420902400": "孝南区经济开发区",
        "420902450": "孝南区朱湖",
        "420902500": "孝南区东山头",
        "420902570": "孝感开发区丹阳",
        "420902571": "孝感开发区孝天",
        "420902572": "孝感开发区槐荫"
    },
    "420921": {
        "420921100": "花园镇",
        "420921101": "丰山镇",
        "420921102": "周巷镇",
        "420921103": "小河镇",
        "420921104": "王店镇",
        "420921105": "卫店镇",
        "420921106": "白沙镇",
        "420921107": "邹岗镇",
        "420921200": "小悟乡",
        "420921201": "季店乡",
        "420921202": "花西乡",
        "420921203": "陡山乡",
        "420921400": "开发区",
        "420921450": "观音湖生态文化旅游渡假区",
        "420921500": "双峰山旅游渡假区"
    },
    "420922": {
        "420922100": "城关镇",
        "420922101": "阳平镇",
        "420922102": "芳畈镇",
        "420922103": "新城镇",
        "420922104": "夏店镇",
        "420922105": "刘集镇",
        "420922106": "河口镇",
        "420922107": "四姑镇",
        "420922108": "吕王镇",
        "420922109": "黄站镇",
        "420922110": "宣化店镇",
        "420922111": "丰店镇",
        "420922112": "大新镇",
        "420922113": "三里镇",
        "420922200": "高店乡",
        "420922201": "彭店乡",
        "420922202": "东新乡"
    },
    "420923": {
        "420923100": "城关镇",
        "420923101": "义堂镇",
        "420923102": "曾店镇",
        "420923103": "吴铺镇",
        "420923104": "伍洛镇",
        "420923105": "下辛店镇",
        "420923106": "道桥镇",
        "420923107": "隔蒲潭镇",
        "420923108": "胡金店镇",
        "420923200": "倒店乡",
        "420923201": "沙河乡",
        "420923202": "清明河乡",
        "420923450": "县经济开发区"
    },
    "420981": {
        "420981001": "城中街道",
        "420981002": "城北街道",
        "420981003": "四里棚街道",
        "420981004": "东马坊街道",
        "420981005": "长江埠街道",
        "420981100": "田店镇",
        "420981101": "杨河镇",
        "420981102": "三合镇",
        "420981103": "郎君镇",
        "420981104": "黄滩镇",
        "420981105": "天鹅镇",
        "420981106": "义和镇",
        "420981107": "陈河镇",
        "420981108": "杨岭镇",
        "420981109": "汤池镇",
        "420981400": "经济技术开发区",
        "420981500": "南垸良种场"
    },
    "420982": {
        "420982001": "府城街道",
        "420982002": "南城街道",
        "420982100": "赵棚镇",
        "420982101": "李店镇",
        "420982102": "巡店镇",
        "420982103": "棠棣镇",
        "420982104": "雷公镇",
        "420982105": "王义贞镇",
        "420982106": "烟店镇",
        "420982107": "孛畈镇",
        "420982108": "伏水镇",
        "420982200": "陈店乡",
        "420982201": "辛榨乡",
        "420982202": "木梓乡",
        "420982203": "接官乡",
        "420982400": "开发区"
    },
    "420984": {
        "420984001": "仙女山街道",
        "420984002": "汈东街道",
        "420984100": "马口镇",
        "420984101": "脉旺镇",
        "420984102": "城隍镇",
        "420984103": "分水镇",
        "420984104": "沉湖镇",
        "420984105": "田二河镇",
        "420984106": "回龙镇",
        "420984107": "新堰镇",
        "420984108": "垌塚镇",
        "420984109": "麻河镇",
        "420984110": "刘家隔镇",
        "420984111": "新河镇",
        "420984112": "庙头镇",
        "420984113": "杨林沟镇",
        "420984200": "西江乡",
        "420984201": "湾潭乡",
        "420984202": "南河乡",
        "420984203": "马鞍乡",
        "420984204": "里潭乡",
        "420984205": "韩集乡",
        "420984450": "国营中洲农场",
        "420984500": "华严农场",
        "420984502": "三星垸原种场",
        "420984503": "汈汊湖养殖场",
        "420984504": "汉川市经济技术开发区"
    },
    "421000": {
        "421002": "沙市区",
        "421003": "荆州区",
        "421022": "公安县",
        "421023": "监利县",
        "421024": "江陵县",
        "421071": "荆州经济技术开发区",
        "421081": "石首市",
        "421083": "洪湖市",
        "421087": "松滋市"
    },
    "421002": {
        "421002001": "中山街道",
        "421002002": "崇文街道",
        "421002003": "解放街道",
        "421002004": "胜利街道",
        "421002005": "朝阳街道",
        "421002006": "立新街道",
        "421002100": "锣场镇",
        "421002101": "岑河镇",
        "421002102": "观音当镇",
        "421002103": "关沮镇"
    },
    "421003": {
        "421003001": "西城街道",
        "421003002": "东城街道",
        "421003003": "城南高新园管理委员会",
        "421003100": "纪南镇",
        "421003101": "川店镇",
        "421003102": "马山镇",
        "421003103": "八岭山镇",
        "421003104": "李埠镇",
        "421003105": "弥市镇",
        "421003106": "郢城镇",
        "421003450": "太湖港管理区",
        "421003451": "菱角湖管理区"
    },
    "421022": {
        "421022100": "埠河镇",
        "421022101": "斗湖堤镇",
        "421022102": "夹竹园镇",
        "421022103": "闸口镇",
        "421022104": "杨家厂镇",
        "421022105": "麻豪口镇",
        "421022106": "藕池镇",
        "421022107": "黄山头镇",
        "421022108": "孟家溪镇",
        "421022109": "南平镇",
        "421022110": "章庄铺镇",
        "421022111": "狮子口镇",
        "421022112": "斑竹垱镇",
        "421022113": "毛家港镇",
        "421022200": "甘家厂乡",
        "421022201": "章田寺乡"
    },
    "421023": {
        "421023100": "容城镇",
        "421023101": "朱河镇",
        "421023102": "新沟镇",
        "421023103": "龚场镇",
        "421023104": "周老嘴镇",
        "421023105": "黄歇口镇",
        "421023106": "汪桥镇",
        "421023107": "程集镇",
        "421023108": "分盐镇",
        "421023109": "毛市镇",
        "421023110": "福田寺镇",
        "421023111": "上车湾镇",
        "421023112": "汴河镇",
        "421023113": "尺八镇",
        "421023114": "白螺镇",
        "421023115": "网市镇",
        "421023116": "三洲镇",
        "421023117": "桥市镇",
        "421023200": "红城乡",
        "421023201": "棋盘乡",
        "421023202": "柘木乡",
        "421023450": "人民大垸农场管理区",
        "421023451": "荒湖农场管理区"
    },
    "421024": {
        "421024100": "资市镇",
        "421024102": "熊河镇",
        "421024103": "白马寺镇",
        "421024104": "沙岗镇",
        "421024105": "普济镇",
        "421024106": "郝穴镇",
        "421024200": "马家寨乡",
        "421024201": "秦市乡",
        "421024450": "江北监狱",
        "421024500": "三湖管理区",
        "421024501": "六合垸管理区"
    },
    "421071": {"421071001": "联合街道", "421071002": "西湖街道", "421071100": "滩桥镇", "421071400": "沙市农场"},
    "421081": {
        "421081001": "绣林街道",
        "421081002": "笔架山街道",
        "421081100": "新厂镇",
        "421081101": "横沟市镇",
        "421081102": "大垸镇",
        "421081103": "小河口镇",
        "421081104": "桃花山镇",
        "421081105": "调关镇",
        "421081106": "东升镇",
        "421081107": "高基庙镇",
        "421081108": "南口镇",
        "421081109": "高陵镇",
        "421081110": "团山寺镇",
        "421081200": "久合垸乡",
        "421081500": "天鹅洲开发区"
    },
    "421083": {
        "421083001": "新堤街道",
        "421083002": "滨湖街道",
        "421083100": "螺山镇",
        "421083101": "乌林镇",
        "421083102": "龙口镇",
        "421083103": "燕窝镇",
        "421083104": "新滩镇",
        "421083105": "峰口镇",
        "421083106": "曹市镇",
        "421083107": "府场镇",
        "421083108": "戴家场镇",
        "421083109": "瞿家湾镇",
        "421083110": "沙口镇",
        "421083111": "万全镇",
        "421083112": "汊河镇",
        "421083113": "黄家口镇",
        "421083200": "老湾乡",
        "421083450": "小港管理区",
        "421083451": "大同湖管理区",
        "421083452": "大沙湖管理区"
    },
    "421087": {
        "421087100": "新江口镇",
        "421087101": "南海镇",
        "421087102": "八宝镇",
        "421087103": "涴市镇",
        "421087104": "老城镇",
        "421087105": "陈店镇",
        "421087106": "王家桥镇",
        "421087107": "斯家场镇",
        "421087108": "杨林市镇",
        "421087109": "纸厂河镇",
        "421087110": "街河市镇",
        "421087111": "洈水镇",
        "421087112": "刘家场镇",
        "421087113": "沙道观镇",
        "421087200": "万家乡",
        "421087201": "卸甲坪土家族乡"
    },
    "421100": {
        "421102": "黄州区",
        "421121": "团风县",
        "421122": "红安县",
        "421123": "罗田县",
        "421124": "英山县",
        "421125": "浠水县",
        "421126": "蕲春县",
        "421127": "黄梅县",
        "421171": "龙感湖管理区",
        "421181": "麻城市",
        "421182": "武穴市"
    },
    "421102": {
        "421102001": "赤壁街道",
        "421102002": "东湖街道",
        "421102003": "禹王街道",
        "421102004": "南湖街道",
        "421102100": "路口镇",
        "421102101": "堵城镇",
        "421102102": "陈策楼镇",
        "421102200": "陶店乡",
        "421102450": "火车站开发区",
        "421102470": "黄冈高新技术产业园区",
        "421102471": "武汉经济技术开发区黄冈产业园管委会"
    },
    "421121": {
        "421121100": "团风镇",
        "421121101": "淋山河镇",
        "421121102": "方高坪镇",
        "421121103": "回龙山镇",
        "421121104": "马曹庙镇",
        "421121105": "上巴河镇",
        "421121106": "总路咀镇",
        "421121107": "但店镇",
        "421121200": "贾庙乡",
        "421121201": "杜皮乡",
        "421121400": "黄湖农场",
        "421121401": "金锣港农场"
    },
    "421122": {
        "421122100": "城关镇",
        "421122101": "七里坪镇",
        "421122102": "华家河镇",
        "421122103": "二程镇",
        "421122104": "上新集镇",
        "421122105": "高桥镇",
        "421122106": "觅儿寺镇",
        "421122107": "八里湾镇",
        "421122108": "太平桥镇",
        "421122109": "永佳河镇",
        "421122200": "杏花乡",
        "421122500": "火连畈茶场",
        "421122501": "天台山管理处",
        "421122503": "红安经济开发区"
    },
    "421123": {
        "421123100": "凤山镇",
        "421123101": "骆驼坳镇",
        "421123102": "大河岸镇",
        "421123103": "九资河镇",
        "421123104": "胜利镇",
        "421123105": "河铺镇",
        "421123106": "三里畈镇",
        "421123107": "匡河镇",
        "421123108": "白庙河镇",
        "421123109": "大崎镇",
        "421123200": "白莲河乡",
        "421123203": "平湖乡",
        "421123400": "罗田县经济开发区",
        "421123500": "青苔关林场",
        "421123501": "天堂寨林场",
        "421123502": "薄刀峰林场",
        "421123503": "黄狮寨林场"
    },
    "421124": {
        "421124100": "温泉镇",
        "421124101": "南河镇",
        "421124102": "红山镇",
        "421124103": "金家铺镇",
        "421124104": "石头咀镇",
        "421124105": "草盘地镇",
        "421124106": "雷家店镇",
        "421124107": "杨柳湾镇",
        "421124200": "方家咀乡",
        "421124201": "孔家坊乡",
        "421124202": "陶家河乡",
        "421124400": "桃花冲林场",
        "421124401": "吴家山林场",
        "421124402": "五峰山林场",
        "421124403": "英山县经济开发区"
    },
    "421125": {
        "421125100": "清泉镇",
        "421125101": "巴河镇",
        "421125102": "竹瓦镇",
        "421125103": "汪岗镇",
        "421125104": "团陂镇",
        "421125105": "关口镇",
        "421125106": "白莲镇",
        "421125107": "蔡河镇",
        "421125108": "洗马镇",
        "421125109": "丁司垱镇",
        "421125110": "散花镇",
        "421125111": "兰溪镇",
        "421125200": "绿杨乡",
        "421125400": "策湖国家湿地公园管理处",
        "421125401": "三角山旅游度假区管理委员会",
        "421125402": "经济开发区镇"
    },
    "421126": {
        "421126100": "漕河镇",
        "421126101": "赤东镇",
        "421126102": "蕲州镇",
        "421126103": "管窑镇",
        "421126104": "彭思镇",
        "421126105": "横车镇",
        "421126106": "株林镇",
        "421126107": "刘河镇",
        "421126108": "狮子镇",
        "421126109": "青石镇",
        "421126110": "张榜镇",
        "421126111": "大同镇",
        "421126112": "檀林镇",
        "421126200": "向桥乡",
        "421126450": "八里湖农场",
        "421126451": "李时珍医药工业园区管委会",
        "421126453": "河西工业园区"
    },
    "421127": {
        "421127100": "黄梅镇",
        "421127101": "孔垄镇",
        "421127102": "小池镇",
        "421127103": "下新镇",
        "421127104": "大河镇",
        "421127105": "停前镇",
        "421127106": "五祖镇",
        "421127107": "濯港镇",
        "421127108": "蔡山镇",
        "421127109": "新开镇",
        "421127110": "独山镇",
        "421127111": "分路镇",
        "421127200": "柳林乡",
        "421127201": "杉木乡",
        "421127202": "苦竹乡",
        "421127203": "刘佐乡",
        "421127400": "五祖寺（挪步园）风景名胜区"
    },
    "421171": {
        "421171450": "严家闸",
        "421171451": "芦柴湖",
        "421171452": "洋湖",
        "421171453": "沙湖",
        "421171454": "春港",
        "421171455": "塞湖",
        "421171456": "青泥湖",
        "421171457": "湖北龙感湖工业园区"
    },
    "421181": {
        "421181001": "龙池",
        "421181002": "鼓楼",
        "421181003": "南湖",
        "421181100": "中馆驿镇",
        "421181101": "宋埠镇",
        "421181102": "歧亭镇",
        "421181103": "白果镇",
        "421181104": "夫子河镇",
        "421181105": "阎家河镇",
        "421181106": "龟山镇",
        "421181107": "盐田河镇",
        "421181108": "张家畈镇",
        "421181109": "木子店镇",
        "421181110": "三河口镇",
        "421181111": "黄土岗镇",
        "421181112": "福田河镇",
        "421181113": "乘马岗镇",
        "421181114": "顺河镇",
        "421181200": "铁门岗乡",
        "421181401": "宋埠良种场",
        "421181402": "麻城明山水库",
        "421181403": "麻城三河水库",
        "421181404": "狮子峰林场",
        "421181405": "麻城林店茶场",
        "421181471": "湖北省麻城开发区",
        "421181472": "龟山风景区管理处",
        "421181473": "湖北浮桥河国家湿地公园管理处",
        "421181500": "五脑山林场"
    },
    "421182": {
        "421182001": "武穴街道",
        "421182002": "刊江街道",
        "421182003": "田镇街道",
        "421182004": "万丈湖街道",
        "421182100": "梅川镇",
        "421182101": "余川镇",
        "421182102": "花桥镇",
        "421182103": "大金镇",
        "421182104": "石佛寺镇",
        "421182105": "四望镇",
        "421182106": "大法寺镇",
        "421182107": "龙坪镇"
    },
    "421200": {"421202": "咸安区", "421221": "嘉鱼县", "421222": "通城县", "421223": "崇阳县", "421224": "通山县", "421281": "赤壁市"},
    "421202": {
        "421202001": "温泉",
        "421202002": "浮山",
        "421202003": "永安",
        "421202100": "汀泗桥镇",
        "421202101": "向阳湖镇",
        "421202102": "官埠桥镇",
        "421202103": "横沟桥镇",
        "421202104": "贺胜桥镇",
        "421202105": "双溪桥镇",
        "421202106": "马桥镇",
        "421202107": "桂花镇",
        "421202108": "高桥镇",
        "421202200": "大幕乡",
        "421202450": "向阳湖奶牛良种场",
        "421202570": "咸宁市经济技术开发区",
        "421202571": "湖北咸安经济开发区"
    },
    "421221": {
        "421221100": "陆溪镇",
        "421221101": "高铁岭镇",
        "421221102": "官桥镇",
        "421221103": "鱼岳镇",
        "421221104": "新街镇",
        "421221105": "渡普镇",
        "421221106": "潘家湾镇",
        "421221107": "牌洲湾镇",
        "421221450": "头墩农场",
        "421221570": "嘉鱼县经济开发区"
    },
    "421222": {
        "421222100": "隽水镇",
        "421222101": "麦市镇",
        "421222102": "塘湖镇",
        "421222103": "关刀镇",
        "421222104": "沙堆镇",
        "421222105": "五里镇",
        "421222106": "石南镇",
        "421222107": "北港镇",
        "421222108": "马港镇",
        "421222200": "四庄乡",
        "421222201": "大坪乡",
        "421222500": "鹿角山林场",
        "421222501": "锡山森林公园管理处",
        "421222502": "黄龙林场",
        "421222503": "黄袍林场",
        "421222504": "药姑林场"
    },
    "421223": {
        "421223100": "天城镇",
        "421223101": "沙坪镇",
        "421223102": "石城镇",
        "421223103": "桂花泉镇",
        "421223104": "白霓镇",
        "421223105": "路口镇",
        "421223106": "金塘镇",
        "421223107": "青山镇",
        "421223200": "肖岭乡",
        "421223201": "铜钟乡",
        "421223202": "港口乡",
        "421223203": "高枧乡",
        "421223570": "崇阳县工业园区"
    },
    "421224": {
        "421224100": "通羊镇",
        "421224101": "南林桥镇",
        "421224102": "黄沙铺镇",
        "421224103": "厦铺镇",
        "421224104": "九宫山镇",
        "421224105": "闯王镇",
        "421224106": "洪港镇",
        "421224107": "大畈镇",
        "421224200": "大路乡",
        "421224201": "杨芳林乡",
        "421224202": "燕厦乡",
        "421224203": "慈口乡",
        "421224450": "九宫山自然保护管理局",
        "421224570": "开发区"
    },
    "421281": {
        "421281001": "蒲圻街道",
        "421281002": "赤马港街道",
        "421281003": "陆水湖街道",
        "421281100": "新店镇",
        "421281101": "赵李桥镇",
        "421281102": "茶庵岭镇",
        "421281103": "车埠镇",
        "421281104": "赤壁镇",
        "421281105": "柳山湖镇",
        "421281106": "神山镇",
        "421281107": "中伙铺镇",
        "421281108": "官塘驿镇",
        "421281109": "黄盖湖镇",
        "421281200": "余家桥乡",
        "421281400": "蒲纺工业园",
        "421281401": "官塘驿林场",
        "421281402": "羊楼洞茶场",
        "421281403": "沧湖开发区",
        "421281404": "赤壁市经济开发区"
    },
    "421300": {"421303": "曾都区", "421321": "随县", "421381": "广水市"},
    "421303": {
        "421303001": "西城街道（）",
        "421303002": "东城街道（）",
        "421303003": "南郊街道（）",
        "421303004": "北郊街道（）",
        "421303005": "城南新区",
        "421303101": "万店镇",
        "421303102": "何店镇",
        "421303103": "洛阳镇",
        "421303104": "府河镇",
        "421303187": "淅河镇",
        "421303499": "曾都区新型工业基地",
        "421303587": "随州市经济开发区"
    },
    "421321": {
        "421321101": "厉山镇",
        "421321102": "高城镇",
        "421321103": "殷店镇",
        "421321104": "草店镇",
        "421321105": "小林镇",
        "421321106": "淮河镇",
        "421321107": "万和镇",
        "421321108": "尚市镇",
        "421321109": "唐县镇",
        "421321110": "吴山镇",
        "421321111": "新街镇",
        "421321112": "安居镇",
        "421321113": "澴潭镇",
        "421321114": "洪山镇",
        "421321115": "长岗镇",
        "421321116": "三里岗镇",
        "421321117": "柳林镇",
        "421321118": "均川镇",
        "421321119": "万福店镇"
    },
    "421381": {
        "421381001": "应山街道",
        "421381002": "十里街道",
        "421381003": "广水街道",
        "421381004": "城郊街道",
        "421381100": "武胜关镇",
        "421381101": "杨寨镇",
        "421381102": "陈巷镇",
        "421381103": "长岭镇",
        "421381104": "马坪镇",
        "421381105": "关庙镇",
        "421381106": "余店镇",
        "421381107": "吴店镇",
        "421381108": "郝店镇",
        "421381109": "蔡河镇",
        "421381110": "李店镇",
        "421381111": "太平镇",
        "421381112": "骆店镇",
        "421381400": "中华山林场",
        "421381401": "三潭风景区",
        "421381402": "工业基地"
    },
    "422800": {
        "422801": "恩施市",
        "422802": "利川市",
        "422822": "建始县",
        "422823": "巴东县",
        "422825": "宣恩县",
        "422826": "咸丰县",
        "422827": "来凤县",
        "422828": "鹤峰县"
    },
    "422801": {
        "422801001": "舞阳坝街道",
        "422801002": "六角亭街道",
        "422801003": "小渡船街道",
        "422801100": "龙凤镇",
        "422801101": "崔家坝镇",
        "422801102": "板桥镇",
        "422801103": "白杨坪镇",
        "422801104": "三岔镇",
        "422801201": "新塘乡",
        "422801202": "红土乡",
        "422801203": "沙地乡",
        "422801204": "太阳河乡",
        "422801205": "屯堡乡",
        "422801206": "白果乡",
        "422801207": "芭蕉侗族乡",
        "422801208": "盛家坝乡",
        "422801400": "恩施大峡谷风景管理处"
    },
    "422802": {
        "422802001": "都亭街道",
        "422802002": "东城街道",
        "422802100": "谋道镇",
        "422802101": "柏杨坝镇",
        "422802102": "汪营镇",
        "422802103": "建南镇",
        "422802104": "忠路镇",
        "422802105": "团堡镇",
        "422802106": "毛坝镇",
        "422802200": "凉雾乡",
        "422802201": "元堡乡",
        "422802202": "南坪乡",
        "422802203": "文斗乡",
        "422802205": "沙溪乡"
    },
    "422822": {
        "422822100": "业州镇",
        "422822101": "高坪镇",
        "422822102": "红岩寺镇",
        "422822103": "景阳镇",
        "422822104": "官店镇",
        "422822105": "花坪镇",
        "422822106": "长梁镇",
        "422822201": "茅田乡",
        "422822202": "龙坪乡",
        "422822203": "三里乡"
    },
    "422823": {
        "422823100": "信陵镇",
        "422823101": "东瀼口镇",
        "422823102": "沿渡河镇",
        "422823103": "官渡口镇",
        "422823104": "茶店子镇",
        "422823105": "绿葱坡镇",
        "422823106": "大支坪镇",
        "422823107": "野三关镇",
        "422823108": "水布垭镇",
        "422823109": "清太坪镇",
        "422823200": "溪丘湾乡",
        "422823201": "金果坪乡"
    },
    "422825": {
        "422825100": "珠山镇",
        "422825101": "椒园镇",
        "422825102": "沙道沟镇",
        "422825103": "李家河镇",
        "422825104": "高罗镇",
        "422825200": "万寨乡",
        "422825201": "长潭河侗族乡",
        "422825203": "晓关侗族乡",
        "422825205": "椿木营乡"
    },
    "422826": {
        "422826100": "高乐山镇",
        "422826101": "忠堡镇",
        "422826102": "坪坝营镇",
        "422826103": "朝阳寺镇",
        "422826104": "清坪镇",
        "422826105": "唐崖镇",
        "422826106": "曲江镇",
        "422826202": "活龙坪乡",
        "422826203": "小村乡",
        "422826204": "黄金洞乡",
        "422826400": "大路坝区"
    },
    "422827": {
        "422827100": "翔凤镇",
        "422827101": "百福司镇",
        "422827102": "大河镇",
        "422827103": "绿水镇",
        "422827104": "旧司镇",
        "422827105": "革勒车镇",
        "422827200": "漫水乡",
        "422827202": "三胡乡"
    },
    "422828": {
        "422828100": "走马镇",
        "422828101": "容美镇",
        "422828102": "太平镇",
        "422828103": "燕子镇",
        "422828104": "中营镇",
        "422828200": "铁炉乡",
        "422828201": "五里乡",
        "422828203": "下坪乡",
        "422828204": "邬阳乡"
    },
    "429004": {
        "429004001": "沙嘴街道",
        "429004002": "干河街道",
        "429004003": "龙华山办事处",
        "429004100": "郑场镇",
        "429004101": "毛嘴镇",
        "429004102": "豆河镇",
        "429004103": "三伏潭镇",
        "429004104": "胡场镇",
        "429004105": "长倘口镇",
        "429004106": "西流河镇",
        "429004107": "沙湖镇",
        "429004108": "杨林尾镇",
        "429004109": "彭场镇",
        "429004110": "张沟镇",
        "429004111": "郭河镇",
        "429004112": "沔城回族镇",
        "429004113": "通海口镇",
        "429004114": "陈场镇",
        "429004400": "工业园区",
        "429004401": "九合垸原种场",
        "429004402": "沙湖原种场",
        "429004404": "五湖渔场",
        "429004405": "赵西垸林场",
        "429004407": "畜禽良种场",
        "429004408": "排湖风景区"
    },
    "429005": {
        "429005001": "园林办事处",
        "429005002": "杨市办事处",
        "429005003": "周矶办事处",
        "429005004": "广华办事处",
        "429005005": "泰丰办事处",
        "429005006": "高场办事处",
        "429005100": "竹根滩镇",
        "429005101": "渔洋镇",
        "429005102": "王场镇",
        "429005103": "高石碑镇",
        "429005104": "熊口镇",
        "429005105": "老新镇",
        "429005106": "浩口镇",
        "429005107": "积玉口镇",
        "429005108": "张金镇",
        "429005109": "龙湾镇",
        "429005400": "江汉石油管理局",
        "429005401": "潜江经济开发区",
        "429005450": "周矶管理区",
        "429005451": "后湖管理区",
        "429005452": "熊口管理区",
        "429005453": "总口管理区",
        "429005454": "白鹭湖管理区",
        "429005455": "运粮湖管理区",
        "429005457": "浩口原种场"
    },
    "429006": {
        "429006001": "竟陵街道",
        "429006002": "侨乡街道开发区",
        "429006003": "杨林街道",
        "429006100": "多宝镇",
        "429006101": "拖市镇",
        "429006102": "张港镇",
        "429006103": "蒋场镇",
        "429006104": "汪场镇",
        "429006105": "渔薪镇",
        "429006106": "黄潭镇",
        "429006107": "岳口镇",
        "429006108": "横林镇",
        "429006109": "彭市镇",
        "429006110": "麻洋镇",
        "429006111": "多祥镇",
        "429006112": "干驿镇",
        "429006113": "马湾镇",
        "429006114": "卢市镇",
        "429006115": "小板镇",
        "429006116": "九真镇",
        "429006118": "皂市镇",
        "429006119": "胡市镇",
        "429006120": "石河镇",
        "429006121": "佛子山镇",
        "429006201": "净潭乡",
        "429006450": "蒋湖农场",
        "429006451": "白茅湖农场",
        "429006452": "沉湖管委会"
    },
    "429021": {
        "429021100": "松柏镇",
        "429021101": "阳日镇",
        "429021102": "木鱼镇",
        "429021103": "红坪镇",
        "429021104": "新华镇",
        "429021105": "九湖镇",
        "429021200": "宋洛乡",
        "429021202": "下谷坪土家族乡"
    },
    "430000": {
        "430100": "长沙市",
        "430200": "株洲市",
        "430300": "湘潭市",
        "430400": "衡阳市",
        "430500": "邵阳市",
        "430600": "岳阳市",
        "430700": "常德市",
        "430800": "张家界市",
        "430900": "益阳市",
        "431000": "郴州市",
        "431100": "永州市",
        "431200": "怀化市",
        "431300": "娄底市",
        "433100": "湘西土家族苗族自治州"
    },
    "430100": {
        "430102": "芙蓉区",
        "430103": "天心区",
        "430104": "岳麓区",
        "430105": "开福区",
        "430111": "雨花区",
        "430112": "望城区",
        "430121": "长沙县",
        "430181": "浏阳市",
        "430182": "宁乡市"
    },
    "430102": {
        "430102001": "文艺路街道",
        "430102002": "朝阳街街道",
        "430102003": "韭菜园街道",
        "430102008": "五里牌街道",
        "430102009": "火星街道",
        "430102010": "马王堆街道",
        "430102011": "东屯渡街道",
        "430102012": "湘湖街道",
        "430102013": "定王台街道",
        "430102014": "荷花园街道",
        "430102015": "东岸街道",
        "430102016": "马坡岭街道",
        "430102017": "东湖街道",
        "430102400": "隆平高科技园"
    },
    "430103": {
        "430103002": "坡子街街道",
        "430103004": "城南路街道",
        "430103005": "裕南街街道",
        "430103006": "金盆岭街道",
        "430103007": "新开铺街道",
        "430103008": "青园街道",
        "430103009": "桂花坪街道",
        "430103010": "赤岭路街道",
        "430103011": "文源街道",
        "430103012": "先锋街道",
        "430103013": "黑石铺街道",
        "430103014": "大托铺街道",
        "430103015": "暮云街道",
        "430103016": "南托街道"
    },
    "430104": {
        "430104001": "望月湖街道",
        "430104002": "岳麓街道",
        "430104003": "桔子洲街道",
        "430104004": "银盆岭街道",
        "430104005": "观沙岭街道",
        "430104006": "望城坡街道",
        "430104007": "西湖街道",
        "430104008": "咸嘉湖街道",
        "430104009": "望岳街道",
        "430104010": "梅溪湖街道",
        "430104011": "麓谷街道",
        "430104012": "坪塘街道",
        "430104013": "含浦街道",
        "430104014": "天顶街道",
        "430104015": "洋湖街道",
        "430104016": "学士街道",
        "430104102": "莲花镇",
        "430104105": "雨敞坪镇"
    },
    "430105": {
        "430105001": "芙蓉北路街道",
        "430105002": "东风路街道",
        "430105003": "清水塘街道",
        "430105004": "望麓园街道",
        "430105005": "湘雅路街道",
        "430105006": "伍家岭街道",
        "430105009": "新河街道",
        "430105010": "通泰街街道",
        "430105012": "四方坪街道",
        "430105013": "洪山街道",
        "430105014": "浏阳河街道",
        "430105015": "月湖街道",
        "430105016": "秀峰街道",
        "430105019": "沙坪街道",
        "430105020": "捞刀河街道",
        "430105021": "青竹湖街道"
    },
    "430111": {
        "430111001": "侯家塘街道",
        "430111002": "左家塘街道",
        "430111003": "圭塘街道",
        "430111004": "砂子塘街道",
        "430111005": "东塘街道",
        "430111006": "雨花亭街道",
        "430111007": "高桥街道",
        "430111008": "洞井街道",
        "430111009": "黎托街道",
        "430111010": "井湾子街道",
        "430111011": "同升街道",
        "430111012": "东山街道",
        "430111101": "跳马镇",
        "430111400": "长沙雨花经济开发区管理委员会"
    },
    "430112": {
        "430112001": "高塘岭街道",
        "430112003": "丁字湾街道",
        "430112005": "大泽湖街道",
        "430112006": "月亮岛街道",
        "430112007": "白沙洲街道",
        "430112008": "金山桥街道",
        "430112009": "黄金园街道",
        "430112011": "乌山街道",
        "430112012": "铜官街道",
        "430112013": "雷锋街道",
        "430112014": "白马街道",
        "430112101": "桥驿镇",
        "430112103": "茶亭镇",
        "430112106": "靖港镇",
        "430112107": "乔口镇",
        "430112115": "白箬铺镇"
    },
    "430121": {
        "430121001": "星沙街道",
        "430121002": "泉塘街道",
        "430121003": "湘龙街道",
        "430121004": "榔梨街道",
        "430121005": "长龙街道",
        "430121102": "黄兴镇",
        "430121104": "江背镇",
        "430121105": "黄花镇",
        "430121106": "春华镇",
        "430121107": "果园镇",
        "430121108": "路口镇",
        "430121109": "高桥镇",
        "430121110": "金井镇",
        "430121111": "福临镇",
        "430121112": "青山铺镇",
        "430121113": "安沙镇",
        "430121114": "北山镇",
        "430121116": "开慧镇"
    },
    "430181": {
        "430181001": "淮川街道",
        "430181002": "集里街道",
        "430181003": "荷花街道",
        "430181004": "关口街道",
        "430181102": "社港镇",
        "430181104": "官渡镇",
        "430181105": "张坊镇",
        "430181106": "达浒镇",
        "430181107": "沿溪镇",
        "430181108": "古港镇",
        "430181109": "永和镇",
        "430181110": "大瑶镇",
        "430181111": "金刚镇",
        "430181112": "文家市镇",
        "430181114": "枨冲镇",
        "430181115": "镇头镇",
        "430181116": "普迹镇",
        "430181117": "永安镇",
        "430181118": "北盛镇",
        "430181120": "龙伏镇",
        "430181121": "澄潭江镇",
        "430181122": "中和镇",
        "430181123": "柏加镇",
        "430181124": "洞阳镇",
        "430181125": "大围山镇",
        "430181126": "沙市镇",
        "430181127": "淳口镇",
        "430181128": "高坪镇",
        "430181130": "官桥镇",
        "430181131": "葛家镇",
        "430181132": "蕉溪镇",
        "430181201": "小河乡"
    },
    "430182": {
        "430182001": "玉潭街道",
        "430182002": "白马桥街道",
        "430182003": "历经铺街道",
        "430182004": "城郊街道",
        "430182101": "道林镇",
        "430182102": "花明楼镇",
        "430182103": "东湖塘镇",
        "430182104": "夏铎铺镇",
        "430182105": "双江口镇",
        "430182106": "煤炭坝镇",
        "430182107": "坝塘镇",
        "430182109": "灰汤镇",
        "430182110": "双凫铺镇",
        "430182111": "老粮仓镇",
        "430182112": "流沙河镇",
        "430182113": "巷子口镇",
        "430182114": "龙田镇",
        "430182116": "横市镇",
        "430182117": "回龙铺镇",
        "430182118": "黄材镇",
        "430182119": "大成桥镇",
        "430182120": "青山桥镇",
        "430182121": "金洲镇",
        "430182122": "大屯营镇",
        "430182123": "资福镇",
        "430182203": "菁华铺乡",
        "430182209": "喻家坳乡",
        "430182213": "沙田乡",
        "430182218": "沩山乡"
    },
    "430200": {
        "430202": "荷塘区",
        "430203": "芦淞区",
        "430204": "石峰区",
        "430211": "天元区",
        "430212": "渌口区",
        "430223": "攸县",
        "430224": "茶陵县",
        "430225": "炎陵县",
        "430271": "云龙示范区",
        "430281": "醴陵市"
    },
    "430202": {
        "430202001": "月塘街道",
        "430202002": "茨菇塘街道",
        "430202003": "宋家桥街道",
        "430202004": "桂花街道",
        "430202005": "金山街道",
        "430202100": "仙庾镇"
    },
    "430203": {
        "430203001": "贺家土街道",
        "430203002": "建设街道",
        "430203003": "建宁街道",
        "430203004": "董家段街道",
        "430203005": "庆云街道",
        "430203006": "枫溪街道",
        "430203007": "龙泉街道",
        "430203100": "白关镇"
    },
    "430204": {
        "430204001": "田心街道",
        "430204002": "响石岭街道",
        "430204003": "清水塘街道",
        "430204004": "铜塘湾街道",
        "430204005": "井龙街道"
    },
    "430211": {
        "430211001": "嵩山路街道",
        "430211002": "泰山路街道",
        "430211003": "栗雨街道",
        "430211004": "马家河街道",
        "430211101": "群丰镇",
        "430211102": "雷打石镇",
        "430211103": "三门镇"
    },
    "430212": {
        "430212100": "渌口镇",
        "430212101": "朱亭镇",
        "430212106": "古岳峰镇",
        "430212107": "淦田镇",
        "430212108": "龙门镇",
        "430212109": "龙潭镇",
        "430212110": "南洲镇",
        "430212111": "龙船镇"
    },
    "430223": {
        "430223001": "联星街道",
        "430223002": "江桥街道",
        "430223003": "春联街道",
        "430223004": "谭桥街道",
        "430223102": "酒埠江镇",
        "430223103": "桃水镇",
        "430223106": "网岭镇",
        "430223109": "渌田镇",
        "430223115": "石羊塘镇",
        "430223118": "黄丰桥镇",
        "430223119": "鸾山镇",
        "430223120": "丫江桥镇",
        "430223122": "皇图岭镇",
        "430223123": "新市镇",
        "430223124": "菜花坪镇",
        "430223125": "莲塘坳镇",
        "430223126": "宁家坪镇",
        "430223400": "攸县工业园",
        "430223401": "攸县网岭循环经济园"
    },
    "430224": {
        "430224002": "下东街道",
        "430224003": "思聪街道",
        "430224004": "洣江街道",
        "430224005": "云阳街道",
        "430224101": "界首镇",
        "430224103": "湖口镇",
        "430224105": "马江镇",
        "430224106": "高陇镇",
        "430224109": "虎踞镇",
        "430224111": "枣市镇",
        "430224112": "火田镇",
        "430224113": "严塘镇",
        "430224115": "秩堂镇",
        "430224116": "腰潞镇",
        "430224203": "舲舫乡",
        "430224213": "桃坑乡",
        "430224404": "汉背",
        "430224500": "茶陵县红色农场"
    },
    "430225": {
        "430225100": "霞阳镇",
        "430225101": "沔渡镇",
        "430225102": "十都镇",
        "430225103": "水口镇",
        "430225105": "鹿原镇",
        "430225200": "垄溪乡",
        "430225202": "策源乡",
        "430225203": "下村乡",
        "430225207": "船形乡",
        "430225209": "中村瑶族乡",
        "430225400": "炎陵县大院农场",
        "430225401": "炎陵县桃源洞管理局"
    },
    "430271": {"430271006": "学林街道", "430271101": "云田镇"},
    "430281": {
        "430281002": "阳三石街道",
        "430281003": "仙岳山街道",
        "430281004": "国瓷街道",
        "430281006": "来龙门街道",
        "430281102": "白兔潭镇",
        "430281103": "浦口镇",
        "430281105": "王仙镇",
        "430281107": "泗汾镇",
        "430281108": "沈潭镇",
        "430281109": "船湾镇",
        "430281120": "均楚镇",
        "430281121": "东富镇",
        "430281122": "石亭镇",
        "430281125": "孙家湾镇",
        "430281126": "官庄镇",
        "430281127": "嘉树镇",
        "430281128": "板杉镇",
        "430281129": "沩山镇",
        "430281130": "枫林镇",
        "430281131": "李畋镇",
        "430281132": "明月镇",
        "430281133": "左权镇",
        "430281134": "茶山镇",
        "430281400": "湖南醴陵经济开发区"
    },
    "430300": {
        "430302": "雨湖区",
        "430304": "岳塘区",
        "430321": "湘潭县",
        "430371": "湖南湘潭高新技术产业园区",
        "430372": "湘潭昭山示范区",
        "430373": "湘潭九华示范区",
        "430381": "湘乡市",
        "430382": "韶山市"
    },
    "430302": {
        "430302001": "雨湖路街道",
        "430302002": "城正街街道",
        "430302003": "云塘街道",
        "430302004": "广场街道",
        "430302005": "窑湾街道",
        "430302006": "昭潭街道",
        "430302007": "万楼街道",
        "430302008": "先锋街道",
        "430302100": "鹤岭镇",
        "430302101": "楠竹山镇",
        "430302102": "姜畲镇",
        "430302202": "长城乡"
    },
    "430304": {
        "430304001": "岳塘街道",
        "430304002": "东坪街道",
        "430304004": "书院路街道",
        "430304005": "下摄司街道",
        "430304006": "建设路街道",
        "430304008": "五里堆街道",
        "430304010": "宝塔街道",
        "430304014": "霞城街道",
        "430304015": "荷塘街道"
    },
    "430321": {
        "430321100": "易俗河镇",
        "430321102": "谭家山镇",
        "430321103": "中路铺镇",
        "430321104": "茶恩寺镇",
        "430321105": "河口镇",
        "430321106": "射埠镇",
        "430321107": "花石镇",
        "430321108": "青山桥镇",
        "430321109": "石鼓镇",
        "430321111": "云湖桥镇",
        "430321112": "石潭镇",
        "430321113": "杨嘉桥镇",
        "430321114": "乌石镇",
        "430321115": "白石镇",
        "430321202": "分水乡",
        "430321203": "排头乡",
        "430321205": "锦石乡"
    },
    "430371": {"430371001": "板塘街道", "430371002": "双马街道"},
    "430372": {"430372100": "昭山镇"},
    "430373": {"430373001": "和平街道", "430373200": "响水乡"},
    "430381": {
        "430381001": "望春门街道",
        "430381002": "新湘路街道",
        "430381003": "昆仑桥街道",
        "430381004": "东山街道",
        "430381100": "山枣镇",
        "430381101": "栗山镇",
        "430381102": "中沙镇",
        "430381103": "虞唐镇",
        "430381104": "潭市镇",
        "430381105": "棋梓镇",
        "430381106": "壶天镇",
        "430381107": "翻江镇",
        "430381108": "金石镇",
        "430381109": "白田镇",
        "430381110": "月山镇",
        "430381111": "泉塘镇",
        "430381112": "梅桥镇",
        "430381113": "毛田镇",
        "430381114": "龙洞镇",
        "430381200": "东郊乡",
        "430381202": "金薮乡",
        "430381203": "育塅乡"
    },
    "430382": {"430382100": "清溪镇", "430382101": "银田镇", "430382200": "韶山乡", "430382203": "杨林乡"},
    "430400": {
        "430405": "珠晖区",
        "430406": "雁峰区",
        "430407": "石鼓区",
        "430408": "蒸湘区",
        "430412": "南岳区",
        "430421": "衡阳县",
        "430422": "衡南县",
        "430423": "衡山县",
        "430424": "衡东县",
        "430426": "祁东县",
        "430471": "衡阳综合保税区",
        "430472": "湖南衡阳高新技术产业园区",
        "430473": "湖南衡阳松木经济开发区",
        "430481": "耒阳市",
        "430482": "常宁市"
    },
    "430405": {
        "430405007": "衡州路街道",
        "430405009": "广东路街道",
        "430405010": "东风路街道",
        "430405011": "冶金街道",
        "430405012": "苗圃街道",
        "430405013": "粤汉街道",
        "430405014": "东阳渡街道",
        "430405100": "茶山坳镇",
        "430405200": "和平乡",
        "430405201": "酃湖乡",
        "430405400": "金甲岭农场",
        "430405403": "松林茶场"
    },
    "430406": {
        "430406001": "先锋街道",
        "430406002": "雁峰街道",
        "430406003": "天马山街道",
        "430406004": "黄茶岭街道",
        "430406005": "白沙洲街道",
        "430406100": "岳屏镇",
        "430406400": "白沙洲工业园"
    },
    "430407": {
        "430407001": "人民路街道",
        "430407002": "青山街道",
        "430407003": "潇湘街道",
        "430407004": "五一街道",
        "430407005": "合江街道",
        "430407006": "黄沙湾街道",
        "430407201": "角山乡"
    },
    "430408": {"430408001": "蒸湘街道", "430408002": "红湘街道", "430408004": "联合街道", "430408100": "呆鹰岭镇", "430408101": "雨母山镇"},
    "430412": {"430412001": "祝融街道", "430412100": "南岳镇", "430412203": "寿岳乡"},
    "430421": {
        "430421100": "西渡镇",
        "430421101": "集兵镇",
        "430421103": "杉桥镇",
        "430421104": "井头镇",
        "430421105": "演陂镇",
        "430421106": "金兰镇",
        "430421107": "洪市镇",
        "430421108": "曲兰镇",
        "430421109": "金溪镇",
        "430421110": "界牌镇",
        "430421111": "渣江镇",
        "430421112": "三湖镇",
        "430421113": "台源镇",
        "430421114": "关市镇",
        "430421115": "库宗桥镇",
        "430421116": "岘山镇",
        "430421117": "石市镇",
        "430421200": "樟木乡",
        "430421201": "岣嵝乡",
        "430421205": "栏垅乡",
        "430421207": "大安乡",
        "430421208": "溪江乡",
        "430421210": "长安乡",
        "430421211": "板市乡",
        "430421212": "樟树乡"
    },
    "430422": {
        "430422100": "云集镇",
        "430422102": "廖田镇",
        "430422103": "茶市镇",
        "430422104": "冠市镇",
        "430422105": "江口镇",
        "430422106": "宝盖镇",
        "430422107": "花桥镇",
        "430422108": "铁丝塘镇",
        "430422109": "泉溪镇",
        "430422110": "洪山镇",
        "430422111": "三塘镇",
        "430422112": "谭子山镇",
        "430422113": "鸡笼镇",
        "430422114": "泉湖镇",
        "430422115": "柞市镇",
        "430422116": "茅市镇",
        "430422117": "硫市镇",
        "430422118": "栗江镇",
        "430422119": "近尾洲镇",
        "430422121": "咸塘镇",
        "430422122": "松江镇",
        "430422200": "相市乡",
        "430422407": "衡州监狱"
    },
    "430423": {
        "430423100": "开云镇",
        "430423101": "白果镇",
        "430423102": "东湖镇",
        "430423103": "萱洲镇",
        "430423104": "长江镇",
        "430423105": "新桥镇",
        "430423107": "店门镇",
        "430423201": "永和乡",
        "430423203": "福田铺乡",
        "430423204": "岭坡乡",
        "430423207": "贯塘乡",
        "430423208": "江东乡"
    },
    "430424": {
        "430424100": "洣水镇",
        "430424101": "石湾镇",
        "430424102": "新塘镇",
        "430424103": "大浦镇",
        "430424104": "吴集镇",
        "430424105": "甘溪镇",
        "430424106": "杨林镇",
        "430424107": "草市镇",
        "430424108": "杨桥镇",
        "430424109": "霞流镇",
        "430424110": "荣桓镇",
        "430424111": "高湖镇",
        "430424112": "白莲镇",
        "430424113": "三樟镇",
        "430424114": "蓬源镇",
        "430424204": "南湾乡",
        "430424211": "石滩乡",
        "430424401": "河西开发区",
        "430424403": "大浦工业园"
    },
    "430426": {
        "430426001": "洪桥街道",
        "430426002": "玉合街道",
        "430426003": "永昌街道",
        "430426004": "白鹤街道",
        "430426102": "金桥镇",
        "430426103": "鸟江镇",
        "430426104": "粮市镇",
        "430426105": "河洲镇",
        "430426106": "归阳镇",
        "430426107": "过水坪镇",
        "430426108": "双桥镇",
        "430426109": "灵官镇",
        "430426110": "风石堰镇",
        "430426111": "白地市镇",
        "430426112": "黄土铺镇",
        "430426113": "石亭子镇",
        "430426114": "官家嘴镇",
        "430426115": "步云桥镇",
        "430426116": "砖塘镇",
        "430426117": "蒋家桥镇",
        "430426118": "太和堂镇",
        "430426200": "马杜桥乡",
        "430426201": "凤歧坪乡",
        "430426202": "城连圩乡"
    },
    "430471": {"430471401": "衡阳综合保税区"},
    "430472": {"430472003": "华兴街道"},
    "430473": {"430473400": "松木工业园"},
    "430481": {
        "430481001": "蔡子池街道",
        "430481002": "灶市街街道",
        "430481003": "水东江街道",
        "430481004": "五里牌街道",
        "430481005": "三架街道",
        "430481006": "余庆街道",
        "430481100": "黄市镇",
        "430481101": "小水镇",
        "430481102": "公平圩镇",
        "430481104": "三都镇",
        "430481105": "南阳镇",
        "430481107": "夏塘镇",
        "430481108": "龙塘镇",
        "430481109": "哲桥镇",
        "430481110": "永济镇",
        "430481111": "遥田镇",
        "430481112": "新市镇",
        "430481113": "淝田镇",
        "430481114": "大市镇",
        "430481115": "仁义镇",
        "430481116": "南京镇",
        "430481117": "大义镇",
        "430481118": "东湖圩镇",
        "430481119": "马水镇",
        "430481120": "导子镇",
        "430481201": "亮源乡",
        "430481203": "太平圩乡",
        "430481213": "长坪乡",
        "430481214": "太和圩乡",
        "430481215": "坛下乡"
    },
    "430482": {
        "430482001": "宜阳街道",
        "430482002": "泉峰街道",
        "430482003": "培元街道",
        "430482006": "天堂山",
        "430482101": "柏坊镇",
        "430482102": "水口山镇",
        "430482103": "烟洲镇",
        "430482104": "荫田镇",
        "430482105": "白沙镇",
        "430482106": "西岭镇",
        "430482108": "三角塘镇",
        "430482109": "洋泉镇",
        "430482110": "庙前镇",
        "430482111": "罗桥镇",
        "430482112": "板桥镇",
        "430482113": "胜桥镇",
        "430482114": "官岭镇",
        "430482115": "新河镇",
        "430482201": "蓬塘乡",
        "430482202": "兰江乡",
        "430482203": "大堡乡",
        "430482206": "塔山瑶族乡"
    },
    "430500": {
        "430502": "双清区",
        "430503": "大祥区",
        "430511": "北塔区",
        "430521": "邵东县",
        "430522": "新邵县",
        "430523": "邵阳县",
        "430524": "隆回县",
        "430525": "洞口县",
        "430527": "绥宁县",
        "430528": "新宁县",
        "430529": "城步苗族自治县",
        "430581": "武冈市"
    },
    "430502": {
        "430502001": "兴隆街道",
        "430502002": "龙须塘街道",
        "430502003": "汽车站街道",
        "430502004": "小江湖街道",
        "430502005": "东风路街道",
        "430502006": "桥头街道",
        "430502007": "滨江街道",
        "430502008": "石桥街道",
        "430502009": "爱莲街道",
        "430502100": "高崇山镇",
        "430502101": "渡头桥镇",
        "430502202": "火车站乡"
    },
    "430503": {
        "430503001": "中心路街道",
        "430503002": "红旗路街道",
        "430503003": "城北路街道",
        "430503004": "城西街道",
        "430503005": "翠园街道",
        "430503006": "百春园街道",
        "430503007": "城南街道",
        "430503008": "火车南站街道",
        "430503009": "学院路街道",
        "430503010": "雨溪街道",
        "430503011": "檀江街道",
        "430503101": "罗市镇",
        "430503202": "蔡锷乡",
        "430503203": "板桥乡"
    },
    "430511": {
        "430511001": "新滩镇街道",
        "430511002": "状元洲街道",
        "430511003": "田江街道",
        "430511004": "茶元头街道",
        "430511203": "陈家桥乡"
    },
    "430521": {
        "430521001": "大禾塘街道",
        "430521002": "两市塘街道",
        "430521003": "宋家塘街道",
        "430521101": "牛马司镇",
        "430521103": "九龙岭镇",
        "430521104": "仙槎桥镇",
        "430521105": "火厂坪镇",
        "430521106": "佘田桥镇",
        "430521107": "灵官殿镇",
        "430521108": "团山镇",
        "430521109": "砂石镇",
        "430521110": "廉桥镇",
        "430521111": "流光岭镇",
        "430521112": "流泽镇",
        "430521113": "魏家桥镇",
        "430521114": "野鸡坪镇",
        "430521115": "杨桥镇",
        "430521116": "水东江镇",
        "430521117": "黑田铺镇",
        "430521118": "简家陇镇",
        "430521119": "界岭镇",
        "430521203": "双凤乡",
        "430521204": "周官桥乡",
        "430521209": "堡面前乡",
        "430521212": "斫曹乡"
    },
    "430522": {
        "430522100": "酿溪镇",
        "430522101": "严塘镇",
        "430522102": "雀塘镇",
        "430522103": "陈家坊镇",
        "430522104": "潭溪镇",
        "430522105": "寸石镇",
        "430522106": "坪上镇",
        "430522107": "龙溪铺镇",
        "430522108": "巨口铺镇",
        "430522109": "新田铺镇",
        "430522110": "小塘镇",
        "430522111": "太芝庙镇",
        "430522112": "大新镇",
        "430522200": "潭府乡",
        "430522203": "迎光乡"
    },
    "430523": {
        "430523100": "塘渡口镇",
        "430523101": "白仓镇",
        "430523102": "金称市镇",
        "430523103": "塘田市镇",
        "430523104": "黄亭市镇",
        "430523105": "长阳铺镇",
        "430523106": "岩口铺镇",
        "430523107": "九公桥镇",
        "430523108": "下花桥镇",
        "430523109": "谷洲镇",
        "430523110": "郦家坪镇",
        "430523111": "五峰铺镇",
        "430523201": "小溪市乡",
        "430523203": "长乐乡",
        "430523204": "蔡桥乡",
        "430523205": "河伯乡",
        "430523206": "黄荆乡",
        "430523207": "诸甲亭乡",
        "430523208": "罗城乡",
        "430523209": "金江乡",
        "430523401": "七里山园艺场",
        "430523402": "五丰铺林场",
        "430523403": "河伯岭林场"
    },
    "430524": {
        "430524100": "桃洪镇",
        "430524101": "小沙江镇",
        "430524102": "金石桥镇",
        "430524103": "司门前镇",
        "430524104": "高平镇",
        "430524105": "六都寨镇",
        "430524106": "荷香桥镇",
        "430524107": "横板桥镇",
        "430524108": "周旺镇",
        "430524109": "滩头镇",
        "430524110": "鸭田镇",
        "430524111": "西洋江镇",
        "430524113": "岩口镇",
        "430524114": "北山镇",
        "430524115": "三阁司镇",
        "430524116": "南岳庙镇",
        "430524117": "七江镇",
        "430524118": "羊古坳镇",
        "430524119": "罗洪镇",
        "430524200": "麻塘山乡",
        "430524201": "虎形山瑶族乡",
        "430524203": "大水田乡",
        "430524207": "荷田乡",
        "430524212": "山界回族乡"
    },
    "430525": {
        "430525001": "文昌街道",
        "430525002": "雪峰街道",
        "430525003": "花古街道",
        "430525101": "江口镇",
        "430525102": "毓兰镇",
        "430525103": "高沙镇",
        "430525104": "竹市镇",
        "430525105": "石江镇",
        "430525106": "黄桥镇",
        "430525107": "山门镇",
        "430525108": "醪田镇",
        "430525109": "花园镇",
        "430525110": "岩山镇",
        "430525111": "水东镇",
        "430525112": "杨林镇",
        "430525113": "月溪镇",
        "430525114": "石柱镇",
        "430525201": "古楼乡",
        "430525202": "长塘瑶族乡",
        "430525203": "罗溪瑶族乡",
        "430525205": "渣坪乡",
        "430525211": "桐山乡",
        "430525212": "大屋瑶族乡",
        "430525500": "茶铺茶场管理区"
    },
    "430527": {
        "430527100": "长铺镇",
        "430527101": "武阳镇",
        "430527102": "李熙桥镇",
        "430527103": "红岩镇",
        "430527104": "唐家坊镇",
        "430527105": "金屋塘镇",
        "430527106": "瓦屋塘镇",
        "430527107": "黄土矿镇",
        "430527200": "东山侗族乡",
        "430527201": "鹅公岭侗族苗族乡",
        "430527203": "寨市苗族侗族乡",
        "430527204": "乐安铺苗族侗族乡",
        "430527206": "关峡苗族乡",
        "430527207": "长铺子苗族侗族乡",
        "430527210": "麻塘苗族瑶族乡",
        "430527212": "河口苗族乡",
        "430527217": "水口乡"
    },
    "430528": {
        "430528100": "金石镇",
        "430528101": "水庙镇",
        "430528102": "崀山镇",
        "430528104": "黄龙镇",
        "430528105": "高桥镇",
        "430528106": "回龙寺镇",
        "430528107": "一渡水镇",
        "430528108": "马头桥镇",
        "430528200": "黄金瑶族乡",
        "430528201": "麻林瑶族乡",
        "430528203": "万塘乡",
        "430528204": "清江桥乡",
        "430528205": "安山乡",
        "430528206": "丰田乡",
        "430528208": "巡田乡",
        "430528209": "靖位乡"
    },
    "430529": {
        "430529100": "儒林镇",
        "430529101": "茅坪镇",
        "430529102": "西岩镇",
        "430529103": "丹口镇",
        "430529104": "五团镇",
        "430529106": "长安营镇",
        "430529200": "威溪乡",
        "430529202": "白毛坪乡",
        "430529203": "兰蓉乡",
        "430529204": "汀坪乡",
        "430529205": "蒋坊乡",
        "430529206": "金紫乡"
    },
    "430581": {
        "430581001": "辕门口街道",
        "430581002": "迎春亭街道",
        "430581003": "法相岩街道",
        "430581004": "水西门街道",
        "430581100": "邓元泰镇",
        "430581101": "湾头桥镇",
        "430581102": "文坪镇",
        "430581103": "荆竹铺镇",
        "430581104": "稠树塘镇",
        "430581105": "邓家铺镇",
        "430581106": "龙溪镇",
        "430581107": "司马冲镇",
        "430581108": "秦桥镇",
        "430581109": "双牌镇",
        "430581110": "大甸镇",
        "430581204": "马坪乡",
        "430581205": "晏田乡",
        "430581207": "水浸坪乡"
    },
    "430600": {
        "430602": "岳阳楼区",
        "430603": "云溪区",
        "430611": "君山区",
        "430621": "岳阳县",
        "430623": "华容县",
        "430624": "湘阴县",
        "430626": "平江县",
        "430671": "岳阳市屈原管理区",
        "430681": "汨罗市",
        "430682": "临湘市"
    },
    "430602": {
        "430602001": "岳阳楼街道",
        "430602002": "三眼桥街道",
        "430602003": "吕仙亭街道",
        "430602004": "金鹗山街道",
        "430602005": "东茅岭街道",
        "430602006": "五里牌街道",
        "430602007": "望岳路街道",
        "430602008": "城陵矶街道",
        "430602009": "枫桥湖街道",
        "430602010": "奇家岭街道",
        "430602011": "洞庭街道",
        "430602012": "洛王街道",
        "430602013": "南湖街道",
        "430602014": "站前街道",
        "430602015": "王家河街道",
        "430602016": "求索街道",
        "430602017": "湖滨街道",
        "430602018": "龙山街道",
        "430602019": "月山街道",
        "430602100": "西塘镇",
        "430602201": "郭镇乡",
        "430602203": "康王乡",
        "430602402": "经济技术开发区通海路管理处",
        "430602406": "金凤桥管理处",
        "430602407": "木里港管理处"
    },
    "430603": {"430603001": "长岭街道", "430603002": "云溪街道", "430603101": "陆城镇", "430603102": "路口镇"},
    "430611": {"430611001": "柳林洲街道", "430611101": "广兴洲镇", "430611102": "许市镇", "430611103": "钱粮湖镇", "430611104": "良心堡镇"},
    "430621": {
        "430621100": "荣家湾镇",
        "430621104": "黄沙街镇",
        "430621105": "新墙镇",
        "430621106": "柏祥镇",
        "430621107": "筻口镇",
        "430621108": "公田镇",
        "430621109": "毛田镇",
        "430621110": "月田镇",
        "430621111": "张谷英镇",
        "430621112": "新开镇",
        "430621113": "步仙镇",
        "430621114": "杨林街镇",
        "430621202": "中洲乡",
        "430621203": "长湖乡",
        "430621500": "东洞庭湖管委会"
    },
    "430623": {
        "430623101": "三封寺镇",
        "430623102": "治河渡镇",
        "430623103": "北景港镇",
        "430623104": "鲇鱼须镇",
        "430623106": "万庾镇",
        "430623110": "插旗镇",
        "430623111": "注滋口镇",
        "430623112": "操军镇",
        "430623114": "东山镇",
        "430623119": "梅田湖镇",
        "430623120": "章华镇",
        "430623121": "禹山镇",
        "430623201": "新河乡",
        "430623207": "团洲乡"
    },
    "430624": {
        "430624100": "文星镇",
        "430624101": "东塘镇",
        "430624104": "樟树镇",
        "430624110": "三塘镇",
        "430624116": "岭北镇",
        "430624117": "新泉镇",
        "430624118": "湘滨镇",
        "430624119": "南湖洲镇",
        "430624120": "鹤龙湖镇",
        "430624121": "金龙镇",
        "430624122": "静河镇",
        "430624123": "玉华镇",
        "430624201": "六塘乡",
        "430624206": "杨林寨乡"
    },
    "430626": {
        "430626100": "汉昌镇",
        "430626101": "安定镇",
        "430626102": "三市镇",
        "430626103": "加义镇",
        "430626104": "长寿镇",
        "430626105": "龙门镇",
        "430626106": "虹桥镇",
        "430626107": "南江镇",
        "430626108": "梅仙镇",
        "430626109": "浯口镇",
        "430626110": "瓮江镇",
        "430626111": "伍市镇",
        "430626112": "向家镇",
        "430626113": "童市镇",
        "430626114": "岑川镇",
        "430626115": "福寿山镇",
        "430626116": "余坪镇",
        "430626117": "石牛寨镇",
        "430626118": "上塔市镇",
        "430626200": "三阳乡",
        "430626205": "木金乡",
        "430626208": "板江乡",
        "430626209": "大洲乡",
        "430626210": "三墩乡"
    },
    "430671": {"430671001": "天问街道", "430671101": "营田镇", "430671102": "河市镇", "430671203": "凤凰乡"},
    "430681": {
        "430681101": "汨罗镇",
        "430681102": "新市镇",
        "430681103": "古培镇",
        "430681104": "白水镇",
        "430681105": "川山坪镇",
        "430681107": "弼时镇",
        "430681110": "长乐镇",
        "430681111": "大荆镇",
        "430681112": "桃林寺镇",
        "430681113": "三江镇",
        "430681118": "屈子祠镇",
        "430681119": "归义镇",
        "430681120": "神鼎山镇",
        "430681121": "罗江镇",
        "430681122": "白塘镇",
        "430681403": "营田"
    },
    "430682": {
        "430682001": "长安街道",
        "430682003": "桃矿街道",
        "430682004": "五里牌街道",
        "430682005": "云湖街道",
        "430682101": "忠防镇",
        "430682103": "聂市镇",
        "430682105": "江南镇",
        "430682107": "桃林镇",
        "430682108": "长塘镇",
        "430682109": "白羊田镇",
        "430682110": "詹桥镇",
        "430682113": "黄盖镇",
        "430682114": "羊楼司镇",
        "430682115": "坦渡镇"
    },
    "430700": {
        "430702": "武陵区",
        "430703": "鼎城区",
        "430721": "安乡县",
        "430722": "汉寿县",
        "430723": "澧县",
        "430724": "临澧县",
        "430725": "桃源县",
        "430726": "石门县",
        "430771": "常德市西洞庭管理区",
        "430781": "津市市"
    },
    "430702": {
        "430702001": "启明街道",
        "430702002": "府坪街道",
        "430702003": "穿紫河街道",
        "430702004": "丹阳街道",
        "430702005": "白马湖街道",
        "430702006": "德山街道",
        "430702007": "东江街道",
        "430702008": "永安街道",
        "430702009": "南坪街道",
        "430702010": "长庚街道",
        "430702011": "芷兰街道",
        "430702012": "芙蓉街道",
        "430702013": "柳叶湖街道",
        "430702014": "七里桥街道",
        "430702015": "樟木桥街道",
        "430702100": "河洑镇",
        "430702102": "白鹤镇",
        "430702200": "芦荻山乡",
        "430702204": "丹洲乡"
    },
    "430703": {
        "430703001": "玉霞街道",
        "430703002": "红云街道",
        "430703003": "郭家铺街道",
        "430703004": "斗姆湖街道",
        "430703101": "蒿子港镇",
        "430703102": "中河口镇",
        "430703103": "十美堂镇",
        "430703104": "牛鼻滩镇",
        "430703105": "韩公渡镇",
        "430703106": "石公桥镇",
        "430703107": "镇德桥镇",
        "430703108": "周家店镇",
        "430703110": "双桥坪镇",
        "430703111": "灌溪镇",
        "430703112": "蔡家岗镇",
        "430703114": "草坪镇",
        "430703115": "石门桥镇",
        "430703116": "谢家铺镇",
        "430703117": "黄土店镇",
        "430703118": "尧天坪镇",
        "430703121": "石板滩镇",
        "430703122": "花岩溪镇",
        "430703204": "许家桥回族维吾尔族乡",
        "430703500": "鼎城区园艺场",
        "430703501": "特种养殖场",
        "430703502": "花岩溪林场",
        "430703503": "贺家山原种场"
    },
    "430721": {
        "430721100": "深柳镇",
        "430721101": "大鲸港镇",
        "430721102": "黄山头镇",
        "430721103": "三岔河镇",
        "430721104": "官垱镇",
        "430721105": "下渔口镇",
        "430721106": "陈家嘴镇",
        "430721108": "大湖口镇",
        "430721201": "安障乡",
        "430721205": "安全乡",
        "430721208": "安丰乡",
        "430721210": "安康乡"
    },
    "430722": {
        "430722001": "龙阳街道",
        "430722002": "辰阳街道",
        "430722003": "沧浪街道",
        "430722004": "株木山街道",
        "430722101": "蒋家嘴镇",
        "430722102": "岩汪湖镇",
        "430722103": "坡头镇",
        "430722104": "酉港镇",
        "430722105": "洲口镇",
        "430722106": "罐头嘴镇",
        "430722107": "沧港镇",
        "430722108": "朱家铺镇",
        "430722109": "太子庙镇",
        "430722111": "崔家桥镇",
        "430722112": "军山铺镇",
        "430722113": "百禄桥镇",
        "430722114": "西湖镇",
        "430722115": "洋淘湖镇",
        "430722117": "丰家铺镇",
        "430722118": "龙潭桥镇",
        "430722205": "聂家桥乡",
        "430722206": "毛家滩回族维吾尔族乡",
        "430722501": "西湖管理区"
    },
    "430723": {
        "430723001": "澧西街道",
        "430723002": "澧阳街道",
        "430723003": "澧浦街道",
        "430723004": "澧澹街道",
        "430723102": "小渡口镇",
        "430723103": "梦溪镇",
        "430723104": "复兴镇",
        "430723105": "盐井镇",
        "430723107": "大堰垱镇",
        "430723108": "王家厂镇",
        "430723109": "金罗镇",
        "430723110": "码头铺镇",
        "430723112": "甘溪滩镇",
        "430723113": "火连坡镇",
        "430723114": "澧南镇",
        "430723115": "如东镇",
        "430723116": "涔南镇",
        "430723117": "官垸镇",
        "430723118": "城头山镇"
    },
    "430724": {
        "430724001": "安福街道",
        "430724002": "望城街道",
        "430724101": "合口镇",
        "430724102": "新安镇",
        "430724103": "佘市桥镇",
        "430724104": "太浮镇",
        "430724105": "四新岗镇",
        "430724106": "停弦渡镇",
        "430724107": "修梅镇",
        "430724204": "烽火乡",
        "430724209": "刻木山乡"
    },
    "430725": {
        "430725001": "漳江街道办",
        "430725002": "浔阳街道办",
        "430725101": "陬市镇",
        "430725102": "盘塘镇",
        "430725103": "热市镇",
        "430725104": "黄石镇",
        "430725105": "漆河镇",
        "430725106": "理公港镇",
        "430725107": "观音寺镇",
        "430725108": "龙潭镇",
        "430725109": "三阳港镇",
        "430725110": "剪市镇",
        "430725111": "茶庵铺镇",
        "430725112": "西安镇",
        "430725113": "沙坪镇",
        "430725114": "桃花源镇",
        "430725115": "架桥镇",
        "430725116": "马鬃岭镇",
        "430725117": "夷望溪镇",
        "430725118": "双溪口镇",
        "430725119": "九溪镇",
        "430725120": "牛车河镇",
        "430725121": "杨溪桥镇",
        "430725122": "郑家驿镇",
        "430725123": "木塘垸镇",
        "430725200": "青林回族维吾尔族乡",
        "430725202": "枫树维吾尔族回族乡",
        "430725212": "佘家坪乡",
        "430725216": "泥窝潭乡"
    },
    "430726": {
        "430726001": "楚江街道",
        "430726002": "永兴街道",
        "430726003": "宝峰街道",
        "430726004": "二都街道",
        "430726101": "蒙泉镇",
        "430726102": "夹山镇",
        "430726103": "易家渡镇",
        "430726104": "新关镇",
        "430726105": "皂市镇",
        "430726106": "维新镇",
        "430726107": "太平镇",
        "430726108": "磨市镇",
        "430726109": "壶瓶山镇",
        "430726110": "南北镇",
        "430726111": "白云镇",
        "430726112": "新铺镇",
        "430726113": "子良镇",
        "430726203": "三圣乡",
        "430726205": "所街乡",
        "430726206": "雁池乡",
        "430726207": "罗坪乡",
        "430726500": "秀坪园艺场",
        "430726502": "东山峰农场",
        "430726503": "大同山林场",
        "430726504": "白云山林场",
        "430726505": "洛浦寺林场",
        "430726506": "夹山管理处"
    },
    "430771": {"430771004": "龙泉街道", "430771005": "金凤街道", "430771101": "祝丰镇"},
    "430781": {
        "430781001": "三洲驿街道",
        "430781002": "汪家桥街道",
        "430781003": "襄阳街街道",
        "430781004": "金鱼岭街道",
        "430781005": "嘉山街道",
        "430781100": "新洲镇",
        "430781103": "白衣镇",
        "430781105": "药山镇",
        "430781106": "毛里湖镇",
        "430781500": "涔澹农场"
    },
    "430800": {"430802": "永定区", "430811": "武陵源区", "430821": "慈利县", "430822": "桑植县"},
    "430802": {
        "430802001": "永定街道",
        "430802002": "大庸桥街道",
        "430802003": "西溪坪街道",
        "430802004": "官黎坪街道",
        "430802005": "崇文街道",
        "430802006": "南庄坪街道",
        "430802100": "新桥镇",
        "430802101": "茅岩河镇",
        "430802102": "教字垭镇",
        "430802103": "天门山镇",
        "430802104": "沅古坪镇",
        "430802105": "尹家溪镇",
        "430802108": "王家坪镇",
        "430802201": "三家馆乡",
        "430802204": "合作桥乡",
        "430802206": "谢家垭乡",
        "430802208": "罗塔坪乡",
        "430802209": "罗水乡",
        "430802210": "桥头乡",
        "430802212": "四都坪乡",
        "430802404": "原种场"
    },
    "430811": {"430811001": "军地坪街道", "430811002": "锣鼓塔街道", "430811201": "协合乡", "430811202": "中湖乡"},
    "430821": {
        "430821100": "零阳镇",
        "430821101": "岩泊渡镇",
        "430821102": "溪口镇",
        "430821103": "东岳观镇",
        "430821104": "通津铺镇",
        "430821105": "杉木桥镇",
        "430821106": "象市镇",
        "430821107": "江垭镇",
        "430821108": "苗市镇",
        "430821109": "零溪镇",
        "430821110": "高桥镇",
        "430821111": "龙潭河镇",
        "430821112": "广福桥镇",
        "430821113": "三合镇",
        "430821114": "二坊坪镇",
        "430821200": "南山坪乡",
        "430821202": "洞溪乡",
        "430821209": "杨柳铺乡",
        "430821211": "三官寺土家族乡",
        "430821212": "高峰土家族乡",
        "430821213": "许家坊土家族乡",
        "430821214": "金岩土家族乡",
        "430821215": "赵家岗土家族乡",
        "430821216": "甘堰土家族乡",
        "430821217": "阳和土家族乡"
    },
    "430822": {
        "430822100": "澧源镇",
        "430822101": "瑞塔铺镇",
        "430822102": "官地坪镇",
        "430822103": "凉水口镇",
        "430822104": "龙潭坪镇",
        "430822105": "五道水镇",
        "430822106": "陈家河镇",
        "430822107": "廖家村镇",
        "430822108": "利福塔镇",
        "430822109": "八大公山镇",
        "430822110": "桥自弯镇",
        "430822111": "人潮溪镇",
        "430822200": "空壳树乡",
        "430822202": "竹叶坪乡",
        "430822203": "走马坪白族乡",
        "430822207": "刘家坪白族乡",
        "430822208": "芙蓉桥白族乡",
        "430822210": "马合口白族乡",
        "430822213": "洪家关白族乡",
        "430822216": "沙塔坪乡",
        "430822224": "河口乡",
        "430822225": "上河溪乡",
        "430822228": "上洞街乡"
    },
    "430900": {
        "430902": "资阳区",
        "430903": "赫山区",
        "430921": "南县",
        "430922": "桃江县",
        "430923": "安化县",
        "430971": "益阳市大通湖管理区",
        "430972": "湖南益阳高新技术产业园区",
        "430981": "沅江市"
    },
    "430902": {
        "430902005": "大码头街道",
        "430902006": "汽车路街道",
        "430902102": "迎风桥镇",
        "430902104": "沙头镇",
        "430902105": "茈湖口镇",
        "430902106": "长春镇",
        "430902107": "新桥河镇",
        "430902203": "张家塞乡",
        "430902401": "长春工业园"
    },
    "430903": {
        "430903001": "赫山街道",
        "430903002": "桃花仑街道",
        "430903003": "金银山街道",
        "430903006": "会龙山街道",
        "430903007": "鱼形山街道",
        "430903008": "龙光桥街道",
        "430903101": "八字哨镇",
        "430903103": "泉交河镇",
        "430903104": "欧江岔镇",
        "430903105": "沧水铺镇",
        "430903107": "岳家桥镇",
        "430903110": "新市渡镇",
        "430903111": "兰溪镇",
        "430903113": "衡龙桥镇",
        "430903114": "泥江口镇",
        "430903209": "笔架山乡",
        "430903402": "龙岭工业园"
    },
    "430921": {
        "430921102": "明山头镇",
        "430921103": "青树嘴镇",
        "430921106": "厂窖镇",
        "430921107": "武圣宫镇",
        "430921125": "南洲镇",
        "430921126": "华阁镇",
        "430921127": "茅草街镇",
        "430921128": "三仙湖镇",
        "430921129": "麻河口镇",
        "430921131": "浪拔湖镇",
        "430921201": "乌嘴乡",
        "430921212": "中鱼口乡"
    },
    "430922": {
        "430922101": "修山镇",
        "430922103": "鸬鹚渡镇",
        "430922107": "石牛江镇",
        "430922108": "牛田镇",
        "430922109": "松木塘镇",
        "430922112": "桃花江镇",
        "430922113": "灰山港镇",
        "430922114": "武潭镇",
        "430922115": "马迹塘镇",
        "430922116": "三堂街镇",
        "430922117": "大栗港镇",
        "430922118": "沾溪镇",
        "430922203": "高桥乡",
        "430922208": "鲊埠回族乡",
        "430922212": "浮丘山乡",
        "430922400": "板溪林场"
    },
    "430923": {
        "430923101": "清塘铺镇",
        "430923103": "仙溪镇",
        "430923105": "长塘镇",
        "430923106": "小淹镇",
        "430923108": "羊角塘镇",
        "430923109": "冷市镇",
        "430923111": "奎溪镇",
        "430923112": "烟溪镇",
        "430923113": "渠江镇",
        "430923114": "平口镇",
        "430923115": "柘溪镇",
        "430923116": "乐安镇",
        "430923117": "滔溪镇",
        "430923118": "梅城镇",
        "430923119": "大福镇",
        "430923120": "马路镇",
        "430923121": "东坪镇",
        "430923122": "江南镇",
        "430923200": "高明乡",
        "430923206": "龙塘乡",
        "430923209": "田庄乡",
        "430923211": "南金乡",
        "430923212": "古楼乡"
    },
    "430971": {"430971121": "河坝镇", "430971123": "金盆镇", "430971124": "北洲子镇", "430971130": "千山红镇", "430971405": "南湾湖"},
    "430972": {"430972005": "朝阳街道", "430972006": "东部产业园", "430972109": "谢林港镇"},
    "430981": {
        "430981006": "琼湖街道",
        "430981008": "胭脂湖街道",
        "430981102": "四季红镇",
        "430981105": "泗湖山镇",
        "430981107": "南嘴镇",
        "430981108": "新湾镇",
        "430981110": "茶盘洲镇",
        "430981112": "南大膳镇",
        "430981113": "黄茅洲镇",
        "430981114": "草尾镇",
        "430981115": "阳罗洲镇",
        "430981116": "共华镇",
        "430981401": "南洞庭芦苇场",
        "430981402": "漉湖芦苇场"
    },
    "431000": {
        "431002": "北湖区",
        "431003": "苏仙区",
        "431021": "桂阳县",
        "431022": "宜章县",
        "431023": "永兴县",
        "431024": "嘉禾县",
        "431025": "临武县",
        "431026": "汝城县",
        "431027": "桂东县",
        "431028": "安仁县",
        "431081": "资兴市"
    },
    "431002": {
        "431002001": "人民路街道",
        "431002002": "北湖街道",
        "431002003": "燕泉街道",
        "431002004": "下湄桥街道",
        "431002005": "骆仙街道",
        "431002006": "增福街道",
        "431002007": "郴江街道",
        "431002008": "涌泉街道",
        "431002009": "安和街道",
        "431002010": "石盖塘街道",
        "431002101": "华塘镇",
        "431002102": "鲁塘镇",
        "431002209": "仰天湖瑶族乡",
        "431002210": "保和瑶族乡"
    },
    "431003": {
        "431003001": "苏仙岭街道",
        "431003002": "南塔街道",
        "431003003": "白鹿洞街道",
        "431003004": "王仙岭街道",
        "431003005": "卜里坪街道",
        "431003006": "观山洞街道",
        "431003101": "白露塘镇",
        "431003102": "良田镇",
        "431003103": "栖凤渡镇",
        "431003104": "坳上镇",
        "431003105": "许家洞镇",
        "431003107": "五里牌镇",
        "431003109": "五盖山镇",
        "431003110": "飞天山镇"
    },
    "431021": {
        "431021001": "龙潭街道",
        "431021002": "鹿峰街道",
        "431021003": "黄沙坪街道",
        "431021102": "仁义镇",
        "431021103": "太和镇",
        "431021104": "洋市镇",
        "431021105": "和平镇",
        "431021106": "流峰镇",
        "431021107": "塘市镇",
        "431021108": "莲塘镇",
        "431021109": "舂陵江镇",
        "431021110": "荷叶镇",
        "431021111": "方元镇",
        "431021112": "樟市镇",
        "431021113": "敖泉镇",
        "431021114": "正和镇",
        "431021115": "浩塘镇",
        "431021116": "雷坪镇",
        "431021117": "欧阳海镇",
        "431021118": "四里镇",
        "431021209": "桥市乡",
        "431021226": "白水瑶族乡"
    },
    "431022": {
        "431022101": "白石渡镇",
        "431022102": "杨梅山镇",
        "431022103": "瑶岗仙镇",
        "431022104": "梅田镇",
        "431022106": "黄沙镇",
        "431022107": "迎春镇",
        "431022108": "一六镇",
        "431022109": "栗源镇",
        "431022110": "岩泉镇",
        "431022111": "玉溪镇",
        "431022112": "天塘镇",
        "431022113": "笆篱镇",
        "431022114": "里田镇",
        "431022115": "五岭镇",
        "431022201": "浆水乡",
        "431022202": "长村乡",
        "431022205": "莽山瑶族乡",
        "431022208": "关溪乡",
        "431022212": "赤石乡"
    },
    "431023": {
        "431023001": "便江街道",
        "431023002": "湘阴渡街道",
        "431023101": "马田镇",
        "431023104": "金龟镇",
        "431023105": "柏林镇",
        "431023106": "鲤鱼塘镇",
        "431023109": "悦来镇",
        "431023112": "黄泥镇",
        "431023113": "樟树镇",
        "431023114": "太和镇",
        "431023115": "油麻镇",
        "431023116": "高亭司镇",
        "431023203": "洋塘乡",
        "431023214": "大布江乡",
        "431023215": "龙形市乡",
        "431023216": "七甲乡"
    },
    "431024": {
        "431024100": "珠泉镇",
        "431024102": "塘村镇",
        "431024103": "袁家镇",
        "431024104": "行廊镇",
        "431024106": "龙潭镇",
        "431024107": "石桥镇",
        "431024108": "坦坪镇",
        "431024110": "广发镇",
        "431024111": "晋屏镇",
        "431024201": "普满乡"
    },
    "431025": {
        "431025100": "舜峰镇",
        "431025101": "金江镇",
        "431025104": "武水镇",
        "431025105": "南强镇",
        "431025106": "汾市镇",
        "431025107": "水东镇",
        "431025108": "楚江镇",
        "431025109": "麦市镇",
        "431025110": "香花镇",
        "431025208": "花塘乡",
        "431025211": "万水乡",
        "431025214": "镇南乡",
        "431025216": "西山瑶族乡"
    },
    "431026": {
        "431026102": "热水镇",
        "431026103": "土桥镇",
        "431026104": "泉水镇",
        "431026105": "暖水镇",
        "431026106": "大坪镇",
        "431026107": "三江口瑶族镇",
        "431026108": "卢阳镇",
        "431026109": "马桥镇",
        "431026110": "井坡镇",
        "431026207": "南洞乡",
        "431026209": "濠头乡",
        "431026214": "延寿瑶族乡",
        "431026216": "集益乡",
        "431026217": "文明瑶族乡",
        "431026400": "大坪国有林场",
        "431026401": "汝城经济开发区"
    },
    "431027": {
        "431027100": "沤江镇",
        "431027101": "沙田镇",
        "431027102": "清泉镇",
        "431027103": "大塘镇",
        "431027104": "四都镇",
        "431027105": "寨前镇",
        "431027106": "普乐镇",
        "431027200": "桥头乡",
        "431027208": "新坊乡",
        "431027210": "东洛乡",
        "431027213": "青山乡",
        "431027400": "宋坪林场"
    },
    "431028": {
        "431028101": "安平镇",
        "431028102": "龙海镇",
        "431028105": "灵官镇",
        "431028106": "永乐江镇",
        "431028107": "金紫仙镇",
        "431028201": "龙市乡",
        "431028204": "渡口乡",
        "431028205": "华王乡",
        "431028206": "牌楼乡",
        "431028207": "平背乡",
        "431028209": "承坪乡",
        "431028210": "竹山乡",
        "431028214": "洋际乡"
    },
    "431081": {
        "431081001": "唐洞街道",
        "431081002": "东江街道",
        "431081100": "滁口镇",
        "431081102": "三都镇",
        "431081103": "蓼江镇",
        "431081105": "兴宁镇",
        "431081106": "州门司镇",
        "431081108": "黄草镇",
        "431081110": "汤溪镇",
        "431081112": "清江镇",
        "431081113": "白廊镇",
        "431081219": "回龙山瑶族乡",
        "431081220": "八面山瑶族乡"
    },
    "431100": {
        "431102": "零陵区",
        "431103": "冷水滩区",
        "431121": "祁阳县",
        "431122": "东安县",
        "431123": "双牌县",
        "431124": "道县",
        "431125": "江永县",
        "431126": "宁远县",
        "431127": "蓝山县",
        "431128": "新田县",
        "431129": "江华瑶族自治县",
        "431171": "永州经济技术开发区",
        "431172": "永州市金洞管理区",
        "431173": "永州市回龙圩管理区"
    },
    "431102": {
        "431102001": "朝阳街道",
        "431102002": "南津渡街道",
        "431102003": "七里店街道",
        "431102004": "徐家井街道",
        "431102005": "接履桥街道",
        "431102006": "石山脚街道",
        "431102101": "水口山镇",
        "431102102": "珠山镇",
        "431102103": "黄田铺镇",
        "431102104": "富家桥镇",
        "431102105": "菱角塘镇",
        "431102106": "邮亭圩镇",
        "431102108": "石岩头镇",
        "431102200": "大庆坪乡",
        "431102201": "梳子铺乡",
        "431102203": "凼底乡"
    },
    "431103": {
        "431103001": "梅湾街道",
        "431103002": "菱角山街道",
        "431103003": "肖家园街道",
        "431103004": "杨家桥街道",
        "431103005": "梧桐街道",
        "431103006": "凤凰街道",
        "431103007": "珊瑚街道",
        "431103008": "曲河街道",
        "431103009": "岚角山街道",
        "431103101": "花桥街镇",
        "431103102": "普利桥镇",
        "431103103": "牛角坝镇",
        "431103104": "高溪市镇",
        "431103105": "黄阳司镇",
        "431103106": "上岭桥镇",
        "431103108": "伊塘镇",
        "431103110": "蔡市镇",
        "431103201": "杨村甸乡"
    },
    "431121": {
        "431121001": "龙山街道",
        "431121002": "长虹街道",
        "431121003": "浯溪街道",
        "431121101": "观音滩镇",
        "431121102": "茅竹镇",
        "431121103": "大忠桥镇",
        "431121104": "三口塘镇",
        "431121105": "肖家镇",
        "431121106": "八宝镇",
        "431121107": "白水镇",
        "431121108": "黄泥塘镇",
        "431121109": "进宝塘镇",
        "431121110": "潘市镇",
        "431121111": "梅溪镇",
        "431121112": "羊角塘镇",
        "431121113": "下马渡镇",
        "431121114": "七里桥镇",
        "431121115": "大村甸镇",
        "431121116": "黎家坪镇",
        "431121117": "文富市镇",
        "431121118": "文明铺镇",
        "431121119": "龚家坪镇",
        "431121401": "原种场",
        "431121402": "畜牧场"
    },
    "431122": {
        "431122100": "白牙市镇",
        "431122101": "大庙口镇",
        "431122102": "紫溪市镇",
        "431122103": "横塘镇",
        "431122104": "石期市镇",
        "431122105": "井头圩镇",
        "431122106": "端桥铺镇",
        "431122107": "鹿马桥镇",
        "431122108": "芦洪市镇",
        "431122109": "新圩江镇",
        "431122110": "花桥镇",
        "431122111": "大盛镇",
        "431122112": "南桥镇",
        "431122201": "川岩乡",
        "431122202": "水岭乡",
        "431122400": "大庙口林场",
        "431122401": "黄泥洞林场"
    },
    "431123": {
        "431123100": "泷泊镇",
        "431123101": "江村镇",
        "431123102": "五里牌镇",
        "431123103": "茶林镇",
        "431123104": "何家洞镇",
        "431123105": "麻江镇",
        "431123206": "塘底乡",
        "431123207": "上梧江瑶族乡",
        "431123208": "理家坪乡",
        "431123209": "五星岭乡",
        "431123210": "打鼓坪乡",
        "431123400": "双牌打鼓坪林场",
        "431123401": "双牌五星岭林场",
        "431123402": "阳明山国家森林公园管理局"
    },
    "431124": {
        "431124001": "濂溪街道",
        "431124002": "西洲街道",
        "431124003": "上关街道",
        "431124004": "营江街道",
        "431124005": "东门街道",
        "431124006": "富塘街道",
        "431124007": "万家庄街道",
        "431124101": "梅花镇",
        "431124102": "寿雁镇",
        "431124103": "仙子脚镇",
        "431124104": "清塘镇",
        "431124105": "祥霖铺镇",
        "431124106": "蚣坝镇",
        "431124107": "四马桥镇",
        "431124108": "白马渡镇",
        "431124109": "柑子园镇",
        "431124111": "白芒铺镇",
        "431124112": "桥头镇",
        "431124201": "乐福堂乡",
        "431124210": "审章塘瑶族乡",
        "431124212": "横岭瑶族乡",
        "431124213": "洪塘营瑶族乡",
        "431124402": "道县大坪铺农场"
    },
    "431125": {
        "431125100": "潇浦镇",
        "431125101": "上江圩镇",
        "431125103": "夏层铺镇",
        "431125104": "桃川镇",
        "431125105": "粗石江镇",
        "431125200": "松柏瑶族乡",
        "431125202": "千家峒瑶族乡",
        "431125203": "兰溪瑶族乡",
        "431125204": "源口瑶族乡"
    },
    "431126": {
        "431126001": "文庙街道",
        "431126002": "舜陵街道",
        "431126003": "桐山街道",
        "431126004": "东溪街道",
        "431126101": "天堂镇",
        "431126102": "水市镇",
        "431126103": "湾井镇",
        "431126104": "冷水镇",
        "431126105": "太平镇",
        "431126106": "禾亭镇",
        "431126107": "仁和镇",
        "431126108": "中和镇",
        "431126109": "柏家坪镇",
        "431126110": "清水桥镇",
        "431126111": "鲤溪镇",
        "431126112": "保安镇",
        "431126200": "九疑山瑶族乡",
        "431126202": "五龙山瑶族乡",
        "431126203": "棉花坪瑶族乡",
        "431126204": "桐木漯瑶族乡"
    },
    "431127": {
        "431127100": "塔峰镇",
        "431127102": "毛俊镇",
        "431127103": "楠市镇",
        "431127104": "所城镇",
        "431127105": "新圩镇",
        "431127106": "祠堂圩镇",
        "431127107": "土市镇",
        "431127108": "太平圩镇",
        "431127200": "汇源瑶族乡",
        "431127201": "犁头瑶族乡",
        "431127202": "浆洞瑶族乡",
        "431127203": "湘江源瑶族乡",
        "431127204": "大桥瑶族乡",
        "431127205": "荆竹瑶族乡",
        "431127400": "蓝山荆竹林场",
        "431127401": "蓝山浆洞林场",
        "431127402": "蓝山南岭林场",
        "431127403": "蓝山原种场",
        "431127404": "蓝山黄毛岭茶场"
    },
    "431128": {
        "431128100": "龙泉镇",
        "431128101": "金陵镇",
        "431128102": "骥村镇",
        "431128103": "枧头镇",
        "431128104": "新圩镇",
        "431128105": "石羊镇",
        "431128106": "新隆镇",
        "431128107": "三井镇",
        "431128108": "大坪塘镇",
        "431128109": "陶岭镇",
        "431128110": "金盆镇",
        "431128202": "门楼下瑶族乡"
    },
    "431129": {
        "431129100": "沱江镇",
        "431129103": "大路铺镇",
        "431129104": "白芒营镇",
        "431129105": "涛圩镇",
        "431129106": "河路口镇",
        "431129108": "大圩镇",
        "431129109": "水口镇",
        "431129110": "码市镇",
        "431129111": "涔天河镇",
        "431129200": "界牌乡",
        "431129201": "桥市乡",
        "431129202": "大石桥乡",
        "431129207": "湘江乡",
        "431129209": "蔚竹口乡",
        "431129210": "大锡乡",
        "431129211": "小圩壮族乡"
    },
    "431171": {"431171001": "仁湾街道"},
    "431172": {"431172120": "金洞镇", "431172201": "晒北滩瑶族乡", "431172202": "凤凰乡", "431172204": "石鼓源乡"},
    "431173": {"431173100": "回龙圩镇"},
    "431200": {
        "431202": "鹤城区",
        "431221": "中方县",
        "431222": "沅陵县",
        "431223": "辰溪县",
        "431224": "溆浦县",
        "431225": "会同县",
        "431226": "麻阳苗族自治县",
        "431227": "新晃侗族自治县",
        "431228": "芷江侗族自治县",
        "431229": "靖州苗族侗族自治县",
        "431230": "通道侗族自治县",
        "431271": "怀化市洪江管理区",
        "431281": "洪江市"
    },
    "431202": {
        "431202001": "城中街道",
        "431202002": "城北街道",
        "431202003": "红星街道",
        "431202004": "迎丰街道",
        "431202005": "坨院街道",
        "431202006": "河西街道",
        "431202007": "城南街道",
        "431202101": "黄金坳镇",
        "431202201": "盈口乡",
        "431202205": "凉亭坳乡",
        "431202400": "黄岩旅游度假区"
    },
    "431221": {
        "431221100": "中方镇",
        "431221102": "泸阳镇",
        "431221103": "花桥镇",
        "431221104": "铜湾镇",
        "431221105": "桐木镇",
        "431221106": "铁坡镇",
        "431221107": "新建镇",
        "431221108": "接龙镇",
        "431221109": "铜鼎镇",
        "431221110": "新路河镇",
        "431221111": "袁家镇",
        "431221214": "蒿吉坪瑶族乡"
    },
    "431222": {
        "431222102": "麻溪铺镇",
        "431222105": "五强溪镇",
        "431222110": "明溪口镇",
        "431222111": "凉水井镇",
        "431222112": "七甲坪镇",
        "431222113": "筲箕湾镇",
        "431222114": "官庄镇",
        "431222115": "沅陵镇",
        "431222223": "杜家坪乡",
        "431222224": "楠木铺乡",
        "431222230": "肖家桥乡",
        "431222234": "火场土家族乡",
        "431222238": "陈家滩乡",
        "431222239": "清浪乡",
        "431222245": "借母溪乡",
        "431222247": "荔溪乡",
        "431222248": "大合坪乡",
        "431222250": "马底驿乡",
        "431222251": "北溶乡",
        "431222252": "二酉乡",
        "431222253": "盘古乡"
    },
    "431223": {
        "431223100": "辰阳镇",
        "431223101": "孝坪镇",
        "431223102": "田湾镇",
        "431223103": "火马冲镇",
        "431223104": "黄溪口镇",
        "431223105": "潭湾镇",
        "431223106": "安坪镇",
        "431223108": "锦滨镇",
        "431223109": "修溪镇",
        "431223200": "船溪乡",
        "431223204": "长田湾乡",
        "431223205": "小龙门乡",
        "431223206": "后塘瑶族乡",
        "431223207": "苏木溪瑶族乡",
        "431223208": "罗子山瑶族乡",
        "431223209": "上蒲溪瑶族乡",
        "431223210": "仙人湾瑶族乡",
        "431223211": "龙头庵乡",
        "431223215": "大水田乡",
        "431223216": "桥头溪乡",
        "431223218": "龙泉岩乡",
        "431223221": "柿溪乡",
        "431223222": "谭家场乡"
    },
    "431224": {
        "431224100": "卢峰镇",
        "431224102": "低庄镇",
        "431224103": "桥江镇",
        "431224104": "龙潭镇",
        "431224105": "均坪镇",
        "431224106": "观音阁镇",
        "431224107": "双井镇",
        "431224108": "水东镇",
        "431224109": "两丫坪镇",
        "431224110": "黄茅园镇",
        "431224112": "葛竹坪镇",
        "431224118": "大江口镇",
        "431224119": "思蒙镇",
        "431224120": "深子湖镇",
        "431224121": "祖师殿镇",
        "431224122": "三江镇",
        "431224123": "统溪河镇",
        "431224124": "北斗溪镇",
        "431224204": "舒溶溪乡",
        "431224213": "油洋乡",
        "431224219": "小横垅乡",
        "431224223": "中都乡",
        "431224224": "沿溪乡",
        "431224230": "龙庄湾乡",
        "431224231": "淘金坪乡"
    },
    "431225": {
        "431225100": "林城镇",
        "431225101": "坪村镇",
        "431225102": "堡子镇",
        "431225103": "团河镇",
        "431225104": "若水镇",
        "431225106": "广坪镇",
        "431225107": "马鞍镇",
        "431225108": "金竹镇",
        "431225200": "沙溪乡",
        "431225201": "金子岩侗族苗族乡",
        "431225204": "高椅乡",
        "431225209": "宝田侗族苗族乡",
        "431225210": "漠滨侗族苗族乡",
        "431225211": "蒲稳侗族苗族乡",
        "431225212": "青朗侗族苗族乡",
        "431225213": "炮团侗族苗族乡",
        "431225214": "地灵乡",
        "431225215": "连山乡"
    },
    "431226": {
        "431226101": "锦和镇",
        "431226102": "江口墟镇",
        "431226103": "岩门镇",
        "431226104": "兰里镇",
        "431226105": "吕家坪镇",
        "431226106": "高村镇",
        "431226107": "尧市镇",
        "431226108": "郭公坪镇",
        "431226204": "文昌阁乡",
        "431226205": "大桥江乡",
        "431226206": "舒家村乡",
        "431226207": "隆家堡乡",
        "431226208": "谭家寨乡",
        "431226209": "石羊哨乡",
        "431226210": "板栗树乡",
        "431226212": "兰村乡",
        "431226215": "和平溪乡",
        "431226216": "黄桑乡"
    },
    "431227": {
        "431227101": "波洲镇",
        "431227103": "鱼市镇",
        "431227104": "凉伞镇",
        "431227105": "扶罗镇",
        "431227106": "中寨镇",
        "431227107": "晃洲镇",
        "431227108": "林冲镇",
        "431227109": "贡溪镇",
        "431227110": "禾滩镇",
        "431227200": "步头降苗族乡",
        "431227215": "米贝苗族乡"
    },
    "431228": {
        "431228100": "芷江镇",
        "431228101": "罗旧镇",
        "431228102": "新店坪镇",
        "431228103": "碧涌镇",
        "431228104": "公坪镇",
        "431228105": "岩桥镇",
        "431228106": "三道坑镇",
        "431228107": "土桥镇",
        "431228108": "楠木坪镇",
        "431228201": "牛牯坪乡",
        "431228204": "水宽乡",
        "431228212": "大树坳乡",
        "431228215": "梨溪口乡",
        "431228216": "洞下场乡",
        "431228220": "禾梨坳乡",
        "431228221": "冷水溪乡",
        "431228223": "晓坪乡",
        "431228224": "罗卜田乡"
    },
    "431229": {
        "431229100": "渠阳镇",
        "431229101": "甘棠镇",
        "431229102": "大堡子镇",
        "431229103": "坳上镇",
        "431229104": "新厂镇",
        "431229105": "平茶镇",
        "431229200": "太阳坪乡",
        "431229201": "三锹乡",
        "431229202": "文溪乡",
        "431229203": "寨牙乡",
        "431229206": "藕团乡"
    },
    "431230": {
        "431230100": "双江镇",
        "431230101": "县溪镇",
        "431230102": "播阳镇",
        "431230104": "牙屯堡镇",
        "431230105": "菁芜洲镇",
        "431230106": "溪口镇",
        "431230107": "陇城镇",
        "431230108": "万佛山镇",
        "431230109": "独坡镇",
        "431230203": "大高坪苗族乡",
        "431230216": "坪坦乡",
        "431230400": "通道县地连林场",
        "431230401": "通道县播阳农场"
    },
    "431271": {
        "431271001": "河滨路街道",
        "431271002": "沅江路街道",
        "431271003": "新街街道",
        "431271004": "高坡街街道",
        "431271218": "横岩乡",
        "431271220": "桂花园乡"
    },
    "431281": {
        "431281101": "黔城镇",
        "431281102": "安江镇",
        "431281103": "托口镇",
        "431281104": "雪峰镇",
        "431281106": "江市镇",
        "431281107": "沅河镇",
        "431281108": "塘湾镇",
        "431281202": "岔头乡",
        "431281203": "茅渡乡",
        "431281204": "大崇乡",
        "431281205": "熟坪乡",
        "431281206": "铁山乡",
        "431281207": "群峰乡",
        "431281208": "湾溪乡",
        "431281209": "洗马乡",
        "431281211": "沙湾乡",
        "431281212": "深渡苗族乡",
        "431281213": "龙船塘瑶族乡",
        "431281214": "太平乡",
        "431281217": "岩垅乡"
    },
    "431300": {"431302": "娄星区", "431321": "双峰县", "431322": "新化县", "431381": "冷水江市", "431382": "涟源市"},
    "431302": {
        "431302001": "乐坪街道",
        "431302002": "花山街道",
        "431302003": "黄泥塘街道",
        "431302004": "长青街道",
        "431302005": "大科街道",
        "431302006": "涟滨街道",
        "431302007": "大埠桥街道",
        "431302100": "杉山镇",
        "431302101": "万宝镇",
        "431302104": "石井镇",
        "431302105": "水洞底镇",
        "431302106": "蛇形山镇",
        "431302204": "双江乡"
    },
    "431321": {
        "431321100": "永丰镇",
        "431321101": "荷叶镇",
        "431321102": "井字镇",
        "431321103": "梓门桥镇",
        "431321104": "杏子铺镇",
        "431321105": "走马街镇",
        "431321107": "洪山殿镇",
        "431321108": "甘棠镇",
        "431321109": "三塘铺镇",
        "431321110": "青树坪镇",
        "431321111": "花门镇",
        "431321112": "锁石镇",
        "431321200": "石牛乡",
        "431321201": "沙塘乡",
        "431321202": "印塘乡",
        "431321400": "双峰经济开发区"
    },
    "431322": {
        "431322001": "上梅街道",
        "431322002": "上渡街道",
        "431322003": "枫林街道",
        "431322101": "石冲口镇",
        "431322102": "洋溪镇",
        "431322103": "槎溪镇",
        "431322104": "水车镇",
        "431322105": "文田镇",
        "431322106": "奉家镇",
        "431322107": "炉观镇",
        "431322108": "游家镇",
        "431322109": "西河镇",
        "431322110": "孟公镇",
        "431322111": "琅塘镇",
        "431322112": "白溪镇",
        "431322113": "圳上镇",
        "431322114": "吉庆镇",
        "431322115": "温塘镇",
        "431322116": "田坪镇",
        "431322117": "桑梓镇",
        "431322118": "曹家镇",
        "431322200": "科头乡",
        "431322201": "维山乡",
        "431322202": "天门乡",
        "431322203": "荣华乡",
        "431322204": "金凤乡",
        "431322205": "油溪乡",
        "431322206": "坐石乡",
        "431322500": "大熊山国有林场",
        "431322501": "古台山国有林场"
    },
    "431381": {
        "431381001": "冷水江街道",
        "431381002": "锡矿山街道",
        "431381003": "沙塘湾街道",
        "431381004": "布溪街道",
        "431381100": "禾青镇",
        "431381102": "渣渡镇",
        "431381103": "铎山镇",
        "431381105": "三尖镇",
        "431381106": "金竹山镇",
        "431381205": "中连乡"
    },
    "431382": {
        "431382001": "蓝田街道",
        "431382100": "六亩塘镇",
        "431382101": "石马山镇",
        "431382102": "安平镇",
        "431382103": "湄江镇",
        "431382104": "伏口镇",
        "431382105": "桥头河镇",
        "431382106": "七星街镇",
        "431382107": "杨市镇",
        "431382108": "枫坪镇",
        "431382109": "斗笠山镇",
        "431382111": "白马镇",
        "431382112": "茅塘镇",
        "431382113": "荷塘镇",
        "431382114": "金石镇",
        "431382115": "龙塘镇",
        "431382116": "渡头塘镇",
        "431382200": "三甲乡",
        "431382202": "古塘乡"
    },
    "433100": {
        "433101": "吉首市",
        "433122": "泸溪县",
        "433123": "凤凰县",
        "433124": "花垣县",
        "433125": "保靖县",
        "433126": "古丈县",
        "433127": "永顺县",
        "433130": "龙山县",
        "433172": "湖南吉首经济开发区",
        "433173": "湖南永顺经济开发区"
    },
    "433101": {
        "433101002": "峒河街道",
        "433101004": "乾州街道",
        "433101005": "镇溪街道",
        "433101007": "石家冲街道",
        "433101008": "双塘街道",
        "433101009": "吉凤街道",
        "433101100": "矮寨镇",
        "433101101": "马颈坳镇",
        "433101102": "河溪镇",
        "433101104": "丹青镇",
        "433101105": "太平镇",
        "433101201": "已略乡"
    },
    "433122": {
        "433122102": "达岚镇",
        "433122104": "兴隆场镇",
        "433122105": "潭溪镇",
        "433122106": "洗溪镇",
        "433122107": "武溪镇",
        "433122108": "浦市镇",
        "433122109": "合水镇",
        "433122203": "石榴坪乡",
        "433122206": "解放岩乡",
        "433122207": "小章乡",
        "433122208": "白羊溪乡",
        "433122400": "军亭界林场"
    },
    "433123": {
        "433123101": "廖家桥镇",
        "433123104": "茶田镇",
        "433123105": "吉信镇",
        "433123107": "腊尔山镇",
        "433123108": "禾库镇",
        "433123109": "沱江镇",
        "433123110": "阿拉营镇",
        "433123111": "木江坪镇",
        "433123112": "山江镇",
        "433123113": "落潮井镇",
        "433123114": "新场镇",
        "433123115": "筸子坪镇",
        "433123116": "千工坪镇",
        "433123204": "水打田乡",
        "433123205": "林峰乡",
        "433123216": "麻冲乡",
        "433123220": "两林乡"
    },
    "433124": {
        "433124102": "龙潭镇",
        "433124103": "民乐镇",
        "433124105": "吉卫镇",
        "433124106": "麻栗场镇",
        "433124107": "雅酉镇",
        "433124108": "边城镇",
        "433124109": "花垣镇",
        "433124110": "双龙镇",
        "433124111": "石栏镇",
        "433124200": "长乐乡",
        "433124206": "猫儿乡",
        "433124213": "补抽乡"
    },
    "433125": {
        "433125104": "普戎镇",
        "433125108": "复兴镇",
        "433125109": "迁陵镇",
        "433125110": "清水坪镇",
        "433125111": "比耳镇",
        "433125112": "毛沟镇",
        "433125113": "水田河镇",
        "433125114": "葫芦镇",
        "433125115": "碗米坡镇",
        "433125116": "吕洞山镇",
        "433125217": "阳朝乡",
        "433125218": "长潭河乡",
        "433125401": "扁朝牧场",
        "433125403": "白云山农垦场",
        "433125404": "钟灵山工业园",
        "433125405": "麻风村"
    },
    "433126": {
        "433126101": "古阳镇",
        "433126103": "岩头寨镇",
        "433126104": "默戎镇",
        "433126105": "红石林镇",
        "433126106": "断龙山镇",
        "433126107": "高峰镇",
        "433126108": "坪坝镇"
    },
    "433127": {
        "433127101": "首车镇",
        "433127103": "芙蓉镇",
        "433127104": "永茂镇",
        "433127108": "小溪镇",
        "433127109": "青坪镇",
        "433127112": "石堤镇",
        "433127113": "万坪镇",
        "433127114": "塔卧镇",
        "433127115": "砂坝镇",
        "433127116": "灵溪镇",
        "433127117": "松柏镇",
        "433127118": "泽家镇",
        "433127200": "两岔乡",
        "433127202": "西歧乡",
        "433127203": "对山乡",
        "433127210": "高坪乡",
        "433127216": "朗溪乡",
        "433127220": "润雅乡",
        "433127222": "车坪乡",
        "433127228": "毛坝乡",
        "433127229": "万民乡",
        "433127230": "盐井乡",
        "433127238": "颗砂乡"
    },
    "433130": {
        "433130001": "民安街道",
        "433130002": "华塘街道",
        "433130004": "兴隆街道",
        "433130005": "石羔街道",
        "433130104": "洗车河镇",
        "433130110": "石牌镇",
        "433130112": "茨岩塘镇",
        "433130113": "红岩溪镇",
        "433130114": "靛房镇",
        "433130115": "苗儿滩镇",
        "433130116": "里耶镇",
        "433130117": "桂塘镇",
        "433130118": "召市镇",
        "433130119": "洗洛镇",
        "433130120": "水田坝镇",
        "433130121": "农车镇",
        "433130200": "洛塔乡",
        "433130210": "大安乡",
        "433130220": "内溪乡",
        "433130236": "咱果乡",
        "433130237": "茅坪乡"
    },
    "433172": {"433172598": "乾州街道经开区"},
    "433173": {"433173002": "湖南永顺经济开发区管委会"},
    "440000": {
        "440100": "广州市",
        "440200": "韶关市",
        "440300": "深圳市",
        "440400": "珠海市",
        "440500": "汕头市",
        "440600": "佛山市",
        "440700": "江门市",
        "440800": "湛江市",
        "440900": "茂名市",
        "441200": "肇庆市",
        "441300": "惠州市",
        "441400": "梅州市",
        "441500": "汕尾市",
        "441600": "河源市",
        "441700": "阳江市",
        "441800": "清远市",
        "441900": "东莞市",
        "442000": "中山市",
        "445100": "潮州市",
        "445200": "揭阳市",
        "445300": "云浮市"
    },
    "440100": {
        "440103": "荔湾区",
        "440104": "越秀区",
        "440105": "海珠区",
        "440106": "天河区",
        "440111": "白云区",
        "440112": "黄埔区",
        "440113": "番禺区",
        "440114": "花都区",
        "440115": "南沙区",
        "440117": "从化区",
        "440118": "增城区"
    },
    "440103": {
        "440103001": "沙面街道",
        "440103002": "岭南街道",
        "440103003": "华林街道",
        "440103004": "多宝街道",
        "440103005": "昌华街道",
        "440103006": "逢源街道",
        "440103007": "龙津街道",
        "440103008": "金花街道",
        "440103009": "彩虹街道",
        "440103010": "南源街道",
        "440103011": "西村街道",
        "440103012": "站前街道",
        "440103013": "桥中街道",
        "440103014": "白鹤洞街道",
        "440103015": "冲口街道",
        "440103016": "花地街道",
        "440103017": "石围塘街道",
        "440103018": "茶滘街道",
        "440103019": "东漖街道",
        "440103020": "海龙街道",
        "440103021": "东沙街道",
        "440103022": "中南街道"
    },
    "440104": {
        "440104001": "洪桥街道",
        "440104003": "北京街道",
        "440104004": "六榕街道",
        "440104005": "流花街道",
        "440104007": "光塔街道",
        "440104010": "人民街道",
        "440104011": "东山街道",
        "440104012": "农林街道",
        "440104013": "梅花村街道",
        "440104014": "黄花岗街道",
        "440104015": "华乐街道",
        "440104016": "建设街道",
        "440104017": "大塘街道",
        "440104018": "珠光街道",
        "440104019": "大东街道",
        "440104020": "白云街道",
        "440104021": "登峰街道",
        "440104022": "矿泉街道"
    },
    "440105": {
        "440105001": "赤岗街道",
        "440105002": "新港街道",
        "440105003": "昌岗街道",
        "440105004": "江南中街道",
        "440105005": "滨江街道",
        "440105006": "素社街道",
        "440105007": "海幢街道",
        "440105008": "南华西街道",
        "440105009": "龙凤街道",
        "440105010": "沙园街道",
        "440105011": "南石头街道",
        "440105012": "凤阳街道",
        "440105013": "瑞宝街道",
        "440105014": "江海街道",
        "440105015": "琶洲街道",
        "440105016": "南洲街道",
        "440105017": "华洲街道",
        "440105018": "官洲街道"
    },
    "440106": {
        "440106001": "五山街道",
        "440106002": "员村街道",
        "440106003": "车陂街道",
        "440106004": "沙河街道",
        "440106006": "石牌街道",
        "440106007": "沙东街道",
        "440106008": "天河南街道",
        "440106009": "林和街道",
        "440106010": "兴华街道",
        "440106011": "棠下街道",
        "440106012": "天园街道",
        "440106013": "猎德街道",
        "440106014": "冼村街道",
        "440106015": "元岗街道",
        "440106016": "黄村街道",
        "440106017": "长兴街道",
        "440106018": "龙洞街道",
        "440106019": "凤凰街道",
        "440106020": "前进街道",
        "440106021": "珠吉街道",
        "440106022": "新塘街道"
    },
    "440111": {
        "440111002": "三元里街道",
        "440111003": "松洲街道",
        "440111004": "景泰街道",
        "440111005": "同德街道",
        "440111006": "黄石街道",
        "440111007": "棠景街道",
        "440111008": "新市街道",
        "440111009": "同和街道",
        "440111010": "京溪街道",
        "440111011": "永平街道",
        "440111012": "嘉禾街道",
        "440111013": "均禾街道",
        "440111014": "石井街道",
        "440111015": "金沙街道",
        "440111016": "云城街道",
        "440111017": "鹤龙街道",
        "440111018": "白云湖街道",
        "440111019": "石门街道",
        "440111103": "人和镇",
        "440111107": "太和镇",
        "440111108": "钟落潭镇",
        "440111113": "江高镇"
    },
    "440112": {
        "440112001": "黄埔街道",
        "440112002": "红山街道",
        "440112003": "鱼珠街道",
        "440112005": "大沙街道",
        "440112006": "文冲街道",
        "440112007": "穗东街道",
        "440112008": "南岗街道",
        "440112010": "长洲街道",
        "440112011": "夏港街道",
        "440112012": "萝岗街道",
        "440112013": "云埔街道",
        "440112014": "联和街道",
        "440112015": "永和街道",
        "440112016": "长岭街道",
        "440112101": "九龙镇"
    },
    "440113": {
        "440113007": "市桥街道",
        "440113008": "沙头街道",
        "440113009": "东环街道",
        "440113010": "桥南街道",
        "440113011": "小谷围街道",
        "440113012": "大石街道",
        "440113013": "洛浦街道",
        "440113014": "石壁街道",
        "440113015": "钟村街道",
        "440113016": "大龙街道",
        "440113102": "南村镇",
        "440113103": "新造镇",
        "440113104": "化龙镇",
        "440113105": "石楼镇",
        "440113118": "沙湾镇",
        "440113120": "石基镇"
    },
    "440114": {
        "440114001": "新华街道",
        "440114002": "花城街道",
        "440114003": "秀全街道",
        "440114004": "新雅街道",
        "440114103": "梯面镇",
        "440114104": "花山镇",
        "440114105": "花东镇",
        "440114107": "炭步镇",
        "440114108": "赤坭镇",
        "440114109": "狮岭镇"
    },
    "440115": {
        "440115001": "南沙街道",
        "440115002": "珠江街道",
        "440115003": "龙穴街道",
        "440115100": "万顷沙镇",
        "440115101": "横沥镇",
        "440115102": "黄阁镇",
        "440115103": "东涌镇",
        "440115104": "大岗镇",
        "440115105": "榄核镇"
    },
    "440117": {
        "440117001": "街口街道",
        "440117002": "江埔街道",
        "440117003": "城郊街道",
        "440117103": "温泉镇",
        "440117104": "良口镇",
        "440117107": "吕田镇",
        "440117111": "太平镇",
        "440117113": "鳌头镇",
        "440117404": "黄龙带水库管理处",
        "440117405": "大岭山林场"
    },
    "440118": {
        "440118001": "荔城街道",
        "440118002": "增江街道",
        "440118003": "朱村街道",
        "440118004": "永宁街道",
        "440118101": "新塘镇",
        "440118102": "石滩镇",
        "440118103": "中新镇",
        "440118104": "正果镇",
        "440118105": "派潭镇",
        "440118106": "小楼镇",
        "440118107": "仙村镇"
    },
    "440200": {
        "440203": "武江区",
        "440204": "浈江区",
        "440205": "曲江区",
        "440222": "始兴县",
        "440224": "仁化县",
        "440229": "翁源县",
        "440232": "乳源瑶族自治县",
        "440233": "新丰县",
        "440281": "乐昌市",
        "440282": "南雄市"
    },
    "440203": {
        "440203001": "新华街道",
        "440203002": "惠民街道",
        "440203100": "西联镇",
        "440203101": "西河镇",
        "440203102": "龙归镇",
        "440203103": "江湾镇",
        "440203104": "重阳镇"
    },
    "440204": {
        "440204001": "东河街道",
        "440204002": "车站街道",
        "440204008": "风采街道",
        "440204100": "新韶镇",
        "440204101": "乐园镇",
        "440204102": "十里亭镇",
        "440204103": "犁市镇",
        "440204104": "花坪镇",
        "440204401": "田螺冲",
        "440204402": "曲仁"
    },
    "440205": {
        "440205001": "松山街道",
        "440205100": "马坝镇",
        "440205101": "大塘镇",
        "440205102": "枫湾镇",
        "440205103": "小坑镇",
        "440205104": "沙溪镇",
        "440205106": "乌石镇",
        "440205107": "樟市镇",
        "440205108": "白土镇",
        "440205109": "罗坑镇",
        "440205452": "曲江区大宝山矿"
    },
    "440222": {
        "440222100": "太平镇",
        "440222101": "马市镇",
        "440222102": "澄江镇",
        "440222103": "顿岗镇",
        "440222104": "罗坝镇",
        "440222105": "司前镇",
        "440222106": "隘子镇",
        "440222107": "城南镇",
        "440222108": "沈所镇",
        "440222204": "深渡水乡"
    },
    "440224": {
        "440224001": "丹霞街道",
        "440224102": "闻韶镇",
        "440224103": "扶溪镇",
        "440224104": "长江镇",
        "440224105": "城口镇",
        "440224106": "红山镇",
        "440224107": "石塘镇",
        "440224108": "董塘镇",
        "440224109": "大桥镇",
        "440224110": "周田镇",
        "440224111": "黄坑镇"
    },
    "440229": {
        "440229100": "龙仙镇",
        "440229104": "坝仔镇",
        "440229106": "江尾镇",
        "440229109": "官渡镇",
        "440229111": "周陂镇",
        "440229113": "翁城镇",
        "440229114": "新江镇",
        "440229450": "铁龙林场"
    },
    "440232": {
        "440232100": "乳城镇",
        "440232103": "一六镇",
        "440232104": "桂头镇",
        "440232106": "洛阳镇",
        "440232108": "大布镇",
        "440232109": "大桥镇",
        "440232111": "东坪镇",
        "440232112": "游溪镇",
        "440232113": "必背镇",
        "440232450": "天井山林场",
        "440232451": "乳阳林业局"
    },
    "440233": {
        "440233001": "丰城街道",
        "440233100": "黄礤镇",
        "440233101": "马头镇",
        "440233102": "梅坑镇",
        "440233103": "沙田镇",
        "440233104": "遥田镇",
        "440233105": "回龙镇"
    },
    "440281": {
        "440281001": "乐城街道",
        "440281102": "北乡镇",
        "440281103": "九峰镇",
        "440281104": "廊田镇",
        "440281105": "长来镇",
        "440281106": "梅花镇",
        "440281107": "三溪镇",
        "440281108": "坪石镇",
        "440281110": "黄圃镇",
        "440281111": "五山镇",
        "440281112": "两江镇",
        "440281113": "沙坪镇",
        "440281114": "云岩镇",
        "440281115": "秀水镇",
        "440281117": "大源镇",
        "440281118": "庆云镇",
        "440281119": "白石镇",
        "440281451": "坪石",
        "440281452": "梅田"
    },
    "440282": {
        "440282001": "雄州街道",
        "440282100": "乌迳镇",
        "440282103": "界址镇",
        "440282104": "坪田镇",
        "440282105": "黄坑镇",
        "440282106": "邓坊镇",
        "440282107": "油山镇",
        "440282109": "南亩镇",
        "440282110": "水口镇",
        "440282111": "江头镇",
        "440282112": "湖口镇",
        "440282113": "珠玑镇",
        "440282115": "主田镇",
        "440282116": "古市镇",
        "440282118": "全安镇",
        "440282120": "百顺镇",
        "440282121": "澜河镇",
        "440282122": "帽子峰镇",
        "440282400": "东莞大岭山（南雄）产业转移工业园"
    },
    "440300": {
        "440303": "罗湖区",
        "440304": "福田区",
        "440305": "南山区",
        "440306": "宝安区",
        "440307": "龙岗区",
        "440308": "盐田区",
        "440309": "龙华区",
        "440310": "坪山区",
        "440311": "光明区"
    },
    "440303": {
        "440303001": "桂园街道",
        "440303002": "黄贝街道",
        "440303003": "东门街道",
        "440303004": "翠竹街道",
        "440303005": "南湖街道",
        "440303006": "笋岗街道",
        "440303007": "东湖街道",
        "440303008": "莲塘街道",
        "440303009": "东晓街道",
        "440303010": "清水河街道"
    },
    "440304": {
        "440304001": "南园街道",
        "440304002": "园岭街道",
        "440304004": "福田街道",
        "440304005": "沙头街道",
        "440304006": "香蜜湖街道",
        "440304007": "梅林街道",
        "440304008": "莲花街道",
        "440304009": "华富街道",
        "440304010": "福保街道",
        "440304011": "华强北街道",
        "440304400": "福田保税区"
    },
    "440305": {
        "440305001": "南头街道",
        "440305002": "南山街道",
        "440305003": "沙河街道",
        "440305005": "蛇口街道",
        "440305006": "招商街道",
        "440305007": "粤海街道",
        "440305008": "桃源街道",
        "440305009": "西丽街道",
        "440305400": "前海合作区"
    },
    "440306": {
        "440306017": "新安街道",
        "440306018": "西乡街道",
        "440306019": "航城街道",
        "440306020": "福永街道",
        "440306021": "福海街道",
        "440306022": "沙井街道",
        "440306023": "新桥街道",
        "440306024": "松岗街道",
        "440306025": "燕罗街道",
        "440306026": "石岩街道",
        "440306400": "深圳市宝安国际机场"
    },
    "440307": {
        "440307003": "平湖街道",
        "440307006": "坪地街道",
        "440307009": "葵涌街道",
        "440307010": "大鹏街道",
        "440307011": "南澳街道",
        "440307012": "南湾街道",
        "440307013": "坂田街道",
        "440307014": "布吉街道",
        "440307015": "龙城街道",
        "440307016": "龙岗街道",
        "440307017": "横岗街道",
        "440307018": "吉华街道",
        "440307019": "宝龙街道",
        "440307020": "园山街道"
    },
    "440308": {
        "440308001": "梅沙街道",
        "440308002": "盐田街道",
        "440308003": "沙头角街道",
        "440308004": "海山街道",
        "440308400": "市保税区（沙头角）",
        "440308401": "市保税区（盐田港）"
    },
    "440309": {
        "440309001": "观湖街道",
        "440309002": "民治街道",
        "440309003": "龙华街道",
        "440309004": "大浪街道",
        "440309005": "福城街道",
        "440309006": "观澜街道"
    },
    "440310": {
        "440310001": "坪山街道",
        "440310002": "马峦街道",
        "440310003": "碧岭街道",
        "440310004": "石井街道",
        "440310005": "坑梓街道",
        "440310006": "龙田街道",
        "440310401": "深圳市大工业区"
    },
    "440311": {
        "440311001": "光明街道",
        "440311002": "公明街道",
        "440311003": "新湖街道",
        "440311004": "凤凰街道",
        "440311005": "玉塘街道",
        "440311006": "马田街道"
    },
    "440400": {"440402": "香洲区", "440403": "斗门区", "440404": "金湾区"},
    "440402": {
        "440402001": "翠香街道办",
        "440402002": "梅华街道办",
        "440402003": "前山街道办",
        "440402004": "吉大街道办",
        "440402005": "拱北街道办",
        "440402006": "香湾街道办",
        "440402007": "狮山街道办",
        "440402008": "湾仔街道办",
        "440402100": "唐家湾镇",
        "440402102": "南屏镇",
        "440402104": "横琴镇",
        "440402105": "桂山镇",
        "440402106": "万山镇",
        "440402107": "担杆镇",
        "440402401": "南屏科技园",
        "440402402": "保税区",
        "440402403": "前山商贸物流中心",
        "440402404": "洪湾商贸物流中心",
        "440402406": "金鼎工业园"
    },
    "440403": {
        "440403001": "白藤街道",
        "440403100": "莲洲镇",
        "440403103": "斗门镇",
        "440403105": "乾务镇",
        "440403106": "白蕉镇",
        "440403107": "井岸镇"
    },
    "440404": {
        "440404100": "三灶镇",
        "440404101": "南水镇",
        "440404103": "红旗镇",
        "440404104": "平沙镇",
        "440404400": "联港工业区",
        "440404401": "航空产业园"
    },
    "440500": {
        "440507": "龙湖区",
        "440511": "金平区",
        "440512": "濠江区",
        "440513": "潮阳区",
        "440514": "潮南区",
        "440515": "澄海区",
        "440523": "南澳县"
    },
    "440507": {
        "440507001": "金霞街道",
        "440507003": "珠池街道",
        "440507004": "新津街道",
        "440507005": "鸥汀街道",
        "440507008": "龙祥街道",
        "440507100": "外砂镇",
        "440507101": "新溪镇"
    },
    "440511": {
        "440511001": "石炮台街道",
        "440511002": "金砂街道",
        "440511003": "金厦街道",
        "440511004": "东方街道",
        "440511005": "大华街道",
        "440511006": "同益街道",
        "440511007": "光华街道",
        "440511008": "东墩街道",
        "440511009": "广厦街道",
        "440511010": "海安街道",
        "440511011": "新福街道",
        "440511012": "乌桥街道",
        "440511013": "岐山街道",
        "440511014": "永祥街道",
        "440511015": "鮀莲街道",
        "440511016": "鮀江街道",
        "440511017": "月浦街道"
    },
    "440512": {
        "440512001": "达濠街道",
        "440512002": "马窖街道",
        "440512003": "礐石街道",
        "440512004": "广澳街道",
        "440512005": "滨海街道",
        "440512006": "河浦街道",
        "440512007": "玉新街道"
    },
    "440513": {
        "440513001": "文光街道",
        "440513002": "棉北街道",
        "440513003": "城南街道",
        "440513004": "金浦街道",
        "440513100": "海门镇",
        "440513101": "河溪镇",
        "440513102": "和平镇",
        "440513103": "西胪镇",
        "440513104": "关埠镇",
        "440513106": "谷饶镇",
        "440513107": "贵屿镇",
        "440513108": "铜盂镇",
        "440513110": "金灶镇"
    },
    "440514": {
        "440514001": "峡山街道",
        "440514101": "井都镇",
        "440514103": "成田镇",
        "440514104": "司马浦镇",
        "440514105": "陈店镇",
        "440514106": "两英镇",
        "440514107": "仙城镇",
        "440514108": "胪岗镇",
        "440514109": "红场镇",
        "440514110": "雷岭镇",
        "440514112": "陇田镇"
    },
    "440515": {
        "440515001": "凤翔街道",
        "440515002": "广益街道",
        "440515003": "澄华街道",
        "440515100": "上华镇",
        "440515101": "隆都镇",
        "440515102": "莲下镇",
        "440515103": "莲上镇",
        "440515104": "溪南镇",
        "440515105": "东里镇",
        "440515106": "盐鸿镇",
        "440515107": "莲华镇"
    },
    "440523": {"440523100": "后宅镇", "440523101": "云澳镇", "440523102": "深澳镇"},
    "440600": {"440604": "禅城区", "440605": "南海区", "440606": "顺德区", "440607": "三水区", "440608": "高明区"},
    "440604": {"440604010": "石湾镇街道", "440604011": "张槎街道", "440604012": "祖庙街道", "440604100": "南庄镇"},
    "440605": {
        "440605011": "桂城街道",
        "440605121": "九江镇",
        "440605122": "西樵镇",
        "440605123": "丹灶镇",
        "440605124": "狮山镇",
        "440605125": "大沥镇",
        "440605126": "里水镇"
    },
    "440606": {
        "440606003": "伦教街道",
        "440606004": "勒流街道",
        "440606005": "大良街道",
        "440606006": "容桂街道",
        "440606101": "陈村镇",
        "440606102": "北滘镇",
        "440606103": "乐从镇",
        "440606104": "龙江镇",
        "440606105": "杏坛镇",
        "440606106": "均安镇"
    },
    "440607": {
        "440607001": "西南街道",
        "440607004": "云东海街道",
        "440607101": "大塘镇",
        "440607103": "乐平镇",
        "440607104": "白坭镇",
        "440607105": "芦苞镇",
        "440607106": "南山镇",
        "440607400": "省三水劳教所",
        "440607401": "省南丰劳教所",
        "440607402": "省女子劳教所",
        "440607403": "省第二戒毒劳教所",
        "440607404": "广州军区三水农场"
    },
    "440608": {
        "440608004": "荷城街道",
        "440608106": "杨和镇",
        "440608107": "明城镇",
        "440608108": "更合镇",
        "440608400": "高明监狱",
        "440608401": "云勇林场",
        "440608402": "对川茶场",
        "440608403": "佛山监狱"
    },
    "440700": {
        "440703": "蓬江区",
        "440704": "江海区",
        "440705": "新会区",
        "440781": "台山市",
        "440783": "开平市",
        "440784": "鹤山市",
        "440785": "恩平市"
    },
    "440703": {
        "440703002": "白沙街道",
        "440703005": "潮连街道",
        "440703006": "环市街道",
        "440703101": "棠下镇",
        "440703102": "荷塘镇",
        "440703103": "杜阮镇"
    },
    "440704": {"440704001": "江南街道", "440704004": "外海街道", "440704005": "礼乐街道"},
    "440705": {
        "440705001": "会城街道办",
        "440705101": "大泽镇",
        "440705102": "司前镇",
        "440705103": "罗坑镇",
        "440705104": "双水镇",
        "440705105": "崖门镇",
        "440705106": "沙堆镇",
        "440705107": "古井镇",
        "440705108": "三江镇",
        "440705109": "睦洲镇",
        "440705110": "大鳌镇",
        "440705450": "圭峰管理委员会",
        "440705451": "银湖湾管理委员会",
        "440705470": "新会经济开发区管理委员会"
    },
    "440781": {
        "440781001": "台城街道",
        "440781100": "大江镇",
        "440781101": "水步镇",
        "440781102": "四九镇",
        "440781103": "白沙镇",
        "440781104": "三合镇",
        "440781105": "冲蒌镇",
        "440781106": "斗山镇",
        "440781107": "都斛镇",
        "440781108": "赤溪镇",
        "440781109": "端芬镇",
        "440781110": "广海镇",
        "440781111": "海宴镇",
        "440781112": "汶村镇",
        "440781113": "深井镇",
        "440781114": "北陡镇",
        "440781115": "川岛镇"
    },
    "440783": {
        "440783001": "三埠街道",
        "440783002": "长沙街道",
        "440783103": "沙塘镇",
        "440783104": "苍城镇",
        "440783105": "龙胜镇",
        "440783106": "大沙镇",
        "440783107": "马冈镇",
        "440783108": "塘口镇",
        "440783109": "赤坎镇",
        "440783110": "百合镇",
        "440783111": "蚬冈镇",
        "440783112": "金鸡镇",
        "440783115": "月山镇",
        "440783116": "赤水镇",
        "440783117": "水口镇",
        "440783400": "翠山湖新区"
    },
    "440784": {
        "440784001": "沙坪街道",
        "440784101": "龙口镇",
        "440784102": "雅瑶镇",
        "440784103": "古劳镇",
        "440784104": "桃源镇",
        "440784105": "鹤城镇",
        "440784106": "共和镇",
        "440784107": "址山镇",
        "440784108": "宅梧镇",
        "440784112": "双合镇",
        "440784401": "广东省江门监狱",
        "440784402": "江门市四堡林场"
    },
    "440785": {
        "440785001": "恩城街道",
        "440785100": "横陂镇",
        "440785101": "圣堂镇",
        "440785102": "良西镇",
        "440785103": "沙湖镇",
        "440785104": "牛江镇",
        "440785105": "君堂镇",
        "440785106": "大田镇",
        "440785107": "那吉镇",
        "440785108": "大槐镇",
        "440785109": "东成镇",
        "440785400": "恩平园区管委会"
    },
    "440800": {
        "440802": "赤坎区",
        "440803": "霞山区",
        "440804": "坡头区",
        "440811": "麻章区",
        "440823": "遂溪县",
        "440825": "徐闻县",
        "440881": "廉江市",
        "440882": "雷州市",
        "440883": "吴川市"
    },
    "440802": {
        "440802001": "中华街道",
        "440802002": "寸金街道",
        "440802003": "民主街道",
        "440802004": "中山街道",
        "440802005": "沙湾街道",
        "440802006": "调顺街道",
        "440802007": "南桥街道",
        "440802008": "北桥街道"
    },
    "440803": {
        "440803001": "解放街道",
        "440803002": "爱国街道",
        "440803003": "工农街道",
        "440803004": "友谊街道",
        "440803005": "新兴街道",
        "440803006": "海滨街道",
        "440803009": "建设街道",
        "440803010": "东新街道",
        "440803011": "新园街道",
        "440803012": "海头街道",
        "440803070": "泉庄街道",
        "440803071": "乐华街道"
    },
    "440804": {
        "440804001": "南调街道",
        "440804002": "麻斜街道",
        "440804100": "南三镇",
        "440804101": "坡头镇",
        "440804102": "乾塘镇",
        "440804103": "龙头镇",
        "440804104": "官渡镇",
        "440804401": "湛江市坡头区官渡工业园"
    },
    "440811": {
        "440811070": "东山街道办",
        "440811071": "东简街道办",
        "440811072": "民安街道办",
        "440811100": "麻章镇",
        "440811101": "太平镇",
        "440811102": "湖光镇",
        "440811173": "硇洲镇",
        "440811450": "湖光农场"
    },
    "440823": {
        "440823100": "遂城镇",
        "440823101": "黄略镇",
        "440823102": "洋青镇",
        "440823103": "界炮镇",
        "440823104": "乐民镇",
        "440823105": "江洪镇",
        "440823106": "杨柑镇",
        "440823107": "城月镇",
        "440823108": "乌塘镇",
        "440823109": "建新镇",
        "440823110": "岭北镇",
        "440823111": "北坡镇",
        "440823112": "港门镇",
        "440823113": "草潭镇",
        "440823114": "河头镇",
        "440823450": "广前公司"
    },
    "440825": {
        "440825001": "徐城街道办",
        "440825102": "迈陈镇",
        "440825103": "海安镇",
        "440825104": "曲界镇",
        "440825105": "前山镇",
        "440825106": "西连镇",
        "440825107": "下桥镇",
        "440825108": "龙塘镇",
        "440825109": "下洋镇",
        "440825110": "锦和镇",
        "440825111": "和安镇",
        "440825112": "新寮镇",
        "440825113": "南山镇",
        "440825201": "城北乡",
        "440825204": "角尾乡",
        "440825451": "南华农场",
        "440825452": "五一农场",
        "440825453": "华海公司",
        "440825454": "友好农场",
        "440825455": "红星农场"
    },
    "440881": {
        "440881001": "罗州街道办",
        "440881002": "城南街道办",
        "440881003": "城北街道办",
        "440881101": "石城镇",
        "440881102": "新民镇",
        "440881103": "吉水镇",
        "440881104": "河唇镇",
        "440881105": "石角镇",
        "440881106": "良垌镇",
        "440881107": "横山镇",
        "440881108": "安铺镇",
        "440881109": "营仔镇",
        "440881110": "青平镇",
        "440881111": "车板镇",
        "440881112": "高桥镇",
        "440881113": "石岭镇",
        "440881114": "雅塘镇",
        "440881115": "石颈镇",
        "440881116": "长山镇",
        "440881117": "塘蓬镇",
        "440881118": "和寮镇",
        "440881401": "九洲江开发区"
    },
    "440882": {
        "440882001": "雷城街道办",
        "440882002": "西湖街道办",
        "440882003": "新城街道办",
        "440882100": "白沙镇",
        "440882101": "沈塘镇",
        "440882102": "客路镇",
        "440882103": "杨家镇",
        "440882104": "唐家镇",
        "440882105": "企水镇",
        "440882106": "纪家镇",
        "440882107": "松竹镇",
        "440882108": "南兴镇",
        "440882109": "雷高镇",
        "440882110": "东里镇",
        "440882111": "调风镇",
        "440882112": "龙门镇",
        "440882113": "英利镇",
        "440882114": "北和镇",
        "440882115": "乌石镇",
        "440882116": "覃斗镇",
        "440882117": "附城镇",
        "440882450": "湛江奋勇高新技术产业开发区管理委员会",
        "440882451": "广东省丰收糖业发展有限公司",
        "440882452": "广东省火炬农场",
        "440882453": "广东省幸福农场",
        "440882454": "湛江农垦东方红农场",
        "440882455": "湛江农垦金星农场"
    },
    "440883": {
        "440883001": "梅录街道",
        "440883002": "塘尾街道",
        "440883003": "大山江街道",
        "440883004": "博铺街道",
        "440883005": "海滨街道",
        "440883100": "浅水镇",
        "440883101": "长岐镇",
        "440883102": "覃巴镇",
        "440883103": "王村港镇",
        "440883104": "振文镇",
        "440883105": "樟铺镇",
        "440883106": "吴阳镇",
        "440883107": "塘缀镇",
        "440883109": "黄坡镇",
        "440883111": "兰石镇"
    },
    "440900": {"440902": "茂南区", "440904": "电白区", "440981": "高州市", "440982": "化州市", "440983": "信宜市"},
    "440902": {
        "440902001": "红旗街道办",
        "440902002": "河西街道办",
        "440902003": "河东街道办",
        "440902004": "露天矿街道办",
        "440902005": "新华街道办",
        "440902006": "官渡街道办",
        "440902007": "站前街道办",
        "440902100": "金塘镇",
        "440902101": "公馆镇",
        "440902102": "新坡镇",
        "440902103": "镇盛镇",
        "440902104": "鳌头镇",
        "440902105": "袂花镇",
        "440902106": "高山镇",
        "440902107": "山阁镇",
        "440902108": "羊角镇",
        "440902400": "茂南开发区"
    },
    "440904": {
        "440904001": "南海街道",
        "440904002": "高地街道",
        "440904100": "马踏镇",
        "440904101": "岭门镇",
        "440904102": "坡心镇",
        "440904103": "七迳镇",
        "440904104": "树仔镇",
        "440904105": "沙院镇",
        "440904106": "麻岗镇",
        "440904107": "旦场镇",
        "440904109": "小良镇",
        "440904115": "霞洞镇",
        "440904116": "观珠镇",
        "440904117": "沙琅镇",
        "440904118": "黄岭镇",
        "440904119": "望夫镇",
        "440904120": "罗坑镇",
        "440904121": "那霍镇",
        "440904123": "水东镇",
        "440904125": "博贺镇",
        "440904126": "林头镇",
        "440904127": "电城镇",
        "440904128": "陈村镇",
        "440904451": "曙光农场",
        "440904452": "水丰农场",
        "440904453": "新华农场",
        "440904454": "电白盐场"
    },
    "440981": {
        "440981002": "石仔岭街道",
        "440981003": "山美街道",
        "440981006": "金山街道",
        "440981007": "潘州街道",
        "440981008": "宝光街道",
        "440981100": "谢鸡镇",
        "440981101": "新垌镇",
        "440981102": "云潭镇",
        "440981103": "分界镇",
        "440981104": "根子镇",
        "440981105": "泗水镇",
        "440981107": "镇江镇",
        "440981109": "沙田镇",
        "440981110": "南塘镇",
        "440981111": "荷花镇",
        "440981112": "石板镇",
        "440981115": "大井镇",
        "440981116": "潭头镇",
        "440981119": "大坡镇",
        "440981120": "平山镇",
        "440981121": "深镇镇",
        "440981122": "马贵镇",
        "440981123": "古丁镇",
        "440981124": "曹江镇",
        "440981126": "荷塘镇",
        "440981127": "石鼓镇",
        "440981128": "东岸镇",
        "440981129": "长坡镇",
        "440981450": "团结农场",
        "440981451": "胜利农场",
        "440981452": "火星农场"
    },
    "440982": {
        "440982001": "河西街道",
        "440982002": "东山街道",
        "440982003": "下郭街道",
        "440982004": "南盛街道",
        "440982005": "石湾街道",
        "440982100": "长岐镇",
        "440982101": "同庆镇",
        "440982102": "杨梅镇",
        "440982103": "良光镇",
        "440982104": "笪桥镇",
        "440982106": "丽岗镇",
        "440982107": "新安镇",
        "440982108": "官桥镇",
        "440982110": "林尘镇",
        "440982112": "合江镇",
        "440982113": "那务镇",
        "440982114": "播扬镇",
        "440982115": "宝圩镇",
        "440982116": "平定镇",
        "440982117": "文楼镇",
        "440982118": "江湖镇",
        "440982120": "中垌镇",
        "440982400": "鉴江开发区",
        "440982401": "新时代农场",
        "440982402": "新华农场",
        "440982403": "和平农场",
        "440982404": "红阳农场",
        "440982405": "红峰农场",
        "440982406": "建设农场",
        "440982407": "石滩农场"
    },
    "440983": {
        "440983001": "东镇街道办",
        "440983101": "镇隆镇",
        "440983102": "水口镇",
        "440983105": "丁堡镇",
        "440983106": "池洞镇",
        "440983112": "贵子镇",
        "440983113": "怀乡镇",
        "440983114": "茶山镇",
        "440983115": "洪冠镇",
        "440983116": "白石镇",
        "440983117": "大成镇",
        "440983118": "钱排镇",
        "440983119": "合水镇",
        "440983120": "新宝镇",
        "440983121": "平塘镇",
        "440983122": "思贺镇",
        "440983123": "金垌镇",
        "440983124": "朱砂镇",
        "440983125": "北界镇",
        "440983400": "信宜市国营红旗农场"
    },
    "441200": {
        "441202": "端州区",
        "441203": "鼎湖区",
        "441204": "高要区",
        "441223": "广宁县",
        "441224": "怀集县",
        "441225": "封开县",
        "441226": "德庆县",
        "441284": "四会市"
    },
    "441202": {"441202001": "端州区城东街道", "441202003": "端州区城西街道", "441202005": "端州区黄岗街道", "441202006": "端州区睦岗街道"},
    "441203": {
        "441203001": "坑口街道",
        "441203002": "桂城街道",
        "441203003": "广利街道",
        "441203101": "永安镇",
        "441203102": "沙浦镇",
        "441203103": "凤凰镇",
        "441203104": "莲花镇"
    },
    "441204": {
        "441204001": "南岸街道",
        "441204100": "河台镇",
        "441204101": "乐城镇",
        "441204102": "水南镇",
        "441204103": "禄步镇",
        "441204105": "小湘镇",
        "441204106": "大湾镇",
        "441204107": "新桥镇",
        "441204108": "白诸镇",
        "441204109": "莲塘镇",
        "441204110": "活道镇",
        "441204111": "蛟塘镇",
        "441204112": "回龙镇",
        "441204113": "白土镇",
        "441204114": "金渡镇",
        "441204115": "金利镇",
        "441204116": "蚬岗镇"
    },
    "441223": {
        "441223100": "排沙镇",
        "441223102": "潭布镇",
        "441223103": "江屯镇",
        "441223105": "螺岗镇",
        "441223106": "北市镇",
        "441223107": "坑口镇",
        "441223108": "赤坑镇",
        "441223109": "南街镇",
        "441223110": "宾亨镇",
        "441223111": "五和镇",
        "441223112": "横山镇",
        "441223113": "木格镇",
        "441223114": "石咀镇",
        "441223115": "古水镇",
        "441223116": "洲仔镇"
    },
    "441224": {
        "441224100": "怀城镇",
        "441224101": "闸岗镇",
        "441224102": "坳仔镇",
        "441224103": "汶朗镇",
        "441224104": "甘洒镇",
        "441224105": "凤岗镇",
        "441224106": "洽水镇",
        "441224107": "梁村镇",
        "441224108": "大岗镇",
        "441224109": "岗坪镇",
        "441224110": "冷坑镇",
        "441224111": "马宁镇",
        "441224112": "蓝钟镇",
        "441224113": "永固镇",
        "441224114": "诗洞镇",
        "441224115": "桥头镇",
        "441224116": "中洲镇",
        "441224118": "连麦镇",
        "441224200": "下帅壮族瑶族乡"
    },
    "441225": {
        "441225100": "江口镇",
        "441225101": "江川镇",
        "441225102": "白垢镇",
        "441225103": "大洲镇",
        "441225104": "渔涝镇",
        "441225105": "河儿口镇",
        "441225106": "连都镇",
        "441225107": "杏花镇",
        "441225108": "罗董镇",
        "441225109": "长岗镇",
        "441225110": "平凤镇",
        "441225111": "南丰镇",
        "441225112": "大玉口镇",
        "441225113": "都平镇",
        "441225114": "金装镇",
        "441225115": "长安镇"
    },
    "441226": {
        "441226001": "德城街道",
        "441226101": "新圩镇",
        "441226102": "回龙镇",
        "441226103": "官圩镇",
        "441226105": "马圩镇",
        "441226106": "高良镇",
        "441226107": "莫村镇",
        "441226109": "永丰镇",
        "441226110": "武垄镇",
        "441226111": "播植镇",
        "441226112": "凤村镇",
        "441226113": "悦城镇",
        "441226114": "九市镇"
    },
    "441284": {
        "441284001": "城中街道",
        "441284002": "东城街道",
        "441284003": "贞山街道",
        "441284100": "龙甫镇",
        "441284101": "地豆镇",
        "441284102": "威整镇",
        "441284103": "罗源镇",
        "441284104": "迳口镇",
        "441284105": "大沙镇",
        "441284107": "石狗镇",
        "441284108": "黄田镇",
        "441284110": "江谷镇",
        "441284113": "下茆镇",
        "441284400": "广东省济广监狱",
        "441284450": "肇庆高新技术产业开发区"
    },
    "441300": {"441302": "惠城区", "441303": "惠阳区", "441322": "博罗县", "441323": "惠东县", "441324": "龙门县"},
    "441302": {
        "441302001": "桥东街道",
        "441302002": "桥西街道",
        "441302003": "江南街道",
        "441302004": "江北街道",
        "441302005": "龙丰街道",
        "441302006": "河南岸街道",
        "441302007": "惠环街道",
        "441302008": "陈江街道",
        "441302009": "水口街道",
        "441302010": "小金口街道",
        "441302100": "汝湖镇",
        "441302103": "三栋镇",
        "441302106": "潼湖镇",
        "441302107": "沥林镇",
        "441302109": "马安镇",
        "441302110": "横沥镇",
        "441302112": "芦洲镇",
        "441302114": "潼侨镇",
        "441302401": "东江高新科技产业园",
        "441302402": "惠南高新科技产业园",
        "441302450": "惠州市林场"
    },
    "441303": {
        "441303001": "淡水街道",
        "441303002": "秋长街道",
        "441303003": "惠阳区三和街道",
        "441303006": "大亚湾澳头",
        "441303007": "大亚湾霞涌",
        "441303008": "大亚湾西区",
        "441303101": "沙田镇",
        "441303103": "新圩镇",
        "441303104": "镇隆镇",
        "441303108": "永湖镇",
        "441303109": "良井镇",
        "441303110": "平潭镇"
    },
    "441322": {
        "441322001": "罗阳街道",
        "441322002": "龙溪街道",
        "441322100": "石坝镇",
        "441322102": "麻陂镇",
        "441322103": "观音阁镇",
        "441322104": "公庄镇",
        "441322105": "杨村镇",
        "441322106": "柏塘镇",
        "441322108": "泰美镇",
        "441322113": "湖镇镇",
        "441322115": "长宁镇",
        "441322116": "福田镇",
        "441322117": "龙华镇",
        "441322119": "园洲镇",
        "441322121": "石湾镇",
        "441322122": "杨侨镇",
        "441322123": "横河镇",
        "441322570": "罗浮山管委会"
    },
    "441323": {
        "441323001": "平山街道",
        "441323002": "大岭街道办",
        "441323102": "白花镇",
        "441323103": "梁化镇",
        "441323104": "稔山镇",
        "441323105": "铁涌镇",
        "441323106": "平海镇",
        "441323109": "吉隆镇",
        "441323110": "黄埠镇",
        "441323113": "多祝镇",
        "441323115": "安墩镇",
        "441323118": "高潭镇",
        "441323120": "宝口镇",
        "441323121": "白盆珠镇",
        "441323450": "广东省惠州市国营惠东梁化林场",
        "441323451": "广东省惠州市国营惠东九龙峰林场",
        "441323452": "惠东县港口海龟湾自然保护区",
        "441323453": "莲花山白盆珠省级自然保护区",
        "441323454": "广东惠东古田省级自然保护区",
        "441323455": "惠东县珠三角产业转移园",
        "441323570": "巺寮滨海旅游渡假区管理委员会"
    },
    "441324": {
        "441324001": "龙城街道",
        "441324002": "平陵街道",
        "441324100": "麻榨镇",
        "441324101": "永汉镇",
        "441324115": "龙田镇",
        "441324116": "龙潭镇",
        "441324117": "地派镇",
        "441324118": "龙华镇",
        "441324119": "龙江镇",
        "441324200": "蓝田瑶族乡",
        "441324453": "龙门县密溪林场",
        "441324570": "南昆山生态旅游区管理委员会"
    },
    "441400": {
        "441402": "梅江区",
        "441403": "梅县区",
        "441422": "大埔县",
        "441423": "丰顺县",
        "441424": "五华县",
        "441426": "平远县",
        "441427": "蕉岭县",
        "441481": "兴宁市"
    },
    "441402": {
        "441402004": "江南街道",
        "441402006": "金山街道",
        "441402007": "西郊街道",
        "441402102": "三角镇",
        "441402103": "长沙镇",
        "441402105": "城北镇",
        "441402106": "西阳镇"
    },
    "441403": {
        "441403001": "新城",
        "441403101": "城东镇",
        "441403102": "石扇镇",
        "441403103": "梅西镇",
        "441403104": "大坪镇",
        "441403105": "石坑镇",
        "441403109": "水车镇",
        "441403110": "梅南镇",
        "441403113": "丙村镇",
        "441403115": "白渡镇",
        "441403116": "松源镇",
        "441403117": "隆文镇",
        "441403118": "桃尧镇",
        "441403126": "畲江镇",
        "441403129": "雁洋镇",
        "441403130": "松口镇",
        "441403131": "南口镇",
        "441403132": "程江镇",
        "441403133": "扶大镇",
        "441403500": "梅西水库"
    },
    "441422": {
        "441422100": "湖寮镇",
        "441422103": "青溪镇",
        "441422105": "三河镇",
        "441422108": "银江镇",
        "441422109": "洲瑞镇",
        "441422112": "光德镇",
        "441422113": "桃源镇",
        "441422116": "百侯镇",
        "441422117": "大东镇",
        "441422121": "大麻镇",
        "441422122": "枫朗镇",
        "441422123": "茶阳镇",
        "441422124": "高陂镇",
        "441422125": "西河镇",
        "441422400": "丰溪林场",
        "441422401": "洲瑞林场",
        "441422402": "大埔林场"
    },
    "441423": {
        "441423103": "北斗镇",
        "441423104": "汤西镇",
        "441423105": "汤南镇",
        "441423106": "埔寨镇",
        "441423110": "建桥镇",
        "441423111": "龙岗镇",
        "441423112": "潘田镇",
        "441423114": "黄金镇",
        "441423122": "小胜镇",
        "441423123": "砂田镇",
        "441423124": "八乡山镇",
        "441423125": "丰良镇",
        "441423126": "潭江镇",
        "441423127": "汤坑镇",
        "441423128": "留隍镇",
        "441423129": "大龙华镇",
        "441423450": "埔寨农场"
    },
    "441424": {
        "441424103": "转水镇",
        "441424108": "潭下镇",
        "441424117": "郭田镇",
        "441424118": "双华镇",
        "441424125": "梅林镇",
        "441424126": "华阳镇",
        "441424130": "华城镇",
        "441424131": "周江镇",
        "441424134": "水寨镇",
        "441424135": "河东镇",
        "441424136": "岐岭镇",
        "441424137": "长布镇",
        "441424138": "横陂镇",
        "441424139": "安流镇",
        "441424140": "棉洋镇",
        "441424141": "龙村镇"
    },
    "441426": {
        "441426101": "石正镇",
        "441426104": "八尺镇",
        "441426105": "差干镇",
        "441426107": "河头镇",
        "441426108": "中行镇",
        "441426109": "上举镇",
        "441426110": "泗水镇",
        "441426114": "长田镇",
        "441426115": "热柘镇",
        "441426117": "东石镇",
        "441426118": "仁居镇",
        "441426119": "大柘镇"
    },
    "441427": {
        "441427103": "三圳镇",
        "441427106": "文福镇",
        "441427107": "广福镇",
        "441427112": "新铺镇",
        "441427113": "蓝坊镇",
        "441427114": "南礤镇",
        "441427115": "蕉城镇",
        "441427116": "长潭镇",
        "441427450": "华侨农场"
    },
    "441481": {
        "441481001": "兴田街道",
        "441481002": "福兴街道",
        "441481003": "宁新街道",
        "441481107": "永和镇",
        "441481111": "新圩镇",
        "441481115": "罗浮镇",
        "441481116": "罗岗镇",
        "441481117": "黄槐镇",
        "441481123": "龙田镇",
        "441481124": "石马镇",
        "441481128": "宁中镇",
        "441481129": "径南镇",
        "441481130": "坭陂镇",
        "441481131": "水口镇",
        "441481132": "黄陂镇",
        "441481133": "合水镇",
        "441481134": "大坪镇",
        "441481135": "叶塘镇",
        "441481136": "新陂镇",
        "441481137": "刁坊镇"
    },
    "441500": {"441502": "城区", "441521": "海丰县", "441523": "陆河县", "441581": "陆丰市"},
    "441502": {
        "441502001": "新港街道",
        "441502002": "香洲街道",
        "441502003": "凤山街道",
        "441502004": "田墘街道",
        "441502005": "东洲街道",
        "441502006": "遮浪街道",
        "441502007": "马宫街道",
        "441502100": "红草镇",
        "441502102": "东涌镇",
        "441502103": "捷胜镇"
    },
    "441521": {
        "441521101": "梅陇镇",
        "441521102": "小漠镇",
        "441521105": "鮜门镇",
        "441521106": "联安镇",
        "441521107": "陶河镇",
        "441521108": "赤坑镇",
        "441521109": "大湖镇",
        "441521110": "可塘镇",
        "441521112": "黄羌镇",
        "441521113": "平东镇",
        "441521115": "海城镇",
        "441521116": "鹅埠镇",
        "441521117": "赤石镇",
        "441521118": "公平镇",
        "441521119": "附城镇",
        "441521120": "城东镇",
        "441521450": "梅陇农场"
    },
    "441523": {
        "441523100": "河田镇",
        "441523101": "水唇镇",
        "441523102": "河口镇",
        "441523103": "新田镇",
        "441523104": "上护镇",
        "441523105": "螺溪镇",
        "441523106": "东坑镇",
        "441523107": "南万镇"
    },
    "441581": {
        "441581001": "东海街道",
        "441581002": "河西街道",
        "441581003": "城东街道",
        "441581101": "甲子镇",
        "441581102": "碣石镇",
        "441581103": "湖东镇",
        "441581104": "大安镇",
        "441581105": "博美镇",
        "441581106": "内湖镇",
        "441581107": "南塘镇",
        "441581108": "陂洋镇",
        "441581109": "八万镇",
        "441581110": "金厢镇",
        "441581111": "潭西镇",
        "441581112": "甲东镇",
        "441581115": "河东镇",
        "441581116": "上英镇",
        "441581117": "桥冲镇",
        "441581118": "甲西镇",
        "441581119": "西南镇",
        "441581400": "华侨管理区",
        "441581450": "铜锣湖农场",
        "441581451": "星都经济开发试验区",
        "441581452": "大安农场",
        "441581453": "罗经嶂林场",
        "441581454": "湖东林场",
        "441581455": "东海岸林场",
        "441581456": "红岭林场",
        "441581457": "畜牧果林场"
    },
    "441600": {"441602": "源城区", "441621": "紫金县", "441622": "龙川县", "441623": "连平县", "441624": "和平县", "441625": "东源县"},
    "441602": {
        "441602001": "上城街道",
        "441602002": "新江街道",
        "441602003": "东埔街道",
        "441602004": "源西街道",
        "441602005": "高埔岗街道",
        "441602006": "城东街道",
        "441602100": "源南镇",
        "441602101": "埔前镇"
    },
    "441621": {
        "441621100": "紫城镇",
        "441621102": "龙窝镇",
        "441621103": "九和镇",
        "441621104": "上义镇",
        "441621105": "蓝塘镇",
        "441621106": "凤安镇",
        "441621107": "义容镇",
        "441621108": "古竹镇",
        "441621109": "临江镇",
        "441621110": "柏埔镇",
        "441621111": "黄塘镇",
        "441621112": "敬梓镇",
        "441621114": "水墩镇",
        "441621115": "南岭镇",
        "441621116": "苏区镇",
        "441621117": "瓦溪镇",
        "441621118": "好义镇",
        "441621119": "中坝镇"
    },
    "441622": {
        "441622100": "老隆镇",
        "441622102": "义都镇",
        "441622103": "佗城镇",
        "441622104": "鹤市镇",
        "441622105": "黄布镇",
        "441622106": "紫市镇",
        "441622107": "通衢镇",
        "441622109": "登云镇",
        "441622110": "丰稔镇",
        "441622111": "四都镇",
        "441622112": "铁场镇",
        "441622114": "龙母镇",
        "441622115": "田心镇",
        "441622116": "黎咀镇",
        "441622117": "黄石镇",
        "441622118": "赤光镇",
        "441622119": "廻龙镇",
        "441622120": "新田镇",
        "441622121": "车田镇",
        "441622123": "岩镇镇",
        "441622124": "麻布岗镇",
        "441622125": "贝岭镇",
        "441622126": "细坳镇",
        "441622127": "上坪镇"
    },
    "441623": {
        "441623100": "元善镇",
        "441623101": "上坪镇",
        "441623102": "内莞镇",
        "441623104": "陂头镇",
        "441623105": "溪山镇",
        "441623107": "隆街镇",
        "441623108": "田源镇",
        "441623109": "油溪镇",
        "441623110": "忠信镇",
        "441623111": "高莞镇",
        "441623113": "大湖镇",
        "441623114": "三角镇",
        "441623115": "绣缎镇"
    },
    "441624": {
        "441624100": "阳明镇",
        "441624101": "大坝镇",
        "441624102": "长塘镇",
        "441624103": "下车镇",
        "441624104": "上陵镇",
        "441624105": "优胜镇",
        "441624106": "贝墩镇",
        "441624107": "古寨镇",
        "441624108": "彭寨镇",
        "441624109": "合水镇",
        "441624110": "公白镇",
        "441624111": "青州镇",
        "441624112": "浰源镇",
        "441624113": "热水镇",
        "441624114": "东水镇",
        "441624115": "礼士镇",
        "441624116": "林寨镇"
    },
    "441625": {
        "441625100": "仙塘镇",
        "441625101": "灯塔镇",
        "441625102": "骆湖镇",
        "441625103": "船塘镇",
        "441625104": "顺天镇",
        "441625105": "上莞镇",
        "441625106": "曾田镇",
        "441625107": "柳城镇",
        "441625108": "义合镇",
        "441625109": "蓝口镇",
        "441625110": "黄田镇",
        "441625111": "叶潭镇",
        "441625112": "黄村镇",
        "441625113": "康禾镇",
        "441625114": "锡场镇",
        "441625115": "新港镇",
        "441625116": "双江镇",
        "441625117": "涧头镇",
        "441625121": "新回龙镇",
        "441625122": "半江镇",
        "441625200": "漳溪乡"
    },
    "441700": {"441702": "江城区", "441704": "阳东区", "441721": "阳西县", "441781": "阳春市"},
    "441702": {
        "441702001": "南恩街道",
        "441702002": "城南街道",
        "441702003": "城北街道",
        "441702004": "中洲街道",
        "441702006": "城东街道",
        "441702008": "岗列街道",
        "441702009": "城西街道",
        "441702011": "白沙街道",
        "441702102": "埠场镇",
        "441702103": "平冈镇",
        "441702105": "闸坡镇",
        "441702106": "双捷镇",
        "441702401": "阳江林场罗琴分场",
        "441702450": "平冈农场",
        "441702453": "红十月农场"
    },
    "441704": {
        "441704100": "东城镇",
        "441704101": "北惯镇",
        "441704102": "那龙镇",
        "441704103": "东平镇",
        "441704104": "雅韶镇",
        "441704105": "大沟镇",
        "441704106": "新洲镇",
        "441704107": "合山镇",
        "441704108": "塘坪镇",
        "441704109": "大八镇",
        "441704110": "红丰镇",
        "441704400": "阳江监狱",
        "441704401": "红五月农场",
        "441704402": "鸡山农场",
        "441704501": "阳江林场宝山分场",
        "441704502": "阳江林场田畔分场",
        "441704503": "阳东原种场",
        "441704504": "阳东林场",
        "441704505": "阳江林场东岸分场"
    },
    "441721": {
        "441721100": "织篢镇",
        "441721101": "程村镇",
        "441721102": "塘口镇",
        "441721103": "上洋镇",
        "441721104": "溪头镇",
        "441721105": "沙扒镇",
        "441721106": "儒洞镇",
        "441721107": "新圩镇",
        "441721501": "织贡农场",
        "441721502": "阳江林场儒洞分场"
    },
    "441781": {
        "441781001": "春城街道",
        "441781002": "河西街道",
        "441781101": "河朗镇",
        "441781102": "松柏镇",
        "441781103": "石望镇",
        "441781105": "春湾镇",
        "441781106": "合水镇",
        "441781107": "陂面镇",
        "441781108": "圭岗镇",
        "441781109": "永宁镇",
        "441781110": "马水镇",
        "441781111": "岗美镇",
        "441781112": "河口镇",
        "441781113": "潭水镇",
        "441781114": "三甲镇",
        "441781116": "双窖镇",
        "441781117": "八甲镇",
        "441781402": "三叶农场",
        "441781403": "石录矿区",
        "441781404": "花滩林场",
        "441781405": "东湖林场",
        "441781406": "河尾山林场",
        "441781407": "南山矿",
        "441781408": "阳春监狱",
        "441781410": "硫铁矿"
    },
    "441800": {
        "441802": "清城区",
        "441803": "清新区",
        "441821": "佛冈县",
        "441823": "阳山县",
        "441825": "连山壮族瑶族自治县",
        "441826": "连南瑶族自治县",
        "441881": "英德市",
        "441882": "连州市"
    },
    "441802": {
        "441802006": "凤城街道",
        "441802007": "东城街道",
        "441802008": "洲心街道",
        "441802009": "横荷街道",
        "441802103": "源潭镇",
        "441802105": "龙塘镇",
        "441802106": "石角镇",
        "441802107": "飞来峡镇",
        "441802400": "清远市高新技术产业开发区",
        "441802450": "清远市国营银盏林场"
    },
    "441803": {
        "441803102": "太和镇",
        "441803104": "太平镇",
        "441803105": "山塘镇",
        "441803106": "三坑镇",
        "441803112": "龙颈镇",
        "441803113": "禾云镇",
        "441803117": "浸潭镇",
        "441803118": "石潭镇",
        "441803450": "笔架林场"
    },
    "441821": {
        "441821100": "石角镇",
        "441821101": "水头镇",
        "441821102": "汤塘镇",
        "441821103": "龙山镇",
        "441821104": "高岗镇",
        "441821107": "迳头镇"
    },
    "441823": {
        "441823100": "青莲镇",
        "441823101": "江英镇",
        "441823104": "杜步镇",
        "441823105": "七拱镇",
        "441823107": "太平镇",
        "441823108": "杨梅镇",
        "441823110": "大崀镇",
        "441823111": "小江镇",
        "441823113": "岭背镇",
        "441823115": "黄坌镇",
        "441823116": "黎埠镇",
        "441823119": "阳城镇",
        "441823201": "秤架瑶族乡"
    },
    "441825": {
        "441825100": "永和镇",
        "441825104": "吉田镇",
        "441825105": "太保镇",
        "441825106": "禾洞镇",
        "441825108": "福堂镇",
        "441825109": "小三江镇",
        "441825111": "上帅镇",
        "441825450": "连山林场",
        "441825451": "禾洞农林场"
    },
    "441826": {
        "441826100": "三江镇",
        "441826101": "大麦山镇",
        "441826102": "寨岗镇",
        "441826105": "三排镇",
        "441826106": "涡水镇",
        "441826108": "大坪镇",
        "441826109": "香坪镇"
    },
    "441881": {
        "441881001": "英城街道",
        "441881101": "沙口镇",
        "441881102": "望埠镇",
        "441881103": "横石水镇",
        "441881105": "桥头镇",
        "441881106": "青塘镇",
        "441881108": "白沙镇",
        "441881109": "大站镇",
        "441881110": "西牛镇",
        "441881111": "九龙镇",
        "441881112": "含光镇",
        "441881114": "大湾镇",
        "441881115": "石灰铺镇",
        "441881116": "石牯塘镇",
        "441881120": "下太镇",
        "441881124": "波罗镇",
        "441881125": "横石塘镇",
        "441881126": "大洞镇",
        "441881129": "连江口镇",
        "441881130": "黎溪镇",
        "441881131": "水边镇",
        "441881136": "英红镇",
        "441881137": "东华镇",
        "441881138": "黄花镇"
    },
    "441882": {
        "441882100": "连州镇",
        "441882101": "星子镇",
        "441882102": "大路边镇",
        "441882103": "龙坪镇",
        "441882104": "西岸镇",
        "441882105": "保安镇",
        "441882106": "丰阳镇",
        "441882107": "东陂镇",
        "441882109": "九陂镇",
        "441882118": "西江镇",
        "441882200": "瑶安瑶族乡",
        "441882201": "三水瑶族乡"
    },
    "441900": {
        "441901003": "东城街道",
        "441901004": "南城街道",
        "441901005": "万江街道",
        "441901006": "莞城街道",
        "441901101": "石碣镇",
        "441901102": "石龙镇",
        "441901103": "茶山镇",
        "441901104": "石排镇",
        "441901105": "企石镇",
        "441901106": "横沥镇",
        "441901107": "桥头镇",
        "441901108": "谢岗镇",
        "441901109": "东坑镇",
        "441901110": "常平镇",
        "441901111": "寮步镇",
        "441901112": "樟木头镇",
        "441901113": "大朗镇",
        "441901114": "黄江镇",
        "441901115": "清溪镇",
        "441901116": "塘厦镇",
        "441901117": "凤岗镇",
        "441901118": "大岭山镇",
        "441901119": "长安镇",
        "441901121": "虎门镇",
        "441901122": "厚街镇",
        "441901123": "沙田镇",
        "441901124": "道滘镇",
        "441901125": "洪梅镇",
        "441901126": "麻涌镇",
        "441901127": "望牛墩镇",
        "441901128": "中堂镇",
        "441901129": "高埗镇",
        "441901401": "松山湖管委会",
        "441901402": "虎门港管委会",
        "441901403": "东莞生态园"
    },
    "441900003": {
        "441900003001": "岗贝社区",
        "441900003002": "花园新村社区",
        "441900003003": "东泰社区",
        "441900003004": "温塘社区",
        "441900003005": "桑园社区",
        "441900003006": "周屋社区",
        "441900003007": "余屋社区",
        "441900003008": "鳌峙塘社区",
        "441900003009": "峡口社区",
        "441900003010": "柏洲边社区",
        "441900003011": "上桥社区",
        "441900003012": "下桥社区",
        "441900003013": "樟村社区",
        "441900003014": "梨川社区",
        "441900003015": "堑头社区",
        "441900003016": "主山社区",
        "441900003017": "石井社区",
        "441900003018": "同沙社区",
        "441900003019": "光明社区",
        "441900003020": "牛山社区",
        "441900003021": "立新社区",
        "441900003022": "火炼树社区居民委会",
        "441900003023": "星城社区",
        "441900003400": "黄旗生活区",
        "441900003401": "同沙生活区"
    },
    "441900004": {
        "441900004003": "鸿福社区",
        "441900004005": "元美社区",
        "441900004006": "胜和社区",
        "441900004007": "亨美社区",
        "441900004008": "三元里社区",
        "441900004009": "篁村社区",
        "441900004010": "新基社区",
        "441900004011": "周溪社区",
        "441900004012": "袁屋边社区",
        "441900004013": "白马社区",
        "441900004014": "石鼓社区",
        "441900004015": "蛤地社区",
        "441900004016": "西平社区",
        "441900004017": "水濂社区",
        "441900004018": "雅园社区",
        "441900004019": "新城社区",
        "441900004020": "宏远社区",
        "441900004021": "宏图社区"
    },
    "441900005": {
        "441900005001": "万江墟社区",
        "441900005002": "万江社区",
        "441900005003": "石美社区",
        "441900005004": "莫屋社区",
        "441900005005": "拔蛟窝社区",
        "441900005006": "黄粘洲社区",
        "441900005007": "蚬涌社区",
        "441900005008": "谷涌社区",
        "441900005009": "小享社区",
        "441900005010": "滘联社区",
        "441900005011": "金泰社区",
        "441900005012": "曲海社区",
        "441900005013": "牌楼基社区",
        "441900005014": "大莲塘社区",
        "441900005015": "水蛇涌社区",
        "441900005016": "共联社区",
        "441900005017": "新谷涌社区",
        "441900005018": "坝头社区",
        "441900005019": "胜利社区",
        "441900005020": "官桥滘社区",
        "441900005021": "简沙洲社区",
        "441900005022": "新和社区",
        "441900005023": "上甲社区",
        "441900005024": "严屋社区",
        "441900005025": "大汾社区",
        "441900005026": "新村社区",
        "441900005027": "流涌尾社区",
        "441900005028": "新城社区",
        "441900005029": "坝新社区"
    },
    "441900006": {
        "441900006001": "东正社区",
        "441900006002": "市桥社区",
        "441900006003": "北隅社区",
        "441900006004": "西隅社区",
        "441900006005": "罗沙社区",
        "441900006006": "博厦社区",
        "441900006008": "兴塘社区",
        "441900006009": "创业社区"
    },
    "441900101": {
        "441900101002": "城中社区",
        "441900101200": "唐洪村村民委员会",
        "441900101201": "水南村村民委员会",
        "441900101202": "石碣村村民委员会",
        "441900101203": "刘屋村村民委员会",
        "441900101204": "横滘村村民委员会",
        "441900101205": "鹤田厦村村民委员会",
        "441900101206": "四甲村村民委员会",
        "441900101207": "沙腰村村民委员会",
        "441900101208": "梁家村村民委员会",
        "441900101209": "桔洲村村民委员会",
        "441900101210": "单屋村村民委员会",
        "441900101211": "涌口村村民委员会",
        "441900101212": "西南村村民委员会",
        "441900101213": "黄泗围村村民委员会"
    },
    "441900102": {
        "441900102004": "中山东社区",
        "441900102005": "中山西社区",
        "441900102006": "兴龙社区",
        "441900102200": "忠维村村民委员会",
        "441900102201": "林屋村村民委员会",
        "441900102202": "新维村村民委员会",
        "441900102203": "蒲溪村村民委员会",
        "441900102204": "西湖村村民委员会",
        "441900102205": "王屋洲村村民委员会",
        "441900102206": "黄家山村村民委员会"
    },
    "441900103": {
        "441900103002": "茶山圩社区",
        "441900103003": "茶溪社区",
        "441900103200": "上元村村民委员会",
        "441900103201": "茶山村村民委员会",
        "441900103202": "下朗村村民委员会",
        "441900103203": "横江村村民委员会",
        "441900103204": "卢边村村民委员会",
        "441900103205": "寒溪水村村民委员会",
        "441900103206": "增埗村村民委员会",
        "441900103207": "南社村村民委员会",
        "441900103208": "塘角村村民委员会",
        "441900103209": "京山村村民委员会",
        "441900103210": "博头村村民委员会",
        "441900103211": "冲美村村民委员会",
        "441900103212": "粟边村村民委员会",
        "441900103213": "刘黄村村民委员会",
        "441900103214": "孙屋村村民委员会",
        "441900103215": "超朗村村民委员会"
    },
    "441900104": {
        "441900104002": "太和社区",
        "441900104200": "石排村村民委员会",
        "441900104201": "下沙村村民委员会",
        "441900104202": "庙边王村村民委员会",
        "441900104203": "福隆村村民委员会",
        "441900104204": "沙角村村民委员会",
        "441900104205": "黄家壆村村民委员会",
        "441900104206": "赤坎村村民委员会",
        "441900104207": "向西村村民委员会",
        "441900104208": "水贝村村民委员会",
        "441900104209": "田寮村村民委员会",
        "441900104210": "横山村村民委员会",
        "441900104211": "谷吓村村民委员会",
        "441900104212": "埔心村村民委员会",
        "441900104213": "塘尾村村民委员会",
        "441900104214": "李家坊村村民委员会",
        "441900104215": "田边村村民委员会",
        "441900104216": "中坑村村民委员会",
        "441900104217": "燕窝村村民委员会"
    },
    "441900105": {
        "441900105002": "宝石社区",
        "441900105200": "铁岗村村民委员会",
        "441900105201": "深巷村村民委员会",
        "441900105202": "湖美村村民委员会",
        "441900105203": "博厦村村民委员会",
        "441900105204": "上洞村村民委员会",
        "441900105205": "清湖村村民委员会",
        "441900105206": "江边村村民委员会",
        "441900105207": "旧围村村民委员会",
        "441900105208": "东平村村民委员会",
        "441900105209": "上截村村民委员会",
        "441900105210": "下截村村民委员会",
        "441900105211": "东山村村民委员会",
        "441900105212": "铁炉坑村村民委员会",
        "441900105213": "企石村村民委员会",
        "441900105214": "杨屋村村民委员会",
        "441900105215": "莫屋村村民委员会",
        "441900105216": "霞朗村村民委员会",
        "441900105217": "新南村村民委员会",
        "441900105218": "南坑村村民委员会"
    },
    "441900106": {
        "441900106002": "恒泉社区",
        "441900106200": "石涌村村民委员会",
        "441900106201": "隔坑村村民委员会",
        "441900106202": "半仙山村村民委员会",
        "441900106203": "横沥村村民委员会",
        "441900106204": "田头村村民委员会",
        "441900106205": "田坑村村民委员会",
        "441900106206": "村头村村民委员会",
        "441900106207": "长巷村村民委员会",
        "441900106208": "田饶步村村民委员会",
        "441900106209": "六甲村村民委员会",
        "441900106210": "村尾村村民委员会",
        "441900106211": "水边村村民委员会",
        "441900106212": "新四村村民委员会",
        "441900106213": "山厦村村民委员会",
        "441900106214": "月塘村村民委员会",
        "441900106215": "张坑村村民委员会"
    },
    "441900107": {
        "441900107002": "莲城社区",
        "441900107003": "桥头社区",
        "441900107004": "迳联社区",
        "441900107005": "田新社区",
        "441900107006": "岭头社区",
        "441900107007": "大洲社区",
        "441900107200": "东江村村民委员会",
        "441900107201": "山和村村民委员会",
        "441900107202": "屋厦村村民委员会",
        "441900107203": "岗头村村民委员会",
        "441900107204": "李屋村村民委员会",
        "441900107205": "邓屋村村民委员会",
        "441900107206": "朗厦村村民委员会",
        "441900107207": "邵岗头村村民委员会",
        "441900107212": "石水口村村民委员会",
        "441900107214": "禾坑村村民委员会",
        "441900107215": "田头角村村民委员会"
    },
    "441900108": {
        "441900108002": "泰园社区",
        "441900108200": "黎村村民委员会",
        "441900108201": "南面村村民委员会",
        "441900108202": "窑山村村民委员会",
        "441900108203": "大龙村村民委员会",
        "441900108204": "大厚村村民委员会",
        "441900108205": "谢山村村民委员会",
        "441900108206": "谢岗村村民委员会",
        "441900108207": "赵林村村民委员会",
        "441900108208": "曹乐村村民委员会",
        "441900108209": "五星村村民委员会",
        "441900108210": "稔子园村村民委员会"
    },
    "441900109": {
        "441900109002": "草塘社区",
        "441900109003": "骏达社区",
        "441900109200": "东坑村村民委员会",
        "441900109201": "黄麻岭村村民委员会",
        "441900109202": "长安塘村村民委员会",
        "441900109203": "寮边头村村民委员会",
        "441900109204": "塔岗村村民委员会",
        "441900109205": "坑美村村民委员会",
        "441900109206": "新门楼村村民委员会",
        "441900109207": "井美村村民委员会",
        "441900109208": "初坑村村民委员会",
        "441900109209": "凤大村村民委员会",
        "441900109210": "丁屋村村民委员会",
        "441900109211": "彭屋村村民委员会",
        "441900109212": "黄屋村村民委员会",
        "441900109213": "角社村村民委员会"
    },
    "441900110": {
        "441900110002": "常平社区",
        "441900110003": "新民社区",
        "441900110200": "下墟村村民委员会",
        "441900110201": "岗梓村村民委员会",
        "441900110202": "桥梓村村民委员会",
        "441900110203": "塘角村村民委员会",
        "441900110204": "苏坑村村民委员会",
        "441900110205": "袁山贝村村民委员会",
        "441900110206": "金美村村民委员会",
        "441900110207": "还珠沥村村民委员会",
        "441900110208": "朗贝村村民委员会",
        "441900110209": "板石村村民委员会",
        "441900110210": "桥沥村村民委员会",
        "441900110211": "卢屋村村民委员会",
        "441900110212": "土塘村村民委员会",
        "441900110213": "麦元村村民委员会",
        "441900110214": "九江水村村民委员会",
        "441900110215": "朗洲村村民委员会",
        "441900110216": "陈屋贝村村民委员会",
        "441900110217": "司马村村民委员会",
        "441900110218": "霞坑村村民委员会",
        "441900110219": "漱旧村村民委员会",
        "441900110220": "漱新村村民委员会",
        "441900110221": "黄泥塘村村民委员会",
        "441900110222": "元江元村村民委员会",
        "441900110223": "横江厦村村民委员会",
        "441900110224": "田尾村村民委员会",
        "441900110225": "白花沥村村民委员会",
        "441900110226": "沙湖口村村民委员会",
        "441900110227": "白石岗村村民委员会",
        "441900110228": "松柏塘村村民委员会",
        "441900110229": "上坑村村民委员会",
        "441900110230": "木棆村村民委员会"
    },
    "441900111": {
        "441900111002": "寮步社区",
        "441900111003": "塘边社区",
        "441900111004": "岭厦社区",
        "441900111005": "新旧围社区",
        "441900111006": "横坑社区",
        "441900111007": "牛杨社区",
        "441900111008": "泉塘社区",
        "441900111009": "坑口社区",
        "441900111010": "缪边社区",
        "441900111011": "良平社区",
        "441900111201": "西溪村村民委员会",
        "441900111202": "凫山村村民委员会",
        "441900111203": "石步村村民委员会",
        "441900111204": "良边村村民委员会",
        "441900111205": "富竹山村村民委员会",
        "441900111206": "塘唇村村民委员会",
        "441900111207": "向西村村民委员会",
        "441900111208": "下岭贝村村民委员会",
        "441900111211": "霞边村村民委员会",
        "441900111213": "竹园村村民委员会",
        "441900111214": "上屯村村民委员会",
        "441900111215": "石龙坑村村民委员会",
        "441900111219": "浮竹山村村民委员会",
        "441900111220": "上底村村民委员会",
        "441900111221": "刘屋巷村村民委员会",
        "441900111222": "药勒村村民委员会",
        "441900111223": "陈家埔村村民委员会",
        "441900111225": "小坑村村民委员会",
        "441900111226": "长坑村村民委员会",
        "441900111227": "井巷村村民委员会"
    },
    "441900112": {
        "441900112002": "圩镇社区",
        "441900112003": "樟罗社区",
        "441900112004": "百果洞社区",
        "441900112005": "樟洋社区",
        "441900112006": "石新社区",
        "441900112007": "柏地社区",
        "441900112008": "官仓社区",
        "441900112009": "裕丰社区",
        "441900112010": "金河社区",
        "441900112011": "樟新社区",
        "441900112400": "樟木头生活区"
    },
    "441900113": {
        "441900113002": "大朗社区",
        "441900113003": "竹山社区",
        "441900113004": "巷头社区",
        "441900113005": "巷尾社区",
        "441900113006": "求富路社区",
        "441900113007": "长塘社区",
        "441900113008": "大井头社区",
        "441900113009": "圣堂社区",
        "441900113010": "黄草朗社区",
        "441900113011": "佛新社区",
        "441900113012": "屏山社区",
        "441900113013": "长富社区",
        "441900113201": "高英村村民委员会",
        "441900113208": "蔡边村村民委员会",
        "441900113209": "水口村村民委员会",
        "441900113210": "洋乌村村民委员会",
        "441900113211": "洋坑塘村村民委员会",
        "441900113212": "松柏朗村村民委员会",
        "441900113214": "黎贝岭村村民委员会",
        "441900113215": "佛子凹村村民委员会",
        "441900113217": "松木山村村民委员会",
        "441900113218": "犀牛陂村村民委员会",
        "441900113219": "水平村村民委员会",
        "441900113221": "宝陂村村民委员会",
        "441900113222": "石厦村村民委员会",
        "441900113223": "杨涌村村民委员会",
        "441900113224": "沙步村村民委员会",
        "441900113225": "新马莲村村民委员会"
    },
    "441900114": {
        "441900114002": "新市社区",
        "441900114003": "三新社区",
        "441900114004": "田美社区",
        "441900114005": "北岸社区",
        "441900114006": "长龙社区",
        "441900114007": "梅塘社区",
        "441900114008": "宝山社区"
    },
    "441900115": {
        "441900115002": "清溪社区",
        "441900115200": "罗马村村民委员会",
        "441900115201": "长山头村村民委员会",
        "441900115202": "荔横村村民委员会",
        "441900115203": "浮岗村村民委员会",
        "441900115204": "松岗村村民委员会",
        "441900115205": "上元村村民委员会",
        "441900115206": "重河村村民委员会",
        "441900115207": "清厦村村民委员会",
        "441900115208": "铁松村村民委员会",
        "441900115209": "铁场村村民委员会",
        "441900115210": "九乡村村民委员会",
        "441900115211": "大埔村村民委员会",
        "441900115212": "大利村村民委员会",
        "441900115213": "渔樑围村村民委员会",
        "441900115214": "三星村村民委员会",
        "441900115215": "厦坭村村民委员会",
        "441900115216": "土桥村村民委员会",
        "441900115217": "谢坑村村民委员会",
        "441900115218": "三中村村民委员会",
        "441900115219": "青皇村村民委员会",
        "441900115400": "清溪生活区"
    },
    "441900116": {
        "441900116003": "塘厦社区",
        "441900116005": "林村社区",
        "441900116006": "莲湖社区",
        "441900116007": "石潭埔社区",
        "441900116008": "横塘社区",
        "441900116009": "莆心湖社区",
        "441900116010": "诸佛岭社区",
        "441900116011": "振兴围社区",
        "441900116012": "四村社区",
        "441900116013": "蛟乙塘社区",
        "441900116014": "大坪社区",
        "441900116015": "田心社区",
        "441900116016": "龙背岭社区",
        "441900116017": "石鼓社区",
        "441900116018": "平山社区",
        "441900116019": "桥陇社区",
        "441900116020": "凤凰岗社区",
        "441900116021": "沙湖社区",
        "441900116022": "石马社区",
        "441900116023": "清湖头社区",
        "441900116024": "塘新社区",
        "441900116400": "大屏障生活区",
        "441900116401": "东深局社区"
    },
    "441900117": {
        "441900117002": "凤岗社区",
        "441900117200": "雁田村村民委员会",
        "441900117201": "官井头村村民委员会",
        "441900117202": "油甘埔村村民委员会",
        "441900117203": "凤德岭村村民委员会",
        "441900117204": "塘沥村村民委员会",
        "441900117205": "黄洞村村民委员会",
        "441900117206": "竹塘村村民委员会",
        "441900117207": "竹尾田村村民委员会",
        "441900117208": "三联村村民委员会",
        "441900117209": "五联村村民委员会",
        "441900117210": "天堂围村村民委员会"
    },
    "441900118": {
        "441900118002": "大岭山社区",
        "441900118003": "农场社区",
        "441900118200": "马蹄岗村村民委员会",
        "441900118201": "大塘朗村村民委员会",
        "441900118202": "新塘村村民委员会",
        "441900118203": "元岭村村民委员会",
        "441900118204": "金桔村村民委员会",
        "441900118205": "鸡翅岭村村民委员会",
        "441900118206": "大沙村村民委员会",
        "441900118207": "旧飞鹅村村民委员会",
        "441900118208": "连平村村民委员会",
        "441900118209": "梅林村村民委员会",
        "441900118210": "下高田村村民委员会",
        "441900118211": "大环村村民委员会",
        "441900118212": "太公岭村村民委员会",
        "441900118213": "百花洞村村民委员会",
        "441900118214": "大片美村村民委员会",
        "441900118215": "矮岭冚村村民委员会",
        "441900118216": "大岭村村民委员会",
        "441900118218": "水朗村村民委员会",
        "441900118219": "大塘村村民委员会",
        "441900118220": "杨屋村村民委员会",
        "441900118221": "颜屋村村民委员会"
    },
    "441900119": {
        "441900119002": "长盛社区",
        "441900119003": "涌头社区",
        "441900119004": "霄边社区",
        "441900119005": "咸西社区",
        "441900119006": "锦厦社区",
        "441900119007": "新安社区",
        "441900119008": "乌沙社区",
        "441900119009": "新民社区",
        "441900119010": "沙头社区",
        "441900119011": "上沙社区",
        "441900119012": "厦岗社区",
        "441900119013": "厦边社区",
        "441900119014": "上角社区"
    },
    "441900121": {
        "441900121004": "东方社区",
        "441900121005": "则徐社区",
        "441900121006": "虎门寨社区",
        "441900121007": "树田社区",
        "441900121008": "怀德社区",
        "441900121009": "居歧社区",
        "441900121010": "村头社区",
        "441900121011": "陈村社区",
        "441900121012": "黄村社区",
        "441900121013": "大宁社区",
        "441900121014": "北栅社区",
        "441900121015": "龙眼社区",
        "441900121016": "赤岗社区",
        "441900121017": "博涌社区",
        "441900121018": "新联社区",
        "441900121019": "白沙社区",
        "441900121020": "镇口社区",
        "441900121021": "金洲社区",
        "441900121022": "小捷滘社区",
        "441900121023": "南栅社区",
        "441900121024": "东风社区",
        "441900121025": "宴岗社区",
        "441900121026": "路东社区",
        "441900121027": "沙角社区",
        "441900121028": "九门寨社区",
        "441900121029": "南面社区",
        "441900121030": "北面社区",
        "441900121031": "武山沙社区",
        "441900121035": "新湾社区",
        "441900121036": "民泰社区",
        "441900121400": "大岭山生活区",
        "441900121401": "沙角电厂社区"
    },
    "441900122": {
        "441900122002": "竹溪社区",
        "441900122003": "厚街社区",
        "441900122004": "珊美社区",
        "441900122005": "寮厦社区",
        "441900122006": "河田社区",
        "441900122007": "汀山社区",
        "441900122008": "环冈社区",
        "441900122009": "三屯社区",
        "441900122010": "宝屯社区",
        "441900122011": "陈屋社区",
        "441900122012": "赤岭社区",
        "441900122013": "桥头社区",
        "441900122014": "南五社区",
        "441900122015": "溪头社区",
        "441900122016": "沙塘社区",
        "441900122017": "宝塘社区",
        "441900122018": "下汴社区",
        "441900122019": "白濠社区",
        "441900122020": "新塘社区",
        "441900122021": "双岗社区",
        "441900122022": "涌口社区",
        "441900122023": "大迳社区",
        "441900122024": "新围社区",
        "441900122025": "湖景社区"
    },
    "441900123": {
        "441900123002": "横流社区",
        "441900123003": "滨港社区",
        "441900123200": "中围村村民委员会",
        "441900123201": "和安村村民委员会",
        "441900123202": "大流村村民委员会",
        "441900123203": "泥洲村村民委员会",
        "441900123204": "杨公洲村村民委员会",
        "441900123205": "阇西村村民委员会",
        "441900123206": "民田村村民委员会",
        "441900123207": "大泥村村民委员会",
        "441900123208": "福禄沙村村民委员会",
        "441900123209": "西大坦村村民委员会",
        "441900123210": "穗丰年村村民委员会",
        "441900123211": "齐沙村村民委员会",
        "441900123212": "稔洲村村民委员会",
        "441900123213": "义沙村村民委员会",
        "441900123214": "西太隆村村民委员会",
        "441900123215": "先锋村村民委员会"
    },
    "441900124": {
        "441900124002": "兴隆社区",
        "441900124200": "南城村村民委员会",
        "441900124201": "闸口村村民委员会",
        "441900124202": "北永村村民委员会",
        "441900124203": "永庆村村民委员会",
        "441900124204": "厚德村村民委员会",
        "441900124205": "蔡白村村民委员会",
        "441900124206": "南丫村村民委员会",
        "441900124207": "九曲村村民委员会",
        "441900124208": "大罗沙村村民委员会",
        "441900124209": "小河村村民委员会",
        "441900124210": "昌平村村民委员会",
        "441900124211": "大岭丫村村民委员会",
        "441900124212": "大鱼沙村村民委员会"
    },
    "441900125": {
        "441900125002": "洪梅社区",
        "441900125200": "洪屋涡村村民委员会",
        "441900125201": "新庄村村民委员会",
        "441900125202": "梅沙村村民委员会",
        "441900125203": "金鳌沙村村民委员会",
        "441900125204": "乌沙村村民委员会",
        "441900125205": "尧均村村民委员会",
        "441900125206": "夏汇村村民委员会",
        "441900125207": "氹涌村村民委员会",
        "441900125208": "黎洲角村村民委员会"
    },
    "441900126": {
        "441900126003": "麻涌社区",
        "441900126004": "麻二社区",
        "441900126200": "麻一村村民委员会",
        "441900126202": "麻三村村民委员会",
        "441900126203": "麻四村村民委员会",
        "441900126204": "大步村村民委员会",
        "441900126205": "东太村村民委员会",
        "441900126206": "新基村村民委员会",
        "441900126207": "川槎村村民委员会",
        "441900126208": "鸥涌村村民委员会",
        "441900126209": "黎滘村村民委员会",
        "441900126210": "华阳村村民委员会",
        "441900126211": "南洲村村民委员会",
        "441900126212": "大盛村村民委员会",
        "441900126213": "漳澎村村民委员会"
    },
    "441900127": {
        "441900127002": "望牛墩社区",
        "441900127200": "李屋村村民委员会",
        "441900127201": "望东村村民委员会",
        "441900127202": "扶涌村村民委员会",
        "441900127203": "赤滘村村民委员会",
        "441900127204": "五涌村村民委员会",
        "441900127205": "下漕村村民委员会",
        "441900127206": "上合村村民委员会",
        "441900127207": "聚龙江村村民委员会",
        "441900127208": "望联村村民委员会",
        "441900127209": "洲湾村村民委员会",
        "441900127210": "洲涡村村民委员会",
        "441900127211": "寮厦村村民委员会",
        "441900127212": "官桥涌村村民委员会",
        "441900127213": "芙蓉沙村村民委员会",
        "441900127214": "杜屋村村民委员会",
        "441900127215": "横沥村村民委员会",
        "441900127216": "福安村村民委员会",
        "441900127217": "官洲村村民委员会",
        "441900127218": "石排村村民委员会",
        "441900127219": "朱平沙村村民委员会",
        "441900127220": "锦涡村村民委员会"
    },
    "441900128": {
        "441900128002": "中心社区",
        "441900128003": "斗朗社区",
        "441900128004": "红锋社区",
        "441900128005": "东泊社区",
        "441900128006": "江南社区",
        "441900128200": "潢涌村村民委员会",
        "441900128201": "三涌村村民委员会",
        "441900128202": "湛翠村村民委员会",
        "441900128203": "袁家涌村村民委员会",
        "441900128204": "吴家涌村村民委员会",
        "441900128205": "凤冲村村民委员会",
        "441900128206": "鹤田村村民委员会",
        "441900128208": "中堂村村民委员会",
        "441900128209": "一村村民委员会",
        "441900128210": "东向村村民委员会",
        "441900128213": "蕉利村村民委员会",
        "441900128214": "槎滘村村民委员会",
        "441900128215": "下芦村村民委员会",
        "441900128216": "马沥村村民委员会",
        "441900128217": "四乡村村民委员会"
    },
    "441900129": {
        "441900129002": "新创社区",
        "441900129200": "冼沙村村民委员会",
        "441900129201": "卢溪村村民委员会",
        "441900129202": "宝莲村村民委员会",
        "441900129203": "塘厦村村民委员会",
        "441900129204": "草墩村村民委员会",
        "441900129205": "护安围村村民委员会",
        "441900129206": "保安围村村民委员会",
        "441900129207": "三联村村民委员会",
        "441900129208": "横滘头村村民委员会",
        "441900129209": "低涌村村民委员会",
        "441900129210": "朱磡村村民委员会",
        "441900129211": "欧邓村村民委员会",
        "441900129212": "芦村村民委员会",
        "441900129213": "高埗村村民委员会",
        "441900129214": "凌屋村村民委员会",
        "441900129215": "上江城村村民委员会",
        "441900129216": "下江城村村民委员会",
        "441900129217": "新联村村民委员会"
    },
    "441900401": {"441900401001": "松山湖社区"},
    "441900402": {"441900402498": "东莞港虚拟社区"},
    "441900403": {"441900403498": "东莞生态园虚拟社区"},
    "442000": {
        "442001001": "石岐区街道",
        "442001002": "东区街道",
        "442001003": "火炬开发区街道",
        "442001004": "西区街道",
        "442001005": "南区街道",
        "442001006": "五桂山街道",
        "442001100": "小榄镇",
        "442001101": "黄圃镇",
        "442001102": "民众镇",
        "442001103": "东凤镇",
        "442001104": "东升镇",
        "442001105": "古镇镇",
        "442001106": "沙溪镇",
        "442001107": "坦洲镇",
        "442001108": "港口镇",
        "442001109": "三角镇",
        "442001110": "横栏镇",
        "442001111": "南头镇",
        "442001112": "阜沙镇",
        "442001113": "南朗镇",
        "442001114": "三乡镇",
        "442001115": "板芙镇",
        "442001116": "大涌镇",
        "442001117": "神湾镇"
    },
    "442000001": {
        "442000001007": "桂园社区",
        "442000001008": "民族社区",
        "442000001016": "太平社区",
        "442000001018": "莲新社区",
        "442000001022": "迎阳社区",
        "442000001024": "仙湖社区",
        "442000001025": "湖滨社区",
        "442000001027": "悦来南社区",
        "442000001031": "凤鸣社区",
        "442000001036": "东明社区",
        "442000001044": "博爱社区",
        "442000001045": "民权社区",
        "442000001046": "民生社区",
        "442000001047": "宏基社区",
        "442000001048": "莲员社区",
        "442000001049": "康华社区",
        "442000001050": "大信社区",
        "442000001051": "莲兴社区",
        "442000001052": "东港湾社区"
    },
    "442000002": {
        "442000002002": "竹苑社区",
        "442000002003": "夏洋社区",
        "442000002005": "花苑社区",
        "442000002015": "起湾社区",
        "442000002016": "桥岗社区",
        "442000002017": "新鳌岭社区",
        "442000002018": "长江三溪社区",
        "442000002019": "齐富湾社区",
        "442000002020": "东裕社区",
        "442000002021": "桃苑社区"
    },
    "442000003": {
        "442000003002": "张家边社区",
        "442000003003": "博凯社区",
        "442000003004": "联富社区",
        "442000003005": "六和社区",
        "442000003006": "城东社区",
        "442000003007": "海滨社区",
        "442000003008": "中山港社区"
    },
    "442000004": {
        "442000004001": "烟洲社区",
        "442000004003": "广丰社区",
        "442000004005": "西苑社区",
        "442000004006": "长洲社区",
        "442000004007": "后山社区",
        "442000004008": "沙朗社区",
        "442000004009": "隆昌社区",
        "442000004010": "隆平社区",
        "442000004011": "彩虹社区"
    },
    "442000005": {"442000005002": "城南社区", "442000005009": "马岭社区", "442000005011": "良都社区", "442000005012": "北溪社区"},
    "442000006": {
        "442000006001": "五桂山社区",
        "442000006201": "龙石村民委员会",
        "442000006202": "长命水村民委员会",
        "442000006203": "桂南村民委员会",
        "442000006204": "南桥村民委员会"
    },
    "442000100": {
        "442000100002": "沙口社区",
        "442000100003": "新市社区",
        "442000100004": "东区社区",
        "442000100005": "绩西社区",
        "442000100006": "北区社区",
        "442000100007": "竹源社区",
        "442000100008": "绩东一社区",
        "442000100009": "绩东二社区",
        "442000100010": "盛丰社区",
        "442000100011": "宝丰社区",
        "442000100012": "九洲基社区",
        "442000100013": "永宁社区",
        "442000100014": "西区社区",
        "442000100015": "埒西一社区",
        "442000100016": "联丰社区"
    },
    "442000101": {
        "442000101001": "三社社区",
        "442000101002": "永平社区",
        "442000101003": "新糖社区",
        "442000101004": "文明社区",
        "442000101201": "新地村民委员会",
        "442000101202": "兆丰村民委员会",
        "442000101203": "吴栏村民委员会",
        "442000101204": "大雁村民委员会",
        "442000101205": "大岑村民委员会",
        "442000101206": "石军村民委员会",
        "442000101207": "横档村民委员会",
        "442000101208": "新沙村民委员会",
        "442000101209": "马安村民委员会",
        "442000101210": "团范村民委员会",
        "442000101211": "镇一村民委员会",
        "442000101213": "鳌山村民委员会"
    },
    "442000102": {
        "442000102001": "民众社区",
        "442000102002": "多宝社区",
        "442000102003": "浪网社区",
        "442000102201": "新伦村民委员会",
        "442000102202": "民平村民委员会",
        "442000102203": "义仓村民委员会",
        "442000102204": "裕安村民委员会",
        "442000102205": "新平村民委员会",
        "442000102206": "新平四村民委员会",
        "442000102207": "新建村民委员会",
        "442000102208": "沙仔村民委员会",
        "442000102209": "锦标村民委员会",
        "442000102210": "沿江村民委员会",
        "442000102211": "上网村民委员会",
        "442000102212": "东胜村民委员会",
        "442000102213": "浪网村民委员会",
        "442000102214": "三墩村民委员会",
        "442000102215": "接源村民委员会",
        "442000102217": "群安村民委员会"
    },
    "442000103": {
        "442000103001": "东兴社区",
        "442000103002": "东海社区",
        "442000103003": "伯公社区",
        "442000103004": "民乐社区",
        "442000103005": "小沥社区",
        "442000103204": "同安村民委员会",
        "442000103205": "安乐村民委员会",
        "442000103206": "和泰村民委员会",
        "442000103207": "穗成村民委员会",
        "442000103208": "东和平村民委员会",
        "442000103209": "吉昌村民委员会",
        "442000103210": "东罟步村民委员会",
        "442000103211": "西罟步村民委员会",
        "442000103212": "永益村民委员会"
    },
    "442000104": {
        "442000104001": "利生社区",
        "442000104002": "东升社区",
        "442000104003": "裕民社区",
        "442000104004": "高沙社区",
        "442000104005": "同乐社区",
        "442000104006": "同茂社区",
        "442000104007": "兆龙社区",
        "442000104008": "东城社区",
        "442000104201": "益隆村民委员会",
        "442000104202": "胜龙村民委员会",
        "442000104203": "新胜村民委员会",
        "442000104204": "坦背村民委员会",
        "442000104205": "太平村民委员会",
        "442000104206": "白鲤村民委员会"
    },
    "442000105": {
        "442000105001": "古镇社区",
        "442000105201": "古四村民委员会",
        "442000105202": "古三村民委员会",
        "442000105203": "古二村民委员会",
        "442000105204": "古一村民委员会",
        "442000105205": "六坊村民委员会",
        "442000105206": "七坊村民委员会",
        "442000105207": "冈东村民委员会",
        "442000105208": "冈南村民委员会",
        "442000105209": "曹一村民委员会",
        "442000105210": "曹二村民委员会",
        "442000105211": "曹三村民委员会",
        "442000105212": "海洲村民委员会"
    },
    "442000106": {
        "442000106001": "汇源社区",
        "442000106201": "康乐村民委员会",
        "442000106202": "龙山村民委员会",
        "442000106203": "龙瑞村民委员会",
        "442000106204": "云汉村民委员会",
        "442000106205": "乐群村民委员会",
        "442000106206": "龙头环村民委员会",
        "442000106207": "圣狮村民委员会",
        "442000106208": "象角村民委员会",
        "442000106209": "沙溪村民委员会",
        "442000106210": "虎逊村民委员会",
        "442000106211": "濠涌村民委员会",
        "442000106212": "涌头村民委员会",
        "442000106213": "中兴村民委员会",
        "442000106214": "涌边村民委员会",
        "442000106215": "港园村民委员会"
    },
    "442000107": {
        "442000107001": "坦洲村社区",
        "442000107002": "合胜社区",
        "442000107003": "安阜社区",
        "442000107004": "十四村社区",
        "442000107005": "七村社区",
        "442000107006": "同胜社区",
        "442000107007": "金斗社区",
        "442000107201": "永一村民委员会",
        "442000107202": "永二村民委员会",
        "442000107203": "裕洲村民委员会",
        "442000107204": "新合村民委员会",
        "442000107205": "群联村民委员会",
        "442000107206": "新前进村民委员会",
        "442000107207": "联一村民委员会"
    },
    "442000108": {
        "442000108001": "港口社区",
        "442000108002": "群众社区",
        "442000108003": "民主社区",
        "442000108004": "群乐社区",
        "442000108005": "石特社区",
        "442000108006": "胜隆社区",
        "442000108007": "西街社区",
        "442000108205": "下南村民委员会",
        "442000108206": "中南村民委员会"
    },
    "442000109": {
        "442000109001": "中心社区",
        "442000109201": "结民村民委员会",
        "442000109202": "蟠龙村民委员会",
        "442000109203": "东南村民委员会",
        "442000109204": "沙栏村民委员会",
        "442000109205": "光明村民委员会",
        "442000109206": "高平村民委员会",
        "442000109207": "三角村民委员会"
    },
    "442000110": {
        "442000110001": "西冲社区",
        "442000110201": "新丰村民委员会",
        "442000110202": "横东村民委员会",
        "442000110203": "横西村民委员会",
        "442000110204": "贴边村民委员会",
        "442000110205": "宝裕村民委员会",
        "442000110206": "新茂村民委员会",
        "442000110207": "裕祥村民委员会",
        "442000110208": "五沙村民委员会",
        "442000110209": "六沙村民委员会",
        "442000110210": "三沙村民委员会"
    },
    "442000111": {
        "442000111002": "穗西社区",
        "442000111003": "民安社区",
        "442000111004": "北帝社区",
        "442000111005": "滘心社区",
        "442000111006": "南城社区",
        "442000111007": "将军社区"
    },
    "442000112": {
        "442000112001": "阜圩社区",
        "442000112201": "罗松村民委员会",
        "442000112202": "阜沙村民委员会",
        "442000112203": "卫民村民委员会",
        "442000112204": "牛角村民委员会",
        "442000112205": "阜东村民委员会",
        "442000112207": "大有村民委员会",
        "442000112208": "丰联村民委员会",
        "442000112209": "上南村民委员会"
    },
    "442000113": {
        "442000113001": "南朗社区",
        "442000113002": "横门社区",
        "442000113201": "南朗村民委员会",
        "442000113202": "濠涌村民委员会",
        "442000113203": "榄边村民委员会",
        "442000113204": "翠亨村民委员会",
        "442000113205": "崖口村民委员会",
        "442000113206": "泮沙村民委员会",
        "442000113207": "龙穴村民委员会",
        "442000113208": "左步村民委员会",
        "442000113209": "冲口村民委员会",
        "442000113210": "大车村民委员会",
        "442000113211": "关塘村民委员会",
        "442000113212": "白企村民委员会",
        "442000113213": "华照村民委员会"
    },
    "442000114": {
        "442000114001": "圩仔社区",
        "442000114002": "前陇社区",
        "442000114003": "南龙社区",
        "442000114004": "西山社区",
        "442000114201": "平南村民委员会",
        "442000114202": "平岚东村民委员会",
        "442000114203": "桥头村民委员会",
        "442000114204": "乌石村民委员会",
        "442000114205": "白石村民委员会",
        "442000114207": "大布村民委员会",
        "442000114208": "雍陌村民委员会",
        "442000114209": "古鹤村民委员会",
        "442000114210": "新圩村民委员会",
        "442000114211": "茅湾村民委员会",
        "442000114212": "岗泉村民委员会",
        "442000114213": "塘敢村民委员会"
    },
    "442000115": {
        "442000115001": "板芙社区",
        "442000115202": "板芙村民委员会",
        "442000115203": "板尾村民委员会",
        "442000115204": "四联村民委员会",
        "442000115205": "金钟村民委员会",
        "442000115206": "里溪村民委员会",
        "442000115207": "深湾村民委员会",
        "442000115208": "广福村民委员会",
        "442000115209": "白溪村民委员会",
        "442000115210": "湖洲村民委员会",
        "442000115211": "禄围村民委员会"
    },
    "442000116": {
        "442000116001": "大涌社区",
        "442000116002": "南文社区",
        "442000116003": "安堂社区",
        "442000116004": "岚田社区",
        "442000116005": "青岗社区",
        "442000116006": "兴涌社区",
        "442000116007": "石井社区",
        "442000116008": "全禄社区",
        "442000116009": "起凤环社区",
        "442000116201": "旗南村民委员会",
        "442000116203": "叠石村民委员会"
    },
    "442000117": {
        "442000117001": "神湾社区",
        "442000117201": "神溪村民委员会",
        "442000117202": "宥南村民委员会",
        "442000117203": "海港村民委员会",
        "442000117204": "外沙村民委员会",
        "442000117205": "竹排村民委员会"
    },
    "445100": {"445102": "湘桥区", "445103": "潮安区", "445122": "饶平县"},
    "445102": {
        "445102001": "湘桥街道",
        "445102002": "西湖街道",
        "445102003": "金山街道",
        "445102004": "太平街道",
        "445102005": "南春街道",
        "445102006": "西新街道",
        "445102007": "桥东街道",
        "445102008": "城西街道",
        "445102009": "凤新街道",
        "445102100": "意溪镇",
        "445102111": "磷溪镇",
        "445102112": "铁铺镇",
        "445102113": "官塘镇",
        "445102400": "红山林场",
        "445102401": "开发区"
    },
    "445103": {
        "445103100": "古巷镇",
        "445103101": "登塘镇",
        "445103103": "凤塘镇",
        "445103104": "浮洋镇",
        "445103105": "龙湖镇",
        "445103106": "金石镇",
        "445103107": "沙溪镇",
        "445103108": "彩塘镇",
        "445103109": "东凤镇",
        "445103110": "庵埠镇",
        "445103114": "江东镇",
        "445103115": "归湖镇",
        "445103116": "文祠镇",
        "445103117": "凤凰镇",
        "445103120": "赤凤镇",
        "445103121": "枫溪镇",
        "445103400": "万峰林场",
        "445103404": "大坑苗圃场",
        "445103405": "东山湖农场",
        "445103450": "庵埠经济开发试验区"
    },
    "445122": {
        "445122100": "黄冈镇",
        "445122102": "上饶镇",
        "445122103": "饶洋镇",
        "445122104": "新丰镇",
        "445122106": "建饶镇",
        "445122107": "三饶镇",
        "445122108": "新塘镇",
        "445122109": "汤溪镇",
        "445122110": "浮滨镇",
        "445122112": "浮山镇",
        "445122113": "东山镇",
        "445122114": "新圩镇",
        "445122116": "樟溪镇",
        "445122117": "钱东镇",
        "445122118": "高堂镇",
        "445122119": "联饶镇",
        "445122120": "所城镇",
        "445122121": "大埕镇",
        "445122122": "柘林镇",
        "445122123": "汫洲镇",
        "445122124": "海山镇",
        "445122400": "韩江林场"
    },
    "445200": {"445202": "榕城区", "445203": "揭东区", "445222": "揭西县", "445224": "惠来县", "445281": "普宁市"},
    "445202": {
        "445202001": "榕华街道",
        "445202002": "新兴街道",
        "445202003": "中山街道",
        "445202004": "西马街道",
        "445202005": "东兴街道",
        "445202006": "榕东街道",
        "445202007": "仙桥街道",
        "445202008": "梅云街道",
        "445202009": "东升街道",
        "445202010": "东阳街道",
        "445202101": "渔湖镇",
        "445202102": "炮台镇",
        "445202103": "地都镇",
        "445202105": "登岗镇"
    },
    "445203": {
        "445203001": "曲溪街道",
        "445203002": "磐东街道",
        "445203102": "云路镇",
        "445203103": "玉窖镇",
        "445203104": "锡场镇",
        "445203105": "新亨镇",
        "445203106": "玉湖镇",
        "445203107": "埔田镇",
        "445203108": "霖磐镇",
        "445203109": "月城镇",
        "445203110": "白塔镇",
        "445203111": "龙尾镇",
        "445203112": "桂岭镇",
        "445203401": "东径茶场",
        "445203402": "坪上农场",
        "445203403": "揭阳高新技术产业开发区",
        "445203404": "卅岭农场",
        "445203405": "蓝城区工业园区管理处",
        "445203406": "珠海（揭阳）产业转移工业园"
    },
    "445222": {
        "445222001": "河婆街道",
        "445222102": "龙潭镇",
        "445222103": "南山镇",
        "445222104": "五经富镇",
        "445222105": "京溪园镇",
        "445222106": "灰寨镇",
        "445222107": "塔头镇",
        "445222108": "东园镇",
        "445222109": "凤江镇",
        "445222110": "棉湖镇",
        "445222111": "金和镇",
        "445222112": "大溪镇",
        "445222113": "钱坑镇",
        "445222114": "坪上镇",
        "445222115": "五云镇",
        "445222116": "上砂镇",
        "445222201": "良田乡",
        "445222401": "北山农林场",
        "445222402": "果林场",
        "445222403": "油桐林场",
        "445222404": "高田农场",
        "445222405": "河輋农场",
        "445222406": "县苗圃场"
    },
    "445224": {
        "445224100": "惠城镇",
        "445224102": "华湖镇",
        "445224103": "仙庵镇",
        "445224104": "靖海镇",
        "445224105": "周田镇",
        "445224106": "前詹镇",
        "445224107": "神泉镇",
        "445224108": "东陇镇",
        "445224109": "岐石镇",
        "445224110": "隆江镇",
        "445224111": "溪西镇",
        "445224112": "鳌江镇",
        "445224113": "东港镇",
        "445224114": "葵潭镇",
        "445224401": "东埔农场",
        "445224402": "揭阳市大南山华侨管理区",
        "445224403": "葵潭农场",
        "445224404": "青坑林场",
        "445224450": "揭阳（惠来）沿海经济开发试验区",
        "445224451": "揭阳大南海石化工业区南海街道"
    },
    "445281": {
        "445281001": "流沙东街道",
        "445281002": "流沙南街道",
        "445281003": "流沙西街道",
        "445281004": "流沙北街道",
        "445281005": "池尾街道",
        "445281006": "燎原街道",
        "445281007": "大南山街道",
        "445281102": "赤岗镇",
        "445281103": "大坝镇",
        "445281104": "洪阳镇",
        "445281105": "南溪镇",
        "445281106": "广太镇",
        "445281107": "麒麟镇",
        "445281108": "南径镇",
        "445281109": "占陇镇",
        "445281110": "军埠镇",
        "445281111": "下架山镇",
        "445281113": "高埔镇",
        "445281114": "云落镇",
        "445281115": "大坪镇",
        "445281116": "船埔镇",
        "445281117": "梅林镇",
        "445281119": "里湖镇",
        "445281121": "梅塘镇",
        "445281201": "后溪乡",
        "445281401": "大池农场",
        "445281402": "马鞍山农场",
        "445281403": "大坪农场",
        "445281404": "普侨区",
        "445281405": "三坑农场",
        "445281406": "乌石农场",
        "445281407": "科技工业园",
        "445281408": "英歌山工业园"
    },
    "445300": {"445302": "云城区", "445303": "云安区", "445321": "新兴县", "445322": "郁南县", "445381": "罗定市"},
    "445302": {
        "445302001": "云城街道",
        "445302002": "高峰街道",
        "445302003": "河口街道",
        "445302004": "安塘街道",
        "445302102": "腰古镇",
        "445302103": "思劳镇",
        "445302105": "前锋镇",
        "445302106": "南盛镇"
    },
    "445303": {
        "445303100": "六都镇",
        "445303101": "高村镇",
        "445303102": "白石镇",
        "445303103": "镇安镇",
        "445303104": "富林镇",
        "445303105": "石城镇",
        "445303106": "都杨镇",
        "445303400": "大云雾林场"
    },
    "445321": {
        "445321100": "新城镇",
        "445321101": "车岗镇",
        "445321103": "水台镇",
        "445321104": "稔村镇",
        "445321105": "东成镇",
        "445321107": "太平镇",
        "445321108": "里洞镇",
        "445321110": "大江镇",
        "445321112": "天堂镇",
        "445321113": "河头镇",
        "445321114": "簕竹镇",
        "445321115": "六祖镇",
        "445321501": "广东省西江林业局良洞迳林场",
        "445321502": "云浮市国有水台林场",
        "445321503": "国营新兴县岩头林场"
    },
    "445322": {
        "445322100": "都城镇",
        "445322101": "平台镇",
        "445322102": "桂圩镇",
        "445322104": "通门镇",
        "445322105": "建城镇",
        "445322106": "宝珠镇",
        "445322108": "大方镇",
        "445322110": "千官镇",
        "445322111": "大湾镇",
        "445322112": "河口镇",
        "445322113": "宋桂镇",
        "445322114": "东坝镇",
        "445322115": "连滩镇",
        "445322116": "历洞镇",
        "445322117": "南江口镇",
        "445322400": "西江林场",
        "445322401": "通门林场",
        "445322402": "大历林场",
        "445322403": "同乐林场"
    },
    "445381": {
        "445381001": "罗城街道",
        "445381002": "素龙街道",
        "445381003": "附城街道",
        "445381004": "双东镇街道",
        "445381100": "罗镜镇",
        "445381101": "太平镇",
        "445381102": "分界镇",
        "445381104": "罗平镇",
        "445381105": "船步镇",
        "445381106": "满塘镇",
        "445381107": "苹塘镇",
        "445381108": "金鸡镇",
        "445381109": "围底镇",
        "445381110": "华石镇",
        "445381111": "林滨镇",
        "445381113": "黎少镇",
        "445381114": "生江镇",
        "445381115": "连州镇",
        "445381116": "泗纶镇",
        "445381118": "加益镇",
        "445381119": "龙湾镇",
        "445381400": "罗平农场"
    },
    "450000": {
        "450100": "南宁市",
        "450200": "柳州市",
        "450300": "桂林市",
        "450400": "梧州市",
        "450500": "北海市",
        "450600": "防城港市",
        "450700": "钦州市",
        "450800": "贵港市",
        "450900": "玉林市",
        "451000": "百色市",
        "451100": "贺州市",
        "451200": "河池市",
        "451300": "来宾市",
        "451400": "崇左市"
    },
    "450100": {
        "450102": "兴宁区",
        "450103": "青秀区",
        "450105": "江南区",
        "450107": "西乡塘区",
        "450108": "良庆区",
        "450109": "邕宁区",
        "450110": "武鸣区",
        "450123": "隆安县",
        "450124": "马山县",
        "450125": "上林县",
        "450126": "宾阳县",
        "450127": "横县"
    },
    "450102": {
        "450102001": "民生街道",
        "450102002": "朝阳街道",
        "450102003": "兴东街道",
        "450102101": "三塘镇",
        "450102102": "五塘镇",
        "450102104": "昆仑镇",
        "450102450": "九曲湾农场"
    },
    "450103": {
        "450103001": "新竹街道",
        "450103002": "中山街道",
        "450103003": "建政街道",
        "450103004": "南湖街道",
        "450103005": "津头街道",
        "450103006": "仙葫经济开发区管理委员会",
        "450103100": "刘圩镇",
        "450103101": "南阳镇",
        "450103102": "伶俐镇",
        "450103103": "长塘镇",
        "450103451": "青秀山管理委员会"
    },
    "450105": {
        "450105001": "福建园街道",
        "450105002": "江南街道",
        "450105003": "沙井街道",
        "450105004": "那洪街道",
        "450105005": "金凯街道",
        "450105102": "吴圩镇",
        "450105103": "苏圩镇",
        "450105104": "延安镇",
        "450105105": "江西镇",
        "450105450": "经济技术开发区",
        "450105451": "明阳工业园区管委会"
    },
    "450107": {
        "450107001": "衡阳街道",
        "450107002": "北湖街道",
        "450107003": "西乡塘街道",
        "450107004": "安吉街道",
        "450107005": "华强街道",
        "450107006": "新阳街道",
        "450107007": "上尧街道",
        "450107008": "安宁街道",
        "450107009": "石埠街道",
        "450107010": "心圩街道",
        "450107100": "金陵镇",
        "450107101": "双定镇",
        "450107105": "坛洛镇",
        "450107450": "那龙矿务局",
        "450107454": "高新技术开发区管委会"
    },
    "450108": {
        "450108001": "大沙田街道",
        "450108002": "玉洞街道",
        "450108100": "良庆镇",
        "450108101": "那马镇",
        "450108102": "那陈镇",
        "450108103": "大塘镇",
        "450108104": "南晓镇",
        "450108452": "良庆经济开发区管委会"
    },
    "450109": {"450109100": "蒲庙镇", "450109101": "那楼镇", "450109102": "新江镇", "450109103": "百济镇", "450109202": "中和乡"},
    "450110": {
        "450110100": "城厢镇",
        "450110102": "太平镇",
        "450110103": "双桥镇",
        "450110104": "宁武镇",
        "450110105": "锣圩镇",
        "450110106": "仙湖镇",
        "450110107": "府城镇",
        "450110108": "陆斡镇",
        "450110109": "两江镇",
        "450110110": "罗波镇",
        "450110111": "灵马镇",
        "450110112": "甘圩镇",
        "450110113": "马头镇",
        "450110451": "南宁华侨投资区"
    },
    "450123": {
        "450123100": "城厢镇",
        "450123101": "南圩镇",
        "450123102": "雁江镇",
        "450123103": "那桐镇",
        "450123104": "乔建镇",
        "450123105": "丁当镇",
        "450123201": "古潭乡",
        "450123203": "都结乡",
        "450123204": "布泉乡",
        "450123205": "屏山乡",
        "450123450": "隆安华侨管理区管理委员会"
    },
    "450124": {
        "450124100": "白山镇",
        "450124101": "百龙滩镇",
        "450124102": "林圩镇",
        "450124103": "古零镇",
        "450124104": "金钗镇",
        "450124105": "周鹿镇",
        "450124106": "永州镇",
        "450124200": "乔利乡",
        "450124201": "加方乡",
        "450124202": "古寨瑶族乡",
        "450124203": "里当瑶族乡",
        "450124450": "光明山林场"
    },
    "450125": {
        "450125100": "大丰镇",
        "450125101": "明亮镇",
        "450125102": "巷贤镇",
        "450125103": "白圩镇",
        "450125104": "三里镇",
        "450125105": "乔贤镇",
        "450125106": "西燕镇",
        "450125200": "澄泰乡",
        "450125202": "木山乡",
        "450125203": "塘红乡",
        "450125205": "镇圩瑶族乡"
    },
    "450126": {
        "450126100": "宾州镇",
        "450126101": "黎塘镇",
        "450126102": "甘棠镇",
        "450126103": "思陇镇",
        "450126104": "新桥镇",
        "450126105": "新圩镇",
        "450126106": "邹圩镇",
        "450126107": "大桥镇",
        "450126108": "武陵镇",
        "450126109": "中华镇",
        "450126110": "古辣镇",
        "450126111": "露圩镇",
        "450126112": "王灵镇",
        "450126113": "和吉镇",
        "450126114": "洋桥镇",
        "450126115": "陈平镇",
        "450126451": "廖平农场"
    },
    "450127": {
        "450127100": "横州镇",
        "450127101": "百合镇",
        "450127102": "那阳镇",
        "450127103": "南乡镇",
        "450127104": "新福镇",
        "450127105": "莲塘镇",
        "450127106": "平马镇",
        "450127107": "峦城镇",
        "450127108": "六景镇",
        "450127110": "石塘镇",
        "450127112": "陶圩镇",
        "450127113": "校椅镇",
        "450127114": "云表镇",
        "450127115": "马岭镇",
        "450127200": "马山乡",
        "450127203": "平朗乡",
        "450127204": "镇龙乡",
        "450127400": "南宁六景工业园区管委会"
    },
    "450200": {
        "450202": "城中区",
        "450203": "鱼峰区",
        "450204": "柳南区",
        "450205": "柳北区",
        "450206": "柳江区",
        "450222": "柳城县",
        "450223": "鹿寨县",
        "450224": "融安县",
        "450225": "融水苗族自治县",
        "450226": "三江侗族自治县"
    },
    "450202": {
        "450202001": "城中街道",
        "450202002": "公园街道",
        "450202003": "中南街道",
        "450202004": "水上街道",
        "450202005": "潭中街道",
        "450202006": "河东街道",
        "450202007": "静兰街道"
    },
    "450203": {
        "450203001": "天马街道",
        "450203002": "驾鹤街道",
        "450203003": "箭盘山街道",
        "450203004": "五里亭街道",
        "450203005": "荣军街道",
        "450203006": "白莲街道",
        "450203007": "麒麟街道",
        "450203008": "阳和街道",
        "450203100": "雒容镇",
        "450203101": "洛埠镇",
        "450203102": "白沙镇",
        "450203103": "里雍镇"
    },
    "450204": {
        "450204001": "河西街道",
        "450204002": "南站街道",
        "450204003": "鹅山街道",
        "450204004": "柳南街道",
        "450204005": "柳石街道",
        "450204006": "银山街道",
        "450204007": "潭西街道",
        "450204008": "南环街道",
        "450204100": "太阳村镇"
    },
    "450205": {
        "450205001": "解放街道",
        "450205002": "雅儒街道",
        "450205003": "胜利街道",
        "450205004": "雀儿山街道",
        "450205005": "钢城街道",
        "450205006": "锦绣街道",
        "450205007": "白露街道",
        "450205008": "跃进街道",
        "450205009": "柳长街道",
        "450205100": "石碑坪镇",
        "450205101": "沙塘镇",
        "450205102": "长塘镇"
    },
    "450206": {
        "450206100": "拉堡镇",
        "450206102": "百朋镇",
        "450206103": "成团镇",
        "450206104": "洛满镇",
        "450206105": "流山镇",
        "450206106": "三都镇",
        "450206107": "里高镇",
        "450206108": "进德镇",
        "450206109": "穿山镇",
        "450206110": "土博镇",
        "450206400": "新兴工业园",
        "450206401": "新兴农场"
    },
    "450222": {
        "450222100": "大埔镇",
        "450222101": "龙头镇",
        "450222102": "太平镇",
        "450222103": "沙埔镇",
        "450222104": "东泉镇",
        "450222105": "凤山镇",
        "450222106": "六塘镇",
        "450222107": "冲脉镇",
        "450222108": "寨隆镇",
        "450222109": "马山镇",
        "450222200": "古砦仫佬族乡",
        "450222202": "社冲乡"
    },
    "450223": {
        "450223100": "鹿寨镇",
        "450223102": "中渡镇",
        "450223103": "寨沙镇",
        "450223104": "平山镇",
        "450223105": "黄冕镇",
        "450223106": "四排镇",
        "450223201": "江口乡",
        "450223202": "导江乡",
        "450223203": "拉沟乡"
    },
    "450224": {
        "450224100": "长安镇",
        "450224101": "浮石镇",
        "450224102": "泗顶镇",
        "450224103": "板榄镇",
        "450224104": "大将镇",
        "450224105": "大良镇",
        "450224200": "雅瑶乡",
        "450224201": "大坡乡",
        "450224202": "东起乡",
        "450224203": "沙子乡",
        "450224204": "桥板乡",
        "450224205": "潭头乡"
    },
    "450225": {
        "450225100": "融水镇",
        "450225101": "和睦镇",
        "450225102": "三防镇",
        "450225103": "怀宝镇",
        "450225104": "洞头镇",
        "450225105": "大浪镇",
        "450225106": "永乐镇",
        "450225201": "四荣乡",
        "450225202": "香粉乡",
        "450225203": "安太乡",
        "450225205": "汪洞乡",
        "450225206": "同练瑶族乡",
        "450225207": "滚贝侗族乡",
        "450225208": "杆洞乡",
        "450225209": "安陲乡",
        "450225211": "白云乡",
        "450225212": "红水乡",
        "450225213": "拱洞乡",
        "450225214": "良寨乡",
        "450225215": "大年乡"
    },
    "450226": {
        "450226100": "古宜镇",
        "450226101": "斗江镇",
        "450226102": "丹洲镇",
        "450226103": "八江镇",
        "450226104": "林溪镇",
        "450226105": "独峒镇",
        "450226203": "同乐苗族乡",
        "450226204": "梅林乡",
        "450226205": "富禄苗族乡",
        "450226206": "洋溪乡",
        "450226207": "良口乡",
        "450226208": "老堡乡",
        "450226209": "高基瑶族乡",
        "450226210": "和平乡",
        "450226211": "程村乡"
    },
    "450300": {
        "450302": "秀峰区",
        "450303": "叠彩区",
        "450304": "象山区",
        "450305": "七星区",
        "450311": "雁山区",
        "450312": "临桂区",
        "450321": "阳朔县",
        "450323": "灵川县",
        "450324": "全州县",
        "450325": "兴安县",
        "450326": "永福县",
        "450327": "灌阳县",
        "450328": "龙胜各族自治县",
        "450329": "资源县",
        "450330": "平乐县",
        "450332": "恭城瑶族自治县",
        "450381": "荔浦市"
    },
    "450302": {"450302001": "秀峰街道", "450302002": "丽君街道", "450302003": "甲山街道"},
    "450303": {"450303001": "叠彩街道", "450303002": "北门街道", "450303200": "大河乡"},
    "450304": {"450304001": "南门街道", "450304002": "象山街道", "450304003": "平山街道", "450304200": "二塘乡"},
    "450305": {
        "450305001": "七星区街道",
        "450305002": "东江街道",
        "450305003": "穿山街道",
        "450305004": "漓东街道",
        "450305200": "朝阳乡",
        "450305400": "桂林华侨旅游经济区管理委员会"
    },
    "450311": {"450311001": "良丰街道", "450311100": "雁山镇", "450311101": "柘木镇", "450311200": "大埠乡", "450311201": "草坪回族乡"},
    "450312": {
        "450312100": "临桂镇",
        "450312101": "六塘镇",
        "450312102": "会仙镇",
        "450312103": "两江镇",
        "450312104": "五通镇",
        "450312105": "四塘镇",
        "450312106": "南边山镇",
        "450312107": "中庸镇",
        "450312108": "茶洞镇",
        "450312204": "宛田瑶族乡",
        "450312205": "黄沙瑶族乡"
    },
    "450321": {
        "450321100": "阳朔镇",
        "450321101": "白沙镇",
        "450321102": "福利镇",
        "450321103": "兴坪镇",
        "450321104": "葡萄镇",
        "450321105": "高田镇",
        "450321200": "金宝乡",
        "450321201": "普益乡",
        "450321202": "杨堤乡"
    },
    "450323": {
        "450323100": "灵川镇",
        "450323101": "大圩镇",
        "450323102": "定江镇",
        "450323103": "三街镇",
        "450323104": "潭下镇",
        "450323106": "九屋镇",
        "450323107": "灵田镇",
        "450323200": "潮田乡",
        "450323201": "大境瑶族乡",
        "450323202": "海洋乡",
        "450323206": "兰田瑶族乡",
        "450323207": "公平乡"
    },
    "450324": {
        "450324100": "全州镇",
        "450324101": "黄沙河镇",
        "450324102": "庙头镇",
        "450324103": "文桥镇",
        "450324104": "大西江镇",
        "450324105": "龙水镇",
        "450324106": "才湾镇",
        "450324107": "绍水镇",
        "450324108": "石塘镇",
        "450324109": "咸水镇",
        "450324110": "凤凰镇",
        "450324111": "安和镇",
        "450324112": "两河镇",
        "450324113": "枧塘镇",
        "450324114": "永岁镇",
        "450324204": "蕉江瑶族乡",
        "450324207": "白宝乡",
        "450324208": "东山瑶族乡"
    },
    "450325": {
        "450325100": "兴安镇",
        "450325101": "湘漓镇",
        "450325102": "界首镇",
        "450325103": "高尚镇",
        "450325104": "严关镇",
        "450325105": "溶江镇",
        "450325200": "漠川乡",
        "450325201": "白石乡",
        "450325202": "崔家乡",
        "450325203": "华江瑶族乡"
    },
    "450326": {
        "450326100": "永福镇",
        "450326101": "罗锦镇",
        "450326102": "百寿镇",
        "450326103": "苏桥镇",
        "450326104": "三皇镇",
        "450326105": "堡里镇",
        "450326202": "广福乡",
        "450326204": "永安乡",
        "450326205": "龙江乡"
    },
    "450327": {
        "450327100": "灌阳镇",
        "450327101": "黄关镇",
        "450327102": "文市镇",
        "450327103": "新街镇",
        "450327104": "新圩镇",
        "450327105": "水车镇",
        "450327200": "洞井瑶族乡",
        "450327201": "观音阁乡",
        "450327202": "西山瑶族乡"
    },
    "450328": {
        "450328100": "龙胜镇",
        "450328101": "瓢里镇",
        "450328102": "三门镇",
        "450328103": "龙脊镇",
        "450328104": "平等镇",
        "450328201": "泗水乡",
        "450328202": "江底乡",
        "450328203": "马堤乡",
        "450328204": "伟江乡",
        "450328206": "乐江乡"
    },
    "450329": {
        "450329100": "资源镇",
        "450329101": "中峰镇",
        "450329102": "梅溪镇",
        "450329202": "瓜里乡",
        "450329203": "车田苗族乡",
        "450329204": "两水苗族乡",
        "450329205": "河口瑶族乡"
    },
    "450330": {
        "450330100": "平乐镇",
        "450330101": "二塘镇",
        "450330102": "沙子镇",
        "450330103": "同安镇",
        "450330104": "张家镇",
        "450330105": "源头镇",
        "450330200": "阳安乡",
        "450330201": "青龙乡",
        "450330202": "桥亭乡",
        "450330203": "大发瑶族乡"
    },
    "450332": {
        "450332100": "恭城镇",
        "450332101": "栗木镇",
        "450332102": "莲花镇",
        "450332103": "嘉会镇",
        "450332104": "西岭镇",
        "450332200": "平安乡",
        "450332201": "三江乡",
        "450332204": "观音乡",
        "450332205": "龙虎乡"
    },
    "450381": {
        "450381100": "荔城镇",
        "450381101": "东昌镇",
        "450381102": "新坪镇",
        "450381103": "杜莫镇",
        "450381104": "青山镇",
        "450381105": "修仁镇",
        "450381106": "大塘镇",
        "450381107": "花箦镇",
        "450381108": "双江镇",
        "450381109": "马岭镇",
        "450381200": "龙怀乡",
        "450381201": "茶城乡",
        "450381202": "蒲芦瑶族乡"
    },
    "450400": {
        "450403": "万秀区",
        "450405": "长洲区",
        "450406": "龙圩区",
        "450421": "苍梧县",
        "450422": "藤县",
        "450423": "蒙山县",
        "450481": "岑溪市"
    },
    "450403": {
        "450403001": "城东街道",
        "450403002": "城南街道",
        "450403003": "城中办街道事处",
        "450403004": "城北办街道事处",
        "450403005": "角嘴街道",
        "450403006": "东兴街道",
        "450403007": "富民街道",
        "450403101": "城东镇",
        "450403103": "龙湖镇",
        "450403104": "夏郢镇"
    },
    "450405": {"450405001": "大塘街道", "450405002": "兴龙街道", "450405101": "长洲镇", "450405102": "倒水镇"},
    "450406": {"450406100": "龙圩镇", "450406101": "大坡镇", "450406102": "广平镇", "450406103": "新地镇"},
    "450421": {
        "450421100": "石桥镇",
        "450421101": "沙头镇",
        "450421102": "梨埠镇",
        "450421105": "岭脚镇",
        "450421108": "京南镇",
        "450421109": "狮寨镇",
        "450421110": "旺甫镇",
        "450421112": "六堡镇",
        "450421114": "木双镇"
    },
    "450422": {
        "450422100": "藤州镇",
        "450422103": "塘步镇",
        "450422104": "埌南镇",
        "450422105": "同心镇",
        "450422106": "金鸡镇",
        "450422107": "新庆镇",
        "450422108": "象棋镇",
        "450422109": "岭景镇",
        "450422110": "天平镇",
        "450422111": "蒙江镇",
        "450422112": "和平镇",
        "450422113": "太平镇",
        "450422114": "古龙镇",
        "450422115": "东荣镇",
        "450422116": "大黎镇",
        "450422200": "平福乡",
        "450422201": "宁康乡"
    },
    "450423": {
        "450423100": "蒙山镇",
        "450423101": "西河镇",
        "450423102": "新圩镇",
        "450423103": "文圩镇",
        "450423104": "黄村镇",
        "450423105": "陈塘镇",
        "450423200": "汉豪乡",
        "450423201": "长坪瑶族乡",
        "450423202": "夏宜瑶族乡"
    },
    "450481": {
        "450481100": "岑城镇",
        "450481102": "马路镇",
        "450481103": "南渡镇",
        "450481105": "水汶镇",
        "450481106": "大隆镇",
        "450481107": "梨木镇",
        "450481108": "大业镇",
        "450481109": "筋竹镇",
        "450481110": "诚谏镇",
        "450481111": "归义镇",
        "450481112": "糯垌镇",
        "450481113": "安平镇",
        "450481114": "三堡镇",
        "450481115": "波塘镇"
    },
    "450500": {"450502": "海城区", "450503": "银海区", "450512": "铁山港区", "450521": "合浦县"},
    "450502": {
        "450502001": "中街街道",
        "450502002": "东街街道",
        "450502003": "西街街道",
        "450502004": "海角街道",
        "450502005": "地角街道",
        "450502006": "高德街道",
        "450502007": "驿马街道",
        "450502100": "涠洲镇"
    },
    "450503": {"450503100": "福成镇", "450503101": "银滩镇", "450503102": "平阳镇", "450503103": "侨港镇"},
    "450512": {"450512100": "南康镇", "450512101": "营盘镇", "450512102": "兴港镇"},
    "450521": {
        "450521100": "廉州镇",
        "450521101": "党江镇",
        "450521102": "西场镇",
        "450521103": "沙岗镇",
        "450521104": "乌家镇",
        "450521105": "闸口镇",
        "450521106": "公馆镇",
        "450521107": "白沙镇",
        "450521108": "山口镇",
        "450521109": "沙田镇",
        "450521110": "石湾镇",
        "450521111": "石康镇",
        "450521112": "常乐镇",
        "450521113": "星岛湖镇",
        "450521200": "曲樟乡"
    },
    "450600": {"450602": "港口区", "450603": "防城区", "450621": "上思县", "450681": "东兴市"},
    "450602": {
        "450602001": "渔州坪街道",
        "450602002": "白沙万街道",
        "450602003": "沙潭江街道",
        "450602004": "王府街道",
        "450602100": "企沙镇",
        "450602101": "光坡镇"
    },
    "450603": {
        "450603001": "水营街道",
        "450603002": "珠河街道",
        "450603003": "文昌街道",
        "450603101": "大菉镇",
        "450603102": "华石镇",
        "450603103": "那梭镇",
        "450603104": "那良镇",
        "450603105": "峒中镇",
        "450603106": "江山镇",
        "450603107": "茅岭镇",
        "450603108": "扶隆镇",
        "450603203": "滩营乡",
        "450603209": "十万山瑶族乡",
        "450603450": "小峰经济作物场",
        "450603451": "荣光农场",
        "450603453": "垌美农场",
        "450603454": "那梭农场"
    },
    "450621": {
        "450621100": "思阳镇",
        "450621101": "在妙镇",
        "450621102": "华兰镇",
        "450621103": "叫安镇",
        "450621203": "南屏瑶族乡",
        "450621204": "平福乡",
        "450621205": "那琴乡",
        "450621206": "公正乡",
        "450621450": "国有昌菱农场"
    },
    "450681": {"450681100": "东兴镇", "450681101": "江平镇", "450681102": "马路镇"},
    "450700": {"450702": "钦南区", "450703": "钦北区", "450721": "灵山县", "450722": "浦北县"},
    "450702": {
        "450702001": "向阳街道",
        "450702002": "水东街道",
        "450702003": "文峰街道",
        "450702004": "南珠街道",
        "450702005": "尖山街道",
        "450702100": "沙埠镇",
        "450702101": "康熙岭镇",
        "450702102": "黄屋屯镇",
        "450702104": "大番坡镇",
        "450702105": "龙门港镇",
        "450702107": "久隆镇",
        "450702108": "东场镇",
        "450702109": "那丽镇",
        "450702110": "那彭镇",
        "450702111": "那思镇",
        "450702112": "犀牛脚镇",
        "450702400": "丽光华侨农场",
        "450702401": "钦州港经济技术开发区",
        "450702402": "三娘湾旅游管理区",
        "450702403": "广西钦州保税港区",
        "450702404": "中马钦州产业园区"
    },
    "450703": {
        "450703001": "长田街道",
        "450703002": "鸿亭街道",
        "450703003": "子材街道",
        "450703100": "大垌镇",
        "450703101": "平吉镇",
        "450703102": "青塘镇",
        "450703103": "小董镇",
        "450703104": "板城镇",
        "450703105": "那蒙镇",
        "450703106": "长滩镇",
        "450703107": "新棠镇",
        "450703108": "大直镇",
        "450703109": "大寺镇",
        "450703110": "贵台镇"
    },
    "450721": {
        "450721001": "灵城街道",
        "450721002": "三海街道",
        "450721101": "新圩镇",
        "450721102": "丰塘镇",
        "450721103": "平山镇",
        "450721104": "石塘镇",
        "450721105": "佛子镇",
        "450721106": "平南镇",
        "450721107": "烟墩镇",
        "450721108": "檀圩镇",
        "450721109": "那隆镇",
        "450721110": "三隆镇",
        "450721111": "陆屋镇",
        "450721112": "旧州镇",
        "450721113": "太平镇",
        "450721114": "沙坪镇",
        "450721115": "武利镇",
        "450721116": "文利镇",
        "450721117": "伯劳镇",
        "450721400": "国营新光农场",
        "450721401": "国营华山农场"
    },
    "450722": {
        "450722001": "小江街道",
        "450722002": "江城街道",
        "450722101": "泉水镇",
        "450722102": "石埇镇",
        "450722103": "安石镇",
        "450722104": "张黄镇",
        "450722105": "大成镇",
        "450722106": "白石水镇",
        "450722107": "北通镇",
        "450722108": "三合镇",
        "450722109": "龙门镇",
        "450722110": "福旺镇",
        "450722111": "寨圩镇",
        "450722112": "乐民镇",
        "450722113": "六硍镇",
        "450722114": "平睦镇",
        "450722115": "官垌镇"
    },
    "450800": {"450802": "港北区", "450803": "港南区", "450804": "覃塘区", "450821": "平南县", "450881": "桂平市"},
    "450802": {
        "450802001": "贵城街道",
        "450802002": "港城街道",
        "450802101": "大圩镇",
        "450802102": "庆丰镇",
        "450802103": "根竹镇",
        "450802104": "武乐镇",
        "450802200": "奇石乡",
        "450802201": "中里乡"
    },
    "450803": {
        "450803001": "江南街道",
        "450803002": "八塘街道",
        "450803100": "桥圩镇",
        "450803101": "木格镇",
        "450803102": "木梓镇",
        "450803103": "湛江镇",
        "450803104": "东津镇",
        "450803106": "新塘镇",
        "450803107": "瓦塘镇"
    },
    "450804": {
        "450804001": "覃塘街道",
        "450804101": "东龙镇",
        "450804102": "三里镇",
        "450804103": "黄练镇",
        "450804104": "石卡镇",
        "450804105": "五里镇",
        "450804106": "樟木镇",
        "450804107": "蒙公镇",
        "450804200": "山北乡",
        "450804204": "大岭乡"
    },
    "450821": {
        "450821001": "平南街道",
        "450821002": "上渡街道",
        "450821101": "平山镇",
        "450821102": "寺面镇",
        "450821103": "六陈镇",
        "450821104": "大新镇",
        "450821105": "大安镇",
        "450821106": "武林镇",
        "450821107": "大坡镇",
        "450821108": "大洲镇",
        "450821109": "镇隆镇",
        "450821112": "安怀镇",
        "450821113": "丹竹镇",
        "450821114": "官成镇",
        "450821115": "思旺镇",
        "450821116": "大鹏镇",
        "450821117": "同和镇",
        "450821118": "东华镇",
        "450821204": "思界乡",
        "450821205": "国安瑶族乡",
        "450821206": "马练瑶族乡"
    },
    "450881": {
        "450881101": "木乐镇",
        "450881102": "木圭镇",
        "450881103": "石咀镇",
        "450881104": "油麻镇",
        "450881105": "社坡镇",
        "450881106": "罗秀镇",
        "450881107": "麻垌镇",
        "450881108": "社步镇",
        "450881109": "下湾镇",
        "450881110": "木根镇",
        "450881111": "中沙镇",
        "450881113": "大洋镇",
        "450881114": "大湾镇",
        "450881115": "白沙镇",
        "450881116": "石龙镇",
        "450881117": "蒙圩镇",
        "450881118": "西山镇",
        "450881119": "南木镇",
        "450881120": "江口镇",
        "450881121": "金田镇",
        "450881122": "紫荆镇",
        "450881200": "马皮乡",
        "450881202": "寻旺乡",
        "450881203": "罗播乡",
        "450881204": "厚禄乡",
        "450881206": "垌心乡"
    },
    "450900": {
        "450902": "玉州区",
        "450903": "福绵区",
        "450921": "容县",
        "450922": "陆川县",
        "450923": "博白县",
        "450924": "兴业县",
        "450981": "北流市"
    },
    "450902": {
        "450902001": "玉城街道",
        "450902002": "南江街道",
        "450902003": "城西街道",
        "450902004": "城北街道",
        "450902005": "名山街道",
        "450902100": "大塘镇",
        "450902105": "茂林镇",
        "450902106": "仁东镇",
        "450902113": "仁厚镇"
    },
    "450903": {
        "450903107": "福绵镇",
        "450903108": "成均镇",
        "450903109": "樟木镇",
        "450903110": "新桥镇",
        "450903111": "沙田镇",
        "450903112": "石和镇"
    },
    "450921": {
        "450921100": "容州镇",
        "450921102": "杨梅镇",
        "450921103": "灵山镇",
        "450921104": "六王镇",
        "450921105": "黎村镇",
        "450921106": "杨村镇",
        "450921107": "县底镇",
        "450921108": "自良镇",
        "450921109": "松山镇",
        "450921110": "罗江镇",
        "450921111": "石头镇",
        "450921112": "石寨镇",
        "450921113": "十里镇",
        "450921114": "容西镇",
        "450921115": "浪水镇"
    },
    "450922": {
        "450922101": "温泉镇",
        "450922102": "米场镇",
        "450922103": "马坡镇",
        "450922104": "珊罗镇",
        "450922105": "平乐镇",
        "450922106": "沙坡镇",
        "450922107": "大桥镇",
        "450922108": "乌石镇",
        "450922109": "良田镇",
        "450922110": "清湖镇",
        "450922111": "古城镇",
        "450922112": "沙湖镇",
        "450922113": "横山镇",
        "450922114": "滩面镇"
    },
    "450923": {
        "450923100": "博白镇",
        "450923102": "双凤镇",
        "450923105": "顿谷镇",
        "450923106": "水鸣镇",
        "450923107": "那林镇",
        "450923108": "江宁镇",
        "450923109": "三滩镇",
        "450923110": "黄凌镇",
        "450923111": "亚山镇",
        "450923112": "旺茂镇",
        "450923114": "东平镇",
        "450923115": "沙河镇",
        "450923116": "菱角镇",
        "450923117": "新田镇",
        "450923118": "凤山镇",
        "450923119": "宁潭镇",
        "450923120": "文地镇",
        "450923121": "英桥镇",
        "450923122": "那卜镇",
        "450923123": "大垌镇",
        "450923124": "沙陂镇",
        "450923125": "双旺镇",
        "450923126": "松旺镇",
        "450923127": "龙潭镇",
        "450923128": "大坝镇",
        "450923129": "永安镇",
        "450923130": "径口镇",
        "450923131": "浪平镇"
    },
    "450924": {
        "450924100": "石南镇",
        "450924101": "大平山镇",
        "450924102": "葵阳镇",
        "450924103": "城隍镇",
        "450924104": "山心镇",
        "450924105": "沙塘镇",
        "450924106": "蒲塘镇",
        "450924107": "北市镇",
        "450924108": "龙安镇",
        "450924109": "高峰镇",
        "450924110": "小平山镇",
        "450924111": "卖酒镇",
        "450924112": "洛阳镇"
    },
    "450981": {
        "450981001": "陵城街道",
        "450981002": "城南街道",
        "450981003": "城北街道",
        "450981100": "北流镇",
        "450981101": "新荣镇",
        "450981102": "民安镇",
        "450981103": "山围镇",
        "450981104": "民乐镇",
        "450981105": "西埌镇",
        "450981106": "新圩镇",
        "450981107": "大里镇",
        "450981108": "塘岸镇",
        "450981109": "清水口镇",
        "450981110": "隆盛镇",
        "450981111": "大坡外镇",
        "450981112": "六麻镇",
        "450981113": "新丰镇",
        "450981114": "沙垌镇",
        "450981115": "平政镇",
        "450981116": "白马镇",
        "450981117": "大伦镇",
        "450981118": "扶新镇",
        "450981119": "六靖镇",
        "450981120": "石窝镇",
        "450981121": "清湾镇"
    },
    "451000": {
        "451002": "右江区",
        "451021": "田阳县",
        "451022": "田东县",
        "451023": "平果县",
        "451024": "德保县",
        "451026": "那坡县",
        "451027": "凌云县",
        "451028": "乐业县",
        "451029": "田林县",
        "451030": "西林县",
        "451031": "隆林各族自治县",
        "451081": "靖西市"
    },
    "451002": {
        "451002001": "百城街道",
        "451002002": "龙景街道",
        "451002101": "阳圩镇",
        "451002102": "四塘镇",
        "451002103": "龙川镇",
        "451002104": "永乐镇",
        "451002202": "汪甸瑶族乡",
        "451002203": "大楞乡",
        "451002204": "泮水乡"
    },
    "451021": {
        "451021100": "田州镇",
        "451021101": "那坡镇",
        "451021102": "坡洪镇",
        "451021103": "那满镇",
        "451021104": "百育镇",
        "451021105": "玉凤镇",
        "451021106": "头塘镇",
        "451021107": "五村镇",
        "451021108": "洞靖镇",
        "451021204": "巴别乡"
    },
    "451022": {
        "451022100": "平马镇",
        "451022101": "祥周镇",
        "451022103": "林逢镇",
        "451022105": "思林镇",
        "451022106": "印茶镇",
        "451022107": "江城镇",
        "451022108": "朔良镇",
        "451022109": "义圩镇",
        "451022110": "那拔镇",
        "451022201": "作登瑶族乡"
    },
    "451023": {
        "451023100": "马头镇",
        "451023101": "新安镇",
        "451023102": "果化镇",
        "451023103": "太平镇",
        "451023104": "坡造镇",
        "451023105": "四塘镇",
        "451023106": "旧城镇",
        "451023107": "榜圩镇",
        "451023108": "凤梧镇",
        "451023200": "海城乡",
        "451023202": "黎明乡",
        "451023203": "同老乡",
        "451023450": "平果铝"
    },
    "451024": {
        "451024100": "城关镇",
        "451024101": "足荣镇",
        "451024102": "隆桑镇",
        "451024103": "敬德镇",
        "451024104": "马隘镇",
        "451024105": "东凌镇",
        "451024106": "那甲镇",
        "451024200": "都安乡",
        "451024203": "荣华乡",
        "451024204": "燕峒乡",
        "451024205": "龙光乡",
        "451024206": "巴头乡",
        "451024450": "德保铝业"
    },
    "451026": {
        "451026100": "城厢镇",
        "451026101": "平孟镇",
        "451026102": "龙合镇",
        "451026200": "坡荷乡",
        "451026203": "德隆乡",
        "451026204": "百合乡",
        "451026205": "百南乡",
        "451026207": "百省乡",
        "451026208": "百都乡"
    },
    "451027": {
        "451027100": "泗城镇",
        "451027101": "逻楼镇",
        "451027102": "加尤镇",
        "451027103": "下甲镇",
        "451027201": "伶站瑶族乡",
        "451027202": "朝里瑶族乡",
        "451027203": "沙里瑶族乡",
        "451027205": "玉洪瑶族乡"
    },
    "451028": {
        "451028100": "同乐镇",
        "451028101": "甘田镇",
        "451028102": "新化镇",
        "451028103": "花坪镇",
        "451028201": "逻沙乡",
        "451028203": "逻西乡",
        "451028204": "幼平乡",
        "451028205": "雅长乡"
    },
    "451029": {
        "451029100": "乐里镇",
        "451029101": "旧州镇",
        "451029102": "定安镇",
        "451029103": "六隆镇",
        "451029104": "浪平镇",
        "451029200": "潞城瑶族乡",
        "451029201": "利周瑶族乡",
        "451029202": "平塘乡",
        "451029204": "八桂瑶族乡",
        "451029205": "八渡瑶族乡",
        "451029206": "那比乡",
        "451029207": "高龙乡",
        "451029208": "百乐乡",
        "451029209": "者苗乡"
    },
    "451030": {
        "451030100": "八达镇",
        "451030101": "古障镇",
        "451030102": "那劳镇",
        "451030103": "马蚌镇",
        "451030203": "普合苗族乡",
        "451030204": "西平乡",
        "451030206": "那佐苗族乡",
        "451030208": "足别瑶族苗族乡"
    },
    "451031": {
        "451031100": "新州镇",
        "451031101": "桠杈镇",
        "451031102": "天生桥镇",
        "451031103": "平班镇",
        "451031104": "德峨镇",
        "451031105": "隆或镇",
        "451031200": "沙梨乡",
        "451031203": "者保乡",
        "451031204": "者浪乡",
        "451031205": "革步乡",
        "451031206": "金钟山乡",
        "451031208": "猪场乡",
        "451031210": "蛇场乡",
        "451031211": "克长乡",
        "451031213": "岩茶乡",
        "451031215": "介廷乡"
    },
    "451081": {
        "451081100": "新靖镇",
        "451081101": "化峒镇",
        "451081102": "湖润镇",
        "451081103": "安德镇",
        "451081104": "龙临镇",
        "451081105": "渠洋镇",
        "451081106": "岳圩镇",
        "451081107": "龙邦镇",
        "451081108": "禄峒镇",
        "451081109": "武平镇",
        "451081110": "地州镇",
        "451081200": "同德乡",
        "451081201": "壬庄乡",
        "451081202": "安宁乡",
        "451081206": "南坡乡",
        "451081207": "吞盘乡",
        "451081209": "果乐乡",
        "451081211": "新甲乡",
        "451081215": "魁圩乡"
    },
    "451100": {"451102": "八步区", "451103": "平桂区", "451121": "昭平县", "451122": "钟山县", "451123": "富川瑶族自治县"},
    "451102": {
        "451102001": "八步街道",
        "451102002": "城东街道",
        "451102003": "江南街道",
        "451102101": "贺街镇",
        "451102102": "步头镇",
        "451102103": "莲塘镇",
        "451102104": "大宁镇",
        "451102105": "南乡镇",
        "451102106": "桂岭镇",
        "451102107": "开山镇",
        "451102109": "里松镇",
        "451102114": "信都镇",
        "451102115": "灵峰镇",
        "451102116": "仁义镇",
        "451102117": "铺门镇",
        "451102200": "黄洞瑶族乡"
    },
    "451103": {
        "451103001": "西湾街道",
        "451103101": "黄田镇",
        "451103102": "鹅塘镇",
        "451103103": "沙田镇",
        "451103104": "公会镇",
        "451103105": "水口镇",
        "451103106": "望高镇",
        "451103107": "羊头镇",
        "451103201": "大平瑶族乡"
    },
    "451121": {
        "451121100": "昭平镇",
        "451121101": "文竹镇",
        "451121102": "黄姚镇",
        "451121103": "富罗镇",
        "451121104": "北陀镇",
        "451121105": "马江镇",
        "451121107": "五将镇",
        "451121108": "走马镇",
        "451121109": "樟木林镇",
        "451121201": "仙回瑶族乡",
        "451121206": "凤凰乡",
        "451121208": "木格乡"
    },
    "451122": {
        "451122100": "钟山镇",
        "451122104": "回龙镇",
        "451122105": "石龙镇",
        "451122106": "凤翔镇",
        "451122107": "珊瑚镇",
        "451122108": "同古镇",
        "451122109": "公安镇",
        "451122111": "清塘镇",
        "451122112": "燕塘镇",
        "451122113": "红花镇",
        "451122200": "花山瑶族乡",
        "451122201": "两安瑶族乡"
    },
    "451123": {
        "451123100": "富阳镇",
        "451123101": "白沙镇",
        "451123102": "莲山镇",
        "451123103": "古城镇",
        "451123104": "福利镇",
        "451123105": "麦岭镇",
        "451123106": "葛坡镇",
        "451123107": "城北镇",
        "451123108": "朝东镇",
        "451123200": "新华乡",
        "451123201": "石家乡",
        "451123203": "柳家乡"
    },
    "451200": {
        "451202": "金城江区",
        "451203": "宜州区",
        "451221": "南丹县",
        "451222": "天峨县",
        "451223": "凤山县",
        "451224": "东兰县",
        "451225": "罗城仫佬族自治县",
        "451226": "环江毛南族自治县",
        "451227": "巴马瑶族自治县",
        "451228": "都安瑶族自治县",
        "451229": "大化瑶族自治县"
    },
    "451202": {
        "451202001": "金城江街道",
        "451202101": "东江镇",
        "451202102": "六圩镇",
        "451202103": "六甲镇",
        "451202104": "河池镇",
        "451202105": "拔贡镇",
        "451202106": "九圩镇",
        "451202107": "五圩镇",
        "451202200": "白土乡",
        "451202203": "侧岭乡",
        "451202204": "保平乡",
        "451202205": "长老乡"
    },
    "451203": {
        "451203100": "庆远镇",
        "451203101": "三岔镇",
        "451203102": "洛西镇",
        "451203103": "怀远镇",
        "451203104": "德胜镇",
        "451203105": "石别镇",
        "451203106": "北山镇",
        "451203107": "刘三姐镇",
        "451203108": "洛东镇",
        "451203200": "祥贝乡",
        "451203203": "屏南乡",
        "451203207": "福龙瑶族乡",
        "451203208": "北牙瑶族乡",
        "451203210": "同德乡",
        "451203211": "安马乡",
        "451203213": "龙头乡"
    },
    "451221": {
        "451221100": "城关镇",
        "451221101": "大厂镇",
        "451221102": "车河镇",
        "451221103": "芒场镇",
        "451221104": "六寨镇",
        "451221105": "月里镇",
        "451221106": "吾隘镇",
        "451221107": "罗富镇",
        "451221202": "中堡苗族乡",
        "451221203": "八圩瑶族乡",
        "451221204": "里湖瑶族乡"
    },
    "451222": {
        "451222100": "六排镇",
        "451222101": "向阳镇",
        "451222200": "岜暮乡",
        "451222201": "八腊瑶族乡",
        "451222203": "纳直乡",
        "451222204": "更新乡",
        "451222206": "下老乡",
        "451222207": "坡结乡",
        "451222208": "三堡乡"
    },
    "451223": {
        "451223100": "凤城镇",
        "451223101": "长洲镇",
        "451223102": "三门海镇",
        "451223201": "砦牙乡",
        "451223203": "乔音乡",
        "451223205": "金牙瑶族乡",
        "451223207": "中亭乡",
        "451223208": "平乐瑶族乡",
        "451223209": "江洲瑶族乡"
    },
    "451224": {
        "451224100": "东兰镇",
        "451224101": "隘洞镇",
        "451224102": "长乐镇",
        "451224103": "三石镇",
        "451224104": "武篆镇",
        "451224105": "长江镇",
        "451224200": "泗孟乡",
        "451224201": "兰木乡",
        "451224203": "巴畴乡",
        "451224204": "金谷乡",
        "451224205": "三弄瑶族乡",
        "451224206": "大同乡",
        "451224207": "花香乡",
        "451224208": "切学乡"
    },
    "451225": {
        "451225100": "东门镇",
        "451225101": "龙岸镇",
        "451225102": "黄金镇",
        "451225103": "小长安镇",
        "451225105": "四把镇",
        "451225106": "天河镇",
        "451225107": "怀群镇",
        "451225200": "宝坛乡",
        "451225202": "乔善乡",
        "451225203": "纳翁乡",
        "451225204": "兼爱乡"
    },
    "451226": {
        "451226100": "思恩镇",
        "451226101": "水源镇",
        "451226102": "洛阳镇",
        "451226103": "川山镇",
        "451226104": "明伦镇",
        "451226105": "东兴镇",
        "451226200": "大才乡",
        "451226202": "下南乡",
        "451226204": "大安乡",
        "451226205": "长美乡",
        "451226206": "龙岩乡",
        "451226207": "驯乐苗族乡"
    },
    "451227": {
        "451227100": "巴马镇",
        "451227101": "甲篆镇",
        "451227102": "燕洞镇",
        "451227202": "那社乡",
        "451227203": "所略乡",
        "451227205": "西山乡",
        "451227207": "东山乡",
        "451227208": "凤凰乡",
        "451227209": "百林乡",
        "451227210": "那桃乡"
    },
    "451228": {
        "451228100": "安阳镇",
        "451228101": "高岭镇",
        "451228102": "地苏镇",
        "451228103": "下坳镇",
        "451228104": "拉烈镇",
        "451228105": "百旺镇",
        "451228106": "澄江镇",
        "451228107": "大兴镇",
        "451228108": "拉仁镇",
        "451228109": "永安镇",
        "451228204": "东庙乡",
        "451228208": "隆福乡",
        "451228209": "保安乡",
        "451228210": "板岭乡",
        "451228212": "三只羊乡",
        "451228213": "龙湾乡",
        "451228214": "菁盛乡",
        "451228217": "加贵乡",
        "451228219": "九渡乡"
    },
    "451229": {
        "451229100": "大化镇",
        "451229101": "都阳镇",
        "451229102": "岩滩镇",
        "451229103": "北景镇",
        "451229200": "共和乡",
        "451229201": "贡川乡",
        "451229202": "百马乡",
        "451229203": "古河乡",
        "451229204": "古文乡",
        "451229205": "江南乡",
        "451229206": "羌圩乡",
        "451229207": "乙圩乡",
        "451229210": "板升乡",
        "451229211": "七百弄乡",
        "451229213": "雅龙乡",
        "451229214": "六也乡"
    },
    "451300": {
        "451302": "兴宾区",
        "451321": "忻城县",
        "451322": "象州县",
        "451323": "武宣县",
        "451324": "金秀瑶族自治县",
        "451381": "合山市"
    },
    "451302": {
        "451302001": "城东街道",
        "451302002": "城北街道",
        "451302003": "河西街道",
        "451302005": "来华街道",
        "451302101": "凤凰镇",
        "451302102": "良江镇",
        "451302103": "小平阳镇",
        "451302104": "迁江镇",
        "451302105": "石陵镇",
        "451302106": "平阳镇",
        "451302107": "蒙村镇",
        "451302108": "大湾镇",
        "451302109": "桥巩镇",
        "451302110": "寺山镇",
        "451302111": "城厢镇",
        "451302112": "三五镇",
        "451302113": "陶邓镇",
        "451302114": "石牙镇",
        "451302202": "五山乡",
        "451302205": "良塘乡",
        "451302206": "七洞乡",
        "451302213": "南泗乡",
        "451302214": "高安乡",
        "451302216": "正龙乡",
        "451302402": "来华投资区"
    },
    "451321": {
        "451321100": "城关镇",
        "451321101": "大塘镇",
        "451321102": "思练镇",
        "451321103": "红渡镇",
        "451321104": "古蓬镇",
        "451321105": "果遂镇",
        "451321201": "马泗乡",
        "451321202": "欧洞乡",
        "451321203": "安东乡",
        "451321205": "新圩乡",
        "451321206": "遂意乡",
        "451321207": "北更乡"
    },
    "451322": {
        "451322100": "象州镇",
        "451322101": "石龙镇",
        "451322102": "运江镇",
        "451322103": "寺村镇",
        "451322104": "中平镇",
        "451322105": "罗秀镇",
        "451322106": "大乐镇",
        "451322107": "马坪镇",
        "451322201": "妙皇乡",
        "451322202": "百丈乡",
        "451322203": "水晶乡"
    },
    "451323": {
        "451323100": "武宣镇",
        "451323101": "桐岭镇",
        "451323102": "通挽镇",
        "451323103": "东乡镇",
        "451323104": "三里镇",
        "451323105": "二塘镇",
        "451323106": "黄茆镇",
        "451323107": "禄新镇",
        "451323108": "思灵镇",
        "451323204": "金鸡乡",
        "451323401": "黔江农场"
    },
    "451324": {
        "451324100": "金秀镇",
        "451324101": "桐木镇",
        "451324102": "头排镇",
        "451324200": "三角乡",
        "451324201": "忠良乡",
        "451324202": "罗香乡",
        "451324203": "长垌乡",
        "451324204": "大樟乡",
        "451324205": "六巷乡",
        "451324207": "三江乡"
    },
    "451381": {"451381100": "岭南镇", "451381101": "北泗镇", "451381102": "河里镇"},
    "451400": {
        "451402": "江州区",
        "451421": "扶绥县",
        "451422": "宁明县",
        "451423": "龙州县",
        "451424": "大新县",
        "451425": "天等县",
        "451481": "凭祥市"
    },
    "451402": {
        "451402001": "太平街道",
        "451402002": "江南街道",
        "451402003": "石景林街道",
        "451402101": "新和镇",
        "451402102": "濑湍镇",
        "451402103": "江州镇",
        "451402104": "左州镇",
        "451402105": "那隆镇",
        "451402106": "驮卢镇",
        "451402201": "罗白乡",
        "451402202": "板利乡",
        "451402400": "新和华侨农场",
        "451402401": "左江华侨农场",
        "451402402": "中泰产业园"
    },
    "451421": {
        "451421100": "新宁镇",
        "451421101": "渠黎镇",
        "451421102": "渠旧镇",
        "451421103": "柳桥镇",
        "451421104": "东门镇",
        "451421105": "山圩镇",
        "451421106": "中东镇",
        "451421107": "东罗镇",
        "451421201": "龙头乡",
        "451421202": "岜盆乡",
        "451421203": "昌平乡"
    },
    "451422": {
        "451422100": "城中镇",
        "451422101": "爱店镇",
        "451422102": "明江镇",
        "451422103": "海渊镇",
        "451422104": "桐棉镇",
        "451422105": "那堪镇",
        "451422106": "亭亮镇",
        "451422202": "寨安乡",
        "451422203": "峙浪乡",
        "451422204": "东安乡",
        "451422205": "板棍乡",
        "451422206": "北江乡",
        "451422209": "那楠乡",
        "451422401": "国营宁明华侨农场",
        "451422402": "国营天西华侨农场"
    },
    "451423": {
        "451423100": "龙州镇",
        "451423101": "下冻镇",
        "451423102": "水口镇",
        "451423103": "金龙镇",
        "451423104": "响水镇",
        "451423201": "八角乡",
        "451423202": "上降乡",
        "451423203": "彬桥乡",
        "451423204": "上龙乡",
        "451423205": "武德乡",
        "451423206": "逐卜乡",
        "451423207": "上金乡"
    },
    "451424": {
        "451424100": "桃城镇",
        "451424101": "全茗镇",
        "451424102": "雷平镇",
        "451424103": "硕龙镇",
        "451424104": "下雷镇",
        "451424200": "五山乡",
        "451424201": "龙门乡",
        "451424202": "昌明乡",
        "451424203": "福隆乡",
        "451424204": "那岭乡",
        "451424205": "恩城乡",
        "451424206": "榄圩乡",
        "451424207": "宝圩乡",
        "451424208": "堪圩乡",
        "451424400": "国营桃城华侨农场"
    },
    "451425": {
        "451425100": "天等镇",
        "451425101": "龙茗镇",
        "451425102": "进结镇",
        "451425103": "向都镇",
        "451425104": "东平镇",
        "451425105": "福新镇",
        "451425200": "都康乡",
        "451425201": "宁干乡",
        "451425202": "驮堪乡",
        "451425205": "进远乡",
        "451425206": "上映乡",
        "451425207": "把荷乡",
        "451425208": "小山乡"
    },
    "451481": {
        "451481100": "凭祥镇",
        "451481101": "友谊镇",
        "451481102": "上石镇",
        "451481103": "夏石镇",
        "451481400": "广西凭祥综合保税区"
    },
    "460000": {
        "460100": "海口市",
        "460200": "三亚市",
        "460300": "三沙市",
        "460400": "儋州市",
        "469001": "五指山市",
        "469002": "琼海市",
        "469005": "文昌市",
        "469006": "万宁市",
        "469007": "东方市",
        "469021": "定安县",
        "469022": "屯昌县",
        "469023": "澄迈县",
        "469024": "临高县",
        "469025": "白沙黎族自治县",
        "469026": "昌江黎族自治县",
        "469027": "乐东黎族自治县",
        "469028": "陵水黎族自治县",
        "469029": "保亭黎族苗族自治县",
        "469030": "琼中黎族苗族自治县"
    },
    "460100": {"460105": "秀英区", "460106": "龙华区", "460107": "琼山区", "460108": "美兰区"},
    "460105": {
        "460105001": "秀英街道",
        "460105002": "海秀街道办",
        "460105100": "长流镇",
        "460105101": "西秀镇",
        "460105102": "海秀镇",
        "460105103": "石山镇",
        "460105104": "永兴镇",
        "460105105": "东山镇"
    },
    "460106": {
        "460106001": "中山街道",
        "460106002": "滨海街道",
        "460106003": "金贸街道",
        "460106004": "大同街道",
        "460106005": "海垦街道",
        "460106006": "金宇街道",
        "460106100": "城西镇",
        "460106101": "龙桥镇",
        "460106102": "新坡镇",
        "460106103": "遵谭镇",
        "460106104": "龙泉镇"
    },
    "460107": {
        "460107001": "国兴街道办",
        "460107002": "府城街道办",
        "460107003": "滨江街道办",
        "460107004": "凤翔街道办",
        "460107101": "龙塘镇",
        "460107102": "云龙镇",
        "460107103": "红旗镇",
        "460107104": "三门坡镇",
        "460107105": "大坡镇",
        "460107106": "甲子镇",
        "460107107": "旧州镇",
        "460107500": "省岭脚热带作物场",
        "460107501": "省长昌煤矿"
    },
    "460108": {
        "460108001": "海府街道",
        "460108002": "蓝天街道",
        "460108003": "博爱街道",
        "460108004": "海甸街道",
        "460108005": "人民路街道",
        "460108006": "白龙街道",
        "460108007": "和平南街道",
        "460108008": "白沙街道",
        "460108009": "新埠街道",
        "460108101": "灵山镇",
        "460108102": "演丰镇",
        "460108103": "三江镇",
        "460108104": "大致坡镇",
        "460108400": "海口桂林洋经济开发区（农场）",
        "460108500": "罗牛山农场"
    },
    "460200": {
        "460201100": "海棠湾镇",
        "460201101": "吉阳镇",
        "460201102": "凤凰镇",
        "460201103": "崖城镇",
        "460201104": "天涯镇",
        "460201105": "育才镇",
        "460201400": "国营南田农场",
        "460201401": "国营南新农场",
        "460201403": "国营立才农场",
        "460201404": "国营南滨农场",
        "460201451": "河西区街道",
        "460201452": "河东区街道"
    },
    "460202": {"460202198": "海棠湾镇"},
    "460203": {"460203198": "吉阳镇"},
    "460204": {"460204198": "天涯镇"},
    "460205": {"460205198": "崖城镇"},
    "460300": {"460321": "西沙群岛", "460322": "南沙群岛", "460323": "中沙群岛的岛礁及其海域"},
    "460321": {"460321451": "永兴管委会", "460321452": "永乐群岛管委会", "460321453": "七连屿管委会"},
    "460322": {"460322451": "永暑岛"},
    "460323": {"460323451": "中沙岛礁"},
    "460400": {
        "460400100": "那大镇",
        "460400101": "和庆镇",
        "460400102": "南丰镇",
        "460400103": "大成镇",
        "460400104": "雅星镇",
        "460400105": "兰洋镇",
        "460400106": "光村镇",
        "460400107": "木棠镇",
        "460400108": "海头镇",
        "460400109": "峨蔓镇",
        "460400111": "王五镇",
        "460400112": "白马井镇",
        "460400113": "中和镇",
        "460400114": "排浦镇",
        "460400115": "东成镇",
        "460400116": "新州镇",
        "460400499": "洋浦经济开发区",
        "460400500": "华南热作学院"
    },
    "460400100": {
        "460400100001": "西干社区居委会",
        "460400100002": "大同社区居委会",
        "460400100003": "铺仔社区居委会",
        "460400100004": "解放社区居委会",
        "460400100005": "东风社区居委会",
        "460400100006": "胜利社区居委会",
        "460400100007": "先锋社区居委会",
        "460400100008": "群英社区居委会",
        "460400100009": "东干社区居委会",
        "460400100010": "东兴社区居委会",
        "460400100011": "城北社区居委会",
        "460400100012": "西联居居委会",
        "460400100200": "石屋村委会",
        "460400100201": "侨南村委会",
        "460400100202": "白南村委会",
        "460400100203": "红旗村委会",
        "460400100204": "茶山村委会",
        "460400100205": "王桐村委会",
        "460400100206": "横岭村委会",
        "460400100207": "头潭村委会",
        "460400100208": "蔬菜村委会",
        "460400100209": "清平村委会",
        "460400100210": "那恁村委会",
        "460400100211": "军屯村委会",
        "460400100212": "洛基村委会",
        "460400100213": "槟榔村委会",
        "460400100214": "洛南村委会",
        "460400100215": "抱龙村委会",
        "460400100216": "力崖村委会",
        "460400100217": "屋基村委会",
        "460400100218": "番真村委会",
        "460400100219": "加平村委会",
        "460400100220": "美扶村委会",
        "460400100500": "前进农场",
        "460400100501": "牙拉农场",
        "460400100502": "合罗农场",
        "460400100503": "尖岭农场",
        "460400100504": "先锋农场",
        "460400100505": "侨锋农场",
        "460400100506": "侨值农场",
        "460400100507": "南辰农场"
    },
    "460400101": {
        "460400101001": "和庆社区居委会",
        "460400101002": "西流居居委会",
        "460400101200": "美万村委会",
        "460400101201": "美灵村委会",
        "460400101202": "和祥村委会",
        "460400101203": "新村村委会",
        "460400101204": "拱教村委会",
        "460400101205": "文卷村委会",
        "460400101206": "木排村委会",
        "460400101207": "美敖村委会",
        "460400101209": "罗便村委会",
        "460400101210": "美万新村委会",
        "460400101500": "和庆农场",
        "460400101501": "木排热作场"
    },
    "460400102": {
        "460400102001": "南丰社区居委会",
        "460400102200": "南丰村委会",
        "460400102201": "尖岭村委会",
        "460400102202": "头佑村委会",
        "460400102203": "陶江村委会",
        "460400102204": "武教村委会",
        "460400102205": "松门村委会",
        "460400102206": "油麻村委会",
        "460400102207": "马岭苗族村委会",
        "460400102208": "新村村委会",
        "460400102209": "油文村委会",
        "460400102500": "南丰农场"
    },
    "460400103": {
        "460400103001": "大成居委会",
        "460400103002": "工交居委会",
        "460400103003": "西培居居委会",
        "460400103004": "西华居居委会",
        "460400103005": "西庆居居委会",
        "460400103200": "调南村委会",
        "460400103201": "新风村委会",
        "460400103202": "推赛村委会",
        "460400103203": "可运村委会",
        "460400103204": "小岭村委会",
        "460400103206": "新营村委会",
        "460400103207": "道隆村委会",
        "460400103208": "南乐村委会",
        "460400103210": "南盛村委会",
        "460400103211": "南园村委会",
        "460400103212": "瑞图村委会",
        "460400103213": "新兰村委会",
        "460400103214": "公司村委会",
        "460400103215": "江南村委会",
        "460400103216": "红灯村委会",
        "460400103217": "可沟村委会",
        "460400103218": "岛村村委会",
        "460400103219": "新龙村委会",
        "460400103220": "大星村委会",
        "460400103500": "镇农场"
    },
    "460400104": {
        "460400104001": "雅星社区居委会",
        "460400104002": "富街社区居委会",
        "460400104003": "八一居居委会",
        "460400104004": "金川居居委会",
        "460400104005": "英岛居居委会",
        "460400104006": "东山居居委会",
        "460400104007": "长岭居居委会",
        "460400104008": "春江居居委会",
        "460400104009": "工矿居居委会",
        "460400104010": "红岭居居委会",
        "460400104200": "飞巴村委会",
        "460400104201": "文丰村委会",
        "460400104202": "新让黎族村委会",
        "460400104203": "雅星黎族村委会",
        "460400104204": "庙陀村委会",
        "460400104205": "新隆村委会",
        "460400104206": "白鱼塘村委会",
        "460400104207": "富仍村委会",
        "460400104208": "乐贺村委会",
        "460400104209": "乐满村委会",
        "460400104210": "富克村委会",
        "460400104211": "大讲村委会",
        "460400104212": "大沟村委会",
        "460400104213": "调打村委会",
        "460400104214": "合罗村委会",
        "460400104215": "和盛村委会",
        "460400104216": "栖榕村委会",
        "460400104217": "田头村委会",
        "460400104218": "文山村委会",
        "460400104500": "东光农场",
        "460400104501": "东方农场",
        "460400104502": "茶山农场",
        "460400104503": "陀骂村委会"
    },
    "460400105": {
        "460400105001": "兰泉社区居委会",
        "460400105002": "蓝洋居居委会",
        "460400105200": "兰洋村委会",
        "460400105201": "水南村委会",
        "460400105202": "大塘村委会",
        "460400105203": "番开村委会",
        "460400105204": "南报村委会",
        "460400105205": "头竹村委会",
        "460400105206": "南罗村委会",
        "460400105207": "加老村委会",
        "460400105208": "海孔村委会",
        "460400105209": "番加村委会",
        "460400105210": "番新村委会",
        "460400105211": "番打村委会",
        "460400105212": "番雅村委会",
        "460400105213": "三雅村委会",
        "460400105500": "兰兴农场",
        "460400105501": "鹿母湾林场",
        "460400105502": "番加农场"
    },
    "460400106": {
        "460400106001": "永昌社区居委会",
        "460400106002": "泊潮社区居委会",
        "460400106003": "新盈居居委会",
        "460400106200": "新隆村委会",
        "460400106201": "光红村委会",
        "460400106202": "屯积村委会",
        "460400106203": "巨雄村委会",
        "460400106204": "挺进村委会",
        "460400106205": "新地村委会",
        "460400106206": "扁墩村委会",
        "460400106207": "欧宅村委会",
        "460400106208": "大老村委会",
        "460400106209": "沙井村委会"
    },
    "460400107": {
        "460400107200": "李坊村委会",
        "460400107201": "梁宅村委会",
        "460400107202": "薛屋村委会",
        "460400107203": "荣谋村委会",
        "460400107204": "苏宅村委会",
        "460400107205": "大文村委会",
        "460400107206": "陈坊村委会",
        "460400107207": "铁匠村委会",
        "460400107208": "春坡村委会",
        "460400107209": "木棠村委会",
        "460400107210": "王坊村委会",
        "460400107211": "积万村委会",
        "460400107212": "蒌根村委会",
        "460400107213": "高堂村委会",
        "460400107214": "谭乐村委会",
        "460400107215": "神冲村委会",
        "460400107216": "长老村委会",
        "460400107217": "周坊村委会",
        "460400107218": "东方村委会",
        "460400107219": "大域村委会",
        "460400107220": "二图村委会",
        "460400107221": "美龙村委会",
        "460400107222": "塘坎村委会",
        "460400107223": "兰训村委会",
        "460400107224": "道南村委会"
    },
    "460400108": {
        "460400108001": "港口社区居委会",
        "460400108002": "南港社区居委会",
        "460400108003": "新市社区居委会",
        "460400108004": "德立社区居委会",
        "460400108200": "那历村委会",
        "460400108201": "珠江村委会",
        "460400108202": "加乐村委会",
        "460400108203": "岛村村委会",
        "460400108204": "七柏榔村委会",
        "460400108205": "红坎村委会",
        "460400108206": "岭地村委会",
        "460400108207": "新洋村委会",
        "460400108208": "洋加东村委会",
        "460400108209": "红洋村委会"
    },
    "460400109": {
        "460400109200": "峨蔓村委会",
        "460400109201": "盐丁村委会",
        "460400109202": "王坡村委会",
        "460400109203": "长荣村委会",
        "460400109204": "田井村委会",
        "460400109205": "多业村委会",
        "460400109206": "多美村委会",
        "460400109207": "片石村委会",
        "460400109208": "茅园村委会",
        "460400109209": "黄沙村委会",
        "460400109210": "龙门村委会",
        "460400109211": "高根村委会",
        "460400109212": "笔架村委会"
    },
    "460400111": {
        "460400111001": "王五社区居委会",
        "460400111200": "山营村委会",
        "460400111201": "徐浦村委会",
        "460400111202": "小千村委会",
        "460400111203": "流方村委会",
        "460400111204": "新坊村委会",
        "460400111205": "东光村委会",
        "460400111206": "光村村委会",
        "460400111207": "新地村委会"
    },
    "460400112": {
        "460400112001": "胜利社区居委会",
        "460400112002": "解放社区居委会",
        "460400112003": "海滨社区居委会",
        "460400112004": "东方社区居委会",
        "460400112005": "新街社区居委会",
        "460400112006": "新马社区居委会",
        "460400112007": "南司居委会",
        "460400112200": "福村村委会",
        "460400112201": "藤根村委会",
        "460400112202": "禾囊村委会",
        "460400112203": "马口井村委会",
        "460400112204": "钱地村委会",
        "460400112205": "竹古村委会",
        "460400112206": "山花村委会",
        "460400112207": "寨基村委会",
        "460400112208": "南庄村委会",
        "460400112209": "松鸣村委会",
        "460400112210": "英丰村委会",
        "460400112211": "旧地村委会",
        "460400112212": "兰城村委会",
        "460400112213": "学兰村委会",
        "460400112214": "东山村委会"
    },
    "460400113": {
        "460400113001": "中和社区居委会",
        "460400113200": "高第村委会",
        "460400113201": "水井村委会",
        "460400113202": "环龙村委会",
        "460400113203": "和平村委会",
        "460400113204": "山春村委会",
        "460400113205": "灵春村委会",
        "460400113206": "横山村委会",
        "460400113207": "五里村委会",
        "460400113208": "七里村委会",
        "460400113209": "长村村委会",
        "460400113210": "黄江村委会"
    },
    "460400114": {
        "460400114001": "排浦社区居委会",
        "460400114002": "龙山居居委会",
        "460400114200": "禾丰村委会",
        "460400114201": "沙沟村委会",
        "460400114202": "南华村委会",
        "460400114203": "昌王村委会",
        "460400114204": "瓜兰村委会",
        "460400114205": "黑石村委会",
        "460400114206": "春花村委会"
    },
    "460400115": {
        "460400115001": "长坡社区居委会",
        "460400115200": "茅坡村委会",
        "460400115201": "吴村村委会",
        "460400115202": "中心村委会",
        "460400115203": "周坊村委会",
        "460400115204": "大坡村委会",
        "460400115205": "迈格村委会",
        "460400115206": "里仁村委会",
        "460400115207": "流坡村委会",
        "460400115208": "书村村委会",
        "460400115209": "东成村委会",
        "460400115210": "番陈村委会",
        "460400115211": "平地村委会",
        "460400115212": "寨脚村委会",
        "460400115213": "高荣村委会",
        "460400115214": "加悦村委会",
        "460400115215": "洪山村委会",
        "460400115216": "崖碧村委会",
        "460400115217": "文柏村委会",
        "460400115218": "抱舍村委会"
    },
    "460400116": {
        "460400116001": "马墩社区居委会",
        "460400116004": "唐庙巷社区居委会",
        "460400116005": "东方头社区居委会",
        "460400116006": "新街社区居委会",
        "460400116007": "周庙巷社区居委会",
        "460400116008": "世禄巷社区居委会",
        "460400116009": "中巷社区居委会",
        "460400116010": "西境社区居委会",
        "460400116011": "黄塘社区居委会",
        "460400116012": "新州社区居委会",
        "460400116013": "新坊社区居委会",
        "460400116200": "荣上村委会",
        "460400116201": "英均村委会",
        "460400116202": "蓝田村委会",
        "460400116203": "黄玉村委会",
        "460400116204": "新地村委会",
        "460400116205": "英隆村委会",
        "460400116206": "攀步村委会",
        "460400116207": "南岸村委会",
        "460400116208": "英进村委会",
        "460400116209": "敦教村委会",
        "460400116210": "大屯村委会",
        "460400116211": "黄村村委会",
        "460400116212": "腾阳村委会",
        "460400116213": "宣泮村委会",
        "460400116214": "盐场村委会",
        "460400116215": "宝山村委会",
        "460400116216": "长塘村委会",
        "460400116217": "西边村委会",
        "460400116218": "泮山村委会",
        "460400116500": "儋州林场"
    },
    "460400499": {
        "460400499001": "南便社区居委会",
        "460400499002": "儒兰社区居委会",
        "460400499003": "厦兰社区居委会",
        "460400499004": "海勤社区居委会",
        "460400499005": "洋浦社区居委会",
        "460400499006": "东临社区居委会",
        "460400499007": "春鸣社区居委会",
        "460400499008": "五山社区居委会",
        "460400499009": "咸塘社区居委会",
        "460400499010": "白沙社区居委会",
        "460400499011": "高山社区居委会",
        "460400499012": "公堂下社区居委会",
        "460400499013": "太平社区居委会",
        "460400499014": "共呜社区居委会",
        "460400499015": "港区社区居委会",
        "460400499016": "新英湾社区居委会",
        "460400499017": "新都社区居委会",
        "460400499018": "新兴社区居委会",
        "460400499200": "三都村委会",
        "460400499201": "南滩村委会",
        "460400499202": "漾月村委会",
        "460400499203": "旧州村委会",
        "460400499204": "德义村委会",
        "460400499205": "西照村委会",
        "460400499206": "冠英村委会",
        "460400499207": "棠柏村委会",
        "460400499208": "颜村村委会"
    },
    "460400500": {"460400500498": "华南热作学院虚拟社区"},
    "469001": {
        "469001100": "通什镇",
        "469001101": "南圣镇",
        "469001102": "毛阳镇",
        "469001103": "番阳镇",
        "469001200": "畅好乡",
        "469001201": "毛道乡",
        "469001202": "水满乡",
        "469001400": "国营畅好农场"
    },
    "469002": {
        "469002100": "嘉积镇",
        "469002101": "万泉镇",
        "469002102": "石壁镇",
        "469002103": "中原镇",
        "469002104": "博鳌镇",
        "469002105": "阳江镇",
        "469002106": "龙江镇",
        "469002107": "潭门镇",
        "469002108": "塔洋镇",
        "469002109": "长坡镇",
        "469002110": "大路镇",
        "469002111": "会山镇",
        "469002400": "国营东太农场",
        "469002402": "国营东红农场",
        "469002403": "国营东升农场",
        "469002500": "彬村山华侨农场"
    },
    "469003": {
        "469003100": "那大镇",
        "469003101": "和庆镇",
        "469003102": "南丰镇",
        "469003103": "大成镇",
        "469003104": "雅星镇",
        "469003105": "兰洋镇",
        "469003106": "光村镇",
        "469003107": "木棠镇",
        "469003108": "海头镇",
        "469003109": "峨蔓镇",
        "469003110": "三都镇",
        "469003111": "王五镇",
        "469003112": "白马井镇",
        "469003113": "中和镇",
        "469003114": "排浦镇",
        "469003115": "东成镇",
        "469003116": "新州镇",
        "469003400": "国营西培农场",
        "469003404": "国营西联农场",
        "469003405": "国营蓝洋农场",
        "469003407": "国营八一农场",
        "469003499": "洋浦经济开发区",
        "469003500": "华南热作学院"
    },
    "469005": {
        "469005100": "文城镇",
        "469005101": "重兴镇",
        "469005102": "蓬莱镇",
        "469005103": "会文镇",
        "469005104": "东路镇",
        "469005105": "潭牛镇",
        "469005106": "东阁镇",
        "469005107": "文教镇",
        "469005108": "东郊镇",
        "469005109": "龙楼镇",
        "469005110": "昌洒镇",
        "469005111": "翁田镇",
        "469005112": "抱罗镇",
        "469005113": "冯坡镇",
        "469005114": "锦山镇",
        "469005115": "铺前镇",
        "469005116": "公坡镇",
        "469005400": "国营东路农场",
        "469005401": "国营南阳农场",
        "469005402": "国营罗豆农场"
    },
    "469006": {
        "469006100": "万城镇",
        "469006101": "龙滚镇",
        "469006102": "和乐镇",
        "469006103": "后安镇",
        "469006104": "大茂镇",
        "469006105": "东澳镇",
        "469006106": "礼纪镇",
        "469006107": "长丰镇",
        "469006108": "山根镇",
        "469006109": "北大镇",
        "469006110": "南桥镇",
        "469006111": "三更罗镇",
        "469006400": "国营东兴农场",
        "469006401": "国营东和农场",
        "469006404": "国营新中农场",
        "469006500": "兴隆华侨农场",
        "469006501": "地方国营六连林场"
    },
    "469007": {
        "469007100": "八所镇",
        "469007101": "东河镇",
        "469007102": "大田镇",
        "469007103": "感城镇",
        "469007104": "板桥镇",
        "469007105": "三家镇",
        "469007106": "四更镇",
        "469007107": "新龙镇",
        "469007200": "天安乡",
        "469007201": "江边乡",
        "469007400": "国营广坝农场",
        "469007500": "东方华侨农场"
    },
    "469021": {
        "469021100": "定城镇",
        "469021101": "新竹镇",
        "469021102": "龙湖镇",
        "469021103": "黄竹镇",
        "469021104": "雷鸣镇",
        "469021105": "龙门镇",
        "469021106": "龙河镇",
        "469021107": "岭口镇",
        "469021108": "翰林镇",
        "469021109": "富文镇"
    },
    "469022": {
        "469022100": "屯城镇",
        "469022101": "新兴镇",
        "469022102": "枫木镇",
        "469022103": "乌坡镇",
        "469022104": "南吕镇",
        "469022105": "南坤镇",
        "469022106": "坡心镇",
        "469022107": "西昌镇"
    },
    "469023": {
        "469023100": "金江镇",
        "469023101": "老城镇",
        "469023102": "瑞溪镇",
        "469023103": "永发镇",
        "469023104": "加乐镇",
        "469023105": "文儒镇",
        "469023106": "中兴镇",
        "469023107": "仁兴镇",
        "469023108": "福山镇",
        "469023109": "桥头镇",
        "469023110": "大丰镇",
        "469023405": "国营金安农场"
    },
    "469024": {
        "469024100": "临城镇",
        "469024101": "波莲镇",
        "469024102": "东英镇",
        "469024103": "博厚镇",
        "469024104": "皇桐镇",
        "469024105": "多文镇",
        "469024106": "和舍镇",
        "469024107": "南宝镇",
        "469024108": "新盈镇",
        "469024109": "调楼镇",
        "469024401": "国营加来农场"
    },
    "469025": {
        "469025100": "牙叉镇",
        "469025101": "七坊镇",
        "469025102": "邦溪镇",
        "469025103": "打安镇",
        "469025200": "细水乡",
        "469025201": "元门乡",
        "469025202": "南开乡",
        "469025203": "阜龙乡",
        "469025204": "青松乡",
        "469025205": "金波乡",
        "469025206": "荣邦乡"
    },
    "469026": {
        "469026100": "石碌镇",
        "469026101": "叉河镇",
        "469026102": "十月田镇",
        "469026103": "乌烈镇",
        "469026104": "昌化镇",
        "469026105": "海尾镇",
        "469026106": "七叉镇",
        "469026200": "王下乡",
        "469026500": "国营霸王岭林场"
    },
    "469027": {
        "469027100": "抱由镇",
        "469027101": "万冲镇",
        "469027102": "大安镇",
        "469027103": "志仲镇",
        "469027104": "千家镇",
        "469027105": "九所镇",
        "469027106": "利国镇",
        "469027107": "黄流镇",
        "469027108": "佛罗镇",
        "469027109": "尖峰镇",
        "469027110": "莺歌海镇",
        "469027500": "国营尖峰岭林业公司",
        "469027501": "国营莺歌海盐场"
    },
    "469028": {
        "469028100": "椰林镇",
        "469028101": "光坡镇",
        "469028102": "三才镇",
        "469028103": "英州镇",
        "469028104": "隆广镇",
        "469028105": "文罗镇",
        "469028106": "本号镇",
        "469028107": "新村镇",
        "469028108": "黎安镇",
        "469028200": "提蒙乡",
        "469028201": "群英乡",
        "469028500": "国营吊罗山林业公司"
    },
    "469029": {
        "469029100": "保城镇",
        "469029101": "什玲镇",
        "469029102": "加茂镇",
        "469029103": "响水镇",
        "469029104": "新政镇",
        "469029105": "三道镇",
        "469029198": "新星镇",
        "469029200": "六弓乡",
        "469029201": "南林乡",
        "469029202": "毛感乡"
    },
    "469030": {
        "469030100": "营根镇",
        "469030101": "湾岭镇",
        "469030102": "黎母山镇",
        "469030103": "和平镇",
        "469030104": "长征镇",
        "469030105": "红毛镇",
        "469030106": "中平镇",
        "469030200": "吊罗山乡",
        "469030201": "上安乡",
        "469030202": "什运乡",
        "469030500": "海南黎母山省级自然保护区管理站"
    },
    "500000": {"500100": "重庆市"},
    "500100": {
        "500101": "万州区",
        "500102": "涪陵区",
        "500103": "渝中区",
        "500104": "大渡口区",
        "500105": "江北区",
        "500106": "沙坪坝区",
        "500107": "九龙坡区",
        "500108": "南岸区",
        "500109": "北碚区",
        "500110": "綦江区",
        "500111": "大足区",
        "500112": "渝北区",
        "500113": "巴南区",
        "500114": "黔江区",
        "500115": "长寿区",
        "500116": "江津区",
        "500117": "合川区",
        "500118": "永川区",
        "500119": "南川区",
        "500120": "璧山区",
        "500151": "铜梁区",
        "500152": "潼南区",
        "500153": "荣昌区",
        "500154": "开州区",
        "500155": "梁平区",
        "500156": "武隆区",
        "500229": "城口县",
        "500230": "丰都县",
        "500231": "垫江县",
        "500233": "忠县",
        "500235": "云阳县",
        "500236": "奉节县",
        "500237": "巫山县",
        "500238": "巫溪县",
        "500240": "石柱土家族自治县",
        "500241": "秀山土家族苗族自治县",
        "500242": "酉阳土家族苗族自治县",
        "500243": "彭水苗族土家族自治县"
    },
    "500101": {
        "500101001": "高笋塘街道",
        "500101017": "太白街道",
        "500101018": "牌楼街道",
        "500101019": "双河口街道",
        "500101020": "龙都街道",
        "500101021": "周家坝街道",
        "500101022": "沙河街道",
        "500101023": "钟鼓楼街道",
        "500101024": "百安坝街道",
        "500101025": "五桥街道",
        "500101026": "陈家坝街道",
        "500101109": "小周镇",
        "500101110": "大周镇",
        "500101123": "新乡镇",
        "500101132": "孙家镇",
        "500101134": "高峰镇",
        "500101135": "龙沙镇",
        "500101136": "响水镇",
        "500101137": "武陵镇",
        "500101138": "瀼渡镇",
        "500101139": "甘宁镇",
        "500101140": "天城镇",
        "500101141": "熊家镇",
        "500101142": "高梁镇",
        "500101143": "李河镇",
        "500101144": "分水镇",
        "500101145": "余家镇",
        "500101146": "后山镇",
        "500101147": "弹子镇",
        "500101148": "长岭镇",
        "500101149": "新田镇",
        "500101150": "白羊镇",
        "500101151": "龙驹镇",
        "500101152": "走马镇",
        "500101153": "罗田镇",
        "500101154": "太龙镇",
        "500101155": "长滩镇",
        "500101156": "太安镇",
        "500101157": "白土镇",
        "500101158": "郭村镇",
        "500101201": "柱山乡",
        "500101212": "铁峰乡",
        "500101225": "溪口乡",
        "500101226": "长坪乡",
        "500101227": "燕山乡",
        "500101234": "梨树乡",
        "500101237": "普子乡",
        "500101238": "地宝土家族乡",
        "500101239": "恒合土家族乡",
        "500101244": "黄柏乡",
        "500101245": "九池乡",
        "500101247": "茨竹乡"
    },
    "500102": {
        "500102006": "敦仁街道",
        "500102007": "崇义街道",
        "500102008": "荔枝街道",
        "500102009": "江北街道",
        "500102010": "江东街道",
        "500102011": "李渡街道",
        "500102012": "龙桥街道",
        "500102013": "白涛街道",
        "500102014": "马鞍街道",
        "500102103": "南沱镇",
        "500102107": "青羊镇",
        "500102118": "百胜镇",
        "500102119": "珍溪镇",
        "500102120": "清溪镇",
        "500102121": "焦石镇",
        "500102122": "马武镇",
        "500102123": "龙潭镇",
        "500102124": "蔺市镇",
        "500102125": "新妙镇",
        "500102126": "石沱镇",
        "500102127": "义和镇",
        "500102204": "罗云乡",
        "500102206": "大木乡",
        "500102208": "武陵山乡",
        "500102216": "大顺乡",
        "500102217": "增福乡",
        "500102222": "同乐乡"
    },
    "500103": {
        "500103001": "七星岗街道",
        "500103003": "解放碑街道",
        "500103004": "两路口街道",
        "500103005": "上清寺街道",
        "500103007": "菜园坝街道",
        "500103008": "南纪门街道",
        "500103010": "朝天门街道",
        "500103011": "大溪沟街道",
        "500103012": "大坪街道",
        "500103013": "化龙桥街道",
        "500103014": "石油路街道"
    },
    "500104": {
        "500104001": "新山村街道",
        "500104002": "跃进村街道",
        "500104003": "九宫庙街道",
        "500104004": "茄子溪街道",
        "500104005": "春晖路街道",
        "500104101": "八桥镇",
        "500104102": "建胜镇",
        "500104103": "跳磴镇"
    },
    "500105": {
        "500105001": "华新街街道",
        "500105002": "江北城街道",
        "500105003": "石马河街道",
        "500105004": "大石坝街道",
        "500105005": "寸滩街道",
        "500105006": "观音桥街道",
        "500105007": "五里店街道",
        "500105008": "郭家沱街道",
        "500105009": "铁山坪街道",
        "500105100": "鱼嘴镇人民政府",
        "500105101": "复盛镇人民政府",
        "500105102": "五宝镇人民政府"
    },
    "500106": {
        "500106001": "小龙坎街道",
        "500106002": "沙坪坝街道",
        "500106003": "渝碚路街道",
        "500106004": "磁器口街道",
        "500106005": "童家桥街道",
        "500106006": "石井坡街道",
        "500106007": "双碑街道",
        "500106008": "井口街道",
        "500106009": "歌乐山街道",
        "500106010": "山洞街道",
        "500106011": "新桥街道",
        "500106012": "天星桥街道",
        "500106013": "土湾街道",
        "500106014": "覃家岗街道",
        "500106015": "陈家桥街道",
        "500106016": "虎溪街道",
        "500106017": "西永街道",
        "500106018": "联芳街道",
        "500106019": "丰文街道",
        "500106020": "香炉山街道",
        "500106102": "井口镇",
        "500106103": "歌乐山镇",
        "500106104": "青木关镇",
        "500106105": "凤凰镇",
        "500106106": "回龙坝镇",
        "500106108": "曾家镇",
        "500106111": "土主镇",
        "500106112": "中梁镇"
    },
    "500107": {
        "500107001": "杨家坪街道",
        "500107002": "黄桷坪街道",
        "500107003": "谢家湾街道",
        "500107004": "石坪桥街道",
        "500107005": "石桥铺街道",
        "500107006": "中梁山街道",
        "500107007": "渝州路街道",
        "500107008": "二郎街道",
        "500107100": "九龙镇",
        "500107102": "华岩镇",
        "500107103": "含谷镇",
        "500107104": "金凤镇",
        "500107105": "白市驿镇",
        "500107106": "走马镇",
        "500107107": "石板镇",
        "500107108": "巴福镇",
        "500107109": "陶家镇",
        "500107110": "西彭镇",
        "500107111": "铜罐驿镇"
    },
    "500108": {
        "500108001": "铜元局街道",
        "500108002": "花园路街道",
        "500108003": "南坪街道",
        "500108004": "海棠溪街道",
        "500108005": "龙门浩街道",
        "500108006": "弹子石街道",
        "500108007": "南山街道",
        "500108008": "天文街道",
        "500108101": "南坪镇",
        "500108102": "涂山镇",
        "500108103": "鸡冠石镇",
        "500108105": "峡口镇",
        "500108106": "长生桥镇",
        "500108107": "迎龙镇",
        "500108108": "广阳镇"
    },
    "500109": {
        "500109001": "天生街道",
        "500109002": "朝阳街道",
        "500109003": "北温泉街道",
        "500109004": "东阳街道",
        "500109005": "龙凤桥街道",
        "500109006": "蔡家岗街道",
        "500109007": "歇马街道",
        "500109008": "水土街道",
        "500109009": "复兴街道",
        "500109102": "澄江镇",
        "500109104": "童家溪镇",
        "500109105": "天府镇",
        "500109108": "施家梁镇",
        "500109110": "静观镇",
        "500109111": "柳荫镇",
        "500109113": "三圣镇",
        "500109116": "金刀峡镇"
    },
    "500110": {
        "500110001": "古南街道",
        "500110002": "文龙街道",
        "500110003": "三江街道",
        "500110004": "万盛街道",
        "500110005": "东林街道",
        "500110100": "万东镇",
        "500110101": "南桐镇",
        "500110102": "青年镇",
        "500110103": "关坝镇",
        "500110104": "丛林镇",
        "500110105": "石林镇",
        "500110106": "金桥镇",
        "500110107": "黑山镇",
        "500110108": "石角镇",
        "500110109": "东溪镇",
        "500110110": "赶水镇",
        "500110111": "打通镇",
        "500110112": "石壕镇",
        "500110113": "永新镇",
        "500110114": "三角镇",
        "500110115": "隆盛镇",
        "500110116": "郭扶镇",
        "500110117": "篆塘镇",
        "500110118": "丁山镇",
        "500110119": "安稳镇",
        "500110120": "扶欢镇",
        "500110121": "永城镇",
        "500110122": "新盛镇",
        "500110123": "中峰镇",
        "500110124": "横山镇"
    },
    "500111": {
        "500111001": "龙岗街道",
        "500111002": "棠香街道",
        "500111003": "龙滩子街道",
        "500111004": "双路街道",
        "500111005": "通桥街道",
        "500111006": "智凤街道",
        "500111100": "龙水镇",
        "500111102": "宝顶镇",
        "500111103": "中敖镇",
        "500111104": "三驱镇",
        "500111105": "宝兴镇",
        "500111106": "玉龙镇",
        "500111107": "石马镇",
        "500111108": "拾万镇",
        "500111109": "回龙镇",
        "500111110": "金山镇",
        "500111111": "万古镇",
        "500111112": "国梁镇",
        "500111113": "雍溪镇",
        "500111114": "珠溪镇",
        "500111115": "龙石镇",
        "500111116": "邮亭镇",
        "500111117": "铁山镇",
        "500111118": "高升镇",
        "500111119": "季家镇",
        "500111120": "古龙镇",
        "500111121": "高坪镇"
    },
    "500112": {
        "500112002": "双龙湖街道",
        "500112004": "回兴街道",
        "500112007": "鸳鸯街道",
        "500112008": "翠云街道",
        "500112009": "人和街道",
        "500112010": "天宫殿街道",
        "500112011": "龙溪街道",
        "500112012": "龙山街道",
        "500112013": "龙塔街道",
        "500112014": "大竹林街道",
        "500112015": "悦来街道",
        "500112016": "两路街道",
        "500112017": "双凤桥街道",
        "500112018": "王家街道",
        "500112019": "礼嘉街道",
        "500112020": "宝圣湖街道",
        "500112021": "金山街道",
        "500112022": "康美街道",
        "500112023": "仙桃街道",
        "500112129": "玉峰山镇",
        "500112131": "龙兴镇",
        "500112133": "统景镇",
        "500112135": "大湾镇",
        "500112138": "兴隆镇",
        "500112139": "木耳镇",
        "500112140": "茨竹镇",
        "500112141": "古路镇",
        "500112142": "石船镇",
        "500112143": "大盛镇",
        "500112144": "洛碛镇"
    },
    "500113": {
        "500113003": "龙洲湾街道",
        "500113004": "鱼洞街道",
        "500113005": "花溪街道",
        "500113006": "李家沱街道",
        "500113007": "南泉街道",
        "500113008": "一品街道",
        "500113009": "南彭街道",
        "500113010": "惠民街道",
        "500113103": "界石镇",
        "500113107": "安澜镇",
        "500113108": "圣灯山镇",
        "500113109": "木洞镇",
        "500113110": "双河口镇",
        "500113111": "麻柳嘴镇",
        "500113112": "丰盛镇",
        "500113113": "二圣镇",
        "500113114": "东温泉镇",
        "500113115": "姜家镇",
        "500113116": "天星寺镇",
        "500113117": "接龙镇",
        "500113118": "石滩镇",
        "500113119": "石龙镇"
    },
    "500114": {
        "500114001": "城东街道",
        "500114002": "城南街道",
        "500114003": "城西街道",
        "500114004": "正阳街道",
        "500114005": "舟白街道",
        "500114006": "冯家街道",
        "500114105": "阿蓬江镇",
        "500114106": "石会镇",
        "500114107": "黑溪镇",
        "500114108": "黄溪镇",
        "500114109": "黎水镇",
        "500114110": "金溪镇",
        "500114111": "马喇镇",
        "500114112": "濯水镇",
        "500114113": "石家镇",
        "500114114": "鹅池镇",
        "500114117": "小南海镇",
        "500114119": "邻鄂镇",
        "500114245": "中塘乡",
        "500114246": "蓬东乡",
        "500114247": "沙坝乡",
        "500114248": "白石乡",
        "500114249": "杉岭乡",
        "500114250": "太极乡",
        "500114251": "水田乡",
        "500114252": "白土乡",
        "500114253": "金洞乡",
        "500114254": "五里乡",
        "500114255": "水市乡",
        "500114256": "新华乡"
    },
    "500115": {
        "500115001": "凤城街道",
        "500115002": "晏家街道",
        "500115003": "江南街道",
        "500115004": "渡舟街道",
        "500115005": "新市街道",
        "500115006": "八颗街道",
        "500115007": "菩提街道",
        "500115123": "邻封镇",
        "500115124": "但渡镇",
        "500115125": "云集镇",
        "500115126": "长寿湖镇",
        "500115127": "双龙镇",
        "500115128": "龙河镇",
        "500115129": "石堰镇",
        "500115130": "云台镇",
        "500115131": "海棠镇",
        "500115132": "葛兰镇",
        "500115136": "洪湖镇",
        "500115137": "万顺镇"
    },
    "500116": {
        "500116001": "几江街道",
        "500116002": "德感街道",
        "500116004": "双福街道",
        "500116005": "鼎山街道",
        "500116006": "圣泉街道",
        "500116101": "油溪镇",
        "500116102": "吴滩镇",
        "500116103": "石门镇",
        "500116104": "朱杨镇",
        "500116105": "石蟆镇",
        "500116106": "永兴镇",
        "500116107": "塘河镇",
        "500116108": "白沙镇",
        "500116109": "龙华镇",
        "500116110": "李市镇",
        "500116111": "慈云镇",
        "500116112": "蔡家镇",
        "500116113": "中山镇",
        "500116114": "嘉平镇",
        "500116115": "柏林镇",
        "500116116": "先锋镇",
        "500116117": "珞璜镇",
        "500116118": "贾嗣镇",
        "500116119": "夏坝镇",
        "500116120": "西湖镇",
        "500116121": "杜市镇",
        "500116122": "广兴镇",
        "500116123": "四面山镇",
        "500116124": "支坪镇",
        "500116125": "四屏镇"
    },
    "500117": {
        "500117001": "合阳城街道",
        "500117002": "钓鱼城街道",
        "500117003": "南津街街道",
        "500117004": "盐井街道",
        "500117005": "草街街道",
        "500117006": "云门街道",
        "500117007": "大石街道",
        "500117100": "沙鱼镇",
        "500117101": "官渡镇",
        "500117102": "涞滩镇",
        "500117103": "肖家镇",
        "500117104": "古楼镇",
        "500117105": "三庙镇",
        "500117106": "二郎镇",
        "500117107": "龙凤镇",
        "500117108": "隆兴镇",
        "500117109": "铜溪镇",
        "500117112": "双凤镇",
        "500117113": "狮滩镇",
        "500117114": "清平镇",
        "500117115": "土场镇",
        "500117116": "小沔镇",
        "500117117": "三汇镇",
        "500117118": "香龙镇",
        "500117120": "钱塘镇",
        "500117121": "龙市镇",
        "500117123": "燕窝镇",
        "500117124": "太和镇",
        "500117125": "渭沱镇",
        "500117126": "双槐镇"
    },
    "500118": {
        "500118001": "中山路街道",
        "500118002": "胜利路街道",
        "500118003": "南大街街道",
        "500118004": "茶山竹海街道",
        "500118005": "大安街道",
        "500118006": "陈食街道",
        "500118007": "卫星湖街道",
        "500118100": "青峰镇",
        "500118102": "金龙镇",
        "500118104": "临江镇",
        "500118106": "何埂镇",
        "500118107": "松溉镇",
        "500118108": "仙龙镇",
        "500118109": "吉安镇",
        "500118110": "五间镇",
        "500118111": "来苏镇",
        "500118112": "宝峰镇",
        "500118113": "双石镇",
        "500118114": "红炉镇",
        "500118115": "永荣镇",
        "500118116": "三教镇",
        "500118117": "板桥镇",
        "500118118": "朱沱镇"
    },
    "500119": {
        "500119001": "东城街道",
        "500119002": "南城街道",
        "500119003": "西城街道",
        "500119100": "三泉镇",
        "500119101": "南平镇",
        "500119102": "神童镇",
        "500119103": "鸣玉镇",
        "500119104": "大观镇",
        "500119105": "兴隆镇",
        "500119106": "太平场镇",
        "500119107": "白沙镇",
        "500119108": "水江镇",
        "500119109": "石墙镇",
        "500119110": "金山镇",
        "500119111": "头渡镇",
        "500119112": "大有镇",
        "500119113": "合溪镇",
        "500119114": "黎香湖镇",
        "500119115": "山王坪镇",
        "500119116": "木凉镇",
        "500119117": "楠竹山镇",
        "500119118": "石溪镇",
        "500119119": "德隆镇",
        "500119120": "民主镇",
        "500119121": "福寿镇",
        "500119122": "河图镇",
        "500119123": "庆元镇",
        "500119124": "古花镇",
        "500119125": "石莲镇",
        "500119126": "乾丰镇",
        "500119127": "骑龙镇",
        "500119128": "冷水关镇",
        "500119207": "中桥乡",
        "500119212": "峰岩乡"
    },
    "500120": {
        "500120001": "璧城街道",
        "500120002": "璧泉街道",
        "500120003": "青杠街道",
        "500120004": "来凤街道",
        "500120005": "丁家街道",
        "500120006": "大路街道",
        "500120100": "八塘镇",
        "500120101": "七塘镇",
        "500120102": "河边镇",
        "500120103": "福禄镇",
        "500120104": "大兴镇",
        "500120105": "正兴镇",
        "500120106": "广普镇",
        "500120107": "三合镇",
        "500120108": "健龙镇"
    },
    "500151": {
        "500151001": "巴川街道",
        "500151002": "东城街道",
        "500151003": "南城街道",
        "500151004": "蒲吕街道",
        "500151005": "旧县街道",
        "500151101": "土桥镇",
        "500151103": "二坪镇",
        "500151104": "水口镇",
        "500151105": "安居镇",
        "500151106": "白羊镇",
        "500151107": "平滩镇",
        "500151108": "小林镇",
        "500151109": "双山镇",
        "500151110": "虎峰镇",
        "500151111": "石鱼镇",
        "500151112": "福果镇",
        "500151113": "庆隆镇",
        "500151114": "少云镇",
        "500151115": "维新镇",
        "500151116": "高楼镇",
        "500151117": "大庙镇",
        "500151118": "围龙镇",
        "500151119": "华兴镇",
        "500151120": "永嘉镇",
        "500151121": "安溪镇",
        "500151122": "西河镇",
        "500151123": "侣俸镇",
        "500151124": "太平镇"
    },
    "500152": {
        "500152001": "桂林街道",
        "500152002": "梓潼街道",
        "500152101": "上和镇",
        "500152102": "龙形镇",
        "500152103": "古溪镇",
        "500152104": "宝龙镇",
        "500152107": "玉溪镇",
        "500152108": "米心镇",
        "500152109": "群力镇",
        "500152110": "双江镇",
        "500152112": "花岩镇",
        "500152113": "柏梓镇",
        "500152114": "崇龛镇",
        "500152115": "塘坝镇",
        "500152116": "新胜镇",
        "500152117": "太安镇",
        "500152119": "小渡镇",
        "500152120": "卧佛镇",
        "500152121": "五桂镇",
        "500152122": "田家镇",
        "500152123": "别口镇",
        "500152124": "寿桥镇"
    },
    "500153": {
        "500153001": "昌元街道",
        "500153002": "昌州街道",
        "500153003": "广顺街道",
        "500153004": "双河街道",
        "500153005": "安富街道",
        "500153006": "峰高街道",
        "500153100": "荣隆镇",
        "500153101": "仁义镇",
        "500153102": "盘龙镇",
        "500153103": "吴家镇",
        "500153104": "直升镇",
        "500153105": "万灵镇",
        "500153106": "清升镇",
        "500153107": "清江镇",
        "500153108": "古昌镇",
        "500153109": "河包镇",
        "500153110": "观胜镇",
        "500153111": "铜鼓镇",
        "500153112": "清流镇",
        "500153113": "远觉镇",
        "500153114": "龙集镇"
    },
    "500154": {
        "500154001": "汉丰街道",
        "500154002": "文峰街道",
        "500154003": "云枫街道",
        "500154004": "镇东街道",
        "500154005": "丰乐街道",
        "500154006": "白鹤街道",
        "500154007": "赵家街道",
        "500154100": "大德镇",
        "500154101": "镇安镇",
        "500154102": "厚坝镇",
        "500154103": "金峰镇",
        "500154104": "温泉镇",
        "500154105": "郭家镇",
        "500154106": "白桥镇",
        "500154107": "和谦镇",
        "500154108": "河堰镇",
        "500154109": "大进镇",
        "500154110": "谭家镇",
        "500154111": "敦好镇",
        "500154112": "高桥镇",
        "500154113": "九龙山镇",
        "500154114": "天和镇",
        "500154115": "中和镇",
        "500154116": "义和镇",
        "500154117": "临江镇",
        "500154118": "竹溪镇",
        "500154119": "铁桥镇",
        "500154120": "南雅镇",
        "500154121": "巫山镇",
        "500154122": "岳溪镇",
        "500154123": "长沙镇",
        "500154124": "南门镇",
        "500154125": "渠口镇",
        "500154200": "满月乡",
        "500154201": "关面乡",
        "500154202": "白泉乡",
        "500154203": "麻柳乡",
        "500154204": "紫水乡",
        "500154205": "三汇口乡",
        "500154206": "五通乡"
    },
    "500155": {
        "500155001": "梁山街道",
        "500155002": "双桂街道",
        "500155101": "仁贤镇",
        "500155102": "礼让镇",
        "500155103": "云龙镇",
        "500155104": "屏锦镇",
        "500155106": "袁驿镇",
        "500155107": "新盛镇",
        "500155108": "福禄镇",
        "500155109": "金带镇",
        "500155110": "聚奎镇",
        "500155111": "明达镇",
        "500155112": "荫平镇",
        "500155113": "和林镇",
        "500155114": "回龙镇",
        "500155115": "碧山镇",
        "500155116": "虎城镇",
        "500155117": "七星镇",
        "500155118": "龙门镇",
        "500155119": "文化镇",
        "500155120": "合兴镇",
        "500155121": "石安镇",
        "500155122": "柏家镇",
        "500155123": "大观镇",
        "500155124": "竹山镇",
        "500155125": "蟠龙镇",
        "500155126": "星桥镇",
        "500155127": "曲水镇",
        "500155128": "安胜镇",
        "500155129": "复平镇",
        "500155130": "紫照镇",
        "500155201": "铁门乡",
        "500155202": "龙胜乡",
        "500155400": "梁平区农场",
        "500155401": "梁平区双桂工业园区"
    },
    "500156": {
        "500156001": "凤山街道",
        "500156002": "芙蓉街道",
        "500156101": "白马镇",
        "500156102": "江口镇",
        "500156103": "仙女山镇",
        "500156104": "火炉镇",
        "500156105": "鸭江镇",
        "500156106": "羊角镇",
        "500156107": "长坝镇",
        "500156108": "平桥镇",
        "500156109": "桐梓镇",
        "500156110": "土坎镇",
        "500156111": "和顺镇",
        "500156112": "双河镇",
        "500156200": "凤来乡",
        "500156201": "庙垭乡",
        "500156202": "石桥苗族土家族乡",
        "500156203": "黄莺乡",
        "500156204": "沧沟乡",
        "500156205": "文复苗族土家族乡",
        "500156206": "土地乡",
        "500156207": "白云乡",
        "500156208": "后坪苗族土家族乡",
        "500156209": "浩口苗族仡佬族乡",
        "500156210": "接龙乡",
        "500156211": "赵家乡",
        "500156212": "大洞河乡"
    },
    "500229": {
        "500229001": "葛城街道",
        "500229002": "复兴街道",
        "500229102": "巴山镇",
        "500229103": "坪坝镇",
        "500229104": "庙坝镇",
        "500229105": "明通镇",
        "500229106": "修齐镇",
        "500229107": "高观镇",
        "500229108": "高燕镇",
        "500229109": "东安镇",
        "500229110": "咸宜镇",
        "500229111": "高楠镇",
        "500229201": "龙田乡",
        "500229202": "北屏乡",
        "500229205": "左岚乡",
        "500229208": "沿河乡",
        "500229210": "双河乡",
        "500229211": "蓼子乡",
        "500229212": "鸡鸣乡",
        "500229214": "周溪乡",
        "500229216": "明中乡",
        "500229217": "治平乡",
        "500229219": "岚天乡",
        "500229220": "厚坪乡",
        "500229221": "河鱼乡"
    },
    "500230": {
        "500230001": "三合街道",
        "500230002": "名山街道",
        "500230101": "虎威镇",
        "500230102": "社坛镇",
        "500230103": "三元镇",
        "500230104": "许明寺镇",
        "500230105": "董家镇",
        "500230106": "树人镇",
        "500230107": "十直镇",
        "500230109": "高家镇",
        "500230110": "兴义镇",
        "500230111": "双路镇",
        "500230112": "江池镇",
        "500230113": "龙河镇",
        "500230114": "武平镇",
        "500230115": "包鸾镇",
        "500230116": "湛普镇",
        "500230118": "南天湖镇",
        "500230119": "保合镇",
        "500230120": "兴龙镇",
        "500230121": "仁沙镇",
        "500230122": "龙孔镇",
        "500230123": "暨龙镇",
        "500230124": "双龙镇",
        "500230125": "仙女湖镇",
        "500230202": "青龙乡",
        "500230206": "太平坝乡",
        "500230207": "都督乡",
        "500230209": "栗子乡",
        "500230210": "三建乡"
    },
    "500231": {
        "500231001": "桂溪街道",
        "500231002": "桂阳街道",
        "500231101": "新民镇",
        "500231102": "沙坪镇",
        "500231103": "周嘉镇",
        "500231104": "普顺镇",
        "500231105": "永安镇",
        "500231106": "高安镇",
        "500231107": "高峰镇",
        "500231108": "五洞镇",
        "500231109": "澄溪镇",
        "500231110": "太平镇",
        "500231111": "鹤游镇",
        "500231112": "坪山镇",
        "500231113": "砚台镇",
        "500231114": "曹回镇",
        "500231115": "杠家镇",
        "500231116": "包家镇",
        "500231117": "白家镇",
        "500231118": "永平镇",
        "500231119": "三溪镇",
        "500231120": "裴兴镇",
        "500231121": "黄沙镇",
        "500231122": "长龙镇",
        "500231202": "沙河乡",
        "500231204": "大石乡"
    },
    "500233": {
        "500233001": "忠州街道",
        "500233002": "白公街道",
        "500233003": "乌杨街道",
        "500233004": "新生街道",
        "500233102": "任家镇",
        "500233104": "洋渡镇",
        "500233105": "东溪镇",
        "500233106": "复兴镇",
        "500233107": "石宝镇",
        "500233108": "汝溪镇",
        "500233109": "野鹤镇",
        "500233110": "官坝镇",
        "500233111": "石黄镇",
        "500233112": "马灌镇",
        "500233113": "金鸡镇",
        "500233114": "新立镇",
        "500233115": "双桂镇",
        "500233116": "拔山镇",
        "500233117": "花桥镇",
        "500233118": "永丰镇",
        "500233119": "三汇镇",
        "500233120": "白石镇",
        "500233122": "黄金镇",
        "500233201": "善广乡",
        "500233203": "石子乡",
        "500233204": "磨子土家族乡",
        "500233206": "涂井乡",
        "500233208": "金声乡",
        "500233210": "兴峰乡"
    },
    "500235": {
        "500235001": "双江街道",
        "500235002": "青龙街道",
        "500235003": "人和街道",
        "500235004": "盘龙街道",
        "500235105": "龙角镇",
        "500235107": "故陵镇",
        "500235108": "红狮镇",
        "500235115": "路阳镇",
        "500235116": "农坝镇",
        "500235118": "渠马镇",
        "500235121": "黄石镇",
        "500235122": "巴阳镇",
        "500235123": "沙市镇",
        "500235124": "鱼泉镇",
        "500235125": "凤鸣镇",
        "500235127": "宝坪镇",
        "500235128": "南溪镇",
        "500235129": "双土镇",
        "500235130": "桑坪镇",
        "500235131": "江口镇",
        "500235132": "高阳镇",
        "500235133": "平安镇",
        "500235135": "云阳镇",
        "500235136": "云安镇",
        "500235137": "栖霞镇",
        "500235138": "双龙镇",
        "500235139": "泥溪镇",
        "500235140": "蔈草镇",
        "500235141": "养鹿镇",
        "500235142": "水口镇",
        "500235143": "堰坪镇",
        "500235144": "龙洞镇",
        "500235145": "后叶镇",
        "500235146": "耀灵镇",
        "500235147": "大阳镇",
        "500235208": "外郎乡",
        "500235215": "新津乡",
        "500235216": "普安乡",
        "500235218": "洞鹿乡",
        "500235219": "石门乡",
        "500235239": "上坝乡",
        "500235242": "清水土家族自治乡"
    },
    "500236": {
        "500236001": "永安街道",
        "500236002": "鱼复街道",
        "500236003": "夔门街道",
        "500236117": "白帝镇",
        "500236118": "草堂镇",
        "500236119": "汾河镇",
        "500236120": "康乐镇",
        "500236121": "大树镇",
        "500236122": "竹园镇",
        "500236123": "公平镇",
        "500236124": "朱衣镇",
        "500236125": "甲高镇",
        "500236126": "羊市镇",
        "500236127": "吐祥镇",
        "500236128": "兴隆镇",
        "500236129": "青龙镇",
        "500236130": "新民镇",
        "500236131": "永乐镇",
        "500236132": "安坪镇",
        "500236133": "五马镇",
        "500236134": "青莲镇",
        "500236265": "岩湾乡",
        "500236266": "平安乡",
        "500236267": "红土乡",
        "500236269": "石岗乡",
        "500236270": "康坪乡",
        "500236272": "太和土家族乡",
        "500236274": "鹤峰乡",
        "500236275": "冯坪乡",
        "500236276": "长安土家族乡",
        "500236277": "龙桥土家族乡",
        "500236278": "云雾土家族乡"
    },
    "500237": {
        "500237001": "高唐街道",
        "500237002": "龙门街道",
        "500237101": "庙宇镇",
        "500237102": "大昌镇",
        "500237103": "福田镇",
        "500237104": "龙溪镇",
        "500237105": "双龙镇",
        "500237106": "官阳镇",
        "500237107": "骡坪镇",
        "500237108": "抱龙镇",
        "500237109": "官渡镇",
        "500237110": "铜鼓镇",
        "500237111": "巫峡镇",
        "500237200": "红椿乡",
        "500237207": "两坪乡",
        "500237208": "曲尺乡",
        "500237210": "建坪乡",
        "500237211": "大溪乡",
        "500237214": "金坪乡",
        "500237216": "平河乡",
        "500237219": "当阳乡",
        "500237222": "竹贤乡",
        "500237225": "三溪乡",
        "500237227": "培石乡",
        "500237229": "笃坪乡",
        "500237231": "邓家乡"
    },
    "500238": {
        "500238001": "宁河街道",
        "500238002": "柏杨街道",
        "500238100": "城厢镇",
        "500238101": "凤凰镇",
        "500238102": "宁厂镇",
        "500238103": "上磺镇",
        "500238104": "古路镇",
        "500238105": "文峰镇",
        "500238106": "徐家镇",
        "500238107": "白鹿镇",
        "500238108": "尖山镇",
        "500238109": "下堡镇",
        "500238110": "峰灵镇",
        "500238111": "塘坊镇",
        "500238112": "朝阳镇",
        "500238113": "田坝镇",
        "500238114": "通城镇",
        "500238115": "菱角镇",
        "500238116": "蒲莲镇",
        "500238117": "土城镇",
        "500238118": "红池坝镇",
        "500238204": "胜利乡",
        "500238207": "大河乡",
        "500238208": "天星乡",
        "500238210": "长桂乡",
        "500238226": "鱼鳞乡",
        "500238227": "乌龙乡",
        "500238237": "花台乡",
        "500238239": "兰英乡",
        "500238240": "双阳乡",
        "500238242": "中梁乡",
        "500238243": "天元乡",
        "500238400": "红池坝经济开发区"
    },
    "500240": {
        "500240001": "南宾街道",
        "500240002": "万安街道",
        "500240003": "下路街道",
        "500240101": "西沱镇",
        "500240103": "悦崃镇",
        "500240104": "临溪镇",
        "500240105": "黄水镇",
        "500240106": "马武镇",
        "500240107": "沙子镇",
        "500240108": "王场镇",
        "500240109": "沿溪镇",
        "500240110": "龙沙镇",
        "500240111": "鱼池镇",
        "500240112": "三河镇",
        "500240113": "大歇镇",
        "500240114": "桥头镇",
        "500240115": "万朝镇",
        "500240116": "冷水镇",
        "500240117": "黄鹤镇",
        "500240118": "枫木镇",
        "500240203": "黎场乡",
        "500240204": "三星乡",
        "500240205": "六塘乡",
        "500240207": "三益乡",
        "500240208": "王家乡",
        "500240209": "河嘴乡",
        "500240210": "石家乡",
        "500240213": "中益乡",
        "500240214": "洗新乡",
        "500240216": "龙潭乡",
        "500240217": "新乐乡",
        "500240218": "金铃乡",
        "500240219": "金竹乡"
    },
    "500241": {
        "500241001": "中和街道",
        "500241002": "乌杨街道",
        "500241003": "平凯街道",
        "500241004": "官庄街道",
        "500241102": "清溪场镇",
        "500241103": "隘口镇",
        "500241104": "溶溪镇",
        "500241106": "龙池镇",
        "500241107": "石堤镇",
        "500241108": "峨溶镇",
        "500241109": "洪安镇",
        "500241110": "雅江镇",
        "500241111": "石耶镇",
        "500241112": "梅江镇",
        "500241113": "兰桥镇",
        "500241114": "膏田镇",
        "500241115": "溪口镇",
        "500241116": "妙泉镇",
        "500241117": "宋农镇",
        "500241118": "里仁镇",
        "500241119": "钟灵镇",
        "500241201": "孝溪乡",
        "500241207": "海洋乡",
        "500241208": "大溪乡",
        "500241211": "涌洞乡",
        "500241214": "中平乡",
        "500241215": "岑溪乡"
    },
    "500242": {
        "500242001": "桃花源街道",
        "500242002": "钟多街道",
        "500242101": "龙潭镇",
        "500242102": "麻旺镇",
        "500242103": "酉酬镇",
        "500242104": "大溪镇",
        "500242105": "兴隆镇",
        "500242106": "黑水镇",
        "500242107": "丁市镇",
        "500242108": "龚滩镇",
        "500242109": "李溪镇",
        "500242110": "泔溪镇",
        "500242111": "酉水河镇",
        "500242112": "苍岭镇",
        "500242113": "小河镇",
        "500242114": "板溪镇",
        "500242115": "涂市镇",
        "500242116": "铜鼓镇",
        "500242117": "五福镇",
        "500242118": "万木镇",
        "500242119": "南腰界镇",
        "500242204": "可大乡",
        "500242205": "偏柏乡",
        "500242207": "木叶乡",
        "500242208": "毛坝乡",
        "500242209": "花田乡",
        "500242210": "后坪乡",
        "500242211": "天馆乡",
        "500242212": "宜居乡",
        "500242214": "两罾乡",
        "500242215": "板桥乡",
        "500242216": "官清乡",
        "500242218": "车田乡",
        "500242219": "腴地乡",
        "500242220": "清泉乡",
        "500242221": "庙溪乡",
        "500242222": "浪坪乡",
        "500242223": "双泉乡",
        "500242224": "楠木乡"
    },
    "500243": {
        "500243001": "汉葭街道",
        "500243002": "绍庆街道",
        "500243003": "靛水街道",
        "500243101": "保家镇",
        "500243102": "郁山镇",
        "500243103": "高谷镇",
        "500243104": "桑柘镇",
        "500243105": "鹿角镇",
        "500243106": "黄家镇",
        "500243107": "普子镇",
        "500243108": "龙射镇",
        "500243109": "连湖镇",
        "500243110": "万足镇",
        "500243111": "平安镇",
        "500243112": "长生镇",
        "500243113": "新田镇",
        "500243114": "鞍子镇",
        "500243115": "太原镇",
        "500243116": "龙溪镇",
        "500243117": "梅子垭镇",
        "500243118": "大同镇",
        "500243201": "岩东乡",
        "500243202": "鹿鸣乡",
        "500243204": "棣棠乡",
        "500243206": "三义乡",
        "500243207": "联合乡",
        "500243208": "石柳乡",
        "500243210": "走马乡",
        "500243211": "芦塘乡",
        "500243213": "乔梓乡",
        "500243217": "诸佛乡",
        "500243219": "桐楼乡",
        "500243222": "善感乡",
        "500243223": "双龙乡",
        "500243224": "石盘乡",
        "500243225": "大垭乡",
        "500243226": "润溪乡",
        "500243227": "朗溪乡",
        "500243228": "龙塘乡"
    },
    "510000": {
        "510100": "成都市",
        "510300": "自贡市",
        "510400": "攀枝花市",
        "510500": "泸州市",
        "510600": "德阳市",
        "510700": "绵阳市",
        "510800": "广元市",
        "510900": "遂宁市",
        "511000": "内江市",
        "511100": "乐山市",
        "511300": "南充市",
        "511400": "眉山市",
        "511500": "宜宾市",
        "511600": "广安市",
        "511700": "达州市",
        "511800": "雅安市",
        "511900": "巴中市",
        "512000": "资阳市",
        "513200": "阿坝藏族羌族自治州",
        "513300": "甘孜藏族自治州",
        "513400": "凉山彝族自治州"
    },
    "510100": {
        "510104": "锦江区",
        "510105": "青羊区",
        "510106": "金牛区",
        "510107": "武侯区",
        "510108": "成华区",
        "510112": "龙泉驿区",
        "510113": "青白江区",
        "510114": "新都区",
        "510115": "温江区",
        "510116": "双流区",
        "510117": "郫都区",
        "510121": "金堂县",
        "510129": "大邑县",
        "510131": "蒲江县",
        "510132": "新津县",
        "510181": "都江堰市",
        "510182": "彭州市",
        "510183": "邛崃市",
        "510184": "崇州市",
        "510185": "简阳市"
    },
    "510104": {
        "510104020": "督院街街道",
        "510104021": "盐市口街道",
        "510104022": "春熙路街道",
        "510104023": "书院街街道",
        "510104024": "合江亭街道",
        "510104025": "水井坊街道",
        "510104026": "牛市口街道",
        "510104027": "龙舟路街道",
        "510104028": "双桂路街道",
        "510104029": "莲新街道",
        "510104030": "沙河街道",
        "510104031": "东光街道",
        "510104032": "狮子山街道",
        "510104035": "成龙路街道",
        "510104036": "柳江街道",
        "510104037": "三圣街道"
    },
    "510105": {
        "510105001": "太升路街道",
        "510105002": "草市街街道",
        "510105003": "西御河街道",
        "510105004": "汪家拐街道",
        "510105005": "少城街道",
        "510105006": "新华西路街道",
        "510105007": "草堂街道",
        "510105008": "府南街道",
        "510105009": "光华街道",
        "510105010": "东坡街道",
        "510105011": "金沙街道",
        "510105012": "黄田坝街道",
        "510105013": "苏坡街道",
        "510105014": "文家街道"
    },
    "510106": {
        "510106024": "西安路街道",
        "510106025": "西华街道",
        "510106026": "人民北路街道",
        "510106027": "荷花池街道",
        "510106030": "驷马桥街道",
        "510106031": "茶店子街道",
        "510106032": "抚琴街道",
        "510106035": "九里堤街道",
        "510106036": "五块石街道",
        "510106037": "黄忠街道",
        "510106038": "营门口街道",
        "510106040": "金泉街道",
        "510106041": "沙河源街道",
        "510106042": "天回镇街道",
        "510106043": "凤凰山街道"
    },
    "510107": {
        "510107001": "浆洗街街道",
        "510107004": "望江路街道",
        "510107005": "玉林街道",
        "510107006": "跳伞塔街道",
        "510107007": "火车南站街道",
        "510107008": "双楠街道",
        "510107009": "晋阳街道",
        "510107010": "红牌楼街道",
        "510107011": "簇桥街道",
        "510107012": "机投桥街道",
        "510107013": "金花桥街道",
        "510107014": "簇锦街道",
        "510107015": "华兴街道",
        "510107061": "芳草街道",
        "510107062": "肖家河街道",
        "510107063": "石羊场街道",
        "510107064": "桂溪街道"
    },
    "510108": {
        "510108002": "猛追湾街道",
        "510108004": "双桥子街道",
        "510108005": "建设路街道",
        "510108006": "府青路街道",
        "510108007": "二仙桥街道",
        "510108008": "跳蹬河街道",
        "510108010": "双水碾街道",
        "510108011": "万年场街道",
        "510108012": "桃蹊路街道",
        "510108013": "圣灯街道",
        "510108014": "保和街道",
        "510108015": "青龙街道",
        "510108016": "龙潭街道",
        "510108017": "白莲池街道"
    },
    "510112": {
        "510112001": "龙泉街道",
        "510112002": "大面街道",
        "510112003": "十陵街道",
        "510112004": "同安街道",
        "510112102": "洛带镇",
        "510112104": "西河镇",
        "510112108": "洪安镇",
        "510112109": "柏合镇",
        "510112110": "茶店镇",
        "510112111": "黄土镇",
        "510112115": "山泉镇",
        "510112200": "万兴乡"
    },
    "510113": {
        "510113001": "红阳街道",
        "510113002": "大弯街道",
        "510113102": "弥牟镇",
        "510113103": "大同镇",
        "510113104": "城厢镇",
        "510113105": "祥福镇",
        "510113106": "姚渡镇",
        "510113108": "清泉镇",
        "510113110": "龙王镇",
        "510113111": "福洪镇",
        "510113203": "人和乡"
    },
    "510114": {
        "510114001": "大丰街道",
        "510114003": "三河街道",
        "510114004": "新都街道",
        "510114102": "石板滩镇",
        "510114103": "新繁镇",
        "510114104": "新民镇",
        "510114106": "泰兴镇",
        "510114108": "斑竹园镇",
        "510114109": "清流镇",
        "510114111": "马家镇",
        "510114112": "龙桥镇",
        "510114114": "木兰镇",
        "510114116": "军屯镇"
    },
    "510115": {
        "510115001": "柳城街道",
        "510115002": "公平街道",
        "510115003": "涌泉街道",
        "510115004": "天府街道",
        "510115101": "和盛镇",
        "510115102": "永盛镇",
        "510115103": "金马镇",
        "510115104": "永宁镇",
        "510115105": "万春镇",
        "510115106": "寿安镇"
    },
    "510116": {
        "510116001": "东升街道办",
        "510116002": "西航港街道办",
        "510116003": "华阳镇街道办",
        "510116004": "中和街道办",
        "510116005": "九江街道",
        "510116006": "黄甲街道",
        "510116007": "公兴街道",
        "510116008": "协和街道",
        "510116101": "太平镇",
        "510116102": "永兴镇",
        "510116106": "籍田镇",
        "510116107": "正兴镇",
        "510116108": "彭镇",
        "510116109": "大林镇",
        "510116110": "煎茶镇",
        "510116111": "黄龙溪镇",
        "510116112": "永安镇",
        "510116115": "黄水镇",
        "510116116": "金桥镇",
        "510116119": "胜利镇",
        "510116120": "新兴镇",
        "510116121": "兴隆镇",
        "510116122": "万安镇",
        "510116123": "白沙镇",
        "510116124": "三星镇",
        "510116125": "合江镇"
    },
    "510117": {
        "510117001": "郫筒街道",
        "510117019": "合作街道",
        "510117020": "西园街道",
        "510117101": "团结镇",
        "510117102": "犀浦镇",
        "510117103": "花园镇",
        "510117104": "唐昌镇",
        "510117105": "安德镇",
        "510117106": "三道堰镇",
        "510117107": "安靖镇",
        "510117108": "红光镇",
        "510117110": "新民场镇",
        "510117112": "德源镇",
        "510117115": "友爱镇",
        "510117117": "古城镇",
        "510117118": "唐元镇"
    },
    "510121": {
        "510121001": "赵镇街道",
        "510121101": "三星镇",
        "510121102": "清江镇",
        "510121103": "官仓镇",
        "510121104": "淮口镇",
        "510121105": "白果镇",
        "510121106": "五凤镇",
        "510121108": "高板镇",
        "510121109": "三溪镇",
        "510121110": "福兴镇",
        "510121111": "金龙镇",
        "510121112": "赵家镇",
        "510121113": "竹篙镇",
        "510121114": "广兴镇",
        "510121115": "隆盛镇",
        "510121116": "转龙镇",
        "510121117": "土桥镇",
        "510121118": "云合镇",
        "510121119": "又新镇",
        "510121200": "栖贤乡",
        "510121203": "平桥乡"
    },
    "510129": {
        "510129001": "晋原街道",
        "510129101": "王泗镇",
        "510129103": "新场镇",
        "510129104": "悦来镇",
        "510129105": "安仁镇",
        "510129106": "出江镇",
        "510129107": "花水湾镇",
        "510129108": "西岭镇",
        "510129109": "斜源镇",
        "510129110": "董场镇",
        "510129111": "韩场镇",
        "510129112": "三岔镇",
        "510129113": "上安镇",
        "510129114": "苏家镇",
        "510129115": "青霞镇",
        "510129116": "沙渠镇",
        "510129118": "蔡场镇",
        "510129202": "雾山乡",
        "510129203": "金星乡",
        "510129205": "鹤鸣乡"
    },
    "510131": {
        "510131001": "鹤山街道",
        "510131101": "大塘镇",
        "510131102": "寿安镇",
        "510131103": "朝阳湖镇",
        "510131104": "西来镇",
        "510131105": "大兴镇",
        "510131106": "甘溪镇",
        "510131107": "成佳镇",
        "510131200": "复兴乡",
        "510131201": "光明乡",
        "510131202": "白云乡",
        "510131203": "长秋乡"
    },
    "510132": {
        "510132001": "五津街道",
        "510132101": "花桥镇",
        "510132102": "花源镇",
        "510132103": "金华镇",
        "510132104": "普兴镇",
        "510132105": "兴义镇",
        "510132106": "新平镇",
        "510132107": "方兴镇",
        "510132108": "安西镇",
        "510132109": "永商镇",
        "510132110": "邓双镇",
        "510132202": "文井乡"
    },
    "510181": {
        "510181001": "奎光塔街道",
        "510181002": "幸福街道",
        "510181003": "永丰街道",
        "510181004": "灌口街道",
        "510181005": "银杏街道",
        "510181102": "蒲阳镇",
        "510181103": "聚源镇",
        "510181104": "崇义镇",
        "510181105": "天马镇",
        "510181106": "石羊镇",
        "510181107": "柳街镇",
        "510181108": "玉堂镇",
        "510181109": "中兴镇",
        "510181110": "青城山镇",
        "510181111": "龙池镇",
        "510181112": "胥家镇",
        "510181113": "安龙镇",
        "510181114": "大观镇",
        "510181202": "向峨乡"
    },
    "510182": {
        "510182001": "天彭街道",
        "510182101": "龙门山镇",
        "510182102": "新兴镇",
        "510182103": "丽春镇",
        "510182104": "九尺镇",
        "510182105": "濛阳镇",
        "510182106": "通济镇",
        "510182107": "丹景山镇",
        "510182108": "隆丰镇",
        "510182109": "敖平镇",
        "510182110": "磁峰镇",
        "510182111": "桂花镇",
        "510182113": "军乐镇",
        "510182114": "三界镇",
        "510182116": "小鱼洞镇",
        "510182118": "红岩镇",
        "510182120": "升平镇",
        "510182121": "白鹿镇",
        "510182123": "葛仙山镇",
        "510182126": "致和镇"
    },
    "510183": {
        "510183001": "文君街道",
        "510183002": "临邛街道",
        "510183101": "羊安镇",
        "510183102": "牟礼镇",
        "510183103": "桑园镇",
        "510183104": "平乐镇",
        "510183105": "夹关镇",
        "510183106": "火井镇",
        "510183107": "水口镇",
        "510183108": "固驿镇",
        "510183109": "冉义镇",
        "510183110": "回龙镇",
        "510183111": "高埂镇",
        "510183112": "前进镇",
        "510183113": "高何镇",
        "510183115": "临济镇",
        "510183116": "卧龙镇",
        "510183118": "天台山镇",
        "510183120": "宝林镇",
        "510183121": "南宝山镇",
        "510183201": "茶园乡",
        "510183206": "道佐乡",
        "510183212": "大同乡",
        "510183214": "孔明乡"
    },
    "510184": {
        "510184001": "崇阳街道",
        "510184101": "三江镇",
        "510184102": "江源镇",
        "510184103": "羊马镇",
        "510184104": "廖家镇",
        "510184105": "元通镇",
        "510184106": "观胜镇",
        "510184107": "怀远镇",
        "510184108": "三郎镇",
        "510184109": "街子镇",
        "510184110": "文井江镇",
        "510184111": "王场镇",
        "510184112": "白头镇",
        "510184113": "道明镇",
        "510184114": "隆兴镇",
        "510184116": "大划镇",
        "510184117": "崇平镇",
        "510184118": "梓潼镇",
        "510184119": "桤泉镇",
        "510184201": "锦江乡",
        "510184202": "公议乡",
        "510184206": "鸡冠山乡",
        "510184208": "济协乡",
        "510184209": "燎原乡",
        "510184211": "集贤乡"
    },
    "510185": {
        "510185001": "简城街道",
        "510185002": "射洪坝街道",
        "510185003": "十里坝街道",
        "510185004": "杨柳街道",
        "510185101": "石桥镇",
        "510185102": "新市镇",
        "510185103": "石盘镇",
        "510185104": "东溪镇",
        "510185105": "平泉镇",
        "510185106": "禾丰镇",
        "510185107": "云龙镇",
        "510185108": "三星镇",
        "510185109": "养马镇",
        "510185110": "贾家镇",
        "510185111": "石板凳镇",
        "510185112": "三岔镇",
        "510185113": "镇金镇",
        "510185114": "石钟镇",
        "510185115": "施家镇",
        "510185116": "三合镇",
        "510185117": "平武镇",
        "510185118": "金马镇",
        "510185119": "踏水镇",
        "510185120": "江源镇",
        "510185121": "涌泉镇",
        "510185122": "芦葭镇",
        "510185123": "草池镇",
        "510185124": "太平桥镇",
        "510185125": "青龙镇",
        "510185200": "老君井乡",
        "510185201": "福田乡",
        "510185202": "宏缘乡",
        "510185203": "周家乡",
        "510185204": "平窝乡",
        "510185205": "武庙乡",
        "510185206": "高明乡",
        "510185207": "玉成乡",
        "510185208": "丹景乡",
        "510185209": "望水乡",
        "510185210": "清风乡",
        "510185211": "董家埂乡",
        "510185212": "五星乡",
        "510185213": "飞龙乡",
        "510185214": "灵仙乡",
        "510185215": "五指乡",
        "510185216": "新民乡",
        "510185217": "新星乡",
        "510185218": "同合乡",
        "510185219": "老龙乡",
        "510185220": "壮溪乡",
        "510185221": "海螺乡",
        "510185222": "坛罐乡",
        "510185223": "雷家乡",
        "510185224": "安乐乡",
        "510185225": "普安乡",
        "510185226": "平息乡",
        "510185227": "五合乡",
        "510185228": "永宁乡"
    },
    "510300": {"510302": "自流井区", "510303": "贡井区", "510304": "大安区", "510311": "沿滩区", "510321": "荣县", "510322": "富顺县"},
    "510302": {
        "510302001": "五星街街道",
        "510302002": "东兴寺街道",
        "510302003": "新街街道",
        "510302004": "郭家坳街街道",
        "510302005": "丹桂街道",
        "510302006": "学苑街道",
        "510302101": "仲权镇",
        "510302102": "舒坪镇",
        "510302103": "荣边镇",
        "510302201": "红旗乡",
        "510302202": "高峰乡",
        "510302203": "农团乡",
        "510302204": "漆树乡"
    },
    "510303": {
        "510303001": "筱溪街道",
        "510303002": "贡井街道",
        "510303100": "艾叶镇",
        "510303101": "建设镇",
        "510303102": "长土镇",
        "510303104": "龙潭镇",
        "510303105": "桥头镇",
        "510303106": "五宝镇",
        "510303107": "莲花镇",
        "510303108": "成佳镇",
        "510303109": "白庙镇",
        "510303200": "章佳乡",
        "510303201": "牛尾乡"
    },
    "510304": {
        "510304001": "大安街道",
        "510304002": "龙井街道",
        "510304003": "马冲口街道",
        "510304004": "凉高山街道",
        "510304100": "大山铺镇",
        "510304102": "团结镇",
        "510304103": "三多寨镇",
        "510304104": "何市镇",
        "510304105": "新店镇",
        "510304106": "新民镇",
        "510304107": "牛佛镇",
        "510304108": "庙坝镇",
        "510304109": "回龙镇",
        "510304204": "永嘉乡",
        "510304205": "和平乡",
        "510304207": "凤凰乡"
    },
    "510311": {
        "510311100": "沿滩镇",
        "510311101": "卫坪镇",
        "510311102": "兴隆镇",
        "510311105": "富全镇",
        "510311106": "永安镇",
        "510311107": "联络镇",
        "510311108": "邓关镇",
        "510311109": "王井镇",
        "510311110": "黄市镇",
        "510311111": "瓦市镇",
        "510311112": "仙市镇",
        "510311203": "刘山乡",
        "510311204": "九洪乡"
    },
    "510321": {
        "510321100": "旭阳镇",
        "510321102": "双石镇",
        "510321103": "望佳镇",
        "510321107": "鼎新镇",
        "510321110": "乐德镇",
        "510321111": "过水镇",
        "510321112": "古文镇",
        "510321113": "河口镇",
        "510321114": "新桥镇",
        "510321115": "正紫镇",
        "510321116": "度佳镇",
        "510321117": "东佳镇",
        "510321118": "长山镇",
        "510321119": "保华镇",
        "510321120": "留佳镇",
        "510321121": "来牟镇",
        "510321122": "双古镇",
        "510321123": "观山镇",
        "510321124": "高山镇",
        "510321125": "东兴镇",
        "510321126": "铁厂镇",
        "510321202": "金花乡",
        "510321203": "雷音乡",
        "510321204": "古佳乡",
        "510321205": "于佳乡",
        "510321206": "复兴乡",
        "510321207": "墨林乡"
    },
    "510322": {
        "510322100": "富世镇",
        "510322101": "东湖镇",
        "510322102": "琵琶镇",
        "510322103": "狮市镇",
        "510322104": "骑龙镇",
        "510322107": "互助镇",
        "510322111": "代寺镇",
        "510322112": "中石镇",
        "510322113": "童寺镇",
        "510322114": "古佛镇",
        "510322115": "永年镇",
        "510322116": "彭庙镇",
        "510322117": "兜山镇",
        "510322118": "板桥镇",
        "510322119": "福善镇",
        "510322120": "李桥镇",
        "510322121": "赵化镇",
        "510322122": "安溪镇",
        "510322123": "万寿镇",
        "510322124": "飞龙镇",
        "510322125": "怀德镇",
        "510322126": "长滩镇",
        "510322200": "龙万乡",
        "510322201": "宝庆乡",
        "510322202": "富和乡",
        "510322203": "石道乡"
    },
    "510400": {"510402": "东区", "510403": "西区", "510411": "仁和区", "510421": "米易县", "510422": "盐边县"},
    "510402": {
        "510402001": "大渡口街道",
        "510402002": "炳草岗街道",
        "510402003": "南山街道",
        "510402004": "向阳村街道",
        "510402005": "弄弄坪街道",
        "510402006": "枣子坪街道",
        "510402007": "长寿路街道",
        "510402008": "密地街道",
        "510402009": "瓜子坪街道",
        "510402100": "银江镇"
    },
    "510403": {
        "510403001": "清香坪街道",
        "510403002": "玉泉街道",
        "510403003": "河门口街道",
        "510403004": "陶家渡街道",
        "510403005": "摩梭河街道",
        "510403006": "大宝鼎街道",
        "510403100": "格里坪镇"
    },
    "510411": {
        "510411001": "大河中路街道",
        "510411100": "仁和镇",
        "510411101": "平地镇",
        "510411102": "大田镇",
        "510411103": "福田镇",
        "510411104": "同德镇",
        "510411105": "金江镇",
        "510411106": "布德镇",
        "510411107": "前进镇",
        "510411200": "大龙潭彝族乡",
        "510411201": "啊喇彝族乡",
        "510411202": "总发乡",
        "510411203": "太平乡",
        "510411204": "务本乡",
        "510411205": "中坝乡"
    },
    "510421": {
        "510421100": "攀莲镇",
        "510421101": "丙谷镇",
        "510421102": "得石镇",
        "510421103": "撒莲镇",
        "510421104": "垭口镇",
        "510421105": "白马镇",
        "510421106": "普威镇",
        "510421200": "草场乡",
        "510421201": "湾丘彝族乡",
        "510421202": "白坡彝族乡",
        "510421203": "麻陇彝族乡",
        "510421204": "新山傈僳族乡"
    },
    "510422": {
        "510422100": "桐子林镇",
        "510422101": "红格镇",
        "510422102": "渔门镇",
        "510422103": "永兴镇",
        "510422200": "益民乡",
        "510422201": "新九乡",
        "510422202": "和爱彝族乡",
        "510422203": "红果彝族乡",
        "510422204": "鳡鱼彝族乡",
        "510422205": "共和乡",
        "510422206": "国胜乡",
        "510422207": "红宝苗族彝族乡",
        "510422208": "惠民乡",
        "510422209": "箐河傈僳族乡",
        "510422210": "温泉彝族乡",
        "510422211": "格萨拉彝族乡"
    },
    "510500": {
        "510502": "江阳区",
        "510503": "纳溪区",
        "510504": "龙马潭区",
        "510521": "泸县",
        "510522": "合江县",
        "510524": "叙永县",
        "510525": "古蔺县"
    },
    "510502": {
        "510502001": "南城街道",
        "510502002": "北城街道",
        "510502003": "大山坪街道",
        "510502004": "邻玉街道",
        "510502005": "蓝田街道",
        "510502006": "茜草街道",
        "510502007": "华阳街道",
        "510502008": "张坝街道",
        "510502009": "泰安街道",
        "510502010": "况场街道",
        "510502103": "黄舣镇",
        "510502104": "弥陀镇",
        "510502106": "通滩镇",
        "510502107": "江北镇",
        "510502108": "方山镇",
        "510502109": "丹林镇",
        "510502110": "分水岭镇",
        "510502111": "石寨镇",
        "510502400": "高新技术开发区",
        "510502401": "酒业集中发展区",
        "510502402": "轻工业园区"
    },
    "510503": {
        "510503001": "安富街道",
        "510503002": "永宁街道",
        "510503003": "东升街道",
        "510503100": "大渡口镇",
        "510503101": "护国镇",
        "510503102": "打古镇",
        "510503103": "上马镇",
        "510503104": "合面镇",
        "510503105": "棉花坡镇",
        "510503106": "丰乐镇",
        "510503107": "白节镇",
        "510503108": "天仙镇",
        "510503109": "新乐镇",
        "510503110": "渠坝镇",
        "510503111": "龙车镇",
        "510503400": "泸州化工园区"
    },
    "510504": {
        "510504001": "小市街道",
        "510504002": "高坝街道",
        "510504003": "红星街道",
        "510504004": "莲花池街道",
        "510504005": "罗汉街道",
        "510504006": "鱼塘街道",
        "510504007": "安宁街道",
        "510504008": "石洞街道",
        "510504009": "特兴街道",
        "510504103": "胡市镇",
        "510504106": "双加镇",
        "510504107": "金龙镇",
        "510504108": "长安镇"
    },
    "510521": {
        "510521001": "玉蟾街道",
        "510521100": "福集镇",
        "510521101": "嘉明镇",
        "510521102": "喻寺镇",
        "510521103": "得胜镇",
        "510521105": "牛滩镇",
        "510521106": "兆雅镇",
        "510521107": "玄滩镇",
        "510521108": "太伏镇",
        "510521109": "云龙镇",
        "510521110": "石桥镇",
        "510521111": "毗卢镇",
        "510521112": "奇峰镇",
        "510521113": "潮河镇",
        "510521114": "云锦镇",
        "510521115": "立石镇",
        "510521116": "百和镇",
        "510521117": "天兴镇",
        "510521118": "方洞镇",
        "510521123": "海潮镇",
        "510521400": "泸县城西工业园区",
        "510521401": "泸县城北工业园区"
    },
    "510522": {
        "510522100": "合江镇",
        "510522101": "望龙镇",
        "510522102": "白沙镇",
        "510522103": "佛荫镇",
        "510522104": "先市镇",
        "510522105": "尧坝镇",
        "510522106": "九支镇",
        "510522107": "五通镇",
        "510522108": "凤鸣镇",
        "510522109": "榕山镇",
        "510522110": "白鹿镇",
        "510522111": "甘雨镇",
        "510522112": "福宝镇",
        "510522113": "先滩镇",
        "510522114": "自怀镇",
        "510522115": "大桥镇",
        "510522116": "车辋镇",
        "510522117": "白米镇",
        "510522118": "参宝镇",
        "510522119": "法王寺镇",
        "510522120": "虎头镇",
        "510522121": "神臂城镇",
        "510522122": "南滩镇",
        "510522123": "实录镇",
        "510522124": "石龙镇",
        "510522125": "真龙镇",
        "510522210": "榕右乡",
        "510522400": "合江县临港工业园区"
    },
    "510524": {
        "510524100": "叙永镇",
        "510524101": "江门镇",
        "510524102": "马岭镇",
        "510524103": "天池镇",
        "510524104": "水尾镇",
        "510524105": "两河镇",
        "510524106": "落卜镇",
        "510524107": "后山镇",
        "510524108": "分水镇",
        "510524109": "摩尼镇",
        "510524110": "赤水镇",
        "510524111": "龙凤镇",
        "510524112": "正东镇",
        "510524113": "观兴镇",
        "510524114": "向林镇",
        "510524115": "兴隆镇",
        "510524116": "营山镇",
        "510524117": "麻城镇",
        "510524118": "大石镇",
        "510524119": "黄坭镇",
        "510524210": "合乐苗族乡",
        "510524211": "白腊苗族乡",
        "510524212": "枧槽苗族乡",
        "510524213": "水潦彝族乡",
        "510524215": "石厢子彝族乡"
    },
    "510525": {
        "510525100": "古蔺镇",
        "510525101": "龙山镇",
        "510525102": "永乐镇",
        "510525103": "太平镇",
        "510525104": "二郎镇",
        "510525105": "大村镇",
        "510525106": "石宝镇",
        "510525107": "丹桂镇",
        "510525108": "茅溪镇",
        "510525109": "观文镇",
        "510525110": "双沙镇",
        "510525111": "德耀镇",
        "510525112": "护家镇",
        "510525113": "石屏镇",
        "510525114": "土城镇",
        "510525115": "皇华镇",
        "510525116": "鱼化镇",
        "510525117": "东新镇",
        "510525118": "椒园镇",
        "510525119": "马蹄镇",
        "510525120": "桂花镇",
        "510525121": "黄荆镇",
        "510525207": "白泥乡",
        "510525209": "马嘶苗族乡",
        "510525212": "箭竹苗族乡",
        "510525214": "大寨苗族乡"
    },
    "510600": {"510603": "旌阳区", "510604": "罗江区", "510623": "中江县", "510681": "广汉市", "510682": "什邡市", "510683": "绵竹市"},
    "510603": {
        "510603001": "旌阳街道",
        "510603002": "城南街道",
        "510603003": "城北街道",
        "510603004": "旌东街道",
        "510603005": "工农街道",
        "510603006": "八角井街道",
        "510603007": "黄河街道",
        "510603008": "天虹街道",
        "510603100": "黄许镇",
        "510603101": "孝泉镇",
        "510603103": "柏隆镇",
        "510603104": "孝感镇",
        "510603105": "天元镇",
        "510603107": "扬嘉镇",
        "510603108": "德新镇",
        "510603110": "双东镇",
        "510603111": "新中镇",
        "510603117": "和新镇",
        "510603201": "东湖乡"
    },
    "510604": {
        "510604100": "万安镇",
        "510604101": "鄢家镇",
        "510604102": "金山镇",
        "510604103": "略坪镇",
        "510604104": "御营镇",
        "510604105": "慧觉镇",
        "510604106": "调元镇",
        "510604107": "新盛镇",
        "510604108": "蟠龙镇",
        "510604113": "白马关镇"
    },
    "510623": {
        "510623100": "凯江镇",
        "510623101": "南华镇",
        "510623102": "回龙镇",
        "510623103": "通济镇",
        "510623104": "永太镇",
        "510623105": "黄鹿镇",
        "510623106": "集凤镇",
        "510623107": "富兴镇",
        "510623108": "辑庆镇",
        "510623109": "兴隆镇",
        "510623110": "龙台镇",
        "510623111": "永安镇",
        "510623112": "双龙镇",
        "510623113": "玉兴镇",
        "510623114": "永兴镇",
        "510623115": "悦来镇",
        "510623116": "继光镇",
        "510623117": "仓山镇",
        "510623118": "广福镇",
        "510623119": "会龙镇",
        "510623120": "万福镇",
        "510623121": "普兴镇",
        "510623122": "联合镇",
        "510623123": "冯店镇",
        "510623124": "积金镇",
        "510623125": "太安镇",
        "510623127": "杰兴镇",
        "510623130": "南山镇",
        "510623132": "东北镇",
        "510623200": "古店乡",
        "510623201": "青市乡",
        "510623203": "瓦店乡",
        "510623204": "石泉乡",
        "510623206": "柏树乡",
        "510623207": "白果乡",
        "510623208": "清河乡",
        "510623209": "高店乡",
        "510623210": "石笋乡",
        "510623211": "太平乡",
        "510623212": "民主乡",
        "510623214": "永丰乡",
        "510623216": "元兴乡",
        "510623218": "通山乡",
        "510623219": "石龙乡",
        "510623221": "合兴乡"
    },
    "510681": {
        "510681100": "雒城镇",
        "510681101": "三水镇",
        "510681102": "连山镇",
        "510681103": "高坪镇",
        "510681104": "南兴镇",
        "510681105": "向阳镇",
        "510681106": "小汉镇",
        "510681107": "金轮镇",
        "510681108": "新丰镇",
        "510681109": "兴隆镇",
        "510681112": "和兴镇",
        "510681113": "松林镇",
        "510681114": "金鱼镇",
        "510681115": "新平镇",
        "510681116": "南丰镇",
        "510681119": "西高镇",
        "510681200": "北外乡",
        "510681202": "西外乡"
    },
    "510682": {
        "510682001": "方亭街道",
        "510682002": "皂角街道",
        "510682101": "元石镇",
        "510682103": "回澜镇",
        "510682105": "洛水镇",
        "510682106": "禾丰镇",
        "510682107": "双盛镇",
        "510682108": "马祖镇",
        "510682109": "隐峰镇",
        "510682110": "马井镇",
        "510682111": "蓥华镇",
        "510682113": "南泉镇",
        "510682116": "湔氐镇",
        "510682118": "红白镇",
        "510682119": "冰川镇",
        "510682120": "师古镇"
    },
    "510683": {
        "510683100": "剑南镇",
        "510683101": "东北镇",
        "510683102": "西南镇",
        "510683103": "兴隆镇",
        "510683104": "九龙镇",
        "510683105": "遵道镇",
        "510683106": "汉旺镇",
        "510683108": "拱星镇",
        "510683109": "土门镇",
        "510683110": "广济镇",
        "510683111": "金花镇",
        "510683112": "玉泉镇",
        "510683113": "板桥镇",
        "510683115": "新市镇",
        "510683116": "孝德镇",
        "510683119": "富新镇",
        "510683121": "齐天镇",
        "510683122": "什地镇",
        "510683123": "绵远镇",
        "510683124": "清平镇",
        "510683201": "天池乡",
        "510683400": "四川绵竹经济开发区"
    },
    "510700": {
        "510703": "涪城区",
        "510704": "游仙区",
        "510705": "安州区",
        "510722": "三台县",
        "510723": "盐亭县",
        "510725": "梓潼县",
        "510726": "北川羌族自治县",
        "510727": "平武县",
        "510781": "江油市"
    },
    "510703": {
        "510703001": "城厢街道",
        "510703002": "城北街道",
        "510703003": "工区街道",
        "510703004": "南山街道",
        "510703005": "朝阳街道",
        "510703006": "普明街道",
        "510703007": "城南街道",
        "510703008": "金家林街道",
        "510703009": "科创园街道",
        "510703011": "石塘街道",
        "510703012": "城郊街道",
        "510703013": "石桥街道",
        "510703100": "丰谷镇",
        "510703101": "关帝镇",
        "510703102": "塘汛镇",
        "510703103": "青义镇",
        "510703104": "龙门镇",
        "510703106": "吴家镇",
        "510703107": "杨家镇",
        "510703108": "金峰镇",
        "510703109": "玉皇镇",
        "510703110": "新皂镇",
        "510703111": "河边镇",
        "510703112": "磨家镇",
        "510703113": "永兴镇",
        "510703201": "石洞乡"
    },
    "510704": {
        "510704001": "涪江街道",
        "510704003": "富乐街道",
        "510704004": "游仙街道办",
        "510704006": "春雷街道",
        "510704007": "松林街道",
        "510704008": "华丰街道",
        "510704101": "石马镇",
        "510704102": "新桥镇",
        "510704103": "小枧沟镇",
        "510704104": "魏城镇",
        "510704105": "沉抗镇",
        "510704106": "忠兴镇",
        "510704107": "柏林镇",
        "510704108": "徐家镇",
        "510704109": "石板镇",
        "510704110": "刘家镇",
        "510704111": "玉河镇",
        "510704112": "松垭镇",
        "510704113": "白蝉镇",
        "510704114": "观太镇",
        "510704115": "云凤镇",
        "510704116": "太平镇",
        "510704117": "街子镇",
        "510704118": "东林镇",
        "510704119": "梓棉镇",
        "510704120": "东宣镇",
        "510704202": "建华乡",
        "510704207": "朝真乡",
        "510704210": "凤凰乡",
        "510704400": "四川省新华劳动教育管理所"
    },
    "510705": {
        "510705101": "桑枣镇",
        "510705102": "花荄镇",
        "510705103": "黄土镇",
        "510705104": "塔水镇",
        "510705105": "秀水镇",
        "510705106": "河清镇",
        "510705108": "界牌镇",
        "510705109": "永河镇",
        "510705110": "雎水镇",
        "510705111": "清泉镇",
        "510705112": "宝林镇",
        "510705113": "沸水镇",
        "510705114": "晓坝镇",
        "510705115": "乐兴镇",
        "510705116": "千佛镇",
        "510705201": "兴仁乡",
        "510705202": "高川乡",
        "510705203": "迎新乡"
    },
    "510722": {
        "510722001": "北坝街道",
        "510722101": "潼川镇",
        "510722102": "东塔镇",
        "510722103": "百顷镇",
        "510722104": "塔山镇",
        "510722105": "柳池镇",
        "510722106": "龙树镇",
        "510722107": "石安镇",
        "510722108": "富顺镇",
        "510722109": "三元镇",
        "510722110": "秋林镇",
        "510722111": "永新镇",
        "510722112": "新德镇",
        "510722113": "新生镇",
        "510722114": "鲁班镇",
        "510722115": "景福镇",
        "510722116": "紫河镇",
        "510722117": "安居镇",
        "510722118": "观桥镇",
        "510722119": "郪江镇",
        "510722120": "中新镇",
        "510722121": "古井镇",
        "510722122": "万安镇",
        "510722123": "西平镇",
        "510722124": "八洞镇",
        "510722125": "跃进镇",
        "510722126": "乐安镇",
        "510722127": "建平镇",
        "510722128": "前锋镇",
        "510722129": "建设镇",
        "510722130": "光辉镇",
        "510722131": "中太镇",
        "510722132": "金石镇",
        "510722133": "新鲁镇",
        "510722134": "黎曙镇",
        "510722135": "刘营镇",
        "510722136": "灵兴镇",
        "510722137": "芦溪镇",
        "510722138": "立新镇",
        "510722139": "花园镇",
        "510722140": "永明镇",
        "510722141": "建中镇",
        "510722142": "争胜镇",
        "510722143": "玉林镇",
        "510722144": "幸福镇",
        "510722145": "老马镇",
        "510722146": "双胜镇",
        "510722147": "里程镇",
        "510722148": "金鼓镇",
        "510722149": "菊河镇",
        "510722200": "高堰乡",
        "510722201": "忠孝乡",
        "510722204": "断石乡",
        "510722206": "乐加乡",
        "510722207": "曙光乡",
        "510722209": "宝泉乡",
        "510722210": "广利乡",
        "510722211": "协和乡",
        "510722212": "双乐乡",
        "510722215": "下新乡",
        "510722216": "进都乡",
        "510722217": "上新乡",
        "510722221": "云同乡"
    },
    "510723": {
        "510723001": "凤灵街道办",
        "510723002": "麻秧街道办",
        "510723100": "云溪镇",
        "510723101": "玉龙镇",
        "510723102": "富驿镇",
        "510723103": "金孔镇",
        "510723104": "两河镇",
        "510723105": "黄甸镇",
        "510723106": "柏梓镇",
        "510723107": "八角镇",
        "510723108": "黑坪镇",
        "510723109": "高灯镇",
        "510723110": "金鸡镇",
        "510723111": "安家镇",
        "510723112": "林农镇",
        "510723113": "巨龙镇",
        "510723200": "龙泉乡",
        "510723201": "折弓乡",
        "510723203": "三元乡",
        "510723204": "五龙乡",
        "510723205": "茶亭乡",
        "510723206": "金安乡",
        "510723207": "洗泽乡",
        "510723208": "毛公乡",
        "510723209": "冯河乡",
        "510723210": "石牛庙乡",
        "510723211": "大兴回族乡",
        "510723212": "宗海乡",
        "510723214": "剑河乡",
        "510723215": "来龙乡",
        "510723216": "永泰乡",
        "510723217": "黄溪乡",
        "510723218": "榉溪乡",
        "510723219": "双碑乡",
        "510723220": "林山乡"
    },
    "510725": {
        "510725100": "文昌镇",
        "510725101": "长卿镇",
        "510725102": "许州镇",
        "510725103": "黎雅镇",
        "510725104": "白云镇",
        "510725105": "卧龙镇",
        "510725106": "观义镇",
        "510725107": "玛瑙镇",
        "510725108": "石牛镇",
        "510725109": "自强镇",
        "510725110": "仁和镇",
        "510725111": "双板镇",
        "510725112": "金龙镇",
        "510725113": "文兴镇",
        "510725114": "演武镇",
        "510725115": "仙峰镇",
        "510725116": "马鸣镇",
        "510725117": "大新镇",
        "510725200": "东石乡",
        "510725201": "三泉乡",
        "510725202": "宏仁乡",
        "510725203": "小垭乡",
        "510725207": "豢龙乡",
        "510725208": "双峰乡",
        "510725209": "交泰乡",
        "510725211": "石台乡",
        "510725212": "仙鹅乡",
        "510725214": "马迎乡",
        "510725215": "二洞乡",
        "510725216": "建兴乡",
        "510725217": "宝石乡",
        "510725218": "定远乡"
    },
    "510726": {
        "510726100": "曲山镇",
        "510726101": "擂鼓镇",
        "510726102": "通口镇",
        "510726103": "永昌镇",
        "510726104": "安昌镇",
        "510726105": "永安镇",
        "510726106": "禹里镇",
        "510726107": "桂溪镇",
        "510726108": "陈家坝镇",
        "510726109": "小坝镇",
        "510726200": "香泉乡",
        "510726203": "贯岭乡",
        "510726205": "漩坪乡",
        "510726206": "白坭乡",
        "510726208": "片口乡",
        "510726209": "开坪乡",
        "510726210": "坝底乡",
        "510726211": "白什乡",
        "510726212": "青片乡",
        "510726213": "都坝乡",
        "510726214": "桃龙藏族乡",
        "510726215": "墩上乡",
        "510726216": "马槽乡"
    },
    "510727": {
        "510727100": "龙安镇",
        "510727101": "古城镇",
        "510727102": "南坝镇",
        "510727103": "响岩镇",
        "510727104": "平通镇",
        "510727105": "豆叩镇",
        "510727106": "大印镇",
        "510727107": "大桥镇",
        "510727108": "水晶镇",
        "510727200": "高村乡",
        "510727201": "水田羌族乡",
        "510727202": "坝子乡",
        "510727203": "水观乡",
        "510727204": "平南羌族乡",
        "510727205": "徐塘羌族乡",
        "510727206": "锁江羌族乡",
        "510727207": "土城藏族乡",
        "510727208": "旧堡羌族乡",
        "510727209": "阔达藏族乡",
        "510727210": "黄羊关藏族乡",
        "510727211": "虎牙藏族乡",
        "510727212": "泗耳藏族乡",
        "510727213": "白马藏族乡",
        "510727214": "木座藏族乡",
        "510727215": "木皮藏族乡"
    },
    "510781": {
        "510781004": "华坪街道",
        "510781005": "长城街道",
        "510781006": "中坝街道",
        "510781101": "太平镇",
        "510781102": "三合镇",
        "510781103": "含增镇",
        "510781104": "青莲镇",
        "510781105": "彰明镇",
        "510781106": "龙凤镇",
        "510781107": "武都镇",
        "510781108": "大康镇",
        "510781109": "新安镇",
        "510781110": "战旗镇",
        "510781111": "双河镇",
        "510781112": "永胜镇",
        "510781113": "小溪坝镇",
        "510781114": "河口镇",
        "510781115": "重华镇",
        "510781116": "厚坝镇",
        "510781117": "二郎庙镇",
        "510781118": "马角镇",
        "510781119": "雁门镇",
        "510781120": "九岭镇",
        "510781121": "西屏镇",
        "510781122": "贯山镇",
        "510781123": "大堰镇",
        "510781124": "文胜镇",
        "510781125": "方水镇",
        "510781126": "香水镇",
        "510781127": "八一镇",
        "510781128": "义新镇",
        "510781129": "东兴镇",
        "510781209": "新兴乡",
        "510781210": "新春乡",
        "510781211": "东安乡",
        "510781212": "铜星乡",
        "510781214": "重兴乡",
        "510781215": "云集乡",
        "510781216": "石元乡",
        "510781217": "敬元乡",
        "510781218": "六合乡",
        "510781219": "枫顺乡"
    },
    "510800": {
        "510802": "利州区",
        "510811": "昭化区",
        "510812": "朝天区",
        "510821": "旺苍县",
        "510822": "青川县",
        "510823": "剑阁县",
        "510824": "苍溪县"
    },
    "510802": {
        "510802001": "东坝街道",
        "510802002": "嘉陵街道",
        "510802003": "河西街道",
        "510802004": "雪峰街道",
        "510802005": "南河街道",
        "510802006": "上西街道",
        "510802007": "回龙河街道",
        "510802008": "杨家岩街道",
        "510802100": "荣山镇",
        "510802101": "大石镇",
        "510802102": "盘龙镇",
        "510802103": "宝轮镇",
        "510802104": "赤化镇",
        "510802105": "三堆镇",
        "510802106": "工农镇",
        "510802200": "白朝乡",
        "510802201": "金洞乡",
        "510802202": "龙潭乡",
        "510802405": "袁家坝",
        "510802407": "下西"
    },
    "510811": {
        "510811001": "拣银岩社区街道",
        "510811100": "元坝镇",
        "510811101": "卫子镇",
        "510811102": "王家镇",
        "510811103": "磨滩镇",
        "510811104": "柏林沟镇",
        "510811105": "太公镇",
        "510811106": "虎跳镇",
        "510811107": "红岩镇",
        "510811108": "昭化镇",
        "510811109": "石井铺镇",
        "510811110": "明觉镇",
        "510811200": "晋贤乡",
        "510811201": "文村乡",
        "510811202": "清水乡",
        "510811203": "张家乡",
        "510811204": "香溪乡",
        "510811205": "青牛乡",
        "510811206": "陈江乡",
        "510811207": "丁家乡",
        "510811208": "黄龙乡",
        "510811210": "白果乡",
        "510811211": "梅树乡",
        "510811213": "射箭乡",
        "510811214": "朝阳乡",
        "510811215": "大朝乡",
        "510811216": "沙坝乡",
        "510811217": "柳桥乡",
        "510811218": "紫云乡"
    },
    "510812": {
        "510812100": "朝天镇",
        "510812101": "大滩镇",
        "510812102": "羊木镇",
        "510812103": "曾家镇",
        "510812104": "中子镇",
        "510812105": "沙河镇",
        "510812106": "宣河镇",
        "510812107": "转斗镇",
        "510812108": "东溪河镇",
        "510812200": "陈家乡",
        "510812201": "小安乡",
        "510812202": "鱼洞乡",
        "510812204": "花石乡",
        "510812205": "蒲家乡",
        "510812206": "西北乡",
        "510812209": "青林乡",
        "510812210": "平溪乡",
        "510812211": "两河口乡",
        "510812212": "李家乡",
        "510812213": "汪家乡",
        "510812214": "麻柳乡",
        "510812215": "临溪乡",
        "510812216": "文安乡",
        "510812217": "马家坝乡",
        "510812218": "柏杨乡"
    },
    "510821": {
        "510821100": "东河镇",
        "510821101": "嘉川镇",
        "510821102": "木门镇",
        "510821103": "白水镇",
        "510821104": "尚武镇",
        "510821105": "张华镇",
        "510821106": "黄洋镇",
        "510821107": "普济镇",
        "510821108": "三江镇",
        "510821109": "金溪镇",
        "510821110": "五权镇",
        "510821111": "高阳镇",
        "510821112": "双汇镇",
        "510821113": "英萃镇",
        "510821114": "国华镇",
        "510821115": "龙凤镇",
        "510821116": "九龙镇",
        "510821117": "米仓山镇",
        "510821201": "大河乡",
        "510821203": "万家乡",
        "510821204": "燕子乡",
        "510821205": "水磨乡",
        "510821207": "檬子乡",
        "510821208": "福庆乡",
        "510821209": "枣林乡",
        "510821210": "麻英乡",
        "510821211": "柳溪乡",
        "510821212": "农建乡",
        "510821213": "化龙乡",
        "510821214": "大两乡",
        "510821215": "万山乡",
        "510821216": "正源乡",
        "510821217": "天星乡",
        "510821218": "盐河乡",
        "510821219": "大德乡",
        "510821400": "静乐寺管理办公室",
        "510821401": "陈家岭管理办公室",
        "510821402": "磨岩管理办公室"
    },
    "510822": {
        "510822100": "乔庄镇",
        "510822101": "青溪镇",
        "510822102": "房石镇",
        "510822103": "关庄镇",
        "510822104": "凉水镇",
        "510822105": "竹园镇",
        "510822106": "木鱼镇",
        "510822107": "沙州镇",
        "510822108": "姚渡镇",
        "510822109": "三锅镇",
        "510822110": "马鹿镇",
        "510822200": "黄坪乡",
        "510822201": "瓦砾乡",
        "510822202": "孔溪乡",
        "510822203": "茶坝乡",
        "510822204": "大坝乡",
        "510822205": "桥楼乡",
        "510822207": "蒿溪回族乡",
        "510822208": "乐安寺乡",
        "510822209": "前进乡",
        "510822210": "曲河乡",
        "510822211": "马公乡",
        "510822212": "石坝乡",
        "510822213": "红光乡",
        "510822214": "苏河乡",
        "510822215": "茅坝乡",
        "510822216": "大院回族乡",
        "510822217": "楼子乡",
        "510822218": "金子山乡",
        "510822220": "七佛乡",
        "510822221": "建峰乡",
        "510822222": "白家乡",
        "510822223": "板桥乡",
        "510822224": "骑马乡",
        "510822225": "观音店乡",
        "510822227": "营盘乡",
        "510822400": "唐家河国家级自然保护区"
    },
    "510823": {
        "510823100": "普安镇",
        "510823101": "龙源镇",
        "510823102": "城北镇",
        "510823103": "盐店镇",
        "510823104": "柳沟镇",
        "510823105": "武连镇",
        "510823106": "东宝镇",
        "510823107": "开封镇",
        "510823108": "元山镇",
        "510823109": "演圣镇",
        "510823110": "王河镇",
        "510823111": "公兴镇",
        "510823112": "金仙镇",
        "510823113": "香沉镇",
        "510823114": "白龙镇",
        "510823115": "鹤龄镇",
        "510823116": "杨村镇",
        "510823117": "羊岭镇",
        "510823118": "江口镇",
        "510823119": "木马镇",
        "510823120": "剑门关镇",
        "510823121": "汉阳镇",
        "510823122": "下寺镇",
        "510823123": "涂山镇",
        "510823124": "店子镇",
        "510823125": "高观镇",
        "510823126": "张王镇",
        "510823200": "江石乡",
        "510823201": "田家乡",
        "510823202": "闻溪乡",
        "510823203": "姚家乡",
        "510823204": "北庙乡",
        "510823205": "西庙乡",
        "510823206": "义兴乡",
        "510823207": "毛坝乡",
        "510823208": "凉山乡",
        "510823209": "垂泉乡",
        "510823210": "秀钟乡",
        "510823211": "正兴乡",
        "510823212": "马灯乡",
        "510823213": "高池乡",
        "510823214": "碗泉乡",
        "510823215": "迎水乡",
        "510823216": "国光乡",
        "510823217": "柘坝乡",
        "510823218": "公店乡",
        "510823219": "吼狮乡",
        "510823220": "长岭乡",
        "510823222": "圈龙乡",
        "510823223": "碑垭乡",
        "510823224": "广坪乡",
        "510823225": "禾丰乡",
        "510823227": "摇铃乡",
        "510823228": "樵店乡",
        "510823229": "锦屏乡",
        "510823230": "柏垭乡",
        "510823233": "上寺乡"
    },
    "510824": {
        "510824100": "陵江镇",
        "510824102": "云峰镇",
        "510824104": "东青镇",
        "510824105": "白桥镇",
        "510824106": "八庙镇",
        "510824107": "五龙镇",
        "510824108": "永宁镇",
        "510824109": "鸳溪镇",
        "510824110": "三川镇",
        "510824111": "龙王镇",
        "510824112": "元坝镇",
        "510824113": "唤马镇",
        "510824114": "歧坪镇",
        "510824115": "白驿镇",
        "510824116": "漓江镇",
        "510824117": "文昌镇",
        "510824118": "岳东镇",
        "510824119": "石马镇",
        "510824120": "运山镇",
        "510824121": "东溪镇",
        "510824122": "高坡镇",
        "510824123": "龙山镇",
        "510824124": "中土镇",
        "510824125": "亭子镇",
        "510824202": "禅林乡",
        "510824208": "白鹤乡",
        "510824209": "浙水乡",
        "510824210": "雍河乡",
        "510824212": "新观乡",
        "510824216": "石门乡",
        "510824223": "月山乡",
        "510824226": "白山乡",
        "510824228": "彭店乡",
        "510824229": "桥溪乡",
        "510824232": "龙洞乡",
        "510824233": "黄猫乡",
        "510824234": "石灶乡",
        "510824238": "河地乡",
        "510824240": "双河乡",
        "510824400": "苍溪九龙山自然保护区管理处",
        "510824401": "苍溪县三溪口森林经营所",
        "510824402": "苍溪县良种场"
    },
    "510900": {"510903": "船山区", "510904": "安居区", "510921": "蓬溪县", "510922": "射洪县", "510923": "大英县"},
    "510903": {
        "510903001": "南津路街道",
        "510903002": "凯旋路街道",
        "510903003": "高升街街道",
        "510903004": "镇江寺街道",
        "510903005": "育才路街道",
        "510903006": "介福路街道",
        "510903007": "嘉禾街道",
        "510903008": "广德街道",
        "510903009": "富源路街道",
        "510903010": "龙坪街道",
        "510903011": "灵泉街道",
        "510903012": "慈音街道",
        "510903013": "九莲街道",
        "510903014": "南强街道",
        "510903015": "金家沟街道",
        "510903100": "龙凤镇",
        "510903101": "仁里镇",
        "510903102": "复桥镇",
        "510903103": "永兴镇",
        "510903104": "河沙镇",
        "510903105": "新桥镇",
        "510903106": "桂花镇",
        "510903200": "西宁乡",
        "510903201": "老池乡",
        "510903202": "保升乡",
        "510903203": "唐家乡",
        "510903204": "北固乡"
    },
    "510904": {
        "510904001": "柔刚街道",
        "510904002": "凤凰街道",
        "510904100": "安居镇",
        "510904101": "东禅镇",
        "510904102": "分水镇",
        "510904103": "石洞镇",
        "510904104": "拦江镇",
        "510904105": "保石镇",
        "510904106": "白马镇",
        "510904107": "中兴镇",
        "510904108": "横山镇",
        "510904109": "会龙镇",
        "510904110": "三家镇",
        "510904111": "玉丰镇",
        "510904112": "西眉镇",
        "510904113": "磨溪镇",
        "510904114": "聚贤镇",
        "510904115": "观音镇",
        "510904116": "常理镇",
        "510904201": "莲花乡",
        "510904203": "步云乡",
        "510904205": "大安乡",
        "510904206": "马家乡"
    },
    "510921": {
        "510921100": "赤城镇",
        "510921101": "新会镇",
        "510921102": "文井镇",
        "510921103": "明月镇",
        "510921104": "常乐镇",
        "510921105": "天福镇",
        "510921106": "红江镇",
        "510921107": "宝梵镇",
        "510921108": "大石镇",
        "510921109": "吉祥镇",
        "510921110": "鸣凤镇",
        "510921111": "任隆镇",
        "510921112": "三凤镇",
        "510921113": "高坪镇",
        "510921114": "蓬南镇",
        "510921115": "群利镇",
        "510921116": "吉星镇",
        "510921117": "金桥镇",
        "510921200": "下东乡",
        "510921201": "新星乡",
        "510921202": "罗戈乡",
        "510921203": "板桥乡",
        "510921204": "槐花乡",
        "510921206": "黄泥乡",
        "510921207": "荷叶乡",
        "510921208": "金龙乡",
        "510921209": "农兴乡",
        "510921210": "新胜乡",
        "510921211": "回水乡",
        "510921212": "群力乡",
        "510921213": "高升乡"
    },
    "510922": {
        "510922001": "子昂街道",
        "510922002": "平安街道",
        "510922100": "太和镇",
        "510922101": "大榆镇",
        "510922102": "广兴镇",
        "510922103": "金华镇",
        "510922104": "沱牌镇",
        "510922105": "太乙镇",
        "510922106": "金家镇",
        "510922107": "复兴镇",
        "510922108": "天仙镇",
        "510922109": "仁和镇",
        "510922110": "青岗镇",
        "510922111": "洋溪镇",
        "510922112": "香山镇",
        "510922113": "明星镇",
        "510922114": "涪西镇",
        "510922115": "陈古镇",
        "510922116": "凤来镇",
        "510922117": "潼射镇",
        "510922118": "曹碑镇",
        "510922119": "官升镇",
        "510922200": "瞿河乡",
        "510922201": "伏河乡",
        "510922202": "青堤乡",
        "510922203": "双溪乡",
        "510922204": "文升乡",
        "510922205": "万林乡",
        "510922206": "太兴乡",
        "510922207": "东岳乡",
        "510922208": "金鹤乡",
        "510922209": "玉太乡"
    },
    "510923": {
        "510923100": "蓬莱镇",
        "510923101": "隆盛镇",
        "510923102": "回马镇",
        "510923103": "天保镇",
        "510923104": "河边镇",
        "510923105": "卓筒井镇",
        "510923106": "玉峰镇",
        "510923107": "象山镇",
        "510923108": "金元镇",
        "510923200": "通仙乡",
        "510923202": "智水乡"
    },
    "511000": {
        "511002": "市中区",
        "511011": "东兴区",
        "511024": "威远县",
        "511025": "资中县",
        "511071": "内江经济开发区",
        "511083": "隆昌市"
    },
    "511002": {
        "511002001": "城东街道",
        "511002002": "城南街道",
        "511002003": "城西街道",
        "511002004": "玉溪街道",
        "511002005": "牌楼街道",
        "511002007": "乐贤街道",
        "511002100": "白马镇",
        "511002101": "史家镇",
        "511002102": "凌家镇",
        "511002103": "朝阳镇",
        "511002104": "永安镇",
        "511002105": "全安镇",
        "511002106": "靖民镇",
        "511002108": "龚家镇",
        "511002109": "凤鸣镇",
        "511002110": "伏龙镇",
        "511002111": "龙门镇"
    },
    "511011": {
        "511011001": "东兴街道",
        "511011002": "西林街道",
        "511011003": "新江街道",
        "511011004": "胜利街道",
        "511011005": "高桥街道",
        "511011100": "田家镇",
        "511011101": "郭北镇",
        "511011102": "高梁镇",
        "511011103": "白合镇",
        "511011104": "顺河镇",
        "511011107": "双才镇",
        "511011108": "小河口镇",
        "511011109": "杨家镇",
        "511011110": "椑木镇",
        "511011111": "石子镇",
        "511011112": "椑南镇",
        "511011113": "永兴镇",
        "511011114": "平坦镇",
        "511011115": "中山镇",
        "511011116": "柳桥镇",
        "511011117": "双桥镇",
        "511011118": "富溪镇",
        "511011119": "同福镇",
        "511011120": "永福镇",
        "511011121": "三烈镇",
        "511011200": "太安乡",
        "511011201": "苏家乡",
        "511011207": "新店乡",
        "511011211": "大治乡"
    },
    "511024": {
        "511024100": "严陵镇",
        "511024101": "铺子湾镇",
        "511024102": "新店镇",
        "511024103": "向义镇",
        "511024104": "界牌镇",
        "511024105": "龙会镇",
        "511024106": "高石镇",
        "511024107": "东联镇",
        "511024108": "靖和镇",
        "511024109": "镇西镇",
        "511024110": "庆卫镇",
        "511024111": "山王镇",
        "511024112": "黄荆沟镇",
        "511024113": "观英滩镇",
        "511024114": "新场镇",
        "511024115": "连界镇",
        "511024116": "越溪镇",
        "511024117": "两河镇",
        "511024118": "碗厂镇",
        "511024119": "小河镇"
    },
    "511025": {
        "511025100": "重龙镇",
        "511025101": "甘露镇",
        "511025102": "归德镇",
        "511025103": "鱼溪镇",
        "511025104": "金李井镇",
        "511025105": "铁佛镇",
        "511025106": "球溪镇",
        "511025107": "顺河场镇",
        "511025108": "龙结镇",
        "511025109": "罗泉镇",
        "511025110": "发轮镇",
        "511025111": "兴隆街镇",
        "511025112": "银山镇",
        "511025113": "宋家镇",
        "511025114": "太平镇",
        "511025115": "骝马镇",
        "511025116": "水南镇",
        "511025117": "苏家湾镇",
        "511025118": "新桥镇",
        "511025119": "明心寺镇",
        "511025120": "双河镇",
        "511025121": "公民镇",
        "511025122": "龙江镇",
        "511025123": "双龙镇",
        "511025124": "高楼镇",
        "511025125": "陈家镇",
        "511025126": "配龙镇",
        "511025127": "走马镇",
        "511025128": "孟塘镇",
        "511025129": "马鞍镇",
        "511025130": "狮子镇",
        "511025131": "板栗桠镇",
        "511025132": "龙山镇"
    },
    "511071": {"511071006": "壕子口街道", "511071101": "交通镇", "511071102": "四合镇"},
    "511083": {
        "511083001": "古湖街道",
        "511083002": "金鹅街道",
        "511083101": "山川镇",
        "511083102": "响石镇",
        "511083103": "圣灯镇",
        "511083104": "黄家镇",
        "511083105": "双凤镇",
        "511083106": "龙市镇",
        "511083107": "迎祥镇",
        "511083108": "界市镇",
        "511083109": "石碾镇",
        "511083110": "周兴镇",
        "511083111": "渔箭镇",
        "511083112": "石燕桥镇",
        "511083113": "李市镇",
        "511083114": "胡家镇",
        "511083115": "云顶镇",
        "511083116": "桂花井镇",
        "511083117": "普润镇"
    },
    "511100": {
        "511102": "市中区",
        "511111": "沙湾区",
        "511112": "五通桥区",
        "511113": "金口河区",
        "511123": "犍为县",
        "511124": "井研县",
        "511126": "夹江县",
        "511129": "沐川县",
        "511132": "峨边彝族自治县",
        "511133": "马边彝族自治县",
        "511181": "峨眉山市"
    },
    "511102": {
        "511102001": "张公桥街道",
        "511102002": "泊水街街道",
        "511102003": "上河街街道",
        "511102004": "大佛街道",
        "511102005": "肖坝街道",
        "511102006": "通江街道",
        "511102007": "柏杨街道",
        "511102101": "牟子镇",
        "511102102": "土主镇",
        "511102103": "白马镇",
        "511102104": "茅桥镇",
        "511102105": "青平镇",
        "511102106": "苏稽镇",
        "511102107": "水口镇",
        "511102108": "安谷镇",
        "511102109": "棉竹镇",
        "511102110": "全福镇",
        "511102111": "童家镇",
        "511102112": "九峰镇",
        "511102113": "罗汉镇",
        "511102114": "临江镇",
        "511102115": "车子镇",
        "511102200": "悦来乡",
        "511102201": "关庙乡",
        "511102202": "石龙乡",
        "511102203": "剑峰乡",
        "511102204": "凌云乡",
        "511102205": "迎阳乡",
        "511102206": "九龙乡",
        "511102207": "普仁乡",
        "511102208": "平兴乡",
        "511102209": "杨湾乡"
    },
    "511111": {
        "511111100": "沙湾镇",
        "511111101": "嘉农镇",
        "511111102": "太平镇",
        "511111103": "福禄镇",
        "511111104": "牛石镇",
        "511111105": "龚嘴镇",
        "511111106": "葫芦镇",
        "511111107": "踏水镇",
        "511111200": "谭坝乡",
        "511111201": "轸溪乡",
        "511111202": "范店乡",
        "511111203": "铜茨乡",
        "511111204": "碧山乡",
        "511111400": "沫江"
    },
    "511112": {
        "511112100": "竹根镇",
        "511112101": "牛华镇",
        "511112102": "杨柳镇",
        "511112103": "桥沟镇",
        "511112104": "金粟镇",
        "511112105": "金山镇",
        "511112106": "辉山镇",
        "511112107": "西坝镇",
        "511112108": "冠英镇",
        "511112109": "蔡金镇",
        "511112110": "石麟镇",
        "511112200": "新云乡"
    },
    "511113": {
        "511113100": "永和镇",
        "511113101": "金河镇",
        "511113200": "和平彝族乡",
        "511113201": "共安彝族乡",
        "511113202": "吉星乡",
        "511113203": "永胜乡"
    },
    "511123": {
        "511123100": "玉津镇",
        "511123101": "孝姑镇",
        "511123102": "石溪镇",
        "511123103": "清溪镇",
        "511123104": "新民镇",
        "511123105": "罗城镇",
        "511123106": "芭沟镇",
        "511123107": "龙孔镇",
        "511123108": "定文镇",
        "511123109": "敖家镇",
        "511123110": "金石井镇",
        "511123111": "泉水镇",
        "511123200": "双溪乡",
        "511123201": "九井乡",
        "511123202": "同兴乡",
        "511123203": "榨鼓乡",
        "511123204": "铁炉乡",
        "511123205": "大兴乡",
        "511123206": "南阳乡",
        "511123207": "纪家乡",
        "511123208": "新盛乡",
        "511123209": "寿保乡",
        "511123210": "舞雩乡",
        "511123211": "下渡乡",
        "511123212": "玉屏乡",
        "511123213": "岷东乡",
        "511123214": "塘坝乡",
        "511123215": "马庙乡",
        "511123216": "公平乡",
        "511123217": "伏龙乡"
    },
    "511124": {
        "511124100": "研城镇",
        "511124101": "马踏镇",
        "511124102": "竹园镇",
        "511124103": "研经镇",
        "511124104": "周坡镇",
        "511124105": "千佛镇",
        "511124106": "王村镇",
        "511124107": "三江镇",
        "511124108": "东林镇",
        "511124109": "磨池镇",
        "511124200": "集益乡",
        "511124201": "纯复乡",
        "511124202": "三教乡",
        "511124203": "高滩乡",
        "511124204": "宝五乡",
        "511124205": "四合乡",
        "511124206": "黄钵乡",
        "511124207": "胜泉乡",
        "511124208": "门坎乡",
        "511124209": "石牛乡",
        "511124210": "高凤乡",
        "511124211": "金峰乡",
        "511124212": "分全乡",
        "511124213": "镇阳乡",
        "511124214": "天云乡",
        "511124215": "乌抛乡",
        "511124216": "大佛乡"
    },
    "511126": {
        "511126100": "漹城镇",
        "511126101": "黄土镇",
        "511126102": "甘江镇",
        "511126103": "界牌镇",
        "511126104": "中兴镇",
        "511126105": "三洞镇",
        "511126106": "吴场镇",
        "511126107": "木城镇",
        "511126108": "华头镇",
        "511126109": "甘霖镇",
        "511126110": "新场镇",
        "511126200": "顺河乡",
        "511126201": "马村乡",
        "511126202": "土门乡",
        "511126203": "青州乡",
        "511126204": "梧凤乡",
        "511126205": "永青乡",
        "511126206": "迎江乡",
        "511126207": "龙沱乡",
        "511126208": "南安乡",
        "511126209": "歇马乡",
        "511126210": "麻柳乡"
    },
    "511129": {
        "511129100": "沐溪镇",
        "511129101": "永福镇",
        "511129102": "大楠镇",
        "511129103": "箭板镇",
        "511129104": "舟坝镇",
        "511129105": "黄丹镇",
        "511129106": "利店镇",
        "511129200": "建和乡",
        "511129201": "幸福乡",
        "511129202": "新凡乡",
        "511129203": "富和乡",
        "511129204": "炭库乡",
        "511129205": "底堡乡",
        "511129206": "杨村乡",
        "511129207": "高笋乡",
        "511129208": "茨竹乡",
        "511129209": "海云乡",
        "511129210": "武圣乡",
        "511129211": "凤村乡",
        "511129400": "五马坪监狱"
    },
    "511132": {
        "511132100": "沙坪镇",
        "511132101": "大堡镇",
        "511132102": "毛坪镇",
        "511132103": "五渡镇",
        "511132104": "新林镇",
        "511132105": "黑竹沟镇",
        "511132200": "红花乡",
        "511132201": "宜坪乡",
        "511132202": "杨村乡",
        "511132203": "白杨乡",
        "511132204": "觉莫乡",
        "511132205": "万坪乡",
        "511132206": "杨河乡",
        "511132207": "共和乡",
        "511132208": "新场乡",
        "511132209": "平等乡",
        "511132210": "哈曲乡",
        "511132211": "金岩乡",
        "511132212": "勒乌乡"
    },
    "511133": {
        "511133100": "民建镇",
        "511133101": "荣丁镇",
        "511133102": "下溪镇",
        "511133103": "苏坝镇",
        "511133104": "烟峰镇",
        "511133200": "劳动乡",
        "511133201": "建设乡",
        "511133203": "石梁乡",
        "511133204": "荍坝乡",
        "511133206": "民主乡",
        "511133207": "老河坝乡",
        "511133209": "雪口山乡",
        "511133210": "镇江庙乡",
        "511133211": "大竹堡乡",
        "511133215": "袁家溪乡",
        "511133216": "沙腔乡",
        "511133217": "三河口乡",
        "511133221": "梅子坝乡",
        "511133223": "高卓营乡",
        "511133226": "永红乡"
    },
    "511181": {
        "511181100": "绥山镇",
        "511181101": "高桥镇",
        "511181102": "罗目镇",
        "511181103": "九里镇",
        "511181104": "龙池镇",
        "511181105": "乐都镇",
        "511181106": "符溪镇",
        "511181107": "峨山镇",
        "511181108": "双福镇",
        "511181109": "桂花桥镇",
        "511181110": "大为镇",
        "511181111": "胜利镇",
        "511181112": "黄湾镇",
        "511181113": "新平镇",
        "511181114": "川主镇",
        "511181200": "龙门乡",
        "511181202": "沙溪乡",
        "511181204": "普兴乡",
        "511181400": "东方电气集团峨嵋半导体材料有限公司",
        "511181401": "四川川投峨眉铁合金（集团）有限责任公司",
        "511181402": "四川省食品药品学校",
        "511181403": "五二五厂",
        "511181404": "西南交大峨眉校区"
    },
    "511300": {
        "511302": "顺庆区",
        "511303": "高坪区",
        "511304": "嘉陵区",
        "511321": "南部县",
        "511322": "营山县",
        "511323": "蓬安县",
        "511324": "仪陇县",
        "511325": "西充县",
        "511381": "阆中市"
    },
    "511302": {
        "511302001": "中城街道",
        "511302002": "北城街道",
        "511302003": "西城街道",
        "511302004": "东南街道",
        "511302005": "舞凤街道",
        "511302006": "新建街道",
        "511302007": "华凤街道",
        "511302008": "和平路街道",
        "511302009": "潆溪街道",
        "511302010": "荆溪街道",
        "511302011": "西山街道",
        "511302104": "共兴镇",
        "511302105": "金台镇",
        "511302106": "芦溪镇",
        "511302107": "李家镇",
        "511302108": "双桥镇",
        "511302110": "搬罾镇",
        "511302111": "大林镇",
        "511302112": "辉景镇",
        "511302113": "永丰镇",
        "511302200": "新复乡",
        "511302201": "同仁乡",
        "511302205": "梵殿乡",
        "511302206": "顺河乡",
        "511302207": "灯台乡",
        "511302209": "龙桂乡",
        "511302211": "桂花乡",
        "511302212": "凤山乡",
        "511302213": "渔溪乡",
        "511302401": "潆华工业集中区"
    },
    "511303": {
        "511303001": "白塔街道",
        "511303002": "清溪街道",
        "511303003": "小龙街道",
        "511303004": "龙门街道",
        "511303005": "青莲街道",
        "511303006": "都京街道",
        "511303007": "青松街道",
        "511303103": "江陵镇",
        "511303104": "擦耳镇",
        "511303105": "老君镇",
        "511303106": "东观镇",
        "511303107": "长乐镇",
        "511303108": "胜观镇",
        "511303109": "永安镇",
        "511303110": "阙家镇",
        "511303111": "石圭镇",
        "511303112": "青居镇",
        "511303115": "会龙镇",
        "511303116": "螺溪镇",
        "511303201": "走马乡",
        "511303202": "喻家乡",
        "511303203": "马家乡",
        "511303204": "黄溪乡",
        "511303205": "万家乡",
        "511303207": "御史乡",
        "511303208": "隆兴乡",
        "511303209": "斑竹乡",
        "511303210": "鄢家乡",
        "511303211": "佛门乡",
        "511303212": "溪头乡",
        "511303215": "凤凰乡",
        "511303218": "南江乡"
    },
    "511304": {
        "511304001": "火花街道",
        "511304002": "凤垭街道",
        "511304003": "都尉街道",
        "511304004": "文峰街道",
        "511304005": "西兴街道",
        "511304006": "南湖街道",
        "511304101": "曲水镇",
        "511304102": "李渡镇",
        "511304103": "吉安镇",
        "511304104": "龙岭镇",
        "511304105": "金凤镇",
        "511304106": "安福镇",
        "511304107": "安平镇",
        "511304108": "世阳镇",
        "511304109": "大通镇",
        "511304110": "一立镇",
        "511304111": "龙蟠镇",
        "511304112": "里坝镇",
        "511304113": "集凤镇",
        "511304114": "金宝镇",
        "511304115": "三会镇",
        "511304117": "双桂镇",
        "511304119": "七宝寺镇",
        "511304120": "龙泉镇",
        "511304121": "河西镇",
        "511304122": "花园镇",
        "511304123": "木老镇",
        "511304124": "华兴镇",
        "511304202": "移山乡",
        "511304205": "新场乡",
        "511304207": "土门乡",
        "511304208": "临江乡",
        "511304209": "双店乡",
        "511304211": "白家乡",
        "511304213": "大同乡",
        "511304215": "盐溪乡",
        "511304216": "桥龙乡",
        "511304218": "天星乡",
        "511304219": "大观乡",
        "511304220": "大兴乡",
        "511304221": "新庙乡",
        "511304222": "桃园乡",
        "511304223": "太和乡",
        "511304226": "积善乡",
        "511304228": "石楼乡",
        "511304229": "礼乐乡"
    },
    "511321": {
        "511321001": "滨江街道",
        "511321002": "蜀北街道",
        "511321100": "南隆镇",
        "511321101": "河东镇",
        "511321102": "老鸦镇",
        "511321103": "永定镇",
        "511321104": "碑院镇",
        "511321105": "谢河镇",
        "511321106": "盘龙镇",
        "511321107": "铁佛塘镇",
        "511321108": "石河镇",
        "511321109": "王家镇",
        "511321110": "富利镇",
        "511321111": "楠木镇",
        "511321112": "长坪镇",
        "511321113": "东坝镇",
        "511321114": "河坝镇",
        "511321115": "定水镇",
        "511321116": "大王镇",
        "511321117": "黄金镇",
        "511321118": "流马镇",
        "511321119": "建兴镇",
        "511321120": "三官镇",
        "511321121": "伏虎镇",
        "511321122": "双佛镇",
        "511321123": "花罐镇",
        "511321124": "大桥镇",
        "511321125": "大河镇",
        "511321126": "万年镇",
        "511321127": "升钟镇",
        "511321128": "升水镇",
        "511321129": "大坪镇",
        "511321130": "神坝镇",
        "511321131": "八尔湖镇",
        "511321200": "碾盘乡",
        "511321201": "火峰乡",
        "511321202": "群龙乡",
        "511321204": "大富乡",
        "511321206": "碧龙乡",
        "511321208": "三清乡",
        "511321209": "中心乡",
        "511321210": "五灵乡",
        "511321211": "平桥乡",
        "511321212": "梅家乡",
        "511321213": "龙庙乡",
        "511321214": "马王乡",
        "511321216": "窑场乡",
        "511321217": "太华乡",
        "511321218": "兴盛乡",
        "511321220": "寒坡乡",
        "511321222": "肖家乡",
        "511321225": "四龙乡",
        "511321226": "碾垭乡",
        "511321227": "千秋乡",
        "511321228": "玉镇乡",
        "511321229": "小元乡",
        "511321230": "柳驿乡",
        "511321231": "石泉乡",
        "511321232": "雄狮乡",
        "511321233": "宏观乡",
        "511321234": "永庆乡",
        "511321235": "永红乡",
        "511321236": "柳树乡",
        "511321237": "保城乡",
        "511321238": "双峰乡",
        "511321239": "皂角乡",
        "511321240": "丘垭乡",
        "511321241": "光中乡",
        "511321242": "铁鞭乡",
        "511321243": "太霞乡",
        "511321244": "店垭乡",
        "511321245": "桐坪乡",
        "511321246": "西河乡",
        "511321401": "工业园区"
    },
    "511322": {
        "511322001": "绥安街道",
        "511322100": "朗池镇",
        "511322101": "渌井镇",
        "511322102": "东升镇",
        "511322103": "骆市镇",
        "511322104": "黄渡镇",
        "511322105": "小桥镇",
        "511322106": "灵鹫镇",
        "511322107": "老林镇",
        "511322108": "木垭镇",
        "511322109": "消水镇",
        "511322110": "双流镇",
        "511322111": "绿水镇",
        "511322112": "三兴镇",
        "511322113": "蓼叶镇",
        "511322114": "新店镇",
        "511322115": "回龙镇",
        "511322116": "星火镇",
        "511322117": "西桥镇",
        "511322118": "城南镇",
        "511322119": "济川镇",
        "511322120": "茶盘镇",
        "511322204": "双溪乡",
        "511322206": "带河乡",
        "511322207": "四喜乡",
        "511322210": "玲珑乡",
        "511322211": "涌泉乡",
        "511322212": "木顶乡",
        "511322213": "清源乡",
        "511322214": "龙伏乡",
        "511322215": "双林乡",
        "511322216": "明德乡",
        "511322217": "普岭乡",
        "511322218": "三元乡",
        "511322219": "太蓬乡",
        "511322221": "柏林乡",
        "511322222": "孔雀乡",
        "511322223": "合兴乡",
        "511322224": "六合乡",
        "511322225": "悦中乡",
        "511322227": "高码乡",
        "511322228": "安固乡",
        "511322230": "大庙乡",
        "511322231": "通天乡",
        "511322232": "安化乡",
        "511322233": "法堂乡",
        "511322234": "增产乡",
        "511322236": "丰产乡",
        "511322237": "清水乡",
        "511322238": "青山乡",
        "511322239": "福源乡",
        "511322240": "柏坪乡",
        "511322241": "七涧乡",
        "511322243": "凉风乡"
    },
    "511323": {
        "511323101": "锦屏镇",
        "511323102": "巨龙镇",
        "511323103": "正源镇",
        "511323104": "龙云镇",
        "511323105": "金溪镇",
        "511323106": "徐家镇",
        "511323107": "河舒镇",
        "511323108": "利溪镇",
        "511323109": "龙蚕镇",
        "511323110": "杨家镇",
        "511323111": "罗家镇",
        "511323112": "福德镇",
        "511323113": "银汉镇",
        "511323114": "兴旺镇",
        "511323115": "相如镇",
        "511323201": "高庙乡",
        "511323202": "群乐乡",
        "511323203": "长梁乡",
        "511323204": "两路乡",
        "511323205": "睦坝乡",
        "511323207": "石梁乡",
        "511323208": "平头乡",
        "511323209": "鲜店乡",
        "511323211": "茶亭乡",
        "511323212": "诸家乡",
        "511323214": "骑龙乡",
        "511323215": "金甲乡",
        "511323217": "新园乡",
        "511323218": "三坝乡",
        "511323220": "碧溪乡",
        "511323221": "柳滩乡",
        "511323222": "石孔乡",
        "511323223": "开元乡",
        "511323225": "新河乡",
        "511323226": "南燕乡",
        "511323227": "天成乡",
        "511323228": "海田乡",
        "511323229": "济渡乡",
        "511323230": "凤石乡"
    },
    "511324": {
        "511324100": "金城镇",
        "511324101": "新政镇",
        "511324102": "马鞍镇",
        "511324103": "永乐镇",
        "511324104": "日兴镇",
        "511324105": "土门镇",
        "511324106": "复兴镇",
        "511324107": "观紫镇",
        "511324108": "先锋镇",
        "511324109": "三蛟镇",
        "511324110": "回春镇",
        "511324111": "柳垭镇",
        "511324112": "义路镇",
        "511324113": "立山镇",
        "511324114": "三河镇",
        "511324115": "瓦子镇",
        "511324116": "大寅镇",
        "511324117": "二道镇",
        "511324118": "赛金镇",
        "511324119": "丁字桥镇",
        "511324120": "大仪镇",
        "511324121": "张公镇",
        "511324122": "五福镇",
        "511324123": "周河镇",
        "511324124": "杨桥镇",
        "511324125": "保平镇",
        "511324126": "文星镇",
        "511324127": "双胜镇",
        "511324128": "度门镇",
        "511324200": "老木乡",
        "511324201": "檬垭乡",
        "511324202": "铜鼓乡",
        "511324205": "中坝乡",
        "511324206": "双盘乡",
        "511324207": "凤仪乡",
        "511324208": "双庆乡",
        "511324209": "大风乡",
        "511324210": "福临乡",
        "511324212": "来仪乡",
        "511324213": "碧泉乡",
        "511324214": "乐兴乡",
        "511324216": "石佛乡",
        "511324217": "思德乡",
        "511324218": "秋垭乡",
        "511324219": "大罗乡",
        "511324220": "义门乡",
        "511324221": "合作乡",
        "511324225": "龙桥乡",
        "511324226": "板桥乡",
        "511324227": "永光乡",
        "511324228": "炬光乡",
        "511324229": "九龙乡",
        "511324230": "芭蕉乡",
        "511324231": "灯塔乡",
        "511324232": "武棚乡",
        "511324241": "柴井乡",
        "511324242": "光华乡"
    },
    "511325": {
        "511325100": "晋城镇",
        "511325101": "太平镇",
        "511325102": "大全镇",
        "511325103": "仙林镇",
        "511325104": "古楼镇",
        "511325105": "义兴镇",
        "511325106": "关文镇",
        "511325107": "凤鸣镇",
        "511325108": "青狮镇",
        "511325109": "槐树镇",
        "511325110": "鸣龙镇",
        "511325111": "双凤镇",
        "511325112": "高院镇",
        "511325113": "仁和镇",
        "511325114": "多扶镇",
        "511325115": "莲池镇",
        "511325202": "常林乡",
        "511325203": "占山乡",
        "511325205": "宏桥乡",
        "511325207": "金泉乡",
        "511325208": "华光乡",
        "511325209": "金源乡",
        "511325210": "岱林乡",
        "511325211": "李桥乡",
        "511325212": "中岭乡",
        "511325213": "西碾乡",
        "511325214": "紫岩乡",
        "511325215": "复安乡",
        "511325216": "观凤乡",
        "511325217": "青龙乡",
        "511325218": "双洛乡",
        "511325219": "义和乡",
        "511325221": "中南乡",
        "511325222": "双江乡",
        "511325223": "凤和乡",
        "511325224": "东岱乡",
        "511325225": "同德乡",
        "511325226": "祥龙乡",
        "511325227": "车龙乡",
        "511325228": "扶君乡",
        "511325229": "东太乡",
        "511325230": "永清乡",
        "511325231": "金山乡",
        "511325232": "罐垭乡"
    },
    "511381": {
        "511381001": "保宁街道",
        "511381003": "沙溪街道",
        "511381004": "七里街道",
        "511381005": "江南街道",
        "511381103": "彭城镇",
        "511381104": "双龙镇",
        "511381105": "柏垭镇",
        "511381106": "飞凤镇",
        "511381107": "思依镇",
        "511381108": "文成镇",
        "511381109": "二龙镇",
        "511381110": "石滩镇",
        "511381111": "老观镇",
        "511381112": "龙泉镇",
        "511381113": "千佛镇",
        "511381114": "望垭镇",
        "511381115": "河溪镇",
        "511381116": "妙高镇",
        "511381117": "洪山镇",
        "511381118": "石龙镇",
        "511381119": "宝马镇",
        "511381120": "水观镇",
        "511381121": "金垭镇",
        "511381122": "玉台镇",
        "511381123": "裕华镇",
        "511381124": "木兰镇",
        "511381125": "东兴镇",
        "511381126": "凉水镇",
        "511381127": "五马镇",
        "511381201": "垭口乡",
        "511381202": "治平乡",
        "511381208": "天宫乡",
        "511381209": "天林乡",
        "511381210": "枣碧乡",
        "511381211": "北门乡",
        "511381212": "桥楼乡",
        "511381213": "河楼乡",
        "511381217": "清泉乡",
        "511381219": "博树回族乡",
        "511381224": "解元乡",
        "511381225": "西山乡",
        "511381226": "方山乡",
        "511381232": "金子乡",
        "511381233": "三庙乡",
        "511381234": "峰占乡",
        "511381235": "鹤峰乡",
        "511381239": "金城乡",
        "511381243": "朱镇乡",
        "511381245": "宝台乡",
        "511381247": "福星乡"
    },
    "511400": {"511402": "东坡区", "511403": "彭山区", "511421": "仁寿县", "511423": "洪雅县", "511424": "丹棱县", "511425": "青神县"},
    "511402": {
        "511402001": "通惠街道",
        "511402002": "大石桥街道",
        "511402003": "苏祠街道",
        "511402100": "白马镇",
        "511402101": "象耳镇",
        "511402102": "太和镇",
        "511402103": "悦兴镇",
        "511402104": "尚义镇",
        "511402105": "多悦镇",
        "511402106": "秦家镇",
        "511402107": "万胜镇",
        "511402108": "崇仁镇",
        "511402109": "思蒙镇",
        "511402110": "修文镇",
        "511402112": "松江镇",
        "511402113": "崇礼镇",
        "511402114": "富牛镇",
        "511402115": "永寿镇",
        "511402116": "三苏镇",
        "511402205": "广济乡",
        "511402206": "盘鳌乡",
        "511402210": "土地乡",
        "511402211": "复盛乡",
        "511402212": "复兴乡",
        "511402213": "金花乡",
        "511402214": "柳圣乡"
    },
    "511403": {
        "511403001": "凤鸣街道",
        "511403002": "彭溪街道",
        "511403115": "武阳镇",
        "511403116": "江口镇",
        "511403118": "公义镇",
        "511403119": "牧马镇",
        "511403120": "谢家镇",
        "511403121": "黄丰镇",
        "511403122": "观音镇",
        "511403124": "青龙镇",
        "511403214": "保胜乡",
        "511403215": "义和乡",
        "511403216": "锦江乡"
    },
    "511421": {
        "511421102": "文宫镇",
        "511421112": "禾加镇",
        "511421116": "龙马镇",
        "511421117": "方家镇",
        "511421123": "文林镇",
        "511421124": "大化镇",
        "511421125": "高家镇",
        "511421126": "中农镇",
        "511421127": "禄加镇",
        "511421128": "宝飞镇",
        "511421129": "彰加镇",
        "511421130": "慈航镇",
        "511421131": "汪洋镇",
        "511421132": "钟祥镇",
        "511421133": "始建镇",
        "511421134": "满井镇",
        "511421135": "富加镇",
        "511421136": "龙正镇",
        "511421137": "黑龙滩镇",
        "511421138": "清水镇",
        "511421139": "视高镇",
        "511421140": "北斗镇",
        "511421141": "兴盛镇",
        "511421142": "观寺镇",
        "511421143": "宝马镇",
        "511421144": "珠嘉镇",
        "511421145": "四公镇",
        "511421146": "曹家镇",
        "511421147": "天峨镇",
        "511421148": "中岗镇",
        "511421149": "向家镇",
        "511421150": "识经镇",
        "511421151": "曲江镇",
        "511421152": "玉龙镇",
        "511421153": "元通镇",
        "511421154": "里仁镇",
        "511421155": "农旺镇",
        "511421200": "虞丞乡",
        "511421203": "青岗乡",
        "511421209": "古佛乡",
        "511421210": "板燕乡",
        "511421218": "石咀乡",
        "511421219": "藕塘乡",
        "511421232": "合兴乡",
        "511421239": "促进乡",
        "511421251": "鸭池乡",
        "511421254": "双堡乡",
        "511421258": "河口乡",
        "511421259": "板桥乡",
        "511421281": "兆嘉乡",
        "511421289": "鳌陵乡",
        "511421292": "龙桥乡",
        "511421294": "城堰乡",
        "511421295": "谢安乡",
        "511421296": "新店乡",
        "511421299": "凤陵乡",
        "511421300": "涂加乡",
        "511421302": "松峰乡",
        "511421304": "景贤乡",
        "511421306": "洪峰乡"
    },
    "511423": {
        "511423101": "止戈镇",
        "511423102": "三宝镇",
        "511423108": "花溪镇",
        "511423112": "洪川镇",
        "511423113": "余坪镇",
        "511423114": "槽渔滩镇",
        "511423115": "中保镇",
        "511423116": "东岳镇",
        "511423117": "柳江镇",
        "511423118": "高庙镇",
        "511423119": "瓦屋山镇",
        "511423120": "七里坪镇",
        "511423200": "中山乡",
        "511423201": "将军乡",
        "511423205": "汉王乡"
    },
    "511424": {
        "511424102": "仁美镇",
        "511424105": "丹棱镇",
        "511424106": "杨场镇",
        "511424107": "双桥镇",
        "511424108": "张场镇",
        "511424200": "石桥乡",
        "511424204": "顺龙乡"
    },
    "511425": {
        "511425102": "汉阳镇",
        "511425105": "河坝子镇",
        "511425106": "南城镇",
        "511425107": "青城镇",
        "511425108": "瑞峰镇",
        "511425109": "黑龙镇",
        "511425110": "西龙镇",
        "511425205": "高台乡",
        "511425217": "白果乡",
        "511425218": "罗波乡"
    },
    "511500": {
        "511502": "翠屏区",
        "511503": "南溪区",
        "511504": "叙州区",
        "511523": "江安县",
        "511524": "长宁县",
        "511525": "高县",
        "511526": "珙县",
        "511527": "筠连县",
        "511528": "兴文县",
        "511529": "屏山县"
    },
    "511502": {
        "511502001": "北城街道",
        "511502002": "东城街道",
        "511502003": "南城街道",
        "511502004": "西城街道",
        "511502006": "西郊街道",
        "511502007": "安阜街道",
        "511502008": "白沙湾街道",
        "511502010": "象鼻街道",
        "511502011": "沙坪街道",
        "511502101": "李庄镇",
        "511502102": "菜坝镇",
        "511502104": "金坪镇",
        "511502106": "高店镇",
        "511502108": "牟坪镇",
        "511502109": "李端镇",
        "511502110": "邱场镇",
        "511502111": "宗场镇",
        "511502112": "宋家镇",
        "511502113": "明威镇",
        "511502114": "凉姜镇",
        "511502115": "思坡镇",
        "511502116": "白花镇",
        "511502117": "孔滩镇",
        "511502118": "双谊镇",
        "511502119": "王场镇",
        "511502120": "永兴镇"
    },
    "511503": {
        "511503001": "南溪街道",
        "511503002": "罗龙街道",
        "511503102": "刘家镇",
        "511503103": "江南镇",
        "511503104": "大观镇",
        "511503105": "汪家镇",
        "511503106": "黄沙镇",
        "511503107": "仙临镇",
        "511503108": "长兴镇",
        "511503109": "裴石镇",
        "511503201": "马家乡",
        "511503203": "大坪乡",
        "511503204": "石鼓乡",
        "511503205": "林丰乡",
        "511503206": "留宾乡"
    },
    "511504": {
        "511504002": "南岸街道",
        "511504003": "赵场街道",
        "511504100": "柏溪镇",
        "511504101": "南广镇",
        "511504102": "喜捷镇",
        "511504103": "观音镇",
        "511504104": "横江镇",
        "511504105": "柳嘉镇",
        "511504106": "泥溪镇",
        "511504107": "蕨溪镇",
        "511504108": "商州镇",
        "511504109": "高场镇",
        "511504110": "安边镇",
        "511504111": "双龙镇",
        "511504112": "李场镇",
        "511504113": "合什镇",
        "511504114": "古罗镇",
        "511504115": "复龙镇",
        "511504116": "普安镇",
        "511504117": "古柏镇",
        "511504118": "泥南镇",
        "511504201": "隆兴乡",
        "511504202": "龙池乡",
        "511504203": "凤仪乡"
    },
    "511523": {
        "511523100": "江安镇",
        "511523101": "红桥镇",
        "511523102": "桐梓镇",
        "511523103": "井口镇",
        "511523104": "怡乐镇",
        "511523105": "留耕镇",
        "511523106": "底蓬镇",
        "511523108": "五矿镇",
        "511523109": "迎安镇",
        "511523110": "夕佳山镇",
        "511523111": "水清镇",
        "511523112": "铁清镇",
        "511523113": "四面山镇",
        "511523114": "大井镇",
        "511523115": "阳春镇",
        "511523200": "大妙乡",
        "511523203": "蟠龙乡",
        "511523205": "仁和乡"
    },
    "511524": {
        "511524100": "长宁镇",
        "511524101": "梅硐镇",
        "511524102": "双河镇",
        "511524103": "硐底镇",
        "511524104": "花滩镇",
        "511524105": "竹海镇",
        "511524106": "老翁镇",
        "511524107": "古河镇",
        "511524108": "下长镇",
        "511524110": "龙头镇",
        "511524111": "开佛镇",
        "511524112": "铜鼓镇",
        "511524113": "井江镇",
        "511524200": "铜锣乡",
        "511524201": "桃坪乡",
        "511524205": "三元乡",
        "511524207": "富兴乡",
        "511524208": "梅白乡"
    },
    "511525": {
        "511525100": "文江镇",
        "511525101": "庆符镇",
        "511525102": "沙河镇",
        "511525103": "嘉乐镇",
        "511525104": "大窝镇",
        "511525105": "罗场镇",
        "511525106": "蕉村镇",
        "511525107": "可久镇",
        "511525108": "来复镇",
        "511525109": "月江镇",
        "511525110": "胜天镇",
        "511525111": "复兴镇",
        "511525203": "趱滩乡",
        "511525204": "羊田乡",
        "511525205": "落润乡",
        "511525206": "潆溪乡",
        "511525207": "庆岭乡",
        "511525208": "双河乡",
        "511525210": "四烈乡"
    },
    "511526": {
        "511526100": "珙泉镇",
        "511526101": "巡场镇",
        "511526102": "孝儿镇",
        "511526103": "底洞镇",
        "511526104": "上罗镇",
        "511526105": "洛表镇",
        "511526106": "洛亥镇",
        "511526107": "王家镇",
        "511526108": "沐滩镇",
        "511526109": "下罗镇",
        "511526110": "曹营镇",
        "511526200": "恒丰乡",
        "511526203": "仁义乡",
        "511526205": "玉和苗族乡",
        "511526209": "罗渡苗族乡",
        "511526211": "石碑乡",
        "511526212": "观斗苗族乡"
    },
    "511527": {
        "511527100": "筠连镇",
        "511527101": "腾达镇",
        "511527102": "巡司镇",
        "511527103": "双腾镇",
        "511527104": "沐爱镇",
        "511527105": "维新镇",
        "511527107": "镇舟镇",
        "511527108": "蒿坝镇",
        "511527109": "大雪山镇",
        "511527200": "武德乡",
        "511527202": "塘坝乡",
        "511527203": "龙镇乡",
        "511527204": "孔雀乡",
        "511527205": "乐义乡",
        "511527206": "高坎乡",
        "511527208": "团林苗族乡",
        "511527209": "联合苗族乡",
        "511527210": "高坪苗族乡"
    },
    "511528": {
        "511528100": "古宋镇",
        "511528101": "僰王山镇",
        "511528103": "共乐镇",
        "511528105": "莲花镇",
        "511528107": "九丝城镇",
        "511528108": "石海镇",
        "511528109": "太平镇",
        "511528110": "周家镇",
        "511528111": "五星镇",
        "511528112": "玉屏镇",
        "511528200": "大坝苗族乡",
        "511528201": "毓秀苗族乡",
        "511528205": "大河苗族乡",
        "511528206": "麒麟苗族乡",
        "511528207": "仙峰苗族乡"
    },
    "511529": {
        "511529100": "锦屏镇",
        "511529101": "新市镇",
        "511529102": "中都镇",
        "511529103": "龙华镇",
        "511529104": "大乘镇",
        "511529106": "富荣镇",
        "511529107": "新安镇",
        "511529109": "书楼镇",
        "511529110": "屏山镇",
        "511529203": "鸭池乡",
        "511529204": "龙溪乡",
        "511529205": "太平乡",
        "511529208": "夏溪乡",
        "511529210": "屏边彝族乡",
        "511529211": "清平彝族乡"
    },
    "511600": {"511602": "广安区", "511603": "前锋区", "511621": "岳池县", "511622": "武胜县", "511623": "邻水县", "511681": "华蓥市"},
    "511602": {
        "511602001": "浓洄街道",
        "511602002": "北辰街道",
        "511602004": "广福街道",
        "511602005": "万盛街道",
        "511602006": "中桥街道",
        "511602100": "枣山镇",
        "511602101": "官盛镇",
        "511602102": "协兴镇",
        "511602103": "浓溪镇",
        "511602104": "悦来镇",
        "511602105": "兴平镇",
        "511602106": "井河镇",
        "511602107": "花桥镇",
        "511602108": "龙台镇",
        "511602109": "肖溪镇",
        "511602110": "恒升镇",
        "511602111": "石笋镇",
        "511602112": "白市镇",
        "511602113": "大安镇",
        "511602200": "穿石乡",
        "511602201": "广门乡",
        "511602202": "广罗乡",
        "511602203": "方坪乡",
        "511602204": "化龙乡",
        "511602205": "大龙乡",
        "511602206": "崇望乡",
        "511602207": "龙安乡",
        "511602208": "彭家乡",
        "511602209": "杨坪乡",
        "511602210": "郑山乡",
        "511602211": "蒲莲乡",
        "511602212": "大有乡",
        "511602213": "消河乡",
        "511602214": "东岳乡",
        "511602215": "苏溪乡",
        "511602216": "白马乡"
    },
    "511603": {
        "511603001": "奎阁街道",
        "511603002": "大佛寺街道",
        "511603003": "龙塘街道",
        "511603004": "新桥街道",
        "511603101": "桂兴镇",
        "511603102": "观阁镇",
        "511603103": "广兴镇",
        "511603104": "代市镇",
        "511603105": "观塘镇",
        "511603106": "护安镇",
        "511603107": "龙滩镇",
        "511603108": "虎城镇",
        "511603200": "小井乡",
        "511603202": "光辉乡"
    },
    "511621": {
        "511621100": "九龙镇",
        "511621101": "花园镇",
        "511621102": "坪滩镇",
        "511621103": "龙孔镇",
        "511621104": "镇裕镇",
        "511621105": "白庙镇",
        "511621106": "酉溪镇",
        "511621107": "同兴镇",
        "511621108": "兴隆镇",
        "511621109": "秦溪镇",
        "511621110": "顾县镇",
        "511621111": "苟角镇",
        "511621112": "天平镇",
        "511621113": "石垭镇",
        "511621114": "乔家镇",
        "511621115": "罗渡镇",
        "511621116": "裕民镇",
        "511621117": "中和镇",
        "511621118": "新场镇",
        "511621119": "普安镇",
        "511621120": "赛龙镇",
        "511621121": "临溪镇",
        "511621200": "朝阳乡",
        "511621201": "北城乡",
        "511621202": "镇龙乡",
        "511621203": "粽粑乡",
        "511621205": "排楼乡",
        "511621206": "西板乡",
        "511621207": "嘉陵乡",
        "511621208": "石鼓乡",
        "511621209": "平安乡",
        "511621210": "恐龙乡",
        "511621211": "团结乡",
        "511621212": "黄龙乡",
        "511621213": "双鄢乡",
        "511621214": "东板乡",
        "511621215": "长田乡",
        "511621216": "鱼峰乡",
        "511621218": "大石乡",
        "511621219": "花板乡",
        "511621220": "大佛乡",
        "511621222": "齐福乡",
        "511621223": "伏龙乡"
    },
    "511622": {
        "511622100": "沿口镇",
        "511622101": "中心镇",
        "511622102": "烈面镇",
        "511622103": "飞龙镇",
        "511622104": "乐善镇",
        "511622105": "万善镇",
        "511622106": "龙女镇",
        "511622107": "三溪镇",
        "511622108": "赛马镇",
        "511622109": "胜利镇",
        "511622110": "金牛镇",
        "511622111": "清平镇",
        "511622112": "街子镇",
        "511622113": "万隆镇",
        "511622114": "礼安镇",
        "511622115": "华封镇",
        "511622116": "宝箴塞镇",
        "511622200": "鸣钟乡",
        "511622201": "真静乡",
        "511622202": "猛山乡",
        "511622203": "双星乡",
        "511622204": "龙庭乡",
        "511622205": "石盘乡",
        "511622207": "旧县乡",
        "511622208": "鼓匠乡",
        "511622209": "白坪乡",
        "511622210": "永胜乡",
        "511622211": "新学乡",
        "511622213": "金光乡",
        "511622214": "八一乡",
        "511622215": "高石乡"
    },
    "511623": {
        "511623100": "鼎屏镇",
        "511623101": "城北镇",
        "511623102": "城南镇",
        "511623103": "柑子镇",
        "511623104": "龙安镇",
        "511623105": "观音桥镇",
        "511623106": "牟家镇",
        "511623107": "合流镇",
        "511623108": "坛同镇",
        "511623109": "高滩镇",
        "511623110": "九龙镇",
        "511623111": "御临镇",
        "511623112": "袁市镇",
        "511623113": "丰禾镇",
        "511623114": "八耳镇",
        "511623115": "石永镇",
        "511623116": "兴仁镇",
        "511623117": "王家镇",
        "511623118": "石滓镇",
        "511623119": "三古镇",
        "511623120": "两河镇",
        "511623200": "太和乡",
        "511623201": "新镇乡",
        "511623202": "冷家乡",
        "511623203": "长安乡",
        "511623204": "西天乡",
        "511623205": "梁板乡",
        "511623206": "甘坝乡",
        "511623207": "四海乡",
        "511623208": "九峰乡",
        "511623209": "椿木乡",
        "511623210": "华蓥乡",
        "511623211": "子中乡",
        "511623212": "风垭乡",
        "511623213": "黎家乡",
        "511623214": "龙桥乡",
        "511623215": "关河乡",
        "511623217": "长滩乡",
        "511623218": "凉山乡",
        "511623219": "复盛乡",
        "511623220": "古路乡",
        "511623221": "荆坪乡",
        "511623222": "柳塘乡",
        "511623224": "护邻乡",
        "511623225": "同石乡"
    },
    "511681": {
        "511681001": "双河街道",
        "511681002": "古桥街道",
        "511681003": "华龙街道",
        "511681101": "天池镇",
        "511681102": "禄市镇",
        "511681103": "永兴镇",
        "511681104": "明月镇",
        "511681105": "阳和镇",
        "511681106": "高兴镇",
        "511681107": "观音溪镇",
        "511681108": "溪口镇",
        "511681109": "庆华镇",
        "511681200": "红岩乡"
    },
    "511700": {
        "511702": "通川区",
        "511703": "达川区",
        "511722": "宣汉县",
        "511723": "开江县",
        "511724": "大竹县",
        "511725": "渠县",
        "511771": "达州经济开发区",
        "511781": "万源市"
    },
    "511702": {
        "511702001": "东城街道",
        "511702002": "西城街道",
        "511702003": "朝阳街道",
        "511702100": "西外镇",
        "511702101": "北外镇",
        "511702102": "罗江镇",
        "511702103": "蒲家镇",
        "511702104": "复兴镇",
        "511702105": "双龙镇",
        "511702106": "魏兴镇",
        "511702107": "江陵镇",
        "511702108": "碑庙镇",
        "511702109": "磐石镇",
        "511702110": "东岳镇",
        "511702111": "梓桐镇",
        "511702112": "北山镇",
        "511702113": "金石镇",
        "511702202": "新村乡",
        "511702203": "檬双乡",
        "511702204": "龙滩乡",
        "511702208": "安云乡",
        "511702209": "青宁乡"
    },
    "511703": {
        "511703001": "三里坪街道",
        "511703002": "翠屏街道",
        "511703100": "亭子镇",
        "511703101": "福善镇",
        "511703102": "麻柳镇",
        "511703103": "檀木镇",
        "511703104": "大树镇",
        "511703105": "南岳镇",
        "511703106": "万家镇",
        "511703107": "景市镇",
        "511703108": "百节镇",
        "511703109": "赵家镇",
        "511703110": "河市镇",
        "511703111": "石板镇",
        "511703112": "金垭镇",
        "511703113": "渡市镇",
        "511703114": "管村镇",
        "511703115": "石梯镇",
        "511703116": "石桥镇",
        "511703117": "堡子镇",
        "511703118": "平滩镇",
        "511703119": "马家镇",
        "511703120": "双庙镇",
        "511703121": "金檀镇",
        "511703122": "赵固镇",
        "511703123": "桥湾镇",
        "511703124": "木子镇",
        "511703125": "大堰镇",
        "511703126": "五四镇",
        "511703127": "罐子镇",
        "511703128": "九岭镇",
        "511703200": "大风乡",
        "511703201": "江阳乡",
        "511703202": "东兴乡",
        "511703203": "安仁乡",
        "511703204": "葫芦乡",
        "511703205": "大滩乡",
        "511703206": "花红乡",
        "511703207": "黄庭乡",
        "511703208": "黄都乡",
        "511703210": "碑高乡",
        "511703216": "陈家乡",
        "511703217": "龙会乡",
        "511703219": "申家乡",
        "511703220": "草兴乡",
        "511703221": "木头乡",
        "511703228": "银铁乡",
        "511703229": "沿河乡",
        "511703230": "香隆乡",
        "511703231": "永进乡",
        "511703232": "洛车乡",
        "511703233": "道让乡",
        "511703234": "虎让乡",
        "511703235": "米城乡"
    },
    "511722": {
        "511722100": "东乡镇",
        "511722101": "君塘镇",
        "511722102": "清溪镇",
        "511722103": "普光镇",
        "511722104": "天生镇",
        "511722105": "柏树镇",
        "511722106": "芭蕉镇",
        "511722107": "南坝镇",
        "511722108": "五宝镇",
        "511722109": "峰城镇",
        "511722110": "土黄镇",
        "511722111": "华景镇",
        "511722112": "樊哙镇",
        "511722113": "新华镇",
        "511722114": "黄金镇",
        "511722115": "胡家镇",
        "511722116": "毛坝镇",
        "511722117": "双河镇",
        "511722118": "大成镇",
        "511722119": "土主镇",
        "511722120": "下八镇",
        "511722121": "红岭镇",
        "511722122": "塔河镇",
        "511722123": "茶河镇",
        "511722124": "厂溪镇",
        "511722125": "红峰镇",
        "511722126": "七里镇",
        "511722127": "白马镇",
        "511722128": "桃花镇",
        "511722129": "柳池镇",
        "511722130": "马渡关镇",
        "511722200": "明月乡",
        "511722203": "三河乡",
        "511722204": "老君乡",
        "511722205": "黄石乡",
        "511722207": "庙安乡",
        "511722208": "天宝乡",
        "511722209": "东林乡",
        "511722211": "凉风乡",
        "511722212": "上峡乡",
        "511722215": "天台乡",
        "511722216": "观山乡",
        "511722217": "南坪乡",
        "511722218": "凤林乡",
        "511722221": "漆碑乡",
        "511722222": "三墩土家族乡",
        "511722223": "漆树土家族乡",
        "511722224": "龙泉土家族乡",
        "511722225": "渡口土家族乡",
        "511722226": "石铁乡",
        "511722229": "凤鸣乡",
        "511722230": "花池乡",
        "511722232": "庆云乡",
        "511722234": "隘口乡"
    },
    "511723": {
        "511723100": "新宁镇",
        "511723101": "普安镇",
        "511723102": "回龙镇",
        "511723103": "天师镇",
        "511723104": "永兴镇",
        "511723105": "讲治镇",
        "511723106": "甘棠镇",
        "511723107": "任市镇",
        "511723108": "广福镇",
        "511723109": "长岭镇",
        "511723110": "八庙镇",
        "511723111": "灵岩镇",
        "511723112": "宝石镇",
        "511723200": "长田乡",
        "511723201": "骑龙乡",
        "511723202": "新太乡",
        "511723204": "沙坝场乡",
        "511723205": "梅家乡",
        "511723207": "靖安乡",
        "511723208": "新街乡"
    },
    "511724": {
        "511724001": "竹阳街道",
        "511724002": "东柳街道",
        "511724003": "白塔街道",
        "511724101": "乌木镇",
        "511724102": "团坝镇",
        "511724103": "杨家镇",
        "511724104": "清河镇",
        "511724105": "柏林镇",
        "511724106": "石河镇",
        "511724107": "双拱镇",
        "511724108": "石桥铺镇",
        "511724109": "观音镇",
        "511724110": "周家镇",
        "511724111": "石子镇",
        "511724112": "文星镇",
        "511724113": "妈妈镇",
        "511724114": "高穴镇",
        "511724115": "欧家镇",
        "511724116": "庙坝镇",
        "511724117": "清水镇",
        "511724118": "月华镇",
        "511724119": "高明镇",
        "511724120": "童家镇",
        "511724121": "天城镇",
        "511724122": "人和镇",
        "511724123": "二郎镇",
        "511724124": "张家镇",
        "511724125": "四合镇",
        "511724126": "永胜镇",
        "511724127": "白坝镇",
        "511724200": "城西乡",
        "511724201": "竹北乡",
        "511724203": "朝阳乡",
        "511724205": "中华乡",
        "511724206": "黄家乡",
        "511724207": "柏家乡",
        "511724208": "李家乡",
        "511724211": "蒲包乡",
        "511724213": "新生乡",
        "511724214": "安吉乡",
        "511724216": "双溪乡",
        "511724218": "八渡乡",
        "511724219": "中和乡",
        "511724220": "杨通乡",
        "511724225": "神合乡",
        "511724226": "金鸡乡",
        "511724227": "黄滩乡",
        "511724228": "牌坊乡",
        "511724229": "姚市乡",
        "511724230": "莲印乡",
        "511724231": "川主乡"
    },
    "511725": {
        "511725100": "渠江镇",
        "511725101": "天星镇",
        "511725102": "临巴镇",
        "511725103": "土溪镇",
        "511725104": "三汇镇",
        "511725105": "文崇镇",
        "511725106": "涌兴镇",
        "511725107": "贵福镇",
        "511725108": "岩峰镇",
        "511725109": "静边镇",
        "511725110": "清溪场镇",
        "511725111": "宝城镇",
        "511725112": "有庆镇",
        "511725113": "鲜渡镇",
        "511725114": "琅琊镇",
        "511725115": "李渡镇",
        "511725116": "中滩镇",
        "511725117": "龙潭镇",
        "511725118": "三板镇",
        "511725119": "丰乐镇",
        "511725120": "李馥镇",
        "511725121": "鹤林镇",
        "511725122": "流溪镇",
        "511725123": "青龙镇",
        "511725124": "水口镇",
        "511725125": "卷硐镇",
        "511725126": "望溪镇",
        "511725127": "板桥镇",
        "511725128": "龙凤镇",
        "511725129": "新市镇",
        "511725200": "渠南乡",
        "511725201": "渠北乡",
        "511725204": "锡溪乡",
        "511725206": "河东乡",
        "511725208": "青神乡",
        "511725210": "东安乡",
        "511725211": "汇东乡",
        "511725212": "汇南乡",
        "511725213": "汇北乡",
        "511725215": "报恩乡",
        "511725216": "安北乡",
        "511725217": "平安乡",
        "511725218": "千佛乡",
        "511725219": "柏水乡",
        "511725220": "大义乡",
        "511725221": "义和乡",
        "511725224": "巨光乡",
        "511725225": "蔡和乡",
        "511725227": "白兔乡",
        "511725228": "青丝乡",
        "511725229": "万寿乡",
        "511725230": "射洪乡",
        "511725231": "望江乡",
        "511725232": "和乐乡",
        "511725235": "宋家乡",
        "511725236": "拱市乡",
        "511725238": "屏西乡",
        "511725239": "定远乡",
        "511725240": "嘉禾乡",
        "511725243": "双土乡"
    },
    "511771": {"511771200": "斌郎乡", "511771201": "幺塘乡"},
    "511781": {
        "511781100": "太平镇",
        "511781101": "青花镇",
        "511781102": "旧院镇",
        "511781103": "罗文镇",
        "511781104": "河口镇",
        "511781105": "草坝镇",
        "511781106": "竹峪镇",
        "511781107": "大竹镇",
        "511781108": "黄钟镇",
        "511781109": "官渡镇",
        "511781110": "白沙镇",
        "511781111": "沙滩镇",
        "511781112": "石窝镇",
        "511781113": "八台镇",
        "511781114": "石塘镇",
        "511781115": "铁矿镇",
        "511781116": "大沙镇",
        "511781117": "魏家镇",
        "511781118": "白果镇",
        "511781200": "茶垭乡",
        "511781201": "长石乡",
        "511781202": "白羊乡",
        "511781204": "固军乡",
        "511781205": "井溪乡",
        "511781206": "堰塘乡",
        "511781207": "蜂桶乡",
        "511781208": "花楼乡",
        "511781209": "长坝乡",
        "511781210": "曾家乡",
        "511781212": "秦河乡",
        "511781213": "庙垭乡",
        "511781214": "鹰背乡",
        "511781216": "玉带乡",
        "511781217": "新店乡",
        "511781219": "柳黄乡",
        "511781220": "溪口乡",
        "511781221": "永宁乡",
        "511781222": "虹桥乡",
        "511781223": "康乐乡",
        "511781225": "钟亭乡",
        "511781226": "庙子乡",
        "511781228": "紫溪乡",
        "511781229": "庙坡乡",
        "511781230": "梨树乡",
        "511781231": "皮窝乡",
        "511781232": "丝罗乡",
        "511781233": "罐坝乡",
        "511781234": "石人乡",
        "511781235": "赵塘乡",
        "511781236": "中坪乡",
        "511781238": "花萼乡",
        "511781239": "曹家乡"
    },
    "511800": {
        "511802": "雨城区",
        "511803": "名山区",
        "511822": "荥经县",
        "511823": "汉源县",
        "511824": "石棉县",
        "511825": "天全县",
        "511826": "芦山县",
        "511827": "宝兴县"
    },
    "511802": {
        "511802001": "东城街道",
        "511802002": "西城街道",
        "511802003": "河北街道",
        "511802004": "青江街道",
        "511802100": "北郊镇",
        "511802101": "草坝镇",
        "511802102": "合江镇",
        "511802103": "大兴镇",
        "511802104": "对岩镇",
        "511802105": "沙坪镇",
        "511802106": "中里镇",
        "511802107": "上里镇",
        "511802108": "严桥镇",
        "511802109": "晏场镇",
        "511802110": "多营镇",
        "511802111": "碧峰峡镇",
        "511802200": "南郊乡",
        "511802201": "八步乡",
        "511802202": "观化乡",
        "511802203": "孔坪乡",
        "511802204": "凤鸣乡",
        "511802205": "望鱼乡"
    },
    "511803": {
        "511803100": "蒙阳镇",
        "511803101": "百丈镇",
        "511803102": "车岭镇",
        "511803103": "永兴镇",
        "511803104": "马岭镇",
        "511803105": "新店镇",
        "511803106": "蒙顶山镇",
        "511803107": "黑竹镇",
        "511803108": "红星镇",
        "511803200": "城东乡",
        "511803201": "前进乡",
        "511803202": "中峰乡",
        "511803203": "联江乡",
        "511803204": "廖场乡",
        "511803205": "万古乡",
        "511803206": "红岩乡",
        "511803207": "双河乡",
        "511803208": "建山乡",
        "511803209": "解放乡",
        "511803210": "茅河乡"
    },
    "511822": {
        "511822100": "严道镇",
        "511822101": "花滩镇",
        "511822102": "龙苍沟镇",
        "511822103": "牛背山镇",
        "511822104": "新添镇",
        "511822200": "六合乡",
        "511822201": "烈太乡",
        "511822202": "安靖乡",
        "511822203": "民建彝族乡",
        "511822204": "烈士乡",
        "511822205": "荥河乡",
        "511822206": "新建乡",
        "511822207": "泗坪乡",
        "511822208": "新庙乡",
        "511822210": "大田坝乡",
        "511822211": "天凤乡",
        "511822212": "宝峰彝族乡",
        "511822214": "附城乡",
        "511822215": "五宪乡",
        "511822216": "烟竹乡",
        "511822217": "青龙乡"
    },
    "511823": {
        "511823100": "富林镇",
        "511823101": "九襄镇",
        "511823102": "乌斯河镇",
        "511823103": "宜东镇",
        "511823104": "富庄镇",
        "511823105": "清溪镇",
        "511823106": "大树镇",
        "511823107": "皇木镇",
        "511823108": "唐家镇",
        "511823109": "富泉镇",
        "511823200": "大田乡",
        "511823203": "河西乡",
        "511823205": "前域乡",
        "511823206": "后域乡",
        "511823209": "富乡乡",
        "511823210": "梨园乡",
        "511823211": "三交乡",
        "511823212": "双溪乡",
        "511823213": "西溪乡",
        "511823218": "安乐乡",
        "511823219": "万里乡",
        "511823220": "马烈乡",
        "511823224": "河南乡",
        "511823225": "晒经乡",
        "511823226": "料林乡",
        "511823227": "小堡藏族彝族乡",
        "511823228": "片马彝族乡",
        "511823229": "坭美彝族乡",
        "511823230": "永利彝族乡",
        "511823231": "顺河彝族乡"
    },
    "511824": {
        "511824001": "棉城街道",
        "511824100": "新棉镇",
        "511824200": "安顺彝族乡",
        "511824201": "先锋藏族乡",
        "511824202": "蟹螺藏族乡",
        "511824203": "永和乡",
        "511824204": "回隆彝族乡",
        "511824205": "擦罗彝族乡",
        "511824206": "栗子坪彝族乡",
        "511824207": "美罗乡",
        "511824208": "迎政乡",
        "511824209": "宰羊乡",
        "511824210": "丰乐乡",
        "511824211": "新民藏族彝族乡",
        "511824212": "挖角彝族藏族乡",
        "511824213": "田湾彝族乡",
        "511824214": "草科藏族乡"
    },
    "511825": {
        "511825100": "城厢镇",
        "511825101": "始阳镇",
        "511825200": "小河乡",
        "511825201": "思经乡",
        "511825202": "鱼泉乡",
        "511825203": "紫石乡",
        "511825204": "两路乡",
        "511825205": "大坪乡",
        "511825206": "乐英乡",
        "511825207": "多功乡",
        "511825208": "仁义乡",
        "511825209": "老场乡",
        "511825210": "新华乡",
        "511825211": "新场乡",
        "511825212": "兴业乡"
    },
    "511826": {
        "511826001": "芦阳街道",
        "511826101": "飞仙关镇",
        "511826102": "双石镇",
        "511826103": "太平镇",
        "511826104": "大川镇",
        "511826105": "思延镇",
        "511826106": "龙门镇",
        "511826201": "清仁乡",
        "511826203": "宝盛乡"
    },
    "511827": {
        "511827100": "穆坪镇",
        "511827101": "灵关镇",
        "511827102": "陇东镇",
        "511827201": "蜂桶寨乡",
        "511827202": "硗碛乡",
        "511827203": "永富乡",
        "511827204": "明礼乡",
        "511827205": "五龙乡",
        "511827206": "大溪乡"
    },
    "511900": {
        "511902": "巴州区",
        "511903": "恩阳区",
        "511921": "通江县",
        "511922": "南江县",
        "511923": "平昌县",
        "511971": "巴中经济开发区"
    },
    "511902": {
        "511902001": "东城街道",
        "511902002": "西城街道",
        "511902003": "回风街道",
        "511902004": "江北街道",
        "511902005": "宕梁街道",
        "511902006": "玉堂街道",
        "511902100": "大茅坪镇",
        "511902101": "清江镇",
        "511902103": "水宁寺镇",
        "511902104": "化成镇",
        "511902105": "曾口镇",
        "511902106": "梁永镇",
        "511902107": "三江镇",
        "511902108": "鼎山镇",
        "511902109": "大罗镇",
        "511902122": "枣林镇",
        "511902123": "平梁镇",
        "511902124": "光辉镇",
        "511902125": "寺岭镇",
        "511902126": "梓桐庙镇",
        "511902127": "凤溪镇",
        "511902202": "花溪乡",
        "511902203": "大和乡",
        "511902204": "白庙乡",
        "511902205": "关渡乡",
        "511902206": "凌云乡",
        "511902209": "金碑乡",
        "511902210": "羊凤乡",
        "511902212": "龙背乡"
    },
    "511903": {
        "511903001": "登科街道",
        "511903002": "文治街道",
        "511903003": "司城街道",
        "511903100": "明阳镇",
        "511903101": "玉山镇",
        "511903102": "渔溪镇",
        "511903103": "三河场镇",
        "511903104": "青木镇",
        "511903105": "花丛镇",
        "511903106": "柳林镇",
        "511903107": "下八庙镇",
        "511903108": "茶坝镇",
        "511903109": "观音井镇",
        "511903110": "三汇镇",
        "511903111": "上八庙镇",
        "511903112": "关公镇",
        "511903113": "兴隆场镇",
        "511903114": "双胜镇",
        "511903115": "群乐镇",
        "511903116": "义兴镇",
        "511903200": "三星乡",
        "511903202": "舞凤乡",
        "511903204": "石城乡",
        "511903205": "九镇乡",
        "511903206": "尹家乡",
        "511903209": "万安乡",
        "511903210": "玉井乡"
    },
    "511921": {
        "511921100": "诺江镇",
        "511921101": "民胜镇",
        "511921102": "火炬镇",
        "511921103": "广纳镇",
        "511921104": "铁佛镇",
        "511921105": "麻石镇",
        "511921106": "至诚镇",
        "511921107": "洪口镇",
        "511921108": "沙溪镇",
        "511921109": "瓦室镇",
        "511921110": "永安镇",
        "511921111": "铁溪镇",
        "511921112": "涪阳镇",
        "511921113": "诺水河镇",
        "511921114": "毛浴镇",
        "511921115": "泥溪镇",
        "511921116": "两河口镇",
        "511921117": "板桥口镇",
        "511921118": "新场镇",
        "511921119": "杨柏镇",
        "511921120": "三溪镇",
        "511921121": "春在镇",
        "511921122": "龙凤场镇",
        "511921123": "空山镇",
        "511921201": "大兴乡",
        "511921202": "东山乡",
        "511921204": "双泉乡",
        "511921205": "文峰乡",
        "511921207": "三合乡",
        "511921208": "云昙乡",
        "511921209": "唱歌乡",
        "511921210": "芝苞乡",
        "511921212": "董溪乡",
        "511921213": "澌波乡",
        "511921214": "松溪乡",
        "511921215": "九层乡",
        "511921216": "胜利乡",
        "511921217": "板凳乡",
        "511921218": "文胜乡",
        "511921219": "兴隆乡",
        "511921222": "烟溪乡",
        "511921223": "沙坪乡",
        "511921224": "朱元乡",
        "511921225": "长坪乡",
        "511921228": "青浴乡",
        "511921230": "铁厂乡",
        "511921232": "陈河乡",
        "511921233": "草池乡",
        "511921234": "回林乡"
    },
    "511922": {
        "511922100": "南江镇",
        "511922101": "沙河镇",
        "511922102": "乐坝镇",
        "511922103": "长赤镇",
        "511922104": "正直镇",
        "511922105": "大河镇",
        "511922106": "光雾山镇",
        "511922107": "东榆镇",
        "511922108": "下两镇",
        "511922109": "赶场镇",
        "511922110": "杨坝镇",
        "511922111": "天池镇",
        "511922112": "关坝镇",
        "511922113": "红光镇",
        "511922114": "元潭镇",
        "511922115": "赤溪镇",
        "511922116": "八庙镇",
        "511922117": "双流镇",
        "511922118": "坪河镇",
        "511922119": "桥亭镇",
        "511922120": "和平镇",
        "511922121": "侯家镇",
        "511922122": "仁和镇",
        "511922123": "高塔镇",
        "511922124": "凤仪镇",
        "511922125": "兴马镇",
        "511922126": "关门镇",
        "511922201": "燕山乡",
        "511922204": "团结乡",
        "511922206": "傅家乡",
        "511922207": "红四乡",
        "511922210": "双桂乡",
        "511922212": "朱公乡",
        "511922213": "黑潭乡",
        "511922217": "高桥乡",
        "511922218": "平岗乡",
        "511922220": "石滩乡",
        "511922223": "北极乡",
        "511922224": "关路乡",
        "511922225": "关田乡",
        "511922226": "红岩乡",
        "511922228": "贵民乡",
        "511922229": "沙坝乡",
        "511922230": "柳湾乡",
        "511922231": "汇滩乡",
        "511922232": "上两乡",
        "511922234": "寨坡乡",
        "511922236": "流坝乡"
    },
    "511923": {
        "511923001": "同州街道",
        "511923100": "江口镇",
        "511923101": "响滩镇",
        "511923102": "西兴镇",
        "511923103": "佛楼镇",
        "511923104": "白衣镇",
        "511923105": "涵水镇",
        "511923106": "岳家镇",
        "511923107": "兰草镇",
        "511923108": "驷马镇",
        "511923109": "坦溪镇",
        "511923110": "元山镇",
        "511923111": "云台镇",
        "511923112": "邱家镇",
        "511923113": "笔山镇",
        "511923114": "镇龙镇",
        "511923115": "得胜镇",
        "511923116": "鹿鸣镇",
        "511923117": "青凤镇",
        "511923118": "灵山镇",
        "511923119": "土兴镇",
        "511923120": "望京镇",
        "511923121": "龙岗镇",
        "511923122": "板庙镇",
        "511923123": "泥龙镇",
        "511923124": "五木镇",
        "511923125": "青云镇",
        "511923126": "大寨镇",
        "511923127": "土垭镇",
        "511923128": "澌岸镇",
        "511923129": "元石镇",
        "511923130": "粉壁镇",
        "511923131": "澌滩镇",
        "511923132": "高峰镇",
        "511923202": "六门乡",
        "511923208": "岩口乡",
        "511923210": "喜神乡",
        "511923212": "石垭乡",
        "511923215": "马鞍乡",
        "511923218": "界牌乡",
        "511923222": "南风乡",
        "511923223": "福申乡",
        "511923224": "双鹿乡",
        "511923225": "黑水乡"
    },
    "511971": {"511971007": "兴文街道", "511971008": "奇章街道", "511971009": "时新街道"},
    "512000": {"512002": "雁江区", "512021": "安岳县", "512022": "乐至县"},
    "512002": {
        "512002001": "莲花街道",
        "512002002": "三贤祠街道",
        "512002003": "资溪街道",
        "512002004": "狮子山街道",
        "512002100": "雁江镇",
        "512002101": "松涛镇",
        "512002102": "宝台镇",
        "512002103": "临江镇",
        "512002104": "保和镇",
        "512002105": "老君镇",
        "512002106": "中和镇",
        "512002107": "丹山镇",
        "512002108": "小院镇",
        "512002109": "堪嘉镇",
        "512002110": "伍隍镇",
        "512002111": "石岭镇",
        "512002112": "东峰镇",
        "512002113": "南津镇",
        "512002114": "忠义镇",
        "512002115": "碑记镇",
        "512002116": "丰裕镇",
        "512002117": "迎接镇",
        "512002118": "祥符镇",
        "512002119": "清水镇",
        "512002200": "新场乡",
        "512002201": "回龙乡",
        "512002400": "四川大堰劳动教养管理所"
    },
    "512021": {
        "512021100": "岳阳镇",
        "512021101": "鸳大镇",
        "512021102": "石桥铺镇",
        "512021103": "通贤镇",
        "512021104": "龙台镇",
        "512021105": "姚市镇",
        "512021106": "林凤镇",
        "512021107": "毛家镇",
        "512021108": "永清镇",
        "512021109": "永顺镇",
        "512021110": "石羊镇",
        "512021111": "两板桥镇",
        "512021112": "护龙镇",
        "512021113": "李家镇",
        "512021114": "元坝镇",
        "512021115": "兴隆镇",
        "512021116": "天林镇",
        "512021117": "镇子镇",
        "512021118": "文化镇",
        "512021119": "周礼镇",
        "512021120": "驯龙镇",
        "512021121": "华严镇",
        "512021122": "卧佛镇",
        "512021123": "长河源镇",
        "512021124": "忠义镇",
        "512021125": "护建镇",
        "512021126": "南薰镇",
        "512021127": "思贤镇",
        "512021128": "人和镇",
        "512021129": "清流镇",
        "512021130": "协和镇",
        "512021131": "朝阳镇",
        "512021200": "城北乡",
        "512021201": "城西乡",
        "512021203": "石鼓乡",
        "512021205": "来凤乡",
        "512021206": "天马乡",
        "512021209": "团结乡",
        "512021210": "悦来乡",
        "512021211": "白水乡",
        "512021212": "云峰乡",
        "512021213": "岳新乡",
        "512021214": "偏岩乡",
        "512021215": "东胜乡",
        "512021216": "坪河乡",
        "512021217": "乾龙乡",
        "512021218": "高升乡",
        "512021219": "横庙乡",
        "512021220": "瑞云乡",
        "512021221": "白塔寺乡",
        "512021222": "双龙街乡",
        "512021223": "顶新乡",
        "512021224": "和平乡",
        "512021225": "高屋乡",
        "512021227": "合义乡",
        "512021228": "努力乡",
        "512021231": "共和乡",
        "512021232": "天宝乡",
        "512021234": "鱼龙乡",
        "512021235": "建华乡",
        "512021236": "大平乡",
        "512021237": "九龙乡",
        "512021238": "岳源乡",
        "512021239": "龙桥乡",
        "512021240": "千佛乡",
        "512021241": "拱桥乡",
        "512021242": "宝华乡",
        "512021244": "自治乡",
        "512021245": "大埝乡"
    },
    "512022": {
        "512022100": "天池镇",
        "512022101": "石佛镇",
        "512022102": "回澜镇",
        "512022103": "石湍镇",
        "512022104": "童家镇",
        "512022105": "宝林镇",
        "512022106": "大佛镇",
        "512022107": "良安镇",
        "512022108": "金顺镇",
        "512022109": "中和场镇",
        "512022110": "劳动镇",
        "512022111": "中天镇",
        "512022112": "佛星镇",
        "512022113": "蟠龙镇",
        "512022114": "东山镇",
        "512022115": "通旅镇",
        "512022116": "高寺镇",
        "512022200": "龙溪乡",
        "512022201": "全胜乡",
        "512022202": "孔雀乡",
        "512022203": "龙门乡",
        "512022204": "双河场乡",
        "512022205": "放生乡",
        "512022206": "盛池乡",
        "512022207": "凉水乡"
    },
    "513200": {
        "513201": "马尔康市",
        "513221": "汶川县",
        "513222": "理县",
        "513223": "茂县",
        "513224": "松潘县",
        "513225": "九寨沟县",
        "513226": "金川县",
        "513227": "小金县",
        "513228": "黑水县",
        "513230": "壤塘县",
        "513231": "阿坝县",
        "513232": "若尔盖县",
        "513233": "红原县"
    },
    "513201": {
        "513201100": "马尔康镇",
        "513201101": "卓克基镇",
        "513201102": "松岗镇",
        "513201103": "沙尔宗镇",
        "513201200": "梭磨乡",
        "513201201": "白湾乡",
        "513201202": "党坝乡",
        "513201203": "木尔宗乡",
        "513201204": "脚木足乡",
        "513201206": "龙尔甲乡",
        "513201207": "大藏乡",
        "513201208": "康山乡",
        "513201209": "草登乡",
        "513201210": "日部乡"
    },
    "513221": {
        "513221100": "威州镇",
        "513221102": "映秀镇",
        "513221103": "卧龙镇",
        "513221105": "水磨镇",
        "513221106": "漩口镇",
        "513221107": "三江镇",
        "513221108": "耿达镇",
        "513221109": "绵虒镇",
        "513221200": "龙溪乡",
        "513221201": "克枯乡",
        "513221202": "雁门乡",
        "513221204": "银杏乡"
    },
    "513222": {
        "513222100": "杂谷脑镇",
        "513222101": "米亚罗镇",
        "513222102": "古尔沟镇",
        "513222103": "薛城镇",
        "513222104": "桃坪镇",
        "513222105": "朴头镇",
        "513222200": "夹壁乡",
        "513222202": "甘堡乡",
        "513222203": "蒲溪乡",
        "513222204": "上孟乡",
        "513222205": "下孟乡",
        "513222206": "木卡乡",
        "513222207": "通化乡"
    },
    "513223": {
        "513223100": "凤仪镇",
        "513223101": "南新镇",
        "513223102": "叠溪镇",
        "513223103": "光明镇",
        "513223104": "雅都镇",
        "513223105": "富顺镇",
        "513223106": "东兴镇",
        "513223107": "土门镇",
        "513223108": "太平镇",
        "513223201": "渭门乡",
        "513223202": "永和乡",
        "513223203": "沟口乡",
        "513223208": "黑虎乡",
        "513223209": "飞虹乡",
        "513223210": "回龙乡",
        "513223211": "三龙乡",
        "513223212": "白溪乡",
        "513223213": "洼底乡",
        "513223214": "石大关乡",
        "513223216": "松坪沟乡",
        "513223217": "曲谷乡"
    },
    "513224": {
        "513224100": "进安镇",
        "513224101": "川主寺镇",
        "513224102": "青云镇",
        "513224103": "毛儿盖镇",
        "513224200": "进安回族乡",
        "513224201": "十里回族乡",
        "513224203": "安宏乡",
        "513224204": "大寨乡",
        "513224205": "牟尼乡",
        "513224206": "镇江关乡",
        "513224207": "镇坪乡",
        "513224208": "岷江乡",
        "513224209": "大姓乡",
        "513224210": "白羊乡",
        "513224211": "红土乡",
        "513224212": "红扎乡",
        "513224213": "小姓乡",
        "513224214": "燕云乡",
        "513224215": "山巴乡",
        "513224216": "水晶乡",
        "513224217": "小河乡",
        "513224218": "施家堡乡",
        "513224219": "黄龙乡",
        "513224221": "下八寨乡",
        "513224222": "草原乡",
        "513224401": "松潘林业局"
    },
    "513225": {
        "513225100": "永乐镇",
        "513225101": "漳扎镇",
        "513225103": "双河镇",
        "513225200": "永丰乡",
        "513225201": "永和乡",
        "513225202": "安乐乡",
        "513225203": "白河乡",
        "513225205": "保华乡",
        "513225206": "罗依乡",
        "513225207": "勿角乡",
        "513225208": "马家乡",
        "513225209": "郭元乡",
        "513225210": "草地乡",
        "513225211": "陵江乡",
        "513225212": "黑河乡",
        "513225213": "玉瓦乡",
        "513225214": "大录乡",
        "513225400": "九寨沟国营牧场",
        "513225401": "九寨沟风景名胜管理局"
    },
    "513226": {
        "513226101": "观音桥镇",
        "513226102": "安宁镇",
        "513226103": "勒乌镇",
        "513226200": "沙耳乡",
        "513226201": "庆宁乡",
        "513226202": "咯尔乡",
        "513226204": "万林乡",
        "513226205": "河东乡",
        "513226206": "河西乡",
        "513226207": "集沐乡",
        "513226208": "撒瓦脚乡",
        "513226209": "卡拉脚乡",
        "513226210": "俄热乡",
        "513226211": "太阳河乡",
        "513226212": "二嘎里乡",
        "513226213": "阿科里乡",
        "513226215": "卡撒乡",
        "513226216": "曾达乡",
        "513226217": "独松乡",
        "513226218": "马尔邦乡",
        "513226219": "马奈乡",
        "513226220": "毛日乡"
    },
    "513227": {
        "513227100": "美兴镇",
        "513227101": "四姑娘山镇",
        "513227102": "两河口镇",
        "513227103": "达维镇",
        "513227104": "沃日镇",
        "513227200": "老营乡",
        "513227201": "崇德乡",
        "513227202": "新桥乡",
        "513227203": "美沃乡",
        "513227204": "沙龙乡",
        "513227205": "宅垄乡",
        "513227206": "新格乡",
        "513227208": "日尔乡",
        "513227209": "结斯乡",
        "513227211": "木坡乡",
        "513227213": "抚边乡",
        "513227214": "八角乡",
        "513227215": "双柏乡",
        "513227216": "窝底乡",
        "513227217": "汗牛乡",
        "513227218": "潘安乡"
    },
    "513228": {
        "513228100": "芦花镇",
        "513228101": "卡龙镇",
        "513228102": "色尔古镇",
        "513228200": "沙石多乡",
        "513228201": "红岩乡",
        "513228202": "麻窝乡",
        "513228203": "双溜索乡",
        "513228204": "瓦钵梁子乡",
        "513228206": "石碉楼乡",
        "513228207": "龙坝乡",
        "513228208": "洛多乡",
        "513228209": "木苏乡",
        "513228210": "维古乡",
        "513228211": "知木林乡",
        "513228212": "扎窝乡",
        "513228213": "晴朗乡",
        "513228214": "慈坝乡"
    },
    "513230": {
        "513230100": "壤柯镇",
        "513230101": "南木达镇",
        "513230102": "中壤塘镇",
        "513230200": "蒲西乡",
        "513230201": "宗科乡",
        "513230202": "石里乡",
        "513230203": "吾伊乡",
        "513230204": "岗木达乡",
        "513230205": "上杜柯乡",
        "513230206": "茸木达乡",
        "513230208": "尕多乡",
        "513230210": "上壤塘乡"
    },
    "513231": {
        "513231100": "阿坝镇",
        "513231101": "贾洛镇",
        "513231102": "麦尔玛镇",
        "513231200": "哇尔玛乡",
        "513231201": "麦昆乡",
        "513231202": "河支乡",
        "513231203": "龙藏乡",
        "513231204": "求吉玛乡",
        "513231205": "甲尔多乡",
        "513231206": "各莫乡",
        "513231207": "德格乡",
        "513231208": "四洼乡",
        "513231209": "安斗乡",
        "513231210": "柯河乡",
        "513231211": "垮沙乡",
        "513231212": "安羌乡",
        "513231213": "查理乡",
        "513231214": "茸安乡",
        "513231215": "洛尔达乡",
        "513231400": "若柯河农场",
        "513231401": "贾柯河牧场"
    },
    "513232": {
        "513232100": "达扎寺镇",
        "513232101": "唐克镇",
        "513232102": "红星镇",
        "513232103": "辖曼镇",
        "513232200": "班佑乡",
        "513232201": "阿西乡",
        "513232205": "麦溪乡",
        "513232206": "嫩哇乡",
        "513232207": "冻列乡",
        "513232208": "崇尔乡",
        "513232209": "热尔乡",
        "513232210": "占哇乡",
        "513232211": "降扎乡",
        "513232212": "巴西乡",
        "513232213": "阿西茸乡",
        "513232214": "求吉乡",
        "513232215": "包座乡",
        "513232400": "白河牧场",
        "513232401": "辖曼种羊场"
    },
    "513233": {
        "513233100": "邛溪镇",
        "513233101": "刷经寺镇",
        "513233102": "瓦切镇",
        "513233103": "安曲镇",
        "513233104": "色地镇",
        "513233201": "龙日乡",
        "513233202": "江茸乡",
        "513233203": "查尔玛乡",
        "513233205": "阿木乡",
        "513233206": "壤口乡",
        "513233207": "麦洼乡"
    },
    "513300": {
        "513301": "康定市",
        "513322": "泸定县",
        "513323": "丹巴县",
        "513324": "九龙县",
        "513325": "雅江县",
        "513326": "道孚县",
        "513327": "炉霍县",
        "513328": "甘孜县",
        "513329": "新龙县",
        "513330": "德格县",
        "513331": "白玉县",
        "513332": "石渠县",
        "513333": "色达县",
        "513334": "理塘县",
        "513335": "巴塘县",
        "513336": "乡城县",
        "513337": "稻城县",
        "513338": "得荣县"
    },
    "513301": {
        "513301001": "炉城街道",
        "513301002": "榆林街道",
        "513301101": "姑咱镇",
        "513301102": "新都桥镇",
        "513301103": "塔公镇",
        "513301104": "沙德镇",
        "513301105": "金汤镇",
        "513301201": "雅拉乡",
        "513301202": "时济乡",
        "513301205": "麦崩乡",
        "513301206": "三合乡",
        "513301208": "捧塔乡",
        "513301210": "贡嘎山乡",
        "513301211": "普沙绒乡",
        "513301212": "吉居乡",
        "513301213": "瓦泽乡",
        "513301214": "呷巴乡",
        "513301215": "甲根坝乡",
        "513301216": "朋布西乡",
        "513301218": "孔玉乡",
        "513301219": "鱼通乡"
    },
    "513322": {
        "513322100": "泸桥镇",
        "513322101": "冷碛镇",
        "513322102": "兴隆镇",
        "513322103": "磨西镇",
        "513322104": "燕子沟镇",
        "513322105": "得妥镇",
        "513322106": "烹坝镇",
        "513322200": "岚安乡",
        "513322202": "田坝乡",
        "513322203": "杵坭乡",
        "513322204": "加郡乡",
        "513322205": "德威乡"
    },
    "513323": {
        "513323100": "章谷镇",
        "513323101": "巴底镇",
        "513323102": "革什扎镇",
        "513323201": "巴旺乡",
        "513323202": "聂呷乡",
        "513323204": "边耳乡",
        "513323205": "丹东乡",
        "513323206": "东谷乡",
        "513323207": "水子乡",
        "513323208": "格宗乡",
        "513323209": "梭坡乡",
        "513323210": "中路乡",
        "513323211": "岳扎乡",
        "513323212": "半扇门乡",
        "513323213": "太平桥乡"
    },
    "513324": {
        "513324100": "呷尔镇",
        "513324101": "烟袋镇",
        "513324200": "汤古乡",
        "513324201": "斜卡乡",
        "513324202": "三岩龙乡",
        "513324203": "上团乡",
        "513324204": "八窝龙乡",
        "513324205": "乃渠乡",
        "513324206": "乌拉溪乡",
        "513324208": "魁多乡",
        "513324209": "子耳彝族乡",
        "513324210": "三垭彝族乡",
        "513324211": "俄尔彝族乡",
        "513324212": "小金彝族乡",
        "513324213": "朵洛彝族乡",
        "513324214": "踏卡彝族乡",
        "513324215": "湾坝彝族乡",
        "513324216": "洪坝乡"
    },
    "513325": {
        "513325100": "河口镇",
        "513325101": "呷拉镇",
        "513325102": "西俄洛镇",
        "513325103": "红龙镇",
        "513325201": "八角楼乡",
        "513325202": "普巴绒乡",
        "513325203": "祝桑乡",
        "513325204": "米龙乡",
        "513325205": "八衣绒乡",
        "513325206": "波斯河乡",
        "513325207": "恶古乡",
        "513325208": "牙衣河乡",
        "513325210": "麻郎错乡",
        "513325211": "德差乡",
        "513325213": "柯拉乡",
        "513325214": "瓦多乡",
        "513325215": "木绒乡"
    },
    "513326": {
        "513326100": "鲜水镇",
        "513326101": "八美镇",
        "513326102": "亚卓镇",
        "513326103": "甲宗镇",
        "513326200": "格西乡",
        "513326201": "麻孜乡",
        "513326202": "孔色乡",
        "513326203": "葛卡乡",
        "513326205": "仲尼乡",
        "513326206": "红顶乡",
        "513326207": "扎拖乡",
        "513326208": "下拖乡",
        "513326209": "瓦日乡",
        "513326210": "木茹乡",
        "513326211": "甲斯孔乡",
        "513326213": "七美乡",
        "513326214": "银恩乡",
        "513326215": "维它乡",
        "513326216": "龙灯乡",
        "513326217": "协德乡",
        "513326218": "色卡乡",
        "513326219": "沙冲乡"
    },
    "513327": {
        "513327100": "新都镇",
        "513327101": "朱倭镇",
        "513327102": "斯木镇",
        "513327200": "泥巴乡",
        "513327201": "雅德乡",
        "513327202": "洛秋乡",
        "513327204": "宜木乡",
        "513327205": "仁达乡",
        "513327207": "旦都乡",
        "513327208": "充古乡",
        "513327209": "更知乡",
        "513327210": "卡娘乡",
        "513327211": "宗塔乡",
        "513327212": "宗麦乡",
        "513327213": "上罗柯马乡",
        "513327214": "下罗柯马乡"
    },
    "513328": {
        "513328100": "甘孜镇",
        "513328101": "查龙镇",
        "513328102": "来马镇",
        "513328200": "呷拉乡",
        "513328201": "色西底乡",
        "513328202": "南多乡",
        "513328203": "生康乡",
        "513328204": "贡隆乡",
        "513328205": "扎科乡",
        "513328207": "昔色乡",
        "513328208": "卡攻乡",
        "513328209": "仁果乡",
        "513328210": "拖坝乡",
        "513328211": "斯俄乡",
        "513328212": "庭卡乡",
        "513328213": "下雄乡",
        "513328214": "四通达乡",
        "513328215": "夺多乡",
        "513328216": "泥柯乡",
        "513328217": "茶扎乡",
        "513328218": "大德乡",
        "513328219": "卡龙乡"
    },
    "513329": {
        "513329100": "如龙镇",
        "513329101": "拉日马镇",
        "513329102": "大盖镇",
        "513329200": "沙堆乡",
        "513329201": "乐安乡",
        "513329203": "绕鲁乡",
        "513329204": "色威乡",
        "513329205": "甲拉西乡",
        "513329207": "博美乡",
        "513329208": "尤拉西乡",
        "513329209": "子拖西乡",
        "513329210": "和平乡",
        "513329211": "洛古乡",
        "513329212": "雄龙西乡",
        "513329213": "麻日乡",
        "513329214": "通宵乡",
        "513329215": "友谊乡",
        "513329216": "皮擦乡",
        "513329217": "银多乡"
    },
    "513330": {
        "513330100": "更庆镇",
        "513330101": "马尼干戈镇",
        "513330102": "竹庆镇",
        "513330103": "阿须镇",
        "513330104": "达马镇",
        "513330105": "错阿镇",
        "513330201": "普马乡",
        "513330202": "岳巴乡",
        "513330203": "八帮乡",
        "513330204": "龚垭乡",
        "513330205": "白垭乡",
        "513330206": "汪布顶乡",
        "513330207": "柯洛洞乡",
        "513330208": "卡松渡乡",
        "513330209": "俄南乡",
        "513330211": "俄支乡",
        "513330213": "玉隆乡",
        "513330215": "中扎科乡",
        "513330216": "然姑乡",
        "513330217": "窝公乡",
        "513330218": "温拖乡",
        "513330219": "年古乡",
        "513330220": "浪多乡",
        "513330222": "打滚乡",
        "513330223": "亚丁乡",
        "513330224": "所巴乡"
    },
    "513331": {
        "513331100": "建设镇",
        "513331101": "阿察镇",
        "513331200": "金沙乡",
        "513331201": "绒盖乡",
        "513331202": "章都乡",
        "513331203": "麻绒乡",
        "513331204": "河坡乡",
        "513331205": "热加乡",
        "513331206": "登龙乡",
        "513331207": "赠科乡",
        "513331209": "麻邛乡",
        "513331210": "辽西乡",
        "513331211": "纳塔乡",
        "513331212": "安孜乡",
        "513331213": "盖玉乡",
        "513331214": "沙马乡",
        "513331215": "山岩乡"
    },
    "513332": {
        "513332100": "尼呷镇",
        "513332101": "洛须镇",
        "513332102": "色须镇",
        "513332103": "虾扎镇",
        "513332104": "温波镇",
        "513332105": "蒙宜镇",
        "513332106": "阿日扎镇",
        "513332200": "真达乡",
        "513332201": "奔达乡",
        "513332202": "正科乡",
        "513332203": "麻呷乡",
        "513332204": "德荣马乡",
        "513332206": "长沙贡马乡",
        "513332207": "呷衣乡",
        "513332208": "格孟乡",
        "513332210": "新荣乡",
        "513332211": "宜牛乡",
        "513332213": "起坞乡",
        "513332215": "长须贡马乡",
        "513332216": "长沙干马乡",
        "513332217": "长须干马乡",
        "513332219": "瓦须乡"
    },
    "513333": {
        "513333100": "色柯镇",
        "513333101": "翁达镇",
        "513333102": "洛若镇",
        "513333103": "泥朵镇",
        "513333201": "克戈乡",
        "513333202": "然充乡",
        "513333203": "康勒乡",
        "513333204": "大章乡",
        "513333205": "大则乡",
        "513333206": "亚龙乡",
        "513333207": "塔子乡",
        "513333208": "年龙乡",
        "513333210": "霍西乡",
        "513333211": "旭日乡",
        "513333212": "杨各乡",
        "513333213": "甲学乡",
        "513333214": "歌乐沱乡"
    },
    "513334": {
        "513334100": "高城镇",
        "513334101": "甲洼镇",
        "513334200": "君坝乡",
        "513334201": "哈依乡",
        "513334202": "觉吾乡",
        "513334203": "莫坝乡",
        "513334204": "亚火乡",
        "513334205": "绒坝乡",
        "513334206": "呷洼乡",
        "513334207": "奔戈乡",
        "513334208": "村戈乡",
        "513334209": "禾尼乡",
        "513334210": "曲登乡",
        "513334211": "喇嘛垭乡",
        "513334212": "章纳乡",
        "513334213": "上木拉乡",
        "513334214": "下木拉乡",
        "513334215": "中木拉乡",
        "513334216": "濯桑乡",
        "513334218": "藏坝乡",
        "513334219": "格木乡",
        "513334220": "拉波乡",
        "513334221": "麦洼乡",
        "513334222": "德巫乡"
    },
    "513335": {
        "513335100": "夏邛镇",
        "513335101": "中咱镇",
        "513335102": "措拉镇",
        "513335200": "拉哇乡",
        "513335201": "党巴乡",
        "513335202": "竹巴龙乡",
        "513335203": "中心绒乡",
        "513335204": "苏哇龙乡",
        "513335205": "昌波乡",
        "513335206": "地巫乡",
        "513335208": "亚日贡乡",
        "513335209": "波密乡",
        "513335210": "莫多乡",
        "513335211": "松多乡",
        "513335212": "波戈溪乡",
        "513335213": "甲英乡",
        "513335215": "茶洛乡",
        "513335216": "列衣乡",
        "513335217": "德达乡"
    },
    "513336": {
        "513336100": "香巴拉镇",
        "513336101": "青德镇",
        "513336102": "尼斯镇",
        "513336201": "沙贡乡",
        "513336202": "水洼乡",
        "513336204": "青麦乡",
        "513336205": "然乌乡",
        "513336206": "洞松乡",
        "513336207": "热打乡",
        "513336208": "定波乡",
        "513336209": "正斗乡",
        "513336210": "白依乡"
    },
    "513337": {
        "513337100": "金珠镇",
        "513337101": "香格里拉镇",
        "513337102": "桑堆镇",
        "513337103": "吉呷镇",
        "513337201": "省母乡",
        "513337202": "傍河乡",
        "513337203": "色拉乡",
        "513337204": "巨龙乡",
        "513337205": "邓波乡",
        "513337206": "木拉乡",
        "513337207": "赤土乡",
        "513337209": "蒙自乡",
        "513337210": "各卡乡",
        "513337212": "俄牙同乡"
    },
    "513338": {
        "513338100": "松麦镇",
        "513338101": "瓦卡镇",
        "513338102": "白松镇",
        "513338200": "斯闸乡",
        "513338201": "徐龙乡",
        "513338202": "日龙乡",
        "513338203": "曲雅贡乡",
        "513338204": "奔都乡",
        "513338205": "八日乡",
        "513338206": "古学乡",
        "513338208": "贡波乡",
        "513338210": "茨巫乡"
    },
    "513400": {
        "513401": "西昌市",
        "513422": "木里藏族自治县",
        "513423": "盐源县",
        "513424": "德昌县",
        "513425": "会理县",
        "513426": "会东县",
        "513427": "宁南县",
        "513428": "普格县",
        "513429": "布拖县",
        "513430": "金阳县",
        "513431": "昭觉县",
        "513432": "喜德县",
        "513433": "冕宁县",
        "513434": "越西县",
        "513435": "甘洛县",
        "513436": "美姑县",
        "513437": "雷波县"
    },
    "513401": {
        "513401001": "北城街道",
        "513401002": "西城街道",
        "513401003": "东城街道",
        "513401004": "长安街道",
        "513401005": "新村街道",
        "513401006": "长宁街道",
        "513401100": "马道镇",
        "513401101": "礼州镇",
        "513401102": "安宁镇",
        "513401103": "川兴镇",
        "513401104": "黄联关镇",
        "513401105": "佑君镇",
        "513401106": "太和镇",
        "513401107": "安哈镇",
        "513401200": "西郊乡",
        "513401201": "高枧乡",
        "513401202": "小庙乡",
        "513401203": "四合乡",
        "513401204": "月华乡",
        "513401205": "兴胜乡",
        "513401206": "琅环乡",
        "513401207": "民胜乡",
        "513401208": "西乡乡",
        "513401209": "樟木箐乡",
        "513401210": "响水乡",
        "513401211": "开元乡",
        "513401212": "大兴乡",
        "513401213": "海南乡",
        "513401214": "大箐乡",
        "513401215": "经久乡",
        "513401216": "西溪乡",
        "513401217": "黄水乡",
        "513401218": "洛古波乡",
        "513401219": "裕隆回族乡",
        "513401220": "高草回族乡",
        "513401221": "中坝乡",
        "513401222": "阿七乡",
        "513401223": "荞地乡",
        "513401224": "磨盘乡",
        "513401225": "巴汝乡",
        "513401226": "银厂乡",
        "513401227": "白马乡",
        "513401228": "马鞍山乡"
    },
    "513422": {
        "513422100": "乔瓦镇",
        "513422101": "瓦厂镇",
        "513422102": "茶布朗镇",
        "513422103": "雅砻江镇",
        "513422201": "博科乡",
        "513422202": "宁朗乡",
        "513422203": "依吉乡",
        "513422204": "俄亚纳西族乡",
        "513422205": "水洛乡",
        "513422206": "牦牛坪乡",
        "513422207": "屋脚蒙古族乡",
        "513422208": "项脚蒙古族乡",
        "513422209": "李子坪乡",
        "513422210": "列瓦乡",
        "513422211": "芽祖乡",
        "513422212": "下麦地乡",
        "513422213": "西秋乡",
        "513422214": "克尔乡",
        "513422215": "白碉苗族乡",
        "513422216": "三桷桠乡",
        "513422217": "倮波乡",
        "513422218": "卡拉乡",
        "513422219": "后所乡",
        "513422220": "沙湾乡",
        "513422222": "固增苗族乡",
        "513422223": "麦日乡",
        "513422224": "东朗乡",
        "513422225": "唐央乡",
        "513422226": "博窝乡"
    },
    "513423": {
        "513423100": "盐井镇",
        "513423101": "卫城镇",
        "513423102": "梅雨镇",
        "513423103": "白乌镇",
        "513423104": "树河镇",
        "513423105": "黄草镇",
        "513423106": "平川镇",
        "513423107": "泸沽湖镇",
        "513423108": "官地镇",
        "513423109": "梅子坪镇",
        "513423200": "双河乡",
        "513423202": "下海乡",
        "513423203": "棉桠乡",
        "513423204": "甘塘乡",
        "513423206": "藤桥乡",
        "513423207": "田湾乡",
        "513423209": "大河乡",
        "513423210": "盐塘乡",
        "513423211": "巫木乡",
        "513423212": "大草乡",
        "513423213": "博大乡",
        "513423214": "金河乡",
        "513423215": "右所乡",
        "513423218": "长柏乡",
        "513423219": "桃子乡",
        "513423220": "盖租乡",
        "513423221": "前所乡",
        "513423222": "沃底乡",
        "513423223": "大坡蒙古族乡",
        "513423224": "洼里乡"
    },
    "513424": {
        "513424100": "德州镇",
        "513424101": "永郎镇",
        "513424102": "乐跃镇",
        "513424103": "麻栗镇",
        "513424104": "阿月镇",
        "513424105": "六所镇",
        "513424106": "茨达镇",
        "513424107": "小高镇",
        "513424108": "王所镇",
        "513424109": "巴洞镇",
        "513424110": "宽裕镇",
        "513424111": "锦川镇",
        "513424112": "老碾镇",
        "513424113": "黑龙潭镇",
        "513424114": "铁炉镇",
        "513424115": "热河镇",
        "513424215": "大陆槽乡",
        "513424217": "南山傈僳族乡",
        "513424218": "金沙傈僳族乡"
    },
    "513425": {
        "513425001": "城北街道",
        "513425002": "城南街道",
        "513425101": "鹿厂镇",
        "513425102": "黎溪镇",
        "513425103": "通安镇",
        "513425104": "太平镇",
        "513425105": "益门镇",
        "513425106": "绿水镇",
        "513425107": "新发镇",
        "513425108": "云甸镇",
        "513425109": "关河镇",
        "513425110": "富乐镇",
        "513425111": "彰冠镇",
        "513425112": "木古镇",
        "513425113": "六华镇",
        "513425114": "小黑箐镇",
        "513425202": "内东乡",
        "513425204": "外北乡",
        "513425206": "爱民乡",
        "513425217": "黎洪乡",
        "513425219": "树堡乡",
        "513425221": "新安傣族乡",
        "513425223": "竹箐乡",
        "513425225": "杨家坝乡",
        "513425234": "槽元乡",
        "513425238": "白果湾乡",
        "513425239": "下村乡"
    },
    "513426": {
        "513426100": "鲹鱼河镇",
        "513426101": "铅锌镇",
        "513426102": "堵格镇",
        "513426103": "姜州镇",
        "513426104": "乌东德镇",
        "513426105": "淌塘镇",
        "513426106": "铁柳镇",
        "513426107": "嘎吉镇",
        "513426108": "满银沟镇",
        "513426109": "新街镇",
        "513426110": "鲁吉镇",
        "513426111": "大崇镇",
        "513426112": "松坪镇",
        "513426200": "小坝乡",
        "513426201": "拉马乡",
        "513426202": "老君滩乡",
        "513426203": "江西街乡",
        "513426235": "溜姑乡",
        "513426239": "野牛坪乡",
        "513426251": "野租乡"
    },
    "513427": {
        "513427100": "披砂镇",
        "513427101": "松新镇",
        "513427102": "竹寿镇",
        "513427103": "华弹镇",
        "513427104": "白鹤滩镇",
        "513427105": "葫芦口镇",
        "513427106": "西瑶镇",
        "513427107": "景星镇",
        "513427108": "大同镇",
        "513427109": "骑骡沟镇",
        "513427110": "跑马镇",
        "513427111": "幸福镇",
        "513427112": "石梨镇",
        "513427113": "六铁镇",
        "513427114": "松林镇",
        "513427115": "新华镇",
        "513427201": "俱乐乡",
        "513427202": "新村乡",
        "513427204": "海子乡",
        "513427206": "新建乡",
        "513427207": "稻谷乡",
        "513427211": "杉树乡",
        "513427212": "梁子乡",
        "513427215": "红星乡",
        "513427217": "倮格乡"
    },
    "513428": {
        "513428100": "普基镇",
        "513428101": "荞窝镇",
        "513428102": "螺髻山镇",
        "513428200": "永安乡",
        "513428201": "向阳乡",
        "513428202": "文坪乡",
        "513428203": "黎安乡",
        "513428204": "花山乡",
        "513428205": "东山乡",
        "513428206": "大坪乡",
        "513428207": "辉隆乡",
        "513428208": "洛乌沟乡",
        "513428209": "雨水乡",
        "513428210": "甘天地乡",
        "513428211": "洛乌乡",
        "513428212": "孟甘乡",
        "513428213": "特兹乡",
        "513428214": "吉乐乡",
        "513428215": "特口乡",
        "513428216": "耶底乡",
        "513428217": "夹铁乡",
        "513428218": "瓦洛乡",
        "513428219": "哈力洛乡",
        "513428220": "菜子乡",
        "513428221": "祝联乡",
        "513428222": "刘家坪乡",
        "513428223": "月吾乡",
        "513428224": "特补乡",
        "513428225": "五道箐乡",
        "513428226": "特尔果乡",
        "513428227": "大槽乡",
        "513428228": "马洪乡",
        "513428229": "洛甘乡",
        "513428230": "红莫依达乡"
    },
    "513429": {
        "513429100": "特木里镇",
        "513429101": "龙潭镇",
        "513429102": "拖觉镇",
        "513429200": "木尔乡",
        "513429201": "九都乡",
        "513429202": "拉达乡",
        "513429203": "乌科乡",
        "513429204": "沙洛乡",
        "513429205": "洛古乡",
        "513429206": "补尔乡",
        "513429207": "觉撒乡",
        "513429208": "美撒乡",
        "513429209": "拉果乡",
        "513429210": "乌依乡",
        "513429211": "浪珠乡",
        "513429212": "包谷坪乡",
        "513429213": "合井乡",
        "513429214": "罗家坪乡",
        "513429215": "牛角湾乡",
        "513429216": "补洛乡",
        "513429217": "火烈乡",
        "513429218": "乐安乡",
        "513429219": "四棵乡",
        "513429220": "地洛乡",
        "513429221": "俄里坪乡",
        "513429222": "瓦都乡",
        "513429223": "采哈乡",
        "513429224": "委只洛乡",
        "513429225": "联补乡",
        "513429226": "基只乡"
    },
    "513430": {
        "513430100": "天地坝镇",
        "513430101": "派来镇",
        "513430102": "芦稿镇",
        "513430103": "对坪镇",
        "513430200": "桃坪乡",
        "513430201": "热水河乡",
        "513430202": "马依足乡",
        "513430203": "红峰乡",
        "513430204": "尔觉西乡",
        "513430205": "热柯觉乡",
        "513430206": "甲依乡",
        "513430207": "木府乡",
        "513430208": "寨子乡",
        "513430209": "则祖乡",
        "513430210": "基觉乡",
        "513430211": "小银木乡",
        "513430212": "春江乡",
        "513430213": "红联乡",
        "513430214": "青松乡",
        "513430215": "放马坪乡",
        "513430216": "梗堡乡",
        "513430217": "山江乡",
        "513430218": "洛觉乡",
        "513430219": "向岭乡",
        "513430220": "谷德乡",
        "513430221": "高峰乡",
        "513430222": "老寨子乡",
        "513430223": "德溪乡",
        "513430224": "南瓦乡",
        "513430225": "依莫合乡",
        "513430226": "土沟乡",
        "513430227": "丙底乡",
        "513430228": "依达乡",
        "513430229": "丝窝乡"
    },
    "513431": {
        "513431100": "新城镇",
        "513431200": "城北乡",
        "513431201": "树坪乡",
        "513431202": "谷曲乡",
        "513431203": "达洛乡",
        "513431204": "龙恩乡",
        "513431205": "美甘乡",
        "513431206": "四开乡",
        "513431207": "大坝乡",
        "513431208": "地莫乡",
        "513431209": "柳且乡",
        "513431210": "博洛乡",
        "513431211": "库莫乡",
        "513431212": "解放乡",
        "513431213": "三岗乡",
        "513431214": "洒拉地坡乡",
        "513431215": "三岔河乡",
        "513431216": "尼地乡",
        "513431217": "碗厂乡",
        "513431218": "普诗乡",
        "513431219": "玛增依乌乡",
        "513431220": "塘且乡",
        "513431221": "久特洛古乡",
        "513431222": "齿可波西乡",
        "513431223": "特口甲谷乡",
        "513431224": "竹核乡",
        "513431225": "阿并洛古乡",
        "513431226": "格吾乡",
        "513431227": "特布洛乡",
        "513431228": "庆恒乡",
        "513431229": "拉一木乡",
        "513431230": "色底乡",
        "513431231": "补约乡",
        "513431232": "比尔乡",
        "513431233": "库依乡",
        "513431234": "金曲乡",
        "513431235": "宜牧地乡",
        "513431236": "波洛乡",
        "513431237": "央摩租乡",
        "513431238": "则普乡",
        "513431239": "永乐乡",
        "513431240": "且莫乡",
        "513431241": "甘多洛古乡",
        "513431242": "支尔莫乡",
        "513431243": "龙沟乡",
        "513431244": "日哈乡",
        "513431245": "哈甘乡"
    },
    "513432": {
        "513432100": "光明镇",
        "513432101": "冕山镇",
        "513432102": "红莫镇",
        "513432103": "两河口镇",
        "513432104": "米市镇",
        "513432105": "洛哈镇",
        "513432106": "尼波镇",
        "513432200": "拉克乡",
        "513432201": "则约乡",
        "513432202": "贺波洛乡",
        "513432203": "鲁基乡",
        "513432204": "李子乡",
        "513432205": "北山乡",
        "513432206": "西河乡",
        "513432207": "东河乡",
        "513432208": "且拖乡",
        "513432209": "博洛拉达乡",
        "513432210": "沙马拉达乡",
        "513432211": "巴久乡",
        "513432212": "洛莫乡",
        "513432213": "依洛乡",
        "513432214": "热柯依达乡",
        "513432215": "额尼乡",
        "513432216": "乐武乡"
    },
    "513433": {
        "513433100": "城厢镇",
        "513433101": "漫水湾镇",
        "513433102": "大桥镇",
        "513433103": "复兴镇",
        "513433104": "泸沽镇",
        "513433105": "沙坝镇",
        "513433106": "彝海镇",
        "513433107": "石龙镇",
        "513433108": "回龙镇",
        "513433109": "河边镇",
        "513433110": "锦屏镇",
        "513433111": "后山镇",
        "513433112": "里庄镇",
        "513433113": "惠安镇",
        "513433114": "宏模镇",
        "513433115": "泽远镇",
        "513433200": "回坪乡",
        "513433202": "哈哈乡",
        "513433203": "森荣乡",
        "513433204": "林里乡",
        "513433206": "铁厂乡",
        "513433208": "河里乡",
        "513433209": "冶勒乡",
        "513433210": "拖乌乡",
        "513433212": "曹古乡",
        "513433216": "先锋乡",
        "513433219": "金林乡",
        "513433220": "腊窝乡",
        "513433221": "联合乡",
        "513433222": "麦地沟乡",
        "513433224": "南河乡",
        "513433225": "青纳乡",
        "513433226": "和爱藏族乡",
        "513433227": "棉沙湾乡",
        "513433228": "马头乡",
        "513433229": "窝堡乡",
        "513433230": "新兴乡",
        "513433231": "健美乡"
    },
    "513434": {
        "513434100": "越城镇",
        "513434101": "中所镇",
        "513434102": "新民镇",
        "513434103": "乃托镇",
        "513434104": "普雄镇",
        "513434105": "大瑞镇",
        "513434106": "竹阿觉镇",
        "513434107": "书古镇",
        "513434108": "依洛地坝镇",
        "513434109": "南箐镇",
        "513434200": "新乡乡",
        "513434201": "马拖乡",
        "513434204": "丁山乡",
        "513434205": "大花乡",
        "513434206": "河东乡",
        "513434207": "西山乡",
        "513434208": "板桥乡",
        "513434209": "瓦岩乡",
        "513434210": "大屯乡",
        "513434211": "保安藏族乡",
        "513434212": "白果乡",
        "513434213": "梅花乡",
        "513434214": "拉普乡",
        "513434215": "铁西乡",
        "513434216": "尔觉乡",
        "513434217": "四甘普乡",
        "513434218": "贡莫乡",
        "513434219": "拉白乡",
        "513434220": "乐青地乡",
        "513434221": "德吉乡",
        "513434223": "尔赛乡",
        "513434226": "保石乡",
        "513434227": "五里箐乡",
        "513434229": "瓦普莫乡",
        "513434230": "申果乡",
        "513434231": "瓦曲觉乡",
        "513434232": "申普乡",
        "513434235": "拉吉乡"
    },
    "513435": {
        "513435100": "新市坝镇",
        "513435101": "田坝镇",
        "513435102": "海棠镇",
        "513435103": "吉米镇",
        "513435104": "斯觉镇",
        "513435105": "普昌镇",
        "513435106": "玉田镇",
        "513435200": "前进乡",
        "513435201": "胜利乡",
        "513435202": "新茶乡",
        "513435203": "两河乡",
        "513435204": "里克乡",
        "513435205": "尼尔觉乡",
        "513435206": "拉莫乡",
        "513435207": "波波乡",
        "513435208": "阿嘎乡",
        "513435209": "阿尔乡",
        "513435210": "石海乡",
        "513435211": "团结乡",
        "513435212": "嘎日乡",
        "513435213": "则拉乡",
        "513435214": "坪坝乡",
        "513435215": "蓼坪乡",
        "513435216": "阿兹觉乡",
        "513435217": "乌史大桥乡",
        "513435218": "黑马乡",
        "513435219": "沙岱乡",
        "513435220": "苏雄乡"
    },
    "513436": {
        "513436100": "巴普镇",
        "513436200": "觉洛乡",
        "513436201": "井叶特西乡",
        "513436202": "合姑洛乡",
        "513436203": "巴古乡",
        "513436204": "农作乡",
        "513436205": "佐戈依达乡",
        "513436206": "子威乡",
        "513436207": "依洛拉达乡",
        "513436208": "典补乡",
        "513436209": "哈洛乡",
        "513436210": "牛牛坝乡",
        "513436211": "尔合乡",
        "513436212": "竹库乡",
        "513436213": "候古莫乡",
        "513436214": "候播乃拖乡",
        "513436215": "采红乡",
        "513436216": "苏洛乡",
        "513436217": "九口乡",
        "513436218": "洛俄依甘乡",
        "513436219": "拉木阿觉乡",
        "513436220": "洛莫依达乡",
        "513436221": "柳洪乡",
        "513436222": "乐约乡",
        "513436223": "尔其乡",
        "513436224": "瓦古乡",
        "513436225": "峨曲古乡",
        "513436226": "炳途乡",
        "513436227": "拖木乡",
        "513436228": "尼哈乡",
        "513436229": "龙门乡",
        "513436230": "依果觉乡",
        "513436231": "洒库乡",
        "513436232": "瓦西乡",
        "513436233": "树窝乡",
        "513436234": "龙窝乡"
    },
    "513437": {
        "513437100": "锦城镇",
        "513437101": "西宁镇",
        "513437102": "汶水镇",
        "513437103": "黄琅镇",
        "513437104": "金沙镇",
        "513437201": "海湾乡",
        "513437202": "杉树堡乡",
        "513437203": "箐口乡",
        "513437204": "帕哈乡",
        "513437205": "永盛乡",
        "513437206": "溪洛米乡",
        "513437208": "顺河乡",
        "513437209": "渡口乡",
        "513437210": "回龙场乡",
        "513437211": "马湖乡",
        "513437212": "中田乡",
        "513437213": "谷米乡",
        "513437214": "柑子乡",
        "513437215": "双河口乡",
        "513437216": "罗山溪乡",
        "513437217": "桂花乡",
        "513437218": "烂坝子乡",
        "513437219": "沙沱乡",
        "513437220": "山棱岗乡",
        "513437221": "长河乡",
        "513437222": "谷堆乡",
        "513437223": "八寨乡",
        "513437224": "拉咪乡",
        "513437225": "松树乡",
        "513437226": "曲依乡",
        "513437227": "千万贯乡",
        "513437228": "五官乡",
        "513437229": "上田坝乡",
        "513437230": "大坪子乡",
        "513437231": "簸箕梁子乡",
        "513437232": "小沟乡",
        "513437233": "莫红乡",
        "513437234": "克觉乡",
        "513437235": "坪头乡",
        "513437236": "雷池乡",
        "513437237": "巴姑乡",
        "513437238": "咪姑乡",
        "513437239": "一车乡",
        "513437240": "斯古溪乡",
        "513437241": "卡哈洛乡",
        "513437242": "元宝山乡",
        "513437243": "大岩洞乡",
        "513437244": "岩脚乡"
    },
    "520000": {
        "520100": "贵阳市",
        "520200": "六盘水市",
        "520300": "遵义市",
        "520400": "安顺市",
        "520500": "毕节市",
        "520600": "铜仁市",
        "522300": "黔西南布依族苗族自治州",
        "522600": "黔东南苗族侗族自治州",
        "522700": "黔南布依族苗族自治州"
    },
    "520100": {
        "520102": "南明区",
        "520103": "云岩区",
        "520111": "花溪区",
        "520112": "乌当区",
        "520113": "白云区",
        "520115": "观山湖区",
        "520121": "开阳县",
        "520122": "息烽县",
        "520123": "修文县",
        "520181": "清镇市"
    },
    "520102": {
        "520102200": "后巢乡",
        "520102201": "云关乡",
        "520102202": "小碧布依族苗族乡",
        "520102203": "永乐乡",
        "520102400": "新华社区服务中心",
        "520102401": "西湖社区服务中心",
        "520102402": "水口寺社区服务中心",
        "520102403": "大南社区服务中心",
        "520102404": "中南社区服务中心",
        "520102405": "市府社区服务中心",
        "520102406": "河滨社区服务中心",
        "520102407": "遵义社区服务中心",
        "520102408": "兴关社区服务中心",
        "520102409": "沙冲社区服务中心",
        "520102410": "沙南社区服务中心",
        "520102411": "太慈社区服务中心",
        "520102412": "湘雅社区服务中心",
        "520102413": "油榨社区服务中心",
        "520102414": "中曹司社区服务中心",
        "520102415": "二戈寨社区服务中心",
        "520102416": "见龙社区服务中心",
        "520102417": "龙洞社区服务中心",
        "520102418": "花果园社区服务中心"
    },
    "520103": {
        "520103100": "黔灵镇",
        "520103400": "中华社区服务中心",
        "520103401": "中环社区服务中心",
        "520103402": "中东社区服务中心",
        "520103403": "东山社区服务中心",
        "520103404": "延中社区服务中心",
        "520103405": "市西社区服务中心",
        "520103406": "威清社区服务中心",
        "520103407": "普陀社区服务中心",
        "520103408": "北京路社区服务中心",
        "520103409": "栖霞社区服务中心",
        "520103410": "黔东社区服务中心",
        "520103411": "宅吉社区服务中心",
        "520103412": "省府社区服务中心",
        "520103413": "金狮社区服务中心",
        "520103414": "中天社区服务中心",
        "520103415": "贵乌社区服务中心",
        "520103416": "头桥社区服务中心",
        "520103417": "金龙社区服务中心",
        "520103418": "圣泉社区服务中心",
        "520103419": "三桥社区服务中心",
        "520103420": "金关社区服务中心",
        "520103421": "蔡关社区服务中心",
        "520103422": "荷塘社区服务中心",
        "520103423": "普天社区服务中心",
        "520103424": "金鸭社区服务中心",
        "520103425": "金惠社区服务中心",
        "520103426": "水东社区服务中心"
    },
    "520111": {
        "520111101": "青岩镇",
        "520111102": "石板镇",
        "520111103": "党武镇",
        "520111104": "麦坪镇",
        "520111105": "燕楼镇",
        "520111201": "孟关苗族布依族乡",
        "520111204": "湖潮苗族布依族乡",
        "520111205": "久安乡",
        "520111207": "高坡苗族乡",
        "520111208": "黔陶布依族苗族乡",
        "520111209": "马铃布依族苗族乡",
        "520111400": "羊艾农场",
        "520111401": "阳光社区服务中心",
        "520111402": "贵筑社区服务中心",
        "520111403": "溪北社区服务中心",
        "520111404": "清溪社区服务中心",
        "520111405": "明珠社区服务中心",
        "520111406": "兴隆社区服务中心",
        "520111407": "瑞华社区服务中心",
        "520111408": "清浦社区服务中心",
        "520111409": "黔江社区服务中心",
        "520111410": "平桥社区服务中心",
        "520111411": "航天社区服务中心",
        "520111412": "航空社区服务中心",
        "520111413": "金欣社区服务中心",
        "520111414": "黄河社区服务中心",
        "520111415": "三江社区服务中心",
        "520111416": "小孟社区服务中心",
        "520111417": "金竹社区服务中心",
        "520111418": "花孟社区服务中心"
    },
    "520112": {
        "520112101": "东风镇",
        "520112102": "水田镇",
        "520112103": "羊昌镇",
        "520112105": "下坝镇",
        "520112106": "新场镇",
        "520112107": "百宜镇",
        "520112203": "新堡布依族乡",
        "520112206": "偏坡布依族乡",
        "520112401": "顺新社区服务中心",
        "520112402": "新天社区服务中心",
        "520112403": "创新社区服务中心",
        "520112404": "振新社区服务中心",
        "520112405": "高新社区服务中心"
    },
    "520113": {
        "520113100": "艳山红镇",
        "520113101": "麦架镇",
        "520113102": "沙文镇",
        "520113200": "都拉布依族乡",
        "520113201": "牛场布依族乡",
        "520113400": "红云社区服务中心",
        "520113401": "大山洞社区服务中心",
        "520113402": "艳山红社区服务中心",
        "520113403": "铝兴社区服务中心",
        "520113404": "白沙关社区服务中心",
        "520113405": "都新社区服务中心",
        "520113406": "贵阳市白云区泉湖社区服务中心"
    },
    "520115": {
        "520115100": "金华镇",
        "520115101": "朱昌镇",
        "520115102": "百花湖镇",
        "520115400": "世纪城社区服务中心",
        "520115401": "金源社区服务中心",
        "520115402": "逸景社区服务中心",
        "520115403": "碧海社区服务中心",
        "520115404": "金麦社区服务中心",
        "520115405": "新世界社区服务中心",
        "520115406": "金华园社区服务中心",
        "520115407": "金岭社区服务中心",
        "520115408": "会展社区服务中心",
        "520115409": "观山社区服务中心"
    },
    "520121": {
        "520121100": "城关镇",
        "520121101": "双流镇",
        "520121102": "金中镇",
        "520121103": "冯三镇",
        "520121104": "楠木渡镇",
        "520121105": "龙岗镇",
        "520121106": "永温镇",
        "520121107": "花梨镇",
        "520121200": "南龙乡",
        "520121202": "宅吉乡",
        "520121204": "龙水乡",
        "520121205": "米坪乡",
        "520121206": "禾丰布依族苗族乡",
        "520121207": "南江布依族苗族乡",
        "520121208": "高寨苗族布依族乡",
        "520121209": "毛云乡",
        "520121401": "紫兴社区服务中心",
        "520121402": "南山社区服务中心"
    },
    "520122": {
        "520122100": "永靖镇",
        "520122101": "温泉镇",
        "520122102": "九庄镇",
        "520122103": "小寨坝镇",
        "520122104": "西山镇",
        "520122105": "养龙司镇",
        "520122106": "石硐镇",
        "520122107": "鹿窝镇",
        "520122108": "流长镇",
        "520122200": "青山苗族乡",
        "520122400": "新华社区服务中心"
    },
    "520123": {
        "520123100": "龙场镇",
        "520123101": "扎佐镇",
        "520123102": "久长镇",
        "520123103": "六广镇",
        "520123104": "六屯镇",
        "520123105": "洒坪镇",
        "520123106": "六桶镇",
        "520123107": "谷堡镇",
        "520123108": "小箐镇",
        "520123204": "大石布依族乡",
        "520123400": "龙岗社区服务中心",
        "520123401": "珍珠河社区服务中心"
    },
    "520181": {
        "520181100": "红枫湖镇",
        "520181101": "站街镇",
        "520181102": "卫城镇",
        "520181103": "新店镇",
        "520181104": "暗流镇",
        "520181105": "犁倭镇",
        "520181201": "麦格苗族布依族乡",
        "520181203": "王庄布依族苗族乡",
        "520181204": "流长苗族乡",
        "520181400": "新岭社区服务中心",
        "520181401": "红塔社区服务中心",
        "520181402": "巢凤社区服务中心",
        "520181403": "百花社区服务中心",
        "520181405": "红新社区服务中心"
    },
    "520200": {"520201": "钟山区", "520203": "六枝特区", "520221": "水城县", "520281": "盘州市"},
    "520201": {
        "520201101": "大河镇",
        "520201102": "汪家寨镇",
        "520201103": "大湾镇",
        "520201400": "建设路社区服务中心",
        "520201401": "黄土坡社区服务中心",
        "520201402": "红岩社区服务中心",
        "520201403": "荷泉社区服务中心",
        "520201404": "荷城社区服务中心",
        "520201405": "杉树林社区服务中心",
        "520201406": "杨柳社区服务中心",
        "520201407": "场坝社区服务中心",
        "520201408": "凤凰社区服务中心",
        "520201409": "广场社区服务中心",
        "520201410": "明湖社区服务中心",
        "520201411": "德坞社区服务中心",
        "520201412": "西宁社区服务中心",
        "520201413": "月照社区服务中心",
        "520201415": "双戛社区服务中心"
    },
    "520203": {
        "520203001": "九龙街道",
        "520203002": "银壶街道",
        "520203003": "塔山街道",
        "520203102": "岩脚镇",
        "520203103": "木岗镇",
        "520203104": "大用镇",
        "520203105": "关寨镇",
        "520203106": "牂牁镇",
        "520203107": "新华镇",
        "520203108": "龙河镇",
        "520203109": "新窑镇",
        "520203110": "郎岱镇",
        "520203203": "梭戛苗族彝族回族乡",
        "520203204": "牛场苗族彝族乡",
        "520203205": "新场乡",
        "520203208": "中寨苗族彝族布依族乡",
        "520203213": "落别布依族彝族乡",
        "520203214": "月亮河彝族布依族苗族乡"
    },
    "520221": {
        "520221001": "双水街道",
        "520221002": "尖山街道",
        "520221003": "老鹰山街道",
        "520221004": "董地街道",
        "520221102": "比德镇",
        "520221103": "化乐镇",
        "520221104": "蟠龙镇",
        "520221105": "阿戛镇",
        "520221106": "勺米镇",
        "520221107": "玉舍镇",
        "520221108": "都格镇",
        "520221109": "发耳镇",
        "520221110": "鸡场镇",
        "520221111": "木果镇",
        "520221112": "保华镇",
        "520221113": "陡箐镇",
        "520221114": "米箩镇",
        "520221204": "南开苗族彝族乡",
        "520221205": "青林苗族彝族乡",
        "520221207": "金盆苗族彝族乡",
        "520221214": "坪寨彝族乡",
        "520221218": "龙场苗族白族彝族乡",
        "520221219": "营盘苗族彝族白族乡",
        "520221220": "顺场苗族彝族布依族乡",
        "520221221": "花戛苗族布依族彝族乡",
        "520221222": "杨梅彝族苗族回族乡",
        "520221223": "新街彝族苗族布依族乡",
        "520221224": "野钟苗族彝族布依族乡",
        "520221225": "果布戛彝族苗族布依族乡",
        "520221230": "猴场苗族布依族乡"
    },
    "520281": {
        "520281001": "亦资街道",
        "520281002": "翰林街道",
        "520281003": "两河街道",
        "520281004": "红果街道",
        "520281005": "胜境街道",
        "520281006": "刘官街道",
        "520281104": "民主镇",
        "520281105": "大山镇",
        "520281106": "保田镇",
        "520281109": "石桥镇",
        "520281111": "响水镇",
        "520281117": "柏果镇",
        "520281121": "新民镇",
        "520281123": "盘关镇",
        "520281124": "竹海镇",
        "520281125": "英武镇",
        "520281126": "鸡场坪镇",
        "520281127": "双凤镇",
        "520281128": "丹霞镇",
        "520281129": "乌蒙镇",
        "520281202": "普田回族乡",
        "520281208": "坪地彝族乡",
        "520281210": "淤泥彝族乡",
        "520281211": "普古彝族苗族乡",
        "520281212": "旧营白族彝族苗族乡",
        "520281213": "羊场布依族白族苗族乡",
        "520281214": "保基苗族彝族乡"
    },
    "520300": {
        "520302": "红花岗区",
        "520303": "汇川区",
        "520304": "播州区",
        "520322": "桐梓县",
        "520323": "绥阳县",
        "520324": "正安县",
        "520325": "道真仡佬族苗族自治县",
        "520326": "务川仡佬族苗族自治县",
        "520327": "凤冈县",
        "520328": "湄潭县",
        "520329": "余庆县",
        "520330": "习水县",
        "520381": "赤水市",
        "520382": "仁怀市"
    },
    "520302": {
        "520302001": "老城街道",
        "520302002": "万里路街道",
        "520302003": "中华路街道",
        "520302004": "迎红街道",
        "520302005": "延安路街道",
        "520302006": "舟水桥街道",
        "520302007": "中山路街道",
        "520302008": "北京路街道",
        "520302009": "长征街道",
        "520302010": "礼仪街道",
        "520302011": "南关街道",
        "520302012": "忠庄街道",
        "520302013": "新蒲街道",
        "520302014": "新中街道",
        "520302101": "巷口镇",
        "520302104": "海龙镇",
        "520302105": "深溪镇",
        "520302106": "金鼎山镇",
        "520302108": "新舟镇",
        "520302109": "虾子镇",
        "520302110": "三渡镇",
        "520302111": "永乐镇",
        "520302112": "喇叭镇"
    },
    "520303": {
        "520303001": "上海路街道",
        "520303002": "洗马路街道",
        "520303003": "大连路街道",
        "520303004": "高桥街道",
        "520303005": "董公寺街道",
        "520303006": "高坪街道",
        "520303102": "团泽镇",
        "520303104": "板桥镇",
        "520303105": "泗渡镇",
        "520303106": "沙湾镇",
        "520303107": "山盆镇",
        "520303108": "芝麻镇",
        "520303109": "松林镇",
        "520303110": "毛石镇"
    },
    "520304": {
        "520304001": "南白街道",
        "520304002": "播南街道",
        "520304003": "影山湖街道",
        "520304004": "桂花桥街道",
        "520304005": "龙坑街道",
        "520304102": "三岔镇",
        "520304103": "苟江镇",
        "520304104": "三合镇",
        "520304105": "乌江镇",
        "520304106": "龙坪镇",
        "520304107": "团溪镇",
        "520304108": "铁厂镇",
        "520304109": "西坪镇",
        "520304110": "尚嵇镇",
        "520304111": "茅栗镇",
        "520304112": "新民镇",
        "520304113": "鸭溪镇",
        "520304114": "石板镇",
        "520304115": "乐山镇",
        "520304116": "枫香镇",
        "520304117": "泮水镇",
        "520304118": "马蹄镇",
        "520304200": "平正仡佬族乡",
        "520304201": "洪关苗族乡"
    },
    "520322": {
        "520322001": "海校街道",
        "520322002": "娄山关街道",
        "520322101": "楚米镇",
        "520322102": "新站镇",
        "520322103": "松坎镇",
        "520322104": "高桥镇",
        "520322105": "水坝塘镇",
        "520322106": "官仓镇",
        "520322107": "花秋镇",
        "520322108": "羊磴镇",
        "520322109": "九坝镇",
        "520322110": "大河镇",
        "520322111": "夜郎镇",
        "520322112": "木瓜镇",
        "520322113": "坡渡镇",
        "520322114": "燎原镇",
        "520322115": "狮溪镇",
        "520322116": "茅石镇",
        "520322117": "尧龙山镇",
        "520322118": "风水镇",
        "520322119": "容光镇",
        "520322120": "芭蕉镇",
        "520322203": "小水乡",
        "520322205": "黄莲乡",
        "520322207": "马鬃苗族乡"
    },
    "520323": {
        "520323100": "洋川镇",
        "520323101": "郑场镇",
        "520323102": "旺草镇",
        "520323103": "蒲场镇",
        "520323104": "风华镇",
        "520323105": "茅垭镇",
        "520323106": "枧坝镇",
        "520323107": "宽阔镇",
        "520323108": "黄杨镇",
        "520323109": "青杠塘镇",
        "520323110": "太白镇",
        "520323111": "温泉镇",
        "520323112": "坪乐镇",
        "520323200": "大路槽乡",
        "520323201": "小关乡"
    },
    "520324": {
        "520324001": "凤仪街道",
        "520324101": "瑞溪镇",
        "520324102": "和溪镇",
        "520324103": "安场镇",
        "520324104": "土坪镇",
        "520324105": "流渡镇",
        "520324106": "格林镇",
        "520324107": "新州镇",
        "520324108": "庙塘镇",
        "520324109": "小雅镇",
        "520324110": "中观镇",
        "520324111": "芙蓉江镇",
        "520324112": "班竹镇",
        "520324113": "碧峰镇",
        "520324114": "乐俭镇",
        "520324115": "杨兴镇",
        "520324116": "桴焉镇",
        "520324202": "谢坝仡佬族苗族乡",
        "520324203": "市坪苗族仡佬族乡"
    },
    "520325": {
        "520325100": "玉溪镇",
        "520325101": "三江镇",
        "520325102": "隆兴镇",
        "520325103": "旧城镇",
        "520325104": "忠信镇",
        "520325105": "洛龙镇",
        "520325106": "阳溪镇",
        "520325107": "三桥镇",
        "520325108": "大磏镇",
        "520325109": "平模镇",
        "520325110": "河口镇",
        "520325200": "上坝土家族乡",
        "520325201": "棕坪乡",
        "520325202": "桃源乡"
    },
    "520326": {
        "520326001": "丹砂街道",
        "520326002": "都濡街道",
        "520326003": "大坪街道",
        "520326101": "丰乐镇",
        "520326102": "黄都镇",
        "520326103": "涪洋镇",
        "520326104": "镇南镇",
        "520326105": "砚山镇",
        "520326106": "浞水镇",
        "520326107": "茅天镇",
        "520326108": "柏村镇",
        "520326110": "泥高镇",
        "520326111": "分水镇",
        "520326112": "蕉坝镇",
        "520326203": "红丝乡",
        "520326204": "石朝乡"
    },
    "520327": {
        "520327100": "龙泉镇",
        "520327101": "进化镇",
        "520327102": "琊川镇",
        "520327103": "蜂岩镇",
        "520327104": "永和镇",
        "520327105": "花坪镇",
        "520327106": "绥阳镇",
        "520327107": "土溪镇",
        "520327108": "永安镇",
        "520327109": "何坝镇",
        "520327110": "天桥镇",
        "520327111": "王寨镇",
        "520327112": "新建镇",
        "520327203": "石径乡"
    },
    "520328": {
        "520328004": "湄江街道",
        "520328005": "黄家坝街道",
        "520328006": "鱼泉街道",
        "520328101": "永兴镇",
        "520328102": "复兴镇",
        "520328103": "马山镇",
        "520328106": "高台镇",
        "520328107": "茅坪镇",
        "520328108": "兴隆镇",
        "520328109": "新南镇",
        "520328110": "石莲镇",
        "520328111": "西河镇",
        "520328112": "洗马镇",
        "520328113": "抄乐镇",
        "520328114": "天城镇"
    },
    "520329": {
        "520329001": "子营街道",
        "520329102": "龙溪镇",
        "520329103": "构皮滩镇",
        "520329104": "大乌江镇",
        "520329105": "敖溪镇",
        "520329106": "龙家镇",
        "520329107": "松烟镇",
        "520329108": "关兴镇",
        "520329109": "白泥镇",
        "520329200": "花山苗族乡"
    },
    "520330": {
        "520330001": "东皇街道",
        "520330002": "九龙街道",
        "520330003": "杉王街道",
        "520330004": "马临街道",
        "520330101": "土城镇",
        "520330102": "同民镇",
        "520330103": "醒民镇",
        "520330104": "隆兴镇",
        "520330105": "习酒镇",
        "520330106": "回龙镇",
        "520330107": "桑木镇",
        "520330108": "永安镇",
        "520330109": "良村镇",
        "520330110": "温水镇",
        "520330111": "仙源镇",
        "520330112": "官店镇",
        "520330113": "寨坝镇",
        "520330114": "民化镇",
        "520330115": "二郎镇",
        "520330116": "二里镇",
        "520330117": "三岔河镇",
        "520330118": "大坡镇",
        "520330119": "桃林镇",
        "520330120": "程寨镇",
        "520330205": "双龙乡",
        "520330207": "坭坝乡"
    },
    "520381": {
        "520381001": "市中街道",
        "520381002": "文华街道",
        "520381003": "金华街道",
        "520381100": "天台镇",
        "520381101": "复兴镇",
        "520381102": "大同镇",
        "520381103": "旺隆镇",
        "520381104": "葫市镇",
        "520381105": "元厚镇",
        "520381106": "官渡镇",
        "520381107": "长期镇",
        "520381108": "长沙镇",
        "520381109": "两河口镇",
        "520381110": "丙安镇",
        "520381202": "宝源乡",
        "520381203": "石堡乡",
        "520381204": "白云乡"
    },
    "520382": {
        "520382001": "盐津街道",
        "520382002": "中枢街道",
        "520382003": "苍龙街道",
        "520382004": "坛厂街道",
        "520382005": "鲁班街道",
        "520382102": "长岗镇",
        "520382104": "五马镇",
        "520382105": "茅坝镇",
        "520382106": "九仓镇",
        "520382107": "喜头镇",
        "520382108": "大坝镇",
        "520382109": "三合镇",
        "520382110": "合马镇",
        "520382112": "火石镇",
        "520382113": "学孔镇",
        "520382114": "龙井镇",
        "520382115": "美酒河镇",
        "520382116": "高大坪镇",
        "520382117": "茅台镇",
        "520382201": "后山苗族布依族乡"
    },
    "520400": {
        "520402": "西秀区",
        "520403": "平坝区",
        "520422": "普定县",
        "520423": "镇宁布依族苗族自治县",
        "520424": "关岭布依族苗族自治县",
        "520425": "紫云苗族布依族自治县"
    },
    "520402": {
        "520402001": "南街街道",
        "520402002": "东街街道",
        "520402003": "西街街道",
        "520402004": "北街街道",
        "520402005": "东关街道",
        "520402006": "华西街道",
        "520402007": "西航街道",
        "520402100": "宋旗镇",
        "520402101": "幺铺镇",
        "520402102": "宁谷镇",
        "520402103": "龙宫镇",
        "520402104": "双堡镇",
        "520402105": "大西桥镇",
        "520402106": "七眼桥镇",
        "520402107": "蔡官镇",
        "520402108": "轿子山镇",
        "520402109": "旧州镇",
        "520402200": "新场布依族苗族乡",
        "520402201": "岩腊苗族布依族乡",
        "520402202": "鸡场布依族苗族乡",
        "520402203": "杨武布依族苗族乡",
        "520402204": "东屯乡",
        "520402205": "黄腊布依族苗族乡",
        "520402206": "刘官乡"
    },
    "520403": {
        "520403001": "安平街道",
        "520403002": "鼓楼街道",
        "520403101": "白云镇",
        "520403102": "高峰镇",
        "520403103": "天龙镇",
        "520403104": "夏云镇",
        "520403105": "马场镇",
        "520403106": "乐平镇",
        "520403107": "齐伯镇",
        "520403202": "十字回族苗族乡",
        "520403203": "羊昌布依族苗族乡"
    },
    "520422": {
        "520422001": "定南街道",
        "520422002": "穿洞街道",
        "520422003": "黄桶街道",
        "520422101": "马官镇",
        "520422102": "化处镇",
        "520422103": "马场镇",
        "520422104": "白岩镇",
        "520422105": "坪上镇",
        "520422106": "鸡场坡镇",
        "520422203": "补郎苗族乡",
        "520422204": "猴场苗族仡佬族乡",
        "520422205": "猫洞苗族仡佬族乡"
    },
    "520423": {
        "520423001": "白马湖街道",
        "520423002": "环翠街道",
        "520423003": "丁旗街道",
        "520423004": "双龙山街道",
        "520423102": "黄果树镇",
        "520423105": "马厂镇",
        "520423107": "良田镇",
        "520423108": "扁担山镇",
        "520423109": "募役镇",
        "520423110": "江龙镇",
        "520423111": "本寨镇",
        "520423112": "六马镇",
        "520423204": "沙子乡",
        "520423206": "革利乡",
        "520423210": "简嘎乡"
    },
    "520424": {
        "520424002": "顶云街道",
        "520424003": "关索街道",
        "520424004": "龙潭街道",
        "520424102": "永宁镇",
        "520424103": "岗乌镇",
        "520424104": "上关镇",
        "520424105": "坡贡镇",
        "520424107": "白水镇",
        "520424108": "新铺镇",
        "520424109": "沙营镇",
        "520424110": "花江镇",
        "520424111": "断桥镇",
        "520424202": "普利乡"
    },
    "520425": {
        "520425001": "松山街道",
        "520425002": "五峰街道",
        "520425101": "格凸河镇",
        "520425102": "猴场镇",
        "520425103": "猫营镇",
        "520425104": "板当镇",
        "520425105": "宗地镇",
        "520425106": "大营镇",
        "520425107": "坝羊镇",
        "520425108": "火花镇",
        "520425200": "白石岩乡",
        "520425203": "四大寨乡"
    },
    "520500": {
        "520502": "七星关区",
        "520521": "大方县",
        "520522": "黔西县",
        "520523": "金沙县",
        "520524": "织金县",
        "520525": "纳雍县",
        "520526": "威宁彝族回族苗族自治县",
        "520527": "赫章县"
    },
    "520502": {
        "520502001": "市西街道",
        "520502002": "市东街道",
        "520502003": "三板桥街道",
        "520502005": "大新桥街道",
        "520502006": "观音桥街道",
        "520502007": "洪山街道",
        "520502008": "麻园街道",
        "520502009": "碧阳街道",
        "520502010": "德溪街道",
        "520502011": "碧海街道",
        "520502012": "青龙街道",
        "520502100": "鸭池镇",
        "520502101": "梨树镇",
        "520502102": "岔河镇",
        "520502103": "朱昌镇",
        "520502104": "田坝镇",
        "520502105": "长春堡镇",
        "520502106": "撒拉溪镇",
        "520502107": "杨家湾镇",
        "520502108": "放珠镇",
        "520502109": "青场镇",
        "520502110": "水箐镇",
        "520502111": "何官屯镇",
        "520502112": "对坡镇",
        "520502113": "大银镇",
        "520502114": "林口镇",
        "520502115": "生机镇",
        "520502116": "清水铺镇",
        "520502117": "亮岩镇",
        "520502118": "燕子口镇",
        "520502119": "八寨镇",
        "520502120": "田坝桥镇",
        "520502121": "海子街镇",
        "520502122": "小坝镇",
        "520502123": "层台镇",
        "520502124": "小吉场镇",
        "520502125": "普宜镇",
        "520502126": "龙场营镇",
        "520502200": "千溪彝族苗族白族乡",
        "520502201": "阴底彝族苗族白族乡",
        "520502202": "野角乡",
        "520502203": "大河乡",
        "520502204": "团结彝族苗族乡",
        "520502205": "阿市苗族彝族乡",
        "520502206": "大屯彝族乡",
        "520502207": "田坎彝族乡"
    },
    "520521": {
        "520521001": "红旗街道",
        "520521002": "顺德街道",
        "520521003": "慕俄格古城街道",
        "520521101": "双山镇",
        "520521102": "猫场镇",
        "520521103": "马场镇",
        "520521104": "羊场镇",
        "520521105": "黄泥塘镇",
        "520521106": "六龙镇",
        "520521107": "达溪镇",
        "520521108": "瓢井镇",
        "520521109": "长石镇",
        "520521110": "对江镇",
        "520521200": "东关乡",
        "520521201": "竹园彝族苗族乡",
        "520521202": "响水白族彝族仡佬族乡",
        "520521203": "文阁乡",
        "520521204": "绿塘乡",
        "520521205": "鼎新彝族苗族乡",
        "520521206": "牛场苗族彝族乡",
        "520521208": "小屯乡",
        "520521209": "理化苗族彝族乡",
        "520521211": "凤山彝族蒙古族乡",
        "520521212": "安乐彝族仡佬族乡",
        "520521213": "核桃彝族白族乡",
        "520521214": "八堡彝族苗族乡",
        "520521215": "兴隆苗族乡",
        "520521216": "果瓦乡",
        "520521217": "大山苗族彝族乡",
        "520521218": "雨冲乡",
        "520521219": "黄泥彝族苗族满族乡",
        "520521220": "大水彝族苗族布依族乡",
        "520521221": "沙厂彝族乡",
        "520521222": "普底彝族苗族白族乡",
        "520521223": "百纳彝族乡",
        "520521224": "三元彝族苗族白族乡",
        "520521225": "星宿苗族彝族仡佬族乡"
    },
    "520522": {
        "520522001": "莲城街道",
        "520522002": "水西街道",
        "520522003": "文峰街道",
        "520522004": "杜鹃街道",
        "520522101": "金碧镇",
        "520522102": "雨朵镇",
        "520522103": "大关镇",
        "520522104": "谷里镇",
        "520522105": "素朴镇",
        "520522106": "中坪镇",
        "520522107": "重新镇",
        "520522108": "林泉镇",
        "520522109": "金兰镇",
        "520522110": "甘棠镇",
        "520522111": "洪水镇",
        "520522112": "锦星镇",
        "520522113": "钟山镇",
        "520522114": "协和镇",
        "520522115": "观音洞镇",
        "520522200": "五里布依族苗族乡",
        "520522203": "绿化白族彝族乡",
        "520522204": "新仁苗族乡",
        "520522206": "铁石苗族彝族乡",
        "520522208": "太来彝族苗族乡",
        "520522210": "永燊彝族苗族乡",
        "520522211": "中建苗族彝族乡",
        "520522212": "花溪彝族苗族乡",
        "520522213": "定新彝族苗族乡",
        "520522215": "金坡苗族彝族满族乡",
        "520522216": "仁和彝族苗族乡",
        "520522217": "红林彝族苗族乡"
    },
    "520523": {
        "520523001": "西洛街道",
        "520523002": "岩孔街道",
        "520523003": "五龙街道",
        "520523004": "鼓场街道",
        "520523101": "安底镇",
        "520523102": "沙土镇",
        "520523104": "禹谟镇",
        "520523105": "岚头镇",
        "520523106": "清池镇",
        "520523107": "柳塘镇",
        "520523108": "平坝镇",
        "520523109": "源村镇",
        "520523110": "高坪镇",
        "520523111": "化觉镇",
        "520523112": "茶园镇",
        "520523113": "木孔镇",
        "520523114": "长坝镇",
        "520523115": "后山镇",
        "520523202": "石场苗族彝族乡",
        "520523203": "桂花乡",
        "520523204": "太平彝族苗族乡",
        "520523215": "安洛苗族彝族满族乡",
        "520523216": "新化苗族彝族满族乡",
        "520523217": "大田彝族苗族布依族乡",
        "520523218": "马路彝族苗族乡"
    },
    "520524": {
        "520524001": "双堰街道",
        "520524002": "文腾街道",
        "520524003": "金凤街道",
        "520524004": "三甲街道",
        "520524005": "绮陌街道",
        "520524006": "八步街道",
        "520524101": "桂果镇",
        "520524102": "牛场镇",
        "520524103": "猫场镇",
        "520524104": "化起镇",
        "520524105": "龙场镇",
        "520524107": "以那镇",
        "520524108": "三塘镇",
        "520524109": "阿弓镇",
        "520524110": "珠藏镇",
        "520524111": "中寨镇",
        "520524112": "马场镇",
        "520524113": "板桥镇",
        "520524114": "白泥镇",
        "520524115": "少普镇",
        "520524116": "熊家场镇",
        "520524117": "黑土镇",
        "520524201": "自强苗族乡",
        "520524202": "大平苗族彝族乡",
        "520524203": "官寨苗族乡",
        "520524204": "茶店布依族苗族彝族乡",
        "520524205": "金龙苗族彝族布依族乡",
        "520524206": "后寨苗族乡",
        "520524207": "鸡场苗族彝族乡",
        "520524211": "实兴乡",
        "520524213": "上坪寨乡",
        "520524215": "纳雍乡"
    },
    "520525": {
        "520525001": "雍熙街道",
        "520525002": "文昌街道",
        "520525003": "居仁街道",
        "520525101": "中岭镇",
        "520525102": "阳长镇",
        "520525103": "维新镇",
        "520525104": "龙场镇",
        "520525105": "乐治镇",
        "520525107": "百兴镇",
        "520525108": "张家湾镇",
        "520525114": "勺窝镇",
        "520525115": "寨乐镇",
        "520525116": "玉龙坝镇",
        "520525117": "沙包镇",
        "520525118": "水东镇",
        "520525119": "曙光镇",
        "520525201": "新房彝族苗族乡",
        "520525202": "厍东关彝族白族苗族乡",
        "520525203": "董地苗族彝族乡",
        "520525205": "化作苗族彝族乡",
        "520525210": "姑开苗族彝族乡",
        "520525211": "羊场苗族彝族乡",
        "520525212": "锅圈岩苗族彝族乡",
        "520525213": "昆寨苗族彝族白族乡",
        "520525214": "左鸠戛彝族苗族乡",
        "520525215": "猪场苗族彝族乡"
    },
    "520526": {
        "520526001": "海边街道",
        "520526002": "五里岗街道",
        "520526003": "六桥街道",
        "520526004": "陕桥街道",
        "520526100": "草海镇",
        "520526101": "么站镇",
        "520526102": "金钟镇",
        "520526103": "炉山镇",
        "520526104": "龙场镇",
        "520526105": "黑石头镇",
        "520526106": "哲觉镇",
        "520526107": "观风海镇",
        "520526108": "牛棚镇",
        "520526109": "迤那镇",
        "520526110": "中水镇",
        "520526111": "龙街镇",
        "520526112": "雪山镇",
        "520526113": "羊街镇",
        "520526114": "小海镇",
        "520526115": "盐仓镇",
        "520526116": "东风镇",
        "520526117": "二塘镇",
        "520526118": "猴场镇",
        "520526119": "秀水镇",
        "520526120": "双龙镇",
        "520526121": "麻乍镇",
        "520526122": "兔街镇",
        "520526123": "海拉镇",
        "520526124": "玉龙镇",
        "520526125": "哈喇河镇",
        "520526126": "斗古镇",
        "520526127": "金斗镇",
        "520526128": "岔河镇",
        "520526129": "黑土河镇",
        "520526201": "新发布依族乡",
        "520526210": "石门乡",
        "520526211": "云贵乡",
        "520526214": "板底乡",
        "520526215": "大街乡"
    },
    "520527": {
        "520527001": "双河街道",
        "520527002": "白果街道",
        "520527102": "妈姑镇",
        "520527103": "财神镇",
        "520527104": "六曲河镇",
        "520527105": "野马川镇",
        "520527106": "罗州镇",
        "520527107": "平山镇",
        "520527108": "哲庄镇",
        "520527109": "古基镇",
        "520527110": "朱明镇",
        "520527111": "德卓镇",
        "520527200": "达依乡",
        "520527201": "水塘堡彝族苗族乡",
        "520527202": "兴发苗族彝族回族乡",
        "520527203": "松林坡白族彝族苗族乡",
        "520527204": "雉街彝族苗族乡",
        "520527205": "珠市彝族乡",
        "520527207": "双坪彝族苗族乡",
        "520527208": "铁匠苗族乡",
        "520527209": "辅处彝族苗族乡",
        "520527210": "可乐彝族苗族乡",
        "520527211": "河镇彝族苗族乡",
        "520527213": "安乐溪乡",
        "520527215": "结构彝族苗族乡",
        "520527219": "古达苗族彝族乡",
        "520527220": "威奢乡"
    },
    "520600": {
        "520602": "碧江区",
        "520603": "万山区",
        "520621": "江口县",
        "520622": "玉屏侗族自治县",
        "520623": "石阡县",
        "520624": "思南县",
        "520625": "印江土家族苗族自治县",
        "520626": "德江县",
        "520627": "沿河土家族自治县",
        "520628": "松桃苗族自治县"
    },
    "520602": {
        "520602001": "市中街道",
        "520602002": "环北街道",
        "520602003": "河西街道",
        "520602005": "灯塔街道",
        "520602006": "川硐街道",
        "520602101": "坝黄镇",
        "520602102": "云场坪镇",
        "520602103": "漾头镇",
        "520602200": "桐木坪侗族乡",
        "520602201": "滑石侗族苗族土家族乡",
        "520602202": "和平土家族侗族乡",
        "520602203": "瓦屋侗族乡",
        "520602204": "六龙山侗族土家族乡"
    },
    "520603": {
        "520603001": "谢桥街道",
        "520603002": "茶店街道",
        "520603003": "仁山街道",
        "520603100": "万山镇",
        "520603200": "高楼坪侗族乡",
        "520603201": "黄道侗族乡",
        "520603202": "敖寨侗族乡",
        "520603203": "下溪侗族乡",
        "520603204": "鱼塘侗族苗族乡",
        "520603205": "大坪侗族土家族苗族乡"
    },
    "520621": {
        "520621001": "双江街道",
        "520621002": "凯德街道",
        "520621101": "闵孝镇",
        "520621102": "太平镇",
        "520621103": "坝盘镇",
        "520621104": "民和镇",
        "520621105": "桃映镇",
        "520621106": "怒溪镇",
        "520621202": "德旺土家族苗族乡",
        "520621204": "官和侗族土家族苗族乡"
    },
    "520622": {
        "520622001": "皂角坪街道",
        "520622002": "平溪街道",
        "520622100": "新店镇",
        "520622101": "大龙镇",
        "520622102": "朱家场镇",
        "520622103": "田坪镇",
        "520622200": "亚鱼乡"
    },
    "520623": {
        "520623001": "汤山街道",
        "520623002": "泉都街道",
        "520623003": "中坝街道",
        "520623101": "本庄镇",
        "520623102": "白沙镇",
        "520623103": "龙塘镇",
        "520623104": "花桥镇",
        "520623105": "五德镇",
        "520623107": "河坝镇",
        "520623201": "国荣乡",
        "520623202": "聚凤仡佬族侗族乡",
        "520623203": "龙井仡佬族侗族乡",
        "520623204": "大沙坝仡佬族侗族乡",
        "520623205": "枫香仡佬族侗族乡",
        "520623206": "青阳苗族仡佬族侗族乡",
        "520623207": "石固仡佬族侗族乡",
        "520623208": "坪地场仡佬族侗族乡",
        "520623209": "甘溪仡佬族侗族乡",
        "520623210": "坪山仡佬族侗族乡"
    },
    "520624": {
        "520624001": "思唐街道",
        "520624002": "关中坝街道",
        "520624003": "双塘街道",
        "520624101": "塘头镇",
        "520624102": "许家坝镇",
        "520624103": "大坝场镇",
        "520624104": "文家店镇",
        "520624105": "鹦鹉溪镇",
        "520624106": "合朋溪镇",
        "520624107": "张家寨镇",
        "520624108": "孙家坝镇",
        "520624109": "青杠坡镇",
        "520624110": "瓮溪镇",
        "520624111": "凉水井镇",
        "520624112": "邵家桥镇",
        "520624113": "大河坝镇",
        "520624114": "亭子坝镇",
        "520624115": "香坝镇",
        "520624116": "长坝镇",
        "520624117": "板桥镇",
        "520624201": "思林土家族苗族乡",
        "520624203": "胡家湾苗族土家族乡",
        "520624204": "宽坪苗族土家族乡",
        "520624206": "枫芸土家族苗族乡",
        "520624210": "三道水土家族苗族乡",
        "520624211": "天桥土家族苗族乡",
        "520624212": "兴隆土家族苗族乡",
        "520624213": "杨家坳苗族土家族乡"
    },
    "520625": {
        "520625001": "峨岭街道",
        "520625002": "龙津街道",
        "520625003": "中兴街道",
        "520625101": "板溪镇",
        "520625102": "沙子坡镇",
        "520625103": "天堂镇",
        "520625104": "木黄镇",
        "520625105": "合水镇",
        "520625106": "朗溪镇",
        "520625107": "缠溪镇",
        "520625108": "洋溪镇",
        "520625109": "新寨镇",
        "520625110": "杉树镇",
        "520625111": "刀坝镇",
        "520625112": "紫薇镇",
        "520625113": "杨柳镇",
        "520625206": "罗场乡"
    },
    "520626": {
        "520626001": "青龙街道",
        "520626002": "玉水街道",
        "520626101": "煎茶镇",
        "520626102": "潮砥镇",
        "520626103": "枫香溪镇",
        "520626104": "稳坪镇",
        "520626105": "复兴镇",
        "520626106": "合兴镇",
        "520626107": "高山镇",
        "520626108": "泉口镇",
        "520626109": "长堡镇",
        "520626110": "共和镇",
        "520626111": "平原镇",
        "520626200": "荆角土家族乡",
        "520626201": "堰塘土家族乡",
        "520626202": "龙泉土家族乡",
        "520626203": "钱家土家族乡",
        "520626204": "沙溪土家族乡",
        "520626205": "楠杆土家族乡",
        "520626206": "长丰土家族乡",
        "520626207": "桶井土家族乡"
    },
    "520627": {
        "520627001": "团结街道",
        "520627002": "和平街道",
        "520627003": "沙子街道",
        "520627101": "黑水镇",
        "520627102": "谯家镇",
        "520627103": "夹石镇",
        "520627104": "淇滩镇",
        "520627105": "官舟镇",
        "520627106": "土地坳镇",
        "520627107": "思渠镇",
        "520627108": "客田镇",
        "520627109": "洪渡镇",
        "520627110": "中界镇",
        "520627111": "甘溪镇",
        "520627112": "板场镇",
        "520627113": "泉坝镇",
        "520627114": "中寨镇",
        "520627115": "黄土镇",
        "520627116": "新景镇",
        "520627117": "塘坝镇",
        "520627203": "晓景乡",
        "520627211": "后坪乡"
    },
    "520628": {
        "520628001": "大兴街道",
        "520628002": "蓼皋街道",
        "520628003": "世昌街道",
        "520628004": "太平营街道",
        "520628005": "九江街道",
        "520628101": "盘石镇",
        "520628102": "盘信镇",
        "520628103": "大坪场镇",
        "520628104": "普觉镇",
        "520628105": "寨英镇",
        "520628106": "孟溪镇",
        "520628107": "乌罗镇",
        "520628108": "甘龙镇",
        "520628109": "长兴堡镇",
        "520628110": "迓驾镇",
        "520628112": "牛郎镇",
        "520628113": "黄板镇",
        "520628114": "平头镇",
        "520628115": "大路镇",
        "520628116": "木树镇",
        "520628117": "冷水溪镇",
        "520628118": "正大镇",
        "520628203": "长坪乡",
        "520628207": "妙隘乡",
        "520628209": "石梁乡",
        "520628210": "瓦溪乡",
        "520628211": "永安乡",
        "520628214": "沙坝河乡"
    },
    "522300": {
        "522301": "兴义市",
        "522302": "兴仁市",
        "522323": "普安县",
        "522324": "晴隆县",
        "522325": "贞丰县",
        "522326": "望谟县",
        "522327": "册亨县",
        "522328": "安龙县"
    },
    "522301": {
        "522301001": "黄草街道",
        "522301002": "兴泰街道",
        "522301003": "桔山街道",
        "522301004": "丰都街道",
        "522301005": "坪东街道",
        "522301006": "木贾街道",
        "522301007": "下五屯街道",
        "522301008": "万峰林街道",
        "522301100": "敬南镇",
        "522301101": "泥凼镇",
        "522301102": "南盘江镇",
        "522301103": "捧乍镇",
        "522301104": "鲁布格镇",
        "522301105": "三江口镇",
        "522301106": "乌沙镇",
        "522301107": "白碗窑镇",
        "522301108": "马岭镇",
        "522301109": "威舍镇",
        "522301110": "清水河镇",
        "522301111": "顶效镇",
        "522301112": "郑屯镇",
        "522301113": "万屯镇",
        "522301114": "鲁屯镇",
        "522301115": "仓更镇",
        "522301116": "七舍镇",
        "522301200": "则戎乡",
        "522301201": "沧江乡",
        "522301202": "洛万乡",
        "522301203": "猪场坪乡",
        "522301204": "雄武乡"
    },
    "522302": {
        "522302001": "东湖街道",
        "522302002": "城南街道",
        "522302003": "真武山街道",
        "522302004": "城北街道",
        "522302101": "屯脚镇",
        "522302102": "巴铃镇",
        "522302103": "百德镇",
        "522302104": "雨樟镇",
        "522302105": "潘家庄镇",
        "522302106": "回龙镇",
        "522302107": "下山镇",
        "522302108": "新龙场镇",
        "522302109": "大山镇",
        "522302110": "马马崖镇",
        "522302111": "波阳镇",
        "522302200": "鲁础营回族乡"
    },
    "522323": {
        "522323001": "盘水街道",
        "522323002": "南湖街道",
        "522323101": "龙吟镇",
        "522323103": "江西坡镇",
        "522323105": "地瓜镇",
        "522323107": "楼下镇",
        "522323108": "兴中镇",
        "522323109": "青山镇",
        "522323110": "罗汉镇",
        "522323111": "新店镇",
        "522323200": "白沙乡",
        "522323201": "高棉乡"
    },
    "522324": {
        "522324001": "东观街道",
        "522324002": "莲城街道",
        "522324101": "沙子镇",
        "522324102": "碧痕镇",
        "522324103": "大厂镇",
        "522324104": "鸡场镇",
        "522324105": "花贡镇",
        "522324106": "中营镇",
        "522324107": "光照镇",
        "522324108": "茶马镇",
        "522324200": "长流乡",
        "522324204": "紫马乡",
        "522324205": "安谷乡",
        "522324206": "三宝彝族乡"
    },
    "522325": {
        "522325001": "永丰街道",
        "522325002": "珉谷街道",
        "522325101": "龙场镇",
        "522325102": "者相镇",
        "522325103": "北盘江镇",
        "522325104": "白层镇",
        "522325105": "鲁贡镇",
        "522325106": "小屯镇",
        "522325107": "长田镇",
        "522325108": "沙坪镇",
        "522325109": "挽澜镇",
        "522325200": "连环乡",
        "522325204": "平街乡",
        "522325205": "鲁容乡"
    },
    "522326": {
        "522326001": "王母街道",
        "522326002": "平洞街道",
        "522326003": "新屯街道",
        "522326101": "乐元镇",
        "522326102": "打易镇",
        "522326103": "乐旺镇",
        "522326104": "桑郎镇",
        "522326105": "麻山镇",
        "522326107": "石屯镇",
        "522326108": "蔗香镇",
        "522326109": "郊纳镇",
        "522326110": "大观镇",
        "522326111": "边饶镇",
        "522326112": "昂武镇",
        "522326206": "油迈瑶族乡"
    },
    "522327": {
        "522327001": "者楼街道",
        "522327002": "纳福街道",
        "522327103": "丫他镇",
        "522327104": "巧马镇",
        "522327105": "秧坝镇",
        "522327107": "岩架镇",
        "522327108": "八渡镇",
        "522327109": "冗渡镇",
        "522327110": "坡妹镇",
        "522327111": "双江镇",
        "522327112": "弼佑镇",
        "522327204": "百口乡"
    },
    "522328": {
        "522328001": "招堤街道",
        "522328002": "栖凤街道",
        "522328003": "钱相街道",
        "522328101": "龙广镇",
        "522328102": "德卧镇",
        "522328103": "万峰湖镇",
        "522328104": "木咱镇",
        "522328105": "洒雨镇",
        "522328106": "普坪镇",
        "522328107": "龙山镇",
        "522328110": "新桥镇",
        "522328111": "海子镇",
        "522328112": "笃山镇"
    },
    "522600": {
        "522601": "凯里市",
        "522622": "黄平县",
        "522623": "施秉县",
        "522624": "三穗县",
        "522625": "镇远县",
        "522626": "岑巩县",
        "522627": "天柱县",
        "522628": "锦屏县",
        "522629": "剑河县",
        "522630": "台江县",
        "522631": "黎平县",
        "522632": "榕江县",
        "522633": "从江县",
        "522634": "雷山县",
        "522635": "麻江县",
        "522636": "丹寨县"
    },
    "522601": {
        "522601001": "城西街道",
        "522601002": "大十字街道",
        "522601003": "西门街道",
        "522601004": "洗马河街道",
        "522601005": "湾溪街道",
        "522601006": "鸭塘街道",
        "522601007": "开怀街道",
        "522601100": "三棵树镇",
        "522601101": "舟溪镇",
        "522601103": "旁海镇",
        "522601104": "湾水镇",
        "522601105": "炉山镇",
        "522601106": "万潮镇",
        "522601107": "龙场镇",
        "522601108": "碧波镇",
        "522601109": "下司镇",
        "522601110": "凯棠镇",
        "522601111": "大风洞镇"
    },
    "522622": {
        "522622100": "新州镇",
        "522622101": "旧州镇",
        "522622102": "重安镇",
        "522622103": "谷陇镇",
        "522622104": "平溪镇",
        "522622105": "野洞河镇",
        "522622106": "浪洞镇",
        "522622107": "上塘镇",
        "522622203": "一碗水乡",
        "522622204": "纸房乡",
        "522622206": "翁坪乡"
    },
    "522623": {
        "522623100": "城关镇",
        "522623101": "杨柳塘镇",
        "522623102": "双井镇",
        "522623103": "牛大场镇",
        "522623104": "马号镇",
        "522623200": "白垛乡",
        "522623201": "甘溪乡",
        "522623203": "马溪乡"
    },
    "522624": {
        "522624100": "八弓镇",
        "522624101": "台烈镇",
        "522624102": "瓦寨镇",
        "522624103": "桐林镇",
        "522624104": "雪洞镇",
        "522624105": "长吉镇",
        "522624106": "良上镇",
        "522624200": "滚马乡",
        "522624202": "款场乡"
    },
    "522625": {
        "522625100": "舞阳镇",
        "522625101": "蕉溪镇",
        "522625102": "青溪镇",
        "522625103": "羊坪镇",
        "522625104": "羊场镇",
        "522625105": "都坪镇",
        "522625106": "金堡镇",
        "522625107": "江古镇",
        "522625201": "涌溪乡",
        "522625203": "报京乡",
        "522625204": "大地乡",
        "522625205": "尚寨土家族乡"
    },
    "522626": {
        "522626100": "思旸镇",
        "522626101": "水尾镇",
        "522626102": "天马镇",
        "522626103": "龙田镇",
        "522626104": "大有镇",
        "522626105": "注溪镇",
        "522626106": "凯本镇",
        "522626107": "平庄镇",
        "522626108": "客楼镇",
        "522626202": "天星乡",
        "522626203": "羊桥土家族乡"
    },
    "522627": {
        "522627001": "凤城街道",
        "522627002": "邦洞街道",
        "522627003": "社学街道",
        "522627102": "坪地镇",
        "522627103": "蓝田镇",
        "522627104": "瓮洞镇",
        "522627105": "高酿镇",
        "522627106": "石洞镇",
        "522627107": "远口镇",
        "522627108": "坌处镇",
        "522627109": "白市镇",
        "522627110": "渡马镇",
        "522627111": "江东镇",
        "522627112": "竹林镇",
        "522627202": "注溪乡",
        "522627203": "地湖乡"
    },
    "522628": {
        "522628100": "三江镇",
        "522628101": "茅坪镇",
        "522628102": "敦寨镇",
        "522628103": "启蒙镇",
        "522628104": "平秋镇",
        "522628105": "铜鼓镇",
        "522628106": "平略镇",
        "522628200": "大同乡",
        "522628201": "新化乡",
        "522628202": "隆里乡",
        "522628203": "钟灵乡",
        "522628204": "偶里乡",
        "522628205": "固本乡",
        "522628206": "河口乡",
        "522628207": "彦洞乡"
    },
    "522629": {
        "522629001": "仰阿莎街道",
        "522629100": "柳川镇",
        "522629101": "岑松镇",
        "522629102": "南加镇",
        "522629103": "南明镇",
        "522629104": "革东镇",
        "522629105": "太拥镇",
        "522629106": "磻溪镇",
        "522629107": "久仰镇",
        "522629108": "南哨镇",
        "522629109": "南寨镇",
        "522629110": "观么镇",
        "522629205": "敏洞乡"
    },
    "522630": {
        "522630001": "台拱街道",
        "522630002": "萃文街道",
        "522630101": "施洞镇",
        "522630102": "南宫镇",
        "522630103": "革一镇",
        "522630104": "方召镇",
        "522630201": "排羊乡",
        "522630202": "台盘乡",
        "522630204": "老屯乡"
    },
    "522631": {
        "522631001": "德凤街道",
        "522631002": "高屯街道",
        "522631102": "中潮镇",
        "522631103": "孟彦镇",
        "522631104": "敖市镇",
        "522631105": "九潮镇",
        "522631106": "岩洞镇",
        "522631107": "水口镇",
        "522631108": "洪州镇",
        "522631109": "尚重镇",
        "522631110": "双江镇",
        "522631111": "肇兴镇",
        "522631112": "龙额镇",
        "522631113": "永从镇",
        "522631114": "茅贡镇",
        "522631115": "地坪镇",
        "522631200": "顺化瑶族乡",
        "522631201": "雷洞瑶族水族乡",
        "522631203": "罗里乡",
        "522631205": "坝寨乡",
        "522631206": "口江乡",
        "522631211": "德顺乡",
        "522631212": "大稼乡",
        "522631213": "平寨乡",
        "522631214": "德化乡"
    },
    "522632": {
        "522632100": "古州镇",
        "522632101": "忠诚镇",
        "522632102": "寨蒿镇",
        "522632103": "平永镇",
        "522632104": "乐里镇",
        "522632105": "朗洞镇",
        "522632106": "栽麻镇",
        "522632107": "平江镇",
        "522632108": "八开镇",
        "522632202": "崇义乡",
        "522632204": "三江水族乡",
        "522632205": "仁里水族乡",
        "522632206": "塔石瑶族水族乡",
        "522632208": "定威水族乡",
        "522632209": "兴华水族乡",
        "522632210": "计划乡",
        "522632211": "水尾水族乡",
        "522632212": "平阳乡",
        "522632213": "两汪乡"
    },
    "522633": {
        "522633100": "丙妹镇",
        "522633101": "贯洞镇",
        "522633102": "洛香镇",
        "522633103": "下江镇",
        "522633104": "宰便镇",
        "522633105": "西山镇",
        "522633106": "停洞镇",
        "522633107": "往洞镇",
        "522633108": "庆云镇",
        "522633109": "斗里镇",
        "522633110": "东朗镇",
        "522633111": "加鸠镇",
        "522633200": "高增乡",
        "522633201": "谷坪乡",
        "522633204": "刚边壮族乡",
        "522633205": "加榜乡",
        "522633206": "秀塘壮族乡",
        "522633208": "翠里瑶族壮族乡",
        "522633212": "加勉乡"
    },
    "522634": {
        "522634100": "丹江镇",
        "522634101": "西江镇",
        "522634102": "永乐镇",
        "522634103": "郎德镇",
        "522634104": "大塘镇",
        "522634200": "望丰乡",
        "522634203": "达地水族乡",
        "522634204": "方祥乡"
    },
    "522635": {
        "522635001": "杏山街道",
        "522635002": "金竹街道",
        "522635101": "谷硐镇",
        "522635103": "宣威镇",
        "522635105": "龙山镇",
        "522635106": "贤昌镇",
        "522635203": "坝芒布依族乡"
    },
    "522636": {
        "522636100": "龙泉镇",
        "522636101": "兴仁镇",
        "522636102": "排调镇",
        "522636103": "扬武镇",
        "522636202": "雅灰乡",
        "522636203": "南皋乡"
    },
    "522700": {
        "522701": "都匀市",
        "522702": "福泉市",
        "522722": "荔波县",
        "522723": "贵定县",
        "522725": "瓮安县",
        "522726": "独山县",
        "522727": "平塘县",
        "522728": "罗甸县",
        "522729": "长顺县",
        "522730": "龙里县",
        "522731": "惠水县",
        "522732": "三都水族自治县"
    },
    "522701": {
        "522701001": "广惠街道",
        "522701002": "文峰街道",
        "522701004": "小围寨街道",
        "522701005": "沙包堡街道",
        "522701006": "绿茵湖街道",
        "522701106": "墨冲镇",
        "522701107": "平浪镇",
        "522701110": "毛尖镇",
        "522701111": "匀东镇",
        "522701208": "归兰水族乡"
    },
    "522702": {
        "522702001": "金山",
        "522702002": "马场坪",
        "522702102": "凤山镇",
        "522702103": "陆坪镇",
        "522702105": "龙昌镇",
        "522702106": "牛场镇",
        "522702107": "道坪镇",
        "522702203": "仙桥乡"
    },
    "522722": {
        "522722001": "玉屏街道",
        "522722101": "朝阳镇",
        "522722102": "茂兰镇",
        "522722104": "甲良镇",
        "522722105": "佳荣镇",
        "522722107": "小七孔镇",
        "522722211": "瑶山瑶族乡",
        "522722212": "黎明关水族乡"
    },
    "522723": {
        "522723001": "金南街道",
        "522723002": "宝山街道",
        "522723102": "新巴镇",
        "522723108": "德新镇",
        "522723109": "盘江镇",
        "522723110": "沿山镇",
        "522723111": "昌明镇",
        "522723112": "云雾镇"
    },
    "522725": {
        "522725001": "雍阳街道",
        "522725002": "瓮水街道",
        "522725101": "平定营镇",
        "522725103": "中坪镇",
        "522725104": "建中镇",
        "522725105": "永和镇",
        "522725106": "珠藏镇",
        "522725107": "玉山镇",
        "522725108": "天文镇",
        "522725109": "银盏镇",
        "522725110": "猴场镇",
        "522725111": "江界河镇",
        "522725205": "岚关乡"
    },
    "522726": {
        "522726108": "百泉镇",
        "522726109": "影山镇",
        "522726110": "基长镇",
        "522726111": "下司镇",
        "522726112": "麻尾镇",
        "522726113": "麻万镇",
        "522726114": "上司镇",
        "522726115": "玉水镇"
    },
    "522727": {
        "522727001": "金盆街道",
        "522727100": "平舟镇",
        "522727101": "牙舟镇",
        "522727102": "通州镇",
        "522727103": "大塘镇",
        "522727104": "克度镇",
        "522727105": "塘边镇",
        "522727106": "甲茶镇",
        "522727107": "者密镇",
        "522727108": "掌布镇",
        "522727200": "卡蒲毛南族乡"
    },
    "522728": {
        "522728001": "斛兴街道",
        "522728107": "龙坪镇",
        "522728108": "边阳镇",
        "522728109": "沫阳镇",
        "522728110": "逢亭镇",
        "522728111": "罗悃镇",
        "522728112": "茂井镇",
        "522728113": "红水河镇",
        "522728114": "木引镇",
        "522728219": "凤亭乡"
    },
    "522729": {
        "522729001": "长寨街道",
        "522729101": "广顺镇",
        "522729103": "摆所镇",
        "522729104": "代化镇",
        "522729105": "白云山镇",
        "522729106": "鼓扬镇",
        "522729209": "敦操乡"
    },
    "522730": {
        "522730001": "冠山街道",
        "522730100": "龙山镇",
        "522730102": "醒狮镇",
        "522730103": "谷脚镇",
        "522730104": "湾滩河镇",
        "522730105": "洗马镇"
    },
    "522731": {
        "522731001": "涟江街道",
        "522731002": "濛江街道",
        "522731102": "好花红镇",
        "522731103": "摆金镇",
        "522731104": "雅水镇",
        "522731105": "断杉镇",
        "522731106": "芦山镇",
        "522731107": "王佑镇",
        "522731108": "羡塘镇",
        "522731109": "岗度镇"
    },
    "522732": {
        "522732001": "三合街道",
        "522732111": "大河镇",
        "522732112": "普安镇",
        "522732113": "都江镇",
        "522732114": "中和镇",
        "522732115": "周覃镇",
        "522732116": "九阡镇"
    },
    "530000": {
        "530100": "昆明市",
        "530300": "曲靖市",
        "530400": "玉溪市",
        "530500": "保山市",
        "530600": "昭通市",
        "530700": "丽江市",
        "530800": "普洱市",
        "530900": "临沧市",
        "532300": "楚雄彝族自治州",
        "532500": "红河哈尼族彝族自治州",
        "532600": "文山壮族苗族自治州",
        "532800": "西双版纳傣族自治州",
        "532900": "大理白族自治州",
        "533100": "德宏傣族景颇族自治州",
        "533300": "怒江傈僳族自治州",
        "533400": "迪庆藏族自治州"
    },
    "530100": {
        "530102": "五华区",
        "530103": "盘龙区",
        "530111": "官渡区",
        "530112": "西山区",
        "530113": "东川区",
        "530114": "呈贡区",
        "530115": "晋宁区",
        "530124": "富民县",
        "530125": "宜良县",
        "530126": "石林彝族自治县",
        "530127": "嵩明县",
        "530128": "禄劝彝族苗族自治县",
        "530129": "寻甸回族彝族自治县",
        "530181": "安宁市"
    },
    "530102": {
        "530102001": "华山街道",
        "530102002": "护国街道",
        "530102003": "大观街道",
        "530102004": "龙翔街道",
        "530102005": "丰宁街道",
        "530102006": "莲华街道",
        "530102007": "红云街道",
        "530102008": "黑林铺街道",
        "530102009": "普吉街道",
        "530102010": "西翥街道",
        "530102401": "昆明国家高新技术产业开发区"
    },
    "530103": {
        "530103001": "拓东街道",
        "530103002": "鼓楼街道",
        "530103003": "东华街道",
        "530103004": "联盟街道",
        "530103005": "金辰街道",
        "530103006": "青云街道",
        "530103007": "龙泉街道",
        "530103008": "茨坝街道",
        "530103009": "双龙街道",
        "530103010": "松华街道",
        "530103011": "盘龙区滇源街道",
        "530103012": "盘龙区阿子营街道"
    },
    "530111": {
        "530111001": "关上街道",
        "530111002": "太和街道",
        "530111003": "吴井街道",
        "530111004": "金马街道",
        "530111005": "小板桥街道",
        "530111006": "官渡街道",
        "530111007": "矣六街道",
        "530111008": "六甲街道",
        "530111009": "大板桥街道",
        "530111010": "阿拉街道",
        "530111402": "昆明经济技术开发区"
    },
    "530112": {
        "530112001": "西苑街道",
        "530112002": "马街街道",
        "530112003": "金碧街道",
        "530112004": "永昌街道",
        "530112005": "前卫街道",
        "530112006": "福海街道",
        "530112007": "棕树营街道",
        "530112008": "碧鸡街道",
        "530112009": "海口街道",
        "530112010": "团结街道",
        "530112403": "昆明滇池国家旅游度假区"
    },
    "530113": {
        "530113001": "铜都街道",
        "530113102": "汤丹镇",
        "530113103": "因民镇",
        "530113104": "阿旺镇",
        "530113105": "乌龙镇",
        "530113106": "红土地镇",
        "530113107": "拖布卡镇",
        "530113201": "舍块乡"
    },
    "530114": {
        "530114001": "龙城街道",
        "530114002": "洛龙街道",
        "530114003": "斗南街道",
        "530114004": "乌龙街道",
        "530114005": "吴家营街道",
        "530114006": "雨花街道",
        "530114007": "七甸街道",
        "530114008": "洛羊街道",
        "530114009": "大渔街道",
        "530114010": "马金铺街道"
    },
    "530115": {
        "530115001": "昆阳街道",
        "530115002": "宝峰街道",
        "530115100": "晋城镇",
        "530115101": "二街镇",
        "530115102": "上蒜镇",
        "530115103": "六街镇",
        "530115200": "双河彝族乡",
        "530115201": "夕阳彝族乡"
    },
    "530124": {
        "530124001": "永定街道",
        "530124002": "大营街道",
        "530124103": "罗免镇",
        "530124104": "赤鹫镇",
        "530124105": "东村镇",
        "530124106": "款庄镇",
        "530124107": "散旦镇"
    },
    "530125": {
        "530125001": "匡远街道",
        "530125002": "汤池街道",
        "530125003": "南羊街道",
        "530125102": "北古城镇",
        "530125103": "狗街镇",
        "530125105": "竹山镇",
        "530125106": "马街镇",
        "530125201": "耿家营彝族苗族乡",
        "530125202": "九乡彝族回族乡",
        "530125404": "昆明阳宗海风景名胜区"
    },
    "530126": {
        "530126001": "鹿阜街道",
        "530126002": "石林街道",
        "530126003": "板桥街道",
        "530126105": "西街口镇",
        "530126106": "长湖镇",
        "530126107": "圭山镇",
        "530126201": "大可乡"
    },
    "530127": {
        "530127001": "嵩阳街道",
        "530127002": "嵩明县杨桥街道",
        "530127102": "小街镇",
        "530127103": "杨林镇",
        "530127104": "牛栏江镇",
        "530127401": "嵩明杨林经济技术开发区",
        "530127402": "云南省嵩明职业教育基地"
    },
    "530128": {
        "530128001": "屏山街道",
        "530128102": "撒营盘镇",
        "530128103": "转龙镇",
        "530128104": "茂山镇",
        "530128105": "团街镇",
        "530128106": "中屏镇",
        "530128107": "皎平渡镇",
        "530128108": "乌东德镇",
        "530128109": "翠华镇",
        "530128110": "九龙镇",
        "530128204": "云龙乡",
        "530128207": "汤郎乡",
        "530128208": "马鹿塘乡",
        "530128211": "则黑乡",
        "530128212": "乌蒙乡",
        "530128213": "雪山乡",
        "530128401": "禄劝工业园区管理委员会"
    },
    "530129": {
        "530129001": "仁德街道",
        "530129002": "塘子街道",
        "530129003": "金所街道",
        "530129103": "羊街镇",
        "530129104": "柯渡镇",
        "530129105": "倘甸镇",
        "530129106": "功山镇",
        "530129107": "河口镇",
        "530129108": "七星镇",
        "530129109": "先锋镇",
        "530129110": "鸡街镇",
        "530129111": "凤合镇",
        "530129205": "六哨乡",
        "530129208": "联合乡",
        "530129209": "金源乡",
        "530129210": "甸沙乡",
        "530129405": "昆明倘甸产业园区轿子山旅游开发区"
    },
    "530181": {
        "530181001": "连然街道",
        "530181002": "金方街道",
        "530181003": "八街街道",
        "530181004": "温泉街道",
        "530181005": "青龙街道",
        "530181006": "禄脿街道",
        "530181007": "草铺街道",
        "530181008": "太平新城街道",
        "530181009": "县街街道"
    },
    "530300": {
        "530302": "麒麟区",
        "530303": "沾益区",
        "530304": "马龙区",
        "530322": "陆良县",
        "530323": "师宗县",
        "530324": "罗平县",
        "530325": "富源县",
        "530326": "会泽县",
        "530381": "宣威市"
    },
    "530302": {
        "530302001": "南宁街道",
        "530302002": "建宁街道",
        "530302003": "白石江街道",
        "530302004": "寥廓街道",
        "530302005": "西城街道",
        "530302006": "益宁街道",
        "530302007": "文华街道",
        "530302008": "太和街道",
        "530302009": "潇湘街道",
        "530302010": "翠峰街道",
        "530302011": "三宝街道",
        "530302012": "沿江街道",
        "530302013": "珠街街道",
        "530302102": "越州镇",
        "530302103": "东山镇",
        "530302104": "茨营镇"
    },
    "530303": {
        "530303001": "西平街道",
        "530303002": "龙华街道",
        "530303003": "金龙街道",
        "530303004": "花山街道",
        "530303101": "白水镇",
        "530303102": "盘江镇",
        "530303201": "炎方乡",
        "530303202": "播乐乡",
        "530303203": "大坡乡",
        "530303204": "菱角乡",
        "530303205": "德泽乡"
    },
    "530304": {
        "530304001": "通泉街道",
        "530304002": "鸡头村街道",
        "530304003": "王家庄街道",
        "530304004": "张安屯街道",
        "530304005": "旧县街道",
        "530304100": "马过河镇",
        "530304101": "纳章镇",
        "530304200": "马鸣乡",
        "530304201": "大庄乡",
        "530304202": "月望乡"
    },
    "530322": {
        "530322001": "中枢街道",
        "530322002": "同乐街道",
        "530322102": "板桥镇",
        "530322103": "三岔河镇",
        "530322104": "马街镇",
        "530322105": "召夸镇",
        "530322106": "大莫古镇",
        "530322107": "芳华镇",
        "530322108": "小百户镇",
        "530322201": "活水乡",
        "530322202": "龙海乡",
        "530322501": "陆良华侨管理区（镇）"
    },
    "530323": {
        "530323001": "丹凤街道",
        "530323002": "漾月街道",
        "530323003": "大同街道",
        "530323102": "雄壁镇",
        "530323103": "葵山镇",
        "530323104": "彩云镇",
        "530323105": "竹基镇",
        "530323202": "龙庆彝族壮族乡",
        "530323203": "五龙壮族乡",
        "530323204": "高良壮族苗族瑶族乡"
    },
    "530324": {
        "530324001": "罗雄街道",
        "530324002": "腊山街道",
        "530324003": "九龙街道",
        "530324102": "板桥镇",
        "530324103": "马街镇",
        "530324104": "富乐镇",
        "530324106": "阿岗镇",
        "530324201": "大水井乡",
        "530324202": "鲁布革布依族苗族乡",
        "530324203": "旧屋基彝族乡",
        "530324204": "钟山乡",
        "530324205": "长底布依族乡",
        "530324206": "老厂乡"
    },
    "530325": {
        "530325001": "中安街道",
        "530325002": "胜境街道",
        "530325102": "营上镇",
        "530325103": "黄泥河镇",
        "530325104": "竹园镇",
        "530325105": "后所镇",
        "530325106": "大河镇",
        "530325107": "墨红镇",
        "530325108": "富村镇",
        "530325109": "十八连山镇",
        "530325110": "老厂镇",
        "530325201": "古敢水族乡"
    },
    "530326": {
        "530326001": "古城街道",
        "530326002": "宝云街道",
        "530326003": "金钟街道",
        "530326102": "娜姑镇",
        "530326103": "迤车镇",
        "530326104": "乐业镇",
        "530326105": "矿山镇",
        "530326106": "者海镇",
        "530326107": "大井镇",
        "530326108": "待补镇",
        "530326201": "大海乡",
        "530326202": "老厂乡",
        "530326203": "五星乡",
        "530326204": "大桥乡",
        "530326205": "纸厂乡",
        "530326206": "马路乡",
        "530326207": "火红乡",
        "530326208": "新街回族乡",
        "530326209": "雨碌乡",
        "530326210": "鲁纳乡",
        "530326211": "上村乡",
        "530326212": "驾车乡",
        "530326213": "田坝乡"
    },
    "530381": {
        "530381001": "宛水街道",
        "530381002": "西宁街道",
        "530381003": "双龙街道",
        "530381004": "虹桥街道",
        "530381005": "丰华街道",
        "530381006": "板桥街道",
        "530381007": "来宾街道",
        "530381008": "凤凰街道",
        "530381102": "格宜镇",
        "530381103": "田坝镇",
        "530381104": "羊场镇",
        "530381106": "倘塘镇",
        "530381107": "落水镇",
        "530381108": "务德镇",
        "530381109": "海岱镇",
        "530381110": "龙场镇",
        "530381111": "龙潭镇",
        "530381112": "热水镇",
        "530381113": "宝山镇",
        "530381114": "东山镇",
        "530381115": "杨柳镇",
        "530381201": "普立乡",
        "530381202": "西泽乡",
        "530381203": "得禄乡",
        "530381205": "双河乡",
        "530381206": "乐丰乡",
        "530381207": "文兴乡",
        "530381208": "阿都乡"
    },
    "530400": {
        "530402": "红塔区",
        "530403": "江川区",
        "530422": "澄江县",
        "530423": "通海县",
        "530424": "华宁县",
        "530425": "易门县",
        "530426": "峨山彝族自治县",
        "530427": "新平彝族傣族自治县",
        "530428": "元江哈尼族彝族傣族自治县"
    },
    "530402": {
        "530402001": "玉兴路街道",
        "530402002": "凤凰路街道",
        "530402003": "玉带路街道",
        "530402004": "北城街道",
        "530402005": "春和街道",
        "530402006": "李棋街道",
        "530402007": "大营街街道",
        "530402008": "研和街道",
        "530402009": "高仓街道",
        "530402201": "小石桥彝族乡",
        "530402202": "洛河彝族乡"
    },
    "530403": {
        "530403001": "大街街道",
        "530403101": "江城镇",
        "530403102": "前卫镇",
        "530403103": "九溪镇",
        "530403104": "路居镇",
        "530403201": "安化彝族乡",
        "530403202": "雄关乡"
    },
    "530422": {
        "530422001": "凤麓街道",
        "530422002": "龙街街道",
        "530422101": "右所镇",
        "530422102": "阳宗镇",
        "530422103": "海口镇",
        "530422104": "九村镇"
    },
    "530423": {
        "530423001": "秀山街道",
        "530423002": "九龙街道",
        "530423101": "杨广镇",
        "530423102": "河西镇",
        "530423103": "四街镇",
        "530423104": "纳古镇",
        "530423201": "里山彝族乡",
        "530423202": "高大傣族彝族乡",
        "530423203": "兴蒙蒙古族乡"
    },
    "530424": {
        "530424001": "宁州街道",
        "530424102": "盘溪镇",
        "530424103": "华溪镇",
        "530424104": "青龙镇",
        "530424201": "通红甸彝族苗族乡"
    },
    "530425": {
        "530425001": "龙泉街道",
        "530425002": "六街街道",
        "530425100": "绿汁镇",
        "530425201": "浦贝彝族乡",
        "530425202": "十街彝族乡",
        "530425203": "铜厂彝族乡",
        "530425204": "小街乡"
    },
    "530426": {
        "530426001": "双江街道",
        "530426002": "小街街道",
        "530426103": "甸中镇",
        "530426104": "化念镇",
        "530426105": "塔甸镇",
        "530426201": "岔河乡",
        "530426202": "大龙潭乡",
        "530426203": "富良棚乡"
    },
    "530427": {
        "530427001": "桂山街道",
        "530427002": "古城街道",
        "530427100": "扬武镇",
        "530427101": "漠沙镇",
        "530427102": "戛洒镇",
        "530427103": "水塘镇",
        "530427200": "平甸乡",
        "530427201": "新化乡",
        "530427202": "建兴乡",
        "530427203": "老厂乡",
        "530427204": "者竜乡",
        "530427205": "平掌乡"
    },
    "530428": {
        "530428001": "红河街道",
        "530428002": "澧江街道",
        "530428003": "甘庄街道",
        "530428101": "曼来镇",
        "530428102": "因远镇",
        "530428201": "龙潭乡",
        "530428202": "羊街乡",
        "530428203": "那诺乡",
        "530428204": "洼垤乡",
        "530428205": "咪哩乡"
    },
    "530500": {"530502": "隆阳区", "530521": "施甸县", "530523": "龙陵县", "530524": "昌宁县", "530581": "腾冲市"},
    "530502": {
        "530502001": "兰城街道",
        "530502002": "永昌街道",
        "530502003": "九隆街道",
        "530502004": "青华街道",
        "530502005": "河图街道",
        "530502006": "永盛街道",
        "530502101": "板桥镇",
        "530502103": "汉庄镇",
        "530502104": "蒲缥镇",
        "530502105": "瓦窑镇",
        "530502106": "潞江镇",
        "530502201": "金鸡乡",
        "530502202": "辛街乡",
        "530502203": "西邑乡",
        "530502204": "丙麻乡",
        "530502205": "瓦渡乡",
        "530502206": "水寨乡",
        "530502207": "瓦马彝族白族乡",
        "530502208": "瓦房彝族苗族乡",
        "530502209": "杨柳白族彝族乡",
        "530502210": "芒宽彝族傣族乡",
        "530502401": "保山工贸园区",
        "530502501": "保山市高黎贡山旅游渡假区"
    },
    "530521": {
        "530521101": "甸阳镇",
        "530521102": "由旺镇",
        "530521103": "姚关镇",
        "530521104": "仁和镇",
        "530521105": "太平镇",
        "530521201": "万兴乡",
        "530521202": "摆榔彝族布朗族乡",
        "530521203": "酒房乡",
        "530521204": "旧城乡",
        "530521205": "木老元布朗族彝族乡",
        "530521206": "老麦乡",
        "530521207": "何元乡",
        "530521208": "水长乡",
        "530521401": "保山市水长工业园区"
    },
    "530523": {
        "530523101": "龙山镇",
        "530523102": "镇安镇",
        "530523103": "勐糯镇",
        "530523104": "腊勐镇",
        "530523105": "象达镇",
        "530523201": "龙江乡",
        "530523203": "碧寨乡",
        "530523204": "龙新乡",
        "530523206": "平达乡",
        "530523207": "木城彝族傈僳族乡"
    },
    "530524": {
        "530524101": "田园镇",
        "530524102": "漭水镇",
        "530524103": "柯街镇",
        "530524104": "卡斯镇",
        "530524105": "勐统镇",
        "530524106": "温泉镇",
        "530524107": "大田坝镇",
        "530524108": "鸡飞镇",
        "530524109": "翁堵镇",
        "530524205": "湾甸傣族乡",
        "530524206": "更戛乡",
        "530524207": "珠街彝族乡",
        "530524208": "耈街彝族苗族乡"
    },
    "530581": {
        "530581101": "腾越镇",
        "530581102": "固东镇",
        "530581103": "滇滩镇",
        "530581104": "猴桥镇",
        "530581105": "和顺镇",
        "530581106": "界头镇",
        "530581107": "曲石镇",
        "530581108": "明光镇",
        "530581109": "中和镇",
        "530581110": "芒棒镇",
        "530581111": "荷花镇",
        "530581201": "马站乡",
        "530581202": "北海乡",
        "530581203": "清水乡",
        "530581204": "五合乡",
        "530581205": "新华乡",
        "530581206": "蒲川乡",
        "530581207": "团田乡"
    },
    "530600": {
        "530602": "昭阳区",
        "530621": "鲁甸县",
        "530622": "巧家县",
        "530623": "盐津县",
        "530624": "大关县",
        "530625": "永善县",
        "530626": "绥江县",
        "530627": "镇雄县",
        "530628": "彝良县",
        "530629": "威信县",
        "530681": "水富市"
    },
    "530602": {
        "530602001": "凤凰街道",
        "530602002": "龙泉街道",
        "530602003": "太平街道",
        "530602101": "旧圃镇",
        "530602102": "永丰镇",
        "530602103": "北闸镇",
        "530602104": "盘河镇",
        "530602105": "靖安镇",
        "530602106": "洒渔镇",
        "530602107": "乐居镇",
        "530602108": "苏家院镇",
        "530602109": "大山包镇",
        "530602110": "炎山镇",
        "530602201": "布嘎回族乡",
        "530602202": "守望回族乡",
        "530602203": "小龙洞回族彝族乡",
        "530602206": "青岗岭回族彝族乡",
        "530602210": "苏甲乡",
        "530602212": "大寨子乡",
        "530602214": "田坝乡"
    },
    "530621": {
        "530621101": "文屏镇",
        "530621102": "水磨镇",
        "530621103": "龙头山镇",
        "530621104": "小寨镇",
        "530621105": "江底镇",
        "530621106": "火德红镇",
        "530621107": "龙树镇",
        "530621108": "新街镇",
        "530621109": "梭山镇",
        "530621110": "乐红镇",
        "530621201": "桃源回族乡",
        "530621202": "茨院回族乡"
    },
    "530622": {
        "530622101": "白鹤滩镇",
        "530622102": "大寨镇",
        "530622103": "小河镇",
        "530622104": "药山镇",
        "530622105": "马树镇",
        "530622106": "老店镇",
        "530622107": "茂租镇",
        "530622108": "东坪镇",
        "530622109": "新店镇",
        "530622110": "崇溪镇",
        "530622111": "金塘镇",
        "530622112": "蒙姑镇",
        "530622203": "红山乡",
        "530622205": "包谷垴乡",
        "530622206": "中寨乡",
        "530622209": "炉房乡"
    },
    "530623": {
        "530623101": "盐井镇",
        "530623102": "普洱镇",
        "530623103": "豆沙镇",
        "530623104": "中和镇",
        "530623105": "庙坝镇",
        "530623106": "柿子镇",
        "530623201": "兴隆乡",
        "530623202": "落雁乡",
        "530623203": "滩头乡",
        "530623204": "牛寨乡"
    },
    "530624": {
        "530624101": "翠华镇",
        "530624102": "玉碗镇",
        "530624103": "吉利镇",
        "530624104": "天星镇",
        "530624105": "木杆镇",
        "530624106": "悦乐镇",
        "530624107": "寿山镇",
        "530624108": "高桥镇",
        "530624201": "上高桥回族彝族苗族乡"
    },
    "530625": {
        "530625101": "溪洛渡镇",
        "530625102": "桧溪镇",
        "530625103": "黄华镇",
        "530625104": "茂林镇",
        "530625105": "大兴镇",
        "530625106": "莲峰镇",
        "530625107": "务基镇",
        "530625108": "码口镇",
        "530625201": "团结乡",
        "530625202": "细沙乡",
        "530625203": "青胜乡",
        "530625205": "马楠苗族彝族乡",
        "530625206": "水竹乡",
        "530625207": "墨翰乡",
        "530625208": "伍寨彝族苗族乡"
    },
    "530626": {"530626101": "中城镇", "530626102": "南岸镇", "530626103": "新滩镇", "530626104": "会仪镇", "530626105": "板栗镇"},
    "530627": {
        "530627001": "乌峰街道",
        "530627002": "南台街道",
        "530627003": "旧府街道",
        "530627102": "泼机镇",
        "530627103": "黑树镇",
        "530627104": "母享镇",
        "530627105": "大湾镇",
        "530627106": "以勒镇",
        "530627107": "赤水源镇",
        "530627108": "芒部镇",
        "530627109": "雨河镇",
        "530627110": "罗坎镇",
        "530627111": "牛场镇",
        "530627112": "五德镇",
        "530627113": "坡头镇",
        "530627114": "以古镇",
        "530627115": "场坝镇",
        "530627116": "塘房镇",
        "530627117": "中屯镇",
        "530627118": "木卓镇",
        "530627119": "盐源镇",
        "530627120": "碗厂镇",
        "530627121": "坪上镇",
        "530627202": "鱼洞乡",
        "530627203": "花朗乡",
        "530627204": "尖山乡",
        "530627208": "杉树乡",
        "530627209": "花山乡",
        "530627211": "果珠彝族乡",
        "530627212": "林口彝族苗族乡"
    },
    "530628": {
        "530628101": "角奎镇",
        "530628102": "洛泽河镇",
        "530628103": "牛街镇",
        "530628104": "海子镇",
        "530628105": "荞山镇",
        "530628106": "龙安镇",
        "530628107": "钟鸣镇",
        "530628108": "两河镇",
        "530628109": "小草坝镇",
        "530628110": "龙海镇",
        "530628201": "龙街苗族彝族乡",
        "530628202": "奎香苗族彝族乡",
        "530628203": "树林彝族苗族乡",
        "530628211": "柳溪苗族乡",
        "530628212": "洛旺苗族乡"
    },
    "530629": {
        "530629101": "扎西镇",
        "530629102": "旧城镇",
        "530629103": "罗布镇",
        "530629104": "麟凤镇",
        "530629105": "长安镇",
        "530629106": "庙沟镇",
        "530629107": "水田镇",
        "530629201": "双河苗族彝族乡",
        "530629202": "高田乡",
        "530629207": "三桃乡"
    },
    "530681": {"530681001": "云富街道", "530681101": "向家坝镇", "530681102": "太平镇", "530681103": "两碗镇"},
    "530700": {"530702": "古城区", "530721": "玉龙纳西族自治县", "530722": "永胜县", "530723": "华坪县", "530724": "宁蒗彝族自治县"},
    "530702": {
        "530702001": "西安街道",
        "530702002": "大研街道",
        "530702003": "祥和街道",
        "530702004": "束河街道",
        "530702005": "金山街道",
        "530702006": "开南街道",
        "530702007": "文化街道",
        "530702101": "金安镇",
        "530702102": "七河镇",
        "530702203": "大东乡",
        "530702205": "金江白族乡"
    },
    "530721": {
        "530721101": "黄山镇",
        "530721102": "石鼓镇",
        "530721103": "巨甸镇",
        "530721104": "白沙镇",
        "530721105": "拉市镇",
        "530721106": "奉科镇",
        "530721107": "鸣音镇",
        "530721203": "太安乡",
        "530721204": "龙蟠乡",
        "530721205": "黎明傈僳族乡",
        "530721206": "鲁甸乡",
        "530721207": "塔城乡",
        "530721208": "大具乡",
        "530721209": "宝山乡",
        "530721212": "石头白族乡",
        "530721213": "九河白族乡"
    },
    "530722": {
        "530722101": "永北镇",
        "530722102": "仁和镇",
        "530722103": "期纳镇",
        "530722104": "三川镇",
        "530722105": "程海镇",
        "530722106": "涛源镇",
        "530722107": "鲁地拉镇",
        "530722108": "片角镇",
        "530722109": "顺州镇",
        "530722201": "羊坪彝族乡",
        "530722202": "六德傈僳族彝族乡",
        "530722203": "东山傈僳族彝族乡",
        "530722207": "光华傈僳族彝族乡",
        "530722208": "松坪傈僳族彝族乡",
        "530722209": "大安彝族纳西族乡"
    },
    "530723": {
        "530723101": "中心镇",
        "530723102": "荣将镇",
        "530723103": "兴泉镇",
        "530723104": "石龙坝镇",
        "530723202": "新庄傈僳族傣族乡",
        "530723203": "通达傈僳族乡",
        "530723204": "永兴傈僳族乡",
        "530723205": "船房傈僳族傣族乡"
    },
    "530724": {
        "530724101": "大兴镇",
        "530724201": "拉伯乡",
        "530724202": "永宁乡",
        "530724203": "翠玉傈僳族普米族乡",
        "530724204": "红桥乡",
        "530724205": "宁利乡",
        "530724206": "金棉乡",
        "530724207": "西川乡",
        "530724208": "西布河乡",
        "530724209": "战河乡",
        "530724210": "永宁坪乡",
        "530724211": "跑马坪乡",
        "530724212": "蝉战河乡",
        "530724213": "新营盘乡",
        "530724214": "烂泥箐乡"
    },
    "530800": {
        "530802": "思茅区",
        "530821": "宁洱哈尼族彝族自治县",
        "530822": "墨江哈尼族自治县",
        "530823": "景东彝族自治县",
        "530824": "景谷傣族彝族自治县",
        "530825": "镇沅彝族哈尼族拉祜族自治县",
        "530826": "江城哈尼族彝族自治县",
        "530827": "孟连傣族拉祜族佤族自治县",
        "530828": "澜沧拉祜族自治县",
        "530829": "西盟佤族自治县"
    },
    "530802": {
        "530802101": "思茅镇",
        "530802102": "南屏镇",
        "530802103": "倚象镇",
        "530802104": "思茅港镇",
        "530802105": "六顺镇",
        "530802201": "龙潭彝族傣族乡",
        "530802202": "云仙彝族乡"
    },
    "530821": {
        "530821101": "宁洱镇",
        "530821102": "磨黑镇",
        "530821103": "德化镇",
        "530821104": "同心镇",
        "530821105": "勐先镇",
        "530821106": "梅子镇",
        "530821201": "德安乡",
        "530821205": "普义乡",
        "530821206": "黎明乡"
    },
    "530822": {
        "530822101": "联珠镇",
        "530822102": "通关镇",
        "530822103": "龙坝镇",
        "530822104": "新安镇",
        "530822105": "团田镇",
        "530822106": "新抚镇",
        "530822107": "景星镇",
        "530822108": "鱼塘镇",
        "530822109": "文武镇",
        "530822110": "坝溜镇",
        "530822111": "泗南江镇",
        "530822112": "雅邑镇",
        "530822203": "孟弄彝族乡",
        "530822208": "龙潭乡",
        "530822212": "那哈乡"
    },
    "530823": {
        "530823101": "锦屏镇",
        "530823102": "文井镇",
        "530823103": "漫湾镇",
        "530823104": "大朝山东镇",
        "530823105": "花山镇",
        "530823106": "大街镇",
        "530823107": "太忠镇",
        "530823108": "文龙镇",
        "530823109": "安定镇",
        "530823110": "景福镇",
        "530823201": "曼等乡",
        "530823205": "龙街乡",
        "530823208": "林街乡"
    },
    "530824": {
        "530824101": "威远镇",
        "530824102": "永平镇",
        "530824103": "正兴镇",
        "530824104": "民乐镇",
        "530824105": "凤山镇",
        "530824106": "景谷镇",
        "530824201": "碧安乡",
        "530824204": "益智乡",
        "530824205": "半坡乡",
        "530824206": "勐班乡"
    },
    "530825": {
        "530825101": "恩乐镇",
        "530825102": "按板镇",
        "530825103": "勐大镇",
        "530825104": "者东镇",
        "530825105": "九甲镇",
        "530825106": "古城镇",
        "530825107": "振太镇",
        "530825108": "和平镇",
        "530825201": "田坝乡"
    },
    "530826": {
        "530826101": "勐烈镇",
        "530826102": "整董镇",
        "530826103": "曲水镇",
        "530826104": "宝藏镇",
        "530826105": "康平镇",
        "530826202": "国庆乡",
        "530826203": "嘉禾乡"
    },
    "530827": {
        "530827101": "娜允镇",
        "530827102": "勐马镇",
        "530827103": "芒信镇",
        "530827104": "富岩镇",
        "530827201": "景信乡",
        "530827202": "公信乡"
    },
    "530828": {
        "530828101": "勐朗镇",
        "530828102": "上允镇",
        "530828103": "糯扎渡镇",
        "530828104": "惠民镇",
        "530828105": "东回镇",
        "530828201": "发展河哈尼族乡",
        "530828202": "谦六彝族乡",
        "530828203": "糯福乡",
        "530828204": "东河乡",
        "530828205": "大山乡",
        "530828206": "南岭乡",
        "530828207": "雪林佤族乡",
        "530828208": "木戛乡",
        "530828209": "酒井哈尼族乡",
        "530828212": "拉巴乡",
        "530828213": "竹塘乡",
        "530828214": "富邦乡",
        "530828215": "安康佤族乡",
        "530828216": "文东佤族乡",
        "530828217": "富东乡"
    },
    "530829": {
        "530829101": "勐梭镇",
        "530829102": "勐卡镇",
        "530829103": "翁嘎科镇",
        "530829104": "中课镇",
        "530829105": "新厂镇",
        "530829202": "力所拉祜族乡",
        "530829203": "岳宋乡"
    },
    "530900": {
        "530902": "临翔区",
        "530921": "凤庆县",
        "530922": "云县",
        "530923": "永德县",
        "530924": "镇康县",
        "530925": "双江拉祜族佤族布朗族傣族自治县",
        "530926": "耿马傣族佤族自治县",
        "530927": "沧源佤族自治县"
    },
    "530902": {
        "530902001": "凤翔街道",
        "530902002": "忙畔街道",
        "530902102": "博尚镇",
        "530902202": "南美拉祜族乡",
        "530902203": "蚂蚁堆乡",
        "530902204": "章驮乡",
        "530902206": "圈内乡",
        "530902207": "马台乡",
        "530902208": "邦东乡",
        "530902209": "平村彝族傣族乡"
    },
    "530921": {
        "530921101": "凤山镇",
        "530921102": "鲁史镇",
        "530921103": "小湾镇",
        "530921104": "营盘镇",
        "530921105": "三岔河镇",
        "530921106": "勐佑镇",
        "530921107": "雪山镇",
        "530921108": "洛党镇",
        "530921201": "诗礼乡",
        "530921202": "新华彝族苗族乡",
        "530921204": "大寺乡",
        "530921206": "腰街彝族乡",
        "530921207": "郭大寨彝族白族乡"
    },
    "530922": {
        "530922101": "爱华镇",
        "530922102": "漫湾镇",
        "530922103": "大朝山西镇",
        "530922104": "涌宝镇",
        "530922105": "茂兰镇",
        "530922106": "幸福镇",
        "530922107": "大寨镇",
        "530922201": "忙怀彝族布朗族乡",
        "530922202": "晓街乡",
        "530922203": "茶房乡",
        "530922204": "栗树彝族傣族乡",
        "530922205": "后箐彝族乡",
        "530922501": "幸福农场",
        "530922502": "新城农场"
    },
    "530923": {
        "530923101": "德党镇",
        "530923102": "小勐统镇",
        "530923103": "永康镇",
        "530923203": "勐板乡",
        "530923204": "亚练乡",
        "530923205": "乌木龙彝族乡",
        "530923206": "大雪山彝族拉祜族傣族乡",
        "530923207": "班卡乡",
        "530923208": "崇岗乡",
        "530923209": "大山乡",
        "530923401": "勐底农场"
    },
    "530924": {
        "530924101": "凤尾镇",
        "530924102": "勐捧镇",
        "530924103": "南伞镇",
        "530924201": "忙丙乡",
        "530924202": "勐堆乡",
        "530924204": "木场乡",
        "530924205": "军赛佤族拉祜族傈僳族德昂族乡"
    },
    "530925": {
        "530925101": "勐勐镇",
        "530925102": "勐库镇",
        "530925201": "沙河乡",
        "530925202": "大文乡",
        "530925203": "忙糯乡",
        "530925205": "邦丙乡"
    },
    "530926": {
        "530926100": "耿马镇",
        "530926101": "勐永镇",
        "530926102": "勐撒镇",
        "530926103": "孟定镇",
        "530926200": "大兴乡",
        "530926201": "芒洪拉祜族布朗族乡",
        "530926202": "四排山乡",
        "530926203": "贺派乡",
        "530926204": "勐简乡",
        "530926401": "耿马华侨农场",
        "530926402": "勐撒农场",
        "530926403": "孟定农场"
    },
    "530927": {
        "530927101": "勐董镇",
        "530927102": "岩帅镇",
        "530927103": "勐省镇",
        "530927104": "芒卡镇",
        "530927202": "单甲乡",
        "530927203": "糯良乡",
        "530927204": "勐来乡",
        "530927205": "勐角傣族彝族拉祜族乡",
        "530927206": "班洪乡",
        "530927207": "班老乡",
        "530927401": "勐省农场"
    },
    "532300": {
        "532301": "楚雄市",
        "532322": "双柏县",
        "532323": "牟定县",
        "532324": "南华县",
        "532325": "姚安县",
        "532326": "大姚县",
        "532327": "永仁县",
        "532328": "元谋县",
        "532329": "武定县",
        "532331": "禄丰县"
    },
    "532301": {
        "532301101": "鹿城镇",
        "532301102": "东瓜镇",
        "532301103": "吕合镇",
        "532301104": "紫溪镇",
        "532301105": "东华镇",
        "532301106": "子午镇",
        "532301107": "苍岭镇",
        "532301108": "三街镇",
        "532301109": "八角镇",
        "532301110": "中山镇",
        "532301111": "新村镇",
        "532301112": "西舍路镇",
        "532301201": "树苴乡",
        "532301202": "大过口乡",
        "532301203": "大地基乡"
    },
    "532322": {
        "532322101": "妥甸镇",
        "532322102": "大庄镇",
        "532322103": "法脿镇",
        "532322104": "鄂嘉镇",
        "532322105": "大麦地镇",
        "532322201": "安龙堡乡",
        "532322202": "爱尼山乡",
        "532322203": "独田乡"
    },
    "532323": {
        "532323101": "共和镇",
        "532323102": "新桥镇",
        "532323103": "江坡镇",
        "532323104": "凤屯镇",
        "532323201": "蟠猫乡",
        "532323202": "戌街乡",
        "532323203": "安乐乡"
    },
    "532324": {
        "532324101": "龙川镇",
        "532324102": "沙桥镇",
        "532324103": "五街镇",
        "532324104": "红土坡镇",
        "532324105": "马街镇",
        "532324106": "兔街镇",
        "532324201": "雨露白族乡",
        "532324202": "一街乡",
        "532324203": "罗武庄乡",
        "532324204": "五顶山乡"
    },
    "532325": {
        "532325101": "栋川镇",
        "532325102": "光禄镇",
        "532325103": "前场镇",
        "532325104": "弥兴镇",
        "532325105": "太平镇",
        "532325106": "官屯镇",
        "532325201": "适中乡",
        "532325202": "左门乡",
        "532325204": "大河口乡"
    },
    "532326": {
        "532326101": "金碧镇",
        "532326102": "石羊镇",
        "532326103": "六苴镇",
        "532326104": "龙街镇",
        "532326105": "新街镇",
        "532326106": "赵家店镇",
        "532326107": "三岔河镇",
        "532326108": "桂花镇",
        "532326204": "昙华乡",
        "532326206": "湾碧傣族傈僳族乡",
        "532326207": "铁锁乡",
        "532326208": "三台乡"
    },
    "532327": {
        "532327101": "永定镇",
        "532327102": "宜就镇",
        "532327103": "中和镇",
        "532327201": "莲池乡",
        "532327202": "维的乡",
        "532327203": "猛虎乡",
        "532327204": "永兴傣族乡"
    },
    "532328": {
        "532328101": "元马镇",
        "532328103": "黄瓜园镇",
        "532328104": "羊街镇",
        "532328202": "老城乡",
        "532328203": "物茂乡",
        "532328204": "江边乡",
        "532328205": "新华乡",
        "532328206": "平田乡",
        "532328209": "凉山乡",
        "532328210": "姜驿乡"
    },
    "532329": {
        "532329101": "狮山镇",
        "532329102": "高桥镇",
        "532329103": "猫街镇",
        "532329104": "插甸镇",
        "532329105": "白路镇",
        "532329106": "万德镇",
        "532329107": "己衣镇",
        "532329202": "田心乡",
        "532329203": "发窝乡",
        "532329207": "环州乡",
        "532329208": "东坡傣族乡"
    },
    "532331": {
        "532331101": "金山镇",
        "532331102": "仁兴镇",
        "532331103": "碧城镇",
        "532331104": "勤丰镇",
        "532331105": "一平浪镇",
        "532331106": "广通镇",
        "532331107": "黑井镇",
        "532331108": "土官镇",
        "532331109": "彩云镇",
        "532331110": "和平镇",
        "532331111": "恐龙山镇",
        "532331201": "中村乡",
        "532331203": "高峰乡",
        "532331204": "妥安乡"
    },
    "532500": {
        "532501": "个旧市",
        "532502": "开远市",
        "532503": "蒙自市",
        "532504": "弥勒市",
        "532523": "屏边苗族自治县",
        "532524": "建水县",
        "532525": "石屏县",
        "532527": "泸西县",
        "532528": "元阳县",
        "532529": "红河县",
        "532530": "金平苗族瑶族傣族自治县",
        "532531": "绿春县",
        "532532": "河口瑶族自治县"
    },
    "532501": {
        "532501001": "城区街道",
        "532501101": "锡城镇",
        "532501102": "沙甸镇",
        "532501103": "鸡街镇",
        "532501104": "大屯镇",
        "532501106": "老厂镇",
        "532501107": "卡房镇",
        "532501108": "蔓耗镇",
        "532501202": "贾沙乡",
        "532501203": "保和乡"
    },
    "532502": {
        "532502001": "乐白道街道",
        "532502002": "灵泉街道",
        "532502101": "中和营镇",
        "532502102": "小龙潭镇",
        "532502201": "大庄回族乡",
        "532502202": "羊街乡",
        "532502203": "碑格乡"
    },
    "532503": {
        "532503101": "文澜镇",
        "532503102": "草坝镇",
        "532503103": "雨过铺镇",
        "532503104": "新安所镇",
        "532503105": "芷村镇",
        "532503106": "鸣鹫镇",
        "532503107": "冷泉镇",
        "532503201": "期路白苗族乡",
        "532503202": "老寨苗族乡",
        "532503203": "水田乡",
        "532503204": "西北勒乡"
    },
    "532504": {
        "532504101": "弥阳镇",
        "532504102": "新哨镇",
        "532504103": "虹溪镇",
        "532504104": "竹园镇",
        "532504105": "朋普镇",
        "532504106": "巡检司镇",
        "532504107": "西一镇",
        "532504108": "西二镇",
        "532504109": "西三镇",
        "532504110": "东山镇",
        "532504202": "五山乡",
        "532504203": "江边乡"
    },
    "532523": {
        "532523101": "玉屏镇",
        "532523102": "新现镇",
        "532523103": "和平镇",
        "532523104": "白河镇",
        "532523204": "白云乡",
        "532523205": "新华乡",
        "532523206": "湾塘乡"
    },
    "532524": {
        "532524101": "临安镇",
        "532524102": "官厅镇",
        "532524103": "西庄镇",
        "532524104": "青龙镇",
        "532524105": "南庄镇",
        "532524106": "岔科镇",
        "532524107": "曲江镇",
        "532524108": "面甸镇",
        "532524201": "普雄乡",
        "532524202": "李浩寨乡",
        "532524203": "坡头乡",
        "532524204": "盘江乡",
        "532524205": "利民乡",
        "532524206": "甸尾乡"
    },
    "532525": {
        "532525101": "异龙镇",
        "532525102": "宝秀镇",
        "532525103": "坝心镇",
        "532525104": "龙朋镇",
        "532525105": "龙武镇",
        "532525106": "哨冲镇",
        "532525107": "牛街镇",
        "532525201": "新城乡",
        "532525202": "大桥乡"
    },
    "532527": {
        "532527101": "中枢镇",
        "532527102": "金马镇",
        "532527104": "旧城镇",
        "532527106": "午街铺镇",
        "532527107": "白水镇",
        "532527201": "向阳乡",
        "532527202": "三塘乡",
        "532527203": "永宁乡"
    },
    "532528": {
        "532528101": "南沙镇",
        "532528102": "新街镇",
        "532528103": "牛角寨镇",
        "532528203": "沙拉托乡",
        "532528204": "嘎娘乡",
        "532528205": "上新城乡",
        "532528206": "小新街乡",
        "532528207": "逢春岭乡",
        "532528208": "大坪乡",
        "532528209": "攀枝花乡",
        "532528210": "黄茅岭乡",
        "532528211": "黄草岭乡",
        "532528212": "俄扎乡",
        "532528213": "马街乡"
    },
    "532529": {
        "532529101": "迤萨镇",
        "532529102": "甲寅镇",
        "532529103": "宝华镇",
        "532529104": "乐育镇",
        "532529105": "浪堤镇",
        "532529203": "洛恩乡",
        "532529204": "石头寨乡",
        "532529205": "阿扎河乡",
        "532529208": "大羊街乡",
        "532529209": "车古乡",
        "532529210": "架车乡",
        "532529211": "垤玛乡",
        "532529212": "三村乡"
    },
    "532530": {
        "532530101": "金河镇",
        "532530102": "金水河镇",
        "532530103": "勐拉镇",
        "532530104": "老勐镇",
        "532530202": "铜厂乡",
        "532530204": "老集寨乡",
        "532530205": "者米拉祜族乡",
        "532530206": "阿得博乡",
        "532530207": "沙依坡乡",
        "532530208": "大寨乡",
        "532530209": "马鞍底乡",
        "532530210": "勐桥乡",
        "532530211": "营盘乡"
    },
    "532531": {
        "532531101": "大兴镇",
        "532531102": "牛孔镇",
        "532531103": "大黑山镇",
        "532531104": "平河镇",
        "532531201": "戈奎乡",
        "532531203": "大水沟乡",
        "532531205": "半坡乡",
        "532531206": "骑马坝乡",
        "532531207": "三猛乡"
    },
    "532532": {
        "532532101": "河口镇",
        "532532102": "南溪镇",
        "532532201": "老范寨乡",
        "532532202": "桥头苗族壮族乡",
        "532532203": "瑶山乡",
        "532532204": "莲花滩乡"
    },
    "532600": {
        "532601": "文山市",
        "532622": "砚山县",
        "532623": "西畴县",
        "532624": "麻栗坡县",
        "532625": "马关县",
        "532626": "丘北县",
        "532627": "广南县",
        "532628": "富宁县"
    },
    "532601": {
        "532601001": "开化街道",
        "532601002": "卧龙街道",
        "532601003": "新平街道",
        "532601102": "古木镇",
        "532601103": "平坝镇",
        "532601104": "马塘镇",
        "532601105": "德厚镇",
        "532601106": "小街镇",
        "532601107": "薄竹镇",
        "532601108": "追栗街镇",
        "532601201": "东山彝族乡",
        "532601202": "柳井彝族乡",
        "532601203": "新街乡",
        "532601204": "喜古乡",
        "532601205": "坝心彝族乡",
        "532601206": "秉烈彝族乡",
        "532601207": "红甸回族乡",
        "532601401": "文山市三七产业园区管理委员会",
        "532601402": "文山市马塘工业园区管理委员会"
    },
    "532622": {
        "532622101": "江那镇",
        "532622102": "平远镇",
        "532622103": "稼依镇",
        "532622104": "阿猛镇",
        "532622201": "阿舍彝族乡",
        "532622202": "维摩彝族乡",
        "532622203": "盘龙彝族乡",
        "532622204": "八嘎乡",
        "532622205": "者腊乡",
        "532622206": "蚌峨乡",
        "532622207": "干河彝族乡",
        "532622501": "平远管理区",
        "532622502": "稼依管理区"
    },
    "532623": {
        "532623101": "西洒镇",
        "532623102": "兴街镇",
        "532623201": "蚌谷乡",
        "532623202": "莲花塘乡",
        "532623203": "新马街乡",
        "532623204": "柏林乡",
        "532623205": "法斗乡",
        "532623206": "董马乡",
        "532623207": "鸡街乡"
    },
    "532624": {
        "532624101": "麻栗镇",
        "532624102": "大坪镇",
        "532624103": "董干镇",
        "532624104": "天保镇",
        "532624201": "猛硐瑶族乡",
        "532624202": "下金厂乡",
        "532624203": "八布乡",
        "532624204": "六河乡",
        "532624205": "杨万乡",
        "532624206": "铁厂乡",
        "532624207": "马街乡"
    },
    "532625": {
        "532625101": "马白镇",
        "532625102": "八寨镇",
        "532625103": "仁和镇",
        "532625104": "木厂镇",
        "532625105": "夹寒箐镇",
        "532625106": "小坝子镇",
        "532625107": "都龙镇",
        "532625108": "金厂镇",
        "532625109": "坡脚镇",
        "532625201": "南捞乡",
        "532625202": "大栗树乡",
        "532625203": "篾厂乡",
        "532625204": "古林箐乡",
        "532625501": "健康农场"
    },
    "532626": {
        "532626101": "锦屏镇",
        "532626102": "曰者镇",
        "532626103": "双龙营镇",
        "532626201": "八道哨彝族乡",
        "532626202": "天星乡",
        "532626203": "平寨乡",
        "532626204": "树皮彝族乡",
        "532626205": "腻脚彝族乡",
        "532626206": "新店彝族乡",
        "532626207": "舍得彝族乡",
        "532626208": "官寨乡",
        "532626209": "温浏乡"
    },
    "532627": {
        "532627101": "莲城镇",
        "532627102": "八宝镇",
        "532627103": "南屏镇",
        "532627104": "珠街镇",
        "532627105": "那洒镇",
        "532627106": "珠琳镇",
        "532627107": "坝美镇",
        "532627201": "董堡乡",
        "532627202": "旧莫乡",
        "532627203": "杨柳井乡",
        "532627204": "板蚌乡",
        "532627205": "曙光乡",
        "532627206": "黑支果乡",
        "532627207": "篆角乡",
        "532627208": "五珠乡",
        "532627209": "者兔乡",
        "532627210": "者太乡",
        "532627211": "底圩乡"
    },
    "532628": {
        "532628101": "新华镇",
        "532628102": "归朝镇",
        "532628103": "剥隘镇",
        "532628104": "里达镇",
        "532628105": "田蓬镇",
        "532628106": "木央镇",
        "532628201": "板仑乡",
        "532628202": "谷拉乡",
        "532628203": "者桑乡",
        "532628204": "那能乡",
        "532628205": "洞波瑶族乡",
        "532628206": "阿用乡",
        "532628207": "花甲乡"
    },
    "532800": {"532801": "景洪市", "532822": "勐海县", "532823": "勐腊县"},
    "532801": {
        "532801001": "允景洪街道",
        "532801102": "嘎洒镇",
        "532801103": "勐龙镇",
        "532801104": "勐罕镇",
        "532801105": "勐养镇",
        "532801106": "普文镇",
        "532801202": "景哈哈尼族乡",
        "532801203": "景讷乡",
        "532801204": "大渡岗乡",
        "532801205": "勐旺乡",
        "532801206": "基诺山基诺族乡",
        "532801401": "景洪农场",
        "532801402": "东风农场",
        "532801403": "橄榄坝农场",
        "532801404": "勐养农场",
        "532801405": "大渡岗农场",
        "532801406": "西双版纳州度假区管理委员",
        "532801407": "景洪工业园区",
        "532801408": "西双版纳监狱"
    },
    "532822": {
        "532822101": "勐海镇",
        "532822102": "打洛镇",
        "532822103": "勐混镇",
        "532822104": "勐遮镇",
        "532822105": "勐满镇",
        "532822106": "勐阿镇",
        "532822201": "勐宋乡",
        "532822202": "勐往乡",
        "532822203": "格朗和哈尼族乡",
        "532822204": "布朗山布朗族乡",
        "532822205": "西定哈尼族布朗族乡",
        "532822401": "黎明农场"
    },
    "532823": {
        "532823101": "勐腊镇",
        "532823102": "勐捧镇",
        "532823103": "勐满镇",
        "532823104": "勐仑镇",
        "532823105": "磨憨镇",
        "532823106": "勐伴镇",
        "532823107": "关累镇",
        "532823108": "易武镇",
        "532823202": "象明彝族乡",
        "532823203": "瑶区瑶族乡",
        "532823401": "勐腊农场",
        "532823402": "勐捧农场",
        "532823403": "勐满农场",
        "532823404": "勐醒农场"
    },
    "532900": {
        "532901": "大理市",
        "532922": "漾濞彝族自治县",
        "532923": "祥云县",
        "532924": "宾川县",
        "532925": "弥渡县",
        "532926": "南涧彝族自治县",
        "532927": "巍山彝族回族自治县",
        "532928": "永平县",
        "532929": "云龙县",
        "532930": "洱源县",
        "532931": "剑川县",
        "532932": "鹤庆县"
    },
    "532901": {
        "532901001": "大理创新工业园区天井",
        "532901002": "大理创新工业园区满江",
        "532901101": "下关镇",
        "532901102": "大理镇",
        "532901103": "凤仪镇",
        "532901104": "喜洲镇",
        "532901105": "海东镇",
        "532901106": "挖色镇",
        "532901107": "湾桥镇",
        "532901108": "银桥镇",
        "532901109": "双廊镇",
        "532901110": "上关镇",
        "532901201": "太邑彝族乡"
    },
    "532922": {
        "532922101": "苍山西镇",
        "532922102": "漾江镇",
        "532922103": "平坡镇",
        "532922104": "顺濞镇",
        "532922201": "富恒乡",
        "532922202": "太平乡",
        "532922204": "瓦厂乡",
        "532922205": "龙潭乡",
        "532922206": "鸡街乡"
    },
    "532923": {
        "532923101": "祥城镇",
        "532923102": "沙龙镇",
        "532923103": "云南驿镇",
        "532923104": "下庄镇",
        "532923105": "普棚镇",
        "532923106": "刘厂镇",
        "532923107": "禾甸镇",
        "532923108": "米甸镇",
        "532923201": "鹿鸣乡",
        "532923202": "东山彝族乡"
    },
    "532924": {
        "532924101": "金牛镇",
        "532924102": "宾居镇",
        "532924103": "州城镇",
        "532924104": "大营镇",
        "532924105": "鸡足山镇",
        "532924106": "力角镇",
        "532924107": "平川镇",
        "532924108": "乔甸镇",
        "532924201": "钟英傈僳族彝族乡",
        "532924202": "拉乌彝族乡"
    },
    "532925": {
        "532925101": "弥城镇",
        "532925102": "红岩镇",
        "532925103": "新街镇",
        "532925104": "寅街镇",
        "532925105": "苴力镇",
        "532925106": "密祉镇",
        "532925203": "德苴乡",
        "532925204": "牛街彝族乡"
    },
    "532926": {
        "532926101": "南涧镇",
        "532926102": "小湾东镇",
        "532926103": "公郎镇",
        "532926104": "宝华镇",
        "532926105": "无量山镇",
        "532926201": "拥翠乡",
        "532926202": "乐秋乡",
        "532926203": "碧溪乡"
    },
    "532927": {
        "532927101": "南诏镇",
        "532927102": "庙街镇",
        "532927103": "大仓镇",
        "532927104": "永建镇",
        "532927201": "巍宝山乡",
        "532927202": "马鞍山乡",
        "532927203": "紫金乡",
        "532927204": "五印乡",
        "532927205": "牛街乡",
        "532927206": "青华乡"
    },
    "532928": {
        "532928101": "博南镇",
        "532928102": "杉阳镇",
        "532928103": "龙街镇",
        "532928201": "龙门乡",
        "532928202": "北斗彝族乡",
        "532928203": "厂街彝族乡",
        "532928204": "水泄彝族乡"
    },
    "532929": {
        "532929101": "诺邓镇",
        "532929102": "功果桥镇",
        "532929103": "漕涧镇",
        "532929104": "白石镇",
        "532929201": "宝丰乡",
        "532929202": "关坪乡",
        "532929203": "团结彝族乡",
        "532929204": "长新乡",
        "532929205": "检槽乡",
        "532929206": "苗尾傈僳族乡",
        "532929207": "民建乡"
    },
    "532930": {
        "532930101": "茈碧湖镇",
        "532930102": "邓川镇",
        "532930103": "右所镇",
        "532930104": "三营镇",
        "532930105": "凤羽镇",
        "532930106": "乔后镇",
        "532930201": "牛街乡",
        "532930202": "炼铁乡",
        "532930203": "西山乡"
    },
    "532931": {
        "532931101": "金华镇",
        "532931102": "老君山镇",
        "532931103": "甸南镇",
        "532931104": "沙溪镇",
        "532931105": "马登镇",
        "532931201": "羊岑乡",
        "532931202": "弥沙乡",
        "532931203": "象图乡"
    },
    "532932": {
        "532932101": "云鹤镇",
        "532932102": "辛屯镇",
        "532932103": "松桂镇",
        "532932104": "黄坪镇",
        "532932105": "草海镇",
        "532932106": "西邑镇",
        "532932107": "龙开口镇",
        "532932201": "金墩乡",
        "532932203": "六合彝族乡"
    },
    "533100": {"533102": "瑞丽市", "533103": "芒市", "533122": "梁河县", "533123": "盈江县", "533124": "陇川县"},
    "533102": {
        "533102101": "勐卯镇",
        "533102102": "畹町镇",
        "533102103": "弄岛镇",
        "533102201": "姐相乡",
        "533102202": "户育乡",
        "533102203": "勐秀乡"
    },
    "533103": {
        "533103001": "勐焕街道",
        "533103101": "芒市镇",
        "533103102": "遮放镇",
        "533103103": "勐戛镇",
        "533103104": "芒海镇",
        "533103105": "风平镇",
        "533103201": "轩岗乡",
        "533103202": "江东乡",
        "533103203": "西山乡",
        "533103204": "中山乡",
        "533103205": "三台山德昂族乡",
        "533103206": "五岔路乡"
    },
    "533122": {
        "533122101": "遮岛镇",
        "533122102": "芒东镇",
        "533122103": "勐养镇",
        "533122201": "平山乡",
        "533122202": "小厂乡",
        "533122203": "大厂乡",
        "533122204": "九保阿昌族乡",
        "533122205": "曩宋阿昌族乡",
        "533122206": "河西乡"
    },
    "533123": {
        "533123101": "平原镇",
        "533123102": "旧城镇",
        "533123103": "那邦镇",
        "533123104": "弄璋镇",
        "533123105": "盏西镇",
        "533123106": "卡场镇",
        "533123107": "昔马镇",
        "533123108": "太平镇",
        "533123201": "新城乡",
        "533123202": "油松岭乡",
        "533123203": "芒章乡",
        "533123204": "支那乡",
        "533123205": "苏典傈僳族乡",
        "533123206": "勐弄乡",
        "533123207": "铜壁关乡"
    },
    "533124": {
        "533124101": "章凤镇",
        "533124102": "陇把镇",
        "533124103": "景罕镇",
        "533124104": "城子镇",
        "533124201": "户撒阿昌族乡",
        "533124202": "护国乡",
        "533124203": "清平乡",
        "533124204": "王子树乡",
        "533124205": "勐约乡"
    },
    "533300": {"533301": "泸水市", "533323": "福贡县", "533324": "贡山独龙族怒族自治县", "533325": "兰坪白族普米族自治县"},
    "533301": {
        "533301101": "六库镇",
        "533301102": "鲁掌镇",
        "533301103": "片马镇",
        "533301104": "上江镇",
        "533301105": "老窝镇",
        "533301106": "大兴地镇",
        "533301204": "称杆乡",
        "533301205": "古登乡",
        "533301206": "洛本卓白族乡"
    },
    "533323": {
        "533323101": "上帕镇",
        "533323201": "匹河怒族乡",
        "533323202": "子里甲乡",
        "533323203": "架科底乡",
        "533323204": "鹿马登乡",
        "533323205": "石月亮乡",
        "533323206": "马吉乡"
    },
    "533324": {"533324101": "茨开镇", "533324102": "丙中洛镇", "533324202": "捧当乡", "533324203": "普拉底乡", "533324204": "独龙江乡"},
    "533325": {
        "533325101": "金顶镇",
        "533325102": "啦井镇",
        "533325103": "营盘镇",
        "533325104": "通甸镇",
        "533325201": "河西乡",
        "533325202": "中排乡",
        "533325203": "石登乡",
        "533325204": "兔峨乡"
    },
    "533400": {"533401": "香格里拉市", "533422": "德钦县", "533423": "维西傈僳族自治县"},
    "533401": {
        "533401101": "建塘镇",
        "533401102": "小中甸镇",
        "533401103": "虎跳峡镇",
        "533401104": "金江镇",
        "533401201": "上江乡",
        "533401202": "三坝纳西族乡",
        "533401203": "洛吉乡",
        "533401204": "尼西乡",
        "533401205": "格咱乡",
        "533401206": "东旺乡",
        "533401207": "五境乡"
    },
    "533422": {
        "533422101": "升平镇",
        "533422102": "奔子栏镇",
        "533422201": "佛山乡",
        "533422202": "云岭乡",
        "533422203": "燕门乡",
        "533422204": "拖顶傈僳族乡",
        "533422205": "霞若傈僳族乡",
        "533422206": "羊拉乡"
    },
    "533423": {
        "533423101": "保和镇",
        "533423102": "叶枝镇",
        "533423103": "塔城镇",
        "533423201": "永春乡",
        "533423202": "攀天阁乡",
        "533423203": "白济汛乡",
        "533423204": "康普乡",
        "533423205": "巴迪乡",
        "533423206": "中路乡",
        "533423207": "维登乡"
    },
    "540000": {
        "540100": "拉萨市",
        "540200": "日喀则市",
        "540300": "昌都市",
        "540400": "林芝市",
        "540500": "山南市",
        "540600": "那曲市",
        "542500": "阿里地区"
    },
    "540100": {
        "540102": "城关区",
        "540103": "堆龙德庆区",
        "540104": "达孜区",
        "540121": "林周县",
        "540122": "当雄县",
        "540123": "尼木县",
        "540124": "曲水县",
        "540127": "墨竹工卡县",
        "540171": "格尔木藏青工业园区",
        "540172": "拉萨经济技术开发区",
        "540173": "西藏文化旅游创意园区",
        "540174": "达孜工业园区"
    },
    "540102": {
        "540102002": "八廓街道",
        "540102003": "吉日街道",
        "540102004": "吉崩岗街道",
        "540102005": "扎细街道",
        "540102006": "公德林街道",
        "540102007": "嘎玛贡桑街道",
        "540102008": "两岛街道",
        "540102009": "金珠西路街道",
        "540102200": "蔡公堂乡",
        "540102201": "纳金乡",
        "540102202": "娘热乡",
        "540102203": "夺底乡"
    },
    "540103": {
        "540103001": "东嘎街道",
        "540103002": "乃琼街道",
        "540103003": "羊达街道",
        "540103004": "柳梧街道",
        "540103201": "古荣乡",
        "540103203": "马乡",
        "540103204": "德庆乡"
    },
    "540104": {
        "540104100": "德庆镇",
        "540104200": "塔杰乡",
        "540104201": "章多乡",
        "540104202": "唐嘎乡",
        "540104203": "雪乡",
        "540104204": "帮堆乡"
    },
    "540121": {
        "540121100": "甘丹曲果镇",
        "540121200": "春堆乡",
        "540121201": "松盘乡",
        "540121202": "强嘎乡",
        "540121203": "卡孜乡",
        "540121204": "边交林乡",
        "540121205": "江热夏乡",
        "540121206": "阿朗乡",
        "540121207": "唐古乡",
        "540121208": "旁多乡"
    },
    "540122": {
        "540122100": "当曲卡镇",
        "540122101": "羊八井镇",
        "540122200": "格达乡",
        "540122201": "宁中乡",
        "540122202": "公塘乡",
        "540122203": "龙仁乡",
        "540122204": "乌玛塘乡",
        "540122205": "纳木湖乡"
    },
    "540123": {
        "540123100": "塔荣镇",
        "540123200": "麻江乡",
        "540123201": "普松乡",
        "540123202": "卡如乡",
        "540123203": "尼木乡",
        "540123204": "续迈乡",
        "540123205": "帕古乡",
        "540123206": "吞巴乡"
    },
    "540124": {
        "540124100": "曲水镇",
        "540124200": "达嘎乡",
        "540124201": "才纳乡",
        "540124202": "南木乡",
        "540124203": "聂当乡",
        "540124204": "茶巴拉乡"
    },
    "540127": {
        "540127100": "工卡镇",
        "540127200": "扎雪乡",
        "540127201": "门巴乡",
        "540127202": "扎西岗乡",
        "540127203": "日多乡",
        "540127204": "尼玛江热乡",
        "540127205": "甲玛乡",
        "540127206": "唐加乡"
    },
    "540171": {"540171400": "藏青工业园乡"},
    "540172": {"540172400": "拉萨市经济技术开发区管委会"},
    "540173": {"540173400": "西藏文化旅游创意园区"},
    "540174": {"540174400": "达孜县工业园区"},
    "540200": {
        "540202": "桑珠孜区",
        "540221": "南木林县",
        "540222": "江孜县",
        "540223": "定日县",
        "540224": "萨迦县",
        "540225": "拉孜县",
        "540226": "昂仁县",
        "540227": "谢通门县",
        "540228": "白朗县",
        "540229": "仁布县",
        "540230": "康马县",
        "540231": "定结县",
        "540232": "仲巴县",
        "540233": "亚东县",
        "540234": "吉隆县",
        "540235": "聂拉木县",
        "540236": "萨嘎县",
        "540237": "岗巴县"
    },
    "540202": {
        "540202001": "城南街道",
        "540202002": "城北街道",
        "540202200": "曲布雄乡",
        "540202201": "曲美乡",
        "540202202": "聂日雄乡",
        "540202203": "甲措雄乡",
        "540202204": "纳尔乡",
        "540202205": "东嘎乡",
        "540202206": "边雄乡",
        "540202207": "江当乡",
        "540202208": "年木乡",
        "540202209": "联乡"
    },
    "540221": {
        "540221100": "南木林镇",
        "540221200": "达那乡",
        "540221201": "卡孜乡",
        "540221202": "多角乡",
        "540221203": "秋木乡",
        "540221204": "艾玛乡",
        "540221205": "土布加乡",
        "540221206": "查尔乡",
        "540221207": "索金乡",
        "540221208": "达孜乡",
        "540221209": "奴玛乡",
        "540221210": "热当乡",
        "540221211": "拉布普乡",
        "540221212": "普当乡",
        "540221213": "仁堆乡",
        "540221214": "芒热乡",
        "540221215": "甲措乡"
    },
    "540222": {
        "540222100": "江孜镇",
        "540222200": "纳如乡",
        "540222201": "卡麦乡",
        "540222202": "卡堆乡",
        "540222203": "藏改乡",
        "540222204": "日朗乡",
        "540222205": "达孜乡",
        "540222206": "热索乡",
        "540222207": "重孜乡",
        "540222208": "龙马乡",
        "540222209": "加克西乡",
        "540222210": "紫金乡",
        "540222211": "江热乡",
        "540222212": "年雄乡",
        "540222213": "康卓乡",
        "540222214": "金嘎乡",
        "540222215": "日星乡",
        "540222216": "车仁乡",
        "540222217": "热龙乡"
    },
    "540223": {
        "540223100": "协格尔镇",
        "540223101": "岗嘎镇",
        "540223200": "扎西宗乡",
        "540223201": "绒辖乡",
        "540223202": "曲当乡",
        "540223203": "措果乡",
        "540223204": "曲洛乡",
        "540223205": "长所乡",
        "540223206": "尼辖乡",
        "540223207": "扎果乡",
        "540223208": "克玛乡",
        "540223209": "盆吉乡",
        "540223210": "加措乡"
    },
    "540224": {
        "540224100": "萨迦镇",
        "540224101": "吉定镇",
        "540224200": "雄麦乡",
        "540224201": "麻布加乡",
        "540224202": "雄玛乡",
        "540224203": "扎西岗乡",
        "540224204": "扯休乡",
        "540224205": "赛乡",
        "540224206": "拉洛乡",
        "540224207": "查荣乡",
        "540224208": "木拉乡"
    },
    "540225": {
        "540225100": "曲下镇",
        "540225101": "拉孜镇",
        "540225200": "扎西宗乡",
        "540225201": "曲玛乡",
        "540225202": "彭措林乡",
        "540225203": "扎西岗乡",
        "540225204": "柳乡",
        "540225205": "热萨乡",
        "540225206": "锡钦乡",
        "540225207": "芒普乡",
        "540225208": "查务乡"
    },
    "540226": {
        "540226100": "卡嘎镇",
        "540226101": "桑桑镇",
        "540226200": "达若乡",
        "540226201": "贡久布乡",
        "540226202": "措迈乡",
        "540226203": "雄巴乡",
        "540226204": "查孜乡",
        "540226205": "阿木雄乡",
        "540226206": "如萨乡",
        "540226207": "孔隆乡",
        "540226208": "尼果乡",
        "540226209": "日吾其乡",
        "540226210": "多白乡",
        "540226211": "切热乡",
        "540226212": "秋窝乡",
        "540226213": "达居乡",
        "540226214": "亚木乡"
    },
    "540227": {
        "540227100": "卡嘎镇",
        "540227200": "达木夏乡",
        "540227201": "查布乡",
        "540227202": "春哲乡",
        "540227203": "则许乡",
        "540227204": "娘热乡",
        "540227205": "措布西乡",
        "540227206": "纳当乡",
        "540227207": "青都乡",
        "540227208": "切琼乡",
        "540227209": "美巴切勤乡",
        "540227210": "列巴乡",
        "540227211": "塔丁乡",
        "540227212": "荣玛乡",
        "540227213": "通门乡",
        "540227214": "达那普乡",
        "540227215": "达那答乡",
        "540227216": "南木切乡",
        "540227217": "仁钦则乡"
    },
    "540228": {
        "540228100": "洛江镇",
        "540228101": "嘎东镇",
        "540228200": "巴扎乡",
        "540228201": "玛乡",
        "540228202": "旺丹乡",
        "540228203": "曲奴乡",
        "540228204": "杜琼乡",
        "540228205": "强堆乡",
        "540228206": "嘎普乡",
        "540228207": "者下乡",
        "540228208": "东喜乡"
    },
    "540229": {
        "540229100": "德吉林镇",
        "540229200": "康雄乡",
        "540229201": "普松乡",
        "540229202": "帕当乡",
        "540229203": "然巴乡",
        "540229204": "查巴乡",
        "540229205": "切娃乡",
        "540229206": "姆乡",
        "540229207": "仁布乡"
    },
    "540230": {
        "540230100": "康马镇",
        "540230200": "南尼乡",
        "540230201": "少岗乡",
        "540230202": "康如乡",
        "540230203": "萨玛达乡",
        "540230204": "嘎拉乡",
        "540230205": "涅如堆乡",
        "540230206": "涅如麦乡",
        "540230207": "雄章乡"
    },
    "540231": {
        "540231100": "江嘎镇",
        "540231101": "陈塘镇",
        "540231102": "日屋镇",
        "540231201": "确布乡",
        "540231202": "定结乡",
        "540231203": "多布扎乡",
        "540231204": "扎西岗乡",
        "540231205": "琼孜乡",
        "540231206": "萨尔乡",
        "540231207": "郭加乡"
    },
    "540232": {
        "540232100": "帕羊镇",
        "540232200": "拉让乡",
        "540232201": "琼果乡",
        "540232202": "亚热乡",
        "540232203": "布多乡",
        "540232204": "偏吉乡",
        "540232205": "纳久乡",
        "540232206": "吉拉乡",
        "540232207": "霍尔巴乡",
        "540232208": "隆格尔乡",
        "540232209": "吉玛乡",
        "540232210": "仁多乡",
        "540232211": "帕江乡"
    },
    "540233": {
        "540233100": "下司马镇",
        "540233101": "帕里镇",
        "540233200": "下亚东乡",
        "540233201": "上亚东乡",
        "540233202": "康布乡",
        "540233203": "堆纳乡",
        "540233204": "吉汝乡"
    },
    "540234": {
        "540234100": "宗嘎镇",
        "540234101": "吉隆镇",
        "540234200": "差那乡",
        "540234201": "折巴乡",
        "540234202": "贡当乡",
        "540234203": "萨勒乡"
    },
    "540235": {
        "540235100": "聂拉木镇",
        "540235101": "樟木镇",
        "540235200": "亚来乡",
        "540235201": "锁作乡",
        "540235202": "乃龙乡",
        "540235203": "门布乡",
        "540235204": "波绒乡"
    },
    "540236": {
        "540236100": "加加镇",
        "540236200": "昌果乡",
        "540236201": "雄如乡",
        "540236202": "拉藏乡",
        "540236203": "如角乡",
        "540236204": "达吉岭乡",
        "540236205": "旦嘎乡",
        "540236206": "夏如乡"
    },
    "540237": {"540237100": "岗巴镇", "540237200": "昌龙乡", "540237201": "直克乡", "540237202": "孔玛乡", "540237203": "龙中乡"},
    "540300": {
        "540302": "卡若区",
        "540321": "江达县",
        "540322": "贡觉县",
        "540323": "类乌齐县",
        "540324": "丁青县",
        "540325": "察雅县",
        "540326": "八宿县",
        "540327": "左贡县",
        "540328": "芒康县",
        "540329": "洛隆县",
        "540330": "边坝县"
    },
    "540302": {
        "540302100": "城关镇",
        "540302101": "俄洛镇",
        "540302102": "卡若镇",
        "540302200": "芒达乡",
        "540302201": "约巴乡",
        "540302202": "妥坝乡",
        "540302203": "拉多乡",
        "540302204": "面达乡",
        "540302205": "嘎玛乡",
        "540302206": "柴维乡",
        "540302207": "日通乡",
        "540302208": "如意乡",
        "540302209": "埃西乡",
        "540302210": "若巴乡",
        "540302211": "沙贡乡"
    },
    "540321": {
        "540321100": "江达镇",
        "540321101": "岗托镇",
        "540321200": "卡贡乡",
        "540321201": "岩比乡",
        "540321202": "邓柯乡",
        "540321203": "生达乡",
        "540321204": "娘西乡",
        "540321205": "字嘎乡",
        "540321206": "青泥洞乡",
        "540321207": "汪布顶乡",
        "540321208": "德登乡",
        "540321209": "同普乡",
        "540321210": "波罗乡"
    },
    "540322": {
        "540322100": "莫洛镇",
        "540322200": "相皮乡",
        "540322201": "哈加乡",
        "540322202": "雄松乡",
        "540322203": "拉妥乡",
        "540322204": "阿旺乡",
        "540322205": "木协乡",
        "540322206": "罗麦乡",
        "540322207": "沙东乡",
        "540322208": "克日乡",
        "540322209": "则巴乡",
        "540322210": "敏都乡"
    },
    "540323": {
        "540323100": "类乌齐镇",
        "540323101": "桑多镇",
        "540323200": "甲桑卡乡",
        "540323201": "长毛岭乡",
        "540323202": "岗色乡",
        "540323203": "吉多乡",
        "540323204": "宾达乡",
        "540323205": "卡玛多乡",
        "540323206": "尚卡乡",
        "540323207": "伊日乡"
    },
    "540324": {
        "540324100": "丁青镇",
        "540324101": "尺犊镇",
        "540324200": "觉恩乡",
        "540324201": "沙贡乡",
        "540324202": "当堆乡",
        "540324203": "桑多乡",
        "540324204": "木塔乡",
        "540324205": "布塔乡",
        "540324206": "巴达乡",
        "540324207": "甘岩乡",
        "540324208": "嘎塔乡",
        "540324209": "色扎乡",
        "540324210": "协雄乡"
    },
    "540325": {
        "540325100": "烟多镇",
        "540325101": "香堆镇",
        "540325102": "吉塘镇",
        "540325200": "宗沙乡",
        "540325201": "卡贡乡",
        "540325202": "荣周乡",
        "540325203": "巴日乡",
        "540325204": "阿孜乡",
        "540325205": "王卡乡",
        "540325206": "新卡乡",
        "540325207": "肯通乡",
        "540325208": "扩达乡",
        "540325209": "察拉乡"
    },
    "540326": {
        "540326100": "白玛镇",
        "540326101": "帮达镇",
        "540326102": "然乌镇",
        "540326103": "同卡镇",
        "540326202": "郭庆乡",
        "540326203": "拉根乡",
        "540326204": "益庆乡",
        "540326205": "吉中乡",
        "540326206": "卡瓦白庆乡",
        "540326207": "吉达乡",
        "540326208": "夏里乡",
        "540326209": "拥乡",
        "540326210": "瓦乡",
        "540326211": "林卡乡"
    },
    "540327": {
        "540327100": "旺达镇",
        "540327101": "田妥镇",
        "540327102": "扎玉镇",
        "540327203": "东坝乡",
        "540327204": "仁果乡",
        "540327207": "绕金乡",
        "540327208": "碧土乡",
        "540327210": "美玉乡",
        "540327211": "中林卡乡",
        "540327212": "下林卡乡"
    },
    "540328": {
        "540328100": "嘎托镇",
        "540328101": "如美镇",
        "540328200": "索多西乡",
        "540328201": "莽岭乡",
        "540328202": "宗西乡",
        "540328203": "昂多乡",
        "540328204": "措瓦乡",
        "540328205": "洛尼乡",
        "540328206": "戈波乡",
        "540328207": "帮达乡",
        "540328208": "徐中乡",
        "540328209": "曲登乡",
        "540328210": "木许乡",
        "540328211": "纳西民族乡",
        "540328212": "竹巴龙乡",
        "540328213": "曲孜卡乡"
    },
    "540329": {
        "540329100": "孜托镇",
        "540329101": "硕督镇",
        "540329111": "康沙镇",
        "540329112": "马利镇",
        "540329200": "达龙乡",
        "540329201": "新荣乡",
        "540329202": "白达乡",
        "540329203": "玉西乡",
        "540329204": "腊久乡",
        "540329205": "俄西乡",
        "540329206": "中亦乡"
    },
    "540330": {
        "540330100": "边坝镇",
        "540330101": "草卡镇",
        "540330201": "沙丁乡",
        "540330202": "金岭乡",
        "540330203": "加贡乡",
        "540330204": "马武乡",
        "540330205": "热玉乡",
        "540330206": "尼木乡",
        "540330207": "马秀乡",
        "540330208": "拉孜乡",
        "540330209": "都瓦乡"
    },
    "540400": {
        "540402": "巴宜区",
        "540421": "工布江达县",
        "540422": "米林县",
        "540423": "墨脱县",
        "540424": "波密县",
        "540425": "察隅县",
        "540426": "朗县"
    },
    "540402": {
        "540402001": "白玛岗街道",
        "540402002": "觉木街道",
        "540402100": "林芝镇",
        "540402101": "百巴镇",
        "540402102": "八一镇",
        "540402103": "鲁朗镇",
        "540402200": "更章门巴民族乡",
        "540402202": "布久乡",
        "540402203": "米瑞乡"
    },
    "540421": {
        "540421100": "工布江达镇",
        "540421101": "金达镇",
        "540421102": "巴河镇",
        "540421200": "朱拉乡",
        "540421201": "错高乡",
        "540421202": "仲莎乡",
        "540421203": "江达乡",
        "540421204": "娘蒲乡",
        "540421205": "加兴乡"
    },
    "540422": {
        "540422100": "米林镇",
        "540422101": "派镇",
        "540422102": "卧龙镇",
        "540422200": "丹娘乡",
        "540422201": "南伊珞巴民族乡",
        "540422202": "扎西绕登乡",
        "540422203": "里龙乡",
        "540422204": "羌纳乡"
    },
    "540423": {
        "540423100": "墨脱镇",
        "540423200": "加热萨乡",
        "540423201": "甘登乡",
        "540423202": "达木珞巴民族乡",
        "540423203": "帮辛乡",
        "540423204": "格当乡",
        "540423205": "德兴乡",
        "540423206": "背崩乡"
    },
    "540424": {
        "540424100": "扎木镇",
        "540424101": "倾多镇",
        "540424102": "松宗镇",
        "540424200": "易贡乡",
        "540424201": "玉普乡",
        "540424202": "康玉乡",
        "540424203": "多吉乡",
        "540424204": "玉许乡",
        "540424205": "八盖乡",
        "540424207": "古乡"
    },
    "540425": {
        "540425100": "竹瓦根镇",
        "540425101": "上察隅镇",
        "540425102": "下察隅镇",
        "540425200": "察瓦龙乡",
        "540425201": "古拉乡",
        "540425202": "古玉乡"
    },
    "540426": {
        "540426100": "朗镇",
        "540426101": "仲达镇",
        "540426102": "洞嘎镇",
        "540426200": "拉多乡",
        "540426201": "金东乡",
        "540426202": "登木乡"
    },
    "540500": {
        "540502": "乃东区",
        "540521": "扎囊县",
        "540522": "贡嘎县",
        "540523": "桑日县",
        "540524": "琼结县",
        "540525": "曲松县",
        "540526": "措美县",
        "540527": "洛扎县",
        "540528": "加查县",
        "540529": "隆子县",
        "540530": "错那县",
        "540531": "浪卡子县"
    },
    "540502": {
        "540502100": "泽当镇",
        "540502101": "昌珠镇",
        "540502200": "亚堆乡",
        "540502201": "索珠乡",
        "540502202": "多颇章乡",
        "540502203": "结巴乡",
        "540502204": "颇章乡"
    },
    "540521": {"540521100": "扎塘镇", "540521101": "桑耶镇", "540521200": "扎其乡", "540521201": "阿扎乡", "540521202": "吉汝乡"},
    "540522": {
        "540522100": "吉雄镇",
        "540522101": "甲竹林镇",
        "540522102": "杰德秀镇",
        "540522103": "岗堆镇",
        "540522104": "江塘镇",
        "540522200": "朗杰学乡",
        "540522201": "昌果乡",
        "540522205": "东拉乡",
        "540522206": "克西乡"
    },
    "540523": {"540523100": "桑日镇", "540523200": "增期乡", "540523201": "白堆乡", "540523202": "绒乡"},
    "540524": {"540524100": "琼结镇", "540524200": "加麻乡", "540524201": "下水乡", "540524202": "拉玉乡"},
    "540525": {"540525100": "曲松镇", "540525101": "罗布沙镇", "540525200": "下江乡", "540525201": "邱多江乡", "540525202": "堆随乡"},
    "540526": {"540526100": "措美镇", "540526101": "哲古镇", "540526200": "乃西乡", "540526201": "古堆乡"},
    "540527": {
        "540527100": "洛扎镇",
        "540527101": "拉康镇",
        "540527201": "扎日乡",
        "540527202": "色乡",
        "540527203": "生格乡",
        "540527204": "边巴乡",
        "540527205": "拉郊乡"
    },
    "540528": {
        "540528100": "加查镇",
        "540528101": "安绕镇",
        "540528200": "拉绥乡",
        "540528201": "崔久乡",
        "540528202": "坝乡",
        "540528203": "冷达乡",
        "540528204": "洛林乡"
    },
    "540529": {
        "540529100": "隆子镇",
        "540529101": "日当镇",
        "540529200": "列麦乡",
        "540529201": "热荣乡",
        "540529202": "三安曲林乡",
        "540529203": "准巴乡",
        "540529204": "雪萨乡",
        "540529205": "扎日乡",
        "540529206": "玉麦乡",
        "540529207": "加玉乡",
        "540529208": "斗玉珞巴民族乡"
    },
    "540530": {
        "540530100": "错那镇",
        "540530200": "卡达乡",
        "540530201": "觉拉乡",
        "540530202": "浪坡乡",
        "540530203": "曲卓木乡",
        "540530204": "库局乡",
        "540530205": "麻麻门巴民族乡",
        "540530206": "贡日门巴民族乡",
        "540530207": "吉巴门巴民族乡",
        "540530208": "勒门巴民族乡"
    },
    "540531": {
        "540531100": "浪卡子镇",
        "540531101": "打隆镇",
        "540531200": "张达乡",
        "540531201": "伦布雪乡",
        "540531202": "多却乡",
        "540531203": "普玛江塘乡",
        "540531204": "阿扎乡",
        "540531205": "卡龙乡",
        "540531206": "白地乡",
        "540531207": "卡热乡"
    },
    "540600": {
        "540602": "色尼区",
        "540621": "嘉黎县",
        "540622": "比如县",
        "540623": "聂荣县",
        "540624": "安多县",
        "540625": "申扎县",
        "540626": "索县",
        "540627": "班戈县",
        "540628": "巴青县",
        "540629": "尼玛县",
        "540630": "双湖县"
    },
    "540602": {
        "540602100": "那曲镇",
        "540602101": "罗玛镇",
        "540602102": "古露镇",
        "540602200": "达萨乡",
        "540602202": "油恰乡",
        "540602203": "香茂乡",
        "540602205": "那么切乡",
        "540602209": "达前乡",
        "540602210": "劳麦乡",
        "540602211": "孔玛乡",
        "540602213": "尼玛乡",
        "540602217": "色雄乡"
    },
    "540621": {
        "540621100": "阿扎镇",
        "540621101": "嘉黎镇",
        "540621201": "忠玉乡",
        "540621202": "藏比乡",
        "540621203": "措多乡",
        "540621204": "夏玛乡",
        "540621206": "林堤乡",
        "540621207": "措拉乡",
        "540621209": "绒多乡",
        "540621210": "鸽群乡"
    },
    "540622": {
        "540622100": "比如镇",
        "540622101": "夏曲镇",
        "540622201": "白嘎乡",
        "540622202": "达塘乡",
        "540622203": "恰则乡",
        "540622204": "扎拉乡",
        "540622207": "羊秀乡",
        "540622208": "香曲乡",
        "540622209": "良曲乡",
        "540622210": "茶曲乡"
    },
    "540623": {
        "540623100": "聂荣镇",
        "540623201": "尼玛乡",
        "540623203": "色庆乡",
        "540623205": "桑荣乡",
        "540623206": "下曲乡",
        "540623207": "白雄乡",
        "540623208": "索雄乡",
        "540623210": "当木江乡",
        "540623211": "查当乡",
        "540623212": "永曲乡"
    },
    "540624": {
        "540624100": "帕那镇",
        "540624101": "强玛镇",
        "540624102": "扎仁镇",
        "540624103": "雁石坪镇",
        "540624201": "多玛乡",
        "540624202": "玛曲乡",
        "540624205": "滩堆乡",
        "540624207": "帮爱乡",
        "540624209": "玛荣乡",
        "540624210": "扎曲乡",
        "540624211": "色务乡",
        "540624212": "措玛乡",
        "540624213": "岗尼乡"
    },
    "540625": {
        "540625100": "申扎镇",
        "540625101": "雄梅镇",
        "540625201": "下过乡",
        "540625202": "卡乡",
        "540625203": "巴扎乡",
        "540625204": "塔尔玛乡",
        "540625206": "买巴乡",
        "540625207": "马跃乡"
    },
    "540626": {
        "540626100": "亚拉镇",
        "540626101": "荣布镇",
        "540626201": "若达乡",
        "540626202": "加勤乡",
        "540626203": "赤多乡",
        "540626205": "西昌乡",
        "540626206": "江达乡",
        "540626208": "热瓦乡",
        "540626209": "嘎美乡",
        "540626210": "嘎木乡"
    },
    "540627": {
        "540627100": "普保镇",
        "540627101": "北拉镇",
        "540627102": "德庆镇",
        "540627103": "佳琼镇",
        "540627205": "尼玛乡",
        "540627207": "保吉乡",
        "540627209": "青龙乡",
        "540627211": "马前乡",
        "540627215": "门当乡",
        "540627216": "新吉乡"
    },
    "540628": {
        "540628100": "拉西镇",
        "540628101": "杂色镇",
        "540628102": "雅安镇",
        "540628201": "江绵乡",
        "540628202": "玛如乡",
        "540628204": "阿秀乡",
        "540628205": "贡日乡",
        "540628206": "岗切乡",
        "540628210": "巴青乡",
        "540628211": "本塔乡"
    },
    "540629": {
        "540629100": "尼玛镇",
        "540629201": "卓尼乡",
        "540629202": "达果乡",
        "540629203": "阿索乡",
        "540629204": "荣玛乡",
        "540629205": "中仓乡",
        "540629206": "来多乡",
        "540629207": "申亚乡",
        "540629208": "卓瓦乡",
        "540629209": "俄久乡",
        "540629210": "文部乡",
        "540629211": "甲谷乡",
        "540629212": "军仓乡",
        "540629219": "吉瓦乡"
    },
    "540630": {
        "540630101": "措折罗玛镇",
        "540630200": "协德乡",
        "540630201": "雅曲乡",
        "540630202": "嘎措乡",
        "540630203": "措折强玛乡",
        "540630204": "多玛乡",
        "540630205": "巴岭乡"
    },
    "542500": {
        "542521": "普兰县",
        "542522": "札达县",
        "542523": "噶尔县",
        "542524": "日土县",
        "542525": "革吉县",
        "542526": "改则县",
        "542527": "措勤县"
    },
    "542521": {"542521100": "普兰镇", "542521200": "巴嘎乡", "542521201": "霍尔乡"},
    "542522": {
        "542522100": "托林镇",
        "542522200": "萨让乡",
        "542522201": "达巴乡",
        "542522202": "底雅乡",
        "542522203": "香孜乡",
        "542522204": "曲松乡",
        "542522205": "楚鲁松杰乡"
    },
    "542523": {"542523100": "狮泉河镇", "542523200": "昆莎乡", "542523201": "左左乡", "542523202": "门士乡", "542523203": "扎西岗乡"},
    "542524": {"542524100": "日土镇", "542524200": "热帮乡", "542524201": "日松乡", "542524202": "东汝乡", "542524203": "多玛乡"},
    "542525": {"542525100": "革吉镇", "542525200": "雄巴乡", "542525201": "亚热乡", "542525202": "盐湖乡", "542525203": "文布当桑乡"},
    "542526": {
        "542526100": "改则镇",
        "542526200": "物玛乡",
        "542526201": "先遣乡",
        "542526202": "麻米乡",
        "542526203": "洞措乡",
        "542526204": "古姆乡",
        "542526205": "察布乡"
    },
    "542527": {"542527100": "措勤镇", "542527200": "磁石乡", "542527201": "曲洛乡", "542527202": "江让乡", "542527203": "达雄乡"},
    "610000": {
        "610100": "西安市",
        "610200": "铜川市",
        "610300": "宝鸡市",
        "610400": "咸阳市",
        "610500": "渭南市",
        "610600": "延安市",
        "610700": "汉中市",
        "610800": "榆林市",
        "610900": "安康市",
        "611000": "商洛市"
    },
    "610100": {
        "610102": "新城区",
        "610103": "碑林区",
        "610104": "莲湖区",
        "610111": "灞桥区",
        "610112": "未央区",
        "610113": "雁塔区",
        "610114": "阎良区",
        "610115": "临潼区",
        "610116": "长安区",
        "610117": "高陵区",
        "610118": "鄠邑区",
        "610122": "蓝田县",
        "610124": "周至县"
    },
    "610102": {
        "610102001": "西一路街道",
        "610102002": "长乐中路街道",
        "610102003": "中山门街道",
        "610102004": "韩森寨街道",
        "610102005": "解放门街道",
        "610102006": "自强路街道",
        "610102007": "太华路街道",
        "610102008": "长乐西路街道",
        "610102009": "胡家庙街道"
    },
    "610103": {
        "610103001": "南院门街道",
        "610103002": "柏树林街道",
        "610103003": "长乐坊街道",
        "610103004": "东关南街街道",
        "610103005": "太乙路街道",
        "610103006": "文艺路街道",
        "610103007": "长安路街道",
        "610103008": "张家村街道"
    },
    "610104": {
        "610104001": "青年路街道",
        "610104002": "北院门街道",
        "610104003": "北关街道",
        "610104004": "红庙坡街道",
        "610104005": "环城西路街道",
        "610104006": "西关街道",
        "610104007": "土门街道",
        "610104008": "桃园路街道",
        "610104009": "枣园街道"
    },
    "610111": {
        "610111001": "纺织城街道",
        "610111002": "十里铺街道",
        "610111003": "红旗街道",
        "610111004": "席王街道",
        "610111005": "洪庆街道",
        "610111006": "狄寨街道",
        "610111007": "灞桥街道",
        "610111008": "新筑街道",
        "610111009": "新合街道"
    },
    "610112": {
        "610112001": "张家堡街道",
        "610112002": "三桥街道",
        "610112003": "辛家庙街道",
        "610112004": "徐家湾街道",
        "610112005": "大明宫街道",
        "610112006": "谭家街道",
        "610112007": "草滩街道",
        "610112008": "六村堡街道",
        "610112009": "未央宫街道",
        "610112010": "汉城街道",
        "610112011": "未央湖街道",
        "610112012": "建章路街道"
    },
    "610113": {
        "610113001": "小寨路街道",
        "610113002": "大雁塔街道",
        "610113003": "长延堡街道",
        "610113004": "电子城街道",
        "610113005": "等驾坡街道",
        "610113006": "鱼化寨街道",
        "610113007": "丈八沟街道",
        "610113008": "曲江街道",
        "610113009": "杜城街道",
        "610113010": "漳浒寨街道"
    },
    "610114": {
        "610114001": "凤凰路街道",
        "610114002": "新华路街道",
        "610114003": "振兴街道",
        "610114004": "新兴街道",
        "610114005": "北屯街道",
        "610114100": "武屯镇",
        "610114101": "关山镇"
    },
    "610115": {
        "610115001": "骊山街道",
        "610115002": "秦陵街道",
        "610115003": "新丰街道",
        "610115004": "代王街道",
        "610115005": "斜口街道",
        "610115006": "行者街道",
        "610115007": "零口街道",
        "610115008": "相桥街道",
        "610115009": "雨金街道",
        "610115010": "新市街道",
        "610115011": "徐杨街道",
        "610115012": "西泉街道",
        "610115013": "栎阳街道",
        "610115014": "马额街道",
        "610115015": "何寨街道",
        "610115016": "交口街道",
        "610115017": "油槐街道",
        "610115018": "北田街道",
        "610115019": "铁炉街道",
        "610115020": "任留街道",
        "610115021": "穆寨街道",
        "610115022": "小金街道",
        "610115023": "仁宗街道"
    },
    "610116": {
        "610116001": "韦曲街道",
        "610116002": "郭杜街道",
        "610116003": "滦镇街道",
        "610116004": "引镇街道",
        "610116005": "王寺街道",
        "610116006": "马王街道",
        "610116007": "太乙宫街道",
        "610116008": "东大街道",
        "610116009": "子午街道",
        "610116010": "斗门街道",
        "610116011": "细柳街道",
        "610116012": "杜曲街道",
        "610116013": "大兆街道",
        "610116014": "黄良街道",
        "610116015": "兴隆街道",
        "610116016": "王曲街道",
        "610116017": "鸣犊街道",
        "610116018": "王莽街道",
        "610116019": "五台街道",
        "610116020": "高桥街道",
        "610116021": "五星街道",
        "610116022": "灵沼街道",
        "610116023": "杨庄街道",
        "610116024": "砲里街道",
        "610116025": "魏寨街道"
    },
    "610117": {
        "610117001": "鹿苑街道",
        "610117002": "泾渭街道",
        "610117003": "崇皇街道",
        "610117004": "姬家街道",
        "610117005": "耿镇街道",
        "610117006": "张卜街道",
        "610117007": "通远街道"
    },
    "610118": {
        "610118001": "甘亭街道办",
        "610118101": "余下镇",
        "610118102": "祖庵镇",
        "610118103": "秦渡镇",
        "610118104": "大王镇",
        "610118105": "草堂镇",
        "610118106": "蒋村镇",
        "610118107": "庞光镇",
        "610118108": "涝店镇",
        "610118109": "甘河镇",
        "610118110": "石井镇",
        "610118111": "玉蝉镇",
        "610118112": "五竹镇",
        "610118114": "渭丰镇"
    },
    "610122": {
        "610122001": "蓝关街道",
        "610122101": "洩湖镇",
        "610122102": "华胥镇",
        "610122103": "前卫镇",
        "610122104": "汤峪镇",
        "610122105": "焦岱镇",
        "610122106": "玉山镇",
        "610122107": "三里镇",
        "610122108": "普化镇",
        "610122109": "葛牌镇",
        "610122110": "灞源镇",
        "610122111": "九间房镇",
        "610122112": "蓝桥镇",
        "610122113": "辋川镇",
        "610122115": "厚镇",
        "610122116": "三官庙镇",
        "610122118": "安村镇",
        "610122119": "孟村镇",
        "610122120": "小寨镇"
    },
    "610124": {
        "610124001": "二曲街道",
        "610124101": "哑柏镇",
        "610124102": "终南镇",
        "610124103": "马召镇",
        "610124104": "集贤镇",
        "610124105": "楼观镇",
        "610124106": "尚村镇",
        "610124107": "广济镇",
        "610124108": "厚畛子镇",
        "610124109": "青化镇",
        "610124110": "竹峪镇",
        "610124111": "翠峰镇",
        "610124112": "四屯镇",
        "610124115": "司竹镇",
        "610124116": "九峰镇",
        "610124117": "富仁镇",
        "610124118": "骆峪镇",
        "610124119": "陈河镇",
        "610124120": "板房子镇",
        "610124121": "王家河镇"
    },
    "610200": {"610202": "王益区", "610203": "印台区", "610204": "耀州区", "610222": "宜君县"},
    "610202": {
        "610202001": "七一路街道",
        "610202002": "红旗街道",
        "610202003": "桃园街道",
        "610202004": "青年路街道",
        "610202005": "王家河街道",
        "610202006": "王益街道",
        "610202100": "黄堡镇"
    },
    "610203": {
        "610203001": "城关街道",
        "610203002": "三里洞街道",
        "610203003": "王石凹街道",
        "610203004": "印台街道",
        "610203100": "陈炉镇",
        "610203101": "红土镇",
        "610203102": "广阳镇",
        "610203104": "金锁关镇",
        "610203106": "阿庄镇"
    },
    "610204": {
        "610204001": "永安路街道",
        "610204002": "天宝路街道",
        "610204003": "咸丰路街道",
        "610204004": "正阳路街道",
        "610204005": "锦阳路街道",
        "610204006": "坡头街道",
        "610204101": "董家河镇",
        "610204102": "庙湾镇",
        "610204103": "瑶曲镇",
        "610204104": "照金镇",
        "610204107": "小丘镇",
        "610204108": "孙原镇",
        "610204109": "关庄镇",
        "610204111": "石柱镇"
    },
    "610222": {
        "610222001": "宜阳街道",
        "610222101": "彭镇",
        "610222102": "五里镇",
        "610222103": "太安镇",
        "610222104": "棋盘镇",
        "610222105": "尧生镇",
        "610222106": "哭泉镇",
        "610222201": "云梦乡"
    },
    "610300": {
        "610302": "渭滨区",
        "610303": "金台区",
        "610304": "陈仓区",
        "610322": "凤翔县",
        "610323": "岐山县",
        "610324": "扶风县",
        "610326": "眉县",
        "610327": "陇县",
        "610328": "千阳县",
        "610329": "麟游县",
        "610330": "凤县",
        "610331": "太白县"
    },
    "610302": {
        "610302001": "金陵街道",
        "610302002": "经二路街道",
        "610302003": "清姜街道",
        "610302004": "姜谭街道",
        "610302005": "桥南街道",
        "610302100": "马营镇",
        "610302101": "石鼓镇",
        "610302102": "神农镇",
        "610302103": "高家镇",
        "610302104": "八鱼镇"
    },
    "610303": {
        "610303001": "中山东路街道",
        "610303002": "西关街道",
        "610303003": "中山西路街道",
        "610303004": "群众路街道",
        "610303006": "东风路街道",
        "610303007": "十里铺街道",
        "610303008": "卧龙寺街道",
        "610303100": "陈仓镇",
        "610303101": "蟠龙镇",
        "610303102": "金河镇",
        "610303103": "硖石镇"
    },
    "610304": {
        "610304001": "虢镇街道",
        "610304002": "东关街道",
        "610304003": "千渭街道",
        "610304101": "阳平镇",
        "610304102": "千河镇",
        "610304103": "磻溪镇",
        "610304104": "天王镇",
        "610304105": "慕仪镇",
        "610304106": "周原镇",
        "610304107": "贾村镇",
        "610304109": "县功镇",
        "610304110": "新街镇",
        "610304111": "坪头镇",
        "610304112": "香泉镇",
        "610304113": "赤沙镇",
        "610304114": "拓石镇",
        "610304115": "凤阁岭镇",
        "610304116": "钓渭镇"
    },
    "610322": {
        "610322100": "城关镇",
        "610322101": "虢王镇",
        "610322102": "彪角镇",
        "610322103": "横水镇",
        "610322104": "田家庄镇",
        "610322105": "糜杆桥镇",
        "610322107": "南指挥镇",
        "610322108": "陈村镇",
        "610322109": "长青镇",
        "610322110": "柳林镇",
        "610322111": "姚家沟镇",
        "610322112": "范家寨镇"
    },
    "610323": {
        "610323100": "凤鸣镇",
        "610323101": "蔡家坡镇",
        "610323103": "益店镇",
        "610323104": "蒲村镇",
        "610323106": "青化镇",
        "610323107": "枣林镇",
        "610323108": "雍川镇",
        "610323111": "故郡镇",
        "610323112": "京当镇"
    },
    "610324": {
        "610324001": "城关街道",
        "610324101": "天度镇",
        "610324102": "午井镇",
        "610324103": "绛帐镇",
        "610324104": "段家镇",
        "610324105": "杏林镇",
        "610324106": "召公镇",
        "610324107": "法门镇"
    },
    "610326": {
        "610326001": "首善街道",
        "610326101": "横渠镇",
        "610326102": "槐芽镇",
        "610326103": "汤峪镇",
        "610326104": "常兴镇",
        "610326105": "金渠镇",
        "610326106": "营头镇",
        "610326107": "齐镇",
        "610326400": "陕西省太白山旅游区",
        "610326401": "红河谷森林公园"
    },
    "610327": {
        "610327100": "城关镇",
        "610327101": "东风镇",
        "610327102": "八渡镇",
        "610327104": "东南镇",
        "610327106": "温水镇",
        "610327107": "天成镇",
        "610327108": "曹家湾镇",
        "610327109": "固关镇",
        "610327112": "河北镇",
        "610327113": "新集川镇"
    },
    "610328": {
        "610328100": "城关镇",
        "610328101": "崔家头镇",
        "610328102": "南寨镇",
        "610328103": "张家塬镇",
        "610328104": "水沟镇",
        "610328105": "草碧镇",
        "610328107": "高崖镇"
    },
    "610329": {
        "610329100": "九成宫镇",
        "610329101": "崔木镇",
        "610329102": "招贤镇",
        "610329103": "两亭镇",
        "610329105": "常丰镇",
        "610329106": "丈八镇",
        "610329107": "酒房镇"
    },
    "610330": {
        "610330100": "双石铺镇",
        "610330101": "凤州镇",
        "610330102": "黄牛铺镇",
        "610330103": "红花铺镇",
        "610330104": "河口镇",
        "610330105": "唐藏镇",
        "610330106": "平木镇",
        "610330107": "坪坎镇",
        "610330110": "留凤关镇",
        "610330400": "马头滩林业局",
        "610330401": "辛家山林业场"
    },
    "610331": {
        "610331100": "咀头镇",
        "610331101": "桃川镇",
        "610331102": "鹦鸽镇",
        "610331103": "靖口镇",
        "610331104": "太白河镇",
        "610331105": "黄柏塬镇",
        "610331106": "王家堎镇"
    },
    "610400": {
        "610402": "秦都区",
        "610403": "杨陵区",
        "610404": "渭城区",
        "610422": "三原县",
        "610423": "泾阳县",
        "610424": "乾县",
        "610425": "礼泉县",
        "610426": "永寿县",
        "610428": "长武县",
        "610429": "旬邑县",
        "610430": "淳化县",
        "610431": "武功县",
        "610481": "兴平市",
        "610482": "彬州市"
    },
    "610402": {
        "610402001": "人民路街道",
        "610402002": "西兰路街道",
        "610402003": "吴家堡街道",
        "610402004": "渭阳西路街道",
        "610402005": "陈杨寨街道",
        "610402006": "古渡街道",
        "610402007": "沣东街道",
        "610402008": "钓台街道",
        "610402009": "马泉街道",
        "610402010": "渭滨街道",
        "610402011": "双照街道",
        "610402012": "马庄街道"
    },
    "610403": {"610403001": "杨陵街道", "610403002": "李台街道", "610403003": "大寨街道", "610403100": "五泉镇", "610403102": "揉谷镇"},
    "610404": {
        "610404001": "中山街街道",
        "610404002": "文汇路街道",
        "610404003": "新兴路街道",
        "610404004": "渭阳街道",
        "610404005": "渭城街道",
        "610404006": "窑店街道",
        "610404007": "正阳街道",
        "610404008": "周陵街道",
        "610404009": "底张街道",
        "610404010": "北杜街道"
    },
    "610422": {
        "610422001": "城关街道",
        "610422102": "陂西镇",
        "610422103": "独李镇",
        "610422104": "大程镇",
        "610422105": "西阳镇",
        "610422106": "鲁桥镇",
        "610422107": "陵前镇",
        "610422109": "新兴镇",
        "610422110": "嵯峨镇",
        "610422111": "渠岸镇"
    },
    "610423": {
        "610423001": "泾干街道",
        "610423101": "永乐镇",
        "610423102": "云阳镇",
        "610423103": "桥底镇",
        "610423104": "王桥镇",
        "610423105": "口镇",
        "610423107": "三渠镇",
        "610423108": "高庄镇",
        "610423111": "太平镇",
        "610423112": "崇文镇",
        "610423113": "安吴镇",
        "610423114": "兴隆镇",
        "610423115": "中张镇"
    },
    "610424": {
        "610424001": "城关街道",
        "610424101": "薛录镇",
        "610424102": "梁村镇",
        "610424103": "临平镇",
        "610424104": "姜村镇",
        "610424105": "王村镇",
        "610424106": "马连镇",
        "610424107": "阳峪镇",
        "610424108": "峰阳镇",
        "610424109": "注泔镇",
        "610424110": "灵源镇",
        "610424111": "阳洪镇",
        "610424112": "梁山镇",
        "610424113": "周城镇",
        "610424114": "新阳镇",
        "610424115": "大杨镇"
    },
    "610425": {
        "610425001": "城关街道",
        "610425101": "史德镇",
        "610425102": "西张堡镇",
        "610425103": "阡东镇",
        "610425104": "烽火镇",
        "610425105": "烟霞镇",
        "610425106": "赵镇",
        "610425108": "叱干镇",
        "610425109": "南坊镇",
        "610425110": "石潭镇",
        "610425111": "昭陵镇",
        "610425112": "骏马镇"
    },
    "610426": {
        "610426001": "监军街道",
        "610426101": "店头镇",
        "610426102": "常宁镇",
        "610426104": "甘井镇",
        "610426105": "马坊镇",
        "610426108": "渠子镇",
        "610426110": "永平镇"
    },
    "610428": {
        "610428001": "昭仁街道",
        "610428101": "相公镇",
        "610428102": "巨家镇",
        "610428103": "丁家镇",
        "610428104": "洪家镇",
        "610428105": "亭口镇",
        "610428106": "彭公镇",
        "610428108": "枣园镇"
    },
    "610429": {
        "610429001": "城关街道",
        "610429101": "土桥镇",
        "610429102": "职田镇",
        "610429103": "张洪镇",
        "610429104": "太村镇",
        "610429105": "郑家镇",
        "610429106": "湫坡头镇",
        "610429107": "底庙镇",
        "610429109": "马栏镇",
        "610429110": "清塬镇"
    },
    "610430": {
        "610430001": "城关街道",
        "610430101": "官庄镇",
        "610430103": "方里镇",
        "610430104": "润镇",
        "610430105": "车坞镇",
        "610430106": "铁王镇",
        "610430107": "石桥镇",
        "610430109": "十里塬镇"
    },
    "610431": {
        "610431001": "普集街道",
        "610431101": "苏坊镇",
        "610431102": "武功镇",
        "610431103": "游凤镇",
        "610431104": "贞元镇",
        "610431105": "长宁镇",
        "610431106": "小村镇",
        "610431107": "大庄镇"
    },
    "610481": {
        "610481001": "东城街道",
        "610481002": "西城街道",
        "610481003": "店张街道",
        "610481004": "西吴街道",
        "610481005": "马嵬街道",
        "610481101": "赵村镇",
        "610481102": "桑镇",
        "610481104": "南市镇",
        "610481105": "庄头镇",
        "610481106": "南位镇",
        "610481107": "阜寨镇",
        "610481108": "丰仪镇",
        "610481109": "汤坊镇"
    },
    "610482": {
        "610482001": "城关街道",
        "610482101": "北极镇",
        "610482102": "新民镇",
        "610482103": "龙高镇",
        "610482105": "永乐镇",
        "610482106": "义门镇",
        "610482107": "水口镇",
        "610482108": "韩家镇",
        "610482109": "太峪镇"
    },
    "610500": {
        "610502": "临渭区",
        "610503": "华州区",
        "610522": "潼关县",
        "610523": "大荔县",
        "610524": "合阳县",
        "610525": "澄城县",
        "610526": "蒲城县",
        "610527": "白水县",
        "610528": "富平县",
        "610581": "韩城市",
        "610582": "华阴市"
    },
    "610502": {
        "610502001": "人民街道",
        "610502002": "杜桥街道",
        "610502003": "解放街道",
        "610502004": "向阳街道",
        "610502005": "站南街道",
        "610502006": "双王街道",
        "610502007": "良田街道",
        "610502008": "崇业路街道",
        "610502009": "辛市街道",
        "610502010": "龙背街道",
        "610502011": "阳曲街道",
        "610502012": "信义街道",
        "610502013": "白杨街道",
        "610502100": "桥南镇",
        "610502101": "阳郭镇",
        "610502102": "故市镇",
        "610502103": "下邽镇",
        "610502104": "三张镇",
        "610502105": "交斜镇",
        "610502107": "崇宁镇",
        "610502108": "孝义镇",
        "610502109": "吝店镇",
        "610502111": "官底镇",
        "610502112": "官路镇",
        "610502113": "丰原镇",
        "610502114": "阎村镇",
        "610502116": "官道镇"
    },
    "610503": {
        "610503001": "华州街道",
        "610503101": "杏林镇",
        "610503102": "赤水镇",
        "610503103": "高塘镇",
        "610503104": "大明镇",
        "610503105": "瓜坡镇",
        "610503106": "莲花寺镇",
        "610503107": "柳枝镇",
        "610503108": "下庙镇",
        "610503109": "金堆镇"
    },
    "610522": {"610522001": "城关街道", "610522101": "秦东镇", "610522102": "太要镇", "610522103": "桐峪镇", "610522104": "代字营镇"},
    "610523": {
        "610523001": "西城街道",
        "610523002": "东城街道",
        "610523101": "许庄镇",
        "610523102": "朝邑镇",
        "610523103": "安仁镇",
        "610523104": "两宜镇",
        "610523105": "羌白镇",
        "610523106": "官池镇",
        "610523107": "冯村镇",
        "610523108": "双泉镇",
        "610523110": "下寨镇",
        "610523111": "韦林镇",
        "610523112": "范家镇",
        "610523113": "苏村镇",
        "610523114": "赵渡镇",
        "610523116": "埝桥镇",
        "610523117": "段家镇",
        "610523400": "国营陕西荔北企业公司",
        "610523401": "国营陕西沙苑企业公司",
        "610523402": "国营陕西黄河企业公司"
    },
    "610524": {
        "610524001": "城关街道",
        "610524101": "甘井镇",
        "610524102": "坊镇",
        "610524103": "洽川镇",
        "610524104": "新池镇",
        "610524105": "黑池镇",
        "610524106": "路井镇",
        "610524107": "和家庄镇",
        "610524108": "王村镇",
        "610524110": "同家庄镇",
        "610524111": "百良镇",
        "610524112": "金峪镇"
    },
    "610525": {
        "610525001": "城关街道",
        "610525101": "冯原镇",
        "610525102": "王庄镇",
        "610525103": "尧头镇",
        "610525104": "赵庄镇",
        "610525105": "交道镇",
        "610525106": "寺前镇",
        "610525107": "韦庄镇",
        "610525108": "安里镇",
        "610525109": "庄头镇"
    },
    "610526": {
        "610526001": "奉先街道",
        "610526002": "紫荆街道",
        "610526101": "罕井镇",
        "610526102": "孙镇",
        "610526103": "兴镇",
        "610526104": "党睦镇",
        "610526105": "高阳镇",
        "610526106": "永丰镇",
        "610526107": "荆姚镇",
        "610526108": "苏坊镇",
        "610526109": "龙阳镇",
        "610526112": "洛滨镇",
        "610526113": "陈庄镇",
        "610526114": "桥陵镇",
        "610526116": "尧山镇",
        "610526117": "椿林镇",
        "610526118": "龙池镇"
    },
    "610527": {
        "610527001": "城关街道",
        "610527102": "尧禾镇",
        "610527103": "杜康镇",
        "610527104": "西固镇",
        "610527105": "林皋镇",
        "610527106": "史官镇",
        "610527107": "北塬镇",
        "610527109": "雷牙镇"
    },
    "610528": {
        "610528001": "城关街道",
        "610528101": "庄里镇",
        "610528102": "张桥镇",
        "610528103": "美原镇",
        "610528104": "流曲镇",
        "610528105": "淡村镇",
        "610528107": "留古镇",
        "610528108": "老庙镇",
        "610528109": "薛镇",
        "610528110": "到贤镇",
        "610528111": "曹村镇",
        "610528112": "宫里镇",
        "610528113": "梅家坪镇",
        "610528114": "刘集镇",
        "610528115": "齐村镇"
    },
    "610581": {
        "610581001": "新城街道",
        "610581002": "金城街道",
        "610581100": "龙门镇",
        "610581101": "桑树坪镇",
        "610581103": "芝川镇",
        "610581104": "西庄镇",
        "610581106": "芝阳镇",
        "610581107": "板桥镇"
    },
    "610582": {
        "610582001": "太华路街道",
        "610582002": "岳庙街道",
        "610582100": "孟塬镇",
        "610582103": "华西镇",
        "610582104": "罗敷镇",
        "610582105": "华山镇",
        "610582400": "国营陕西华山企业公司"
    },
    "610600": {
        "610602": "宝塔区",
        "610603": "安塞区",
        "610621": "延长县",
        "610622": "延川县",
        "610623": "子长县",
        "610625": "志丹县",
        "610626": "吴起县",
        "610627": "甘泉县",
        "610628": "富县",
        "610629": "洛川县",
        "610630": "宜川县",
        "610631": "黄龙县",
        "610632": "黄陵县"
    },
    "610602": {
        "610602001": "宝塔山街道",
        "610602002": "南市街道",
        "610602003": "凤凰山街道",
        "610602004": "枣园街道",
        "610602005": "桥沟街道",
        "610602102": "河庄坪镇",
        "610602103": "李渠镇",
        "610602104": "姚店镇",
        "610602105": "青化砭镇",
        "610602106": "蟠龙镇",
        "610602107": "柳林镇",
        "610602108": "南泥湾镇",
        "610602109": "临镇",
        "610602110": "甘谷驿镇",
        "610602200": "川口乡",
        "610602202": "冯庄乡",
        "610602205": "麻洞川乡",
        "610602208": "万花山乡"
    },
    "610603": {
        "610603001": "真武洞街道",
        "610603002": "金明街道",
        "610603003": "白坪街道",
        "610603101": "砖窑湾镇",
        "610603102": "沿河湾镇",
        "610603103": "招安镇",
        "610603104": "化子坪镇",
        "610603105": "坪桥镇",
        "610603106": "建华镇",
        "610603107": "高桥镇",
        "610603108": "镰刀湾镇"
    },
    "610621": {
        "610621001": "七里村街道",
        "610621101": "黑家堡镇",
        "610621102": "郑庄镇",
        "610621103": "张家滩镇",
        "610621104": "交口镇",
        "610621106": "雷赤镇",
        "610621107": "罗子山镇",
        "610621108": "安沟镇"
    },
    "610622": {
        "610622001": "大禹街道",
        "610622101": "永坪镇",
        "610622102": "延水关镇",
        "610622103": "文安驿镇",
        "610622104": "杨家圪台镇",
        "610622105": "贾家坪镇",
        "610622107": "关庄镇",
        "610622108": "乾坤湾镇"
    },
    "610623": {
        "610623001": "瓦窑堡街道",
        "610623101": "杨家园则镇",
        "610623102": "玉家湾镇",
        "610623103": "安定镇",
        "610623104": "马家砭镇",
        "610623105": "南沟岔镇",
        "610623106": "涧峪岔镇",
        "610623107": "李家岔镇",
        "610623108": "余家坪镇"
    },
    "610625": {
        "610625001": "保安街道",
        "610625101": "杏河镇",
        "610625102": "顺宁镇",
        "610625103": "旦八镇",
        "610625104": "金丁镇",
        "610625105": "永宁镇",
        "610625106": "义正镇",
        "610625107": "双河镇"
    },
    "610626": {
        "610626001": "吴起街道",
        "610626101": "铁边城镇",
        "610626102": "周湾镇",
        "610626103": "白豹镇",
        "610626104": "长官庙镇",
        "610626105": "长城镇",
        "610626106": "五谷城镇",
        "610626107": "吴仓堡镇",
        "610626108": "庙沟镇"
    },
    "610627": {
        "610627001": "美水街道",
        "610627101": "下寺湾镇",
        "610627102": "道镇",
        "610627103": "石门镇",
        "610627200": "桥镇乡",
        "610627203": "劳山乡"
    },
    "610628": {
        "610628001": "茶坊街道",
        "610628101": "羊泉镇",
        "610628102": "张村驿镇",
        "610628103": "张家湾镇",
        "610628104": "直罗镇",
        "610628106": "牛武镇",
        "610628109": "寺仙镇",
        "610628204": "北道德乡"
    },
    "610629": {
        "610629001": "凤栖街道",
        "610629101": "旧县镇",
        "610629102": "交口河镇",
        "610629103": "老庙镇",
        "610629104": "土基镇",
        "610629105": "石头镇",
        "610629106": "槐柏镇",
        "610629107": "永乡镇",
        "610629200": "菩堤乡"
    },
    "610630": {
        "610630001": "丹州街道",
        "610630101": "秋林镇",
        "610630102": "云岩镇",
        "610630104": "集义镇",
        "610630105": "壶口镇",
        "610630200": "英旺乡",
        "610630201": "交里乡"
    },
    "610631": {
        "610631100": "石堡镇",
        "610631101": "白马滩镇",
        "610631102": "瓦子街镇",
        "610631106": "界头庙镇",
        "610631107": "三岔镇",
        "610631205": "圪台乡",
        "610631206": "崾崄乡"
    },
    "610632": {
        "610632001": "桥山街道",
        "610632101": "店头镇",
        "610632102": "隆坊镇",
        "610632103": "田庄镇",
        "610632104": "阿党镇",
        "610632105": "双龙镇"
    },
    "610700": {
        "610702": "汉台区",
        "610703": "南郑区",
        "610722": "城固县",
        "610723": "洋县",
        "610724": "西乡县",
        "610725": "勉县",
        "610726": "宁强县",
        "610727": "略阳县",
        "610728": "镇巴县",
        "610729": "留坝县",
        "610730": "佛坪县"
    },
    "610702": {
        "610702001": "北关街道",
        "610702002": "东大街街道",
        "610702003": "汉中路街道",
        "610702004": "中山街街道",
        "610702005": "东关街道",
        "610702006": "鑫源街道",
        "610702008": "七里街道",
        "610702009": "龙江街道",
        "610702100": "铺镇",
        "610702101": "武乡镇",
        "610702102": "河东店镇",
        "610702104": "宗营镇",
        "610702105": "老君镇",
        "610702106": "汉王镇",
        "610702107": "徐望镇"
    },
    "610703": {
        "610703001": "汉山街道",
        "610703101": "圣水镇",
        "610703102": "大河坎镇",
        "610703103": "协税镇",
        "610703104": "梁山镇",
        "610703105": "阳春镇",
        "610703106": "高台镇",
        "610703107": "新集镇",
        "610703108": "濂水镇",
        "610703109": "黄官镇",
        "610703110": "青树镇",
        "610703111": "红庙镇",
        "610703112": "牟家坝镇",
        "610703113": "法镇",
        "610703114": "湘水镇",
        "610703115": "小南海镇",
        "610703116": "碑坝镇",
        "610703117": "黎坪镇",
        "610703118": "福成镇",
        "610703119": "两河镇",
        "610703120": "胡家营镇"
    },
    "610722": {
        "610722001": "博望街道",
        "610722002": "莲花街道",
        "610722101": "龙头镇",
        "610722102": "沙河营镇",
        "610722103": "文川镇",
        "610722104": "柳林镇",
        "610722105": "老庄镇",
        "610722107": "桔园镇",
        "610722108": "原公镇",
        "610722109": "上元观镇",
        "610722110": "天明镇",
        "610722111": "二里镇",
        "610722112": "五堵镇",
        "610722114": "双溪镇",
        "610722116": "小河镇",
        "610722119": "董家营镇",
        "610722120": "三合镇",
        "610722400": "陕西飞机制造公司"
    },
    "610723": {
        "610723001": "洋州街道",
        "610723002": "纸坊街道",
        "610723003": "戚氏街道",
        "610723103": "龙亭镇",
        "610723104": "谢村镇",
        "610723105": "马畅镇",
        "610723107": "溢水镇",
        "610723108": "磨子桥镇",
        "610723109": "黄家营镇",
        "610723110": "黄安镇",
        "610723111": "黄金峡镇",
        "610723112": "槐树关镇",
        "610723113": "金水镇",
        "610723114": "华阳镇",
        "610723115": "茅坪镇",
        "610723119": "八里关镇",
        "610723120": "桑溪镇",
        "610723121": "关帝镇"
    },
    "610724": {
        "610724001": "城北街道",
        "610724002": "城南街道",
        "610724101": "杨河镇",
        "610724102": "柳树镇",
        "610724103": "沙河镇",
        "610724104": "私渡镇",
        "610724105": "桑园镇",
        "610724106": "白龙塘镇",
        "610724108": "峡口镇",
        "610724109": "堰口镇",
        "610724110": "茶镇",
        "610724111": "高川镇",
        "610724112": "两河口镇",
        "610724114": "大河镇",
        "610724116": "骆家坝镇",
        "610724117": "子午镇",
        "610724118": "白勉峡镇"
    },
    "610725": {
        "610725001": "勉阳街道",
        "610725101": "武侯镇",
        "610725102": "周家山镇",
        "610725103": "同沟寺镇",
        "610725105": "新街子镇",
        "610725106": "老道寺镇",
        "610725107": "褒城镇",
        "610725109": "金泉镇",
        "610725110": "定军山镇",
        "610725111": "温泉镇",
        "610725112": "元墩镇",
        "610725113": "阜川镇",
        "610725114": "新铺镇",
        "610725116": "茶店镇",
        "610725117": "镇川镇",
        "610725118": "漆树坝镇",
        "610725119": "张家河镇",
        "610725120": "长沟河镇"
    },
    "610726": {
        "610726001": "汉源街道",
        "610726002": "高寨子街道",
        "610726102": "大安镇",
        "610726103": "代家坝镇",
        "610726104": "阳平关镇",
        "610726105": "燕子砭镇",
        "610726106": "广坪镇",
        "610726107": "青木川镇",
        "610726108": "毛坝河镇",
        "610726109": "铁锁关镇",
        "610726110": "胡家坝镇",
        "610726111": "巴山镇",
        "610726112": "巨亭镇",
        "610726113": "舒家坝镇",
        "610726117": "太阳岭镇",
        "610726118": "安乐河镇",
        "610726119": "二郎坝镇",
        "610726120": "禅家岩镇"
    },
    "610727": {
        "610727001": "兴州街道",
        "610727002": "横现河街道",
        "610727101": "接官亭镇",
        "610727102": "西淮坝镇",
        "610727103": "两河口镇",
        "610727104": "金家河镇",
        "610727105": "徐家坪镇",
        "610727106": "白水江镇",
        "610727107": "硖口驿镇",
        "610727108": "马蹄湾镇",
        "610727109": "乐素河镇",
        "610727110": "郭镇",
        "610727111": "黑河镇",
        "610727112": "白雀寺镇",
        "610727113": "仙台坝镇",
        "610727114": "五龙洞镇",
        "610727115": "观音寺镇"
    },
    "610728": {
        "610728001": "泾洋街道",
        "610728101": "渔渡镇",
        "610728102": "盐场镇",
        "610728103": "观音镇",
        "610728104": "巴庙镇",
        "610728105": "兴隆镇",
        "610728106": "长岭镇",
        "610728107": "三元镇",
        "610728108": "简池镇",
        "610728109": "碾子镇",
        "610728110": "小洋镇",
        "610728111": "青水镇",
        "610728112": "赤南镇",
        "610728113": "平安镇",
        "610728114": "杨家河镇",
        "610728115": "巴山镇",
        "610728116": "黎坝镇",
        "610728117": "仁村镇",
        "610728118": "大池镇",
        "610728120": "永乐镇"
    },
    "610729": {
        "610729001": "紫柏街道",
        "610729101": "马道镇",
        "610729102": "武关驿镇",
        "610729103": "留侯镇",
        "610729104": "江口镇",
        "610729105": "玉皇庙镇",
        "610729106": "火烧店镇",
        "610729107": "青桥驿镇"
    },
    "610730": {
        "610730001": "袁家庄街道",
        "610730101": "陈家坝镇",
        "610730102": "大河坝镇",
        "610730103": "西岔河镇",
        "610730104": "岳坝镇",
        "610730105": "长角坝镇",
        "610730106": "石墩河镇"
    },
    "610800": {
        "610802": "榆阳区",
        "610803": "横山区",
        "610822": "府谷县",
        "610824": "靖边县",
        "610825": "定边县",
        "610826": "绥德县",
        "610827": "米脂县",
        "610828": "佳县",
        "610829": "吴堡县",
        "610830": "清涧县",
        "610831": "子洲县",
        "610881": "神木市"
    },
    "610802": {
        "610802001": "鼓楼街道",
        "610802002": "青山路街道",
        "610802003": "上郡路街道",
        "610802004": "新明楼街道",
        "610802005": "驼峰路街道",
        "610802006": "崇文路街道",
        "610802007": "航宇路街道",
        "610802008": "长城路街道",
        "610802009": "金沙路街道",
        "610802010": "朝阳路街道",
        "610802011": "沙河路街道",
        "610802012": "明珠路街道",
        "610802100": "鱼河镇",
        "610802101": "上盐湾镇",
        "610802102": "镇川镇",
        "610802105": "麻黄梁镇",
        "610802106": "牛家梁镇",
        "610802107": "金鸡滩镇",
        "610802108": "马合镇",
        "610802109": "巴拉素镇",
        "610802111": "鱼河峁镇",
        "610802112": "青云镇",
        "610802113": "古塔镇",
        "610802114": "大河塔镇",
        "610802115": "小纪汗镇",
        "610802116": "芹河镇",
        "610802205": "孟家湾乡",
        "610802206": "小壕兔乡",
        "610802207": "岔河则乡",
        "610802208": "补浪河乡",
        "610802209": "红石桥乡"
    },
    "610803": {
        "610803001": "城关街道",
        "610803002": "怀远街道",
        "610803003": "夏州街道",
        "610803004": "怀仁路街道",
        "610803005": "崇德路街道",
        "610803101": "石湾镇",
        "610803102": "高镇",
        "610803103": "武镇",
        "610803104": "党岔镇",
        "610803105": "响水镇",
        "610803106": "波罗镇",
        "610803107": "殿市镇",
        "610803108": "塔湾镇",
        "610803109": "赵石畔镇",
        "610803110": "魏家楼镇",
        "610803111": "韩岔镇",
        "610803112": "白界镇",
        "610803113": "雷龙湾镇"
    },
    "610822": {
        "610822100": "府谷镇",
        "610822101": "黄甫镇",
        "610822103": "哈镇",
        "610822104": "庙沟门镇",
        "610822105": "新民镇",
        "610822106": "孤山镇",
        "610822107": "清水镇",
        "610822108": "大昌汗镇",
        "610822109": "古城镇",
        "610822110": "三道沟镇",
        "610822111": "老高川镇",
        "610822112": "武家庄镇",
        "610822113": "木瓜镇",
        "610822114": "田家寨镇"
    },
    "610824": {
        "610824001": "张家畔街道",
        "610824101": "东坑镇",
        "610824102": "青阳岔镇",
        "610824103": "宁条梁镇",
        "610824104": "周河镇",
        "610824105": "红墩界镇",
        "610824106": "杨桥畔镇",
        "610824107": "王渠则镇",
        "610824108": "中山涧镇",
        "610824109": "杨米涧镇",
        "610824110": "天赐湾镇",
        "610824111": "小河镇",
        "610824112": "龙洲镇",
        "610824113": "黄蒿界镇",
        "610824114": "海则滩镇",
        "610824115": "席麻湾镇",
        "610824116": "镇靖镇"
    },
    "610825": {
        "610825001": "定边街道",
        "610825101": "贺圈镇",
        "610825102": "红柳沟镇",
        "610825103": "砖井镇",
        "610825104": "白泥井镇",
        "610825105": "安边镇",
        "610825106": "堆子梁镇",
        "610825107": "白湾子镇",
        "610825108": "姬塬镇",
        "610825109": "杨井镇",
        "610825110": "新安边镇",
        "610825111": "张崾先镇",
        "610825112": "樊学镇",
        "610825113": "盐场堡镇",
        "610825114": "郝滩镇",
        "610825202": "石洞沟乡",
        "610825204": "油房庄乡",
        "610825207": "冯地坑乡",
        "610825210": "学庄乡"
    },
    "610826": {
        "610826100": "名州镇",
        "610826101": "薛家峁镇",
        "610826102": "崔家湾镇",
        "610826103": "定仙墕镇",
        "610826104": "枣林坪镇",
        "610826105": "义合镇",
        "610826106": "吉镇",
        "610826107": "薛家河镇",
        "610826108": "四十里铺镇",
        "610826109": "石家湾镇",
        "610826110": "田庄镇",
        "610826111": "中角镇",
        "610826112": "满堂川镇",
        "610826113": "张家砭镇",
        "610826114": "白家硷镇"
    },
    "610827": {
        "610827001": "银州街道",
        "610827101": "桃镇",
        "610827102": "龙镇",
        "610827103": "杨家沟镇",
        "610827104": "杜家石沟镇",
        "610827105": "沙家店镇",
        "610827106": "印斗镇",
        "610827107": "郭兴庄镇",
        "610827108": "城郊镇"
    },
    "610828": {
        "610828001": "佳州街道",
        "610828101": "坑镇",
        "610828102": "店镇",
        "610828103": "乌镇",
        "610828104": "金明寺镇",
        "610828105": "通镇",
        "610828106": "王家砭镇",
        "610828107": "方塌镇",
        "610828108": "朱家坬镇",
        "610828109": "螅镇",
        "610828110": "朱官寨镇",
        "610828111": "刘国具镇",
        "610828112": "木头峪镇"
    },
    "610829": {
        "610829001": "宋家川街道",
        "610829101": "辛家沟镇",
        "610829102": "郭家沟镇",
        "610829103": "寇家塬镇",
        "610829104": "岔上镇",
        "610829105": "张家山镇"
    },
    "610830": {
        "610830100": "宽州镇",
        "610830101": "石咀驿镇",
        "610830102": "折家坪镇",
        "610830103": "玉家河镇",
        "610830104": "高杰村镇",
        "610830105": "李家塔镇",
        "610830106": "店则沟镇",
        "610830107": "解家沟镇",
        "610830108": "下廿里铺镇"
    },
    "610831": {
        "610831001": "双湖峪街道",
        "610831101": "何家集镇",
        "610831102": "老君殿镇",
        "610831103": "裴家湾镇",
        "610831104": "苗家坪镇",
        "610831105": "三川口镇",
        "610831106": "马蹄沟镇",
        "610831107": "周家硷镇",
        "610831108": "电市镇",
        "610831109": "砖庙镇",
        "610831110": "淮宁湾镇",
        "610831111": "马岔镇",
        "610831200": "驼耳巷乡"
    },
    "610881": {
        "610881001": "滨河新区街道",
        "610881002": "西沙街道",
        "610881003": "麟州街道",
        "610881004": "迎宾路街道",
        "610881005": "永兴街道",
        "610881006": "西沟街道",
        "610881101": "高家堡镇",
        "610881102": "店塔镇",
        "610881103": "孙家岔镇",
        "610881104": "大柳塔镇",
        "610881105": "花石崖镇",
        "610881106": "中鸡镇",
        "610881107": "贺家川镇",
        "610881108": "尔林兔镇",
        "610881109": "万镇",
        "610881110": "大保当镇",
        "610881111": "马镇",
        "610881112": "栏杆堡镇",
        "610881113": "沙峁镇",
        "610881114": "锦界镇"
    },
    "610900": {
        "610902": "汉滨区",
        "610921": "汉阴县",
        "610922": "石泉县",
        "610923": "宁陕县",
        "610924": "紫阳县",
        "610925": "岚皋县",
        "610926": "平利县",
        "610927": "镇坪县",
        "610928": "旬阳县",
        "610929": "白河县"
    },
    "610902": {
        "610902001": "老城街道",
        "610902002": "新城街道",
        "610902003": "江北街道",
        "610902004": "建民",
        "610902100": "关庙镇",
        "610902101": "张滩镇",
        "610902102": "瀛湖镇",
        "610902104": "五里镇",
        "610902107": "恒口镇",
        "610902109": "吉河镇",
        "610902110": "流水镇",
        "610902111": "大竹园镇",
        "610902112": "洪山镇",
        "610902114": "茨沟镇",
        "610902115": "大河镇",
        "610902116": "沈坝镇",
        "610902117": "双龙镇",
        "610902118": "叶坪镇",
        "610902119": "中原镇",
        "610902120": "县河镇",
        "610902121": "紫荆镇",
        "610902122": "早阳镇",
        "610902123": "关家镇",
        "610902124": "石梯镇",
        "610902125": "坝河镇",
        "610902128": "牛蹄镇",
        "610902130": "晏坝镇",
        "610902132": "谭坝镇"
    },
    "610921": {
        "610921100": "城关镇",
        "610921101": "涧池镇",
        "610921102": "蒲溪镇",
        "610921103": "平梁镇",
        "610921104": "双乳镇",
        "610921105": "铁佛寺镇",
        "610921107": "漩涡镇",
        "610921108": "汉阳镇",
        "610921110": "双河口镇",
        "610921112": "观音河镇"
    },
    "610922": {
        "610922100": "城关镇",
        "610922101": "饶峰镇",
        "610922102": "两河镇",
        "610922103": "迎丰镇",
        "610922104": "池河镇",
        "610922105": "后柳镇",
        "610922106": "喜河镇",
        "610922107": "熨斗镇",
        "610922108": "云雾山镇",
        "610922109": "中池镇",
        "610922110": "曾溪镇"
    },
    "610923": {
        "610923100": "城关镇",
        "610923101": "四亩地镇",
        "610923102": "江口镇",
        "610923104": "广货街镇",
        "610923105": "龙王镇",
        "610923106": "筒车湾镇",
        "610923108": "金川镇",
        "610923109": "皇冠镇",
        "610923110": "太山庙镇",
        "610923111": "梅子镇",
        "610923113": "新场镇"
    },
    "610924": {
        "610924100": "城关镇",
        "610924101": "蒿坪镇",
        "610924102": "汉王镇",
        "610924103": "焕古镇",
        "610924104": "向阳镇",
        "610924105": "洞河镇",
        "610924106": "洄水镇",
        "610924108": "双桥镇",
        "610924109": "高桥镇",
        "610924110": "红椿镇",
        "610924111": "高滩镇",
        "610924112": "毛坝镇",
        "610924113": "瓦庙镇",
        "610924114": "麻柳镇",
        "610924115": "双安镇",
        "610924116": "东木镇",
        "610924117": "界岭镇"
    },
    "610925": {
        "610925100": "城关镇",
        "610925101": "佐龙镇",
        "610925103": "滔河镇",
        "610925104": "官元镇",
        "610925105": "石门镇",
        "610925106": "民主镇",
        "610925107": "大道河镇",
        "610925109": "堰门镇",
        "610925111": "蔺河镇",
        "610925112": "四季镇",
        "610925114": "孟石岭镇",
        "610925115": "南宫山镇"
    },
    "610926": {
        "610926100": "城关镇",
        "610926101": "兴隆镇",
        "610926102": "老县镇",
        "610926103": "大贵镇",
        "610926104": "三阳镇",
        "610926105": "洛河镇",
        "610926106": "广佛镇",
        "610926107": "八仙镇",
        "610926108": "长安镇",
        "610926109": "正阳镇",
        "610926110": "西河镇"
    },
    "610927": {
        "610927100": "城关镇",
        "610927101": "曾家镇",
        "610927102": "牛头店镇",
        "610927103": "钟宝镇",
        "610927105": "上竹镇",
        "610927106": "华坪镇",
        "610927108": "曙坪镇"
    },
    "610928": {
        "610928100": "城关镇",
        "610928101": "棕溪镇",
        "610928102": "关口镇",
        "610928103": "蜀河镇",
        "610928104": "双河镇",
        "610928105": "小河镇",
        "610928106": "赵湾镇",
        "610928107": "麻坪镇",
        "610928108": "甘溪镇",
        "610928109": "白柳镇",
        "610928110": "吕河镇",
        "610928111": "神河镇",
        "610928112": "铜钱关镇",
        "610928113": "段家河镇",
        "610928114": "仙河镇",
        "610928115": "金寨镇",
        "610928116": "桐木镇",
        "610928117": "构元镇",
        "610928118": "石门镇",
        "610928119": "红军镇",
        "610928120": "仁河口镇"
    },
    "610929": {
        "610929100": "城关镇",
        "610929101": "中厂镇",
        "610929102": "构扒镇",
        "610929103": "卡子镇",
        "610929104": "茅坪镇",
        "610929105": "宋家镇",
        "610929106": "西营镇",
        "610929107": "仓上镇",
        "610929108": "冷水镇",
        "610929109": "双丰镇",
        "610929110": "麻虎镇"
    },
    "611000": {
        "611002": "商州区",
        "611021": "洛南县",
        "611022": "丹凤县",
        "611023": "商南县",
        "611024": "山阳县",
        "611025": "镇安县",
        "611026": "柞水县"
    },
    "611002": {
        "611002001": "城关街道",
        "611002002": "大赵峪街道",
        "611002003": "陈塬街道",
        "611002004": "刘湾街道",
        "611002101": "夜村镇",
        "611002104": "沙河子镇",
        "611002105": "杨峪河镇",
        "611002106": "金陵寺镇",
        "611002107": "黑山镇",
        "611002108": "杨斜镇",
        "611002109": "麻街镇",
        "611002111": "牧护关镇",
        "611002112": "大荆镇",
        "611002113": "腰市镇",
        "611002114": "板桥镇",
        "611002115": "北宽坪镇",
        "611002119": "三岔河镇",
        "611002121": "闫村镇",
        "611002400": "二龙山水库",
        "611002401": "南秦水库",
        "611002402": "二龙山国营林场",
        "611002403": "商丹循环工业园",
        "611002404": "荆河工业园",
        "611002405": "荆河农业示范园",
        "611002406": "高校园区",
        "611002407": "商洛职业技术学院",
        "611002408": "氟化硅产业业园"
    },
    "611021": {
        "611021001": "城关街道",
        "611021002": "四皓街道",
        "611021101": "景村镇",
        "611021102": "古城镇",
        "611021103": "三要镇",
        "611021104": "灵口镇",
        "611021106": "寺耳镇",
        "611021107": "巡检镇",
        "611021108": "石坡镇",
        "611021109": "石门镇",
        "611021110": "麻坪镇",
        "611021111": "洛源镇",
        "611021112": "保安镇",
        "611021114": "永丰镇",
        "611021117": "柏峪寺镇",
        "611021118": "高耀镇"
    },
    "611022": {
        "611022001": "龙驹寨街道",
        "611022101": "庾岭镇",
        "611022102": "蔡川镇",
        "611022103": "峦庄镇",
        "611022104": "铁峪铺镇",
        "611022105": "武关镇",
        "611022106": "竹林关镇",
        "611022107": "土门镇",
        "611022108": "寺坪镇",
        "611022109": "商镇",
        "611022110": "棣花镇",
        "611022115": "花瓶子镇"
    },
    "611023": {
        "611023001": "城关街道",
        "611023101": "富水镇",
        "611023102": "湘河镇",
        "611023104": "赵川镇",
        "611023107": "过风楼镇",
        "611023108": "试马镇",
        "611023109": "清油河镇",
        "611023110": "十里坪镇",
        "611023111": "金丝峡镇",
        "611023112": "青山镇"
    },
    "611024": {
        "611024001": "城关街道",
        "611024002": "十里铺街道",
        "611024101": "高坝店镇",
        "611024102": "天竺山镇",
        "611024103": "中村镇",
        "611024104": "银花镇",
        "611024105": "西照川镇",
        "611024106": "漫川关镇",
        "611024107": "南宽坪镇",
        "611024108": "户家塬镇",
        "611024109": "杨地镇",
        "611024111": "小河口镇",
        "611024112": "色河铺镇",
        "611024113": "板岩镇",
        "611024116": "延坪镇",
        "611024117": "两岭镇",
        "611024118": "王阎镇",
        "611024121": "法官镇"
    },
    "611025": {
        "611025001": "永乐街道",
        "611025101": "回龙镇",
        "611025102": "铁厂镇",
        "611025103": "大坪镇",
        "611025104": "米粮镇",
        "611025105": "茅坪回族镇",
        "611025106": "西口回族镇",
        "611025107": "高峰镇",
        "611025108": "青铜关镇",
        "611025109": "柴坪镇",
        "611025110": "达仁镇",
        "611025111": "木王镇",
        "611025113": "云盖寺镇",
        "611025114": "庙沟镇",
        "611025117": "月河镇",
        "611025400": "黑窑沟林厂",
        "611025401": "木王林厂",
        "611025402": "镇安县苗圃"
    },
    "611026": {
        "611026001": "乾佑街道",
        "611026101": "营盘镇",
        "611026102": "下梁镇",
        "611026104": "小岭镇",
        "611026105": "凤凰镇",
        "611026106": "红岩寺镇",
        "611026107": "曹坪镇",
        "611026109": "杏坪镇",
        "611026110": "瓦房口镇"
    },
    "620000": {
        "620100": "兰州市",
        "620200": "嘉峪关市",
        "620300": "金昌市",
        "620400": "白银市",
        "620500": "天水市",
        "620600": "武威市",
        "620700": "张掖市",
        "620800": "平凉市",
        "620900": "酒泉市",
        "621000": "庆阳市",
        "621100": "定西市",
        "621200": "陇南市",
        "622900": "临夏回族自治州",
        "623000": "甘南藏族自治州"
    },
    "620100": {
        "620102": "城关区",
        "620103": "七里河区",
        "620104": "西固区",
        "620105": "安宁区",
        "620111": "红古区",
        "620121": "永登县",
        "620122": "皋兰县",
        "620123": "榆中县",
        "620171": "兰州新区"
    },
    "620102": {
        "620102001": "酒泉路街道",
        "620102002": "张掖路街道",
        "620102003": "雁南街道",
        "620102004": "临夏路街道",
        "620102005": "雁北街道",
        "620102006": "五泉街道",
        "620102007": "白银路街道",
        "620102008": "皋兰路街道",
        "620102009": "广武门街道",
        "620102010": "伏龙坪街道",
        "620102011": "靖远路街道",
        "620102012": "草场街街道",
        "620102013": "火车站街道",
        "620102014": "拱星墩街道",
        "620102015": "东岗街道",
        "620102016": "团结新村街道",
        "620102017": "东岗西路街道",
        "620102018": "铁路东村街道",
        "620102019": "铁路西村街道",
        "620102020": "渭源路街道",
        "620102021": "盐场路街道",
        "620102022": "嘉峪关路街道",
        "620102023": "焦家湾街道",
        "620102024": "青白石街道",
        "620102025": "雁园街道",
        "620102400": "高新区",
        "620102402": "甘肃农垦集团",
        "620102403": "甘肃监狱企业集团公司"
    },
    "620103": {
        "620103001": "西园街道",
        "620103002": "西湖街道",
        "620103003": "建兰路街道",
        "620103004": "敦煌路街道",
        "620103005": "西站街道",
        "620103006": "晏家坪街道",
        "620103007": "龚家湾街道",
        "620103008": "土门墩街道",
        "620103009": "秀川街道",
        "620103100": "阿干镇",
        "620103101": "八里镇",
        "620103102": "彭家坪镇",
        "620103103": "西果园镇",
        "620103104": "黄峪镇",
        "620103201": "魏岭乡"
    },
    "620104": {
        "620104001": "陈坪街道",
        "620104002": "先锋路街道",
        "620104003": "福利路街道",
        "620104004": "西固城街道",
        "620104005": "四季青街道",
        "620104006": "临洮街街道",
        "620104007": "西柳沟街道",
        "620104100": "新城镇",
        "620104101": "东川镇",
        "620104102": "河口镇",
        "620104103": "达川镇",
        "620104104": "柳泉镇",
        "620104204": "金沟乡",
        "620104400": "新安路街道"
    },
    "620105": {
        "620105001": "培黎街道",
        "620105002": "西路街道",
        "620105003": "沙井驿街道",
        "620105004": "十里店街道",
        "620105005": "孔家崖街道",
        "620105006": "银滩路街道",
        "620105007": "刘家堡街道",
        "620105008": "安宁堡街道"
    },
    "620111": {
        "620111001": "窑街街道",
        "620111002": "下窑街道",
        "620111003": "矿区街道",
        "620111004": "华龙街道",
        "620111101": "海石湾镇",
        "620111102": "花庄镇",
        "620111103": "平安镇",
        "620111104": "红古镇"
    },
    "620121": {
        "620121100": "城关镇",
        "620121101": "红城镇",
        "620121102": "中堡镇",
        "620121103": "武胜驿镇",
        "620121104": "河桥镇",
        "620121105": "连城镇",
        "620121106": "苦水镇",
        "620121109": "大同镇",
        "620121110": "龙泉寺镇",
        "620121111": "树屏镇",
        "620121112": "上川镇",
        "620121113": "柳树镇",
        "620121114": "通远镇",
        "620121201": "坪城乡",
        "620121202": "民乐乡",
        "620121204": "七山乡"
    },
    "620122": {
        "620122100": "石洞镇",
        "620122102": "忠和镇",
        "620122103": "什川镇",
        "620122104": "九合镇",
        "620122105": "水阜镇",
        "620122106": "黑石镇"
    },
    "620123": {
        "620123100": "城关镇",
        "620123101": "夏官营镇",
        "620123102": "高崖镇",
        "620123103": "金崖镇",
        "620123104": "和平镇",
        "620123105": "甘草店镇",
        "620123106": "青城镇",
        "620123107": "定远镇",
        "620123108": "连搭镇",
        "620123109": "新营镇",
        "620123110": "贡井镇",
        "620123200": "来紫堡乡",
        "620123201": "三角城乡",
        "620123202": "小康营乡",
        "620123204": "银山乡",
        "620123205": "马坡乡",
        "620123207": "清水驿乡",
        "620123208": "龙泉乡",
        "620123209": "韦营乡",
        "620123210": "中连川乡",
        "620123212": "园子岔乡",
        "620123213": "上花岔乡",
        "620123214": "哈岘乡"
    },
    "620171": {"620171101": "中川镇", "620171102": "秦川镇", "620171103": "西岔镇"},
    "620200": {
        "620201100": "新城镇",
        "620201101": "峪泉镇",
        "620201102": "文殊镇",
        "620201401": "雄关区",
        "620201402": "镜铁区",
        "620201403": "长城区"
    },
    "620300": {"620302": "金川区", "620321": "永昌县"},
    "620302": {
        "620302001": "滨河路街道",
        "620302002": "桂林路街道",
        "620302003": "北京路街道",
        "620302004": "金川路街道",
        "620302005": "新华路街道",
        "620302006": "广州路街道",
        "620302100": "宁远堡镇",
        "620302101": "双湾镇"
    },
    "620321": {
        "620321100": "城关镇",
        "620321101": "河西堡镇",
        "620321102": "新城子镇",
        "620321103": "朱王堡镇",
        "620321104": "东寨镇",
        "620321105": "水源镇",
        "620321106": "红山窑镇",
        "620321107": "焦家庄镇",
        "620321108": "六坝镇",
        "620321203": "南坝乡"
    },
    "620400": {"620402": "白银区", "620403": "平川区", "620421": "靖远县", "620422": "会宁县", "620423": "景泰县"},
    "620402": {
        "620402001": "人民路街道",
        "620402002": "公园路街道",
        "620402003": "四龙路街道",
        "620402004": "工农路街道",
        "620402005": "纺织路街道",
        "620402100": "水川镇",
        "620402101": "四龙镇",
        "620402102": "王岘镇",
        "620402201": "强湾乡",
        "620402202": "武川乡"
    },
    "620403": {
        "620403001": "长征街道",
        "620403002": "电力路街道",
        "620403003": "红会路街道",
        "620403004": "兴平路街道",
        "620403100": "王家山镇",
        "620403101": "水泉镇",
        "620403102": "共和镇",
        "620403105": "宝积镇",
        "620403106": "黄峤镇",
        "620403203": "种田乡",
        "620403204": "复兴乡"
    },
    "620421": {
        "620421101": "北湾镇",
        "620421102": "东湾镇",
        "620421103": "乌兰镇",
        "620421104": "刘川镇",
        "620421105": "北滩镇",
        "620421106": "五合镇",
        "620421107": "大芦镇",
        "620421108": "糜滩镇",
        "620421109": "高湾镇",
        "620421110": "平堡镇",
        "620421111": "东升镇",
        "620421112": "双龙镇",
        "620421113": "三滩镇",
        "620421206": "兴隆乡",
        "620421208": "石门乡",
        "620421211": "靖安乡",
        "620421215": "永新乡",
        "620421216": "若笠乡"
    },
    "620422": {
        "620422100": "会师镇",
        "620422101": "郭城驿镇",
        "620422102": "河畔镇",
        "620422103": "头寨子镇",
        "620422104": "太平店镇",
        "620422105": "甘沟驿镇",
        "620422106": "侯家川镇",
        "620422107": "柴家门镇",
        "620422108": "汉家岔镇",
        "620422109": "刘家寨子镇",
        "620422110": "白草塬镇",
        "620422111": "大沟镇",
        "620422112": "四房吴镇",
        "620422113": "中川镇",
        "620422114": "老君坡镇",
        "620422115": "平头川镇",
        "620422116": "丁家沟镇",
        "620422117": "杨崖集镇",
        "620422118": "翟家所镇",
        "620422119": "韩家集镇",
        "620422120": "土门岘镇",
        "620422121": "新塬镇",
        "620422122": "草滩镇",
        "620422123": "新庄镇",
        "620422202": "新添堡回族乡",
        "620422204": "党家岘乡",
        "620422213": "八里湾乡",
        "620422225": "土高山乡"
    },
    "620423": {
        "620423100": "一条山镇",
        "620423101": "芦阳镇",
        "620423102": "上沙沃镇",
        "620423103": "喜泉镇",
        "620423104": "草窝滩镇",
        "620423105": "红水镇",
        "620423106": "中泉镇",
        "620423107": "正路镇",
        "620423203": "寺滩乡",
        "620423205": "五佛乡",
        "620423207": "漫水滩乡",
        "620423400": "条山集团"
    },
    "620500": {
        "620502": "秦州区",
        "620503": "麦积区",
        "620521": "清水县",
        "620522": "秦安县",
        "620523": "甘谷县",
        "620524": "武山县",
        "620525": "张家川回族自治县"
    },
    "620502": {
        "620502001": "大城街道",
        "620502002": "七里墩街道",
        "620502003": "东关街道",
        "620502004": "中城街道",
        "620502005": "西关街道",
        "620502006": "石马坪街道",
        "620502007": "天水郡街道",
        "620502100": "玉泉镇",
        "620502101": "太京镇",
        "620502102": "藉口镇",
        "620502103": "皂郊镇",
        "620502104": "汪川镇",
        "620502105": "牡丹镇",
        "620502106": "关子镇",
        "620502107": "平南镇",
        "620502108": "天水镇",
        "620502109": "娘娘坝镇",
        "620502110": "中梁镇",
        "620502111": "杨家寺镇",
        "620502112": "齐寿镇",
        "620502113": "大门镇",
        "620502114": "秦岭镇",
        "620502115": "华歧镇"
    },
    "620503": {
        "620503001": "道北街道",
        "620503002": "北道埠街道",
        "620503003": "桥南街道",
        "620503100": "社棠镇",
        "620503101": "马跑泉镇",
        "620503102": "甘泉镇",
        "620503103": "渭南镇",
        "620503104": "东岔镇",
        "620503105": "花牛镇",
        "620503106": "中滩镇",
        "620503107": "新阳镇",
        "620503108": "元龙镇",
        "620503109": "伯阳镇",
        "620503110": "麦积镇",
        "620503111": "石佛镇",
        "620503112": "三岔镇",
        "620503113": "琥珀镇",
        "620503114": "利桥镇",
        "620503115": "五龙镇",
        "620503116": "党川镇",
        "620503400": "天水经济开发区",
        "620503401": "天水市高新技术工业园",
        "620503402": "天水市农业高新技术示范区",
        "620503403": "天水市麦积区三阳工业示范区",
        "620503404": "天水市麦积区廿铺工业示范区",
        "620503405": "天水市麦积区东柯河工业园区"
    },
    "620521": {
        "620521100": "永清镇",
        "620521101": "红堡镇",
        "620521102": "白驼镇",
        "620521103": "金集镇",
        "620521104": "秦亭镇",
        "620521105": "山门镇",
        "620521106": "白沙镇",
        "620521107": "王河镇",
        "620521108": "郭川镇",
        "620521109": "黄门镇",
        "620521110": "松树镇",
        "620521111": "远门镇",
        "620521112": "土门镇",
        "620521113": "草川铺镇",
        "620521114": "陇东镇",
        "620521206": "贾川乡",
        "620521207": "丰望乡",
        "620521211": "新城乡",
        "620521400": "清水县科技养殖示范园区"
    },
    "620522": {
        "620522100": "兴国镇",
        "620522101": "莲花镇",
        "620522102": "西川镇",
        "620522103": "陇城镇",
        "620522104": "郭嘉镇",
        "620522105": "五营镇",
        "620522106": "叶堡镇",
        "620522107": "魏店镇",
        "620522108": "安伏镇",
        "620522109": "千户镇",
        "620522110": "王尹镇",
        "620522111": "兴丰镇",
        "620522112": "中山镇",
        "620522113": "刘坪镇",
        "620522114": "王铺镇",
        "620522115": "王窑镇",
        "620522116": "云山镇"
    },
    "620523": {
        "620523100": "大像山镇",
        "620523101": "新兴镇",
        "620523102": "磐安镇",
        "620523103": "六峰镇",
        "620523104": "安远镇",
        "620523105": "金山镇",
        "620523106": "大石镇",
        "620523107": "礼辛镇",
        "620523108": "武家河镇",
        "620523109": "大庄镇",
        "620523110": "古坡镇",
        "620523111": "八里湾镇",
        "620523112": "西坪镇",
        "620523200": "谢家湾乡",
        "620523207": "白家湾乡"
    },
    "620524": {
        "620524100": "城关镇",
        "620524101": "洛门镇",
        "620524102": "鸳鸯镇",
        "620524103": "滩歌镇",
        "620524104": "四门镇",
        "620524105": "马力镇",
        "620524106": "山丹镇",
        "620524107": "温泉镇",
        "620524108": "桦林镇",
        "620524109": "龙台镇",
        "620524110": "榆盘镇",
        "620524111": "高楼镇",
        "620524112": "杨河镇",
        "620524202": "咀头乡",
        "620524206": "沿安乡"
    },
    "620525": {
        "620525100": "张家川镇",
        "620525101": "龙山镇",
        "620525102": "恭门镇",
        "620525103": "马鹿镇",
        "620525104": "梁山镇",
        "620525105": "马关镇",
        "620525106": "刘堡镇",
        "620525107": "胡川镇",
        "620525108": "大阳镇",
        "620525109": "川王镇",
        "620525202": "张棉乡",
        "620525204": "木河乡",
        "620525209": "连五乡",
        "620525213": "平安乡",
        "620525214": "阎家乡"
    },
    "620600": {"620602": "凉州区", "620621": "民勤县", "620622": "古浪县", "620623": "天祝藏族自治县"},
    "620602": {
        "620602001": "东大街街道",
        "620602002": "西大街街道",
        "620602003": "东关街街道",
        "620602004": "西关街街道",
        "620602005": "火车站街街道",
        "620602006": "地质新村街街道",
        "620602007": "荣华街街道",
        "620602008": "宣武街道",
        "620602009": "黄羊河街道",
        "620602100": "黄羊镇",
        "620602101": "武南镇",
        "620602102": "清源镇",
        "620602103": "永昌镇",
        "620602104": "双城镇",
        "620602105": "丰乐镇",
        "620602106": "高坝镇",
        "620602107": "金羊镇",
        "620602108": "和平镇",
        "620602109": "羊下坝镇",
        "620602110": "中坝镇",
        "620602111": "永丰镇",
        "620602112": "古城镇",
        "620602113": "张义镇",
        "620602114": "发放镇",
        "620602115": "西营镇",
        "620602116": "四坝镇",
        "620602117": "洪祥镇",
        "620602118": "谢河镇",
        "620602119": "金沙镇",
        "620602120": "松树镇",
        "620602121": "怀安镇",
        "620602122": "下双镇",
        "620602123": "清水镇",
        "620602124": "河东镇",
        "620602125": "五和镇",
        "620602126": "长城镇",
        "620602127": "吴家井镇",
        "620602128": "金河镇",
        "620602129": "韩佐镇",
        "620602130": "大柳镇",
        "620602131": "柏树镇",
        "620602132": "金塔镇",
        "620602133": "九墩镇",
        "620602134": "金山镇",
        "620602135": "新华镇",
        "620602136": "康宁镇",
        "620602400": "九墩滩指挥部",
        "620602401": "邓马营湖生态建设指挥部"
    },
    "620621": {
        "620621100": "三雷镇",
        "620621101": "东坝镇",
        "620621102": "泉山镇",
        "620621103": "西渠镇",
        "620621104": "东湖镇",
        "620621105": "红沙岗镇",
        "620621106": "昌宁镇",
        "620621107": "重兴镇",
        "620621108": "薛百镇",
        "620621109": "大坝镇",
        "620621110": "苏武镇",
        "620621111": "大滩镇",
        "620621112": "双茨科镇",
        "620621113": "红沙梁镇",
        "620621114": "蔡旗镇",
        "620621115": "夹河镇",
        "620621116": "收成镇",
        "620621117": "南湖镇"
    },
    "620622": {
        "620622100": "古浪镇",
        "620622101": "泗水镇",
        "620622102": "土门镇",
        "620622103": "大靖镇",
        "620622104": "裴家营镇",
        "620622105": "海子滩镇",
        "620622106": "定宁镇",
        "620622107": "黄羊川镇",
        "620622108": "黑松驿镇",
        "620622109": "永丰滩镇",
        "620622110": "黄花滩镇",
        "620622111": "西靖镇",
        "620622112": "民权镇",
        "620622113": "直滩镇",
        "620622114": "古丰镇",
        "620622205": "新堡乡",
        "620622206": "干城乡",
        "620622207": "横梁乡",
        "620622208": "十八里堡乡"
    },
    "620623": {
        "620623100": "华藏寺镇",
        "620623101": "打柴沟镇",
        "620623102": "安远镇",
        "620623103": "炭山岭镇",
        "620623104": "哈溪镇",
        "620623105": "赛什斯镇",
        "620623106": "石门镇",
        "620623107": "松山镇",
        "620623108": "天堂镇",
        "620623109": "朵什镇",
        "620623110": "西大滩镇",
        "620623111": "抓喜秀龙镇",
        "620623112": "大红沟镇",
        "620623113": "祁连镇",
        "620623201": "东坪乡",
        "620623202": "赛拉隆乡",
        "620623203": "东大滩乡",
        "620623208": "毛藏乡",
        "620623210": "旦马乡",
        "620623401": "天祝建材厂",
        "620623402": "天祝煤电公司"
    },
    "620700": {
        "620702": "甘州区",
        "620721": "肃南裕固族自治县",
        "620722": "民乐县",
        "620723": "临泽县",
        "620724": "高台县",
        "620725": "山丹县"
    },
    "620702": {
        "620702001": "东街街道",
        "620702002": "南街街道",
        "620702003": "西街街道",
        "620702004": "北街街道",
        "620702005": "火车站街道",
        "620702100": "梁家墩镇",
        "620702101": "上秦镇",
        "620702102": "大满镇",
        "620702103": "沙井镇",
        "620702104": "乌江镇",
        "620702105": "甘浚镇",
        "620702106": "新墩镇",
        "620702107": "党寨镇",
        "620702108": "碱滩镇",
        "620702109": "三闸镇",
        "620702110": "小满镇",
        "620702111": "明永镇",
        "620702112": "长安镇",
        "620702202": "龙渠乡",
        "620702203": "安阳乡",
        "620702204": "花寨乡",
        "620702207": "靖安乡",
        "620702211": "平山湖蒙古族乡",
        "620702402": "张掖经济技术开发区"
    },
    "620721": {
        "620721100": "红湾寺镇",
        "620721101": "皇城镇",
        "620721102": "康乐镇",
        "620721200": "马蹄藏族乡",
        "620721202": "白银蒙古族乡",
        "620721203": "大河乡",
        "620721204": "明花乡",
        "620721205": "祁丰蔵族乡",
        "620721500": "甘肃省绵羊育种场",
        "620721501": "张掖宝瓶河牧场"
    },
    "620722": {
        "620722101": "洪水镇",
        "620722102": "六坝镇",
        "620722103": "新天镇",
        "620722104": "南古镇",
        "620722105": "永固镇",
        "620722106": "三堡镇",
        "620722107": "南丰镇",
        "620722108": "民联镇",
        "620722109": "顺化镇",
        "620722110": "丰乐镇",
        "620722400": "民乐生态工业园区"
    },
    "620723": {
        "620723101": "沙河镇",
        "620723102": "新华镇",
        "620723103": "蓼泉镇",
        "620723104": "平川镇",
        "620723105": "板桥镇",
        "620723106": "鸭暖镇",
        "620723107": "倪家营镇",
        "620723500": "国营临泽农场",
        "620723501": "五泉林场",
        "620723502": "沙河林场",
        "620723503": "小泉子治沙站",
        "620723504": "园艺场",
        "620723505": "良种繁殖场"
    },
    "620724": {
        "620724100": "城关镇",
        "620724101": "宣化镇",
        "620724102": "南华镇",
        "620724103": "巷道镇",
        "620724104": "合黎镇",
        "620724105": "骆驼城镇",
        "620724106": "新坝镇",
        "620724107": "黑泉镇",
        "620724108": "罗城镇",
        "620724400": "甘肃高台工业园区"
    },
    "620725": {
        "620725101": "清泉镇",
        "620725102": "位奇镇",
        "620725103": "霍城镇",
        "620725104": "陈户镇",
        "620725105": "大马营镇",
        "620725106": "东乐镇",
        "620725203": "老军乡",
        "620725204": "李桥乡",
        "620725400": "国营山丹农场",
        "620725402": "中牧公司山丹马场"
    },
    "620800": {
        "620802": "崆峒区",
        "620821": "泾川县",
        "620822": "灵台县",
        "620823": "崇信县",
        "620825": "庄浪县",
        "620826": "静宁县",
        "620881": "华亭市"
    },
    "620802": {
        "620802001": "东关街道",
        "620802002": "中街街道",
        "620802003": "西郊街道",
        "620802101": "崆峒镇",
        "620802102": "白水镇",
        "620802103": "草峰镇",
        "620802104": "安国镇",
        "620802105": "柳湖镇",
        "620802106": "四十里铺镇",
        "620802107": "花所镇",
        "620802203": "索罗乡",
        "620802204": "香莲乡",
        "620802205": "西阳乡",
        "620802206": "大秦乡",
        "620802207": "白庙乡",
        "620802208": "寨河乡",
        "620802209": "大寨乡",
        "620802210": "上杨乡",
        "620802211": "麻武乡",
        "620802212": "峡门乡",
        "620802498": "平凉崆峒山大景区管理委员会"
    },
    "620821": {
        "620821001": "街道",
        "620821100": "城关镇",
        "620821101": "玉都镇",
        "620821102": "高平镇",
        "620821103": "荔堡镇",
        "620821104": "王村镇",
        "620821105": "窑店镇",
        "620821106": "飞云镇",
        "620821107": "丰台镇",
        "620821108": "党原镇",
        "620821109": "汭丰镇",
        "620821110": "太平镇",
        "620821201": "罗汉洞乡",
        "620821202": "泾明乡",
        "620821203": "红河乡",
        "620821400": "温泉经济开发区",
        "620821500": "张老寺农场"
    },
    "620822": {
        "620822001": "街道",
        "620822100": "中台镇",
        "620822101": "邵寨镇",
        "620822102": "独店镇",
        "620822103": "什字镇",
        "620822104": "朝那镇",
        "620822105": "西屯镇",
        "620822106": "上良镇",
        "620822107": "百里镇",
        "620822108": "蒲窝镇",
        "620822201": "新开乡",
        "620822204": "梁原乡",
        "620822205": "龙门乡",
        "620822206": "星火乡",
        "620822400": "万宝川农场"
    },
    "620823": {
        "620823001": "锦屏街道办",
        "620823100": "锦屏镇",
        "620823101": "新窑镇",
        "620823102": "柏树镇",
        "620823103": "黄寨镇",
        "620823202": "黄花乡",
        "620823203": "木林乡",
        "620823400": "崇信县新窑工业园区管理委员会",
        "620823401": "五举农场",
        "620823402": "崇信县铜城工业园区管理委员会"
    },
    "620825": {
        "620825001": "水洛街道",
        "620825100": "水洛镇",
        "620825101": "南湖镇",
        "620825102": "朱店镇",
        "620825103": "万泉镇",
        "620825104": "韩店镇",
        "620825105": "卧龙镇",
        "620825106": "阳川镇",
        "620825107": "盘安镇",
        "620825108": "大庄镇",
        "620825109": "通化镇",
        "620825110": "永宁镇",
        "620825111": "良邑镇",
        "620825112": "岳堡镇",
        "620825113": "柳梁镇",
        "620825114": "南坪镇",
        "620825201": "杨河乡",
        "620825202": "赵墩乡",
        "620825215": "郑河乡"
    },
    "620826": {
        "620826001": "城区街道",
        "620826100": "城关镇",
        "620826101": "威戎镇",
        "620826102": "界石铺镇",
        "620826103": "八里镇",
        "620826104": "李店镇",
        "620826105": "古城镇",
        "620826106": "仁大镇",
        "620826107": "甘沟镇",
        "620826108": "城川镇",
        "620826109": "曹务镇",
        "620826110": "雷大镇",
        "620826111": "四河镇",
        "620826112": "细巷镇",
        "620826113": "双岘镇",
        "620826114": "治平镇",
        "620826115": "红寺镇",
        "620826116": "原安镇",
        "620826201": "司桥乡",
        "620826206": "余湾乡",
        "620826208": "贾河乡",
        "620826209": "深沟乡",
        "620826211": "新店乡",
        "620826216": "三合乡",
        "620826218": "灵芝乡"
    },
    "620881": {
        "620881001": "东华街道",
        "620881100": "东华镇",
        "620881101": "安口镇",
        "620881102": "西华镇",
        "620881103": "马峡镇",
        "620881104": "策底镇",
        "620881105": "上关镇",
        "620881106": "河西镇",
        "620881201": "神峪乡",
        "620881202": "山寨乡",
        "620881204": "砚峡乡",
        "620881400": "石堡子开发区管委会"
    },
    "620900": {
        "620902": "肃州区",
        "620921": "金塔县",
        "620922": "瓜州县",
        "620923": "肃北蒙古族自治县",
        "620924": "阿克塞哈萨克族自治县",
        "620981": "玉门市",
        "620982": "敦煌市"
    },
    "620902": {
        "620902001": "东北街街道",
        "620902002": "东南街街道",
        "620902003": "工业园街道",
        "620902004": "新城街道",
        "620902005": "西北街街道",
        "620902006": "西南街街道",
        "620902007": "玉管局生活基地街道",
        "620902100": "西洞镇",
        "620902101": "清水镇",
        "620902102": "总寨镇",
        "620902103": "金佛寺镇",
        "620902104": "上坝镇",
        "620902105": "三墩镇",
        "620902106": "银达镇",
        "620902107": "西峰镇",
        "620902108": "泉湖镇",
        "620902109": "果园镇",
        "620902110": "下河清镇",
        "620902111": "铧尖镇",
        "620902112": "东洞镇",
        "620902113": "丰乐镇",
        "620902207": "黄泥堡乡",
        "620902401": "国营下河清农场",
        "620902403": "酒泉经济技术开发区",
        "620902404": "十号基地"
    },
    "620921": {
        "620921100": "中东镇",
        "620921101": "鼎新镇",
        "620921102": "金塔镇",
        "620921103": "东坝镇",
        "620921105": "航天镇",
        "620921106": "大庄子镇",
        "620921107": "西坝镇",
        "620921204": "古城乡",
        "620921209": "羊井子湾乡",
        "620921400": "甘肃亚盛农工商集团有限责任公司",
        "620921401": "工业园区管委会"
    },
    "620922": {
        "620922100": "渊泉镇",
        "620922101": "柳园镇",
        "620922102": "三道沟镇",
        "620922103": "南岔镇",
        "620922104": "锁阳城镇",
        "620922105": "瓜州镇",
        "620922106": "西湖镇",
        "620922107": "河东镇",
        "620922108": "双塔镇",
        "620922109": "腰站子东乡族镇",
        "620922201": "布隆吉乡",
        "620922212": "七墩回族东乡族乡",
        "620922214": "广至藏族乡",
        "620922215": "沙河回族乡",
        "620922216": "梁湖乡",
        "620922400": "国营小宛农场"
    },
    "620923": {"620923100": "党城湾镇", "620923101": "马鬃山镇", "620923201": "盐池湾乡", "620923202": "石包城乡"},
    "620924": {"620924100": "红柳湾镇", "620924200": "阿克旗乡", "620924203": "阿勒腾乡", "620924400": "阿克塞县工业园区管理委员会"},
    "620981": {
        "620981002": "新市区街道",
        "620981101": "玉门镇",
        "620981102": "赤金镇",
        "620981103": "花海镇",
        "620981104": "老君庙镇",
        "620981105": "黄闸湾镇",
        "620981106": "下西号镇",
        "620981107": "柳河镇",
        "620981108": "昌马镇",
        "620981109": "柳湖镇",
        "620981110": "六墩镇",
        "620981206": "小金湾东乡族乡",
        "620981208": "独山子东乡族乡",
        "620981400": "国营饮马农场",
        "620981401": "国营黄花农场",
        "620981402": "甘肃农垦裕盛农业公司",
        "620981403": "甘肃省农垦建筑工程公司",
        "620981404": "甘肃矿区"
    },
    "620982": {
        "620982100": "七里镇",
        "620982101": "沙州镇",
        "620982102": "肃州镇",
        "620982103": "莫高镇",
        "620982104": "转渠口镇",
        "620982105": "阳关镇",
        "620982106": "月牙泉镇",
        "620982107": "郭家堡镇",
        "620982108": "黄渠镇",
        "620982400": "国营敦煌农场",
        "620982401": "青海石油管理局生活基地"
    },
    "621000": {
        "621002": "西峰区",
        "621021": "庆城县",
        "621022": "环县",
        "621023": "华池县",
        "621024": "合水县",
        "621025": "正宁县",
        "621026": "宁县",
        "621027": "镇原县"
    },
    "621002": {
        "621002001": "北街",
        "621002002": "南街",
        "621002003": "西街",
        "621002100": "肖金镇",
        "621002101": "董志镇",
        "621002102": "后官寨镇",
        "621002103": "彭原镇",
        "621002104": "温泉镇",
        "621002203": "什社乡",
        "621002204": "显胜乡"
    },
    "621021": {
        "621021100": "庆城镇",
        "621021101": "驿马镇",
        "621021102": "三十里铺镇",
        "621021103": "马岭镇",
        "621021104": "玄马镇",
        "621021105": "白马铺镇",
        "621021106": "桐川镇",
        "621021107": "赤城镇",
        "621021108": "高楼镇",
        "621021203": "太白梁乡",
        "621021204": "土桥乡",
        "621021205": "蔡口集乡",
        "621021207": "南庄乡",
        "621021208": "翟家河乡",
        "621021209": "蔡家庙乡"
    },
    "621022": {
        "621022100": "环城镇",
        "621022101": "曲子镇",
        "621022102": "甜水镇",
        "621022103": "木钵镇",
        "621022104": "洪德镇",
        "621022105": "合道镇",
        "621022106": "虎洞镇",
        "621022107": "毛井镇",
        "621022108": "樊家川镇",
        "621022109": "车道镇",
        "621022200": "天池乡",
        "621022201": "演武乡",
        "621022204": "八珠乡",
        "621022206": "耿湾乡",
        "621022207": "秦团庄乡",
        "621022208": "山城乡",
        "621022209": "南湫乡",
        "621022210": "罗山川乡",
        "621022212": "小南沟乡",
        "621022215": "芦家湾乡",
        "621022500": "四合原旅游开发办公室"
    },
    "621023": {
        "621023100": "悦乐镇",
        "621023101": "柔远镇",
        "621023102": "元城镇",
        "621023103": "南梁镇",
        "621023104": "城壕镇",
        "621023105": "五蛟镇",
        "621023202": "上里塬乡",
        "621023203": "王咀子乡",
        "621023204": "白马乡",
        "621023205": "怀安乡",
        "621023206": "乔川乡",
        "621023207": "乔河乡",
        "621023208": "山庄乡",
        "621023210": "林镇乡",
        "621023211": "紫坊畔乡"
    },
    "621024": {
        "621024100": "西华池镇",
        "621024101": "老城镇",
        "621024102": "太白镇",
        "621024103": "板桥镇",
        "621024104": "何家畔镇",
        "621024200": "吉岘乡",
        "621024201": "肖咀乡",
        "621024202": "段家集乡",
        "621024203": "固城乡",
        "621024204": "太莪乡",
        "621024205": "店子乡",
        "621024208": "蒿咀铺乡"
    },
    "621025": {
        "621025100": "山河镇",
        "621025101": "榆林子镇",
        "621025102": "宫河镇",
        "621025103": "永和镇",
        "621025104": "永正镇",
        "621025105": "周家镇",
        "621025106": "湫头镇",
        "621025107": "西坡镇",
        "621025204": "五顷原乡",
        "621025205": "三嘉乡"
    },
    "621026": {
        "621026100": "新宁镇",
        "621026101": "平子镇",
        "621026102": "早胜镇",
        "621026103": "长庆桥镇",
        "621026104": "和盛镇",
        "621026105": "湘乐镇",
        "621026106": "新庄镇",
        "621026107": "盘克镇",
        "621026108": "中村镇",
        "621026109": "焦村镇",
        "621026110": "米桥镇",
        "621026111": "良平镇",
        "621026112": "太昌镇",
        "621026113": "春荣镇",
        "621026205": "南义乡",
        "621026206": "瓦斜乡",
        "621026207": "金村乡",
        "621026208": "九岘乡"
    },
    "621027": {
        "621027100": "城关镇",
        "621027101": "屯字镇",
        "621027102": "孟坝镇",
        "621027103": "三岔镇",
        "621027104": "平泉镇",
        "621027105": "开边镇",
        "621027106": "太平镇",
        "621027107": "临泾镇",
        "621027108": "新城镇",
        "621027109": "上肖镇",
        "621027201": "南川乡",
        "621027203": "新集乡",
        "621027204": "方山乡",
        "621027205": "殷家城乡",
        "621027206": "马渠乡",
        "621027207": "庙渠乡",
        "621027208": "武沟乡",
        "621027209": "郭原乡",
        "621027210": "中原乡"
    },
    "621100": {
        "621102": "安定区",
        "621121": "通渭县",
        "621122": "陇西县",
        "621123": "渭源县",
        "621124": "临洮县",
        "621125": "漳县",
        "621126": "岷县"
    },
    "621102": {
        "621102001": "中华路街道",
        "621102002": "永定路街道",
        "621102003": "福台路街道",
        "621102101": "凤翔镇",
        "621102102": "内官营镇",
        "621102103": "巉口镇",
        "621102104": "称钩驿镇",
        "621102105": "鲁家沟镇",
        "621102106": "西巩驿镇",
        "621102107": "宁远镇",
        "621102108": "李家堡镇",
        "621102109": "团结镇",
        "621102110": "香泉镇",
        "621102111": "符家川镇",
        "621102112": "葛家岔镇",
        "621102204": "白碌乡",
        "621102207": "石峡湾乡",
        "621102208": "新集乡",
        "621102209": "青岚山乡",
        "621102210": "高峰乡",
        "621102211": "石泉乡",
        "621102214": "杏园乡"
    },
    "621121": {
        "621121100": "平襄镇",
        "621121101": "马营镇",
        "621121102": "鸡川镇",
        "621121103": "榜罗镇",
        "621121104": "常家河镇",
        "621121105": "义岗川镇",
        "621121106": "陇阳镇",
        "621121107": "陇山镇",
        "621121108": "陇川镇",
        "621121109": "碧玉镇",
        "621121110": "襄南镇",
        "621121111": "什川镇",
        "621121112": "华家岭镇",
        "621121113": "北城铺镇",
        "621121203": "新景乡",
        "621121207": "李家店乡",
        "621121212": "第三铺乡",
        "621121215": "寺子川乡"
    },
    "621122": {
        "621122100": "巩昌镇",
        "621122101": "文峰镇",
        "621122102": "首阳镇",
        "621122103": "菜子镇",
        "621122104": "福星镇",
        "621122105": "通安驿镇",
        "621122106": "云田镇",
        "621122107": "碧岩镇",
        "621122108": "马河镇",
        "621122109": "柯寨镇",
        "621122110": "双泉镇",
        "621122111": "权家湾镇",
        "621122200": "渭阳乡",
        "621122201": "宏伟乡",
        "621122202": "和平乡",
        "621122205": "德兴乡",
        "621122206": "永吉乡"
    },
    "621123": {
        "621123100": "清源镇",
        "621123101": "莲峰镇",
        "621123102": "会川镇",
        "621123103": "五竹镇",
        "621123104": "路园镇",
        "621123105": "北寨镇",
        "621123106": "新寨镇",
        "621123107": "麻家集镇",
        "621123108": "锹峪镇",
        "621123109": "庆坪镇",
        "621123110": "祁家庙镇",
        "621123111": "上湾镇",
        "621123203": "大安乡",
        "621123204": "秦祁乡",
        "621123209": "峡城乡",
        "621123210": "田家河乡"
    },
    "621124": {
        "621124100": "洮阳镇",
        "621124101": "八里铺镇",
        "621124102": "新添镇",
        "621124103": "辛店镇",
        "621124104": "太石镇",
        "621124105": "中铺镇",
        "621124106": "峡口镇",
        "621124107": "龙门镇",
        "621124108": "窑店镇",
        "621124109": "玉井镇",
        "621124110": "衙下集镇",
        "621124111": "南屏镇",
        "621124200": "红旗乡",
        "621124201": "上营乡",
        "621124202": "康家集乡",
        "621124203": "站滩乡",
        "621124204": "漫洼乡",
        "621124205": "连儿湾乡"
    },
    "621125": {
        "621125100": "武阳镇",
        "621125101": "三岔镇",
        "621125102": "新寺镇",
        "621125103": "金钟镇",
        "621125104": "盐井镇",
        "621125105": "殪虎桥镇",
        "621125106": "大草滩镇",
        "621125107": "四族镇",
        "621125108": "石川镇",
        "621125109": "贵清山镇",
        "621125204": "马泉乡",
        "621125208": "武当乡",
        "621125209": "东泉乡"
    },
    "621126": {
        "621126100": "岷阳镇",
        "621126101": "蒲麻镇",
        "621126102": "西寨镇",
        "621126103": "梅川镇",
        "621126104": "西江镇",
        "621126105": "闾井镇",
        "621126106": "十里镇",
        "621126107": "茶埠镇",
        "621126108": "中寨镇",
        "621126109": "清水镇",
        "621126110": "寺沟镇",
        "621126111": "麻子川镇",
        "621126112": "维新镇",
        "621126113": "禾驮镇",
        "621126114": "马坞镇",
        "621126204": "秦许乡",
        "621126207": "申都乡",
        "621126208": "锁龙乡"
    },
    "621200": {
        "621202": "武都区",
        "621221": "成县",
        "621222": "文县",
        "621223": "宕昌县",
        "621224": "康县",
        "621225": "西和县",
        "621226": "礼县",
        "621227": "徽县",
        "621228": "两当县"
    },
    "621202": {
        "621202001": "钟楼滩街道",
        "621202002": "吉石坝街道",
        "621202003": "江北街道",
        "621202004": "江南街道",
        "621202100": "城关镇",
        "621202101": "安化镇",
        "621202102": "东江镇",
        "621202103": "两水镇",
        "621202104": "汉王镇",
        "621202105": "洛塘镇",
        "621202106": "角弓镇",
        "621202107": "马街镇",
        "621202108": "三河镇",
        "621202109": "甘泉镇",
        "621202110": "鱼龙镇",
        "621202111": "琵琶镇",
        "621202112": "外纳镇",
        "621202113": "马营镇",
        "621202114": "柏林镇",
        "621202115": "姚寨镇",
        "621202116": "佛崖镇",
        "621202117": "石门镇",
        "621202118": "五马镇",
        "621202119": "裕河镇",
        "621202120": "汉林镇",
        "621202121": "桔柑镇",
        "621202122": "隆兴镇",
        "621202123": "黄坪镇",
        "621202124": "五库镇",
        "621202125": "三仓镇",
        "621202201": "坪垭藏族乡",
        "621202203": "蒲池乡",
        "621202211": "池坝乡",
        "621202218": "龙坝乡",
        "621202219": "龙凤乡",
        "621202221": "磨坝藏族乡",
        "621202225": "玉皇乡",
        "621202226": "郭河乡",
        "621202229": "枫相乡",
        "621202233": "月照乡"
    },
    "621221": {
        "621221100": "城关镇",
        "621221101": "黄渚镇",
        "621221102": "红川镇",
        "621221103": "小川镇",
        "621221104": "纸坊镇",
        "621221105": "抛沙镇",
        "621221107": "店村镇",
        "621221108": "王磨镇",
        "621221109": "陈院镇",
        "621221110": "沙坝镇",
        "621221111": "黄陈镇",
        "621221112": "鸡峰镇",
        "621221113": "苏元镇",
        "621221114": "索池镇",
        "621221203": "宋坪乡",
        "621221206": "二郎乡",
        "621221213": "镡河乡"
    },
    "621222": {
        "621222100": "城关镇",
        "621222101": "碧口镇",
        "621222102": "尚德镇",
        "621222103": "中寨镇",
        "621222104": "临江镇",
        "621222105": "桥头镇",
        "621222106": "梨坪镇",
        "621222107": "天池镇",
        "621222108": "堡子坝镇",
        "621222109": "石坊镇",
        "621222110": "石鸡坝镇",
        "621222111": "丹堡镇",
        "621222112": "中庙镇",
        "621222113": "范坝镇",
        "621222200": "铁楼藏族乡",
        "621222205": "刘家坪乡",
        "621222206": "玉垒乡",
        "621222211": "口头坝乡",
        "621222212": "尖山乡",
        "621222215": "舍书乡"
    },
    "621223": {
        "621223100": "城关镇",
        "621223101": "哈达铺镇",
        "621223102": "理川镇",
        "621223103": "南阳镇",
        "621223104": "官亭镇",
        "621223105": "沙湾镇",
        "621223106": "阿坞镇",
        "621223107": "南河镇",
        "621223108": "八力镇",
        "621223109": "临江铺镇",
        "621223110": "两河口镇",
        "621223206": "木耳乡",
        "621223207": "庞家乡",
        "621223209": "何家堡乡",
        "621223211": "贾河乡",
        "621223212": "将台乡",
        "621223213": "车拉乡",
        "621223214": "新城子藏族乡",
        "621223216": "好梯乡",
        "621223217": "韩院乡",
        "621223218": "竹院乡",
        "621223219": "兴化乡",
        "621223220": "甘江头乡",
        "621223223": "新寨乡",
        "621223224": "狮子乡"
    },
    "621224": {
        "621224100": "城关镇",
        "621224101": "平洛镇",
        "621224102": "大堡镇",
        "621224103": "岸门口镇",
        "621224104": "两河镇",
        "621224105": "长坝镇",
        "621224106": "云台镇",
        "621224107": "阳坝镇",
        "621224108": "王坝镇",
        "621224109": "碾坝镇",
        "621224110": "豆坝镇",
        "621224111": "望关镇",
        "621224112": "大南峪镇",
        "621224113": "周家坝镇",
        "621224114": "寺台镇",
        "621224115": "白杨镇",
        "621224116": "铜钱镇",
        "621224117": "三河坝镇",
        "621224204": "迷坝乡",
        "621224209": "店子乡",
        "621224214": "太石乡"
    },
    "621225": {
        "621225100": "汉源镇",
        "621225101": "长道镇",
        "621225102": "何坝镇",
        "621225103": "姜席镇",
        "621225104": "石峡镇",
        "621225105": "洛峪镇",
        "621225106": "西峪镇",
        "621225107": "马元镇",
        "621225108": "大桥镇",
        "621225109": "十里镇",
        "621225110": "石堡镇",
        "621225111": "兴隆镇",
        "621225112": "苏合镇",
        "621225113": "卢河镇",
        "621225114": "稍峪镇",
        "621225115": "西高山镇",
        "621225208": "晒经乡",
        "621225217": "蒿林乡",
        "621225218": "太石河乡",
        "621225220": "六巷乡"
    },
    "621226": {
        "621226100": "城关镇",
        "621226101": "盐官镇",
        "621226102": "石桥镇",
        "621226103": "白河镇",
        "621226104": "宽川镇",
        "621226105": "永兴镇",
        "621226106": "祁山镇",
        "621226107": "红河镇",
        "621226108": "永坪镇",
        "621226109": "中坝镇",
        "621226110": "罗坝镇",
        "621226111": "雷坝镇",
        "621226112": "崖城镇",
        "621226113": "洮坪镇",
        "621226114": "龙林镇",
        "621226115": "固城镇",
        "621226116": "江口镇",
        "621226117": "湫山镇",
        "621226118": "白关镇",
        "621226119": "桥头镇",
        "621226120": "王坝镇",
        "621226121": "滩坪镇",
        "621226203": "马河乡",
        "621226215": "上坪乡",
        "621226219": "雷王乡",
        "621226224": "沙金乡",
        "621226228": "草坪乡",
        "621226231": "肖良乡",
        "621226232": "三峪乡"
    },
    "621227": {
        "621227100": "城关镇",
        "621227101": "伏家镇",
        "621227102": "江洛镇",
        "621227103": "泥阳镇",
        "621227104": "柳林镇",
        "621227105": "嘉陵镇",
        "621227106": "永宁镇",
        "621227107": "银杏树镇",
        "621227108": "水阳镇",
        "621227109": "栗川镇",
        "621227110": "麻沿河镇",
        "621227111": "高桥镇",
        "621227112": "大河店镇",
        "621227208": "榆树乡",
        "621227211": "虞关乡"
    },
    "621228": {
        "621228100": "城关镇",
        "621228101": "站儿巷镇",
        "621228102": "西坡镇",
        "621228103": "杨店镇",
        "621228104": "显龙镇",
        "621228105": "云屏镇",
        "621228201": "左家乡",
        "621228203": "鱼池乡",
        "621228204": "兴化乡",
        "621228207": "张家乡",
        "621228210": "泰山乡",
        "621228212": "金洞乡"
    },
    "622900": {
        "622901": "临夏市",
        "622921": "临夏县",
        "622922": "康乐县",
        "622923": "永靖县",
        "622924": "广河县",
        "622925": "和政县",
        "622926": "东乡族自治县",
        "622927": "积石山保安族东乡族撒拉族自治县"
    },
    "622901": {
        "622901001": "城南街道",
        "622901002": "城北街道",
        "622901003": "东关街道",
        "622901004": "西关街道",
        "622901005": "八坊街道",
        "622901006": "红园街道",
        "622901007": "东区街道",
        "622901100": "城郊镇",
        "622901101": "枹罕镇",
        "622901102": "南龙镇",
        "622901103": "折桥镇"
    },
    "622921": {
        "622921100": "韩集镇",
        "622921101": "土桥镇",
        "622921102": "马集镇",
        "622921103": "莲花镇",
        "622921104": "新集镇",
        "622921105": "尹集镇",
        "622921106": "刁祁镇",
        "622921107": "北塬镇",
        "622921108": "黄泥湾镇",
        "622921200": "营滩乡",
        "622921201": "掌子沟乡",
        "622921202": "麻尼寺沟乡",
        "622921204": "漠泥沟乡",
        "622921208": "漫路乡",
        "622921209": "榆林乡",
        "622921210": "井沟乡",
        "622921213": "坡头乡",
        "622921214": "桥寺乡",
        "622921215": "先锋乡",
        "622921216": "河西乡",
        "622921217": "安家坡乡",
        "622921218": "南塬乡",
        "622921220": "红台乡",
        "622921223": "路盘乡",
        "622921224": "民主乡"
    },
    "622922": {
        "622922100": "附城镇",
        "622922101": "苏集镇",
        "622922102": "胭脂镇",
        "622922103": "景古镇",
        "622922104": "莲麓镇",
        "622922200": "康丰乡",
        "622922201": "虎关乡",
        "622922202": "流川乡",
        "622922203": "白王乡",
        "622922205": "八松乡",
        "622922206": "鸣鹿乡",
        "622922207": "八丹乡",
        "622922208": "上湾乡",
        "622922211": "草滩乡",
        "622922212": "五户乡"
    },
    "622923": {
        "622923100": "刘家峡镇",
        "622923101": "盐锅峡镇",
        "622923102": "太极镇",
        "622923103": "西河镇",
        "622923104": "三塬镇",
        "622923105": "岘塬镇",
        "622923106": "陈井镇",
        "622923107": "川城镇",
        "622923108": "王台镇",
        "622923109": "红泉镇",
        "622923200": "关山乡",
        "622923201": "徐顶乡",
        "622923202": "三条岘乡",
        "622923203": "坪沟乡",
        "622923206": "新寺乡",
        "622923207": "小岭乡",
        "622923208": "杨塔乡"
    },
    "622924": {
        "622924100": "城关镇",
        "622924101": "三甲集镇",
        "622924102": "祁家集镇",
        "622924103": "庄窠集镇",
        "622924104": "买家巷镇",
        "622924105": "齐家镇",
        "622924202": "水泉乡",
        "622924203": "官坊乡",
        "622924204": "阿力麻土东乡族乡"
    },
    "622925": {
        "622925100": "城关镇",
        "622925101": "三合镇",
        "622925102": "三十里铺镇",
        "622925103": "马家堡镇",
        "622925104": "买家集镇",
        "622925105": "松鸣镇",
        "622925106": "陈家集镇",
        "622925107": "罗家集镇",
        "622925108": "新营镇",
        "622925200": "梁家寺乡",
        "622925203": "卜家庄乡",
        "622925205": "新庄乡",
        "622925207": "达浪乡"
    },
    "622926": {
        "622926100": "锁南镇",
        "622926101": "达板镇",
        "622926102": "河滩镇",
        "622926103": "那勒寺镇",
        "622926104": "唐汪镇",
        "622926105": "果园镇",
        "622926106": "汪集镇",
        "622926107": "龙泉镇",
        "622926200": "春台乡",
        "622926201": "柳树乡",
        "622926202": "东塬乡",
        "622926204": "坪庄乡",
        "622926205": "百和乡",
        "622926206": "关卜乡",
        "622926208": "赵家乡",
        "622926209": "五家乡",
        "622926212": "沿岭乡",
        "622926214": "风山乡",
        "622926215": "车家湾乡",
        "622926216": "高山乡",
        "622926219": "大树乡",
        "622926220": "北岭乡",
        "622926222": "考勒乡",
        "622926223": "董岭乡"
    },
    "622927": {
        "622927100": "吹麻滩镇",
        "622927101": "大河家镇",
        "622927102": "居集镇",
        "622927103": "癿藏镇",
        "622927200": "刘集乡",
        "622927201": "石塬乡",
        "622927202": "柳沟乡",
        "622927203": "关家川乡",
        "622927204": "胡林家乡",
        "622927205": "安集乡",
        "622927206": "寨子沟乡",
        "622927207": "郭干乡",
        "622927208": "徐扈家乡",
        "622927209": "中咀岭乡",
        "622927210": "小关乡",
        "622927211": "铺川乡",
        "622927212": "银川乡"
    },
    "623000": {
        "623001": "合作市",
        "623021": "临潭县",
        "623022": "卓尼县",
        "623023": "舟曲县",
        "623024": "迭部县",
        "623025": "玛曲县",
        "623026": "碌曲县",
        "623027": "夏河县"
    },
    "623001": {
        "623001001": "当周街道",
        "623001002": "伊合昂街道",
        "623001003": "坚木克尔街道",
        "623001004": "通钦街道",
        "623001100": "那吾镇",
        "623001101": "勒秀镇",
        "623001102": "佐盖曼玛镇",
        "623001200": "卡加曼乡",
        "623001201": "卡加道乡",
        "623001202": "佐盖多玛乡",
        "623001400": "甘南州综合实验场",
        "623001401": "合作市苗圃"
    },
    "623021": {
        "623021100": "城关镇",
        "623021101": "新城镇",
        "623021102": "冶力关镇",
        "623021103": "羊永镇",
        "623021104": "王旗镇",
        "623021105": "古战镇",
        "623021106": "洮滨镇",
        "623021107": "八角镇",
        "623021108": "流顺镇",
        "623021109": "店子镇",
        "623021110": "羊沙镇",
        "623021200": "术布乡",
        "623021202": "卓洛乡",
        "623021203": "长川乡",
        "623021208": "三岔乡",
        "623021210": "石门乡"
    },
    "623022": {
        "623022100": "柳林镇",
        "623022101": "木耳镇",
        "623022102": "扎古录镇",
        "623022103": "喀尔钦镇",
        "623022104": "藏巴哇镇",
        "623022105": "纳浪镇",
        "623022106": "洮砚镇",
        "623022107": "阿子滩镇",
        "623022108": "申藏镇",
        "623022109": "完冒镇",
        "623022110": "尼巴镇",
        "623022202": "刀告乡",
        "623022207": "恰盖乡",
        "623022208": "康多乡",
        "623022209": "勺哇土族乡",
        "623022400": "卓尼县林场",
        "623022401": "国营大峪牧场",
        "623022402": "国营柏林牧场"
    },
    "623023": {
        "623023100": "城关镇",
        "623023101": "大川镇",
        "623023102": "峰迭镇",
        "623023103": "立节镇",
        "623023104": "东山镇",
        "623023105": "曲告纳镇",
        "623023106": "博峪镇",
        "623023107": "巴藏镇",
        "623023108": "憨班镇",
        "623023109": "坪定镇",
        "623023110": "果耶镇",
        "623023111": "武坪镇",
        "623023112": "大峪镇",
        "623023113": "江盘镇",
        "623023114": "拱坝镇",
        "623023200": "曲瓦乡",
        "623023208": "南峪乡",
        "623023210": "八楞乡",
        "623023212": "插岗乡",
        "623023400": "舟曲县林场"
    },
    "623024": {
        "623024100": "电尕镇",
        "623024101": "益哇镇",
        "623024102": "旺藏镇",
        "623024103": "腊子口镇",
        "623024104": "洛大镇",
        "623024201": "卡坝乡",
        "623024202": "达拉乡",
        "623024203": "尼傲乡",
        "623024205": "阿夏乡",
        "623024206": "多儿乡",
        "623024207": "桑坝乡",
        "623024400": "国营迭部县林场"
    },
    "623025": {
        "623025100": "尼玛镇",
        "623025101": "曼日玛镇",
        "623025102": "阿万仓镇",
        "623025103": "齐哈玛镇",
        "623025104": "采日玛镇",
        "623025105": "欧拉镇",
        "623025201": "欧拉秀玛乡",
        "623025203": "木西合乡",
        "623025400": "河曲马场",
        "623025401": "大水军牧场",
        "623025402": "高原生物研究所"
    },
    "623026": {
        "623026100": "郎木寺镇",
        "623026101": "玛艾镇",
        "623026102": "西仓镇",
        "623026103": "尕海镇",
        "623026104": "双岔镇",
        "623026202": "拉仁关乡",
        "623026204": "阿拉乡",
        "623026402": "双岔林场",
        "623026403": "李恰如种畜场"
    },
    "623027": {
        "623027100": "拉卜楞镇",
        "623027101": "王格尔塘镇",
        "623027102": "阿木去乎镇",
        "623027103": "桑科镇",
        "623027104": "甘加镇",
        "623027105": "麻当镇",
        "623027106": "博拉镇",
        "623027107": "科才镇",
        "623027202": "达麦乡",
        "623027204": "曲奥乡",
        "623027205": "唐尕昂乡",
        "623027206": "扎油乡",
        "623027208": "吉仓乡",
        "623027400": "夏河县种子站",
        "623027401": "夏河县机饲总站",
        "623027402": "夏河县桑科种羊场"
    },
    "630000": {
        "630100": "西宁市",
        "630200": "海东市",
        "632200": "海北藏族自治州",
        "632300": "黄南藏族自治州",
        "632500": "海南藏族自治州",
        "632600": "果洛藏族自治州",
        "632700": "玉树藏族自治州",
        "632800": "海西蒙古族藏族自治州"
    },
    "630100": {
        "630102": "城东区",
        "630103": "城中区",
        "630104": "城西区",
        "630105": "城北区",
        "630121": "大通回族土族自治县",
        "630122": "湟中县",
        "630123": "湟源县"
    },
    "630102": {
        "630102001": "东关大街社区公共服务中心",
        "630102002": "清真巷社区公共服务中心",
        "630102003": "大众街社区公共服务中心",
        "630102004": "周家泉社区公共服务中心",
        "630102005": "火车站社区公共服务中心",
        "630102006": "康乐社区公共服务中心",
        "630102007": "林家崖社区公共服务中心",
        "630102008": "国际村社区公共服务中心",
        "630102009": "富强巷社区公共服务中心",
        "630102010": "青藏花园社区公共服务中心",
        "630102011": "泰宁社区公共服务中心",
        "630102012": "互助中路社区公共服务中心",
        "630102100": "乐家湾镇政府",
        "630102101": "韵家口镇政府"
    },
    "630103": {
        "630103001": "人民街街道",
        "630103002": "南滩街道",
        "630103003": "仓门街街道",
        "630103004": "礼让街街道",
        "630103005": "饮马街街道",
        "630103006": "南川东路街道",
        "630103007": "南川西路街道",
        "630103100": "总寨镇",
        "630103400": "南川工业园"
    },
    "630104": {
        "630104001": "西关大街街道",
        "630104002": "古城台街道",
        "630104003": "虎台街道",
        "630104004": "胜利路街道",
        "630104005": "兴海路街道",
        "630104006": "文汇路街道",
        "630104007": "通海路街道",
        "630104100": "彭家寨镇"
    },
    "630105": {
        "630105001": "朝阳街道",
        "630105002": "小桥大街街道",
        "630105003": "马坊街道",
        "630105004": "火车西站",
        "630105100": "大堡子镇",
        "630105101": "廿里铺镇",
        "630105400": "生物科技产业园"
    },
    "630121": {
        "630121100": "桥头镇",
        "630121101": "城关镇",
        "630121102": "塔尔镇",
        "630121103": "东峡镇",
        "630121104": "黄家寨镇",
        "630121105": "长宁镇",
        "630121106": "景阳镇",
        "630121107": "多林镇",
        "630121108": "新庄镇",
        "630121200": "青林乡",
        "630121201": "青山乡",
        "630121202": "逊让乡",
        "630121203": "极乐乡",
        "630121204": "石山乡",
        "630121205": "宝库乡",
        "630121206": "斜沟乡",
        "630121207": "良教乡",
        "630121208": "向化藏族乡",
        "630121209": "桦林乡",
        "630121210": "朔北藏族乡"
    },
    "630122": {
        "630122001": "康川街道",
        "630122100": "鲁沙尔镇",
        "630122101": "西堡镇",
        "630122102": "上新庄镇",
        "630122103": "田家寨镇",
        "630122104": "甘河滩镇",
        "630122105": "共和镇",
        "630122106": "多巴镇",
        "630122107": "拦隆口镇",
        "630122108": "上五庄镇",
        "630122109": "李家山镇",
        "630122200": "群加乡",
        "630122201": "土门关乡",
        "630122202": "汉东乡",
        "630122203": "大才乡",
        "630122204": "海子沟乡",
        "630122400": "甘河工业园"
    },
    "630123": {
        "630123100": "城关镇",
        "630123101": "大华镇",
        "630123200": "东峡乡",
        "630123201": "日月乡",
        "630123202": "和平乡",
        "630123203": "波航乡",
        "630123204": "申中乡",
        "630123205": "巴燕乡",
        "630123206": "寺寨乡"
    },
    "630200": {
        "630202": "乐都区",
        "630203": "平安区",
        "630222": "民和回族土族自治县",
        "630223": "互助土族自治县",
        "630224": "化隆回族自治县",
        "630225": "循化撒拉族自治县"
    },
    "630202": {
        "630202100": "碾伯镇",
        "630202101": "雨润镇",
        "630202102": "寿乐镇",
        "630202103": "高庙镇",
        "630202104": "洪水镇",
        "630202105": "高店镇",
        "630202106": "瞿昙镇",
        "630202200": "共和乡",
        "630202201": "中岭乡",
        "630202202": "李家乡",
        "630202203": "下营乡",
        "630202204": "芦花乡",
        "630202205": "马营乡",
        "630202206": "马厂乡",
        "630202207": "蒲台乡",
        "630202208": "中坝乡",
        "630202209": "峰堆乡",
        "630202210": "城台乡",
        "630202211": "达拉乡",
        "630202400": "海东工业园区乐都工业园"
    },
    "630203": {
        "630203100": "平安镇",
        "630203101": "小峡镇",
        "630203102": "三合镇",
        "630203200": "洪水泉乡",
        "630203201": "石灰窑乡",
        "630203202": "古城乡",
        "630203203": "沙沟乡",
        "630203204": "巴藏沟乡",
        "630203400": "曹家堡临空综合经济园平安园区"
    },
    "630222": {
        "630222100": "川口镇",
        "630222101": "古鄯镇",
        "630222102": "马营镇",
        "630222103": "官亭镇",
        "630222104": "巴州镇",
        "630222105": "满坪镇",
        "630222106": "李二堡镇",
        "630222107": "峡门镇",
        "630222200": "马场垣乡",
        "630222201": "北山乡",
        "630222202": "松树乡",
        "630222203": "西沟乡",
        "630222204": "总堡乡",
        "630222205": "隆治乡",
        "630222206": "大庄乡",
        "630222207": "转导乡",
        "630222208": "前河乡",
        "630222209": "甘沟乡",
        "630222210": "中川乡",
        "630222211": "杏儿乡",
        "630222212": "核桃庄乡",
        "630222213": "新民乡"
    },
    "630223": {
        "630223100": "威远镇",
        "630223101": "丹麻镇",
        "630223102": "高寨镇",
        "630223103": "南门峡镇",
        "630223104": "加定镇",
        "630223105": "塘川镇",
        "630223106": "五十镇",
        "630223107": "五峰镇",
        "630223200": "台子乡",
        "630223201": "西山乡",
        "630223202": "红崖子沟乡",
        "630223203": "巴扎藏族乡",
        "630223204": "哈拉直沟乡",
        "630223205": "松多藏族乡",
        "630223206": "东山乡",
        "630223207": "东和乡",
        "630223208": "东沟乡",
        "630223209": "林川乡",
        "630223210": "蔡家堡乡",
        "630223400": "曹家堡临空综合经济园互助园区"
    },
    "630224": {
        "630224100": "巴燕镇",
        "630224101": "群科镇",
        "630224102": "牙什尕镇",
        "630224103": "甘都镇",
        "630224104": "扎巴镇",
        "630224105": "昂思多镇",
        "630224200": "雄先藏族乡",
        "630224201": "初麻乡",
        "630224202": "查甫藏族乡",
        "630224203": "塔加藏族乡",
        "630224204": "金源藏族乡",
        "630224205": "二塘乡",
        "630224206": "谢家滩乡",
        "630224207": "德恒隆乡",
        "630224208": "沙连堡乡",
        "630224209": "阿什奴乡",
        "630224210": "石大仓乡",
        "630224400": "李家峡管理委员会",
        "630224401": "公伯峡管理委员会"
    },
    "630225": {
        "630225100": "积石镇",
        "630225101": "白庄镇",
        "630225102": "街子镇",
        "630225200": "道帏藏族乡",
        "630225201": "清水乡",
        "630225202": "岗察藏族乡",
        "630225203": "查汗都斯乡",
        "630225204": "文都藏族乡",
        "630225205": "尕楞藏族乡"
    },
    "632200": {"632221": "门源回族自治县", "632222": "祁连县", "632223": "海晏县", "632224": "刚察县"},
    "632221": {
        "632221100": "浩门镇",
        "632221101": "青石咀镇",
        "632221102": "泉口镇",
        "632221103": "东川镇",
        "632221200": "北山乡",
        "632221201": "麻莲乡",
        "632221202": "西滩乡",
        "632221203": "阴田乡",
        "632221204": "仙米乡",
        "632221205": "珠固乡",
        "632221206": "苏吉滩乡",
        "632221207": "皇城蒙古族乡",
        "632221400": "门源监狱",
        "632221401": "门源种马场"
    },
    "632222": {
        "632222100": "八宝镇",
        "632222101": "峨堡镇",
        "632222102": "默勒镇",
        "632222200": "扎麻什乡",
        "632222201": "阿柔乡",
        "632222202": "野牛沟乡",
        "632222203": "央隆乡"
    },
    "632223": {
        "632223100": "三角城镇",
        "632223101": "西海镇",
        "632223200": "金滩乡",
        "632223201": "哈勒景蒙古族乡",
        "632223202": "青海湖乡",
        "632223203": "甘子河乡"
    },
    "632224": {"632224100": "沙柳河镇", "632224101": "哈尔盖镇", "632224200": "伊克乌兰乡", "632224201": "泉吉乡", "632224202": "吉尔孟乡"},
    "632300": {"632321": "同仁县", "632322": "尖扎县", "632323": "泽库县", "632324": "河南蒙古族自治县"},
    "632321": {
        "632321100": "隆务镇",
        "632321101": "保安镇",
        "632321102": "多哇镇",
        "632321200": "兰采乡",
        "632321201": "双朋西乡",
        "632321202": "扎毛乡",
        "632321203": "黄乃亥乡",
        "632321204": "曲库乎乡",
        "632321205": "年都乎乡",
        "632321207": "瓜什则乡",
        "632321208": "加吾乡"
    },
    "632322": {
        "632322100": "马克堂镇",
        "632322101": "康扬镇",
        "632322102": "坎布拉镇",
        "632322200": "贾加乡",
        "632322201": "措周乡",
        "632322202": "昂拉乡",
        "632322203": "能科乡",
        "632322204": "当顺乡",
        "632322205": "尖扎滩乡"
    },
    "632323": {
        "632323100": "泽曲镇",
        "632323101": "麦秀镇",
        "632323102": "和日镇",
        "632323200": "宁秀乡",
        "632323202": "王加乡",
        "632323203": "西卜沙乡",
        "632323204": "多禾茂乡"
    },
    "632324": {
        "632324100": "优干宁镇",
        "632324101": "宁木特镇",
        "632324201": "多松乡",
        "632324202": "赛尔龙乡",
        "632324203": "柯生乡",
        "632324204": "托叶玛乡"
    },
    "632500": {"632521": "共和县", "632522": "同德县", "632523": "贵德县", "632524": "兴海县", "632525": "贵南县"},
    "632521": {
        "632521100": "恰卜恰镇",
        "632521101": "倒淌河镇",
        "632521102": "龙羊峡镇",
        "632521103": "塘格木镇",
        "632521104": "黑马河镇",
        "632521105": "石乃亥镇",
        "632521106": "江西沟镇",
        "632521202": "沙珠玉乡",
        "632521203": "铁盖乡",
        "632521204": "廿地乡",
        "632521205": "切吉乡",
        "632521401": "湖东种羊场",
        "632521402": "巴卡台农场",
        "632521403": "安置农场",
        "632521404": "铁卜加草改站"
    },
    "632522": {
        "632522100": "尕巴松多镇",
        "632522101": "唐谷镇",
        "632522200": "巴沟乡",
        "632522201": "秀麻乡",
        "632522202": "河北乡",
        "632522400": "省牧草良种繁殖场"
    },
    "632523": {
        "632523100": "河阴镇",
        "632523101": "河西镇",
        "632523102": "拉西瓦镇",
        "632523103": "常牧镇",
        "632523200": "河东乡",
        "632523201": "新街回族乡",
        "632523202": "尕让乡"
    },
    "632524": {
        "632524100": "子科滩镇",
        "632524101": "河卡镇",
        "632524102": "曲什安镇",
        "632524200": "温泉乡",
        "632524201": "龙藏乡",
        "632524202": "中铁乡",
        "632524203": "唐乃亥乡",
        "632524400": "河卡种羊场"
    },
    "632525": {
        "632525100": "茫曲镇",
        "632525101": "过马营镇",
        "632525102": "森多镇",
        "632525200": "沙沟乡",
        "632525202": "茫拉乡",
        "632525203": "塔秀乡",
        "632525400": "贵南草业开发有限责任公司"
    },
    "632600": {"632621": "玛沁县", "632622": "班玛县", "632623": "甘德县", "632624": "达日县", "632625": "久治县", "632626": "玛多县"},
    "632621": {
        "632621100": "大武镇",
        "632621101": "拉加镇",
        "632621200": "大武乡",
        "632621201": "东倾沟乡",
        "632621202": "雪山乡",
        "632621203": "下大武乡",
        "632621204": "优云乡",
        "632621205": "当洛乡"
    },
    "632622": {
        "632622100": "赛来塘镇",
        "632622200": "多贡麻乡",
        "632622201": "马可河乡",
        "632622202": "吉卡乡",
        "632622203": "达卡乡",
        "632622204": "知钦乡",
        "632622205": "江日堂乡",
        "632622206": "亚尔堂乡",
        "632622207": "灯塔乡"
    },
    "632623": {
        "632623100": "柯曲镇",
        "632623200": "上贡麻乡",
        "632623201": "下贡麻乡",
        "632623202": "岗龙乡",
        "632623203": "青珍乡",
        "632623204": "江千乡",
        "632623205": "下藏科乡"
    },
    "632624": {
        "632624100": "吉迈镇",
        "632624200": "满掌乡",
        "632624201": "德昂乡",
        "632624202": "窝赛乡",
        "632624203": "莫坝乡",
        "632624204": "上红科乡",
        "632624205": "下红科乡",
        "632624206": "建设乡",
        "632624207": "桑日麻乡",
        "632624208": "特合土乡"
    },
    "632625": {
        "632625100": "智青松多镇",
        "632625200": "门堂乡",
        "632625201": "哇赛乡",
        "632625202": "索呼日麻乡",
        "632625203": "白玉乡",
        "632625204": "哇尔依乡"
    },
    "632626": {"632626100": "玛查理镇", "632626101": "花石峡镇", "632626200": "黄河乡", "632626201": "扎陵湖乡"},
    "632700": {"632701": "玉树市", "632722": "杂多县", "632723": "称多县", "632724": "治多县", "632725": "囊谦县", "632726": "曲麻莱县"},
    "632701": {
        "632701001": "结古镇街道",
        "632701002": "扎西科街道",
        "632701003": "西杭街道",
        "632701004": "新寨街道",
        "632701101": "隆宝镇",
        "632701102": "下拉秀镇",
        "632701200": "仲达乡",
        "632701201": "巴塘乡",
        "632701202": "小苏莽乡",
        "632701203": "上拉秀乡",
        "632701204": "哈秀乡",
        "632701205": "安冲乡"
    },
    "632722": {
        "632722100": "萨呼腾镇",
        "632722200": "昂赛乡",
        "632722201": "结多乡",
        "632722202": "阿多乡",
        "632722203": "苏鲁乡",
        "632722204": "查旦乡",
        "632722205": "莫云乡",
        "632722206": "扎青乡"
    },
    "632723": {
        "632723100": "称文镇",
        "632723101": "歇武镇",
        "632723102": "扎朵镇",
        "632723103": "清水河镇",
        "632723104": "珍秦镇",
        "632723200": "尕朵乡",
        "632723201": "拉布乡"
    },
    "632724": {
        "632724100": "加吉博洛格镇",
        "632724200": "索加乡",
        "632724201": "扎河乡",
        "632724202": "多彩乡",
        "632724203": "治渠乡",
        "632724204": "立新乡"
    },
    "632725": {
        "632725100": "香达镇",
        "632725200": "白扎乡",
        "632725201": "吉曲乡",
        "632725202": "娘拉乡",
        "632725203": "毛庄乡",
        "632725204": "觉拉乡",
        "632725205": "东坝乡",
        "632725206": "尕羊乡",
        "632725207": "吉尼赛乡",
        "632725208": "着晓乡"
    },
    "632726": {
        "632726100": "约改镇",
        "632726200": "巴干乡",
        "632726201": "秋智乡",
        "632726202": "叶格乡",
        "632726203": "麻多乡",
        "632726204": "曲麻河乡"
    },
    "632800": {
        "632801": "格尔木市",
        "632802": "德令哈市",
        "632803": "茫崖市",
        "632821": "乌兰县",
        "632822": "都兰县",
        "632823": "天峻县",
        "632857": "大柴旦行政委员会"
    },
    "632801": {
        "632801001": "昆仑路街道",
        "632801002": "黄河路街道",
        "632801003": "金峰路街道",
        "632801004": "河西街道",
        "632801005": "西藏路街道",
        "632801100": "郭勒木德镇",
        "632801101": "唐古拉镇",
        "632801200": "大格勒乡",
        "632801201": "乌图美仁乡",
        "632801400": "察尔汗工行委",
        "632801401": "格尔木农垦有限公司"
    },
    "632802": {
        "632802001": "河西街道",
        "632802002": "河东街道",
        "632802003": "火车站街道",
        "632802100": "尕海镇",
        "632802101": "怀头他拉镇",
        "632802102": "柯鲁柯镇",
        "632802200": "蓄集乡"
    },
    "632803": {"632803100": "花土沟镇", "632803101": "茫崖镇", "632803102": "冷湖镇"},
    "632821": {
        "632821100": "希里沟镇",
        "632821101": "茶卡镇",
        "632821102": "柯柯镇",
        "632821103": "铜普镇",
        "632821500": "海西州莫河畜牧场"
    },
    "632822": {
        "632822100": "察汉乌苏镇",
        "632822101": "香日德镇",
        "632822102": "夏日哈镇",
        "632822103": "宗加镇",
        "632822200": "热水乡",
        "632822201": "香加乡",
        "632822202": "沟里乡",
        "632822203": "巴隆乡"
    },
    "632823": {
        "632823100": "新源镇",
        "632823101": "木里镇",
        "632823102": "江河镇",
        "632823200": "快尔玛乡",
        "632823201": "舟群乡",
        "632823202": "织合玛乡",
        "632823203": "苏里乡",
        "632823204": "生格乡",
        "632823205": "阳康乡",
        "632823206": "龙门乡"
    },
    "632857": {"632857100": "柴旦镇", "632857101": "锡铁山镇"},
    "640000": {"640100": "银川市", "640200": "石嘴山市", "640300": "吴忠市", "640400": "固原市", "640500": "中卫市"},
    "640100": {"640104": "兴庆区", "640105": "西夏区", "640106": "金凤区", "640121": "永宁县", "640122": "贺兰县", "640181": "灵武市"},
    "640104": {
        "640104001": "凤凰北街街道",
        "640104002": "解放西街街道",
        "640104003": "文化街街道",
        "640104004": "富宁街街道",
        "640104005": "新华街街道",
        "640104006": "玉皇阁北街街道",
        "640104007": "前进街街道",
        "640104008": "中山南街街道",
        "640104009": "银古路街道",
        "640104010": "胜利街街道",
        "640104011": "丽景街街道",
        "640104100": "掌政镇",
        "640104101": "大新镇",
        "640104200": "通贵乡",
        "640104201": "月牙湖乡",
        "640104400": "燕鸽湖管委会"
    },
    "640105": {
        "640105001": "西花园街道",
        "640105002": "北京西路街道",
        "640105003": "文昌路街道",
        "640105004": "朔方路街道",
        "640105005": "宁华路街道",
        "640105006": "贺兰山西路街道",
        "640105007": "怀远路街道",
        "640105100": "兴泾镇",
        "640105101": "镇北堡镇"
    },
    "640106": {
        "640106001": "满城北街街道",
        "640106002": "黄河东路街道",
        "640106003": "长城中路街道",
        "640106004": "北京中路街道",
        "640106005": "上海西路街道",
        "640106100": "良田镇",
        "640106101": "丰登镇"
    },
    "640121": {
        "640121001": "团结西路街道",
        "640121100": "杨和镇",
        "640121101": "李俊镇",
        "640121102": "望远镇",
        "640121103": "望洪镇",
        "640121104": "闽宁镇",
        "640121200": "胜利乡",
        "640121400": "黄羊滩农场",
        "640121401": "玉泉营农场"
    },
    "640122": {
        "640122100": "习岗镇",
        "640122101": "金贵镇",
        "640122102": "立岗镇",
        "640122103": "洪广镇",
        "640122200": "常信乡",
        "640122400": "南梁台子管委会",
        "640122401": "暖泉农场",
        "640122402": "宁夏原种场",
        "640122403": "京星农牧场"
    },
    "640181": {
        "640181001": "城区街道",
        "640181100": "东塔镇",
        "640181101": "郝家桥镇",
        "640181102": "崇兴镇",
        "640181103": "宁东镇",
        "640181104": "马家滩镇",
        "640181105": "临河镇",
        "640181200": "梧桐树乡",
        "640181201": "白土岗乡",
        "640181400": "灵武农场",
        "640181401": "狼皮子梁管委会"
    },
    "640200": {"640202": "大武口区", "640205": "惠农区", "640221": "平罗县"},
    "640202": {
        "640202001": "长胜街道",
        "640202002": "朝阳街道",
        "640202003": "人民路街道",
        "640202004": "长城街道",
        "640202005": "青山街道",
        "640202006": "石炭井街道",
        "640202007": "白芨沟街道",
        "640202008": "沟口街道",
        "640202010": "长兴街道",
        "640202011": "锦林街道",
        "640202100": "星海镇"
    },
    "640205": {
        "640205001": "育才路街道",
        "640205002": "南街街道",
        "640205003": "中街街道",
        "640205004": "北街街道",
        "640205005": "河滨街道",
        "640205006": "火车站街道",
        "640205100": "红果子镇",
        "640205101": "尾闸镇",
        "640205102": "园艺镇",
        "640205200": "庙台乡",
        "640205201": "礼和乡",
        "640205202": "燕子墩乡",
        "640205401": "简泉农场",
        "640205402": "国营农林牧场",
        "640205403": "良种繁殖场"
    },
    "640221": {
        "640221100": "城关镇",
        "640221101": "黄渠桥镇",
        "640221102": "宝丰镇",
        "640221103": "头闸镇",
        "640221104": "姚伏镇",
        "640221105": "崇岗镇",
        "640221106": "陶乐镇",
        "640221200": "高庄乡",
        "640221201": "灵沙乡",
        "640221202": "渠口乡",
        "640221203": "通伏乡",
        "640221204": "高仁乡",
        "640221205": "红崖子乡",
        "640221400": "前进农场"
    },
    "640300": {"640302": "利通区", "640303": "红寺堡区", "640323": "盐池县", "640324": "同心县", "640381": "青铜峡市"},
    "640302": {
        "640302100": "金积镇",
        "640302101": "金银滩镇",
        "640302102": "高闸镇",
        "640302103": "扁担沟镇",
        "640302104": "上桥镇",
        "640302105": "古城镇",
        "640302106": "金星镇",
        "640302107": "胜利镇",
        "640302200": "东塔寺乡",
        "640302201": "板桥乡",
        "640302202": "马莲渠乡",
        "640302203": "郭家桥乡",
        "640302400": "巴浪湖农场",
        "640302401": "孙家滩管委会"
    },
    "640303": {
        "640303001": "红寺堡区新民街道",
        "640303100": "红寺堡区红寺堡镇",
        "640303101": "红寺堡区太阳山镇",
        "640303200": "红寺堡区大河乡",
        "640303201": "红寺堡区新庄集乡",
        "640303202": "红寺堡区柳泉乡",
        "640303400": "红寺堡工业园区管委会"
    },
    "640323": {
        "640323100": "花马池镇",
        "640323101": "大水坑镇",
        "640323102": "惠安堡镇",
        "640323103": "高沙窝镇",
        "640323200": "王乐井乡",
        "640323201": "冯记沟乡",
        "640323202": "青山乡",
        "640323203": "麻黄山乡"
    },
    "640324": {
        "640324100": "豫海镇",
        "640324101": "河西镇",
        "640324102": "韦州镇",
        "640324103": "下马关镇",
        "640324104": "预旺镇",
        "640324105": "王团镇",
        "640324106": "丁塘镇",
        "640324201": "田老庄乡",
        "640324202": "马高庄乡",
        "640324203": "张家塬乡",
        "640324204": "兴隆乡"
    },
    "640381": {
        "640381001": "裕民街道",
        "640381100": "小坝镇",
        "640381101": "大坝镇",
        "640381102": "青铜峡镇",
        "640381103": "叶盛镇",
        "640381104": "瞿靖镇",
        "640381105": "峡口镇",
        "640381106": "邵岗镇",
        "640381107": "陈袁滩镇",
        "640381400": "连湖农场",
        "640381401": "树新林场"
    },
    "640400": {"640402": "原州区", "640422": "西吉县", "640423": "隆德县", "640424": "泾源县", "640425": "彭阳县"},
    "640402": {
        "640402001": "南关街道",
        "640402002": "古雁街道",
        "640402003": "北塬街道",
        "640402100": "三营镇",
        "640402103": "官厅镇",
        "640402104": "开城镇",
        "640402105": "张易镇",
        "640402106": "彭堡镇",
        "640402107": "头营镇",
        "640402108": "黄铎堡镇",
        "640402200": "中河乡",
        "640402201": "河川乡",
        "640402204": "炭山乡",
        "640402205": "寨科乡"
    },
    "640422": {
        "640422100": "吉强镇",
        "640422101": "兴隆镇",
        "640422102": "平峰镇",
        "640422103": "将台堡镇",
        "640422200": "新营乡",
        "640422201": "红耀乡",
        "640422202": "田坪乡",
        "640422203": "马建乡",
        "640422204": "震湖乡",
        "640422205": "兴平乡",
        "640422206": "西滩乡",
        "640422207": "王民乡",
        "640422208": "什字乡",
        "640422209": "马莲乡",
        "640422211": "硝河乡",
        "640422212": "偏城乡",
        "640422213": "沙沟乡",
        "640422214": "白崖乡",
        "640422215": "火石寨乡"
    },
    "640423": {
        "640423100": "城关镇",
        "640423101": "沙塘镇",
        "640423102": "联财镇",
        "640423200": "陈靳乡",
        "640423201": "好水乡",
        "640423202": "观庄乡",
        "640423203": "杨河乡",
        "640423204": "神林乡",
        "640423205": "张程乡",
        "640423206": "凤岭乡",
        "640423207": "山河乡",
        "640423208": "温堡乡",
        "640423209": "奠安乡"
    },
    "640424": {
        "640424100": "香水镇",
        "640424101": "泾河源镇",
        "640424102": "六盘山镇",
        "640424200": "新民乡",
        "640424201": "兴盛乡",
        "640424202": "黄花乡",
        "640424203": "大湾乡"
    },
    "640425": {
        "640425100": "白阳镇",
        "640425101": "王洼镇",
        "640425102": "古城镇",
        "640425103": "红河镇",
        "640425200": "新集乡",
        "640425201": "城阳乡",
        "640425203": "冯庄乡",
        "640425204": "小岔乡",
        "640425205": "孟塬乡",
        "640425206": "罗洼乡",
        "640425207": "交岔乡",
        "640425208": "草庙乡"
    },
    "640500": {"640502": "沙坡头区", "640521": "中宁县", "640522": "海原县"},
    "640502": {
        "640502100": "滨河镇",
        "640502101": "文昌镇",
        "640502102": "东园镇",
        "640502103": "柔远镇",
        "640502104": "镇罗镇",
        "640502105": "宣和镇",
        "640502106": "永康镇",
        "640502107": "常乐镇",
        "640502108": "迎水桥镇",
        "640502109": "兴仁镇",
        "640502200": "香山乡",
        "640502400": "中卫山羊选育场",
        "640502401": "中卫工业园区"
    },
    "640521": {
        "640521100": "宁安镇",
        "640521101": "鸣沙镇",
        "640521102": "石空镇",
        "640521103": "新堡镇",
        "640521104": "恩和镇",
        "640521105": "大战场镇",
        "640521200": "舟塔乡",
        "640521201": "白马乡",
        "640521202": "余丁乡",
        "640521204": "喊叫水乡",
        "640521205": "徐套乡",
        "640521206": "太阳梁乡"
    },
    "640522": {
        "640522100": "海城镇",
        "640522101": "李旺镇",
        "640522103": "西安镇",
        "640522104": "三河镇",
        "640522105": "七营镇",
        "640522200": "史店乡",
        "640522201": "树台乡",
        "640522202": "关桥乡",
        "640522206": "高崖乡",
        "640522207": "郑旗乡",
        "640522208": "贾塘乡",
        "640522209": "曹洼乡",
        "640522210": "九彩乡",
        "640522211": "李俊乡",
        "640522212": "红羊乡",
        "640522213": "关庄乡",
        "640522214": "甘城乡",
        "640522400": "种羊场",
        "640522401": "涵养林总场",
        "640522402": "老城管理办公室",
        "640522406": "海兴开发区"
    },
    "650000": {
        "650100": "乌鲁木齐市",
        "650200": "克拉玛依市",
        "650400": "吐鲁番市",
        "650500": "哈密市",
        "652300": "昌吉回族自治州",
        "652700": "博尔塔拉蒙古自治州",
        "652800": "巴音郭楞蒙古自治州",
        "652900": "阿克苏地区",
        "653000": "克孜勒苏柯尔克孜自治州",
        "653100": "喀什地区",
        "653200": "和田地区",
        "654000": "伊犁哈萨克自治州",
        "654200": "塔城地区",
        "654300": "阿勒泰地区",
        "659001": "石河子市",
        "659002": "阿拉尔市",
        "659003": "图木舒克市",
        "659004": "五家渠市",
        "659006": "铁门关市"
    },
    "650100": {
        "650102": "天山区",
        "650103": "沙依巴克区",
        "650104": "新市区",
        "650105": "水磨沟区",
        "650106": "头屯河区",
        "650107": "达坂城区",
        "650109": "米东区",
        "650121": "乌鲁木齐县",
        "650171": "乌鲁木齐经济技术开发区",
        "650172": "乌鲁木齐高新技术产业开发区"
    },
    "650102": {
        "650102002": "燕儿窝街道",
        "650102003": "胜利路街道",
        "650102004": "团结路街道",
        "650102005": "解放南路街道",
        "650102006": "新华南路街道",
        "650102007": "和平路街道",
        "650102008": "解放北路街道",
        "650102009": "幸福路街道",
        "650102010": "东门街道",
        "650102011": "新华北路街道",
        "650102012": "青年路街道",
        "650102013": "碱泉街道",
        "650102014": "延安路街道",
        "650102015": "红雁街道",
        "650102016": "南草滩街道",
        "650102017": "东泉路街道",
        "650102400": "二道桥街道",
        "650102401": "黑甲山街道",
        "650102402": "大湾街道",
        "650102403": "赛马场街道",
        "650102404": "南湾街街道"
    },
    "650103": {
        "650103001": "长江路街道",
        "650103002": "和田街街道",
        "650103003": "扬子江路街道",
        "650103004": "友好南路街道",
        "650103005": "友好北路街道",
        "650103006": "八一街道",
        "650103007": "炉院街街道",
        "650103008": "西山街道",
        "650103009": "雅玛里克山街道",
        "650103010": "红庙子街道",
        "650103011": "长胜东街道",
        "650103012": "长胜西街道",
        "650103013": "长胜南街道",
        "650103014": "火车南站街道",
        "650103015": "仓房沟片区街道",
        "650103016": "环卫路街道",
        "650103017": "骑马山街道",
        "650103018": "平顶山街道",
        "650103501": "兵团农十二师一零四团",
        "650103502": "兵团十二师西山农场"
    },
    "650104": {
        "650104001": "北京路街道",
        "650104002": "二工街道",
        "650104003": "三工街道",
        "650104004": "石油新村街道",
        "650104005": "迎宾路街道",
        "650104006": "喀什东路街道",
        "650104009": "八家户片区管委会",
        "650104010": "银川路街道",
        "650104011": "南纬路街道",
        "650104012": "杭州路街道",
        "650104013": "鲤鱼山街道",
        "650104014": "百园路街道",
        "650104015": "正扬路街道",
        "650104016": "机场片区管委会",
        "650104017": "友谊路街道",
        "650104100": "安宁渠镇",
        "650104200": "二工乡",
        "650104201": "地窝堡乡",
        "650104202": "青格达湖乡",
        "650104203": "六十户乡"
    },
    "650105": {
        "650105001": "水磨沟街道",
        "650105002": "六道湾街道",
        "650105003": "苇湖梁街道",
        "650105004": "八道湾街道",
        "650105005": "新民路街道",
        "650105006": "南湖南路街道",
        "650105007": "南湖北路街道",
        "650105008": "七道湾街道",
        "650105010": "榆树沟街道",
        "650105011": "石人子沟街道",
        "650105012": "水塔山街道",
        "650105013": "华光街街道",
        "650105014": "龙盛街街道",
        "650105015": "振安街街道"
    },
    "650106": {
        "650106001": "钢城片区管委会",
        "650106002": "火车西站片区管委会",
        "650106003": "王家沟街道",
        "650106004": "乌昌路街道",
        "650106005": "北站西路街道",
        "650106198": "头屯河区乡类似乡镇单位",
        "650106501": "兵团十二师三坪农场",
        "650106502": "兵团十二师五一农场",
        "650106503": "新疆兵团十二师头屯河农场"
    },
    "650107": {
        "650107001": "艾维尔沟街道",
        "650107004": "乌拉泊街道",
        "650107006": "达坂城区盐湖街道",
        "650107100": "达坂城镇",
        "650107200": "东沟乡",
        "650107201": "西沟乡",
        "650107202": "阿克苏乡",
        "650107400": "柴窝堡管委会"
    },
    "650109": {
        "650109001": "石化街道",
        "650109002": "地磅街道",
        "650109003": "卡子湾街道",
        "650109004": "古牧地东路街道",
        "650109005": "古牧地西路街道",
        "650109006": "南路街道",
        "650109007": "永祥街街道",
        "650109008": "盛达东路片区管委会",
        "650109100": "古牧地镇",
        "650109101": "铁厂沟镇",
        "650109102": "长山子镇",
        "650109103": "羊毛工镇",
        "650109104": "三道坝镇",
        "650109200": "柏杨河乡",
        "650109201": "芦草沟乡"
    },
    "650121": {
        "650121001": "清水泉片区管委会（托里乡）",
        "650121002": "谢家沟片区管委会（甘沟乡）",
        "650121100": "水西沟镇",
        "650121102": "板房沟镇",
        "650121103": "永丰镇",
        "650121207": "萨尔达坂乡",
        "650121208": "甘沟乡",
        "650121213": "托里乡"
    },
    "650171": {
        "650171001": "中亚北路街道",
        "650171002": "中亚南路街道",
        "650171004": "嵩山街街道",
        "650171005": "高铁片区管委会",
        "650171006": "白鸟湖片区管委会",
        "650171007": "西湖片区管委会",
        "650171008": "北站东路街道",
        "650171009": "两河片区管委会"
    },
    "650172": {"650172001": "高新街街道", "650172002": "长春中路街道"},
    "650200": {"650202": "独山子区", "650203": "克拉玛依区", "650204": "白碱滩区", "650205": "乌尔禾区"},
    "650202": {"650202001": "金山路街道", "650202002": "西宁路街道", "650202003": "新北区"},
    "650203": {
        "650203001": "天山路街道",
        "650203002": "胜利路街道",
        "650203003": "昆仑路街道",
        "650203004": "银河路街道",
        "650203005": "金龙镇街道",
        "650203006": "五五新镇街道",
        "650203007": "迎宾街道",
        "650203008": "古海街道",
        "650203200": "小拐乡",
        "650203506": "兵团一二九团",
        "650203530": "兵团一三六团"
    },
    "650204": {"650204001": "中兴路街道", "650204002": "三平路街道"},
    "650205": {"650205001": "柳树街街道", "650205100": "乌尔禾镇", "650205509": "兵团一三七团"},
    "650400": {"650402": "高昌区", "650421": "鄯善县", "650422": "托克逊县"},
    "650402": {
        "650402001": "老城路街道",
        "650402002": "高昌路街道",
        "650402003": "葡萄沟街道",
        "650402100": "七泉湖镇",
        "650402101": "大河沿镇",
        "650402102": "亚尔镇",
        "650402103": "艾丁湖镇",
        "650402104": "葡萄镇",
        "650402203": "恰特喀勒乡",
        "650402204": "二堡乡",
        "650402205": "三堡乡",
        "650402206": "胜金乡",
        "650402400": "红柳河园艺场",
        "650402401": "原种场",
        "650402502": "兵团二二一团"
    },
    "650421": {
        "650421100": "鄯善镇",
        "650421101": "七克台镇",
        "650421102": "火车站镇",
        "650421103": "连木沁镇",
        "650421104": "鲁克沁镇",
        "650421105": "辟展镇",
        "650421106": "迪坎镇",
        "650421201": "东巴扎回族乡",
        "650421202": "吐峪沟乡",
        "650421203": "达朗坎乡",
        "650421400": "南山矿区",
        "650421500": "园艺场"
    },
    "650422": {
        "650422100": "托克逊镇",
        "650422101": "库米什镇",
        "650422102": "克尔碱镇",
        "650422103": "阿乐惠镇",
        "650422104": "伊拉湖镇",
        "650422105": "夏镇",
        "650422106": "博斯坦镇",
        "650422201": "郭勒布依乡"
    },
    "650500": {"650502": "伊州区", "650521": "巴里坤哈萨克自治县", "650522": "伊吾县"},
    "650502": {
        "650502001": "东河街道",
        "650502002": "西河街道",
        "650502003": "城北街道",
        "650502004": "丽园街道",
        "650502005": "石油新城街道",
        "650502100": "雅满苏镇",
        "650502101": "七角井镇",
        "650502102": "星星峡镇",
        "650502103": "二堡镇",
        "650502104": "陶家宫镇",
        "650502105": "五堡镇",
        "650502106": "三道岭镇",
        "650502200": "沁城乡",
        "650502201": "乌拉台哈萨克民族乡",
        "650502202": "双井子乡",
        "650502203": "大泉湾乡",
        "650502205": "回城乡",
        "650502206": "花园乡",
        "650502207": "南湖乡",
        "650502209": "德外里都如克哈萨克乡",
        "650502210": "西山乡",
        "650502211": "天山乡",
        "650502212": "白石头乡",
        "650502214": "柳树沟乡",
        "650502404": "现代农业园区管理委员会",
        "650502405": "哈密伊吾马场",
        "650502406": "哈密工业园区",
        "650502407": "东郊开发区管理委员会",
        "650502500": "兵团红星一场",
        "650502501": "兵团红星二场",
        "650502502": "兵团红星四场",
        "650502503": "兵团黄田农场",
        "650502504": "兵团火箭农场",
        "650502505": "兵团柳树泉农场"
    },
    "650521": {
        "650521100": "巴里坤镇",
        "650521101": "博尔羌吉镇",
        "650521102": "大河镇",
        "650521103": "奎苏镇",
        "650521104": "三塘湖镇",
        "650521200": "萨尔乔克乡",
        "650521201": "海子沿乡",
        "650521202": "下涝坝乡",
        "650521205": "石人子乡",
        "650521206": "花园乡",
        "650521208": "大红柳峡乡",
        "650521209": "八墙子乡",
        "650521400": "良种繁育场",
        "650521401": "黄土场开发区",
        "650521402": "巴里坤县山南开发区",
        "650521500": "兵团红山农场"
    },
    "650522": {
        "650522100": "伊吾镇",
        "650522101": "淖毛湖镇",
        "650522102": "盐池镇",
        "650522201": "苇子峡乡",
        "650522202": "下马崖乡",
        "650522204": "吐葫芦乡",
        "650522205": "前山哈萨克民族乡",
        "650522400": "伊吾县工业加工区",
        "650522403": "伊吾县山南开发区管委会",
        "650522500": "兵团淖毛湖农场"
    },
    "652300": {
        "652301": "昌吉市",
        "652302": "阜康市",
        "652323": "呼图壁县",
        "652324": "玛纳斯县",
        "652325": "奇台县",
        "652327": "吉木萨尔县",
        "652328": "木垒哈萨克自治县"
    },
    "652301": {
        "652301001": "宁边路",
        "652301002": "延安北路",
        "652301003": "北京南路",
        "652301004": "建国路",
        "652301005": "中山路街道",
        "652301006": "绿洲路街道",
        "652301100": "硫磺沟镇",
        "652301101": "三工镇",
        "652301102": "榆树沟镇",
        "652301103": "二六工镇",
        "652301104": "大西渠镇",
        "652301105": "六工镇",
        "652301106": "滨湖镇",
        "652301107": "佃坝镇",
        "652301202": "阿什里哈萨克民族乡",
        "652301203": "庙尔沟乡",
        "652301400": "新疆昌吉国家农业科技园区管理委员会",
        "652301401": "昌吉市北部荒漠生态保护管理站",
        "652301402": "昌吉国家高新技术产业开发区",
        "652301503": "兵团共青团农场",
        "652301504": "兵团军户农场"
    },
    "652302": {
        "652302001": "博峰街",
        "652302002": "阜新街",
        "652302003": "准东",
        "652302100": "甘河子镇",
        "652302101": "城关镇",
        "652302102": "九运街镇",
        "652302103": "滋泥泉子镇",
        "652302201": "上户沟哈萨克民族乡",
        "652302202": "水磨沟乡",
        "652302203": "三工河哈萨克民族乡",
        "652302503": "兵团农六师土墩子农场",
        "652302504": "兵团六运湖农场",
        "652302505": "兵团二二二团农场"
    },
    "652323": {
        "652323100": "呼图壁镇",
        "652323101": "大丰镇",
        "652323102": "雀尔沟镇",
        "652323103": "二十里店镇",
        "652323104": "园户村镇",
        "652323105": "五工台镇",
        "652323201": "石梯子哈萨克民族乡",
        "652323402": "干河子林场",
        "652323405": "呼图壁种牛场",
        "652323520": "兵团一零五团",
        "652323521": "兵团一零六团",
        "652323523": "兵团芳草湖总场"
    },
    "652324": {
        "652324100": "玛纳斯镇",
        "652324101": "乐土驿镇",
        "652324102": "包家店镇",
        "652324103": "凉州户镇",
        "652324104": "北五岔镇",
        "652324105": "六户地镇",
        "652324106": "兰州湾镇",
        "652324201": "广东地乡",
        "652324202": "清水河子哈萨克民族乡",
        "652324203": "塔西河乡",
        "652324204": "旱卡子滩乡",
        "652324400": "玛电工业区",
        "652324401": "园艺场",
        "652324402": "自治区林业厅玛纳斯平原林场",
        "652324403": "新疆农科院玛纳斯试验站",
        "652324520": "兵团农六师新湖农场",
        "652324521": "兵团一四七团",
        "652324522": "兵团一四八团",
        "652324523": "兵团一四九团",
        "652324524": "兵团一五零团"
    },
    "652325": {
        "652325100": "奇台镇",
        "652325101": "老奇台镇",
        "652325102": "半截沟镇",
        "652325103": "吉布库镇",
        "652325104": "东湾镇",
        "652325105": "西地镇",
        "652325106": "碧流河镇",
        "652325107": "三个庄子镇",
        "652325108": "西北湾镇",
        "652325202": "坎尔孜乡",
        "652325203": "五马场乡",
        "652325204": "古城乡",
        "652325205": "乔仁乡",
        "652325206": "七户乡",
        "652325208": "塔塔尔乡",
        "652325503": "兵团奇台农场",
        "652325504": "兵团农六师北塔山牧场"
    },
    "652327": {
        "652327100": "吉木萨尔镇",
        "652327101": "三台镇",
        "652327102": "泉子街镇",
        "652327103": "北庭镇",
        "652327104": "二工镇",
        "652327105": "大有镇",
        "652327202": "庆阳湖乡",
        "652327203": "老台乡",
        "652327205": "新地乡",
        "652327400": "五彩湾工业园区",
        "652327500": "兵团农六师红旗农场"
    },
    "652328": {
        "652328100": "木垒镇",
        "652328101": "西吉尔镇",
        "652328102": "东城镇",
        "652328103": "新户镇",
        "652328200": "英格堡乡",
        "652328201": "照壁山乡",
        "652328203": "雀仁乡",
        "652328204": "白杨河乡",
        "652328205": "大石头乡",
        "652328206": "大南沟乌孜别克乡",
        "652328207": "博斯坦乡",
        "652328401": "克热克库都克牧场"
    },
    "652700": {"652701": "博乐市", "652702": "阿拉山口市", "652722": "精河县", "652723": "温泉县"},
    "652701": {
        "652701001": "青得里街道",
        "652701002": "顾力木图街道",
        "652701004": "南城区街道",
        "652701100": "小营盘镇",
        "652701101": "达勒特镇",
        "652701102": "乌图布拉格镇",
        "652701103": "青得里镇",
        "652701201": "贝林哈日莫墩乡",
        "652701400": "阿热勒托海牧场",
        "652701408": "五台工业园区",
        "652701501": "兵团八十一团",
        "652701502": "兵团八十四团",
        "652701504": "兵团八十六团",
        "652701505": "兵团八十九团",
        "652701506": "兵团九十团"
    },
    "652702": {"652702001": "阿拉套街道", "652702100": "艾比湖镇"},
    "652722": {
        "652722100": "精河镇",
        "652722101": "大河沿子镇",
        "652722102": "托里镇",
        "652722103": "托托镇",
        "652722200": "茫丁乡",
        "652722400": "阿合其农场",
        "652722401": "八家户农场",
        "652722402": "精河工业园区",
        "652722503": "兵团八十三团",
        "652722504": "兵团九十一团"
    },
    "652723": {
        "652723100": "博格达尔镇",
        "652723101": "哈日布呼镇",
        "652723102": "安格里格镇",
        "652723201": "查干屯格乡",
        "652723202": "扎勒木特乡",
        "652723203": "塔秀乡",
        "652723400": "呼和托哈种畜场",
        "652723401": "昆得仑牧场",
        "652723502": "兵团八十七团",
        "652723503": "兵团八十八团"
    },
    "652800": {
        "652801": "库尔勒市",
        "652822": "轮台县",
        "652823": "尉犁县",
        "652824": "若羌县",
        "652825": "且末县",
        "652826": "焉耆回族自治县",
        "652827": "和静县",
        "652828": "和硕县",
        "652829": "博湖县",
        "652871": "库尔勒经济技术开发区"
    },
    "652801": {
        "652801001": "团结街道",
        "652801002": "萨依巴格街道",
        "652801003": "天山街道",
        "652801004": "新城街道",
        "652801005": "建设街道",
        "652801006": "朝阳街道",
        "652801007": "梨香街道",
        "652801100": "塔什店镇",
        "652801101": "上户镇",
        "652801102": "库尔勒市西尼尔镇",
        "652801200": "铁克其乡",
        "652801201": "恰尔巴格乡",
        "652801202": "英下乡",
        "652801203": "库尔勒市兰干乡",
        "652801204": "和什力克乡",
        "652801205": "哈拉玉宫乡",
        "652801206": "阿瓦提乡",
        "652801207": "库尔勒市托布力其乡",
        "652801208": "普惠乡",
        "652801400": "库尔楚园艺场",
        "652801401": "包头湖农场",
        "652801402": "普惠农场",
        "652801403": "巴州阿瓦提农场",
        "652801404": "巴州沙依东园艺场",
        "652801408": "巴州奶牛场",
        "652801409": "普惠牧场",
        "652801410": "经济牧场",
        "652801411": "良种场",
        "652801501": "兵团二十九团",
        "652801502": "兵团三十团"
    },
    "652822": {
        "652822100": "轮台镇",
        "652822101": "轮南镇",
        "652822102": "群巴克镇",
        "652822103": "阳霞镇",
        "652822201": "哈尔巴克乡",
        "652822202": "野云沟乡",
        "652822203": "阿克萨来乡",
        "652822204": "塔尔拉克乡",
        "652822205": "草湖乡",
        "652822206": "铁热克巴扎乡",
        "652822207": "策达雅乡"
    },
    "652823": {
        "652823100": "尉犁镇",
        "652823110": "团结镇",
        "652823120": "兴平镇",
        "652823200": "塔里木乡",
        "652823203": "墩阔坦乡",
        "652823204": "喀尔曲尕乡",
        "652823205": "阿克苏普乡",
        "652823206": "古勒巴格乡",
        "652823501": "兵团三十一团",
        "652823502": "兵团三十三团",
        "652823503": "兵团三十四团"
    },
    "652824": {
        "652824100": "若羌镇",
        "652824101": "依吞布拉克镇",
        "652824102": "罗布泊镇",
        "652824103": "瓦石峡镇",
        "652824104": "铁干里克镇",
        "652824201": "吾塔木乡",
        "652824203": "铁木里克乡",
        "652824204": "祁曼塔克乡",
        "652824501": "兵团三十六团"
    },
    "652825": {
        "652825100": "且末镇",
        "652825101": "奥依亚依拉克镇",
        "652825102": "塔提让镇",
        "652825103": "塔中镇",
        "652825104": "阿羌镇",
        "652825105": "阿热勒镇",
        "652825201": "琼库勒乡",
        "652825202": "托格拉克勒克乡",
        "652825203": "巴格艾日克乡",
        "652825204": "英吾斯塘乡",
        "652825205": "阿克提坎墩乡",
        "652825206": "阔什萨特玛乡",
        "652825209": "库拉木勒克乡",
        "652825501": "兵团三十七团",
        "652825502": "兵团三十八团"
    },
    "652826": {
        "652826100": "焉耆镇",
        "652826101": "七个星镇",
        "652826102": "永宁镇",
        "652826103": "四十里城子镇",
        "652826200": "北大渠乡",
        "652826201": "五号渠乡",
        "652826203": "查汗采开乡",
        "652826204": "包尔海乡",
        "652826400": "王家庄牧场",
        "652826401": "苏海良种场",
        "652826501": "兵团二十七团"
    },
    "652827": {
        "652827100": "和静镇",
        "652827101": "巴伦台镇",
        "652827102": "巴润哈尔莫敦镇",
        "652827103": "哈尔莫敦镇",
        "652827104": "巴音布鲁克镇",
        "652827105": "巩乃斯镇",
        "652827106": "乃门莫敦镇",
        "652827107": "协比乃尔布呼镇",
        "652827203": "克尔古提乡",
        "652827204": "阿拉沟乡",
        "652827205": "额勒再特乌鲁乡",
        "652827206": "巴音郭楞乡",
        "652827400": "乌拉斯台农场",
        "652827501": "兵团二十一团",
        "652827502": "兵团二十二团",
        "652827503": "兵团二二三团",
        "652827504": "兵团农二师湖光糖厂"
    },
    "652828": {
        "652828100": "特吾里克镇",
        "652828101": "塔哈其镇",
        "652828102": "曲惠镇",
        "652828200": "乌什塔拉回族民族乡",
        "652828203": "苏哈特乡",
        "652828204": "乃仁克尔乡",
        "652828205": "新塔热乡",
        "652828400": "清水河农场",
        "652828402": "和硕县马兰公安管区",
        "652828504": "兵团二师二十四团"
    },
    "652829": {
        "652829100": "博湖镇",
        "652829101": "本布图镇",
        "652829200": "塔温觉肯乡",
        "652829202": "乌兰再格森乡",
        "652829203": "才坎诺尔乡",
        "652829204": "查干诺尔乡",
        "652829205": "博斯腾湖乡",
        "652829501": "兵团二十五团"
    },
    "652871": {"652871400": "开发区乡级单位"},
    "652900": {
        "652901": "阿克苏市",
        "652922": "温宿县",
        "652923": "库车县",
        "652924": "沙雅县",
        "652925": "新和县",
        "652926": "拜城县",
        "652927": "乌什县",
        "652928": "阿瓦提县",
        "652929": "柯坪县"
    },
    "652901": {
        "652901001": "栏杆街道",
        "652901002": "英巴扎街道",
        "652901003": "红桥街道",
        "652901004": "新城街道",
        "652901005": "南城街道",
        "652901006": "柯柯牙街道",
        "652901007": "多浪街道",
        "652901100": "喀勒塔勒镇",
        "652901101": "阿依库勒镇",
        "652901200": "依干其乡",
        "652901201": "拜什吐格曼乡",
        "652901202": "托普鲁克乡",
        "652901203": "库木巴希乡",
        "652901401": "红旗坡农场",
        "652901404": "实验林场",
        "652901407": "纺织工业城",
        "652901408": "经济技术开发区",
        "652901409": "特色产业园区"
    },
    "652922": {
        "652922100": "温宿镇",
        "652922101": "吐木秀克镇",
        "652922102": "克孜勒镇",
        "652922103": "阿热勒镇",
        "652922104": "佳木镇",
        "652922105": "托甫汗镇",
        "652922106": "共青团镇",
        "652922107": "柯柯牙镇",
        "652922200": "托乎拉乡",
        "652922202": "恰格拉克乡",
        "652922204": "依希来木其乡",
        "652922206": "古勒阿瓦提乡",
        "652922207": "博孜墩柯尔克孜族乡"
    },
    "652923": {
        "652923001": "热斯坦街道",
        "652923002": "萨克萨克街道",
        "652923003": "新城街道",
        "652923004": "东城街道",
        "652923101": "乌恰镇",
        "652923102": "前进镇",
        "652923103": "齐满镇",
        "652923104": "墩阔坦镇",
        "652923105": "牙哈镇",
        "652923106": "乌尊镇",
        "652923107": "龟兹镇",
        "652923108": "二八台镇",
        "652923201": "玉奇吾斯塘乡",
        "652923202": "东风乡",
        "652923203": "红星乡",
        "652923204": "阿克吾斯塘乡",
        "652923205": "阿格乡",
        "652923206": "塔里木乡",
        "652923500": "库车经济技术开发区"
    },
    "652924": {
        "652924100": "沙雅镇",
        "652924101": "托依堡勒迪镇",
        "652924102": "红旗镇",
        "652924103": "英买力镇",
        "652924104": "沙雅县哈德墩镇",
        "652924105": "古勒巴格镇",
        "652924106": "海楼镇",
        "652924205": "努尔巴格乡",
        "652924206": "塔里木乡",
        "652924207": "盖孜库木乡",
        "652924208": "央塔克协海尔乡",
        "652924400": "新垦农场",
        "652924402": "二牧场",
        "652924403": "沙雅监狱",
        "652924404": "沙雅县工业集中区"
    },
    "652925": {
        "652925100": "新和镇",
        "652925101": "尤鲁都斯巴格镇",
        "652925102": "依其艾日克镇",
        "652925103": "塔什艾日克镇",
        "652925201": "排先拜巴扎乡",
        "652925203": "渭干乡",
        "652925204": "玉奇喀特乡",
        "652925205": "塔木托格拉克乡",
        "652925300": "央塔库都片区管委会乡极"
    },
    "652926": {
        "652926100": "拜城镇",
        "652926101": "铁热克镇",
        "652926102": "察尔其镇",
        "652926103": "赛里木镇",
        "652926200": "黑英山乡",
        "652926201": "克孜尔乡",
        "652926203": "托克逊乡",
        "652926204": "亚吐尔乡",
        "652926205": "康其乡",
        "652926206": "布隆乡",
        "652926207": "米吉克乡",
        "652926208": "温巴什乡",
        "652926209": "大桥乡",
        "652926210": "老虎台乡",
        "652926400": "大宛其管理委员会",
        "652926401": "种羊场"
    },
    "652927": {
        "652927100": "乌什镇",
        "652927101": "阿合雅镇",
        "652927102": "依麻木镇",
        "652927200": "阿克托海乡",
        "652927201": "亚科瑞克乡",
        "652927202": "阿恰塔格乡",
        "652927205": "英阿瓦提乡",
        "652927206": "亚曼苏柯尔克孜族乡",
        "652927207": "奥特贝希乡"
    },
    "652928": {
        "652928100": "阿瓦提镇",
        "652928101": "乌鲁却勒镇",
        "652928102": "拜什艾日克镇",
        "652928103": "塔木托格拉克镇",
        "652928104": "英艾日克镇",
        "652928200": "阿依巴格乡",
        "652928203": "多浪乡",
        "652928204": "巴格托格拉克乡",
        "652928405": "阿克苏监狱"
    },
    "652929": {"652929100": "柯坪镇", "652929101": "盖孜力克镇", "652929102": "阿恰勒镇", "652929201": "玉尔其乡", "652929203": "启浪乡"},
    "653000": {"653001": "阿图什市", "653022": "阿克陶县", "653023": "阿合奇县", "653024": "乌恰县"},
    "653001": {
        "653001001": "幸福路街道",
        "653001002": "光明路街道",
        "653001003": "新城街道",
        "653001100": "上阿图什镇",
        "653001200": "松他克乡",
        "653001201": "阿扎克乡",
        "653001202": "阿湖乡",
        "653001204": "格达良乡",
        "653001205": "哈拉峻乡",
        "653001206": "吐古买提乡",
        "653001500": "兵团农三师红旗农场"
    },
    "653022": {
        "653022100": "阿克陶镇",
        "653022101": "奥依塔克镇",
        "653022200": "玉麦乡",
        "653022201": "皮拉勒乡",
        "653022202": "巴仁乡",
        "653022203": "喀热克其克乡",
        "653022204": "加马铁热克乡",
        "653022205": "木吉乡",
        "653022206": "布伦口乡",
        "653022207": "克孜勒陶乡",
        "653022208": "恰尔隆乡",
        "653022210": "塔尔塔吉克族乡",
        "653022400": "托尔塔依农场",
        "653022401": "阿克达拉牧场",
        "653022402": "原种场",
        "653022403": "克孜勒苏柯尔克孜自治州林场",
        "653022404": "苗圃"
    },
    "653023": {
        "653023100": "阿合奇镇",
        "653023200": "库兰萨日克乡",
        "653023201": "色帕巴依乡",
        "653023202": "苏木塔什乡",
        "653023203": "哈拉奇乡",
        "653023204": "哈拉布拉克乡"
    },
    "653024": {
        "653024100": "乌恰镇",
        "653024101": "康苏镇",
        "653024200": "乌鲁克恰提乡",
        "653024201": "吾合沙鲁乡",
        "653024202": "膘尔托阔依乡",
        "653024203": "黑孜苇乡",
        "653024204": "托云乡",
        "653024205": "铁列克乡",
        "653024206": "巴音库鲁提乡",
        "653024207": "波斯坦铁列克乡",
        "653024208": "吉根乡",
        "653024501": "兵团托云牧场"
    },
    "653100": {
        "653101": "喀什市",
        "653121": "疏附县",
        "653122": "疏勒县",
        "653123": "英吉沙县",
        "653124": "泽普县",
        "653125": "莎车县",
        "653126": "叶城县",
        "653127": "麦盖提县",
        "653128": "岳普湖县",
        "653129": "伽师县",
        "653130": "巴楚县",
        "653131": "塔什库尔干塔吉克自治县"
    },
    "653101": {
        "653101001": "恰萨街道",
        "653101002": "亚瓦格街道",
        "653101003": "吾斯塘博依街道",
        "653101004": "库木代尔瓦扎街道",
        "653101005": "西域大道街道",
        "653101006": "东湖街道",
        "653101007": "迎宾大道街道",
        "653101008": "西公园街道",
        "653101100": "乃则尔巴格镇",
        "653101101": "夏马勒巴格镇",
        "653101202": "多来特巴格乡",
        "653101203": "浩罕乡",
        "653101204": "色满乡",
        "653101205": "荒地乡",
        "653101206": "帕哈太克里乡",
        "653101207": "伯什克然木乡",
        "653101208": "阿瓦提乡",
        "653101209": "英吾斯坦乡",
        "653101210": "阿克喀什乡"
    },
    "653121": {
        "653121100": "托克扎克镇",
        "653121101": "兰干镇",
        "653121102": "吾库萨克镇",
        "653121103": "乌帕尔镇",
        "653121201": "塔什米里克乡",
        "653121202": "铁日木乡",
        "653121203": "布拉克苏乡",
        "653121204": "萨依巴格乡",
        "653121205": "站敏乡",
        "653121211": "木什乡",
        "653121400": "县种畜场",
        "653121401": "县园艺场",
        "653121402": "县林场",
        "653121403": "县良种场",
        "653121404": "县英艾力克大畜场",
        "653121406": "疏附广州工业城"
    },
    "653122": {
        "653122100": "疏勒镇",
        "653122101": "罕南力克镇",
        "653122102": "牙甫泉镇",
        "653122200": "巴仁乡",
        "653122201": "洋大曼乡",
        "653122202": "亚曼牙乡",
        "653122203": "巴合齐乡",
        "653122204": "塔孜洪乡",
        "653122205": "英尔力克乡",
        "653122206": "库木西力克乡",
        "653122207": "塔合其乡",
        "653122208": "艾尔木东乡",
        "653122209": "阿拉力乡",
        "653122210": "阿拉甫乡",
        "653122211": "英阿瓦提乡",
        "653122400": "疏勒南疆齐鲁工业园区"
    },
    "653123": {
        "653123100": "城镇",
        "653123101": "乌恰镇",
        "653123102": "芒辛镇",
        "653123103": "萨罕镇",
        "653123200": "城关乡",
        "653123201": "乔勒潘乡",
        "653123202": "龙甫乡",
        "653123204": "色提力乡",
        "653123206": "英也尔乡",
        "653123207": "克孜勒乡",
        "653123208": "托普鲁克乡",
        "653123209": "苏盖提乡",
        "653123211": "艾古斯乡",
        "653123212": "依格孜也尔乡",
        "653123401": "英吉沙工业园区",
        "653123500": "兵团东风农场"
    },
    "653124": {
        "653124100": "泽普镇",
        "653124101": "奎依巴格镇",
        "653124200": "波斯喀木乡",
        "653124201": "泽普县依玛乡",
        "653124202": "古勒巴格乡",
        "653124203": "赛力乡",
        "653124204": "依肯苏乡",
        "653124205": "图呼其乡",
        "653124206": "奎依巴格乡",
        "653124207": "阿克塔木乡",
        "653124208": "阿依库勒乡",
        "653124209": "布依鲁克塔吉克族乡",
        "653124210": "桐安乡",
        "653124400": "农场场部",
        "653124401": "泽普县良种场",
        "653124402": "园艺场",
        "653124403": "国营林场"
    },
    "653125": {
        "653125001": "叶尔羌街道",
        "653125002": "城中街道",
        "653125003": "城东街道",
        "653125004": "城西街道",
        "653125005": "城北街道",
        "653125100": "莎车镇",
        "653125101": "恰热克镇",
        "653125102": "艾力西湖镇",
        "653125103": "荒地镇",
        "653125104": "阿瓦提镇",
        "653125105": "白什坎特镇",
        "653125106": "依盖尔其镇",
        "653125107": "古勒巴格镇",
        "653125108": "米夏镇",
        "653125109": "托木吾斯塘镇",
        "653125110": "塔尕尔其镇",
        "653125111": "乌达力克镇",
        "653125112": "阿拉买提镇",
        "653125113": "阿扎特巴格镇",
        "653125201": "阿热勒乡",
        "653125202": "恰尔巴格乡",
        "653125204": "英吾斯塘乡",
        "653125206": "阿尔斯兰巴格乡",
        "653125207": "孜热甫夏提塔吉克族乡",
        "653125208": "亚喀艾日克乡",
        "653125209": "喀群乡",
        "653125210": "霍什拉甫乡",
        "653125211": "达木斯乡",
        "653125213": "伊什库力乡",
        "653125214": "拍克其乡",
        "653125216": "阔什艾日克乡",
        "653125217": "墩巴格乡",
        "653125220": "巴格阿瓦提乡",
        "653125221": "喀拉苏乡",
        "653125401": "喀什监狱",
        "653125402": "莎车县良种繁育场",
        "653125403": "莎车县第一林场（国营苗圃）",
        "653125404": "莎车县国营二林场",
        "653125405": "莎车县园艺场",
        "653125406": "莎车县蚕种场",
        "653125407": "莎车县鱼苗场",
        "653125408": "农科院莎车农业试验站",
        "653125409": "工业园区管委会",
        "653125410": "英阿瓦提管理委员会",
        "653125514": "兵团五十四团"
    },
    "653126": {
        "653126100": "喀格勒克镇",
        "653126101": "恰尔巴格镇",
        "653126102": "乌夏巴什镇",
        "653126200": "洛克乡",
        "653126201": "伯西热克乡",
        "653126202": "铁提乡",
        "653126203": "恰萨美其特乡",
        "653126204": "吐古其乡",
        "653126205": "江格勒斯乡",
        "653126206": "加依提勒克乡",
        "653126207": "巴仁乡",
        "653126208": "乌吉热克乡",
        "653126209": "夏合甫乡",
        "653126210": "依力克其乡",
        "653126211": "依提木孔乡",
        "653126212": "宗朗乡",
        "653126213": "柯克亚乡",
        "653126214": "西合休乡",
        "653126215": "棋盘乡",
        "653126216": "萨依巴格乡",
        "653126402": "阿克塔什农场",
        "653126403": "良种场",
        "653126404": "园艺场",
        "653126405": "林场",
        "653126408": "恰其库木管理区",
        "653126409": "叶城工业园区",
        "653126500": "兵团叶城牧场"
    },
    "653127": {
        "653127100": "麦盖提镇",
        "653127112": "巴扎结米镇",
        "653127201": "希依提墩乡",
        "653127202": "央塔克乡",
        "653127203": "吐曼塔勒乡",
        "653127204": "尕孜库勒乡",
        "653127205": "克孜勒阿瓦提乡",
        "653127206": "库木库萨尔乡",
        "653127207": "昂格特勒克乡",
        "653127208": "库尔玛乡",
        "653127400": "胡杨林场",
        "653127401": "园艺场",
        "653127402": "五一林场",
        "653127500": "兵团农三师前进水库管理处",
        "653127505": "兵团四十五团",
        "653127506": "兵团四十六团"
    },
    "653128": {
        "653128100": "岳普湖镇",
        "653128101": "艾西曼镇",
        "653128102": "铁热木镇",
        "653128103": "也克先拜巴扎镇",
        "653128200": "岳普湖乡",
        "653128203": "阿其克乡",
        "653128204": "色也克乡",
        "653128206": "巴依阿瓦提乡",
        "653128207": "阿洪鲁库木乡",
        "653128402": "岳普湖县奶牛场",
        "653128502": "兵团四十二团"
    },
    "653129": {
        "653129100": "巴仁镇",
        "653129101": "西克尔库勒镇",
        "653129102": "夏普吐勒镇",
        "653129103": "卧里托格拉克镇",
        "653129104": "克孜勒博依镇",
        "653129107": "和夏阿瓦提镇",
        "653129200": "铁日木乡",
        "653129201": "英买里乡",
        "653129202": "江巴孜乡",
        "653129205": "米夏乡",
        "653129208": "克孜勒苏乡",
        "653129209": "古勒鲁克乡",
        "653129210": "玉代克力克乡",
        "653129500": "兵团伽师总场"
    },
    "653130": {
        "653130100": "巴楚镇",
        "653130101": "色力布亚镇",
        "653130102": "阿瓦提镇",
        "653130103": "三岔口镇",
        "653130200": "恰尔巴格乡",
        "653130201": "多来提巴格乡",
        "653130202": "阿纳库勒乡",
        "653130203": "夏马勒乡",
        "653130204": "阿克萨克玛热勒乡",
        "653130205": "阿拉根乡",
        "653130206": "琼库恰克乡",
        "653130207": "英吾斯坦乡",
        "653130508": "兵团四十八团"
    },
    "653131": {
        "653131100": "塔什库尔干镇",
        "653131101": "塔吉克阿巴提镇",
        "653131200": "塔什库尔干乡",
        "653131201": "塔合曼乡",
        "653131202": "科克亚尔柯尔克孜族乡",
        "653131203": "提孜那甫乡",
        "653131204": "达布达尔乡",
        "653131205": "马尔洋乡",
        "653131206": "瓦恰乡",
        "653131207": "班迪尔乡",
        "653131208": "库科西鲁格乡",
        "653131210": "大同乡"
    },
    "653200": {
        "653201": "和田市",
        "653221": "和田县",
        "653222": "墨玉县",
        "653223": "皮山县",
        "653224": "洛浦县",
        "653225": "策勒县",
        "653226": "于田县",
        "653227": "民丰县"
    },
    "653201": {
        "653201001": "努尔巴格街道",
        "653201002": "古江巴格街道",
        "653201003": "古勒巴格街道",
        "653201004": "纳尔巴格街道",
        "653201100": "拉斯奎镇",
        "653201101": "玉龙喀什镇",
        "653201102": "吐沙拉镇",
        "653201200": "肖尔巴格乡",
        "653201201": "伊里其乡",
        "653201202": "古江巴格乡",
        "653201204": "吉亚乡",
        "653201205": "阿克恰勒乡",
        "653201401": "北京工业园区",
        "653201402": "和田市京和物流园区"
    },
    "653221": {
        "653221100": "巴格其镇",
        "653221102": "罕艾日克镇",
        "653221202": "英阿瓦提乡",
        "653221203": "英艾日克乡",
        "653221204": "布扎克乡",
        "653221205": "拉依喀乡",
        "653221206": "朗如乡",
        "653221207": "塔瓦库勒乡",
        "653221208": "伊斯拉木阿瓦提乡",
        "653221209": "色格孜库勒乡",
        "653221210": "喀什塔什乡",
        "653221211": "吾宗肖乡",
        "653221401": "和田县经济新区"
    },
    "653222": {
        "653222100": "喀拉喀什镇",
        "653222101": "扎瓦镇",
        "653222102": "奎牙镇",
        "653222103": "喀尔赛镇",
        "653222104": "普恰克其镇",
        "653222203": "阿克萨拉依乡",
        "653222204": "乌尔其乡",
        "653222205": "托胡拉乡",
        "653222206": "萨依巴格乡",
        "653222207": "加汗巴格乡",
        "653222209": "芒来乡",
        "653222210": "阔依其乡",
        "653222211": "雅瓦乡",
        "653222212": "吐外特乡",
        "653222213": "英也尔乡",
        "653222214": "喀瓦克乡",
        "653222500": "兵团四十七团",
        "653222501": "兵团二二四团"
    },
    "653223": {
        "653223001": "街道",
        "653223100": "固玛镇",
        "653223101": "杜瓦镇",
        "653223102": "赛图拉镇",
        "653223103": "木吉镇",
        "653223104": "阔什塔格镇",
        "653223105": "桑株镇",
        "653223201": "克里阳乡",
        "653223202": "科克铁热克乡",
        "653223205": "乔达乡",
        "653223206": "木奎拉乡",
        "653223207": "藏桂乡",
        "653223208": "皮亚勒玛乡",
        "653223209": "皮西那乡",
        "653223210": "巴什兰干乡",
        "653223211": "垴阿巴提塔吉克民族乡",
        "653223212": "康克尔柯尔克孜民族乡",
        "653223401": "皮山三峡工业园区",
        "653223500": "兵团皮山农场"
    },
    "653224": {
        "653224001": "城区街道",
        "653224100": "洛浦镇",
        "653224101": "山普鲁镇",
        "653224102": "杭桂镇",
        "653224201": "布亚乡",
        "653224203": "恰尔巴格乡",
        "653224205": "多鲁乡",
        "653224206": "纳瓦乡",
        "653224207": "拜什托格拉克乡",
        "653224208": "阿其克乡",
        "653224401": "良种场",
        "653224402": "洛浦县北京农业科技示范园区",
        "653224403": "洛浦县北京工业园区"
    },
    "653225": {
        "653225100": "策勒镇",
        "653225101": "固拉合玛镇",
        "653225200": "策勒乡",
        "653225202": "达玛沟乡",
        "653225203": "恰哈乡",
        "653225204": "乌鲁克萨依乡",
        "653225205": "奴尔乡",
        "653225206": "博斯坦乡",
        "653225500": "兵团一牧场"
    },
    "653226": {
        "653226100": "木尕拉镇",
        "653226101": "先拜巴扎镇",
        "653226200": "加依乡",
        "653226201": "科克亚乡",
        "653226202": "阿热勒乡",
        "653226203": "阿日希乡",
        "653226204": "兰干乡",
        "653226205": "斯也克乡",
        "653226206": "托格日尕孜乡",
        "653226207": "喀拉克尔乡",
        "653226208": "奥依托格拉克乡",
        "653226209": "阿羌乡",
        "653226210": "英巴格乡",
        "653226211": "希吾勒乡",
        "653226212": "达里雅布依乡",
        "653226401": "国营（昆仑）种羊场",
        "653226402": "于田监狱",
        "653226500": "兵团二二五团"
    },
    "653227": {
        "653227100": "尼雅镇",
        "653227200": "尼雅乡",
        "653227201": "若克雅乡",
        "653227202": "萨勒吾则克乡",
        "653227203": "叶亦克乡",
        "653227204": "安迪尔乡",
        "653227205": "亚瓦通古孜乡"
    },
    "654000": {
        "654002": "伊宁市",
        "654003": "奎屯市",
        "654004": "霍尔果斯市",
        "654021": "伊宁县",
        "654022": "察布查尔锡伯自治县",
        "654023": "霍城县",
        "654024": "巩留县",
        "654025": "新源县",
        "654026": "昭苏县",
        "654027": "特克斯县",
        "654028": "尼勒克县"
    },
    "654002": {
        "654002001": "萨依布依街道",
        "654002002": "墩买里街道",
        "654002003": "伊犁河路街道",
        "654002004": "喀赞其街道",
        "654002005": "都来提巴格街道",
        "654002006": "琼科瑞克街道",
        "654002007": "艾兰木巴格街道",
        "654002008": "解放路街道",
        "654002009": "伊宁市边境经济合作区管委会",
        "654002100": "巴彦岱镇",
        "654002101": "潘津镇",
        "654002102": "英也尔镇",
        "654002103": "达达木图镇",
        "654002201": "汉宾乡",
        "654002202": "塔什科瑞克乡",
        "654002203": "喀尔墩乡",
        "654002204": "托格拉克乡",
        "654002205": "克伯克圩孜乡",
        "654002400": "伊犁州农业良种繁育中心",
        "654002401": "伊宁市园艺场",
        "654002402": "伊犁州奶牛场"
    },
    "654003": {
        "654003001": "团结路街道办",
        "654003002": "乌东路街道办",
        "654003003": "北京路街道",
        "654003004": "乌鲁木齐西路街道",
        "654003005": "火车站街道办",
        "654003201": "开干齐乡",
        "654003508": "兵团一三一团",
        "654003510": "天北新区"
    },
    "654004": {
        "654004001": "卡拉苏街道",
        "654004002": "亚欧东路街道",
        "654004003": "亚欧西路街道",
        "654004004": "工业园区街道",
        "654004200": "伊车嘎善乡",
        "654004400": "格干沟牧场",
        "654004401": "莫乎尔牧场",
        "654004505": "兵团六十一团",
        "654004506": "兵团六十二团"
    },
    "654021": {
        "654021100": "吉里于孜镇",
        "654021101": "墩麻扎镇",
        "654021102": "英塔木镇",
        "654021103": "胡地于孜镇",
        "654021104": "巴依托海镇",
        "654021105": "阿热吾斯塘镇",
        "654021106": "萨木于孜镇",
        "654021107": "喀什镇",
        "654021201": "吐鲁番于孜乡",
        "654021202": "喀拉亚尕奇乡",
        "654021203": "武功乡",
        "654021204": "萨地克于孜乡",
        "654021205": "愉群翁回族乡",
        "654021209": "维吾尔玉其温乡",
        "654021212": "麻扎乡",
        "654021213": "温亚尔乡",
        "654021214": "阿乌利亚乡",
        "654021215": "曲鲁海乡",
        "654021400": "青年农场",
        "654021401": "多浪农场",
        "654021501": "兵团七十团中心团场"
    },
    "654022": {
        "654022100": "察布查尔镇",
        "654022101": "爱新色里镇",
        "654022102": "孙扎齐牛录镇",
        "654022103": "绰霍尔镇",
        "654022104": "加尕斯台镇",
        "654022105": "琼博拉镇",
        "654022200": "堆齐牛录乡",
        "654022203": "纳达齐牛录乡",
        "654022204": "扎库齐牛录乡",
        "654022205": "米粮泉回族乡",
        "654022206": "坎乡",
        "654022207": "阔洪奇乡",
        "654022208": "海努克乡",
        "654022401": "安班巴格良繁场",
        "654022403": "伊犁州平原林场",
        "654022404": "山区林场",
        "654022408": "都拉塔口岸",
        "654022502": "兵团六十七团",
        "654022503": "兵团六十八团",
        "654022504": "兵团六十九团"
    },
    "654023": {
        "654023100": "水定镇",
        "654023101": "清水河镇",
        "654023102": "芦草沟镇",
        "654023103": "惠远镇",
        "654023104": "萨尔布拉克镇",
        "654023200": "兰干乡",
        "654023201": "三道河乡",
        "654023204": "三宫乡",
        "654023205": "大西沟乡",
        "654023402": "果子沟牧场",
        "654023403": "良种繁育中心",
        "654023507": "兵团六十三团",
        "654023508": "兵团六十四团",
        "654023509": "兵团六十六团（中心团场）"
    },
    "654024": {
        "654024100": "巩留镇",
        "654024101": "阿克吐别克镇",
        "654024102": "库尔德宁镇",
        "654024103": "东买里镇",
        "654024104": "阿尕尔森镇",
        "654024105": "提克阿热克镇",
        "654024201": "吉尔格郎乡",
        "654024204": "塔斯托别乡",
        "654024400": "综合农场",
        "654024401": "阔什阿尕什羊场",
        "654024402": "牛场",
        "654024403": "良凡场",
        "654024405": "林场",
        "654024406": "莫乎尔农场",
        "654024510": "兵团七十三团"
    },
    "654025": {
        "654025100": "新源镇",
        "654025101": "则克台镇",
        "654025102": "阿热勒托别镇",
        "654025103": "塔勒德镇",
        "654025104": "那拉提镇",
        "654025105": "肖尔布拉克镇",
        "654025106": "喀拉布拉镇",
        "654025107": "阿勒玛勒镇",
        "654025108": "坎苏镇",
        "654025200": "别斯托别乡",
        "654025204": "吐尔根乡",
        "654025400": "种羊场",
        "654025403": "公安农场",
        "654025404": "吐尔根农场",
        "654025405": "马场",
        "654025406": "台勒哈拉牧场",
        "654025407": "野果林改良场",
        "654025511": "兵团七十一团",
        "654025512": "兵团七十二团"
    },
    "654026": {
        "654026100": "昭苏镇",
        "654026101": "喀夏加尔镇",
        "654026102": "阿克达拉镇",
        "654026103": "喀拉苏镇",
        "654026104": "洪纳海镇",
        "654026201": "乌尊布拉克乡",
        "654026203": "萨尔阔布乡",
        "654026206": "察汗乌苏蒙古族乡",
        "654026207": "夏特柯尔克孜族乡",
        "654026208": "胡松图喀尔逊蒙古族乡",
        "654026402": "种马场",
        "654026403": "天山西部林业局昭苏林场",
        "654026404": "昭苏马场",
        "654026513": "兵团七十四团",
        "654026514": "兵团七十五团",
        "654026515": "兵团七十六团",
        "654026516": "兵团七十七团"
    },
    "654027": {
        "654027100": "特克斯镇",
        "654027101": "乔拉克铁热克镇",
        "654027102": "喀拉达拉镇",
        "654027103": "齐勒乌泽克镇",
        "654027104": "喀拉托海镇",
        "654027200": "呼吉尔特蒙古民族乡",
        "654027201": "阔克苏乡",
        "654027203": "阔克铁热克柯尔克孜民族乡",
        "654027404": "特克斯军马场",
        "654027405": "科克苏林场",
        "654027517": "兵团七十八团"
    },
    "654028": {
        "654028100": "尼勒克镇",
        "654028101": "乌拉斯台镇",
        "654028102": "乌赞镇",
        "654028103": "木斯镇",
        "654028200": "苏布台乡",
        "654028201": "喀拉苏乡",
        "654028202": "加哈乌拉斯台乡",
        "654028204": "科克浩特浩尔蒙古民族乡",
        "654028206": "克令乡",
        "654028207": "喀拉托别乡",
        "654028208": "胡吉尔台乡",
        "654028402": "尼勒克马场",
        "654028404": "种蜂场",
        "654028518": "兵团七十九团"
    },
    "654200": {
        "654201": "塔城市",
        "654202": "乌苏市",
        "654221": "额敏县",
        "654223": "沙湾县",
        "654224": "托里县",
        "654225": "裕民县",
        "654226": "和布克赛尔蒙古自治县"
    },
    "654201": {
        "654201001": "和平街道",
        "654201002": "杜别克街道",
        "654201003": "新城街道",
        "654201100": "二工镇",
        "654201101": "恰夏镇",
        "654201200": "喀拉哈巴克乡",
        "654201202": "阿西尔达斡尔民族乡",
        "654201203": "阿不都拉乡",
        "654201204": "也门勒乡",
        "654201403": "恰合吉牧场",
        "654201404": "博孜达克农场",
        "654201405": "窝依加依劳牧场",
        "654201406": "地区种牛场",
        "654201502": "兵团第九师一六二团",
        "654201503": "兵团农九师一六三团",
        "654201504": "兵团农九师一六四团"
    },
    "654202": {
        "654202001": "南苑街道",
        "654202002": "虹桥街道",
        "654202003": "新市区街道",
        "654202006": "西城街道",
        "654202007": "奎河街道",
        "654202100": "白杨沟镇",
        "654202101": "哈图布呼镇",
        "654202102": "皇宫镇",
        "654202103": "车排子镇",
        "654202104": "甘河子镇",
        "654202105": "百泉镇",
        "654202106": "四棵树镇",
        "654202107": "古尔图镇",
        "654202108": "西湖镇",
        "654202109": "西大沟镇",
        "654202200": "八十四户乡",
        "654202201": "夹河子乡",
        "654202202": "九间楼乡",
        "654202203": "石桥乡",
        "654202204": "头台乡",
        "654202205": "吉尔格勒特郭愣蒙古民族乡",
        "654202206": "塔布勒合特蒙古民族乡",
        "654202400": "甘家湖牧场",
        "654202401": "巴音沟牧场",
        "654202402": "赛力克提牧场",
        "654202403": "乌苏监狱",
        "654202500": "兵团一二三团",
        "654202501": "兵团一二四团",
        "654202502": "兵团一二五团",
        "654202503": "兵团一二六团",
        "654202504": "兵团一二七团",
        "654202505": "兵团一二八团",
        "654202507": "兵团一三零团"
    },
    "654221": {
        "654221100": "额敏镇",
        "654221101": "玉什喀拉苏镇",
        "654221102": "杰勒阿尕什镇",
        "654221103": "上户镇",
        "654221104": "玛热勒苏镇",
        "654221105": "喀拉也木勒镇",
        "654221200": "郊区乡",
        "654221202": "额玛勒郭楞蒙古民族乡",
        "654221207": "喇嘛昭乡",
        "654221208": "霍吉尔特蒙古民族乡",
        "654221209": "二道桥乡",
        "654221400": "二支河牧场",
        "654221401": "加尔布拉克农场",
        "654221402": "阔什比克良种场",
        "654221403": "萨尔也木勒牧场",
        "654221404": "也木勒牧场",
        "654221405": "塔城地区种羊场",
        "654221406": "吾宗布拉克牧场",
        "654221505": "兵团农九师一六五团",
        "654221506": "兵团农九师一六六团",
        "654221507": "兵团农九师一六七团",
        "654221508": "兵团农九师一六八团",
        "654221511": "兵团农九师团结农场"
    },
    "654223": {
        "654223100": "三道河子镇",
        "654223101": "四道河子镇",
        "654223102": "老沙湾镇",
        "654223103": "乌兰乌苏镇",
        "654223104": "安集海镇",
        "654223105": "东湾镇",
        "654223106": "西戈壁镇",
        "654223107": "柳毛湾镇",
        "654223108": "金沟河镇",
        "654223200": "商户地乡",
        "654223201": "大泉乡",
        "654223202": "博尔通古乡",
        "654223400": "牛圈子牧场",
        "654223401": "博尔通古牧场",
        "654223402": "良种场",
        "654223503": "兵团一二一团",
        "654223506": "兵团一三三团",
        "654223507": "兵团一三四团",
        "654223509": "兵团一四一团",
        "654223510": "兵团一四二团",
        "654223511": "兵团一四三团",
        "654223512": "兵团一四四团"
    },
    "654224": {
        "654224100": "托里镇",
        "654224101": "铁厂沟镇",
        "654224102": "庙尔沟镇",
        "654224200": "多拉特乡",
        "654224201": "乌雪特乡",
        "654224202": "库普乡",
        "654224203": "阿克别里斗乡",
        "654224401": "白杨河林场",
        "654224402": "老风口林场",
        "654224403": "巴尔鲁克山塔斯特林场",
        "654224510": "兵团农九师一七零团"
    },
    "654225": {
        "654225100": "哈拉布拉镇",
        "654225101": "吉也克镇",
        "654225200": "哈拉布拉乡",
        "654225201": "新地乡",
        "654225202": "阿勒腾也木勒乡",
        "654225204": "江格斯乡",
        "654225400": "察汗托海牧场",
        "654225501": "兵团农九师一六一团"
    },
    "654226": {
        "654226100": "和布克赛尔镇",
        "654226101": "和什托洛盖镇",
        "654226200": "夏孜盖乡",
        "654226201": "铁布肯乌散乡",
        "654226202": "查干库勒乡",
        "654226203": "巴音傲瓦乡",
        "654226204": "莫特格乡",
        "654226205": "查和特乡",
        "654226400": "伊克乌图布拉格牧场",
        "654226401": "那仁和布克牧场",
        "654226402": "巴尕乌图布拉格牧场",
        "654226403": "布斯屯格牧场",
        "654226404": "仓德格矿区",
        "654226500": "兵团一八四团",
        "654226501": "新疆屯鑫能源开发有限公司"
    },
    "654300": {
        "654301": "阿勒泰市",
        "654321": "布尔津县",
        "654322": "富蕴县",
        "654323": "福海县",
        "654324": "哈巴河县",
        "654325": "青河县",
        "654326": "吉木乃县"
    },
    "654301": {
        "654301001": "金山路",
        "654301002": "解放路",
        "654301003": "团结路",
        "654301004": "恰秀路街道",
        "654301100": "北屯镇",
        "654301101": "阿苇滩镇",
        "654301102": "红墩镇",
        "654301103": "切木尔切克镇",
        "654301104": "阿拉哈克镇",
        "654301202": "汗德尕特蒙古族乡",
        "654301203": "拉斯特乡",
        "654301204": "喀拉希力克乡",
        "654301205": "萨尔胡松乡",
        "654301206": "巴里巴盖乡",
        "654301207": "切尔克齐乡",
        "654301400": "喀拉尕什牧场",
        "654301401": "阿克吐木斯克牧场",
        "654301500": "兵团一八一团"
    },
    "654321": {
        "654321100": "布尔津镇",
        "654321101": "冲乎尔镇",
        "654321102": "窝依莫克镇",
        "654321103": "阔斯特克镇",
        "654321201": "杜来提乡",
        "654321204": "也格孜托别乡",
        "654321205": "禾木哈纳斯蒙古民族乡"
    },
    "654322": {
        "654322100": "库额尔齐斯镇",
        "654322101": "可可托海镇",
        "654322102": "恰库尔图镇",
        "654322103": "喀拉通克镇",
        "654322104": "杜热镇",
        "654322200": "吐尔洪乡",
        "654322202": "库尔特乡",
        "654322203": "克孜勒希力克乡",
        "654322204": "铁买克乡",
        "654322205": "喀拉布勒根乡"
    },
    "654323": {
        "654323100": "福海镇",
        "654323101": "喀拉玛盖镇",
        "654323102": "解特阿热勒镇",
        "654323201": "阔克阿尕什乡",
        "654323202": "齐干吉迭乡",
        "654323204": "阿尔达乡",
        "654323400": "地区一农场",
        "654323401": "福海监狱",
        "654323500": "兵团一八二团",
        "654323501": "兵团一八三团",
        "654323502": "兵团一八七团",
        "654323503": "兵团一八八团"
    },
    "654324": {
        "654324100": "阿克齐镇",
        "654324101": "萨尔布拉克镇",
        "654324102": "齐巴尔镇",
        "654324103": "库勒拜镇",
        "654324200": "萨尔塔木乡",
        "654324201": "加依勒玛乡",
        "654324204": "铁热克提乡",
        "654324500": "兵团一八五团"
    },
    "654325": {
        "654325100": "青河镇",
        "654325101": "塔克什肯镇",
        "654325102": "阿热勒托别镇",
        "654325103": "阿格达拉镇",
        "654325104": "阿热勒镇",
        "654325202": "萨尔托海乡",
        "654325203": "查干郭勒乡",
        "654325204": "阿尕什敖包乡"
    },
    "654326": {
        "654326100": "托普铁热克镇",
        "654326101": "吉木乃镇",
        "654326102": "喀尔交镇",
        "654326103": "乌拉斯特镇",
        "654326201": "托斯特乡",
        "654326202": "恰勒什海乡",
        "654326204": "别斯铁热克乡",
        "654326500": "兵团一八六团"
    },
    "659001": {
        "659001001": "新城街道",
        "659001002": "向阳街道",
        "659001003": "红山街道",
        "659001004": "老街街道",
        "659001005": "东城街道",
        "659001100": "北泉镇",
        "659001200": "石河子乡",
        "659001500": "兵团一五二团"
    },
    "659002": {
        "659002001": "金银川路街道",
        "659002002": "幸福路街道",
        "659002003": "青松路街道",
        "659002004": "南口街道",
        "659002200": "托喀依乡",
        "659002402": "工业园区",
        "659002500": "兵团七团",
        "659002501": "兵团八团",
        "659002503": "兵团十团",
        "659002504": "兵团十一团",
        "659002505": "兵团十二团",
        "659002506": "兵团十三团",
        "659002507": "兵团十四团",
        "659002509": "兵团十六团",
        "659002511": "兵团第一师水利水电工程处",
        "659002512": "兵团第一师塔里木灌区水利管理处",
        "659002513": "阿拉尔农场",
        "659002514": "兵团第一师幸福农场",
        "659002515": "中心监狱"
    },
    "659003": {
        "659003001": "齐干却勒街道",
        "659003002": "前海街道",
        "659003003": "永安坝街道",
        "659003504": "兵团四十四团",
        "659003509": "兵团四十九团",
        "659003510": "兵团五十团",
        "659003511": "兵团五十一团",
        "659003513": "兵团五十三团",
        "659003514": "兵团图木舒克市喀拉拜勒镇",
        "659003515": "兵团图木舒克市永安坝"
    },
    "659004": {
        "659004001": "军垦路街道",
        "659004002": "青湖路街道",
        "659004003": "人民路街道",
        "659004500": "兵团一零一团",
        "659004501": "兵团一零二团",
        "659004502": "兵团一零三团"
    },
    "659006": {"659006100": "博古其镇", "659006101": "双丰镇"},
    "710000": {
        "710100": "台北",
        "710200": "高雄",
        "710300": "台南",
        "710400": "台中",
        "710500": "金门",
        "710600": "南投",
        "710700": "基隆",
        "710800": "新竹市",
        "710900": "嘉义市",
        "711100": "新北",
        "711200": "宜兰",
        "711300": "新竹县",
        "711400": "桃园",
        "711500": "苗栗",
        "711700": "彰化",
        "711900": "嘉义县",
        "712100": "云林",
        "712400": "屏东",
        "712500": "台东",
        "712600": "花莲",
        "712700": "澎湖",
        "712800": "连江"
    },
    "710100": {
        "710101": "中正",
        "710102": "大同",
        "710103": "中山",
        "710104": "松山",
        "710105": "大安",
        "710106": "万华",
        "710107": "信义",
        "710108": "士林",
        "710109": "北投",
        "710110": "内湖",
        "710111": "南港",
        "710112": "文山"
    },
    "710200": {
        "710201": "新兴",
        "710202": "前金",
        "710203": "芩雅",
        "710204": "盐埕",
        "710205": "鼓山",
        "710206": "旗津",
        "710207": "前镇",
        "710208": "三民",
        "710209": "左营",
        "710210": "楠梓",
        "710211": "小港",
        "710241": "苓雅",
        "710242": "仁武",
        "710243": "大社",
        "710244": "冈山",
        "710245": "路竹",
        "710246": "阿莲",
        "710247": "田寮",
        "710248": "燕巢",
        "710249": "桥头",
        "710250": "梓官",
        "710251": "弥陀",
        "710252": "永安",
        "710253": "湖内",
        "710254": "凤山",
        "710255": "大寮",
        "710256": "林园",
        "710257": "鸟松",
        "710258": "大树",
        "710259": "旗山",
        "710260": "美浓",
        "710261": "六龟",
        "710262": "内门",
        "710263": "杉林",
        "710264": "甲仙",
        "710265": "桃源",
        "710266": "那玛夏",
        "710267": "茂林",
        "710268": "茄萣"
    },
    "710300": {
        "710301": "中西",
        "710302": "东区",
        "710303": "南区",
        "710304": "北区",
        "710305": "安平",
        "710306": "安南",
        "710339": "永康",
        "710340": "归仁",
        "710341": "新化",
        "710342": "左镇",
        "710343": "玉井",
        "710344": "楠西",
        "710345": "南化",
        "710346": "仁德",
        "710347": "关庙",
        "710348": "龙崎",
        "710349": "官田",
        "710350": "麻豆",
        "710351": "佳里",
        "710352": "西港",
        "710353": "七股",
        "710354": "将军",
        "710355": "学甲",
        "710356": "北门",
        "710357": "新营",
        "710358": "后壁",
        "710359": "白河",
        "710360": "东山",
        "710361": "六甲",
        "710362": "下营",
        "710363": "柳营",
        "710364": "盐水",
        "710365": "善化",
        "710366": "大内",
        "710367": "山上",
        "710368": "新市",
        "710369": "安定"
    },
    "710400": {
        "710401": "中区",
        "710402": "东区",
        "710403": "南区",
        "710404": "西区",
        "710405": "北区",
        "710406": "北屯",
        "710407": "西屯",
        "710408": "南屯",
        "710431": "太平",
        "710432": "大里",
        "710433": "雾峰",
        "710434": "乌日",
        "710435": "丰原",
        "710436": "后里",
        "710437": "石冈",
        "710438": "东势",
        "710439": "和平",
        "710440": "新社",
        "710441": "潭子",
        "710442": "大雅",
        "710443": "神冈",
        "710444": "大肚",
        "710445": "沙鹿",
        "710446": "龙井",
        "710447": "梧栖",
        "710448": "清水",
        "710449": "大甲",
        "710450": "外埔",
        "710451": "大安"
    },
    "710500": {"710507": "金沙", "710508": "金湖", "710509": "金宁", "710510": "金城", "710511": "烈屿", "710512": "乌丘"},
    "710600": {
        "710614": "南投",
        "710615": "中寮",
        "710616": "草屯",
        "710617": "国姓",
        "710618": "埔里",
        "710619": "仁爱",
        "710620": "名间",
        "710621": "集集",
        "710622": "水里",
        "710623": "鱼池",
        "710624": "信义",
        "710625": "竹山",
        "710626": "鹿谷"
    },
    "710700": {
        "710701": "仁爱",
        "710702": "信义",
        "710703": "中正",
        "710704": "中山",
        "710705": "安乐",
        "710706": "暖暖",
        "710707": "七堵"
    },
    "710800": {"710801": "东区", "710802": "北区", "710803": "香山"},
    "710900": {"710901": "东区", "710902": "西区"},
    "711100": {
        "711130": "万里",
        "711131": "金山",
        "711132": "板桥",
        "711133": "汐止",
        "711134": "深坑",
        "711135": "石碇",
        "711136": "瑞芳",
        "711137": "平溪",
        "711138": "双溪",
        "711139": "贡寮",
        "711140": "新店",
        "711141": "坪林",
        "711142": "乌来",
        "711143": "永和",
        "711144": "中和",
        "711145": "土城",
        "711146": "三峡",
        "711147": "树林",
        "711148": "莺歌",
        "711149": "三重",
        "711150": "新庄",
        "711151": "泰山",
        "711152": "林口",
        "711153": "芦洲",
        "711154": "五股",
        "711155": "八里",
        "711156": "淡水",
        "711157": "三芝",
        "711158": "石门"
    },
    "711200": {
        "711214": "宜兰",
        "711215": "头城",
        "711216": "礁溪",
        "711217": "壮围",
        "711218": "员山",
        "711219": "罗东",
        "711220": "三星",
        "711221": "大同",
        "711222": "五结",
        "711223": "冬山",
        "711224": "苏澳",
        "711225": "南澳",
        "711226": "钓鱼"
    },
    "711300": {
        "711314": "竹北",
        "711315": "湖口",
        "711316": "新丰",
        "711317": "新埔",
        "711318": "关西",
        "711319": "芎林",
        "711320": "宝山",
        "711321": "竹东",
        "711322": "五峰",
        "711323": "横山",
        "711324": "尖石",
        "711325": "北埔",
        "711326": "峨眉"
    },
    "711400": {
        "711414": "中坜",
        "711415": "平镇",
        "711416": "龙潭",
        "711417": "杨梅",
        "711418": "新屋",
        "711419": "观音",
        "711420": "桃园",
        "711421": "龟山",
        "711422": "八德",
        "711423": "大溪",
        "711424": "复兴",
        "711425": "大园",
        "711426": "芦竹"
    },
    "711500": {
        "711519": "竹南",
        "711520": "头份",
        "711521": "三湾",
        "711522": "南庄",
        "711523": "狮潭",
        "711524": "后龙",
        "711525": "通霄",
        "711526": "苑里",
        "711527": "苗栗",
        "711528": "造桥",
        "711529": "头屋",
        "711530": "公馆",
        "711531": "大湖",
        "711532": "泰安",
        "711533": "铜锣",
        "711534": "三义",
        "711535": "西湖",
        "711536": "卓兰"
    },
    "711700": {
        "711727": "彰化",
        "711728": "芬园",
        "711729": "花坛",
        "711730": "秀水",
        "711731": "鹿港",
        "711732": "福兴",
        "711733": "线西",
        "711734": "和美",
        "711735": "伸港",
        "711736": "员林",
        "711737": "社头",
        "711738": "永靖",
        "711739": "埔心",
        "711740": "溪湖",
        "711741": "大村",
        "711742": "埔盐",
        "711743": "田中",
        "711744": "北斗",
        "711745": "田尾",
        "711746": "埤头",
        "711747": "溪州",
        "711748": "竹塘",
        "711749": "二林",
        "711750": "大城",
        "711751": "芳苑",
        "711752": "二水"
    },
    "711900": {
        "711919": "番路",
        "711920": "梅山",
        "711921": "竹崎",
        "711922": "阿里山",
        "711923": "中埔",
        "711924": "大埔",
        "711925": "水上",
        "711926": "鹿草",
        "711927": "太保",
        "711928": "朴子",
        "711929": "东石",
        "711930": "六脚",
        "711931": "新港",
        "711932": "民雄",
        "711933": "大林",
        "711934": "溪口",
        "711935": "义竹",
        "711936": "布袋"
    },
    "712100": {
        "712121": "斗南",
        "712122": "大埤",
        "712123": "虎尾",
        "712124": "土库",
        "712125": "褒忠",
        "712126": "东势",
        "712127": "台西",
        "712128": "仑背",
        "712129": "麦寮",
        "712130": "斗六",
        "712131": "林内",
        "712132": "古坑",
        "712133": "莿桐",
        "712134": "西螺",
        "712135": "二仑",
        "712136": "北港",
        "712137": "水林",
        "712138": "口湖",
        "712139": "四湖",
        "712140": "元长"
    },
    "712400": {
        "712434": "屏东",
        "712435": "三地门",
        "712436": "雾台",
        "712437": "玛家",
        "712438": "九如",
        "712439": "里港",
        "712440": "高树",
        "712441": "盐埔",
        "712442": "长治",
        "712443": "麟洛",
        "712444": "竹田",
        "712445": "内埔",
        "712446": "万丹",
        "712447": "潮州",
        "712448": "泰武",
        "712449": "来义",
        "712450": "万峦",
        "712451": "莰顶",
        "712452": "新埤",
        "712453": "南州",
        "712454": "林边",
        "712455": "东港",
        "712456": "琉球",
        "712457": "佳冬",
        "712458": "新园",
        "712459": "枋寮",
        "712460": "枋山",
        "712461": "春日",
        "712462": "狮子",
        "712463": "车城",
        "712464": "牡丹",
        "712465": "恒春",
        "712466": "满州"
    },
    "712500": {
        "712517": "台东",
        "712518": "绿岛",
        "712519": "兰屿",
        "712520": "延平",
        "712521": "卑南",
        "712522": "鹿野",
        "712523": "关山",
        "712524": "海端",
        "712525": "池上",
        "712526": "东河",
        "712527": "成功",
        "712528": "长滨",
        "712529": "金峰",
        "712530": "大武",
        "712531": "达仁",
        "712532": "太麻里"
    },
    "712600": {
        "712615": "花莲",
        "712616": "新城",
        "712617": "太鲁",
        "712618": "秀林",
        "712619": "吉安",
        "712620": "寿丰",
        "712621": "凤林",
        "712622": "光复",
        "712623": "丰滨",
        "712624": "瑞穗",
        "712625": "万荣",
        "712626": "玉里",
        "712627": "卓溪",
        "712628": "富里"
    },
    "712700": {"712707": "马公", "712708": "西屿", "712709": "望安", "712710": "七美", "712711": "白沙", "712712": "湖西"},
    "712800": {"712805": "南竿", "712806": "北竿", "712807": "莒光", "712808": "东引"},
    "810000": {"810100": "香港岛", "810200": "九龙", "810300": "新界"},
    "810100": {"810101": "中西", "810102": "湾仔", "810103": "东区", "810104": "南区"},
    "810200": {"810201": "九龙城", "810202": "油尖旺", "810203": "深水埗", "810204": "黄大仙", "810205": "观塘"},
    "810300": {
        "810301": "北区",
        "810302": "大埔",
        "810303": "沙田",
        "810304": "西贡",
        "810305": "元朗",
        "810306": "屯门",
        "810307": "荃湾",
        "810308": "葵青",
        "810309": "离岛"
    },
    "820000": {"820100": "澳门半岛", "820200": "离岛"},
    "86": {
        "110000": "北京市",
        "120000": "天津市",
        "130000": "河北省",
        "140000": "山西省",
        "150000": "内蒙古自治区",
        "210000": "辽宁省",
        "220000": "吉林省",
        "230000": "黑龙江省",
        "310000": "上海市",
        "320000": "江苏省",
        "330000": "浙江省",
        "340000": "安徽省",
        "350000": "福建省",
        "360000": "江西省",
        "370000": "山东省",
        "410000": "河南省",
        "420000": "湖北省",
        "430000": "湖南省",
        "440000": "广东省",
        "450000": "广西壮族自治区",
        "460000": "海南省",
        "500000": "重庆市",
        "510000": "四川省",
        "520000": "贵州省",
        "530000": "云南省",
        "540000": "西藏自治区",
        "610000": "陕西省",
        "620000": "甘肃省",
        "630000": "青海省",
        "640000": "宁夏回族自治区",
        "650000": "新疆维吾尔自治区",
        "710000": "台湾",
        "810000": "香港",
        "820000": "澳门"
    }
}
