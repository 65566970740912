<template>
  <div style="width: 100%">
    <el-cascader placeholder="请选择区域" v-if="show" style="width: 100%" :props="props" @change="handleChange"></el-cascader>
  </div>
</template>

<script>
import areaData from "@/components/shop/YChooseAreaPro/area-data";

export default {
  name: "YChooseAreaPro",
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    mode: {
      type: Number,
      default: 1,
    },
    modelVal: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      show:true,
      value: this.modelVal,
      props: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          let nodeVal = node.value ? node.value : node.level === 0 ? "86" : false;
          if (!nodeVal) {
            resolve([])
            return
          }
          let nodeKeys = Object.keys(areaData[nodeVal]);
          let arr = [];
          nodeKeys.forEach(item => {
            arr.push({
              label: areaData[nodeVal][item],
              value: item,
              leaf: !(areaData[item] && node.level < (4 - this.mode))
            })
          })
          resolve(arr)
        }
      }
    }
  },
  mounted() {

  },
  computed: {
    modeLabel() {
      let str = ""
      switch (parseInt(this.mode)) {
        case 4:
          str = "省级代理";
          break;
        case 3:
          str = "市级代理";
          break;
        case 2:
          str = "区县代理";
          break;
        case 1:
          str = "乡镇代理";
          break;
      }
      return str;
    }
  },
  methods: {
    reset(){
      this.show = false;
      setTimeout(()=>{
        this.show = true;
      },100)
    },
    handleChange(e) {
      let arr = [];
      e.forEach((item, key) => {
        let top = key === 0 ? '86' : e[key - 1]
        arr.push({
          label: areaData[top][e[key]],
          value: e[key]
        })
      })
      this.$emit("change", arr)
    },
  }
}
</script>

<style scoped>

</style>